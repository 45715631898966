import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface RfParagraphMiniProps {
  className?: string
  color?: ColorOptions
}

const RfParagraphMini = ({
  children,
  color = ColorOptions.gray400,
  className
}: PropsWithChildren<RfParagraphMiniProps>) => (
  <p
    className={twMerge(
      `m-0 p-0 text-${color}`,
      className, // allow margin and padding overrides
      'text-[10.5px] leading-[15.75px] sm:text-[12px] sm:leading-[18px]'
    )}
  >
    {children}
  </p>
)

export default RfParagraphMini
