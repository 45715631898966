export const capitalizeFirstLetter = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const titleize = (str: string) => {
  const titleizedStringArray = str.split(' ').map(function (str) {
    return capitalizeFirstLetter(str)
  })
  return titleizedStringArray.join(' ')
}

export const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export type Selection = Record<string, boolean>

/**
 * @param {string} str A comma-delimited string
 * @returns {Object} An object with each delimited element as key and value of true
 */
export const stringToSelections = (str: string | null | undefined) => {
  const selections: Selection = {}
  if (str) {
    str.split(',').map((item) => {
      selections[item] = true
    })
  }
  return selections
}

/**
 * @param {Object} selections An object with each selection as key and value of 'true'
 * @returns {string} A comma-delimited string formed with the keys of selections, sorted alphabetically
 */
export const selectionsToSortedString = (selections: Selection) => {
  return selections ? Object.keys(selections).sort().join(',') : null
}
/**
 * @param {string} str Convert a camel cased string into a human readable string.
 * @returns {string} A string with spaces in between each word and the first letter capitalized.
 * > humanizeCamelCase("thisIsAString")
 * > "This is a string"
 */
export function humanizeCamelCase(str: string) {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (s) => {
        return s.toUpperCase()
      })
  )
}

/**
 * @param {number} count Number to convert to a word
 * @returns {string} Number converted to a placing/rank.
 * > countToPlace(1)
 * > "first"
 */
export function countToPlace(count = 0) {
  if (count === 0) return ''
  if (Number(count) === 1) return 'first'
  if (Number(count) === 2) return 'second'
  if (Number(count) === 2) return 'third'
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
export const escapeRegexCharacters = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const numberWithCommas = (number: number) => {
  return number.toLocaleString()
}

export const pluralize = (singular: string, plural: string, count: number) => {
  return count > 1 ? plural : singular
}

export const printArrayWithMaxLength = (
  array: string[],
  maxLength: number,
  separator: string = ', '
) =>
  array.reduce((total, currentValue) => {
    const newVal = `${total}${total !== '' ? separator : ''} ${currentValue}`
    return newVal.length < maxLength ? newVal : total
  }, '')

type SnakeToCamel<S extends string> = S extends `${infer P}_${infer Q}${infer R}`
  ? `${Lowercase<P>}${Uppercase<Q>}${SnakeToCamel<R>}`
  : Lowercase<S>

// Returns a string with all hyphens and underscores removed, and all letters after hyphens and underscores capitalized.
export function toCamelCase<S extends string>(str: S): SnakeToCamel<S> {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase()) as SnakeToCamel<S>
}

export const listify = (items: string[]) => {
  if (items.length < 1) return ''
  if (items.length === 1) return items[0]
  if (items.length === 2) return `${items[0]} & ${items[1]}`

  return `${items.slice(0, items.length - 1).join(', ')}, & ${items[items.length - 1]}`
}
