import PropTypes from 'prop-types'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { typeStyle } from 'constants/types'

import { useAfterDelay } from 'hooks/useAfterDelay'

interface LoadingProps {
  ghosted?: boolean
  ratio?: number
  className?: string
  style?: React.CSSProperties
}

const RATIO_BASE = 30

const Loading = ({ ghosted, ratio, className, style }: LoadingProps) => {
  const pastDelay = useAfterDelay(200)

  if (!pastDelay) {
    return null
  }

  return (
    <div
      data-test="loading"
      className={ghosted ? 'absolute inset-0 z-100 flex pt-5' : ''}
    >
      <div
        data-test="loading-spinner"
        style={style}
        className={twMerge('mb-5 flex w-full flex-col items-center', className)}
      >
        <svg
          width={ratio ? RATIO_BASE * ratio : RATIO_BASE}
          height={ratio ? RATIO_BASE * ratio : RATIO_BASE}
          viewBox="0 0 30 30"
          className="animate-spinnerRotate"
        >
          <circle
            fill="none"
            stroke="#000"
            cx="15"
            cy="15"
            r="14"
            className="origin-center animate-spinnerDash"
            style={{
              strokeWidth: `${ratio ? 1 / ratio : 1}px`,
              strokeLinecap: 'round',
              strokeDasharray: '88px'
            }}
          ></circle>
        </svg>
      </div>
    </div>
  )
}

Loading.defaultProps = {
  ratio: 2
}

Loading.propTypes = {
  ratio: PropTypes.number,
  ghosted: PropTypes.bool,
  className: PropTypes.string,
  style: typeStyle
}

export default Loading
