import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig
} from 'graphql'

import type { ProgramProgressScalar } from 'typings/scalars'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string
  /** Represents untyped JSON */
  JSON: any
  /** A hash of program progress. */
  ProgramProgressScalar: ProgramProgressScalar
}

/** Autogenerated input type of ActivateSubscription */
export type ActivateSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Subscription member token */
  mtoken: Scalars['String']
}

/** Autogenerated return type of ActivateSubscription */
export type ActivateSubscriptionPayload = {
  __typename: 'ActivateSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Redirect if one is neccessary */
  redirectPath?: Maybe<Scalars['String']>
  /** Detail of subscription member */
  subscriptionMember?: Maybe<SubscriptionMember>
}

/** An activity on a resource */
export type Activity = {
  __typename: 'Activity'
  /** Action of activity */
  action: Scalars['String']
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Created at date in words */
  createdAtInWords: Scalars['String']
  /** ID of activity */
  id: Scalars['ID']
  /** Trackable ID */
  trackableId: Scalars['ID']
  /** Trackable slug */
  trackableSlug: Scalars['String']
  /** Trackable title */
  trackableTitle: Scalars['String']
  /** Trackable type */
  trackableType: Scalars['String']
  /** ID of user */
  userId: Scalars['ID']
}

/** Activity action */
export type ActivityAction = {
  __typename: 'ActivityAction'
  /** Action string */
  actionString?: Maybe<Scalars['String']>
  /** Reaction strings */
  reactions?: Maybe<Array<Scalars['String']>>
  /** Action type */
  type?: Maybe<Scalars['String']>
}

/** Activity list with metadata */
export type ActivityList = {
  __typename: 'ActivityList'
  /** Activity List */
  activities: Array<ActivityListItem>
  /** Pagination data for list */
  meta: Pagination
}

/** Activity list with metadata */
export type ActivityListItem = {
  __typename: 'ActivityListItem'
  /** Action performed */
  action: ActivityAction
  /** Activity Ids */
  activityIds: Array<Scalars['ID']>
  /** Link to the cohort channel if cohort channel activity */
  cohortChannelHref?: Maybe<Scalars['String']>
  /** Name of associated collection */
  collectionName?: Maybe<Scalars['String']>
  /** Path of associated collection */
  collectionPath?: Maybe<Scalars['String']>
  /** Activity excerpt */
  excerpt?: Maybe<Scalars['String']>
  /** Activity noun */
  noun: ActivityNoun
  /** Path to content referenced for building a link */
  pathname?: Maybe<Scalars['String']>
  /** Whether activity is read */
  read: Scalars['Boolean']
  /** Activity subjects */
  subjects: Array<ActivitySubject>
  /** Timestamp */
  timestamp: Scalars['String']
  /** Anchor of associated item */
  trackedAnchor?: Maybe<Scalars['String']>
  /** ID of associated item */
  trackedId?: Maybe<Scalars['ID']>
  /** Path of associated item */
  trackedPath?: Maybe<Scalars['String']>
  /** Slug of associated item */
  trackedSlug?: Maybe<Scalars['String']>
  /** Title of associated item */
  trackedTitle?: Maybe<Scalars['String']>
  /** Type of associated item */
  trackedType: Scalars['String']
}

/** Activity noun */
export type ActivityNoun = {
  __typename: 'ActivityNoun'
  /** Noun as string */
  asString?: Maybe<Scalars['String']>
  /** Recipient Name */
  name?: Maybe<Scalars['String']>
  /** String pattern */
  pattern?: Maybe<Scalars['String']>
  /** Slug of recipient */
  slug?: Maybe<Scalars['ID']>
}

/** Activity subject (User) */
export type ActivitySubject = {
  __typename: 'ActivitySubject'
  /** User Name */
  name: Scalars['String']
  /** Remaining User Names */
  remainingNames?: Maybe<Array<Scalars['String']>>
  /** User slug */
  slug?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of AddAnnotationReaction */
export type AddAnnotationReactionInput = {
  /** Annotation ID */
  annotationId: Scalars['String']
  /** Artifact ID */
  artifactId: Scalars['String']
  /** Artifact Title */
  artifactTitle?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction ID */
  reaction: Scalars['String']
  /** User ID */
  userId: Scalars['String']
}

/** Autogenerated return type of AddAnnotationReaction */
export type AddAnnotationReactionPayload = {
  __typename: 'AddAnnotationReactionPayload'
  /** Annotation reaction */
  annotationReaction?: Maybe<ArtifactAnnotationReaction>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of AddArtifactComment */
export type AddArtifactCommentInput = {
  /** Artifact ID */
  artifactId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Comment message */
  message: Scalars['String']
  /** User ID */
  userId: Scalars['String']
}

/** Autogenerated return type of AddArtifactComment */
export type AddArtifactCommentPayload = {
  __typename: 'AddArtifactCommentPayload'
  /** Artifact comment */
  artifactComment?: Maybe<ArtifactComment>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of AddArtifactCommentReaction */
export type AddArtifactCommentReactionInput = {
  /** Artifact comment ID */
  artifactCommentId: Scalars['String']
  /** Artifact ID */
  artifactId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction */
  reaction: Scalars['String']
  /** User ID */
  userId: Scalars['String']
}

/** Autogenerated return type of AddArtifactCommentReaction */
export type AddArtifactCommentReactionPayload = {
  __typename: 'AddArtifactCommentReactionPayload'
  /** Artifact comment reaction */
  artifactCommentReaction?: Maybe<ArtifactCommentReaction>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of AddEmailToUser */
export type AddEmailToUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** New email the user would like to add. */
  email: Scalars['String']
}

/** Autogenerated return type of AddEmailToUser */
export type AddEmailToUserPayload = {
  __typename: 'AddEmailToUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of newly created email */
  id?: Maybe<Scalars['String']>
}

/** Autogenerated input type of AddReaction */
export type AddReactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction kind */
  kind: Scalars['String']
  /** Reactable ID */
  reactableId: Scalars['ID']
  /** Reactable type */
  reactableType: Scalars['String']
}

/** Autogenerated return type of AddReaction */
export type AddReactionPayload = {
  __typename: 'AddReactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error message for add reaction mutation */
  errors?: Maybe<Array<Scalars['String']>>
  /** The added reaction */
  reaction?: Maybe<Reaction>
}

/** Autogenerated input type of AddUserCompany */
export type AddUserCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The company name. */
  companyName: Scalars['String']
}

/** Autogenerated return type of AddUserCompany */
export type AddUserCompanyPayload = {
  __typename: 'AddUserCompanyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The updated user (if successful). */
  user?: Maybe<User>
  /** The created user company (if successful). */
  userCompany?: Maybe<UserCompany>
}

/** An AI chat response */
export type AiChat = {
  __typename: 'AiChat'
  /** The text of the conversation */
  conversationText: Scalars['String']
  /** When the chat was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The external ID of the AI chat */
  extId: Scalars['ID']
  /** The ID of the AI chat */
  id: Scalars['ID']
  /** A brief text description of the chat */
  title: Scalars['String']
}

/** The connection type for AiChat. */
export type AiChatConnection = {
  __typename: 'AiChatConnection'
  /** A list of edges. */
  edges: Array<AiChatEdge>
  /** A list of nodes. */
  nodes: Array<AiChat>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type AiChatEdge = {
  __typename: 'AiChatEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: AiChat
}

/** A chat message */
export type AiChatMessage = {
  __typename: 'AiChatMessage'
  /** The content of the message */
  content: Scalars['String']
  /** Data associated with the message */
  data?: Maybe<Array<Scalars['JSON']>>
  /** Debug information associated with the message */
  debug?: Maybe<Scalars['JSON']>
  /** The documents associated with the message */
  documents?: Maybe<Scalars['JSON']>
  /** The ID of the message */
  id: Scalars['ID']
  /** Whether the message is predefined */
  isPredefined: Scalars['Boolean']
  /** The mode in which the message created */
  mode?: Maybe<Scalars['String']>
  /** The mode options in which the message created */
  modeOptions?: Maybe<Scalars['JSON']>
  /** The role of the message */
  role: Scalars['String']
}

/** AI Extension App */
export type AiExtensionApp = {
  __typename: 'AiExtensionApp'
  /** App Logo */
  appLogo: Image
  /** App Name */
  appName: Scalars['String']
  /** ID */
  id: Scalars['ID']
}

/** AI personalization information for a user */
export type AiPersonalization = {
  __typename: 'AiPersonalization'
  /** User-specific additional details used for AI personalization */
  additionalDetails?: Maybe<Scalars['String']>
  /** User-defined target audience for AI personalization purposes */
  audience?: Maybe<Scalars['String']>
  /** User’s company information used for AI personalization */
  company?: Maybe<Scalars['String']>
  /** Indicates if AI personalization is enabled for the user */
  enabled?: Maybe<Scalars['Boolean']>
  /** The ID of the AI personalization record */
  id: Scalars['ID']
  /** User’s industry information used for AI personalization */
  industry?: Maybe<Scalars['String']>
  /** User’s role information targeted by AI personalization */
  role?: Maybe<Scalars['String']>
}

/** Programs which accept applications */
export type ApplicablePrograms = {
  __typename: 'ApplicablePrograms'
  /** Name of the program. */
  name: Scalars['String']
  /** The path to the program details page, e.g. growth-for-founders-eg */
  slug: Scalars['String']
}

/** Autogenerated input type of ApproveSubscriptionJoinRequest */
export type ApproveSubscriptionJoinRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the join request */
  id?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of ApproveSubscriptionJoinRequest */
export type ApproveSubscriptionJoinRequestPayload = {
  __typename: 'ApproveSubscriptionJoinRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error encountered while approving join request */
  error?: Maybe<Scalars['String']>
  /** Subscription join request */
  subscriptionJoinRequest?: Maybe<SubscriptionJoinRequest>
}

/** Artifacts coming from Sanity CMS */
export type Artifact = {
  __typename: 'Artifact'
  /** All contributions for the artifact. */
  allContributions?: Maybe<Array<ArtifactContribution>>
  /** Authors for the artifact. */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Number of comments the artifact has received. */
  commentCount?: Maybe<Scalars['Int']>
  /** Company for the artifact. */
  company?: Maybe<SanityCompany>
  /** Company logo URL for the artifact. */
  companyLogoUrl?: Maybe<Scalars['String']>
  /** Company maturity for the artifact. */
  companyMaturity?: Maybe<Scalars['String']>
  /** Company name for the artifact. */
  companyName?: Maybe<Scalars['String']>
  /** Company number of employees for the artifact. */
  companyNumEmployees?: Maybe<Scalars['String']>
  /** Company type for the artifact. */
  companyType?: Maybe<Scalars['String']>
  /** Description for the artifact. */
  description?: Maybe<Scalars['JSON']>
  /** User facing name of the document that can be drafted */
  draftLabelName?: Maybe<Scalars['String']>
  /** The template used to generate an AI draft. */
  draftTemplateName?: Maybe<Scalars['String']>
  /** Filters for the artifact. */
  filters?: Maybe<Array<ArtifactFilter>>
  /** format for artifact: document,image,presentation,spreadsheets,video */
  format?: Maybe<Scalars['String']>
  /** ID of the artifact. */
  id: Scalars['ID']
  /** Is the artifact free or only accessible for members? */
  isFree?: Maybe<Scalars['Boolean']>
  /** Publish date for the artifact. */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Slug for the artifact. */
  slug?: Maybe<Scalars['String']>
  /** Summary for the artifact. */
  summary?: Maybe<Scalars['JSON']>
  /** Thumbnail photo for the artifact. */
  thumbnailPhoto?: Maybe<Scalars['JSON']>
  /** Thumbnail URL for the artifact. */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Title for the artifact. */
  title?: Maybe<Scalars['String']>
  /** topics */
  topics?: Maybe<Scalars['JSON']>
  /** Number of views the artifact has received. */
  viewCount?: Maybe<Scalars['Int']>
}

/** An annotation/note on an artifact */
export type ArtifactAnnotation = {
  __typename: 'ArtifactAnnotation'
  /** Date annotation was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Annotation content from Sanity */
  descriptionBody?: Maybe<Scalars['JSON']>
  /** ID from Sanity */
  id: Scalars['ID']
  /** Reactions grouped by reaction name (e.g. [{ name "smiling", count: 3 }]) */
  reactionsGroupedByName: Array<ArtifactAnnotationReactionGrouped>
  /** Video associated with annotation */
  video?: Maybe<ArtifactAnnotationVideo>
}

/** A reaction to an artifact annotation */
export type ArtifactAnnotationReaction = {
  __typename: 'ArtifactAnnotationReaction'
  /** The ID of the annotation */
  annotationId?: Maybe<Scalars['String']>
  /** The ID of the artifact annotation */
  artifactAnnotationId: Scalars['String']
  /** The ID of the artifact */
  artifactId?: Maybe<Scalars['String']>
  /** The ID of the AI chat */
  id: Scalars['ID']
  /** The reactions to the artifact annotation */
  reactions: Array<Scalars['String']>
}

/** Artifact annotation reactions grouped by name with count */
export type ArtifactAnnotationReactionGrouped = {
  __typename: 'ArtifactAnnotationReactionGrouped'
  /** The count of the reaction by name */
  count: Scalars['Int']
  /** Whether the current user has reacted with this reaction */
  currentUserHasReacted: Scalars['Boolean']
  /** The name of the reaction */
  name: Scalars['String']
}

/** Artifact annotation reactions grouped by name with count */
export type ArtifactAnnotationVideo = {
  __typename: 'ArtifactAnnotationVideo'
  /** The playback ID of the video */
  playbackId?: Maybe<Scalars['ID']>
  /** The time in seconds of the video thumbnail */
  thumbTime?: Maybe<Scalars['Int']>
}

/** Artifact authors coming from Sanity CMS */
export type ArtifactAuthor = {
  __typename: 'ArtifactAuthor'
  /** Avatar photo for the artifact author. */
  avatarPhoto?: Maybe<Image>
  /** Avatar URL for the artifact author. */
  avatarUrl?: Maybe<Scalars['String']>
  /** Call to action text */
  callToAction?: Maybe<Scalars['String']>
  /** Company for the author. */
  company?: Maybe<SanityCompany>
  /** Company for the author. */
  companyName?: Maybe<Scalars['String']>
  /** ID of the artifact author. */
  id: Scalars['String']
  /** Returns true if author is part of collective group */
  isCollectiveMember?: Maybe<Scalars['Boolean']>
  /** LinkedIn URL for the artifact author. */
  linkedinUrl?: Maybe<Scalars['String']>
  /** Location for the artifact author. */
  location?: Maybe<Scalars['String']>
  /** Slug for the artifact author. */
  name?: Maybe<Scalars['String']>
  /** Past companies for the artifact author. */
  pastCompanies?: Maybe<Array<Scalars['String']>>
  /** Position for the artifact author. */
  position?: Maybe<Scalars['String']>
  /** Short bio for the artifact author. */
  shortBio?: Maybe<Scalars['String']>
  /** Slug for the artifact author. */
  slug?: Maybe<Scalars['String']>
  /** Twitter URL for the artifact author. */
  twitterUrl?: Maybe<Scalars['String']>
  /** Call to action URL */
  url?: Maybe<Scalars['String']>
  /** User associated with the artifact author */
  user?: Maybe<ExpertUserProfile>
  /** Reforge User ID for the artifact author. */
  userId?: Maybe<Scalars['String']>
  /** Work entries for the artifact author. */
  workEntries?: Maybe<Array<WorkEntry>>
}

/** A comment on an artifact */
export type ArtifactComment = {
  __typename: 'ArtifactComment'
  /** The ID of the artifact the comment is on */
  artifactId?: Maybe<Scalars['String']>
  /** The date the comment was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The ID of the comment */
  id: Scalars['ID']
  /** The message of the comment */
  message: Scalars['String']
  /** Reactions grouped by reaction name (e.g. [{ name "smiling", count: 3 }]) */
  reactionsGroupedByName: Array<ArtifactCommentReactionGrouped>
  /** The user who made the comment */
  user: ArtifactCommentUser
}

/** A reaction to an artifact annotation */
export type ArtifactCommentReaction = {
  __typename: 'ArtifactCommentReaction'
  /** The ID of the annotation */
  annotationId?: Maybe<Scalars['String']>
  /** The ID of the artifact comment */
  artifactCommentId: Scalars['String']
  /** The ID of the artifact */
  artifactId?: Maybe<Scalars['String']>
  /** The comment that was reacted to */
  comment: ArtifactComment
  /** The ID of the artifact comment */
  commentId: Scalars['String']
  /** The ID of the AI chat */
  id: Scalars['ID']
  /** The reactions to the artifact annotation */
  reactions: Array<Scalars['String']>
}

/** Artifact comment reactions grouped by name with count */
export type ArtifactCommentReactionGrouped = {
  __typename: 'ArtifactCommentReactionGrouped'
  /** The count of the reaction by name */
  count: Scalars['Int']
  /** Whether the current user has reacted with this reaction */
  currentUserHasReacted: Scalars['Boolean']
  /** The name of the reaction */
  name: Scalars['String']
}

/** Author of the artifact comment */
export type ArtifactCommentUser = {
  __typename: 'ArtifactCommentUser'
  /** The URL of the user's avatar */
  avatarUrl: Scalars['String']
  /** The name of the user's company */
  companyName?: Maybe<Scalars['String']>
  /** The name of the user */
  fullName?: Maybe<Scalars['String']>
  /** The ID of the user */
  id: Scalars['ID']
  /** The role of the user */
  role?: Maybe<Scalars['String']>
}

/** Artifacts contribution from Sanity CMS */
export type ArtifactContribution = {
  __typename: 'ArtifactContribution'
  /** Artifact */
  artifact?: Maybe<Artifact>
  /** Artifact author */
  artifactAuthor?: Maybe<ArtifactAuthor>
  /** Authors for the artifact. */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Contribution verb */
  contributionVerb?: Maybe<Scalars['String']>
  /** ID */
  id?: Maybe<Scalars['ID']>
  /** Status */
  status?: Maybe<Scalars['String']>
}

/** An contributor on an artifact */
export type ArtifactContributor = {
  __typename: 'ArtifactContributor'
  /** All work entries */
  allEntries?: Maybe<Scalars['JSON']>
  /** Avatar URL of the contributor */
  avatarUrl?: Maybe<Scalars['String']>
  /** Company name of the contributor */
  companyName?: Maybe<Scalars['String']>
  /** Contribution verb (authored/contributed) */
  contributionVerb: Scalars['ID']
  /** ID of the contributor */
  id: Scalars['ID']
  /** Is collective member */
  isCollectiveMember?: Maybe<Scalars['Boolean']>
  /** Name of the contributor */
  name?: Maybe<Scalars['String']>
  /** Position of the contributor */
  position?: Maybe<Scalars['String']>
  /** Primary email of the contributor */
  primaryEmail?: Maybe<Scalars['String']>
  /** Relevant work entry */
  relevantWorkEntry?: Maybe<Scalars['JSON']>
  /** Slug of the contributor */
  slug?: Maybe<Scalars['String']>
  /** Status of contribution */
  status: Scalars['ID']
  /** Expert user profile */
  user: ExpertUserProfile
}

/** Artifact filter */
export type ArtifactFilter = {
  __typename: 'ArtifactFilter'
  /** Slug */
  slug: Scalars['String']
  /** Name */
  title: Scalars['String']
  /** Type */
  type: Scalars['String']
}

/** Filters for artifacts */
export type ArtifactFilters = {
  /** Matching company Type */
  businessModel?: InputMaybe<Array<Scalars['String']>>
  /** Matching company Number of Employees */
  companySize?: InputMaybe<Array<Scalars['String']>>
  /** Matching company Maturity */
  companyStage?: InputMaybe<Array<Scalars['String']>>
  /** Matching user-selected topics */
  forYou?: InputMaybe<Scalars['Boolean']>
  /** Matching certain functions */
  functionIds?: InputMaybe<Array<Scalars['String']>>
  /** Matching artifact IDs */
  onlyCertainArtifacts?: InputMaybe<Array<Scalars['String']>>
  /** Matching user-viewed artifacts, sorted by viewed time */
  recentlyViewed?: InputMaybe<Scalars['Boolean']>
  /** This field accepts most-popular argument or leave it blank to sort by published date */
  sortBy?: InputMaybe<Scalars['String']>
  /** Matching certain subtopics */
  subtopicIds?: InputMaybe<Array<Scalars['String']>>
  /** Matching certain topics */
  topicIds?: InputMaybe<Array<Scalars['String']>>
}

/** Subtopic used to categorize artifacts */
export type ArtifactSubtopic = {
  __typename: 'ArtifactSubtopic'
  /** Subtopic ID */
  id?: Maybe<Scalars['ID']>
  /** Whether this subtopic is deprecated */
  isDeprecated?: Maybe<Scalars['Boolean']>
  /** Timestamp of when this subtopic was last updated */
  lastUpdate?: Maybe<Scalars['ISO8601DateTime']>
  /** URL to redirect to if isDeprecated */
  redirectTo?: Maybe<Scalars['String']>
  /** Related blog posts */
  relatedBlogPosts?: Maybe<Array<BlogPost>>
  /** Related blog posts */
  relatedGuides?: Maybe<Array<Unit>>
  /** Subtopic slug */
  slug: Scalars['String']
  /** Definition of the subtopic */
  termDefinition?: Maybe<Scalars['JSON']>
  /** Subtopic title */
  title: Scalars['String']
  /** Subtopic title in plural form */
  titlePlural: Scalars['String']
  /** Topic this subtopic belongs to */
  topic: ArtifactTopic
}

/** Topic used to categorize artifacts */
export type ArtifactTopic = {
  __typename: 'ArtifactTopic'
  /** Topic icon */
  icon?: Maybe<Image>
  /** Topic ID */
  id?: Maybe<Scalars['ID']>
  /** Whether this topic is deprecated */
  isDeprecated?: Maybe<Scalars['Boolean']>
  /** Whether this topic is public */
  isPublic?: Maybe<Scalars['Boolean']>
  /** Last time this topic's subtopics were updated */
  lastUpdate?: Maybe<Scalars['ISO8601DateTime']>
  /** URL to redirect to if isDeprecated */
  redirectTo?: Maybe<Scalars['String']>
  /** Topic slug */
  slug: Scalars['String']
  /** Subtopics slugs to display */
  subtopicsToDisplay?: Maybe<Array<Scalars['String']>>
  /** Topic title */
  title: Scalars['String']
  /** Total number of artifacts */
  totalArtifactCount?: Maybe<Scalars['Int']>
}

/** Artifact Topic Filter coming from Sanity CMS */
export type ArtifactTopicFilter = {
  __typename: 'ArtifactTopicFilter'
  /** ID */
  id: Scalars['ID']
  /** Topic filter slug */
  slug: Scalars['String']
  /** Topic filter title */
  title: Scalars['String']
  /** Topic filter type */
  type: Scalars['String']
}

/** Artifact Topic selected for Onboarding */
export type ArtifactTopicInput = {
  /** Topic ID */
  id: Scalars['ID']
  /** Topic filter title */
  title: Scalars['String']
}

/** Artifact save/view information for a user */
export type ArtifactUserData = {
  __typename: 'ArtifactUserData'
  /** Saved artifacts for user */
  savedArtifactsForUser: Array<Scalars['ID']>
  /** Saved count per artifact */
  savedCountPerArtifact: Array<SavedCountPerArtifact>
  /** Viewed artifacts for user */
  viewedArtifactsForUser: Array<Scalars['ID']>
}

/** Artifact with workEntry field */
export type ArtifactWithWorkEntry = {
  __typename: 'ArtifactWithWorkEntry'
  /** All contributions for the artifact. */
  allContributions?: Maybe<Array<ArtifactContribution>>
  /** Authors for the artifact. */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Number of comments the artifact has received. */
  commentCount?: Maybe<Scalars['Int']>
  /** Company for the artifact. */
  company?: Maybe<SanityCompany>
  /** Company logo URL for the artifact. */
  companyLogoUrl?: Maybe<Scalars['String']>
  /** Company maturity for the artifact. */
  companyMaturity?: Maybe<Scalars['String']>
  /** Company name for the artifact. */
  companyName?: Maybe<Scalars['String']>
  /** Company number of employees for the artifact. */
  companyNumEmployees?: Maybe<Scalars['String']>
  /** Company type for the artifact. */
  companyType?: Maybe<Scalars['String']>
  /** Description for the artifact. */
  description?: Maybe<Scalars['JSON']>
  /** User facing name of the document that can be drafted */
  draftLabelName?: Maybe<Scalars['String']>
  /** The template used to generate an AI draft. */
  draftTemplateName?: Maybe<Scalars['String']>
  /** Filters for the artifact. */
  filters?: Maybe<Array<ArtifactFilter>>
  /** format for artifact: document,image,presentation,spreadsheets,video */
  format?: Maybe<Scalars['String']>
  /** ID of the artifact. */
  id: Scalars['ID']
  /** Is the artifact free or only accessible for members? */
  isFree?: Maybe<Scalars['Boolean']>
  /** Publish date for the artifact. */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Slug for the artifact. */
  slug?: Maybe<Scalars['String']>
  /** Summary for the artifact. */
  summary?: Maybe<Scalars['JSON']>
  /** Thumbnail photo for the artifact. */
  thumbnailPhoto?: Maybe<Scalars['JSON']>
  /** Thumbnail URL for the artifact. */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Title for the artifact. */
  title?: Maybe<Scalars['String']>
  /** topics */
  topics?: Maybe<Scalars['JSON']>
  /** Number of views the artifact has received. */
  viewCount?: Maybe<Scalars['Int']>
  /** Work entry for this artifact */
  workEntry?: Maybe<WorkEntry>
}

/** Artifacts for Subtopic */
export type ArtifactsForSubtopic = {
  __typename: 'ArtifactsForSubtopic'
  /** Artifacts for subtopic */
  artifacts: Array<ArtifactWithWorkEntry>
  /** Total count of artifacts for subtopic */
  totalCount: Scalars['Int']
}

/** Autogenerated input type of AssignCohortCredit */
export type AssignCohortCreditInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the seat */
  seatId: Scalars['ID']
}

/** Autogenerated return type of AssignCohortCredit */
export type AssignCohortCreditPayload = {
  __typename: 'AssignCohortCreditPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated seat (if successful) */
  seat?: Maybe<Seat>
}

/** Autogenerated input type of AssignSeat */
export type AssignSeatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Email of the user */
  email: Scalars['String']
  /** ID of the seat */
  seatId: Scalars['ID']
  /** ID of the subscription */
  subscriptionId: Scalars['ID']
}

/** Autogenerated return type of AssignSeat */
export type AssignSeatPayload = {
  __typename: 'AssignSeatPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated seat */
  seat?: Maybe<Seat>
}

/** Attendees feed */
export type AttendeesFeed = {
  __typename: 'AttendeesFeed'
  /** Attendees */
  attendees?: Maybe<Array<EventAttendee>>
  /** Count of the users returned after search and filters. */
  count?: Maybe<Scalars['Int']>
  /** Count of the users returned before search and filters. */
  totalCount?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of Batch */
export type BatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cohort ID */
  cohortId?: InputMaybe<Scalars['ID']>
  /** IDs of the events */
  eventIds: Array<Scalars['ID']>
  /** IDs of the events previously selected */
  previouslySelectedEventIds?: InputMaybe<Array<Scalars['ID']>>
  /** The string used to group the events by time */
  timeGrouping?: InputMaybe<Scalars['String']>
  /** Timezone of the user */
  timezone: Scalars['String']
}

/** Autogenerated return type of Batch */
export type BatchPayload = {
  __typename: 'BatchPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** Time data for cohort events for the batch rsvp flow */
export type BatchRsvpTimeData = {
  __typename: 'BatchRsvpTimeData'
  /** The time this group of events ends at */
  endsAtUtc?: Maybe<Scalars['String']>
  /** The ids for this group of events */
  ids: Array<Scalars['String']>
  /** The time this group of events begins at */
  startsAtUtc?: Maybe<Scalars['String']>
  /** The string used to group the events by time */
  timeGrouping?: Maybe<Scalars['String']>
}

/** Billing information */
export type BillingInformation = {
  __typename: 'BillingInformation'
  /** Address */
  address?: Maybe<StripeAddress>
  /** Name */
  name?: Maybe<Scalars['String']>
  /** Tax IDs */
  taxIds?: Maybe<Array<Scalars['String']>>
}

/** Marketing Blog Index from Sanity CMS */
export type BlogIndex = {
  __typename: 'BlogIndex'
  /** Blog post list */
  blogPosts: Array<BlogPost>
  /** Blog post list */
  featuredBlogPosts: Array<BlogPost>
  /** Next blog posts count */
  nextBlogPostsCount: Scalars['Int']
  /** Page */
  page: Page
  /** Previous blog posts count */
  prevBlogPostsCount: Scalars['Int']
}

/** Marketing Blog Post from Sanity CMS */
export type BlogPost = {
  __typename: 'BlogPost'
  /** Authors */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Categories */
  categories?: Maybe<Array<BlogPostCategory>>
  /** Content block */
  contentBlock?: Maybe<Scalars['JSON']>
  /** ID */
  id: Scalars['ID']
  /** Is featured */
  isFeatured?: Maybe<Scalars['Boolean']>
  /** Overview */
  overview?: Maybe<Scalars['JSON']>
  /** Publish date */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Slug */
  slug?: Maybe<Scalars['String']>
  /** Subtopics */
  subtopicIds?: Maybe<Array<Scalars['ID']>>
  /** Summary image */
  summaryImage?: Maybe<Image>
  /** Summary text */
  summaryText?: Maybe<Scalars['JSON']>
  /** Table of contents */
  tableOfContents?: Maybe<Scalars['JSON']>
  /** Title */
  title: Scalars['String']
}

/** Marketing Blog post category from Sanity CMS */
export type BlogPostCategory = {
  __typename: 'BlogPostCategory'
  /** Category ID */
  id: Scalars['ID']
  /** Category name */
  name: Scalars['String']
  /** Category value */
  value: Scalars['String']
}

/** Marketing Blog Post details page from Sanity CMS */
export type BlogPostDetails = {
  __typename: 'BlogPostDetails'
  /** Blog post */
  blogPost: BlogPost
  /** Created at */
  createdAt: Scalars['ISO8601DateTime']
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
  /** Updated at */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Bookmark type */
export type Bookmark = {
  __typename: 'Bookmark'
  /** Bookmark anchor */
  anchor?: Maybe<Scalars['String']>
  /** Sanity artifact data for bookmark */
  artifact?: Maybe<Artifact>
  /** Bookmark markup */
  basedOn?: Maybe<Scalars['String']>
  /** data for CclCourse if attached to bookmark */
  cclCourse?: Maybe<CclCourse>
  /** The CmsModule for the bookmark */
  cmsModule?: Maybe<CmsModule>
  /** The CmsProgram for the bookmark */
  cmsProgram?: Maybe<CmsProgram>
  /** The CmsSection for the bookmark */
  cmsSection?: Maybe<CmsSection>
  /** Sanity data for Course */
  course?: Maybe<MarketingCourse>
  /** Bookmark creation relative time */
  created?: Maybe<Scalars['String']>
  /** Numerical representation of createdAt */
  createdAtInteger: Scalars['Int']
  /** Highlighted text ending offset */
  endOffset?: Maybe<Scalars['Int']>
  /** Event object */
  event?: Maybe<Event>
  /** Event ID for bookmark */
  eventId?: Maybe<Scalars['ID']>
  /** External URL of the bookmark */
  externalUrl?: Maybe<Scalars['String']>
  /** List of filed bookmarks */
  filedBookmarks: Array<FiledBookmark>
  /** Sanity guide data for bookmark */
  guide?: Maybe<Unit>
  /** ID of bookmark */
  id: Scalars['ID']
  /** Whether the bookmark is within content */
  isWithinContent: Scalars['Boolean']
  /** When the content was last viewed */
  lastViewedContentAtInteger?: Maybe<Scalars['Int']>
  /** Bookmarked lesson position */
  lessonPosition?: Maybe<Scalars['Int']>
  /** Highlighting metadata for bookmark */
  metadata?: Maybe<Scalars['JSON']>
  /** Most recent CMS activity per user */
  mostRecentCmsActivityPerUser: Array<UserCmsActivity>
  /** Bookmark note body */
  noteBody?: Maybe<Scalars['String']>
  /** Number of people who bookmarked */
  numPeopleWhoBookmarked?: Maybe<Scalars['Int']>
  /** Reference image */
  referenceImageUrl?: Maybe<Scalars['String']>
  /** Sanity ID for bookmark */
  sanityId?: Maybe<Scalars['String']>
  /** Seconds */
  seconds?: Maybe<Scalars['Int']>
  /** Highlighted text starting offset */
  startOffset?: Maybe<Scalars['Int']>
  /** Title of the bookmark */
  title?: Maybe<Scalars['String']>
  /** Bookmark type */
  type: BookmarkType
  /** Bookmark updated relative time */
  updated?: Maybe<Scalars['String']>
  /** Updated at date */
  updatedAt: Scalars['ISO8601DateTime']
  /** Bookmark video code */
  videoCode?: Maybe<Scalars['String']>
}

/** Bookmark type */
export type BookmarkMostRecentCmsActivityPerUserArgs = {
  collectionId: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

/** Filters for user bookmarks */
export type BookmarkFilters = {
  /** Module ID associated with bookmark */
  cmsModuleId?: InputMaybe<Scalars['ID']>
  /** Program ID associated with bookmark */
  cmsProgramId?: InputMaybe<Scalars['ID']>
  /** Section ID associated with bookmark */
  cmsSectionId?: InputMaybe<Scalars['ID']>
  /** Text to search for in bookmark */
  searchText?: InputMaybe<Scalars['String']>
  /** Type of bookmark */
  type?: InputMaybe<Scalars['String']>
  /** Types of bookmarks */
  types?: InputMaybe<Array<Scalars['String']>>
}

/** Folder for bookmarks a user has made */
export type BookmarkFolder = {
  __typename: 'BookmarkFolder'
  /** The bookmarks in the folder */
  bookmarks: Array<Bookmark>
  /** The time since the bookmark folder was created */
  createdAtInWords: Scalars['String']
  /** The bookmark folder description */
  description?: Maybe<Scalars['String']>
  /** Folder ID current folder was forked from */
  forkedFromId?: Maybe<Scalars['ID']>
  /** Whether the goal is complete. If not a goal, this will be null */
  goalComplete?: Maybe<Scalars['Boolean']>
  /** ID */
  id: Scalars['ID']
  /**
   * The users who the collection
   *                                                                          has been shared with individually
   */
  individualUsersSharedWith: Array<User>
  /** Is the collection shared */
  isShared: Scalars['Boolean']
  /** Is this collection a white glove collection */
  isWhiteGlove: Scalars['Boolean']
  /** Name of the folder */
  name: Scalars['String']
  /** ID of parent folder */
  parentId?: Maybe<Scalars['ID']>
  /** Is the collection a reforge collection */
  reforgeCollection: Scalars['Boolean']
  /** shared folder records */
  sharedFolders: Array<SharedFolder>
  /**
   * When the bookmark folder is a
   *                                                                             team goal, these are the team
   *                                                                             members who have completed the goal. If not
   *                                                                             a team goal, this will be null
   */
  teamMembersWhoCompletedGoal?: Maybe<Array<User>>
  /**
   * When the bookmark folder is a
   *                                                                             team learning goal, these are the team
   *                                                                             members who have started the goal. If not
   *                                                                             a team goal, this will be null
   */
  teamMembersWhoStartedGoal?: Maybe<Array<User>>
  /**
   * Team progress on the goal, as a percentage. If not a team
   *                                                             goal, this will be null
   */
  teamProgress?: Maybe<Scalars['Int']>
  /** The user who created the folder */
  user: User
  /** The users with whom the collection has been shared */
  usersSharedWith: Array<User>
}

/** Filters for user bookmarks modules */
export type BookmarkModulesFilters = {
  /** Module ID associated with bookmark */
  cmsModuleId?: InputMaybe<Scalars['ID']>
  /** Program ID associated with bookmark */
  cmsProgramId?: InputMaybe<Scalars['ID']>
}

/** Related identifiers for a bookmark */
export type BookmarkRelatedIdentifiers = {
  /** ID of the related course. */
  courseId?: InputMaybe<Scalars['String']>
  /** ID of the related course session. */
  courseSessionId?: InputMaybe<Scalars['String']>
  /** Title of the related course. */
  courseTitle?: InputMaybe<Scalars['String']>
}

/** The type of bookmark */
export enum BookmarkType {
  /** Artifact type bookmark */
  ARTIFACTBOOKMARK = 'ArtifactBookmark',
  /** Blog post type bookmark */
  BLOGPOST = 'BlogPost',
  /** A Concept type bookmark */
  CONCEPTBOOKMARK = 'ConceptBookmark',
  /** Course type bookmark */
  COURSEBOOKMARK = 'CourseBookmark',
  /** Event type Bookmark */
  EVENTBOOKMARK = 'EventBookmark',
  /** An external URL in a Collection */
  EXTERNALURLBOOKMARK = 'ExternalUrlBookmark',
  /** A Guide type bookmark */
  GUIDEBOOKMARK = 'GuideBookmark',
  /** Image type bookmark */
  IMAGEBOOKMARK = 'ImageBookmark',
  /** A bookmark for a lesson */
  LESSONBOOKMARK = 'LessonBookmark',
  /** Program type bookmark */
  PROGRAMBOOKMARK = 'ProgramBookmark',
  /** Project type bookmark */
  PROJECTBOOKMARK = 'ProjectBookmark',
  /** Resource type bookmark */
  RESOURCEBOOKMARK = 'ResourceBookmark',
  /** A bookmark for a section */
  SECTIONBOOKMARK = 'SectionBookmark',
  /** A text block in a Collection */
  TEXTBLOCKBOOKMARK = 'TextBlockBookmark',
  /** Text type bookmark */
  TEXTBOOKMARK = 'TextBookmark',
  /** Unit type bookmark */
  UNITBOOKMARK = 'UnitBookmark',
  /** Video type bookmark */
  VIDEOBOOKMARK = 'VideoBookmark'
}

/** BookmarksFeed type (bookmarks with paging metadata) */
export type BookmarksFeed = {
  __typename: 'BookmarksFeed'
  /** List of bookmarks */
  bookmarks: Array<Bookmark>
  /** provided or default limit for paging */
  limit: Scalars['Int']
  /** provided or default offset for paging */
  offset: Scalars['Int']
  /** Total number of listed bookmarks, ignoring limit */
  total: Scalars['Int']
}

/** Autogenerated input type of Cancel */
export type CancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the event */
  id: Scalars['ID']
}

/** Autogenerated return type of Cancel */
export type CancelPayload = {
  __typename: 'CancelPayload'
  /** The attendee record created or updated */
  attendee?: Maybe<EventAttendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors array if any errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Marketing Case Studies Index from Sanity CMS */
export type CaseStudiesIndex = {
  __typename: 'CaseStudiesIndex'
  /** Experts list */
  caseStudies: Array<CaseStudy>
  /** Page */
  page: Page
}

/** Marketing Case Study from Sanity CMS */
export type CaseStudy = {
  __typename: 'CaseStudy'
  /** Content */
  content?: Maybe<Array<CaseStudyContentBlock>>
  /** Contributors */
  contributors: Array<CaseStudyContributor>
  /** ID */
  id: Scalars['ID']
  /** Preview title */
  previewTitle?: Maybe<Scalars['String']>
  /** Slug */
  slug?: Maybe<Scalars['String']>
  /** Title */
  title?: Maybe<Scalars['String']>
  /** Use cases */
  useCases?: Maybe<Scalars['String']>
}

/** Marketing Case Study content block from Sanity CMS */
export type CaseStudyContentBlock = {
  __typename: 'CaseStudyContentBlock'
  /** Background color */
  background: Scalars['String']
  /** Content */
  contentBlock: Scalars['JSON']
  /** ID */
  id: Scalars['ID']
}

/** Marketing Case Study Contributor from Sanity CMS */
export type CaseStudyContributor = {
  __typename: 'CaseStudyContributor'
  /** Contributor avatar photo */
  avatarPhoto: Image
  /** Contributor company */
  company: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Contributor name */
  name: Scalars['String']
  /** Contributor position */
  position: Scalars['String']
}

/** Marketing Case Study Details from Sanity CMS */
export type CaseStudyDetails = {
  __typename: 'CaseStudyDetails'
  /** Case study details */
  caseStudy: CaseStudy
  /** Date the case study was created */
  createdAt: Scalars['ISO8601DateTime']
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
  /** Date the case study was updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** CCL Artifact Type */
export type CclArtifact = {
  __typename: 'CclArtifact'
  /** ID of the artifact */
  id: Scalars['ID']
  /** Slug of the artifact */
  slug: Scalars['String']
  /** Sanity ID of the artifact */
  sourceId?: Maybe<Scalars['String']>
  /** Title of the artifact */
  title: Scalars['String']
}

/** CCL Blog Post Type */
export type CclBlogPost = {
  __typename: 'CclBlogPost'
  /** Creators of the blog post */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Course title */
  cclProfileIds?: Maybe<Scalars['Int']>
  /** Blog record created at */
  createdAt: Scalars['ISO8601DateTime']
  /** Blog publish date */
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Blog post id */
  id: Scalars['ID']
  /** Slug of the blog */
  slug: Scalars['String']
  /** Sanity ID of the artifact */
  sourceId?: Maybe<Scalars['String']>
  /** Blog status */
  status: Scalars['String']
  /** Blog summary  */
  summary?: Maybe<Scalars['JSON']>
  /** Blog thumbnail */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Blog title */
  title: Scalars['String']
}

/** CCL Company Type */
export type CclCompany = {
  __typename: 'CclCompany'
  /** ID of the company */
  id: Scalars['ID']
  /** URL of the company logo */
  logoUrl?: Maybe<Scalars['String']>
  /** Name of the company */
  name: Scalars['String']
  /** URL of the company wordmark logo */
  wordmarkLogoUrl?: Maybe<Scalars['String']>
}

/** CCL Course Type */
export type CclCourse = {
  __typename: 'CclCourse'
  /** Audience for the course */
  audience?: Maybe<Scalars['String']>
  /** Blocks of the course */
  blocks?: Maybe<Array<CclCourseBlock>>
  /** The CMS program associated with the course */
  cmsProgram?: Maybe<CmsProgram>
  /** Percentage of course completion */
  completionPercentage?: Maybe<Scalars['Int']>
  /** Creators of the course */
  creators?: Maybe<Array<CclExpert>>
  /** Current session for the course */
  currentSession?: Maybe<CclCourseSession>
  /** Featured example companies on the course */
  exampleCompanies?: Maybe<Array<CclCompany>>
  /** Focus of the course */
  focus?: Maybe<Scalars['String']>
  /** Goals of the course */
  goals?: Maybe<Scalars['String']>
  /** Number of guides associated with the course */
  guideCount?: Maybe<Scalars['Int']>
  /** Hero image URL for the course */
  heroImageUrl?: Maybe<Scalars['String']>
  /** ID of the course */
  id: Scalars['ID']
  /** Legacy ID of the course from the courses table */
  legacyCourseId?: Maybe<Scalars['ID']>
  /** Whether course is live */
  live?: Maybe<Scalars['Boolean']>
  /** Whether the course is on demand */
  onDemand?: Maybe<Scalars['Boolean']>
  /** Whether the course outline is complete */
  outlineComplete?: Maybe<Scalars['Boolean']>
  /** Past sessions for the course */
  pastSessions?: Maybe<Array<CclCourseSession>>
  /** Course publish date */
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Short description of the course */
  shortDescription?: Maybe<Scalars['String']>
  /** Slug of the course */
  slug: Scalars['String']
  /** Sanity ID of the course */
  sourceId?: Maybe<Scalars['String']>
  /** Static ID of the course */
  staticId: Scalars['String']
  /** Testimonials for the course */
  testimonials?: Maybe<Array<CclReview>>
  /** Title of the course */
  title: Scalars['String']
  /** Topics associated with the course */
  topics?: Maybe<Array<CclTopic>>
  /** Trailer URL for the course */
  trailerUrl?: Maybe<Scalars['String']>
  /** Upcoming sessions for the course */
  upcomingSessions?: Maybe<Array<CclCourseSession>>
}

/** CCL Block Type */
export type CclCourseBlock = {
  __typename: 'CclCourseBlock'
  /** Artifacts of the course block */
  artifacts?: Maybe<Array<CclArtifact>>
  /** Content blocks for the course block. */
  contentBlocks?: Maybe<Array<ContentBlock>>
  /** Description of the expert */
  description?: Maybe<Scalars['String']>
  /** Display order of the course block */
  displayOrder: Scalars['Int']
  /** Event previews of the course block */
  eventPreviews?: Maybe<Array<CclVideo>>
  /** Events of the course block */
  events?: Maybe<Array<CclSessionEvent>>
  /** Guides of the course block */
  guides?: Maybe<Array<CclGuide>>
  /** ID of the expert */
  id: Scalars['ID']
  /** Kind of the course block */
  kind?: Maybe<Scalars['String']>
  /** Whether the course block is previewable */
  previewable?: Maybe<Scalars['Boolean']>
  /** Title of the course block */
  title: Scalars['String']
}

/** CCL Course Session Type */
export type CclCourseSession = {
  __typename: 'CclCourseSession'
  /** Time when the course session was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Duration of the course session */
  duration?: Maybe<Scalars['String']>
  /** End time of the course session */
  endsAt: Scalars['ISO8601DateTime']
  /** When enrollment starts. */
  enrollmentStartsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Events for the course session */
  events?: Maybe<Array<CclSessionEvent>>
  /** Experts for the course session */
  experts?: Maybe<Array<CclExpert>>
  /** ID of the course session */
  id: Scalars['ID']
  /** Whether or not the current time is within the enrollment window */
  isDuringEnrollmentPeriod: Scalars['Boolean']
  /** Whether or not enrollment has filled up for this particular type of user */
  isEnrollmentFull: Scalars['Boolean']
  /** Enrollment cap for users paying or using a cohort credit to enroll in this session */
  maxEnrollmentPaid?: Maybe<Scalars['Int']>
  /** Number of paying users enrolled in this session */
  numEnrolledPaid?: Maybe<Scalars['Int']>
  /** Price of the course session */
  price?: Maybe<Scalars['Float']>
  /** Sanity ID of the course session */
  sourceId?: Maybe<Scalars['String']>
  /** Start time of the course session */
  startsAt: Scalars['ISO8601DateTime']
  /** Course title */
  title: Scalars['String']
}

/** CCL Expert Type */
export type CclExpert = {
  __typename: 'CclExpert'
  /** Avatar URL of the expert */
  avatarUrl?: Maybe<Scalars['String']>
  /** Bio of the expert */
  bio?: Maybe<Scalars['String']>
  /** Company logo URL of the expert */
  companyLogoUrl?: Maybe<Scalars['String']>
  /** Company of the expert */
  companyName?: Maybe<Scalars['String']>
  /** ID of the expert */
  id: Scalars['ID']
  /** Returns true if author is part of collective group */
  isCollectiveMember?: Maybe<Scalars['Boolean']>
  /** Name of the expert */
  name: Scalars['String']
  /** Position of the expert */
  position?: Maybe<Scalars['String']>
  /** Slug of the expert */
  slug: Scalars['String']
}

/** CCL Guide Type */
export type CclGuide = {
  __typename: 'CclGuide'
  /** Creators of the course */
  creators?: Maybe<Array<CclExpert>>
  /** Display order of the guide */
  displayOrder: Scalars['Int']
  /** Estimated minutes to complete the guide */
  estimatedMinutes?: Maybe<Scalars['Int']>
  /** Whether the guide is free previewable */
  freePreview?: Maybe<Scalars['Boolean']>
  /** ID of the expert */
  id: Scalars['ID']
  /** Overview of the guide */
  overview?: Maybe<Scalars['String']>
  /** Guide publish date */
  publishedAt: Scalars['ISO8601DateTime']
  /** Slug of the expert */
  slug: Scalars['String']
  /** Sanity ID of the guide */
  sourceId?: Maybe<Scalars['String']>
  /** Static ID of the guide */
  staticId: Scalars['String']
  /** Guide steps */
  stepsCount: Scalars['Int']
  /** Guide thumbnail */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Title of the course block */
  title: Scalars['String']
}

/** CCL Review Type */
export type CclReview = {
  __typename: 'CclReview'
  /** Company of the review */
  company?: Maybe<CclCompany>
  /** ID of the review */
  id: Scalars['ID']
  /** Quote of the review */
  quote?: Maybe<Scalars['String']>
  /** Sub title of the review */
  subTitle?: Maybe<Scalars['String']>
  /** Title of the review */
  title?: Maybe<Scalars['String']>
}

/** CCL Session Event Type */
export type CclSessionEvent = {
  __typename: 'CclSessionEvent'
  /** Description of the session event */
  description?: Maybe<Scalars['String']>
  /** End time of the session event */
  endsAt: Scalars['ISO8601DateTime']
  /** ID of the session event */
  id: Scalars['ID']
  /** Location ID of the course session */
  locationId?: Maybe<Scalars['ID']>
  /** Sanity ID of the session event */
  sourceId?: Maybe<Scalars['String']>
  /** Start time of the session event */
  startsAt: Scalars['ISO8601DateTime']
  /** Title of the expert */
  title: Scalars['String']
}

/** CCL Topic Type */
export type CclTopic = {
  __typename: 'CclTopic'
  /** Icon alt text */
  iconAltText?: Maybe<Scalars['String']>
  /** Icon url */
  iconUrl?: Maybe<Scalars['String']>
  /** ID of the topic */
  id: Scalars['ID']
  /** Whether the topic is deprecated */
  isDeprecated: Scalars['Boolean']
  /** Whether the topic is public */
  isPublic: Scalars['Boolean']
  /** Slug of the topic */
  slug: Scalars['String']
  /** Title of the topic */
  title: Scalars['String']
}

/** CCL Video Type */
export type CclVideo = {
  __typename: 'CclVideo'
  /** Description of the video */
  description?: Maybe<Scalars['String']>
  /** Duration of the video in seconds */
  durationSeconds?: Maybe<Scalars['Int']>
  /** ID of the video */
  id: Scalars['ID']
  /** Location of the video */
  location: Scalars['String']
  /** Sanity ID of the video */
  sourceId?: Maybe<Scalars['String']>
  /** Title of the video */
  title: Scalars['String']
}

/** Certificate of completion. */
export type Certificates = {
  __typename: 'Certificates'
  /** Program is completed when over 80% */
  completed: Scalars['Boolean']
  /** ID */
  id: Scalars['ID']
  /** Name of the program */
  name: Scalars['String']
  /** URL to the PDF. */
  pdfUrl?: Maybe<Scalars['String']>
}

/** Top level queries for ai */
export type ChatQuery = {
  /** Returns a collection of users who have AI chat logs */
  aiChatUsers: Array<User>
  /** Returns a collection of AI chat logs */
  aiChats: Array<AiChat>
  /** Returns a collection of chat messages */
  aiSessionHistory: Array<AiChatMessage>
  /** Returns the ext_id of an AiChat for a given user */
  exchangeChatIdForExtId: Scalars['String']
  /** Returns a collection of recent AI chats */
  recentChats: AiChatConnection
  /** Returns a count of recent AI chats */
  recentChatsCount: Scalars['Int']
  /** Returns a collection of query suggestions */
  suggestedPrompts: Array<Scalars['String']>
}

/** Top level queries for ai */
export type ChatQueryAiChatsArgs = {
  userId: Scalars['ID']
}

/** Top level queries for ai */
export type ChatQueryAiSessionHistoryArgs = {
  sessionId: Scalars['ID']
}

/** Top level queries for ai */
export type ChatQueryExchangeChatIdForExtIdArgs = {
  chatId: Scalars['ID']
}

/** Top level queries for ai */
export type ChatQueryRecentChatsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Top level queries for ai */
export type ChatQuerySuggestedPromptsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of Checkout */
export type CheckoutInput = {
  /** True if using 2x cohort pass discount */
  addCohortPassDiscount?: InputMaybe<Scalars['Boolean']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The number of cohort passes to add to the subscription */
  numCohortPasses?: InputMaybe<Scalars['Int']>
  /** The number of seats for the subscription */
  numSeats?: InputMaybe<Scalars['Int']>
  /** The id of the payment method to use for the purchase */
  paymentMethodId: Scalars['String']
  /** The name of the plan to upgrade to */
  planName?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of Checkout */
export type CheckoutPayload = {
  __typename: 'CheckoutPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Payment intent client secret returned only if payment requires user action */
  clientSecret?: Maybe<Scalars['String']>
  /** Errors that occurred during the checkout */
  errors?: Maybe<Array<Scalars['String']>>
  /** Stripe sub id, returned only if payment requires user action */
  stripeSubscriptionId?: Maybe<Scalars['ID']>
  /** Whether the checkout was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Checkout line item for proration adjustment */
export type CheckoutProrationAdjustment = {
  __typename: 'CheckoutProrationAdjustment'
  /** Amount due in cents */
  amount: Scalars['Int']
  /** New plan remaining amount */
  amountForNewPlanRemainingTime: Scalars['Int']
  /** Period End date */
  endDate: Scalars['ISO8601DateTime']
  /** Whether the user has a tax_rate set on the previous plan proration item */
  paidTaxOnPrevPlan: Scalars['Boolean']
  /** Period Start date */
  startDate: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of ChoosePlan */
export type ChoosePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** name of the plan to switch to */
  newPlan: Scalars['String']
  /** number of cohort passes to buy at renewal */
  numCohortPasses: Scalars['Int']
  /** type of update */
  updateType: Scalars['String']
}

/** Autogenerated return type of ChoosePlan */
export type ChoosePlanPayload = {
  __typename: 'ChoosePlanPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The Stripe upcoming invoice */
  upcomingInvoice?: Maybe<StripeUpcomingInvoice>
}

/** Company data from Clearbit */
export type ClearbitCompany = {
  __typename: 'ClearbitCompany'
  /** The domain of the company */
  domain: Scalars['String']
  /** The url for the logo of the company */
  logo: Scalars['String']
  /** The name of the company */
  name: Scalars['String']
}

/** Short video content for easy viewing */
export type Clip = {
  __typename: 'Clip'
  /** The Subtype for the clip  */
  clipSubtype?: Maybe<Scalars['String']>
  /** The company related data for the clip */
  company?: Maybe<Company>
  /** The duration in seconds for the clip */
  duration?: Maybe<Scalars['Int']>
  /** Has the current user viewed the clip */
  hasBeenViewedByCurrentUser: Scalars['Boolean']
  /** The Id for the clip */
  id: Scalars['ID']
  /** Last Viewed Relative Time */
  lastViewedAt?: Maybe<Scalars['String']>
  /** The Slug for the Clip */
  slug: Scalars['String']
  /** Summary description for the video */
  summary?: Maybe<Scalars['String']>
  /** Code to dispaly video */
  summaryWistiaCode: Scalars['String']
  /** Url for the preview image */
  thumbnailUrl: Scalars['String']
  /** Title for the video */
  title: Scalars['String']
  /** The users associated with the video */
  users: Array<User>
}

/** Cms content */
export type CmsContent = {
  __typename: 'CmsContent'
  /** List of bookmark */
  bookmarks: Array<Bookmark>
  /** ID of the CMS Section */
  id: Scalars['ID']
  /** List of anchors */
  inlinePostAnchors: Array<Scalars['String']>
}

/** A module within a CmsProgram. Contains CmsSections (lessons) */
export type CmsModule = {
  __typename: 'CmsModule'
  /**
   * If the module has at leasst one CmsSection that counts
   *                                                                 towards completion
   */
  anyCountsTowardsCompletion?: Maybe<Scalars['Boolean']>
  /** Clips associated with the Module */
  clips?: Maybe<Array<Clip>>
  /** The CmsSections contained within module */
  cmsSections: Array<CmsSection>
  /** Any lessons are previewable */
  containsPreviewableLessons: Scalars['Boolean']
  /** The estimated time to complete the module */
  estimatedTime: Scalars['Int']
  /** Cms Module events */
  events: Array<Event>
  /** Featured guests for the cms module */
  featuredGuests: Array<EventSpeaker>
  /** The url sent to when clicking on a module card */
  href: Scalars['String']
  /** ID of the module */
  id: Scalars['ID']
  /** Lesson count */
  lessonCount?: Maybe<Scalars['Int']>
  /** The name of the module */
  name: Scalars['String']
  /** The number of Bookmarks a user has for a module */
  numBookmarks?: Maybe<Scalars['Int']>
  /** The number of lessons a module has */
  numLessons: Scalars['Int']
  /** Position of the cms module */
  position?: Maybe<Scalars['Int']>
  /** The cms program code */
  programCode: Scalars['String']
  /** The icon image for a program */
  programIconImageUrl?: Maybe<Scalars['String']>
  /** The banner image for a program */
  programImage: Scalars['String']
  /** The percent completed a user has for a module */
  progressPercent: Scalars['Int']
  /** The 4+2 project that is used by some of programs */
  project?: Maybe<CmsSection>
  /** The date the module will be published */
  publishAt?: Maybe<Scalars['String']>
  /** Whether the module has been published or not */
  published?: Maybe<Scalars['Boolean']>
  /** A short description about the module */
  shortDescription?: Maybe<Scalars['String']>
  /** True if the module should collect feedback */
  showFeedback: Scalars['Boolean']
  /** The name of the module */
  slug?: Maybe<Scalars['String']>
  /** The total number of bookmarks for this module for a user */
  totalBookmarks?: Maybe<Scalars['Int']>
}

/** A live cohort program the user can participate in. */
export type CmsProgram = {
  __typename: 'CmsProgram'
  /** Bookmarks */
  bookmarks: Array<Bookmark>
  /**
   * Description of what the user should gain by the
   *                                end of the program.
   */
  byTheEnd?: Maybe<Scalars['String']>
  /**
   * False if the program has no cohort for the season currently open
   *                                 for enrollment. True if it does have a cohort or there is no season currently
   *                                 open for enrollment.
   */
  canEnroll: Scalars['Boolean']
  /**
   * False if the program has no cohort for the current
   *                                                    season. True if it does have a cohort or if the current season is not
   *                                                    open for enrollment.
   */
  canEnrollForCurrentSeason: Scalars['Boolean']
  /**
   * Categories that the program falls in:
   *                                      Product, Growth, Engineering, etc.
   */
  categoryNames?: Maybe<Array<Scalars['String']>>
  /** The CCL course associated with the program */
  cclCourse?: Maybe<CclCourse>
  /** The CCL course ID associated with the program */
  cclStaticCourseId?: Maybe<Scalars['ID']>
  /** The cms_modules for the program */
  cmsModules: Array<CmsModule>
  /** The code for the program */
  code?: Maybe<Scalars['String']>
  /** Latest cohort for the program. */
  cohort?: Maybe<Cohort>
  /** Program collaborators. */
  collaborators: Array<Host>
  /** The bookmark id if the program was bookmarked by the user */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Content is in the my saved items default collection */
  contentIsInMySavedItems?: Maybe<Scalars['Boolean']>
  /** The most recent module seen */
  currentModule?: Maybe<CmsModule>
  /** When this program is deprecated at and hidden */
  deprecatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The short description for a program */
  description?: Maybe<Scalars['String']>
  /** True if the current user is enrolled in the program */
  enrolled: Scalars['Boolean']
  /** The estimated time to complete the program */
  estimatedTime: Scalars['Int']
  /** True if the program has previewable content */
  hasPreviewableContent: Scalars['Boolean']
  /** URL for image that shows in the program hero section. */
  heroImageUrl?: Maybe<Scalars['String']>
  /** URL for image that shows in the program icon section. */
  iconImageUrl?: Maybe<Scalars['String']>
  /** ID of the program. */
  id: Scalars['ID']
  /** The date the program was launched at */
  launchAt?: Maybe<Scalars['ISO8601DateTime']>
  /** True if "launch_at" is in the past */
  launched: Scalars['Boolean']
  /** The number of lessons in the program */
  lessonCount: Scalars['Int']
  /** The long description of the program */
  longDescription: Scalars['String']
  /** Name of the program. */
  name: Scalars['String']
  /** Whether the program has no lessons */
  noLessons: Scalars['Boolean']
  /** Whether the program has been launched */
  notLaunched?: Maybe<Scalars['Boolean']>
  /** The number of bookmarks a user has created for a program */
  numBookmarks?: Maybe<Scalars['Int']>
  /** Developmental outcomes of the program. */
  outcomes?: Maybe<Scalars['String']>
  /** The number of lessons that can be previewed. To not be used in n+1 queries. */
  previewableLessonCount: Scalars['Int']
  /** URL to the main program image. */
  programImage?: Maybe<Scalars['String']>
  /** Cms program notification */
  programNotification?: Maybe<CmsProgramNotification>
  /** The percentage of progress a user has made */
  progressPercent: Scalars['Int']
  /** True if the cms program is purchased */
  purchased: Scalars['Boolean']
  /**
   * An ordered integer for how highly we
   *                                         recommend the program to the current user based on their interests.
   *                                         A higher number is more highly recommended.
   */
  recommendationRanking?: Maybe<Scalars['Int']>
  /** Related programs based on the category */
  relatedPrograms: Array<CmsProgram>
  /**
   * If program is upcoming, when it will be available for
   *                                             enrollment
   */
  releaseEnrollmentDate?: Maybe<Scalars['String']>
  /** If program is upcoming, what season it will be available */
  releaseSeasonDate?: Maybe<Scalars['String']>
  /** If program is upcoming, when it will be first available */
  releaseStartDate?: Maybe<Scalars['String']>
  /** Season object. */
  season?: Maybe<Season>
  /** Formatted season name for UI. */
  seasonNameFormatted?: Maybe<Scalars['String']>
  /** The short description of the program */
  shortDescription: Scalars['String']
  /** True if the program is new for current user */
  showNewBadge: Scalars['Boolean']
  /** The path to the program details page, e.g. growth-for-founders-eg */
  slug: Scalars['String']
  /** Short description of the program. */
  synopsis?: Maybe<Scalars['String']>
  /** The templates for the given program. */
  templates?: Maybe<CmsModule>
  /**
   * A tentative list of the
   *                                                                               upcoming cohorts for a program
   */
  tentativeUpcomingCohorts?: Maybe<Array<TentativeUpcomingCohort>>
  /** An array of testimonial objects. */
  testimonials: Array<Testimonial>
  /** The topic of the program */
  topic?: Maybe<Scalars['String']>
  /** Cms program is upcoming */
  upcoming: Scalars['Boolean']
  /** What you have learned after taking this program. */
  whatYouLearned?: Maybe<Scalars['String']>
  /** Description for who the program is best suited. */
  whoItsFor?: Maybe<Scalars['String']>
}

/** A live cohort program the user can participate in. */
export type CmsProgramBookmarksArgs = {
  cmsSectionId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated input type of CmsProgramExperts */
export type CmsProgramExpertsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of cms program */
  cmsProgramId: Scalars['Int']
  /** Id of the user */
  userId: Scalars['Int']
}

/** Autogenerated return type of CmsProgramExperts */
export type CmsProgramExpertsPayload = {
  __typename: 'CmsProgramExpertsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the cms program */
  cmsProgramId?: Maybe<Scalars['ID']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Expert users */
  experts?: Maybe<Array<User>>
  /** The ID of the cms program expert */
  id?: Maybe<Scalars['ID']>
  /**
   * The possible expert users that
   *                                                 may have been involved in creating this program
   */
  possibleExperts?: Maybe<Array<User>>
}

/** A program notification. */
export type CmsProgramNotification = {
  __typename: 'CmsProgramNotification'
  /** Program notification image url */
  imageUrl: Scalars['String']
  /** Program notification message */
  message: Scalars['String']
  /** Program notification name */
  notificationName: Scalars['String']
  /** Program notification title */
  title: Scalars['String']
}

/** A (lesson) within a CmsModule. May contain children CmsSections */
export type CmsSection = {
  __typename: 'CmsSection'
  /** Access level of current user */
  accessLevel?: Maybe<Scalars['String']>
  /** Blocked content html safe */
  blockedContentHtmlSafe?: Maybe<Scalars['String']>
  /** Blocked description html safe */
  blockedDescriptionHtmlSafe?: Maybe<Scalars['String']>
  /** Bookmarks */
  bookmarks?: Maybe<Array<Bookmark>>
  /** If section is a parent, these are the published children */
  children: Array<CmsSection>
  /**
   * If section is a parent, this is the number of children. \
   *                                                  Will be 0 if section is not a parent.
   */
  childrenCount: Scalars['Int']
  /** The CmsModule for the section */
  cmsModule?: Maybe<CmsModule>
  /** The CmsModule ID for the section */
  cmsModuleId: Scalars['ID']
  /**
   * The CmsProgram that the lesson is ultimately
   *                                                              contained within
   */
  cmsProgram: CmsProgram
  /** The CmsProgram that the lesson is ultimately contained within */
  cmsProgramId: Scalars['ID']
  /**
   * If section is a parent, this is the number of
   *                                                                        of children the user has completed.
   */
  completedChildrenCount?: Maybe<Scalars['Int']>
  /** Concept Icon */
  conceptIcon?: Maybe<Scalars['String']>
  /** An ID for a bookmarkable section of content */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Content is in the my saved items default collection */
  contentIsInMySavedItems?: Maybe<Scalars['Boolean']>
  /** Content Subtype */
  contentSubtype?: Maybe<Scalars['String']>
  /** Type of content */
  contentType?: Maybe<CmsSectionContentType>
  /** An image differentiating lesson type */
  contentTypeIcon?: Maybe<Scalars['String']>
  /** True if the section count towards program completion */
  countsTowardsCompletion: Scalars['Boolean']
  /** True if current user submitted feedback */
  currentUserSubmittedFeedback: Scalars['Boolean']
  /** Example project deliverables */
  deliverableExamples: Array<ProjectDeliverable>
  /** Description html safe */
  descriptionHtmlSafe?: Maybe<Scalars['String']>
  /** Cms section description text */
  descriptionText?: Maybe<Scalars['String']>
  /** The estimated time to complete the CmsSection */
  estimatedTime: Scalars['Int']
  /** The groups this section is in */
  groups: GroupConnection
  /** ContentCard hero image for the lesson */
  heroImageUrl?: Maybe<Scalars['String']>
  /** root url for card (used to work with ContentCard) */
  href: Scalars['String']
  /** Icon */
  icon?: Maybe<Scalars['String']>
  /** The id of the CmsSection */
  id: Scalars['ID']
  /** Project and concept intro html */
  introHtmlSafe?: Maybe<Scalars['String']>
  /**
   * Is the section a resource?
   *                             This is being used as a test on the membership program right now.
   */
  isResource: Scalars['Boolean']
  /**
   * Is the section a unit?
   *                             This is being used as a test on the membership program right now.
   */
  isUnit: Scalars['Boolean']
  /** Last Viewed Relative Time */
  lastViewedAt?: Maybe<Scalars['String']>
  /** Lesson position in the module */
  lessonNum?: Maybe<Scalars['Int']>
  /** The name of the CmsSection */
  name: Scalars['String']
  /** The next section */
  nextSection?: Maybe<CmsSection>
  /** Url of next section */
  nextSectionLearningCmsSectionPath?: Maybe<Scalars['String']>
  /** Next section name and path */
  nextSectionNameAndPath: Array<Scalars['String']>
  /** Amount of bookmarks */
  numBookmarks?: Maybe<Scalars['Int']>
  /** number of phases */
  numPhases?: Maybe<Scalars['Int']>
  /** The parent section */
  parent?: Maybe<CmsSection>
  /** If the lesson is child, this is the parent id */
  parentId?: Maybe<Scalars['ID']>
  /** The url of the CmsSection content viewer page */
  path?: Maybe<Scalars['String']>
  /** Position of the section */
  position?: Maybe<Scalars['Int']>
  /** Returns true if the lesson is previewable */
  previewable: Scalars['Boolean']
  /**
   * The amount of progress the current user has \
   *                                       made on the content, as a percentage
   */
  progressPercent?: Maybe<Scalars['Int']>
  /** Project Icon */
  projectIcon?: Maybe<Scalars['String']>
  /** The date the Cms section will be published */
  publishAt?: Maybe<Scalars['String']>
  /** Whether the section has been published or not */
  published?: Maybe<Scalars['Boolean']>
  /** Related cards */
  relatedContent?: Maybe<Array<CmsSection>>
  /** Description of the lesson that fits in the ContentCard */
  shortDescription?: Maybe<Scalars['String']>
  /** True if the section can collect feedback */
  showFeedback: Scalars['Boolean']
  /** Should show new badge */
  showNewBadge: Scalars['Boolean']
  /** Used for the url and sometimes querying */
  slug: Scalars['String']
  /** Status of CmsSection */
  status: Scalars['String']
  /** Lesson summary */
  summaryHtmlSafe?: Maybe<Scalars['String']>
  /** Cms Section tags */
  tags?: Maybe<Array<CmsSectionTag>>
  /** Cms section toc list */
  toc: Array<ContentTocItem>
  /** Topic */
  topic?: Maybe<PostTopic>
  /** Truncated cms section description html */
  truncatedDescriptionHtml?: Maybe<Scalars['String']>
  /** Is access unlimited */
  unlimitedAccess: Scalars['Boolean']
  /** Wistia code */
  wistiaCode?: Maybe<Scalars['String']>
}

/** A (lesson) within a CmsModule. May contain children CmsSections */
export type CmsSectionBookmarksArgs = {
  type?: InputMaybe<BookmarkType>
}

/** A (lesson) within a CmsModule. May contain children CmsSections */
export type CmsSectionGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** The Type of the CmsSection */
export enum CmsSectionContentType {
  CONCEPT = 'Concept',
  LESSON = 'Lesson',
  PROJECT = 'Project',
  RESOURCE = 'Resource',
  SECTION = 'Section',
  UNIT = 'Unit'
}

/** Cms Section Tag */
export type CmsSectionTag = {
  __typename: 'CmsSectionTag'
  /** ID of the Cms Section */
  cmsSectionId: Scalars['ID']
  /** ID of the CMS Section Tag */
  id: Scalars['ID']
  /** Tag type */
  tag: Scalars['String']
}

/** Coaching session data */
export type CoachingSession = {
  __typename: 'CoachingSession'
  /** Created at */
  createdAt: Scalars['ISO8601DateTime']
  /** Ai generated data */
  data?: Maybe<Scalars['JSON']>
  /** User the session belongs to */
  externalId: Scalars['String']
  /** Session Id */
  id: Scalars['ID']
  /** Initial coaching prompt */
  prompt?: Maybe<Scalars['String']>
  /** Last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** User the session belongs to */
  userId: Scalars['Int']
}

/** A certain time period that users participate in a live program. */
export type Cohort = {
  __typename: 'Cohort'
  /**
   * Data for the batch
   *                                                                rsvp flow for the cohort
   */
  batchRsvpTimeData: Array<BatchRsvpTimeData>
  /** The CmsProgram for the Cohort */
  cmsProgram: CmsProgram
  /** A list of cohort collections */
  cohortCollections: Array<CohortBookmarkFolder>
  /** Has the cohort ended its core (non-bonus) weeks */
  coreWeeksHaveEnded: Scalars['Boolean']
  /** Number of weeks the cohort will be in session for. */
  durationInWeeks?: Maybe<Scalars['Int']>
  /** When the cohort officially ends */
  endsAt?: Maybe<Scalars['String']>
  /** Number of events within the cohort */
  eventCount: Scalars['Int']
  /**
   * Event times that the cohort will meet.
   *                                                      This is populated from a rake task.
   */
  eventTimes?: Maybe<Array<CohortEventTimes>>
  /** The events scheduled for the cohort */
  events: Array<Event>
  /** The cohort-agnostic getting started module for the cohort season */
  gettingStartedModuleForSeason?: Maybe<CmsModule>
  /** The Conversation Group it is in */
  group?: Maybe<Group>
  /** Has cohort off-boarding started */
  hasCohortOffboardingStarted: Scalars['Boolean']
  /** Are we after cohort end date */
  hasEnded: Scalars['Boolean']
  /** Hosts for the program. */
  hosts?: Maybe<Array<Host>>
  /** ID */
  id: Scalars['ID']
  /** is the cohort between its start and end date */
  isOngoing: Scalars['Boolean']
  /** is the cohort before its start date */
  isUpcoming: Scalars['Boolean']
  /** The kickoff events scheduled for the cohort */
  kickoffEvents: Array<Event>
  /** true */
  marketingSiteUrl?: Maybe<Scalars['String']>
  /** Name of the cohort */
  name: Scalars['String']
  /** The scheduled week for the next week. */
  nextWeek?: Maybe<ScheduledWeek>
  /** Number of cohort unique cohort events */
  numUniqCohortEvents: Scalars['Int']
  /** Are we in post cohort timeframe */
  postCohort: Scalars['Boolean']
  /**
   * The special datetime
   *     set to open enrollment during the post-cohort period has passed.
   */
  postCoreWeeksEnrollmentIsOpen: Scalars['Boolean']
  /** Are we in post kickoff timeframe */
  postKickoff: Scalars['Boolean']
  /** Program collaborators. */
  programCollaborators?: Maybe<Array<Host>>
  /**
   * Information about the week
   *                                                             for a cohort cohort
   */
  scheduledWeeks: Array<ScheduledWeek>
  /** The season for the cohort */
  season: Season
  /** Whether to show the NPS survey */
  shouldShowNpsSurvey?: Maybe<Scalars['Boolean']>
  /** The slack channel group for the cohort */
  slackChannelId?: Maybe<Scalars['String']>
  /** The slug for the cohort */
  slug: Scalars['String']
  /** Whether it is within the release window of the cohort snapshot */
  snapshotReleased?: Maybe<Scalars['Boolean']>
  /** When the cohort officially starts */
  startsAt?: Maybe<Scalars['String']>
  /** The scheduled week for the current week. */
  thisWeek?: Maybe<ScheduledWeek>
  /** The live program duration in weeks */
  weekCount: Scalars['Int']
}

/** A certain time period that users participate in a live program. */
export type CohortGettingStartedModuleForSeasonArgs = {
  slug: Scalars['String']
}

/** Cohort Activity list item */
export type CohortActivityListItem = {
  __typename: 'CohortActivityListItem'
  /** Type of action performed (activity recordable_type) */
  actionType?: Maybe<Scalars['String']>
  /** Ids of activities grouped into the list item */
  activityIds: Array<Scalars['ID']>
  /** Time ago that the latest grouped activity in the list item was created */
  agoTimephrase: Scalars['String']
  /** true if any of the grouped activities have not yet been read */
  anyActivityUnread?: Maybe<Scalars['Boolean']>
  /** created_at of the latest grouped activity in the list item */
  latestActivityCreatedAt: Scalars['String']
  /** body of post on which activity was performed */
  postBodyText: Scalars['String']
  /** ID of post on which activity was performed */
  postId: Scalars['ID']
  /** topic slug of post on which activity was performed */
  postTopicSlug: Scalars['String']
  /** topic title of post on which activity was performed */
  postTopicTitle: Scalars['String']
  /**
   * Array of string representations of reactions from grouped
   *                                                               activities
   */
  reactions: Array<ReactionKind>
  /** Body test of replies from grouped activities */
  replies: Array<Scalars['String']>
  /** Ids of unread activities grouped into the list item */
  unreadActivityIds?: Maybe<Array<Scalars['ID']>>
  /** Profile avatar of user who performed activity */
  userAvatarUrl?: Maybe<Scalars['String']>
  /** Full name of user who performed activity */
  userFullName?: Maybe<Scalars['String']>
}

/** Cohort activity list */
export type CohortActivityListWithMetadata = {
  __typename: 'CohortActivityListWithMetadata'
  /**
   * list of cohort activities (grouped)
   *                                                                           for a user
   */
  activities: Array<CohortActivityListItem>
  /**
   * total number of unread cohort notifications
   *                                                                      for user
   */
  unreadCount: Scalars['Int']
}

/** Cohort collection (bookmark folder) */
export type CohortBookmarkFolder = {
  __typename: 'CohortBookmarkFolder'
  /** The ID of Cohort Bookmark Folder */
  bookmarkFolderId: Scalars['ID']
  /** The ID of the Cohort Bookmark Folder */
  id: Scalars['ID']
  /** Name of the Cohort Bookmark Folder */
  name: Scalars['String']
}

/** A cohort completion certificate */
export type CohortCertificate = {
  __typename: 'CohortCertificate'
  /** URL to the Certificate. */
  certificateUrl?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
}

/** A credit that allows a user to participate in a cohort. */
export type CohortCredit = {
  __typename: 'CohortCredit'
  /** The date and time the credit expires. */
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The ID of the cohort credit. */
  id: Scalars['ID']
  /** Which seat the credit is assigned to. */
  seat?: Maybe<Seat>
  /** The status of the credit. */
  status: Scalars['String']
  /** Whether the credit can be used. */
  useable: Scalars['Boolean']
}

/** Cohort credits organised according to their state */
export type CohortCredits = {
  __typename: 'CohortCredits'
  /** All, including expired and used */
  all: Array<CohortCredit>
  /** unexpired, unused, unassigned cohort credits */
  assignable: Array<CohortCredit>
  /** Expired cohort credits */
  expired: Array<CohortCredit>
  /** Unassigned (where seat id is nil) */
  unassigned: Array<CohortCredit>
  /** Unexpired cohort credits */
  unexpired: Array<CohortCredit>
  /** Unused (where user_cohort_id is nil) */
  unused: Array<CohortCredit>
  /** unexpired, unused cohort credits */
  useable: Array<CohortCredit>
}

/** Event times and details that the cohort meets in a live session. */
export type CohortEventTimes = {
  __typename: 'CohortEventTimes'
  /**
   * How many times the event will occur. Usually once or twice a
   *                        week for the duration of the cohort.
   */
  count: Scalars['Int']
  /** First end date and time of the first event. */
  firstEndDate?: Maybe<Scalars['String']>
  /** First start date and time of the first event. */
  firstStartDate: Scalars['String']
}

/** Details about a cohort pass prices */
export type CohortPassPriceTier = {
  __typename: 'CohortPassPriceTier'
  /** The upper bound of the range of passes at this price */
  maxCount: Scalars['Int']
  /** The lower bound of the range of passes at this price */
  minCount: Scalars['Int']
  /** The price of an individual pass within this range */
  pricePerPass: Scalars['Int']
  /** The Stripe ID of the cohort pass price tier */
  stripeId?: Maybe<Scalars['String']>
}

/** Cohort Post Type */
export type CohortPost = {
  __typename: 'CohortPost'
  /** Total number of posts. */
  count: Scalars['Int']
  /** List of pinned posts */
  pinnedPosts: Array<Post>
  /** List of posts */
  posts: Array<Post>
  /** Topic of the posts */
  topic: PostTopic
}

/** Cohort Team Type */
export type CohortTeam = {
  __typename: 'CohortTeam'
  /**
   * list of cohort team activities excluding
   *                                                                           current user.
   */
  activities: Array<CohortActivityListItem>
  /** Cohort team ID */
  id: Scalars['ID']
  /** Cohort Team members. */
  members: Array<User>
  /** Cohort team name */
  name: Scalars['String']
}

/** An individual collection of recommendations */
export type Collection = {
  __typename: 'Collection'
  /** Content types recommended in the collection */
  contentTypes: Array<ReforgeContent>
  /** UUID of the collection */
  id: Scalars['ID']
  /** Items in the collection */
  items: Array<RecommendedItem>
  /** Title of the collection */
  title: Scalars['String']
}

/** Activity a user has made on a collection */
export type CollectionActivity = {
  __typename: 'CollectionActivity'
  /** User facing text for action */
  actionText: Scalars['String']
  /** Type of action */
  actionType: Scalars['String']
  /** The bookmark folder id */
  bookmarkFolderId?: Maybe<Scalars['ID']>
  /** The time since the activity was created */
  createdAtInWords: Scalars['String']
  /** The filed bookmark */
  filedBookmark?: Maybe<FiledBookmark>
  /** whether the activity can be reversed */
  hasRestoreAction: Scalars['Boolean']
  /** The id */
  id: Scalars['ID']
  /** The user who did the action */
  user: User
  /** The id of the user who did the action */
  userId: Scalars['ID']
}

/** A list of collection activities */
export type CollectionActivityList = {
  __typename: 'CollectionActivityList'
  /** A list of collection activities */
  collectionActivities: Array<CollectionActivity>
  /** Total number of collection activities */
  totalCount: Scalars['Int']
}

/** Top level queries for collections */
export type CollectionsQuery = {
  /** A folder acessible to the user */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** All bookmarks from a folder */
  bookmarksFromFolder: Array<Bookmark>
  /** most recent activity for a collection per each user shared with */
  mostRecentCollectionActivityPerUser: Array<CollectionActivity>
}

/** Top level queries for collections */
export type CollectionsQueryBookmarkFolderArgs = {
  folderId: Scalars['ID']
}

/** Top level queries for collections */
export type CollectionsQueryBookmarksFromFolderArgs = {
  folderId: Scalars['ID']
}

/** Top level queries for collections */
export type CollectionsQueryMostRecentCollectionActivityPerUserArgs = {
  collectionId: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

/** A discussion comment made by a user. */
export type Comment = {
  __typename: 'Comment'
  /** Text of the post */
  body?: Maybe<Scalars['String']>
  /** Text of the post */
  bodyText?: Maybe<Scalars['String']>
  /** The ID of the commentable object */
  commentableId?: Maybe<Scalars['ID']>
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Created at date in concise time */
  createdAtInWords: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Left */
  lft?: Maybe<Scalars['Int']>
  /** The ID of the parent comment */
  parentId?: Maybe<Scalars['ID']>
  /** Post associated with the comment */
  post?: Maybe<Post>
  /** Reactions associated with the comment */
  reactions: Array<Reaction>
  /** Right */
  rgt?: Maybe<Scalars['Int']>
  /** Title of the post */
  title?: Maybe<Scalars['String']>
  /** Updated at date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The associated user */
  user: User
}

/** A company that a user is or has been employed with. */
export type Company = {
  __typename: 'Company'
  /** Company card logo url. */
  cardLogoUrl?: Maybe<Scalars['String']>
  /** Company name. */
  companyName?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** Company name. */
  name?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CompleteProductTour */
export type CompleteProductTourInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The product tour to mark as complete */
  productTourKey: ProductTourKey
}

/** Autogenerated return type of CompleteProductTour */
export type CompleteProductTourPayload = {
  __typename: 'CompleteProductTourPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Collection of completed product tours */
  completedProductTours: ProductTour
}

/** Whether the user has finished providing feedback on different surveys and questionnaires */
export type CompletedFeedback = {
  __typename: 'CompletedFeedback'
  /** Whether or not the user has completed the post-apply survey */
  postApplySurvey: Scalars['Boolean']
}

/** Data related to a cohort-completion snapshot */
export type CompletionSnapshot = {
  __typename: 'CompletionSnapshot'
  /** The URL pointing to the stored avatar image for the user */
  avatarUrl: Scalars['String']
  /** ID of the cohort associated with the snapshot */
  cohortId: Scalars['ID']
  /** The hashtag to be used when sharing the snapshot on social media */
  hashtag: Scalars['String']
  /** The URL pointing to the stored image of the snapshot */
  imagePath?: Maybe<Scalars['String']>
  /** Whether we are currently in the release window for the snapshot */
  isReleased: Scalars['Boolean']
  /** The name of the program associated with the snapshot */
  programName: Scalars['String']
  /** Name of the season associated with the snapshot */
  seasonName: Scalars['String']
  /** The URL used for sharing the snapshot */
  snapshotShareUrl: Scalars['String']
  /** When the season associated with the snapshot starts */
  startsAt: Scalars['String']
  /** The unique token associated with the snapshot */
  token?: Maybe<Scalars['String']>
  /** ID of the user cohort */
  userCohortId: Scalars['ID']
}

/** Autogenerated input type of ConfirmUserMerge */
export type ConfirmUserMergeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Confirmation nonce */
  nonce: Scalars['String']
}

/** Autogenerated return type of ConfirmUserMerge */
export type ConfirmUserMergePayload = {
  __typename: 'ConfirmUserMergePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** True if merge was successful */
  success: Scalars['Boolean']
  /** User merge associated with confirmation */
  userMerge?: Maybe<UserMerge>
}

/** Content for UI type */
export type Content = {
  __typename: 'Content'
  /** Access level of current user */
  accessLevel?: Maybe<Scalars['String']>
  /** The content */
  cmsSection: CmsSection
  /** Content is bookmarked by user */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Content is in the my saved items default collection */
  contentIsInMySavedItems?: Maybe<Scalars['Boolean']>
  /** Should show new badge */
  showNewBadge?: Maybe<Scalars['Boolean']>
}

/** Content blocks for units coming from Sanity CMS */
export type ContentBlock = {
  __typename: 'ContentBlock'
  /** Content block collapsible for the content block. */
  contentBlockCollapsible?: Maybe<Array<ContentBlockCollapsible>>
  /** Description for the content block. */
  description?: Maybe<Scalars['JSON']>
  /** Header for the content block. */
  header?: Maybe<Scalars['String']>
  /** ID of the tag. */
  id: Scalars['String']
  /** Ordering for the content block. */
  order?: Maybe<Scalars['Int']>
  /** Slug for the unit. */
  slug: Scalars['String']
}

/** Content block collapsible coming from Sanity CMS */
export type ContentBlockCollapsible = {
  __typename: 'ContentBlockCollapsible'
  /** Description for the content block collapsible. */
  description?: Maybe<Scalars['JSON']>
  /** ID of the tag. */
  id: Scalars['String']
  /** Label for Content block collapsible */
  label?: Maybe<Scalars['String']>
  /** Type for the content block. */
  type?: Maybe<Scalars['String']>
}

/** Filters accepted for content type for projects and concepts */
export enum ContentCategory {
  /** Bookmarked category */
  BOOKMARKED = 'BOOKMARKED',
  /** Completed category */
  COMPLETED = 'COMPLETED',
  /** Started category */
  STARTED = 'STARTED',
  /** Unviewed category */
  UNVIEWED = 'UNVIEWED'
}

/** Past concepts feed. */
export type ContentFeed = {
  __typename: 'ContentFeed'
  /** CMS Module ID */
  cmsModuleId?: Maybe<Scalars['ID']>
  /** Content. */
  content: Array<Content>
  /** Total number of content matching filters. */
  count: Scalars['Int']
  /** Unique Topic titles */
  topics?: Maybe<Array<Scalars['String']>>
  /** User program */
  userProgram?: Maybe<UserProgram>
}

/** Content filters to apply during event queries. */
export type ContentFilters = {
  /** Array of content types to filter content by */
  contentType?: InputMaybe<Array<ContentCategory>>
  /** Array of topics to filter cms section by. */
  topic?: InputMaybe<Array<Scalars['String']>>
}

/** Content (cms section) toc item */
export type ContentTocItem = {
  __typename: 'ContentTocItem'
  /** Content toc item children */
  children?: Maybe<Array<ContentTocItem>>
  /** Content toc icon */
  contentTypeIcon?: Maybe<Scalars['String']>
  /** Content toc selected */
  current?: Maybe<Scalars['Boolean']>
  /** Estimated time to complete the toc item */
  estimatedTime?: Maybe<Scalars['String']>
  /** Content toc item href */
  href?: Maybe<Scalars['String']>
  /** Content toc item id */
  id: Scalars['ID']
  /** Content toc item id */
  isSidebar?: Maybe<Scalars['Boolean']>
  /** Content toc item string */
  name?: Maybe<Scalars['String']>
  /** Content toc item num bookmarks */
  numBookmarks?: Maybe<Scalars['Int']>
  /** Content toc item id */
  parentId?: Maybe<Scalars['ID']>
  /** Content toc phase number */
  phaseNumber?: Maybe<Scalars['Int']>
}

/** The table of contents for the content in the viewer */
export type ContentViewer = {
  __typename: 'ContentViewer'
  /** Cms section */
  cmsContent?: Maybe<CmsSection>
  /** The type of content (Lesson, Project, Concept) */
  cmsContentType?: Maybe<Scalars['String']>
  /** Cms module */
  cmsModule?: Maybe<CmsModule>
  /** Cms program */
  cmsProgram?: Maybe<CmsProgram>
  /** A deliverable for a Project */
  cmsProjectDeliverable?: Maybe<ProjectDeliverable>
  /** Content bookmarks for current user */
  contentBookmarkAnchors: Array<Bookmark>
  /** in default folder */
  contentBookmarkAnchorsInMySavedItems: Array<Bookmark>
  /** Expert users */
  expertUsers?: Maybe<Array<User>>
  /** Is sidebar */
  isSidebar?: Maybe<Scalars['Boolean']>
  /** User program */
  userProgram?: Maybe<UserProgram>
  /** current user's level of access to content */
  viewAccess: Scalars['String']
}

/** Cookie consent from Sanity CMS */
export type CookieConsent = {
  __typename: 'CookieConsent'
  /** Is Enabled */
  enabled: Scalars['Boolean']
  /** Consent message */
  message?: Maybe<Scalars['String']>
  /** Learn more URL */
  url?: Maybe<Scalars['String']>
}

/** Data for course */
export type Course = {
  __typename: 'Course'
  /** The CourseBookmark if it exists */
  bookmark?: Maybe<Bookmark>
  /** The CCL course equivalent of the course, if any */
  cclCourse?: Maybe<CclCourse>
  /** The CMS program associated with the course */
  cmsProgram?: Maybe<CmsProgram>
  /** The completion percentage of the course */
  completionPercentage?: Maybe<Scalars['Int']>
  /** Course blocks for the course. */
  courseBlocks?: Maybe<Array<CourseBlock>>
  /** Creators for the course. */
  creators?: Maybe<Array<ArtifactAuthor>>
  /** The ID of the course */
  id: Scalars['ID']
  /** Whether or not the course is available on-demand */
  isAvailableOnDemand?: Maybe<Scalars['Boolean']>
  /** Whether users are able to enroll in the course */
  isEnrollmentOpen: Scalars['Boolean']
  /** The sanity id for the course */
  sanityId?: Maybe<Scalars['String']>
  /** Short description of the course. */
  shortDescription?: Maybe<Scalars['String']>
  /** The url slug for the course */
  slug: Scalars['String']
  /** The name of the course */
  title: Scalars['String']
  /** What you will learn in this course */
  whatYouWillLearn?: Maybe<Scalars['JSON']>
  /** Who this course is for. */
  whoThisIsFor?: Maybe<Scalars['JSON']>
}

/** Course index Course Benefit from Sanity CMS */
export type CourseBenefit = {
  __typename: 'CourseBenefit'
  /** Description */
  description: Scalars['String']
  /** Icon */
  icon: Image
  /** ID */
  id: Scalars['ID']
  /** Title */
  title: Scalars['String']
}

/** Course blocks for courses coming from Sanity CMS */
export type CourseBlock = {
  __typename: 'CourseBlock'
  /** Artifacts for the course. */
  artifacts?: Maybe<Array<Artifact>>
  /** Description for the content block. */
  description?: Maybe<Scalars['JSON']>
  /** When course block ends. */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Free Event for preview */
  eventPreviews?: Maybe<Array<CourseEvent>>
  /** Events for the course. */
  events?: Maybe<Array<CourseEvent>>
  /** ID of the tag. */
  id?: Maybe<Scalars['String']>
  /** Whether the course block is previewable for free */
  previewable?: Maybe<Scalars['Boolean']>
  /** When to read the content by. */
  readByDate?: Maybe<Scalars['ISO8601DateTime']>
  /** When course block starts. */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Slug for the unit. */
  title?: Maybe<Scalars['String']>
  /** Units for the course. */
  units?: Maybe<Array<Unit>>
}

/** A course completion certificate */
export type CourseCertificate = {
  __typename: 'CourseCertificate'
  /** URL to the certificate */
  certificateUrl?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
}

/** Data related to a course completion snapshot */
export type CourseCompletionSnapshot = {
  __typename: 'CourseCompletionSnapshot'
  /** The ID of the course completion snapshot */
  id: Scalars['ID']
  /** The URL pointing to the stored image of the snapshot */
  imageUrl?: Maybe<Scalars['String']>
  /** URL to share on LinkedIn */
  linkedinShareUrl?: Maybe<Scalars['String']>
  /** Link to the snapshot landing page */
  shareUrl?: Maybe<Scalars['String']>
  /** UUID of the course completion snapshot */
  token: Scalars['ID']
  /** URL to share on Twitter */
  twitterShareUrl?: Maybe<Scalars['String']>
}

/** Topics and functions associated to a course */
export type CourseConcepts = {
  __typename: 'CourseConcepts'
  /** Functions associated with the course */
  functions?: Maybe<Array<Function>>
  /** Topics associated with the course */
  topics?: Maybe<Array<Topic>>
}

/** Marketing Course Details from Sanity CMS */
export type CourseDetails = {
  __typename: 'CourseDetails'
  /** Course details */
  course: UnifiedCourse
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** Autogenerated input type of CourseEnrollmentConfirmed */
export type CourseEnrollmentConfirmedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of Course Session user trying to enroll in, if provided */
  courseSessionId?: InputMaybe<Scalars['ID']>
  /** Slug of Course user trying to enroll in */
  courseSlug: Scalars['String']
  /** Track location in UI */
  ctaLocation?: InputMaybe<Scalars['String']>
  /** Payment method used to enroll in course */
  paymentMethod?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CourseEnrollmentConfirmed */
export type CourseEnrollmentConfirmedPayload = {
  __typename: 'CourseEnrollmentConfirmedPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Data related to a course enrollment snapshot */
export type CourseEnrollmentSnapshot = {
  __typename: 'CourseEnrollmentSnapshot'
  /** The ID of the course enrollment snapshot */
  id: Scalars['ID']
  /** The URL pointing to the stored image of the snapshot */
  imageUrl?: Maybe<Scalars['String']>
  /** URL to share on LinkedIn */
  linkedinShareUrl?: Maybe<Scalars['String']>
  /** Link to the snapshot landing page */
  shareUrl?: Maybe<Scalars['String']>
  /** UUID of the course enrollment snapshot */
  token: Scalars['ID']
  /** URL to share on Twitter */
  twitterShareUrl?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CourseEnrollmentStarted */
export type CourseEnrollmentStartedInput = {
  /** ID of CCL Course Session user trying to enroll in, if provided */
  cclCourseSessionId?: InputMaybe<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of Course Session user trying to enroll in, if provided */
  courseSessionId?: InputMaybe<Scalars['ID']>
  /** Slug of Course user trying to enroll in */
  courseSlug: Scalars['String']
  /** Track location in UI */
  ctaLocation?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CourseEnrollmentStarted */
export type CourseEnrollmentStartedPayload = {
  __typename: 'CourseEnrollmentStartedPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Events coming from Sanity CMS */
export type CourseEvent = {
  __typename: 'CourseEvent'
  /** When the event ends. */
  endsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Events per course session. */
  eventPerCourseSession?: Maybe<Array<EventPerCourseSession>>
  /** Hosts for the event. */
  featuredGuests?: Maybe<Array<ArtifactAuthor>>
  /** Hosts for the event. */
  hosts?: Maybe<Array<ArtifactAuthor>>
  /** URL to join event. */
  joinLink?: Maybe<Scalars['String']>
  /** Event recording video. */
  recording?: Maybe<Scalars['JSON']>
  /** Sanity ID for the event. */
  sanityId?: Maybe<Scalars['String']>
  /** Description for the event. */
  shortDescription?: Maybe<Scalars['String']>
  /** When the event starts. */
  startsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Title of the event. */
  title?: Maybe<Scalars['String']>
}

/** Data for course */
export type CourseForTopicAndFunction = {
  __typename: 'CourseForTopicAndFunction'
  /** Creators for the course. */
  creators?: Maybe<Array<ArtifactAuthor>>
  /** The ID of the course */
  id: Scalars['ID']
  /** Short description of the course. */
  shortDescription: Scalars['String']
  /** The url slug for the course */
  slug: Scalars['String']
  /** Testimonials for the course. */
  testimonials?: Maybe<Array<SanityTestimonial>>
  /** The name of the course */
  title: Scalars['String']
  /** What you will learn in this course */
  whatYouWillLearn?: Maybe<Scalars['JSON']>
}

/** Marketing Courses Index from Sanity CMS */
export type CourseIndex = {
  __typename: 'CourseIndex'
  /** Courses list */
  courses: Array<MarketingCourse>
  /** Page */
  page: CourseIndexPage
}

/** Marketing Courses Index content from Sanity CMS */
export type CourseIndexContent = {
  __typename: 'CourseIndexContent'
  /** Course Benefits */
  courseBenefits: Array<CourseBenefit>
  /** CTA Link */
  ctaLink: Scalars['String']
  /** CTA Text */
  ctaText: Scalars['String']
  /** Text Switcher Title */
  heading: Scalars['String']
  /** Hero Image */
  heroImage: Image
  /** Logo Images */
  logos?: Maybe<Array<Image>>
  /** Text Switcher Title */
  subheading: Scalars['String']
}

/** Marketing Courses Index page from Sanity CMS */
export type CourseIndexPage = {
  __typename: 'CourseIndexPage'
  /** Content */
  content: CourseIndexContent
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** Course Info Card coming from Sanity CMS */
export type CourseInfoCard = {
  __typename: 'CourseInfoCard'
  /** Description of the course */
  description?: Maybe<Scalars['String']>
  /** ID */
  id?: Maybe<Scalars['String']>
  /** Title of the course */
  title?: Maybe<Scalars['String']>
}

/** Course preview content */
export type CoursePreview = {
  __typename: 'CoursePreview'
  /** Course blocks for the course. */
  courseBlocks?: Maybe<Array<CourseBlock>>
  /** Creators for the course. */
  creators?: Maybe<Array<ArtifactAuthor>>
  /** The ID of the course */
  id: Scalars['ID']
  /** Short description of the course. */
  shortDescription?: Maybe<Scalars['String']>
  /** The url slug for the course */
  slug: Scalars['String']
  /** The name of the course */
  title: Scalars['String']
  /** Next course session. */
  upcomingSession?: Maybe<CourseSession>
  /** Who this course is for. */
  whoThisIsFor?: Maybe<Scalars['JSON']>
}

/** A session run of a course. */
export type CourseSession = {
  __typename: 'CourseSession'
  /** ID of Course */
  courseId: Scalars['String']
  /** Slug of Course */
  courseSlug: Scalars['String']
  /** Title of Course */
  courseTitle: Scalars['String']
  /** When the session ends. */
  endsAt: Scalars['ISO8601DateTime']
  /** When enrollment starts. */
  enrollmentStartsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Getting started block */
  gettingStartedBlock?: Maybe<GettingStartedBlock>
  /** Whether course session has ended */
  hasEnded: Scalars['Boolean']
  /** Whether course session has started */
  hasStarted: Scalars['Boolean']
  /** Hosts for this course session */
  hosts?: Maybe<Array<ArtifactAuthor>>
  /** ID. */
  id: Scalars['ID']
  /** Whether course content is open to view */
  isContentOpen: Scalars['Boolean']
  /** Whether course session is current */
  isCurrent: Scalars['Boolean']
  /** Whether or not the current time is within the enrollment window */
  isDuringEnrollmentPeriod: Scalars['Boolean']
  /** Whether or not enrollment has filled up for this particular type of user */
  isEnrollmentFull: Scalars['Boolean']
  /** Whether the current user is a host or creator of for this course session */
  isHostOrCreator: Scalars['Boolean']
  /** Whether the course is past */
  isPast: Scalars['Boolean']
  /** Whether the course is upcoming */
  isUpcoming: Scalars['Boolean']
  /** Mark your calendar */
  markYourCalendar?: Maybe<Scalars['JSON']>
  /** Enrollment cap for users paying or using a cohort credit to enroll in this session */
  maxEnrollmentPaid?: Maybe<Scalars['Int']>
  /** Total enrollment cap of this session */
  maxEnrollmentTotal?: Maybe<Scalars['Int']>
  /** AYCE member enrollment cap of this session */
  maxEnrollmentUnlimited?: Maybe<Scalars['Int']>
  /** Number of paying users enrolled in this session */
  numEnrolledPaid?: Maybe<Scalars['Int']>
  /** Total number of users enrolled in this session */
  numEnrolledTotal?: Maybe<Scalars['Int']>
  /** Number of AYCE members enrolled in this session */
  numEnrolledUnlimited?: Maybe<Scalars['Int']>
  /** Overall duration of the course. */
  overallDuration?: Maybe<Scalars['String']>
  /** Price of the course for this session */
  price: Scalars['Int']
  /** Slack channel ID for this session */
  slackChannelId?: Maybe<Scalars['String']>
  /** When the session begins. */
  startsAt: Scalars['ISO8601DateTime']
  /** Survey link for this session */
  surveyLink?: Maybe<Scalars['String']>
  /** Wrap up for this session */
  wrapUp?: Maybe<Scalars['JSON']>
}

/** In-depth details on a course session, separated by data source. */
export type CourseSessionDetail = {
  __typename: 'CourseSessionDetail'
  /** ID of Course from DB */
  dbCourseId: Scalars['ID']
  /** ID of Course Session from DB */
  dbCourseSessionId: Scalars['ID']
  /** Course slug from DB */
  dbCourseSlug: Scalars['String']
  /** Course title from DB */
  dbCourseTitle: Scalars['String']
  /** Course session end time from DB */
  dbEndsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Course session enrollment start time from DB */
  dbEnrollmentStartsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The user IDs allowed to access the course session */
  dbHostAccessAllowedForUserIds: Array<Scalars['Int']>
  /** The maximum paid enrollees */
  dbMaxEnrollmentPaid?: Maybe<Scalars['Int']>
  /** The maximum total enrollees */
  dbMaxEnrollmentTotal?: Maybe<Scalars['Int']>
  /** The maximum unlimited enrollees */
  dbMaxEnrollmentUnlimited?: Maybe<Scalars['Int']>
  /** The price to enroll in the course session */
  dbPrice?: Maybe<Scalars['Int']>
  /** Sanity ID for course session from DB */
  dbSanityId?: Maybe<Scalars['String']>
  /** The id for the group of the course sessions this one is in, if any */
  dbSessionGroupId?: Maybe<Scalars['Int']>
  /** Course session Slack channel ID from DB */
  dbSlackChannelId?: Maybe<Scalars['String']>
  /** Course session start time from DB */
  dbStartsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Stripe product ID for course session from DB */
  dbStripeProductId?: Maybe<Scalars['String']>
  /** The Course ID in Sanity */
  sanityCourseId?: Maybe<Scalars['String']>
  /** The Course Session ID in Sanity */
  sanityCourseSessionId?: Maybe<Scalars['String']>
  /** The Course Session title in Sanity */
  sanityCourseSessionTitle?: Maybe<Scalars['String']>
  /** The Course Title in Sanity */
  sanityCourseTitle?: Maybe<Scalars['String']>
  /** The overall duration of the course session */
  sanityDuration?: Maybe<Scalars['String']>
  /** The Course Session ID in Sanity */
  sanityEndsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The Course Session Slack channel ID in Sanity */
  sanitySlackChannelId?: Maybe<Scalars['String']>
  /** The Course Session ID in Sanity */
  sanityStartsAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Data associated with course enrollment snapshot */
export type CourseSnapshot = {
  __typename: 'CourseSnapshot'
  /** Description of the course associated with the snapshot */
  courseDescription?: Maybe<Scalars['String']>
  /** Name of the course associated with the snapshot */
  courseName?: Maybe<Scalars['String']>
  /** Slug of the course associated with the snapshot */
  courseSlug?: Maybe<Scalars['String']>
  /** First name of the snapshot holder */
  firstName?: Maybe<Scalars['String']>
  /** URL pointing to the image of the snapshot in storage */
  imageUrl?: Maybe<Scalars['String']>
}

/** Marketing Course Topic Covered from Sanity CMS */
export type CourseTopicCovered = {
  __typename: 'CourseTopicCovered'
  /** Description */
  description?: Maybe<Scalars['String']>
  /** Heading */
  heading?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
}

/** Autogenerated input type of CreateAccount */
export type CreateAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Company name. */
  companyName?: InputMaybe<Scalars['String']>
  /** Email consent. */
  emailConsent?: InputMaybe<Scalars['Boolean']>
  /** Job function. */
  jobFunction: Scalars['String']
  /** set onboarded true if joining a team invite */
  onboardingStep?: InputMaybe<Scalars['Int']>
  /** Referral source. */
  referralSource: Scalars['String']
  /** Role at the company. */
  role: Scalars['String']
  /** Transitioning companies. */
  transitioningCompanies?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of CreateAccount */
export type CreateAccountPayload = {
  __typename: 'CreateAccountPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Updated user */
  currentUser: User
  /** True if successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of CreateBookmarkFolder */
export type CreateBookmarkFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The bookmark folder description */
  description?: InputMaybe<Scalars['String']>
  /** The name of the folder */
  name: Scalars['String']
  /** Id of parent for nested folder */
  parentId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateBookmarkFolder */
export type CreateBookmarkFolderPayload = {
  __typename: 'CreateBookmarkFolderPayload'
  /** The folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of CreateBookmark */
export type CreateBookmarkInput = {
  /** Anchor of bookmark */
  anchor?: InputMaybe<Scalars['String']>
  /** Bookmark based on */
  basedOn?: InputMaybe<Scalars['String']>
  /** Id of folder for FiledBookmark */
  bookmarkFolderId?: InputMaybe<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of cms module */
  cmsModuleId?: InputMaybe<Scalars['ID']>
  /** ID of cms program */
  cmsProgramId?: InputMaybe<Scalars['ID']>
  /** ID of cms section */
  cmsSectionId?: InputMaybe<Scalars['ID']>
  /** Slug of course */
  courseSlug?: InputMaybe<Scalars['String']>
  /** Highlighted text ending offset */
  endOffset?: InputMaybe<Scalars['Int']>
  /** Event ID for bookmark */
  eventId?: InputMaybe<Scalars['ID']>
  /** External URL of the bookmark */
  externalUrl?: InputMaybe<Scalars['String']>
  /** Highlighting metadata for bookmark */
  metadata?: InputMaybe<Scalars['JSON']>
  /** Notes for bookmark */
  noteBody?: InputMaybe<Scalars['String']>
  /** Position of the FiledBookmark */
  position?: InputMaybe<Scalars['Int']>
  /** URL of bookmark image */
  referenceImageUrl?: InputMaybe<Scalars['String']>
  /** Related identifiers */
  relatedIdentifiers?: InputMaybe<BookmarkRelatedIdentifiers>
  /** Sanity ID for bookmark */
  sanityId?: InputMaybe<Scalars['String']>
  /** Bookmark seconds */
  seconds?: InputMaybe<Scalars['Int']>
  /** Highlighted text starting offset */
  startOffset?: InputMaybe<Scalars['Int']>
  /** Title of the bookmark */
  title?: InputMaybe<Scalars['String']>
  /** Type of bookmark */
  type: BookmarkType
  /** Video code */
  videoCode?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateBookmark */
export type CreateBookmarkPayload = {
  __typename: 'CreateBookmarkPayload'
  /** Created object of bookmark */
  bookmark?: Maybe<Bookmark>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The collection activity */
  collectionActivity?: Maybe<CollectionActivity>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of CreateCohortPost */
export type CreateCohortPostInput = {
  /** Description of the post */
  body: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Group ID of the cohort */
  cohortGroupId: Scalars['ID']
  /** Cohort ID for the post */
  cohortId: Scalars['ID']
  /** If the cohort should be notified about the post */
  notifyCohort?: InputMaybe<Scalars['Boolean']>
  /** Topic ID for the post */
  topicId: Scalars['ID']
}

/** Autogenerated return type of CreateCohortPost */
export type CreateCohortPostPayload = {
  __typename: 'CreateCohortPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the post */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of CreateCohortReply */
export type CreateCohortReplyInput = {
  /** Reply body */
  body: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Group ID of the cohort */
  cohortGroupId: Scalars['ID']
  /** Post ID */
  id: Scalars['ID']
}

/** Autogenerated return type of CreateCohortReply */
export type CreateCohortReplyPayload = {
  __typename: 'CreateCohortReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the created reply */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of CreateCourseEnrollment */
export type CreateCourseEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** slug for course */
  courseSlug: Scalars['String']
  /** The id of the payment method to use for the purchase */
  paymentMethodId?: InputMaybe<Scalars['String']>
  /** payment_type to set; caller must be an admin to include */
  paymentType?: InputMaybe<Scalars['String']>
  /** Whether or not the user is shadowing the course and not truly enrolled */
  shadow?: InputMaybe<Scalars['Boolean']>
  /** Date that the course session begins */
  startDate?: InputMaybe<Scalars['String']>
  /** The stripe invoice ID if one exists */
  stripeInvoiceId?: InputMaybe<Scalars['String']>
  /** whether or not the user wishes to use a cohort pass to pay for the course */
  useCohortPass?: InputMaybe<Scalars['Boolean']>
  /** id of user; caller must be an admin to include */
  userId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateCourseEnrollment */
export type CreateCourseEnrollmentPayload = {
  __typename: 'CreateCourseEnrollmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** course session id */
  courseSessionId?: Maybe<Scalars['Int']>
  /** list of errors if returned from api */
  errors?: Maybe<Array<Scalars['String']>>
  /** whether the user has cohort passes to spend */
  hasAssignableCohortPasses?: Maybe<Scalars['Boolean']>
  /** id of user course record */
  id?: Maybe<Scalars['Int']>
  /** url that user should be redirected to */
  redirectUrl?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateCourseSession */
export type CreateCourseSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Course ID for the session */
  courseId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of CreateCourseSession */
export type CreateCourseSessionPayload = {
  __typename: 'CreateCourseSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if update failed */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the updated course session */
  id: Scalars['ID']
}

/** Autogenerated input type of CreateCourseSessionSlackChannel */
export type CreateCourseSessionSlackChannelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Course Session ID for the slack channel */
  courseSessionId: Scalars['ID']
}

/** Autogenerated return type of CreateCourseSessionSlackChannel */
export type CreateCourseSessionSlackChannelPayload = {
  __typename: 'CreateCourseSessionSlackChannelPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if update failed */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the updated course session */
  id: Scalars['ID']
}

/** Autogenerated input type of CreateDraftSubscriptionJoinRequest */
export type CreateDraftSubscriptionJoinRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** External ID of the subscription */
  subscriptionExternalId: Scalars['String']
}

/** Autogenerated return type of CreateDraftSubscriptionJoinRequest */
export type CreateDraftSubscriptionJoinRequestPayload = {
  __typename: 'CreateDraftSubscriptionJoinRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription join request */
  subscriptionJoinRequest?: Maybe<SubscriptionJoinRequest>
}

/** Autogenerated input type of CreateFiledBookmark */
export type CreateFiledBookmarkInput = {
  /** ID of the bookmark folder */
  bookmarkFolderId?: InputMaybe<Scalars['ID']>
  /** ID of the bookmark */
  bookmarkId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Whether to use the default folder */
  defaultBookmarkFolder?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of CreateFiledBookmark */
export type CreateFiledBookmarkPayload = {
  __typename: 'CreateFiledBookmarkPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The filed bookmark */
  filedBookmark?: Maybe<FiledBookmark>
}

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Whether the event is in progress */
  happening?: InputMaybe<Scalars['Boolean']>
  /** ID of the event */
  id: Scalars['ID']
  /** RSVP for recording only */
  recordingOnly?: InputMaybe<Scalars['Boolean']>
  /** Timezone of the user */
  timezone: Scalars['String']
}

/** Autogenerated return type of Create */
export type CreatePayload = {
  __typename: 'CreatePayload'
  /** The attendee record created or updated */
  attendee?: Maybe<EventAttendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors array if any errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of CreateProgramApplication */
export type CreateProgramApplicationInput = {
  /** is alumni */
  alumni?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** company name */
  companyName?: InputMaybe<Scalars['String']>
  /** coupon code */
  couponCode?: InputMaybe<Scalars['String']>
  /** email */
  email: Scalars['String']
  /** email consent */
  emailConsent?: InputMaybe<Scalars['Boolean']>
  /** first name */
  firstName?: InputMaybe<Scalars['String']>
  /** internal referrer */
  internalRef?: InputMaybe<Scalars['String']>
  /** job function */
  jobFunction?: InputMaybe<Scalars['String']>
  /** job title */
  jobTitle: Scalars['String']
  /** last name */
  lastName?: InputMaybe<Scalars['String']>
  /** linked in url */
  linkedinUrl?: InputMaybe<Scalars['String']>
  /** program name */
  programName?: InputMaybe<Scalars['String']>
  /** referral source */
  referralSource: Scalars['String']
  /** season */
  season?: InputMaybe<Scalars['String']>
  /** source */
  source?: InputMaybe<Scalars['String']>
  /** is user transitioning companies */
  transitioningCompanies?: InputMaybe<Scalars['Boolean']>
  /** utm campaign */
  utmCampaign?: InputMaybe<Scalars['String']>
  /** utm content */
  utmContent?: InputMaybe<Scalars['String']>
  /** utm medium */
  utmMedium?: InputMaybe<Scalars['String']>
  /** utm source */
  utmSource?: InputMaybe<Scalars['String']>
  /** utm term */
  utmTerm?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateProgramApplication */
export type CreateProgramApplicationPayload = {
  __typename: 'CreateProgramApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if returned from api */
  errors?: Maybe<Array<Scalars['String']>>
  /** id of program application */
  id?: Maybe<Scalars['Int']>
  /** token for authentication */
  token?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateProgramInvitation */
export type CreateProgramInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** is alumni */
  email: Scalars['String']
  /** first name */
  firstName?: InputMaybe<Scalars['String']>
  /** inviter first name */
  inviterFirstName?: InputMaybe<Scalars['String']>
  /** inviter last name */
  inviterLastName?: InputMaybe<Scalars['String']>
  /** last name */
  lastName?: InputMaybe<Scalars['String']>
  /** program name */
  programName: Scalars['String']
  /** why recommending description */
  whyWouldTheyBeAFit?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateProgramInvitation */
export type CreateProgramInvitationPayload = {
  __typename: 'CreateProgramInvitationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if returned from api */
  errors?: Maybe<Array<Scalars['String']>>
  /** id of program invitation */
  id?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of CreateRailsSubscription */
export type CreateRailsSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Id of the Stripe subscription */
  stripeSubscriptionId: Scalars['ID']
}

/** Autogenerated return type of CreateRailsSubscription */
export type CreateRailsSubscriptionPayload = {
  __typename: 'CreateRailsSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors that occurred during the subscription creation */
  errors?: Maybe<Array<Scalars['String']>>
  /** Whether the subscription creation was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of CreateRecommendations */
export type CreateRecommendationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Selected topics */
  topics: Array<ArtifactTopicInput>
}

/** Autogenerated return type of CreateRecommendations */
export type CreateRecommendationsPayload = {
  __typename: 'CreateRecommendationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors that occurred during the recommentadion creation */
  errors?: Maybe<Array<Scalars['String']>>
  /** Whether the recommendation creation was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of CreateReply */
export type CreateReplyInput = {
  /** Reply body */
  body: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post ID */
  id: Scalars['ID']
  /** Type of post associated with the reply */
  parentType: ReplyParent
  /** Should substitute newlines from body */
  substituteNewlines?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of CreateReply */
export type CreateReplyPayload = {
  __typename: 'CreateReplyPayload'
  /** The body of the created reply */
  body: Scalars['String']
  /** The body of the created reply */
  bodyText: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the created reply */
  id: Scalars['ID']
  /** The ID of the parent of the created reply */
  parentId: Scalars['ID']
  /** The avatar url for the created reply's user */
  userAvatar: Scalars['String']
  /** The user ID of the created reply */
  userId: Scalars['ID']
  /** The created reply's user name */
  userName?: Maybe<Scalars['String']>
  /** The created reply's user role with company */
  userRoleWithCompany?: Maybe<Scalars['String']>
}

/** Autogenerated input type of CreateSubscriptionJoinRequest */
export type CreateSubscriptionJoinRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the subscription */
  subscriptionId: Scalars['ID']
}

/** Autogenerated return type of CreateSubscriptionJoinRequest */
export type CreateSubscriptionJoinRequestPayload = {
  __typename: 'CreateSubscriptionJoinRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription join request */
  subscriptionJoinRequest?: Maybe<SubscriptionJoinRequest>
}

/** Autogenerated input type of CreateTeamPost */
export type CreateTeamPostInput = {
  /** Anchor for inline post */
  anchor?: InputMaybe<Scalars['String']>
  /** Based on for inline post */
  basedOn?: InputMaybe<Scalars['String']>
  /** Description of the post */
  body?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cms section ID for inline post */
  cmsSectionId?: InputMaybe<Scalars['Int']>
  /** Reference image url for inline post */
  referenceImageUrl?: InputMaybe<Scalars['String']>
  /** Sanity ID for inline post */
  sanityId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateTeamPost */
export type CreateTeamPostPayload = {
  __typename: 'CreateTeamPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the team post */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of CreateTestUsers */
export type CreateTestUsersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The number of cohort passes to create */
  cohortPassCount?: InputMaybe<Scalars['Int']>
  /** The name of the plan */
  planName: PlanName
  /** Whether to create content and activity for the test users */
  populateContentAndActivity?: InputMaybe<Scalars['Boolean']>
  /** Whether to create users that have completed onboarding */
  usersOnboarded?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of CreateTestUsers */
export type CreateTestUsersPayload = {
  __typename: 'CreateTestUsersPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors that occurred during the creation of test users */
  errors?: Maybe<Array<Scalars['String']>>
  /** The subscription */
  subscription?: Maybe<Subscription>
  /** The subscription members */
  subscriptionMembers?: Maybe<Array<SubscriptionMember>>
  /** The subscription owner */
  subscriptionOwner?: Maybe<User>
  /** Whether the creation of test users was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of CreateUserClipActivity */
export type CreateUserClipActivityInput = {
  /** The action taken on the clip */
  action: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The id of the clip */
  clipId: Scalars['ID']
}

/** Autogenerated return type of CreateUserClipActivity */
export type CreateUserClipActivityPayload = {
  __typename: 'CreateUserClipActivityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** ID of the user clip activity */
  id: Scalars['ID']
}

/** Autogenerated input type of CreateUserCmsActivity */
export type CreateUserCmsActivityInput = {
  /** The action taken on the content */
  action: Scalars['String']
  /** The static id of the course */
  cclStaticCourseId?: InputMaybe<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The content type of the activity */
  contentType?: InputMaybe<Scalars['String']>
  /** The id of the course */
  courseId?: InputMaybe<Scalars['ID']>
  /** The id of the course session */
  courseSessionId?: InputMaybe<Scalars['ID']>
  /** The action taken on the content */
  sanityId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateUserCmsActivity */
export type CreateUserCmsActivityPayload = {
  __typename: 'CreateUserCmsActivityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** ID of the user cms activity */
  id: Scalars['ID']
}

/** Autogenerated input type of CreateUserEventActivity */
export type CreateUserEventActivityInput = {
  /** Kind of the activity */
  activityType: UserEventActivity
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The id of the event */
  eventId: Scalars['ID']
}

/** Autogenerated return type of CreateUserEventActivity */
export type CreateUserEventActivityPayload = {
  __typename: 'CreateUserEventActivityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** ID of the user event activity */
  id: Scalars['ID']
}

/** Creator existing only on the Marketing Course coming from Sanity CMS */
export type Creator = {
  __typename: 'Creator'
  /**
   * Avatar photo
   * @deprecated This is null; use expert > avatarPhoto
   */
  avatarPhoto?: Maybe<Image>
  /** Expert */
  expert?: Maybe<ArtifactAuthor>
  /** ID of the creator */
  id: Scalars['ID']
  /**
   * Name of the creator
   * @deprecated This is null; use expert > name
   */
  name?: Maybe<Scalars['String']>
  /** Role of the creator */
  role?: Maybe<Scalars['String']>
}

/** Marketing CTA Type from Sanity CMS */
export type Cta = {
  __typename: 'Cta'
  /** CTA Link */
  ctaLink: Scalars['String']
  /** CTA Text */
  ctaText: Scalars['String']
}

/** Autogenerated input type of DeclineSubscriptionJoinRequest */
export type DeclineSubscriptionJoinRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the join request */
  id?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of DeclineSubscriptionJoinRequest */
export type DeclineSubscriptionJoinRequestPayload = {
  __typename: 'DeclineSubscriptionJoinRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error encountered while denying join request */
  error?: Maybe<Scalars['String']>
  /** Subscription join request */
  subscriptionJoinRequest: SubscriptionJoinRequest
  /** True if update was successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of DeleteBookmarkFolder */
export type DeleteBookmarkFolderInput = {
  /** True if any member of the subscription can delete the bookmark folder */
  allowAnyTeamMember?: InputMaybe<Scalars['Boolean']>
  /** The ID of the bookmark */
  bookmarkFolderId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Returns true if deleting is due to a goal being removed */
  isLearningGoal?: InputMaybe<Scalars['Boolean']>
  /**
   * If the deletion is because of a goal being removed, this \
   *                                           must be "team" or "personal."
   */
  learningGoalType?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of DeleteBookmarkFolder */
export type DeleteBookmarkFolderPayload = {
  __typename: 'DeleteBookmarkFolderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the bookmark folder that this bookmark folder was forked from */
  forkedFromId?: Maybe<Scalars['ID']>
  /** The ID of the bookmark folder */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteBookmark */
export type DeleteBookmarkInput = {
  /** The ID of the bookmark */
  bookmarkId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of DeleteBookmark */
export type DeleteBookmarkPayload = {
  __typename: 'DeleteBookmarkPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the bookmark */
  id?: Maybe<Scalars['ID']>
  /** Status of bookmark deletion */
  status?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DeleteCohortPass */
export type DeleteCohortPassInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The ID of the cohort pass */
  cohortPassId: Scalars['ID']
}

/** Autogenerated return type of DeleteCohortPass */
export type DeleteCohortPassPayload = {
  __typename: 'DeleteCohortPassPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the cohort pass */
  id?: Maybe<Scalars['ID']>
  /** The status of the deletion */
  status?: Maybe<Scalars['String']>
}

/** Autogenerated input type of DeleteCohortPost */
export type DeleteCohortPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post ID */
  id: Scalars['ID']
}

/** Autogenerated return type of DeleteCohortPost */
export type DeleteCohortPostPayload = {
  __typename: 'DeleteCohortPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the deleted post */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteCohortReply */
export type DeleteCohortReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cohort reply ID */
  id: Scalars['ID']
}

/** Autogenerated return type of DeleteCohortReply */
export type DeleteCohortReplyPayload = {
  __typename: 'DeleteCohortReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the deleted cohort reply */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteCustomerTaxId */
export type DeleteCustomerTaxIdInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Tax id */
  taxId: Scalars['String']
}

/** Autogenerated return type of DeleteCustomerTaxId */
export type DeleteCustomerTaxIdPayload = {
  __typename: 'DeleteCustomerTaxIdPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** True if the deletion was successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of DeleteEmail */
export type DeleteEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the email to be deleted */
  id: Scalars['ID']
}

/** Autogenerated return type of DeleteEmail */
export type DeleteEmailPayload = {
  __typename: 'DeleteEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of the deleted email */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteFiledBookmark */
export type DeleteFiledBookmarkInput = {
  /** ID of the bookmark folder */
  bookmarkFolderId?: InputMaybe<Scalars['String']>
  /** ID of the bookmark */
  bookmarkId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Whether the bookmark is in the default folder */
  defaultBookmarkFolder?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of DeleteFiledBookmark */
export type DeleteFiledBookmarkPayload = {
  __typename: 'DeleteFiledBookmarkPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The collection activity */
  collectionActivity?: Maybe<CollectionActivity>
  /** Whether the bookmark was destroyed as well */
  destroyedBookmark?: Maybe<Scalars['Boolean']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The filed bookmark */
  filedBookmark?: Maybe<FiledBookmark>
}

/** Autogenerated input type of DeletePaymentSource */
export type DeletePaymentSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of payment source to detach from user */
  id: Scalars['ID']
}

/** Autogenerated return type of DeletePaymentSource */
export type DeletePaymentSourcePayload = {
  __typename: 'DeletePaymentSourcePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** Payment source id that was detached */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteTeamPost */
export type DeleteTeamPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post ID */
  id: Scalars['ID']
}

/** Autogenerated return type of DeleteTeamPost */
export type DeleteTeamPostPayload = {
  __typename: 'DeleteTeamPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the deleted post */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of DeleteUserCompany */
export type DeleteUserCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The user company id. */
  id: Scalars['String']
}

/** Autogenerated return type of DeleteUserCompany */
export type DeleteUserCompanyPayload = {
  __typename: 'DeleteUserCompanyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated user (if successful). */
  user?: Maybe<User>
}

/** Duplicate Account */
export type DuplicateAccount = {
  __typename: 'DuplicateAccount'
  /** Account creation date */
  createdAt: Scalars['String']
  /** User Email */
  email: Scalars['String']
  /** User ID */
  id: Scalars['ID']
}

/** Autogenerated input type of EndSubscriptionTrialEarly */
export type EndSubscriptionTrialEarlyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of EndSubscriptionTrialEarly */
export type EndSubscriptionTrialEarlyPayload = {
  __typename: 'EndSubscriptionTrialEarlyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors that occurred in the process of ending the trial and charging the user */
  errors?: Maybe<Array<Scalars['String']>>
  /** Whether ending the trial was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Course data for team owners about team engagement */
export type EngagementReportCourse = {
  __typename: 'EngagementReportCourse'
  /** The ID of the program */
  id: Scalars['ID']
  /** The url for the course image */
  imageUrl: Scalars['String']
  /** The name of the program */
  name: Scalars['String']
  /** Data for users who have started the program */
  participants: Array<EngagementReportParticipant>
  /** The synopsis of the program */
  synopsis: Scalars['String']
}

/** Lesson data for reports on team engagement */
export type EngagementReportLesson = {
  __typename: 'EngagementReportLesson'
  /** Number of lessons that have been viewed by a team within the last 3 months */
  id: Scalars['ID']
  /** The last time the lesson was viewed by a team member */
  lastViewedAt?: Maybe<Scalars['String']>
  /** Number of programs that have been worked by members of a team in the last 3 months */
  name: Scalars['String']
  /** Number of items that have been saved by members of a team in the last 3 months */
  numViews: Scalars['Int']
  /** Number of live cohorts that members on a team have enrolled in */
  programName: Scalars['String']
}

/** Data for users who have started a program */
export type EngagementReportParticipant = {
  __typename: 'EngagementReportParticipant'
  /** The avatar URL of the user */
  avatarUrl: Scalars['String']
  /** The ID of the user */
  id: Scalars['ID']
}

/** Bookmark/Saved item data for reports on team engagement */
export type EngagementReportSavedItem = {
  __typename: 'EngagementReportSavedItem'
  /** The name of the section, lesson, project, or concept that was saved */
  cmsSectionName: Scalars['String']
  /** ID for the Bookmark record to save this content */
  id: Scalars['ID']
  /** When the item was last saved */
  updatedAt: Scalars['String']
}

/** Summary data for team owners about team engagement */
export type EngagementReportSummary = {
  __typename: 'EngagementReportSummary'
  /** Number of content items that have been viewed by a team within the past year */
  contentViewedCount: Scalars['Int']
  /** Number of courses that have been worked by members of a team within the past year */
  coursesWorkedOnCount: Scalars['Int']
  /** Number of items that have been saved by members of a team within the past year */
  insightsSavedCount: Scalars['Int']
  /** Number of live courses that members on a team have enrolled in */
  liveCourseEnrollmentCount: Scalars['Int']
  /** User is eligible for patronage (accepted) */
  recentLessons: Array<EngagementReportLesson>
  /** User is eligible for patronage (accepted) */
  recentSavedItems: Array<EngagementReportSavedItem>
  /** User is eligible for patronage (accepted) */
  topCourses: Array<EngagementReportCourse>
  /** User is eligible for patronage (accepted) */
  topLessons: Array<EngagementReportLesson>
}

/** Engagement data on each team member for team owners */
export type EngagementReportTeam = {
  __typename: 'EngagementReportTeam'
  /** Data on each team member */
  teamMembers: Array<EngagementReportTeamMember>
}

/** Engagement data on each team member for team owners */
export type EngagementReportTeamMember = {
  __typename: 'EngagementReportTeamMember'
  /** URL for the user's avatar image */
  avatarUrl: Scalars['String']
  /** List of content pieces that the team member has viewed */
  contentViewed: Array<Scalars['String']>
  /** Number of content pieces that members have viewed */
  contentViewedCount: Scalars['Int']
  /** List of courses that have been worked by members of a team in the past year */
  coursesWorkedOn: Array<Scalars['String']>
  /** Number of courses that have been worked by members of a team in the past year */
  coursesWorkedOnCount: Scalars['Int']
  /** Name of the course that the team member is currently enrolled in or their most recent course */
  currentCourseName: Scalars['String']
  /** Full name of the team member or their email if they do not have a name set */
  fullName: Scalars['String']
  /** User ID of the team member */
  id: Scalars['ID']
  /** List of items that have been saved by members of a team in the last 12 months */
  insightsSaved: Array<Scalars['String']>
  /** Number of insights saved */
  insightsSavedCount: Scalars['Int']
  /** List of live courses that members on a team have enrolled in */
  liveCoursesTaken: Array<Scalars['String']>
  /** Number of live courses that members on a team have enrolled in */
  liveCoursesTakenCount: Scalars['Int']
}

/** An event the members can attend. */
export type Event = {
  __typename: 'Event'
  /** Attendees connection */
  attendees: EventAttendeeConnection
  /** Attendees_count */
  attendeesCount?: Maybe<Scalars['Int']>
  /** Whether a user is attending an event or not */
  attendingStatus?: Maybe<Scalars['String']>
  /** Company name for case study events */
  caseCompany?: Maybe<Company>
  /** Cms Module */
  cmsModuleId?: Maybe<Scalars['ID']>
  /** CMS Program the event is for. */
  cmsProgram?: Maybe<CmsProgram>
  /** CMS Program ID the event is attached to. */
  cmsProgramId?: Maybe<Scalars['ID']>
  /** Cms section */
  cmsSectionId?: Maybe<Scalars['ID']>
  /** The cohorts the event is associated with */
  cohorts: Array<Cohort>
  /** Desc of event */
  description?: Maybe<Scalars['String']>
  /** Ends at */
  endsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Ends at date in UTC. */
  endsAtUtc: Scalars['String']
  /** Event format */
  eventFormat: Scalars['String']
  /** Event prework */
  eventPrework?: Maybe<EventPrework>
  /** Event prework submission */
  eventPreworkSubmission?: Maybe<EventPreworkSubmission>
  /** Featured guests of event */
  featuredGuests: Array<EventSpeaker>
  /** Featured image on event */
  featuredImageUrl?: Maybe<Scalars['String']>
  /** Google cal end at */
  googleCalendarEndsAt?: Maybe<Scalars['String']>
  /** Google cal start at */
  googleCalendarStartsAt?: Maybe<Scalars['String']>
  /** Hosts of event */
  hosts: Array<EventSpeaker>
  /** ID */
  id: Scalars['ID']
  /** Is current user a featured guest */
  isFeaturedGuest: Scalars['Boolean']
  /** True if event is publicly accessible. */
  isPublic?: Maybe<Scalars['Boolean']>
  /**
   * Kind of event:
   *                          ["Cohort Event", "Workshop", "Seminar", "Office Hours",
   *                           "Program Event", "Case Study", "Community"]
   */
  kind: Scalars['String']
  /** Last viewed relative time */
  lastViewedAt?: Maybe<Scalars['String']>
  /** Location of event */
  location?: Maybe<Scalars['String']>
  /** Event name. */
  name: Scalars['String']
  /** True if the event has ended. */
  past: Scalars['Boolean']
  /** Prep of event */
  prep: Scalars['String']
  /** Preread of event */
  preread?: Maybe<Scalars['String']>
  /** Recording url */
  recordingUrl?: Maybe<Scalars['String']>
  /** Current user attendee */
  rsvp?: Maybe<EventAttendee>
  /** Unique slug string for event. */
  slug: Scalars['String']
  /**
   * List of users that will be speaking
   *                                                 at an event.
   */
  speakers?: Maybe<Array<EventSpeaker>>
  /** Starts at */
  startsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Starts at date in UTC. */
  startsAtUtc: Scalars['String']
  /** Transcript */
  summaryTranscript?: Maybe<Scalars['String']>
  /**
   * Unique recording URL slug
   *                                                     if the recording is available.
   */
  summaryWistiaCode?: Maybe<Scalars['String']>
  /** Time until event string */
  timeUntil?: Maybe<Scalars['String']>
  /** Timezone of cu */
  timezone?: Maybe<Scalars['String']>
  /**
   * Unique UUID for event.
   *                                       TODO: Purpose for this and a unique slug?
   */
  uuid: Scalars['String']
  /** True if event will be recorded. */
  willBeRecorded: Scalars['Boolean']
  /** Zoom url */
  zoomLink?: Maybe<Scalars['String']>
}

/** An event the members can attend. */
export type EventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasAvatar?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
}

/** Records tracking what users are attending what events. */
export type EventAttendee = {
  __typename: 'EventAttendee'
  /** User avatar url */
  avatarUrl: Scalars['String']
  /** Event ID */
  eventId: Scalars['ID']
  /** Does user have badge */
  hasBadge: Scalars['Boolean']
  /** ID */
  id: Scalars['ID']
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** Attending status of the user: [Attending, Not Attending] */
  status: Scalars['String']
  /** User info for attendee */
  user: User
}

/** The connection type for EventAttendee. */
export type EventAttendeeConnection = {
  __typename: 'EventAttendeeConnection'
  /** A list of edges. */
  edges: Array<EventAttendeeEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  /** Total number of attendees */
  totalCount: Scalars['Int']
}

/** An edge in a connection. */
export type EventAttendeeEdge = {
  __typename: 'EventAttendeeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: EventAttendee
}

/** Event filters to apply during event queries. */
export type EventFilters = {
  /** Array of kinds to filter events by. */
  kind?: InputMaybe<Array<Scalars['String']>>
  /** An input value set by user to search events */
  userInputFilter?: InputMaybe<Scalars['String']>
}

/** Events coming from Sanity CMS */
export type EventPerCourseSession = {
  __typename: 'EventPerCourseSession'
  /** Course session for the event. */
  courseSession?: Maybe<CourseSession>
  /** When the event ends. */
  endsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Event format */
  eventFormat?: Maybe<Scalars['String']>
  /** Hosts for the event. */
  featuredGuests?: Maybe<Array<ArtifactAuthor>>
  /** Hosts for the event. */
  hosts?: Maybe<Array<ArtifactAuthor>>
  /** URL to join event. */
  joinLink?: Maybe<Scalars['String']>
  /** When the event starts. */
  startsAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Event Prework Type */
export type EventPrework = {
  __typename: 'EventPrework'
  /** Is application open */
  applicationOpen?: Maybe<Scalars['Boolean']>
  /** Featured guest count */
  featuredGuestSlots?: Maybe<Scalars['Int']>
  /** Helper text */
  helperText: Scalars['String']
  /** Id */
  id: Scalars['ID']
  /** Include template link */
  includeTemplateLink?: Maybe<Scalars['Boolean']>
  /** Instructions for event */
  instructions?: Maybe<Scalars['String']>
  /** Context */
  preworkContext?: Maybe<Scalars['String']>
  /** Submission description */
  submissionDescription?: Maybe<Scalars['String']>
  /** Prompt for submission */
  submissionPrompt?: Maybe<Scalars['String']>
  /** Submission title */
  submissionTitle?: Maybe<Scalars['String']>
  /** Template link cta */
  templateLinkCTA?: Maybe<Scalars['String']>
  /** Link url */
  templateLinkUrl?: Maybe<Scalars['String']>
}

/** Event Prework Submission Type */
export type EventPreworkSubmission = {
  __typename: 'EventPreworkSubmission'
  /** Status of prework submission */
  status?: Maybe<Scalars['String']>
}

/** Related resources for an event. */
export type EventRelatedResources = Artifact | CclCourse | MarketingCourse

/** User that will be speaking at an event. The user properties pass through to the actual user record. */
export type EventSpeaker = {
  __typename: 'EventSpeaker'
  /** Id of event */
  eventId: Scalars['ID']
  /** Number of events where speaker is a Host */
  hostedEventsCount: Scalars['Int']
  /** ID */
  id: Scalars['ID']
  /** Kind of the speaker: [Featured Guest, Host] */
  kind?: Maybe<Scalars['String']>
  /** The user who is speaking. */
  user: User
}

/** Past events feed. */
export type EventsPastFeed = {
  __typename: 'EventsPastFeed'
  /** Total length of the events list queried. */
  count?: Maybe<Scalars['Int']>
  /** Events. */
  events?: Maybe<Array<Event>>
}

/** Biographical profile data for a User who is an Expert. */
export type ExpertUserProfile = {
  __typename: 'ExpertUserProfile'
  /** About me */
  aboutMeText?: Maybe<Scalars['String']>
  /** All blog post by expert */
  allBlogPosts: Array<CclBlogPost>
  /** All courses by expert */
  allCourses: Array<CclCourse>
  /** All guides by expert */
  allGuides: Array<CclGuide>
  /** a list of hosted or featured guest events */
  allHostedEvents: Array<Event>
  /** Avatar URL */
  avatarUrl: Scalars['String']
  /**
   * Information about whether a user should be granted access to content,
   *                                     pages, and functionalities.
   */
  can: UserCan
  /** Name of the company */
  companyName?: Maybe<Scalars['String']>
  /** Creator cta button text */
  ctaText?: Maybe<Scalars['String']>
  /** Creator cta button link */
  ctaUrl?: Maybe<Scalars['String']>
  /** User external id */
  externalId?: Maybe<Scalars['String']>
  /** First name of user */
  firstName?: Maybe<Scalars['String']>
  /** Full name of user */
  fullName?: Maybe<Scalars['String']>
  /** Has badge of user */
  hasBadge: Scalars['Boolean']
  /** The unique ID of a user. */
  id: Scalars['ID']
  /** Information about the type of user. */
  is: UserIs
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** Last name of user */
  lastName?: Maybe<Scalars['String']>
  /** User linkedin account name */
  linkedin?: Maybe<Scalars['String']>
  /** User location */
  location?: Maybe<Scalars['String']>
  /** previous companies */
  previousCompanies: Array<UserCompany>
  /** User role */
  role?: Maybe<Scalars['String']>
  /** User twitter account name */
  twitter?: Maybe<Scalars['String']>
  /** list of upcoming events hosted or featured guest */
  upcomingEvents: Array<Event>
  /** Creator courses */
  upcomingLiveCourses: Array<CclCourse>
}

/** Marketing Experts Index from Sanity CMS */
export type ExpertsIndex = {
  __typename: 'ExpertsIndex'
  /** List of artifact authors in the collective */
  experts: Array<ArtifactAuthor>
  /** Page */
  page: Page
}

/** Frequently asked questions tied to a single season. */
export type Faq = {
  __typename: 'Faq'
  /** Answer to the frequently asked question. */
  answer: Scalars['String']
  /** The freqently asked question. */
  question: Scalars['String']
  /** Order number for question to appear */
  questionNumber: Scalars['Int']
  /** ID of season associated with the FAQ */
  seasonId: Scalars['ID']
}

/** Featured post */
export type FeaturedPost = {
  __typename: 'FeaturedPost'
  /** ID */
  id: Scalars['ID']
  /** Kind of the featured post, which feed, eg. this_week */
  kind: Scalars['String']
  /** The featured post */
  post: Post
  /** The start date of the featured post */
  startsAt: Scalars['ISO8601DateTime']
}

/** Autogenerated input type of Feedbacks */
export type FeedbacksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of cms program */
  cmsProgramId: Scalars['ID']
  /** ID of cms section */
  cmsSectionId: Scalars['ID']
  /** Feedbacks quant */
  quant: Scalars['Int']
}

/** Autogenerated return type of Feedbacks */
export type FeedbacksPayload = {
  __typename: 'FeedbacksPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the feedback */
  feedbackId?: Maybe<Scalars['ID']>
}

/** Folder for bookmarks a user has made */
export type FiledBookmark = {
  __typename: 'FiledBookmark'
  /** ID of the bookmark */
  bookmark: Bookmark
  /** ID of the bookmark folder */
  bookmarkFolderId: Scalars['ID']
  /** ID of the bookmark */
  bookmarkId: Scalars['ID']
  /** ID */
  id: Scalars['ID']
  /** The order in the collection */
  position: Scalars['Int']
}

/** Filtered artifacts query result */
export type FilteredArtifacts = {
  __typename: 'FilteredArtifacts'
  /** A list of Artifacts from Sanity */
  artifacts: Array<Artifact>
  /** Count of Artifacts as next to currently shown */
  nextArtifactsCount: Scalars['Int']
  /** Count of Artifacts as previous to currently shown */
  prevArtifactsCount: Scalars['Int']
  /** Total count of Artifacts from query */
  totalArtifactsCount: Scalars['Int']
}

/** Filtered user search */
export type FilteredUserSearch = {
  __typename: 'FilteredUserSearch'
  /** Avatar URL */
  avatarUrl: Scalars['String']
  /** Name of the company */
  companyAudience?: Maybe<Scalars['String']>
  /** Name of the company */
  companyName?: Maybe<Scalars['String']>
  /** Created at */
  createdAt?: Maybe<Scalars['String']>
  /** Full name */
  fullName?: Maybe<Scalars['String']>
  /** Has badge of user */
  hasBadge: Scalars['Boolean']
  /** ID */
  id: Scalars['ID']
  /** User is expired */
  isExpired: Scalars['Boolean']
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** User location */
  location?: Maybe<Scalars['String']>
  /** Name of the company */
  mostRecentProgram?: Maybe<Scalars['String']>
  /** User role */
  role?: Maybe<Scalars['String']>
  /** Slug of user */
  slug?: Maybe<Scalars['String']>
  /** Whether the user profile is private or public */
  status: UserStatus
}

/** Filtered user search list */
export type FilteredUserSearchList = {
  __typename: 'FilteredUserSearchList'
  /** Filtered users list */
  members: Array<FilteredUserSearch>
  /** Pagination data for list */
  pagination: Pagination
}

/** Autogenerated input type of FlagArtifactComment */
export type FlagArtifactCommentInput = {
  /** Artifact ID */
  artifactCommentId: Scalars['String']
  /** Artifact Title */
  artifactTitle: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of FlagArtifactComment */
export type FlagArtifactCommentPayload = {
  __typename: 'FlagArtifactCommentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of ForkBookmarkFolder */
export type ForkBookmarkFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Id of bookmark folder to fork */
  forkedFromId: Scalars['ID']
  /** Returns true if forking is due to a goal being set */
  isLearningGoal?: InputMaybe<Scalars['Boolean']>
  /**
   * If the fork is because of a goal being set, this \
   *                                           must be "team" or "personal."
   */
  learningGoalType?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ForkBookmarkFolder */
export type ForkBookmarkFolderPayload = {
  __typename: 'ForkBookmarkFolderPayload'
  /** The forked folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Newly created bookmarks from forking a folder */
  newForkedBookmarks?: Maybe<Array<Bookmark>>
}

/** Forum Contribution Type */
export type ForumContribution = Comment | Post | Reply

/** Function tags coming from Sanity CMS */
export type Function = {
  __typename: 'Function'
  /** ID of the Function */
  id: Scalars['String']
  /** Whether this function is deprecated */
  isDeprecated?: Maybe<Scalars['Boolean']>
  /** URL to redirect to if isDeprecated */
  redirectTo?: Maybe<Scalars['String']>
  /** Slug for the Function. */
  slug: Scalars['String']
  /** Sort order of the function. */
  sortOrder?: Maybe<Scalars['Int']>
  /** Title of the Function. */
  title: Scalars['String']
}

/** Autogenerated input type of GenerateAiGoals */
export type GenerateAiGoalsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Session generated data */
  prompt?: InputMaybe<Scalars['String']>
  /** Existing session id */
  sessionId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of GenerateAiGoals */
export type GenerateAiGoalsPayload = {
  __typename: 'GenerateAiGoalsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Updated Session */
  coachingSession?: Maybe<CoachingSession>
  /** Indicates if data was successfully generated */
  success: Scalars['Boolean']
}

/** Autogenerated input type of GenerateAiInference */
export type GenerateAiInferenceInput = {
  /** The action type */
  actionType: InferableAction
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The path to the content */
  path: Scalars['String']
}

/** Autogenerated return type of GenerateAiInference */
export type GenerateAiInferencePayload = {
  __typename: 'GenerateAiInferencePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Indicates if the job was enqueued */
  success: Scalars['Boolean']
}

/** Content blocks for units coming from Sanity CMS */
export type GettingStartedBlock = {
  __typename: 'GettingStartedBlock'
  /** Description for the content block. */
  description?: Maybe<Scalars['JSON']>
  /** Content block collapsible for the content block. */
  gettingStartedCollapsible?: Maybe<Array<GettingStartedCollapsible>>
  /** ID of the tag. */
  id?: Maybe<Scalars['String']>
}

/** Getting started collapsible */
export type GettingStartedCollapsible = {
  __typename: 'GettingStartedCollapsible'
  /** Description for the content block collapsible. */
  description?: Maybe<Scalars['JSON']>
  /** ID of the tag. */
  id?: Maybe<Scalars['String']>
  /** Type for the content block. */
  type?: Maybe<Scalars['String']>
}

/** Group data */
export type Group = {
  __typename: 'Group'
  /** Group category */
  groupCategory?: Maybe<GroupCategory>
  /** ID */
  id: Scalars['ID']
  /** Industries of group */
  industries: Array<Industry>
  /** Member stack of group */
  memberStack: Array<User>
  /** Count of group users */
  membersCount: Scalars['Int']
  /** Populated industries of group */
  populatedIndustries: Array<Industry>
  /** Populated topics of group */
  populatedTopics: Array<PostTopic>
  /** Slug of group */
  slug: Scalars['String']
  /** Title of group */
  title: Scalars['String']
  /** Topics of group */
  topics: Array<PostTopic>
  /** True if current user is a member of the group */
  userIsMember: Scalars['Boolean']
}

/** Group category data */
export type GroupCategory = {
  __typename: 'GroupCategory'
  /** Name of the group category */
  category?: Maybe<Scalars['String']>
  /** Description of the group category */
  description?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
}

/** The connection type for Group. */
export type GroupConnection = {
  __typename: 'GroupConnection'
  /** A list of edges. */
  edges: Array<GroupEdge>
  /** A list of nodes. */
  nodes: Array<Group>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type GroupEdge = {
  __typename: 'GroupEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Group
}

/** Groups Feed */
export type GroupFeed = {
  __typename: 'GroupFeed'
  /** Cache key of group */
  cacheKey?: Maybe<Scalars['String']>
  /** Groups */
  groups: Array<Group>
}

/** Members in a group */
export type GroupMemberList = {
  __typename: 'GroupMemberList'
  /** members in list */
  members: Array<FilteredUserSearch>
  /** Pagination data */
  pagination: Pagination
}

/** Group post feed */
export type GroupPostFeed = {
  __typename: 'GroupPostFeed'
  /** Group posts */
  posts: Array<Post>
  /** Array of unread post ids */
  unreadPostIds: Array<Scalars['Int']>
}

/** Data of dashboard home page */
export type HomeDashboard = {
  __typename: 'HomeDashboard'
  /** Classic content */
  classicContent: Array<CmsSection>
  /** Concept bookmarks */
  conceptBookmarks: Array<Bookmark>
  /** Concepts */
  concepts: Array<Content>
  /** events */
  events: Array<Event>
  /** Previous companies */
  previousCompanies: Array<UserCompany>
  /** Program bookmarks */
  programBookmarks: Array<Bookmark>
  /** Programs */
  programs: Array<CmsProgram>
  /** Project bookmarks */
  projectBookmarks: Array<Bookmark>
  /** Projects */
  projects: Array<Content>
  /** Redirect URL */
  redirectPath?: Maybe<Scalars['String']>
}

/** Marketing Homepage from Sanity CMS */
export type Homepage = {
  __typename: 'Homepage'
  /** Content */
  content: HomepageContent
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** A collection representing the state of homepage checklists for a user */
export type HomepageCheck = {
  __typename: 'HomepageCheck'
  /** Has completed one search */
  completedAskAQuestionWithSearch?: Maybe<Scalars['Boolean']>
  /** Has viewed a program overview */
  completedChooseAProgram?: Maybe<Scalars['Boolean']>
  /** Completed a lesson, etc */
  completedCompleteALesson?: Maybe<Scalars['Boolean']>
  /** Has saved a piece of content */
  completedSaveInsightsForLater?: Maybe<Scalars['Boolean']>
  /** Has opened a Reforge collection */
  completedSetYourFirstGoal?: Maybe<Scalars['Boolean']>
  /** Name of current checklist */
  currentChecklist?: Maybe<Scalars['String']>
  /** Hide checklists on homepage */
  hideChecklists?: Maybe<Scalars['Boolean']>
}

/** Marketing Homepage Content from Sanity CMS */
export type HomepageContent = {
  __typename: 'HomepageContent'
  /** AI CTA */
  aiCta: Cta
  /** AI CTA */
  aiExtensionCta: Cta
  /** Subtitle for the ai section */
  aiSubtitle: Scalars['String']
  /** Title for the ai section */
  aiTitle: Scalars['String']
  /** Subtitle for the artifacts / guides section */
  artifactsAndGuidesSubtitle: Scalars['String']
  /** Title for the artifacts / guides section */
  artifactsAndGuidesTitle: Scalars['String']
  /** Copy about the benefits of artifacts */
  artifactsBenefits: Array<Scalars['String']>
  /** Artifacts CTA */
  artifactsCta: Cta
  /** Bottom CTA */
  bottomCta: Cta
  /** Bottom Section Title */
  bottomTitle: Scalars['String']
  /**
   * Courses
   * @deprecated No longer displayed
   */
  courses: Array<HomepageCourse>
  /**
   * Courses CTA
   * @deprecated No longer displayed
   */
  coursesCta: Cta
  /**
   * Courses Subtitle
   * @deprecated No longer displayed
   */
  coursesSubtitle: Scalars['String']
  /**
   * Courses Title
   * @deprecated No longer displayed
   */
  coursesTitle: Scalars['String']
  /** Extension apps */
  extensionApps: Array<AiExtensionApp>
  /** Extension card subtitle */
  extensionSubtitle: Scalars['String']
  /** Extension card title */
  extensionTitle: Scalars['String']
  /** Copy about the benefits of artifacts */
  guidesBenefits: Array<Scalars['String']>
  /** Guides CTA */
  guidesCta: Cta
  /** Logos */
  logos?: Maybe<Array<Image>>
  /** Main CTA */
  mainCta: Cta
  /** Course Benefits 1 */
  membershipBenefits1: Array<Scalars['String']>
  /** Course Benefits 1 CTA */
  membershipBenefits1Cta: Cta
  /** Course Benefits 2 */
  membershipBenefits2: Array<Scalars['String']>
  /** Course Benefits 2 CTA */
  membershipBenefits2Cta?: Maybe<Cta>
  /** Membership Title */
  membershipSubtitle: Scalars['String']
  /** Membership Title */
  membershipTitle: Scalars['String']
  /**
   * App Reviews
   * @deprecated No longer displayed
   */
  reviews: Array<Scalars['String']>
  /** Secondary CTA */
  secondaryCta: Cta
  /** Homepage Subtitle */
  subtitle: Scalars['String']
  /** Teams Benefits */
  teamsBenefits: Array<HomepageTeamsBenefit>
  /** Teams Benefits Image */
  teamsBenefitsImage: Image
  /** Teams CTA */
  teamsCta: Cta
  /** Teams Subtitle */
  teamsSubtitle: Scalars['String']
  /** Teams Title */
  teamsTitle: Scalars['String']
  /** Testimonials */
  testimonials: Array<TestimonialWithImage>
  /** Homepage Title */
  title: Scalars['String']
}

/** Homepage Course from Sanity CMS */
export type HomepageCourse = {
  __typename: 'HomepageCourse'
  /** Marketing Course */
  course?: Maybe<SanityCourse>
  /** Course Type */
  courseType: Scalars['String']
  /** Course Creators */
  creators?: Maybe<Array<Creator>>
  /** Preview image */
  homepageCoursePreviewImage?: Maybe<Image>
  /** Course Hosts */
  hosts?: Maybe<Array<HomepageCourseHost>>
  /** ID */
  id: Scalars['ID']
  /** Preview Image */
  previewImage?: Maybe<Image>
  /** Slug */
  slug?: Maybe<Scalars['String']>
  /** Subtitle */
  subtitle?: Maybe<Scalars['String']>
  /** Title */
  title?: Maybe<Scalars['String']>
}

/** Homepage Course Host from Sanity CMS */
export type HomepageCourseHost = {
  __typename: 'HomepageCourseHost'
  /** Host name */
  hostName: Scalars['String']
  /** ID */
  id: Scalars['ID']
}

/** Object which may be either an Artifact or a Guide (Unit) or a Lesson (CmsSection) */
export type HomepageRecentlyViewedItem = Artifact | Content | Unit

/** Homepage Teams Benefit from Sanity CMS */
export type HomepageTeamsBenefit = {
  __typename: 'HomepageTeamsBenefit'
  /** Description */
  description: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Title */
  title: Scalars['String']
}

/** A user that runs or "hosts" an event */
export type Host = {
  __typename: 'Host'
  /** The name of the host and a description of their role/experience. */
  bio?: Maybe<Scalars['String']>
  /** User current company name */
  companyName?: Maybe<Scalars['String']>
  /** The role/experience of the host. */
  description?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** Name of the host */
  name?: Maybe<Scalars['String']>
  /** Previous company names the host worked for. */
  previousCompanies?: Maybe<Scalars['String']>
  /** User role */
  role?: Maybe<Scalars['String']>
  /** Whether the user profile is private or public */
  status: UserStatus
  /** URL to the host avatar. */
  thumbnailUrl: Scalars['String']
  /** Job title or position */
  title?: Maybe<Scalars['String']>
  /** User ID of the host */
  userId?: Maybe<Scalars['ID']>
}

/** Image Type from Sanity CMS */
export type Image = {
  __typename: 'Image'
  /** Aspect Ratio */
  aspectRatio?: Maybe<Scalars['Float']>
  /** Alt Text */
  imageAlt?: Maybe<Scalars['String']>
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']>
}

/** Industry */
export type Industry = {
  __typename: 'Industry'
  /** ID */
  id: Scalars['ID']
  /** Name of industry */
  name?: Maybe<Scalars['String']>
  /** Slug of industry */
  slug?: Maybe<Scalars['String']>
}

/** An action that can be inferred about by the AI */
export enum InferableAction {
  /** User views content */
  CONTENT_VIEW = 'CONTENT_VIEW'
}

/** Inline post */
export type InlinePost = {
  __typename: 'InlinePost'
  /** Cms Section content anchor */
  anchor: Scalars['String']
  /** Based on for inline post */
  basedOn?: Maybe<Scalars['String']>
  /** Html content of the post */
  body: Scalars['String']
  /** String content of the post */
  bodyText: Scalars['String']
  /** Created at date in human readable format e.g. 12 Jan 2023 */
  createdAtDate?: Maybe<Scalars['String']>
  /** Post groups */
  groups: Array<Group>
  /** Postable ID */
  id: Scalars['ID']
  /** Post Reactions */
  reactions: Array<Reaction>
  /** Reference image url */
  referenceImageUrl?: Maybe<Scalars['String']>
  /** Post Replies */
  replies: Array<InlinePostReply>
  /** Post title */
  title: Scalars['String']
  /** Post topics */
  topics: Array<PostTopic>
  /** Postable Type */
  type: Scalars['String']
  /** Post user avatar */
  userAvatar: Scalars['String']
  /** Post user full name */
  userFullName?: Maybe<Scalars['String']>
  /** Id of the post user */
  userId: Scalars['ID']
  /** Post user role with company */
  userRoleWithCompany?: Maybe<Scalars['String']>
}

/** Inline post reply */
export type InlinePostReply = {
  __typename: 'InlinePostReply'
  /** Html content of the post */
  body: Scalars['String']
  /** String content of the post */
  bodyText: Scalars['String']
  /** Reply comments */
  comments: Array<Comment>
  /** Postable ID */
  id: Scalars['ID']
  /** ID of the associated post or team post */
  replyableId: Scalars['ID']
  /** Reply user avatar */
  userAvatar: Scalars['String']
  /** Reply user full name */
  userFullName?: Maybe<Scalars['String']>
  /** Id of the reply user */
  userId: Scalars['ID']
  /** Reply user role with company */
  userRoleWithCompany?: Maybe<Scalars['String']>
}

/** Invoices */
export type Invoice = {
  __typename: 'Invoice'
  /** Billing information */
  billingInformation?: Maybe<BillingInformation>
  /** Internal PO number */
  companyAddress?: Maybe<Scalars['String']>
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Extra information */
  extraInformation?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** Internal PO number */
  internalPoNumber?: Maybe<Scalars['String']>
  /** Invoice members */
  members: Array<InvoiceMember>
  /** Payment record */
  paymentRecord: PaymentRecord
  /** Stripe invoice */
  stripeInvoice: StripeInvoice
}

/** Invoice member */
export type InvoiceMember = {
  __typename: 'InvoiceMember'
  /** Email */
  email: Scalars['String']
  /** Full name */
  fullName: Scalars['String']
  /** ID */
  id: Scalars['ID']
}

/** Autogenerated input type of JoinCourseWaitlist */
export type JoinCourseWaitlistInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Course ID */
  courseId?: InputMaybe<Scalars['ID']>
  /** Course Session ID */
  courseSessionId?: InputMaybe<Scalars['ID']>
  /** Course title */
  courseTitle?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of JoinCourseWaitlist */
export type JoinCourseWaitlistPayload = {
  __typename: 'JoinCourseWaitlistPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of JoinPublicCourseWaitlist */
export type JoinPublicCourseWaitlistInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Course ID */
  courseId?: InputMaybe<Scalars['ID']>
  /** Course Session ID */
  courseSessionId?: InputMaybe<Scalars['ID']>
  /** Course title */
  courseTitle?: InputMaybe<Scalars['String']>
  /** Email to subscribe if user is not known */
  email: Scalars['String']
  /** First name of subscriber */
  firstName?: InputMaybe<Scalars['String']>
  /** Last name of subscriber */
  lastName?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of JoinPublicCourseWaitlist */
export type JoinPublicCourseWaitlistPayload = {
  __typename: 'JoinPublicCourseWaitlistPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** The table of lessons for the content in the viewer */
export type LessonViewer = {
  __typename: 'LessonViewer'
  /** Cms module */
  cmsModule?: Maybe<CmsModule>
  /** Cms program */
  cmsProgram?: Maybe<CmsProgram>
  /** Cms section */
  cmsSection?: Maybe<CmsSection>
  /** Expert users */
  expertUsers?: Maybe<Array<User>>
  /** Guest end module enabled */
  guestEndModuleEnabled?: Maybe<Scalars['Boolean']>
  /** User cohort */
  sameActiveUserCohort?: Maybe<UserCohort>
  /** User program */
  userProgram?: Maybe<UserProgram>
  /** current user's level of access to content */
  viewAccess: Scalars['String']
}

/** Login parameters */
export type LoginParamsInput = {
  /** Slug for the CMS Program */
  cmsProgramSlug?: InputMaybe<Scalars['String']>
  /** Type of the course */
  courseType?: InputMaybe<Scalars['String']>
  /** Event ID */
  eventId?: InputMaybe<Scalars['String']>
  /** Navigation began with event RSVP */
  forEventRSVP?: InputMaybe<Scalars['Boolean']>
  /** Forward URL */
  fwd?: InputMaybe<Scalars['String']>
  /** Event is in session */
  happening?: InputMaybe<Scalars['Boolean']>
  /** Navigation originated within Marketing site */
  marketingNav?: InputMaybe<Scalars['Boolean']>
  /** Membership token */
  mtoken?: InputMaybe<Scalars['String']>
  /** Referrer URL */
  referer?: InputMaybe<Scalars['String']>
  /** Free product login */
  reforgeLogin?: InputMaybe<Scalars['Boolean']>
  /** User token */
  token?: InputMaybe<Scalars['String']>
  /** User agreed to TOS */
  tos?: InputMaybe<Scalars['String']>
  /** User timezone */
  userTimezone?: InputMaybe<Scalars['String']>
}

/** Autogenerated input type of MakeEmailPrimary */
export type MakeEmailPrimaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the email to be set as primary */
  id: Scalars['ID']
}

/** Autogenerated return type of MakeEmailPrimary */
export type MakeEmailPrimaryPayload = {
  __typename: 'MakeEmailPrimaryPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of the primary email */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of MarkActivitiesAsRead */
export type MarkActivitiesAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** IDs of activities to mark as read */
  ids: Array<Scalars['ID']>
}

/** Autogenerated return type of MarkActivitiesAsRead */
export type MarkActivitiesAsReadPayload = {
  __typename: 'MarkActivitiesAsReadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error message */
  message?: Maybe<Scalars['String']>
  /** Whether the update was successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of MarkAllActivitiesAsRead */
export type MarkAllActivitiesAsReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of MarkAllActivitiesAsRead */
export type MarkAllActivitiesAsReadPayload = {
  __typename: 'MarkAllActivitiesAsReadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Whether the update was successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of MarkArtifactAsViewed */
export type MarkArtifactAsViewedInput = {
  /** Artifact ID from Sanity */
  artifactId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of MarkArtifactAsViewed */
export type MarkArtifactAsViewedPayload = {
  __typename: 'MarkArtifactAsViewedPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Upserted ViewedArtifact */
  viewedArtifact?: Maybe<ViewedArtifact>
}

/** MarketingCourse coming from Sanity CMS */
export type MarketingCourse = {
  __typename: 'MarketingCourse'
  /** Background image */
  backgroundImage?: Maybe<Image>
  /** Bookmark ID for LEGACY courses (CmsPrograms) */
  bookmarkId?: Maybe<Scalars['ID']>
  /** CclCourse associated with if any */
  cclCourse?: Maybe<CclCourse>
  /** CclTopic associated with if any */
  cclTopics?: Maybe<Array<CclTopic>>
  /**
   * cms_program for the course for courseType
   *                                                                 legacy if linked by cmsProgramSlug
   */
  cmsProgram?: Maybe<CmsProgram>
  /** cms_program slug for the course for courseType legacy */
  cmsProgramSlug?: Maybe<Scalars['String']>
  /** Course directly from Sanity */
  course?: Maybe<SanityCourse>
  /** Course FAQ */
  courseFaq?: Maybe<Array<MarketingFaq>>
  /** Course Info Cards */
  courseInfoCards?: Maybe<Array<CourseInfoCard>>
  /** Is the course preview available? */
  coursePreviewAvailable?: Maybe<Scalars['Boolean']>
  /** course type */
  courseType?: Maybe<Scalars['String']>
  /** Creators of the Course */
  creators?: Maybe<Array<Creator>>
  /** Duration of the course */
  duration?: Maybe<Scalars['String']>
  /** Subtitle of the host */
  hostSubtitle?: Maybe<Scalars['String']>
  /** Hosts */
  hosts?: Maybe<Array<SanityHost>>
  /** ID */
  id: Scalars['ID']
  /** Is Live */
  isLive?: Maybe<Scalars['Boolean']>
  /** Legacy Program ID */
  legacyProgramId?: Maybe<Scalars['ID']>
  /** Preview image on index page */
  previewImage?: Maybe<Image>
  /** Price of the course */
  price?: Maybe<Scalars['String']>
  /** The url slug for the course */
  slug?: Maybe<Scalars['String']>
  /** When the course starts */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Subtitle of the course */
  subtitle?: Maybe<Scalars['String']>
  /** Title of the course */
  title?: Maybe<Scalars['String']>
}

/** FAQ coming from Sanity CMS */
export type MarketingFaq = {
  __typename: 'MarketingFaq'
  /** Answer */
  answer: Scalars['JSON']
  /** ID */
  id: Scalars['ID']
  /** Question */
  question: Scalars['String']
}

/** Status of a user completing a module and overall program progress percent */
export type ModuleCompletionStatus = {
  __typename: 'ModuleCompletionStatus'
  /** Whether the module is viewed in full (completed) */
  isComplete: Scalars['Boolean']
  /** Completed percentage of the entire program */
  programPercentComplete: Scalars['Int']
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type Mutation = {
  __typename: 'Mutation'
  /** Activate subscription */
  activateSubscription?: Maybe<ActivateSubscriptionPayload>
  /** Add annotation reaction */
  addAnnotationReaction?: Maybe<AddAnnotationReactionPayload>
  /** Add artifact comment */
  addArtifactComment?: Maybe<AddArtifactCommentPayload>
  /** Add artifact comment reaction */
  addArtifactCommentReaction?: Maybe<AddArtifactCommentReactionPayload>
  /** Add an email to a user so they can be added to a team. */
  addEmailToUser?: Maybe<AddEmailToUserPayload>
  /** Add reaction to a post or comment or reply */
  addReaction?: Maybe<AddReactionPayload>
  /** Add company to user. */
  addUserCompany?: Maybe<AddUserCompanyPayload>
  /** Approve subscription join request */
  approveSubscriptionJoinRequest?: Maybe<ApproveSubscriptionJoinRequestPayload>
  /** Assign cohort access to a seat on a subscription */
  assignCohortCredit?: Maybe<AssignCohortCreditPayload>
  /** Assign user to a subscription seat */
  assignSeat?: Maybe<AssignSeatPayload>
  /** batch rsvp events */
  batchRsvp?: Maybe<BatchPayload>
  /** cancels rsvp for user to event */
  cancelRsvp?: Maybe<CancelPayload>
  /** Cancels a user subscription */
  cancelSubscription?: Maybe<SubscriptionCancelPayload>
  /** Save the subscription cancelation reason */
  cancelSubscriptionMotivation?: Maybe<SubscriptionCancelMotivationPayload>
  /** Checkout for an immediate purchase with stripe */
  checkout?: Maybe<CheckoutPayload>
  /** Select plan to change to on renewal date */
  choosePlan?: Maybe<ChoosePlanPayload>
  /** Create a program expert */
  cmsProgramExperts?: Maybe<CmsProgramExpertsPayload>
  /** Marks a product tour as completed */
  completeProductTour?: Maybe<CompleteProductTourPayload>
  /** Confirm merging user account */
  confirmUserMerge?: Maybe<ConfirmUserMergePayload>
  /** Called from the Create Account Page. Used to do the necessary updates before taking the user to the free homepage. */
  createAccount?: Maybe<CreateAccountPayload>
  /** Creates a bookmark for user */
  createBookmark?: Maybe<CreateBookmarkPayload>
  /** Creates a bookmarks folder */
  createBookmarkFolder?: Maybe<CreateBookmarkFolderPayload>
  /** create cohort post */
  createCohortPost?: Maybe<CreateCohortPostPayload>
  /** create cohort reply for a cohort post */
  createCohortReply?: Maybe<CreateCohortReplyPayload>
  /** create course enrollment as a logged in member */
  createCourseEnrollment?: Maybe<CreateCourseEnrollmentPayload>
  /** create course session */
  createCourseSession?: Maybe<CreateCourseSessionPayload>
  /** create Slack channel for course session */
  createCourseSessionSlackChannel?: Maybe<CreateCourseSessionSlackChannelPayload>
  /** Create a draft subscription join request for the current user */
  createDraftSubscriptionJoinRequest?: Maybe<CreateDraftSubscriptionJoinRequestPayload>
  /** Creates a filed bookmark */
  createFiledBookmark?: Maybe<CreateFiledBookmarkPayload>
  /** create program application */
  createProgramApplication?: Maybe<CreateProgramApplicationPayload>
  /** create program invitation */
  createProgramInvitation?: Maybe<CreateProgramInvitationPayload>
  /** Create a rails subscription after payment that requires user action */
  createRailsSubscription?: Maybe<CreateRailsSubscriptionPayload>
  /** Create Artifact recommendations based on user chosen topics */
  createRecommendations?: Maybe<CreateRecommendationsPayload>
  /** Create a reply for a post */
  createReply?: Maybe<CreateReplyPayload>
  /** Create a subscription join request for the current user */
  createSubscriptionJoinRequest?: Maybe<CreateSubscriptionJoinRequestPayload>
  /** create team post */
  createTeamPost?: Maybe<CreateTeamPostPayload>
  /** Create a test user for development and staging */
  createTestUsers?: Maybe<CreateTestUsersPayload>
  /** Create ClipActivity record for a user */
  createUserClipActivity?: Maybe<CreateUserClipActivityPayload>
  /** Create a UserCmsActivity record */
  createUserCmsActivity?: Maybe<CreateUserCmsActivityPayload>
  /** Create EventActivity record for a user */
  createUserEventActivity?: Maybe<CreateUserEventActivityPayload>
  /** Decline subscription join request */
  declineSubscriptionJoinRequest?: Maybe<DeclineSubscriptionJoinRequestPayload>
  /** Deletes a bookmark for user */
  deleteBookmark?: Maybe<DeleteBookmarkPayload>
  /** Deletes a bookmark folder */
  deleteBookmarkFolder?: Maybe<DeleteBookmarkFolderPayload>
  /** Delete a cohort pass */
  deleteCohortPass?: Maybe<DeleteCohortPassPayload>
  /** delete cohort post */
  deleteCohortPost?: Maybe<DeleteCohortPostPayload>
  /** delete cohort reply for a cohort post */
  deleteCohortReply?: Maybe<DeleteCohortReplyPayload>
  /** Delete a tax id number from a customer in Stripe */
  deleteCustomerTaxId?: Maybe<DeleteCustomerTaxIdPayload>
  /** Soft delete an email */
  deleteEmail?: Maybe<DeleteEmailPayload>
  /** Delete a filed bookmark */
  deleteFiledBookmark?: Maybe<DeleteFiledBookmarkPayload>
  /** Delete a payment source */
  deletePaymentSource?: Maybe<DeletePaymentSourcePayload>
  /** Delete a team post */
  deleteTeamPost?: Maybe<DeleteTeamPostPayload>
  /** Remove a company from user. */
  deleteUserCompany?: Maybe<DeleteUserCompanyPayload>
  /** End a subscription trial early and charge the user */
  endSubscriptionTrialEarly?: Maybe<EndSubscriptionTrialEarlyPayload>
  /** Give a user rating to a piece of content */
  feedbacks?: Maybe<FeedbacksPayload>
  /** Flag artifact comment */
  flagArtifactComment?: Maybe<FlagArtifactCommentPayload>
  /** Forks a bookmark folder */
  forkBookmarkFolder?: Maybe<ForkBookmarkFolderPayload>
  /** R data related to coaching sessions */
  generateAiGoals?: Maybe<GenerateAiGoalsPayload>
  /** Generate AI inferences based on user actions */
  generateAiInference?: Maybe<GenerateAiInferencePayload>
  /** Join a course waitlist */
  joinCourseWaitlist?: Maybe<JoinCourseWaitlistPayload>
  /** Join a course waitlist as a non-logged-in user */
  joinPublicCourseWaitlist?: Maybe<JoinPublicCourseWaitlistPayload>
  /** Set an email as primary */
  makeEmailPrimary?: Maybe<MakeEmailPrimaryPayload>
  /** Marks activities as read */
  markActivitiesAsRead?: Maybe<MarkActivitiesAsReadPayload>
  /** Marks all activities as read */
  markAllActivitiesAsRead?: Maybe<MarkAllActivitiesAsReadPayload>
  /** Mark an artifact as viewed */
  markArtifactAsViewed?: Maybe<MarkArtifactAsViewedPayload>
  /** Not attending mutation */
  notAttending?: Maybe<NotAttendingPayload>
  /** Regenerate a course enrollment snapshot */
  regenerateCourseSnapshot?: Maybe<RegenerateCourseSnapshotPayload>
  /** Reinstate a seat on a subscription */
  reinstateSeat?: Maybe<ReinstateSeatPayload>
  /** Soft delete an AI chat */
  removeAiChat?: Maybe<RemoveAiChatPayload>
  /** Remove annotation reaction */
  removeAnnotationReaction?: Maybe<RemoveAnnotationReactionPayload>
  /** Remove artifact comment */
  removeArtifactComment?: Maybe<RemoveArtifactCommentPayload>
  /** Remove artifact comment reaction */
  removeArtifactCommentReaction?: Maybe<RemoveArtifactCommentReactionPayload>
  /** Remove reaction from a post or comment or reply */
  removeReaction?: Maybe<RemoveReactionPayload>
  /** Remove a reply from a post */
  removeReply?: Maybe<RemoveReplyPayload>
  /** Destroy a seat on a subscription */
  removeSeat?: Maybe<RemoveSeatPayload>
  /** Remove a subscription from an org */
  removeSubscriptionFromOrg?: Maybe<RemoveSubscriptionFromOrgPayload>
  /** Remove subscription for member */
  removeSubscriptionMember?: Maybe<RemoveSubscriptionMemberPayload>
  /** Rename an AI chat */
  renameAiChat?: Maybe<RenameAiChatPayload>
  /** Reorders a filed bookmark */
  reorderFiledBookmark?: Maybe<ReorderFiledBookmarkPayload>
  /** Report a post or reply in cohort channels */
  reportCohortPostOrReply?: Maybe<ReportCohortPostOrReplyPayload>
  /** Restores Collection to prior state */
  restoreCollectionActivity?: Maybe<RestoreCollectionActivityPayload>
  /** creates an RSVP for user to event */
  rsvp?: Maybe<CreatePayload>
  /** Save a tax id number and type for a customer in Stripe */
  saveCustomerTaxId?: Maybe<SaveCustomerTaxIdPayload>
  /** Select featured posts for a specific week */
  selectFeaturedPosts?: Maybe<SelectFeaturedPostsPayload>
  /** Send email verification */
  sendEmailVerify?: Maybe<SendEmailVerifyPayload>
  /** Send merge request */
  sendMergeRequestEmail?: Maybe<SendMergeRequestEmailPayload>
  /** Set the date when the user dismissed the <UpdateCompanyBanner> */
  setDismissedUpdateCompanyBannerAt?: Maybe<SetDismissedUpdateCompanyBannerAtPayload>
  /** Set the demographics modal to have been */
  setHasSeenDemographicsModal?: Maybe<SetHasSeenDemographicsModalPayload>
  /** Set user preferences for cohort */
  setUserCohortPreferences?: Maybe<SetUserCohortPreferencesPayload>
  /** Shares a bookmark folder */
  shareBookmarkFolder?: Maybe<ShareBookmarkFolderPayload>
  /** Create a payment intent with Stripe */
  stripeSetupIntent?: Maybe<StripeSetupIntentPayload>
  /** Submit onboarding questionnaire */
  submitOnboardingQuestionnaire?: Maybe<SubmitOnboardingQuestionnairePayload>
  /** Submit survey after application */
  submitPostApplySurvey?: Maybe<SubmitPostApplySurveyPayload>
  /** Returns a preview of the amount a user would need to pay when updating the number of seats */
  subscriptionSeatsChangePreview?: Maybe<SubscriptionSeatsChangePreviewPayload>
  /** Toggle the manual_assign field on a Subscription */
  toggleManualAssignSubscription?: Maybe<ToggleManualAssignSubscriptionPayload>
  /** Toggle follow/unfollow for a post */
  togglePostFollow?: Maybe<TogglePostFollowPayload>
  /**
   * Track Course Enrollment Confirmed Events from the UI, on the
   *                                                Server Side
   */
  trackCourseEnrollmentConfirmed?: Maybe<CourseEnrollmentConfirmedPayload>
  /**
   * Track Course Enrollment Started Events from the UI, on the
   *                                              Server Side
   */
  trackCourseEnrollmentStarted?: Maybe<CourseEnrollmentStartedPayload>
  /** Track onboarding events */
  trackOnboarding?: Maybe<OnboardingTrackingPayload>
  /** Generic mutation to track a specified server event */
  trackServerEvent?: Maybe<TrackServerEventPayload>
  /** Remove cohort access from a seat on a subscription */
  unassignCohortCredit?: Maybe<UnassignCohortCreditPayload>
  /** Unassign a seat from a member on a subscription */
  unassignSeat?: Maybe<UnassignSeatPayload>
  /** Unshares bookmark folder */
  unshareBookmarkFolder?: Maybe<UnshareBookmarkFolderPayload>
  /** Update AI personalization */
  updateAiPersonalization?: Maybe<UpdateAiPersonalizationPayload>
  /** Update artifact comment */
  updateArtifactComment?: Maybe<UpdateArtifactCommentPayload>
  /** Updates a bookmark for user */
  updateBookmark?: Maybe<UpdateBookmarkPayload>
  /** Updates a bookmarks folder */
  updateBookmarkFolder?: Maybe<UpdateBookmarkFolderPayload>
  /** update ccl tables */
  updateCcl?: Maybe<UpdateCclPayload>
  /** Add or remove Cms Section Tag */
  updateCmsSectionTags?: Maybe<UpdateCmsSectionTagsPayload>
  /** Saves data related to coaching sessions */
  updateCoachingSession?: Maybe<UpdateCoachingSessionPayload>
  /** update cohort post */
  updateCohortPost?: Maybe<UpdateCohortPostPayload>
  /** update cohort reply for a cohort post */
  updateCohortReply?: Maybe<UpdateCohortReplyPayload>
  /** update course enrollment */
  updateCourseEnrollment?: Maybe<UpdateCourseEnrollmentPayload>
  /** update course session */
  updateCourseSession?: Maybe<UpdateCourseSessionPayload>
  /** Update customer contact information */
  updateCustomerContact: UpdateCustomerContactPayload
  /**
   * Update homepage checklist data for a user
   * @deprecated Not used on the feed homepage anymore
   */
  updateHomepageCheck?: Maybe<UpdateHomepageCheckPayload>
  /** Update invoice user fields */
  updateInvoiceUserFields?: Maybe<UpdateInvoiceUserFieldsPayload>
  /** Update a payment method */
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>
  /** Update user preferences */
  updatePreferences?: Maybe<UpdatePreferencesPayload>
  /** Update a project deliverable */
  updateProjectDeliverable?: Maybe<UpdateProjectDeliverablePayload>
  /** Edit a reply from a post */
  updateReply?: Maybe<UpdateReplyPayload>
  /** Update the user's team */
  updateTeam?: Maybe<UpdateTeamPayload>
  /** Updates a team post */
  updateTeamPost?: Maybe<UpdateTeamPostPayload>
  /** Update topic activity record */
  updateTopicActivity?: Maybe<UpdateTopicActivityPayload>
  /** Update the current user. */
  updateUser?: Maybe<UpdateUserPayload>
  /** Updates a UserCohort */
  updateUserCohort?: Maybe<UpdateUserCohortPayload>
  /** Updates a UserCourse */
  updateUserCourse?: Maybe<UpdateUserCoursePayload>
  /** Upgrades the subscription in stripe for a prorated price */
  upgradeSubscription?: Maybe<UpgradeSubscriptionPayload>
  /** Notify the server of a client URL change */
  urlChange?: Maybe<UrlChangePayload>
  /** verify customer email address */
  verifyEmail?: Maybe<VerifyEmailPayload>
  /** Registers a view for a bookmark folder */
  viewBookmarkFolder?: Maybe<ViewBookmarkFolderPayload>
  /** Save the subscription cancelation additional feedback */
  whatWeCanDoBetter?: Maybe<WhatWeCanDoBetterPayload>
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationActivateSubscriptionArgs = {
  input: ActivateSubscriptionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddAnnotationReactionArgs = {
  input: AddAnnotationReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddArtifactCommentArgs = {
  input: AddArtifactCommentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddArtifactCommentReactionArgs = {
  input: AddArtifactCommentReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddEmailToUserArgs = {
  input: AddEmailToUserInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddReactionArgs = {
  input: AddReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAddUserCompanyArgs = {
  input: AddUserCompanyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationApproveSubscriptionJoinRequestArgs = {
  input: ApproveSubscriptionJoinRequestInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAssignCohortCreditArgs = {
  input: AssignCohortCreditInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationAssignSeatArgs = {
  input: AssignSeatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationBatchRsvpArgs = {
  input: BatchInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCancelRsvpArgs = {
  input: CancelInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCancelSubscriptionArgs = {
  input: SubscriptionCancelInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCancelSubscriptionMotivationArgs = {
  input: SubscriptionCancelMotivationInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCheckoutArgs = {
  input: CheckoutInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationChoosePlanArgs = {
  input: ChoosePlanInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCmsProgramExpertsArgs = {
  input: CmsProgramExpertsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCompleteProductTourArgs = {
  input: CompleteProductTourInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationConfirmUserMergeArgs = {
  input: ConfirmUserMergeInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateBookmarkArgs = {
  input: CreateBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateBookmarkFolderArgs = {
  input: CreateBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateCohortPostArgs = {
  input: CreateCohortPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateCohortReplyArgs = {
  input: CreateCohortReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateCourseEnrollmentArgs = {
  input: CreateCourseEnrollmentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateCourseSessionArgs = {
  input: CreateCourseSessionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateCourseSessionSlackChannelArgs = {
  input: CreateCourseSessionSlackChannelInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateDraftSubscriptionJoinRequestArgs = {
  input: CreateDraftSubscriptionJoinRequestInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateFiledBookmarkArgs = {
  input: CreateFiledBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateProgramApplicationArgs = {
  input: CreateProgramApplicationInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateProgramInvitationArgs = {
  input: CreateProgramInvitationInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateRailsSubscriptionArgs = {
  input: CreateRailsSubscriptionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateRecommendationsArgs = {
  input: CreateRecommendationsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateReplyArgs = {
  input: CreateReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateSubscriptionJoinRequestArgs = {
  input: CreateSubscriptionJoinRequestInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateTeamPostArgs = {
  input: CreateTeamPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateTestUsersArgs = {
  input: CreateTestUsersInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateUserClipActivityArgs = {
  input: CreateUserClipActivityInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateUserCmsActivityArgs = {
  input: CreateUserCmsActivityInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationCreateUserEventActivityArgs = {
  input: CreateUserEventActivityInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeclineSubscriptionJoinRequestArgs = {
  input: DeclineSubscriptionJoinRequestInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteBookmarkArgs = {
  input: DeleteBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteBookmarkFolderArgs = {
  input: DeleteBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteCohortPassArgs = {
  input: DeleteCohortPassInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteCohortPostArgs = {
  input: DeleteCohortPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteCohortReplyArgs = {
  input: DeleteCohortReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteCustomerTaxIdArgs = {
  input: DeleteCustomerTaxIdInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteEmailArgs = {
  input: DeleteEmailInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteFiledBookmarkArgs = {
  input: DeleteFiledBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeletePaymentSourceArgs = {
  input: DeletePaymentSourceInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteTeamPostArgs = {
  input: DeleteTeamPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationDeleteUserCompanyArgs = {
  input: DeleteUserCompanyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationEndSubscriptionTrialEarlyArgs = {
  input: EndSubscriptionTrialEarlyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationFeedbacksArgs = {
  input: FeedbacksInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationFlagArtifactCommentArgs = {
  input: FlagArtifactCommentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationForkBookmarkFolderArgs = {
  input: ForkBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationGenerateAiGoalsArgs = {
  input: GenerateAiGoalsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationGenerateAiInferenceArgs = {
  input: GenerateAiInferenceInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationJoinCourseWaitlistArgs = {
  input: JoinCourseWaitlistInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationJoinPublicCourseWaitlistArgs = {
  input: JoinPublicCourseWaitlistInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationMakeEmailPrimaryArgs = {
  input: MakeEmailPrimaryInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationMarkActivitiesAsReadArgs = {
  input: MarkActivitiesAsReadInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationMarkAllActivitiesAsReadArgs = {
  input: MarkAllActivitiesAsReadInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationMarkArtifactAsViewedArgs = {
  input: MarkArtifactAsViewedInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationNotAttendingArgs = {
  input: NotAttendingInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRegenerateCourseSnapshotArgs = {
  input: RegenerateCourseSnapshotInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationReinstateSeatArgs = {
  input: ReinstateSeatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveAiChatArgs = {
  input: RemoveAiChatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveAnnotationReactionArgs = {
  input: RemoveAnnotationReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveArtifactCommentArgs = {
  input: RemoveArtifactCommentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveArtifactCommentReactionArgs = {
  input: RemoveArtifactCommentReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveReactionArgs = {
  input: RemoveReactionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveReplyArgs = {
  input: RemoveReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveSeatArgs = {
  input: RemoveSeatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveSubscriptionFromOrgArgs = {
  input: RemoveSubscriptionFromOrgInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRemoveSubscriptionMemberArgs = {
  input: RemoveSubscriptionMemberInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRenameAiChatArgs = {
  input: RenameAiChatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationReorderFiledBookmarkArgs = {
  input: ReorderFiledBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationReportCohortPostOrReplyArgs = {
  input: ReportCohortPostOrReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRestoreCollectionActivityArgs = {
  input: RestoreCollectionActivityInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationRsvpArgs = {
  input: CreateInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSaveCustomerTaxIdArgs = {
  input: SaveCustomerTaxIdInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSelectFeaturedPostsArgs = {
  input: SelectFeaturedPostsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSendEmailVerifyArgs = {
  input: SendEmailVerifyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSendMergeRequestEmailArgs = {
  input: SendMergeRequestEmailInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSetDismissedUpdateCompanyBannerAtArgs = {
  input: SetDismissedUpdateCompanyBannerAtInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSetHasSeenDemographicsModalArgs = {
  input: SetHasSeenDemographicsModalInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSetUserCohortPreferencesArgs = {
  input: SetUserCohortPreferencesInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationShareBookmarkFolderArgs = {
  input: ShareBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationStripeSetupIntentArgs = {
  input: StripeSetupIntentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSubmitOnboardingQuestionnaireArgs = {
  input: SubmitOnboardingQuestionnaireInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSubmitPostApplySurveyArgs = {
  input: SubmitPostApplySurveyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationSubscriptionSeatsChangePreviewArgs = {
  input: SubscriptionSeatsChangePreviewInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationToggleManualAssignSubscriptionArgs = {
  input: ToggleManualAssignSubscriptionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationTogglePostFollowArgs = {
  input: TogglePostFollowInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationTrackCourseEnrollmentConfirmedArgs = {
  input: CourseEnrollmentConfirmedInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationTrackCourseEnrollmentStartedArgs = {
  input: CourseEnrollmentStartedInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationTrackOnboardingArgs = {
  input: OnboardingTrackingInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationTrackServerEventArgs = {
  input: TrackServerEventInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUnassignCohortCreditArgs = {
  input: UnassignCohortCreditInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUnassignSeatArgs = {
  input: UnassignSeatInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUnshareBookmarkFolderArgs = {
  input: UnshareBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateAiPersonalizationArgs = {
  input: UpdateAiPersonalizationInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateArtifactCommentArgs = {
  input: UpdateArtifactCommentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateBookmarkArgs = {
  input: UpdateBookmarkInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateBookmarkFolderArgs = {
  input: UpdateBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCclArgs = {
  input: UpdateCclInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCmsSectionTagsArgs = {
  input: UpdateCmsSectionTagsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCoachingSessionArgs = {
  input: UpdateCoachingSessionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCohortPostArgs = {
  input: UpdateCohortPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCohortReplyArgs = {
  input: UpdateCohortReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCourseEnrollmentArgs = {
  input: UpdateCourseEnrollmentInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCourseSessionArgs = {
  input: UpdateCourseSessionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateCustomerContactArgs = {
  input: UpdateCustomerContactInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateHomepageCheckArgs = {
  input: UpdateHomepageCheckInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateInvoiceUserFieldsArgs = {
  input: UpdateInvoiceUserFieldsInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdatePreferencesArgs = {
  input: UpdatePreferencesInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateProjectDeliverableArgs = {
  input: UpdateProjectDeliverableInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateReplyArgs = {
  input: UpdateReplyInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateTeamPostArgs = {
  input: UpdateTeamPostInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateTopicActivityArgs = {
  input: UpdateTopicActivityInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateUserCohortArgs = {
  input: UpdateUserCohortInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpdateUserCourseArgs = {
  input: UpdateUserCourseInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUpgradeSubscriptionArgs = {
  input: UpgradeSubscriptionInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationUrlChangeArgs = {
  input: UrlChangeInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationViewBookmarkFolderArgs = {
  input: ViewBookmarkFolderInput
}

/** Mutations are modifications to the data. In REST these would be POST, PUT, PATCH, DELETE */
export type MutationWhatWeCanDoBetterArgs = {
  input: WhatWeCanDoBetterInput
}

/** Mux Video */
export type MuxVideo = {
  __typename: 'MuxVideo'
  /** The playback ID of the video */
  playbackId?: Maybe<Scalars['ID']>
  /** The time in seconds of the video thumbnail */
  thumbTime?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of NotAttending */
export type NotAttendingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the event */
  id: Scalars['ID']
}

/** Autogenerated return type of NotAttending */
export type NotAttendingPayload = {
  __typename: 'NotAttendingPayload'
  /** The attendee record created or updated */
  attendee?: Maybe<EventAttendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors array if any errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Onboarding info */
export type OnboardingInfo = {
  __typename: 'OnboardingInfo'
  /** has program recommendations */
  hasProgramRecommendations: Scalars['Boolean']
  /** onboarded? */
  onboarded: Scalars['Boolean']
}

/** Onboarding Questionnaire Type */
export type OnboardingQuestionnaire = {
  __typename: 'OnboardingQuestionnaire'
  /** An areas of expertise of the questionnaire */
  areasOfExpertiseTags?: Maybe<Scalars['String']>
  /** A business model of the questionnaire */
  businessModelTags?: Maybe<Scalars['String']>
  /** A professional relationship of the questionnaire */
  communityInterestTags?: Maybe<Scalars['String']>
  /** A company audience of the questionnaire */
  companyAudienceTags?: Maybe<Scalars['String']>
  /** Created at Date */
  createdAt: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** The team name of the user */
  teamName?: Maybe<Scalars['String']>
  /** The usage the user intends for Reforge */
  usage?: Maybe<Scalars['String']>
  /** The user of the questionnaire */
  user: User
}

/** Autogenerated input type of OnboardingTracking */
export type OnboardingTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Member type completing the onboarding */
  onboardingType: Scalars['String']
  /** Name of first option selected */
  option1Name?: InputMaybe<Scalars['String']>
  /** Value of first option selected, when multiple options to select */
  option1Value?: InputMaybe<Scalars['String']>
  /** Name of second option selected */
  option2Name?: InputMaybe<Scalars['String']>
  /** Value of second option selected, when multiple options to select */
  option2Value?: InputMaybe<Scalars['String']>
  /** Option selected, if ony one option to select */
  optionSelection?: InputMaybe<Scalars['String']>
  /** Whether to call the "Started" event instead on "Completed" */
  startedEvent?: InputMaybe<Scalars['Boolean']>
  /** Name of onboarding flow step */
  stepName: Scalars['String']
  /** Step in onboarding flow */
  stepNumber: Scalars['Int']
}

/** Autogenerated return type of OnboardingTracking */
export type OnboardingTrackingPayload = {
  __typename: 'OnboardingTrackingPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Org */
export type Org = {
  __typename: 'Org'
  /** ID of Org */
  id: Scalars['ID']
  /** Admins of org */
  orgAdmins?: Maybe<Array<OrgAdmin>>
  /** Subscriptions owned by org */
  subscriptions: Array<Subscription>
}

/** Org Admin */
export type OrgAdmin = {
  __typename: 'OrgAdmin'
  /** ID of OrgAdmin */
  id: Scalars['ID']
  /** Org that a user is an admin of */
  org: Org
  /** Status of org admin */
  status: Scalars['String']
  /** User that is an admin of an org */
  user: User
}

/** Page Type from Sanity CMS - general type containing fields common to all pages */
export type Page = {
  __typename: 'Page'
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

/** Page SEO from Sanity CMS */
export type PageSeo = {
  __typename: 'PageSeo'
  /** Comma separated list of authors */
  authors?: Maybe<Scalars['String']>
  /** Meta Description */
  metaDesc?: Maybe<Scalars['String']>
  /** Meta Title */
  metaTitle?: Maybe<Scalars['String']>
  /** Publish Date */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Share Description */
  shareDesc?: Maybe<Scalars['String']>
  /** Share Graphic */
  shareGraphic?: Maybe<Scalars['JSON']>
  /** Share Graphic alt text */
  shareGraphicAlt?: Maybe<Scalars['String']>
  /** Share Title */
  shareTitle?: Maybe<Scalars['String']>
  /** Structured Data JSON String */
  structuredDataSchema?: Maybe<Scalars['String']>
  /** Page type */
  type?: Maybe<PageTypeEnum>
}

/** SEO Type metadata for a page */
export enum PageTypeEnum {
  /** Type used for blog posts or any type of article */
  ARTICLE = 'article',
  /** Type used for all other pages */
  WEBSITE = 'website'
}

/** Pagination data for a list */
export type Pagination = {
  __typename: 'Pagination'
  /** Current page */
  currentPage: Scalars['Int']
  /** Next page */
  nextPage?: Maybe<Scalars['Int']>
  /** Previous page */
  previousPage?: Maybe<Scalars['Int']>
  /** Total number of entries */
  totalEntries: Scalars['Int']
  /** Total pages */
  totalPages: Scalars['Int']
}

/** A payment in the past made for a subscription or live program reservation. */
export type PastPayment = {
  __typename: 'PastPayment'
  /** Created at date */
  createdAt: Scalars['String']
  /** A description of the payment */
  description: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** The ID of the associated invoice */
  invoiceId: Scalars['ID']
  /** Paid at date */
  paidAt?: Maybe<Scalars['String']>
  /** The user ID of the user that paid */
  payerId: Scalars['ID']
  /** The type of payment */
  paymentType: Scalars['String']
  /** The status of the payment */
  status: Scalars['String']
}

/** A user payment record on a subscription. */
export type PaymentRecord = {
  __typename: 'PaymentRecord'
  /** Amount of the payment. */
  amount: Scalars['Int']
  /** Name of the company. */
  companyName?: Maybe<Scalars['String']>
  /** What the payment was for (2 seats, etc.). */
  description: Scalars['String']
  /** Full name of the user. */
  fullName?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** Invoice for the payment. */
  invoice: Invoice
  /** Paid at date */
  paidAt?: Maybe<Scalars['String']>
  /** Name of the program. */
  programName: Scalars['String']
  /** pending: 0, paid: 1, refunded: 2 */
  status: Scalars['String']
}

/** Interface for fetching AI personalization data based on user ID */
export type PersonalizationQuery = {
  /** Fetch AI personalization data for the given user ID */
  aiPersonalization?: Maybe<AiPersonalization>
}

/** Interface for fetching AI personalization data based on user ID */
export type PersonalizationQueryAiPersonalizationArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** Details about plan prices on offer within app */
export type PlanForSale = {
  __typename: 'PlanForSale'
  /** The maximum number of seats for the plan */
  maxSeatCount: Scalars['Int']
  /** The name of the plan */
  name: Scalars['String']
  /** The price of the plan per person (using max_seat) */
  pricePerPerson: Scalars['Int']
  /** The price of the plan per year, in cents */
  pricePerYear: Scalars['Int']
  /** The Stripe ID of the plan */
  stripeId: Scalars['ID']
}

/** Plan name for subscription. */
export enum PlanName {
  ENTERPRISE = 'enterprise',
  EXPIRED = 'expired',
  INDIVIDUAL = 'individual',
  SCALE = 'scale',
  STARTER = 'starter',
  UNKNOWN = 'unknown',
  UNLIMITED = 'unlimited'
}

/** Marketing Podcast from Sanity CMS */
export type Podcast = {
  __typename: 'Podcast'
  /** Content Block */
  contentBlock?: Maybe<Scalars['JSON']>
  /** Podcast Created At */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** CTA Button */
  ctaButton?: Maybe<PodcastCta>
  /** Hosts */
  hosts?: Maybe<Scalars['String']>
  /** Podcast ID */
  id?: Maybe<Scalars['ID']>
  /** Listen On */
  listenOn?: Maybe<Scalars['JSON']>
  /** Next Episode */
  nextEpisode?: Maybe<Podcast>
  /** Previous Episode */
  prevEpisode?: Maybe<Podcast>
  /** Release Date */
  releaseDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Podcast Slug */
  slug?: Maybe<Scalars['String']>
  /** Summary Image */
  summaryImage?: Maybe<Image>
  /** Podcast Summary Text */
  summaryText?: Maybe<Scalars['String']>
  /** Podcast Title */
  title: Scalars['String']
  /** Topics */
  topics?: Maybe<Scalars['String']>
}

/** Podcast CTA Type including Reforge symbol loolean */
export type PodcastCta = {
  __typename: 'PodcastCta'
  /** CTA Link */
  ctaLink?: Maybe<Scalars['String']>
  /** CTA Text */
  ctaText?: Maybe<Scalars['String']>
  /** Is CTA Enabled */
  enabled?: Maybe<Scalars['Boolean']>
  /** Should display a Reforge symbol next to the CTA label */
  withReforgeSymbol?: Maybe<Scalars['Boolean']>
}

/** Marketing Podcast details page from Sanity CMS */
export type PodcastDetails = {
  __typename: 'PodcastDetails'
  /** Created at */
  createdAt: Scalars['ISO8601DateTime']
  /** ID */
  id: Scalars['ID']
  /** Podcast */
  podcast: Podcast
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
  /** Updated at */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Marketing Podcasts Host from Sanity CMS */
export type PodcastHost = {
  __typename: 'PodcastHost'
  /** Host Artifact Author */
  artifactAuthor: ArtifactAuthor
  /** Host ID */
  id: Scalars['ID']
  /** Host Short Bio */
  shortBio: Scalars['String']
}

/** Marketing Podcast Index from Sanity CMS */
export type PodcastIndex = {
  __typename: 'PodcastIndex'
  /** Next podcast count */
  nextPodcastsCount: Scalars['Int']
  /** Page */
  page: PodcastIndexPage
  /** Podcast list */
  podcasts: Array<Podcast>
  /** Previous podcast count */
  prevPodcastsCount: Scalars['Int']
}

/** Marketing Podcasts Index Content from Sanity CMS */
export type PodcastIndexContent = {
  __typename: 'PodcastIndexContent'
  /** Page Description */
  description: Scalars['String']
  /** Form Title */
  formTitle: Scalars['String']
  /** Main heading */
  heading: Scalars['String']
  /** Hosts */
  hosts: Array<PodcastHost>
  /** Main Image */
  mainImage: Image
  /** Platforms */
  platforms: Array<PodcastPlatform>
}

/** Marketing Podcasts index from Sanity CMS */
export type PodcastIndexPage = {
  __typename: 'PodcastIndexPage'
  /** Content */
  content: PodcastIndexContent
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** Marketing Podcasts Platform from Sanity CMS */
export type PodcastPlatform = {
  __typename: 'PodcastPlatform'
  /** Platform ID */
  id: Scalars['ID']
  /** Platform Image */
  platformImage: Image
  /** Platform Link URL */
  platformLink: Scalars['String']
  /** Platform Title */
  title: Scalars['String']
}

/** List of the most popular events denoted by attendee count */
export type PopularEventsPastFeed = {
  __typename: 'PopularEventsPastFeed'
  /** Events. */
  events?: Maybe<Array<Event>>
}

/** Filters for popular posts */
export type PopularPostFilter = {
  /** Audience */
  audience?: InputMaybe<Scalars['String']>
  /** Business model */
  businessModel?: InputMaybe<Scalars['String']>
  /** Contributors */
  contributors?: InputMaybe<Scalars['String']>
  /** Industries */
  industries?: InputMaybe<Scalars['String']>
  /** Intersecting groups */
  intersectingGroups?: InputMaybe<Scalars['String']>
  /** Page number */
  page: Scalars['Int']
  /** Per page records */
  perPage?: InputMaybe<Scalars['Int']>
  /** Post type */
  postType?: InputMaybe<Scalars['String']>
  /** Slug of group */
  slug: Scalars['String']
  /** Sort by */
  sortBy: Scalars['String']
  /** Topics */
  topics?: InputMaybe<Scalars['String']>
}

/** A post made by a user. */
export type Post = {
  __typename: 'Post'
  /** Activities related to post */
  activities: Array<Activity>
  /** Count of reactions */
  allReactionsCount?: Maybe<Scalars['Int']>
  /** Inline post based on */
  basedOn?: Maybe<Scalars['String']>
  /** Text of the post */
  body?: Maybe<Scalars['String']>
  /** Text of the post */
  bodyText?: Maybe<Scalars['String']>
  /** List of all possible categories for a post */
  categories: Array<Scalars['String']>
  /** Category of the post */
  category?: Maybe<Scalars['String']>
  /** Cms program for the post */
  cmsProgram?: Maybe<CmsProgram>
  /** The ID of the associated CMS Program */
  cmsProgramId?: Maybe<Scalars['ID']>
  /** Cms section link of inline post */
  cmsSectionLink?: Maybe<Scalars['String']>
  /** Cms section name of inline post */
  cmsSectionName?: Maybe<Scalars['String']>
  /** The name of the cms section's parent */
  cmsSectionParentName?: Maybe<Scalars['String']>
  /** Cohort for the post */
  cohort?: Maybe<Cohort>
  /** The ID of the associated cohort */
  cohortId?: Maybe<Scalars['ID']>
  /** Whether or not the post is only for a collective */
  collectiveOnly: Scalars['Boolean']
  /** Post comments */
  comments: Array<Comment>
  /** Count of comments */
  commentsCount: Scalars['Int']
  /** Contributors */
  contributors: Array<User>
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Created at date in human readable format */
  createdAtDate?: Maybe<Scalars['String']>
  /** Created at date in concise time */
  createdAtInWords: Scalars['String']
  /** Whether or not the post is a draft */
  draft: Scalars['Boolean']
  /** External ID */
  externalId?: Maybe<Scalars['Int']>
  /** Count of followees */
  followeesCount: Scalars['Int']
  /** Post following */
  following: Scalars['Boolean']
  /** Post groups */
  groups: Array<Group>
  /** Href */
  href: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** List of avatar URLs for replies */
  images: Array<Scalars['String']>
  /** Post industries */
  industries: Array<Industry>
  /** Pinned or Unpinned post */
  isPinned: Scalars['Boolean']
  /** Last response date */
  lastResponded?: Maybe<Scalars['ISO8601DateTime']>
  /** Last response date in concise time */
  lastRespondedAtInWords?: Maybe<Scalars['String']>
  /** Reaction count */
  reactionCount: Scalars['Int']
  /** Post reactions */
  reactions: Array<Reaction>
  /** Reference image url */
  referenceImageUrl?: Maybe<Scalars['String']>
  /** Post replies */
  replies: Array<Reply>
  /** Count of replies */
  repliesCount: Scalars['Int']
  /** Should show new badge */
  replyCount: Scalars['Int']
  /** Post reply previews */
  replyPreviews: Array<Reply>
  /** Count of response */
  responseCount: Scalars['Int']
  /** Post posted in */
  safePostedIn?: Maybe<Scalars['String']>
  /** Skip digest flag */
  skipDigest: Scalars['Boolean']
  /** Skip forum index */
  skipForumIndex?: Maybe<Scalars['Boolean']>
  /** Whether or not post has been soft deleted */
  softDeleted?: Maybe<Scalars['Boolean']>
  /** Text */
  text: Scalars['String']
  /** Datetime */
  timeAgo: Scalars['String']
  /** Title of the post */
  title?: Maybe<Scalars['String']>
  /** Param of the post */
  toParam: Scalars['String']
  /** Topic of the post */
  topic?: Maybe<Scalars['String']>
  /** Post topics */
  topics: Array<PostTopic>
  /**
   * Elements related to post that have not been read
   *                                                         by the user
   */
  unreadElements: Array<UnreadElement>
  /** Updated at date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Updated at date in concise time */
  updatedAtInWords?: Maybe<Scalars['String']>
  /** The associated user */
  user: User
}

/** A post made by a user. */
export type PostActivitiesArgs = {
  includeCohortPosts?: InputMaybe<Scalars['Boolean']>
}

/** List of post contributors used in posts contrbutor filter */
export type PostContributor = {
  __typename: 'PostContributor'
  /** Avatar URL */
  avatarUrl: Scalars['String']
  /** User full name */
  fullName: Scalars['String']
  /** Whether the user has badge */
  hasBadge?: Maybe<Scalars['Boolean']>
  /** User ID */
  id: Scalars['ID']
  /** User kind */
  kind: Scalars['String']
  /** User slug */
  slug: Scalars['String']
}

/** List of posts with metadata */
export type PostList = {
  __typename: 'PostList'
  /** Pagination data for list */
  pagination: Pagination
  /** Relevant posts */
  posts: Array<Post>
  /** List of unread post IDs */
  unreadPostIds: Array<Scalars['ID']>
}

/** Data source for posts */
export enum PostSource {
  /** Posts for a specific contributor */
  CONTRIBUTOR = 'CONTRIBUTOR',
  /** All discussion posts */
  DISCUSSION = 'DISCUSSION',
  /** Posts for a specific group */
  GROUP = 'GROUP'
}

/** Topic */
export type PostTopic = {
  __typename: 'PostTopic'
  /** The Description of the topic */
  description?: Maybe<Scalars['String']>
  /** Group ID */
  groupId?: Maybe<Scalars['ID']>
  /** ID */
  id: Scalars['ID']
  /** Is this a content questions topic */
  isContentClarification: Scalars['Boolean']
  /** Is this a feedback topic */
  isFeedback: Scalars['Boolean']
  /** Topic kind */
  kind?: Maybe<Scalars['String']>
  /** Timestamp of when the last post was created in this topic */
  latestPostCreatedAtForCohort?: Maybe<Scalars['String']>
  /** Posts */
  posts?: Maybe<Array<Post>>
  /** Can only staff post to the topic */
  restrictedToStaff: Scalars['Boolean']
  /** Topic slug */
  slug: Scalars['String']
  /** Topic title */
  title: Scalars['String']
  /** Topic ID */
  topicId?: Maybe<Scalars['ID']>
  /** The topic activity for this topic & current user */
  userTopicActivity: TopicActivity
}

/** Topic */
export type PostTopicLatestPostCreatedAtForCohortArgs = {
  cohortSlug: Scalars['String']
}

/** Posts search response */
export type PostsSearch = {
  __typename: 'PostsSearch'
  /** Groups mathing search query */
  groups: Array<Group>
  /** Members mathing search query */
  members: Array<PostContributor>
  /** Posts mathing search query */
  posts: Array<Post>
  /** Topics mathing search query */
  topics: Array<PostsSearchTopic>
}

/** Topic Type used in Posts Search */
export type PostsSearchTopic = {
  __typename: 'PostsSearchTopic'
  /** Group slug */
  groupSlug: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Topic title */
  title: Scalars['String']
  /** Topic ID */
  topicId: Scalars['ID']
}

/** Marketing Pricing page from Sanity CMS */
export type PricingPage = {
  __typename: 'PricingPage'
  /** Content */
  content: PricingPageContent
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
}

/** Marketing Pricing Page content from Sanity CMS */
export type PricingPageContent = {
  __typename: 'PricingPageContent'
  /** Case Studies */
  caseStudies?: Maybe<Array<CaseStudy>>
  /** Case studies section subtitle */
  caseStudiesSubtitle: Scalars['String']
  /** Case studies section title */
  caseStudiesTitle: Scalars['String']
  /** FAQs */
  faqs?: Maybe<Array<MarketingFaq>>
  /** Features */
  features?: Maybe<Array<PricingPlanFeature>>
  /** Logo Section Title */
  logoSectionTitle?: Maybe<Scalars['String']>
  /** Logos */
  logos?: Maybe<Array<Image>>
  /** Membership Section Subitle */
  membershipSectionSubtitle: Scalars['String']
  /** Membership Section Title */
  membershipSectionTitle: Scalars['String']
  /** Membership Section Mux Video */
  membershipSectionVideo: MuxVideo
  /** comparisons */
  reforgeComparisons?: Maybe<Array<ReforgeComparison>>
  /** Reviews */
  reviews?: Maybe<Array<Review>>
  /** Subtitle */
  subtitle?: Maybe<Scalars['String']>
  /** Title */
  title?: Maybe<Scalars['String']>
}

/** Marketing Pricing Plan Feature from Sanity CMS */
export type PricingPlanFeature = {
  __typename: 'PricingPlanFeature'
  /** Pricing plan feature category */
  category: PricingPlanFeatureCategory
  /** Pricing plan feature label */
  feature: Scalars['String']
  /** Pricing plan ID */
  id: Scalars['ID']
  /** Pricing plan feature values */
  values?: Maybe<Array<PricingPlanFeatureValue>>
}

/** Marketing Pricing Plan Feature Category from Sanity CMS */
export type PricingPlanFeatureCategory = {
  __typename: 'PricingPlanFeatureCategory'
  /** Category name */
  name: Scalars['String']
  /** Order of the category in the list of features */
  order: Scalars['Int']
}

/** Marketing Pricing Plan Feature from Sanity CMS */
export type PricingPlanFeatureValue = {
  __typename: 'PricingPlanFeatureValue'
  /** Is the feature enabled */
  enabled?: Maybe<Scalars['Boolean']>
  /** For plan */
  forPlan: Scalars['String']
  /** Pricing plan ID */
  id: Scalars['ID']
  /** Text value */
  textValue?: Maybe<Scalars['String']>
}

/** A collection representing whether certain product tours have been completed/dismissed or not */
export type ProductTour = {
  __typename: 'ProductTour'
  /** The 'create your first highlight' tour */
  bookmarkCreate?: Maybe<Scalars['Boolean']>
  /** The 'save bookmarks to a collection' tour */
  collectionSaveBookmark?: Maybe<Scalars['Boolean']>
  /** The 'share a collection' tour */
  collectionShare?: Maybe<Scalars['Boolean']>
  /** The 'dive into collection content' tour */
  collectionViewContent?: Maybe<Scalars['Boolean']>
  /** The 'collections tab' highlight */
  collectionsTab?: Maybe<Scalars['Boolean']>
  /** The 'new search clicked' highlight */
  newSearchClicked?: Maybe<Scalars['Boolean']>
  /** The 'saved items tab' highlight */
  savedItemsTab?: Maybe<Scalars['Boolean']>
  /** The number of times the 'search trainer' tour has been viewed */
  searchTrainerViews?: Maybe<Scalars['Int']>
  /** If they've seen the StartDraftFrom button */
  startDraftFromButton?: Maybe<Scalars['Boolean']>
  /** The 'see your team's comments' tour */
  teamCommentView?: Maybe<Scalars['Boolean']>
  /** Has interacted withreforge ai global entry point */
  usedReforgeAiGlobalEntryPoint?: Maybe<Scalars['Boolean']>
}

/** Key names for product tour types */
export enum ProductTourKey {
  /** Create your first highlight */
  BOOKMARK_CREATE = 'bookmark_create',
  /** Save bookmarks to a collection */
  COLLECTION_SAVE_BOOKMARK = 'collection_save_bookmark',
  /** Share a collection */
  COLLECTION_SHARE = 'collection_share',
  /** Dive into collection content */
  COLLECTION_VIEW_CONTENT = 'collection_view_content',
  /** Collections tab */
  COLLECTIONS_TAB = 'collections_tab',
  /** New search clicked */
  NEW_SEARCH_CLICKED = 'new_search_clicked',
  /** Saved items tab */
  SAVED_ITEMS_TAB = 'saved_items_tab',
  /** Search trainer views */
  SEARCH_TRAINER_VIEWS = 'search_trainer_views',
  /** Start draft from button */
  START_DRAFT_FROM_BUTTON = 'start_draft_from_button',
  /** See your team's comments */
  TEAM_COMMENT_VIEW = 'team_comment_view',
  /** Has interacted with reforge ai global entry point */
  USED_REFORGE_AI_GLOBAL_ENTRY_POINT = 'used_reforge_ai_global_entry_point'
}

/** Artifact author profile */
export type Profile = {
  __typename: 'Profile'
  /** Artifact author */
  artifactAuthor: ArtifactAuthor
  /** ID */
  id: Scalars['ID']
  /** SEO */
  seo?: Maybe<PageSeo>
  /** Title */
  title: Scalars['String']
  /** User associated with the artifact author */
  user?: Maybe<ExpertUserProfile>
}

/** User program participating kinds */
export enum ProgramParticipatingKinds {
  /** Collective participation */
  COLLECTIVE = 'collective',
  /** User participates */
  PARTICIPANT = 'participant',
  /** User sponsored */
  SPONSORED = 'sponsored',
  /** Subscription based subscription */
  SUBSCRIPTION = 'subscription'
}

/** A user's progress through the content */
export type ProgramProgress = {
  __typename: 'ProgramProgress'
  /** The JSON collection of progress */
  progress: Scalars['ProgramProgressScalar']
}

/** A deliverable for a Reforge Project */
export type ProjectDeliverable = {
  __typename: 'ProjectDeliverable'
  /** Cms section detail */
  cmsSection?: Maybe<CmsSection>
  /** A company associated with this deliverable */
  company?: Maybe<Company>
  /** The Google Sheets ID for the deliverable sheet */
  gid?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** Is the deliverable an example from another company? */
  isExample?: Maybe<Scalars['Boolean']>
  /** Unique name for the deliverable */
  name?: Maybe<Scalars['String']>
  /** Unique slug for the deliverable */
  slug?: Maybe<Scalars['String']>
  /** The status of the Project deliverable (draft or published) */
  status?: Maybe<Scalars['String']>
  /**
   * The Google Sheets ID for the template
   *                                  Google Sheet that this project is based on
   */
  templateGid?: Maybe<Scalars['String']>
  /** The Google Sheets URL of the template sheet for this project */
  templateUrl?: Maybe<Scalars['String']>
  /** The generic title of the deliverable */
  title?: Maybe<Scalars['String']>
  /** The URL of the deliverable Google Sheet */
  url?: Maybe<Scalars['String']>
  /** The user associated with the deliverable */
  user?: Maybe<User>
}

/** Queries to the Graph. In Rest this would be GET. */
export type Query = ChatQuery &
  CollectionsQuery &
  PersonalizationQuery &
  SsoQuery &
  SwimlanesQuery & {
    __typename: 'Query'
    /** True if applications are being accepted */
    acceptingApplications: Scalars['Boolean']
    /** Course sessions that have not already ended */
    activeCourseSessions: Array<CourseSession>
    /** Active season */
    activeSeason?: Maybe<Season>
    /** Activity List */
    activityList: ActivityList
    /** Returns a collection of users who have AI chat logs */
    aiChatUsers: Array<User>
    /** Returns a collection of AI chat logs */
    aiChats: Array<AiChat>
    /** Fetch AI personalization data for the given user ID */
    aiPersonalization?: Maybe<AiPersonalization>
    /** Returns a collection of chat messages */
    aiSessionHistory: Array<AiChatMessage>
    /** Guides from Sanity CMS */
    allGuidesWithSubtopics: Array<Unit>
    /** ALL Artifact topic filters */
    allTopicsAndFunctions: Array<ArtifactTopicFilter>
    /** User ids exempt from anticopy protection */
    anticopyExceptionIds: Array<Scalars['String']>
    /** Programs which accept applications */
    applicablePrograms?: Maybe<Array<ApplicablePrograms>>
    /** Artifact */
    artifact?: Maybe<Scalars['JSON']>
    /** Artifact annotations */
    artifactAnnotations?: Maybe<Array<ArtifactAnnotation>>
    /** Artifact annotations count */
    artifactAnnotationsCount: Scalars['Int']
    /**
     * Courses by artifact author slug
     * @deprecated no longer used on profile page
     */
    artifactAuthorCourses: Array<MarketingCourse>
    /** Query multiple authors by their slugs */
    artifactAuthorProfiles: Array<Profile>
    /** Artifact comments */
    artifactComments: Array<ArtifactComment>
    /** Artifact comments count */
    artifactCommentsCount: Scalars['Int']
    /** Artifact contributors */
    artifactContributors: Array<ArtifactContributor>
    /** Artifact recommendations based on chosen topics */
    artifactOnboardingRecommendations: Scalars['JSON']
    /** Artifact topic */
    artifactTopic?: Maybe<ArtifactTopic>
    /** User artifact save/view data */
    artifactUserData: ArtifactUserData
    /**
     * Artifacts by IDs
     * @deprecated only use has been removed
     */
    artifactsByIds?: Maybe<Array<Artifact>>
    /** Artifacts associated with subtopic */
    artifactsForSubtopic?: Maybe<ArtifactsForSubtopic>
    /** Attendees feed. */
    attendeesFeed?: Maybe<AttendeesFeed>
    /** Blog index from Sanity */
    blogIndex: BlogIndex
    /** Blog post details from Sanity */
    blogPost?: Maybe<BlogPostDetails>
    /** Blog posts for a topic */
    blogPostsForTopic?: Maybe<Array<BlogPost>>
    /** A folder acessible to the user */
    bookmarkFolder?: Maybe<BookmarkFolder>
    /** bookmarks that are pageable */
    bookmarksFeed: BookmarksFeed
    /** All bookmarks from a folder */
    bookmarksFromFolder: Array<Bookmark>
    /** Time user bulk rsvpd for a cohort. */
    bulkRsvpdAtForCohort?: Maybe<Scalars['String']>
    /** Careers page from Sanity */
    careersPage: Page
    /** Case Studies index from Sanity */
    caseStudies: CaseStudiesIndex
    /** Case study details from Sanity */
    caseStudy?: Maybe<CaseStudyDetails>
    /** CCL Course Type */
    cclCourse?: Maybe<CclCourse>
    /** All CCL Topics assigned to Ccl Courses */
    cclCourseCclTopics?: Maybe<Array<CclTopic>>
    /** CCL Courses */
    cclCourses?: Maybe<Array<CclCourse>>
    /** Classic content */
    classicContent: Array<CmsSection>
    /** Cms content for commentable block */
    cmsContent?: Maybe<CmsContent>
    /** A CmsProgram and information regarding it */
    cmsProgram?: Maybe<CmsProgram>
    /** The names of all CmsPrograms */
    cmsProgramNames: Array<Scalars['String']>
    /** All CmsPrograms related information */
    cmsPrograms: Array<CmsProgram>
    /** Cms section */
    cmsSection?: Maybe<CmsSection>
    /**
     * Cms Units. This is temporary as a test under the membership
     *                                                 program and a single module.
     */
    cmsUnits?: Maybe<Array<CmsSection>>
    /** Cohort Data */
    cohort: Cohort
    /** Scheduled week of cohort */
    cohortDashboardScheduledWeek: ScheduledWeek
    /** Cohort pass price per tier info */
    cohortPassPriceTiers: Array<CohortPassPriceTier>
    /** A particular user's cohort passes */
    cohortPasses: Array<CohortCredit>
    /** An individual cohort post */
    cohortPost: Post
    /** Cohort Conversation for a given topic */
    cohortPosts: CohortPost
    /** Cohort team */
    cohortTeam?: Maybe<CohortTeam>
    /** Cohort teams in cohort */
    cohortTeams: Array<CohortTeam>
    /** all the activity for a collection */
    collectionActivity: CollectionActivityList
    /** Whether different feedback has been provided */
    completedFeedback: CompletedFeedback
    /** Count of the users in the user program who have completed the specified module. */
    completedModuleUsersCount?: Maybe<Scalars['Int']>
    /** Data for completion snapshots */
    completionSnapshot?: Maybe<CompletionSnapshot>
    /** Concepts */
    concepts?: Maybe<ContentFeed>
    /** Contact us page from Sanity */
    contactUs: Page
    /**
     * The table of contents for
     *                                                      the content in the viewer
     */
    contentViewer?: Maybe<ContentViewer>
    /** Copyright dispute page from Sanity */
    copyrightDisputePage: Page
    /** country code of the request */
    countryCode: Scalars['String']
    /** Course enrollment type */
    course?: Maybe<Course>
    /** Course events */
    courseBlocks: Course
    /** Course topics and functions except the one passed in via slug */
    courseConcepts?: Maybe<CourseConcepts>
    /** Course event */
    courseEvent?: Maybe<CourseEvent>
    /** Course preview type */
    coursePreview?: Maybe<CoursePreview>
    /** Course session type */
    courseSession?: Maybe<CourseSession>
    /** Course session details for admin */
    courseSessionDetail: CourseSessionDetail
    /** Data for course enrollment snapshots */
    courseSnapshot: CourseSnapshot
    /** Courses associated with topic or function */
    coursesForTopicAndFunction?: Maybe<Array<CourseForTopicAndFunction>>
    /** Courses Marketplace waitlist page from Sanity */
    coursesMarketplaceWaitlist: Page
    /** Name of current season */
    currentSeasonPretty: Scalars['String']
    /** The currently logged in user */
    currentUser?: Maybe<User>
    /**
     * Cohort Conversations for a
     *         given cohort topic, by the current user
     */
    currentUserCohortPostsByTopicCount: Scalars['Int']
    /** Deduped topics and functions */
    dedupedTopicsAndFunctions: Array<ArtifactTopicFilter>
    /** Discussion post */
    discussionPost?: Maybe<Post>
    /** Discussion posts */
    discussionPosts: PostList
    /** Duplicate Accounts */
    duplicateAccounts: Array<DuplicateAccount>
    /** Email template string */
    emailTemplate: Scalars['String']
    /** Data for the Summary page for the team's Engagement Report */
    engagementReportSummary: EngagementReportSummary
    /** Data for the Team page for the team's Engagement Report */
    engagementReportTeam: EngagementReportTeam
    /** A season in enrollment period */
    enrollmentSeason?: Maybe<Season>
    /** Status of enterprise SSO features for user subscription */
    enterpriseSsoStatus: SsoFeatureSet
    /** Get event data */
    event: Event
    /** Event Hosts with most popular events */
    eventExpertHosts?: Maybe<Array<EventSpeaker>>
    /** Related reources for an event */
    eventRelatedResources: Array<EventRelatedResources>
    /** RSVP for event and current user */
    eventRsvp?: Maybe<EventAttendee>
    /** Get events data by ids */
    eventsByIds: Array<Event>
    /** Past events */
    eventsPastFeed: EventsPastFeed
    /** Returns the ext_id of an AiChat for a given user */
    exchangeChatIdForExtId: Scalars['String']
    /** Popular Events of the selected Host */
    expertHostFeaturedEvents?: Maybe<Array<Event>>
    /** Featured CCL Courses */
    featuredCclCourses?: Maybe<Array<CclCourse>>
    /** Featured posts */
    featuredPosts: Array<FeaturedPost>
    /** Artifacts filtered down to certain parameters */
    filteredArtifacts?: Maybe<FilteredArtifacts>
    /** Filtered users */
    filteredUsers: FilteredUserSearchList
    /** Get a bookmark by id */
    findBookmarkById?: Maybe<Bookmark>
    /** Sanity function */
    function?: Maybe<Function>
    /** User progress on a given learning goal, as a percentage */
    goalProgress?: Maybe<Scalars['Int']>
    group?: Maybe<Group>
    /** Discussion group member page */
    groupMembers: GroupMemberList
    /** Discussion group page */
    groups: GroupFeed
    /** Guides from Sanity CMS */
    guidesBySanityTagGroup: Array<Unit>
    /** Guides from Sanity CMS */
    guidesForDiscovery: Array<Unit>
    /** Data of dashboard home page */
    homeDashboard: HomeDashboard
    /** Recently viewed content displayed on the homepage */
    homepageRecentlyViewedContent: Array<HomepageRecentlyViewedItem>
    /** Industries with posts */
    industries: Array<Industry>
    /** Inline posts */
    inlinePosts: Array<InlinePost>
    /** Invoice */
    invoice: Invoice
    /** The last season */
    lastActiveSeason?: Maybe<Season>
    /** Latest artifacts from Sanity */
    latestArtifacts?: Maybe<Array<Artifact>>
    /** The table of lessons for the content in the viewer */
    lessonViewer?: Maybe<LessonViewer>
    /** Live CCL Courses that include the specified guide */
    liveCoursesIncludingGuide?: Maybe<Array<CclCourse>>
    /** Marketplace Course Details */
    marketingCourse: MarketingCourse
    /**
     * All CCL Topics assigned to
     *     Legacy Marketing Courses
     */
    marketingCourseCclTopics?: Maybe<Array<CclTopic>>
    /** Courses details from Sanity */
    marketingCourseDetails?: Maybe<CourseDetails>
    /** Marketing Courses */
    marketingCourses: Array<MarketingCourse>
    /** Courses index from Sanity */
    marketingCoursesIndex: CourseIndex
    /** Experts index */
    marketingExperts: ExpertsIndex
    /** Marketing Homepage from Sanity */
    marketingHomepage: Homepage
    /** Site Settings from Sanity */
    marketingSiteSettings: SiteSettings
    /** Returns User data when @mentioned in a text editor */
    mentionSearch: Array<UserMention>
    /** Status of a user completing a module and overall program progress percent */
    moduleCompletionStatus: ModuleCompletionStatus
    /** most recent activity for a collection per each user shared with */
    mostRecentCollectionActivityPerUser: Array<CollectionActivity>
    /** Newest content */
    newestContent: Array<CmsSection>
    /** Recommended content */
    onboardingInfo: OnboardingInfo
    /** Open positions page from Sanity */
    openPositionsPage: Page
    /** Pinned posts */
    pinnedPosts: Array<Post>
    /** Plan details for the plans we offer within the app */
    plansForSale: Array<PlanForSale>
    /** Podcast details from Sanity */
    podcast?: Maybe<PodcastDetails>
    /** Podcast index from Sanity */
    podcastIndex: PodcastIndex
    /** Popular Cohort Conversations */
    popularCohortPosts: Array<Post>
    /** list of the most popular events denoted by attendee count */
    popularEventsPastFeed: PopularEventsPastFeed
    /** Popular posts */
    popularPosts: Array<Post>
    /** Discussion popular posts with filtering */
    popularPostsFiltering?: Maybe<GroupPostFeed>
    /** Find posts and responses by user names */
    postContributorsFilter: Array<PostContributor>
    /** Posts Search */
    postsSearch: PostsSearch
    /** A season in pre enrollment period */
    preEnrollmentSeason?: Maybe<Season>
    /** Projected upcoming invoice for subscription upgrade */
    previewUpcomingInvoice?: Maybe<StripeUpcomingInvoice>
    /** Marketing Pricing page from Sanity */
    pricingPage: PricingPage
    /** Privacy policy page from Sanity */
    privacyPolicyPage: Page
    /** Artifact Author Profile */
    profile?: Maybe<Profile>
    /** A project deliverable. */
    projectDeliverable?: Maybe<ProjectDeliverable>
    /** Projects */
    projects?: Maybe<ContentFeed>
    /** Public events */
    publicEvents?: Maybe<Array<Event>>
    /** Returns a collection of recent AI chats */
    recentChats: AiChatConnection
    /** Returns a count of recent AI chats */
    recentChatsCount: Scalars['Int']
    /** Recently viewed content */
    recentlyViewedContent: Array<RecentlyViewedContent>
    /** Recommended collection */
    recommendedCollection: Collection
    /** All of a users current recommended collections */
    recommendedCollections: Array<Collection>
    /** Recommended content for the feed in the user homepage */
    recommendedContent: RecommendedContent
    /** Recommended courses */
    recommendedCourses: Array<CclCourse>
    /** list of all BookmarkFolders */
    reforgeCollections?: Maybe<Array<BookmarkFolder>>
    /**
     * Reforge usages
     * @deprecated No longer used
     */
    reforgeUsages: Array<ReforgeUsage>
    /** Reimbursement page from Sanity */
    reimbursementPage: Page
    /** Related blog posts by subtopics */
    relatedBlogPosts: Array<BlogPost>
    /** Related Courses */
    relatedCourses?: Maybe<Array<SanityCourse>>
    /** Related guides by subtopics */
    relatedGuides: Array<Unit>
    /** Tag group from Sanity CMS */
    sanityTagGroup?: Maybe<TagGroup>
    /** Tag groups from Sanity CMS */
    sanityTagGroups: Array<TagGroup>
    /** Unit from Sanity CMS */
    sanityUnit?: Maybe<Unit>
    /** Artifacts and guides that the user has saved */
    savedArtifactsAndGuidesForUser?: Maybe<Array<Bookmark>>
    /** Artifacts that the user has saved */
    savedArtifactsForUser?: Maybe<Array<Bookmark>>
    /** Saved count for artifact */
    savedCountPerArtifact?: Maybe<Array<SavedCountPerArtifact>>
    /** Whether users progress through a scheduled week has reached the threshold for intervention */
    scheduledWeekProgressPercentHasReachedThresholdForIntervention?: Maybe<
      Scalars['Boolean']
    >
    /** search company names by string */
    searchCompany?: Maybe<Array<Scalars['String']>>
    /** search company names using clearbit */
    searchCompanyWithClearbit?: Maybe<Array<ClearbitCompany>>
    /** Search documents */
    searchDocuments?: Maybe<SearchDocumentList>
    /** Top Search Results */
    searchDocumentsTopResults?: Maybe<SearchDocumentTopResult>
    /** Search suggestions */
    searchSuggestions: Array<Scalars['String']>
    /** A season. */
    season: Season
    /** Similar post suggestions */
    similarPosts: Array<SimilarPost>
    /** Similarity search */
    similaritySearch: SimilaritySearchResponse
    /** Data for snapshot landing pages */
    snapshotLandingPage: SnapshotLandingPage
    /** SSO Authorization for a member */
    ssoAuthorization?: Maybe<SsoAuthorization>
    /** Starting balance (account credit) for a user */
    startingBalance: Scalars['Int']
    /** Subscription join request */
    subscriptionJoinRequest?: Maybe<SubscriptionJoinRequest>
    /** Preview of a given team. */
    subscriptionTeamPreview?: Maybe<TeamPreview>
    /** Artifact subtopic */
    subtopic?: Maybe<ArtifactSubtopic>
    /** Artifact subtopics */
    subtopics?: Maybe<Array<ArtifactSubtopic>>
    /** Returns a collection of query suggestions */
    suggestedPrompts: Array<Scalars['String']>
    /** Course session details for course session hosts */
    supplyCourseSession: SupplyCourseSession
    /** Whether the tax id exists and user is considered exempt based on it */
    taxIdReverseChargeStatus?: Maybe<TaxIdReverseChargeStatus>
    /** Customer Tax IDs */
    taxIds: Array<StripeCustomerTaxId>
    /** Tax information based on billing address and tax ids */
    taxInfo?: Maybe<Array<TaxInfoItem>>
    /** All users who are members on the current user's subscription. */
    teamMembers?: Maybe<Array<User>>
    /** Returns team User data when @mentioned in a text editor */
    teamMentionSearch: Array<UserMention>
    /** Teams Contact Page from Sanity CMS */
    teamsGetInTouchPage?: Maybe<Page>
    /** Teams Marketing Page from Sanity CMS */
    teamsMarketingPage?: Maybe<Page>
    /** Terms of service page from Sanity */
    termsOfServicePage: Page
    /** User testimonials for acquisition */
    testimonials: Array<Testimonial>
    /** A list of supported timezones. */
    timezones: Array<Scalars['String']>
    /** Sanity topic */
    topic?: Maybe<Topic>
    /** Artifact topics */
    topics: Array<ArtifactTopic>
    /** Total number of courses offered by Reforge */
    totalCourseCount: Scalars['Int']
    /** Upcoming course sessions */
    upcomingCourseSessions?: Maybe<Array<CclCourseSession>>
    /** Popular posts that may be useful to feature */
    upcomingPosts: Array<Post>
    /** The upcoming season. */
    upcomingSeason?: Maybe<Season>
    /** UQ questionnaire */
    uqQuestionnaire?: Maybe<UqQuestionnaire>
    /** UQ user answers */
    uqUserAnswers: UqUserAnswers
    /** A user. */
    user?: Maybe<User>
    /** Ai coaching sessions */
    userCoachingSessions: Array<CoachingSession>
    /** Data for the Cohort Viewer */
    userCohort?: Maybe<UserCohort>
    /** Users in user cohort. */
    userCohortUsers?: Maybe<Array<User>>
    /** Count of the users in the user cohort. */
    userCohortUsersCount?: Maybe<Scalars['Int']>
    /** Information for all of a users UserCohorts */
    userCohorts: Array<UserCohort>
    /** User course session */
    userCourse?: Maybe<UserCourse>
    /** List of non deleted email addresses for the current user */
    userEmails: Array<UserEmail>
    /** list of Past Events user RSVP'd to or started watching */
    userEventsPastFeed: UserEventsPastFeed
    /** User subscription info for patronage */
    userPatronage: UserPatronage
    /** Payment methods stored on Stripe. */
    userPaymentMethods: Array<StripePaymentMethod>
    /** Payment sources stored on Stripe. */
    userPaymentSources?: Maybe<Array<StripePaymentSources>>
    /** Users feed. */
    usersFeed?: Maybe<UsersFeed>
    /** validates user email */
    validateEmail: ValidateEmail
    /** Artifacts that the user has viewed */
    viewedArtifactsForUser?: Maybe<Array<ViewedArtifact>>
  }

/** Queries to the Graph. In Rest this would be GET. */
export type QueryActivityListArgs = {
  filter?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryAiChatsArgs = {
  userId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryAiPersonalizationArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryAiSessionHistoryArgs = {
  sessionId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactAnnotationsArgs = {
  artifactSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactAnnotationsCountArgs = {
  artifactSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactAuthorCoursesArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactAuthorProfilesArgs = {
  slug: Array<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactCommentsArgs = {
  artifactId: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactCommentsCountArgs = {
  artifactId: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactContributorsArgs = {
  artifactSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactOnboardingRecommendationsArgs = {
  lastId?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactTopicArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactsByIdsArgs = {
  ids: Array<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryArtifactsForSubtopicArgs = {
  max?: InputMaybe<Scalars['Int']>
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryAttendeesFeedArgs = {
  eventId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBlogIndexArgs = {
  category?: InputMaybe<Scalars['String']>
  lastId?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  reverse?: InputMaybe<Scalars['Boolean']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBlogPostArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBlogPostsForTopicArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBookmarkFolderArgs = {
  folderId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBookmarksFeedArgs = {
  filters?: InputMaybe<BookmarkFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBookmarksFromFolderArgs = {
  folderId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryBulkRsvpdAtForCohortArgs = {
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCaseStudyArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCclCourseArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCclCoursesArgs = {
  availability?: InputMaybe<Array<Scalars['String']>>
  discoverable?: InputMaybe<Scalars['Boolean']>
  topicSlugs?: InputMaybe<Array<Scalars['String']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCmsContentArgs = {
  cmsSectionId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCmsProgramArgs = {
  cmsProgramId?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCmsSectionArgs = {
  contentType: CmsSectionContentType
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortDashboardScheduledWeekArgs = {
  cohortSlug: Scalars['String']
  weekId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortPassesArgs = {
  userId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortPostArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  topicSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortTeamArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCohortTeamsArgs = {
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCollectionActivityArgs = {
  id: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCompletedModuleUsersCountArgs = {
  cmsModuleId?: InputMaybe<Scalars['ID']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryConceptsArgs = {
  filters?: InputMaybe<ContentFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryContentViewerArgs = {
  cmsType: Scalars['String']
  path: Scalars['String']
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseBlocksArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseConceptsArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseEventArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
  sanityId: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCoursePreviewArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseSessionArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseSessionDetailArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCourseSnapshotArgs = {
  snapshotType: Scalars['String']
  token: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCoursesForTopicAndFunctionArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryCurrentUserCohortPostsByTopicCountArgs = {
  slug?: InputMaybe<Scalars['String']>
  topicSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryDiscussionPostArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryDiscussionPostsArgs = {
  audience?: InputMaybe<Array<Scalars['String']>>
  businessModels?: InputMaybe<Array<Scalars['String']>>
  contributors?: InputMaybe<Array<Scalars['String']>>
  filter?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['String']>
  industries?: InputMaybe<Array<Scalars['String']>>
  intersectingGroups?: InputMaybe<Array<Scalars['String']>>
  page?: InputMaybe<Scalars['Int']>
  postSource: PostSource
  postType?: InputMaybe<Array<Scalars['String']>>
  sortBy?: InputMaybe<Scalars['String']>
  topics?: InputMaybe<Array<Scalars['String']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEmailTemplateArgs = {
  templateType: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEventArgs = {
  eventId?: InputMaybe<Scalars['ID']>
  eventUuid?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEventRelatedResourcesArgs = {
  eventId: Scalars['ID']
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEventRsvpArgs = {
  eventId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEventsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryEventsPastFeedArgs = {
  filters?: InputMaybe<EventFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryExchangeChatIdForExtIdArgs = {
  chatId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryExpertHostFeaturedEventsArgs = {
  userId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryFilteredArtifactsArgs = {
  filters?: InputMaybe<ArtifactFilters>
  lastId?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryFilteredUsersArgs = {
  filters?: InputMaybe<UserSearchFilters>
  page?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryFindBookmarkByIdArgs = {
  bookmarkId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryFunctionArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGoalProgressArgs = {
  folderId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGroupArgs = {
  groupSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGroupMembersArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  slug: Scalars['String']
  sortBy?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  onlyWithoutMembership?: InputMaybe<Scalars['Boolean']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGuidesBySanityTagGroupArgs = {
  limit?: InputMaybe<Scalars['Int']>
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryGuidesForDiscoveryArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryInlinePostsArgs = {
  cmsSectionId?: InputMaybe<Scalars['ID']>
  sanityId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryInvoiceArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryLatestArtifactsArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryLessonViewerArgs = {
  cmsModuleSlug: Scalars['String']
  cmsProgramSlug: Scalars['String']
  cmsSectionParentSlug?: InputMaybe<Scalars['String']>
  cmsSectionSlug: Scalars['String']
  path: Scalars['String']
  referringPostId?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryLiveCoursesIncludingGuideArgs = {
  guideSlug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMarketingCourseArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMarketingCourseCclTopicsArgs = {
  courseType?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMarketingCourseDetailsArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMarketingCoursesArgs = {
  courseType?: InputMaybe<Scalars['String']>
  topicSlugs?: InputMaybe<Array<Scalars['String']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMentionSearchArgs = {
  nameQuery: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryModuleCompletionStatusArgs = {
  cmsSectionId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryMostRecentCollectionActivityPerUserArgs = {
  collectionId: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPodcastArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPodcastIndexArgs = {
  lastId?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  reverse?: InputMaybe<Scalars['Boolean']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPopularCohortPostsArgs = {
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPopularPostsFilteringArgs = {
  filters?: InputMaybe<PopularPostFilter>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPostContributorsFilterArgs = {
  excludeSlugs?: InputMaybe<Array<Scalars['String']>>
  groupSlug?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  slugs?: InputMaybe<Array<Scalars['String']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPostsSearchArgs = {
  query: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryPreviewUpcomingInvoiceArgs = {
  numCohortPasses?: InputMaybe<Scalars['Int']>
  planName: Scalars['String']
  subscriptionId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryProfileArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryProjectDeliverableArgs = {
  deliverableId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryProjectsArgs = {
  filters?: InputMaybe<ContentFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRecentChatsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRecommendedCollectionArgs = {
  swimlaneId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRecommendedContentArgs = {
  cursor?: InputMaybe<RecommendedContentFeedCursorInput>
  limit: Scalars['Int']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRelatedBlogPostsArgs = {
  subtopicIds: Array<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRelatedCoursesArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryRelatedGuidesArgs = {
  subtopicIds: Array<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySanityTagGroupArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySanityUnitArgs = {
  contentMode?: InputMaybe<Scalars['String']>
  courseId?: InputMaybe<Scalars['String']>
  courseSessionId?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySavedArtifactsAndGuidesForUserArgs = {
  userId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySavedArtifactsForUserArgs = {
  userId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySavedCountPerArtifactArgs = {
  artifactIds?: InputMaybe<Array<Scalars['String']>>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryScheduledWeekProgressPercentHasReachedThresholdForInterventionArgs = {
  scheduledWeekId: Scalars['ID']
  threshold?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySearchCompanyArgs = {
  kind?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySearchCompanyWithClearbitArgs = {
  search?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySearchDocumentsArgs = {
  page?: InputMaybe<Scalars['Int']>
  path?: InputMaybe<Scalars['String']>
  program?: InputMaybe<Scalars['String']>
  query?: InputMaybe<Scalars['String']>
  topic?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySearchDocumentsTopResultsArgs = {
  query?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySearchSuggestionsArgs = {
  query: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySeasonArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySimilarPostsArgs = {
  title: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySimilaritySearchArgs = {
  initiator?: InputMaybe<Scalars['String']>
  query: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySnapshotLandingPageArgs = {
  snapshotType: Scalars['String']
  token: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySsoAuthorizationArgs = {
  email: Scalars['String']
  loginParams?: InputMaybe<LoginParamsInput>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySubscriptionJoinRequestArgs = {
  id?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySubscriptionTeamPreviewArgs = {
  loginParams?: InputMaybe<LoginParamsInput>
  memberToken: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySubtopicArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySubtopicsArgs = {
  subtopicSlug?: InputMaybe<Scalars['String']>
  topicSlug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySuggestedPromptsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QuerySupplyCourseSessionArgs = {
  courseSessionId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryTaxInfoArgs = {
  productKeys: Array<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryTeamMentionSearchArgs = {
  nameQuery: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryTopicArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryTopicsArgs = {
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUpcomingCourseSessionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUqQuestionnaireArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserArgs = {
  id: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserCohortArgs = {
  slug: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserCohortUsersArgs = {
  cmsSectionId?: InputMaybe<Scalars['ID']>
  includeCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  onlyWithAvatar?: InputMaybe<Scalars['Boolean']>
  random?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserCohortUsersCountArgs = {
  slug?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserCohortsArgs = {
  descendingByStartDate?: InputMaybe<Scalars['Boolean']>
  timePeriod?: InputMaybe<Scalars['String']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserCourseArgs = {
  courseSessionId: Scalars['ID']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserEventsPastFeedArgs = {
  filters?: InputMaybe<EventFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserPatronageArgs = {
  externalId: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserPaymentMethodsArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUserPaymentSourcesArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryUsersFeedArgs = {
  cohortSlug?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryValidateEmailArgs = {
  email: Scalars['String']
}

/** Queries to the Graph. In Rest this would be GET. */
export type QueryViewedArtifactsForUserArgs = {
  userId: Scalars['ID']
}

/** Reactions */
export type Reaction = {
  __typename: 'Reaction'
  /** ID */
  id: Scalars['ID']
  /** Reaction kind */
  kind: ReactionKind
  /** User associated with the reaction */
  user: User
}

/** Reaction kind */
export enum ReactionKind {
  /** agree */
  AGREE = 'agree',
  /** insightful */
  INSIGHTFUL = 'insightful',
  /** thank_you */
  THANK_YOU = 'thank_you',
  /** upvote */
  UPVOTE = 'upvote',
  /** wave */
  WAVE = 'wave'
}

/** Recently viewed content */
export type RecentlyViewedContent = {
  __typename: 'RecentlyViewedContent'
  /** Content name */
  name: Scalars['String']
  /** Content path */
  path: Scalars['String']
}

/** An item of content recommended for a user */
export type RecommendedContent = {
  __typename: 'RecommendedContent'
  /** Cursor for pagination */
  cursor?: Maybe<RecommendedContentCursor>
  /** Results of the recommended content query */
  results: Array<RecommendedContentResults>
}

/** Certain fields of a piece of recommended content, used as cursor for paginating recommended content */
export type RecommendedContentCursor = {
  __typename: 'RecommendedContentCursor'
  /** ID of the associated CclSyncMap record */
  cclSyncMapId?: Maybe<Scalars['ID']>
  /** Date the content was first published */
  firstPublishedAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Cursor for pagination */
export type RecommendedContentFeedCursorInput = {
  /** Primary key ID of item in the CclSyncMap table */
  cclSyncMapId?: InputMaybe<Scalars['String']>
  /** Date the content was first published */
  firstPublishedAt?: InputMaybe<Scalars['String']>
}

/** An item of content recommended for a user */
export type RecommendedContentResults = {
  __typename: 'RecommendedContentResults'
  /** Authors of the content */
  authors: Array<Scalars['JSON']>
  /** ID of the associated CclSyncMap record */
  cclSyncMapId: Scalars['ID']
  /** Number of comments on the content. Null for guides */
  commentCount?: Maybe<Scalars['Int']>
  /** Alt text for the company logo */
  companyLogoAltText?: Maybe<Scalars['String']>
  /** URL for the company logo */
  companyLogoUrl?: Maybe<Scalars['String']>
  /**
   * Name of the company where the \
   *                    creator was when they created the content. Null for guides
   */
  companyName?: Maybe<Scalars['String']>
  /** An ID for a bookmarkable section of content */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Description of the content */
  description?: Maybe<Scalars['String']>
  /** Time to complete the content. Null for artifacts */
  estimatedTimeInMinutes?: Maybe<Scalars['Int']>
  /** Whether the content is available for non-members */
  isFree?: Maybe<Scalars['Boolean']>
  /** Type of content */
  kind: Scalars['String']
  /** Value of local_id in the associated CCL record */
  localId: Scalars['ID']
  /** Date content was first published */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Sanity ID for the content */
  sanityId: Scalars['ID']
  /** Unique slug string for content */
  slug: Scalars['String']
  /** Number of steps in the content. Null for artifacts */
  stepsCount?: Maybe<Scalars['Int']>
  /** URL for the content thumbnail */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Title of the content */
  title: Scalars['String']
  /** Number of views on the content. Null for guides */
  viewerCount?: Maybe<Scalars['Int']>
}

/** An item being recommended */
export type RecommendedItem = {
  __typename: 'RecommendedItem'
  /** Authors of the content */
  authors: Array<Scalars['JSON']>
  /** ID of the associated CclSyncMap record */
  cclSyncMapId: Scalars['ID']
  /** Number of comments on the content. Null for guides */
  commentCount?: Maybe<Scalars['Int']>
  /** Alt text for the company logo */
  companyLogoAltText?: Maybe<Scalars['String']>
  /** URL for the company logo */
  companyLogoUrl?: Maybe<Scalars['String']>
  /**
   * Name of the company where the \
   *                    creator was when they created the content. Null for guides
   */
  companyName?: Maybe<Scalars['String']>
  /** An ID for a bookmarkable section of content */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Description of the content */
  description?: Maybe<Scalars['String']>
  /** Draft template label */
  draftTemplateLabel?: Maybe<Scalars['String']>
  /** Draft template name */
  draftTemplateName?: Maybe<Scalars['String']>
  /** Time to complete the content. Null for artifacts */
  estimatedTimeInMinutes?: Maybe<Scalars['Int']>
  /** ccl_entity id */
  id: Scalars['ID']
  /** Whether the content is available for non-members */
  isFree?: Maybe<Scalars['Boolean']>
  /** Type of content */
  kind: Scalars['String']
  /** Value of local_id in the associated CCL record */
  localId: Scalars['ID']
  /** Whether the content is pay gated */
  previewable?: Maybe<Scalars['Boolean']>
  /** Date content was first published */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Sanity ID for the content */
  sanityId: Scalars['ID']
  /** Unique slug string for content */
  slug: Scalars['String']
  /** Number of steps in the content. Null for artifacts */
  stepsCount?: Maybe<Scalars['Int']>
  /** URL for the content thumbnail */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** Title of the content */
  title: Scalars['String']
  /** Number of views on the content. Null for guides */
  viewerCount?: Maybe<Scalars['Int']>
}

/** Comparisons to Reforge for membership page from Sanity CMS */
export type ReforgeComparison = {
  __typename: 'ReforgeComparison'
  /** Audience */
  audience?: Maybe<Scalars['String']>
  /** Cost */
  cost?: Maybe<Scalars['String']>
  /** Name */
  name?: Maybe<Scalars['String']>
  /** Relevance */
  relevance?: Maybe<Scalars['String']>
  /** Time Commitment */
  timeCommitment?: Maybe<Scalars['String']>
}

/** Content types included in a collection */
export enum ReforgeContent {
  /** CclArtifact */
  ARTIFACT = 'Artifact',
  /** CclCourse */
  COURSE = 'Course',
  /** CclGuide */
  GUIDE = 'Guide'
}

/** Reforge Usage Type */
export type ReforgeUsage = {
  __typename: 'ReforgeUsage'
  /** Checklist copy */
  checklistCopy: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Onboarding copy */
  onboardingCopy: Scalars['String']
  /** Onboarding subcopy */
  onboardingSubcopy: Scalars['String']
}

/** Autogenerated input type of RegenerateCourseSnapshot */
export type RegenerateCourseSnapshotInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Type of snapshot to regenerate */
  snapshotType: Scalars['String']
  /** ID of the user course */
  userCourseId: Scalars['ID']
}

/** Autogenerated return type of RegenerateCourseSnapshot */
export type RegenerateCourseSnapshotPayload = {
  __typename: 'RegenerateCourseSnapshotPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** ID of the user course */
  userCourseId?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of ReinstateSeat */
export type ReinstateSeatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the seat */
  id: Scalars['ID']
}

/** Autogenerated return type of ReinstateSeat */
export type ReinstateSeatPayload = {
  __typename: 'ReinstateSeatPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription that seat belongs to */
  subscription?: Maybe<Subscription>
  /** True if seat reinstatement was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of RemoveAiChat */
export type RemoveAiChatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the chat to delete */
  id: Scalars['ID']
}

/** Autogenerated return type of RemoveAiChat */
export type RemoveAiChatPayload = {
  __typename: 'RemoveAiChatPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors deleting the chat */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the deleted chat */
  id: Scalars['ID']
}

/** Autogenerated input type of RemoveAnnotationReaction */
export type RemoveAnnotationReactionInput = {
  /** Annotation ID */
  annotationId: Scalars['String']
  /** Artifact ID */
  artifactId: Scalars['String']
  /** Artifact Title */
  artifactTitle?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction ID */
  reaction: Scalars['String']
  /** User ID */
  userId: Scalars['String']
}

/** Autogenerated return type of RemoveAnnotationReaction */
export type RemoveAnnotationReactionPayload = {
  __typename: 'RemoveAnnotationReactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of RemoveArtifactComment */
export type RemoveArtifactCommentInput = {
  /** Artifact ID */
  artifactCommentId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of RemoveArtifactComment */
export type RemoveArtifactCommentPayload = {
  __typename: 'RemoveArtifactCommentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of RemoveArtifactCommentReaction */
export type RemoveArtifactCommentReactionInput = {
  /** Artifact comment ID */
  artifactCommentId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction */
  reaction: Scalars['String']
  /** User ID */
  userId: Scalars['String']
}

/** Autogenerated return type of RemoveArtifactCommentReaction */
export type RemoveArtifactCommentReactionPayload = {
  __typename: 'RemoveArtifactCommentReactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of RemoveReaction */
export type RemoveReactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reaction ID */
  id: Scalars['String']
}

/** Autogenerated return type of RemoveReaction */
export type RemoveReactionPayload = {
  __typename: 'RemoveReactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error message for add reaction mutation */
  errors?: Maybe<Array<Scalars['String']>>
  /** The id of the deleted reaction */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of RemoveReply */
export type RemoveReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reply ID */
  id: Scalars['ID']
}

/** Autogenerated return type of RemoveReply */
export type RemoveReplyPayload = {
  __typename: 'RemoveReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the deleted reply */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of RemoveSeat */
export type RemoveSeatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the seat */
  id: Scalars['ID']
}

/** Autogenerated return type of RemoveSeat */
export type RemoveSeatPayload = {
  __typename: 'RemoveSeatPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription that seat belonged to */
  subscription?: Maybe<Subscription>
  /** True if seat removal was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of RemoveSubscriptionFromOrg */
export type RemoveSubscriptionFromOrgInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the org with subscription to remove */
  orgId: Scalars['String']
  /** ID of the subscription to remove */
  subscriptionId: Scalars['String']
}

/** Autogenerated return type of RemoveSubscriptionFromOrg */
export type RemoveSubscriptionFromOrgPayload = {
  __typename: 'RemoveSubscriptionFromOrgPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Org that subscription belongs to */
  org?: Maybe<Org>
}

/** Autogenerated input type of RemoveSubscriptionMember */
export type RemoveSubscriptionMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the member */
  id: Scalars['ID']
  /** Email of user to transfer subscription to */
  transferToEmail?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of RemoveSubscriptionMember */
export type RemoveSubscriptionMemberPayload = {
  __typename: 'RemoveSubscriptionMemberPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription that seat belonged to */
  subscription?: Maybe<Subscription>
  /** True if releasing or transfering a seat was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of RenameAiChat */
export type RenameAiChatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The ID of the AI chat */
  id: Scalars['ID']
  /** The new title of the AI chat */
  title: Scalars['String']
}

/** Autogenerated return type of RenameAiChat */
export type RenameAiChatPayload = {
  __typename: 'RenameAiChatPayload'
  /** The updated AI chat */
  aiChat: AiChat
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors renaming the chat */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of ReorderFiledBookmark */
export type ReorderFiledBookmarkInput = {
  /** Id of the bookmark of the FiledBookmark */
  bookmarkId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Id of bookmark folder of the FiledBookmark */
  folderId: Scalars['ID']
  /** position to move the FiledBookmark to */
  position: Scalars['Int']
}

/** Autogenerated return type of ReorderFiledBookmark */
export type ReorderFiledBookmarkPayload = {
  __typename: 'ReorderFiledBookmarkPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** all filed bookmarks of the bookmark_folder */
  filedBookmark?: Maybe<FiledBookmark>
  /** Old position of filed bookmark */
  oldPosition?: Maybe<Scalars['Int']>
}

/** A discussion reply made by a user. */
export type Reply = {
  __typename: 'Reply'
  /** Text of the reply */
  body?: Maybe<Scalars['String']>
  /** Text of the reply */
  bodyText?: Maybe<Scalars['String']>
  /** Comments on replies */
  comments: Array<Comment>
  /** Number of comments on reply */
  commentsCount?: Maybe<Scalars['Int']>
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Created at date in concise time */
  createdAtInWords: Scalars['String']
  /** Number of followees on reply */
  followeesCount?: Maybe<Scalars['Int']>
  /** ID */
  id: Scalars['ID']
  /** Post associated with the reply */
  post?: Maybe<Post>
  /** Reactions associated with the reply */
  reactions: Array<Reaction>
  /** ID of the associated post or team_post */
  replyableId: Scalars['ID']
  /** Updated at date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** User associated with the reply */
  user: User
}

/** The type of post a reply should be associated with */
export enum ReplyParent {
  /** Community post */
  COMMUNITY = 'community',
  /** Team post */
  TEAM = 'team'
}

/** Autogenerated input type of ReportCohortPostOrReply */
export type ReportCohortPostOrReplyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post ID */
  postId?: InputMaybe<Scalars['ID']>
  /** Reply ID */
  replyId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of ReportCohortPostOrReply */
export type ReportCohortPostOrReplyPayload = {
  __typename: 'ReportCohortPostOrReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** True if successfully reported */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of RestoreCollectionActivity */
export type RestoreCollectionActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Collection Activity ID */
  collectionActivityId: Scalars['ID']
}

/** Autogenerated return type of RestoreCollectionActivity */
export type RestoreCollectionActivityPayload = {
  __typename: 'RestoreCollectionActivityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The collection activity */
  collectionActivity: CollectionActivity
  /** The restored filed bookmark */
  filedBookmark: FiledBookmark
}

/** Marketing review from Sanity CMS */
export type Review = {
  __typename: 'Review'
  /** Review author name */
  authorName: Scalars['String']
  /** Review author position */
  authorPosition: Scalars['String']
  /**
   * Review author (complex portable content)
   * @deprecated Use authorName and authorPosition instead
   */
  complexAuthor: Scalars['JSON']
  /** Review content (complex portable content) */
  complexContent: Scalars['JSON']
  /** Review ID */
  id: Scalars['ID']
  /** Author image */
  image?: Maybe<Image>
}

/** Company coming from Sanity CMS */
export type SanityCompany = {
  __typename: 'SanityCompany'
  /** ID of the company. */
  id?: Maybe<Scalars['String']>
  /** Logo of the company. */
  logo?: Maybe<Image>
  /** Name of the company. */
  name?: Maybe<Scalars['String']>
  /** Slug for the company. */
  slug?: Maybe<Scalars['String']>
}

/** Data for course that comes directly from Sanity */
export type SanityCourse = {
  __typename: 'SanityCourse'
  /** The CourseBookmark Id if it exists */
  bookmarkId?: Maybe<Scalars['ID']>
  /** Course blocks for the course. */
  courseBlocks?: Maybe<Array<CourseBlock>>
  /** Creators from Sanity */
  creators?: Maybe<Array<ArtifactAuthor>>
  /** Hero image for the course */
  heroImage?: Maybe<Image>
  /** The ID of the course */
  id: Scalars['ID']
  /** Whether or not the course is available on-demand */
  isAvailableOnDemand?: Maybe<Scalars['Boolean']>
  /** Popular with roles */
  popularWithRoles?: Maybe<Array<Scalars['String']>>
  /** Short description of the course. */
  shortDescription?: Maybe<Scalars['String']>
  /** The url slug for the course */
  slug: Scalars['String']
  /** Testimonials for the course */
  testimonials?: Maybe<Array<SanityTestimonial>>
  /** The name of the course */
  title: Scalars['String']
  /** Trailer video for the course */
  trailerVideo?: Maybe<Scalars['JSON']>
  /** Upcoming course session. */
  upcomingSessions?: Maybe<Array<CourseSession>>
  /** What you will learn in the course */
  whatYouWillLearn?: Maybe<Scalars['JSON']>
  /** Who this course is for */
  whoThisIsFor?: Maybe<Scalars['JSON']>
}

/** Host existing only on the Marketing Course coming from Sanity CMS */
export type SanityHost = {
  __typename: 'SanityHost'
  /** Host bio */
  hostBio?: Maybe<Scalars['String']>
  /** Name of the host */
  hostName?: Maybe<Scalars['String']>
  /** Host photo */
  hostPicture?: Maybe<Image>
  /** ID of the host */
  id: Scalars['String']
}

/** Testimonials tags coming from Sanity CMS */
export type SanityTestimonial = {
  __typename: 'SanityTestimonial'
  /** Company. */
  company?: Maybe<SanityCompany>
  /** Header. */
  header?: Maybe<Scalars['String']>
  /** ID of the testimonial. */
  id?: Maybe<Scalars['String']>
  /** The testimonial quote. */
  quote?: Maybe<Scalars['String']>
  /** Subheader. */
  subheader?: Maybe<Scalars['String']>
}

/** Autogenerated input type of SaveCustomerTaxId */
export type SaveCustomerTaxIdInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Stripe tax id type */
  type: Scalars['String']
  /** Tax id value */
  value: Scalars['String']
}

/** Autogenerated return type of SaveCustomerTaxId */
export type SaveCustomerTaxIdPayload = {
  __typename: 'SaveCustomerTaxIdPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Saved Customer tax id object */
  taxId: StripeCustomerTaxId
}

/** Saved Artifact */
export type SavedCountPerArtifact = {
  __typename: 'SavedCountPerArtifact'
  /** artifact that was saved */
  artifactId: Scalars['ID']
  /** count of saved artifacts for this artifact_id */
  count: Scalars['Int']
}

/** A certain time period that users participate in a live program. */
export type ScheduledWeek = {
  __typename: 'ScheduledWeek'
  /** Whether the week contains only bonus material or not */
  bonus?: Maybe<Scalars['Boolean']>
  /** Whether the week is part of the core material or not */
  core?: Maybe<Scalars['Boolean']>
  /** A short sentence describing the goal of the week */
  description?: Maybe<Scalars['String']>
  /** When the week ends */
  endsAt?: Maybe<Scalars['String']>
  /** estimated time to complete all the scheduled items */
  estimatedTimeInMinutes?: Maybe<Scalars['Int']>
  /** The events scheduled for the week */
  events: Array<Event>
  /** The id of the scheduled week */
  id: Scalars['ID']
  /** Whether the scheduled week is current */
  isCurrentWeek?: Maybe<Scalars['Boolean']>
  /** The next module that should show on front door */
  nextModule?: Maybe<CmsModule>
  /** The number of cms_sections scheduled this week */
  numLessons?: Maybe<Scalars['Int']>
  /** The number of cms_modules scheduled this week */
  numModules?: Maybe<Scalars['Int']>
  /** The modules scheduled for the week */
  orderedCmsModules: Array<CmsModule>
  /** Is this a post cohort event */
  postCohort: Scalars['Boolean']
  /** A users progress through a scheduled week */
  progressPercent?: Maybe<Scalars['Int']>
  /** When the week starts */
  startsAt?: Maybe<Scalars['String']>
  /** A descriptive header for the week */
  title?: Maybe<Scalars['String']>
}

/** Search document */
export type SearchDocument = {
  __typename: 'SearchDocument'
  /** Cms program data */
  cmsProgram?: Maybe<CmsProgram>
  /** Content */
  content?: Maybe<Scalars['String']>
  /** Content tsvector tsearch */
  contentTsvectorTsearch?: Maybe<Scalars['String']>
  /** Created at date of document */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** ID of search document */
  id: Scalars['ID']
  /** Highlighted search content */
  pgSearchHighlight?: Maybe<Scalars['String']>
  /** Associated searchable object */
  searchable: Searchable
  /** Title of search document */
  title?: Maybe<Scalars['String']>
  /** Topic of search document */
  topic?: Maybe<Scalars['String']>
  /** Last updated at date of document */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** List of search documents with metadata */
export type SearchDocumentList = {
  __typename: 'SearchDocumentList'
  /** Search document results for query */
  results: Array<SearchDocument>
  /** Total number of search document results for query */
  totalCount: Scalars['Int']
}

/** hash of search documents representing top results for each category */
export type SearchDocumentTopResult = {
  __typename: 'SearchDocumentTopResult'
  /** Top results for user search */
  members: SearchDocumentTopResultCategory
  /** Top results for content */
  programContent: SearchDocumentTopResultCategory
}

/** hash of search documents representing top results for each category and total count */
export type SearchDocumentTopResultCategory = {
  __typename: 'SearchDocumentTopResultCategory'
  /** Top results for category */
  results: Array<SearchDocument>
  /** The count of all matching results */
  totalCount: Scalars['Int']
}

/** A search result associated with a search document */
export type Searchable = CmsSection | Comment | Post | Reply | User

/**
 * A season is a block of time when a live cohort happens. As of Fall 2022 can be more than one
 *                 active season at a time.
 */
export type Season = {
  __typename: 'Season'
  /**
   * UTC datetime stamp for when to show the pre-enrollment banner of the season.
   *                                            Nullable because older season dates have not been backfilled.
   */
  activeAt?: Maybe<Scalars['String']>
  /** The blog post url announcing the programs */
  blogPostAnnouncementUrl?: Maybe<Scalars['String']>
  /**
   * UTC datetime stamp for the end of the season.
   *                                         Nullable because older season dates have not been backfilled.
   */
  endsAt?: Maybe<Scalars['String']>
  /**
   * UTC datetime stamp for the enrollment close date of
   *                                                      the season. Nullable because placeholder season records can be
   *                                                      created without all date information.
   */
  enrollmentsCloseAt?: Maybe<Scalars['String']>
  /**
   * UTC datetime stamp for the enrollment open date of
   *                                         the season. Nullable because placeholder season records can be
   *                                         created without all date information.
   */
  enrollmentsOpenAt?: Maybe<Scalars['String']>
  /** Array of FAQ objects. */
  faqs?: Maybe<Array<Faq>>
  /** The season has started */
  hasStarted: Scalars['Boolean']
  /** Unique season ID. */
  id: Scalars['ID']
  /** Which season of the year (e.g. fall, spring...). */
  name: Scalars['String']
  /** The name and year of the following season (e.g. Spring 2022) */
  nextSeasonName: Scalars['String']
  /**
   * A link to NPS Survey shown to programs at the
   *                                                 end of cohort
   */
  npsSurveyLink?: Maybe<Scalars['String']>
  /** Text to display within the NPS survey banner */
  npsSurveyText?: Maybe<Scalars['String']>
  /** Is true only if enrollments are still open. */
  openForEnrollment: Scalars['Boolean']
  /** The pretty name for the season */
  prettyNameSeasonFirst: Scalars['String']
  /** When to show the blog post announcing the programs */
  showBlogPostAnnouncementAt?: Maybe<Scalars['String']>
  /** When to show the cohort viewer. */
  showCohortViewerAt?: Maybe<Scalars['String']>
  /**
   * UTC datetime stamp for the start of the season.
   *                                           Nullable because older season dates have not been backfilled.
   */
  startsAt?: Maybe<Scalars['String']>
  /** Which year the season is in (e.g. 2021 for Fall 2021) */
  year: Scalars['String']
}

/** A seat on a subscription. Each member on a subscription will occupy one seat. */
export type Seat = {
  __typename: 'Seat'
  /** Whether or not a member in this seat can enroll in a cohort */
  hasCohortAccess: Scalars['Boolean']
  /** ID */
  id: Scalars['ID']
  /** Whether or not the seat will remain at renewal */
  isRenewing: Scalars['Boolean']
  /**
   * Date the seat is going to be deleted.
   *                                                             Will be null if not scheduled for deletion.
   */
  scheduledForDeletionDate?: Maybe<Scalars['String']>
  /**
   * Which subscription + user association the
   *                                                                seat is assigned to.
   */
  subscriptionMember?: Maybe<SubscriptionMember>
}

/** Autogenerated input type of SelectFeaturedPosts */
export type SelectFeaturedPostsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The array of post ids to feature for the week */
  selectedPostIds: Array<Scalars['ID']>
  /** The start of the week for the selection */
  weekStartsAt?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of SelectFeaturedPosts */
export type SelectFeaturedPostsPayload = {
  __typename: 'SelectFeaturedPostsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** True if update was successful */
  success: Scalars['Boolean']
}

/** Autogenerated input type of SendEmailVerify */
export type SendEmailVerifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the email to verify */
  id: Scalars['ID']
}

/** Autogenerated return type of SendEmailVerify */
export type SendEmailVerifyPayload = {
  __typename: 'SendEmailVerifyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of the email to verify */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of SendMergeRequestEmail */
export type SendMergeRequestEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the duplicate account */
  id: Scalars['ID']
}

/** Autogenerated return type of SendMergeRequestEmail */
export type SendMergeRequestEmailPayload = {
  __typename: 'SendMergeRequestEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Whether the call was succesfull */
  success: Scalars['Boolean']
}

/** Autogenerated input type of SetDismissedUpdateCompanyBannerAt */
export type SetDismissedUpdateCompanyBannerAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of SetDismissedUpdateCompanyBannerAt */
export type SetDismissedUpdateCompanyBannerAtPayload = {
  __typename: 'SetDismissedUpdateCompanyBannerAtPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The updated user object */
  currentUser: User
}

/** Autogenerated input type of SetHasSeenDemographicsModal */
export type SetHasSeenDemographicsModalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of SetHasSeenDemographicsModal */
export type SetHasSeenDemographicsModalPayload = {
  __typename: 'SetHasSeenDemographicsModalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The updated user object */
  currentUser: User
}

/** Autogenerated input type of SetUserCohortPreferences */
export type SetUserCohortPreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** if true, set_activities_last_viewed_at to now */
  setActivitiesLastViewedAt?: InputMaybe<Scalars['Boolean']>
  /** Cohort slug */
  slug: Scalars['String']
}

/** Autogenerated return type of SetUserCohortPreferences */
export type SetUserCohortPreferencesPayload = {
  __typename: 'SetUserCohortPreferencesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of user cohort preference */
  id?: Maybe<Scalars['ID']>
  /** Success response */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of ShareBookmarkFolder */
export type ShareBookmarkFolderInput = {
  /** ID of bookmark folder to share */
  bookmarkFolderId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Returns true if sharing is due to a team goal being set */
  isLearningGoal?: InputMaybe<Scalars['Boolean']>
  /** List of User IDs if shared with individual users */
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

/** Autogenerated return type of ShareBookmarkFolder */
export type ShareBookmarkFolderPayload = {
  __typename: 'ShareBookmarkFolderPayload'
  /** The folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** the shared folder object */
  sharedFolders?: Maybe<Array<SharedFolder>>
}

/** Record of the sharing of a BookmarkFolder with other users */
export type SharedFolder = {
  __typename: 'SharedFolder'
  /** The BookmarkFolder that was shared */
  bookmarkFolder: BookmarkFolder
  /** ID */
  id: Scalars['ID']
  /** ID of the shared to party */
  sharedPartyId: Scalars['ID']
  /** Class of the shared party */
  sharedPartyType: Scalars['String']
  /** The current sharing status of the folder */
  status: SharedFolderStatus
  /** ID of the user who shared the folder */
  userId: Scalars['ID']
}

/** The Status of the Shared Folder */
export enum SharedFolderStatus {
  PRIVATE = 'private',
  SHARED = 'shared'
}

/** Similar post suggestion */
export type SimilarPost = {
  __typename: 'SimilarPost'
  /** Post age */
  age: Scalars['String']
  /** Post path */
  id: Scalars['String']
  /** Post reactions count */
  reactionsCount: Scalars['Int']
  /** Post response count */
  responseCount: Scalars['Int']
  /** Post title */
  title: Scalars['String']
}

/** Search result breadcrumb */
export type SimilaritySearchBreadcrumb = {
  __typename: 'SimilaritySearchBreadcrumb'
  /** Label of the breadcrumb item */
  label: Scalars['String']
  /** URL or path to the item */
  url: Scalars['String']
}

/** Similarity search content */
export type SimilaritySearchContent = {
  __typename: 'SimilaritySearchContent'
  /** Author of the content, if applicable */
  author?: Maybe<Scalars['String']>
  /** Navigation breadcrumbs for content */
  breadCrumbs: Array<SimilaritySearchBreadcrumb>
  /** The company of the author, if applicable */
  companyName?: Maybe<Scalars['String']>
  /** Type of the content (Lesson, Artifact) */
  contentType: SimilaritySearchContentType
  /** Highlight of content that matches the search */
  highlight: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Tags/labels associated with search result */
  tags: Array<Scalars['String']>
  /** URL to thumbnail image for result */
  thumbnail?: Maybe<Scalars['String']>
  /** Title */
  title: Scalars['String']
  /** URL or path to content */
  url: Scalars['String']
}

/** The type of the search result */
export enum SimilaritySearchContentType {
  ARTIFACT = 'Artifact',
  COURSE = 'Course',
  GUIDE = 'Guide',
  LESSON = 'Lesson'
}

/** Similarity search response */
export type SimilaritySearchResponse = {
  __typename: 'SimilaritySearchResponse'
  /** Unique ID for the search */
  id: Scalars['String']
  /** Similarity search results for a given query */
  results: Array<SimilaritySearchContent>
  /** Suggested search terms for low confidence queries */
  suggestions: Array<Scalars['String']>
}

/** Site SEO from Sanity CMS */
export type SiteSeo = {
  __typename: 'SiteSeo'
  /** Meta Description */
  metaDesc?: Maybe<Scalars['String']>
  /** Meta Title */
  metaTitle?: Maybe<Scalars['String']>
  /** Share Description */
  shareDesc?: Maybe<Scalars['String']>
  /** Share Graphic */
  shareGraphic?: Maybe<Scalars['JSON']>
  /** Share Title */
  shareTitle?: Maybe<Scalars['String']>
}

/** Site Settings from Sanity CMS */
export type SiteSettings = {
  __typename: 'SiteSettings'
  /** Cookie Consent */
  cookieConsent?: Maybe<CookieConsent>
  /** Site SEO */
  seo?: Maybe<SiteSeo>
  /** Site title */
  title?: Maybe<Scalars['String']>
}

/** A user\s Slack account with Reforge. */
export type SlackAccount = {
  __typename: 'SlackAccount'
  /** When the Slack account was created */
  createdAt: Scalars['String']
  /** Unique ID for a Reforge Slack user */
  slackUserId: Scalars['String']
  /** The Reforge user associated with the slack account */
  user: User
}

/** Snapshot representing program completion  */
export type Snapshot = {
  __typename: 'Snapshot'
  /** ID */
  id: Scalars['ID']
  /** Whether we are currently within the snapshot release window */
  released: Scalars['Boolean']
  /** Snapshot identifier used in /snapshots/:token */
  token: Scalars['String']
  /** User cohort associated with the snapshot */
  userCohort: UserCohort
}

/** Data associated with snapshot */
export type SnapshotLandingPage = {
  __typename: 'SnapshotLandingPage'
  /** ID of the program associated with the snapshot */
  cmsProgramId: Scalars['ID']
  /** Link to showcase content of the program associated with the snapshot */
  contentLink: Scalars['String']
  /** First name of the snapshot holder */
  firstName: Scalars['String']
  /** URL pointing to the image of the snapshot in storage */
  imageUrl?: Maybe<Scalars['String']>
  /** Description of the program associated with the snapshot */
  programDescription: Scalars['String']
  /** Name of the program associated with the snapshot */
  programName: Scalars['String']
  /** Name of the season associated with the snapshot */
  seasonName: Scalars['String']
  /** ID of the CMS section for the program showcase content */
  showcaseContentId: Scalars['ID']
  /** Slug of the program associated with the snapshot */
  slug: Scalars['String']
}

/** SSO Authorization Information */
export type SsoAuthorization = {
  __typename: 'SsoAuthorization'
  /** URL to initiate SSO authorization */
  authorizationUrl?: Maybe<Scalars['String']>
  /** Is SSO active for this object */
  isSsoActive: Scalars['Boolean']
}

/** SSO feature set */
export type SsoFeatureSet = {
  __typename: 'SsoFeatureSet'
  /** Status of the SAML feature */
  saml: SsoFeatureStatus
  /** Status of the SCIM feature */
  scim: SsoFeatureStatus
}

/** SSO feature status */
export type SsoFeatureStatus = {
  __typename: 'SsoFeatureStatus'
  /** Errors associated with the feature */
  errors: Array<Scalars['String']>
  /** Status of the SSO feature */
  status: SsoFeatureStatusEnum
}

/** The status values of an SSO feature status */
export enum SsoFeatureStatusEnum {
  /** Feature is fully configured and ready for use. */
  ACTIVE = 'active',
  /** Feature is enabled but is encountering errors. */
  ERROR = 'error',
  /** Feature has not been configured/enabled. */
  INACTIVE = 'inactive',
  /** Feature has been configured but waiting on customer to complete setup. */
  INCOMPLETE = 'incomplete'
}

/** Top level queries for SSO features */
export type SsoQuery = {
  /** Status of enterprise SSO features for user subscription */
  enterpriseSsoStatus: SsoFeatureSet
}

/** ACH Credit Transfer information. */
export type StripeAchCreditTransfer = {
  __typename: 'StripeAchCreditTransfer'
  /** Account number. */
  accountNumber: Scalars['String']
  /** Bank name. */
  bankName: Scalars['String']
  /** Fingerprint . */
  fingerprint: Scalars['String']
  /** Routing number. */
  routingNumber: Scalars['String']
  /** Swift code. */
  swiftCode: Scalars['String']
}

/** Customer billing address */
export type StripeAddress = {
  __typename: 'StripeAddress'
  /** City, district, suburb, town, or village. */
  city: Scalars['String']
  /** Two-letter country code (ISO 3166-1 alpha-2). */
  country: Scalars['String']
  /** Address line 1 (e.g., street, PO Box, or company name). */
  line1: Scalars['String']
  /** Address line 2 (e.g., apartment, suite, unit, or building). */
  line2?: Maybe<Scalars['String']>
  /** ZIP or postal code. */
  postalCode: Scalars['String']
  /** State, county, province, or region. */
  state?: Maybe<Scalars['String']>
}

/** Stripe address input. */
export type StripeAddressInput = {
  /** City, district, suburb, town, or village. */
  city: Scalars['String']
  /** Two-letter country code (ISO 3166-1 alpha-2). */
  country: Scalars['String']
  /** Address line 1 (e.g., street, PO Box, or company name). */
  line1: Scalars['String']
  /** Address line 2 (e.g., apartment, suite, unit, or building). */
  line2?: InputMaybe<Scalars['String']>
  /** ZIP or postal code. */
  postalCode: Scalars['String']
  /** State, county, province, or region. */
  state?: InputMaybe<Scalars['String']>
}

/** User payment methods stored in Stripe. */
export type StripeBankAccount = {
  __typename: 'StripeBankAccount'
  /** The name of the person or business that owns the bank account. */
  accountHolderName: Scalars['String']
  /** The type of entity that holds the account. This can be either individual or company. */
  accountHolderType: Scalars['String']
  /** Name of the bank associated with the routing number. */
  bankName: Scalars['String']
  /** Two-letter ISO code representing the country the bank account is located in. */
  country: Scalars['String']
  /** Three-letter ISO code for the currency paid out to the bank account. */
  currency?: Maybe<Scalars['String']>
  /** True if set as the default payment method. */
  defaultSource?: Maybe<Scalars['Boolean']>
  /** Uniquely identifies this particular bank account. You can use this attribute to check whether two bank accounts are the same. */
  fingerprint: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** The last four digits of the bank account number. */
  last4: Scalars['String']
  /** The routing transit number for the bank account. */
  routingNumber: Scalars['String']
  /** For bank accounts, possible values are new, validated, verified, verification_failed, or errored. */
  status: Scalars['String']
}

/** The collection method of the subscriptions */
export enum StripeCollectionMethod {
  /** Stripe will attempt to pay invoices at the end of the subscription cycle or at invoice finalization using the default payment method attached to the subscription or customer. */
  CHARGE_AUTOMATICALLY = 'charge_automatically',
  /** Stripe will email the customer an invoice with payment instructions. */
  SEND_INVOICE = 'send_invoice'
}

/** Stripe customer information */
export type StripeCustomer = {
  __typename: 'StripeCustomer'
  /** The customer’s address. */
  address?: Maybe<StripeAddress>
  /** Current balance, if any, being stored on the customer. If negative, the customer has credit to apply to their next invoice. If positive, the customer has an amount owed that will be added to their next invoice. The balance does not refer to any unpaid invoices; it solely takes into account amounts that have yet to be successfully applied to any invoice. This balance is only taken into account as invoices are finalized. */
  balance: Scalars['Int']
  /** The stripe customer id */
  id: Scalars['ID']
  /** The customer’s full name or business name. */
  name?: Maybe<Scalars['String']>
}

/** Stripe Tax ID object. This comes from Stripe. */
export type StripeCustomerTaxId = {
  __typename: 'StripeCustomerTaxId'
  /** Two-letter ISO code representing the country of the tax ID. */
  country: Scalars['String']
  /** Creation date. */
  createdAt: Scalars['ISO8601DateTime']
  /** ID */
  id: Scalars['ID']
  /** ID of the Stripe customer. */
  stripeCustomerId: Scalars['String']
  /** Type of the tax ID */
  type: Scalars['String']
  /** Status of Stripe validation on the tax id */
  validationStatus: Scalars['String']
  /** Value of the tax ID. */
  value: Scalars['String']
}

/** Stripe invoice record. This comes from Stripe. */
export type StripeInvoice = {
  __typename: 'StripeInvoice'
  /** The due date of the invoice. */
  createdAt: Scalars['ISO8601DateTime']
  /** The date the invoice was issued. */
  date?: Maybe<Scalars['ISO8601DateTime']>
  /** The invoice description. */
  description: Scalars['String']
  /** The due date of the invoice. */
  dueDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The pay link for the invoice. */
  hostedInvoiceUrl?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** The item of the invoice. */
  item: Scalars['String']
  /** The line items of the invoice. */
  lineItems: Array<StripeInvoiceLineItem>
  /** End of the usage period during which invoice items were added to this invoice. */
  periodEnd?: Maybe<Scalars['ISO8601DateTime']>
  /** Start of the usage period during which invoice items were added to this invoice. */
  periodStart?: Maybe<Scalars['ISO8601DateTime']>
  /** Total amount of all post-payment credit notes issued for this invoice. */
  postPaymentCreditNotesAmount?: Maybe<Scalars['Int']>
  /** The status of the stripe invoice. */
  status?: Maybe<StripeInvoiceStatus>
  /** Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or exclusive tax is applied. Item discounts are already incorporated */
  subtotal: Scalars['Int']
  /** The integer amount representing the subtotal of the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated */
  subtotalExcludingTax: Scalars['Int']
  /** The amount of tax on this invoice. This is the sum of all the tax amounts on this invoice. */
  tax?: Maybe<Scalars['Float']>
  /** Total after discounts and taxes. */
  total: Scalars['Int']
  /** The integer amount representing the total amount of the invoice including all discounts but excluding all tax. */
  totalExcludingTax?: Maybe<Scalars['Int']>
  /** The aggregate amounts calculated per tax rate for all line items. */
  totalTaxAmounts: Array<StripeTaxAmount>
}

/** Stripe invoice line item record. This comes from Stripe. */
export type StripeInvoiceLineItem = {
  __typename: 'StripeInvoiceLineItem'
  /** The line item amount */
  amount: Scalars['Int']
  /** The line item amount excluding tax */
  amountExcludingTax: Scalars['Int']
  /** The line item description */
  description: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Metadata value set on the Stripe Product, matching item codes set on Avalara products */
  productItemCode?: Maybe<Scalars['String']>
  /** The line item quantity */
  quantity: Scalars['Int']
  /** The line item cumulated tax amount */
  taxAmount?: Maybe<Scalars['Float']>
  /** The line item tax amounts */
  taxAmounts: Array<StripeTaxAmount>
  /** The line item cumulated tax rate */
  taxRate?: Maybe<Scalars['Float']>
}

/** The status of the stripe invoice. */
export enum StripeInvoiceStatus {
  /** The starting status for all invoices. You can still edit the invoice at this point. */
  DRAFT = 'draft',
  /** The invoice has been finalized, and is awaiting customer payment. You can no longer edit the invoice. */
  OPEN = 'open',
  /** This invoice was paid. */
  PAID = 'paid',
  /** The customer is unlikely to pay this invoice (treat it as bad debt in your accounting process). */
  UNCOLLECTIBLE = 'uncollectible',
  /** This invoice was a mistake, and must be canceled. */
  VOID = 'void'
}

/** User payment methods stored in Stripe. */
export type StripePaymentMethod = {
  __typename: 'StripePaymentMethod'
  /** Credit Card information. */
  card?: Maybe<StripePaymentMethodCard>
  /** True if set as the default payment method. */
  defaultPaymentMethod?: Maybe<Scalars['Boolean']>
  /** ID */
  id: Scalars['ID']
  /** Type of payment method (card). */
  type: Scalars['String']
}

/** Credit card info stored on a payment method in Stripe. */
export type StripePaymentMethodCard = {
  __typename: 'StripePaymentMethodCard'
  /** Brand of the card: visa, mastercard, etc. */
  brand: Scalars['String']
  /** Month the card expires. */
  expMonth: Scalars['Int']
  /** Year the card expires. */
  expYear: Scalars['Int']
  /** Uniquely identifies this particular card number. */
  fingerprint?: Maybe<Scalars['String']>
  /** Last four numbers of the card. */
  last4: Scalars['String']
  /** If the card is active or expired. */
  status: Scalars['String']
}

/** User payment methods stored in Stripe. */
export type StripePaymentSources = StripeBankAccount | StripeSource

/** Autogenerated input type of StripeSetupIntent */
export type StripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the user */
  userId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of StripeSetupIntent */
export type StripeSetupIntentPayload = {
  __typename: 'StripeSetupIntentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The Stripe client secret to be used in the stripe JS library. */
  clientSecret?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** User payment methods stored in Stripe. */
export type StripeSource = {
  __typename: 'StripeSource'
  /** ACH Credit Transfer information. */
  achCreditTransfer?: Maybe<StripeAchCreditTransfer>
  /** Three-letter ISO code for the currency associated with the source. */
  currency?: Maybe<Scalars['String']>
  /** True if set as the default source. */
  defaultSource?: Maybe<Scalars['Boolean']>
  /** ID */
  id: Scalars['ID']
  /** The status of the source, one of canceled, chargeable, consumed,failed, or pending. Only chargeable sources can be used to create a charge. */
  status: Scalars['String']
  /** Type of payment source (ach_credit_transfer). */
  type: Scalars['String']
}

/** Stripe subscription record. This comes from Stripe. */
export type StripeSubscription = {
  __typename: 'StripeSubscription'
  /** Date the subscription was canceled. */
  cancelAt?: Maybe<Scalars['String']>
  /** Whether the subscription will end when the term ends. */
  cancelAtPeriodEnd?: Maybe<Scalars['Boolean']>
  /** Date the subscription will end. */
  currentPeriodEnd?: Maybe<Scalars['Int']>
  /** ID */
  id: Scalars['ID']
  /** If we have a payment method on file we can charge. */
  isPaymentMethodOnFile?: Maybe<Scalars['Boolean']>
  /** True if the last payment failed. */
  paymentFailed: Scalars['Boolean']
  /** Plan record. */
  plan?: Maybe<StripeSubscriptionPlan>
  /** Subscription status: active, etc. */
  status?: Maybe<Scalars['String']>
}

/** Subscription plan object under a subscription record in Stripe */
export type StripeSubscriptionPlan = {
  __typename: 'StripeSubscriptionPlan'
  /** True if the subscription plan is active. */
  active?: Maybe<Scalars['Boolean']>
  /** ID */
  id: Scalars['ID']
  /** Object type: plan, etc. */
  object?: Maybe<Scalars['String']>
}

/** The status of the stripe subscriptions. */
export enum StripeSubscriptionStatus {
  /** Active subscription */
  ACTIVE = 'active',
  /** Stripe has exhausted all payment retry attempts */
  CANCELED = 'canceled',
  /** Initial payment attempt failed. A subscription in this state can only have metadata and default_source updated. */
  INCOMPLETE = 'incomplete',
  /** First invoice not payed within 23 hours. This is a terminal state, the open invoice will be voided and no further invoices will be generated. */
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  /** Invoice is not paid by the due date. */
  PAST_DUE = 'past_due',
  /** Subscription in trial period. */
  TRIALING = 'trialing',
  /** Stripe has exhausted all payment retry attempts. No subsequent invoices will be attempted */
  UNPAID = 'unpaid'
}

/** The aggregate amounts calculated per tax rate for a line item. */
export type StripeTaxAmount = {
  __typename: 'StripeTaxAmount'
  /** The amount of tax. */
  amount: Scalars['Int']
  /** The tax rate that was applied to get this tax amount. */
  taxRate?: Maybe<StripeTaxRate>
  /** The tax rate object id. */
  taxRateId: Scalars['String']
}

/** Tax rate object */
export type StripeTaxRate = {
  __typename: 'StripeTaxRate'
  /** Two-letter ISO code representing the country the tax rate is in. */
  country?: Maybe<Scalars['String']>
  /** The display name of the tax rates as it will appear to your customer on their receipt email, PDF, and the hosted invoice page. */
  displayName?: Maybe<Scalars['String']>
  /** Tax rate id. */
  id: Scalars['ID']
  /** The jurisdiction for the tax rate. You can use this label field for tax reporting purposes. It also appears on your customer’s invoice. */
  jurisdiction?: Maybe<Scalars['String']>
  /** The percentage of tax rate. */
  percentage?: Maybe<Scalars['Float']>
  /** The state the tax rate is in. */
  state?: Maybe<Scalars['String']>
}

/** Stripe upcoming invoice record. This comes from Stripe. */
export type StripeUpcomingInvoice = {
  __typename: 'StripeUpcomingInvoice'
  /** The total amount due (in cents) for the next upcoming invoice. */
  amountDue: Scalars['Int']
  /** The date of the next upcoming invoice. */
  date: Scalars['String']
  /** Discounted cohort pass amount */
  discountedCohortPassAmount?: Maybe<Scalars['Int']>
  /** an ID for the upcoming invoice. */
  id: Scalars['String']
  /** Invoice line items */
  lines: Array<StripeInvoiceLineItem>
  /** The number of cohort passes for the next upcoming invoice. */
  numCohortPasses: Scalars['Int']
  /** The number of seats for the next upcoming invoice. */
  numSeats: Scalars['Int']
  /** The name of the plan for the next upcoming invoice. */
  planName: PlanName
  /** Prorated price based on unused time from prev plan and remaining time from new plan */
  proratedAdjustment?: Maybe<CheckoutProrationAdjustment>
  /** Balance in customer account before invoice is finalized. If negative, the customer has credit to apply to the amount due. If positive, the customer has an amount due that will be added to the next invoice. */
  startingBalance: Scalars['Int']
  /** Subtotal of all subscription invoice items (incl. prorations and maybe cohort passes), before invoice level discounts and taxes are applied */
  subtotal: Scalars['Int']
  /** Total tax amount for the upcoming invoice */
  tax?: Maybe<Scalars['Int']>
}

/** Autogenerated input type of SubmitOnboardingQuestionnaire */
export type SubmitOnboardingQuestionnaireInput = {
  /** Areas of expertise */
  areasOfExpertise?: InputMaybe<Scalars['String']>
  /** Business model */
  businessModel?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Company audience */
  companyAudience?: InputMaybe<Scalars['String']>
  /** Emails of team members to invite */
  emailsToInvite?: InputMaybe<Array<Scalars['String']>>
  /** Set Manual Assign for Live Course Passes */
  manualAssignPasses?: InputMaybe<Scalars['Boolean']>
  /** Team name */
  teamName?: InputMaybe<Scalars['String']>
  /** Usage */
  usage?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of SubmitOnboardingQuestionnaire */
export type SubmitOnboardingQuestionnairePayload = {
  __typename: 'SubmitOnboardingQuestionnairePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Collection of homepage checks */
  homepageChecks: HomepageCheck
  /** Questionnaire */
  questionnaire: OnboardingQuestionnaire
}

/** Autogenerated input type of SubmitPostApplySurvey */
export type SubmitPostApplySurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Job functions interested in */
  jobFunctionInterests?: InputMaybe<Array<Scalars['String']>>
  /** Programs interested in */
  programInterests?: InputMaybe<Array<Scalars['String']>>
  /** Company reimbursement yearly amount */
  reimbursementAmount?: InputMaybe<Scalars['Int']>
  /** Whether or not company is reimbursing: Yes, No, or Not Sure */
  reimbursementOffered?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of SubmitPostApplySurvey */
export type SubmitPostApplySurveyPayload = {
  __typename: 'SubmitPostApplySurveyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of the feedback record */
  id: Scalars['ID']
}

/** A subscription is what gives a user or set of users access to our content. */
export type Subscription = {
  __typename: 'Subscription'
  /** The cohort credits associated with this subscription. */
  cohortCredits: CohortCredits
  /** Whether the trial has been completed */
  completedTrial?: Maybe<Scalars['Boolean']>
  /** Number of days left before the subscription expires. */
  daysLeft: Scalars['Int']
  /**
   * If a trial subscription, the number of days remaining
   *                                                 before expiration. Returns nil if the subscription is not a trial.
   */
  daysLeftInTrial?: Maybe<Scalars['Int']>
  /** Domain name associated with team */
  domainName?: Maybe<Scalars['String']>
  /** The amount still owed on the most recent invoice */
  dunningAmountDueInCents?: Maybe<Scalars['Int']>
  /**
   * How many days left before we give up attempting payment
   *                                                and expire the subscription.
   */
  dunningDaysLeft?: Maybe<Scalars['Int']>
  /** When the subscription expires. */
  expiresAt: Scalars['String']
  /** The number of seats scheduled for deletion. */
  expiringSeatCount: Scalars['Int']
  /** External ID of team/subscription */
  externalId?: Maybe<Scalars['String']>
  /** Whether subscription has empty seats */
  hasAvailableSeats: Scalars['Boolean']
  /** whether the subscription is from a pre-2023-era unlimited cohort access plan */
  hasUnlimitedCohortAccess?: Maybe<Scalars['Boolean']>
  /** ID */
  id: Scalars['ID']
  /**
   * Has the subscription been canceled? In other words, has autorenew
   *                                  been turned off?
   */
  isCanceled?: Maybe<Scalars['Boolean']>
  /** True if team can be discovered */
  isDiscoverable?: Maybe<Scalars['Boolean']>
  /**
   * Whether or not the recent
   *                                                               payment attempts on the subscription have failed.
   */
  isDunning: Scalars['Boolean']
  /** Is the subscription expired? */
  isExpired?: Maybe<Scalars['Boolean']>
  /**
   * Use the old "plan_" identifiers on stripe_plan_id
   * @deprecated there are no active 2019-era subscriptions and we are not supporting them
   */
  isLegacy?: Maybe<Scalars['Boolean']>
  /** Is the subscription paid on a monthly term? */
  isPaidMonthly: Scalars['Boolean']
  /** Reforge partner */
  isPartnerSubscription?: Maybe<Scalars['Boolean']>
  /** Are the subscription seats provisioned by SCIM? */
  isProvisionedByScim: Scalars['Boolean']
  /**
   * Whether or not the subscription is for a team
   *                              (as opposed to an individual)
   */
  isTeam: Scalars['Boolean']
  /**
   * The UTC timestamp of the last failed payment
   *                                                        attempt. Will be nil if the last payment attempt succeeded.
   */
  lastFailedPaymentAt?: Maybe<Scalars['String']>
  /** The logo url of the company representing this (team) subscription */
  logoUrl?: Maybe<Scalars['String']>
  /** If the course passes need to be manually assigned, default false */
  manualAssign?: Maybe<Scalars['Boolean']>
  /** Team name */
  name?: Maybe<Scalars['String']>
  /**
   * How many seats the subscription purchased.
   *                            Each seat can be assigned to one user at a time.
   */
  numSeats: Scalars['Int']
  /**
   * How many seats would lose access
   *                                                     during a live program were the subscription to expire on
   *                                                     its expiration date
   */
  numSeatsAffectedByCancellation?: Maybe<Scalars['Int']>
  /** How many seats are available for assignment on the subscription */
  numSeatsAvailable: Scalars['Int']
  /** The organization associated with the subscription */
  org?: Maybe<Org>
  /** The organization ID associated with the subscription */
  orgId?: Maybe<Scalars['ID']>
  /** One of [ expired, unlimited, individual, starter, scale, enterprise ] */
  planName: PlanName
  /** User the subscription belongs to (purchaser). */
  purchaser?: Maybe<User>
  /** The number of seats scheduled for renewal. */
  renewSeatCount: Scalars['Int']
  /**
   * Array of seat records. Seats are assigned to users to give them
   *                                      access to the subscription.
   */
  seats: Array<Seat>
  /** URL for inviting users to team */
  shareableLinkUrl: Scalars['String']
  /** The SSO configuration ID associated with the subscription */
  ssoConfigurationId?: Maybe<Scalars['ID']>
  /** When the subscription (whether trial or regular) begins */
  startsAt?: Maybe<Scalars['String']>
  /** Status of subscription: active, canceled, etc. */
  status?: Maybe<Scalars['String']>
  /** Collection method for the stripe subscription */
  stripeCollectionMethod?: Maybe<StripeCollectionMethod>
  /** Customer ID from stripe */
  stripeCustomerId?: Maybe<Scalars['String']>
  /** Subscription from stripe */
  stripeSubscription?: Maybe<StripeSubscription>
  /** Subscription ID from stripe */
  stripeSubscriptionId?: Maybe<Scalars['String']>
  /** The status of the stripe subscriptions. */
  stripeSubscriptionStatus?: Maybe<StripeSubscriptionStatus>
  /** The upcoming invoice from Stripe. */
  stripeUpcomingInvoice?: Maybe<StripeUpcomingInvoice>
  /** searchable team members */
  teamMembers: TeamMembersSearch
  /** The name of the (team) subscription as set by its subscription owner */
  teamName?: Maybe<Scalars['String']>
  /** If paid on a term, when the term ends. */
  termEndsAt?: Maybe<Scalars['String']>
  /** If a trial subscription, the number of days remaining */
  trialDaysLeft?: Maybe<Scalars['Int']>
  /** The end date of the subscription trial. */
  trialEndDate?: Maybe<Scalars['ISO8601DateTime']>
  /**
   * The length of a trial subscription in days, from start to
   *                               expiration. Will be null for non-trial subscriptions.
   */
  trialLength?: Maybe<Scalars['Int']>
  /**
   * If a trial subscription, the type of trial it is.
   *                                Options are "invitation", "application", "guest (pe-migration)", and "guest".
   *                                Will be null if not a trial subscription.
   */
  trialType?: Maybe<Scalars['String']>
  /** User the subscription belongs to. */
  user: User
  /** User that purchased the subscription */
  userId: Scalars['String']
  /**
   * Checks if the current user purchased the subscription
   *                                    ("user_id" matches current_user id). Returns false for trial subscriptions.
   */
  userIsOwner: Scalars['Boolean']
  /** List of associated join requests that current user has made for the subcription */
  userJoinRequests: Array<SubscriptionJoinRequest>
}

/** A subscription is what gives a user or set of users access to our content. */
export type SubscriptionTeamMembersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** A subscription is what gives a user or set of users access to our content. */
export type SubscriptionUserIsOwnerArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated input type of SubscriptionCancel */
export type SubscriptionCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Survey response for: What is your primary motivation for canceling? */
  motivationForCanceling?: InputMaybe<Scalars['String']>
  /** User ID to cancel the subscription for */
  userId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated input type of SubscriptionCancelMotivation */
export type SubscriptionCancelMotivationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Survey response for: What is your primary motivation for canceling? */
  motivationForCanceling: Scalars['String']
}

/** Autogenerated return type of SubscriptionCancelMotivation */
export type SubscriptionCancelMotivationPayload = {
  __typename: 'SubscriptionCancelMotivationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** Created Feedback object ID */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated return type of SubscriptionCancel */
export type SubscriptionCancelPayload = {
  __typename: 'SubscriptionCancelPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** Subscription id that was canceled */
  id?: Maybe<Scalars['ID']>
}

/** Subscription join request */
export type SubscriptionJoinRequest = {
  __typename: 'SubscriptionJoinRequest'
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** ID */
  id: Scalars['ID']
  /** Status - one of [pending, accepted, rejected, canceled] */
  status: Scalars['String']
  /** Subscription */
  subscription: Subscription
  /** User */
  user: User
}

/** Record connecting a subscription to a member on the subscription. */
export type SubscriptionMember = {
  __typename: 'SubscriptionMember'
  /** Date the record was created. */
  createdAt: Scalars['String']
  /** Email of the attached user. */
  email: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Role of the user related to the subscription (e.g. Plan manager). */
  role?: Maybe<Scalars['String']>
  /** Status of the subscription member: [pending, active]. */
  status: Scalars['String']
  /** Subscription */
  subscription: Subscription
  /** Token for the seat. Used for inviting a user to a seat. */
  token?: Maybe<Scalars['String']>
  /** User record attached to subscription member. */
  user: User
  /** ID of the user attached to the subscription. */
  userId: Scalars['ID']
}

/** Autogenerated input type of SubscriptionSeatsChangePreview */
export type SubscriptionSeatsChangePreviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Number of additional seats */
  numSeats: Scalars['Int']
  /** Subscription ID */
  subscriptionId: Scalars['ID']
}

/** Autogenerated return type of SubscriptionSeatsChangePreview */
export type SubscriptionSeatsChangePreviewPayload = {
  __typename: 'SubscriptionSeatsChangePreviewPayload'
  /** Amount a user would need to pay */
  amount: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
}

/** A session run of a course. */
export type SupplyCourseSession = {
  __typename: 'SupplyCourseSession'
  /** ID of Course */
  courseId: Scalars['String']
  /** Slug of Course */
  courseSlug: Scalars['String']
  /** Title of Course */
  courseTitle: Scalars['String']
  /** When the session ends. */
  endsAt: Scalars['ISO8601DateTime']
  /** User data of enrollees for course session hosts to see */
  enrollees: Array<User>
  /** When enrollment opens for the session. */
  enrollmentStartsAt: Scalars['ISO8601DateTime']
  /** Whether course session has ended */
  hasEnded: Scalars['Boolean']
  /** Whether course session has started */
  hasStarted: Scalars['Boolean']
  /** ID. */
  id: Scalars['ID']
  /** Whether course content is open to view */
  isContentOpen: Scalars['Boolean']
  /** Whether or not the current time is within the enrollment window */
  isDuringEnrollmentPeriod: Scalars['Boolean']
  /** Whether the course is past */
  isPast: Scalars['Boolean']
  /** Whether the course is upcoming */
  isUpcoming: Scalars['Boolean']
  /** Enrollment cap for users paying or using a cohort credit to enroll in this session */
  maxEnrollmentPaid?: Maybe<Scalars['Int']>
  /** Total enrollment cap of this session */
  maxEnrollmentTotal?: Maybe<Scalars['Int']>
  /** AYCE member enrollment cap of this session */
  maxEnrollmentUnlimited?: Maybe<Scalars['Int']>
  /** Number of paying users enrolled in this session */
  numEnrolledPaid?: Maybe<Scalars['Int']>
  /** Total number of users enrolled in this session */
  numEnrolledTotal?: Maybe<Scalars['Int']>
  /** Number of AYCE members enrolled in this session */
  numEnrolledUnlimited?: Maybe<Scalars['Int']>
  /** Price of the course for this session */
  price: Scalars['Int']
  /** Slack channel ID for this session */
  slackChannelId?: Maybe<Scalars['String']>
  /** When the session begins. */
  startsAt: Scalars['ISO8601DateTime']
  /** Survey link for this session */
  surveyLink?: Maybe<Scalars['String']>
}

/** Queries for recommendations */
export type SwimlanesQuery = {
  /** Recommended collection */
  recommendedCollection: Collection
  /** All of a users current recommended collections */
  recommendedCollections: Array<Collection>
}

/** Queries for recommendations */
export type SwimlanesQueryRecommendedCollectionArgs = {
  swimlaneId: Scalars['ID']
}

/** Tags coming from Sanity CMS */
export type Tag = {
  __typename: 'Tag'
  /** ID of the tag. */
  id: Scalars['String']
  /** Slug for the tag. */
  slug: Scalars['String']
  /** Title of the tag. */
  title: Scalars['String']
}

/** Tag Groups coming from Sanity CMS */
export type TagGroup = {
  __typename: 'TagGroup'
  /** Function for the tag group. */
  functionTag: Function
  /** Global tag group. */
  global?: Maybe<Scalars['Boolean']>
  /** Global sort order of the tag group. */
  globalSortOrder?: Maybe<Scalars['Int']>
  /** ID of the tag group. */
  id: Scalars['String']
  /** Slug for the tag group. */
  slug: Scalars['String']
  /** Sort order of the tag group. */
  sortOrder?: Maybe<Scalars['Int']>
  /** Tags for the tag group. */
  tags?: Maybe<Array<Tag>>
  /** Title of the tag group. */
  title: Scalars['String']
}

/** Tax id verification status and reverse charge status */
export type TaxIdReverseChargeStatus = {
  __typename: 'TaxIdReverseChargeStatus'
  /** Reverse charge status */
  isReverseCharge?: Maybe<Scalars['Boolean']>
  /** Tax id verification status */
  taxIdVerificationStatus?: Maybe<TaxIdVerificationStatus>
}

/** Tax id verification status */
export enum TaxIdVerificationStatus {
  PENDING = 'pending',
  UNAVAILABLE = 'unavailable',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified'
}

/** Tax item information from Avalara */
export type TaxInfoItem = {
  __typename: 'TaxInfoItem'
  /** Tax details */
  details: Array<TaxInfoItemDetails>
  /** Product item code */
  productItemCode: Scalars['String']
}

/** Tax information details from Avalara */
export type TaxInfoItemDetails = {
  __typename: 'TaxInfoItemDetails'
  /** Tax percentage value */
  rate: Scalars['Float']
  /** Taxable amount */
  taxableAmount: Scalars['Float']
}

/** Preview of a team member on Reforge */
export type TeamMemberPreview = {
  __typename: 'TeamMemberPreview'
  /** Avatar URL */
  avatarUrl: Scalars['String']
  /** The unique ID of a user. */
  id: Scalars['ID']
  /** Limited set of profile data for the user. */
  profile: TeamMemberProfilePreview
}

/** A limited set of profile data for a member on Reforge */
export type TeamMemberProfilePreview = {
  __typename: 'TeamMemberProfilePreview'
  /** Avatar URL */
  avatarUrl: Scalars['String']
}

/** Team Members Search */
export type TeamMembersSearch = {
  __typename: 'TeamMembersSearch'
  /** Count of the users returned after search and filters. */
  count?: Maybe<Scalars['Int']>
  /** Count of the users returned before search and filters. */
  totalCount?: Maybe<Scalars['Int']>
  /** users */
  users?: Maybe<Array<User>>
}

/** A team post made by a user. */
export type TeamPost = {
  __typename: 'TeamPost'
  /** Activities related to team post */
  activities: Array<Activity>
  /** Count of reactions */
  allReactionsCount?: Maybe<Scalars['Int']>
  /** Text of the team post */
  body?: Maybe<Scalars['String']>
  /** Text of the team post */
  bodyText?: Maybe<Scalars['String']>
  /** Cms section link of inline team post */
  cmsSectionLink?: Maybe<Scalars['String']>
  /** Cms section name of inline team post */
  cmsSectionName?: Maybe<Scalars['String']>
  /** The name of the cms section's parent */
  cmsSectionParentName?: Maybe<Scalars['String']>
  /** Created at date */
  createdAt: Scalars['ISO8601DateTime']
  /** Created at date in human readable format */
  createdAtDate?: Maybe<Scalars['String']>
  /** Created at date in concise time */
  createdAtInWords: Scalars['String']
  /** Href */
  href: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** List of avatar URLs for replies */
  images: Array<Scalars['String']>
  /** Team Post reactions */
  reactions: Array<Reaction>
  /** Reference image url */
  referenceImageUrl?: Maybe<Scalars['String']>
  /** Team Post replies */
  replies: Array<Reply>
  /** Count of replies */
  repliesCount: Scalars['Int']
  /** Should show new badge */
  replyCount: Scalars['Int']
  /** Team Post reply previews */
  replyPreviews: Array<Reply>
  /** Text */
  text: Scalars['String']
  /** Datetime */
  timeAgo: Scalars['String']
  /** Param of the team team post */
  toParam: Scalars['String']
  /**
   * Elements related to team post that
   *                                                         have not been read by the user
   */
  unreadElements: Array<UnreadElement>
  /** Updated at date */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Updated at date in concise time */
  updatedAtInWords?: Maybe<Scalars['String']>
  /** The associated user */
  user: User
}

/** Preview of a team on Reforge */
export type TeamPreview = {
  __typename: 'TeamPreview'
  /** Preview of team members. */
  members: Array<TeamMemberPreview>
  /** Company name of the team. */
  name?: Maybe<Scalars['String']>
  /** SSO authorization parameters */
  ssoAuthorization?: Maybe<SsoAuthorization>
  /** Total number of members on the team. */
  totalMembers: Scalars['Int']
}

/** Tentative upcoming cohort for a program */
export type TentativeUpcomingCohort = {
  __typename: 'TentativeUpcomingCohort'
  /** End date */
  endsAt?: Maybe<Scalars['String']>
  /** Link to the marketing site page for the cohort season */
  marketingSiteUrl?: Maybe<Scalars['String']>
  /** Name */
  name?: Maybe<Scalars['String']>
  /** Start date */
  startsAt?: Maybe<Scalars['String']>
  /** Time of year */
  timeOfYear?: Maybe<Scalars['String']>
  /** Year */
  year?: Maybe<Scalars['String']>
}

/** Testimonial or review of Reforge or a program by a user. */
export type Testimonial = {
  __typename: 'Testimonial'
  /** Indicates whether the testimonial is live */
  active: Scalars['Boolean']
  /** Can be pending, approved, or rejected */
  approvalStatus: Scalars['String']
  /** URL pointing to stored avatar image of the user */
  avatarUrl: Scalars['String']
  /** Content of the testimonial. */
  copy?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** When the user joined Reforge */
  joined: Scalars['String']
  /** Name of the user. */
  name?: Maybe<Scalars['String']>
  /** Job title and company. */
  role?: Maybe<Scalars['String']>
  /** Image url for the user avatar. */
  thumbnailUrl?: Maybe<Scalars['String']>
  /** User ID of the user who is giving the testimonial */
  userId: Scalars['ID']
}

/** Testimonial with Image from Sanity CMS */
export type TestimonialWithImage = {
  __typename: 'TestimonialWithImage'
  /** Avatar */
  avatar?: Maybe<Image>
  /** Name */
  name?: Maybe<Scalars['String']>
  /** Position and Company */
  positionAndCompany?: Maybe<Scalars['String']>
  /** Quote */
  quote?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ToggleManualAssignSubscription */
export type ToggleManualAssignSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The subscription to update */
  id: Scalars['ID']
  /** The new value for manual_assign,if not given the value will automatically toggle from current */
  manualAssign?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of ToggleManualAssignSubscription */
export type ToggleManualAssignSubscriptionPayload = {
  __typename: 'ToggleManualAssignSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Current value of the manual_assign field */
  manualAssign: Scalars['Boolean']
}

/** Autogenerated input type of TogglePostFollow */
export type TogglePostFollowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post/Reply ID */
  id: Scalars['ID']
}

/** Autogenerated return type of TogglePostFollow */
export type TogglePostFollowPayload = {
  __typename: 'TogglePostFollowPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Returns true if the post is followed or false if unfollowed */
  following: Scalars['Boolean']
}

/** Topic used to categorize Sanity content types. */
export type Topic = {
  __typename: 'Topic'
  /** Topic icon */
  icon?: Maybe<Image>
  /** Topic ID */
  id?: Maybe<Scalars['ID']>
  /** Whether this topic is deprecated */
  isDeprecated?: Maybe<Scalars['Boolean']>
  /** Whether this topic is public */
  isPublic?: Maybe<Scalars['Boolean']>
  /** URL to redirect to if isDeprecated */
  redirectTo?: Maybe<Scalars['String']>
  /** Topic slug */
  slug: Scalars['String']
  /** Topic title */
  title: Scalars['String']
}

/** A topic activity on a topic */
export type TopicActivity = {
  __typename: 'TopicActivity'
  /** ID of topic activity */
  id: Scalars['ID']
  /** Date of when topic activity was last viewed */
  lastViewedAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Autogenerated input type of TrackServerEvent */
export type TrackServerEventInput = {
  /**
   * Segment anonymous ID - requiring for now since context[:cookies]
   *                                       having ajs_anonymous_id does not seem consistent
   */
  anonymousId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Track event name */
  event: Scalars['String']
  /** Properties to include in the track */
  properties: Scalars['JSON']
}

/** Autogenerated return type of TrackServerEvent */
export type TrackServerEventPayload = {
  __typename: 'TrackServerEventPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of UnassignCohortCredit */
export type UnassignCohortCreditInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the seat */
  seatId: Scalars['ID']
}

/** Autogenerated return type of UnassignCohortCredit */
export type UnassignCohortCreditPayload = {
  __typename: 'UnassignCohortCreditPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated seat (if successful) */
  seat?: Maybe<Seat>
}

/** Autogenerated input type of UnassignSeat */
export type UnassignSeatInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the seat to remove the member from */
  id: Scalars['ID']
}

/** Autogenerated return type of UnassignSeat */
export type UnassignSeatPayload = {
  __typename: 'UnassignSeatPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors, if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** Seat that had the member removed */
  seat?: Maybe<Seat>
  /** True if releasing or transfering a seat was successful */
  success?: Maybe<Scalars['Boolean']>
}

/** Includes fields from MarketingCourseType and exteds with fields for a "legacy course" */
export type UnifiedCourse = {
  __typename: 'UnifiedCourse'
  /** Availability subtitle */
  availabilitySubtitle?: Maybe<Scalars['String']>
  /** Background image */
  backgroundImage?: Maybe<Image>
  /** Bookmark ID for LEGACY courses (CmsPrograms) */
  bookmarkId?: Maybe<Scalars['ID']>
  /** Breakdown background image */
  breakdownBackgroundImage?: Maybe<Image>
  /** Breakdown image */
  breakdownImage?: Maybe<Image>
  /** CclCourse associated with if any */
  cclCourse?: Maybe<CclCourse>
  /** CclTopic associated with if any */
  cclTopics?: Maybe<Array<CclTopic>>
  /**
   * cms_program for the course for courseType
   *                                                                 legacy if linked by cmsProgramSlug
   */
  cmsProgram?: Maybe<CmsProgram>
  /** cms_program slug for the course for courseType legacy */
  cmsProgramSlug?: Maybe<Scalars['String']>
  /** Course directly from Sanity */
  course?: Maybe<SanityCourse>
  /** Course FAQ */
  courseFaq?: Maybe<Array<MarketingFaq>>
  /** Course Info Cards */
  courseInfoCards?: Maybe<Array<CourseInfoCard>>
  /** Is the course preview available? */
  coursePreviewAvailable?: Maybe<Scalars['Boolean']>
  /** course type */
  courseType?: Maybe<Scalars['String']>
  /** Creators of the Course */
  creators?: Maybe<Array<Creator>>
  /** CTA button link */
  ctaButtonLink?: Maybe<Scalars['String']>
  /** CTA button text */
  ctaButtonText?: Maybe<Scalars['String']>
  /** Description */
  description?: Maybe<Scalars['String']>
  /** Duration of the course */
  duration?: Maybe<Scalars['String']>
  /** Goals */
  goals?: Maybe<Scalars['String']>
  /** Host section subtitle */
  hostSectionSubtitle?: Maybe<Scalars['String']>
  /** Host section title */
  hostSectionTitle?: Maybe<Scalars['String']>
  /** Subtitle of the host */
  hostSubtitle?: Maybe<Scalars['String']>
  /** Hosts */
  hosts?: Maybe<Array<SanityHost>>
  /** Icon */
  icon?: Maybe<Image>
  /** ID */
  id: Scalars['ID']
  /** Is Live */
  isLive?: Maybe<Scalars['Boolean']>
  /** Legacy Program ID */
  legacyProgramId?: Maybe<Scalars['ID']>
  /** Preview image on index page */
  previewImage?: Maybe<Image>
  /** Price of the course */
  price?: Maybe<Scalars['String']>
  /** The url slug for the course */
  slug?: Maybe<Scalars['String']>
  /** When the course starts */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Subtitle of the course */
  subtitle?: Maybe<Scalars['String']>
  /** Target audience */
  targetAudience?: Maybe<Scalars['String']>
  /** Title of the course */
  title?: Maybe<Scalars['String']>
  /** Topics covered */
  topicsCovered?: Maybe<Array<CourseTopicCovered>>
}

/** Units coming from Sanity CMS */
export type Unit = {
  __typename: 'Unit'
  /** Artifacts for the unit. */
  artifacts?: Maybe<Array<Artifact>>
  /** Authors for the unit. */
  authors?: Maybe<Array<ArtifactAuthor>>
  /** Bookmarks */
  bookmarks?: Maybe<Array<Bookmark>>
  /** Whether the guide is completed. */
  completed?: Maybe<Scalars['Boolean']>
  /** Content block for the unit. */
  contentBlocks?: Maybe<Array<ContentBlock>>
  /** An ID for a bookmarkable section of content */
  contentBookmarkId?: Maybe<Scalars['ID']>
  /** Content is in the my saved items default collection */
  contentIsInMySavedItems?: Maybe<Scalars['Boolean']>
  /** Estimated time in minutes for the unit. */
  estimatedTimeInMinutes?: Maybe<Scalars['Int']>
  /** CCL Guide for this Sanity unit */
  guide?: Maybe<CclGuide>
  /** Hero Image for the unit. */
  heroImage?: Maybe<Image>
  /** ID of the unit. */
  id: Scalars['ID']
  /** URL for Narration audio mp3 file */
  narrationFileUrl?: Maybe<Scalars['String']>
  /** Overview for the unit. */
  overview?: Maybe<Scalars['JSON']>
  /** Whether the unit is previewable for free */
  previewable?: Maybe<Scalars['Boolean']>
  /** Publish date for the unit. */
  publishDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Recap for the unit. */
  recap?: Maybe<Scalars['JSON']>
  /** Short description of the unit. */
  shortDescription?: Maybe<Scalars['String']>
  /** Defined in the sanity_unit GQL query. Determines if the unit content is behind a paywall.When this is true, content_blocks and recap are not returned. */
  showContentGate?: Maybe<Scalars['Boolean']>
  /** Slug for the unit. */
  slug?: Maybe<Scalars['String']>
  /** Steps count for the unit. */
  stepsCount?: Maybe<Scalars['Int']>
  /** Subtopics for the unit. */
  subtopicIds?: Maybe<Array<Scalars['ID']>>
  /** Tags for the unit. */
  tags?: Maybe<Array<Tag>>
  /** Title of the unit. */
  title?: Maybe<Scalars['String']>
  /** Topic related to the unit. */
  topic?: Maybe<Topic>
  /** What you will do for the unit. */
  whatYouWillDo?: Maybe<Scalars['JSON']>
}

/** Units coming from Sanity CMS */
export type UnitBookmarksArgs = {
  type?: InputMaybe<BookmarkType>
}

/** Unread elements of a post */
export type UnreadElement = {
  __typename: 'UnreadElement'
  /** ID of element */
  id: Scalars['ID']
  /** Type of element */
  type: Scalars['String']
}

/** Autogenerated input type of UnshareBookmarkFolder */
export type UnshareBookmarkFolderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Id of shared folder record */
  sharedFolderId: Scalars['ID']
}

/** Autogenerated return type of UnshareBookmarkFolder */
export type UnshareBookmarkFolderPayload = {
  __typename: 'UnshareBookmarkFolderPayload'
  /** The folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The shared folder object */
  sharedFolder?: Maybe<SharedFolder>
}

/** Autogenerated input type of UpdateAiPersonalization */
export type UpdateAiPersonalizationInput = {
  /** Additional details for personalization */
  additionalDetails?: InputMaybe<Scalars['String']>
  /** Target audience */
  audience?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** User's company */
  company?: InputMaybe<Scalars['String']>
  /** Whether AI personalization is enabled */
  enabled?: InputMaybe<Scalars['Boolean']>
  /** User's industry */
  industry?: InputMaybe<Scalars['String']>
  /** User's role */
  role?: InputMaybe<Scalars['String']>
  /** Selected topics */
  topicIds: Array<Scalars['String']>
  /** The user's ID */
  userId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of UpdateAiPersonalization */
export type UpdateAiPersonalizationPayload = {
  __typename: 'UpdateAiPersonalizationPayload'
  /** The updated AI personalization */
  aiPersonalization?: Maybe<AiPersonalization>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors updating AI personalization */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of UpdateArtifactComment */
export type UpdateArtifactCommentInput = {
  /** Artifact ID */
  artifactCommentId: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Artifact comment message */
  message: Scalars['String']
}

/** Autogenerated return type of UpdateArtifactComment */
export type UpdateArtifactCommentPayload = {
  __typename: 'UpdateArtifactCommentPayload'
  /** Artifact comment */
  artifactComment?: Maybe<ArtifactComment>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of UpdateBookmarkFolder */
export type UpdateBookmarkFolderInput = {
  /** Bookmark Folder ID */
  bookmarkFolderId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The text-value of the bookmark folder description */
  description?: InputMaybe<Scalars['String']>
  /** Bookmark Folder Name */
  name?: InputMaybe<Scalars['String']>
  /** Id of parent for nested folder */
  parentId?: InputMaybe<Scalars['String']>
  /** If the collection is a Reforge collection */
  reforgeCollection?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateBookmarkFolder */
export type UpdateBookmarkFolderPayload = {
  __typename: 'UpdateBookmarkFolderPayload'
  /** The folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of UpdateBookmark */
export type UpdateBookmarkInput = {
  /** Anchor of bookmark */
  anchor?: InputMaybe<Scalars['String']>
  /** Bookmark based on */
  basedOn?: InputMaybe<Scalars['String']>
  /** The ID of the bookmark */
  bookmarkId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of cms module */
  cmsModuleId?: InputMaybe<Scalars['ID']>
  /** ID of cms program */
  cmsProgramId?: InputMaybe<Scalars['ID']>
  /** ID of cms section */
  cmsSectionId?: InputMaybe<Scalars['ID']>
  /** External URL of the bookmark */
  externalUrl?: InputMaybe<Scalars['String']>
  /** Notes for bookmark */
  noteBody?: InputMaybe<Scalars['String']>
  /** URL of bookmark image */
  referenceImageUrl?: InputMaybe<Scalars['String']>
  /** Sanity ID for bookmark */
  sanityId?: InputMaybe<Scalars['String']>
  /** Bookmark seconds */
  seconds?: InputMaybe<Scalars['Int']>
  /** Title of the bookmark */
  title?: InputMaybe<Scalars['String']>
  /** Type of bookmark */
  type?: InputMaybe<BookmarkType>
  /** Video code */
  videoCode?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateBookmark */
export type UpdateBookmarkPayload = {
  __typename: 'UpdateBookmarkPayload'
  /** Updated object of bookmark */
  bookmark?: Maybe<Bookmark>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The collection activity */
  collectionActivity?: Maybe<CollectionActivity>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated input type of UpdateCcl */
export type UpdateCclInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Whether to force a sync of all CCL data */
  force?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateCcl */
export type UpdateCclPayload = {
  __typename: 'UpdateCclPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The status of the attempt to sync CCL data */
  status?: Maybe<Scalars['String']>
}

/** Autogenerated input type of UpdateCmsSectionTags */
export type UpdateCmsSectionTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cms Section ID */
  cmsSectionId: Scalars['ID']
  /** Tag type */
  tag: Scalars['String']
}

/** Autogenerated return type of UpdateCmsSectionTags */
export type UpdateCmsSectionTagsPayload = {
  __typename: 'UpdateCmsSectionTagsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Error message for cms section tags mutation */
  errors?: Maybe<Array<Scalars['String']>>
  /** The added/removed Cms Section Tag */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdateCoachingSession */
export type UpdateCoachingSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Data to update */
  data?: InputMaybe<Scalars['JSON']>
  /** Coaching Session id */
  id: Scalars['ID']
}

/** Autogenerated return type of UpdateCoachingSession */
export type UpdateCoachingSessionPayload = {
  __typename: 'UpdateCoachingSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Updated Session */
  coachingSession: CoachingSession
}

/** Autogenerated input type of UpdateCohortPost */
export type UpdateCohortPostInput = {
  /** Description of the post */
  body?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Post ID */
  id: Scalars['ID']
  /** Pin or Unpin post */
  isPinned?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateCohortPost */
export type UpdateCohortPostPayload = {
  __typename: 'UpdateCohortPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the edited post */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdateCohortReply */
export type UpdateCohortReplyInput = {
  /** Reply body */
  body: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cohort reply ID */
  id: Scalars['ID']
}

/** Autogenerated return type of UpdateCohortReply */
export type UpdateCohortReplyPayload = {
  __typename: 'UpdateCohortReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the edited cohort reply */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdateCourseEnrollment */
export type UpdateCourseEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** is_enrolled value to update */
  isEnrolled: Scalars['Boolean']
  /** Whether the user is a shadow enrollee */
  shadow?: InputMaybe<Scalars['Boolean']>
  /** The ID of the user_course record to update enrollment for */
  userCourseId: Scalars['ID']
}

/** Autogenerated return type of UpdateCourseEnrollment */
export type UpdateCourseEnrollmentPayload = {
  __typename: 'UpdateCourseEnrollmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if update failed */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the user_course record for which enrollment was updated */
  userCourseId: Scalars['ID']
}

/** Autogenerated input type of UpdateCourseSession */
export type UpdateCourseSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Course ID for the session */
  courseId?: InputMaybe<Scalars['ID']>
  /** Course slug for the session */
  courseSlug?: InputMaybe<Scalars['String']>
  /** Time when course ends */
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']>
  /** Time when enrollment opens */
  enrollmentStartsAt?: InputMaybe<Scalars['ISO8601DateTime']>
  /** User ID allowed to access the course dashboard */
  hostAccessAllowedForUserIds?: InputMaybe<Array<Scalars['Int']>>
  /** Course session ID */
  id: Scalars['ID']
  /** Max number of paying enrollees allowed */
  maxEnrollmentPaid?: InputMaybe<Scalars['Int']>
  /** Max number of enrollees allowed */
  maxEnrollmentTotal?: InputMaybe<Scalars['Int']>
  /** Max number of free, AYCE enrollees allowed */
  maxEnrollmentUnlimited?: InputMaybe<Scalars['Int']>
  /** Price for the course session */
  price?: InputMaybe<Scalars['Int']>
  /** Course session Sanity ID */
  sanityId?: InputMaybe<Scalars['String']>
  /** Group of sessions that this should be treated with */
  sessionGroupId?: InputMaybe<Scalars['ID']>
  /** Time when course session begins */
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']>
  /** Stripe product ID for the session */
  stripeProductId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateCourseSession */
export type UpdateCourseSessionPayload = {
  __typename: 'UpdateCourseSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** list of errors if update failed */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the updated course session */
  id: Scalars['ID']
}

/** Autogenerated input type of UpdateCustomerContact */
export type UpdateCustomerContactInput = {
  /** The address of the contact. */
  address: StripeAddressInput
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The customer’s full name or business name. */
  name: Scalars['String']
}

/** Autogenerated return type of UpdateCustomerContact */
export type UpdateCustomerContactPayload = {
  __typename: 'UpdateCustomerContactPayload'
  /** The address of the contact. */
  address: StripeAddress
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The customer’s full name or business name. */
  name: Scalars['String']
}

/** Autogenerated input type of UpdateHomepageCheck */
export type UpdateHomepageCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Has completed one search */
  completedAskAQuestionWithSearch?: InputMaybe<Scalars['Boolean']>
  /** Has viewed a program overview */
  completedChooseAProgram?: InputMaybe<Scalars['Boolean']>
  /** Completed a lesson, etc */
  completedCompleteALesson?: InputMaybe<Scalars['Boolean']>
  /** Has saved a piece of content */
  completedSaveInsightsForLater?: InputMaybe<Scalars['Boolean']>
  /** Has opened a Reforge collection */
  completedSetYourFirstGoal?: InputMaybe<Scalars['Boolean']>
  /** Name of current checklist */
  currentChecklist?: InputMaybe<Scalars['String']>
  /** Hide checklists on homepage */
  hideChecklists?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateHomepageCheck */
export type UpdateHomepageCheckPayload = {
  __typename: 'UpdateHomepageCheckPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Collection of homepage checks */
  homepageChecks: HomepageCheck
}

/** Autogenerated input type of UpdateInvoiceUserFields */
export type UpdateInvoiceUserFieldsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Company address */
  companyAddress?: InputMaybe<Scalars['String']>
  /** Extra information */
  extraInformation?: InputMaybe<Scalars['String']>
  /** The ID of the invoice */
  id: Scalars['ID']
  /** Internal PO number */
  internalPoNumber?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateInvoiceUserFields */
export type UpdateInvoiceUserFieldsPayload = {
  __typename: 'UpdateInvoiceUserFieldsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the updated invoice */
  id: Scalars['ID']
}

/** Autogenerated input type of UpdatePaymentMethod */
export type UpdatePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** ID of the payment method in Stripe */
  id: Scalars['ID']
  /** Is the payment method the default. */
  isDefaultPaymentMethod?: InputMaybe<Scalars['Boolean']>
  /** ID of the user. */
  userId?: InputMaybe<Scalars['ID']>
}

/** Autogenerated return type of UpdatePaymentMethod */
export type UpdatePaymentMethodPayload = {
  __typename: 'UpdatePaymentMethodPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** ID of the payment method in Stripe. */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdatePreferences */
export type UpdatePreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Notification on comment activity */
  commentsActivity: Scalars['String']
  /** Notification when mentioned in discussions */
  mentionEmail: Scalars['String']
  /** Notification on posts activity */
  postsActivity: Scalars['String']
  /** Notification on reply activity */
  repliesActivity: Scalars['String']
}

/** Autogenerated return type of UpdatePreferences */
export type UpdatePreferencesPayload = {
  __typename: 'UpdatePreferencesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Success response */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of UpdateProjectDeliverable */
export type UpdateProjectDeliverableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Id of the project deliverable */
  id: Scalars['ID']
  /** Pass null to retry */
  url?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateProjectDeliverable */
export type UpdateProjectDeliverablePayload = {
  __typename: 'UpdateProjectDeliverablePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated project deliverable */
  projectDeliverable?: Maybe<ProjectDeliverable>
}

/** Autogenerated input type of UpdateReply */
export type UpdateReplyInput = {
  /** Reply Body */
  body: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Reply ID */
  id: Scalars['ID']
  /** Should substitute newlines from body */
  substituteNewlines?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateReply */
export type UpdateReplyPayload = {
  __typename: 'UpdateReplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The ID of the deleted reply */
  id?: Maybe<Scalars['ID']>
  /** ID of the associated post or team post */
  replyableId: Scalars['ID']
}

/** Autogenerated input type of UpdateTeam */
export type UpdateTeamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Domain name to find team by */
  domainName?: InputMaybe<Scalars['String']>
  /** The ID of the team/subscription */
  id: Scalars['ID']
  /** True if team can be discovered */
  isDiscoverable?: InputMaybe<Scalars['Boolean']>
  /** Logo data */
  logoDataUrl?: InputMaybe<Scalars['String']>
  /** Team name */
  name?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateTeam */
export type UpdateTeamPayload = {
  __typename: 'UpdateTeamPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** True if update was successful */
  success: Scalars['Boolean']
  /** Updated team */
  team?: Maybe<Subscription>
}

/** Autogenerated input type of UpdateTeamPost */
export type UpdateTeamPostInput = {
  /** Anchor for inline team post */
  anchor?: InputMaybe<Scalars['String']>
  /** Based on for inline team post */
  basedOn?: InputMaybe<Scalars['String']>
  /** Description of the team post */
  body?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Cms section ID for inline team post */
  cmsSectionId?: InputMaybe<Scalars['Int']>
  /** ID of the team post */
  id: Scalars['ID']
  /** Reference image url for inline team post */
  referenceImageUrl?: InputMaybe<Scalars['String']>
  /** Should substitute newlines from body */
  substituteNewlines?: InputMaybe<Scalars['Boolean']>
}

/** Autogenerated return type of UpdateTeamPost */
export type UpdateTeamPostPayload = {
  __typename: 'UpdateTeamPostPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated team post */
  teamPost?: Maybe<TeamPost>
}

/** Autogenerated input type of UpdateTopicActivity */
export type UpdateTopicActivityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** if true, set last_viewed_at to now */
  setLastViewedAt?: InputMaybe<Scalars['Boolean']>
  /** Topic id */
  topicId: Scalars['ID']
}

/** Autogenerated return type of UpdateTopicActivity */
export type UpdateTopicActivityPayload = {
  __typename: 'UpdateTopicActivityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** ID of topic activity */
  id?: Maybe<Scalars['ID']>
  /** last_viewed_at timestamp of topic activity */
  lastViewedAt?: Maybe<Scalars['ID']>
  /** Success response */
  success?: Maybe<Scalars['Boolean']>
}

/** Autogenerated input type of UpdateUserCohort */
export type UpdateUserCohortInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /**
   * whether the user has
   *                                                 clicked the CTA to meet cohort participants on slack
   */
  hasClickedMeetCohortParticipantsCta?: InputMaybe<Array<Scalars['String']>>
  /**
   * whether the user
   *                                               has clicked the CTA to share the cohort snapshot
   */
  hasClickedShareSnapshotCta?: InputMaybe<Scalars['Boolean']>
  /**
   * whether
   *                                                      the user has dismissed the new content eir message
   */
  hasDismissedNewContentEirMessage?: InputMaybe<Scalars['Boolean']>
  /**
   * whether the user
   *                                                      has dismmissed the post cohort eir message
   */
  hasDismissedPostCohortEirMessage?: InputMaybe<Scalars['Boolean']>
  /** The ID of the user_cohort */
  id: Scalars['ID']
}

/** Autogenerated return type of UpdateUserCohort */
export type UpdateUserCohortPayload = {
  __typename: 'UpdateUserCohortPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the user_cohort */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdateUserCourse */
export type UpdateUserCourseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /**
   * whether the user has
   *                                                 clicked the survey link
   */
  hasClickedOnSurveyLink?: InputMaybe<Scalars['Boolean']>
  /**
   * whether the user has
   *                                                 dismissed the post course callout
   */
  hasDismissedPostCourseCallout?: InputMaybe<Scalars['Boolean']>
  /** The ID of the user_course */
  id: Scalars['ID']
}

/** Autogenerated return type of UpdateUserCourse */
export type UpdateUserCoursePayload = {
  __typename: 'UpdateUserCoursePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** The ID of the user_course */
  id?: Maybe<Scalars['ID']>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** About me text. */
  aboutMeText?: InputMaybe<Scalars['String']>
  /** Ask me about text. */
  askMeAboutText?: InputMaybe<Scalars['String']>
  /** Avatar URL. */
  avatarUrl?: InputMaybe<Scalars['String']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Community interest text. */
  communityInterestText?: InputMaybe<Scalars['String']>
  /** Company name. */
  companyName?: InputMaybe<Scalars['String']>
  /** Company product text. */
  companyProductText?: InputMaybe<Scalars['String']>
  /** Author cta button text. */
  ctaText?: InputMaybe<Scalars['String']>
  /** Author cta button url. */
  ctaUrl?: InputMaybe<Scalars['String']>
  /** First name. */
  firstName?: InputMaybe<Scalars['String']>
  /** Force flag */
  forceFlag?: InputMaybe<Scalars['String']>
  /** ID of an user */
  id?: InputMaybe<Scalars['ID']>
  /** Last name. */
  lastName?: InputMaybe<Scalars['String']>
  /** Learning goals text. */
  learningGoalsText?: InputMaybe<Scalars['String']>
  /** Linkedin. */
  linkedin?: InputMaybe<Scalars['String']>
  /** Location. */
  location?: InputMaybe<Scalars['String']>
  /** If onboarded to Artifacts. */
  networkOnboarded?: InputMaybe<Scalars['Boolean']>
  /** Network profile. */
  networkProfile?: InputMaybe<Scalars['JSON']>
  /** Artifact recommendations based on chosen topics. */
  networkRecommendations?: InputMaybe<Array<Scalars['String']>>
  /** Legacy or new view for courses */
  preferredCourseView?: InputMaybe<Scalars['String']>
  /** Google account email adddress used for projects */
  projectEmail?: InputMaybe<Scalars['String']>
  /** Role at the company. */
  role?: InputMaybe<Scalars['String']>
  /** Allow direct messages on slack. */
  slackAllowDirectMessages?: InputMaybe<Scalars['Boolean']>
  /** Response time on slack. */
  slackResponseTime?: InputMaybe<Scalars['Int']>
  /** Status. */
  status?: InputMaybe<UserStatus>
  /** The timezone. */
  timezone?: InputMaybe<Scalars['String']>
  /** Twitter. */
  twitter?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** The updated user (if successful). */
  user?: Maybe<User>
}

/** Autogenerated input type of UpgradeSubscription */
export type UpgradeSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** The number of cohort passes to add to the subscription */
  numCohortPasses?: InputMaybe<Scalars['Int']>
  /** The number of seats for the subscription */
  numSeats?: InputMaybe<Scalars['Int']>
  /** The ID of the payment method to use for the upgrade */
  paymentMethodId?: InputMaybe<Scalars['String']>
  /** The name of the plan to upgrade to */
  planName: Scalars['String']
  /** The ID of the subscription to upgrade */
  subscriptionId: Scalars['ID']
}

/** Autogenerated return type of UpgradeSubscription */
export type UpgradeSubscriptionPayload = {
  __typename: 'UpgradeSubscriptionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Errors that occurred during the upgrade */
  errors?: Maybe<Array<Scalars['String']>>
  /** Whether the upgrade was successful */
  success: Scalars['Boolean']
}

/** UQ Question Type */
export type UqChoice = {
  __typename: 'UqChoice'
  /** Choice */
  choice?: Maybe<Scalars['String']>
  /** Choice ID */
  id: Scalars['ID']
  /** Choice Tags */
  tags?: Maybe<Scalars['String']>
}

/** UQ Question Type */
export type UqQuestion = {
  __typename: 'UqQuestion'
  /** Choice text */
  choiceText?: Maybe<Scalars['String']>
  /** Questions choices */
  choices: Array<UqChoice>
  /** Question description */
  description?: Maybe<Scalars['String']>
  /** Question ID */
  id: Scalars['ID']
  /** Maximum choices */
  maxChoices?: Maybe<Scalars['Int']>
  /** Question page */
  page: Scalars['Int']
  /** Question position */
  position: Scalars['Int']
  /** Question */
  question: Scalars['String']
}

/** UQ Questionnaire Type */
export type UqQuestionnaire = {
  __typename: 'UqQuestionnaire'
  /** Questionnaire ID */
  id: Scalars['ID']
  /** Number of pages */
  pages: Scalars['Int']
  /** Onboarding questionnaire */
  questionnaire?: Maybe<OnboardingQuestionnaire>
  /** Questions */
  questions: Array<UqQuestion>
  /** Questionnaire Title */
  title: Scalars['String']
}

/** UQ Question Type */
export type UqUserAnswer = {
  __typename: 'UqUserAnswer'
  /** Choice */
  choice: Scalars['String']
  /** Choice Tags */
  choiceTags: Scalars['String']
  /** Answer ID */
  id: Scalars['ID']
  /** Question */
  question: Scalars['String']
  /** Question ID */
  uqChoiceId: Scalars['ID']
  /** Question ID */
  uqQuestionId: Scalars['ID']
}

/** UQ User Answers Type */
export type UqUserAnswers = {
  __typename: 'UqUserAnswers'
  /** Acceptance answered */
  acceptanceAnswered: Scalars['Boolean']
  /** User answers */
  answers: Array<UqUserAnswer>
  /** Onboarding step */
  onboardingStep: Scalars['Int']
}

/** Autogenerated input type of UrlChange */
export type UrlChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Full URL of the page */
  fullUrl: Scalars['String']
  /** Referring URL if available */
  referrer: Scalars['String']
  /** Query search parameters if available */
  search: Scalars['String']
  /** User agent of the browser if available */
  userAgent: Scalars['String']
}

/** Autogenerated return type of UrlChange */
export type UrlChangePayload = {
  __typename: 'UrlChangePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
}

/** A User in the system. */
export type User = {
  __typename: 'User'
  /** About me */
  aboutMeText?: Maybe<Scalars['String']>
  /** Type of access policy a user has. */
  accessPolicyKind: Scalars['String']
  /** User active program */
  activeProgram?: Maybe<UserProgram>
  /** Active subscription membership */
  activeSubscriptionMembership?: Maybe<SubscriptionMember>
  /** Slack allow direct messages */
  allowDirectMessages: Scalars['Boolean']
  /** Artifact Bookmarks belonging to the user */
  artifactBookmarks?: Maybe<Array<Bookmark>>
  /** Ask me about text */
  askMeAboutText?: Maybe<Scalars['String']>
  /** Available team subscriptions that the user can join */
  availableTeamSubscriptions: Array<Subscription>
  /** Avatar URL */
  avatarUrl: Scalars['String']
  /** User challenges */
  biggestChallenges?: Maybe<Scalars['String']>
  /** Modules with bookmarks */
  bookmarkCmsModules: Array<CmsModule>
  /** Programs with bookmarks */
  bookmarkCmsPrograms: Array<CmsProgram>
  /** The bookmark folders for a user */
  bookmarkFolders: Array<BookmarkFolder>
  /** User bookmarks that are pageable */
  bookmarksFeed: BookmarksFeed
  /** Total number of bookmarks for user meeting filter params */
  bookmarksTotal: Scalars['Int']
  /**
   * Information about whether a user should be granted access to content,
   *                                     pages, and functionalities.
   */
  can: UserCan
  /**
   * User was part of experiment to get an additional free
   *                                                    90 days added to their subscription when purchased
   */
  canSubscribeWithoutTrial: Scalars['Boolean']
  /** User completion certificates */
  certificates: Array<Certificates>
  /** All user clips */
  clips: Array<Clip>
  /** Cohort type */
  cohort?: Maybe<Cohort>
  /** list of all user activity for a cohort */
  cohortAllActivitiesWithMetadata: CohortActivityListWithMetadata
  /**
   * Information about the live cohorts of a user,
   *                                             past, present, and future
   */
  cohorts?: Maybe<UserCohorts>
  /** a list of hosted cohorts */
  cohortsHosted: Array<Cohort>
  /**
   * Cohorts that are currently showing NPS
   *                                                                survey
   */
  cohortsShowingNpsSurvey?: Maybe<Array<UserCohort>>
  /** Community interest text */
  communityInterestText?: Maybe<Scalars['String']>
  /** Name of the company */
  companyName?: Maybe<Scalars['String']>
  /** The date when a user last updated their current company */
  companyNameUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Company product text */
  companyProductText?: Maybe<Scalars['String']>
  /** Status of product tours */
  completedProductTours?: Maybe<ProductTour>
  /** Information related to how we contact a user */
  contact: UserContact
  /** Course Bookmarks belonging to the user */
  courseBookmarks?: Maybe<Array<Bookmark>>
  /** User current enrolled course */
  currentEnrolledCourse?: Maybe<UserCourse>
  /** The current team subscription the user is part of */
  currentTeam?: Maybe<Subscription>
  /** User current working on */
  currentWorkingOn?: Maybe<Scalars['String']>
  /** The date when a user last dismissed the <UpdateCompanyBanner> */
  dismissedUpdateCompanyBannerAt?: Maybe<Scalars['ISO8601DateTime']>
  /** User email */
  email: Scalars['String']
  /** a list of events user is enrolled */
  enrolledCohortEvents: Array<Event>
  /** Enrolled programs */
  enrolledPrograms: Array<CmsProgram>
  /** Event Bookmarks belonging to the user */
  eventBookmarks?: Maybe<Array<Bookmark>>
  /**
   * Cohorts user is event_speaker
   *                                                                           for, for the activeseason(s)
   */
  eventSpeakerCohortsForActiveSeasons: Array<Cohort>
  /**
   * Current or future events the user is attending.
   *                                                  RSVP = "Attending"
   */
  eventsAttending?: Maybe<Array<Event>>
  /**
   * Events that have recently started or
   *                                                                are starting in next 3 mins that the user has
   *                                                                RSVPed to.
   */
  eventsAttendingStartingSoon: Array<Event>
  /** a list of hosted or featured guest events */
  eventsHosted: Array<Event>
  /**
   * Events the user is not attending.
   *                                                      RSVP = "Not Attending"
   */
  eventsNotAttending?: Maybe<Array<Event>>
  /** Events for the user that have ended. */
  eventsPast?: Maybe<Array<Event>>
  /** Events that have most recently been interacted with */
  eventsRecentlyInteracted: Array<Event>
  /**
   * Events we recommend for the user
   *                                                      based on their occupation and interests.
   */
  eventsRecommended?: Maybe<Array<Event>>
  /** The UUID of a user exposed for non-members to pay for membership on behalf of said user */
  externalId?: Maybe<Scalars['ID']>
  /** First name of user */
  firstName?: Maybe<Scalars['String']>
  /** Any feature flags to force to be true for the user. */
  forceFlag?: Maybe<Scalars['String']>
  /** The list of ids of reforge collections a user has copied */
  forkedFolderIds?: Maybe<Array<Scalars['ID']>>
  /** Full name of user */
  fullName?: Maybe<Scalars['String']>
  /** Groups the user is associated with */
  groups: Array<Group>
  /** Guide Bookmarks belonging to the user */
  guideBookmarks?: Maybe<Array<Bookmark>>
  /** Has applied */
  hasApplied: Scalars['Boolean']
  /** Has badge of user */
  hasBadge: Scalars['Boolean']
  /**
   * Has program progress, or has enrolled in or taken
   *                                                          a cohort or course
   */
  hasContentInProgressOrComplete: Scalars['Boolean']
  /** User has member access */
  hasMemberAccess: Scalars['Boolean']
  /** User has undeleted posts */
  hasPosts: Scalars['Boolean']
  /** Has been informed of new collections? */
  hasSeenCollectionsPrompt: Scalars['Boolean']
  /** Whether user has seen the demographics modal */
  hasSeenDemographicsModal: Scalars['Boolean']
  /** Whether user has sent invites */
  hasSentInvites: Scalars['Boolean']
  /** Whether the user is on a team subscription */
  hasTeam?: Maybe<Scalars['Boolean']>
  /** Whether user has enrolled to upcoming cohort */
  hasUpcomingCohort: Scalars['Boolean']
  /** Whether user has useable cohort credits assigned to them */
  hasUseableCohortCredits: Scalars['Boolean']
  /** User headline */
  headline?: Maybe<Scalars['String']>
  /** Should hide slack banner */
  hideSlackBanner: Scalars['Boolean']
  /**
   * Homepage checks
   * @deprecated No longer used
   */
  homepageChecks: HomepageCheck
  /**
   * Cohorts user is cohort_host
   *                                                                        for, for the active season(s)
   */
  hostCohortsForActiveSeasons: Array<Cohort>
  /** The unique ID of a user. */
  id: Scalars['ID']
  /** In post cohort */
  inPostCohort: Scalars['Boolean']
  /** In progress course */
  inProgressCourses?: Maybe<Array<UserCourseActivity>>
  /** Information about the type of user. */
  is: UserIs
  /** True if current user is user */
  isCurrentUser: Scalars['Boolean']
  /** User is expired */
  isExpired: Scalars['Boolean']
  /** Job function of the user. */
  jobFunction?: Maybe<Scalars['String']>
  /** Job level of the user. */
  jobLevel?: Maybe<Scalars['String']>
  /** Slack ref url */
  joinSlackHref?: Maybe<Scalars['String']>
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** Last 3 forum contributions. */
  lastForumContributions: Array<ForumContribution>
  /** Last name of user */
  lastName?: Maybe<Scalars['String']>
  /**
   * Used for program filters
   *                                                                                on search page
   */
  launchedEvergreenPrograms: Array<CmsProgram>
  /** Learning goals text */
  learningGoalsText?: Maybe<Scalars['String']>
  /** User linkedin account name */
  linkedin?: Maybe<Scalars['String']>
  /** Used for NPS survery banner */
  liveProgramName?: Maybe<Scalars['String']>
  /** User location */
  location?: Maybe<Scalars['String']>
  /** Month the user joined Reforge. */
  monthJoined?: Maybe<Scalars['String']>
  /** Most recent past user-cohort */
  mostRecentPastUserCohort?: Maybe<UserCohort>
  /** Selected focus areas during/after onboarding */
  networkFocus?: Maybe<Array<Scalars['String']>>
  /** Whether the user went though the network onboarding */
  networkOnboarded: Scalars['Boolean']
  /** Artifact recommendations based on chosen topics */
  networkRecommendations: Array<Scalars['String']>
  /**
   * hash with key of module id and value as
   *                                                                    number of bookmarks for that module
   */
  numModuleBookmarks?: Maybe<Scalars['JSON']>
  /**
   * hash with key of section id and value
   *                                                                    as number of bookmarks for that module
   */
  numSectionBookmarks?: Maybe<Scalars['JSON']>
  /**
   * OAUTH accounts on the user
   *                                                             (Google, Linkedin).
   */
  oauthProviders?: Maybe<Array<UserOauthProvider>>
  /** Onboarded */
  onboarded: Scalars['Boolean']
  /** Bookmark folder id for onboarding */
  onboardingBookmarkFolderId?: Maybe<Scalars['ID']>
  /** Open invoices */
  openInvoices: Array<StripeInvoice>
  /** Orgs that user is an admin of */
  orgs: Array<Org>
  /**
   * Org that user is an admin of
   * @deprecated Org modeling is changing.
   */
  ownedOrg?: Maybe<Org>
  /** User passionate about */
  passionateAbout?: Maybe<Scalars['String']>
  /** Past payments */
  pastPayments: Array<PastPayment>
  /**
   * Link to allow someone else to pay for this user's Reforge subscription.
   * @deprecated removed help_me_pay page
   */
  patronageRequestLink: Scalars['String']
  /**
   * Link to pay for Reforge subscription.
   * @deprecated unused field: use patronage_request_link for users to send to patrons
   */
  paymentLink: Scalars['String']
  /** User payment methods in Stripe. */
  paymentMethods: Array<StripePaymentMethod>
  /** Current user preference */
  preference?: Maybe<UserPreference>
  /** Legacy or new view for courses */
  preferredCourseView: Scalars['String']
  /** previous companies */
  previousCompanies: Array<UserCompany>
  /** Information about subscription plan pricing for a user */
  pricing: UserPricing
  /** Personal information about a user. */
  profile: UserProfile
  /** Program Bookmarks belonging to the user */
  programBookmarks?: Maybe<Array<Bookmark>>
  /** A user's progress through the content */
  programProgress?: Maybe<ProgramProgress>
  /** Users that share team and have made program progress */
  programTeammates: Array<User>
  /** Program topics used for search page */
  programTopics: Array<Scalars['String']>
  /** 3 recent slack joins */
  recentSlackJoins: Array<SlackAccount>
  /**
   * 2 most recent activities
   *                                                                                taken related to cms content
   */
  recentlyReviewedContent: Array<Content>
  /**
   * The recommended collections
   *                                                                       for a team
   */
  recommendedBookmarkFolders: Array<BookmarkFolder>
  /**
   * Programs that Reforge recommends for
   *                                                           the user based on their goals from the enrollment
   *                                                           questionaire.
   */
  recommendedPrograms: Array<CmsProgram>
  /** User role */
  role?: Maybe<Scalars['String']>
  /** User role combined with current company */
  roleWithCompany?: Maybe<Scalars['String']>
  /**
   * Attendee records for the events for the
   *                                                                     user in this week
   */
  rsvpsInWeek: Array<EventAttendee>
  /** How many times user saw slack banner */
  seenSlackBannerCount: Scalars['Int']
  /** Whether to show the homepage courses section */
  shouldSeeHomepageCoursesSection: Scalars['Boolean']
  /** True if the user is a trial member. */
  shouldSeeTrialMessaging: Scalars['Boolean']
  /** Whether to show the NPS survey */
  shouldShowNpsSurvey?: Maybe<Scalars['Boolean']>
  /** Slack response time */
  slackResponseTime?: Maybe<Scalars['String']>
  /** The unique ID of a Reforge Slack user. */
  slackUserId?: Maybe<Scalars['ID']>
  /** Slug of user */
  slug?: Maybe<Scalars['String']>
  /** Whether the user profile is private or public */
  status: UserStatus
  /** Stripe Customer */
  stripeCustomer?: Maybe<StripeCustomer>
  /** All subscription join requests associated with a user. */
  subscriptionJoinRequests: Array<SubscriptionJoinRequest>
  /** All subscriptions associated with a user. */
  subscriptions?: Maybe<UserSubscriptions>
  /** User timezone that is set by the user in their profile. */
  timezone?: Maybe<Scalars['String']>
  /** User trial status */
  trialStatus?: Maybe<Scalars['String']>
  /** User twitter account name */
  twitter?: Maybe<Scalars['String']>
  /** List of confirmed email domains associated with user */
  uniqueEmailDomains: Array<Scalars['String']>
  /** Count of unseen user activities */
  unseenActivityCount: Scalars['Int']
  /** Count of unseen notifications for a cohort */
  unseenCohortNotificationsCount: Scalars['Int']
  /** List of upcoming events the user is eligible for */
  upcomingEligibleEvents: Array<Event>
  /** List of upcoming non-cohort events */
  upcomingNonCohortEvents: Array<Event>
  /** Collections of the user provided */
  userCollections?: Maybe<Array<BookmarkFolder>>
  /** Information about the courses the user has enrolled in */
  userCourses: UserCourses
  /** User program data */
  userProgram?: Maybe<UserProgram>
  /** User programs with progress started (in progress/complete) */
  userProgramsProgressStarted?: Maybe<Array<UserProgram>>
  /** User progress */
  userProgressByProgram?: Maybe<UserProgram>
  /** Email with a domain matching the team they are part of */
  workEmail?: Maybe<Scalars['String']>
  /** Year the user joined Reforge. */
  yearJoined?: Maybe<Scalars['String']>
}

/** A User in the system. */
export type UserBookmarkCmsModulesArgs = {
  moduleFilters?: InputMaybe<BookmarkModulesFilters>
}

/** A User in the system. */
export type UserBookmarkFoldersArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

/** A User in the system. */
export type UserBookmarksFeedArgs = {
  filters?: InputMaybe<BookmarkFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Scalars['String']>
}

/** A User in the system. */
export type UserBookmarksTotalArgs = {
  filters?: InputMaybe<BookmarkFilters>
}

/** A User in the system. */
export type UserCohortAllActivitiesWithMetadataArgs = {
  slug: Scalars['String']
}

/** A User in the system. */
export type UserEventsPastArgs = {
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** A User in the system. */
export type UserEventsRecentlyInteractedArgs = {
  userId?: InputMaybe<Scalars['ID']>
}

/** A User in the system. */
export type UserProgramTeammatesArgs = {
  slug: Scalars['String']
}

/** A User in the system. */
export type UserRsvpsInWeekArgs = {
  weekId: Scalars['ID']
}

/** A User in the system. */
export type UserUnseenCohortNotificationsCountArgs = {
  slug: Scalars['String']
}

/** A User in the system. */
export type UserUserCollectionsArgs = {
  userId: Scalars['ID']
}

/** Information about what a user can do in terms of content access and actions */
export type UserCan = {
  __typename: 'UserCan'
  /** True if the user can access all cohorts events regardless of cohort enrollment */
  accessAllCohortEventReplays: Scalars['Boolean']
  /** True if the user can change their notification preferences */
  editEmailPreferences?: Maybe<Scalars['Boolean']>
  /** True if the user can enroll in a cohort */
  enrollInCohort: Scalars['Boolean']
  /** True if the user can invite guests */
  inviteGuests?: Maybe<Scalars['Boolean']>
  /** True if user can access a link to search results */
  linkToSearchResults?: Maybe<Scalars['Boolean']>
  /** True if a user has access to my team, false otherwise. */
  manageMyTeam?: Maybe<Scalars['Boolean']>
  /** True if the user can onboard */
  onboard?: Maybe<Scalars['Boolean']>
  /** True if the user can make a payment */
  pay?: Maybe<Scalars['Boolean']>
  /** True if the user can view activity */
  viewActivity?: Maybe<Scalars['Boolean']>
  /** True if the user can view activity sidebar */
  viewActivitySidebar?: Maybe<Scalars['Boolean']>
  /** True if the user can view the billing page */
  viewBilling?: Maybe<Scalars['Boolean']>
  /** True if the user can view browse page */
  viewBrowsePage?: Maybe<Scalars['Boolean']>
  /** True if the user can view the cohort history page */
  viewCohortHistory?: Maybe<Scalars['Boolean']>
  /** True if user can view the cohort index page */
  viewCohortIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view the collections index page */
  viewCollectionsIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view the concepts index page */
  viewConceptsIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view the projects, concepts sidebar */
  viewConceptsProjectsSidebar?: Maybe<Scalars['Boolean']>
  /** True if the user can view discussion index page */
  viewDiscussionIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view events. */
  viewEvent?: Maybe<Scalars['Boolean']>
  /** True if the user can view events index page */
  viewEventsIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view learning goals. */
  viewLearningGoals?: Maybe<Scalars['Boolean']>
  /** True if the user can manage subscription seats */
  viewManageSeats?: Maybe<Scalars['Boolean']>
  /** True if the user can view member index */
  viewMemberIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view member profiles. */
  viewMemberProfile?: Maybe<Scalars['Boolean']>
  /** True if the user can view an org. */
  viewOrg?: Maybe<Scalars['Boolean']>
  /** True if the user can view posts. */
  viewPost?: Maybe<Scalars['Boolean']>
  /** True if the user can view programs page */
  viewPrograms?: Maybe<Scalars['Boolean']>
  /** True if the user can view program dashboard page */
  viewProgramsDashboard?: Maybe<Scalars['Boolean']>
  /** True if the user can view the projects and concepts pages */
  viewProjectsAndConcepts?: Maybe<Scalars['Boolean']>
  /** True if the user can view the projects page index */
  viewProjectsIndex?: Maybe<Scalars['Boolean']>
  /** True if the user can view the purchase flow */
  viewPurchaseFlow?: Maybe<Scalars['Boolean']>
  /** True if the user can view search tabs. */
  viewSearchTabs?: Maybe<Scalars['Boolean']>
  /** True if the user can view sidebar groups */
  viewSidebarGroups?: Maybe<Scalars['Boolean']>
  /**
   * True if the user can view the subscription summary
   *                                                information on the account
   */
  viewSubscriptionSummary?: Maybe<Scalars['Boolean']>
}

/** UserCmsActivity type */
export type UserCmsActivity = {
  __typename: 'UserCmsActivity'
  /** Action taken in the CMS */
  actionType?: Maybe<Scalars['String']>
  /** The CmsProgram related to the activity */
  cmsProgram?: Maybe<CmsProgram>
  /** The CmsSection related to the activity */
  cmsSection?: Maybe<CmsSection>
  /** The time since the activity was created */
  createdAtInWords: Scalars['String']
  /** ID of UserCmsActivity */
  id: Scalars['ID']
  /** The User related to the activity */
  user: User
  /** The id of the user who did the action */
  userId: Scalars['ID']
}

/** Record associating a user with a cohort */
export type UserCohort = {
  __typename: 'UserCohort'
  /** Has dismissed post-cohort callout. */
  afterCohortCalloutDismissed: Scalars['Boolean']
  /** Is true only if enrollments are still open. */
  canBeUpdated: Scalars['Boolean']
  /** Is true only if member has completed at least 80% of program. */
  canViewCertificate: Scalars['Boolean']
  /** Cohort assigned to user */
  cohort: Cohort
  /** A cohort completion certificate */
  cohortCompletionCertificate?: Maybe<CohortCertificate>
  /** Number of cohort event user has attended */
  cohortEventsAttended?: Maybe<Scalars['Int']>
  /**
   * Whether the user has
   *                                                   clicked the CTA to meet cohort participants on slack
   */
  hasClickedMeetCohortParticipantsCta: Array<Scalars['String']>
  /**
   * whether the user has
   *                                            clicked the CTA to share the cohort snapshot
   */
  hasClickedShareSnapshotCta: Scalars['Boolean']
  /** Whether the user has completed cohort */
  hasCompletedCohort: Scalars['Boolean']
  /**
   * whether the user
   *                                                   dissmissed the new content eir message
   */
  hasDismissedNewContentEirMessage: Scalars['Boolean']
  /**
   * whether the user
   *                                                            has dismmissed the post cohort eir message
   */
  hasDismissedPostCohortEirMessage: Scalars['Boolean']
  /** Whether the user has said hello to the cohort */
  hasPostedWelcome: Scalars['Boolean']
  /**
   * Whether the user
   *                                                         has completed the statement of commitment
   */
  hasSignedStatementOfCommitment: Scalars['Boolean']
  /** ID */
  id: Scalars['ID']
  /** Number of cohort lessons user has completed */
  numLessonsCompleted?: Maybe<Scalars['Int']>
  /**
   * whether user posted in conversations
   *                                                                                    for scheduled week
   */
  postedInConversationsTopicForScheduledWeek?: Maybe<Scalars['Boolean']>
  /** ID of the program application */
  programApplicationId?: Maybe<Scalars['ID']>
  /** ID of the CMS program affiliated with the cohort */
  programId: Scalars['ID']
  /** Name of the CMS program affiliated with the cohort */
  programName: Scalars['String']
  /** Name of the season associated with the cohort */
  season: Scalars['String']
  /** ID of the season associated with the cohort */
  seasonId: Scalars['ID']
  /** Whether to show the bulk event RSVP modal */
  showEventRsvpModal: Scalars['Boolean']
  /** Program completion snapshot */
  snapshot?: Maybe<Snapshot>
  /** Whether the user cohort has a snapshot */
  snapshotable: Scalars['Boolean']
  /** The time grouping selected by the user for bulk rsvp */
  timeGroupingSelected?: Maybe<Scalars['String']>
  /** The User */
  user: User
}

/** Record associating a user with a cohort */
export type UserCohortPostedInConversationsTopicForScheduledWeekArgs = {
  scheduledWeekId: Scalars['ID']
}

/** Information about the live cohorts of a user, past, present, and future */
export type UserCohorts = {
  __typename: 'UserCohorts'
  /** All cohorts the user is or has been enrolled in */
  all: Array<UserCohort>
  /**
   * Cohorts the user is enrolled in for the active season.
   *                                              There can be more than one during overlapping active seasons.
   */
  current: Array<UserCohort>
  /**
   * The latest cohort the user is enrolled in for the
   *                                                                active season.
   */
  latestCurrent?: Maybe<UserCohort>
  /** Cohorts the user was in during past seasons. */
  previous: Array<UserCohort>
}

/** Previous companies the user has worked for. */
export type UserCompany = {
  __typename: 'UserCompany'
  /** Company the user has worked for. */
  company: Company
  /** ID */
  id: Scalars['ID']
}

/** Information related to how we contact a user */
export type UserContact = {
  __typename: 'UserContact'
  /**
   * Array of all non soft-deleted user emails from the emails table.
   *                                  This should include their primary email from their user record.
   */
  allEmails: Array<Scalars['String']>
  /**
   * How often a user would like
   *                                                                              notifications.
   */
  notificationPreferences: UserNotificationPreferences
  /**
   * Primary user email from emails table.
   *                                   These should stay in sync. This is the email used for all
   *                                   communications from the Reforge platform.
   */
  primaryEmail: Scalars['String']
  /** Email address to invite user to projects. */
  projectEmail?: Maybe<Scalars['String']>
  /**
   * A user's Slack account. Will be null if
   *                                                                the user doesn't have a Slack account with Reforge.
   */
  slackAccount?: Maybe<SlackAccount>
}

/** Data for user_course */
export type UserCourse = {
  __typename: 'UserCourse'
  /** CclCourse equivalent of the course on this course session */
  cclCourse?: Maybe<CclCourse>
  /** CclCourseSession equivalent of this course session */
  cclCourseSession?: Maybe<CclCourseSession>
  /** Cohort credit used to purchase the user course if one was used */
  cohortCredit?: Maybe<CohortCredit>
  /** The course */
  course: Course
  /** A course completion certificate */
  courseCompletionCertificate?: Maybe<CourseCertificate>
  /** The course enrollment snapshot */
  courseCompletionSnapshot?: Maybe<CourseCompletionSnapshot>
  /** The course enrollment snapshot */
  courseEnrollmentSnapshot?: Maybe<CourseEnrollmentSnapshot>
  /** The ID of the course */
  courseId: Scalars['ID']
  /** The course session */
  courseSession: CourseSession
  /** The ID of the course session */
  courseSessionId: Scalars['ID']
  /** Whether the user has clicked on the survey link */
  hasClickedOnSurveyLink?: Maybe<Scalars['Boolean']>
  /**
   * whether the user has
   *                                                 dismissed the post course callout
   */
  hasDismissedPostCourseCallout?: Maybe<Scalars['Boolean']>
  /** The ID of the user_course */
  id: Scalars['ID']
  /** Whether the user is enrolled */
  isEnrolled: Scalars['Boolean']
  /** The payment method used to purchase the course if one was used */
  paymentType?: Maybe<Scalars['String']>
  /** Whether the user is a shadow enrollee */
  shadow?: Maybe<Scalars['Boolean']>
  /** The stripe invoice ID if one exists */
  stripeInvoiceId?: Maybe<Scalars['String']>
  /** The ID of the user */
  userId: Scalars['ID']
}

/** User on demand course activity type */
export type UserCourseActivity = {
  __typename: 'UserCourseActivity'
  /** Course of user course activity */
  cclCourse: CclCourse
  /** Last time user has an activity */
  latestActivityAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Information about the courses of a user */
export type UserCourses = {
  __typename: 'UserCourses'
  /** All course sessions the user has enrolled in */
  all?: Maybe<Array<UserCourse>>
  /** Course session the user is enrolled in that has already started and has not ended yet */
  current?: Maybe<UserCourse>
  /** Last-ended course the user was enrolled in */
  last?: Maybe<UserCourse>
  /** Course sessions the user was enrolled in and that have ended */
  previous?: Maybe<Array<UserCourse>>
  /** Course sessions the user is enrolled in and that have not started */
  upcoming?: Maybe<Array<UserCourse>>
}

/** A saved user email address and its status */
export type UserEmail = {
  __typename: 'UserEmail'
  /** Whether the email address was verified by the user */
  confirmedByUser: Scalars['Boolean']
  /** The email address */
  email: Scalars['String']
  /** ID of the email address */
  id: Scalars['ID']
  /** Whether the email is set as primary */
  isPrimaryEmail: Scalars['Boolean']
}

/** The type of bookmark */
export enum UserEventActivity {
  /** A user started watching the recording */
  STARTEDWATCHING = 'StartedWatching',
  /** A user visited Event Page */
  VISITED = 'Visited'
}

/** Past Events user RSVP'd to or started watching */
export type UserEventsPastFeed = {
  __typename: 'UserEventsPastFeed'
  /** Total length of the events list queried. */
  count?: Maybe<Scalars['Int']>
  /** Events. */
  events?: Maybe<Array<Event>>
}

/** Information about the type of user. */
export type UserIs = {
  __typename: 'UserIs'
  /** True if the user has an access policy kind accepted */
  accepted: Scalars['Boolean']
  /** True if the user is an account_created. */
  accountCreated: Scalars['Boolean']
  /** True if user is an admin */
  admin: Scalars['Boolean']
  /**
   * True if the user is not presently an active member but was
   *                             previously a Reforge member or participated in a past cohort.
   */
  alumni: Scalars['Boolean']
  /**
   * True if the user has the Reforge badge associated with their
   *                                  avatar or profile
   */
  badgeholder: Scalars['Boolean']
  /** True if the user is banned */
  banned: Scalars['Boolean']
  /** True if the user is marked as part of the Reforge collective. */
  collective: Scalars['Boolean']
  /** True if the user has the copy_hold access policy */
  copyHold: Scalars['Boolean']
  /** User is creator */
  creator: Scalars['Boolean']
  /** False if the user is currently trialing or has ever trialed. */
  eligibleForTrial: Scalars['Boolean']
  /** True if the user is enrolled in an active course */
  enrolledInActiveCourse: Scalars['Boolean']
  /**
   * True if the user is enrolled in the season
   *                                                             open for enrollment
   */
  enrolledInEnrollmentSeason: Scalars['Boolean']
  /** True if the user is part of a current cohort. */
  enrolledInLiveProgram: Scalars['Boolean']
  /**
   * True if the user is a former member or participated in a
   *                              live program before membership.
   */
  expired: Scalars['Boolean']
  /** True if user's plan manager is expired */
  expiredPlanManager: Scalars['Boolean']
  /** True if user is a free user */
  freeUser: Scalars['Boolean']
  /** True if user enrolled in course that ended within 2 weeks ago */
  inPostCoursePeriod: Scalars['Boolean']
  /** True if the user is a member. Applied, accepted, has a subscription. */
  member: Scalars['Boolean']
  /** True if the user has the no_access access policy */
  noAccess: Scalars['Boolean']
  /** True if the user is attached to a paid subscription. */
  paidMember: Scalars['Boolean']
  /** True if user is a plan manager */
  planManager: Scalars['Boolean']
  /** True if user is in a premember state. */
  premember: Scalars['Boolean']
  /** True if user is in a SCIM provisioned subscription */
  provisionedByScim: Scalars['Boolean']
  /** True if the user is marked as part of the Reforge staff. */
  staff: Scalars['Boolean']
  /** True if the user is the owner of a subscription. */
  subscriptionOwner: Scalars['Boolean']
  /** True if the user is the owner of a team subscription. */
  teamSubscriptionOwner: Scalars['Boolean']
  /** True if the user is currently in a trial. */
  trialing: Scalars['Boolean']
}

/** User information returned when @mentioning a user in a text editor */
export type UserMention = {
  __typename: 'UserMention'
  /** User ID */
  id: Scalars['ID']
  /** User full name */
  name: Scalars['String']
  /** User profile slug */
  slug: Scalars['String']
  /** User role */
  title: Scalars['String']
}

/** User merge */
export type UserMerge = {
  __typename: 'UserMerge'
  /** ID of the user merge */
  id: Scalars['ID']
  /** Email of user account merged */
  mergedPrimaryEmail: Scalars['String']
}

/** User notification preferences. */
export type UserNotificationPreferences = {
  __typename: 'UserNotificationPreferences'
  /**
   * Frequency of
   *     notifications about activity on comment the user has made
   */
  commentsActivity: Scalars['String']
  /**
   * Frequency of
   *     digest emails of recent general Reforge activity
   */
  digestEmail: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /**
   * Frequency of notifications
   *     when the user is mentioned in the discussions forum
   */
  mentionEmail: Scalars['String']
  /**
   * Frequency of notifications
   *     about activity on posts the user has made
   */
  postsActivity: Scalars['String']
  /**
   * Frequency of notifications
   *     about activity on replies the user has made
   */
  repliesActivity: Scalars['String']
}

/** Oauth accounts the user has connected to in their account. */
export type UserOauthProvider = {
  __typename: 'UserOauthProvider'
  /** Date of creation. */
  createdAt?: Maybe<Scalars['String']>
  /** Oauth provider: Google, Linkedin, etc. */
  provider?: Maybe<Scalars['String']>
}

/** User patronage details */
export type UserPatronage = {
  __typename: 'UserPatronage'
  /** Link to stripe payment page for user's subscription */
  stripePaymentLink?: Maybe<Scalars['String']>
  /** True if user with given external ID was found */
  userExists: Scalars['Boolean']
  /** User full name */
  userFullName?: Maybe<Scalars['String']>
  /** True if user already has an active subscription */
  userHasActiveSubscription: Scalars['Boolean']
  /** User is eligible for patronage (accepted) */
  userIsEligibleForPatronage?: Maybe<Scalars['Boolean']>
}

/** User preference */
export type UserPreference = {
  __typename: 'UserPreference'
  /** Determine whether should skip collecting feedback for this user */
  skipFeedback: Scalars['Boolean']
}

/** Specific pricing information for the user. */
export type UserPricing = {
  __typename: 'UserPricing'
  /** Term for the proposed subscription. Usually "year," otherwise "month." */
  term: Scalars['String']
}

/** Personal information about a user */
export type UserProfile = UserProfileFieldsInterface & {
  __typename: 'UserProfile'
  /**
   * Text written by the user describing
   *                                               themselves and their career.
   */
  aboutMeText?: Maybe<Scalars['String']>
  /**
   * Indicates whether the user wants to be able to be
   *                                            Slack-messaged directly by other user. Will be null if the user
   *                                            does not have a Reforge Slack account.
   */
  allowDirectMessages?: Maybe<Scalars['Boolean']>
  /** Specific areas in which a user has expertise. */
  areasOfExpertiseTags?: Maybe<Scalars['String']>
  /** Text written by the user explaining their skillset. */
  askMeAboutText?: Maybe<Scalars['String']>
  /** URL to the user avatar image */
  avatarUrl: Scalars['String']
  /** The business model(s) of the user's current company. */
  businessModelTags?: Maybe<Scalars['String']>
  /**
   * Specific ways the user wants to leverage the
   *                                                         Reforge community.
   */
  communityInterestTags?: Maybe<Scalars['String']>
  /**
   * Text written by the user describing what they
   *                                             want from the Reforge community.
   */
  communityInterestText?: Maybe<Scalars['String']>
  /** The types of audiences the user's company targets. */
  companyAudienceTags?: Maybe<Scalars['String']>
  /** Current company name */
  companyName?: Maybe<Scalars['String']>
  /** Text written by the user explaining they company's product. */
  companyProductText?: Maybe<Scalars['String']>
  /** Percentage of the user profile that has been completed */
  completionPercentage?: Maybe<Scalars['Int']>
  /** Author cta button text */
  ctaText?: Maybe<Scalars['String']>
  /** Author cta button link */
  ctaUrl?: Maybe<Scalars['String']>
  /** User facebook url */
  facebook?: Maybe<Scalars['String']>
  /** User first name */
  firstName?: Maybe<Scalars['String']>
  /** User first name + user last name */
  fullName?: Maybe<Scalars['String']>
  /** Guest ID */
  guestId?: Maybe<Scalars['String']>
  /** Does user have badge */
  hasBadge?: Maybe<Scalars['Boolean']>
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** User last name */
  lastName?: Maybe<Scalars['String']>
  /**
   * Text written by the user describing what they
   *                                                     want out of Reforge.
   */
  learningGoalsText?: Maybe<Scalars['String']>
  /** User linkedin url */
  linkedin?: Maybe<Scalars['String']>
  /** Raw input of the user location. */
  location?: Maybe<Scalars['String']>
  /** Profile ported over from artifacts product */
  networkProfile?: Maybe<Scalars['JSON']>
  /** User's current onboarding step */
  onboardingStep: Scalars['Int']
  /** Array of companies the user has worked for */
  previousCompanies?: Maybe<Array<UserCompany>>
  /** Path to the user profile page. */
  profilePath?: Maybe<Scalars['String']>
  /** Profile url */
  profileUrl?: Maybe<Scalars['String']>
  /**
   * An indication of how long it takes for the member to respond
   *                                   to direct messages on Slack. Currently can be 'days', 'week' or null. Null
   *                                   indicates that the user either doesn't have a Reforge Slack Account or that
   *                                   they haven't yet indicated a response time.
   */
  responseTime?: Maybe<Scalars['String']>
  /** Current job title or role in their company */
  role?: Maybe<Scalars['String']>
  /** Slug for creator profile */
  sanitySlug?: Maybe<Scalars['String']>
  /** Slug for user profile */
  slug?: Maybe<Scalars['String']>
  /** Whether the user profile is private or public */
  status: UserStatus
  /**
   * Timezone set by the user on their profile. If none is set, when they are
   *                              looking at event times etc., they will be in the browser timezone
   */
  timezone?: Maybe<Scalars['String']>
  /** User twitter url */
  twitter?: Maybe<Scalars['String']>
}

/** Personal information about a user */
export type UserProfileAvatarUrlArgs = {
  size?: InputMaybe<Scalars['String']>
}

/** User profile fields shared with the public user profile */
export type UserProfileFieldsInterface = {
  /** URL to the user avatar image */
  avatarUrl: Scalars['String']
  /** Current company name */
  companyName?: Maybe<Scalars['String']>
  /** Author cta button text */
  ctaText?: Maybe<Scalars['String']>
  /** Author cta button link */
  ctaUrl?: Maybe<Scalars['String']>
  /** User first name + user last name */
  fullName?: Maybe<Scalars['String']>
  /** Does user have badge */
  hasBadge?: Maybe<Scalars['Boolean']>
  /** Kind of user */
  kind?: Maybe<Scalars['String']>
  /** User linkedin url */
  linkedin?: Maybe<Scalars['String']>
  /** Raw input of the user location. */
  location?: Maybe<Scalars['String']>
  /** Profile url */
  profileUrl?: Maybe<Scalars['String']>
  /** Current job title or role in their company */
  role?: Maybe<Scalars['String']>
  /** User twitter url */
  twitter?: Maybe<Scalars['String']>
}

/** User profile fields shared with the public user profile */
export type UserProfileFieldsInterfaceAvatarUrlArgs = {
  size?: InputMaybe<Scalars['String']>
}

/** User program type */
export type UserProgram = {
  __typename: 'UserProgram'
  /** CMS Program of user program */
  cmsProgram: CmsProgram
  /** Is existing user enrolled in this user program */
  enrolled?: Maybe<Scalars['Boolean']>
  /** Id of user program */
  id: Scalars['ID']
  /** User program kind */
  kind: Scalars['String']
  /** User program list of accepted participating user kinds */
  participatingKinds: Array<ProgramParticipatingKinds>
  /** Progress for user */
  progress?: Maybe<Scalars['ProgramProgressScalar']>
  /** Overall progress percent this user program */
  progressPercent?: Maybe<Scalars['Int']>
  /** Last time record updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Filters for user search */
export type UserSearchFilters = {
  /** Areas of expertise */
  areasOfExpertise?: InputMaybe<Scalars['String']>
  /** Business model */
  businessModel?: InputMaybe<Array<Scalars['String']>>
  /** Cohort ID */
  cohortId?: InputMaybe<Scalars['ID']>
  /** Company audience */
  companyAudience?: InputMaybe<Array<Scalars['String']>>
  /** Company contains */
  companyContains?: InputMaybe<Scalars['String']>
  /** Full name search */
  contains?: InputMaybe<Scalars['String']>
  /** User function */
  function?: InputMaybe<Scalars['String']>
  /** User role */
  kind?: InputMaybe<Array<Scalars['String']>>
  /** Location contains */
  locationContains?: InputMaybe<Scalars['String']>
  /** Professional relationship */
  professionalRelationship?: InputMaybe<Array<Scalars['String']>>
  /** Title contains */
  titleContains?: InputMaybe<Scalars['String']>
}

/** User status type */
export enum UserStatus {
  /** User private */
  PRIVATE = 'private',
  /** User public */
  PUBLIC = 'public'
}

/** All subscriptions associated with a user */
export type UserSubscriptions = {
  __typename: 'UserSubscriptions'
  /**
   * The current active subscription. If the user manages a
   *                                             team plan, this will be that subscription. If not, this will be a
   *                                             regular subscription.
   */
  active?: Maybe<Subscription>
  /** The last subscription. */
  last?: Maybe<Subscription>
  /** The last expired subscription for a user */
  lastExpired?: Maybe<Subscription>
  /** The most recent trial subscription, if the user has had one. */
  trial?: Maybe<Subscription>
}

/** Users feed. */
export type UsersFeed = {
  __typename: 'UsersFeed'
  /** Count of the users returned after search and filters. */
  count?: Maybe<Scalars['Int']>
  /** Count of the users returned before search and filters. */
  totalCount?: Maybe<Scalars['Int']>
  /** Users */
  users?: Maybe<Array<User>>
}

/** return type for email validation query */
export type ValidateEmail = {
  __typename: 'ValidateEmail'
  /** suggestion for end user */
  suggestion?: Maybe<Scalars['String']>
  /** is the email valid */
  valid: Scalars['Boolean']
}

/** Autogenerated input type of VerifyEmail */
export type VerifyEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Email nonce */
  nonce: Scalars['String']
}

/** Autogenerated return type of VerifyEmail */
export type VerifyEmailPayload = {
  __typename: 'VerifyEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** The company name */
  companyName?: Maybe<Scalars['String']>
  /** Is the email address the primary email address */
  isPrimaryEmail: Scalars['Boolean']
  /** The user first name */
  name?: Maybe<Scalars['String']>
}

/** Autogenerated input type of ViewBookmarkFolder */
export type ViewBookmarkFolderInput = {
  /** Bookmark Folder ID to be viewed */
  bookmarkFolderId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of ViewBookmarkFolder */
export type ViewBookmarkFolderPayload = {
  __typename: 'ViewBookmarkFolderPayload'
  /** The collection activity */
  activity?: Maybe<CollectionActivity>
  /** The bookmark folder */
  bookmarkFolder?: Maybe<BookmarkFolder>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** List of errors if applicable */
  errors?: Maybe<Array<Scalars['String']>>
  /** is collection the onboarding collection? */
  isOnboardingCollection?: Maybe<Scalars['Boolean']>
}

/** Record of the last time which user has viewed an Artifact */
export type ViewedArtifact = {
  __typename: 'ViewedArtifact'
  /** Artifact ID of which artifact user viewed */
  artifactId: Scalars['String']
  /** When user first viewed the artifact */
  createdAt: Scalars['ISO8601DateTime']
  /** ViewedArtifact ID */
  id: Scalars['ID']
  /** ViewedArtifact ID from Network DB for imported rows */
  originalNetworkId?: Maybe<Scalars['String']>
  /** When user last viewed the artifact */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Which user viewed the artifact */
  userId: Scalars['Int']
}

/** Autogenerated input type of WhatWeCanDoBetter */
export type WhatWeCanDoBetterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Survey response for: What could we do better? */
  whatWeCanDoBetter: Scalars['String']
}

/** Autogenerated return type of WhatWeCanDoBetter */
export type WhatWeCanDoBetterPayload = {
  __typename: 'WhatWeCanDoBetterPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** errors array if errors */
  errors?: Maybe<Array<Scalars['String']>>
  /** Whether the submission was successful */
  success: Scalars['Boolean']
}

/** Artifact authors work entry */
export type WorkEntry = {
  __typename: 'WorkEntry'
  /** Artifact contributions */
  artifactContributions?: Maybe<Array<ArtifactContribution>>
  /** Company */
  company?: Maybe<SanityCompany>
  /** End date */
  end?: Maybe<Scalars['String']>
  /** General role */
  generalRole?: Maybe<Scalars['String']>
  /** General timeframe */
  generalTimeframe?: Maybe<Scalars['String']>
  /** ID */
  id?: Maybe<Scalars['ID']>
  /** Position */
  position?: Maybe<Scalars['String']>
  /** Specific company */
  specificCompany?: Maybe<Scalars['Boolean']>
  /** Specific timeframe */
  specificTimeframe?: Maybe<Scalars['Boolean']>
  /** Start date */
  start?: Maybe<Scalars['String']>
}

export type ActivityFacepileCollectionActivityPartsFragment = {
  __typename: 'CollectionActivity'
  id: string
  actionType: string
  createdAtInWords: string
  userId: string
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    email: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }
}

export type CollectionActivityFacepileQueryVariables = Exact<{
  collectionId: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type CollectionActivityFacepileQuery = {
  __typename: 'Query'
  bookmarkFolder?: {
    __typename: 'BookmarkFolder'
    id: string
    createdAtInWords: string
    usersSharedWith: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }>
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
  } | null
  mostRecentCollectionActivityPerUser: Array<{
    __typename: 'CollectionActivity'
    id: string
    actionType: string
    createdAtInWords: string
    userId: string
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
  }>
}

export type ActivityFacepileCmsActivityPartsFragment = {
  __typename: 'UserCmsActivity'
  id: string
  actionType?: string | null
  createdAtInWords: string
  userId: string
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    email: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }
}

export type ActivityFacepileContentBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  created?: string | null
  mostRecentCmsActivityPerUser: Array<{
    __typename: 'UserCmsActivity'
    id: string
    actionType?: string | null
    createdAtInWords: string
    userId: string
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
  }>
}

export type ContentBookmarkActivityFacepileQueryVariables = Exact<{
  bookmarkId: Scalars['ID']
  collectionId: Scalars['ID']
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type ContentBookmarkActivityFacepileQuery = {
  __typename: 'Query'
  findBookmarkById?: {
    __typename: 'Bookmark'
    id: string
    created?: string | null
    mostRecentCmsActivityPerUser: Array<{
      __typename: 'UserCmsActivity'
      id: string
      actionType?: string | null
      createdAtInWords: string
      userId: string
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    }>
  } | null
  bookmarkFolder?: {
    __typename: 'BookmarkFolder'
    id: string
    user: { __typename: 'User'; id: string }
    usersSharedWith: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }>
  } | null
}

export type CheckoutMutationVariables = Exact<{
  input: CheckoutInput
}>

export type CheckoutMutation = {
  __typename: 'Mutation'
  checkout?: {
    __typename: 'CheckoutPayload'
    success?: boolean | null
    errors?: Array<string> | null
    clientSecret?: string | null
    stripeSubscriptionId?: string | null
  } | null
}

export type CheckoutModalQueryVariables = Exact<{ [key: string]: never }>

export type CheckoutModalQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    email: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        planName: PlanName
        stripeSubscriptionId?: string | null
        stripeCustomerId?: string | null
        startsAt?: string | null
        status?: string | null
      } | null
    } | null
  } | null
  plansForSale: Array<{
    __typename: 'PlanForSale'
    name: string
    pricePerPerson: number
    pricePerYear: number
    maxSeatCount: number
  }>
  cohortPassPriceTiers: Array<{
    __typename: 'CohortPassPriceTier'
    pricePerPass: number
    minCount: number
    maxCount: number
    stripeId?: string | null
  }>
}

export type PreviewLineItemFragment = {
  __typename: 'StripeInvoiceLineItem'
  productItemCode?: string | null
}

export type PreviewUpcomingInvoiceQueryVariables = Exact<{
  planName: Scalars['String']
  subscriptionId: Scalars['ID']
  numCohortPasses?: InputMaybe<Scalars['Int']>
}>

export type PreviewUpcomingInvoiceQuery = {
  __typename: 'Query'
  previewUpcomingInvoice?: {
    __typename: 'StripeUpcomingInvoice'
    amountDue: number
    discountedCohortPassAmount?: number | null
    subtotal: number
    startingBalance: number
    tax?: number | null
    proratedAdjustment?: {
      __typename: 'CheckoutProrationAdjustment'
      amount: number
      startDate: string
      endDate: string
      paidTaxOnPrevPlan: boolean
      amountForNewPlanRemainingTime: number
    } | null
    lines: Array<{ __typename: 'StripeInvoiceLineItem'; productItemCode?: string | null }>
  } | null
}

export type CohortPassPriceTiersQueryVariables = Exact<{ [key: string]: never }>

export type CohortPassPriceTiersQuery = {
  __typename: 'Query'
  cohortPassPriceTiers: Array<{
    __typename: 'CohortPassPriceTier'
    pricePerPass: number
    minCount: number
    maxCount: number
  }>
}

export type DatadogUserSessionQueryVariables = Exact<{ [key: string]: never }>

export type DatadogUserSessionQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    fullName?: string | null
    email: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        name?: string | null
        planName: PlanName
      } | null
    } | null
  } | null
}

export type AiChatUsersQueryVariables = Exact<{ [key: string]: never }>

export type AiChatUsersQuery = {
  __typename: 'Query'
  aiChatUsers: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    email: string
  }>
}

export type AiChatUserFragment = {
  __typename: 'User'
  id: string
  fullName?: string | null
  email: string
}

export type UserAiChatsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserAiChatsQuery = {
  __typename: 'Query'
  aiChats: Array<{ __typename: 'AiChat'; id: string; conversationText: string }>
}

export type CreateTestUsersMutationVariables = Exact<{
  input: CreateTestUsersInput
}>

export type CreateTestUsersMutation = {
  __typename: 'Mutation'
  createTestUsers?: {
    __typename: 'CreateTestUsersPayload'
    success?: boolean | null
    errors?: Array<string> | null
    subscription?: {
      __typename: 'Subscription'
      id: string
      planName: PlanName
      numSeats: number
    } | null
    subscriptionMembers?: Array<{
      __typename: 'SubscriptionMember'
      id: string
      email: string
      status: string
      user: { __typename: 'User'; id: string; email: string }
    }> | null
    subscriptionOwner?: { __typename: 'User'; id: string; email: string } | null
  } | null
}

export type GenerateAiGoalsMutationVariables = Exact<{
  input: GenerateAiGoalsInput
}>

export type GenerateAiGoalsMutation = {
  __typename: 'Mutation'
  generateAiGoals?: {
    __typename: 'GenerateAiGoalsPayload'
    success: boolean
    coachingSession?: {
      __typename: 'CoachingSession'
      id: string
      data?: any | null
      externalId: string
    } | null
  } | null
}

export type UpdateCoachingSessionMutationVariables = Exact<{
  input: UpdateCoachingSessionInput
}>

export type UpdateCoachingSessionMutation = {
  __typename: 'Mutation'
  updateCoachingSession?: {
    __typename: 'UpdateCoachingSessionPayload'
    coachingSession: {
      __typename: 'CoachingSession'
      id: string
      data?: any | null
      externalId: string
    }
  } | null
}

export type GenerateAiInferenceMutationVariables = Exact<{
  input: GenerateAiInferenceInput
}>

export type GenerateAiInferenceMutation = {
  __typename: 'Mutation'
  generateAiInference?: {
    __typename: 'GenerateAiInferencePayload'
    success: boolean
  } | null
}

export type RemoveAiChatMutationVariables = Exact<{
  input: RemoveAiChatInput
}>

export type RemoveAiChatMutation = {
  __typename: 'Mutation'
  removeAiChat?: { __typename: 'RemoveAiChatPayload'; id: string } | null
}

export type RenameAiChatMutationVariables = Exact<{
  input: RenameAiChatInput
}>

export type RenameAiChatMutation = {
  __typename: 'Mutation'
  renameAiChat?: {
    __typename: 'RenameAiChatPayload'
    aiChat: { __typename: 'AiChat'; id: string; title: string; createdAt: string }
  } | null
}

export type UpdateAiPersonalizationMutationVariables = Exact<{
  input: UpdateAiPersonalizationInput
}>

export type UpdateAiPersonalizationMutation = {
  __typename: 'Mutation'
  updateAiPersonalization?: {
    __typename: 'UpdateAiPersonalizationPayload'
    errors?: Array<string> | null
    aiPersonalization?: {
      __typename: 'AiPersonalization'
      id: string
      role?: string | null
      company?: string | null
      audience?: string | null
      industry?: string | null
      additionalDetails?: string | null
      enabled?: boolean | null
    } | null
  } | null
}

export type AiSessionHistoryQueryVariables = Exact<{
  sessionId: Scalars['ID']
}>

export type AiSessionHistoryQuery = {
  __typename: 'Query'
  aiSessionHistory: Array<{
    __typename: 'AiChatMessage'
    id: string
    content: string
    role: string
    data?: Array<any> | null
    debug?: any | null
    mode?: string | null
    modeOptions?: any | null
    documents?: any | null
    isPredefined: boolean
  }>
}

export type CourseInfoForTrackingQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseInfoForTrackingQuery = {
  __typename: 'Query'
  course?: { __typename: 'Course'; id: string; title: string; slug: string } | null
}

export type CourseSessionInfoForTrackingQueryVariables = Exact<{
  courseSessionId: Scalars['ID']
}>

export type CourseSessionInfoForTrackingQuery = {
  __typename: 'Query'
  courseSession?: {
    __typename: 'CourseSession'
    id: string
    hasStarted: boolean
    hasEnded: boolean
  } | null
}

export type AiExchangeChatIdForExtIdQueryVariables = Exact<{
  chatId: Scalars['ID']
}>

export type AiExchangeChatIdForExtIdQuery = {
  __typename: 'Query'
  exchangeChatIdForExtId: string
}

export type AiPersonalizationQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
}>

export type AiPersonalizationQuery = {
  __typename: 'Query'
  aiPersonalization?: {
    __typename: 'AiPersonalization'
    id: string
    role?: string | null
    company?: string | null
    audience?: string | null
    industry?: string | null
    additionalDetails?: string | null
    enabled?: boolean | null
  } | null
}

export type AiRecentChatsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  first?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
}>

export type AiRecentChatsQuery = {
  __typename: 'Query'
  recentChats: {
    __typename: 'AiChatConnection'
    pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
    edges: Array<{
      __typename: 'AiChatEdge'
      node: { __typename: 'AiChat'; extId: string; title: string; createdAt: string }
    }>
  }
}

export type AiRecentChatsCountQueryVariables = Exact<{ [key: string]: never }>

export type AiRecentChatsCountQuery = { __typename: 'Query'; recentChatsCount: number }

export type SuggestedPromptsQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']>
}>

export type SuggestedPromptsQuery = {
  __typename: 'Query'
  suggestedPrompts: Array<string>
}

export type QueryQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type QueryQuery = {
  __typename: 'Query'
  savedArtifactsForUser?: Array<{
    __typename: 'Bookmark'
    id: string
    sanityId?: string | null
  }> | null
  viewedArtifactsForUser?: Array<{
    __typename: 'ViewedArtifact'
    artifactId: string
  }> | null
  savedCountPerArtifact?: Array<{
    __typename: 'SavedCountPerArtifact'
    artifactId: string
    count: number
  }> | null
}

export type RecentlyViewedContentQueryVariables = Exact<{ [key: string]: never }>

export type RecentlyViewedContentQuery = {
  __typename: 'Query'
  recentlyViewedContent: Array<{
    __typename: 'RecentlyViewedContent'
    name: string
    path: string
  }>
}

export type CourseEventAddToCalendarPartsFragment = {
  __typename: 'CourseEvent'
  title?: string | null
  startsAt?: string | null
  endsAt?: string | null
  joinLink?: string | null
  shortDescription?: string | null
  sanityId?: string | null
}

export type CourseAddToCalendarPartsFragment = {
  __typename: 'Course'
  id: string
  title: string
}

export type CourseSessionAddToCalendarPartsFragment = {
  __typename: 'CourseSession'
  id: string
}

export type CurrentUserBannerPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  shouldShowNpsSurvey?: boolean | null
  liveProgramName?: string | null
  shouldSeeTrialMessaging: boolean
  dismissedUpdateCompanyBannerAt?: string | null
  companyName?: string | null
  companyNameUpdatedAt?: string | null
  hasUpcomingCohort: boolean
  cohorts?: {
    __typename: 'UserCohorts'
    current: Array<{
      __typename: 'UserCohort'
      id: string
      afterCohortCalloutDismissed: boolean
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        postCoreWeeksEnrollmentIsOpen: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
        group?: { __typename: 'Group'; id: string } | null
      }
    }>
  } | null
  cohortsShowingNpsSurvey?: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      cmsProgram: { __typename: 'CmsProgram'; id: string; name: string }
      season: {
        __typename: 'Season'
        id: string
        name: string
        year: string
        npsSurveyLink?: string | null
        npsSurveyText?: string | null
      }
    }
  }> | null
  is: {
    __typename: 'UserIs'
    alumni: boolean
    member: boolean
    paidMember: boolean
    enrolledInLiveProgram: boolean
    enrolledInActiveCourse: boolean
    enrolledInEnrollmentSeason: boolean
    trialing: boolean
  }
  can: { __typename: 'UserCan'; pay?: boolean | null; enrollInCohort: boolean }
  profile: { __typename: 'UserProfile'; timezone?: string | null }
  openInvoices: Array<{
    __typename: 'StripeInvoice'
    id: string
    status?: StripeInvoiceStatus | null
  }>
  paymentMethods: Array<{
    __typename: 'StripePaymentMethod'
    id: string
    type: string
    defaultPaymentMethod?: boolean | null
    card?: {
      __typename: 'StripePaymentMethodCard'
      brand: string
      last4: string
      expYear: number
      expMonth: number
      status: string
    } | null
  }>
  stripeCustomer?: { __typename: 'StripeCustomer'; id: string; balance: number } | null
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      id: string
      daysLeft: number
      expiresAt: string
      startsAt?: string | null
      isCanceled?: boolean | null
      isDiscoverable?: boolean | null
      isDunning: boolean
      isExpired?: boolean | null
      isPaidMonthly: boolean
      isTeam: boolean
      lastFailedPaymentAt?: string | null
      numSeats: number
      status?: string | null
      stripeCollectionMethod?: StripeCollectionMethod | null
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null
      userIsOwner: boolean
      renewSeatCount: number
      hasUnlimitedCohortAccess?: boolean | null
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        cancelAtPeriodEnd?: boolean | null
        id: string
      } | null
      stripeUpcomingInvoice?: {
        __typename: 'StripeUpcomingInvoice'
        amountDue: number
      } | null
    } | null
    last?: {
      __typename: 'Subscription'
      id: string
      isCanceled?: boolean | null
      isExpired?: boolean | null
      isTeam: boolean
      numSeats: number
      userIsOwner: boolean
    } | null
    trial?: {
      __typename: 'Subscription'
      id: string
      status?: string | null
      daysLeftInTrial?: number | null
    } | null
  } | null
}

export type BookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  anchor?: string | null
  noteBody?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
  type: BookmarkType
  videoCode?: string | null
  basedOn?: string | null
  title?: string | null
  externalUrl?: string | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    collaborators: Array<{
      __typename: 'Host'
      name?: string | null
      id: string
      companyName?: string | null
      description?: string | null
      title?: string | null
      thumbnailUrl: string
    }>
  } | null
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    path?: string | null
    contentType?: CmsSectionContentType | null
  } | null
}

export type BookmarkFolderPartsFragment = {
  __typename: 'BookmarkFolder'
  id: string
  name: string
  description?: string | null
  forkedFromId?: string | null
  goalComplete?: boolean | null
  isShared: boolean
  parentId?: string | null
  reforgeCollection: boolean
  teamProgress?: number | null
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }
  sharedFolders: Array<{
    __typename: 'SharedFolder'
    id: string
    status: SharedFolderStatus
    sharedPartyId: string
    sharedPartyType: string
    userId: string
    bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
  }>
}

export type BookmarkFolderWithSharingPartsFragment = {
  __typename: 'BookmarkFolder'
  id: string
  name: string
  description?: string | null
  forkedFromId?: string | null
  goalComplete?: boolean | null
  isShared: boolean
  parentId?: string | null
  reforgeCollection: boolean
  teamProgress?: number | null
  usersSharedWith: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }>
  individualUsersSharedWith: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }>
  teamMembersWhoStartedGoal?: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    avatarUrl: string
  }> | null
  teamMembersWhoCompletedGoal?: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    avatarUrl: string
  }> | null
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }
  sharedFolders: Array<{
    __typename: 'SharedFolder'
    id: string
    status: SharedFolderStatus
    sharedPartyId: string
    sharedPartyType: string
    userId: string
    bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
  }>
}

export type SharedFolderFragment = {
  __typename: 'SharedFolder'
  id: string
  status: SharedFolderStatus
  sharedPartyId: string
  sharedPartyType: string
  userId: string
  bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
}

export type CmsProgramFilterPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  progressPercent: number
  recommendationRanking?: number | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  notLaunched?: boolean | null
  enrolled: boolean
}

export type CmsProgramCardPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  lessonCount: number
  slug: string
  name: string
  canEnroll: boolean
  canEnrollForCurrentSeason: boolean
  hasPreviewableContent: boolean
  iconImageUrl?: string | null
  synopsis?: string | null
  releaseSeasonDate?: string | null
  releaseStartDate?: string | null
  releaseEnrollmentDate?: string | null
  launchAt?: string | null
  deprecatedAt?: string | null
  numBookmarks?: number | null
  code?: string | null
  progressPercent: number
  notLaunched?: boolean | null
  enrolled: boolean
  recommendationRanking?: number | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
}

export type CmsProgramListPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  topic?: string | null
  progressPercent: number
  recommendationRanking?: number | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  notLaunched?: boolean | null
  enrolled: boolean
  lessonCount: number
  slug: string
  name: string
  canEnroll: boolean
  canEnrollForCurrentSeason: boolean
  hasPreviewableContent: boolean
  iconImageUrl?: string | null
  synopsis?: string | null
  releaseSeasonDate?: string | null
  releaseStartDate?: string | null
  releaseEnrollmentDate?: string | null
  launchAt?: string | null
  deprecatedAt?: string | null
  numBookmarks?: number | null
  code?: string | null
}

export type CohortLinksPartsFragment = {
  __typename: 'Cohort'
  id: string
  slug: string
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    iconImageUrl?: string | null
    name: string
  }
  season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
}

export type CohortLinksUserPartsFragment = {
  __typename: 'User'
  accessPolicyKind: string
  cohorts?: {
    __typename: 'UserCohorts'
    latestCurrent?: {
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          iconImageUrl?: string | null
          name: string
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
      }
    } | null
  } | null
  eventSpeakerCohortsForActiveSeasons: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      name: string
    }
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }>
  hostCohortsForActiveSeasons: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      name: string
    }
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }>
}

export type ContentBlockCollapsiblePartsFragment = {
  __typename: 'ContentBlockCollapsible'
  id: string
  type?: string | null
  description?: any | null
  label?: string | null
}

export type ContentBlockPartsFragment = {
  __typename: 'ContentBlock'
  id: string
  header?: string | null
  slug: string
  order?: number | null
  description?: any | null
  contentBlockCollapsible?: Array<{
    __typename: 'ContentBlockCollapsible'
    id: string
    type?: string | null
    description?: any | null
    label?: string | null
  }> | null
}

export type CourseDetailPartsFragment = {
  __typename: 'CclCourse'
  id: string
  title: string
  shortDescription?: string | null
  slug: string
  goals?: string | null
  focus?: string | null
  audience?: string | null
  onDemand?: boolean | null
  heroImageUrl?: string | null
  trailerUrl?: string | null
  outlineComplete?: boolean | null
  sourceId?: string | null
  staticId: string
  testimonials?: Array<{
    __typename: 'CclReview'
    id: string
    title?: string | null
    subTitle?: string | null
    quote?: string | null
    company?: {
      __typename: 'CclCompany'
      id: string
      name: string
      logoUrl?: string | null
    } | null
  }> | null
  blocks?: Array<{
    __typename: 'CclCourseBlock'
    id: string
    title: string
    description?: string | null
    previewable?: boolean | null
    displayOrder: number
    kind?: string | null
    contentBlocks?: Array<{
      __typename: 'ContentBlock'
      id: string
      header?: string | null
      slug: string
      order?: number | null
      description?: any | null
      contentBlockCollapsible?: Array<{
        __typename: 'ContentBlockCollapsible'
        id: string
        type?: string | null
        description?: any | null
        label?: string | null
      }> | null
    }> | null
    guides?: Array<{
      __typename: 'CclGuide'
      id: string
      title: string
      overview?: string | null
      slug: string
      freePreview?: boolean | null
      displayOrder: number
      estimatedMinutes?: number | null
      sourceId?: string | null
    }> | null
    artifacts?: Array<{
      __typename: 'CclArtifact'
      id: string
      title: string
      slug: string
      sourceId?: string | null
    }> | null
    events?: Array<{
      __typename: 'CclSessionEvent'
      id: string
      title: string
      startsAt: string
      endsAt: string
      sourceId?: string | null
    }> | null
    eventPreviews?: Array<{
      __typename: 'CclVideo'
      id: string
      title: string
      sourceId?: string | null
      location: string
    }> | null
  }> | null
  creators?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
    bio?: string | null
    position?: string | null
    companyLogoUrl?: string | null
    companyName?: string | null
    slug: string
    isCollectiveMember?: boolean | null
  }> | null
}

export type TestimonialPartsFragment = {
  __typename: 'CclReview'
  id: string
  title?: string | null
  subTitle?: string | null
  quote?: string | null
  company?: {
    __typename: 'CclCompany'
    id: string
    name: string
    logoUrl?: string | null
  } | null
}

export type CreatorPartsFragment = {
  __typename: 'CclExpert'
  id: string
  name: string
  avatarUrl?: string | null
  bio?: string | null
  position?: string | null
  companyLogoUrl?: string | null
  companyName?: string | null
  slug: string
  isCollectiveMember?: boolean | null
}

export type CourseDetailCourseBlockPartsFragment = {
  __typename: 'CclCourseBlock'
  id: string
  title: string
  description?: string | null
  previewable?: boolean | null
  displayOrder: number
  kind?: string | null
  contentBlocks?: Array<{
    __typename: 'ContentBlock'
    id: string
    header?: string | null
    slug: string
    order?: number | null
    description?: any | null
    contentBlockCollapsible?: Array<{
      __typename: 'ContentBlockCollapsible'
      id: string
      type?: string | null
      description?: any | null
      label?: string | null
    }> | null
  }> | null
  guides?: Array<{
    __typename: 'CclGuide'
    id: string
    title: string
    overview?: string | null
    slug: string
    freePreview?: boolean | null
    displayOrder: number
    estimatedMinutes?: number | null
    sourceId?: string | null
  }> | null
  artifacts?: Array<{
    __typename: 'CclArtifact'
    id: string
    title: string
    slug: string
    sourceId?: string | null
  }> | null
  events?: Array<{
    __typename: 'CclSessionEvent'
    id: string
    title: string
    startsAt: string
    endsAt: string
    sourceId?: string | null
  }> | null
  eventPreviews?: Array<{
    __typename: 'CclVideo'
    id: string
    title: string
    sourceId?: string | null
    location: string
  }> | null
}

export type CourseDetailCourseBlockGuidePartsFragment = {
  __typename: 'CclGuide'
  id: string
  title: string
  overview?: string | null
  slug: string
  freePreview?: boolean | null
  displayOrder: number
  estimatedMinutes?: number | null
  sourceId?: string | null
}

export type CourseDetailCourseBlockArtifactPartsFragment = {
  __typename: 'CclArtifact'
  id: string
  title: string
  slug: string
  sourceId?: string | null
}

export type CourseDetailCourseBlockEventPartsFragment = {
  __typename: 'CclSessionEvent'
  id: string
  title: string
  startsAt: string
  endsAt: string
  sourceId?: string | null
}

export type CourseDetailCourseVideoPartsFragment = {
  __typename: 'CclVideo'
  id: string
  title: string
  sourceId?: string | null
  location: string
}

export type RelatedCourseCreatorPartsFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  shortBio?: string | null
  position?: string | null
  slug?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
  } | null
}

export type RelatedCoursePartsFragment = {
  __typename: 'SanityCourse'
  id: string
  title: string
  slug: string
  creators?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    shortBio?: string | null
    position?: string | null
    slug?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
    } | null
  }> | null
}

export type CourseSessionIdentifierPartsFragment = {
  __typename: 'CourseSession'
  id: string
  hasStarted: boolean
  hasEnded: boolean
}

export type CourseIdentifierPartsFragment = {
  __typename: 'Course'
  id: string
  title: string
  sanityId?: string | null
}

export type CoursePreviewIdentifierPartsFragment = {
  __typename: 'CoursePreview'
  id: string
  title: string
}

export type CclCourseIdentifierPartsFragment = {
  __typename: 'CclCourse'
  id: string
  staticId: string
  slug: string
  legacyCourseId?: string | null
  title: string
}

export type CourseLinkUserCoursePartsFragment = {
  __typename: 'UserCourse'
  id: string
  courseSession: {
    __typename: 'CourseSession'
    id: string
    endsAt: string
    startsAt: string
  }
  course: {
    __typename: 'Course'
    id: string
    title: string
    slug: string
    completionPercentage?: number | null
  }
}

export type CourseLinkCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  is: { __typename: 'UserIs'; paidMember: boolean; member: boolean }
  userCourses: {
    __typename: 'UserCourses'
    current?: {
      __typename: 'UserCourse'
      id: string
      courseSession: {
        __typename: 'CourseSession'
        id: string
        endsAt: string
        startsAt: string
      }
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
    } | null
    previous?: Array<{
      __typename: 'UserCourse'
      id: string
      courseSession: {
        __typename: 'CourseSession'
        id: string
        endsAt: string
        startsAt: string
      }
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
    }> | null
    upcoming?: Array<{
      __typename: 'UserCourse'
      id: string
      courseSession: {
        __typename: 'CourseSession'
        id: string
        endsAt: string
        startsAt: string
      }
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
    }> | null
  }
}

export type TrackCourseDashboardContentClickedCoursePartsFragment = {
  __typename: 'Course'
  id: string
  title: string
  sanityId?: string | null
}

export type TrackCourseDashboardContentClickedCourseSessionPartsFragment = {
  __typename: 'CourseSession'
  id: string
  hasStarted: boolean
  hasEnded: boolean
}

export type ProgressConsumerUserPartsFragment = {
  __typename: 'User'
  id: string
  is: { __typename: 'UserIs'; freeUser: boolean }
}

export type ProgressConsumerUnitPartsFragment = {
  __typename: 'Unit'
  id: string
  previewable?: boolean | null
}

export type ProgressConsumerCourseSessionPartsFragment = {
  __typename: 'CourseSession'
  id: string
  hasStarted: boolean
  hasEnded: boolean
}

export type ProgressConsumerCoursePartsFragment = {
  __typename: 'Course'
  id: string
  title: string
  slug: string
}

export type CohortEventSameInfoPartsFragment = {
  __typename: 'Event'
  id: string
  startsAtUtc: string
  endsAtUtc: string
  attendingStatus?: string | null
  past: boolean
  summaryWistiaCode?: string | null
  slug: string
  kind: string
  preread?: string | null
  uuid: string
  caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
}

export type UserProfileImagePartsFragment = {
  __typename: 'UserProfile'
  fullName?: string | null
  avatarUrl: string
}

export type CohortEventSpeakersPartsFragment = {
  __typename: 'EventSpeaker'
  id: string
  kind?: string | null
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      companyName?: string | null
      fullName?: string | null
      avatarUrl: string
    }
  }
}

export type CohortEventCardPartsFragment = {
  __typename: 'Event'
  id: string
  name: string
  willBeRecorded: boolean
  preread?: string | null
  startsAtUtc: string
  endsAtUtc: string
  attendingStatus?: string | null
  past: boolean
  summaryWistiaCode?: string | null
  slug: string
  kind: string
  uuid: string
  speakers?: Array<{
    __typename: 'EventSpeaker'
    id: string
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        companyName?: string | null
        fullName?: string | null
        avatarUrl: string
      }
    }
  }> | null
  caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
}

export type CohortRsvpTimeDataPartsFragment = {
  __typename: 'BatchRsvpTimeData'
  ids: Array<string>
  startsAtUtc?: string | null
  endsAtUtc?: string | null
  timeGrouping?: string | null
}

export type CohortRsvpKickoffEventPartsFragment = { __typename: 'Event'; id: string }

export type CohortFrontDoorNextPartsFragment = {
  __typename: 'UserCohort'
  id: string
  hasClickedMeetCohortParticipantsCta: Array<string>
  hasClickedShareSnapshotCta: boolean
  hasSignedStatementOfCommitment: boolean
  showEventRsvpModal: boolean
  hasPostedWelcome: boolean
  timeGroupingSelected?: string | null
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
  cohort: {
    __typename: 'Cohort'
    id: string
    postCohort: boolean
    hasEnded: boolean
    startsAt?: string | null
    slackChannelId?: string | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      code?: string | null
      name: string
    }
    batchRsvpTimeData: Array<{
      __typename: 'BatchRsvpTimeData'
      ids: Array<string>
      startsAtUtc?: string | null
      endsAtUtc?: string | null
      timeGrouping?: string | null
    }>
    season: {
      __typename: 'Season'
      id: string
      name: string
      year: string
      npsSurveyLink?: string | null
    }
    thisWeek?: {
      __typename: 'ScheduledWeek'
      id: string
      startsAt?: string | null
      endsAt?: string | null
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        willBeRecorded: boolean
        preread?: string | null
        startsAtUtc: string
        endsAtUtc: string
        attendingStatus?: string | null
        past: boolean
        summaryWistiaCode?: string | null
        slug: string
        kind: string
        uuid: string
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              companyName?: string | null
              fullName?: string | null
              avatarUrl: string
            }
          }
        }> | null
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
      }>
      nextModule?: {
        __typename: 'CmsModule'
        estimatedTime: number
        href: string
        id: string
        name: string
        numBookmarks?: number | null
        numLessons: number
        programCode: string
        programIconImageUrl?: string | null
        progressPercent: number
        shortDescription?: string | null
        publishAt?: string | null
        published?: boolean | null
      } | null
    } | null
    nextWeek?: {
      __typename: 'ScheduledWeek'
      id: string
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        willBeRecorded: boolean
        preread?: string | null
        startsAtUtc: string
        endsAtUtc: string
        attendingStatus?: string | null
        past: boolean
        summaryWistiaCode?: string | null
        slug: string
        kind: string
        uuid: string
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              companyName?: string | null
              fullName?: string | null
              avatarUrl: string
            }
          }
        }> | null
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
      }>
    } | null
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      startsAt?: string | null
    }>
  }
}

export type CohortFrontDoorPartsFragment = {
  __typename: 'UserCohort'
  id: string
  canBeUpdated: boolean
  hasClickedMeetCohortParticipantsCta: Array<string>
  hasClickedShareSnapshotCta: boolean
  hasSignedStatementOfCommitment: boolean
  showEventRsvpModal: boolean
  hasPostedWelcome: boolean
  timeGroupingSelected?: string | null
  cohort: {
    __typename: 'Cohort'
    id: string
    postKickoff: boolean
    postCoreWeeksEnrollmentIsOpen: boolean
    slug: string
    startsAt?: string | null
    weekCount: number
    postCohort: boolean
    hasEnded: boolean
    slackChannelId?: string | null
    kickoffEvents: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      bonus?: boolean | null
      postCohort: boolean
      startsAt?: string | null
      endsAt?: string | null
    }>
    events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
    season: {
      __typename: 'Season'
      id: string
      name: string
      year: string
      npsSurveyLink?: string | null
    }
    hosts?: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
      title?: string | null
    }> | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      code?: string | null
      name: string
      heroImageUrl?: string | null
      programImage?: string | null
      synopsis?: string | null
      lessonCount: number
    }
    nextWeek?: {
      __typename: 'ScheduledWeek'
      id: string
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        willBeRecorded: boolean
        preread?: string | null
        startsAtUtc: string
        endsAtUtc: string
        attendingStatus?: string | null
        past: boolean
        summaryWistiaCode?: string | null
        slug: string
        kind: string
        uuid: string
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              companyName?: string | null
              fullName?: string | null
              avatarUrl: string
            }
          }
        }> | null
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
      }>
    } | null
    thisWeek?: {
      __typename: 'ScheduledWeek'
      id: string
      startsAt?: string | null
      endsAt?: string | null
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        willBeRecorded: boolean
        preread?: string | null
        startsAtUtc: string
        endsAtUtc: string
        attendingStatus?: string | null
        past: boolean
        summaryWistiaCode?: string | null
        slug: string
        kind: string
        uuid: string
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              companyName?: string | null
              fullName?: string | null
              avatarUrl: string
            }
          }
        }> | null
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
      }>
      nextModule?: {
        __typename: 'CmsModule'
        estimatedTime: number
        href: string
        id: string
        name: string
        numBookmarks?: number | null
        numLessons: number
        programCode: string
        programIconImageUrl?: string | null
        progressPercent: number
        shortDescription?: string | null
        publishAt?: string | null
        published?: boolean | null
        clips?: Array<{
          __typename: 'Clip'
          id: string
          title: string
          duration?: number | null
          slug: string
          thumbnailUrl: string
          summary?: string | null
          summaryWistiaCode: string
          hasBeenViewedByCurrentUser: boolean
          users: Array<{
            __typename: 'User'
            id: string
            avatarUrl: string
            fullName?: string | null
            role?: string | null
            companyName?: string | null
            profile: { __typename: 'UserProfile'; profileUrl?: string | null }
          }>
        }> | null
      } | null
    } | null
    batchRsvpTimeData: Array<{
      __typename: 'BatchRsvpTimeData'
      ids: Array<string>
      startsAtUtc?: string | null
      endsAtUtc?: string | null
      timeGrouping?: string | null
    }>
  }
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
}

export type CurrentLiveProgramsPartsFragment = {
  __typename: 'UserCohort'
  id: string
  cohort: {
    __typename: 'Cohort'
    coreWeeksHaveEnded: boolean
    postCoreWeeksEnrollmentIsOpen: boolean
    isOngoing: boolean
  }
}

export type HostImagePartsFragment = {
  __typename: 'Host'
  thumbnailUrl: string
  name?: string | null
}

export type ActivateSubscriptionMutationVariables = Exact<{
  input: ActivateSubscriptionInput
}>

export type ActivateSubscriptionMutation = {
  __typename: 'Mutation'
  activateSubscription?: {
    __typename: 'ActivateSubscriptionPayload'
    errors?: Array<string> | null
    redirectPath?: string | null
  } | null
}

export type AddAnnotationReactionMutationVariables = Exact<{
  input: AddAnnotationReactionInput
}>

export type AddAnnotationReactionMutation = {
  __typename: 'Mutation'
  addAnnotationReaction?: {
    __typename: 'AddAnnotationReactionPayload'
    errors?: Array<string> | null
  } | null
}

export type AddArtifactCommentMutationVariables = Exact<{
  input: AddArtifactCommentInput
}>

export type AddArtifactCommentMutation = {
  __typename: 'Mutation'
  addArtifactComment?: {
    __typename: 'AddArtifactCommentPayload'
    errors?: Array<string> | null
    artifactComment?: {
      __typename: 'ArtifactComment'
      id: string
      createdAt: string
    } | null
  } | null
}

export type AddArtifactCommentReactionMutationVariables = Exact<{
  input: AddArtifactCommentReactionInput
}>

export type AddArtifactCommentReactionMutation = {
  __typename: 'Mutation'
  addArtifactCommentReaction?: {
    __typename: 'AddArtifactCommentReactionPayload'
    errors?: Array<string> | null
  } | null
}

export type AddReactionMutationVariables = Exact<{
  input: AddReactionInput
}>

export type AddReactionMutation = {
  __typename: 'Mutation'
  addReaction?: {
    __typename: 'AddReactionPayload'
    reaction?: {
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    } | null
  } | null
}

export type ApproveSubscriptionJoinRequestMutationVariables = Exact<{
  input: ApproveSubscriptionJoinRequestInput
}>

export type ApproveSubscriptionJoinRequestMutation = {
  __typename: 'Mutation'
  approveSubscriptionJoinRequest?: {
    __typename: 'ApproveSubscriptionJoinRequestPayload'
    error?: string | null
    subscriptionJoinRequest?: {
      __typename: 'SubscriptionJoinRequest'
      id: string
      status: string
      subscription: {
        __typename: 'Subscription'
        id: string
        seats: Array<{
          __typename: 'Seat'
          id: string
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            status: string
            email: string
          } | null
        }>
      }
      user: { __typename: 'User'; id: string; fullName?: string | null }
    } | null
  } | null
}

export type AssignCohortCreditMutationVariables = Exact<{
  input: AssignCohortCreditInput
}>

export type AssignCohortCreditMutation = {
  __typename: 'Mutation'
  assignCohortCredit?: {
    __typename: 'AssignCohortCreditPayload'
    errors?: Array<string> | null
    seat?: { __typename: 'Seat'; id: string } | null
  } | null
}

export type AssignSeatMutationVariables = Exact<{
  input: AssignSeatInput
}>

export type AssignSeatMutation = {
  __typename: 'Mutation'
  assignSeat?: {
    __typename: 'AssignSeatPayload'
    errors?: Array<string> | null
    seat?: {
      __typename: 'Seat'
      id: string
      subscriptionMember?: {
        __typename: 'SubscriptionMember'
        id: string
        userId: string
        status: string
        email: string
        role?: string | null
        createdAt: string
        user: {
          __typename: 'User'
          email: string
          workEmail?: string | null
          fullName?: string | null
          profile: { __typename: 'UserProfile'; avatarUrl: string }
          is: { __typename: 'UserIs'; enrolledInLiveProgram: boolean }
        }
      } | null
    } | null
  } | null
}

export type BatchRsvpMutationVariables = Exact<{
  input: BatchInput
}>

export type BatchRsvpMutation = {
  __typename: 'Mutation'
  batchRsvp?: { __typename: 'BatchPayload' } | null
}

export type BookmarkFolderByIdQueryVariables = Exact<{
  folderId: Scalars['ID']
}>

export type BookmarkFolderByIdQuery = {
  __typename: 'Query'
  bookmarkFolder?: {
    __typename: 'BookmarkFolder'
    id: string
    name: string
    description?: string | null
    forkedFromId?: string | null
    goalComplete?: boolean | null
    isShared: boolean
    parentId?: string | null
    reforgeCollection: boolean
    teamProgress?: number | null
    usersSharedWith: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }>
    individualUsersSharedWith: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }>
    teamMembersWhoStartedGoal?: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      avatarUrl: string
    }> | null
    teamMembersWhoCompletedGoal?: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      avatarUrl: string
    }> | null
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
    sharedFolders: Array<{
      __typename: 'SharedFolder'
      id: string
      status: SharedFolderStatus
      sharedPartyId: string
      sharedPartyType: string
      userId: string
      bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
    }>
  } | null
}

export type CancelSubscriptionMutationVariables = Exact<{
  input: SubscriptionCancelInput
}>

export type CancelSubscriptionMutation = {
  __typename: 'Mutation'
  cancelSubscription?: {
    __typename: 'SubscriptionCancelPayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type CancelSubscriptionMotivationMutationVariables = Exact<{
  input: SubscriptionCancelMotivationInput
}>

export type CancelSubscriptionMotivationMutation = {
  __typename: 'Mutation'
  cancelSubscriptionMotivation?: {
    __typename: 'SubscriptionCancelMotivationPayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type ChoosePlanMutationVariables = Exact<{
  input: ChoosePlanInput
}>

export type ChoosePlanMutation = {
  __typename: 'Mutation'
  choosePlan?: {
    __typename: 'ChoosePlanPayload'
    errors?: Array<string> | null
    upcomingInvoice?: { __typename: 'StripeUpcomingInvoice'; subtotal: number } | null
  } | null
}

export type UpdateCmsSectionTagsMutationVariables = Exact<{
  input: UpdateCmsSectionTagsInput
}>

export type UpdateCmsSectionTagsMutation = {
  __typename: 'Mutation'
  updateCmsSectionTags?: {
    __typename: 'UpdateCmsSectionTagsPayload'
    id?: string | null
  } | null
}

export type CompleteProductTourMutationVariables = Exact<{
  input: CompleteProductTourInput
}>

export type CompleteProductTourMutation = {
  __typename: 'Mutation'
  completeProductTour?: {
    __typename: 'CompleteProductTourPayload'
    completedProductTours: {
      __typename: 'ProductTour'
      bookmarkCreate?: boolean | null
      collectionSaveBookmark?: boolean | null
      collectionShare?: boolean | null
      collectionViewContent?: boolean | null
      collectionsTab?: boolean | null
      teamCommentView?: boolean | null
      searchTrainerViews?: number | null
      savedItemsTab?: boolean | null
      newSearchClicked?: boolean | null
      usedReforgeAiGlobalEntryPoint?: boolean | null
    }
  } | null
}

export type ConfirmUserMergeMutationVariables = Exact<{
  input: ConfirmUserMergeInput
}>

export type ConfirmUserMergeMutation = {
  __typename: 'Mutation'
  confirmUserMerge?: {
    __typename: 'ConfirmUserMergePayload'
    success: boolean
    userMerge?: { __typename: 'UserMerge'; id: string; mergedPrimaryEmail: string } | null
  } | null
}

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput
}>

export type CreateAccountMutation = {
  __typename: 'Mutation'
  createAccount?: { __typename: 'CreateAccountPayload'; success: boolean } | null
}

export type CreateBookmarkMutationVariables = Exact<{
  input: CreateBookmarkInput
}>

export type CreateBookmarkMutation = {
  __typename: 'Mutation'
  createBookmark?: {
    __typename: 'CreateBookmarkPayload'
    errors?: Array<string> | null
    bookmark?: {
      __typename: 'Bookmark'
      lastViewedContentAtInteger?: number | null
      id: string
      anchor?: string | null
      noteBody?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      type: BookmarkType
      videoCode?: string | null
      basedOn?: string | null
      title?: string | null
      externalUrl?: string | null
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      updated?: string | null
      created?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          name?: string | null
          id: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          thumbnailUrl: string
          avatarUrl: string
        }>
      } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        path?: string | null
        contentType?: CmsSectionContentType | null
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    } | null
    collectionActivity?: {
      __typename: 'CollectionActivity'
      id: string
      actionType: string
      actionText: string
      bookmarkFolderId?: string | null
      hasRestoreAction: boolean
      createdAtInWords: string
      filedBookmark?: {
        __typename: 'FiledBookmark'
        id: string
        bookmarkId: string
      } | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    } | null
  } | null
}

export type CreateBookmarkFolderMutationVariables = Exact<{
  input: CreateBookmarkFolderInput
}>

export type CreateBookmarkFolderMutation = {
  __typename: 'Mutation'
  createBookmarkFolder?: {
    __typename: 'CreateBookmarkFolderPayload'
    errors?: Array<string> | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    } | null
  } | null
}

export type CreateCohortPostMutationVariables = Exact<{
  input: CreateCohortPostInput
}>

export type CreateCohortPostMutation = {
  __typename: 'Mutation'
  createCohortPost?: { __typename: 'CreateCohortPostPayload'; id?: string | null } | null
}

export type CreateCohortReplyMutationVariables = Exact<{
  input: CreateCohortReplyInput
}>

export type CreateCohortReplyMutation = {
  __typename: 'Mutation'
  createCohortReply?: {
    __typename: 'CreateCohortReplyPayload'
    id?: string | null
  } | null
}

export type CreateCourseEnrollmentMutationVariables = Exact<{
  input: CreateCourseEnrollmentInput
}>

export type CreateCourseEnrollmentMutation = {
  __typename: 'Mutation'
  createCourseEnrollment?: {
    __typename: 'CreateCourseEnrollmentPayload'
    id?: number | null
    errors?: Array<string> | null
    courseSessionId?: number | null
    redirectUrl?: string | null
  } | null
}

export type CreateCourseSessionMutationVariables = Exact<{
  input: CreateCourseSessionInput
}>

export type CreateCourseSessionMutation = {
  __typename: 'Mutation'
  createCourseSession?: {
    __typename: 'CreateCourseSessionPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type CreateCourseSessionSlackChannelMutationVariables = Exact<{
  input: CreateCourseSessionSlackChannelInput
}>

export type CreateCourseSessionSlackChannelMutation = {
  __typename: 'Mutation'
  createCourseSessionSlackChannel?: {
    __typename: 'CreateCourseSessionSlackChannelPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type CreateDraftSubscriptionJoinRequestMutationVariables = Exact<{
  input: CreateDraftSubscriptionJoinRequestInput
}>

export type CreateDraftSubscriptionJoinRequestMutation = {
  __typename: 'Mutation'
  createDraftSubscriptionJoinRequest?: {
    __typename: 'CreateDraftSubscriptionJoinRequestPayload'
    errors?: Array<string> | null
    subscriptionJoinRequest?: {
      __typename: 'SubscriptionJoinRequest'
      id: string
      status: string
      subscription: { __typename: 'Subscription'; id: string }
      user: { __typename: 'User'; id: string }
    } | null
  } | null
}

export type CreateFiledBookmarkMutationVariables = Exact<{
  input: CreateFiledBookmarkInput
}>

export type CreateFiledBookmarkMutation = {
  __typename: 'Mutation'
  createFiledBookmark?: {
    __typename: 'CreateFiledBookmarkPayload'
    errors?: Array<string> | null
    filedBookmark?: {
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
      bookmark: {
        __typename: 'Bookmark'
        id: string
        eventId?: string | null
        lessonPosition?: number | null
        sanityId?: string | null
        type: BookmarkType
        basedOn?: string | null
        noteBody?: string | null
        updatedAt: string
        createdAtInteger: number
        isWithinContent: boolean
        numPeopleWhoBookmarked?: number | null
        anchor?: string | null
        referenceImageUrl?: string | null
        seconds?: number | null
        updated?: string | null
        created?: string | null
        title?: string | null
        externalUrl?: string | null
        metadata?: any | null
        startOffset?: number | null
        endOffset?: number | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          name: string
          position?: number | null
        } | null
        cmsSection?: {
          __typename: 'CmsSection'
          id: string
          name: string
          position?: number | null
          progressPercent?: number | null
          childrenCount: number
          completedChildrenCount?: number | null
          path?: string | null
        } | null
        content?: {
          __typename: 'CmsSection'
          id: string
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          estimatedTime: number
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          heroImageUrl?: string | null
          href: string
          name: string
          numPhases?: number | null
          projectIcon?: string | null
          shortDescription?: string | null
          numBookmarks?: number | null
          showNewBadge: boolean
          slug: string
          accessLevel?: string | null
          previewable: boolean
          lastViewedAt?: string | null
          contentSubtype?: string | null
          lessonNum?: number | null
          topic?: { __typename: 'PostTopic'; id: string; title: string } | null
          cmsModule?: {
            __typename: 'CmsModule'
            id: string
            slug?: string | null
            name: string
          } | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            notLaunched?: boolean | null
            enrolled: boolean
            slug: string
            name: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
        } | null
        cmsProgram?: {
          __typename: 'CmsProgram'
          id: string
          name: string
          slug: string
          progressPercent: number
          iconImageUrl?: string | null
          notLaunched?: boolean | null
          deprecatedAt?: string | null
          description?: string | null
          synopsis?: string | null
          lessonCount: number
          purchased: boolean
          upcoming: boolean
          enrolled: boolean
          canEnroll: boolean
          showNewBadge: boolean
          topic?: string | null
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
            companyName?: string | null
            description?: string | null
            title?: string | null
            avatarUrl: string
          }>
        } | null
        event?: {
          __typename: 'Event'
          id: string
          name: string
          slug: string
          kind: string
          past: boolean
          willBeRecorded: boolean
          startsAtUtc: string
          endsAtUtc: string
          uuid: string
          attendees: {
            __typename: 'EventAttendeeConnection'
            edges: Array<{
              __typename: 'EventAttendeeEdge'
              node: {
                __typename: 'EventAttendee'
                id: string
                hasBadge: boolean
                kind?: string | null
                status: string
                user: {
                  __typename: 'User'
                  id: string
                  profile: {
                    __typename: 'UserProfile'
                    fullName?: string | null
                    companyName?: string | null
                    avatarUrl: string
                    role?: string | null
                    twitter?: string | null
                    linkedin?: string | null
                    hasBadge?: boolean | null
                    kind?: string | null
                    location?: string | null
                    onboardingStep: number
                    profileUrl?: string | null
                    status: UserStatus
                  }
                }
              }
            }>
          }
          cohorts: Array<{
            __typename: 'Cohort'
            id: string
            slug: string
            season: { __typename: 'Season'; showCohortViewerAt?: string | null }
          }>
          hosts: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
          featuredGuests: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
        } | null
        artifact?: {
          __typename: 'Artifact'
          id: string
          title?: string | null
          slug?: string | null
          description?: any | null
          companyLogoUrl?: string | null
          companyName?: string | null
          thumbnailUrl?: string | null
          viewCount?: number | null
          commentCount?: number | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            slug?: string | null
            position?: string | null
            avatarUrl?: string | null
            company?: {
              __typename: 'SanityCompany'
              id?: string | null
              name?: string | null
            } | null
          }> | null
        } | null
        course?: {
          __typename: 'MarketingCourse'
          id: string
          slug?: string | null
          cmsProgramSlug?: string | null
          startDate?: string | null
          subtitle?: string | null
          title?: string | null
          legacyProgramId?: string | null
          bookmarkId?: string | null
          courseType?: string | null
          course?: {
            __typename: 'SanityCourse'
            id: string
            title: string
            shortDescription?: string | null
            slug: string
            bookmarkId?: string | null
            isAvailableOnDemand?: boolean | null
            creators?: Array<{
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            }> | null
          } | null
          creators?: Array<{
            __typename: 'Creator'
            id: string
            expert?: {
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            } | null
          }> | null
        } | null
        guide?: {
          __typename: 'Unit'
          id: string
          title?: string | null
          whatYouWillDo?: any | null
          estimatedTimeInMinutes?: number | null
          stepsCount?: number | null
          slug?: string | null
          contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
          heroImage?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            slug?: string | null
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        filedBookmarks: Array<{
          __typename: 'FiledBookmark'
          id: string
          bookmarkFolderId: string
        }>
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
      }
    } | null
  } | null
}

export type CreateProgramInvitationMutationVariables = Exact<{
  input: CreateProgramInvitationInput
}>

export type CreateProgramInvitationMutation = {
  __typename: 'Mutation'
  createProgramInvitation?: {
    __typename: 'CreateProgramInvitationPayload'
    id?: number | null
    errors?: Array<string> | null
  } | null
}

export type CreateRecommendationsMutationVariables = Exact<{
  input: CreateRecommendationsInput
}>

export type CreateRecommendationsMutation = {
  __typename: 'Mutation'
  createRecommendations?: {
    __typename: 'CreateRecommendationsPayload'
    errors?: Array<string> | null
    success?: boolean | null
  } | null
}

export type CreateReplyMutationVariables = Exact<{
  input: CreateReplyInput
}>

export type CreateReplyMutation = {
  __typename: 'Mutation'
  createReply?: {
    __typename: 'CreateReplyPayload'
    id: string
    parentId: string
    userAvatar: string
    userName?: string | null
    userRoleWithCompany?: string | null
    body: string
    bodyText: string
    userId: string
  } | null
}

export type CreateSubscriptionJoinRequestMutationVariables = Exact<{
  input: CreateSubscriptionJoinRequestInput
}>

export type CreateSubscriptionJoinRequestMutation = {
  __typename: 'Mutation'
  createSubscriptionJoinRequest?: {
    __typename: 'CreateSubscriptionJoinRequestPayload'
    errors?: Array<string> | null
    subscriptionJoinRequest?: {
      __typename: 'SubscriptionJoinRequest'
      id: string
      status: string
      subscription: { __typename: 'Subscription'; id: string }
      user: { __typename: 'User'; id: string }
    } | null
  } | null
}

export type CreateTeamPostMutationVariables = Exact<{
  input: CreateTeamPostInput
}>

export type CreateTeamPostMutation = {
  __typename: 'Mutation'
  createTeamPost?: {
    __typename: 'CreateTeamPostPayload'
    errors?: Array<string> | null
    id?: string | null
  } | null
}

export type CreateUserClipActivityMutationVariables = Exact<{
  input: CreateUserClipActivityInput
}>

export type CreateUserClipActivityMutation = {
  __typename: 'Mutation'
  createUserClipActivity?: {
    __typename: 'CreateUserClipActivityPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type CreateUserCmsActivityMutationVariables = Exact<{
  input: CreateUserCmsActivityInput
}>

export type CreateUserCmsActivityMutation = {
  __typename: 'Mutation'
  createUserCmsActivity?: {
    __typename: 'CreateUserCmsActivityPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type CreateUserEventActivityMutationVariables = Exact<{
  input: CreateUserEventActivityInput
}>

export type CreateUserEventActivityMutation = {
  __typename: 'Mutation'
  createUserEventActivity?: {
    __typename: 'CreateUserEventActivityPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type DeclineSubscriptionJoinRequestMutationVariables = Exact<{
  input: DeclineSubscriptionJoinRequestInput
}>

export type DeclineSubscriptionJoinRequestMutation = {
  __typename: 'Mutation'
  declineSubscriptionJoinRequest?: {
    __typename: 'DeclineSubscriptionJoinRequestPayload'
    success: boolean
    error?: string | null
    subscriptionJoinRequest: {
      __typename: 'SubscriptionJoinRequest'
      id: string
      status: string
      subscription: { __typename: 'Subscription'; id: string }
      user: { __typename: 'User'; id: string }
    }
  } | null
}

export type DeleteBookmarkMutationVariables = Exact<{
  input: DeleteBookmarkInput
}>

export type DeleteBookmarkMutation = {
  __typename: 'Mutation'
  deleteBookmark?: {
    __typename: 'DeleteBookmarkPayload'
    id?: string | null
    status?: string | null
    errors?: Array<string> | null
  } | null
}

export type DeleteBookmarkFolderMutationVariables = Exact<{
  input: DeleteBookmarkFolderInput
}>

export type DeleteBookmarkFolderMutation = {
  __typename: 'Mutation'
  deleteBookmarkFolder?: {
    __typename: 'DeleteBookmarkFolderPayload'
    id?: string | null
    forkedFromId?: string | null
    errors?: Array<string> | null
  } | null
}

export type DeleteCohortPassMutationVariables = Exact<{
  input: DeleteCohortPassInput
}>

export type DeleteCohortPassMutation = {
  __typename: 'Mutation'
  deleteCohortPass?: {
    __typename: 'DeleteCohortPassPayload'
    id?: string | null
    status?: string | null
  } | null
}

export type DeleteCohortPostMutationVariables = Exact<{
  input: DeleteCohortPostInput
}>

export type DeleteCohortPostMutation = {
  __typename: 'Mutation'
  deleteCohortPost?: { __typename: 'DeleteCohortPostPayload'; id?: string | null } | null
}

export type DeleteCohortReplyMutationVariables = Exact<{
  input: DeleteCohortReplyInput
}>

export type DeleteCohortReplyMutation = {
  __typename: 'Mutation'
  deleteCohortReply?: {
    __typename: 'DeleteCohortReplyPayload'
    id?: string | null
  } | null
}

export type DeleteCustomerTaxIdMutationVariables = Exact<{
  input: DeleteCustomerTaxIdInput
}>

export type DeleteCustomerTaxIdMutation = {
  __typename: 'Mutation'
  deleteCustomerTaxId?: {
    __typename: 'DeleteCustomerTaxIdPayload'
    success: boolean
  } | null
}

export type DeleteFiledBookmarkMutationVariables = Exact<{
  input: DeleteFiledBookmarkInput
}>

export type DeleteFiledBookmarkMutation = {
  __typename: 'Mutation'
  deleteFiledBookmark?: {
    __typename: 'DeleteFiledBookmarkPayload'
    destroyedBookmark?: boolean | null
    errors?: Array<string> | null
    filedBookmark?: {
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
      bookmark: { __typename: 'Bookmark'; id: string }
    } | null
    collectionActivity?: {
      __typename: 'CollectionActivity'
      id: string
      actionType: string
      actionText: string
      bookmarkFolderId?: string | null
      hasRestoreAction: boolean
      createdAtInWords: string
      filedBookmark?: {
        __typename: 'FiledBookmark'
        id: string
        bookmarkId: string
      } | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    } | null
  } | null
}

export type DeletePaymentSourceMutationVariables = Exact<{
  input: DeletePaymentSourceInput
}>

export type DeletePaymentSourceMutation = {
  __typename: 'Mutation'
  deletePaymentSource?: {
    __typename: 'DeletePaymentSourcePayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type DeleteTeamPostMutationVariables = Exact<{
  input: DeleteTeamPostInput
}>

export type DeleteTeamPostMutation = {
  __typename: 'Mutation'
  deleteTeamPost?: { __typename: 'DeleteTeamPostPayload'; id?: string | null } | null
}

export type AddEmailToUserMutationVariables = Exact<{
  input: AddEmailToUserInput
}>

export type AddEmailToUserMutation = {
  __typename: 'Mutation'
  addEmailToUser?: { __typename: 'AddEmailToUserPayload'; id?: string | null } | null
}

export type DeleteEmailMutationVariables = Exact<{
  input: DeleteEmailInput
}>

export type DeleteEmailMutation = {
  __typename: 'Mutation'
  deleteEmail?: { __typename: 'DeleteEmailPayload'; id?: string | null } | null
}

export type MakeEmailPrimaryMutationVariables = Exact<{
  input: MakeEmailPrimaryInput
}>

export type MakeEmailPrimaryMutation = {
  __typename: 'Mutation'
  makeEmailPrimary?: { __typename: 'MakeEmailPrimaryPayload'; id?: string | null } | null
}

export type SendEmailVerifyMutationVariables = Exact<{
  input: SendEmailVerifyInput
}>

export type SendEmailVerifyMutation = {
  __typename: 'Mutation'
  sendEmailVerify?: { __typename: 'SendEmailVerifyPayload'; id?: string | null } | null
}

export type EndSubscriptionTrialEarlyMutationVariables = Exact<{ [key: string]: never }>

export type EndSubscriptionTrialEarlyMutation = {
  __typename: 'Mutation'
  endSubscriptionTrialEarly?: {
    __typename: 'EndSubscriptionTrialEarlyPayload'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type FlagArtifactCommentMutationVariables = Exact<{
  input: FlagArtifactCommentInput
}>

export type FlagArtifactCommentMutation = {
  __typename: 'Mutation'
  flagArtifactComment?: {
    __typename: 'FlagArtifactCommentPayload'
    errors?: Array<string> | null
  } | null
}

export type ForkBookmarkFolderMutationVariables = Exact<{
  input: ForkBookmarkFolderInput
}>

export type ForkBookmarkFolderMutation = {
  __typename: 'Mutation'
  forkBookmarkFolder?: {
    __typename: 'ForkBookmarkFolderPayload'
    errors?: Array<string> | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    } | null
    newForkedBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
  } | null
}

export type TeamMembersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}>

export type TeamMembersQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        teamMembers: {
          __typename: 'TeamMembersSearch'
          count?: number | null
          totalCount?: number | null
          users?: Array<{
            __typename: 'User'
            id: string
            fullName?: string | null
            profile: {
              __typename: 'UserProfile'
              avatarUrl: string
              fullName?: string | null
              role?: string | null
            }
          }> | null
        }
      } | null
    } | null
  } | null
}

export type JoinCourseWaitingListMutationVariables = Exact<{
  input: JoinCourseWaitlistInput
}>

export type JoinCourseWaitingListMutation = {
  __typename: 'Mutation'
  joinCourseWaitlist?: {
    __typename: 'JoinCourseWaitlistPayload'
    errors?: Array<string> | null
  } | null
}

export type JoinPublicCourseWaitingListMutationVariables = Exact<{
  input: JoinPublicCourseWaitlistInput
}>

export type JoinPublicCourseWaitingListMutation = {
  __typename: 'Mutation'
  joinPublicCourseWaitlist?: {
    __typename: 'JoinPublicCourseWaitlistPayload'
    errors?: Array<string> | null
  } | null
}

export type MarkActivitiesAsReadMutationVariables = Exact<{
  input: MarkActivitiesAsReadInput
}>

export type MarkActivitiesAsReadMutation = {
  __typename: 'Mutation'
  markActivitiesAsRead?: {
    __typename: 'MarkActivitiesAsReadPayload'
    success: boolean
    message?: string | null
  } | null
}

export type MarkAllActivitiesAsReadMutationVariables = Exact<{
  input: MarkAllActivitiesAsReadInput
}>

export type MarkAllActivitiesAsReadMutation = {
  __typename: 'Mutation'
  markAllActivitiesAsRead?: {
    __typename: 'MarkAllActivitiesAsReadPayload'
    success: boolean
  } | null
}

export type MarkArtifactAsViewedMutationVariables = Exact<{
  input: MarkArtifactAsViewedInput
}>

export type MarkArtifactAsViewedMutation = {
  __typename: 'Mutation'
  markArtifactAsViewed?: {
    __typename: 'MarkArtifactAsViewedPayload'
    viewedArtifact?: {
      __typename: 'ViewedArtifact'
      id: string
      artifactId: string
    } | null
  } | null
}

export type TrackOnboardingMutationVariables = Exact<{
  input: OnboardingTrackingInput
}>

export type TrackOnboardingMutation = {
  __typename: 'Mutation'
  trackOnboarding?: {
    __typename: 'OnboardingTrackingPayload'
    errors?: Array<string> | null
  } | null
}

export type RegenerateCourseSnapshotMutationVariables = Exact<{
  input: RegenerateCourseSnapshotInput
}>

export type RegenerateCourseSnapshotMutation = {
  __typename: 'Mutation'
  regenerateCourseSnapshot?: {
    __typename: 'RegenerateCourseSnapshotPayload'
    userCourseId?: string | null
    errors?: Array<string> | null
  } | null
}

export type RemoveAnnotationReactionMutationVariables = Exact<{
  input: RemoveAnnotationReactionInput
}>

export type RemoveAnnotationReactionMutation = {
  __typename: 'Mutation'
  removeAnnotationReaction?: {
    __typename: 'RemoveAnnotationReactionPayload'
    errors?: Array<string> | null
  } | null
}

export type RemoveArtifactCommentMutationVariables = Exact<{
  input: RemoveArtifactCommentInput
}>

export type RemoveArtifactCommentMutation = {
  __typename: 'Mutation'
  removeArtifactComment?: {
    __typename: 'RemoveArtifactCommentPayload'
    errors?: Array<string> | null
  } | null
}

export type RemoveArtifactCommentReactionMutationVariables = Exact<{
  input: RemoveArtifactCommentReactionInput
}>

export type RemoveArtifactCommentReactionMutation = {
  __typename: 'Mutation'
  removeArtifactCommentReaction?: {
    __typename: 'RemoveArtifactCommentReactionPayload'
    errors?: Array<string> | null
  } | null
}

export type RemoveReactionMutationVariables = Exact<{
  input: RemoveReactionInput
}>

export type RemoveReactionMutation = {
  __typename: 'Mutation'
  removeReaction?: { __typename: 'RemoveReactionPayload'; id?: string | null } | null
}

export type RemoveReplyMutationVariables = Exact<{
  input: RemoveReplyInput
}>

export type RemoveReplyMutation = {
  __typename: 'Mutation'
  removeReply?: { __typename: 'RemoveReplyPayload'; id?: string | null } | null
}

export type RemoveSubscriptionFromOrgMutationVariables = Exact<{
  input: RemoveSubscriptionFromOrgInput
}>

export type RemoveSubscriptionFromOrgMutation = {
  __typename: 'Mutation'
  removeSubscriptionFromOrg?: {
    __typename: 'RemoveSubscriptionFromOrgPayload'
    errors?: Array<string> | null
    org?: {
      __typename: 'Org'
      id: string
      subscriptions: Array<{ __typename: 'Subscription'; id: string }>
    } | null
  } | null
}

export type ReorderFiledBookmarkMutationVariables = Exact<{
  input: ReorderFiledBookmarkInput
}>

export type ReorderFiledBookmarkMutation = {
  __typename: 'Mutation'
  reorderFiledBookmark?: {
    __typename: 'ReorderFiledBookmarkPayload'
    oldPosition?: number | null
    errors?: Array<string> | null
    filedBookmark?: { __typename: 'FiledBookmark'; id: string; position: number } | null
  } | null
}

export type ReportCohortPostOrReplyMutationVariables = Exact<{
  input: ReportCohortPostOrReplyInput
}>

export type ReportCohortPostOrReplyMutation = {
  __typename: 'Mutation'
  reportCohortPostOrReply?: {
    __typename: 'ReportCohortPostOrReplyPayload'
    success?: boolean | null
  } | null
}

export type RestoreCollectionActivityMutationVariables = Exact<{
  input: RestoreCollectionActivityInput
}>

export type RestoreCollectionActivityMutation = {
  __typename: 'Mutation'
  restoreCollectionActivity?: {
    __typename: 'RestoreCollectionActivityPayload'
    collectionActivity: { __typename: 'CollectionActivity'; id: string }
    filedBookmark: {
      __typename: 'FiledBookmark'
      id: string
      position: number
      bookmarkFolderId: string
      bookmark: {
        __typename: 'Bookmark'
        id: string
        eventId?: string | null
        lessonPosition?: number | null
        sanityId?: string | null
        type: BookmarkType
        basedOn?: string | null
        noteBody?: string | null
        updatedAt: string
        createdAtInteger: number
        isWithinContent: boolean
        numPeopleWhoBookmarked?: number | null
        anchor?: string | null
        referenceImageUrl?: string | null
        seconds?: number | null
        updated?: string | null
        created?: string | null
        title?: string | null
        externalUrl?: string | null
        metadata?: any | null
        startOffset?: number | null
        endOffset?: number | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          name: string
          position?: number | null
        } | null
        cmsSection?: {
          __typename: 'CmsSection'
          id: string
          name: string
          position?: number | null
          progressPercent?: number | null
          childrenCount: number
          completedChildrenCount?: number | null
          path?: string | null
        } | null
        content?: {
          __typename: 'CmsSection'
          id: string
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          estimatedTime: number
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          heroImageUrl?: string | null
          href: string
          name: string
          numPhases?: number | null
          projectIcon?: string | null
          shortDescription?: string | null
          numBookmarks?: number | null
          showNewBadge: boolean
          slug: string
          accessLevel?: string | null
          previewable: boolean
          lastViewedAt?: string | null
          contentSubtype?: string | null
          lessonNum?: number | null
          topic?: { __typename: 'PostTopic'; id: string; title: string } | null
          cmsModule?: {
            __typename: 'CmsModule'
            id: string
            slug?: string | null
            name: string
          } | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            notLaunched?: boolean | null
            enrolled: boolean
            slug: string
            name: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
        } | null
        cmsProgram?: {
          __typename: 'CmsProgram'
          id: string
          name: string
          slug: string
          progressPercent: number
          iconImageUrl?: string | null
          notLaunched?: boolean | null
          deprecatedAt?: string | null
          description?: string | null
          synopsis?: string | null
          lessonCount: number
          purchased: boolean
          upcoming: boolean
          enrolled: boolean
          canEnroll: boolean
          showNewBadge: boolean
          topic?: string | null
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
            companyName?: string | null
            description?: string | null
            title?: string | null
            avatarUrl: string
          }>
        } | null
        event?: {
          __typename: 'Event'
          id: string
          name: string
          slug: string
          kind: string
          past: boolean
          willBeRecorded: boolean
          startsAtUtc: string
          endsAtUtc: string
          uuid: string
          attendees: {
            __typename: 'EventAttendeeConnection'
            edges: Array<{
              __typename: 'EventAttendeeEdge'
              node: {
                __typename: 'EventAttendee'
                id: string
                hasBadge: boolean
                kind?: string | null
                status: string
                user: {
                  __typename: 'User'
                  id: string
                  profile: {
                    __typename: 'UserProfile'
                    fullName?: string | null
                    companyName?: string | null
                    avatarUrl: string
                    role?: string | null
                    twitter?: string | null
                    linkedin?: string | null
                    hasBadge?: boolean | null
                    kind?: string | null
                    location?: string | null
                    onboardingStep: number
                    profileUrl?: string | null
                    status: UserStatus
                  }
                }
              }
            }>
          }
          cohorts: Array<{
            __typename: 'Cohort'
            id: string
            slug: string
            season: { __typename: 'Season'; showCohortViewerAt?: string | null }
          }>
          hosts: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
          featuredGuests: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
        } | null
        artifact?: {
          __typename: 'Artifact'
          id: string
          title?: string | null
          slug?: string | null
          description?: any | null
          companyLogoUrl?: string | null
          companyName?: string | null
          thumbnailUrl?: string | null
          viewCount?: number | null
          commentCount?: number | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            slug?: string | null
            position?: string | null
            avatarUrl?: string | null
            company?: {
              __typename: 'SanityCompany'
              id?: string | null
              name?: string | null
            } | null
          }> | null
        } | null
        course?: {
          __typename: 'MarketingCourse'
          id: string
          slug?: string | null
          cmsProgramSlug?: string | null
          startDate?: string | null
          subtitle?: string | null
          title?: string | null
          legacyProgramId?: string | null
          bookmarkId?: string | null
          courseType?: string | null
          course?: {
            __typename: 'SanityCourse'
            id: string
            title: string
            shortDescription?: string | null
            slug: string
            bookmarkId?: string | null
            isAvailableOnDemand?: boolean | null
            creators?: Array<{
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            }> | null
          } | null
          creators?: Array<{
            __typename: 'Creator'
            id: string
            expert?: {
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            } | null
          }> | null
        } | null
        guide?: {
          __typename: 'Unit'
          id: string
          title?: string | null
          whatYouWillDo?: any | null
          estimatedTimeInMinutes?: number | null
          stepsCount?: number | null
          slug?: string | null
          contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
          heroImage?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            slug?: string | null
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        filedBookmarks: Array<{
          __typename: 'FiledBookmark'
          id: string
          bookmarkFolderId: string
        }>
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
      }
    }
  } | null
}

export type SaveCustomerTaxIdMutationVariables = Exact<{
  input: SaveCustomerTaxIdInput
}>

export type SaveCustomerTaxIdMutation = {
  __typename: 'Mutation'
  saveCustomerTaxId?: {
    __typename: 'SaveCustomerTaxIdPayload'
    taxId: {
      __typename: 'StripeCustomerTaxId'
      country: string
      createdAt: string
      id: string
      stripeCustomerId: string
      type: string
      value: string
      validationStatus: string
    }
  } | null
}

export type SelectFeaturedPostsMutationVariables = Exact<{
  input: SelectFeaturedPostsInput
}>

export type SelectFeaturedPostsMutation = {
  __typename: 'Mutation'
  selectFeaturedPosts?: {
    __typename: 'SelectFeaturedPostsPayload'
    errors?: Array<string> | null
    success: boolean
  } | null
}

export type SendMergeRequestEmailMutationVariables = Exact<{
  input: SendMergeRequestEmailInput
}>

export type SendMergeRequestEmailMutation = {
  __typename: 'Mutation'
  sendMergeRequestEmail?: {
    __typename: 'SendMergeRequestEmailPayload'
    success: boolean
  } | null
}

export type SetDismissedUpdateCompanyBannerAtMutationVariables = Exact<{
  input: SetDismissedUpdateCompanyBannerAtInput
}>

export type SetDismissedUpdateCompanyBannerAtMutation = {
  __typename: 'Mutation'
  setDismissedUpdateCompanyBannerAt?: {
    __typename: 'SetDismissedUpdateCompanyBannerAtPayload'
    currentUser: {
      __typename: 'User'
      id: string
      dismissedUpdateCompanyBannerAt?: string | null
    }
  } | null
}

export type SetHasSeenDemographicsModalMutationVariables = Exact<{
  input: SetHasSeenDemographicsModalInput
}>

export type SetHasSeenDemographicsModalMutation = {
  __typename: 'Mutation'
  setHasSeenDemographicsModal?: {
    __typename: 'SetHasSeenDemographicsModalPayload'
    currentUser: { __typename: 'User'; id: string }
  } | null
}

export type SetUserCohortPreferencesMutationVariables = Exact<{
  input: SetUserCohortPreferencesInput
}>

export type SetUserCohortPreferencesMutation = {
  __typename: 'Mutation'
  setUserCohortPreferences?: {
    __typename: 'SetUserCohortPreferencesPayload'
    id?: string | null
    success?: boolean | null
  } | null
}

export type ShareBookmarkFolderMutationVariables = Exact<{
  input: ShareBookmarkFolderInput
}>

export type ShareBookmarkFolderMutation = {
  __typename: 'Mutation'
  shareBookmarkFolder?: {
    __typename: 'ShareBookmarkFolderPayload'
    errors?: Array<string> | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    } | null
    sharedFolders?: Array<{
      __typename: 'SharedFolder'
      id: string
      status: SharedFolderStatus
      sharedPartyId: string
      sharedPartyType: string
      userId: string
      bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
    }> | null
  } | null
}

export type StripeSetupIntentMutationVariables = Exact<{
  input: StripeSetupIntentInput
}>

export type StripeSetupIntentMutation = {
  __typename: 'Mutation'
  stripeSetupIntent?: {
    __typename: 'StripeSetupIntentPayload'
    clientSecret?: string | null
    errors?: Array<string> | null
  } | null
}

export type SubmitOnboardingQuestionnaireMutationVariables = Exact<{
  input: SubmitOnboardingQuestionnaireInput
}>

export type SubmitOnboardingQuestionnaireMutation = {
  __typename: 'Mutation'
  submitOnboardingQuestionnaire?: {
    __typename: 'SubmitOnboardingQuestionnairePayload'
    questionnaire: {
      __typename: 'OnboardingQuestionnaire'
      id: string
      user: {
        __typename: 'User'
        id: string
        accessPolicyKind: string
        fullName?: string | null
        monthJoined?: string | null
        yearJoined?: string | null
        status: UserStatus
        slackUserId?: string | null
        slackResponseTime?: string | null
        joinSlackHref?: string | null
        networkOnboarded: boolean
        networkRecommendations: Array<string>
        clips: Array<{
          __typename: 'Clip'
          id: string
          title: string
          slug: string
          clipSubtype?: string | null
          duration?: number | null
          thumbnailUrl: string
          summary?: string | null
          summaryWistiaCode: string
          hasBeenViewedByCurrentUser: boolean
          lastViewedAt?: string | null
          company?: {
            __typename: 'Company'
            id: string
            cardLogoUrl?: string | null
            name?: string | null
          } | null
          users: Array<{
            __typename: 'User'
            id: string
            avatarUrl: string
            fullName?: string | null
            role?: string | null
            companyName?: string | null
            profile: { __typename: 'UserProfile'; profileUrl?: string | null }
          }>
        }>
        cohorts?: {
          __typename: 'UserCohorts'
          current: Array<{
            __typename: 'UserCohort'
            id: string
            season: string
            cohort: {
              __typename: 'Cohort'
              id: string
              cmsProgram: {
                __typename: 'CmsProgram'
                id: string
                name: string
                code?: string | null
                iconImageUrl?: string | null
              }
              season: { __typename: 'Season'; id: string; name: string; year: string }
            }
            snapshot?: {
              __typename: 'Snapshot'
              id: string
              token: string
              released: boolean
            } | null
          }>
          previous: Array<{
            __typename: 'UserCohort'
            id: string
            season: string
            cohort: {
              __typename: 'Cohort'
              id: string
              cmsProgram: {
                __typename: 'CmsProgram'
                id: string
                name: string
                code?: string | null
                iconImageUrl?: string | null
              }
              season: { __typename: 'Season'; id: string; name: string; year: string }
            }
            snapshot?: {
              __typename: 'Snapshot'
              id: string
              token: string
              released: boolean
            } | null
          }>
        } | null
        userCourses: {
          __typename: 'UserCourses'
          all?: Array<{
            __typename: 'UserCourse'
            id: string
            course: { __typename: 'Course'; title: string }
            courseSession: {
              __typename: 'CourseSession'
              id: string
              courseTitle: string
              startsAt: string
            }
          }> | null
        }
        cohortsHosted: Array<{
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            code?: string | null
            name: string
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; name: string; year: string }
        }>
        is: { __typename: 'UserIs'; expired: boolean; creator: boolean }
        lastForumContributions: Array<
          | {
              __typename: 'Comment'
              id: string
              body?: string | null
              createdAtInWords: string
              post?: {
                __typename: 'Post'
                id: string
                title?: string | null
                toParam: string
              } | null
            }
          | {
              __typename: 'Post'
              id: string
              createdAtInWords: string
              title?: string | null
              toParam: string
            }
          | {
              __typename: 'Reply'
              id: string
              body?: string | null
              createdAtInWords: string
              post?: {
                __typename: 'Post'
                id: string
                title?: string | null
                toParam: string
              } | null
            }
        >
        profile: {
          __typename: 'UserProfile'
          aboutMeText?: string | null
          avatarUrl: string
          allowDirectMessages?: boolean | null
          areasOfExpertiseTags?: string | null
          askMeAboutText?: string | null
          businessModelTags?: string | null
          communityInterestText?: string | null
          companyProductText?: string | null
          companyAudienceTags?: string | null
          companyName?: string | null
          communityInterestTags?: string | null
          firstName?: string | null
          hasBadge?: boolean | null
          fullName?: string | null
          kind?: string | null
          lastName?: string | null
          learningGoalsText?: string | null
          linkedin?: string | null
          location?: string | null
          role?: string | null
          sanitySlug?: string | null
          twitter?: string | null
        }
        previousCompanies: Array<{
          __typename: 'UserCompany'
          id: string
          company: {
            __typename: 'Company'
            id: string
            companyName?: string | null
            name?: string | null
          }
        }>
        recentSlackJoins: Array<{
          __typename: 'SlackAccount'
          slackUserId: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              avatarUrl: string
              fullName?: string | null
            }
          }
        }>
      }
    }
    homepageChecks: { __typename: 'HomepageCheck'; currentChecklist?: string | null }
  } | null
}

export type SubmitPostApplySurveyMutationVariables = Exact<{
  input: SubmitPostApplySurveyInput
}>

export type SubmitPostApplySurveyMutation = {
  __typename: 'Mutation'
  submitPostApplySurvey?: {
    __typename: 'SubmitPostApplySurveyPayload'
    id: string
  } | null
}

export type SubscriptionSeatsChangePreviewMutationVariables = Exact<{
  input: SubscriptionSeatsChangePreviewInput
}>

export type SubscriptionSeatsChangePreviewMutation = {
  __typename: 'Mutation'
  subscriptionSeatsChangePreview?: {
    __typename: 'SubscriptionSeatsChangePreviewPayload'
    amount: string
  } | null
}

export type ToggleManualAssignSubscriptionMutationVariables = Exact<{
  input: ToggleManualAssignSubscriptionInput
}>

export type ToggleManualAssignSubscriptionMutation = {
  __typename: 'Mutation'
  toggleManualAssignSubscription?: {
    __typename: 'ToggleManualAssignSubscriptionPayload'
    manualAssign: boolean
  } | null
}

export type TogglePostFollowMutationVariables = Exact<{
  input: TogglePostFollowInput
}>

export type TogglePostFollowMutation = {
  __typename: 'Mutation'
  togglePostFollow?: { __typename: 'TogglePostFollowPayload'; following: boolean } | null
}

export type UpdateTopicActivityMutationVariables = Exact<{
  input: UpdateTopicActivityInput
}>

export type UpdateTopicActivityMutation = {
  __typename: 'Mutation'
  updateTopicActivity?: {
    __typename: 'UpdateTopicActivityPayload'
    id?: string | null
    lastViewedAt?: string | null
    success?: boolean | null
  } | null
}

export type TrackCourseEnrollmentConfirmedMutationVariables = Exact<{
  input: CourseEnrollmentConfirmedInput
}>

export type TrackCourseEnrollmentConfirmedMutation = {
  __typename: 'Mutation'
  trackCourseEnrollmentConfirmed?: {
    __typename: 'CourseEnrollmentConfirmedPayload'
    errors?: Array<string> | null
  } | null
}

export type TrackCourseEnrollmentStartedMutationVariables = Exact<{
  input: CourseEnrollmentStartedInput
}>

export type TrackCourseEnrollmentStartedMutation = {
  __typename: 'Mutation'
  trackCourseEnrollmentStarted?: {
    __typename: 'CourseEnrollmentStartedPayload'
    errors?: Array<string> | null
  } | null
}

export type TrackServerEventMutationVariables = Exact<{
  input: TrackServerEventInput
}>

export type TrackServerEventMutation = {
  __typename: 'Mutation'
  trackServerEvent?: {
    __typename: 'TrackServerEventPayload'
    errors?: Array<string> | null
  } | null
}

export type UnassignCohortCreditMutationVariables = Exact<{
  input: UnassignCohortCreditInput
}>

export type UnassignCohortCreditMutation = {
  __typename: 'Mutation'
  unassignCohortCredit?: {
    __typename: 'UnassignCohortCreditPayload'
    errors?: Array<string> | null
    seat?: { __typename: 'Seat'; id: string } | null
  } | null
}

export type UnshareBookmarkFolderMutationVariables = Exact<{
  input: UnshareBookmarkFolderInput
}>

export type UnshareBookmarkFolderMutation = {
  __typename: 'Mutation'
  unshareBookmarkFolder?: {
    __typename: 'UnshareBookmarkFolderPayload'
    errors?: Array<string> | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    } | null
    sharedFolder?: {
      __typename: 'SharedFolder'
      id: string
      status: SharedFolderStatus
      sharedPartyId: string
      sharedPartyType: string
      userId: string
      bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
    } | null
  } | null
}

export type UpdateArtifactCommentMutationVariables = Exact<{
  input: UpdateArtifactCommentInput
}>

export type UpdateArtifactCommentMutation = {
  __typename: 'Mutation'
  updateArtifactComment?: {
    __typename: 'UpdateArtifactCommentPayload'
    errors?: Array<string> | null
    artifactComment?: {
      __typename: 'ArtifactComment'
      id: string
      createdAt: string
    } | null
  } | null
}

export type UpdateBookmarkMutationVariables = Exact<{
  input: UpdateBookmarkInput
}>

export type UpdateBookmarkMutation = {
  __typename: 'Mutation'
  updateBookmark?: {
    __typename: 'UpdateBookmarkPayload'
    errors?: Array<string> | null
    bookmark?: {
      __typename: 'Bookmark'
      id: string
      anchor?: string | null
      noteBody?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      type: BookmarkType
      videoCode?: string | null
      basedOn?: string | null
      title?: string | null
      externalUrl?: string | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          name?: string | null
          id: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          thumbnailUrl: string
        }>
      } | null
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        path?: string | null
        contentType?: CmsSectionContentType | null
      } | null
    } | null
    collectionActivity?: {
      __typename: 'CollectionActivity'
      id: string
      actionType: string
      actionText: string
      bookmarkFolderId?: string | null
      hasRestoreAction: boolean
      createdAtInWords: string
      filedBookmark?: {
        __typename: 'FiledBookmark'
        id: string
        bookmarkId: string
      } | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    } | null
  } | null
}

export type UpdateBookmarkFolderMutationVariables = Exact<{
  input: UpdateBookmarkFolderInput
}>

export type UpdateBookmarkFolderMutation = {
  __typename: 'Mutation'
  updateBookmarkFolder?: {
    __typename: 'UpdateBookmarkFolderPayload'
    errors?: Array<string> | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      parentId?: string | null
      reforgeCollection: boolean
      forkedFromId?: string | null
      user: { __typename: 'User'; id: string; fullName?: string | null }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyType: string
      }>
    } | null
  } | null
}

export type UpdateCclMutationVariables = Exact<{
  input: UpdateCclInput
}>

export type UpdateCclMutation = {
  __typename: 'Mutation'
  updateCcl?: { __typename: 'UpdateCclPayload'; status?: string | null } | null
}

export type UpdateCohortPostMutationVariables = Exact<{
  input: UpdateCohortPostInput
}>

export type UpdateCohortPostMutation = {
  __typename: 'Mutation'
  updateCohortPost?: { __typename: 'UpdateCohortPostPayload'; id?: string | null } | null
}

export type UpdateCohortReplyMutationVariables = Exact<{
  input: UpdateCohortReplyInput
}>

export type UpdateCohortReplyMutation = {
  __typename: 'Mutation'
  updateCohortReply?: {
    __typename: 'UpdateCohortReplyPayload'
    id?: string | null
  } | null
}

export type UpdateCourseEnrollmentMutationVariables = Exact<{
  input: UpdateCourseEnrollmentInput
}>

export type UpdateCourseEnrollmentMutation = {
  __typename: 'Mutation'
  updateCourseEnrollment?: {
    __typename: 'UpdateCourseEnrollmentPayload'
    userCourseId: string
    errors?: Array<string> | null
  } | null
}

export type UpdateCourseSessionMutationVariables = Exact<{
  input: UpdateCourseSessionInput
}>

export type UpdateCourseSessionMutation = {
  __typename: 'Mutation'
  updateCourseSession?: {
    __typename: 'UpdateCourseSessionPayload'
    id: string
    errors?: Array<string> | null
  } | null
}

export type BillingAddressFragment = {
  __typename: 'StripeAddress'
  line1: string
  line2?: string | null
  city: string
  state?: string | null
  country: string
  postal_code: string
}

export type UpdateCustomerContactMutationVariables = Exact<{
  input: UpdateCustomerContactInput
}>

export type UpdateCustomerContactMutation = {
  __typename: 'Mutation'
  updateCustomerContact: {
    __typename: 'UpdateCustomerContactPayload'
    name: string
    address: {
      __typename: 'StripeAddress'
      line1: string
      line2?: string | null
      city: string
      state?: string | null
      country: string
      postal_code: string
    }
  }
}

export type UpdateInvoiceUserFieldsMutationVariables = Exact<{
  input: UpdateInvoiceUserFieldsInput
}>

export type UpdateInvoiceUserFieldsMutation = {
  __typename: 'Mutation'
  updateInvoiceUserFields?: {
    __typename: 'UpdateInvoiceUserFieldsPayload'
    id: string
  } | null
}

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: UpdatePaymentMethodInput
}>

export type UpdatePaymentMethodMutation = {
  __typename: 'Mutation'
  updatePaymentMethod?: {
    __typename: 'UpdatePaymentMethodPayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type UpdatePreferencesMutationVariables = Exact<{
  input: UpdatePreferencesInput
}>

export type UpdatePreferencesMutation = {
  __typename: 'Mutation'
  updatePreferences?: {
    __typename: 'UpdatePreferencesPayload'
    success?: boolean | null
  } | null
}

export type UpdateProjectDeliverableMutationVariables = Exact<{
  input: UpdateProjectDeliverableInput
}>

export type UpdateProjectDeliverableMutation = {
  __typename: 'Mutation'
  updateProjectDeliverable?: {
    __typename: 'UpdateProjectDeliverablePayload'
    errors?: Array<string> | null
    projectDeliverable?: {
      __typename: 'ProjectDeliverable'
      id: string
      url?: string | null
    } | null
  } | null
}

export type UpdateReplyMutationVariables = Exact<{
  input: UpdateReplyInput
}>

export type UpdateReplyMutation = {
  __typename: 'Mutation'
  updateReply?: {
    __typename: 'UpdateReplyPayload'
    id?: string | null
    replyableId: string
  } | null
}

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput
}>

export type UpdateTeamMutation = {
  __typename: 'Mutation'
  updateTeam?: {
    __typename: 'UpdateTeamPayload'
    success: boolean
    errors?: Array<string> | null
    team?: {
      __typename: 'Subscription'
      id: string
      domainName?: string | null
      isDiscoverable?: boolean | null
      logoUrl?: string | null
      name?: string | null
    } | null
  } | null
}

export type UpdateTeamPostMutationVariables = Exact<{
  input: UpdateTeamPostInput
}>

export type UpdateTeamPostMutation = {
  __typename: 'Mutation'
  updateTeamPost?: {
    __typename: 'UpdateTeamPostPayload'
    errors?: Array<string> | null
    teamPost?: { __typename: 'TeamPost'; id: string; body?: string | null } | null
  } | null
}

export type UpdateTimezoneMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateTimezoneMutation = {
  __typename: 'Mutation'
  updateUser?: {
    __typename: 'UpdateUserPayload'
    errors?: Array<string> | null
    user?: { __typename: 'User'; id: string; timezone?: string | null } | null
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename: 'Mutation'
  updateUser?: {
    __typename: 'UpdateUserPayload'
    errors?: Array<string> | null
    user?: {
      __typename: 'User'
      id: string
      aboutMeText?: string | null
      allowDirectMessages: boolean
      askMeAboutText?: string | null
      avatarUrl: string
      communityInterestText?: string | null
      companyName?: string | null
      companyProductText?: string | null
      firstName?: string | null
      lastName?: string | null
      fullName?: string | null
      forceFlag?: string | null
      learningGoalsText?: string | null
      linkedin?: string | null
      location?: string | null
      slackResponseTime?: string | null
      role?: string | null
      status: UserStatus
      timezone?: string | null
      twitter?: string | null
      accessPolicyKind: string
      monthJoined?: string | null
      yearJoined?: string | null
      slackUserId?: string | null
      joinSlackHref?: string | null
      networkOnboarded: boolean
      networkRecommendations: Array<string>
      profile: {
        __typename: 'UserProfile'
        aboutMeText?: string | null
        allowDirectMessages?: boolean | null
        askMeAboutText?: string | null
        avatarUrl: string
        communityInterestText?: string | null
        companyName?: string | null
        companyProductText?: string | null
        ctaText?: string | null
        ctaUrl?: string | null
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        learningGoalsText?: string | null
        linkedin?: string | null
        location?: string | null
        role?: string | null
        status: UserStatus
        timezone?: string | null
        twitter?: string | null
        areasOfExpertiseTags?: string | null
        businessModelTags?: string | null
        companyAudienceTags?: string | null
        communityInterestTags?: string | null
        hasBadge?: boolean | null
        kind?: string | null
        sanitySlug?: string | null
      }
      clips: Array<{
        __typename: 'Clip'
        id: string
        title: string
        slug: string
        clipSubtype?: string | null
        duration?: number | null
        thumbnailUrl: string
        summary?: string | null
        summaryWistiaCode: string
        hasBeenViewedByCurrentUser: boolean
        lastViewedAt?: string | null
        company?: {
          __typename: 'Company'
          id: string
          cardLogoUrl?: string | null
          name?: string | null
        } | null
        users: Array<{
          __typename: 'User'
          id: string
          avatarUrl: string
          fullName?: string | null
          role?: string | null
          companyName?: string | null
          profile: { __typename: 'UserProfile'; profileUrl?: string | null }
        }>
      }>
      cohorts?: {
        __typename: 'UserCohorts'
        current: Array<{
          __typename: 'UserCohort'
          id: string
          season: string
          cohort: {
            __typename: 'Cohort'
            id: string
            cmsProgram: {
              __typename: 'CmsProgram'
              id: string
              name: string
              code?: string | null
              iconImageUrl?: string | null
            }
            season: { __typename: 'Season'; id: string; name: string; year: string }
          }
          snapshot?: {
            __typename: 'Snapshot'
            id: string
            token: string
            released: boolean
          } | null
        }>
        previous: Array<{
          __typename: 'UserCohort'
          id: string
          season: string
          cohort: {
            __typename: 'Cohort'
            id: string
            cmsProgram: {
              __typename: 'CmsProgram'
              id: string
              name: string
              code?: string | null
              iconImageUrl?: string | null
            }
            season: { __typename: 'Season'; id: string; name: string; year: string }
          }
          snapshot?: {
            __typename: 'Snapshot'
            id: string
            token: string
            released: boolean
          } | null
        }>
      } | null
      userCourses: {
        __typename: 'UserCourses'
        all?: Array<{
          __typename: 'UserCourse'
          id: string
          course: { __typename: 'Course'; title: string }
          courseSession: {
            __typename: 'CourseSession'
            id: string
            courseTitle: string
            startsAt: string
          }
        }> | null
      }
      cohortsHosted: Array<{
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          code?: string | null
          name: string
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; name: string; year: string }
      }>
      is: { __typename: 'UserIs'; expired: boolean; creator: boolean }
      lastForumContributions: Array<
        | {
            __typename: 'Comment'
            id: string
            body?: string | null
            createdAtInWords: string
            post?: {
              __typename: 'Post'
              id: string
              title?: string | null
              toParam: string
            } | null
          }
        | {
            __typename: 'Post'
            id: string
            createdAtInWords: string
            title?: string | null
            toParam: string
          }
        | {
            __typename: 'Reply'
            id: string
            body?: string | null
            createdAtInWords: string
            post?: {
              __typename: 'Post'
              id: string
              title?: string | null
              toParam: string
            } | null
          }
      >
      previousCompanies: Array<{
        __typename: 'UserCompany'
        id: string
        company: {
          __typename: 'Company'
          id: string
          companyName?: string | null
          name?: string | null
        }
      }>
      recentSlackJoins: Array<{
        __typename: 'SlackAccount'
        slackUserId: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            avatarUrl: string
            fullName?: string | null
          }
        }
      }>
    } | null
  } | null
}

export type UpdateUserStatusMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserStatusMutation = {
  __typename: 'Mutation'
  updateUser?: {
    __typename: 'UpdateUserPayload'
    errors?: Array<string> | null
    user?: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; status: UserStatus }
    } | null
  } | null
}

export type UpdateUserCohortMutationVariables = Exact<{
  input: UpdateUserCohortInput
}>

export type UpdateUserCohortMutation = {
  __typename: 'Mutation'
  updateUserCohort?: {
    __typename: 'UpdateUserCohortPayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type UpdateUserCourseMutationVariables = Exact<{
  input: UpdateUserCourseInput
}>

export type UpdateUserCourseMutation = {
  __typename: 'Mutation'
  updateUserCourse?: {
    __typename: 'UpdateUserCoursePayload'
    id?: string | null
    errors?: Array<string> | null
  } | null
}

export type UpgradeSubscriptionMutationVariables = Exact<{
  input: UpgradeSubscriptionInput
}>

export type UpgradeSubscriptionMutation = {
  __typename: 'Mutation'
  upgradeSubscription?: {
    __typename: 'UpgradeSubscriptionPayload'
    success: boolean
    errors?: Array<string> | null
  } | null
}

export type UrlChangeMutationVariables = Exact<{
  input: UrlChangeInput
}>

export type UrlChangeMutation = {
  __typename: 'Mutation'
  urlChange?: { __typename: 'UrlChangePayload'; errors?: Array<string> | null } | null
}

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput
}>

export type VerifyEmailMutation = {
  __typename: 'Mutation'
  verifyEmail?: {
    __typename: 'VerifyEmailPayload'
    companyName?: string | null
    isPrimaryEmail: boolean
    name?: string | null
  } | null
}

export type ViewBookmarkFolderMutationVariables = Exact<{
  input: ViewBookmarkFolderInput
}>

export type ViewBookmarkFolderMutation = {
  __typename: 'Mutation'
  viewBookmarkFolder?: {
    __typename: 'ViewBookmarkFolderPayload'
    errors?: Array<string> | null
    isOnboardingCollection?: boolean | null
    bookmarkFolder?: {
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      usersSharedWith: Array<{
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }>
      individualUsersSharedWith: Array<{
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }>
      teamMembersWhoStartedGoal?: Array<{
        __typename: 'User'
        id: string
        fullName?: string | null
        avatarUrl: string
      }> | null
      teamMembersWhoCompletedGoal?: Array<{
        __typename: 'User'
        id: string
        fullName?: string | null
        avatarUrl: string
      }> | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    } | null
    activity?: {
      __typename: 'CollectionActivity'
      id: string
      actionType: string
      actionText: string
      bookmarkFolderId?: string | null
      hasRestoreAction: boolean
      createdAtInWords: string
      filedBookmark?: {
        __typename: 'FiledBookmark'
        id: string
        bookmarkId: string
      } | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    } | null
  } | null
}

export type WhatWeCanDoBetterMutationVariables = Exact<{
  input: WhatWeCanDoBetterInput
}>

export type WhatWeCanDoBetterMutation = {
  __typename: 'Mutation'
  whatWeCanDoBetter?: {
    __typename: 'WhatWeCanDoBetterPayload'
    success: boolean
    errors?: Array<string> | null
  } | null
}

export type AcceptingApplicationsQueryVariables = Exact<{ [key: string]: never }>

export type AcceptingApplicationsQuery = {
  __typename: 'Query'
  acceptingApplications: boolean
}

export type ActiveProgramQueryVariables = Exact<{ [key: string]: never }>

export type ActiveProgramQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    activeProgram?: {
      __typename: 'UserProgram'
      id: string
      enrolled?: boolean | null
      progress?: ProgramProgressScalar | null
    } | null
  } | null
}

export type ActivityListQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<Scalars['String']>
}>

export type ActivityListQuery = {
  __typename: 'Query'
  activityList: {
    __typename: 'ActivityList'
    activities: Array<{
      __typename: 'ActivityListItem'
      activityIds: Array<string>
      excerpt?: string | null
      read: boolean
      timestamp: string
      trackedAnchor?: string | null
      trackedId?: string | null
      trackedPath?: string | null
      trackedSlug?: string | null
      trackedTitle?: string | null
      trackedType: string
      cohortChannelHref?: string | null
      collectionPath?: string | null
      collectionName?: string | null
      action: {
        __typename: 'ActivityAction'
        actionString?: string | null
        type?: string | null
        reactions?: Array<string> | null
      }
      noun: {
        __typename: 'ActivityNoun'
        asString?: string | null
        name?: string | null
        pattern?: string | null
        slug?: string | null
      }
      subjects: Array<{
        __typename: 'ActivitySubject'
        name: string
        slug?: string | null
        remainingNames?: Array<string> | null
      }>
    }>
    meta: {
      __typename: 'Pagination'
      currentPage: number
      totalEntries: number
      totalPages: number
    }
  }
}

export type AdminCourseSessionDetailQueryVariables = Exact<{
  courseSessionId: Scalars['ID']
}>

export type AdminCourseSessionDetailQuery = {
  __typename: 'Query'
  courseSessionDetail: {
    __typename: 'CourseSessionDetail'
    dbHostAccessAllowedForUserIds: Array<number>
    dbCourseSessionId: string
    dbCourseId: string
    dbCourseSlug: string
    dbCourseTitle: string
    dbSanityId?: string | null
    dbStartsAt?: string | null
    dbSlackChannelId?: string | null
    dbEndsAt?: string | null
    dbEnrollmentStartsAt?: string | null
    dbMaxEnrollmentPaid?: number | null
    dbMaxEnrollmentTotal?: number | null
    dbMaxEnrollmentUnlimited?: number | null
    dbPrice?: number | null
    dbSessionGroupId?: number | null
    dbStripeProductId?: string | null
    sanityCourseId?: string | null
    sanityCourseTitle?: string | null
    sanityCourseSessionId?: string | null
    sanityCourseSessionTitle?: string | null
    sanityDuration?: string | null
    sanitySlackChannelId?: string | null
    sanityStartsAt?: string | null
    sanityEndsAt?: string | null
  }
}

export type AnticopyExceptionIdsQueryVariables = Exact<{ [key: string]: never }>

export type AnticopyExceptionIdsQuery = {
  __typename: 'Query'
  anticopyExceptionIds: Array<string>
}

export type ArtifactAnnotationsQueryVariables = Exact<{
  artifactSlug: Scalars['String']
}>

export type ArtifactAnnotationsQuery = {
  __typename: 'Query'
  artifactAnnotations?: Array<{
    __typename: 'ArtifactAnnotation'
    id: string
    descriptionBody?: any | null
    createdAt: string
    video?: {
      __typename: 'ArtifactAnnotationVideo'
      playbackId?: string | null
      thumbTime?: number | null
    } | null
    reactionsGroupedByName: Array<{
      __typename: 'ArtifactAnnotationReactionGrouped'
      name: string
      count: number
      currentUserHasReacted: boolean
    }>
  }> | null
}

export type ArtifactAnnotationsCountQueryVariables = Exact<{
  artifactSlug: Scalars['String']
}>

export type ArtifactAnnotationsCountQuery = {
  __typename: 'Query'
  artifactAnnotationsCount: number
}

export type ArtifactCommentsQueryVariables = Exact<{
  artifactId: Scalars['String']
}>

export type ArtifactCommentsQuery = {
  __typename: 'Query'
  artifactComments: Array<{
    __typename: 'ArtifactComment'
    id: string
    message: string
    createdAt: string
    reactionsGroupedByName: Array<{
      __typename: 'ArtifactCommentReactionGrouped'
      name: string
      count: number
      currentUserHasReacted: boolean
    }>
    user: {
      __typename: 'ArtifactCommentUser'
      id: string
      fullName?: string | null
      avatarUrl: string
      role?: string | null
      companyName?: string | null
    }
  }>
}

export type ArtifactCommentsCountQueryVariables = Exact<{
  artifactId: Scalars['String']
}>

export type ArtifactCommentsCountQuery = {
  __typename: 'Query'
  artifactCommentsCount: number
}

export type ArtifactOnboardingRecommendationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  lastId?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
}>

export type ArtifactOnboardingRecommendationsQuery = {
  __typename: 'Query'
  artifactOnboardingRecommendations: any
}

export type ArtifactPageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ArtifactPageQuery = {
  __typename: 'Query'
  artifact?: any | null
  artifactContributors: Array<{
    __typename: 'ArtifactContributor'
    avatarUrl?: string | null
    companyName?: string | null
    contributionVerb: string
    id: string
    name?: string | null
    position?: string | null
    primaryEmail?: string | null
    slug?: string | null
    status: string
    relevantWorkEntry?: any | null
    allEntries?: any | null
    isCollectiveMember?: boolean | null
    user: {
      __typename: 'ExpertUserProfile'
      id: string
      kind?: string | null
      fullName?: string | null
      avatarUrl: string
      companyName?: string | null
      role?: string | null
    }
  }>
}

export type ArtifactSubtopicQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ArtifactSubtopicQuery = {
  __typename: 'Query'
  subtopic?: {
    __typename: 'ArtifactSubtopic'
    id?: string | null
    title: string
    titlePlural: string
    slug: string
    isDeprecated?: boolean | null
    redirectTo?: string | null
    lastUpdate?: string | null
    termDefinition?: any | null
    topic: {
      __typename: 'ArtifactTopic'
      title: string
      slug: string
      isPublic?: boolean | null
      isDeprecated?: boolean | null
      redirectTo?: string | null
    }
    relatedBlogPosts?: Array<{
      __typename: 'BlogPost'
      id: string
      title: string
      slug?: string | null
      summaryText?: any | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
      }> | null
    }> | null
    relatedGuides?: Array<{
      __typename: 'Unit'
      id: string
      title?: string | null
      slug?: string | null
      overview?: any | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
      }> | null
    }> | null
  } | null
}

export type ArtifactTopicQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ArtifactTopicQuery = {
  __typename: 'Query'
  artifactTopic?: {
    __typename: 'ArtifactTopic'
    id?: string | null
    title: string
    slug: string
    isDeprecated?: boolean | null
    redirectTo?: string | null
    subtopicsToDisplay?: Array<string> | null
    totalArtifactCount?: number | null
    lastUpdate?: string | null
  } | null
}

export type ArtifactsForSubtopicQueryVariables = Exact<{
  slug: Scalars['String']
  max?: InputMaybe<Scalars['Int']>
}>

export type ArtifactsForSubtopicQuery = {
  __typename: 'Query'
  artifactsForSubtopic?: {
    __typename: 'ArtifactsForSubtopic'
    totalCount: number
    artifacts: Array<{
      __typename: 'ArtifactWithWorkEntry'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      summary?: any | null
      thumbnailUrl?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
      }> | null
      workEntry?: {
        __typename: 'WorkEntry'
        position?: string | null
        generalRole?: string | null
        specificCompany?: boolean | null
        company?: {
          __typename: 'SanityCompany'
          name?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type TopicsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type TopicsQuery = {
  __typename: 'Query'
  topics: Array<{
    __typename: 'ArtifactTopic'
    id?: string | null
    title: string
    slug: string
    icon?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }>
}

export type ArtifactSubtopicsQueryVariables = Exact<{
  subtopicSlug?: InputMaybe<Scalars['String']>
  topicSlug?: InputMaybe<Scalars['String']>
}>

export type ArtifactSubtopicsQuery = {
  __typename: 'Query'
  subtopics?: Array<{
    __typename: 'ArtifactSubtopic'
    id?: string | null
    title: string
    slug: string
    titlePlural: string
    topic: { __typename: 'ArtifactTopic'; title: string; slug: string }
  }> | null
}

export type ArtifactSubtopicsWithRelatedBlogsQueryVariables = Exact<{
  subtopicSlug?: InputMaybe<Scalars['String']>
  topicSlug?: InputMaybe<Scalars['String']>
}>

export type ArtifactSubtopicsWithRelatedBlogsQuery = {
  __typename: 'Query'
  subtopics?: Array<{
    __typename: 'ArtifactSubtopic'
    id?: string | null
    title: string
    slug: string
    titlePlural: string
    topic: { __typename: 'ArtifactTopic'; title: string; slug: string }
    relatedBlogPosts?: Array<{
      __typename: 'BlogPost'
      id: string
      title: string
      slug?: string | null
    }> | null
  }> | null
}

export type ArtifactUserDataQueryVariables = Exact<{ [key: string]: never }>

export type ArtifactUserDataQuery = {
  __typename: 'Query'
  artifactUserData: {
    __typename: 'ArtifactUserData'
    savedArtifactsForUser: Array<string>
    viewedArtifactsForUser: Array<string>
    savedCountPerArtifact: Array<{
      __typename: 'SavedCountPerArtifact'
      artifactId: string
      count: number
    }>
  }
}

export type SavedCountPerArtifactQueryVariables = Exact<{ [key: string]: never }>

export type SavedCountPerArtifactQuery = {
  __typename: 'Query'
  savedCountPerArtifact?: Array<{
    __typename: 'SavedCountPerArtifact'
    artifactId: string
    count: number
  }> | null
}

export type ArtifactsIndexPageViewedArtifactsForUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type ArtifactsIndexPageViewedArtifactsForUserQuery = {
  __typename: 'Query'
  viewedArtifactsForUser?: Array<{
    __typename: 'ViewedArtifact'
    artifactId: string
    updatedAt?: string | null
  }> | null
}

export type AllTopicsAndFunctionsQueryVariables = Exact<{ [key: string]: never }>

export type AllTopicsAndFunctionsQuery = {
  __typename: 'Query'
  allTopicsAndFunctions: Array<{
    __typename: 'ArtifactTopicFilter'
    id: string
    title: string
    slug: string
    type: string
  }>
}

export type DedupedTopicsAndFunctionsQueryVariables = Exact<{ [key: string]: never }>

export type DedupedTopicsAndFunctionsQuery = {
  __typename: 'Query'
  dedupedTopicsAndFunctions: Array<{
    __typename: 'ArtifactTopicFilter'
    id: string
    title: string
    slug: string
    type: string
  }>
}

export type ArtifactsIndexPageForYouRecommendationsQueryVariables = Exact<{
  [key: string]: never
}>

export type ArtifactsIndexPageForYouRecommendationsQuery = {
  __typename: 'Query'
  artifactOnboardingRecommendations: any
}

export type ArtifactsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
}>

export type ArtifactsByIdsQuery = {
  __typename: 'Query'
  artifactsByIds?: Array<{
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    thumbnailUrl?: string | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      userId?: string | null
      position?: string | null
      name?: string | null
      company?: { __typename: 'SanityCompany'; name?: string | null } | null
      user?: {
        __typename: 'ExpertUserProfile'
        avatarUrl: string
        aboutMeText?: string | null
        companyName?: string | null
        ctaText?: string | null
        ctaUrl?: string | null
        firstName?: string | null
        fullName?: string | null
        hasBadge: boolean
        lastName?: string | null
        linkedin?: string | null
        location?: string | null
        role?: string | null
        externalId?: string | null
        twitter?: string | null
        kind?: string | null
        is: { __typename: 'UserIs'; collective: boolean }
        previousCompanies: Array<{
          __typename: 'UserCompany'
          id: string
          company: { __typename: 'Company'; id: string; name?: string | null }
        }>
        allCourses: Array<{
          __typename: 'CclCourse'
          id: string
          title: string
          slug: string
          shortDescription?: string | null
          publishedAt?: string | null
          creators?: Array<{
            __typename: 'CclExpert'
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            startsAt: string
            duration?: string | null
            endsAt: string
          }> | null
        }>
        allHostedEvents: Array<{
          __typename: 'Event'
          id: string
          name: string
          slug: string
          past: boolean
          kind: string
          startsAtUtc: string
          endsAtUtc: string
          hosts: Array<{
            __typename: 'EventSpeaker'
            id: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                avatarUrl: string
              }
            }
          }>
        }>
        allBlogPosts: Array<{
          __typename: 'CclBlogPost'
          id: string
          title: string
          slug: string
          thumbnailUrl?: string | null
          firstPublishedAt?: string | null
          summary?: any | null
          sourceId?: string | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            name?: string | null
            avatarUrl?: string | null
          }> | null
        }>
        allGuides: Array<{
          __typename: 'CclGuide'
          id: string
          title: string
          publishedAt: string
          overview?: string | null
          slug: string
          thumbnailUrl?: string | null
          stepsCount: number
          sourceId?: string | null
          estimatedTimeInMinutes?: number | null
          previewable?: boolean | null
          authors?: Array<{
            __typename: 'CclExpert'
            name: string
            avatarUrl?: string | null
          }> | null
        }>
        upcomingEvents: Array<{
          __typename: 'Event'
          id: string
          name: string
          slug: string
          startsAt?: string | null
          endsAt?: string | null
        }>
        upcomingLiveCourses: Array<{
          __typename: 'CclCourse'
          id: string
          title: string
          slug: string
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            duration?: string | null
            endsAt: string
          }> | null
        }>
      } | null
    }> | null
  }> | null
}

export type FilteredArtifactsQueryVariables = Exact<{
  filters?: InputMaybe<ArtifactFilters>
  lastId?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type FilteredArtifactsQuery = {
  __typename: 'Query'
  filteredArtifacts?: {
    __typename: 'FilteredArtifacts'
    nextArtifactsCount: number
    prevArtifactsCount: number
    totalArtifactsCount: number
    artifacts: Array<{
      __typename: 'Artifact'
      id: string
      isFree?: boolean | null
      title?: string | null
      slug?: string | null
      thumbnailUrl?: string | null
      companyLogoUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        avatarUrl?: string | null
        id: string
        userId?: string | null
        name?: string | null
        slug?: string | null
        position?: string | null
        companyName?: string | null
        user?: {
          __typename: 'ExpertUserProfile'
          id: string
          avatarUrl: string
          aboutMeText?: string | null
          companyName?: string | null
          ctaText?: string | null
          ctaUrl?: string | null
          firstName?: string | null
          fullName?: string | null
          hasBadge: boolean
          lastName?: string | null
          linkedin?: string | null
          location?: string | null
          role?: string | null
          externalId?: string | null
          twitter?: string | null
          kind?: string | null
          is: { __typename: 'UserIs'; collective: boolean }
          previousCompanies: Array<{
            __typename: 'UserCompany'
            id: string
            company: { __typename: 'Company'; id: string; name?: string | null }
          }>
          allCourses: Array<{
            __typename: 'CclCourse'
            id: string
            title: string
            slug: string
            shortDescription?: string | null
            publishedAt?: string | null
            creators?: Array<{
              __typename: 'CclExpert'
              name: string
              avatarUrl?: string | null
            }> | null
            upcomingSessions?: Array<{
              __typename: 'CclCourseSession'
              startsAt: string
              duration?: string | null
              endsAt: string
            }> | null
          }>
          allHostedEvents: Array<{
            __typename: 'Event'
            id: string
            name: string
            slug: string
            past: boolean
            kind: string
            startsAtUtc: string
            endsAtUtc: string
            hosts: Array<{
              __typename: 'EventSpeaker'
              id: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }>
          }>
          allBlogPosts: Array<{
            __typename: 'CclBlogPost'
            id: string
            title: string
            slug: string
            thumbnailUrl?: string | null
            firstPublishedAt?: string | null
            summary?: any | null
            sourceId?: string | null
            authors?: Array<{
              __typename: 'ArtifactAuthor'
              name?: string | null
              avatarUrl?: string | null
            }> | null
          }>
          allGuides: Array<{
            __typename: 'CclGuide'
            id: string
            title: string
            publishedAt: string
            overview?: string | null
            slug: string
            thumbnailUrl?: string | null
            stepsCount: number
            sourceId?: string | null
            estimatedTimeInMinutes?: number | null
            previewable?: boolean | null
            authors?: Array<{
              __typename: 'CclExpert'
              name: string
              avatarUrl?: string | null
            }> | null
          }>
          upcomingEvents: Array<{
            __typename: 'Event'
            id: string
            name: string
            slug: string
            startsAt?: string | null
            endsAt?: string | null
          }>
          upcomingLiveCourses: Array<{
            __typename: 'CclCourse'
            id: string
            title: string
            slug: string
            upcomingSessions?: Array<{
              __typename: 'CclCourseSession'
              id: string
              startsAt: string
              duration?: string | null
              endsAt: string
            }> | null
          }>
        } | null
      }> | null
    }>
  } | null
}

export type FilteredArtifactsFragmentFragment = {
  __typename: 'FilteredArtifacts'
  nextArtifactsCount: number
  prevArtifactsCount: number
  totalArtifactsCount: number
  artifacts: Array<{
    __typename: 'Artifact'
    id: string
    slug?: string | null
    publishDate?: string | null
    title?: string | null
    description?: any | null
    thumbnailUrl?: string | null
    viewCount?: number | null
    commentCount?: number | null
    topics?: any | null
    isFree?: boolean | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      isCollectiveMember?: boolean | null
      avatarUrl?: string | null
      user?: {
        __typename: 'ExpertUserProfile'
        avatarUrl: string
        aboutMeText?: string | null
        companyName?: string | null
        ctaText?: string | null
        ctaUrl?: string | null
        firstName?: string | null
        fullName?: string | null
        hasBadge: boolean
        lastName?: string | null
        linkedin?: string | null
        location?: string | null
        role?: string | null
        externalId?: string | null
        twitter?: string | null
        kind?: string | null
        is: { __typename: 'UserIs'; collective: boolean }
        previousCompanies: Array<{
          __typename: 'UserCompany'
          id: string
          company: { __typename: 'Company'; id: string; name?: string | null }
        }>
        allCourses: Array<{
          __typename: 'CclCourse'
          id: string
          title: string
          slug: string
          shortDescription?: string | null
          publishedAt?: string | null
          creators?: Array<{
            __typename: 'CclExpert'
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            startsAt: string
            duration?: string | null
            endsAt: string
          }> | null
        }>
        allHostedEvents: Array<{
          __typename: 'Event'
          id: string
          name: string
          slug: string
          past: boolean
          kind: string
          startsAtUtc: string
          endsAtUtc: string
          hosts: Array<{
            __typename: 'EventSpeaker'
            id: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                avatarUrl: string
              }
            }
          }>
        }>
        allBlogPosts: Array<{
          __typename: 'CclBlogPost'
          id: string
          title: string
          slug: string
          thumbnailUrl?: string | null
          firstPublishedAt?: string | null
          summary?: any | null
          sourceId?: string | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            name?: string | null
            avatarUrl?: string | null
          }> | null
        }>
        allGuides: Array<{
          __typename: 'CclGuide'
          id: string
          title: string
          publishedAt: string
          overview?: string | null
          slug: string
          thumbnailUrl?: string | null
          stepsCount: number
          sourceId?: string | null
          estimatedTimeInMinutes?: number | null
          previewable?: boolean | null
          authors?: Array<{
            __typename: 'CclExpert'
            name: string
            avatarUrl?: string | null
          }> | null
        }>
        upcomingEvents: Array<{
          __typename: 'Event'
          id: string
          name: string
          slug: string
          startsAt?: string | null
          endsAt?: string | null
        }>
        upcomingLiveCourses: Array<{
          __typename: 'CclCourse'
          id: string
          title: string
          slug: string
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            duration?: string | null
            endsAt: string
          }> | null
        }>
      } | null
      company?: { __typename: 'SanityCompany'; name?: string | null } | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
    filters?: Array<{
      __typename: 'ArtifactFilter'
      slug: string
      title: string
      type: string
    }> | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }>
}

export type ArtifactWithExpertFragmentFragment = {
  __typename: 'Artifact'
  id: string
  slug?: string | null
  publishDate?: string | null
  title?: string | null
  description?: any | null
  thumbnailUrl?: string | null
  viewCount?: number | null
  commentCount?: number | null
  topics?: any | null
  isFree?: boolean | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    isCollectiveMember?: boolean | null
    avatarUrl?: string | null
    user?: {
      __typename: 'ExpertUserProfile'
      avatarUrl: string
      aboutMeText?: string | null
      companyName?: string | null
      ctaText?: string | null
      ctaUrl?: string | null
      firstName?: string | null
      fullName?: string | null
      hasBadge: boolean
      lastName?: string | null
      linkedin?: string | null
      location?: string | null
      role?: string | null
      externalId?: string | null
      twitter?: string | null
      kind?: string | null
      is: { __typename: 'UserIs'; collective: boolean }
      previousCompanies: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; id: string; name?: string | null }
      }>
      allCourses: Array<{
        __typename: 'CclCourse'
        id: string
        title: string
        slug: string
        shortDescription?: string | null
        publishedAt?: string | null
        creators?: Array<{
          __typename: 'CclExpert'
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          startsAt: string
          duration?: string | null
          endsAt: string
        }> | null
      }>
      allHostedEvents: Array<{
        __typename: 'Event'
        id: string
        name: string
        slug: string
        past: boolean
        kind: string
        startsAtUtc: string
        endsAtUtc: string
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              avatarUrl: string
            }
          }
        }>
      }>
      allBlogPosts: Array<{
        __typename: 'CclBlogPost'
        id: string
        title: string
        slug: string
        thumbnailUrl?: string | null
        firstPublishedAt?: string | null
        summary?: any | null
        sourceId?: string | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          name?: string | null
          avatarUrl?: string | null
        }> | null
      }>
      allGuides: Array<{
        __typename: 'CclGuide'
        id: string
        title: string
        publishedAt: string
        overview?: string | null
        slug: string
        thumbnailUrl?: string | null
        stepsCount: number
        sourceId?: string | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
        authors?: Array<{
          __typename: 'CclExpert'
          name: string
          avatarUrl?: string | null
        }> | null
      }>
      upcomingEvents: Array<{
        __typename: 'Event'
        id: string
        name: string
        slug: string
        startsAt?: string | null
        endsAt?: string | null
      }>
      upcomingLiveCourses: Array<{
        __typename: 'CclCourse'
        id: string
        title: string
        slug: string
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          duration?: string | null
          endsAt: string
        }> | null
      }>
    } | null
    company?: { __typename: 'SanityCompany'; name?: string | null } | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }> | null
  filters?: Array<{
    __typename: 'ArtifactFilter'
    slug: string
    title: string
    type: string
  }> | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type BannerDataQueryVariables = Exact<{ [key: string]: never }>

export type BannerDataQuery = {
  __typename: 'Query'
  upcomingSeason?: {
    __typename: 'Season'
    id: string
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    name: string
    nextSeasonName: string
    npsSurveyLink?: string | null
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
  } | null
  preEnrollmentSeason?: {
    __typename: 'Season'
    id: string
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    name: string
    nextSeasonName: string
    npsSurveyLink?: string | null
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
  } | null
  enrollmentSeason?: {
    __typename: 'Season'
    id: string
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    name: string
    nextSeasonName: string
    npsSurveyLink?: string | null
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
  } | null
  currentUser?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    shouldShowNpsSurvey?: boolean | null
    liveProgramName?: string | null
    shouldSeeTrialMessaging: boolean
    dismissedUpdateCompanyBannerAt?: string | null
    companyName?: string | null
    companyNameUpdatedAt?: string | null
    hasUpcomingCohort: boolean
    cohorts?: {
      __typename: 'UserCohorts'
      current: Array<{
        __typename: 'UserCohort'
        id: string
        afterCohortCalloutDismissed: boolean
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          postCoreWeeksEnrollmentIsOpen: boolean
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
          group?: { __typename: 'Group'; id: string } | null
        }
      }>
    } | null
    cohortsShowingNpsSurvey?: Array<{
      __typename: 'UserCohort'
      id: string
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: { __typename: 'CmsProgram'; id: string; name: string }
        season: {
          __typename: 'Season'
          id: string
          name: string
          year: string
          npsSurveyLink?: string | null
          npsSurveyText?: string | null
        }
      }
    }> | null
    is: {
      __typename: 'UserIs'
      alumni: boolean
      member: boolean
      paidMember: boolean
      enrolledInLiveProgram: boolean
      enrolledInActiveCourse: boolean
      enrolledInEnrollmentSeason: boolean
      trialing: boolean
    }
    can: { __typename: 'UserCan'; pay?: boolean | null; enrollInCohort: boolean }
    profile: { __typename: 'UserProfile'; timezone?: string | null }
    openInvoices: Array<{
      __typename: 'StripeInvoice'
      id: string
      status?: StripeInvoiceStatus | null
    }>
    paymentMethods: Array<{
      __typename: 'StripePaymentMethod'
      id: string
      type: string
      defaultPaymentMethod?: boolean | null
      card?: {
        __typename: 'StripePaymentMethodCard'
        brand: string
        last4: string
        expYear: number
        expMonth: number
        status: string
      } | null
    }>
    stripeCustomer?: { __typename: 'StripeCustomer'; id: string; balance: number } | null
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        daysLeft: number
        expiresAt: string
        startsAt?: string | null
        isCanceled?: boolean | null
        isDiscoverable?: boolean | null
        isDunning: boolean
        isExpired?: boolean | null
        isPaidMonthly: boolean
        isTeam: boolean
        lastFailedPaymentAt?: string | null
        numSeats: number
        status?: string | null
        stripeCollectionMethod?: StripeCollectionMethod | null
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        userIsOwner: boolean
        renewSeatCount: number
        hasUnlimitedCohortAccess?: boolean | null
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          cancelAtPeriodEnd?: boolean | null
          id: string
        } | null
        stripeUpcomingInvoice?: {
          __typename: 'StripeUpcomingInvoice'
          amountDue: number
        } | null
      } | null
      last?: {
        __typename: 'Subscription'
        id: string
        isCanceled?: boolean | null
        isExpired?: boolean | null
        isTeam: boolean
        numSeats: number
        userIsOwner: boolean
      } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        daysLeftInTrial?: number | null
      } | null
    } | null
  } | null
  currentLivePrograms: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      isOngoing: boolean
      postCohort: boolean
      coreWeeksHaveEnded: boolean
      postCoreWeeksEnrollmentIsOpen: boolean
    }
  }>
}

export type VideoBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  noteBody?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
}

export type BookmarkNoteFormPartsFragment = {
  __typename: 'Bookmark'
  id: string
  anchor?: string | null
  type: BookmarkType
  noteBody?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
}

export type ProgramBookmarkItemPartsFragment = {
  __typename: 'Bookmark'
  id: string
  anchor?: string | null
  type: BookmarkType
  referenceImageUrl?: string | null
  basedOn?: string | null
  seconds?: number | null
  updated?: string | null
  created?: string | null
  title?: string | null
  externalUrl?: string | null
  metadata?: any | null
  startOffset?: number | null
  endOffset?: number | null
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    path?: string | null
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    lessonCount: number
    purchased: boolean
    upcoming: boolean
    enrolled: boolean
    canEnroll: boolean
    showNewBadge: boolean
    topic?: string | null
    collaborators: Array<{
      __typename: 'Host'
      name?: string | null
      id: string
      companyName?: string | null
      description?: string | null
      title?: string | null
      avatarUrl: string
    }>
  } | null
}

export type SharedCourseBookmarkPartsFragment = {
  __typename: 'Bookmark'
  type: BookmarkType
  basedOn?: string | null
  noteBody?: string | null
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
}

export type CourseBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  type: BookmarkType
  basedOn?: string | null
  noteBody?: string | null
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
}

export type ArtifactBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  basedOn?: string | null
  noteBody?: string | null
  sanityId?: string | null
  type: BookmarkType
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
}

export type GuideBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  basedOn?: string | null
  noteBody?: string | null
  sanityId?: string | null
  type: BookmarkType
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
}

export type EventBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  basedOn?: string | null
  noteBody?: string | null
  sanityId?: string | null
  eventId?: string | null
  type: BookmarkType
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
}

export type ProgramBookmarkPartsFragment = {
  __typename: 'Bookmark'
  id: string
  eventId?: string | null
  lessonPosition?: number | null
  sanityId?: string | null
  type: BookmarkType
  basedOn?: string | null
  noteBody?: string | null
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  anchor?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
  updated?: string | null
  created?: string | null
  title?: string | null
  externalUrl?: string | null
  metadata?: any | null
  startOffset?: number | null
  endOffset?: number | null
  cmsModule?: {
    __typename: 'CmsModule'
    id: string
    name: string
    position?: number | null
  } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    position?: number | null
    progressPercent?: number | null
    childrenCount: number
    completedChildrenCount?: number | null
    path?: string | null
  } | null
  content?: {
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    numPhases?: number | null
    projectIcon?: string | null
    shortDescription?: string | null
    numBookmarks?: number | null
    showNewBadge: boolean
    slug: string
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    lessonNum?: number | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      slug?: string | null
      name: string
    } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    slug: string
    progressPercent: number
    iconImageUrl?: string | null
    notLaunched?: boolean | null
    deprecatedAt?: string | null
    description?: string | null
    synopsis?: string | null
    lessonCount: number
    purchased: boolean
    upcoming: boolean
    enrolled: boolean
    canEnroll: boolean
    showNewBadge: boolean
    topic?: string | null
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
      companyName?: string | null
      description?: string | null
      title?: string | null
      avatarUrl: string
    }>
  } | null
  event?: {
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    willBeRecorded: boolean
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendees: {
      __typename: 'EventAttendeeConnection'
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  } | null
  artifact?: {
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    description?: any | null
    companyLogoUrl?: string | null
    companyName?: string | null
    thumbnailUrl?: string | null
    viewCount?: number | null
    commentCount?: number | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarUrl?: string | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
  } | null
  course?: {
    __typename: 'MarketingCourse'
    id: string
    slug?: string | null
    cmsProgramSlug?: string | null
    startDate?: string | null
    subtitle?: string | null
    title?: string | null
    legacyProgramId?: string | null
    bookmarkId?: string | null
    courseType?: string | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      bookmarkId?: string | null
      isAvailableOnDemand?: boolean | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  guide?: {
    __typename: 'Unit'
    id: string
    title?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    slug?: string | null
    contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      slug?: string | null
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
}

export type CmsProgramBookmarksFeedQueryVariables = Exact<{
  cmsProgramId: Scalars['ID']
  cmsSectionId?: InputMaybe<Scalars['ID']>
}>

export type CmsProgramBookmarksFeedQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    bookmarks: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }>
  } | null
}

export type UserBookmarksQueryVariables = Exact<{ [key: string]: never }>

export type UserBookmarksQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasSeenCollectionsPrompt: boolean
    bookmarkFolders: Array<{
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    }>
    courseBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
    programBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
    artifactBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      basedOn?: string | null
      noteBody?: string | null
      sanityId?: string | null
      type: BookmarkType
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
    }> | null
    eventBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      basedOn?: string | null
      noteBody?: string | null
      sanityId?: string | null
      eventId?: string | null
      type: BookmarkType
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
    }> | null
    guideBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      basedOn?: string | null
      noteBody?: string | null
      sanityId?: string | null
      type: BookmarkType
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
    }> | null
  } | null
}

export type CertificatesQueryVariables = Exact<{ [key: string]: never }>

export type CertificatesQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    certificates: Array<{
      __typename: 'Certificates'
      completed: boolean
      id: string
      name: string
      pdfUrl?: string | null
    }>
  } | null
}

export type ClipPartsFragment = {
  __typename: 'Clip'
  id: string
  title: string
  slug: string
  clipSubtype?: string | null
  duration?: number | null
  thumbnailUrl: string
  summary?: string | null
  summaryWistiaCode: string
  hasBeenViewedByCurrentUser: boolean
  lastViewedAt?: string | null
  company?: {
    __typename: 'Company'
    id: string
    cardLogoUrl?: string | null
    name?: string | null
  } | null
  users: Array<{
    __typename: 'User'
    id: string
    avatarUrl: string
    fullName?: string | null
    role?: string | null
    companyName?: string | null
    profile: { __typename: 'UserProfile'; profileUrl?: string | null }
  }>
}

export type CmsContentQueryVariables = Exact<{
  cmsSectionId: Scalars['ID']
}>

export type CmsContentQuery = {
  __typename: 'Query'
  cmsContent?: {
    __typename: 'CmsContent'
    id: string
    inlinePostAnchors: Array<string>
    bookmarks: Array<{
      __typename: 'Bookmark'
      id: string
      anchor?: string | null
      type: BookmarkType
      noteBody?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
    }>
  } | null
}

export type CmsProgramNamesQueryVariables = Exact<{ [key: string]: never }>

export type CmsProgramNamesQuery = { __typename: 'Query'; cmsProgramNames: Array<string> }

export type CmsSectionQueryVariables = Exact<{
  contentType: CmsSectionContentType
  slug: Scalars['String']
}>

export type CmsSectionQuery = {
  __typename: 'Query'
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    status: string
    publishAt?: string | null
    shortDescription?: string | null
    estimatedTime: number
    bookmarks?: Array<{ __typename: 'Bookmark'; id: string }> | null
    cmsProgram: { __typename: 'CmsProgram'; id: string; progressPercent: number }
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
  } | null
}

export type CmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  status: string
  publishAt?: string | null
  shortDescription?: string | null
  estimatedTime: number
  cmsProgram: { __typename: 'CmsProgram'; id: string; progressPercent: number }
  topic?: { __typename: 'PostTopic'; id: string; title: string } | null
}

export type CmsUnitsQueryVariables = Exact<{ [key: string]: never }>

export type CmsUnitsQuery = {
  __typename: 'Query'
  cmsUnits?: Array<{
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    name: string
    conceptIcon?: string | null
    contentTypeIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    estimatedTime: number
    href: string
    heroImageUrl?: string | null
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    shortDescription?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    slug: string
    lessonNum?: number | null
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    children: Array<{
      __typename: 'CmsSection'
      id: string
      slug: string
      name: string
      heroImageUrl?: string | null
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    }>
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }> | null
}

export type SectionCardChildPartsFragment = {
  __typename: 'CmsSection'
  id: string
  slug: string
  name: string
  heroImageUrl?: string | null
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
}

export type SectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  contentType?: CmsSectionContentType | null
  name: string
  conceptIcon?: string | null
  contentTypeIcon?: string | null
  cmsModuleId: string
  cmsProgramId: string
  estimatedTime: number
  href: string
  heroImageUrl?: string | null
  numBookmarks?: number | null
  numPhases?: number | null
  showNewBadge: boolean
  shortDescription?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  unlimitedAccess: boolean
  slug: string
  lessonNum?: number | null
  accessLevel?: string | null
  previewable: boolean
  lastViewedAt?: string | null
  contentSubtype?: string | null
  children: Array<{
    __typename: 'CmsSection'
    id: string
    slug: string
    name: string
    heroImageUrl?: string | null
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  }>
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    name: string
    enrolled: boolean
    slug: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type CmsUnitsPageTempQueryVariables = Exact<{ [key: string]: never }>

export type CmsUnitsPageTempQuery = {
  __typename: 'Query'
  cmsUnits?: Array<{
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    name: string
    conceptIcon?: string | null
    contentTypeIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    estimatedTime: number
    href: string
    heroImageUrl?: string | null
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    shortDescription?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    slug: string
    lessonNum?: number | null
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    children: Array<{
      __typename: 'CmsSection'
      slug: string
      name: string
      heroImageUrl?: string | null
    }>
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }> | null
}

export type FullWidthProgramCardQueryVariables = Exact<{ [key: string]: never }>

export type FullWidthProgramCardQuery = {
  __typename: 'Query'
  enrollmentSeason?: {
    __typename: 'Season'
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    hasStarted: boolean
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    prettyNameSeasonFirst: string
    startsAt?: string | null
    year: string
    showBlogPostAnnouncementAt?: string | null
    blogPostAnnouncementUrl?: string | null
  } | null
  lastActiveSeason?: {
    __typename: 'Season'
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    hasStarted: boolean
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    prettyNameSeasonFirst: string
    startsAt?: string | null
    year: string
    showBlogPostAnnouncementAt?: string | null
    blogPostAnnouncementUrl?: string | null
  } | null
  currentLivePrograms: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      isOngoing: boolean
      eventCount: number
      weekCount: number
      startsAt?: string | null
      endsAt?: string | null
      postCohort: boolean
      coreWeeksHaveEnded: boolean
      postCoreWeeksEnrollmentIsOpen: boolean
      postKickoff: boolean
      slug: string
      thisWeek?: {
        __typename: 'ScheduledWeek'
        id: string
        nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
      } | null
      season: {
        __typename: 'Season'
        hasStarted: boolean
        id: string
        prettyNameSeasonFirst: string
        showCohortViewerAt?: string | null
      }
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        lessonCount: number
        numBookmarks?: number | null
        progressPercent: number
        code?: string | null
        iconImageUrl?: string | null
      }
    }
  }>
}

export type UserCoachingSessionsQueryVariables = Exact<{ [key: string]: never }>

export type UserCoachingSessionsQuery = {
  __typename: 'Query'
  userCoachingSessions: Array<{
    __typename: 'CoachingSession'
    id: string
    data?: any | null
    externalId: string
  }>
}

export type CohortQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortQuery = {
  __typename: 'Query'
  cohort: {
    __typename: 'Cohort'
    id: string
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }
}

export type CohortConversationPostsQueryVariables = Exact<{
  slug: Scalars['String']
  topicSlug: Scalars['String']
  sortBy: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type CohortConversationPostsQuery = {
  __typename: 'Query'
  cohortPosts: {
    __typename: 'CohortPost'
    count: number
    topic: {
      __typename: 'PostTopic'
      id: string
      title: string
      description?: string | null
      restrictedToStaff: boolean
      isContentClarification: boolean
      groupId?: string | null
    }
    pinnedPosts: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      body?: string | null
      createdAtInWords: string
      lastRespondedAtInWords?: string | null
      isPinned: boolean
      following: boolean
      basedOn?: string | null
      cmsSectionName?: string | null
      cmsSectionParentName?: string | null
      cmsSectionLink?: string | null
      referenceImageUrl?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
      }>
      replies: Array<{
        __typename: 'Reply'
        id: string
        body?: string | null
        createdAtInWords: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
        reactions: Array<{
          __typename: 'Reaction'
          id: string
          kind: ReactionKind
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              avatarUrl: string
            }
          }
        }>
      }>
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        title: string
        description?: string | null
      }>
      groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
    }>
    posts: Array<{
      __typename: 'Post'
      id: string
      title?: string | null
      body?: string | null
      createdAtInWords: string
      lastRespondedAtInWords?: string | null
      isPinned: boolean
      following: boolean
      basedOn?: string | null
      cmsSectionName?: string | null
      cmsSectionParentName?: string | null
      cmsSectionLink?: string | null
      referenceImageUrl?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
      }>
      replies: Array<{
        __typename: 'Reply'
        id: string
        body?: string | null
        createdAtInWords: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
        reactions: Array<{
          __typename: 'Reaction'
          id: string
          kind: ReactionKind
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              avatarUrl: string
            }
          }
        }>
      }>
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        title: string
        description?: string | null
      }>
      groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
    }>
  }
}

export type CohortConversationPostQueryVariables = Exact<{
  postId: Scalars['ID']
}>

export type CohortConversationPostQuery = {
  __typename: 'Query'
  cohortPost: {
    __typename: 'Post'
    id: string
    title?: string | null
    body?: string | null
    createdAtInWords: string
    lastRespondedAtInWords?: string | null
    isPinned: boolean
    following: boolean
    basedOn?: string | null
    cmsSectionName?: string | null
    cmsSectionParentName?: string | null
    cmsSectionLink?: string | null
    referenceImageUrl?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
    }>
    replies: Array<{
      __typename: 'Reply'
      id: string
      body?: string | null
      createdAtInWords: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
      }>
    }>
    topics: Array<{
      __typename: 'PostTopic'
      id: string
      title: string
      description?: string | null
    }>
    groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
  }
}

export type CohortPostPartsFragment = {
  __typename: 'Post'
  id: string
  title?: string | null
  body?: string | null
  createdAtInWords: string
  lastRespondedAtInWords?: string | null
  isPinned: boolean
  following: boolean
  basedOn?: string | null
  cmsSectionName?: string | null
  cmsSectionParentName?: string | null
  cmsSectionLink?: string | null
  referenceImageUrl?: string | null
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
    }
  }>
  replies: Array<{
    __typename: 'Reply'
    id: string
    body?: string | null
    createdAtInWords: string
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
    }>
  }>
  topics: Array<{
    __typename: 'PostTopic'
    id: string
    title: string
    description?: string | null
  }>
  groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
}

export type CohortPostReplyPartsFragment = {
  __typename: 'Reply'
  id: string
  body?: string | null
  createdAtInWords: string
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
    }
  }>
}

export type CohortPostReactionPartsFragment = {
  __typename: 'Reaction'
  id: string
  kind: ReactionKind
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
  }
}

export type CohortPostUserPartsFragment = {
  __typename: 'User'
  id: string
  profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
}

export type CohortPostTopicPartsFragment = {
  __typename: 'PostTopic'
  id: string
  title: string
  description?: string | null
  restrictedToStaff: boolean
  isContentClarification: boolean
  groupId?: string | null
}

export type CohortConversationTopicsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortConversationTopicsQuery = {
  __typename: 'Query'
  cohort: {
    __typename: 'Cohort'
    id: string
    group?: {
      __typename: 'Group'
      id: string
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        title: string
        slug: string
        description?: string | null
        restrictedToStaff: boolean
        latestPostCreatedAtForCohort?: string | null
        userTopicActivity: {
          __typename: 'TopicActivity'
          id: string
          lastViewedAt?: string | null
        }
      }>
    } | null
  }
}

export type CohortDashboardScheduledWeekTabPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  isCurrentWeek?: boolean | null
  numLessons?: number | null
  events: Array<{ __typename: 'Event'; id: string }>
}

export type CohortDashboardCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  shouldShowNpsSurvey?: boolean | null
  hasTeam?: boolean | null
  activeProgram?: {
    __typename: 'UserProgram'
    id: string
    progress?: ProgramProgressScalar | null
  } | null
}

export type CohortDashboardSeasonPartsFragment = {
  __typename: 'Season'
  name: string
  year: string
  npsSurveyLink?: string | null
  npsSurveyText?: string | null
}

export type CohortDashboardCohortPartsFragment = {
  __typename: 'Cohort'
  id: string
  numUniqCohortEvents: number
  isOngoing: boolean
  isUpcoming: boolean
  hasCohortOffboardingStarted: boolean
  snapshotReleased?: boolean | null
  shouldShowNpsSurvey?: boolean | null
  season: {
    __typename: 'Season'
    id: string
    name: string
    year: string
    npsSurveyLink?: string | null
    npsSurveyText?: string | null
  }
  scheduledWeeks: Array<{
    __typename: 'ScheduledWeek'
    id: string
    isCurrentWeek?: boolean | null
    numLessons?: number | null
    events: Array<{
      __typename: 'Event'
      id: string
      name: string
      willBeRecorded: boolean
      preread?: string | null
      startsAtUtc: string
      endsAtUtc: string
      attendingStatus?: string | null
      past: boolean
      summaryWistiaCode?: string | null
      slug: string
      kind: string
      uuid: string
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            companyName?: string | null
            fullName?: string | null
            avatarUrl: string
          }
        }
      }> | null
      caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
    }>
  }>
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    name: string
    byTheEnd?: string | null
    shortDescription: string
    templates?: {
      __typename: 'CmsModule'
      id: string
      name: string
      slug?: string | null
      href: string
      estimatedTime: number
      publishAt?: string | null
      published?: boolean | null
      project?: { __typename: 'CmsSection'; id: string } | null
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        slug: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        href: string
        publishAt?: string | null
        published?: boolean | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          estimatedTime: number
          publishAt?: string | null
          published?: boolean | null
          slug: string
          href: string
          contentType?: CmsSectionContentType | null
          heroImageUrl?: string | null
          tags?: Array<{
            __typename: 'CmsSectionTag'
            id: string
            tag: string
            cmsSectionId: string
          }> | null
        }>
      }>
    } | null
  }
}

export type CohortDashboardCmsProgramPartFragment = {
  __typename: 'CmsProgram'
  name: string
  byTheEnd?: string | null
  shortDescription: string
  templates?: {
    __typename: 'CmsModule'
    id: string
    name: string
    slug?: string | null
    href: string
    estimatedTime: number
    publishAt?: string | null
    published?: boolean | null
    project?: { __typename: 'CmsSection'; id: string } | null
    cmsSections: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      slug: string
      estimatedTime: number
      contentType?: CmsSectionContentType | null
      href: string
      publishAt?: string | null
      published?: boolean | null
      heroImageUrl?: string | null
      tags?: Array<{
        __typename: 'CmsSectionTag'
        id: string
        tag: string
        cmsSectionId: string
      }> | null
      children: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        estimatedTime: number
        publishAt?: string | null
        published?: boolean | null
        slug: string
        href: string
        contentType?: CmsSectionContentType | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
      }>
    }>
  } | null
}

export type CohortDashboardUserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  progress?: ProgramProgressScalar | null
}

export type ShowCohortDashboardSeasonPartsFragment = {
  __typename: 'Season'
  id: string
  showCohortViewerAt?: string | null
}

export type CohortDashboardHeaderScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  isCurrentWeek?: boolean | null
  orderedCmsModules: Array<{
    __typename: 'CmsModule'
    id: string
    name: string
    slug?: string | null
    href: string
    estimatedTime: number
    publishAt?: string | null
    published?: boolean | null
    project?: { __typename: 'CmsSection'; id: string } | null
    cmsSections: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      slug: string
      estimatedTime: number
      contentType?: CmsSectionContentType | null
      href: string
      publishAt?: string | null
      published?: boolean | null
      heroImageUrl?: string | null
      tags?: Array<{
        __typename: 'CmsSectionTag'
        id: string
        tag: string
        cmsSectionId: string
      }> | null
      children: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        estimatedTime: number
        publishAt?: string | null
        published?: boolean | null
        slug: string
        href: string
        contentType?: CmsSectionContentType | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
      }>
    }>
  }>
}

export type CohortDashboardQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortDashboardQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    hasTeam?: boolean | null
    activeProgram?: {
      __typename: 'UserProgram'
      id: string
      progress?: ProgramProgressScalar | null
    } | null
  } | null
  cohort: {
    __typename: 'Cohort'
    id: string
    numUniqCohortEvents: number
    isOngoing: boolean
    isUpcoming: boolean
    hasCohortOffboardingStarted: boolean
    snapshotReleased?: boolean | null
    shouldShowNpsSurvey?: boolean | null
    gettingStartedModuleForSeason?: {
      __typename: 'CmsModule'
      id: string
      name: string
      slug?: string | null
      href: string
      estimatedTime: number
      publishAt?: string | null
      published?: boolean | null
      project?: { __typename: 'CmsSection'; id: string } | null
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        slug: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        href: string
        publishAt?: string | null
        published?: boolean | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          estimatedTime: number
          publishAt?: string | null
          published?: boolean | null
          slug: string
          href: string
          contentType?: CmsSectionContentType | null
          heroImageUrl?: string | null
          tags?: Array<{
            __typename: 'CmsSectionTag'
            id: string
            tag: string
            cmsSectionId: string
          }> | null
        }>
      }>
    } | null
    season: {
      __typename: 'Season'
      id: string
      name: string
      year: string
      npsSurveyLink?: string | null
      npsSurveyText?: string | null
    }
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      isCurrentWeek?: boolean | null
      numLessons?: number | null
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        willBeRecorded: boolean
        preread?: string | null
        startsAtUtc: string
        endsAtUtc: string
        attendingStatus?: string | null
        past: boolean
        summaryWistiaCode?: string | null
        slug: string
        kind: string
        uuid: string
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              companyName?: string | null
              fullName?: string | null
              avatarUrl: string
            }
          }
        }> | null
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
      }>
    }>
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      byTheEnd?: string | null
      shortDescription: string
      templates?: {
        __typename: 'CmsModule'
        id: string
        name: string
        slug?: string | null
        href: string
        estimatedTime: number
        publishAt?: string | null
        published?: boolean | null
        project?: { __typename: 'CmsSection'; id: string } | null
        cmsSections: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          slug: string
          estimatedTime: number
          contentType?: CmsSectionContentType | null
          href: string
          publishAt?: string | null
          published?: boolean | null
          heroImageUrl?: string | null
          tags?: Array<{
            __typename: 'CmsSectionTag'
            id: string
            tag: string
            cmsSectionId: string
          }> | null
          children: Array<{
            __typename: 'CmsSection'
            id: string
            name: string
            estimatedTime: number
            publishAt?: string | null
            published?: boolean | null
            slug: string
            href: string
            contentType?: CmsSectionContentType | null
            heroImageUrl?: string | null
            tags?: Array<{
              __typename: 'CmsSectionTag'
              id: string
              tag: string
              cmsSectionId: string
            }> | null
          }>
        }>
      } | null
    }
  }
}

export type CohortDashboardHeaderQueryVariables = Exact<{
  weekId: Scalars['ID']
  cohortSlug: Scalars['String']
}>

export type CohortDashboardHeaderQuery = {
  __typename: 'Query'
  cohortDashboardScheduledWeek: {
    __typename: 'ScheduledWeek'
    id: string
    isCurrentWeek?: boolean | null
    orderedCmsModules: Array<{
      __typename: 'CmsModule'
      id: string
      name: string
      slug?: string | null
      href: string
      estimatedTime: number
      publishAt?: string | null
      published?: boolean | null
      project?: { __typename: 'CmsSection'; id: string } | null
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        slug: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        href: string
        publishAt?: string | null
        published?: boolean | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          estimatedTime: number
          publishAt?: string | null
          published?: boolean | null
          slug: string
          href: string
          contentType?: CmsSectionContentType | null
          heroImageUrl?: string | null
          tags?: Array<{
            __typename: 'CmsSectionTag'
            id: string
            tag: string
            cmsSectionId: string
          }> | null
        }>
      }>
    }>
  }
}

export type CohortDashboardHeaderUserQueryVariables = Exact<{
  seasonId: Scalars['ID']
  userId: Scalars['ID']
}>

export type CohortDashboardHeaderUserQuery = {
  __typename: 'Query'
  season: {
    __typename: 'Season'
    id: string
    startsAt?: string | null
    endsAt?: string | null
    prettyNameSeasonFirst: string
    showCohortViewerAt?: string | null
    faqs?: Array<{
      __typename: 'Faq'
      seasonId: string
      question: string
      answer: string
      questionNumber: number
    }> | null
  }
  user?: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
  } | null
}

export type CohortAboutProgramQueryQueryVariables = Exact<{
  cmsProgramId: Scalars['ID']
}>

export type CohortAboutProgramQueryQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    iconImageUrl?: string | null
    heroImageUrl?: string | null
    launched: boolean
    notLaunched?: boolean | null
    enrolled: boolean
    programImage?: string | null
    name: string
    recommendationRanking?: number | null
    whoItsFor?: string | null
    slug: string
    byTheEnd?: string | null
    synopsis?: string | null
    categoryNames?: Array<string> | null
    canEnroll: boolean
    launchAt?: string | null
    cohort?: {
      __typename: 'Cohort'
      id: string
      endsAt?: string | null
      startsAt?: string | null
      durationInWeeks?: number | null
      weekCount: number
      eventTimes?: Array<{
        __typename: 'CohortEventTimes'
        firstStartDate: string
        firstEndDate?: string | null
        count: number
      }> | null
      hosts?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      programCollaborators?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      scheduledWeeks: Array<{
        __typename: 'ScheduledWeek'
        id: string
        core?: boolean | null
        description?: string | null
        title?: string | null
        bonus?: boolean | null
        postCohort: boolean
        startsAt?: string | null
        endsAt?: string | null
        numLessons?: number | null
        numModules?: number | null
        events: Array<{
          __typename: 'Event'
          startsAtUtc: string
          endsAtUtc: string
          kind: string
        }>
      }>
    } | null
  } | null
}

export type CohortCollectionPartsFragment = {
  __typename: 'CohortBookmarkFolder'
  id: string
  name: string
  bookmarkFolderId: string
}

export type CohortCollectionsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortCollectionsQuery = {
  __typename: 'Query'
  cohort: {
    __typename: 'Cohort'
    id: string
    cohortCollections: Array<{
      __typename: 'CohortBookmarkFolder'
      id: string
      name: string
      bookmarkFolderId: string
    }>
  }
}

export type CohortDashboardNotificationsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortDashboardNotificationsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    cohortAllActivitiesWithMetadata: {
      __typename: 'CohortActivityListWithMetadata'
      unreadCount: number
      activities: Array<{
        __typename: 'CohortActivityListItem'
        actionType?: string | null
        activityIds: Array<string>
        latestActivityCreatedAt: string
        anyActivityUnread?: boolean | null
        agoTimephrase: string
        postBodyText: string
        postId: string
        postTopicSlug: string
        postTopicTitle: string
        reactions: Array<ReactionKind>
        replies: Array<string>
        unreadActivityIds?: Array<string> | null
        userAvatarUrl?: string | null
        userFullName?: string | null
      }>
    }
  } | null
}

export type CohortDashboardNotificationsBadgeQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortDashboardNotificationsBadgeQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    unseenCohortNotificationsCount: number
  } | null
}

export type CohortDashboardCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  slug: string
  estimatedTime: number
  contentType?: CmsSectionContentType | null
  href: string
  publishAt?: string | null
  published?: boolean | null
  heroImageUrl?: string | null
  tags?: Array<{
    __typename: 'CmsSectionTag'
    id: string
    tag: string
    cmsSectionId: string
  }> | null
  children: Array<{
    __typename: 'CmsSection'
    id: string
    name: string
    estimatedTime: number
    publishAt?: string | null
    published?: boolean | null
    slug: string
    href: string
    contentType?: CmsSectionContentType | null
    heroImageUrl?: string | null
    tags?: Array<{
      __typename: 'CmsSectionTag'
      id: string
      tag: string
      cmsSectionId: string
    }> | null
  }>
}

export type CohortCmsSectionTagPartsFragment = {
  __typename: 'CmsSectionTag'
  id: string
  tag: string
  cmsSectionId: string
}

export type CohortCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
  slug?: string | null
  href: string
  estimatedTime: number
  publishAt?: string | null
  published?: boolean | null
  project?: { __typename: 'CmsSection'; id: string } | null
  cmsSections: Array<{
    __typename: 'CmsSection'
    id: string
    name: string
    slug: string
    estimatedTime: number
    contentType?: CmsSectionContentType | null
    href: string
    publishAt?: string | null
    published?: boolean | null
    heroImageUrl?: string | null
    tags?: Array<{
      __typename: 'CmsSectionTag'
      id: string
      tag: string
      cmsSectionId: string
    }> | null
    children: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      estimatedTime: number
      publishAt?: string | null
      published?: boolean | null
      slug: string
      href: string
      contentType?: CmsSectionContentType | null
      heroImageUrl?: string | null
      tags?: Array<{
        __typename: 'CmsSectionTag'
        id: string
        tag: string
        cmsSectionId: string
      }> | null
    }>
  }>
}

export type CohortDashboardProjectPartsFragment = { __typename: 'CmsSection'; id: string }

export type CohortDashboardSectionCardCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
  published?: boolean | null
  publishAt?: string | null
  project?: { __typename: 'CmsSection'; id: string } | null
}

export type CohortDashboardScheduledWeekCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  hasTeam?: boolean | null
  activeProgram?: {
    __typename: 'UserProgram'
    id: string
    progress?: ProgramProgressScalar | null
  } | null
}

export type CohortDashboardScheduledWeekCohortPartsFragment = {
  __typename: 'Cohort'
  id: string
  slackChannelId?: string | null
  batchRsvpTimeData: Array<{
    __typename: 'BatchRsvpTimeData'
    ids: Array<string>
    startsAtUtc?: string | null
    endsAtUtc?: string | null
    timeGrouping?: string | null
  }>
  kickoffEvents: Array<{ __typename: 'Event'; id: string }>
  season: { __typename: 'Season'; id: string; name: string; year: string }
  cmsProgram: { __typename: 'CmsProgram'; name: string }
}

export type CohortDashboardScheduledWeekUserCohortPartsFragment = {
  __typename: 'UserCohort'
  id: string
  showEventRsvpModal: boolean
  hasSignedStatementOfCommitment: boolean
  hasClickedMeetCohortParticipantsCta: Array<string>
  timeGroupingSelected?: string | null
  cohort: {
    __typename: 'Cohort'
    id: string
    slackChannelId?: string | null
    batchRsvpTimeData: Array<{
      __typename: 'BatchRsvpTimeData'
      ids: Array<string>
      startsAtUtc?: string | null
      endsAtUtc?: string | null
      timeGrouping?: string | null
    }>
    kickoffEvents: Array<{ __typename: 'Event'; id: string }>
    season: { __typename: 'Season'; id: string; name: string; year: string }
    cmsProgram: { __typename: 'CmsProgram'; name: string }
  }
}

export type CohortDashboardScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  title?: string | null
  description?: string | null
  isCurrentWeek?: boolean | null
  numLessons?: number | null
  startsAt?: string | null
  endsAt?: string | null
  orderedCmsModules: Array<{
    __typename: 'CmsModule'
    id: string
    name: string
    slug?: string | null
    href: string
    estimatedTime: number
    published?: boolean | null
    publishAt?: string | null
    project?: { __typename: 'CmsSection'; id: string } | null
    cmsSections: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      slug: string
      estimatedTime: number
      contentType?: CmsSectionContentType | null
      href: string
      publishAt?: string | null
      published?: boolean | null
      heroImageUrl?: string | null
      tags?: Array<{
        __typename: 'CmsSectionTag'
        id: string
        tag: string
        cmsSectionId: string
      }> | null
      children: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        estimatedTime: number
        publishAt?: string | null
        published?: boolean | null
        slug: string
        href: string
        contentType?: CmsSectionContentType | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
      }>
    }>
  }>
  events: Array<{
    __typename: 'Event'
    id: string
    name: string
    willBeRecorded: boolean
    preread?: string | null
    startsAtUtc: string
    endsAtUtc: string
    attendingStatus?: string | null
    past: boolean
    summaryWistiaCode?: string | null
    slug: string
    kind: string
    uuid: string
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          companyName?: string | null
          fullName?: string | null
          avatarUrl: string
        }
      }
    }> | null
    caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
  }>
}

export type InterventionEventSameInfoPartsFragment = {
  __typename: 'Event'
  id: string
  startsAtUtc: string
  endsAtUtc: string
  attendingStatus?: string | null
  slug: string
  kind: string
  preread?: string | null
  uuid: string
}

export type CohortDashboardScheduledWeekQueryVariables = Exact<{
  cohortSlug: Scalars['String']
  weekId: Scalars['ID']
  interventionThreshold?: InputMaybe<Scalars['Int']>
}>

export type CohortDashboardScheduledWeekQuery = {
  __typename: 'Query'
  scheduledWeekProgressPercentHasReachedThresholdForIntervention?: boolean | null
  cohortDashboardScheduledWeek: {
    __typename: 'ScheduledWeek'
    id: string
    title?: string | null
    description?: string | null
    isCurrentWeek?: boolean | null
    numLessons?: number | null
    startsAt?: string | null
    endsAt?: string | null
    orderedCmsModules: Array<{
      __typename: 'CmsModule'
      id: string
      name: string
      slug?: string | null
      href: string
      estimatedTime: number
      published?: boolean | null
      publishAt?: string | null
      project?: { __typename: 'CmsSection'; id: string } | null
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        slug: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        href: string
        publishAt?: string | null
        published?: boolean | null
        heroImageUrl?: string | null
        tags?: Array<{
          __typename: 'CmsSectionTag'
          id: string
          tag: string
          cmsSectionId: string
        }> | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          estimatedTime: number
          publishAt?: string | null
          published?: boolean | null
          slug: string
          href: string
          contentType?: CmsSectionContentType | null
          heroImageUrl?: string | null
          tags?: Array<{
            __typename: 'CmsSectionTag'
            id: string
            tag: string
            cmsSectionId: string
          }> | null
        }>
      }>
    }>
    events: Array<{
      __typename: 'Event'
      id: string
      name: string
      willBeRecorded: boolean
      preread?: string | null
      startsAtUtc: string
      endsAtUtc: string
      attendingStatus?: string | null
      past: boolean
      summaryWistiaCode?: string | null
      slug: string
      kind: string
      uuid: string
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            companyName?: string | null
            fullName?: string | null
            avatarUrl: string
          }
        }
      }> | null
      caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
    }>
  }
}

export type CohortDashboardScheduledWeekChecklistQueryVariables = Exact<{
  slug: Scalars['String']
  topicSlug: Scalars['String']
  scheduledWeekId: Scalars['ID']
}>

export type CohortDashboardScheduledWeekChecklistQuery = {
  __typename: 'Query'
  bulkRsvpdAtForCohort?: string | null
  currentUserCohortPostsByTopicCount: number
  userCohort?: {
    __typename: 'UserCohort'
    id: string
    postedInConversationsTopicForScheduledWeek?: boolean | null
    showEventRsvpModal: boolean
    hasSignedStatementOfCommitment: boolean
    hasClickedMeetCohortParticipantsCta: Array<string>
    timeGroupingSelected?: string | null
    cohort: {
      __typename: 'Cohort'
      id: string
      slackChannelId?: string | null
      batchRsvpTimeData: Array<{
        __typename: 'BatchRsvpTimeData'
        ids: Array<string>
        startsAtUtc?: string | null
        endsAtUtc?: string | null
        timeGrouping?: string | null
      }>
      kickoffEvents: Array<{ __typename: 'Event'; id: string }>
      season: { __typename: 'Season'; id: string; name: string; year: string }
      cmsProgram: { __typename: 'CmsProgram'; name: string }
    }
  } | null
}

export type UnexpiredCohortCreditFragment = {
  __typename: 'CohortCredit'
  id: string
  expiresAt?: string | null
  status: string
  useable: boolean
  seat?: {
    __typename: 'Seat'
    id: string
    subscriptionMember?: { __typename: 'SubscriptionMember'; userId: string } | null
  } | null
}

export type CohortPassesListQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type CohortPassesListQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        cohortCredits: {
          __typename: 'CohortCredits'
          unexpired: Array<{
            __typename: 'CohortCredit'
            id: string
            expiresAt?: string | null
            status: string
            useable: boolean
            seat?: {
              __typename: 'Seat'
              id: string
              subscriptionMember?: {
                __typename: 'SubscriptionMember'
                userId: string
              } | null
            } | null
          }>
          expired: Array<{
            __typename: 'CohortCredit'
            id: string
            expiresAt?: string | null
            status: string
            useable: boolean
            seat?: {
              __typename: 'Seat'
              id: string
              subscriptionMember?: {
                __typename: 'SubscriptionMember'
                userId: string
              } | null
            } | null
          }>
        }
      } | null
    } | null
  } | null
}

export type CohortTeamWeekProgressUserPartsFragment = {
  __typename: 'User'
  id: string
  activeProgram?: {
    __typename: 'UserProgram'
    id: string
    progress?: ProgramProgressScalar | null
  } | null
  rsvpsInWeek: Array<{
    __typename: 'EventAttendee'
    id: string
    status: string
    eventId: string
  }>
  profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
}

export type CohortTeamWeekProgressEventAttendeePartsFragment = {
  __typename: 'EventAttendee'
  id: string
  status: string
  eventId: string
}

export type CohortTeamWeekProgressCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  estimatedTime: number
  contentType?: CmsSectionContentType | null
  slug: string
  href: string
}

export type CohortTeamWeekProgressCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
  estimatedTime: number
  slug?: string | null
  href: string
  project?: { __typename: 'CmsSection'; id: string } | null
  cmsSections: Array<{
    __typename: 'CmsSection'
    id: string
    name: string
    estimatedTime: number
    contentType?: CmsSectionContentType | null
    slug: string
    href: string
    children: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      estimatedTime: number
      contentType?: CmsSectionContentType | null
      slug: string
      href: string
    }>
  }>
}

export type CohortTeamWeekProgressEventPartsFragment = {
  __typename: 'Event'
  id: string
  slug: string
  kind: string
  name: string
}

export type CohortTeamWeekProgressScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  numLessons?: number | null
  startsAt?: string | null
  endsAt?: string | null
  orderedCmsModules: Array<{
    __typename: 'CmsModule'
    id: string
    name: string
    estimatedTime: number
    slug?: string | null
    href: string
    project?: { __typename: 'CmsSection'; id: string } | null
    cmsSections: Array<{
      __typename: 'CmsSection'
      id: string
      name: string
      estimatedTime: number
      contentType?: CmsSectionContentType | null
      slug: string
      href: string
      children: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        slug: string
        href: string
      }>
    }>
  }>
  events: Array<{
    __typename: 'Event'
    id: string
    slug: string
    kind: string
    name: string
  }>
}

export type CohortTeamActivitiesListItemPartsFragment = {
  __typename: 'CohortActivityListItem'
  actionType?: string | null
  activityIds: Array<string>
  latestActivityCreatedAt: string
  agoTimephrase: string
  postBodyText: string
  postId: string
  postTopicSlug: string
  postTopicTitle: string
  reactions: Array<ReactionKind>
  replies: Array<string>
  userAvatarUrl?: string | null
  userFullName?: string | null
}

export type CohortTeamWeekProgressQueryVariables = Exact<{
  cohortTeamId: Scalars['ID']
  cohortSlug: Scalars['String']
  weekId: Scalars['ID']
}>

export type CohortTeamWeekProgressQuery = {
  __typename: 'Query'
  cohortTeam?: {
    __typename: 'CohortTeam'
    id: string
    members: Array<{
      __typename: 'User'
      id: string
      activeProgram?: {
        __typename: 'UserProgram'
        id: string
        progress?: ProgramProgressScalar | null
      } | null
      rsvpsInWeek: Array<{
        __typename: 'EventAttendee'
        id: string
        status: string
        eventId: string
      }>
      profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
    }>
  } | null
  cohortDashboardScheduledWeek: {
    __typename: 'ScheduledWeek'
    numLessons?: number | null
    startsAt?: string | null
    endsAt?: string | null
    orderedCmsModules: Array<{
      __typename: 'CmsModule'
      id: string
      name: string
      estimatedTime: number
      slug?: string | null
      href: string
      project?: { __typename: 'CmsSection'; id: string } | null
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        name: string
        estimatedTime: number
        contentType?: CmsSectionContentType | null
        slug: string
        href: string
        children: Array<{
          __typename: 'CmsSection'
          id: string
          name: string
          estimatedTime: number
          contentType?: CmsSectionContentType | null
          slug: string
          href: string
        }>
      }>
    }>
    events: Array<{
      __typename: 'Event'
      id: string
      slug: string
      kind: string
      name: string
    }>
  }
}

export type CohortTeamActivitiesQueryVariables = Exact<{
  cohortTeamId: Scalars['ID']
}>

export type CohortTeamActivitiesQuery = {
  __typename: 'Query'
  cohortTeam?: {
    __typename: 'CohortTeam'
    id: string
    activities: Array<{
      __typename: 'CohortActivityListItem'
      actionType?: string | null
      activityIds: Array<string>
      latestActivityCreatedAt: string
      agoTimephrase: string
      postBodyText: string
      postId: string
      postTopicSlug: string
      postTopicTitle: string
      reactions: Array<ReactionKind>
      replies: Array<string>
      userAvatarUrl?: string | null
      userFullName?: string | null
    }>
  } | null
}

export type CohortUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type CohortUserQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    monthJoined?: string | null
    yearJoined?: string | null
    linkedin?: string | null
    twitter?: string | null
    slackUserId?: string | null
    profile: {
      __typename: 'UserProfile'
      kind?: string | null
      fullName?: string | null
      companyName?: string | null
      avatarUrl: string
      role?: string | null
      location?: string | null
      profileUrl?: string | null
      aboutMeText?: string | null
      askMeAboutText?: string | null
      learningGoalsText?: string | null
      companyProductText?: string | null
      communityInterestText?: string | null
      communityInterestTags?: string | null
      companyAudienceTags?: string | null
      businessModelTags?: string | null
      areasOfExpertiseTags?: string | null
      allowDirectMessages?: boolean | null
      previousCompanies?: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; name?: string | null }
      }> | null
    }
  } | null
}

export type CohortUserProfilePartsFragment = {
  __typename: 'UserProfile'
  kind?: string | null
  fullName?: string | null
  companyName?: string | null
  avatarUrl: string
  role?: string | null
  location?: string | null
  profileUrl?: string | null
  aboutMeText?: string | null
  askMeAboutText?: string | null
  learningGoalsText?: string | null
  companyProductText?: string | null
  communityInterestText?: string | null
  communityInterestTags?: string | null
  companyAudienceTags?: string | null
  businessModelTags?: string | null
  areasOfExpertiseTags?: string | null
  allowDirectMessages?: boolean | null
  previousCompanies?: Array<{
    __typename: 'UserCompany'
    id: string
    company: { __typename: 'Company'; name?: string | null }
  }> | null
}

export type CohortViewerOrderedCmsModulePartsFragment = {
  __typename: 'CmsModule'
  estimatedTime: number
  href: string
  id: string
  name: string
  numBookmarks?: number | null
  numLessons: number
  programCode: string
  progressPercent: number
  shortDescription?: string | null
  publishAt?: string | null
  published?: boolean | null
  programIconImageUrl?: string | null
  clips?: Array<{
    __typename: 'Clip'
    id: string
    title: string
    duration?: number | null
    slug: string
    thumbnailUrl: string
    summary?: string | null
    summaryWistiaCode: string
    hasBeenViewedByCurrentUser: boolean
    company?: {
      __typename: 'Company'
      id: string
      cardLogoUrl?: string | null
      name?: string | null
    } | null
    users: Array<{
      __typename: 'User'
      id: string
      avatarUrl: string
      fullName?: string | null
      role?: string | null
      companyName?: string | null
      profile: { __typename: 'UserProfile'; profileUrl?: string | null }
    }>
  }> | null
}

export type CohortViewerEventPartsFragment = {
  __typename: 'Event'
  attendingStatus?: string | null
  id: string
  name: string
  past: boolean
  slug: string
  startsAtUtc: string
  endsAtUtc: string
  willBeRecorded: boolean
  summaryWistiaCode?: string | null
  uuid: string
  kind: string
  caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
  speakers?: Array<{
    __typename: 'EventSpeaker'
    id: string
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        companyName?: string | null
        avatarUrl: string
      }
    }
  }> | null
}

export type CohortViewerScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  bonus?: boolean | null
  startsAt?: string | null
  endsAt?: string | null
  title?: string | null
  description?: string | null
  numLessons?: number | null
  estimatedTimeInMinutes?: number | null
  postCohort: boolean
  progressPercent?: number | null
  events: Array<{
    __typename: 'Event'
    id: string
    attendingStatus?: string | null
    name: string
    past: boolean
    slug: string
    startsAtUtc: string
    endsAtUtc: string
    willBeRecorded: boolean
    summaryWistiaCode?: string | null
    uuid: string
    kind: string
    caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
        }
      }
    }> | null
  }>
  orderedCmsModules: Array<{
    __typename: 'CmsModule'
    id: string
    estimatedTime: number
    href: string
    name: string
    numBookmarks?: number | null
    numLessons: number
    programCode: string
    progressPercent: number
    shortDescription?: string | null
    publishAt?: string | null
    published?: boolean | null
    programIconImageUrl?: string | null
    clips?: Array<{
      __typename: 'Clip'
      id: string
      title: string
      duration?: number | null
      slug: string
      thumbnailUrl: string
      summary?: string | null
      summaryWistiaCode: string
      hasBeenViewedByCurrentUser: boolean
      company?: {
        __typename: 'Company'
        id: string
        cardLogoUrl?: string | null
        name?: string | null
      } | null
      users: Array<{
        __typename: 'User'
        id: string
        avatarUrl: string
        fullName?: string | null
        role?: string | null
        companyName?: string | null
        profile: { __typename: 'UserProfile'; profileUrl?: string | null }
      }>
    }> | null
  }>
}

export type CohortViewerPartsFragment = {
  __typename: 'UserCohort'
  id: string
  hasPostedWelcome: boolean
  hasSignedStatementOfCommitment: boolean
  hasDismissedNewContentEirMessage: boolean
  showEventRsvpModal: boolean
  timeGroupingSelected?: string | null
  hasDismissedPostCohortEirMessage: boolean
  hasClickedMeetCohortParticipantsCta: Array<string>
  hasClickedShareSnapshotCta: boolean
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
  cohort: {
    __typename: 'Cohort'
    id: string
    slackChannelId?: string | null
    startsAt?: string | null
    shouldShowNpsSurvey?: boolean | null
    kickoffEvents: Array<{ __typename: 'Event'; id: string }>
    batchRsvpTimeData: Array<{
      __typename: 'BatchRsvpTimeData'
      ids: Array<string>
      startsAtUtc?: string | null
      endsAtUtc?: string | null
      timeGrouping?: string | null
    }>
    season: {
      __typename: 'Season'
      id: string
      startsAt?: string | null
      endsAt?: string | null
      name: string
      year: string
      npsSurveyLink?: string | null
      showCohortViewerAt?: string | null
    }
    hosts?: Array<{
      __typename: 'Host'
      id: string
      userId?: string | null
      name?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
      title?: string | null
    }> | null
    programCollaborators?: Array<{
      __typename: 'Host'
      id: string
      userId?: string | null
      name?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
      title?: string | null
    }> | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      code?: string | null
      name: string
      notLaunched?: boolean | null
      iconImageUrl?: string | null
      heroImageUrl?: string | null
      releaseStartDate?: string | null
    }
    events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      bonus?: boolean | null
      startsAt?: string | null
      endsAt?: string | null
      title?: string | null
      description?: string | null
      numLessons?: number | null
      estimatedTimeInMinutes?: number | null
      postCohort: boolean
      progressPercent?: number | null
      events: Array<{
        __typename: 'Event'
        id: string
        attendingStatus?: string | null
        name: string
        past: boolean
        slug: string
        startsAtUtc: string
        endsAtUtc: string
        willBeRecorded: boolean
        summaryWistiaCode?: string | null
        uuid: string
        kind: string
        caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
            }
          }
        }> | null
      }>
      orderedCmsModules: Array<{
        __typename: 'CmsModule'
        id: string
        estimatedTime: number
        href: string
        name: string
        numBookmarks?: number | null
        numLessons: number
        programCode: string
        progressPercent: number
        shortDescription?: string | null
        publishAt?: string | null
        published?: boolean | null
        programIconImageUrl?: string | null
        clips?: Array<{
          __typename: 'Clip'
          id: string
          title: string
          duration?: number | null
          slug: string
          thumbnailUrl: string
          summary?: string | null
          summaryWistiaCode: string
          hasBeenViewedByCurrentUser: boolean
          company?: {
            __typename: 'Company'
            id: string
            cardLogoUrl?: string | null
            name?: string | null
          } | null
          users: Array<{
            __typename: 'User'
            id: string
            avatarUrl: string
            fullName?: string | null
            role?: string | null
            companyName?: string | null
            profile: { __typename: 'UserProfile'; profileUrl?: string | null }
          }>
        }> | null
      }>
    }>
  }
}

export type CohortViewerNewCohortTeamPartsFragment = {
  __typename: 'CohortTeam'
  id: string
  name: string
}

export type CohortViewerNewScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  isCurrentWeek?: boolean | null
  numLessons?: number | null
  events: Array<{ __typename: 'Event'; id: string }>
}

export type CohortViewerNewCohortPartsFragment = {
  __typename: 'Cohort'
  id: string
  name: string
  slug: string
  isOngoing: boolean
  isUpcoming: boolean
  hasCohortOffboardingStarted: boolean
  slackChannelId?: string | null
  shouldShowNpsSurvey?: boolean | null
  scheduledWeeks: Array<{
    __typename: 'ScheduledWeek'
    id: string
    isCurrentWeek?: boolean | null
    numLessons?: number | null
    events: Array<{ __typename: 'Event'; id: string }>
  }>
  season: {
    __typename: 'Season'
    id: string
    name: string
    year: string
    npsSurveyLink?: string | null
    npsSurveyText?: string | null
  }
  cmsProgram: { __typename: 'CmsProgram'; id: string; name: string; slug: string }
  group?: { __typename: 'Group'; id: string } | null
}

export type CohortViewerQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortViewerQuery = {
  __typename: 'Query'
  userCohort?: {
    __typename: 'UserCohort'
    id: string
    hasPostedWelcome: boolean
    hasSignedStatementOfCommitment: boolean
    hasDismissedNewContentEirMessage: boolean
    showEventRsvpModal: boolean
    timeGroupingSelected?: string | null
    hasDismissedPostCohortEirMessage: boolean
    hasClickedMeetCohortParticipantsCta: Array<string>
    hasClickedShareSnapshotCta: boolean
    snapshot?: {
      __typename: 'Snapshot'
      id: string
      token: string
      released: boolean
    } | null
    cohort: {
      __typename: 'Cohort'
      id: string
      slackChannelId?: string | null
      startsAt?: string | null
      shouldShowNpsSurvey?: boolean | null
      kickoffEvents: Array<{ __typename: 'Event'; id: string }>
      batchRsvpTimeData: Array<{
        __typename: 'BatchRsvpTimeData'
        ids: Array<string>
        startsAtUtc?: string | null
        endsAtUtc?: string | null
        timeGrouping?: string | null
      }>
      season: {
        __typename: 'Season'
        id: string
        startsAt?: string | null
        endsAt?: string | null
        name: string
        year: string
        npsSurveyLink?: string | null
        showCohortViewerAt?: string | null
      }
      hosts?: Array<{
        __typename: 'Host'
        id: string
        userId?: string | null
        name?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
        title?: string | null
      }> | null
      programCollaborators?: Array<{
        __typename: 'Host'
        id: string
        userId?: string | null
        name?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
        title?: string | null
      }> | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        code?: string | null
        name: string
        notLaunched?: boolean | null
        iconImageUrl?: string | null
        heroImageUrl?: string | null
        releaseStartDate?: string | null
      }
      events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
      scheduledWeeks: Array<{
        __typename: 'ScheduledWeek'
        id: string
        bonus?: boolean | null
        startsAt?: string | null
        endsAt?: string | null
        title?: string | null
        description?: string | null
        numLessons?: number | null
        estimatedTimeInMinutes?: number | null
        postCohort: boolean
        progressPercent?: number | null
        events: Array<{
          __typename: 'Event'
          id: string
          attendingStatus?: string | null
          name: string
          past: boolean
          slug: string
          startsAtUtc: string
          endsAtUtc: string
          willBeRecorded: boolean
          summaryWistiaCode?: string | null
          uuid: string
          kind: string
          caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
          speakers?: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
              }
            }
          }> | null
        }>
        orderedCmsModules: Array<{
          __typename: 'CmsModule'
          id: string
          estimatedTime: number
          href: string
          name: string
          numBookmarks?: number | null
          numLessons: number
          programCode: string
          progressPercent: number
          shortDescription?: string | null
          publishAt?: string | null
          published?: boolean | null
          programIconImageUrl?: string | null
          clips?: Array<{
            __typename: 'Clip'
            id: string
            title: string
            duration?: number | null
            slug: string
            thumbnailUrl: string
            summary?: string | null
            summaryWistiaCode: string
            hasBeenViewedByCurrentUser: boolean
            company?: {
              __typename: 'Company'
              id: string
              cardLogoUrl?: string | null
              name?: string | null
            } | null
            users: Array<{
              __typename: 'User'
              id: string
              avatarUrl: string
              fullName?: string | null
              role?: string | null
              companyName?: string | null
              profile: { __typename: 'UserProfile'; profileUrl?: string | null }
            }>
          }> | null
        }>
      }>
    }
  } | null
}

export type ContentFeedbackMutationVariables = Exact<{
  input: FeedbacksInput
}>

export type ContentFeedbackMutation = {
  __typename: 'Mutation'
  feedbacks?: { __typename: 'FeedbacksPayload'; feedbackId?: string | null } | null
}

export type CohortViewerNewQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortViewerNewQuery = {
  __typename: 'Query'
  cohort: {
    __typename: 'Cohort'
    id: string
    name: string
    slug: string
    isOngoing: boolean
    isUpcoming: boolean
    hasCohortOffboardingStarted: boolean
    slackChannelId?: string | null
    shouldShowNpsSurvey?: boolean | null
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      isCurrentWeek?: boolean | null
      numLessons?: number | null
      events: Array<{ __typename: 'Event'; id: string }>
    }>
    season: {
      __typename: 'Season'
      id: string
      name: string
      year: string
      npsSurveyLink?: string | null
      npsSurveyText?: string | null
    }
    cmsProgram: { __typename: 'CmsProgram'; id: string; name: string; slug: string }
    group?: { __typename: 'Group'; id: string } | null
  }
  userCohort?: {
    __typename: 'UserCohort'
    id: string
    user: { __typename: 'User'; id: string; shouldShowNpsSurvey?: boolean | null }
  } | null
  cohortTeams: Array<{ __typename: 'CohortTeam'; id: string; name: string }>
}

export type CohortViewerAccessQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CohortViewerAccessQuery = {
  __typename: 'Query'
  cohort: {
    __typename: 'Cohort'
    id: string
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }
}

export type CohortViewerMaterialQueryVariables = Exact<{
  programId: Scalars['ID']
}>

export type CohortViewerMaterialQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    launched: boolean
    cmsModules: Array<{
      __typename: 'CmsModule'
      id: string
      estimatedTime: number
      published?: boolean | null
      name: string
      slug?: string | null
      publishAt?: string | null
      anyCountsTowardsCompletion?: boolean | null
      containsPreviewableLessons: boolean
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        previewable: boolean
        name: string
        estimatedTime: number
        slug: string
        href: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        heroImageUrl?: string | null
        shortDescription?: string | null
        conceptIcon?: string | null
        cmsModuleId: string
        cmsProgramId: string
        numBookmarks?: number | null
        numPhases?: number | null
        showNewBadge: boolean
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        unlimitedAccess: boolean
        lessonNum?: number | null
        accessLevel?: string | null
        lastViewedAt?: string | null
        contentSubtype?: string | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          parentId?: string | null
          name: string
          estimatedTime: number
          slug: string
          href: string
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          heroImageUrl?: string | null
          shortDescription?: string | null
          previewable: boolean
          conceptIcon?: string | null
          cmsModuleId: string
          cmsProgramId: string
          numBookmarks?: number | null
          numPhases?: number | null
          showNewBadge: boolean
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          unlimitedAccess: boolean
          lessonNum?: number | null
          accessLevel?: string | null
          lastViewedAt?: string | null
          contentSubtype?: string | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            programImage?: string | null
            notLaunched?: boolean | null
            name: string
            enrolled: boolean
            slug: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
          cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
          parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
        }>
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          programImage?: string | null
          notLaunched?: boolean | null
          name: string
          enrolled: boolean
          slug: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
      }>
      project?: {
        __typename: 'CmsSection'
        id: string
        cmsProgramId: string
        cmsModuleId: string
        name: string
        estimatedTime: number
        slug: string
        href: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        heroImageUrl?: string | null
        shortDescription?: string | null
        previewable: boolean
        conceptIcon?: string | null
        numBookmarks?: number | null
        numPhases?: number | null
        showNewBadge: boolean
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        unlimitedAccess: boolean
        lessonNum?: number | null
        accessLevel?: string | null
        lastViewedAt?: string | null
        contentSubtype?: string | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          programImage?: string | null
          notLaunched?: boolean | null
          name: string
          enrolled: boolean
          slug: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
      } | null
    }>
  } | null
  currentUser?: {
    __typename: 'User'
    id: string
    numModuleBookmarks?: any | null
    numSectionBookmarks?: any | null
    programProgress?: {
      __typename: 'ProgramProgress'
      progress: ProgramProgressScalar
    } | null
  } | null
}

export type CohortViewerMaterialSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  estimatedTime: number
  slug: string
  href: string
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  heroImageUrl?: string | null
  shortDescription?: string | null
  previewable: boolean
  conceptIcon?: string | null
  cmsModuleId: string
  cmsProgramId: string
  numBookmarks?: number | null
  numPhases?: number | null
  showNewBadge: boolean
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  unlimitedAccess: boolean
  lessonNum?: number | null
  accessLevel?: string | null
  lastViewedAt?: string | null
  contentSubtype?: string | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    name: string
    enrolled: boolean
    slug: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type CohortDashboardLessonPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  estimatedTime: number
  publishAt?: string | null
  published?: boolean | null
  slug: string
  href: string
  contentType?: CmsSectionContentType | null
  heroImageUrl?: string | null
  tags?: Array<{
    __typename: 'CmsSectionTag'
    id: string
    tag: string
    cmsSectionId: string
  }> | null
}

export type CohortViewerMaterialParentSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  previewable: boolean
  name: string
  estimatedTime: number
  slug: string
  href: string
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  heroImageUrl?: string | null
  shortDescription?: string | null
  conceptIcon?: string | null
  cmsModuleId: string
  cmsProgramId: string
  numBookmarks?: number | null
  numPhases?: number | null
  showNewBadge: boolean
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  unlimitedAccess: boolean
  lessonNum?: number | null
  accessLevel?: string | null
  lastViewedAt?: string | null
  contentSubtype?: string | null
  children: Array<{
    __typename: 'CmsSection'
    id: string
    parentId?: string | null
    name: string
    estimatedTime: number
    slug: string
    href: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    heroImageUrl?: string | null
    shortDescription?: string | null
    previewable: boolean
    conceptIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    lessonNum?: number | null
    accessLevel?: string | null
    lastViewedAt?: string | null
    contentSubtype?: string | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }>
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    name: string
    enrolled: boolean
    slug: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type CohortViewerMaterialCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  estimatedTime: number
  published?: boolean | null
  name: string
  slug?: string | null
  publishAt?: string | null
  anyCountsTowardsCompletion?: boolean | null
  containsPreviewableLessons: boolean
  cmsSections: Array<{
    __typename: 'CmsSection'
    id: string
    previewable: boolean
    name: string
    estimatedTime: number
    slug: string
    href: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    heroImageUrl?: string | null
    shortDescription?: string | null
    conceptIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    lessonNum?: number | null
    accessLevel?: string | null
    lastViewedAt?: string | null
    contentSubtype?: string | null
    children: Array<{
      __typename: 'CmsSection'
      id: string
      parentId?: string | null
      name: string
      estimatedTime: number
      slug: string
      href: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      heroImageUrl?: string | null
      shortDescription?: string | null
      previewable: boolean
      conceptIcon?: string | null
      cmsModuleId: string
      cmsProgramId: string
      numBookmarks?: number | null
      numPhases?: number | null
      showNewBadge: boolean
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      unlimitedAccess: boolean
      lessonNum?: number | null
      accessLevel?: string | null
      lastViewedAt?: string | null
      contentSubtype?: string | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        programImage?: string | null
        notLaunched?: boolean | null
        name: string
        enrolled: boolean
        slug: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
      parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
    }>
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }>
  project?: {
    __typename: 'CmsSection'
    id: string
    cmsProgramId: string
    cmsModuleId: string
    name: string
    estimatedTime: number
    slug: string
    href: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    heroImageUrl?: string | null
    shortDescription?: string | null
    previewable: boolean
    conceptIcon?: string | null
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    lessonNum?: number | null
    accessLevel?: string | null
    lastViewedAt?: string | null
    contentSubtype?: string | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  } | null
}

export type CollectionActivityPartsFragment = {
  __typename: 'CollectionActivity'
  id: string
  actionType: string
  actionText: string
  bookmarkFolderId?: string | null
  hasRestoreAction: boolean
  createdAtInWords: string
  filedBookmark?: { __typename: 'FiledBookmark'; id: string; bookmarkId: string } | null
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    email: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }
}

export type CollectionActivityQueryVariables = Exact<{
  collectionId: Scalars['ID']
  page?: InputMaybe<Scalars['Int']>
}>

export type CollectionActivityQuery = {
  __typename: 'Query'
  collectionActivity: {
    __typename: 'CollectionActivityList'
    totalCount: number
    collectionActivities: Array<{
      __typename: 'CollectionActivity'
      id: string
      actionType: string
      actionText: string
      bookmarkFolderId?: string | null
      hasRestoreAction: boolean
      createdAtInWords: string
      filedBookmark?: {
        __typename: 'FiledBookmark'
        id: string
        bookmarkId: string
      } | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        email: string
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
    }>
  }
}

export type MostRecentCollectionActivityPerUserQueryVariables = Exact<{
  collectionId: Scalars['ID']
  userIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type MostRecentCollectionActivityPerUserQuery = {
  __typename: 'Query'
  mostRecentCollectionActivityPerUser: Array<{
    __typename: 'CollectionActivity'
    id: string
    actionType: string
    actionText: string
    bookmarkFolderId?: string | null
    hasRestoreAction: boolean
    createdAtInWords: string
    filedBookmark?: { __typename: 'FiledBookmark'; id: string; bookmarkId: string } | null
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      email: string
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
  }>
}

export type CompletedFeedbackQueryVariables = Exact<{ [key: string]: never }>

export type CompletedFeedbackQuery = {
  __typename: 'Query'
  completedFeedback: { __typename: 'CompletedFeedback'; postApplySurvey: boolean }
}

export type CompletedProductToursQueryVariables = Exact<{ [key: string]: never }>

export type CompletedProductToursQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    is: { __typename: 'UserIs'; member: boolean; paidMember: boolean }
    completedProductTours?: {
      __typename: 'ProductTour'
      bookmarkCreate?: boolean | null
      collectionSaveBookmark?: boolean | null
      collectionShare?: boolean | null
      collectionsTab?: boolean | null
      collectionViewContent?: boolean | null
      savedItemsTab?: boolean | null
      searchTrainerViews?: number | null
      teamCommentView?: boolean | null
      newSearchClicked?: boolean | null
      usedReforgeAiGlobalEntryPoint?: boolean | null
      startDraftFromButton?: boolean | null
    } | null
  } | null
}

export type ConceptCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  shouldSeeTrialMessaging: boolean
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    enrolled?: boolean | null
    progress?: ProgramProgressScalar | null
  } | null
  can: {
    __typename: 'UserCan'
    viewConceptsIndex?: boolean | null
    viewPurchaseFlow?: boolean | null
  }
  is: { __typename: 'UserIs'; premember: boolean; member: boolean }
}

export type ConceptCardSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  estimatedTime: number
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  heroImageUrl?: string | null
  href: string
  name: string
  numPhases?: number | null
  projectIcon?: string | null
  shortDescription?: string | null
  numBookmarks?: number | null
  showNewBadge: boolean
  slug: string
  accessLevel?: string | null
  previewable: boolean
  lastViewedAt?: string | null
  contentSubtype?: string | null
  lessonNum?: number | null
  topic?: { __typename: 'PostTopic'; id: string; title: string } | null
  cmsModule?: {
    __typename: 'CmsModule'
    id: string
    slug?: string | null
    name: string
  } | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    notLaunched?: boolean | null
    enrolled: boolean
    slug: string
    name: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
}

export type ConceptCardContentPartsFragment = {
  __typename: 'Content'
  accessLevel?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  showNewBadge?: boolean | null
  cmsSection: {
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    numPhases?: number | null
    projectIcon?: string | null
    shortDescription?: string | null
    numBookmarks?: number | null
    showNewBadge: boolean
    slug: string
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    lessonNum?: number | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      slug?: string | null
      name: string
    } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  }
}

export type ConceptsFeedQueryVariables = Exact<{
  filters?: InputMaybe<ContentFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type ConceptsFeedQuery = {
  __typename: 'Query'
  concepts?: {
    __typename: 'ContentFeed'
    count: number
    cmsModuleId?: string | null
    topics?: Array<string> | null
    content: Array<{
      __typename: 'Content'
      accessLevel?: string | null
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      showNewBadge?: boolean | null
      cmsSection: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      }
    }>
    userProgram?: {
      __typename: 'UserProgram'
      id: string
      enrolled?: boolean | null
      progress?: ProgramProgressScalar | null
    } | null
  } | null
}

export type ExpertUserPartsFragment = {
  __typename: 'User'
  id: string
  hasBadge: boolean
  avatarUrl: string
  fullName?: string | null
  companyName?: string | null
  role?: string | null
  kind?: string | null
}

export type ContentBookmarkAnchorPartsFragment = {
  __typename: 'Bookmark'
  id: string
  anchor?: string | null
}

export type BaseContentTocItemPartsFragment = {
  __typename: 'ContentTocItem'
  id: string
  name?: string | null
  href?: string | null
  current?: boolean | null
  contentTypeIcon?: string | null
  estimatedTime?: string | null
  isSidebar?: boolean | null
  numBookmarks?: number | null
  phaseNumber?: number | null
  parentId?: string | null
}

export type ContentTocItemPartsFragment = {
  __typename: 'ContentTocItem'
  id: string
  name?: string | null
  href?: string | null
  current?: boolean | null
  contentTypeIcon?: string | null
  estimatedTime?: string | null
  isSidebar?: boolean | null
  numBookmarks?: number | null
  phaseNumber?: number | null
  parentId?: string | null
  children?: Array<{
    __typename: 'ContentTocItem'
    id: string
    name?: string | null
    href?: string | null
    current?: boolean | null
    contentTypeIcon?: string | null
    estimatedTime?: string | null
    isSidebar?: boolean | null
    numBookmarks?: number | null
    phaseNumber?: number | null
    parentId?: string | null
  }> | null
}

export type ContentViewerTocPartsFragment = {
  __typename: 'ContentViewer'
  contentBookmarkAnchors: Array<{
    __typename: 'Bookmark'
    id: string
    anchor?: string | null
  }>
  contentBookmarkAnchorsInMySavedItems: Array<{
    __typename: 'Bookmark'
    id: string
    anchor?: string | null
  }>
  cmsProjectDeliverable?: {
    __typename: 'ProjectDeliverable'
    id: string
    url?: string | null
    templateUrl?: string | null
  } | null
  expertUsers?: Array<{
    __typename: 'User'
    id: string
    hasBadge: boolean
    avatarUrl: string
    fullName?: string | null
    companyName?: string | null
    role?: string | null
    kind?: string | null
  }> | null
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    progress?: ProgramProgressScalar | null
  } | null
  cmsModule?: { __typename: 'CmsModule'; id: string } | null
  cmsContent?: {
    __typename: 'CmsSection'
    id: string
    name: string
    cmsProgramId: string
    cmsModuleId: string
    contentType?: CmsSectionContentType | null
    contentSubtype?: string | null
    toc: Array<{
      __typename: 'ContentTocItem'
      id: string
      name?: string | null
      href?: string | null
      current?: boolean | null
      contentTypeIcon?: string | null
      estimatedTime?: string | null
      isSidebar?: boolean | null
      numBookmarks?: number | null
      phaseNumber?: number | null
      parentId?: string | null
      children?: Array<{
        __typename: 'ContentTocItem'
        id: string
        name?: string | null
        href?: string | null
        current?: boolean | null
        contentTypeIcon?: string | null
        estimatedTime?: string | null
        isSidebar?: boolean | null
        numBookmarks?: number | null
        phaseNumber?: number | null
        parentId?: string | null
      }> | null
    }>
    cmsProgram: { __typename: 'CmsProgram'; id: string; name: string }
  } | null
}

export type ContentViewerExpertsPartsFragment = {
  __typename: 'ContentViewer'
  expertUsers?: Array<{
    __typename: 'User'
    id: string
    hasBadge: boolean
    avatarUrl: string
    fullName?: string | null
    companyName?: string | null
    role?: string | null
    kind?: string | null
  }> | null
}

export type HeaderUserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  kind: string
  participatingKinds: Array<ProgramParticipatingKinds>
}

export type HeaderCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  contentType?: CmsSectionContentType | null
  position?: number | null
  slug: string
  contentTypeIcon?: string | null
  descriptionText?: string | null
  heroImageUrl?: string | null
  shortDescription?: string | null
  estimatedTime: number
}

export type HeaderCmsProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  name: string
}

export type ContentViewerHeaderPartsFragment = {
  __typename: 'ContentViewer'
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    kind: string
    participatingKinds: Array<ProgramParticipatingKinds>
  } | null
  cmsContent?: {
    __typename: 'CmsSection'
    id: string
    href: string
    showNewBadge: boolean
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    contentType?: CmsSectionContentType | null
    name: string
    position?: number | null
    slug: string
    contentTypeIcon?: string | null
    descriptionText?: string | null
    heroImageUrl?: string | null
    shortDescription?: string | null
    estimatedTime: number
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      enrolled: boolean
      slug: string
      name: string
      progressPercent: number
    }
  } | null
  cmsModule?: { __typename: 'CmsModule'; id: string } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    slug: string
    progressPercent: number
    name: string
  } | null
}

export type ContentViewerContentPartsFragment = {
  __typename: 'ContentViewer'
  cmsContent?: {
    __typename: 'CmsSection'
    id: string
    truncatedDescriptionHtml?: string | null
    blockedContentHtmlSafe?: string | null
    accessLevel?: string | null
  } | null
}

export type ContentViewerPartialContentPartsFragment = {
  __typename: 'ContentViewer'
  cmsContent?: {
    __typename: 'CmsSection'
    id: string
    name: string
    truncatedDescriptionHtml?: string | null
    accessLevel?: string | null
  } | null
}

export type RelatedContentPartsFragment = {
  __typename: 'CmsSection'
  id: string
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  heroImageUrl?: string | null
  conceptIcon?: string | null
  contentTypeIcon?: string | null
  name: string
  shortDescription?: string | null
  estimatedTime: number
  numBookmarks?: number | null
  cmsModuleId: string
  cmsProgramId: string
  showNewBadge: boolean
  lessonNum?: number | null
  numPhases?: number | null
  accessLevel?: string | null
  slug: string
  previewable: boolean
  href: string
  contentType?: CmsSectionContentType | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    enrolled: boolean
    name: string
    slug: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type DeliverableExamplePartsFragment = {
  __typename: 'ProjectDeliverable'
  id: string
  name?: string | null
  title?: string | null
  url?: string | null
  company?: {
    __typename: 'Company'
    id: string
    name?: string | null
    cardLogoUrl?: string | null
  } | null
}

export type ContentViewerDeliverablePartsFragment = {
  __typename: 'ContentViewer'
  cmsProjectDeliverable?: {
    __typename: 'ProjectDeliverable'
    id: string
    name?: string | null
    title?: string | null
    url?: string | null
    templateUrl?: string | null
  } | null
  cmsContent?: {
    __typename: 'CmsSection'
    id: string
    deliverableExamples: Array<{
      __typename: 'ProjectDeliverable'
      id: string
      name?: string | null
      title?: string | null
      url?: string | null
      company?: {
        __typename: 'Company'
        id: string
        name?: string | null
        cardLogoUrl?: string | null
      } | null
    }>
  } | null
}

export type ContentViewerQueryVariables = Exact<{
  cmsType: Scalars['String']
  slug: Scalars['String']
  path: Scalars['String']
}>

export type ContentViewerQuery = {
  __typename: 'Query'
  currentUser?: { __typename: 'User'; id: string } | null
  contentViewer?: {
    __typename: 'ContentViewer'
    viewAccess: string
    cmsModule?: { __typename: 'CmsModule'; id: string; showFeedback: boolean } | null
    cmsContent?: {
      __typename: 'CmsSection'
      id: string
      accessLevel?: string | null
      showFeedback: boolean
      currentUserSubmittedFeedback: boolean
      introHtmlSafe?: string | null
      summaryHtmlSafe?: string | null
      previewable: boolean
      unlimitedAccess: boolean
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      truncatedDescriptionHtml?: string | null
      blockedContentHtmlSafe?: string | null
      name: string
      href: string
      showNewBadge: boolean
      contentType?: CmsSectionContentType | null
      cmsProgramId: string
      cmsModuleId: string
      contentSubtype?: string | null
      position?: number | null
      slug: string
      contentTypeIcon?: string | null
      descriptionText?: string | null
      heroImageUrl?: string | null
      shortDescription?: string | null
      estimatedTime: number
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      groups: {
        __typename: 'GroupConnection'
        edges: Array<{
          __typename: 'GroupEdge'
          node: { __typename: 'Group'; id: string }
        }>
      }
      relatedContent?: Array<{
        __typename: 'CmsSection'
        slug: string
        previewable: boolean
        id: string
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        conceptIcon?: string | null
        contentTypeIcon?: string | null
        name: string
        shortDescription?: string | null
        estimatedTime: number
        numBookmarks?: number | null
        cmsModuleId: string
        cmsProgramId: string
        showNewBadge: boolean
        lessonNum?: number | null
        numPhases?: number | null
        accessLevel?: string | null
        href: string
        contentType?: CmsSectionContentType | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          programImage?: string | null
          notLaunched?: boolean | null
          enrolled: boolean
          name: string
          slug: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
      }> | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        programImage?: string | null
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        progressPercent: number
      }
      toc: Array<{
        __typename: 'ContentTocItem'
        id: string
        name?: string | null
        href?: string | null
        current?: boolean | null
        contentTypeIcon?: string | null
        estimatedTime?: string | null
        isSidebar?: boolean | null
        numBookmarks?: number | null
        phaseNumber?: number | null
        parentId?: string | null
        children?: Array<{
          __typename: 'ContentTocItem'
          id: string
          name?: string | null
          href?: string | null
          current?: boolean | null
          contentTypeIcon?: string | null
          estimatedTime?: string | null
          isSidebar?: boolean | null
          numBookmarks?: number | null
          phaseNumber?: number | null
          parentId?: string | null
        }> | null
      }>
      deliverableExamples: Array<{
        __typename: 'ProjectDeliverable'
        id: string
        name?: string | null
        title?: string | null
        url?: string | null
        company?: {
          __typename: 'Company'
          id: string
          name?: string | null
          cardLogoUrl?: string | null
        } | null
      }>
    } | null
    userProgram?: {
      __typename: 'UserProgram'
      id: string
      progress?: ProgramProgressScalar | null
      kind: string
      participatingKinds: Array<ProgramParticipatingKinds>
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      slug: string
      progressPercent: number
      name: string
    } | null
    expertUsers?: Array<{
      __typename: 'User'
      id: string
      hasBadge: boolean
      avatarUrl: string
      fullName?: string | null
      companyName?: string | null
      role?: string | null
      kind?: string | null
    }> | null
    contentBookmarkAnchors: Array<{
      __typename: 'Bookmark'
      id: string
      anchor?: string | null
    }>
    contentBookmarkAnchorsInMySavedItems: Array<{
      __typename: 'Bookmark'
      id: string
      anchor?: string | null
    }>
    cmsProjectDeliverable?: {
      __typename: 'ProjectDeliverable'
      id: string
      url?: string | null
      templateUrl?: string | null
      name?: string | null
      title?: string | null
    } | null
  } | null
}

export type CourseBlocksQueryVariables = Exact<{
  slug: Scalars['String']
  courseSessionId: Scalars['ID']
}>

export type CourseBlocksQuery = {
  __typename: 'Query'
  courseBlocks: {
    __typename: 'Course'
    courseBlocks?: Array<{
      __typename: 'CourseBlock'
      events?: Array<{
        __typename: 'CourseEvent'
        title?: string | null
        sanityId?: string | null
        shortDescription?: string | null
        eventPerCourseSession?: Array<{
          __typename: 'EventPerCourseSession'
          startsAt?: string | null
          endsAt?: string | null
          joinLink?: string | null
        }> | null
      }> | null
    }> | null
  }
}

export type CourseCheckoutPageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseCheckoutPageQuery = {
  __typename: 'Query'
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    heroImageUrl?: string | null
    testimonials?: Array<{
      __typename: 'CclReview'
      id: string
      title?: string | null
      subTitle?: string | null
      quote?: string | null
      company?: {
        __typename: 'CclCompany'
        id: string
        name: string
        logoUrl?: string | null
        wordmarkLogoUrl?: string | null
      } | null
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
  } | null
  upcomingCourseSessions?: Array<{
    __typename: 'CclCourseSession'
    id: string
    startsAt: string
    endsAt: string
    price?: number | null
    duration?: string | null
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
  }> | null
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: { __typename: 'Subscription'; id: string; planName: PlanName } | null
    } | null
  } | null
}

export type CourseCheckoutPartsFragment = {
  __typename: 'CclCourse'
  id: string
  title: string
  heroImageUrl?: string | null
  testimonials?: Array<{
    __typename: 'CclReview'
    id: string
    title?: string | null
    subTitle?: string | null
    quote?: string | null
    company?: {
      __typename: 'CclCompany'
      id: string
      name: string
      logoUrl?: string | null
      wordmarkLogoUrl?: string | null
    } | null
  }> | null
  creators?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
}

export type CheckoutCreatorPartsFragment = {
  __typename: 'CclExpert'
  id: string
  name: string
  avatarUrl?: string | null
}

export type CheckoutCourseSessionFragment = {
  __typename: 'CclCourseSession'
  id: string
  startsAt: string
  endsAt: string
  price?: number | null
  duration?: string | null
  experts?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
}

export type CourseCompletionCertificateQueryVariables = Exact<{
  courseSessionId: Scalars['ID']
}>

export type CourseCompletionCertificateQuery = {
  __typename: 'Query'
  userCourse?: {
    __typename: 'UserCourse'
    courseCompletionCertificate?: {
      __typename: 'CourseCertificate'
      id: string
      certificateUrl?: string | null
    } | null
  } | null
}

export type CourseDetailQueryVariables = Exact<{
  slug: Scalars['String']
  courseSessionId: Scalars['ID']
}>

export type CourseDetailQuery = {
  __typename: 'Query'
  course?: {
    __typename: 'Course'
    id: string
    title: string
    slug: string
    whoThisIsFor?: any | null
    isAvailableOnDemand?: boolean | null
    shortDescription?: string | null
    completionPercentage?: number | null
    sanityId?: string | null
    courseBlocks?: Array<{
      __typename: 'CourseBlock'
      id?: string | null
      title?: string | null
      description?: any | null
      startDate?: string | null
      endDate?: string | null
      readByDate?: string | null
      previewable?: boolean | null
      units?: Array<{
        __typename: 'Unit'
        id: string
        title?: string | null
        slug?: string | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
        completed?: boolean | null
      }> | null
      artifacts?: Array<{
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        thumbnailPhoto?: any | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
            slug?: string | null
            logo?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      }> | null
      events?: Array<{
        __typename: 'CourseEvent'
        sanityId?: string | null
        title?: string | null
        startsAt?: string | null
        endsAt?: string | null
        joinLink?: string | null
        recording?: any | null
        shortDescription?: string | null
      }> | null
    }> | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  courseSession?: {
    __typename: 'CourseSession'
    id: string
    courseId: string
    startsAt: string
    endsAt: string
    hasEnded: boolean
    hasStarted: boolean
    overallDuration?: string | null
    markYourCalendar?: any | null
    wrapUp?: any | null
    surveyLink?: string | null
    slackChannelId?: string | null
    hosts?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
    gettingStartedBlock?: {
      __typename: 'GettingStartedBlock'
      id?: string | null
      description?: any | null
      gettingStartedCollapsible?: Array<{
        __typename: 'GettingStartedCollapsible'
        id?: string | null
        description?: any | null
        type?: string | null
      }> | null
    } | null
  } | null
  userCourse?: {
    __typename: 'UserCourse'
    id: string
    hasClickedOnSurveyLink?: boolean | null
    courseCompletionSnapshot?: {
      __typename: 'CourseCompletionSnapshot'
      id: string
      imageUrl?: string | null
      token: string
      shareUrl?: string | null
      linkedinShareUrl?: string | null
      twitterShareUrl?: string | null
    } | null
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    goals?: string | null
    focus?: string | null
    audience?: string | null
    onDemand?: boolean | null
    heroImageUrl?: string | null
    trailerUrl?: string | null
    outlineComplete?: boolean | null
    sourceId?: string | null
    staticId: string
    testimonials?: Array<{
      __typename: 'CclReview'
      id: string
      title?: string | null
      subTitle?: string | null
      quote?: string | null
      company?: {
        __typename: 'CclCompany'
        id: string
        name: string
        logoUrl?: string | null
      } | null
    }> | null
    blocks?: Array<{
      __typename: 'CclCourseBlock'
      id: string
      title: string
      description?: string | null
      previewable?: boolean | null
      displayOrder: number
      kind?: string | null
      contentBlocks?: Array<{
        __typename: 'ContentBlock'
        id: string
        header?: string | null
        slug: string
        order?: number | null
        description?: any | null
        contentBlockCollapsible?: Array<{
          __typename: 'ContentBlockCollapsible'
          id: string
          type?: string | null
          description?: any | null
          label?: string | null
        }> | null
      }> | null
      guides?: Array<{
        __typename: 'CclGuide'
        id: string
        title: string
        overview?: string | null
        slug: string
        freePreview?: boolean | null
        displayOrder: number
        estimatedMinutes?: number | null
        sourceId?: string | null
      }> | null
      artifacts?: Array<{
        __typename: 'CclArtifact'
        id: string
        title: string
        slug: string
        sourceId?: string | null
      }> | null
      events?: Array<{
        __typename: 'CclSessionEvent'
        id: string
        title: string
        startsAt: string
        endsAt: string
        sourceId?: string | null
      }> | null
      eventPreviews?: Array<{
        __typename: 'CclVideo'
        id: string
        title: string
        sourceId?: string | null
        location: string
      }> | null
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      bio?: string | null
      position?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      slug: string
      isCollectiveMember?: boolean | null
    }> | null
  } | null
}

export type CourseDashboardUserCoursePartsFragment = {
  __typename: 'UserCourse'
  id: string
  hasClickedOnSurveyLink?: boolean | null
  courseCompletionSnapshot?: {
    __typename: 'CourseCompletionSnapshot'
    id: string
    imageUrl?: string | null
    token: string
    shareUrl?: string | null
    linkedinShareUrl?: string | null
    twitterShareUrl?: string | null
  } | null
}

export type CourseCompletionSnapshotPartsFragment = {
  __typename: 'CourseCompletionSnapshot'
  id: string
  imageUrl?: string | null
  token: string
  shareUrl?: string | null
  linkedinShareUrl?: string | null
  twitterShareUrl?: string | null
}

export type CourseDashboardCoursePartsFragment = {
  __typename: 'Course'
  id: string
  title: string
  slug: string
  whoThisIsFor?: any | null
  isAvailableOnDemand?: boolean | null
  shortDescription?: string | null
  completionPercentage?: number | null
  sanityId?: string | null
  creators?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    shortBio?: string | null
    slug?: string | null
    position?: string | null
    pastCompanies?: Array<string> | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CourseDashboardCreatorFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  shortBio?: string | null
  slug?: string | null
  position?: string | null
  pastCompanies?: Array<string> | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    slug?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type CourseDashboardCourseSessionPartsFragment = {
  __typename: 'CourseSession'
  id: string
  courseId: string
  startsAt: string
  endsAt: string
  hasEnded: boolean
  hasStarted: boolean
  overallDuration?: string | null
  markYourCalendar?: any | null
  wrapUp?: any | null
  surveyLink?: string | null
  slackChannelId?: string | null
  hosts?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }> | null
  gettingStartedBlock?: {
    __typename: 'GettingStartedBlock'
    id?: string | null
    description?: any | null
    gettingStartedCollapsible?: Array<{
      __typename: 'GettingStartedCollapsible'
      id?: string | null
      description?: any | null
      type?: string | null
    }> | null
  } | null
}

export type CourseDashboardCourseSessionGettingStartedBlockPartsFragment = {
  __typename: 'GettingStartedBlock'
  id?: string | null
  description?: any | null
  gettingStartedCollapsible?: Array<{
    __typename: 'GettingStartedCollapsible'
    id?: string | null
    description?: any | null
    type?: string | null
  }> | null
}

export type CourseDashboardCourseBlockPartsFragment = {
  __typename: 'CourseBlock'
  id?: string | null
  title?: string | null
  description?: any | null
  startDate?: string | null
  endDate?: string | null
  readByDate?: string | null
  previewable?: boolean | null
  units?: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    estimatedTimeInMinutes?: number | null
    previewable?: boolean | null
    completed?: boolean | null
  }> | null
  artifacts?: Array<{
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    thumbnailPhoto?: any | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }> | null
  events?: Array<{
    __typename: 'CourseEvent'
    sanityId?: string | null
    title?: string | null
    startsAt?: string | null
    endsAt?: string | null
    joinLink?: string | null
    recording?: any | null
    shortDescription?: string | null
  }> | null
}

export type CourseDashboardCourseBlockUnitPartsFragment = {
  __typename: 'Unit'
  id: string
  title?: string | null
  slug?: string | null
  estimatedTimeInMinutes?: number | null
  previewable?: boolean | null
  completed?: boolean | null
}

export type CourseDashboardCourseBlockArtifactPartsFragment = {
  __typename: 'Artifact'
  id: string
  title?: string | null
  slug?: string | null
  thumbnailPhoto?: any | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CohortDashboardCourseBlockArtifactAuthorPartsFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  slug?: string | null
  position?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    slug?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type CourseDashboardCourseBlockEventPartsFragment = {
  __typename: 'CourseEvent'
  sanityId?: string | null
  title?: string | null
  startsAt?: string | null
  endsAt?: string | null
  joinLink?: string | null
  recording?: any | null
  shortDescription?: string | null
}

export type MarketingCourseBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type MarketingCourseBySlugQuery = {
  __typename: 'Query'
  marketingCourse: {
    __typename: 'MarketingCourse'
    id: string
    price?: string | null
    duration?: string | null
    coursePreviewAvailable?: boolean | null
    startDate?: string | null
    hostSubtitle?: string | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      whatYouWillLearn?: any | null
      whoThisIsFor?: any | null
    } | null
    courseInfoCards?: Array<{
      __typename: 'CourseInfoCard'
      id?: string | null
      title?: string | null
      description?: string | null
    }> | null
    backgroundImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    hosts?: Array<{
      __typename: 'SanityHost'
      id: string
      hostName?: string | null
      hostBio?: string | null
      hostPicture?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      role?: string | null
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
    courseFaq?: Array<{
      __typename: 'MarketingFaq'
      id: string
      question: string
      answer: any
    }> | null
  }
}

export type CourseEventQueryVariables = Exact<{
  sanityId: Scalars['String']
  courseSessionId?: InputMaybe<Scalars['ID']>
}>

export type CourseEventQuery = {
  __typename: 'Query'
  courseEvent?: {
    __typename: 'CourseEvent'
    sanityId?: string | null
    title?: string | null
    startsAt?: string | null
    endsAt?: string | null
    joinLink?: string | null
    recording?: any | null
    shortDescription?: string | null
    hosts?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
    featuredGuests?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
  } | null
}

export type CourseEventPartsFragment = {
  __typename: 'CourseEvent'
  sanityId?: string | null
  title?: string | null
  startsAt?: string | null
  endsAt?: string | null
  joinLink?: string | null
  recording?: any | null
  shortDescription?: string | null
  hosts?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
    } | null
  }> | null
  featuredGuests?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
    } | null
  }> | null
}

export type CourseEventHostFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  slug?: string | null
  position?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
  } | null
}

export type CourseNavigationOutlineQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseNavigationOutlineQuery = {
  __typename: 'Query'
  course?: {
    __typename: 'Course'
    id: string
    courseBlocks?: Array<{
      __typename: 'CourseBlock'
      id?: string | null
      units?: Array<{
        __typename: 'Unit'
        id: string
        title?: string | null
        slug?: string | null
      }> | null
    }> | null
  } | null
}

export type CourseNavigationOutlineFragment = {
  __typename: 'Course'
  id: string
  courseBlocks?: Array<{
    __typename: 'CourseBlock'
    id?: string | null
    units?: Array<{
      __typename: 'Unit'
      id: string
      title?: string | null
      slug?: string | null
    }> | null
  }> | null
}

export type CourseOnDemandDetailQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseOnDemandDetailQuery = {
  __typename: 'Query'
  course?: {
    __typename: 'Course'
    id: string
    title: string
    slug: string
    whoThisIsFor?: any | null
    isAvailableOnDemand?: boolean | null
    shortDescription?: string | null
    completionPercentage?: number | null
    sanityId?: string | null
    cmsProgram?: { __typename: 'CmsProgram'; id: string; slug: string } | null
    courseBlocks?: Array<{
      __typename: 'CourseBlock'
      id?: string | null
      title?: string | null
      description?: any | null
      startDate?: string | null
      endDate?: string | null
      readByDate?: string | null
      previewable?: boolean | null
      units?: Array<{
        __typename: 'Unit'
        id: string
        title?: string | null
        slug?: string | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
        completed?: boolean | null
      }> | null
      artifacts?: Array<{
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        thumbnailPhoto?: any | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
            slug?: string | null
            logo?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      }> | null
      events?: Array<{
        __typename: 'CourseEvent'
        sanityId?: string | null
        title?: string | null
        startsAt?: string | null
        endsAt?: string | null
        joinLink?: string | null
        recording?: any | null
        shortDescription?: string | null
      }> | null
    }> | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    goals?: string | null
    focus?: string | null
    audience?: string | null
    onDemand?: boolean | null
    heroImageUrl?: string | null
    trailerUrl?: string | null
    outlineComplete?: boolean | null
    sourceId?: string | null
    staticId: string
    testimonials?: Array<{
      __typename: 'CclReview'
      id: string
      title?: string | null
      subTitle?: string | null
      quote?: string | null
      company?: {
        __typename: 'CclCompany'
        id: string
        name: string
        logoUrl?: string | null
      } | null
    }> | null
    blocks?: Array<{
      __typename: 'CclCourseBlock'
      id: string
      title: string
      description?: string | null
      previewable?: boolean | null
      displayOrder: number
      kind?: string | null
      contentBlocks?: Array<{
        __typename: 'ContentBlock'
        id: string
        header?: string | null
        slug: string
        order?: number | null
        description?: any | null
        contentBlockCollapsible?: Array<{
          __typename: 'ContentBlockCollapsible'
          id: string
          type?: string | null
          description?: any | null
          label?: string | null
        }> | null
      }> | null
      guides?: Array<{
        __typename: 'CclGuide'
        id: string
        title: string
        overview?: string | null
        slug: string
        freePreview?: boolean | null
        displayOrder: number
        estimatedMinutes?: number | null
        sourceId?: string | null
      }> | null
      artifacts?: Array<{
        __typename: 'CclArtifact'
        id: string
        title: string
        slug: string
        sourceId?: string | null
      }> | null
      events?: Array<{
        __typename: 'CclSessionEvent'
        id: string
        title: string
        startsAt: string
        endsAt: string
        sourceId?: string | null
      }> | null
      eventPreviews?: Array<{
        __typename: 'CclVideo'
        id: string
        title: string
        sourceId?: string | null
        location: string
      }> | null
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      bio?: string | null
      position?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      slug: string
      isCollectiveMember?: boolean | null
    }> | null
  } | null
}

export type CourseOnDemandDashboardCourseBlockPartsFragment = {
  __typename: 'CourseBlock'
  id?: string | null
  title?: string | null
  description?: any | null
  startDate?: string | null
  endDate?: string | null
  readByDate?: string | null
  previewable?: boolean | null
  units?: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    estimatedTimeInMinutes?: number | null
    previewable?: boolean | null
    completed?: boolean | null
  }> | null
  artifacts?: Array<{
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    thumbnailPhoto?: any | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }> | null
}

export type CourseOnDemandPageAccessQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseOnDemandPageAccessQuery = {
  __typename: 'Query'
  course?: {
    __typename: 'Course'
    id: string
    title: string
    shortDescription?: string | null
    isAvailableOnDemand?: boolean | null
    slug: string
    sanityId?: string | null
    whoThisIsFor?: any | null
    cmsProgram?: { __typename: 'CmsProgram'; id: string; slug: string } | null
    cclCourse?: { __typename: 'CclCourse'; id: string } | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    staticId: string
    slug: string
    legacyCourseId?: string | null
    title: string
  } | null
}

export type CourseOnDemandCmsProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  slug: string
}

export type CoursePageAccessQueryVariables = Exact<{
  slug: Scalars['String']
  courseSessionId: Scalars['ID']
}>

export type CoursePageAccessQuery = {
  __typename: 'Query'
  course?: {
    __typename: 'Course'
    id: string
    title: string
    shortDescription?: string | null
    isAvailableOnDemand?: boolean | null
    slug: string
    sanityId?: string | null
    whoThisIsFor?: any | null
    cclCourse?: { __typename: 'CclCourse'; id: string } | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  courseSession?: {
    __typename: 'CourseSession'
    id: string
    startsAt: string
    endsAt: string
    hasEnded: boolean
    isContentOpen: boolean
    isHostOrCreator: boolean
    overallDuration?: string | null
    markYourCalendar?: any | null
    slackChannelId?: string | null
    hasStarted: boolean
    hosts?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  userCourse?: {
    __typename: 'UserCourse'
    id: string
    courseEnrollmentSnapshot?: {
      __typename: 'CourseEnrollmentSnapshot'
      id: string
      imageUrl?: string | null
      token: string
      shareUrl?: string | null
      linkedinShareUrl?: string | null
      twitterShareUrl?: string | null
    } | null
  } | null
}

export type CoursePageAccessPartsFragment = {
  __typename: 'Course'
  id: string
  title: string
  shortDescription?: string | null
  isAvailableOnDemand?: boolean | null
  slug: string
  sanityId?: string | null
  whoThisIsFor?: any | null
  cclCourse?: { __typename: 'CclCourse'; id: string } | null
  creators?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    shortBio?: string | null
    slug?: string | null
    position?: string | null
    pastCompanies?: Array<string> | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CourseEnrollmentSnapshotPartsFragment = {
  __typename: 'CourseEnrollmentSnapshot'
  id: string
  imageUrl?: string | null
  token: string
  shareUrl?: string | null
  linkedinShareUrl?: string | null
  twitterShareUrl?: string | null
}

export type CourseSessionAccessPartsFragment = {
  __typename: 'CourseSession'
  id: string
  startsAt: string
  endsAt: string
  hasEnded: boolean
  isContentOpen: boolean
  isHostOrCreator: boolean
  overallDuration?: string | null
  markYourCalendar?: any | null
  slackChannelId?: string | null
  hasStarted: boolean
  hosts?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }> | null
}

export type CoursePageAccessCreatorFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  shortBio?: string | null
  slug?: string | null
  position?: string | null
  pastCompanies?: Array<string> | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    slug?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type CoursePaymentPageQueryVariables = Exact<{
  slug: Scalars['String']
  startDate: Scalars['String']
}>

export type CoursePaymentPageQuery = {
  __typename: 'Query'
  courseSession?: {
    __typename: 'CourseSession'
    id: string
    price: number
    startsAt: string
    endsAt: string
    isDuringEnrollmentPeriod: boolean
    isEnrollmentFull: boolean
    courseTitle: string
  } | null
  currentUser?: {
    __typename: 'User'
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: { __typename: 'Subscription'; id: string; planName: PlanName } | null
    } | null
  } | null
}

export type CoursePreviewQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CoursePreviewQuery = {
  __typename: 'Query'
  coursePreview?: {
    __typename: 'CoursePreview'
    id: string
    title: string
    slug: string
    whoThisIsFor?: any | null
    shortDescription?: string | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
    courseBlocks?: Array<{
      __typename: 'CourseBlock'
      id?: string | null
      title?: string | null
      description?: any | null
      previewable?: boolean | null
      units?: Array<{
        __typename: 'Unit'
        id: string
        title?: string | null
        slug?: string | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
      }> | null
      artifacts?: Array<{
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        thumbnailPhoto?: any | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
            slug?: string | null
            logo?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      }> | null
      eventPreviews?: Array<{
        __typename: 'CourseEvent'
        sanityId?: string | null
        title?: string | null
        recording?: any | null
        shortDescription?: string | null
      }> | null
    }> | null
    upcomingSession?: {
      __typename: 'CourseSession'
      id: string
      price: number
      startsAt: string
      isEnrollmentFull: boolean
    } | null
  } | null
}

export type CoursePreviewDashboardCoursePartsFragment = {
  __typename: 'CoursePreview'
  id: string
  title: string
  slug: string
  whoThisIsFor?: any | null
  shortDescription?: string | null
  creators?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    shortBio?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
  courseBlocks?: Array<{
    __typename: 'CourseBlock'
    id?: string | null
    title?: string | null
    description?: any | null
    previewable?: boolean | null
    units?: Array<{
      __typename: 'Unit'
      id: string
      title?: string | null
      slug?: string | null
      estimatedTimeInMinutes?: number | null
      previewable?: boolean | null
    }> | null
    artifacts?: Array<{
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      thumbnailPhoto?: any | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
          slug?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    }> | null
    eventPreviews?: Array<{
      __typename: 'CourseEvent'
      sanityId?: string | null
      title?: string | null
      recording?: any | null
      shortDescription?: string | null
    }> | null
  }> | null
  upcomingSession?: {
    __typename: 'CourseSession'
    id: string
    price: number
    startsAt: string
    isEnrollmentFull: boolean
  } | null
}

export type CoursePreviewDashboardCourseSessionPartsFragment = {
  __typename: 'CourseSession'
  id: string
  price: number
  startsAt: string
  isEnrollmentFull: boolean
}

export type CoursePreviewDashboardCreatorFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  shortBio?: string | null
  slug?: string | null
  position?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    slug?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type CoursePreviewDashboardCourseBlockPartsFragment = {
  __typename: 'CourseBlock'
  id?: string | null
  title?: string | null
  description?: any | null
  previewable?: boolean | null
  units?: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    estimatedTimeInMinutes?: number | null
    previewable?: boolean | null
  }> | null
  artifacts?: Array<{
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    thumbnailPhoto?: any | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }> | null
  eventPreviews?: Array<{
    __typename: 'CourseEvent'
    sanityId?: string | null
    title?: string | null
    recording?: any | null
    shortDescription?: string | null
  }> | null
}

export type CoursePreviewDashboardCourseBlockUnitPartsFragment = {
  __typename: 'Unit'
  id: string
  title?: string | null
  slug?: string | null
  estimatedTimeInMinutes?: number | null
  previewable?: boolean | null
}

export type CoursePreviewDashboardCourseBlockArtifactPartsFragment = {
  __typename: 'Artifact'
  id: string
  title?: string | null
  slug?: string | null
  thumbnailPhoto?: any | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CoursePreviewDashboardCourseBlockEventPartsFragment = {
  __typename: 'CourseEvent'
  sanityId?: string | null
  title?: string | null
  recording?: any | null
  shortDescription?: string | null
}

export type CourseSessionHostDashboardQueryVariables = Exact<{
  courseSessionId: Scalars['ID']
}>

export type CourseSessionHostDashboardQuery = {
  __typename: 'Query'
  supplyCourseSession: {
    __typename: 'SupplyCourseSession'
    courseTitle: string
    enrollmentStartsAt: string
    startsAt: string
    endsAt: string
    maxEnrollmentTotal?: number | null
    maxEnrollmentPaid?: number | null
    maxEnrollmentUnlimited?: number | null
    numEnrolledTotal?: number | null
    numEnrolledPaid?: number | null
    numEnrolledUnlimited?: number | null
    enrollees: Array<{
      __typename: 'User'
      fullName?: string | null
      email: string
      companyName?: string | null
      jobFunction?: string | null
      role?: string | null
    }>
  }
}

export type CourseSnapshotQueryVariables = Exact<{
  token: Scalars['String']
  snapshotType: Scalars['String']
}>

export type CourseSnapshotQuery = {
  __typename: 'Query'
  courseSnapshot: {
    __typename: 'CourseSnapshot'
    courseDescription?: string | null
    courseName?: string | null
    courseSlug?: string | null
    firstName?: string | null
    imageUrl?: string | null
  }
}

export type LatestArtifactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
}>

export type LatestArtifactsQuery = {
  __typename: 'Query'
  latestArtifacts?: Array<{
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    thumbnailPhoto?: any | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }> | null
}

export type CourseSnapshotArtifactPartsFragment = {
  __typename: 'Artifact'
  id: string
  title?: string | null
  slug?: string | null
  thumbnailPhoto?: any | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CourseSnapshotArtifactAuthorPartsFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  slug?: string | null
  position?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    slug?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
}

export type CourseSnapshotMarketingCoursesPartsFragment = {
  __typename: 'MarketingCourse'
  id: string
  slug?: string | null
  cmsProgramSlug?: string | null
  startDate?: string | null
  subtitle?: string | null
  title?: string | null
  legacyProgramId?: string | null
  bookmarkId?: string | null
  duration?: string | null
  courseType?: string | null
  hosts?: Array<{
    __typename: 'SanityHost'
    id: string
    hostName?: string | null
    hostPicture?: {
      __typename: 'Image'
      imageAlt?: string | null
      imageUrl?: string | null
    } | null
  }> | null
  course?: {
    __typename: 'SanityCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    popularWithRoles?: Array<string> | null
    bookmarkId?: string | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  creators?: Array<{
    __typename: 'Creator'
    id: string
    role?: string | null
    expert?: {
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type CourseSnapshotMarketingCoursesQueryVariables = Exact<{ [key: string]: never }>

export type CourseSnapshotMarketingCoursesQuery = {
  __typename: 'Query'
  marketingCourses: Array<{
    __typename: 'MarketingCourse'
    id: string
    slug?: string | null
    cmsProgramSlug?: string | null
    startDate?: string | null
    subtitle?: string | null
    title?: string | null
    legacyProgramId?: string | null
    bookmarkId?: string | null
    duration?: string | null
    courseType?: string | null
    hosts?: Array<{
      __typename: 'SanityHost'
      id: string
      hostName?: string | null
      hostPicture?: {
        __typename: 'Image'
        imageAlt?: string | null
        imageUrl?: string | null
      } | null
    }> | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      popularWithRoles?: Array<string> | null
      bookmarkId?: string | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      role?: string | null
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }>
}

export type CoursesIncludingGuideQueryVariables = Exact<{
  guideSlug: Scalars['String']
}>

export type CoursesIncludingGuideQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    hasUseableCohortCredits: boolean
    courseBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
  } | null
  liveCoursesIncludingGuide?: Array<{
    __typename: 'CclCourse'
    guideCount?: number | null
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  }> | null
}

export type LiveCourseUserCourseFragment = {
  __typename: 'UserCourse'
  id: string
  courseSession: {
    __typename: 'CourseSession'
    id: string
    courseSlug: string
    courseTitle: string
    overallDuration?: string | null
    startsAt: string
    endsAt: string
    isPast: boolean
  }
}

export type CoursesPageCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  is: { __typename: 'UserIs'; member: boolean }
  userCourses: {
    __typename: 'UserCourses'
    all?: Array<{
      __typename: 'UserCourse'
      id: string
      courseSession: {
        __typename: 'CourseSession'
        id: string
        courseSlug: string
        courseTitle: string
        overallDuration?: string | null
        startsAt: string
        endsAt: string
        isPast: boolean
      }
    }> | null
  }
}

export type CoursesPageActiveCourseSessionPartsFragment = {
  __typename: 'CourseSession'
  courseSlug: string
  hasStarted: boolean
  hosts?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }> | null
}

export type CoursesPageCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CoursesPageCurrentUserQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    is: { __typename: 'UserIs'; member: boolean }
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        courseSession: {
          __typename: 'CourseSession'
          id: string
          courseSlug: string
          courseTitle: string
          overallDuration?: string | null
          startsAt: string
          endsAt: string
          isPast: boolean
        }
      }> | null
    }
  } | null
  userCohorts: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      isOngoing: boolean
      eventCount: number
      weekCount: number
      startsAt?: string | null
      endsAt?: string | null
      postCohort: boolean
      coreWeeksHaveEnded: boolean
      postCoreWeeksEnrollmentIsOpen: boolean
      postKickoff: boolean
      slug: string
      thisWeek?: {
        __typename: 'ScheduledWeek'
        id: string
        nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
      } | null
      season: {
        __typename: 'Season'
        hasStarted: boolean
        id: string
        prettyNameSeasonFirst: string
        showCohortViewerAt?: string | null
      }
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        lessonCount: number
        numBookmarks?: number | null
        progressPercent: number
        code?: string | null
        iconImageUrl?: string | null
      }
    }
  }>
}

export type CoursesPageMarketingCoursesQueryVariables = Exact<{
  courseType?: InputMaybe<Scalars['String']>
}>

export type CoursesPageMarketingCoursesQuery = {
  __typename: 'Query'
  marketingCourses: Array<{
    __typename: 'MarketingCourse'
    id: string
    slug?: string | null
    cmsProgramSlug?: string | null
    startDate?: string | null
    subtitle?: string | null
    title?: string | null
    legacyProgramId?: string | null
    bookmarkId?: string | null
    courseType?: string | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      isAvailableOnDemand?: boolean | null
      slug: string
      bookmarkId?: string | null
      popularWithRoles?: Array<string> | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }>
  activeCourseSessions: Array<{
    __typename: 'CourseSession'
    courseSlug: string
    hasStarted: boolean
    hosts?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  }>
}

export type CourseTopicQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseTopicQuery = {
  __typename: 'Query'
  topic?: {
    __typename: 'Topic'
    id?: string | null
    title: string
    slug: string
    isDeprecated?: boolean | null
    redirectTo?: string | null
  } | null
}

export type CourseFunctionQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseFunctionQuery = {
  __typename: 'Query'
  function?: {
    __typename: 'Function'
    id: string
    title: string
    slug: string
    isDeprecated?: boolean | null
    redirectTo?: string | null
  } | null
}

export type CoursesForTopicAndFunctionQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CoursesForTopicAndFunctionQuery = {
  __typename: 'Query'
  coursesForTopicAndFunction?: Array<{
    __typename: 'CourseForTopicAndFunction'
    id: string
    title: string
    slug: string
    shortDescription: string
    whatYouWillLearn?: any | null
    testimonials?: Array<{
      __typename: 'SanityTestimonial'
      id?: string | null
      header?: string | null
      subheader?: string | null
      quote?: string | null
      company?: {
        __typename: 'SanityCompany'
        name?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      pastCompanies?: Array<string> | null
      company?: { __typename: 'SanityCompany'; name?: string | null } | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  }> | null
}

export type TotalCourseCountQueryVariables = Exact<{ [key: string]: never }>

export type TotalCourseCountQuery = { __typename: 'Query'; totalCourseCount: number }

export type CourseConceptsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseConceptsQuery = {
  __typename: 'Query'
  courseConcepts?: {
    __typename: 'CourseConcepts'
    topics?: Array<{
      __typename: 'Topic'
      id?: string | null
      title: string
      slug: string
    }> | null
    functions?: Array<{
      __typename: 'Function'
      id: string
      title: string
      slug: string
    }> | null
  } | null
}

export type BlogPostsForTopicQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type BlogPostsForTopicQuery = {
  __typename: 'Query'
  blogPostsForTopic?: Array<{
    __typename: 'BlogPost'
    id: string
    title: string
    slug?: string | null
    summaryImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
    }> | null
  }> | null
}

export type UserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  enrolled?: boolean | null
  progress?: ProgramProgressScalar | null
}

export type UserAvatarPartsFragment = {
  __typename: 'User'
  id: string
  fullName?: string | null
  email: string
  profile: { __typename: 'UserProfile'; avatarUrl: string }
}

export type CurrentUserUserCohortPartsFragment = {
  __typename: 'UserCohort'
  id: string
  afterCohortCalloutDismissed: boolean
  canBeUpdated: boolean
  cohort: {
    __typename: 'Cohort'
    id: string
    slug: string
    postCoreWeeksEnrollmentIsOpen: boolean
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      code?: string | null
      iconImageUrl?: string | null
    }
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
    group?: { __typename: 'Group'; id: string } | null
  }
}

export type CurrentUserUserCoursePartsFragment = {
  __typename: 'UserCourse'
  id: string
  hasDismissedPostCourseCallout?: boolean | null
  course: {
    __typename: 'Course'
    id: string
    title: string
    slug: string
    completionPercentage?: number | null
  }
  courseSession: {
    __typename: 'CourseSession'
    id: string
    startsAt: string
    endsAt: string
  }
}

export type TocUserFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  hasApplied: boolean
  numSectionBookmarks?: any | null
  is: {
    __typename: 'UserIs'
    premember: boolean
    paidMember: boolean
    planManager: boolean
  }
}

export type BaseUserFragment = {
  __typename: 'User'
  id: string
  fullName?: string | null
  firstName?: string | null
  lastName?: string | null
  timezone?: string | null
  forceFlag?: string | null
}

export type ContentCardUserFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  is: {
    __typename: 'UserIs'
    premember: boolean
    paidMember: boolean
    planManager: boolean
    member: boolean
  }
}

export type CohortPostCardUserPartsFragment = {
  __typename: 'User'
  id: string
  is: { __typename: 'UserIs'; collective: boolean; staff: boolean }
}

export type CohortsPageAccessUserPartsFragment = {
  __typename: 'User'
  cohorts?: {
    __typename: 'UserCohorts'
    all: Array<{
      __typename: 'UserCohort'
      id: string
      cohort: { __typename: 'Cohort'; id: string; slug: string }
    }>
  } | null
  is: { __typename: 'UserIs'; collective: boolean; staff: boolean }
}

export type CurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  hasApplied: boolean
  hasMemberAccess: boolean
  hasContentInProgressOrComplete: boolean
  numSectionBookmarks?: any | null
  accessPolicyKind: string
  shouldSeeHomepageCoursesSection: boolean
  shouldSeeTrialMessaging: boolean
  hasSeenCollectionsPrompt: boolean
  hasTeam?: boolean | null
  externalId?: string | null
  preferredCourseView: string
  companyName?: string | null
  slackUserId?: string | null
  role?: string | null
  joinSlackHref?: string | null
  jobFunction?: string | null
  onboarded: boolean
  allowDirectMessages: boolean
  networkFocus?: Array<string> | null
  networkOnboarded: boolean
  networkRecommendations: Array<string>
  trialStatus?: string | null
  fullName?: string | null
  firstName?: string | null
  lastName?: string | null
  timezone?: string | null
  forceFlag?: string | null
  profile: {
    __typename: 'UserProfile'
    avatarUrl: string
    firstName?: string | null
    hasBadge?: boolean | null
    kind?: string | null
    timezone?: string | null
    avatarXSmallUrl: string
  }
  cohorts?: {
    __typename: 'UserCohorts'
    all: Array<{
      __typename: 'UserCohort'
      id: string
      afterCohortCalloutDismissed: boolean
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        postCoreWeeksEnrollmentIsOpen: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
        group?: { __typename: 'Group'; id: string } | null
      }
    }>
    current: Array<{
      __typename: 'UserCohort'
      id: string
      afterCohortCalloutDismissed: boolean
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        postCoreWeeksEnrollmentIsOpen: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
        group?: { __typename: 'Group'; id: string } | null
      }
    }>
    latestCurrent?: {
      __typename: 'UserCohort'
      id: string
      afterCohortCalloutDismissed: boolean
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        postCoreWeeksEnrollmentIsOpen: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
        group?: { __typename: 'Group'; id: string } | null
      }
    } | null
    previous: Array<{
      __typename: 'UserCohort'
      id: string
      afterCohortCalloutDismissed: boolean
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        postCoreWeeksEnrollmentIsOpen: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
        group?: { __typename: 'Group'; id: string } | null
      }
    }>
  } | null
  userCourses: {
    __typename: 'UserCourses'
    current?: {
      __typename: 'UserCourse'
      id: string
      hasDismissedPostCourseCallout?: boolean | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
      }
    } | null
    upcoming?: Array<{
      __typename: 'UserCourse'
      id: string
      hasDismissedPostCourseCallout?: boolean | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
      }
    }> | null
    previous?: Array<{
      __typename: 'UserCourse'
      id: string
      hasDismissedPostCourseCallout?: boolean | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
      }
    }> | null
    last?: {
      __typename: 'UserCourse'
      id: string
      hasDismissedPostCourseCallout?: boolean | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        completionPercentage?: number | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
      }
    } | null
  }
  eventSpeakerCohortsForActiveSeasons: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      name: string
    }
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }>
  hostCohortsForActiveSeasons: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      name: string
    }
    season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
  }>
  contact: {
    __typename: 'UserContact'
    allEmails: Array<string>
    primaryEmail: string
    projectEmail?: string | null
  }
  is: {
    __typename: 'UserIs'
    subscriptionOwner: boolean
    accepted: boolean
    admin: boolean
    accountCreated: boolean
    creator: boolean
    collective: boolean
    enrolledInLiveProgram: boolean
    enrolledInEnrollmentSeason: boolean
    enrolledInActiveCourse: boolean
    freeUser: boolean
    expired: boolean
    member: boolean
    paidMember: boolean
    planManager: boolean
    staff: boolean
    teamSubscriptionOwner: boolean
    banned: boolean
    copyHold: boolean
    noAccess: boolean
    provisionedByScim: boolean
    trialing: boolean
    eligibleForTrial: boolean
    premember: boolean
  }
  can: {
    __typename: 'UserCan'
    accessAllCohortEventReplays: boolean
    pay?: boolean | null
    onboard?: boolean | null
    enrollInCohort: boolean
    linkToSearchResults?: boolean | null
    manageMyTeam?: boolean | null
    viewActivity?: boolean | null
    viewActivitySidebar?: boolean | null
    viewCollectionsIndex?: boolean | null
    viewCohortIndex?: boolean | null
    viewConceptsIndex?: boolean | null
    viewConceptsProjectsSidebar?: boolean | null
    viewDiscussionIndex?: boolean | null
    viewEvent?: boolean | null
    viewEventsIndex?: boolean | null
    viewLearningGoals?: boolean | null
    viewMemberIndex?: boolean | null
    viewMemberProfile?: boolean | null
    viewPost?: boolean | null
    viewPrograms?: boolean | null
    viewProjectsIndex?: boolean | null
    viewSearchTabs?: boolean | null
    viewSidebarGroups?: boolean | null
    viewProjectsAndConcepts?: boolean | null
    viewProgramsDashboard?: boolean | null
    viewSubscriptionSummary?: boolean | null
    viewBilling?: boolean | null
    viewPurchaseFlow?: boolean | null
    viewCohortHistory?: boolean | null
    viewManageSeats?: boolean | null
    viewOrg?: boolean | null
  }
  activeProgram?: {
    __typename: 'UserProgram'
    id: string
    enrolled?: boolean | null
    progress?: ProgramProgressScalar | null
  } | null
  preference?: { __typename: 'UserPreference'; skipFeedback: boolean } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasApplied: boolean
    hasMemberAccess: boolean
    hasContentInProgressOrComplete: boolean
    numSectionBookmarks?: any | null
    accessPolicyKind: string
    shouldSeeHomepageCoursesSection: boolean
    shouldSeeTrialMessaging: boolean
    hasSeenCollectionsPrompt: boolean
    hasTeam?: boolean | null
    externalId?: string | null
    preferredCourseView: string
    companyName?: string | null
    slackUserId?: string | null
    role?: string | null
    joinSlackHref?: string | null
    jobFunction?: string | null
    onboarded: boolean
    allowDirectMessages: boolean
    networkFocus?: Array<string> | null
    networkOnboarded: boolean
    networkRecommendations: Array<string>
    trialStatus?: string | null
    fullName?: string | null
    firstName?: string | null
    lastName?: string | null
    timezone?: string | null
    forceFlag?: string | null
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      firstName?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      timezone?: string | null
      avatarXSmallUrl: string
    }
    cohorts?: {
      __typename: 'UserCohorts'
      all: Array<{
        __typename: 'UserCohort'
        id: string
        afterCohortCalloutDismissed: boolean
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          postCoreWeeksEnrollmentIsOpen: boolean
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
          group?: { __typename: 'Group'; id: string } | null
        }
      }>
      current: Array<{
        __typename: 'UserCohort'
        id: string
        afterCohortCalloutDismissed: boolean
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          postCoreWeeksEnrollmentIsOpen: boolean
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
          group?: { __typename: 'Group'; id: string } | null
        }
      }>
      latestCurrent?: {
        __typename: 'UserCohort'
        id: string
        afterCohortCalloutDismissed: boolean
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          postCoreWeeksEnrollmentIsOpen: boolean
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
          group?: { __typename: 'Group'; id: string } | null
        }
      } | null
      previous: Array<{
        __typename: 'UserCohort'
        id: string
        afterCohortCalloutDismissed: boolean
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          postCoreWeeksEnrollmentIsOpen: boolean
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
          group?: { __typename: 'Group'; id: string } | null
        }
      }>
    } | null
    userCourses: {
      __typename: 'UserCourses'
      current?: {
        __typename: 'UserCourse'
        id: string
        hasDismissedPostCourseCallout?: boolean | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          completionPercentage?: number | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
        }
      } | null
      upcoming?: Array<{
        __typename: 'UserCourse'
        id: string
        hasDismissedPostCourseCallout?: boolean | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          completionPercentage?: number | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
        }
      }> | null
      previous?: Array<{
        __typename: 'UserCourse'
        id: string
        hasDismissedPostCourseCallout?: boolean | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          completionPercentage?: number | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
        }
      }> | null
      last?: {
        __typename: 'UserCourse'
        id: string
        hasDismissedPostCourseCallout?: boolean | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          completionPercentage?: number | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
        }
      } | null
    }
    eventSpeakerCohortsForActiveSeasons: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        iconImageUrl?: string | null
        name: string
      }
      season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
    }>
    hostCohortsForActiveSeasons: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        iconImageUrl?: string | null
        name: string
      }
      season: { __typename: 'Season'; id: string; showCohortViewerAt?: string | null }
    }>
    contact: {
      __typename: 'UserContact'
      allEmails: Array<string>
      primaryEmail: string
      projectEmail?: string | null
    }
    is: {
      __typename: 'UserIs'
      subscriptionOwner: boolean
      accepted: boolean
      admin: boolean
      accountCreated: boolean
      creator: boolean
      collective: boolean
      enrolledInLiveProgram: boolean
      enrolledInEnrollmentSeason: boolean
      enrolledInActiveCourse: boolean
      freeUser: boolean
      expired: boolean
      member: boolean
      paidMember: boolean
      planManager: boolean
      staff: boolean
      teamSubscriptionOwner: boolean
      banned: boolean
      copyHold: boolean
      noAccess: boolean
      provisionedByScim: boolean
      trialing: boolean
      eligibleForTrial: boolean
      premember: boolean
    }
    can: {
      __typename: 'UserCan'
      accessAllCohortEventReplays: boolean
      pay?: boolean | null
      onboard?: boolean | null
      enrollInCohort: boolean
      linkToSearchResults?: boolean | null
      manageMyTeam?: boolean | null
      viewActivity?: boolean | null
      viewActivitySidebar?: boolean | null
      viewCollectionsIndex?: boolean | null
      viewCohortIndex?: boolean | null
      viewConceptsIndex?: boolean | null
      viewConceptsProjectsSidebar?: boolean | null
      viewDiscussionIndex?: boolean | null
      viewEvent?: boolean | null
      viewEventsIndex?: boolean | null
      viewLearningGoals?: boolean | null
      viewMemberIndex?: boolean | null
      viewMemberProfile?: boolean | null
      viewPost?: boolean | null
      viewPrograms?: boolean | null
      viewProjectsIndex?: boolean | null
      viewSearchTabs?: boolean | null
      viewSidebarGroups?: boolean | null
      viewProjectsAndConcepts?: boolean | null
      viewProgramsDashboard?: boolean | null
      viewSubscriptionSummary?: boolean | null
      viewBilling?: boolean | null
      viewPurchaseFlow?: boolean | null
      viewCohortHistory?: boolean | null
      viewManageSeats?: boolean | null
      viewOrg?: boolean | null
    }
    activeProgram?: {
      __typename: 'UserProgram'
      id: string
      enrolled?: boolean | null
      progress?: ProgramProgressScalar | null
    } | null
    preference?: { __typename: 'UserPreference'; skipFeedback: boolean } | null
  } | null
}

export type AvailableTeamSubscriptionMemberFragment = {
  __typename: 'SubscriptionMember'
  id: string
  user: { __typename: 'User'; id: string; avatarUrl: string; fullName?: string | null }
}

export type AvailableTeamSeatFragment = {
  __typename: 'Seat'
  id: string
  subscriptionMember?: {
    __typename: 'SubscriptionMember'
    id: string
    user: { __typename: 'User'; id: string; avatarUrl: string; fullName?: string | null }
  } | null
}

export type AvailableTeamSubscriptionJoinRequestFragment = {
  __typename: 'SubscriptionJoinRequest'
  id: string
  createdAt: string
  status: string
}

export type AvailableTeamSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  teamName?: string | null
  logoUrl?: string | null
  domainName?: string | null
  isTeam: boolean
  externalId?: string | null
  user: { __typename: 'User'; id: string; fullName?: string | null }
  seats: Array<{
    __typename: 'Seat'
    id: string
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      user: {
        __typename: 'User'
        id: string
        avatarUrl: string
        fullName?: string | null
      }
    } | null
  }>
  userJoinRequests: Array<{
    __typename: 'SubscriptionJoinRequest'
    id: string
    createdAt: string
    status: string
  }>
}

export type CurrentUserAvailableTeamsQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserAvailableTeamsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    activeSubscriptionMembership?: {
      __typename: 'SubscriptionMember'
      id: string
      createdAt: string
    } | null
    availableTeamSubscriptions: Array<{
      __typename: 'Subscription'
      id: string
      teamName?: string | null
      logoUrl?: string | null
      domainName?: string | null
      isTeam: boolean
      externalId?: string | null
      user: { __typename: 'User'; id: string; fullName?: string | null }
      seats: Array<{
        __typename: 'Seat'
        id: string
        subscriptionMember?: {
          __typename: 'SubscriptionMember'
          id: string
          user: {
            __typename: 'User'
            id: string
            avatarUrl: string
            fullName?: string | null
          }
        } | null
      }>
      userJoinRequests: Array<{
        __typename: 'SubscriptionJoinRequest'
        id: string
        createdAt: string
        status: string
      }>
    }>
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        teamName?: string | null
        logoUrl?: string | null
        domainName?: string | null
        isTeam: boolean
        externalId?: string | null
        user: { __typename: 'User'; id: string; fullName?: string | null }
        seats: Array<{
          __typename: 'Seat'
          id: string
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            user: {
              __typename: 'User'
              id: string
              avatarUrl: string
              fullName?: string | null
            }
          } | null
        }>
        userJoinRequests: Array<{
          __typename: 'SubscriptionJoinRequest'
          id: string
          createdAt: string
          status: string
        }>
      } | null
    } | null
  } | null
}

export type UserCohortsPartsFragment = {
  __typename: 'User'
  cohorts?: {
    __typename: 'UserCohorts'
    latestCurrent?: {
      __typename: 'UserCohort'
      id: string
      canBeUpdated: boolean
      hasClickedMeetCohortParticipantsCta: Array<string>
      hasClickedShareSnapshotCta: boolean
      hasSignedStatementOfCommitment: boolean
      showEventRsvpModal: boolean
      hasPostedWelcome: boolean
      timeGroupingSelected?: string | null
      cohort: {
        __typename: 'Cohort'
        id: string
        postKickoff: boolean
        postCoreWeeksEnrollmentIsOpen: boolean
        slug: string
        startsAt?: string | null
        weekCount: number
        postCohort: boolean
        hasEnded: boolean
        slackChannelId?: string | null
        kickoffEvents: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        scheduledWeeks: Array<{
          __typename: 'ScheduledWeek'
          id: string
          bonus?: boolean | null
          postCohort: boolean
          startsAt?: string | null
          endsAt?: string | null
        }>
        events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        season: {
          __typename: 'Season'
          id: string
          name: string
          year: string
          npsSurveyLink?: string | null
        }
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
          title?: string | null
        }> | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          code?: string | null
          name: string
          heroImageUrl?: string | null
          programImage?: string | null
          synopsis?: string | null
          lessonCount: number
        }
        nextWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
        } | null
        thisWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          startsAt?: string | null
          endsAt?: string | null
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
          nextModule?: {
            __typename: 'CmsModule'
            estimatedTime: number
            href: string
            id: string
            name: string
            numBookmarks?: number | null
            numLessons: number
            programCode: string
            programIconImageUrl?: string | null
            progressPercent: number
            shortDescription?: string | null
            publishAt?: string | null
            published?: boolean | null
            clips?: Array<{
              __typename: 'Clip'
              id: string
              title: string
              duration?: number | null
              slug: string
              thumbnailUrl: string
              summary?: string | null
              summaryWistiaCode: string
              hasBeenViewedByCurrentUser: boolean
              users: Array<{
                __typename: 'User'
                id: string
                avatarUrl: string
                fullName?: string | null
                role?: string | null
                companyName?: string | null
                profile: { __typename: 'UserProfile'; profileUrl?: string | null }
              }>
            }> | null
          } | null
        } | null
        batchRsvpTimeData: Array<{
          __typename: 'BatchRsvpTimeData'
          ids: Array<string>
          startsAtUtc?: string | null
          endsAtUtc?: string | null
          timeGrouping?: string | null
        }>
      }
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    } | null
    current: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        postKickoff: boolean
        hasEnded: boolean
        postCohort: boolean
      }
    }>
  } | null
}

export type UserCohortsQueryVariables = Exact<{ [key: string]: never }>

export type UserCohortsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    cohorts?: {
      __typename: 'UserCohorts'
      latestCurrent?: {
        __typename: 'UserCohort'
        id: string
        canBeUpdated: boolean
        hasClickedMeetCohortParticipantsCta: Array<string>
        hasClickedShareSnapshotCta: boolean
        hasSignedStatementOfCommitment: boolean
        showEventRsvpModal: boolean
        hasPostedWelcome: boolean
        timeGroupingSelected?: string | null
        cohort: {
          __typename: 'Cohort'
          id: string
          postKickoff: boolean
          postCoreWeeksEnrollmentIsOpen: boolean
          slug: string
          startsAt?: string | null
          weekCount: number
          postCohort: boolean
          hasEnded: boolean
          slackChannelId?: string | null
          kickoffEvents: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
          scheduledWeeks: Array<{
            __typename: 'ScheduledWeek'
            id: string
            bonus?: boolean | null
            postCohort: boolean
            startsAt?: string | null
            endsAt?: string | null
          }>
          events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
          season: {
            __typename: 'Season'
            id: string
            name: string
            year: string
            npsSurveyLink?: string | null
          }
          hosts?: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
            previousCompanies?: string | null
            title?: string | null
          }> | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            code?: string | null
            name: string
            heroImageUrl?: string | null
            programImage?: string | null
            synopsis?: string | null
            lessonCount: number
          }
          nextWeek?: {
            __typename: 'ScheduledWeek'
            id: string
            events: Array<{
              __typename: 'Event'
              id: string
              name: string
              willBeRecorded: boolean
              preread?: string | null
              startsAtUtc: string
              endsAtUtc: string
              attendingStatus?: string | null
              past: boolean
              summaryWistiaCode?: string | null
              slug: string
              kind: string
              uuid: string
              speakers?: Array<{
                __typename: 'EventSpeaker'
                id: string
                kind?: string | null
                user: {
                  __typename: 'User'
                  id: string
                  profile: {
                    __typename: 'UserProfile'
                    companyName?: string | null
                    fullName?: string | null
                    avatarUrl: string
                  }
                }
              }> | null
              caseCompany?: {
                __typename: 'Company'
                id: string
                name?: string | null
              } | null
            }>
          } | null
          thisWeek?: {
            __typename: 'ScheduledWeek'
            id: string
            startsAt?: string | null
            endsAt?: string | null
            events: Array<{
              __typename: 'Event'
              id: string
              name: string
              willBeRecorded: boolean
              preread?: string | null
              startsAtUtc: string
              endsAtUtc: string
              attendingStatus?: string | null
              past: boolean
              summaryWistiaCode?: string | null
              slug: string
              kind: string
              uuid: string
              speakers?: Array<{
                __typename: 'EventSpeaker'
                id: string
                kind?: string | null
                user: {
                  __typename: 'User'
                  id: string
                  profile: {
                    __typename: 'UserProfile'
                    companyName?: string | null
                    fullName?: string | null
                    avatarUrl: string
                  }
                }
              }> | null
              caseCompany?: {
                __typename: 'Company'
                id: string
                name?: string | null
              } | null
            }>
            nextModule?: {
              __typename: 'CmsModule'
              estimatedTime: number
              href: string
              id: string
              name: string
              numBookmarks?: number | null
              numLessons: number
              programCode: string
              programIconImageUrl?: string | null
              progressPercent: number
              shortDescription?: string | null
              publishAt?: string | null
              published?: boolean | null
              clips?: Array<{
                __typename: 'Clip'
                id: string
                title: string
                duration?: number | null
                slug: string
                thumbnailUrl: string
                summary?: string | null
                summaryWistiaCode: string
                hasBeenViewedByCurrentUser: boolean
                users: Array<{
                  __typename: 'User'
                  id: string
                  avatarUrl: string
                  fullName?: string | null
                  role?: string | null
                  companyName?: string | null
                  profile: { __typename: 'UserProfile'; profileUrl?: string | null }
                }>
              }> | null
            } | null
          } | null
          batchRsvpTimeData: Array<{
            __typename: 'BatchRsvpTimeData'
            ids: Array<string>
            startsAtUtc?: string | null
            endsAtUtc?: string | null
            timeGrouping?: string | null
          }>
        }
        snapshot?: {
          __typename: 'Snapshot'
          id: string
          token: string
          released: boolean
        } | null
      } | null
      current: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          postKickoff: boolean
          hasEnded: boolean
          postCohort: boolean
        }
      }>
    } | null
  } | null
}

export type CurrentUserMemberAccessQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserMemberAccessQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasMemberAccess: boolean
    hasUseableCohortCredits: boolean
    paymentMethods: Array<{
      __typename: 'StripePaymentMethod'
      defaultPaymentMethod?: boolean | null
      card?: { __typename: 'StripePaymentMethodCard'; last4: string } | null
    }>
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        planName: PlanName
        expiresAt: string
      } | null
    } | null
    stripeCustomer?: { __typename: 'StripeCustomer'; id: string } | null
    is: { __typename: 'UserIs'; trialing: boolean }
  } | null
}

export type CurrentUserPatronageRequestLinkQueryVariables = Exact<{
  [key: string]: never
}>

export type CurrentUserPatronageRequestLinkQuery = {
  __typename: 'Query'
  currentUser?: { __typename: 'User'; id: string; patronageRequestLink: string } | null
}

export type CustomerContactQueryVariables = Exact<{ [key: string]: never }>

export type CustomerContactQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    stripeCustomer?: {
      __typename: 'StripeCustomer'
      id: string
      name?: string | null
      address?: {
        __typename: 'StripeAddress'
        line1: string
        line2?: string | null
        city: string
        state?: string | null
        country: string
        postal_code: string
      } | null
    } | null
  } | null
}

export type DunningFlowQueryVariables = Exact<{ [key: string]: never }>

export type DunningFlowQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        daysLeft: number
        dunningAmountDueInCents?: number | null
        expiresAt: string
        startsAt?: string | null
        isCanceled?: boolean | null
        isDunning: boolean
        isExpired?: boolean | null
        isPaidMonthly: boolean
        isTeam: boolean
        lastFailedPaymentAt?: string | null
        numSeats: number
        status?: string | null
        stripeCollectionMethod?: StripeCollectionMethod | null
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        userIsOwner: boolean
        stripeUpcomingInvoice?: {
          __typename: 'StripeUpcomingInvoice'
          id: string
          amountDue: number
          date: string
          subtotal: number
        } | null
      } | null
    } | null
    paymentMethods: Array<{
      __typename: 'StripePaymentMethod'
      id: string
      type: string
      defaultPaymentMethod?: boolean | null
      card?: {
        __typename: 'StripePaymentMethodCard'
        brand: string
        last4: string
        expYear: number
        expMonth: number
        status: string
      } | null
    }>
  } | null
}

export type DuplicateAccountsQueryVariables = Exact<{ [key: string]: never }>

export type DuplicateAccountsQuery = {
  __typename: 'Query'
  duplicateAccounts: Array<{
    __typename: 'DuplicateAccount'
    id: string
    email: string
    createdAt: string
  }>
}

export type EmailTemplateQueryVariables = Exact<{
  templateType: Scalars['String']
}>

export type EmailTemplateQuery = { __typename: 'Query'; emailTemplate: string }

export type EngagementReportSummaryQueryVariables = Exact<{ [key: string]: never }>

export type EngagementReportSummaryQuery = {
  __typename: 'Query'
  engagementReportSummary: {
    __typename: 'EngagementReportSummary'
    liveCourseEnrollmentCount: number
    coursesWorkedOnCount: number
    contentViewedCount: number
    insightsSavedCount: number
    topCourses: Array<{
      __typename: 'EngagementReportCourse'
      id: string
      imageUrl: string
      name: string
      synopsis: string
      participants: Array<{
        __typename: 'EngagementReportParticipant'
        id: string
        avatarUrl: string
      }>
    }>
    topLessons: Array<{
      __typename: 'EngagementReportLesson'
      id: string
      name: string
      programName: string
      numViews: number
    }>
    recentLessons: Array<{
      __typename: 'EngagementReportLesson'
      id: string
      name: string
      programName: string
      lastViewedAt?: string | null
      numViews: number
    }>
    recentSavedItems: Array<{
      __typename: 'EngagementReportSavedItem'
      id: string
      cmsSectionName: string
      updatedAt: string
    }>
  }
}

export type EngagementReportTeamQueryVariables = Exact<{ [key: string]: never }>

export type EngagementReportTeamQuery = {
  __typename: 'Query'
  engagementReportTeam: {
    __typename: 'EngagementReportTeam'
    teamMembers: Array<{
      __typename: 'EngagementReportTeamMember'
      id: string
      fullName: string
      avatarUrl: string
      currentCourseName: string
      coursesWorkedOn: Array<string>
      coursesWorkedOnCount: number
      liveCoursesTaken: Array<string>
      liveCoursesTakenCount: number
      contentViewed: Array<string>
      contentViewedCount: number
      insightsSaved: Array<string>
      insightsSavedCount: number
    }>
  }
}

export type ProgramSelectorCurrentUserFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  shouldSeeTrialMessaging: boolean
  is: {
    __typename: 'UserIs'
    alumni: boolean
    paidMember: boolean
    enrolledInActiveCourse: boolean
    enrolledInLiveProgram: boolean
    enrolledInEnrollmentSeason: boolean
    member: boolean
  }
  can: { __typename: 'UserCan'; enrollInCohort: boolean }
  profile: {
    __typename: 'UserProfile'
    aboutMeText?: string | null
    allowDirectMessages?: boolean | null
    areasOfExpertiseTags?: string | null
    askMeAboutText?: string | null
    avatarUrl: string
    businessModelTags?: string | null
    communityInterestTags?: string | null
    communityInterestText?: string | null
    companyAudienceTags?: string | null
    companyName?: string | null
    companyProductText?: string | null
    firstName?: string | null
    lastName?: string | null
    learningGoalsText?: string | null
    linkedin?: string | null
    location?: string | null
    responseTime?: string | null
    onboardingStep: number
    role?: string | null
    timezone?: string | null
    twitter?: string | null
    previousCompanies?: Array<{
      __typename: 'UserCompany'
      id: string
      company: { __typename: 'Company'; id: string; name?: string | null }
    }> | null
  }
  pricing: { __typename: 'UserPricing'; term: string }
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      expiresAt: string
      id: string
      isCanceled?: boolean | null
      numSeats: number
      status?: string | null
      stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
    } | null
    trial?: {
      __typename: 'Subscription'
      id: string
      status?: string | null
      daysLeftInTrial?: number | null
    } | null
  } | null
  cohorts?: {
    __typename: 'UserCohorts'
    previous: Array<{
      __typename: 'UserCohort'
      id: string
      programId: string
      programName: string
      season: string
      canBeUpdated: boolean
    }>
    latestCurrent?: {
      __typename: 'UserCohort'
      id: string
      programId: string
      programName: string
      season: string
      seasonId: string
      canBeUpdated: boolean
    } | null
    current: Array<{
      __typename: 'UserCohort'
      id: string
      programId: string
      programName: string
      season: string
      seasonId: string
      canBeUpdated: boolean
      cohort: {
        __typename: 'Cohort'
        id: string
        eventTimes?: Array<{
          __typename: 'CohortEventTimes'
          count: number
          firstStartDate: string
        }> | null
      }
    }>
  } | null
  recommendedPrograms: Array<{
    __typename: 'CmsProgram'
    id: string
    iconImageUrl?: string | null
    heroImageUrl?: string | null
    launched: boolean
    notLaunched?: boolean | null
    enrolled: boolean
    programImage?: string | null
    name: string
    recommendationRanking?: number | null
    whoItsFor?: string | null
    slug: string
    byTheEnd?: string | null
    synopsis?: string | null
    categoryNames?: Array<string> | null
    canEnroll: boolean
    launchAt?: string | null
    testimonials: Array<{
      __typename: 'Testimonial'
      id: string
      copy?: string | null
      name?: string | null
      role?: string | null
      thumbnailUrl?: string | null
    }>
    cohort?: {
      __typename: 'Cohort'
      id: string
      endsAt?: string | null
      startsAt?: string | null
      durationInWeeks?: number | null
      weekCount: number
      eventTimes?: Array<{
        __typename: 'CohortEventTimes'
        firstStartDate: string
        firstEndDate?: string | null
        count: number
      }> | null
      hosts?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      programCollaborators?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      scheduledWeeks: Array<{
        __typename: 'ScheduledWeek'
        id: string
        core?: boolean | null
        description?: string | null
        title?: string | null
        bonus?: boolean | null
        postCohort: boolean
        startsAt?: string | null
        endsAt?: string | null
        numLessons?: number | null
        numModules?: number | null
        events: Array<{
          __typename: 'Event'
          startsAtUtc: string
          endsAtUtc: string
          kind: string
        }>
      }>
    } | null
  }>
}

export type ProgramSelectorProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  iconImageUrl?: string | null
  heroImageUrl?: string | null
  launched: boolean
  notLaunched?: boolean | null
  enrolled: boolean
  programImage?: string | null
  name: string
  recommendationRanking?: number | null
  whoItsFor?: string | null
  slug: string
  byTheEnd?: string | null
  synopsis?: string | null
  categoryNames?: Array<string> | null
  canEnroll: boolean
  launchAt?: string | null
  cohort?: {
    __typename: 'Cohort'
    id: string
    endsAt?: string | null
    startsAt?: string | null
    durationInWeeks?: number | null
    weekCount: number
    eventTimes?: Array<{
      __typename: 'CohortEventTimes'
      firstStartDate: string
      firstEndDate?: string | null
      count: number
    }> | null
    hosts?: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      title?: string | null
      bio?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
    }> | null
    programCollaborators?: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      title?: string | null
      bio?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
    }> | null
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      core?: boolean | null
      description?: string | null
      title?: string | null
      bonus?: boolean | null
      postCohort: boolean
      startsAt?: string | null
      endsAt?: string | null
      numLessons?: number | null
      numModules?: number | null
      events: Array<{
        __typename: 'Event'
        startsAtUtc: string
        endsAtUtc: string
        kind: string
      }>
    }>
  } | null
}

export type ProgramSelectorProgramSparsePartsFragment = {
  __typename: 'CmsProgram'
  id: string
  iconImageUrl?: string | null
  heroImageUrl?: string | null
  launched: boolean
  notLaunched?: boolean | null
  enrolled: boolean
  programImage?: string | null
  name: string
  whoItsFor?: string | null
  slug: string
  byTheEnd?: string | null
  synopsis?: string | null
  categoryNames?: Array<string> | null
  canEnroll: boolean
  launchAt?: string | null
}

export type ProgramSelectorHostPartsFragment = {
  __typename: 'Host'
  id: string
  name?: string | null
  title?: string | null
  bio?: string | null
  thumbnailUrl: string
  previousCompanies?: string | null
}

export type ProgramSelectorScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  core?: boolean | null
  description?: string | null
  title?: string | null
  bonus?: boolean | null
  postCohort: boolean
  startsAt?: string | null
  endsAt?: string | null
  numLessons?: number | null
  numModules?: number | null
  events: Array<{
    __typename: 'Event'
    startsAtUtc: string
    endsAtUtc: string
    kind: string
  }>
}

export type OnboardingModalCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  hasBadge: boolean
  role?: string | null
  firstName?: string | null
  lastName?: string | null
  linkedin?: string | null
  twitter?: string | null
  avatarUrl: string
  companyName?: string | null
  jobFunction?: string | null
  jobLevel?: string | null
  onboarded: boolean
  shouldSeeTrialMessaging: boolean
  previousCompanies: Array<{
    __typename: 'UserCompany'
    id: string
    company: { __typename: 'Company'; id: string; name?: string | null }
  }>
  is: {
    __typename: 'UserIs'
    badgeholder: boolean
    alumni: boolean
    paidMember: boolean
    enrolledInLiveProgram: boolean
    enrolledInEnrollmentSeason: boolean
    member: boolean
    subscriptionOwner: boolean
    teamSubscriptionOwner: boolean
  }
  can: { __typename: 'UserCan'; enrollInCohort: boolean }
  profile: {
    __typename: 'UserProfile'
    aboutMeText?: string | null
    allowDirectMessages?: boolean | null
    areasOfExpertiseTags?: string | null
    askMeAboutText?: string | null
    avatarUrl: string
    businessModelTags?: string | null
    communityInterestTags?: string | null
    communityInterestText?: string | null
    companyAudienceTags?: string | null
    companyName?: string | null
    companyProductText?: string | null
    firstName?: string | null
    lastName?: string | null
    learningGoalsText?: string | null
    linkedin?: string | null
    location?: string | null
    responseTime?: string | null
    onboardingStep: number
    role?: string | null
    timezone?: string | null
    twitter?: string | null
    previousCompanies?: Array<{
      __typename: 'UserCompany'
      id: string
      company: { __typename: 'Company'; id: string; name?: string | null }
    }> | null
  }
  pricing: { __typename: 'UserPricing'; term: string }
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      expiresAt: string
      id: string
      isCanceled?: boolean | null
      numSeats: number
      numSeatsAvailable: number
      planName: PlanName
      status?: string | null
      stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
    } | null
    trial?: {
      __typename: 'Subscription'
      id: string
      status?: string | null
      daysLeftInTrial?: number | null
    } | null
  } | null
  cohorts?: {
    __typename: 'UserCohorts'
    current: Array<{
      __typename: 'UserCohort'
      id: string
      seasonId: string
      canBeUpdated: boolean
      programId: string
    }>
  } | null
  recommendedPrograms: Array<{
    __typename: 'CmsProgram'
    id: string
    iconImageUrl?: string | null
    heroImageUrl?: string | null
    launched: boolean
    notLaunched?: boolean | null
    enrolled: boolean
    programImage?: string | null
    name: string
    whoItsFor?: string | null
    slug: string
    byTheEnd?: string | null
    synopsis?: string | null
    categoryNames?: Array<string> | null
    canEnroll: boolean
    launchAt?: string | null
    testimonials: Array<{
      __typename: 'Testimonial'
      id: string
      copy?: string | null
      name?: string | null
      role?: string | null
      thumbnailUrl?: string | null
    }>
  }>
}

export type GetOnboardingEnrollmentInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetOnboardingEnrollmentInfoQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    hasBadge: boolean
    role?: string | null
    firstName?: string | null
    lastName?: string | null
    linkedin?: string | null
    twitter?: string | null
    avatarUrl: string
    companyName?: string | null
    jobFunction?: string | null
    jobLevel?: string | null
    onboarded: boolean
    shouldSeeTrialMessaging: boolean
    previousCompanies: Array<{
      __typename: 'UserCompany'
      id: string
      company: { __typename: 'Company'; id: string; name?: string | null }
    }>
    is: {
      __typename: 'UserIs'
      badgeholder: boolean
      alumni: boolean
      paidMember: boolean
      enrolledInLiveProgram: boolean
      enrolledInEnrollmentSeason: boolean
      member: boolean
      subscriptionOwner: boolean
      teamSubscriptionOwner: boolean
    }
    can: { __typename: 'UserCan'; enrollInCohort: boolean }
    profile: {
      __typename: 'UserProfile'
      aboutMeText?: string | null
      allowDirectMessages?: boolean | null
      areasOfExpertiseTags?: string | null
      askMeAboutText?: string | null
      avatarUrl: string
      businessModelTags?: string | null
      communityInterestTags?: string | null
      communityInterestText?: string | null
      companyAudienceTags?: string | null
      companyName?: string | null
      companyProductText?: string | null
      firstName?: string | null
      lastName?: string | null
      learningGoalsText?: string | null
      linkedin?: string | null
      location?: string | null
      responseTime?: string | null
      onboardingStep: number
      role?: string | null
      timezone?: string | null
      twitter?: string | null
      previousCompanies?: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; id: string; name?: string | null }
      }> | null
    }
    pricing: { __typename: 'UserPricing'; term: string }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        expiresAt: string
        id: string
        isCanceled?: boolean | null
        numSeats: number
        numSeatsAvailable: number
        planName: PlanName
        status?: string | null
        stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
      } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        daysLeftInTrial?: number | null
      } | null
    } | null
    cohorts?: {
      __typename: 'UserCohorts'
      current: Array<{
        __typename: 'UserCohort'
        id: string
        seasonId: string
        canBeUpdated: boolean
        programId: string
      }>
    } | null
    recommendedPrograms: Array<{
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      heroImageUrl?: string | null
      launched: boolean
      notLaunched?: boolean | null
      enrolled: boolean
      programImage?: string | null
      name: string
      whoItsFor?: string | null
      slug: string
      byTheEnd?: string | null
      synopsis?: string | null
      categoryNames?: Array<string> | null
      canEnroll: boolean
      launchAt?: string | null
      testimonials: Array<{
        __typename: 'Testimonial'
        id: string
        copy?: string | null
        name?: string | null
        role?: string | null
        thumbnailUrl?: string | null
      }>
    }>
  } | null
  enrollmentSeason?: {
    __typename: 'Season'
    id: string
    year: string
    name: string
  } | null
  lastActiveSeason?: { __typename: 'Season'; id: string } | null
  upcomingSeason?: { __typename: 'Season'; id: string } | null
}

export type GetProgramSelectorInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetProgramSelectorInfoQuery = {
  __typename: 'Query'
  lastActiveSeason?: {
    __typename: 'Season'
    id: string
    enrollmentsCloseAt?: string | null
    hasStarted: boolean
    prettyNameSeasonFirst: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
  } | null
  upcomingSeason?: {
    __typename: 'Season'
    hasStarted: boolean
    prettyNameSeasonFirst: string
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
    faqs?: Array<{
      __typename: 'Faq'
      seasonId: string
      question: string
      answer: string
      questionNumber: number
    }> | null
  } | null
  enrollmentSeason?: {
    __typename: 'Season'
    hasStarted: boolean
    prettyNameSeasonFirst: string
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    startsAt?: string | null
    year: string
    showCohortViewerAt?: string | null
    faqs?: Array<{
      __typename: 'Faq'
      seasonId: string
      question: string
      answer: string
      questionNumber: number
    }> | null
  } | null
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    shouldSeeTrialMessaging: boolean
    is: {
      __typename: 'UserIs'
      alumni: boolean
      paidMember: boolean
      enrolledInActiveCourse: boolean
      enrolledInLiveProgram: boolean
      enrolledInEnrollmentSeason: boolean
      member: boolean
    }
    can: { __typename: 'UserCan'; enrollInCohort: boolean }
    profile: {
      __typename: 'UserProfile'
      aboutMeText?: string | null
      allowDirectMessages?: boolean | null
      areasOfExpertiseTags?: string | null
      askMeAboutText?: string | null
      avatarUrl: string
      businessModelTags?: string | null
      communityInterestTags?: string | null
      communityInterestText?: string | null
      companyAudienceTags?: string | null
      companyName?: string | null
      companyProductText?: string | null
      firstName?: string | null
      lastName?: string | null
      learningGoalsText?: string | null
      linkedin?: string | null
      location?: string | null
      responseTime?: string | null
      onboardingStep: number
      role?: string | null
      timezone?: string | null
      twitter?: string | null
      previousCompanies?: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; id: string; name?: string | null }
      }> | null
    }
    pricing: { __typename: 'UserPricing'; term: string }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        expiresAt: string
        id: string
        isCanceled?: boolean | null
        numSeats: number
        status?: string | null
        stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
      } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        daysLeftInTrial?: number | null
      } | null
    } | null
    cohorts?: {
      __typename: 'UserCohorts'
      previous: Array<{
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
        season: string
        canBeUpdated: boolean
      }>
      latestCurrent?: {
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
        season: string
        seasonId: string
        canBeUpdated: boolean
      } | null
      current: Array<{
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
        season: string
        seasonId: string
        canBeUpdated: boolean
        cohort: {
          __typename: 'Cohort'
          id: string
          eventTimes?: Array<{
            __typename: 'CohortEventTimes'
            count: number
            firstStartDate: string
          }> | null
        }
      }>
    } | null
    recommendedPrograms: Array<{
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      heroImageUrl?: string | null
      launched: boolean
      notLaunched?: boolean | null
      enrolled: boolean
      programImage?: string | null
      name: string
      recommendationRanking?: number | null
      whoItsFor?: string | null
      slug: string
      byTheEnd?: string | null
      synopsis?: string | null
      categoryNames?: Array<string> | null
      canEnroll: boolean
      launchAt?: string | null
      testimonials: Array<{
        __typename: 'Testimonial'
        id: string
        copy?: string | null
        name?: string | null
        role?: string | null
        thumbnailUrl?: string | null
      }>
      cohort?: {
        __typename: 'Cohort'
        id: string
        endsAt?: string | null
        startsAt?: string | null
        durationInWeeks?: number | null
        weekCount: number
        eventTimes?: Array<{
          __typename: 'CohortEventTimes'
          firstStartDate: string
          firstEndDate?: string | null
          count: number
        }> | null
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          title?: string | null
          bio?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
        }> | null
        programCollaborators?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          title?: string | null
          bio?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
        }> | null
        scheduledWeeks: Array<{
          __typename: 'ScheduledWeek'
          id: string
          core?: boolean | null
          description?: string | null
          title?: string | null
          bonus?: boolean | null
          postCohort: boolean
          startsAt?: string | null
          endsAt?: string | null
          numLessons?: number | null
          numModules?: number | null
          events: Array<{
            __typename: 'Event'
            startsAtUtc: string
            endsAtUtc: string
            kind: string
          }>
        }>
      } | null
    }>
  } | null
}

export type ModifyEnrollmentInfoQueryVariables = Exact<{ [key: string]: never }>

export type ModifyEnrollmentInfoQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    is: { __typename: 'UserIs'; member: boolean }
    cohorts?: {
      __typename: 'UserCohorts'
      latestCurrent?: {
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
      } | null
    } | null
  } | null
}

export type GetEnrollmentSeasonQueryVariables = Exact<{ [key: string]: never }>

export type GetEnrollmentSeasonQuery = {
  __typename: 'Query'
  enrollmentSeason?: {
    __typename: 'Season'
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    id: string
    name: string
    hasStarted: boolean
    prettyNameSeasonFirst: string
    nextSeasonName: string
    openForEnrollment: boolean
    showCohortViewerAt?: string | null
    startsAt?: string | null
    year: string
  } | null
}

export type EventCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  accessPolicyKind: string
  can: {
    __typename: 'UserCan'
    viewEvent?: boolean | null
    accessAllCohortEventReplays: boolean
  }
  profile: {
    __typename: 'UserProfile'
    avatarUrl: string
    firstName?: string | null
    hasBadge?: boolean | null
    kind?: string | null
    timezone?: string | null
  }
  cohorts?: {
    __typename: 'UserCohorts'
    all: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }
    }>
    current: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
        }
      }
    }>
    previous: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
        }
      }
    }>
  } | null
}

export type UserProfilePartsFragment = {
  __typename: 'UserProfile'
  fullName?: string | null
  companyName?: string | null
  avatarUrl: string
  role?: string | null
  twitter?: string | null
  linkedin?: string | null
  hasBadge?: boolean | null
  kind?: string | null
  location?: string | null
  onboardingStep: number
  profileUrl?: string | null
  status: UserStatus
}

export type SpeakerPartsFragment = {
  __typename: 'EventSpeaker'
  id: string
  kind?: string | null
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      companyName?: string | null
      avatarUrl: string
      role?: string | null
      twitter?: string | null
      linkedin?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      location?: string | null
      onboardingStep: number
      profileUrl?: string | null
      status: UserStatus
    }
  }
}

export type EventExpertHostsPartsFragment = {
  __typename: 'EventSpeaker'
  id: string
  hostedEventsCount: number
  kind?: string | null
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      companyName?: string | null
      avatarUrl: string
      role?: string | null
      twitter?: string | null
      linkedin?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      location?: string | null
      onboardingStep: number
      profileUrl?: string | null
      status: UserStatus
    }
  }
}

export type EventCohortsFragment = {
  __typename: 'Cohort'
  id: string
  slug: string
  season: { __typename: 'Season'; showCohortViewerAt?: string | null }
}

export type EventPartsFragment = {
  __typename: 'Event'
  id: string
  name: string
  slug: string
  kind: string
  past: boolean
  featuredImageUrl?: string | null
  willBeRecorded: boolean
  timezone?: string | null
  startsAtUtc: string
  endsAtUtc: string
  uuid: string
  attendeesCount?: number | null
  attendingStatus?: string | null
  zoomLink?: string | null
  description?: string | null
  lastViewedAt?: string | null
  isPublic?: boolean | null
  summaryWistiaCodeOptional?: string | null
  cmsProgramIdOptional?: string | null
  attendees: {
    __typename: 'EventAttendeeConnection'
    edges: Array<{
      __typename: 'EventAttendeeEdge'
      node: {
        __typename: 'EventAttendee'
        id: string
        hasBadge: boolean
        kind?: string | null
        status: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }
    }>
  }
  cohorts: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    season: { __typename: 'Season'; showCohortViewerAt?: string | null }
  }>
  cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
  speakers?: Array<{
    __typename: 'EventSpeaker'
    id: string
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        companyName?: string | null
        avatarUrl: string
        role?: string | null
        twitter?: string | null
        linkedin?: string | null
        hasBadge?: boolean | null
        kind?: string | null
        location?: string | null
        onboardingStep: number
        profileUrl?: string | null
        status: UserStatus
      }
    }
  }> | null
  hosts: Array<{
    __typename: 'EventSpeaker'
    id: string
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        companyName?: string | null
        avatarUrl: string
        role?: string | null
        twitter?: string | null
        linkedin?: string | null
        hasBadge?: boolean | null
        kind?: string | null
        location?: string | null
        onboardingStep: number
        profileUrl?: string | null
        status: UserStatus
      }
    }
  }>
  featuredGuests: Array<{
    __typename: 'EventSpeaker'
    id: string
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        companyName?: string | null
        avatarUrl: string
        role?: string | null
        twitter?: string | null
        linkedin?: string | null
        hasBadge?: boolean | null
        kind?: string | null
        location?: string | null
        onboardingStep: number
        profileUrl?: string | null
        status: UserStatus
      }
    }
  }>
}

export type EventAttendeePartsFragment = {
  __typename: 'EventAttendee'
  id: string
  hasBadge: boolean
  kind?: string | null
  status: string
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      companyName?: string | null
      avatarUrl: string
      role?: string | null
      twitter?: string | null
      linkedin?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      location?: string | null
      onboardingStep: number
      profileUrl?: string | null
      status: UserStatus
    }
  }
}

export type PublicEventsQueryVariables = Exact<{ [key: string]: never }>

export type PublicEventsQuery = {
  __typename: 'Query'
  publicEvents?: Array<{
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    featuredImageUrl?: string | null
    willBeRecorded: boolean
    timezone?: string | null
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendeesCount?: number | null
    attendingStatus?: string | null
    zoomLink?: string | null
    description?: string | null
    lastViewedAt?: string | null
    isPublic?: boolean | null
    summaryWistiaCodeOptional?: string | null
    cmsProgramIdOptional?: string | null
    attendees: {
      __typename: 'EventAttendeeConnection'
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }> | null
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  }> | null
}

export type UserEventsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserEventsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    accessPolicyKind: string
    enrolledCohortEvents: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }>
    eventsAttending?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
    eventsNotAttending?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
    eventsRecommended?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
    eventsRecentlyInteracted: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }>
    eventsHosted: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }>
    upcomingEligibleEvents: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }>
    can: {
      __typename: 'UserCan'
      viewEvent?: boolean | null
      accessAllCohortEventReplays: boolean
    }
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      firstName?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      timezone?: string | null
    }
    cohorts?: {
      __typename: 'UserCohorts'
      all: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }
      }>
      current: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
          }
        }
      }>
      previous: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
          }
        }
      }>
    } | null
  } | null
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    accessPolicyKind: string
    can: {
      __typename: 'UserCan'
      viewEvent?: boolean | null
      accessAllCohortEventReplays: boolean
    }
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      firstName?: string | null
      hasBadge?: boolean | null
      kind?: string | null
      timezone?: string | null
    }
    cohorts?: {
      __typename: 'UserCohorts'
      all: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }
      }>
      current: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
          }
        }
      }>
      previous: Array<{
        __typename: 'UserCohort'
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
          }
        }
      }>
    } | null
  } | null
}

export type UserStartingSoonEventsQueryVariables = Exact<{ [key: string]: never }>

export type UserStartingSoonEventsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    eventsAttendingStartingSoon: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }>
  } | null
}

export type EventExpertHostsQueryVariables = Exact<{ [key: string]: never }>

export type EventExpertHostsQuery = {
  __typename: 'Query'
  eventExpertHosts?: Array<{
    __typename: 'EventSpeaker'
    id: string
    hostedEventsCount: number
    kind?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        companyName?: string | null
        avatarUrl: string
        role?: string | null
        twitter?: string | null
        linkedin?: string | null
        hasBadge?: boolean | null
        kind?: string | null
        location?: string | null
        onboardingStep: number
        profileUrl?: string | null
        status: UserStatus
      }
    }
  }> | null
}

export type ExpertHostFeaturedEventsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type ExpertHostFeaturedEventsQuery = {
  __typename: 'Query'
  expertHostFeaturedEvents?: Array<{
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    featuredImageUrl?: string | null
    willBeRecorded: boolean
    timezone?: string | null
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendeesCount?: number | null
    attendingStatus?: string | null
    zoomLink?: string | null
    description?: string | null
    lastViewedAt?: string | null
    isPublic?: boolean | null
    summaryWistiaCodeOptional?: string | null
    cmsProgramIdOptional?: string | null
    attendees: {
      __typename: 'EventAttendeeConnection'
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }> | null
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  }> | null
}

export type EventsPastFeedQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<EventFilters>
}>

export type EventsPastFeedQuery = {
  __typename: 'Query'
  eventsPastFeed: {
    __typename: 'EventsPastFeed'
    count?: number | null
    events?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
  }
}

export type UserEventsPastFeedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  filters?: InputMaybe<EventFilters>
}>

export type UserEventsPastFeedQuery = {
  __typename: 'Query'
  userEventsPastFeed: {
    __typename: 'UserEventsPastFeed'
    count?: number | null
    events?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
  }
}

export type PopularEventsPastFeedQueryVariables = Exact<{ [key: string]: never }>

export type PopularEventsPastFeedQuery = {
  __typename: 'Query'
  popularEventsPastFeed: {
    __typename: 'PopularEventsPastFeed'
    events?: Array<{
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      featuredImageUrl?: string | null
      willBeRecorded: boolean
      timezone?: string | null
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendeesCount?: number | null
      attendingStatus?: string | null
      zoomLink?: string | null
      description?: string | null
      lastViewedAt?: string | null
      isPublic?: boolean | null
      summaryWistiaCodeOptional?: string | null
      cmsProgramIdOptional?: string | null
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
      speakers?: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }> | null
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    }> | null
  }
}

export type EventQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['ID']>
  eventUuid?: InputMaybe<Scalars['String']>
}>

export type EventQuery = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    prep: string
    description?: string | null
    eventFormat: string
    attendeesCount?: number | null
    attendingStatus?: string | null
    googleCalendarEndsAt?: string | null
    googleCalendarStartsAt?: string | null
    isFeaturedGuest: boolean
    recordingUrl?: string | null
    summaryTranscript?: string | null
    timeUntil?: string | null
    zoomLink?: string | null
    lastViewedAt?: string | null
    kind: string
    preread?: string | null
    isPublic?: boolean | null
    name: string
    slug: string
    past: boolean
    featuredImageUrl?: string | null
    willBeRecorded: boolean
    timezone?: string | null
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    summaryWistiaCodeOptional?: string | null
    cmsProgramIdOptional?: string | null
    caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
    eventPrework?: {
      __typename: 'EventPrework'
      id: string
      applicationOpen?: boolean | null
      includeTemplateLink?: boolean | null
      templateLinkUrl?: string | null
      templateLinkCTA?: string | null
      instructions?: string | null
      helperText: string
      submissionTitle?: string | null
      submissionDescription?: string | null
      submissionPrompt?: string | null
      preworkContext?: string | null
      featuredGuestSlots?: number | null
    } | null
    eventPreworkSubmission?: {
      __typename: 'EventPreworkSubmission'
      status?: string | null
    } | null
    rsvp?: {
      __typename: 'EventAttendee'
      id: string
      hasBadge: boolean
      kind?: string | null
      status: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    } | null
    attendees: {
      __typename: 'EventAttendeeConnection'
      pageInfo: {
        __typename: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    cmsProgramOptional?: { __typename: 'CmsProgram'; id: string; name: string } | null
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }> | null
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  }
}

export type EventAttendeesQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['ID']>
  eventUuid?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  after?: InputMaybe<Scalars['String']>
}>

export type EventAttendeesQuery = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    attendees: {
      __typename: 'EventAttendeeConnection'
      pageInfo: {
        __typename: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        cursor: string
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
  }
}

export type AttendeesFeedQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}>

export type AttendeesFeedQuery = {
  __typename: 'Query'
  attendeesFeed?: {
    __typename: 'AttendeesFeed'
    count?: number | null
    totalCount?: number | null
    attendees?: Array<{
      __typename: 'EventAttendee'
      id: string
      hasBadge: boolean
      kind?: string | null
      status: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }> | null
  } | null
}

export type EventsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type EventsByIdsQuery = {
  __typename: 'Query'
  eventsByIds: Array<{
    __typename: 'Event'
    id: string
    name: string
    willBeRecorded: boolean
    preread?: string | null
    startsAtUtc: string
    endsAtUtc: string
    attendingStatus?: string | null
    past: boolean
    summaryWistiaCode?: string | null
    slug: string
    kind: string
    uuid: string
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          companyName?: string | null
          fullName?: string | null
          avatarUrl: string
        }
      }
    }> | null
    caseCompany?: { __typename: 'Company'; id: string; name?: string | null } | null
  }>
}

export type EventVideoRecordingQueryVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventVideoRecordingQuery = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    summaryWistiaCode?: string | null
    summaryTranscript?: string | null
  }
}

export type EventDetailsRsvpQueryVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventDetailsRsvpQuery = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    attendeesCount?: number | null
    attendees: {
      __typename: 'EventAttendeeConnection'
      pageInfo: {
        __typename: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    rsvp?: { __typename: 'EventAttendee'; id: string; status: string } | null
  }
}

export type EventRsvpQueryVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventRsvpQuery = {
  __typename: 'Query'
  eventRsvp?: { __typename: 'EventAttendee'; status: string } | null
}

export type RsvpMutationVariables = Exact<{
  input: CreateInput
}>

export type RsvpMutation = {
  __typename: 'Mutation'
  rsvp?: {
    __typename: 'CreatePayload'
    clientMutationId?: string | null
    errors?: Array<string> | null
    attendee?: { __typename: 'EventAttendee'; status: string } | null
  } | null
}

export type CancelRsvpMutationVariables = Exact<{
  input: CancelInput
}>

export type CancelRsvpMutation = {
  __typename: 'Mutation'
  cancelRsvp?: {
    __typename: 'CancelPayload'
    clientMutationId?: string | null
    errors?: Array<string> | null
  } | null
}

export type NotAttendingMutationVariables = Exact<{
  input: NotAttendingInput
}>

export type NotAttendingMutation = {
  __typename: 'Mutation'
  notAttending?: {
    __typename: 'NotAttendingPayload'
    clientMutationId?: string | null
    errors?: Array<string> | null
    attendee?: {
      __typename: 'EventAttendee'
      avatarUrl: string
      hasBadge: boolean
      id: string
      kind?: string | null
      status: string
    } | null
  } | null
}

export type EventCountdownPageAttendeePartsFragment = {
  __typename: 'EventAttendee'
  id: string
  hasBadge: boolean
  kind?: string | null
  status: string
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null; avatarUrl: string }
  }
}

export type EventCountdownPageEventPartsFragment = {
  __typename: 'Event'
  id: string
  startsAtUtc: string
  endsAtUtc: string
  zoomLink?: string | null
  slug: string
  timeUntil?: string | null
  kind: string
  name: string
  prep: string
  attendeesCount?: number | null
  isPublic?: boolean | null
  rsvp?: { __typename: 'EventAttendee'; id: string } | null
  cohorts: Array<{
    __typename: 'Cohort'
    id: string
    slug: string
    season: { __typename: 'Season'; showCohortViewerAt?: string | null }
  }>
  attendees: {
    __typename: 'EventAttendeeConnection'
    pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
    edges: Array<{
      __typename: 'EventAttendeeEdge'
      node: {
        __typename: 'EventAttendee'
        id: string
        hasBadge: boolean
        kind?: string | null
        status: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
          }
        }
      }
    }>
  }
}

export type EventCountdownPageQueryVariables = Exact<{
  eventUuid?: InputMaybe<Scalars['String']>
}>

export type EventCountdownPageQuery = {
  __typename: 'Query'
  event: {
    __typename: 'Event'
    id: string
    startsAtUtc: string
    endsAtUtc: string
    zoomLink?: string | null
    slug: string
    timeUntil?: string | null
    kind: string
    name: string
    prep: string
    attendeesCount?: number | null
    isPublic?: boolean | null
    rsvp?: { __typename: 'EventAttendee'; id: string } | null
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    attendees: {
      __typename: 'EventAttendeeConnection'
      pageInfo: {
        __typename: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              avatarUrl: string
            }
          }
        }
      }>
    }
  }
}

export type EventRelatedResourcesQueryVariables = Exact<{
  eventId: Scalars['ID']
}>

export type EventRelatedResourcesQuery = {
  __typename: 'Query'
  eventRelatedResources: Array<
    | {
        __typename: 'Artifact'
        id: string
        companyName?: string | null
        companyLogoUrl?: string | null
        thumbnailUrl?: string | null
        description?: any | null
        viewCount?: number | null
        commentCount?: number | null
        artifactTitle?: string | null
        artifactSlug?: string | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          position?: string | null
          companyName?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      }
    | {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      }
    | {
        __typename: 'MarketingCourse'
        id: string
        startDate?: string | null
        subtitle?: string | null
        duration?: string | null
        courseType?: string | null
        marketingCourseTitle?: string | null
        marketingCourseSlug?: string | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          role?: string | null
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            slug?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          isAvailableOnDemand?: boolean | null
          slug: string
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
      }
  >
}

export type FeaturedPostsQueryVariables = Exact<{ [key: string]: never }>

export type FeaturedPostsQuery = {
  __typename: 'Query'
  featuredPosts: Array<{
    __typename: 'FeaturedPost'
    id: string
    kind: string
    startsAt: string
    post: {
      __typename: 'Post'
      allReactionsCount?: number | null
      createdAt: string
      href: string
      id: string
      responseCount: number
      title?: string | null
      user: {
        __typename: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        role?: string | null
        companyName?: string | null
      }
    }
  }>
}

export type FilteredUserSearchFieldsFragment = {
  __typename: 'FilteredUserSearch'
  id: string
  avatarUrl: string
  companyAudience?: string | null
  companyName?: string | null
  createdAt?: string | null
  fullName?: string | null
  hasBadge: boolean
  isExpired: boolean
  kind?: string | null
  location?: string | null
  mostRecentProgram?: string | null
  role?: string | null
  status: UserStatus
  slug?: string | null
}

export type PaginationFieldsFragment = {
  __typename: 'Pagination'
  totalEntries: number
  nextPage?: number | null
}

export type MembersUserCohortPartFragment = {
  __typename: 'UserCohort'
  cohort: {
    __typename: 'Cohort'
    id: string
    cmsProgram: { __typename: 'CmsProgram'; name: string }
    season: { __typename: 'Season'; name: string; year: string }
  }
}

export type FilteredUserSearchQueryVariables = Exact<{
  filters?: InputMaybe<UserSearchFilters>
  page?: InputMaybe<Scalars['Int']>
}>

export type FilteredUserSearchQuery = {
  __typename: 'Query'
  filteredUsers: {
    __typename: 'FilteredUserSearchList'
    members: Array<{
      __typename: 'FilteredUserSearch'
      id: string
      avatarUrl: string
      companyAudience?: string | null
      companyName?: string | null
      createdAt?: string | null
      fullName?: string | null
      hasBadge: boolean
      isExpired: boolean
      kind?: string | null
      location?: string | null
      mostRecentProgram?: string | null
      role?: string | null
      status: UserStatus
      slug?: string | null
    }>
    pagination: {
      __typename: 'Pagination'
      totalEntries: number
      nextPage?: number | null
    }
  }
  userCohorts: Array<{
    __typename: 'UserCohort'
    cohort: {
      __typename: 'Cohort'
      id: string
      cmsProgram: { __typename: 'CmsProgram'; name: string }
      season: { __typename: 'Season'; name: string; year: string }
    }
  }>
}

export type FindBookmarkByIdQueryVariables = Exact<{
  bookmarkId: Scalars['ID']
}>

export type FindBookmarkByIdQuery = {
  __typename: 'Query'
  findBookmarkById?: {
    __typename: 'Bookmark'
    id: string
    eventId?: string | null
    lessonPosition?: number | null
    sanityId?: string | null
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    anchor?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    updated?: string | null
    created?: string | null
    title?: string | null
    externalUrl?: string | null
    metadata?: any | null
    startOffset?: number | null
    endOffset?: number | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      name: string
      position?: number | null
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      name: string
      position?: number | null
      progressPercent?: number | null
      childrenCount: number
      completedChildrenCount?: number | null
      path?: string | null
    } | null
    content?: {
      __typename: 'CmsSection'
      id: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      numPhases?: number | null
      projectIcon?: string | null
      shortDescription?: string | null
      numBookmarks?: number | null
      showNewBadge: boolean
      slug: string
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      lessonNum?: number | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        slug?: string | null
        name: string
      } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
      progressPercent: number
      iconImageUrl?: string | null
      notLaunched?: boolean | null
      deprecatedAt?: string | null
      description?: string | null
      synopsis?: string | null
      lessonCount: number
      purchased: boolean
      upcoming: boolean
      enrolled: boolean
      canEnroll: boolean
      showNewBadge: boolean
      topic?: string | null
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
        companyName?: string | null
        description?: string | null
        title?: string | null
        avatarUrl: string
      }>
    } | null
    event?: {
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      willBeRecorded: boolean
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    } | null
    artifact?: {
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      companyLogoUrl?: string | null
      companyName?: string | null
      thumbnailUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarUrl?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
        } | null
      }> | null
    } | null
    course?: {
      __typename: 'MarketingCourse'
      id: string
      slug?: string | null
      cmsProgramSlug?: string | null
      startDate?: string | null
      subtitle?: string | null
      title?: string | null
      legacyProgramId?: string | null
      bookmarkId?: string | null
      courseType?: string | null
      course?: {
        __typename: 'SanityCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        bookmarkId?: string | null
        isAvailableOnDemand?: boolean | null
        creators?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    } | null
    guide?: {
      __typename: 'Unit'
      id: string
      title?: string | null
      whatYouWillDo?: any | null
      estimatedTimeInMinutes?: number | null
      stepsCount?: number | null
      slug?: string | null
      contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        slug?: string | null
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  } | null
}

export type AvatarFragmentFragment = {
  __typename: 'User'
  id: string
  profile: {
    __typename: 'UserProfile'
    avatarUrl: string
    hasBadge?: boolean | null
    kind?: string | null
  }
}

export type ContentLinkInfoPartsFragment = {
  __typename: 'CmsSection'
  id: string
  href: string
  name: string
  contentType?: CmsSectionContentType | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    notLaunched?: boolean | null
    enrolled: boolean
    slug: string
  }
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type UserInfoProfilePartsFragment = {
  __typename: 'UserProfile'
  companyName?: string | null
  role?: string | null
  location?: string | null
  firstName?: string | null
  lastName?: string | null
  linkedin?: string | null
  twitter?: string | null
  kind?: string | null
  previousCompanies?: Array<{
    __typename: 'UserCompany'
    id: string
    company: { __typename: 'Company'; id: string; name?: string | null }
  }> | null
}

export type UserInfoPartsFragment = {
  __typename: 'User'
  id: string
  is: { __typename: 'UserIs'; badgeholder: boolean }
  profile: {
    __typename: 'UserProfile'
    avatarUrl: string
    hasBadge?: boolean | null
    kind?: string | null
    companyName?: string | null
    role?: string | null
    location?: string | null
    firstName?: string | null
    lastName?: string | null
    linkedin?: string | null
    twitter?: string | null
    previousCompanies?: Array<{
      __typename: 'UserCompany'
      id: string
      company: { __typename: 'Company'; id: string; name?: string | null }
    }> | null
  }
}

export type UserHeadingProfilePartsFragment = {
  __typename: 'UserProfile'
  status: UserStatus
  hasBadge?: boolean | null
  role?: string | null
  companyName?: string | null
  kind?: string | null
  avatarUrl: string
  slug?: string | null
  location?: string | null
  fullName?: string | null
  previousCompanies?: Array<{
    __typename: 'UserCompany'
    id: string
    company: { __typename: 'Company'; id: string; name?: string | null }
  }> | null
}

export type LessonCardContentPartsFragment = {
  __typename: 'CmsSection'
  id: string
  accessLevel?: string | null
  contentTypeIcon?: string | null
  estimatedTime: number
  heroImageUrl?: string | null
  name: string
  showNewBadge: boolean
  shortDescription?: string | null
  numBookmarks?: number | null
  lastViewedAt?: string | null
  contentSubtype?: string | null
  href: string
  contentType?: CmsSectionContentType | null
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    notLaunched?: boolean | null
    enrolled: boolean
    slug: string
  }
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type ContentCardPartsFragment = {
  __typename: 'CmsSection'
  id: string
  conceptIcon?: string | null
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  cmsModuleId: string
  cmsProgramId: string
  estimatedTime: number
  href: string
  heroImageUrl?: string | null
  name: string
  numBookmarks?: number | null
  numPhases?: number | null
  showNewBadge: boolean
  shortDescription?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  unlimitedAccess: boolean
  slug: string
  lessonNum?: number | null
  accessLevel?: string | null
  previewable: boolean
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    name: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
}

export type SubscriptionReactivationFieldsFragment = {
  __typename: 'Subscription'
  id: string
  daysLeft: number
  expiresAt: string
  isCanceled?: boolean | null
  isPaidMonthly: boolean
  isTeam: boolean
  numSeats: number
  renewSeatCount: number
  userIsOwner: boolean
  hasUnlimitedCohortAccess?: boolean | null
}

export type UpcomingSeasonEnrollmentFieldsFragment = {
  __typename: 'Season'
  id: string
  activeAt?: string | null
  endsAt?: string | null
  enrollmentsCloseAt?: string | null
  enrollmentsOpenAt?: string | null
  name: string
  nextSeasonName: string
  npsSurveyLink?: string | null
  openForEnrollment: boolean
  startsAt?: string | null
  year: string
}

export type CohortsShowingNpsSurveySeasonFieldsFragment = {
  __typename: 'Season'
  id: string
  name: string
  year: string
  npsSurveyLink?: string | null
  npsSurveyText?: string | null
}

export type SubscriptionCancelingFieldsFragment = {
  __typename: 'Subscription'
  id: string
  daysLeft: number
  expiresAt: string
  isCanceled?: boolean | null
  isPaidMonthly: boolean
  isTeam: boolean
  numSeats: number
  renewSeatCount: number
  userIsOwner: boolean
  hasUnlimitedCohortAccess?: boolean | null
  user: { __typename: 'User'; id: string; email: string }
}

export type SubscriptionTrialBannerFieldsFragment = {
  __typename: 'Subscription'
  id: string
  status?: string | null
  daysLeftInTrial?: number | null
}

export type SeasonNameFieldsFragment = {
  __typename: 'Season'
  id: string
  name: string
  year: string
}

export type GroupMembersQueryVariables = Exact<{
  slug: Scalars['String']
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
}>

export type GroupMembersQuery = {
  __typename: 'Query'
  groupMembers: {
    __typename: 'GroupMemberList'
    members: Array<{
      __typename: 'FilteredUserSearch'
      avatarUrl: string
      companyAudience?: string | null
      id: string
      createdAt?: string | null
      companyName?: string | null
      fullName?: string | null
      hasBadge: boolean
      isExpired: boolean
      kind?: string | null
      location?: string | null
      mostRecentProgram?: string | null
      role?: string | null
      slug?: string | null
      status: UserStatus
    }>
    pagination: {
      __typename: 'Pagination'
      currentPage: number
      nextPage?: number | null
      previousPage?: number | null
      totalEntries: number
      totalPages: number
    }
  }
}

export type MemberStackUserFragment = {
  __typename: 'User'
  id: string
  slug?: string | null
  fullName?: string | null
  kind?: string | null
  avatarUrl: string
  hasBadge: boolean
  isCurrentUser: boolean
}

export type GroupsListGroupTopicFragment = {
  __typename: 'PostTopic'
  id: string
  kind?: string | null
  slug: string
  title: string
  topicId?: string | null
  groupId?: string | null
}

export type GroupsListGroupIndustryFragment = {
  __typename: 'Industry'
  id: string
  name?: string | null
  slug?: string | null
}

export type GroupsListGroupCategoryFragment = {
  __typename: 'GroupCategory'
  id: string
  category?: string | null
  description?: string | null
}

export type GroupsListGroupFragment = {
  __typename: 'Group'
  id: string
  title: string
  slug: string
  userIsMember: boolean
  membersCount: number
  memberStack: Array<{
    __typename: 'User'
    id: string
    slug?: string | null
    fullName?: string | null
    kind?: string | null
    avatarUrl: string
    hasBadge: boolean
    isCurrentUser: boolean
  }>
  topics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    slug: string
    title: string
    topicId?: string | null
    groupId?: string | null
  }>
  populatedTopics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    slug: string
    title: string
    topicId?: string | null
    groupId?: string | null
  }>
  industries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
  populatedIndustries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
  groupCategory?: {
    __typename: 'GroupCategory'
    id: string
    category?: string | null
    description?: string | null
  } | null
}

export type GroupsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  onlyWithoutMembership?: InputMaybe<Scalars['Boolean']>
}>

export type GroupsListQuery = {
  __typename: 'Query'
  groups: {
    __typename: 'GroupFeed'
    groups: Array<{
      __typename: 'Group'
      id: string
      title: string
      slug: string
      userIsMember: boolean
      membersCount: number
      memberStack: Array<{
        __typename: 'User'
        id: string
        slug?: string | null
        fullName?: string | null
        kind?: string | null
        avatarUrl: string
        hasBadge: boolean
        isCurrentUser: boolean
      }>
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        kind?: string | null
        slug: string
        title: string
        topicId?: string | null
        groupId?: string | null
      }>
      populatedTopics: Array<{
        __typename: 'PostTopic'
        id: string
        kind?: string | null
        slug: string
        title: string
        topicId?: string | null
        groupId?: string | null
      }>
      industries: Array<{
        __typename: 'Industry'
        id: string
        name?: string | null
        slug?: string | null
      }>
      populatedIndustries: Array<{
        __typename: 'Industry'
        id: string
        name?: string | null
        slug?: string | null
      }>
      groupCategory?: {
        __typename: 'GroupCategory'
        id: string
        category?: string | null
        description?: string | null
      } | null
    }>
  }
}

export type GroupShowQueryVariables = Exact<{
  groupSlug: Scalars['String']
}>

export type GroupShowQuery = {
  __typename: 'Query'
  currentSeasonPretty: string
  group?: {
    __typename: 'Group'
    id: string
    title: string
    slug: string
    userIsMember: boolean
    membersCount: number
    memberStack: Array<{
      __typename: 'User'
      id: string
      slug?: string | null
      fullName?: string | null
      kind?: string | null
      avatarUrl: string
      hasBadge: boolean
      isCurrentUser: boolean
    }>
    topics: Array<{
      __typename: 'PostTopic'
      id: string
      kind?: string | null
      slug: string
      title: string
      topicId?: string | null
      groupId?: string | null
    }>
    populatedTopics: Array<{
      __typename: 'PostTopic'
      id: string
      kind?: string | null
      slug: string
      title: string
      topicId?: string | null
      groupId?: string | null
    }>
    industries: Array<{
      __typename: 'Industry'
      id: string
      name?: string | null
      slug?: string | null
    }>
    populatedIndustries: Array<{
      __typename: 'Industry'
      id: string
      name?: string | null
      slug?: string | null
    }>
    groupCategory?: {
      __typename: 'GroupCategory'
      id: string
      category?: string | null
      description?: string | null
    } | null
  } | null
}

export type GuidesBySanityTagGroupQueryVariables = Exact<{
  slug: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
}>

export type GuidesBySanityTagGroupQuery = {
  __typename: 'Query'
  guidesBySanityTagGroup: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    slug?: string | null
    previewable?: boolean | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    narrationFileUrl?: string | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      slug?: string | null
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  }>
}

export type GuidesForDiscoveryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
}>

export type GuidesForDiscoveryQuery = {
  __typename: 'Query'
  guidesForDiscovery: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    slug?: string | null
    previewable?: boolean | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    narrationFileUrl?: string | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      slug?: string | null
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  }>
}

export type AllGuidesWithSubtopicsQueryVariables = Exact<{ [key: string]: never }>

export type AllGuidesWithSubtopicsQuery = {
  __typename: 'Query'
  allGuidesWithSubtopics: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    topic?: {
      __typename: 'Topic'
      id?: string | null
      slug: string
      title: string
    } | null
  }>
}

export type UnitFragmentFragment = {
  __typename: 'Unit'
  id: string
  title?: string | null
  whatYouWillDo?: any | null
  estimatedTimeInMinutes?: number | null
  stepsCount?: number | null
  slug?: string | null
  previewable?: boolean | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  narrationFileUrl?: string | null
  heroImage?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
  tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    slug?: string | null
    name?: string | null
    avatarPhoto?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  }> | null
}

export type RelatedGuidesQueryVariables = Exact<{
  subtopicIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type RelatedGuidesQuery = {
  __typename: 'Query'
  relatedGuides: Array<{
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    overview?: any | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
    }> | null
  }>
}

export type ClassicsCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  shouldSeeTrialMessaging: boolean
  timezone?: string | null
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    enrolled?: boolean | null
    progress?: ProgramProgressScalar | null
  } | null
  can: {
    __typename: 'UserCan'
    viewConceptsIndex?: boolean | null
    viewPurchaseFlow?: boolean | null
    viewEvent?: boolean | null
    accessAllCohortEventReplays: boolean
    enrollInCohort: boolean
  }
  is: { __typename: 'UserIs'; premember: boolean; member: boolean; paidMember: boolean }
  profile: {
    __typename: 'UserProfile'
    avatarUrl: string
    firstName?: string | null
    hasBadge?: boolean | null
    kind?: string | null
    timezone?: string | null
  }
  cohorts?: {
    __typename: 'UserCohorts'
    all: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }
    }>
    current: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
        }
      }
    }>
    previous: Array<{
      __typename: 'UserCohort'
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
        }
      }
    }>
  } | null
}

export type CohortCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  joinSlackHref?: string | null
  shouldShowNpsSurvey?: boolean | null
  slackUserId?: string | null
  timezone?: string | null
  cohorts?: {
    __typename: 'UserCohorts'
    current: Array<{
      __typename: 'UserCohort'
      id: string
      canBeUpdated: boolean
      hasClickedMeetCohortParticipantsCta: Array<string>
      hasClickedShareSnapshotCta: boolean
      hasSignedStatementOfCommitment: boolean
      showEventRsvpModal: boolean
      hasPostedWelcome: boolean
      timeGroupingSelected?: string | null
      cohort: {
        __typename: 'Cohort'
        id: string
        postKickoff: boolean
        postCoreWeeksEnrollmentIsOpen: boolean
        slug: string
        startsAt?: string | null
        weekCount: number
        postCohort: boolean
        hasEnded: boolean
        slackChannelId?: string | null
        kickoffEvents: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        scheduledWeeks: Array<{
          __typename: 'ScheduledWeek'
          id: string
          bonus?: boolean | null
          postCohort: boolean
          startsAt?: string | null
          endsAt?: string | null
        }>
        events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        season: {
          __typename: 'Season'
          id: string
          name: string
          year: string
          npsSurveyLink?: string | null
        }
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
          title?: string | null
        }> | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          code?: string | null
          name: string
          heroImageUrl?: string | null
          programImage?: string | null
          synopsis?: string | null
          lessonCount: number
        }
        nextWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
        } | null
        thisWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          startsAt?: string | null
          endsAt?: string | null
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
          nextModule?: {
            __typename: 'CmsModule'
            estimatedTime: number
            href: string
            id: string
            name: string
            numBookmarks?: number | null
            numLessons: number
            programCode: string
            programIconImageUrl?: string | null
            progressPercent: number
            shortDescription?: string | null
            publishAt?: string | null
            published?: boolean | null
            clips?: Array<{
              __typename: 'Clip'
              id: string
              title: string
              duration?: number | null
              slug: string
              thumbnailUrl: string
              summary?: string | null
              summaryWistiaCode: string
              hasBeenViewedByCurrentUser: boolean
              users: Array<{
                __typename: 'User'
                id: string
                avatarUrl: string
                fullName?: string | null
                role?: string | null
                companyName?: string | null
                profile: { __typename: 'UserProfile'; profileUrl?: string | null }
              }>
            }> | null
          } | null
        } | null
        batchRsvpTimeData: Array<{
          __typename: 'BatchRsvpTimeData'
          ids: Array<string>
          startsAtUtc?: string | null
          endsAtUtc?: string | null
          timeGrouping?: string | null
        }>
      }
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    }>
    latestCurrent?: {
      __typename: 'UserCohort'
      id: string
      canBeUpdated: boolean
      hasClickedMeetCohortParticipantsCta: Array<string>
      hasClickedShareSnapshotCta: boolean
      hasSignedStatementOfCommitment: boolean
      showEventRsvpModal: boolean
      hasPostedWelcome: boolean
      timeGroupingSelected?: string | null
      cohort: {
        __typename: 'Cohort'
        id: string
        postKickoff: boolean
        postCoreWeeksEnrollmentIsOpen: boolean
        slug: string
        startsAt?: string | null
        weekCount: number
        postCohort: boolean
        hasEnded: boolean
        slackChannelId?: string | null
        kickoffEvents: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        scheduledWeeks: Array<{
          __typename: 'ScheduledWeek'
          id: string
          bonus?: boolean | null
          postCohort: boolean
          startsAt?: string | null
          endsAt?: string | null
        }>
        events: Array<{ __typename: 'Event'; id: string; startsAtUtc: string }>
        season: {
          __typename: 'Season'
          id: string
          name: string
          year: string
          npsSurveyLink?: string | null
        }
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
          title?: string | null
        }> | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          code?: string | null
          name: string
          heroImageUrl?: string | null
          programImage?: string | null
          synopsis?: string | null
          lessonCount: number
        }
        nextWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
        } | null
        thisWeek?: {
          __typename: 'ScheduledWeek'
          id: string
          startsAt?: string | null
          endsAt?: string | null
          events: Array<{
            __typename: 'Event'
            id: string
            name: string
            willBeRecorded: boolean
            preread?: string | null
            startsAtUtc: string
            endsAtUtc: string
            attendingStatus?: string | null
            past: boolean
            summaryWistiaCode?: string | null
            slug: string
            kind: string
            uuid: string
            speakers?: Array<{
              __typename: 'EventSpeaker'
              id: string
              kind?: string | null
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  companyName?: string | null
                  fullName?: string | null
                  avatarUrl: string
                }
              }
            }> | null
            caseCompany?: {
              __typename: 'Company'
              id: string
              name?: string | null
            } | null
          }>
          nextModule?: {
            __typename: 'CmsModule'
            estimatedTime: number
            href: string
            id: string
            name: string
            numBookmarks?: number | null
            numLessons: number
            programCode: string
            programIconImageUrl?: string | null
            progressPercent: number
            shortDescription?: string | null
            publishAt?: string | null
            published?: boolean | null
            clips?: Array<{
              __typename: 'Clip'
              id: string
              title: string
              duration?: number | null
              slug: string
              thumbnailUrl: string
              summary?: string | null
              summaryWistiaCode: string
              hasBeenViewedByCurrentUser: boolean
              users: Array<{
                __typename: 'User'
                id: string
                avatarUrl: string
                fullName?: string | null
                role?: string | null
                companyName?: string | null
                profile: { __typename: 'UserProfile'; profileUrl?: string | null }
              }>
            }> | null
          } | null
        } | null
        batchRsvpTimeData: Array<{
          __typename: 'BatchRsvpTimeData'
          ids: Array<string>
          startsAtUtc?: string | null
          endsAtUtc?: string | null
          timeGrouping?: string | null
        }>
      }
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    } | null
  } | null
}

export type HomePageUserPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  hasTeam?: boolean | null
  hideSlackBanner: boolean
  seenSlackBannerCount: number
  shouldSeeTrialMessaging: boolean
  onboarded: boolean
  avatarUrl: string
  inPostCohort: boolean
  activeSubscriptionMembership?: {
    __typename: 'SubscriptionMember'
    id: string
    subscription: {
      __typename: 'Subscription'
      id: string
      teamName?: string | null
      userId: string
      teamMembers: {
        __typename: 'TeamMembersSearch'
        users?: Array<{
          __typename: 'User'
          id: string
          avatarUrl: string
          profile: { __typename: 'UserProfile'; avatarUrl: string }
        }> | null
      }
    }
  } | null
  bookmarkFolders: Array<{
    __typename: 'BookmarkFolder'
    id: string
    name: string
    description?: string | null
    forkedFromId?: string | null
    goalComplete?: boolean | null
    isShared: boolean
    parentId?: string | null
    reforgeCollection: boolean
    teamProgress?: number | null
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
    sharedFolders: Array<{
      __typename: 'SharedFolder'
      id: string
      status: SharedFolderStatus
      sharedPartyId: string
      sharedPartyType: string
      userId: string
      bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
    }>
  }>
  is: {
    __typename: 'UserIs'
    alumni: boolean
    accountCreated: boolean
    enrolledInActiveCourse: boolean
    enrolledInEnrollmentSeason: boolean
    inPostCoursePeriod: boolean
    member: boolean
    paidMember: boolean
    premember: boolean
  }
  can: {
    __typename: 'UserCan'
    accessAllCohortEventReplays: boolean
    viewEvent?: boolean | null
    viewPost?: boolean | null
    viewProjectsIndex?: boolean | null
    viewCollectionsIndex?: boolean | null
    viewConceptsIndex?: boolean | null
    viewPurchaseFlow?: boolean | null
    enrollInCohort: boolean
  }
  profile: {
    __typename: 'UserProfile'
    completionPercentage?: number | null
    avatarUrl: string
    firstName?: string | null
    hasBadge?: boolean | null
    kind?: string | null
    timezone?: string | null
  }
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    enrolled?: boolean | null
    progress?: ProgramProgressScalar | null
  } | null
  programProgress?: {
    __typename: 'ProgramProgress'
    progress: ProgramProgressScalar
  } | null
  enrolledPrograms: Array<{
    __typename: 'CmsProgram'
    id: string
    name: string
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    slug: string
    lessonCount: number
    iconImageUrl?: string | null
    numBookmarks?: number | null
    progressPercent: number
    purchased: boolean
    programImage?: string | null
    description?: string | null
    upcoming: boolean
    enrolled: boolean
    canEnroll: boolean
    deprecatedAt?: string | null
    heroImageUrl?: string | null
    showNewBadge: boolean
    topic?: string | null
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      avatarUrl: string
    }>
  }>
}

export type UserTeamQueryVariables = Exact<{ [key: string]: never }>

export type UserTeamQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    currentTeam?: {
      __typename: 'Subscription'
      id: string
      teamName?: string | null
      userId: string
      teamMembers: {
        __typename: 'TeamMembersSearch'
        users?: Array<{
          __typename: 'User'
          id: string
          avatarUrl: string
          profile: { __typename: 'UserProfile'; avatarUrl: string }
        }> | null
      }
    } | null
  } | null
}

export type HomepageRecentlyViewedContentQueryVariables = Exact<{ [key: string]: never }>

export type HomepageRecentlyViewedContentQuery = {
  __typename: 'Query'
  homepageRecentlyViewedContent: Array<
    | {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        publishDate?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        description?: any | null
        companyName?: string | null
        companyLogoUrl?: string | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarUrl?: string | null
          position?: string | null
          companyName?: string | null
        }> | null
      }
    | {
        __typename: 'Content'
        accessLevel?: string | null
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        showNewBadge?: boolean | null
        cmsSection: {
          __typename: 'CmsSection'
          id: string
          slug: string
          conceptIcon?: string | null
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          cmsModuleId: string
          cmsProgramId: string
          estimatedTime: number
          href: string
          heroImageUrl?: string | null
          name: string
          numBookmarks?: number | null
          numPhases?: number | null
          showNewBadge: boolean
          shortDescription?: string | null
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          unlimitedAccess: boolean
          lessonNum?: number | null
          accessLevel?: string | null
          previewable: boolean
          lastViewedAt?: string | null
          contentSubtype?: string | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            programImage?: string | null
            notLaunched?: boolean | null
            name: string
            enrolled: boolean
            slug: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
          cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
          parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
        }
      }
    | {
        __typename: 'Unit'
        id: string
        title?: string | null
        slug?: string | null
        publishDate?: string | null
        stepsCount?: number | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
        contentIsInMySavedItems?: boolean | null
        contentBookmarkId?: string | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }
  >
}

export type IndustryFieldsFragment = {
  __typename: 'Industry'
  id: string
  name?: string | null
  slug?: string | null
}

export type IndustriesListQueryVariables = Exact<{ [key: string]: never }>

export type IndustriesListQuery = {
  __typename: 'Query'
  industries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
}

export type InlinePostsQueryVariables = Exact<{
  cmsSectionId: Scalars['ID']
  userId?: InputMaybe<Scalars['ID']>
}>

export type InlinePostsQuery = {
  __typename: 'Query'
  inlinePosts: Array<{
    __typename: 'InlinePost'
    anchor: string
    basedOn?: string | null
    body: string
    bodyText: string
    createdAtDate?: string | null
    id: string
    referenceImageUrl?: string | null
    title: string
    type: string
    userAvatar: string
    userId: string
    userFullName?: string | null
    userRoleWithCompany?: string | null
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: { __typename: 'User'; id: string; fullName?: string | null }
    }>
    replies: Array<{
      __typename: 'InlinePostReply'
      id: string
      body: string
      bodyText: string
      userAvatar: string
      userFullName?: string | null
      userId: string
      userRoleWithCompany?: string | null
      comments: Array<{
        __typename: 'Comment'
        id: string
        body?: string | null
        bodyText?: string | null
        user: {
          __typename: 'User'
          id: string
          fullName?: string | null
          avatarUrl: string
          roleWithCompany?: string | null
        }
      }>
    }>
    groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
    topics: Array<{
      __typename: 'PostTopic'
      id: string
      kind?: string | null
      slug: string
      title: string
      topicId?: string | null
      groupId?: string | null
    }>
  }>
}

export type PaymentRecordFieldsFragment = {
  __typename: 'PaymentRecord'
  id: string
  description: string
  status: string
  programName: string
  amount: number
  companyName?: string | null
  fullName?: string | null
}

export type InvoiceMemberFieldsFragment = {
  __typename: 'InvoiceMember'
  id: string
  email: string
  fullName: string
}

export type BillingInformationFragment = {
  __typename: 'BillingInformation'
  name?: string | null
  taxIds?: Array<string> | null
  address?: {
    __typename: 'StripeAddress'
    city: string
    country: string
    line1: string
    line2?: string | null
    postalCode: string
    state?: string | null
  } | null
}

export type StripeLineFragment = {
  __typename: 'StripeInvoiceLineItem'
  id: string
  amount: number
  amountExcludingTax: number
  description: string
  quantity: number
  taxAmount?: number | null
  taxRate?: number | null
  taxAmounts: Array<{ __typename: 'StripeTaxAmount'; amount: number; taxRateId: string }>
}

export type TaxAmountFragment = {
  __typename: 'StripeTaxAmount'
  amount: number
  taxRateId: string
  taxRate?: {
    __typename: 'StripeTaxRate'
    country?: string | null
    displayName?: string | null
    jurisdiction?: string | null
    percentage?: number | null
    state?: string | null
  } | null
}

export type StripeInvoiceFragment = {
  __typename: 'StripeInvoice'
  id: string
  date?: string | null
  dueDate?: string | null
  postPaymentCreditNotesAmount?: number | null
  subtotal: number
  subtotalExcludingTax: number
  tax?: number | null
  total: number
  totalExcludingTax?: number | null
  totalTaxAmounts: Array<{
    __typename: 'StripeTaxAmount'
    amount: number
    taxRateId: string
    taxRate?: {
      __typename: 'StripeTaxRate'
      country?: string | null
      displayName?: string | null
      jurisdiction?: string | null
      percentage?: number | null
      state?: string | null
    } | null
  }>
  lineItems: Array<{
    __typename: 'StripeInvoiceLineItem'
    id: string
    amount: number
    amountExcludingTax: number
    description: string
    quantity: number
    taxAmount?: number | null
    taxRate?: number | null
    taxAmounts: Array<{
      __typename: 'StripeTaxAmount'
      amount: number
      taxRateId: string
    }>
  }>
}

export type InvoiceFieldsFragment = {
  __typename: 'Invoice'
  id: string
  companyAddress?: string | null
  createdAt: string
  extraInformation?: string | null
  internalPoNumber?: string | null
  paymentRecord: {
    __typename: 'PaymentRecord'
    id: string
    description: string
    status: string
    programName: string
    amount: number
    companyName?: string | null
    fullName?: string | null
  }
  members: Array<{
    __typename: 'InvoiceMember'
    id: string
    email: string
    fullName: string
  }>
  billingInformation?: {
    __typename: 'BillingInformation'
    name?: string | null
    taxIds?: Array<string> | null
    address?: {
      __typename: 'StripeAddress'
      city: string
      country: string
      line1: string
      line2?: string | null
      postalCode: string
      state?: string | null
    } | null
  } | null
  stripeInvoice: {
    __typename: 'StripeInvoice'
    id: string
    date?: string | null
    dueDate?: string | null
    postPaymentCreditNotesAmount?: number | null
    subtotal: number
    subtotalExcludingTax: number
    tax?: number | null
    total: number
    totalExcludingTax?: number | null
    totalTaxAmounts: Array<{
      __typename: 'StripeTaxAmount'
      amount: number
      taxRateId: string
      taxRate?: {
        __typename: 'StripeTaxRate'
        country?: string | null
        displayName?: string | null
        jurisdiction?: string | null
        percentage?: number | null
        state?: string | null
      } | null
    }>
    lineItems: Array<{
      __typename: 'StripeInvoiceLineItem'
      id: string
      amount: number
      amountExcludingTax: number
      description: string
      quantity: number
      taxAmount?: number | null
      taxRate?: number | null
      taxAmounts: Array<{
        __typename: 'StripeTaxAmount'
        amount: number
        taxRateId: string
      }>
    }>
  }
}

export type InvoiceQueryVariables = Exact<{
  invoiceId: Scalars['ID']
}>

export type InvoiceQuery = {
  __typename: 'Query'
  invoice: {
    __typename: 'Invoice'
    id: string
    companyAddress?: string | null
    createdAt: string
    extraInformation?: string | null
    internalPoNumber?: string | null
    paymentRecord: {
      __typename: 'PaymentRecord'
      id: string
      description: string
      status: string
      programName: string
      amount: number
      companyName?: string | null
      fullName?: string | null
    }
    members: Array<{
      __typename: 'InvoiceMember'
      id: string
      email: string
      fullName: string
    }>
    billingInformation?: {
      __typename: 'BillingInformation'
      name?: string | null
      taxIds?: Array<string> | null
      address?: {
        __typename: 'StripeAddress'
        city: string
        country: string
        line1: string
        line2?: string | null
        postalCode: string
        state?: string | null
      } | null
    } | null
    stripeInvoice: {
      __typename: 'StripeInvoice'
      id: string
      date?: string | null
      dueDate?: string | null
      postPaymentCreditNotesAmount?: number | null
      subtotal: number
      subtotalExcludingTax: number
      tax?: number | null
      total: number
      totalExcludingTax?: number | null
      totalTaxAmounts: Array<{
        __typename: 'StripeTaxAmount'
        amount: number
        taxRateId: string
        taxRate?: {
          __typename: 'StripeTaxRate'
          country?: string | null
          displayName?: string | null
          jurisdiction?: string | null
          percentage?: number | null
          state?: string | null
        } | null
      }>
      lineItems: Array<{
        __typename: 'StripeInvoiceLineItem'
        id: string
        amount: number
        amountExcludingTax: number
        description: string
        quantity: number
        taxAmount?: number | null
        taxRate?: number | null
        taxAmounts: Array<{
          __typename: 'StripeTaxAmount'
          amount: number
          taxRateId: string
        }>
      }>
    }
  }
}

export type LearningGoalsQueryVariables = Exact<{ [key: string]: never }>

export type LearningGoalsQuery = {
  __typename: 'Query'
  reforgeCollections?: Array<{
    __typename: 'BookmarkFolder'
    id: string
    name: string
    isWhiteGlove: boolean
    user: { __typename: 'User'; id: string; fullName?: string | null }
  }> | null
  currentUser?: { __typename: 'User'; forkedFolderIds?: Array<string> | null } | null
}

export type LessonSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  accessLevel?: string | null
  contentType?: CmsSectionContentType | null
  isUnit: boolean
  contentTypeIcon?: string | null
  estimatedTime: number
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  heroImageUrl?: string | null
  href: string
  name: string
  showNewBadge: boolean
  slug: string
  previewable: boolean
  parentId?: string | null
  contentSubtype?: string | null
  cmsProgramId: string
  lastViewedAt?: string | null
  shortDescription?: string | null
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    enrolled: boolean
    slug: string
    name: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
}

export type LessonParentSectionFragment = {
  __typename: 'CmsSection'
  id: string
  slug: string
  accessLevel?: string | null
  contentType?: CmsSectionContentType | null
  isUnit: boolean
  contentTypeIcon?: string | null
  estimatedTime: number
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  heroImageUrl?: string | null
  href: string
  name: string
  showNewBadge: boolean
  previewable: boolean
  parentId?: string | null
  contentSubtype?: string | null
  cmsProgramId: string
  lastViewedAt?: string | null
  shortDescription?: string | null
  children: Array<{
    __typename: 'CmsSection'
    id: string
    accessLevel?: string | null
    contentType?: CmsSectionContentType | null
    isUnit: boolean
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    showNewBadge: boolean
    slug: string
    previewable: boolean
    parentId?: string | null
    contentSubtype?: string | null
    cmsProgramId: string
    lastViewedAt?: string | null
    shortDescription?: string | null
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  }>
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    enrolled: boolean
    slug: string
    name: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
}

export type LessonTocCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
  cmsSections: Array<{
    __typename: 'CmsSection'
    id: string
    slug: string
    accessLevel?: string | null
    contentType?: CmsSectionContentType | null
    isUnit: boolean
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    showNewBadge: boolean
    previewable: boolean
    parentId?: string | null
    contentSubtype?: string | null
    cmsProgramId: string
    lastViewedAt?: string | null
    shortDescription?: string | null
    children: Array<{
      __typename: 'CmsSection'
      id: string
      accessLevel?: string | null
      contentType?: CmsSectionContentType | null
      isUnit: boolean
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      showNewBadge: boolean
      slug: string
      previewable: boolean
      parentId?: string | null
      contentSubtype?: string | null
      cmsProgramId: string
      lastViewedAt?: string | null
      shortDescription?: string | null
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    }>
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  }>
}

export type LessonTocCmsProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  name: string
  slug: string
  noLessons: boolean
  launched: boolean
  releaseStartDate?: string | null
  upcoming: boolean
  iconImageUrl?: string | null
}

export type LessonTocUserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  progress?: ProgramProgressScalar | null
}

export type LessonTocExpertUserPartsFragment = {
  __typename: 'User'
  id: string
  hasBadge: boolean
  avatarUrl: string
  fullName?: string | null
  companyName?: string | null
  role?: string | null
  kind?: string | null
}

export type LessonTocPartsFragment = {
  __typename: 'LessonViewer'
  cmsModule?: {
    __typename: 'CmsModule'
    id: string
    name: string
    cmsSections: Array<{
      __typename: 'CmsSection'
      id: string
      slug: string
      accessLevel?: string | null
      contentType?: CmsSectionContentType | null
      isUnit: boolean
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      showNewBadge: boolean
      previewable: boolean
      parentId?: string | null
      contentSubtype?: string | null
      cmsProgramId: string
      lastViewedAt?: string | null
      shortDescription?: string | null
      children: Array<{
        __typename: 'CmsSection'
        id: string
        accessLevel?: string | null
        contentType?: CmsSectionContentType | null
        isUnit: boolean
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        showNewBadge: boolean
        slug: string
        previewable: boolean
        parentId?: string | null
        contentSubtype?: string | null
        cmsProgramId: string
        lastViewedAt?: string | null
        shortDescription?: string | null
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      }>
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    }>
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    slug: string
    noLessons: boolean
    launched: boolean
    releaseStartDate?: string | null
    upcoming: boolean
    iconImageUrl?: string | null
  } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    previewable: boolean
    nextSectionNameAndPath: Array<string>
  } | null
  userProgram?: {
    __typename: 'UserProgram'
    id: string
    progress?: ProgramProgressScalar | null
  } | null
  expertUsers?: Array<{
    __typename: 'User'
    id: string
    hasBadge: boolean
    avatarUrl: string
    fullName?: string | null
    companyName?: string | null
    role?: string | null
    kind?: string | null
  }> | null
  sameActiveUserCohort?: {
    __typename: 'UserCohort'
    id: string
    cohort: { __typename: 'Cohort'; id: string; slug: string }
  } | null
}

export type LessonFooterCmsProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  slug: string
  upcoming: boolean
}

export type LessonFooterCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  nextSectionNameAndPath: Array<string>
  nextSectionLearningCmsSectionPath?: string | null
  isUnit: boolean
  isResource: boolean
  nextSection?: {
    __typename: 'CmsSection'
    id: string
    countsTowardsCompletion: boolean
    estimatedTime: number
    heroImageUrl?: string | null
    position?: number | null
    name: string
    href: string
  } | null
}

export type LessonFooterCmsModulePartsFragment = { __typename: 'CmsModule'; id: string }

export type LessonFooterPartsFragment = {
  __typename: 'LessonViewer'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    slug: string
    upcoming: boolean
  } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    nextSectionNameAndPath: Array<string>
    nextSectionLearningCmsSectionPath?: string | null
    isUnit: boolean
    isResource: boolean
    nextSection?: {
      __typename: 'CmsSection'
      id: string
      countsTowardsCompletion: boolean
      estimatedTime: number
      heroImageUrl?: string | null
      position?: number | null
      name: string
      href: string
    } | null
  } | null
  cmsModule?: { __typename: 'CmsModule'; id: string } | null
  sameActiveUserCohort?: {
    __typename: 'UserCohort'
    id: string
    cohort: { __typename: 'Cohort'; id: string; slug: string }
  } | null
}

export type LessonHeaderSameActiveUserCohortPartsFragment = {
  __typename: 'UserCohort'
  id: string
  cohort: { __typename: 'Cohort'; id: string; slug: string }
}

export type LessonHeaderCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
}

export type LessonTypePartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  contentTypeIcon?: string | null
  estimatedTime: number
  heroImageUrl?: string | null
  shortDescription?: string | null
  href: string
  cmsModuleId: string
  cmsProgramId: string
  showNewBadge: boolean
  previewable: boolean
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    enrolled: boolean
    slug: string
    name: string
    progressPercent: number
  }
}

export type LessonHeaderCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  contentType?: CmsSectionContentType | null
  position?: number | null
  slug: string
  contentTypeIcon?: string | null
  descriptionText?: string | null
  heroImageUrl?: string | null
  shortDescription?: string | null
  estimatedTime: number
  nextSectionNameAndPath: Array<string>
  nextSectionLearningCmsSectionPath?: string | null
  previewable: boolean
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  isUnit: boolean
  parent?: { __typename: 'CmsSection'; id: string; name: string; slug: string } | null
}

export type LessonHeaderCmsProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  code?: string | null
  name: string
  slug: string
  upcoming: boolean
  iconImageUrl?: string | null
}

export type LessonHeaderUserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  kind: string
  participatingKinds: Array<ProgramParticipatingKinds>
}

export type LessonContentCmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  name: string
}

export type LessonContentCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  truncatedDescriptionHtml?: string | null
}

export type LessonContentPartsFragment = {
  __typename: 'LessonViewer'
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    truncatedDescriptionHtml?: string | null
  } | null
}

export type BookmarkableWistiaPlayerBookmarkFragment = {
  __typename: 'Bookmark'
  id: string
  noteBody?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
  type: BookmarkType
}

export type LessonViewerCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  wistiaCode?: string | null
  unlimitedAccess: boolean
  countsTowardsCompletion: boolean
  blockedDescriptionHtmlSafe?: string | null
  descriptionHtmlSafe?: string | null
  summaryHtmlSafe?: string | null
  showFeedback: boolean
  currentUserSubmittedFeedback: boolean
  id: string
  name: string
  contentType?: CmsSectionContentType | null
  position?: number | null
  slug: string
  contentTypeIcon?: string | null
  descriptionText?: string | null
  heroImageUrl?: string | null
  shortDescription?: string | null
  estimatedTime: number
  nextSectionNameAndPath: Array<string>
  nextSectionLearningCmsSectionPath?: string | null
  previewable: boolean
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  isUnit: boolean
  isResource: boolean
  truncatedDescriptionHtml?: string | null
  href: string
  cmsModuleId: string
  cmsProgramId: string
  showNewBadge: boolean
  videoBookmarks?: Array<{
    __typename: 'Bookmark'
    id: string
    noteBody?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    type: BookmarkType
  }> | null
  topic?: { __typename: 'PostTopic'; id: string; title: string } | null
  groups: {
    __typename: 'GroupConnection'
    edges: Array<{ __typename: 'GroupEdge'; node: { __typename: 'Group'; id: string } }>
  }
  nextSection?: {
    __typename: 'CmsSection'
    id: string
    slug: string
    countsTowardsCompletion: boolean
    estimatedTime: number
    heroImageUrl?: string | null
    position?: number | null
    name: string
    href: string
    parent?: { __typename: 'CmsSection'; slug: string } | null
  } | null
  parent?: { __typename: 'CmsSection'; id: string; name: string; slug: string } | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    enrolled: boolean
    slug: string
    name: string
    progressPercent: number
  }
}

export type LessonViewerQueryVariables = Exact<{
  cmsProgramSlug: Scalars['String']
  cmsModuleSlug: Scalars['String']
  cmsSectionParentSlug?: InputMaybe<Scalars['String']>
  cmsSectionSlug: Scalars['String']
  path: Scalars['String']
  referringPostId?: InputMaybe<Scalars['String']>
}>

export type LessonViewerQuery = {
  __typename: 'Query'
  currentUser?: { __typename: 'User'; id: string } | null
  lessonViewer?: {
    __typename: 'LessonViewer'
    viewAccess: string
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      topic?: string | null
      slug: string
      progressPercent: number
      code?: string | null
      name: string
      upcoming: boolean
      iconImageUrl?: string | null
      noLessons: boolean
      launched: boolean
      releaseStartDate?: string | null
      programNotification?: {
        __typename: 'CmsProgramNotification'
        imageUrl: string
        message: string
        notificationName: string
        title: string
      } | null
    } | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      slug?: string | null
      showFeedback: boolean
      name: string
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        slug: string
        accessLevel?: string | null
        contentType?: CmsSectionContentType | null
        isUnit: boolean
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        showNewBadge: boolean
        previewable: boolean
        parentId?: string | null
        contentSubtype?: string | null
        cmsProgramId: string
        lastViewedAt?: string | null
        shortDescription?: string | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          accessLevel?: string | null
          contentType?: CmsSectionContentType | null
          isUnit: boolean
          contentTypeIcon?: string | null
          estimatedTime: number
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          heroImageUrl?: string | null
          href: string
          name: string
          showNewBadge: boolean
          slug: string
          previewable: boolean
          parentId?: string | null
          contentSubtype?: string | null
          cmsProgramId: string
          lastViewedAt?: string | null
          shortDescription?: string | null
          cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            enrolled: boolean
            slug: string
            name: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
        }>
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      }>
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      wistiaCode?: string | null
      unlimitedAccess: boolean
      countsTowardsCompletion: boolean
      blockedDescriptionHtmlSafe?: string | null
      descriptionHtmlSafe?: string | null
      summaryHtmlSafe?: string | null
      showFeedback: boolean
      currentUserSubmittedFeedback: boolean
      slug: string
      name: string
      contentType?: CmsSectionContentType | null
      position?: number | null
      contentTypeIcon?: string | null
      descriptionText?: string | null
      heroImageUrl?: string | null
      shortDescription?: string | null
      estimatedTime: number
      nextSectionNameAndPath: Array<string>
      nextSectionLearningCmsSectionPath?: string | null
      previewable: boolean
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      isUnit: boolean
      isResource: boolean
      truncatedDescriptionHtml?: string | null
      href: string
      cmsModuleId: string
      cmsProgramId: string
      showNewBadge: boolean
      accessLevel?: string | null
      parentId?: string | null
      contentSubtype?: string | null
      lastViewedAt?: string | null
      videoBookmarks?: Array<{
        __typename: 'Bookmark'
        id: string
        noteBody?: string | null
        referenceImageUrl?: string | null
        seconds?: number | null
        type: BookmarkType
      }> | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      groups: {
        __typename: 'GroupConnection'
        edges: Array<{
          __typename: 'GroupEdge'
          node: { __typename: 'Group'; id: string }
        }>
      }
      nextSection?: {
        __typename: 'CmsSection'
        id: string
        slug: string
        countsTowardsCompletion: boolean
        estimatedTime: number
        heroImageUrl?: string | null
        position?: number | null
        name: string
        href: string
        parent?: { __typename: 'CmsSection'; slug: string } | null
      } | null
      children: Array<{
        __typename: 'CmsSection'
        id: string
        accessLevel?: string | null
        contentType?: CmsSectionContentType | null
        isUnit: boolean
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        showNewBadge: boolean
        slug: string
        previewable: boolean
        parentId?: string | null
        contentSubtype?: string | null
        cmsProgramId: string
        lastViewedAt?: string | null
        shortDescription?: string | null
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      }>
      parent?: { __typename: 'CmsSection'; id: string; name: string; slug: string } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        programImage?: string | null
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        progressPercent: number
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    } | null
    userProgram?: {
      __typename: 'UserProgram'
      id: string
      kind: string
      participatingKinds: Array<ProgramParticipatingKinds>
      progress?: ProgramProgressScalar | null
    } | null
    expertUsers?: Array<{
      __typename: 'User'
      id: string
      hasBadge: boolean
      avatarUrl: string
      fullName?: string | null
      companyName?: string | null
      role?: string | null
      kind?: string | null
    }> | null
    sameActiveUserCohort?: {
      __typename: 'UserCohort'
      id: string
      cohort: { __typename: 'Cohort'; id: string; slug: string }
    } | null
  } | null
}

export type ManagePlanQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ManagePlanQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    is: { __typename: 'UserIs'; trialing: boolean }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        dunningDaysLeft?: number | null
        expiresAt: string
        hasUnlimitedCohortAccess?: boolean | null
        isDunning: boolean
        isCanceled?: boolean | null
        isExpired?: boolean | null
        isTeam: boolean
        numSeats: number
        completedTrial?: boolean | null
        trialDaysLeft?: number | null
        trialEndDate?: string | null
        planName: PlanName
        stripeCollectionMethod?: StripeCollectionMethod | null
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        userIsOwner: boolean
        status?: string | null
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          cancelAtPeriodEnd?: boolean | null
          id: string
        } | null
        stripeUpcomingInvoice?: {
          __typename: 'StripeUpcomingInvoice'
          id: string
          amountDue: number
          numSeats: number
          numCohortPasses: number
          planName: PlanName
          subtotal: number
        } | null
      } | null
    } | null
  } | null
  userPaymentMethods: Array<{ __typename: 'StripePaymentMethod'; id: string }>
  plansForSale: Array<{
    __typename: 'PlanForSale'
    name: string
    pricePerYear: number
    maxSeatCount: number
  }>
}

export type AvailableCoursesQueryForWaitlistQueryVariables = Exact<{
  [key: string]: never
}>

export type AvailableCoursesQueryForWaitlistQuery = {
  __typename: 'Query'
  marketingCourses: Array<{
    __typename: 'MarketingCourse'
    id: string
    title?: string | null
    slug?: string | null
    course?: { __typename: 'SanityCourse'; title: string } | null
  }>
}

export type BlogIndexQueryVariables = Exact<{
  category?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  lastId?: InputMaybe<Scalars['String']>
  reverse?: InputMaybe<Scalars['Boolean']>
}>

export type BlogIndexQuery = {
  __typename: 'Query'
  blogIndex: {
    __typename: 'BlogIndex'
    nextBlogPostsCount: number
    prevBlogPostsCount: number
    page: {
      __typename: 'Page'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
    }
    blogPosts: Array<{
      __typename: 'BlogPost'
      id: string
      publishDate?: string | null
      title: string
      slug?: string | null
      summaryText?: any | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        shortBio?: string | null
        slug?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    }>
    featuredBlogPosts: Array<{
      __typename: 'BlogPost'
      id: string
      title: string
      slug?: string | null
      isFeatured?: boolean | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        shortBio?: string | null
        slug?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    }>
  }
}

export type BlogDetailsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type BlogDetailsQuery = {
  __typename: 'Query'
  blogPost?: {
    __typename: 'BlogPostDetails'
    createdAt: string
    updatedAt: string
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    blogPost: {
      __typename: 'BlogPost'
      id: string
      title: string
      subtopicIds?: Array<string> | null
      overview?: any | null
      contentBlock?: any | null
      tableOfContents?: any | null
      categories?: Array<{
        __typename: 'BlogPostCategory'
        id: string
        name: string
        value: string
      }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        shortBio?: string | null
        slug?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }
  } | null
}

export type RelatedBlogPostsQueryVariables = Exact<{
  subtopicIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type RelatedBlogPostsQuery = {
  __typename: 'Query'
  relatedBlogPosts: Array<{
    __typename: 'BlogPost'
    id: string
    title: string
    slug?: string | null
    summaryText?: any | null
    summaryImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
    }> | null
  }>
}

export type CareersPageQueryVariables = Exact<{ [key: string]: never }>

export type CareersPageQuery = {
  __typename: 'Query'
  careersPage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type OpenPositionsPageQueryVariables = Exact<{ [key: string]: never }>

export type OpenPositionsPageQuery = {
  __typename: 'Query'
  openPositionsPage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type CaseStudiesIndexQueryVariables = Exact<{ [key: string]: never }>

export type CaseStudiesIndexQuery = {
  __typename: 'Query'
  caseStudies: {
    __typename: 'CaseStudiesIndex'
    page: {
      __typename: 'Page'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
    }
    caseStudies: Array<{
      __typename: 'CaseStudy'
      id: string
      previewTitle?: string | null
      slug?: string | null
      contributors: Array<{
        __typename: 'CaseStudyContributor'
        id: string
        name: string
        company: string
        position: string
        avatarPhoto: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
      }>
    }>
  }
}

export type CaseStudyDetailsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CaseStudyDetailsQuery = {
  __typename: 'Query'
  caseStudy?: {
    __typename: 'CaseStudyDetails'
    id: string
    title: string
    createdAt: string
    updatedAt: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    caseStudy: {
      __typename: 'CaseStudy'
      id: string
      title?: string | null
      useCases?: string | null
      contributors: Array<{
        __typename: 'CaseStudyContributor'
        id: string
        name: string
        company: string
        position: string
        avatarPhoto: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
      }>
      content?: Array<{
        __typename: 'CaseStudyContentBlock'
        id: string
        background: string
        contentBlock: any
      }> | null
    }
  } | null
}

export type ContactUsQueryVariables = Exact<{ [key: string]: never }>

export type ContactUsQuery = {
  __typename: 'Query'
  contactUs: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type CopyrightDisputePageQueryVariables = Exact<{ [key: string]: never }>

export type CopyrightDisputePageQuery = {
  __typename: 'Query'
  copyrightDisputePage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type CourseDetailPageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseDetailPageQuery = {
  __typename: 'Query'
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    goals?: string | null
    focus?: string | null
    audience?: string | null
    onDemand?: boolean | null
    heroImageUrl?: string | null
    trailerUrl?: string | null
    outlineComplete?: boolean | null
    sourceId?: string | null
    staticId: string
    testimonials?: Array<{
      __typename: 'CclReview'
      id: string
      title?: string | null
      subTitle?: string | null
      quote?: string | null
      company?: {
        __typename: 'CclCompany'
        id: string
        name: string
        logoUrl?: string | null
      } | null
    }> | null
    blocks?: Array<{
      __typename: 'CclCourseBlock'
      id: string
      title: string
      description?: string | null
      previewable?: boolean | null
      displayOrder: number
      kind?: string | null
      contentBlocks?: Array<{
        __typename: 'ContentBlock'
        id: string
        header?: string | null
        slug: string
        order?: number | null
        description?: any | null
        contentBlockCollapsible?: Array<{
          __typename: 'ContentBlockCollapsible'
          id: string
          type?: string | null
          description?: any | null
          label?: string | null
        }> | null
      }> | null
      guides?: Array<{
        __typename: 'CclGuide'
        id: string
        title: string
        overview?: string | null
        slug: string
        freePreview?: boolean | null
        displayOrder: number
        estimatedMinutes?: number | null
        sourceId?: string | null
      }> | null
      artifacts?: Array<{
        __typename: 'CclArtifact'
        id: string
        title: string
        slug: string
        sourceId?: string | null
      }> | null
      events?: Array<{
        __typename: 'CclSessionEvent'
        id: string
        title: string
        startsAt: string
        endsAt: string
        sourceId?: string | null
      }> | null
      eventPreviews?: Array<{
        __typename: 'CclVideo'
        id: string
        title: string
        sourceId?: string | null
        location: string
      }> | null
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      bio?: string | null
      position?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      slug: string
      isCollectiveMember?: boolean | null
    }> | null
  } | null
  relatedCourses?: Array<{
    __typename: 'SanityCourse'
    id: string
    title: string
    slug: string
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      shortBio?: string | null
      position?: string | null
      slug?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
  }> | null
  upcomingCourseSessions?: Array<{
    __typename: 'CclCourseSession'
    id: string
    startsAt: string
    endsAt: string
    duration?: string | null
    sourceId?: string | null
    price?: number | null
    isDuringEnrollmentPeriod: boolean
    isEnrollmentFull: boolean
    maxEnrollmentPaid?: number | null
    numEnrolledPaid?: number | null
    createdAt: string
    events?: Array<{
      __typename: 'CclSessionEvent'
      id: string
      startsAt: string
      endsAt: string
    }> | null
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      slug: string
    }> | null
  }> | null
}

export type LoggedOutCourseDetailPageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type LoggedOutCourseDetailPageQuery = {
  __typename: 'Query'
  marketingCourseDetails?: {
    __typename: 'CourseDetails'
    course: {
      __typename: 'UnifiedCourse'
      courseFaq?: Array<{
        __typename: 'MarketingFaq'
        id: string
        answer: any
        question: string
      }> | null
    }
  } | null
}

export type CourseDetailEntityPreviewQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type CourseDetailEntityPreviewQuery = {
  __typename: 'Query'
  course?: { __typename: 'Course'; id: string; slug: string; title: string } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    slug: string
    sourceId?: string | null
    staticId: string
  } | null
  upcomingCourseSessions?: Array<{
    __typename: 'CclCourseSession'
    id: string
    startsAt: string
    endsAt: string
    duration?: string | null
    sourceId?: string | null
    price?: number | null
    isDuringEnrollmentPeriod: boolean
    isEnrollmentFull: boolean
    maxEnrollmentPaid?: number | null
    numEnrolledPaid?: number | null
    createdAt: string
    events?: Array<{
      __typename: 'CclSessionEvent'
      id: string
      startsAt: string
      endsAt: string
    }> | null
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      slug: string
    }> | null
  }> | null
}

export type CclCourseSessionCourseDetailEnrollmentBadgesPartsFragment = {
  __typename: 'CclCourseSession'
  id: string
  startsAt: string
  isDuringEnrollmentPeriod: boolean
  isEnrollmentFull: boolean
  maxEnrollmentPaid?: number | null
  numEnrolledPaid?: number | null
  createdAt: string
}

export type CclCourseSessionPartsFragment = {
  __typename: 'CclCourseSession'
  id: string
  startsAt: string
  endsAt: string
  duration?: string | null
  sourceId?: string | null
  price?: number | null
  isDuringEnrollmentPeriod: boolean
  isEnrollmentFull: boolean
  maxEnrollmentPaid?: number | null
  numEnrolledPaid?: number | null
  createdAt: string
  events?: Array<{
    __typename: 'CclSessionEvent'
    id: string
    startsAt: string
    endsAt: string
  }> | null
  experts?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
    slug: string
  }> | null
}

export type CclCourseSessionEventPartsFragment = {
  __typename: 'CclSessionEvent'
  id: string
  startsAt: string
  endsAt: string
}

export type CclCourseSessionExpertPartsFragment = {
  __typename: 'CclExpert'
  id: string
  name: string
  avatarUrl?: string | null
  slug: string
}

export type CourseDetailEntityPreviewPartsFragment = {
  __typename: 'CclCourse'
  id: string
  title: string
  slug: string
  sourceId?: string | null
  staticId: string
}

export type CoursesListQueryVariables = Exact<{
  topicSlugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  availability?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type CoursesListQuery = {
  __typename: 'Query'
  cclCourses?: Array<{
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  }> | null
}

export type CoursesListFiltersQueryVariables = Exact<{ [key: string]: never }>

export type CoursesListFiltersQuery = {
  __typename: 'Query'
  cclCourseCclTopics?: Array<{
    __typename: 'CclTopic'
    id: string
    title: string
    slug: string
  }> | null
  legacyCourseCclTopics?: Array<{
    __typename: 'CclTopic'
    id: string
    title: string
    slug: string
  }> | null
}

export type FeaturedCoursesQueryVariables = Exact<{ [key: string]: never }>

export type FeaturedCoursesQuery = {
  __typename: 'Query'
  featuredCclCourses?: Array<{
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      endsAt: string
      id: string
      startsAt: string
      isDuringEnrollmentPeriod: boolean
      isEnrollmentFull: boolean
      maxEnrollmentPaid?: number | null
      numEnrolledPaid?: number | null
      createdAt: string
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
        slug: string
      }> | null
    }> | null
    exampleCompanies?: Array<{
      __typename: 'CclCompany'
      id: string
      wordmarkLogoUrl?: string | null
      name: string
    }> | null
  }> | null
}

export type CclCourseFeaturedCourseCardPartsFragment = {
  __typename: 'CclCourse'
  id: string
  title: string
  shortDescription?: string | null
  slug: string
  creators?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
  upcomingSessions?: Array<{
    __typename: 'CclCourseSession'
    endsAt: string
    id: string
    startsAt: string
    isDuringEnrollmentPeriod: boolean
    isEnrollmentFull: boolean
    maxEnrollmentPaid?: number | null
    numEnrolledPaid?: number | null
    createdAt: string
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
      slug: string
    }> | null
  }> | null
  exampleCompanies?: Array<{
    __typename: 'CclCompany'
    id: string
    wordmarkLogoUrl?: string | null
    name: string
  }> | null
}

export type CclCourseSessionFeaturedCourseCardPartsFragment = {
  __typename: 'CclCourseSession'
  endsAt: string
  id: string
  startsAt: string
  isDuringEnrollmentPeriod: boolean
  isEnrollmentFull: boolean
  maxEnrollmentPaid?: number | null
  numEnrolledPaid?: number | null
  createdAt: string
  experts?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
    slug: string
  }> | null
}

export type CclExpertFeaturedCourseCardPartsFragment = {
  __typename: 'CclExpert'
  id: string
  name: string
  avatarUrl?: string | null
  slug: string
}

export type CoursesListUserQueryVariables = Exact<{ [key: string]: never }>

export type CoursesListUserQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasContentInProgressOrComplete: boolean
    hasSeenCollectionsPrompt: boolean
    bookmarkFolders: Array<{
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    }>
    courseBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
    programBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
  } | null
}

export type CoursesIndexSeoQueryVariables = Exact<{ [key: string]: never }>

export type CoursesIndexSeoQuery = {
  __typename: 'Query'
  marketingCoursesIndex: {
    __typename: 'CourseIndex'
    page: {
      __typename: 'CourseIndexPage'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
      content: {
        __typename: 'CourseIndexContent'
        heading: string
        subheading: string
        ctaText: string
        ctaLink: string
        heroImage: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
        logos?: Array<{
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }> | null
        courseBenefits: Array<{
          __typename: 'CourseBenefit'
          id: string
          title: string
          description: string
          icon: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          }
        }>
      }
    }
  }
}

export type CclTopicFilterPartsFragment = {
  __typename: 'CclTopic'
  id: string
  title: string
  slug: string
}

export type MarketingCourseCourseCardPartsFragment = {
  __typename: 'MarketingCourse'
  id: string
  title?: string | null
  courseType?: string | null
  bookmarkId?: string | null
  startDate?: string | null
  subtitle?: string | null
  slug?: string | null
  cmsProgramSlug?: string | null
  legacyProgramId?: string | null
  duration?: string | null
  hosts?: Array<{
    __typename: 'SanityHost'
    id: string
    hostName?: string | null
    hostPicture?: {
      __typename: 'Image'
      imageAlt?: string | null
      imageUrl?: string | null
    } | null
  }> | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    completionPercentage?: number | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    live?: boolean | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
  course?: {
    __typename: 'SanityCourse'
    id: string
    title: string
    slug: string
    shortDescription?: string | null
    bookmarkId?: string | null
    creators?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    slug: string
    cclStaticCourseId?: string | null
  } | null
  cclTopics?: Array<{
    __typename: 'CclTopic'
    id: string
    title: string
    slug: string
  }> | null
  creators?: Array<{
    __typename: 'Creator'
    id: string
    name?: string | null
    expert?: {
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
  }> | null
}

export type HostLegacyCourseCardPartsFragment = {
  __typename: 'SanityHost'
  id: string
  hostName?: string | null
  hostPicture?: {
    __typename: 'Image'
    imageAlt?: string | null
    imageUrl?: string | null
  } | null
}

export type CclCourseCourseCardPartsFragment = {
  __typename: 'CclCourse'
  id: string
  title: string
  shortDescription?: string | null
  slug: string
  staticId: string
  onDemand?: boolean | null
  completionPercentage?: number | null
  live?: boolean | null
  cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
  topics?: Array<{
    __typename: 'CclTopic'
    id: string
    title: string
    slug: string
  }> | null
  creators?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
  upcomingSessions?: Array<{
    __typename: 'CclCourseSession'
    id: string
    startsAt: string
    endsAt: string
    duration?: string | null
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
  }> | null
}

export type CclCourseSessionCourseCardPartsFragment = {
  __typename: 'CclCourseSession'
  id: string
  startsAt: string
  endsAt: string
  duration?: string | null
  experts?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
}

export type CclExpertCourseCardPartsFragment = {
  __typename: 'CclExpert'
  id: string
  name: string
  avatarUrl?: string | null
}

export type CoursesMarketplaceWaitlistQueryVariables = Exact<{ [key: string]: never }>

export type CoursesMarketplaceWaitlistQuery = {
  __typename: 'Query'
  coursesMarketplaceWaitlist: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type ExpertsIndexQueryVariables = Exact<{ [key: string]: never }>

export type ExpertsIndexQuery = {
  __typename: 'Query'
  marketingExperts: {
    __typename: 'ExpertsIndex'
    page: {
      __typename: 'Page'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
    }
    experts: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }>
  }
}

export type PageSeoFragmentFragment = {
  __typename: 'PageSeo'
  metaDesc?: string | null
  metaTitle?: string | null
  publishDate?: string | null
  authors?: string | null
  shareDesc?: string | null
  shareGraphic?: any | null
  shareGraphicAlt?: string | null
  shareTitle?: string | null
  structuredDataSchema?: string | null
  type?: PageTypeEnum | null
}

export type ImageFragmentFragment = {
  __typename: 'Image'
  imageUrl?: string | null
  imageAlt?: string | null
  aspectRatio?: number | null
}

export type CtaFragmentFragment = { __typename: 'Cta'; ctaText: string; ctaLink: string }

export type BlogPostCategoryFragmentFragment = {
  __typename: 'BlogPostCategory'
  id: string
  name: string
  value: string
}

export type BlogAuthorFragmentFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  shortBio?: string | null
  slug?: string | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
}

export type MarketingFaqFragment = {
  __typename: 'MarketingFaq'
  id: string
  question: string
  answer: any
}

export type MarketingCourseStartDatePartsFragment = {
  __typename: 'MarketingCourse'
  startDate?: string | null
}

export type LoggedOutCourseDetailsPageBridgeQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type LoggedOutCourseDetailsPageBridgeQuery = {
  __typename: 'Query'
  marketingCourseDetails?: {
    __typename: 'CourseDetails'
    id: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    course: { __typename: 'UnifiedCourse'; courseType?: string | null }
  } | null
  cclCourse?: { __typename: 'CclCourse'; id: string } | null
}

export type MarketingCoursesIndexQueryVariables = Exact<{ [key: string]: never }>

export type MarketingCoursesIndexQuery = {
  __typename: 'Query'
  marketingCoursesIndex: {
    __typename: 'CourseIndex'
    page: {
      __typename: 'CourseIndexPage'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
      content: {
        __typename: 'CourseIndexContent'
        heading: string
        subheading: string
        ctaText: string
        ctaLink: string
        heroImage: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
        logos?: Array<{
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }> | null
        courseBenefits: Array<{
          __typename: 'CourseBenefit'
          id: string
          title: string
          description: string
          icon: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          }
        }>
      }
    }
    courses: Array<{
      __typename: 'MarketingCourse'
      id: string
      courseType?: string | null
      isLive?: boolean | null
      title?: string | null
      startDate?: string | null
      subtitle?: string | null
      slug?: string | null
      cmsProgramSlug?: string | null
      course?: {
        __typename: 'SanityCourse'
        title: string
        shortDescription?: string | null
        slug: string
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: { __typename: 'ArtifactAuthor'; id: string; name?: string | null } | null
      }> | null
      hosts?: Array<{
        __typename: 'SanityHost'
        id: string
        hostName?: string | null
      }> | null
      previewImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }>
  }
}

export type MarketingCoursesIndexPagePartsFragment = {
  __typename: 'CourseIndexPage'
  id: string
  title: string
  seo?: {
    __typename: 'PageSeo'
    metaDesc?: string | null
    metaTitle?: string | null
    publishDate?: string | null
    authors?: string | null
    shareDesc?: string | null
    shareGraphic?: any | null
    shareGraphicAlt?: string | null
    shareTitle?: string | null
    structuredDataSchema?: string | null
    type?: PageTypeEnum | null
  } | null
  content: {
    __typename: 'CourseIndexContent'
    heading: string
    subheading: string
    ctaText: string
    ctaLink: string
    heroImage: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    }
    logos?: Array<{
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    }> | null
    courseBenefits: Array<{
      __typename: 'CourseBenefit'
      id: string
      title: string
      description: string
      icon: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      }
    }>
  }
}

export type MarketingCourseDetailsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type MarketingCourseDetailsQuery = {
  __typename: 'Query'
  marketingCourseDetails?: {
    __typename: 'CourseDetails'
    id: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    course: {
      __typename: 'UnifiedCourse'
      courseType?: string | null
      isLive?: boolean | null
      price?: string | null
      duration?: string | null
      coursePreviewAvailable?: boolean | null
      startDate?: string | null
      title?: string | null
      slug?: string | null
      cmsProgramSlug?: string | null
      subtitle?: string | null
      availabilitySubtitle?: string | null
      hostSubtitle?: string | null
      description?: string | null
      ctaButtonText?: string | null
      ctaButtonLink?: string | null
      hostSectionTitle?: string | null
      hostSectionSubtitle?: string | null
      targetAudience?: string | null
      goals?: string | null
      course?: {
        __typename: 'SanityCourse'
        title: string
        shortDescription?: string | null
        slug: string
        whatYouWillLearn?: any | null
        whoThisIsFor?: any | null
      } | null
      courseInfoCards?: Array<{
        __typename: 'CourseInfoCard'
        id?: string | null
        title?: string | null
        description?: string | null
      }> | null
      icon?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      backgroundImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      hosts?: Array<{
        __typename: 'SanityHost'
        id: string
        hostName?: string | null
        hostBio?: string | null
        hostPicture?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        role?: string | null
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
      breakdownBackgroundImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      breakdownImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      topicsCovered?: Array<{
        __typename: 'CourseTopicCovered'
        id: string
        heading?: string | null
        description?: string | null
      }> | null
      courseFaq?: Array<{
        __typename: 'MarketingFaq'
        id: string
        question: string
        answer: any
      }> | null
    }
  } | null
}

export type MarketingHomepageQueryVariables = Exact<{ [key: string]: never }>

export type MarketingHomepageQuery = {
  __typename: 'Query'
  marketingHomepage: {
    __typename: 'Homepage'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    content: {
      __typename: 'HomepageContent'
      title: string
      subtitle: string
      artifactsAndGuidesTitle: string
      artifactsAndGuidesSubtitle: string
      artifactsBenefits: Array<string>
      guidesBenefits: Array<string>
      aiTitle: string
      aiSubtitle: string
      extensionTitle: string
      extensionSubtitle: string
      membershipTitle: string
      membershipSubtitle: string
      membershipBenefits1: Array<string>
      membershipBenefits2: Array<string>
      teamsTitle: string
      teamsSubtitle: string
      bottomTitle: string
      mainCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      secondaryCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      logos?: Array<{
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      }> | null
      testimonials: Array<{
        __typename: 'TestimonialWithImage'
        name?: string | null
        quote?: string | null
        positionAndCompany?: string | null
        avatar?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }>
      artifactsCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      guidesCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      aiCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      aiExtensionCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      extensionApps: Array<{
        __typename: 'AiExtensionApp'
        id: string
        appName: string
        appLogo: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
      }>
      membershipBenefits1Cta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      membershipBenefits2Cta?: {
        __typename: 'Cta'
        ctaText: string
        ctaLink: string
      } | null
      teamsBenefitsImage: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      }
      teamsBenefits: Array<{
        __typename: 'HomepageTeamsBenefit'
        id: string
        title: string
        description: string
      }>
      teamsCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
      bottomCta: { __typename: 'Cta'; ctaText: string; ctaLink: string }
    }
  }
}

export type MarketingSiteSettingsQueryVariables = Exact<{ [key: string]: never }>

export type MarketingSiteSettingsQuery = {
  __typename: 'Query'
  marketingSiteSettings: {
    __typename: 'SiteSettings'
    title?: string | null
    cookieConsent?: {
      __typename: 'CookieConsent'
      enabled: boolean
      message?: string | null
      url?: string | null
    } | null
    seo?: {
      __typename: 'SiteSeo'
      metaTitle?: string | null
      metaDesc?: string | null
      shareTitle?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
    } | null
  }
}

export type MyCoursesQueryVariables = Exact<{ [key: string]: never }>

export type MyCoursesQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    timezone?: string | null
    hasContentInProgressOrComplete: boolean
    userProgramsProgressStarted?: Array<{
      __typename: 'UserProgram'
      id: string
      progressPercent?: number | null
      updatedAt: string
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        synopsis?: string | null
        slug: string
      }
    }> | null
    artifactBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      basedOn?: string | null
      noteBody?: string | null
      sanityId?: string | null
      type: BookmarkType
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
    }> | null
    eventBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      basedOn?: string | null
      noteBody?: string | null
      sanityId?: string | null
      eventId?: string | null
      type: BookmarkType
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
    }> | null
    courseBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
    inProgressCourses?: Array<{
      __typename: 'UserCourseActivity'
      latestActivityAt?: string | null
      cclCourse: {
        __typename: 'CclCourse'
        id: string
        staticId: string
        title: string
        slug: string
        shortDescription?: string | null
        completionPercentage?: number | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }
    }> | null
    programBookmarks?: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }> | null
    cohorts?: {
      __typename: 'UserCohorts'
      previous: Array<{
        __typename: 'UserCohort'
        id: string
        cohort: {
          __typename: 'Cohort'
          id: string
          slug: string
          startsAt?: string | null
          endsAt?: string | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            iconImageUrl?: string | null
            synopsis?: string | null
            slug: string
          }
          hosts?: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }> | null
        }
      }>
    } | null
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        cclCourseSession?: {
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        } | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          sanityId?: string | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
          isCurrent: boolean
        }
      }> | null
      upcoming?: Array<{
        __typename: 'UserCourse'
        id: string
        cclCourseSession?: {
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        } | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          sanityId?: string | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
          isCurrent: boolean
        }
      }> | null
      previous?: Array<{
        __typename: 'UserCourse'
        id: string
        cclCourseSession?: {
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        } | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          sanityId?: string | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
          isCurrent: boolean
        }
      }> | null
      last?: {
        __typename: 'UserCourse'
        id: string
        cclCourseSession?: {
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        } | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
        course: {
          __typename: 'Course'
          id: string
          title: string
          slug: string
          sanityId?: string | null
        }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
          isCurrent: boolean
        }
      } | null
    }
    is: { __typename: 'UserIs'; member: boolean }
  } | null
  legacyCourses: Array<{
    __typename: 'MarketingCourse'
    id: string
    title?: string | null
    courseType?: string | null
    bookmarkId?: string | null
    startDate?: string | null
    subtitle?: string | null
    slug?: string | null
    cmsProgramSlug?: string | null
    legacyProgramId?: string | null
    duration?: string | null
    hosts?: Array<{
      __typename: 'SanityHost'
      id: string
      hostName?: string | null
      hostPicture?: {
        __typename: 'Image'
        imageAlt?: string | null
        imageUrl?: string | null
      } | null
    }> | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      completionPercentage?: number | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      live?: boolean | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      slug: string
      shortDescription?: string | null
      bookmarkId?: string | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      slug: string
      cclStaticCourseId?: string | null
    } | null
    cclTopics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      name?: string | null
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }>
  marketplaceCourses: Array<{
    __typename: 'MarketingCourse'
    id: string
    title?: string | null
    courseType?: string | null
    bookmarkId?: string | null
    startDate?: string | null
    subtitle?: string | null
    slug?: string | null
    cmsProgramSlug?: string | null
    legacyProgramId?: string | null
    duration?: string | null
    hosts?: Array<{
      __typename: 'SanityHost'
      id: string
      hostName?: string | null
      hostPicture?: {
        __typename: 'Image'
        imageAlt?: string | null
        imageUrl?: string | null
      } | null
    }> | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      completionPercentage?: number | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      live?: boolean | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      slug: string
      shortDescription?: string | null
      bookmarkId?: string | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      slug: string
      cclStaticCourseId?: string | null
    } | null
    cclTopics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      name?: string | null
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  }>
}

export type MyCoursesCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  timezone?: string | null
  hasContentInProgressOrComplete: boolean
  userProgramsProgressStarted?: Array<{
    __typename: 'UserProgram'
    id: string
    progressPercent?: number | null
    updatedAt: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      synopsis?: string | null
      slug: string
    }
  }> | null
  artifactBookmarks?: Array<{
    __typename: 'Bookmark'
    id: string
    basedOn?: string | null
    noteBody?: string | null
    sanityId?: string | null
    type: BookmarkType
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
  }> | null
  eventBookmarks?: Array<{
    __typename: 'Bookmark'
    id: string
    basedOn?: string | null
    noteBody?: string | null
    sanityId?: string | null
    eventId?: string | null
    type: BookmarkType
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
  }> | null
  courseBookmarks?: Array<{
    __typename: 'Bookmark'
    id: string
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  }> | null
  inProgressCourses?: Array<{
    __typename: 'UserCourseActivity'
    latestActivityAt?: string | null
    cclCourse: {
      __typename: 'CclCourse'
      id: string
      staticId: string
      title: string
      slug: string
      shortDescription?: string | null
      completionPercentage?: number | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }
  }> | null
  programBookmarks?: Array<{
    __typename: 'Bookmark'
    id: string
    eventId?: string | null
    lessonPosition?: number | null
    sanityId?: string | null
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    anchor?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    updated?: string | null
    created?: string | null
    title?: string | null
    externalUrl?: string | null
    metadata?: any | null
    startOffset?: number | null
    endOffset?: number | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      name: string
      position?: number | null
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      name: string
      position?: number | null
      progressPercent?: number | null
      childrenCount: number
      completedChildrenCount?: number | null
      path?: string | null
    } | null
    content?: {
      __typename: 'CmsSection'
      id: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      numPhases?: number | null
      projectIcon?: string | null
      shortDescription?: string | null
      numBookmarks?: number | null
      showNewBadge: boolean
      slug: string
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      lessonNum?: number | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        slug?: string | null
        name: string
      } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
      progressPercent: number
      iconImageUrl?: string | null
      notLaunched?: boolean | null
      deprecatedAt?: string | null
      description?: string | null
      synopsis?: string | null
      lessonCount: number
      purchased: boolean
      upcoming: boolean
      enrolled: boolean
      canEnroll: boolean
      showNewBadge: boolean
      topic?: string | null
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
        companyName?: string | null
        description?: string | null
        title?: string | null
        avatarUrl: string
      }>
    } | null
    event?: {
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      willBeRecorded: boolean
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    } | null
    artifact?: {
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      companyLogoUrl?: string | null
      companyName?: string | null
      thumbnailUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarUrl?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
        } | null
      }> | null
    } | null
    course?: {
      __typename: 'MarketingCourse'
      id: string
      slug?: string | null
      cmsProgramSlug?: string | null
      startDate?: string | null
      subtitle?: string | null
      title?: string | null
      legacyProgramId?: string | null
      bookmarkId?: string | null
      courseType?: string | null
      course?: {
        __typename: 'SanityCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        bookmarkId?: string | null
        isAvailableOnDemand?: boolean | null
        creators?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    } | null
    guide?: {
      __typename: 'Unit'
      id: string
      title?: string | null
      whatYouWillDo?: any | null
      estimatedTimeInMinutes?: number | null
      stepsCount?: number | null
      slug?: string | null
      contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        slug?: string | null
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  }> | null
  cohorts?: {
    __typename: 'UserCohorts'
    previous: Array<{
      __typename: 'UserCohort'
      id: string
      cohort: {
        __typename: 'Cohort'
        id: string
        slug: string
        startsAt?: string | null
        endsAt?: string | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          iconImageUrl?: string | null
          synopsis?: string | null
          slug: string
        }
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }> | null
      }
    }>
  } | null
  userCourses: {
    __typename: 'UserCourses'
    all?: Array<{
      __typename: 'UserCourse'
      id: string
      cclCourseSession?: {
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      } | null
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        sanityId?: string | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
        isCurrent: boolean
      }
    }> | null
    upcoming?: Array<{
      __typename: 'UserCourse'
      id: string
      cclCourseSession?: {
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      } | null
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        sanityId?: string | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
        isCurrent: boolean
      }
    }> | null
    previous?: Array<{
      __typename: 'UserCourse'
      id: string
      cclCourseSession?: {
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      } | null
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        sanityId?: string | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
        isCurrent: boolean
      }
    }> | null
    last?: {
      __typename: 'UserCourse'
      id: string
      cclCourseSession?: {
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      } | null
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
      course: {
        __typename: 'Course'
        id: string
        title: string
        slug: string
        sanityId?: string | null
      }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        startsAt: string
        endsAt: string
        isCurrent: boolean
      }
    } | null
  }
  is: { __typename: 'UserIs'; member: boolean }
}

export type LegacyCohortCardCohortPartsFragment = {
  __typename: 'Cohort'
  id: string
  slug: string
  startsAt?: string | null
  endsAt?: string | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    name: string
    iconImageUrl?: string | null
    synopsis?: string | null
    slug: string
  }
  hosts?: Array<{
    __typename: 'Host'
    id: string
    name?: string | null
    thumbnailUrl: string
  }> | null
}

export type MyCoursesUserCoursePartsFragment = {
  __typename: 'UserCourse'
  id: string
  cclCourseSession?: {
    __typename: 'CclCourseSession'
    id: string
    startsAt: string
    endsAt: string
    duration?: string | null
    experts?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
  course: {
    __typename: 'Course'
    id: string
    title: string
    slug: string
    sanityId?: string | null
  }
  courseSession: {
    __typename: 'CourseSession'
    id: string
    startsAt: string
    endsAt: string
    isCurrent: boolean
  }
}

export type MyCourseUserCohortPartsFragment = {
  __typename: 'UserCohort'
  id: string
  cohort: {
    __typename: 'Cohort'
    id: string
    slug: string
    startsAt?: string | null
    endsAt?: string | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      iconImageUrl?: string | null
      synopsis?: string | null
      slug: string
    }
    hosts?: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }> | null
  }
}

export type MyCoursesUserCoursesPartsFragment = {
  __typename: 'UserCourses'
  all?: Array<{
    __typename: 'UserCourse'
    id: string
    cclCourseSession?: {
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    } | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course: {
      __typename: 'Course'
      id: string
      title: string
      slug: string
      sanityId?: string | null
    }
    courseSession: {
      __typename: 'CourseSession'
      id: string
      startsAt: string
      endsAt: string
      isCurrent: boolean
    }
  }> | null
  upcoming?: Array<{
    __typename: 'UserCourse'
    id: string
    cclCourseSession?: {
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    } | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course: {
      __typename: 'Course'
      id: string
      title: string
      slug: string
      sanityId?: string | null
    }
    courseSession: {
      __typename: 'CourseSession'
      id: string
      startsAt: string
      endsAt: string
      isCurrent: boolean
    }
  }> | null
  previous?: Array<{
    __typename: 'UserCourse'
    id: string
    cclCourseSession?: {
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    } | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course: {
      __typename: 'Course'
      id: string
      title: string
      slug: string
      sanityId?: string | null
    }
    courseSession: {
      __typename: 'CourseSession'
      id: string
      startsAt: string
      endsAt: string
      isCurrent: boolean
    }
  }> | null
  last?: {
    __typename: 'UserCourse'
    id: string
    cclCourseSession?: {
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    } | null
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
    course: {
      __typename: 'Course'
      id: string
      title: string
      slug: string
      sanityId?: string | null
    }
    courseSession: {
      __typename: 'CourseSession'
      id: string
      startsAt: string
      endsAt: string
      isCurrent: boolean
    }
  } | null
}

export type MyCoursesUserCohortsPartsFragment = {
  __typename: 'UserCohorts'
  previous: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      slug: string
      startsAt?: string | null
      endsAt?: string | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        iconImageUrl?: string | null
        synopsis?: string | null
        slug: string
      }
      hosts?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }> | null
    }
  }>
}

export type MyCoursesUserProgramPartsFragment = {
  __typename: 'UserProgram'
  id: string
  progressPercent?: number | null
  updatedAt: string
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    name: string
    synopsis?: string | null
    slug: string
  }
}

export type MyCourseCclCourseInProgressPartsFragment = {
  __typename: 'UserCourseActivity'
  latestActivityAt?: string | null
  cclCourse: {
    __typename: 'CclCourse'
    id: string
    staticId: string
    title: string
    slug: string
    shortDescription?: string | null
    completionPercentage?: number | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
  }
}

export type MyCourseCclCoursePartsFragment = {
  __typename: 'CclCourse'
  id: string
  staticId: string
  title: string
  slug: string
  shortDescription?: string | null
  completionPercentage?: number | null
  creators?: Array<{
    __typename: 'CclExpert'
    id: string
    name: string
    avatarUrl?: string | null
  }> | null
}

export type PodcastIndexQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  lastId?: InputMaybe<Scalars['String']>
  reverse?: InputMaybe<Scalars['Boolean']>
}>

export type PodcastIndexQuery = {
  __typename: 'Query'
  podcastIndex: {
    __typename: 'PodcastIndex'
    nextPodcastsCount: number
    prevPodcastsCount: number
    page: {
      __typename: 'PodcastIndexPage'
      id: string
      title: string
      seo?: {
        __typename: 'PageSeo'
        metaDesc?: string | null
        metaTitle?: string | null
        publishDate?: string | null
        authors?: string | null
        shareDesc?: string | null
        shareGraphic?: any | null
        shareGraphicAlt?: string | null
        shareTitle?: string | null
        structuredDataSchema?: string | null
        type?: PageTypeEnum | null
      } | null
      content: {
        __typename: 'PodcastIndexContent'
        heading: string
        description: string
        formTitle: string
        mainImage: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        }
        platforms: Array<{
          __typename: 'PodcastPlatform'
          id: string
          title: string
          platformLink: string
          platformImage: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          }
        }>
        hosts: Array<{
          __typename: 'PodcastHost'
          id: string
          shortBio: string
          artifactAuthor: {
            __typename: 'ArtifactAuthor'
            id: string
            slug?: string | null
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }
        }>
      }
    }
    podcasts: Array<{
      __typename: 'Podcast'
      id?: string | null
      createdAt?: string | null
      slug?: string | null
      title: string
      summaryText?: string | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }>
  }
}

export type PodcastDetailsQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type PodcastDetailsQuery = {
  __typename: 'Query'
  podcast?: {
    __typename: 'PodcastDetails'
    createdAt: string
    updatedAt: string
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    podcast: {
      __typename: 'Podcast'
      id?: string | null
      title: string
      contentBlock?: any | null
      hosts?: string | null
      topics?: string | null
      listenOn?: any | null
      releaseDate?: string | null
      ctaButton?: {
        __typename: 'PodcastCta'
        enabled?: boolean | null
        ctaText?: string | null
        ctaLink?: string | null
        withReforgeSymbol?: boolean | null
      } | null
      summaryImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      nextEpisode?: { __typename: 'Podcast'; title: string; slug?: string | null } | null
      prevEpisode?: { __typename: 'Podcast'; title: string; slug?: string | null } | null
    }
  } | null
}

export type PricingPageQueryVariables = Exact<{ [key: string]: never }>

export type PricingPageQuery = {
  __typename: 'Query'
  pricingPage: {
    __typename: 'PricingPage'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
    content: {
      __typename: 'PricingPageContent'
      title?: string | null
      subtitle?: string | null
      logoSectionTitle?: string | null
      membershipSectionTitle: string
      membershipSectionSubtitle: string
      caseStudiesTitle: string
      caseStudiesSubtitle: string
      logos?: Array<{
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      }> | null
      reforgeComparisons?: Array<{
        __typename: 'ReforgeComparison'
        name?: string | null
        cost?: string | null
        audience?: string | null
        timeCommitment?: string | null
        relevance?: string | null
      }> | null
      features?: Array<{
        __typename: 'PricingPlanFeature'
        id: string
        feature: string
        category: {
          __typename: 'PricingPlanFeatureCategory'
          name: string
          order: number
        }
        values?: Array<{
          __typename: 'PricingPlanFeatureValue'
          id: string
          enabled?: boolean | null
          forPlan: string
          textValue?: string | null
        }> | null
      }> | null
      reviews?: Array<{
        __typename: 'Review'
        id: string
        authorName: string
        authorPosition: string
        complexContent: any
        image?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
      membershipSectionVideo: { __typename: 'MuxVideo'; playbackId?: string | null }
      caseStudies?: Array<{
        __typename: 'CaseStudy'
        id: string
        previewTitle?: string | null
        slug?: string | null
        contributors: Array<{
          __typename: 'CaseStudyContributor'
          id: string
          name: string
          company: string
          position: string
          avatarPhoto: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          }
        }>
      }> | null
      faqs?: Array<{
        __typename: 'MarketingFaq'
        id: string
        question: string
        answer: any
      }> | null
    }
  }
}

export type MarketingReimbursementPageQueryVariables = Exact<{ [key: string]: never }>

export type MarketingReimbursementPageQuery = {
  __typename: 'Query'
  reimbursementPage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type TeamsGetInTouchPageQueryVariables = Exact<{ [key: string]: never }>

export type TeamsGetInTouchPageQuery = {
  __typename: 'Query'
  teamsGetInTouchPage?: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  } | null
}

export type TeamsMarketingPageQueryVariables = Exact<{ [key: string]: never }>

export type TeamsMarketingPageQuery = {
  __typename: 'Query'
  teamsMarketingPage?: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  } | null
}

export type TermsOfServicePageQueryVariables = Exact<{ [key: string]: never }>

export type TermsOfServicePageQuery = {
  __typename: 'Query'
  termsOfServicePage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type PrivacyPolicyPageQueryVariables = Exact<{ [key: string]: never }>

export type PrivacyPolicyPageQuery = {
  __typename: 'Query'
  privacyPolicyPage: {
    __typename: 'Page'
    id: string
    title: string
    seo?: {
      __typename: 'PageSeo'
      metaDesc?: string | null
      metaTitle?: string | null
      publishDate?: string | null
      authors?: string | null
      shareDesc?: string | null
      shareGraphic?: any | null
      shareGraphicAlt?: string | null
      shareTitle?: string | null
      structuredDataSchema?: string | null
      type?: PageTypeEnum | null
    } | null
  }
}

export type MentionSearchQueryVariables = Exact<{
  nameQuery: Scalars['String']
}>

export type MentionSearchQuery = {
  __typename: 'Query'
  mentionSearch: Array<{
    __typename: 'UserMention'
    id: string
    name: string
    title: string
    slug: string
  }>
}

export type TeamMentionSearchQueryVariables = Exact<{
  nameQuery: Scalars['String']
}>

export type TeamMentionSearchQuery = {
  __typename: 'Query'
  teamMentionSearch: Array<{
    __typename: 'UserMention'
    id: string
    name: string
    title: string
    slug: string
  }>
}

export type ModuleCompletionStatusQueryVariables = Exact<{
  cmsSectionId: Scalars['ID']
}>

export type ModuleCompletionStatusQuery = {
  __typename: 'Query'
  moduleCompletionStatus: {
    __typename: 'ModuleCompletionStatus'
    isComplete: boolean
    programPercentComplete: number
  }
}

export type PlansForSaleQueryVariables = Exact<{ [key: string]: never }>

export type PlansForSaleQuery = {
  __typename: 'Query'
  plansForSale: Array<{
    __typename: 'PlanForSale'
    name: string
    pricePerYear: number
    maxSeatCount: number
    pricePerPerson: number
    stripeId: string
  }>
}

export type PostContributorsFilterQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>
  groupSlug?: InputMaybe<Scalars['String']>
  slugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  excludeSlugs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type PostContributorsFilterQuery = {
  __typename: 'Query'
  postContributorsFilter: Array<{
    __typename: 'PostContributor'
    id: string
    slug: string
    fullName: string
    avatarUrl: string
    kind: string
    hasBadge?: boolean | null
  }>
}

export type PostListReactionFragment = {
  __typename: 'Reaction'
  id: string
  kind: ReactionKind
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null }
  }
}

export type PostListReplyFragment = {
  __typename: 'Reply'
  id: string
  createdAt: string
  createdAtInWords: string
  bodyText?: string | null
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      avatarUrl: string
      hasBadge?: boolean | null
      companyName?: string | null
      slug?: string | null
      role?: string | null
      kind?: string | null
    }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
}

export type PostListGroupFragment = {
  __typename: 'Group'
  id: string
  title: string
  slug: string
}

export type PostListTopicFragment = {
  __typename: 'PostTopic'
  id: string
  kind?: string | null
  slug: string
  title: string
  topicId?: string | null
  groupId?: string | null
}

export type PostListIndustryFragment = {
  __typename: 'Industry'
  id: string
  name?: string | null
  slug?: string | null
}

export type PostListUserFragment = {
  __typename: 'User'
  id: string
  profile: {
    __typename: 'UserProfile'
    slug?: string | null
    fullName?: string | null
    avatarUrl: string
    role?: string | null
    companyName?: string | null
    status: UserStatus
    hasBadge?: boolean | null
    kind?: string | null
    location?: string | null
  }
}

export type PostListCommentFragment = {
  __typename: 'Comment'
  id: string
  createdAt: string
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null }
  }
  reactions: Array<{ __typename: 'Reaction'; id: string; kind: ReactionKind }>
}

export type PostListPostFragment = {
  __typename: 'Post'
  id: string
  toParam: string
  body?: string | null
  bodyText?: string | null
  title?: string | null
  category?: string | null
  createdAtDate?: string | null
  createdAtInWords: string
  updatedAtInWords?: string | null
  lastRespondedAtInWords?: string | null
  responseCount: number
  cmsProgramId?: string | null
  following: boolean
  images: Array<string>
  basedOn?: string | null
  cmsSectionName?: string | null
  cmsSectionLink?: string | null
  referenceImageUrl?: string | null
  skipForumIndex?: boolean | null
  replies: Array<{
    __typename: 'Reply'
    id: string
    createdAt: string
    createdAtInWords: string
    bodyText?: string | null
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        avatarUrl: string
        hasBadge?: boolean | null
        companyName?: string | null
        slug?: string | null
        role?: string | null
        kind?: string | null
      }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
  }>
  groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
  topics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    slug: string
    title: string
    topicId?: string | null
    groupId?: string | null
  }>
  industries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      slug?: string | null
      fullName?: string | null
      avatarUrl: string
      role?: string | null
      companyName?: string | null
      status: UserStatus
      hasBadge?: boolean | null
      kind?: string | null
      location?: string | null
    }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
  comments: Array<{
    __typename: 'Comment'
    id: string
    createdAt: string
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
    reactions: Array<{ __typename: 'Reaction'; id: string; kind: ReactionKind }>
  }>
}

export type PostListFieldsFragment = {
  __typename: 'PostList'
  unreadPostIds: Array<string>
  pagination: { __typename: 'Pagination'; currentPage: number; totalPages: number }
  posts: Array<{
    __typename: 'Post'
    id: string
    toParam: string
    body?: string | null
    bodyText?: string | null
    title?: string | null
    category?: string | null
    createdAtDate?: string | null
    createdAtInWords: string
    updatedAtInWords?: string | null
    lastRespondedAtInWords?: string | null
    responseCount: number
    cmsProgramId?: string | null
    following: boolean
    images: Array<string>
    basedOn?: string | null
    cmsSectionName?: string | null
    cmsSectionLink?: string | null
    referenceImageUrl?: string | null
    skipForumIndex?: boolean | null
    replies: Array<{
      __typename: 'Reply'
      id: string
      createdAt: string
      createdAtInWords: string
      bodyText?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
          hasBadge?: boolean | null
          companyName?: string | null
          slug?: string | null
          role?: string | null
          kind?: string | null
        }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
      }>
    }>
    groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
    topics: Array<{
      __typename: 'PostTopic'
      id: string
      kind?: string | null
      slug: string
      title: string
      topicId?: string | null
      groupId?: string | null
    }>
    industries: Array<{
      __typename: 'Industry'
      id: string
      name?: string | null
      slug?: string | null
    }>
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        slug?: string | null
        fullName?: string | null
        avatarUrl: string
        role?: string | null
        companyName?: string | null
        status: UserStatus
        hasBadge?: boolean | null
        kind?: string | null
        location?: string | null
      }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
    comments: Array<{
      __typename: 'Comment'
      id: string
      createdAt: string
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
      reactions: Array<{ __typename: 'Reaction'; id: string; kind: ReactionKind }>
    }>
  }>
}

export type PostListQueryVariables = Exact<{
  audience?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  businessModels?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  contributors?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  filter?: InputMaybe<Scalars['String']>
  group?: InputMaybe<Scalars['String']>
  industries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  intersectingGroups?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  postSource: PostSource
  postType?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  topics?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type PostListQuery = {
  __typename: 'Query'
  discussionPosts: {
    __typename: 'PostList'
    unreadPostIds: Array<string>
    pagination: { __typename: 'Pagination'; currentPage: number; totalPages: number }
    posts: Array<{
      __typename: 'Post'
      id: string
      toParam: string
      body?: string | null
      bodyText?: string | null
      title?: string | null
      category?: string | null
      createdAtDate?: string | null
      createdAtInWords: string
      updatedAtInWords?: string | null
      lastRespondedAtInWords?: string | null
      responseCount: number
      cmsProgramId?: string | null
      following: boolean
      images: Array<string>
      basedOn?: string | null
      cmsSectionName?: string | null
      cmsSectionLink?: string | null
      referenceImageUrl?: string | null
      skipForumIndex?: boolean | null
      replies: Array<{
        __typename: 'Reply'
        id: string
        createdAt: string
        createdAtInWords: string
        bodyText?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
            hasBadge?: boolean | null
            companyName?: string | null
            slug?: string | null
            role?: string | null
            kind?: string | null
          }
        }
        reactions: Array<{
          __typename: 'Reaction'
          id: string
          kind: ReactionKind
          user: {
            __typename: 'User'
            id: string
            profile: { __typename: 'UserProfile'; fullName?: string | null }
          }
        }>
      }>
      groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        kind?: string | null
        slug: string
        title: string
        topicId?: string | null
        groupId?: string | null
      }>
      industries: Array<{
        __typename: 'Industry'
        id: string
        name?: string | null
        slug?: string | null
      }>
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          slug?: string | null
          fullName?: string | null
          avatarUrl: string
          role?: string | null
          companyName?: string | null
          status: UserStatus
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
        }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
      }>
      comments: Array<{
        __typename: 'Comment'
        id: string
        createdAt: string
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
        reactions: Array<{ __typename: 'Reaction'; id: string; kind: ReactionKind }>
      }>
    }>
  }
}

export type PostReactionFragment = {
  __typename: 'Reaction'
  id: string
  kind: ReactionKind
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null }
  }
}

export type PostUserFragment = {
  __typename: 'User'
  id: string
  profile: {
    __typename: 'UserProfile'
    fullName?: string | null
    avatarUrl: string
    hasBadge?: boolean | null
    companyName?: string | null
    location?: string | null
    status: UserStatus
    slug?: string | null
    role?: string | null
    kind?: string | null
  }
}

export type PostActivityFragment = {
  __typename: 'Activity'
  id: string
  createdAt: string
  createdAtInWords: string
  action: string
  trackableId: string
  trackableSlug: string
  trackableTitle: string
  trackableType: string
  userId: string
}

export type PostReplyCommentFragment = {
  __typename: 'Comment'
  id: string
  body?: string | null
  bodyText?: string | null
  commentableId?: string | null
  parentId?: string | null
  createdAt: string
  createdAtInWords: string
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      avatarUrl: string
      hasBadge?: boolean | null
      companyName?: string | null
      location?: string | null
      status: UserStatus
      slug?: string | null
      role?: string | null
      kind?: string | null
    }
  }
}

export type PostReplyFragment = {
  __typename: 'Reply'
  id: string
  createdAt: string
  createdAtInWords: string
  body?: string | null
  bodyText?: string | null
  replyableId: string
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      avatarUrl: string
      hasBadge?: boolean | null
      companyName?: string | null
      location?: string | null
      status: UserStatus
      slug?: string | null
      role?: string | null
      kind?: string | null
    }
  }
  comments: Array<{
    __typename: 'Comment'
    id: string
    body?: string | null
    bodyText?: string | null
    commentableId?: string | null
    parentId?: string | null
    createdAt: string
    createdAtInWords: string
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        avatarUrl: string
        hasBadge?: boolean | null
        companyName?: string | null
        location?: string | null
        status: UserStatus
        slug?: string | null
        role?: string | null
        kind?: string | null
      }
    }
  }>
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
}

export type PostCommentFragment = {
  __typename: 'Comment'
  id: string
  createdAt: string
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; fullName?: string | null }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
}

export type PostTopicFragment = {
  __typename: 'PostTopic'
  id: string
  kind?: string | null
  slug: string
  title: string
  topicId?: string | null
  groupId?: string | null
}

export type PostGroupFragment = {
  __typename: 'Group'
  id: string
  title: string
  slug: string
}

export type PostIndustryFragment = {
  __typename: 'Industry'
  id: string
  name?: string | null
  slug?: string | null
}

export type PostUnreadElementFragment = {
  __typename: 'UnreadElement'
  id: string
  type: string
}

export type PostShowFieldsFragment = {
  __typename: 'Post'
  id: string
  toParam: string
  body?: string | null
  bodyText?: string | null
  title?: string | null
  category?: string | null
  createdAtDate?: string | null
  createdAtInWords: string
  updatedAtInWords?: string | null
  lastRespondedAtInWords?: string | null
  responseCount: number
  cmsProgramId?: string | null
  following: boolean
  images: Array<string>
  basedOn?: string | null
  cmsSectionName?: string | null
  cmsSectionLink?: string | null
  referenceImageUrl?: string | null
  skipForumIndex?: boolean | null
  categories: Array<string>
  unreadElements: Array<{ __typename: 'UnreadElement'; id: string; type: string }>
  replies: Array<{
    __typename: 'Reply'
    id: string
    createdAt: string
    createdAtInWords: string
    body?: string | null
    bodyText?: string | null
    replyableId: string
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        avatarUrl: string
        hasBadge?: boolean | null
        companyName?: string | null
        location?: string | null
        status: UserStatus
        slug?: string | null
        role?: string | null
        kind?: string | null
      }
    }
    comments: Array<{
      __typename: 'Comment'
      id: string
      body?: string | null
      bodyText?: string | null
      commentableId?: string | null
      parentId?: string | null
      createdAt: string
      createdAtInWords: string
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
      }>
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
          hasBadge?: boolean | null
          companyName?: string | null
          location?: string | null
          status: UserStatus
          slug?: string | null
          role?: string | null
          kind?: string | null
        }
      }
    }>
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
  }>
  groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
  topics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    slug: string
    title: string
    topicId?: string | null
    groupId?: string | null
  }>
  industries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
  user: {
    __typename: 'User'
    id: string
    profile: {
      __typename: 'UserProfile'
      fullName?: string | null
      avatarUrl: string
      hasBadge?: boolean | null
      companyName?: string | null
      location?: string | null
      status: UserStatus
      slug?: string | null
      role?: string | null
      kind?: string | null
    }
  }
  reactions: Array<{
    __typename: 'Reaction'
    id: string
    kind: ReactionKind
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
  }>
  comments: Array<{
    __typename: 'Comment'
    id: string
    createdAt: string
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; fullName?: string | null }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
  }>
  activities: Array<{
    __typename: 'Activity'
    id: string
    createdAt: string
    createdAtInWords: string
    action: string
    trackableId: string
    trackableSlug: string
    trackableTitle: string
    trackableType: string
    userId: string
  }>
}

export type PostShowQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type PostShowQuery = {
  __typename: 'Query'
  discussionPost?: {
    __typename: 'Post'
    id: string
    toParam: string
    body?: string | null
    bodyText?: string | null
    title?: string | null
    category?: string | null
    createdAtDate?: string | null
    createdAtInWords: string
    updatedAtInWords?: string | null
    lastRespondedAtInWords?: string | null
    responseCount: number
    cmsProgramId?: string | null
    following: boolean
    images: Array<string>
    basedOn?: string | null
    cmsSectionName?: string | null
    cmsSectionLink?: string | null
    referenceImageUrl?: string | null
    skipForumIndex?: boolean | null
    categories: Array<string>
    unreadElements: Array<{ __typename: 'UnreadElement'; id: string; type: string }>
    replies: Array<{
      __typename: 'Reply'
      id: string
      createdAt: string
      createdAtInWords: string
      body?: string | null
      bodyText?: string | null
      replyableId: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
          hasBadge?: boolean | null
          companyName?: string | null
          location?: string | null
          status: UserStatus
          slug?: string | null
          role?: string | null
          kind?: string | null
        }
      }
      comments: Array<{
        __typename: 'Comment'
        id: string
        body?: string | null
        bodyText?: string | null
        commentableId?: string | null
        parentId?: string | null
        createdAt: string
        createdAtInWords: string
        reactions: Array<{
          __typename: 'Reaction'
          id: string
          kind: ReactionKind
          user: {
            __typename: 'User'
            id: string
            profile: { __typename: 'UserProfile'; fullName?: string | null }
          }
        }>
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            avatarUrl: string
            hasBadge?: boolean | null
            companyName?: string | null
            location?: string | null
            status: UserStatus
            slug?: string | null
            role?: string | null
            kind?: string | null
          }
        }
      }>
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
      }>
    }>
    groups: Array<{ __typename: 'Group'; id: string; title: string; slug: string }>
    topics: Array<{
      __typename: 'PostTopic'
      id: string
      kind?: string | null
      slug: string
      title: string
      topicId?: string | null
      groupId?: string | null
    }>
    industries: Array<{
      __typename: 'Industry'
      id: string
      name?: string | null
      slug?: string | null
    }>
    user: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        avatarUrl: string
        hasBadge?: boolean | null
        companyName?: string | null
        location?: string | null
        status: UserStatus
        slug?: string | null
        role?: string | null
        kind?: string | null
      }
    }
    reactions: Array<{
      __typename: 'Reaction'
      id: string
      kind: ReactionKind
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
    }>
    comments: Array<{
      __typename: 'Comment'
      id: string
      createdAt: string
      user: {
        __typename: 'User'
        id: string
        profile: { __typename: 'UserProfile'; fullName?: string | null }
      }
      reactions: Array<{
        __typename: 'Reaction'
        id: string
        kind: ReactionKind
        user: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        }
      }>
    }>
    activities: Array<{
      __typename: 'Activity'
      id: string
      createdAt: string
      createdAtInWords: string
      action: string
      trackableId: string
      trackableSlug: string
      trackableTitle: string
      trackableType: string
      userId: string
    }>
  } | null
}

export type HomePagePostsPartsFragment = {
  __typename: 'Post'
  id: string
  title?: string | null
  text: string
  href: string
  timeAgo: string
  contributors: Array<{
    __typename: 'User'
    id: string
    fullName?: string | null
    avatarUrl: string
  }>
}

export type PostUserPartsFragment = {
  __typename: 'User'
  id: string
  fullName?: string | null
  avatarUrl: string
}

export type PinnedPostsQueryVariables = Exact<{ [key: string]: never }>

export type PinnedPostsQuery = {
  __typename: 'Query'
  pinnedPosts: Array<{
    __typename: 'Post'
    id: string
    title?: string | null
    text: string
    href: string
    timeAgo: string
    contributors: Array<{
      __typename: 'User'
      id: string
      fullName?: string | null
      avatarUrl: string
    }>
  }>
}

export type PostsSearchPostFragment = {
  __typename: 'Post'
  id: string
  reactionCount: number
  responseCount: number
  title?: string | null
  toParam: string
  user: { __typename: 'User'; fullName?: string | null; roleWithCompany?: string | null }
}

export type PostsSearchGroupFragment = {
  __typename: 'Group'
  id: string
  title: string
  slug: string
  userIsMember: boolean
  membersCount: number
  groupCategory?: {
    __typename: 'GroupCategory'
    category?: string | null
    description?: string | null
  } | null
  memberStack: Array<{
    __typename: 'User'
    id: string
    slug?: string | null
    fullName?: string | null
    kind?: string | null
    avatarUrl: string
    isCurrentUser: boolean
    hasBadge: boolean
  }>
  topics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    groupId?: string | null
    slug: string
    title: string
    topicId?: string | null
  }>
  populatedTopics: Array<{
    __typename: 'PostTopic'
    id: string
    kind?: string | null
    groupId?: string | null
    slug: string
    title: string
    topicId?: string | null
  }>
  industries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
  populatedIndustries: Array<{
    __typename: 'Industry'
    id: string
    name?: string | null
    slug?: string | null
  }>
}

export type PostsSearchQueryVariables = Exact<{
  query: Scalars['String']
}>

export type PostsSearchQuery = {
  __typename: 'Query'
  postsSearch: {
    __typename: 'PostsSearch'
    posts: Array<{
      __typename: 'Post'
      id: string
      reactionCount: number
      responseCount: number
      title?: string | null
      toParam: string
      user: {
        __typename: 'User'
        fullName?: string | null
        roleWithCompany?: string | null
      }
    }>
    topics: Array<{
      __typename: 'PostsSearchTopic'
      id: string
      title: string
      topicId: string
      groupSlug: string
    }>
    members: Array<{
      __typename: 'PostContributor'
      id: string
      avatarUrl: string
      fullName: string
      hasBadge?: boolean | null
      kind: string
      slug: string
    }>
    groups: Array<{
      __typename: 'Group'
      id: string
      title: string
      slug: string
      userIsMember: boolean
      membersCount: number
      groupCategory?: {
        __typename: 'GroupCategory'
        category?: string | null
        description?: string | null
      } | null
      memberStack: Array<{
        __typename: 'User'
        id: string
        slug?: string | null
        fullName?: string | null
        kind?: string | null
        avatarUrl: string
        isCurrentUser: boolean
        hasBadge: boolean
      }>
      topics: Array<{
        __typename: 'PostTopic'
        id: string
        kind?: string | null
        groupId?: string | null
        slug: string
        title: string
        topicId?: string | null
      }>
      populatedTopics: Array<{
        __typename: 'PostTopic'
        id: string
        kind?: string | null
        groupId?: string | null
        slug: string
        title: string
        topicId?: string | null
      }>
      industries: Array<{
        __typename: 'Industry'
        id: string
        name?: string | null
        slug?: string | null
      }>
      populatedIndustries: Array<{
        __typename: 'Industry'
        id: string
        name?: string | null
        slug?: string | null
      }>
    }>
  }
}

export type PostSummaryFieldsFragment = {
  __typename: 'Post'
  id: string
  createdAtInWords: string
  title?: string | null
  toParam: string
}

export type ReplySummaryFieldsFragment = {
  __typename: 'Reply'
  id: string
  body?: string | null
  createdAtInWords: string
  post?: { __typename: 'Post'; id: string; title?: string | null; toParam: string } | null
}

export type CommentSummaryFieldsFragment = {
  __typename: 'Comment'
  id: string
  body?: string | null
  createdAtInWords: string
  post?: { __typename: 'Post'; id: string; title?: string | null; toParam: string } | null
}

type ForumContributionFields_Comment_Fragment = {
  __typename: 'Comment'
  id: string
  body?: string | null
  createdAtInWords: string
  post?: { __typename: 'Post'; id: string; title?: string | null; toParam: string } | null
}

type ForumContributionFields_Post_Fragment = {
  __typename: 'Post'
  id: string
  createdAtInWords: string
  title?: string | null
  toParam: string
}

type ForumContributionFields_Reply_Fragment = {
  __typename: 'Reply'
  id: string
  body?: string | null
  createdAtInWords: string
  post?: { __typename: 'Post'; id: string; title?: string | null; toParam: string } | null
}

export type ForumContributionFieldsFragment =
  | ForumContributionFields_Comment_Fragment
  | ForumContributionFields_Post_Fragment
  | ForumContributionFields_Reply_Fragment

export type MemberHostedCohortFieldsFragment = {
  __typename: 'Cohort'
  id: string
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    code?: string | null
    name: string
    iconImageUrl?: string | null
  }
  season: { __typename: 'Season'; id: string; name: string; year: string }
}

export type MemberProfileUserCohortFieldsFragment = {
  __typename: 'UserCohort'
  id: string
  season: string
  cohort: {
    __typename: 'Cohort'
    id: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      code?: string | null
      iconImageUrl?: string | null
    }
    season: { __typename: 'Season'; id: string; name: string; year: string }
  }
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
}

export type MemberProfileUserCourseSessionFieldsFragment = {
  __typename: 'CourseSession'
  id: string
  courseTitle: string
  startsAt: string
}

export type MemberProfileUserFieldsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  fullName?: string | null
  monthJoined?: string | null
  yearJoined?: string | null
  status: UserStatus
  slackUserId?: string | null
  slackResponseTime?: string | null
  joinSlackHref?: string | null
  networkOnboarded: boolean
  networkRecommendations: Array<string>
  clips: Array<{
    __typename: 'Clip'
    id: string
    title: string
    slug: string
    clipSubtype?: string | null
    duration?: number | null
    thumbnailUrl: string
    summary?: string | null
    summaryWistiaCode: string
    hasBeenViewedByCurrentUser: boolean
    lastViewedAt?: string | null
    company?: {
      __typename: 'Company'
      id: string
      cardLogoUrl?: string | null
      name?: string | null
    } | null
    users: Array<{
      __typename: 'User'
      id: string
      avatarUrl: string
      fullName?: string | null
      role?: string | null
      companyName?: string | null
      profile: { __typename: 'UserProfile'; profileUrl?: string | null }
    }>
  }>
  cohorts?: {
    __typename: 'UserCohorts'
    current: Array<{
      __typename: 'UserCohort'
      id: string
      season: string
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; name: string; year: string }
      }
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    }>
    previous: Array<{
      __typename: 'UserCohort'
      id: string
      season: string
      cohort: {
        __typename: 'Cohort'
        id: string
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          name: string
          code?: string | null
          iconImageUrl?: string | null
        }
        season: { __typename: 'Season'; id: string; name: string; year: string }
      }
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    }>
  } | null
  userCourses: {
    __typename: 'UserCourses'
    all?: Array<{
      __typename: 'UserCourse'
      id: string
      course: { __typename: 'Course'; title: string }
      courseSession: {
        __typename: 'CourseSession'
        id: string
        courseTitle: string
        startsAt: string
      }
    }> | null
  }
  cohortsHosted: Array<{
    __typename: 'Cohort'
    id: string
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      code?: string | null
      name: string
      iconImageUrl?: string | null
    }
    season: { __typename: 'Season'; id: string; name: string; year: string }
  }>
  is: { __typename: 'UserIs'; expired: boolean; creator: boolean }
  lastForumContributions: Array<
    | {
        __typename: 'Comment'
        id: string
        body?: string | null
        createdAtInWords: string
        post?: {
          __typename: 'Post'
          id: string
          title?: string | null
          toParam: string
        } | null
      }
    | {
        __typename: 'Post'
        id: string
        createdAtInWords: string
        title?: string | null
        toParam: string
      }
    | {
        __typename: 'Reply'
        id: string
        body?: string | null
        createdAtInWords: string
        post?: {
          __typename: 'Post'
          id: string
          title?: string | null
          toParam: string
        } | null
      }
  >
  profile: {
    __typename: 'UserProfile'
    aboutMeText?: string | null
    avatarUrl: string
    allowDirectMessages?: boolean | null
    areasOfExpertiseTags?: string | null
    askMeAboutText?: string | null
    businessModelTags?: string | null
    communityInterestText?: string | null
    companyProductText?: string | null
    companyAudienceTags?: string | null
    companyName?: string | null
    communityInterestTags?: string | null
    firstName?: string | null
    hasBadge?: boolean | null
    fullName?: string | null
    kind?: string | null
    lastName?: string | null
    learningGoalsText?: string | null
    linkedin?: string | null
    location?: string | null
    role?: string | null
    sanitySlug?: string | null
    twitter?: string | null
  }
  previousCompanies: Array<{
    __typename: 'UserCompany'
    id: string
    company: {
      __typename: 'Company'
      id: string
      companyName?: string | null
      name?: string | null
    }
  }>
  recentSlackJoins: Array<{
    __typename: 'SlackAccount'
    slackUserId: string
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; avatarUrl: string; fullName?: string | null }
    }
  }>
}

export type MemberProfileSlackAccountFragment = {
  __typename: 'SlackAccount'
  slackUserId: string
  user: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; avatarUrl: string; fullName?: string | null }
  }
}

export type MemberProfileQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type MemberProfileQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    fullName?: string | null
    monthJoined?: string | null
    yearJoined?: string | null
    status: UserStatus
    slackUserId?: string | null
    slackResponseTime?: string | null
    joinSlackHref?: string | null
    networkOnboarded: boolean
    networkRecommendations: Array<string>
    clips: Array<{
      __typename: 'Clip'
      id: string
      title: string
      slug: string
      clipSubtype?: string | null
      duration?: number | null
      thumbnailUrl: string
      summary?: string | null
      summaryWistiaCode: string
      hasBeenViewedByCurrentUser: boolean
      lastViewedAt?: string | null
      company?: {
        __typename: 'Company'
        id: string
        cardLogoUrl?: string | null
        name?: string | null
      } | null
      users: Array<{
        __typename: 'User'
        id: string
        avatarUrl: string
        fullName?: string | null
        role?: string | null
        companyName?: string | null
        profile: { __typename: 'UserProfile'; profileUrl?: string | null }
      }>
    }>
    cohorts?: {
      __typename: 'UserCohorts'
      current: Array<{
        __typename: 'UserCohort'
        id: string
        season: string
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; name: string; year: string }
        }
        snapshot?: {
          __typename: 'Snapshot'
          id: string
          token: string
          released: boolean
        } | null
      }>
      previous: Array<{
        __typename: 'UserCohort'
        id: string
        season: string
        cohort: {
          __typename: 'Cohort'
          id: string
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            name: string
            code?: string | null
            iconImageUrl?: string | null
          }
          season: { __typename: 'Season'; id: string; name: string; year: string }
        }
        snapshot?: {
          __typename: 'Snapshot'
          id: string
          token: string
          released: boolean
        } | null
      }>
    } | null
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        course: { __typename: 'Course'; title: string }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          courseTitle: string
          startsAt: string
        }
      }> | null
    }
    cohortsHosted: Array<{
      __typename: 'Cohort'
      id: string
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        code?: string | null
        name: string
        iconImageUrl?: string | null
      }
      season: { __typename: 'Season'; id: string; name: string; year: string }
    }>
    is: { __typename: 'UserIs'; expired: boolean; creator: boolean }
    lastForumContributions: Array<
      | {
          __typename: 'Comment'
          id: string
          body?: string | null
          createdAtInWords: string
          post?: {
            __typename: 'Post'
            id: string
            title?: string | null
            toParam: string
          } | null
        }
      | {
          __typename: 'Post'
          id: string
          createdAtInWords: string
          title?: string | null
          toParam: string
        }
      | {
          __typename: 'Reply'
          id: string
          body?: string | null
          createdAtInWords: string
          post?: {
            __typename: 'Post'
            id: string
            title?: string | null
            toParam: string
          } | null
        }
    >
    profile: {
      __typename: 'UserProfile'
      aboutMeText?: string | null
      avatarUrl: string
      allowDirectMessages?: boolean | null
      areasOfExpertiseTags?: string | null
      askMeAboutText?: string | null
      businessModelTags?: string | null
      communityInterestText?: string | null
      companyProductText?: string | null
      companyAudienceTags?: string | null
      companyName?: string | null
      communityInterestTags?: string | null
      firstName?: string | null
      hasBadge?: boolean | null
      fullName?: string | null
      kind?: string | null
      lastName?: string | null
      learningGoalsText?: string | null
      linkedin?: string | null
      location?: string | null
      role?: string | null
      sanitySlug?: string | null
      twitter?: string | null
    }
    previousCompanies: Array<{
      __typename: 'UserCompany'
      id: string
      company: {
        __typename: 'Company'
        id: string
        companyName?: string | null
        name?: string | null
      }
    }>
    recentSlackJoins: Array<{
      __typename: 'SlackAccount'
      slackUserId: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          avatarUrl: string
          fullName?: string | null
        }
      }
    }>
  } | null
}

export type PreviousCompaniesCompanyFieldsFragment = {
  __typename: 'Company'
  id: string
  companyName?: string | null
  name?: string | null
}

export type PreviousCompaniesFieldsFragment = {
  __typename: 'UserCompany'
  id: string
  company: {
    __typename: 'Company'
    id: string
    companyName?: string | null
    name?: string | null
  }
}

export type ProfileEditModalUserProfileFieldsFragment = {
  __typename: 'UserProfile'
  aboutMeText?: string | null
  allowDirectMessages?: boolean | null
  areasOfExpertiseTags?: string | null
  askMeAboutText?: string | null
  avatarUrl: string
  businessModelTags?: string | null
  communityInterestTags?: string | null
  communityInterestText?: string | null
  companyAudienceTags?: string | null
  companyName?: string | null
  companyProductText?: string | null
  ctaText?: string | null
  ctaUrl?: string | null
  firstName?: string | null
  lastName?: string | null
  learningGoalsText?: string | null
  linkedin?: string | null
  location?: string | null
  networkProfile?: any | null
  responseTime?: string | null
  role?: string | null
  timezone?: string | null
  twitter?: string | null
  previousCompanies?: Array<{
    __typename: 'UserCompany'
    id: string
    company: {
      __typename: 'Company'
      id: string
      companyName?: string | null
      name?: string | null
    }
  }> | null
}

export type ProfileEditModalUserFieldsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  slackUserId?: string | null
  is: {
    __typename: 'UserIs'
    badgeholder: boolean
    member: boolean
    paidMember: boolean
    creator: boolean
  }
  cohorts?: {
    __typename: 'UserCohorts'
    current: Array<{
      __typename: 'UserCohort'
      id: string
      programName: string
      season: string
    }>
  } | null
  profile: {
    __typename: 'UserProfile'
    aboutMeText?: string | null
    allowDirectMessages?: boolean | null
    areasOfExpertiseTags?: string | null
    askMeAboutText?: string | null
    avatarUrl: string
    businessModelTags?: string | null
    communityInterestTags?: string | null
    communityInterestText?: string | null
    companyAudienceTags?: string | null
    companyName?: string | null
    companyProductText?: string | null
    ctaText?: string | null
    ctaUrl?: string | null
    firstName?: string | null
    lastName?: string | null
    learningGoalsText?: string | null
    linkedin?: string | null
    location?: string | null
    networkProfile?: any | null
    responseTime?: string | null
    role?: string | null
    timezone?: string | null
    twitter?: string | null
    previousCompanies?: Array<{
      __typename: 'UserCompany'
      id: string
      company: {
        __typename: 'Company'
        id: string
        companyName?: string | null
        name?: string | null
      }
    }> | null
  }
}

export type ProfileEditModalQueryVariables = Exact<{ [key: string]: never }>

export type ProfileEditModalQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    slackUserId?: string | null
    is: {
      __typename: 'UserIs'
      badgeholder: boolean
      member: boolean
      paidMember: boolean
      creator: boolean
    }
    cohorts?: {
      __typename: 'UserCohorts'
      current: Array<{
        __typename: 'UserCohort'
        id: string
        programName: string
        season: string
      }>
    } | null
    profile: {
      __typename: 'UserProfile'
      aboutMeText?: string | null
      allowDirectMessages?: boolean | null
      areasOfExpertiseTags?: string | null
      askMeAboutText?: string | null
      avatarUrl: string
      businessModelTags?: string | null
      communityInterestTags?: string | null
      communityInterestText?: string | null
      companyAudienceTags?: string | null
      companyName?: string | null
      companyProductText?: string | null
      ctaText?: string | null
      ctaUrl?: string | null
      firstName?: string | null
      lastName?: string | null
      learningGoalsText?: string | null
      linkedin?: string | null
      location?: string | null
      networkProfile?: any | null
      responseTime?: string | null
      role?: string | null
      timezone?: string | null
      twitter?: string | null
      previousCompanies?: Array<{
        __typename: 'UserCompany'
        id: string
        company: {
          __typename: 'Company'
          id: string
          companyName?: string | null
          name?: string | null
        }
      }> | null
    }
  } | null
}

export type AddUserCompanyMutationVariables = Exact<{
  input: AddUserCompanyInput
}>

export type AddUserCompanyMutation = {
  __typename: 'Mutation'
  addUserCompany?: {
    __typename: 'AddUserCompanyPayload'
    user?: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        previousCompanies?: Array<{
          __typename: 'UserCompany'
          id: string
          company: {
            __typename: 'Company'
            id: string
            companyName?: string | null
            name?: string | null
          }
        }> | null
      }
    } | null
  } | null
}

export type DeleteUserCompanyMutationVariables = Exact<{
  input: DeleteUserCompanyInput
}>

export type DeleteUserCompanyMutation = {
  __typename: 'Mutation'
  deleteUserCompany?: {
    __typename: 'DeleteUserCompanyPayload'
    errors?: Array<string> | null
    user?: {
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        previousCompanies?: Array<{
          __typename: 'UserCompany'
          id: string
          company: {
            __typename: 'Company'
            id: string
            companyName?: string | null
            name?: string | null
          }
        }> | null
      }
    } | null
  } | null
}

export type ProfilePageQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type ProfilePageQuery = {
  __typename: 'Query'
  profile?: {
    __typename: 'Profile'
    artifactAuthor: {
      __typename: 'ArtifactAuthor'
      id: string
      userId?: string | null
      name?: string | null
      slug?: string | null
      workEntries?: Array<{
        __typename: 'WorkEntry'
        id?: string | null
        position?: string | null
        start?: string | null
        end?: string | null
        specificCompany?: boolean | null
        specificTimeframe?: boolean | null
        generalRole?: string | null
        generalTimeframe?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
          slug?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
        artifactContributions?: Array<{
          __typename: 'ArtifactContribution'
          id?: string | null
          contributionVerb?: string | null
          status?: string | null
          artifact?: {
            __typename: 'Artifact'
            id: string
            slug?: string | null
            publishDate?: string | null
            title?: string | null
            description?: any | null
            thumbnailUrl?: string | null
            viewCount?: number | null
            commentCount?: number | null
            topics?: any | null
            isFree?: boolean | null
            authors?: Array<{
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              slug?: string | null
              position?: string | null
              isCollectiveMember?: boolean | null
              avatarUrl?: string | null
              company?: { __typename: 'SanityCompany'; name?: string | null } | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            }> | null
            allContributions?: Array<{
              __typename: 'ArtifactContribution'
              contributionVerb?: string | null
              status?: string | null
              artifactAuthor?: {
                __typename: 'ArtifactAuthor'
                id: string
                name?: string | null
                slug?: string | null
                position?: string | null
                company?: { __typename: 'SanityCompany'; name?: string | null } | null
                avatarPhoto?: {
                  __typename: 'Image'
                  imageUrl?: string | null
                  imageAlt?: string | null
                  aspectRatio?: number | null
                } | null
              } | null
            }> | null
            filters?: Array<{
              __typename: 'ArtifactFilter'
              slug: string
              title: string
              type: string
            }> | null
            company?: {
              __typename: 'SanityCompany'
              id?: string | null
              name?: string | null
              logo?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            } | null
          } | null
        }> | null
      }> | null
    }
    user?: {
      __typename: 'ExpertUserProfile'
      avatarUrl: string
      aboutMeText?: string | null
      companyName?: string | null
      ctaText?: string | null
      ctaUrl?: string | null
      firstName?: string | null
      fullName?: string | null
      hasBadge: boolean
      lastName?: string | null
      linkedin?: string | null
      location?: string | null
      role?: string | null
      externalId?: string | null
      twitter?: string | null
      kind?: string | null
      is: { __typename: 'UserIs'; collective: boolean }
      previousCompanies: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; id: string; name?: string | null }
      }>
      allCourses: Array<{
        __typename: 'CclCourse'
        id: string
        title: string
        slug: string
        shortDescription?: string | null
        publishedAt?: string | null
        creators?: Array<{
          __typename: 'CclExpert'
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          startsAt: string
          duration?: string | null
          endsAt: string
        }> | null
      }>
      allHostedEvents: Array<{
        __typename: 'Event'
        id: string
        name: string
        slug: string
        past: boolean
        kind: string
        startsAtUtc: string
        endsAtUtc: string
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              avatarUrl: string
            }
          }
        }>
      }>
      allBlogPosts: Array<{
        __typename: 'CclBlogPost'
        id: string
        title: string
        slug: string
        thumbnailUrl?: string | null
        firstPublishedAt?: string | null
        summary?: any | null
        sourceId?: string | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          name?: string | null
          avatarUrl?: string | null
        }> | null
      }>
      allGuides: Array<{
        __typename: 'CclGuide'
        id: string
        title: string
        publishedAt: string
        overview?: string | null
        slug: string
        thumbnailUrl?: string | null
        stepsCount: number
        sourceId?: string | null
        estimatedTimeInMinutes?: number | null
        previewable?: boolean | null
        authors?: Array<{
          __typename: 'CclExpert'
          name: string
          avatarUrl?: string | null
        }> | null
      }>
      upcomingEvents: Array<{
        __typename: 'Event'
        id: string
        name: string
        slug: string
        startsAt?: string | null
        endsAt?: string | null
      }>
      upcomingLiveCourses: Array<{
        __typename: 'CclCourse'
        id: string
        title: string
        slug: string
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          duration?: string | null
          endsAt: string
        }> | null
      }>
    } | null
  } | null
}

export type ArtifactAuthorProfilesQueryVariables = Exact<{
  slug: Array<Scalars['String']> | Scalars['String']
}>

export type ArtifactAuthorProfilesQuery = {
  __typename: 'Query'
  artifactAuthorProfiles: Array<{
    __typename: 'Profile'
    title: string
    artifactAuthor: {
      __typename: 'ArtifactAuthor'
      id: string
      userId?: string | null
      name?: string | null
      slug?: string | null
      workEntries?: Array<{
        __typename: 'WorkEntry'
        id?: string | null
        position?: string | null
        start?: string | null
        end?: string | null
        specificCompany?: boolean | null
        specificTimeframe?: boolean | null
        generalRole?: string | null
        generalTimeframe?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
          slug?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
        artifactContributions?: Array<{
          __typename: 'ArtifactContribution'
          id?: string | null
          contributionVerb?: string | null
          status?: string | null
          artifact?: {
            __typename: 'Artifact'
            id: string
            slug?: string | null
            publishDate?: string | null
            title?: string | null
            description?: any | null
            thumbnailUrl?: string | null
            viewCount?: number | null
            commentCount?: number | null
            topics?: any | null
            isFree?: boolean | null
            authors?: Array<{
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              slug?: string | null
              position?: string | null
              isCollectiveMember?: boolean | null
              avatarUrl?: string | null
              company?: { __typename: 'SanityCompany'; name?: string | null } | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            }> | null
            allContributions?: Array<{
              __typename: 'ArtifactContribution'
              contributionVerb?: string | null
              status?: string | null
              artifactAuthor?: {
                __typename: 'ArtifactAuthor'
                id: string
                name?: string | null
                slug?: string | null
                position?: string | null
                company?: { __typename: 'SanityCompany'; name?: string | null } | null
                avatarPhoto?: {
                  __typename: 'Image'
                  imageUrl?: string | null
                  imageAlt?: string | null
                  aspectRatio?: number | null
                } | null
              } | null
            }> | null
            filters?: Array<{
              __typename: 'ArtifactFilter'
              slug: string
              title: string
              type: string
            }> | null
            company?: {
              __typename: 'SanityCompany'
              id?: string | null
              name?: string | null
              logo?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            } | null
          } | null
        }> | null
      }> | null
    }
  }>
}

export type ExpertUserProfileFragmentFragment = {
  __typename: 'ExpertUserProfile'
  avatarUrl: string
  aboutMeText?: string | null
  companyName?: string | null
  ctaText?: string | null
  ctaUrl?: string | null
  firstName?: string | null
  fullName?: string | null
  hasBadge: boolean
  lastName?: string | null
  linkedin?: string | null
  location?: string | null
  role?: string | null
  externalId?: string | null
  twitter?: string | null
  kind?: string | null
  is: { __typename: 'UserIs'; collective: boolean }
  previousCompanies: Array<{
    __typename: 'UserCompany'
    id: string
    company: { __typename: 'Company'; id: string; name?: string | null }
  }>
  allCourses: Array<{
    __typename: 'CclCourse'
    id: string
    title: string
    slug: string
    shortDescription?: string | null
    publishedAt?: string | null
    creators?: Array<{
      __typename: 'CclExpert'
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      startsAt: string
      duration?: string | null
      endsAt: string
    }> | null
  }>
  allHostedEvents: Array<{
    __typename: 'Event'
    id: string
    name: string
    slug: string
    past: boolean
    kind: string
    startsAtUtc: string
    endsAtUtc: string
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          avatarUrl: string
        }
      }
    }>
  }>
  allBlogPosts: Array<{
    __typename: 'CclBlogPost'
    id: string
    title: string
    slug: string
    thumbnailUrl?: string | null
    firstPublishedAt?: string | null
    summary?: any | null
    sourceId?: string | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      name?: string | null
      avatarUrl?: string | null
    }> | null
  }>
  allGuides: Array<{
    __typename: 'CclGuide'
    id: string
    title: string
    publishedAt: string
    overview?: string | null
    slug: string
    thumbnailUrl?: string | null
    stepsCount: number
    sourceId?: string | null
    estimatedTimeInMinutes?: number | null
    previewable?: boolean | null
    authors?: Array<{
      __typename: 'CclExpert'
      name: string
      avatarUrl?: string | null
    }> | null
  }>
  upcomingEvents: Array<{
    __typename: 'Event'
    id: string
    name: string
    slug: string
    startsAt?: string | null
    endsAt?: string | null
  }>
  upcomingLiveCourses: Array<{
    __typename: 'CclCourse'
    id: string
    title: string
    slug: string
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      duration?: string | null
      endsAt: string
    }> | null
  }>
}

export type ArtifactAuthorFragmentFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  name?: string | null
  slug?: string | null
  position?: string | null
  company?: { __typename: 'SanityCompany'; name?: string | null } | null
  avatarPhoto?: {
    __typename: 'Image'
    imageUrl?: string | null
    imageAlt?: string | null
    aspectRatio?: number | null
  } | null
}

export type ArtifactFragmentFragment = {
  __typename: 'Artifact'
  id: string
  slug?: string | null
  publishDate?: string | null
  title?: string | null
  description?: any | null
  thumbnailUrl?: string | null
  viewCount?: number | null
  commentCount?: number | null
  topics?: any | null
  isFree?: boolean | null
  filters?: Array<{
    __typename: 'ArtifactFilter'
    slug: string
    title: string
    type: string
  }> | null
  company?: {
    __typename: 'SanityCompany'
    id?: string | null
    name?: string | null
    logo?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
  } | null
  authors?: Array<{
    __typename: 'ArtifactAuthor'
    id: string
    name?: string | null
    slug?: string | null
    position?: string | null
    isCollectiveMember?: boolean | null
    avatarUrl?: string | null
    company?: { __typename: 'SanityCompany'; name?: string | null } | null
  }> | null
}

export type ArtifactAuthorProfileFragmentFragment = {
  __typename: 'ArtifactAuthor'
  id: string
  userId?: string | null
  name?: string | null
  slug?: string | null
  workEntries?: Array<{
    __typename: 'WorkEntry'
    id?: string | null
    position?: string | null
    start?: string | null
    end?: string | null
    specificCompany?: boolean | null
    specificTimeframe?: boolean | null
    generalRole?: string | null
    generalTimeframe?: string | null
    company?: {
      __typename: 'SanityCompany'
      id?: string | null
      name?: string | null
      slug?: string | null
      logo?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    } | null
    artifactContributions?: Array<{
      __typename: 'ArtifactContribution'
      id?: string | null
      contributionVerb?: string | null
      status?: string | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        slug?: string | null
        publishDate?: string | null
        title?: string | null
        description?: any | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        topics?: any | null
        isFree?: boolean | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          isCollectiveMember?: boolean | null
          avatarUrl?: string | null
          company?: { __typename: 'SanityCompany'; name?: string | null } | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
        allContributions?: Array<{
          __typename: 'ArtifactContribution'
          contributionVerb?: string | null
          status?: string | null
          artifactAuthor?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            slug?: string | null
            position?: string | null
            company?: { __typename: 'SanityCompany'; name?: string | null } | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
        filters?: Array<{
          __typename: 'ArtifactFilter'
          slug: string
          title: string
          type: string
        }> | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      } | null
    }> | null
  }> | null
}

export type CmsModuleGuestUserPartsFragment = {
  __typename: 'User'
  id: string
  profile: { __typename: 'UserProfile'; avatarUrl: string; fullName?: string | null }
}

export type ProgramCardActiveSeasonPartsFragment = {
  __typename: 'Season'
  id: string
  name: string
  year: string
  startsAt?: string | null
  openForEnrollment: boolean
  enrollmentsOpenAt?: string | null
  showCohortViewerAt?: string | null
}

export type ProgramCardUserPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  shouldSeeTrialMessaging: boolean
  timezone?: string | null
  is: { __typename: 'UserIs'; member: boolean; paidMember: boolean }
  can: { __typename: 'UserCan'; enrollInCohort: boolean }
}

export type ProgramCardTopSectionPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  enrolled: boolean
  upcoming: boolean
  name: string
  programImage?: string | null
  iconImageUrl?: string | null
  heroImageUrl?: string | null
  showNewBadge: boolean
  description?: string | null
  topic?: string | null
}

export type ProgramCardPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  name: string
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  slug: string
  lessonCount: number
  iconImageUrl?: string | null
  numBookmarks?: number | null
  progressPercent: number
  purchased: boolean
  programImage?: string | null
  description?: string | null
  upcoming: boolean
  enrolled: boolean
  canEnroll: boolean
  deprecatedAt?: string | null
  heroImageUrl?: string | null
  showNewBadge: boolean
  topic?: string | null
  collaborators: Array<{
    __typename: 'Host'
    id: string
    name?: string | null
    avatarUrl: string
  }>
}

export type ProgramsCmsSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  slug: string
  conceptIcon?: string | null
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  cmsModuleId: string
  cmsProgramId: string
  estimatedTime: number
  href: string
  heroImageUrl?: string | null
  name: string
  numBookmarks?: number | null
  numPhases?: number | null
  showNewBadge: boolean
  shortDescription?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  unlimitedAccess: boolean
  lessonNum?: number | null
  accessLevel?: string | null
  previewable: boolean
  lastViewedAt?: string | null
  contentSubtype?: string | null
  children: Array<{
    __typename: 'CmsSection'
    id: string
    slug: string
    conceptIcon?: string | null
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    estimatedTime: number
    href: string
    heroImageUrl?: string | null
    name: string
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    shortDescription?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    lessonNum?: number | null
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }>
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    programImage?: string | null
    notLaunched?: boolean | null
    name: string
    enrolled: boolean
    slug: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
}

export type CmsModulePartsFragment = {
  __typename: 'CmsModule'
  id: string
  anyCountsTowardsCompletion?: boolean | null
  estimatedTime: number
  published?: boolean | null
  publishAt?: string | null
  name: string
  numBookmarks?: number | null
  events: Array<{
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    willBeRecorded: boolean
    timezone?: string | null
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendingStatus?: string | null
    attendeesCount?: number | null
    lastViewedAt?: string | null
    isPublic?: boolean | null
    summaryWistiaCodeOptional?: string | null
    attendees: {
      __typename: 'EventAttendeeConnection'
      pageInfo: {
        __typename: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
      }
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    speakers?: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }> | null
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  }>
  project?: {
    __typename: 'CmsSection'
    id: string
    cmsProgramId: string
    cmsModuleId: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    showNewBadge: boolean
    estimatedTime: number
    heroImageUrl?: string | null
    href: string
    lastViewedAt?: string | null
    name: string
    numBookmarks?: number | null
    numPhases?: number | null
    shortDescription?: string | null
    slug: string
    unlimitedAccess: boolean
    accessLevel?: string | null
    projectIcon?: string | null
    previewable: boolean
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      numBookmarks?: number | null
      progressPercent: number
      programImage?: string | null
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
  } | null
  featuredGuests: Array<{
    __typename: 'EventSpeaker'
    id: string
    user: {
      __typename: 'User'
      id: string
      profile: { __typename: 'UserProfile'; avatarUrl: string; fullName?: string | null }
    }
  }>
  cmsSections: Array<{
    __typename: 'CmsSection'
    id: string
    slug: string
    conceptIcon?: string | null
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    cmsModuleId: string
    cmsProgramId: string
    estimatedTime: number
    href: string
    heroImageUrl?: string | null
    name: string
    numBookmarks?: number | null
    numPhases?: number | null
    showNewBadge: boolean
    shortDescription?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    unlimitedAccess: boolean
    lessonNum?: number | null
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    children: Array<{
      __typename: 'CmsSection'
      id: string
      slug: string
      conceptIcon?: string | null
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      cmsModuleId: string
      cmsProgramId: string
      estimatedTime: number
      href: string
      heroImageUrl?: string | null
      name: string
      numBookmarks?: number | null
      numPhases?: number | null
      showNewBadge: boolean
      shortDescription?: string | null
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      unlimitedAccess: boolean
      lessonNum?: number | null
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        programImage?: string | null
        notLaunched?: boolean | null
        name: string
        enrolled: boolean
        slug: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
      cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
      parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
    }>
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      programImage?: string | null
      notLaunched?: boolean | null
      name: string
      enrolled: boolean
      slug: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
  }>
}

export type ProgramDetailsQueryVariables = Exact<{
  cmsProgramId?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}>

export type ProgramDetailsQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    name: string
    id: string
    numBookmarks?: number | null
    progressPercent: number
    currentModule?: { __typename: 'CmsModule'; id: string } | null
    cmsModules: Array<{
      __typename: 'CmsModule'
      id: string
      anyCountsTowardsCompletion?: boolean | null
      estimatedTime: number
      published?: boolean | null
      publishAt?: string | null
      name: string
      numBookmarks?: number | null
      events: Array<{
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        timezone?: string | null
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendingStatus?: string | null
        attendeesCount?: number | null
        lastViewedAt?: string | null
        isPublic?: boolean | null
        summaryWistiaCodeOptional?: string | null
        attendees: {
          __typename: 'EventAttendeeConnection'
          pageInfo: {
            __typename: 'PageInfo'
            hasNextPage: boolean
            endCursor?: string | null
          }
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        speakers?: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }> | null
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      }>
      project?: {
        __typename: 'CmsSection'
        id: string
        cmsProgramId: string
        cmsModuleId: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        showNewBadge: boolean
        estimatedTime: number
        heroImageUrl?: string | null
        href: string
        lastViewedAt?: string | null
        name: string
        numBookmarks?: number | null
        numPhases?: number | null
        shortDescription?: string | null
        slug: string
        unlimitedAccess: boolean
        accessLevel?: string | null
        projectIcon?: string | null
        previewable: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          numBookmarks?: number | null
          progressPercent: number
          programImage?: string | null
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      } | null
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            avatarUrl: string
            fullName?: string | null
          }
        }
      }>
      cmsSections: Array<{
        __typename: 'CmsSection'
        id: string
        slug: string
        conceptIcon?: string | null
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        cmsModuleId: string
        cmsProgramId: string
        estimatedTime: number
        href: string
        heroImageUrl?: string | null
        name: string
        numBookmarks?: number | null
        numPhases?: number | null
        showNewBadge: boolean
        shortDescription?: string | null
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        unlimitedAccess: boolean
        lessonNum?: number | null
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        children: Array<{
          __typename: 'CmsSection'
          id: string
          slug: string
          conceptIcon?: string | null
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          cmsModuleId: string
          cmsProgramId: string
          estimatedTime: number
          href: string
          heroImageUrl?: string | null
          name: string
          numBookmarks?: number | null
          numPhases?: number | null
          showNewBadge: boolean
          shortDescription?: string | null
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          unlimitedAccess: boolean
          lessonNum?: number | null
          accessLevel?: string | null
          previewable: boolean
          lastViewedAt?: string | null
          contentSubtype?: string | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            programImage?: string | null
            notLaunched?: boolean | null
            name: string
            enrolled: boolean
            slug: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
          cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
          parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
        }>
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          programImage?: string | null
          notLaunched?: boolean | null
          name: string
          enrolled: boolean
          slug: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
      }>
    }>
  } | null
}

export type ProgramSummaryQueryVariables = Exact<{
  cmsProgramId?: InputMaybe<Scalars['ID']>
  slug?: InputMaybe<Scalars['String']>
}>

export type ProgramSummaryQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    code?: string | null
    name: string
    byTheEnd?: string | null
    iconImageUrl?: string | null
    deprecatedAt?: string | null
    lessonCount: number
    estimatedTime: number
    shortDescription: string
    progressPercent: number
    longDescription: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      title?: string | null
      bio?: string | null
      thumbnailUrl: string
      previousCompanies?: string | null
    }>
    programNotification?: {
      __typename: 'CmsProgramNotification'
      imageUrl: string
      message: string
      notificationName: string
      title: string
    } | null
    cclCourse?: {
      __typename: 'CclCourse'
      onDemand?: boolean | null
      id: string
      staticId: string
      slug: string
      legacyCourseId?: string | null
      title: string
    } | null
  } | null
}

export type ProgramSummaryPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  code?: string | null
  name: string
  byTheEnd?: string | null
  iconImageUrl?: string | null
  deprecatedAt?: string | null
  lessonCount: number
  estimatedTime: number
  shortDescription: string
  progressPercent: number
  longDescription: string
  collaborators: Array<{
    __typename: 'Host'
    id: string
    name?: string | null
    title?: string | null
    bio?: string | null
    thumbnailUrl: string
    previousCompanies?: string | null
  }>
  programNotification?: {
    __typename: 'CmsProgramNotification'
    imageUrl: string
    message: string
    notificationName: string
    title: string
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    onDemand?: boolean | null
    id: string
    staticId: string
    slug: string
    legacyCourseId?: string | null
    title: string
  } | null
}

export type ProgramPageCclCoursePartsFragment = {
  __typename: 'CclCourse'
  onDemand?: boolean | null
  id: string
  staticId: string
  slug: string
  legacyCourseId?: string | null
  title: string
}

export type ScheduledWeekPartsFragment = {
  __typename: 'ScheduledWeek'
  id: string
  title?: string | null
  core?: boolean | null
  startsAt?: string | null
  endsAt?: string | null
  orderedCmsModules: Array<{ __typename: 'CmsModule'; id: string }>
}

export type ProgramCollaboratorsFragment = {
  __typename: 'Host'
  id: string
  name?: string | null
  bio?: string | null
  thumbnailUrl: string
  companyName?: string | null
  role?: string | null
}

export type UpcomingProgramFragment = {
  __typename: 'CmsProgram'
  id: string
  name: string
}

export type TentativeUpcomingCohortFragment = {
  __typename: 'TentativeUpcomingCohort'
  name?: string | null
  startsAt?: string | null
  endsAt?: string | null
  marketingSiteUrl?: string | null
}

export type CmsProgramPreviewFragment = {
  __typename: 'CmsProgram'
  id: string
  slug: string
  name: string
  synopsis?: string | null
  lessonCount: number
  whoItsFor?: string | null
  iconImageUrl?: string | null
  outcomes?: string | null
  previewableLessonCount: number
  tentativeUpcomingCohorts?: Array<{
    __typename: 'TentativeUpcomingCohort'
    name?: string | null
    startsAt?: string | null
    endsAt?: string | null
    marketingSiteUrl?: string | null
  }> | null
  testimonials: Array<{
    __typename: 'Testimonial'
    id: string
    name?: string | null
    role?: string | null
    copy?: string | null
    thumbnailUrl?: string | null
  }>
  cohort?: {
    __typename: 'Cohort'
    id: string
    marketingSiteUrl?: string | null
    scheduledWeeks: Array<{
      __typename: 'ScheduledWeek'
      id: string
      title?: string | null
      core?: boolean | null
      startsAt?: string | null
      endsAt?: string | null
      orderedCmsModules: Array<{ __typename: 'CmsModule'; id: string }>
    }>
    season: {
      __typename: 'Season'
      id: string
      nextSeasonName: string
      startsAt?: string | null
      endsAt?: string | null
    }
  } | null
  collaborators: Array<{
    __typename: 'Host'
    id: string
    name?: string | null
    bio?: string | null
    thumbnailUrl: string
    companyName?: string | null
    role?: string | null
  }>
}

export type CmsProgramPreviewQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type CmsProgramPreviewQuery = {
  __typename: 'Query'
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    slug: string
    name: string
    synopsis?: string | null
    lessonCount: number
    whoItsFor?: string | null
    iconImageUrl?: string | null
    outcomes?: string | null
    previewableLessonCount: number
    tentativeUpcomingCohorts?: Array<{
      __typename: 'TentativeUpcomingCohort'
      name?: string | null
      startsAt?: string | null
      endsAt?: string | null
      marketingSiteUrl?: string | null
    }> | null
    testimonials: Array<{
      __typename: 'Testimonial'
      id: string
      name?: string | null
      role?: string | null
      copy?: string | null
      thumbnailUrl?: string | null
    }>
    cohort?: {
      __typename: 'Cohort'
      id: string
      marketingSiteUrl?: string | null
      scheduledWeeks: Array<{
        __typename: 'ScheduledWeek'
        id: string
        title?: string | null
        core?: boolean | null
        startsAt?: string | null
        endsAt?: string | null
        orderedCmsModules: Array<{ __typename: 'CmsModule'; id: string }>
      }>
      season: {
        __typename: 'Season'
        id: string
        nextSeasonName: string
        startsAt?: string | null
        endsAt?: string | null
      }
    } | null
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      bio?: string | null
      thumbnailUrl: string
      companyName?: string | null
      role?: string | null
    }>
  } | null
}

export type ProgramIndexQueryVariables = Exact<{ [key: string]: never }>

export type ProgramIndexQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    is: { __typename: 'UserIs'; enrolledInActiveCourse: boolean }
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        courseSession: {
          __typename: 'CourseSession'
          id: string
          courseSlug: string
          courseTitle: string
          overallDuration?: string | null
          startsAt: string
          endsAt: string
          isPast: boolean
        }
      }> | null
    }
  } | null
  cmsPrograms: Array<{
    __typename: 'CmsProgram'
    id: string
    topic?: string | null
    progressPercent: number
    recommendationRanking?: number | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    notLaunched?: boolean | null
    enrolled: boolean
    lessonCount: number
    slug: string
    name: string
    canEnroll: boolean
    canEnrollForCurrentSeason: boolean
    hasPreviewableContent: boolean
    iconImageUrl?: string | null
    synopsis?: string | null
    releaseSeasonDate?: string | null
    releaseStartDate?: string | null
    releaseEnrollmentDate?: string | null
    launchAt?: string | null
    deprecatedAt?: string | null
    numBookmarks?: number | null
    code?: string | null
  }>
  upcomingSeason?: {
    __typename: 'Season'
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    hasStarted: boolean
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    prettyNameSeasonFirst: string
    startsAt?: string | null
    year: string
    showBlogPostAnnouncementAt?: string | null
    blogPostAnnouncementUrl?: string | null
  } | null
  enrollmentSeason?: {
    __typename: 'Season'
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    hasStarted: boolean
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    prettyNameSeasonFirst: string
    startsAt?: string | null
    year: string
    showBlogPostAnnouncementAt?: string | null
    blogPostAnnouncementUrl?: string | null
  } | null
  lastActiveSeason?: {
    __typename: 'Season'
    activeAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    enrollmentsOpenAt?: string | null
    hasStarted: boolean
    id: string
    name: string
    nextSeasonName: string
    openForEnrollment: boolean
    prettyNameSeasonFirst: string
    startsAt?: string | null
    year: string
    showBlogPostAnnouncementAt?: string | null
    blogPostAnnouncementUrl?: string | null
  } | null
  currentLivePrograms: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      isOngoing: boolean
      eventCount: number
      weekCount: number
      startsAt?: string | null
      endsAt?: string | null
      postCohort: boolean
      coreWeeksHaveEnded: boolean
      postCoreWeeksEnrollmentIsOpen: boolean
      postKickoff: boolean
      slug: string
      thisWeek?: {
        __typename: 'ScheduledWeek'
        id: string
        nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
      } | null
      season: {
        __typename: 'Season'
        hasStarted: boolean
        id: string
        prettyNameSeasonFirst: string
        showCohortViewerAt?: string | null
      }
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        lessonCount: number
        numBookmarks?: number | null
        progressPercent: number
        code?: string | null
        iconImageUrl?: string | null
      }
    }
  }>
  pastLivePrograms: Array<{
    __typename: 'UserCohort'
    id: string
    cohort: {
      __typename: 'Cohort'
      id: string
      isOngoing: boolean
      eventCount: number
      weekCount: number
      startsAt?: string | null
      endsAt?: string | null
      postCohort: boolean
      coreWeeksHaveEnded: boolean
      postCoreWeeksEnrollmentIsOpen: boolean
      postKickoff: boolean
      slug: string
      thisWeek?: {
        __typename: 'ScheduledWeek'
        id: string
        nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
      } | null
      season: {
        __typename: 'Season'
        hasStarted: boolean
        id: string
        prettyNameSeasonFirst: string
        showCohortViewerAt?: string | null
      }
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        name: string
        lessonCount: number
        numBookmarks?: number | null
        progressPercent: number
        code?: string | null
        iconImageUrl?: string | null
      }
    }
  }>
}

export type ProgramIndexUserCourseSessionFieldsFragment = {
  __typename: 'CourseSession'
  id: string
  courseSlug: string
  courseTitle: string
  overallDuration?: string | null
  startsAt: string
  endsAt: string
  isPast: boolean
}

export type UserCohortPartFragment = {
  __typename: 'UserCohort'
  id: string
  cohort: {
    __typename: 'Cohort'
    id: string
    isOngoing: boolean
    eventCount: number
    weekCount: number
    startsAt?: string | null
    endsAt?: string | null
    postCohort: boolean
    coreWeeksHaveEnded: boolean
    postCoreWeeksEnrollmentIsOpen: boolean
    postKickoff: boolean
    slug: string
    thisWeek?: {
      __typename: 'ScheduledWeek'
      id: string
      nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
    } | null
    season: {
      __typename: 'Season'
      hasStarted: boolean
      id: string
      prettyNameSeasonFirst: string
      showCohortViewerAt?: string | null
    }
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      name: string
      lessonCount: number
      numBookmarks?: number | null
      progressPercent: number
      code?: string | null
      iconImageUrl?: string | null
    }
  }
}

export type CohortPartFragment = {
  __typename: 'Cohort'
  id: string
  isOngoing: boolean
  eventCount: number
  weekCount: number
  startsAt?: string | null
  endsAt?: string | null
  postCohort: boolean
  coreWeeksHaveEnded: boolean
  postCoreWeeksEnrollmentIsOpen: boolean
  postKickoff: boolean
  slug: string
  thisWeek?: {
    __typename: 'ScheduledWeek'
    id: string
    nextModule?: { __typename: 'CmsModule'; id: string; href: string } | null
  } | null
  season: {
    __typename: 'Season'
    hasStarted: boolean
    id: string
    prettyNameSeasonFirst: string
    showCohortViewerAt?: string | null
  }
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    name: string
    lessonCount: number
    numBookmarks?: number | null
    progressPercent: number
    code?: string | null
    iconImageUrl?: string | null
  }
}

export type UserProgramTeammatesQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type UserProgramTeammatesQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    programTeammates: Array<{
      __typename: 'User'
      id: string
      avatarUrl: string
      hasBadge: boolean
      kind?: string | null
    }>
  } | null
}

export type ProgramTeammatesPartsFragment = {
  __typename: 'User'
  id: string
  avatarUrl: string
  hasBadge: boolean
  kind?: string | null
}

export type ProjectDeliverableQueryVariables = Exact<{
  deliverableId: Scalars['ID']
}>

export type ProjectDeliverableQuery = {
  __typename: 'Query'
  projectDeliverable?: {
    __typename: 'ProjectDeliverable'
    id: string
    url?: string | null
    name?: string | null
    title?: string | null
    templateUrl?: string | null
  } | null
}

export type ProjectCurrentUserPartsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  shouldSeeTrialMessaging: boolean
  can: {
    __typename: 'UserCan'
    viewProjectsAndConcepts?: boolean | null
    viewPurchaseFlow?: boolean | null
  }
  is: { __typename: 'UserIs'; premember: boolean; member: boolean }
}

export type ProjectCardProgramPartsFragment = {
  __typename: 'CmsProgram'
  id: string
  notLaunched?: boolean | null
  enrolled: boolean
  numBookmarks?: number | null
  progressPercent: number
  programImage?: string | null
  slug: string
  name: string
  collaborators: Array<{
    __typename: 'Host'
    id: string
    name?: string | null
    thumbnailUrl: string
  }>
}

export type ProjectCardSectionPartsFragment = {
  __typename: 'CmsSection'
  id: string
  cmsProgramId: string
  cmsModuleId: string
  contentType?: CmsSectionContentType | null
  contentTypeIcon?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  showNewBadge: boolean
  estimatedTime: number
  heroImageUrl?: string | null
  href: string
  lastViewedAt?: string | null
  name: string
  numBookmarks?: number | null
  numPhases?: number | null
  shortDescription?: string | null
  slug: string
  unlimitedAccess: boolean
  accessLevel?: string | null
  projectIcon?: string | null
  previewable: boolean
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    notLaunched?: boolean | null
    enrolled: boolean
    numBookmarks?: number | null
    progressPercent: number
    programImage?: string | null
    slug: string
    name: string
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
    }>
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  topic?: { __typename: 'PostTopic'; id: string; title: string } | null
}

export type ProjectCardContentPartsFragment = {
  __typename: 'Content'
  accessLevel?: string | null
  contentBookmarkId?: string | null
  contentIsInMySavedItems?: boolean | null
  showNewBadge?: boolean | null
  cmsSection: {
    __typename: 'CmsSection'
    id: string
    cmsProgramId: string
    cmsModuleId: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    showNewBadge: boolean
    estimatedTime: number
    heroImageUrl?: string | null
    href: string
    lastViewedAt?: string | null
    name: string
    numBookmarks?: number | null
    numPhases?: number | null
    shortDescription?: string | null
    slug: string
    unlimitedAccess: boolean
    accessLevel?: string | null
    projectIcon?: string | null
    previewable: boolean
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      numBookmarks?: number | null
      progressPercent: number
      programImage?: string | null
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
  }
}

export type ProjectsFeedQueryVariables = Exact<{
  filters?: InputMaybe<ContentFilters>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type ProjectsFeedQuery = {
  __typename: 'Query'
  projects?: {
    __typename: 'ContentFeed'
    cmsModuleId?: string | null
    topics?: Array<string> | null
    count: number
    content: Array<{
      __typename: 'Content'
      accessLevel?: string | null
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      showNewBadge?: boolean | null
      cmsSection: {
        __typename: 'CmsSection'
        id: string
        cmsProgramId: string
        cmsModuleId: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        showNewBadge: boolean
        estimatedTime: number
        heroImageUrl?: string | null
        href: string
        lastViewedAt?: string | null
        name: string
        numBookmarks?: number | null
        numPhases?: number | null
        shortDescription?: string | null
        slug: string
        unlimitedAccess: boolean
        accessLevel?: string | null
        projectIcon?: string | null
        previewable: boolean
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          numBookmarks?: number | null
          progressPercent: number
          programImage?: string | null
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      }
    }>
    userProgram?: {
      __typename: 'UserProgram'
      id: string
      progress?: ProgramProgressScalar | null
      enrolled?: boolean | null
    } | null
  } | null
}

export type GetApplicableProgramsQueryVariables = Exact<{ [key: string]: never }>

export type GetApplicableProgramsQuery = {
  __typename: 'Query'
  applicablePrograms?: Array<{
    __typename: 'ApplicablePrograms'
    name: string
    slug: string
  }> | null
}

export type SearchCompanyQueryVariables = Exact<{
  search: Scalars['String']
  kind: Scalars['String']
}>

export type SearchCompanyQuery = {
  __typename: 'Query'
  searchCompany?: Array<string> | null
}

export type SearchCompanyWithClearbitQueryVariables = Exact<{
  search: Scalars['String']
}>

export type SearchCompanyWithClearbitQuery = {
  __typename: 'Query'
  searchCompanyWithClearbit?: Array<{
    __typename: 'ClearbitCompany'
    name: string
    domain: string
    logo: string
  }> | null
}

export type GetCountryCodeQueryVariables = Exact<{ [key: string]: never }>

export type GetCountryCodeQuery = { __typename: 'Query'; countryCode: string }

export type CreateProgramApplicationMutationVariables = Exact<{
  input: CreateProgramApplicationInput
}>

export type CreateProgramApplicationMutation = {
  __typename: 'Mutation'
  createProgramApplication?: {
    __typename: 'CreateProgramApplicationPayload'
    id?: number | null
    token?: string | null
    errors?: Array<string> | null
  } | null
}

export type SnapshotDataFragment = {
  __typename: 'SnapshotLandingPage'
  cmsProgramId: string
  contentLink: string
  firstName: string
  imageUrl?: string | null
  programDescription: string
  programName: string
  seasonName: string
  showcaseContentId: string
  slug: string
}

export type CompletionSnapshotDataFragment = {
  __typename: 'CompletionSnapshot'
  avatarUrl: string
  cohortId: string
  hashtag: string
  imagePath?: string | null
  isReleased: boolean
  programName: string
  seasonName: string
  snapshotShareUrl: string
  startsAt: string
  token?: string | null
  userCohortId: string
}

export type SnapshotLandingPageQueryVariables = Exact<{
  snapshotType: Scalars['String']
  token: Scalars['String']
}>

export type SnapshotLandingPageQuery = {
  __typename: 'Query'
  snapshotLandingPage: {
    __typename: 'SnapshotLandingPage'
    cmsProgramId: string
    contentLink: string
    firstName: string
    imageUrl?: string | null
    programDescription: string
    programName: string
    seasonName: string
    showcaseContentId: string
    slug: string
  }
}

export type CompletionSnapshotQueryVariables = Exact<{ [key: string]: never }>

export type CompletionSnapshotQuery = {
  __typename: 'Query'
  completionSnapshot?: {
    __typename: 'CompletionSnapshot'
    avatarUrl: string
    cohortId: string
    hashtag: string
    imagePath?: string | null
    isReleased: boolean
    programName: string
    seasonName: string
    snapshotShareUrl: string
    startsAt: string
    token?: string | null
    userCohortId: string
  } | null
}

export type SpeakerFieldsFragment = {
  __typename: 'UserProfile'
  avatarUrl: string
  companyName?: string | null
  fullName?: string | null
  hasBadge?: boolean | null
  linkedin?: string | null
  location?: string | null
  profileUrl?: string | null
  role?: string | null
  twitter?: string | null
  kind?: string | null
}

export type SubscriptionTeamPreviewQueryVariables = Exact<{
  token: Scalars['String']
  loginParams?: InputMaybe<LoginParamsInput>
}>

export type SubscriptionTeamPreviewQuery = {
  __typename: 'Query'
  subscriptionTeamPreview?: {
    __typename: 'TeamPreview'
    name?: string | null
    totalMembers: number
    members: Array<{
      __typename: 'TeamMemberPreview'
      id: string
      profile: { __typename: 'TeamMemberProfilePreview'; avatarUrl: string }
    }>
    ssoAuthorization?: {
      __typename: 'SsoAuthorization'
      isSsoActive: boolean
      authorizationUrl?: string | null
    } | null
  } | null
}

export type TestimonialsQueryVariables = Exact<{ [key: string]: never }>

export type TestimonialsQuery = {
  __typename: 'Query'
  testimonials: Array<{
    __typename: 'Testimonial'
    id: string
    userId: string
    avatarUrl: string
    name?: string | null
    role?: string | null
    copy?: string | null
    joined: string
    approvalStatus: string
    active: boolean
  }>
}

export type UserPatronageQueryVariables = Exact<{
  externalId: Scalars['String']
}>

export type UserPatronageQuery = {
  __typename: 'Query'
  userPatronage: {
    __typename: 'UserPatronage'
    userExists: boolean
    userFullName?: string | null
    userHasActiveSubscription: boolean
    stripePaymentLink?: string | null
    userIsEligibleForPatronage?: boolean | null
  }
}

export type RecommendedCoursesQueryVariables = Exact<{ [key: string]: never }>

export type RecommendedCoursesQuery = {
  __typename: 'Query'
  recommendedCourses: Array<{
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  }>
}

export type ItemFragment = {
  __typename: 'RecommendedItem'
  authors: Array<any>
  id: string
  cclSyncMapId: string
  commentCount?: number | null
  companyLogoAltText?: string | null
  companyLogoUrl?: string | null
  companyName?: string | null
  description?: string | null
  draftTemplateLabel?: string | null
  draftTemplateName?: string | null
  estimatedTimeInMinutes?: number | null
  isFree?: boolean | null
  kind: string
  localId: string
  previewable?: boolean | null
  publishDate?: string | null
  sanityId: string
  slug: string
  stepsCount?: number | null
  thumbnailUrl?: string | null
  title: string
  viewerCount?: number | null
  creators: Array<any>
}

export type RecommendedCollectionFragment = {
  __typename: 'Collection'
  id: string
  title: string
  contentTypes: Array<ReforgeContent>
  items: Array<{
    __typename: 'RecommendedItem'
    authors: Array<any>
    id: string
    cclSyncMapId: string
    commentCount?: number | null
    companyLogoAltText?: string | null
    companyLogoUrl?: string | null
    companyName?: string | null
    description?: string | null
    draftTemplateLabel?: string | null
    draftTemplateName?: string | null
    estimatedTimeInMinutes?: number | null
    isFree?: boolean | null
    kind: string
    localId: string
    previewable?: boolean | null
    publishDate?: string | null
    sanityId: string
    slug: string
    stepsCount?: number | null
    thumbnailUrl?: string | null
    title: string
    viewerCount?: number | null
    creators: Array<any>
  }>
}

export type RecommendedCollectionsQueryVariables = Exact<{ [key: string]: never }>

export type RecommendedCollectionsQuery = {
  __typename: 'Query'
  recommendedCollections: Array<{
    __typename: 'Collection'
    id: string
    title: string
    contentTypes: Array<ReforgeContent>
    items: Array<{
      __typename: 'RecommendedItem'
      authors: Array<any>
      id: string
      cclSyncMapId: string
      commentCount?: number | null
      companyLogoAltText?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      description?: string | null
      draftTemplateLabel?: string | null
      draftTemplateName?: string | null
      estimatedTimeInMinutes?: number | null
      isFree?: boolean | null
      kind: string
      localId: string
      previewable?: boolean | null
      publishDate?: string | null
      sanityId: string
      slug: string
      stepsCount?: number | null
      thumbnailUrl?: string | null
      title: string
      viewerCount?: number | null
      creators: Array<any>
    }>
  }>
}

export type RecommendedCollectionQueryVariables = Exact<{
  swimlaneId: Scalars['ID']
}>

export type RecommendedCollectionQuery = {
  __typename: 'Query'
  recommendedCollection: {
    __typename: 'Collection'
    id: string
    title: string
    contentTypes: Array<ReforgeContent>
    items: Array<{
      __typename: 'RecommendedItem'
      authors: Array<any>
      id: string
      cclSyncMapId: string
      commentCount?: number | null
      companyLogoAltText?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      description?: string | null
      draftTemplateLabel?: string | null
      draftTemplateName?: string | null
      estimatedTimeInMinutes?: number | null
      isFree?: boolean | null
      kind: string
      localId: string
      previewable?: boolean | null
      publishDate?: string | null
      sanityId: string
      slug: string
      stepsCount?: number | null
      thumbnailUrl?: string | null
      title: string
      viewerCount?: number | null
      creators: Array<any>
    }>
  }
}

export type RecommendedContentQueryVariables = Exact<{
  cursor?: InputMaybe<RecommendedContentFeedCursorInput>
  limit: Scalars['Int']
}>

export type RecommendedContentQuery = {
  __typename: 'Query'
  recommendedContent: {
    __typename: 'RecommendedContent'
    results: Array<{
      __typename: 'RecommendedContentResults'
      authors: Array<any>
      cclSyncMapId: string
      commentCount?: number | null
      companyLogoAltText?: string | null
      companyLogoUrl?: string | null
      companyName?: string | null
      description?: string | null
      estimatedTimeInMinutes?: number | null
      isFree?: boolean | null
      kind: string
      localId: string
      publishDate?: string | null
      sanityId: string
      slug: string
      stepsCount?: number | null
      thumbnailUrl?: string | null
      title: string
      viewerCount?: number | null
    }>
    cursor?: {
      __typename: 'RecommendedContentCursor'
      firstPublishedAt?: string | null
      cclSyncMapId?: string | null
    } | null
  }
}

export type ReforgeUsagesQueryVariables = Exact<{ [key: string]: never }>

export type ReforgeUsagesQuery = {
  __typename: 'Query'
  reforgeUsages: Array<{
    __typename: 'ReforgeUsage'
    id: string
    onboardingCopy: string
    onboardingSubcopy: string
    checklistCopy: string
  }>
}

export type ReinstateSeatMutationVariables = Exact<{
  input: ReinstateSeatInput
}>

export type ReinstateSeatMutation = {
  __typename: 'Mutation'
  reinstateSeat?: {
    __typename: 'ReinstateSeatPayload'
    success?: boolean | null
    errors?: Array<string> | null
    subscription?: {
      __typename: 'Subscription'
      id: string
      seats: Array<{
        __typename: 'Seat'
        id: string
        scheduledForDeletionDate?: string | null
      }>
    } | null
  } | null
}

export type RemoveSeatMutationVariables = Exact<{
  input: RemoveSeatInput
}>

export type RemoveSeatMutation = {
  __typename: 'Mutation'
  removeSeat?: {
    __typename: 'RemoveSeatPayload'
    success?: boolean | null
    errors?: Array<string> | null
    subscription?: {
      __typename: 'Subscription'
      id: string
      seats: Array<{
        __typename: 'Seat'
        id: string
        scheduledForDeletionDate?: string | null
      }>
    } | null
  } | null
}

export type RemoveSubscriptionMemberMutationVariables = Exact<{
  input: RemoveSubscriptionMemberInput
}>

export type RemoveSubscriptionMemberMutation = {
  __typename: 'Mutation'
  removeSubscriptionMember?: {
    __typename: 'RemoveSubscriptionMemberPayload'
    success?: boolean | null
    errors?: Array<string> | null
    subscription?: {
      __typename: 'Subscription'
      id: string
      seats: Array<{ __typename: 'Seat'; id: string }>
    } | null
  } | null
}

export type SanityUnitQueryVariables = Exact<{
  slug: Scalars['String']
  courseId?: InputMaybe<Scalars['String']>
  courseSessionId?: InputMaybe<Scalars['String']>
  contentMode?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
}>

export type SanityUnitQuery = {
  __typename: 'Query'
  sanityUnit?: {
    __typename: 'Unit'
    id: string
    title?: string | null
    slug?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    previewable?: boolean | null
    subtopicIds?: Array<string> | null
    publishDate?: string | null
    showContentGate?: boolean | null
    narrationFileUrl?: string | null
    shortDescription?: string | null
    overview?: any | null
    recap?: any | null
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    guide?: { __typename: 'CclGuide'; id: string; staticId: string } | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    artifacts?: Array<{
      __typename: 'Artifact'
      id: string
      slug?: string | null
      thumbnailUrl?: string | null
      thumbnailPhoto?: any | null
      viewCount?: number | null
      commentCount?: number | null
      title?: string | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
          slug?: string | null
          logo?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    }> | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      isCollectiveMember?: boolean | null
      shortBio?: string | null
      slug?: string | null
      position?: string | null
      pastCompanies?: Array<string> | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
        slug?: string | null
        logo?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
    contentBlocks?: Array<{
      __typename: 'ContentBlock'
      id: string
      header?: string | null
      slug: string
      order?: number | null
      description?: any | null
      contentBlockCollapsible?: Array<{
        __typename: 'ContentBlockCollapsible'
        id: string
        type?: string | null
        description?: any | null
        label?: string | null
      }> | null
    }> | null
  } | null
}

export type SavedArtifactsAndGuidesForUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type SavedArtifactsAndGuidesForUserQuery = {
  __typename: 'Query'
  savedArtifactsAndGuidesForUser?: Array<{
    __typename: 'Bookmark'
    id: string
    sanityId?: string | null
    numPeopleWhoBookmarked?: number | null
    eventId?: string | null
    lessonPosition?: number | null
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    anchor?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    updated?: string | null
    created?: string | null
    title?: string | null
    externalUrl?: string | null
    metadata?: any | null
    startOffset?: number | null
    endOffset?: number | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      name: string
      position?: number | null
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      name: string
      position?: number | null
      progressPercent?: number | null
      childrenCount: number
      completedChildrenCount?: number | null
      path?: string | null
    } | null
    content?: {
      __typename: 'CmsSection'
      id: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      numPhases?: number | null
      projectIcon?: string | null
      shortDescription?: string | null
      numBookmarks?: number | null
      showNewBadge: boolean
      slug: string
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      lessonNum?: number | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        slug?: string | null
        name: string
      } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
      progressPercent: number
      iconImageUrl?: string | null
      notLaunched?: boolean | null
      deprecatedAt?: string | null
      description?: string | null
      synopsis?: string | null
      lessonCount: number
      purchased: boolean
      upcoming: boolean
      enrolled: boolean
      canEnroll: boolean
      showNewBadge: boolean
      topic?: string | null
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
        companyName?: string | null
        description?: string | null
        title?: string | null
        avatarUrl: string
      }>
    } | null
    event?: {
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      willBeRecorded: boolean
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    } | null
    artifact?: {
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      companyLogoUrl?: string | null
      companyName?: string | null
      thumbnailUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarUrl?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
        } | null
      }> | null
    } | null
    course?: {
      __typename: 'MarketingCourse'
      id: string
      slug?: string | null
      cmsProgramSlug?: string | null
      startDate?: string | null
      subtitle?: string | null
      title?: string | null
      legacyProgramId?: string | null
      bookmarkId?: string | null
      courseType?: string | null
      course?: {
        __typename: 'SanityCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        bookmarkId?: string | null
        isAvailableOnDemand?: boolean | null
        creators?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    } | null
    guide?: {
      __typename: 'Unit'
      id: string
      title?: string | null
      whatYouWillDo?: any | null
      estimatedTimeInMinutes?: number | null
      stepsCount?: number | null
      slug?: string | null
      contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        slug?: string | null
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  }> | null
}

export type SavedArtifactsForUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type SavedArtifactsForUserQuery = {
  __typename: 'Query'
  savedArtifactsForUser?: Array<{
    __typename: 'Bookmark'
    id: string
    sanityId?: string | null
    numPeopleWhoBookmarked?: number | null
    eventId?: string | null
    lessonPosition?: number | null
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    anchor?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    updated?: string | null
    created?: string | null
    title?: string | null
    externalUrl?: string | null
    metadata?: any | null
    startOffset?: number | null
    endOffset?: number | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      name: string
      position?: number | null
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      name: string
      position?: number | null
      progressPercent?: number | null
      childrenCount: number
      completedChildrenCount?: number | null
      path?: string | null
    } | null
    content?: {
      __typename: 'CmsSection'
      id: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      numPhases?: number | null
      projectIcon?: string | null
      shortDescription?: string | null
      numBookmarks?: number | null
      showNewBadge: boolean
      slug: string
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      lessonNum?: number | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        slug?: string | null
        name: string
      } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
      progressPercent: number
      iconImageUrl?: string | null
      notLaunched?: boolean | null
      deprecatedAt?: string | null
      description?: string | null
      synopsis?: string | null
      lessonCount: number
      purchased: boolean
      upcoming: boolean
      enrolled: boolean
      canEnroll: boolean
      showNewBadge: boolean
      topic?: string | null
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
        companyName?: string | null
        description?: string | null
        title?: string | null
        avatarUrl: string
      }>
    } | null
    event?: {
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      willBeRecorded: boolean
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    } | null
    artifact?: {
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      companyLogoUrl?: string | null
      companyName?: string | null
      thumbnailUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarUrl?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
        } | null
      }> | null
    } | null
    course?: {
      __typename: 'MarketingCourse'
      id: string
      slug?: string | null
      cmsProgramSlug?: string | null
      startDate?: string | null
      subtitle?: string | null
      title?: string | null
      legacyProgramId?: string | null
      bookmarkId?: string | null
      courseType?: string | null
      course?: {
        __typename: 'SanityCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        bookmarkId?: string | null
        isAvailableOnDemand?: boolean | null
        creators?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    } | null
    guide?: {
      __typename: 'Unit'
      id: string
      title?: string | null
      whatYouWillDo?: any | null
      estimatedTimeInMinutes?: number | null
      stepsCount?: number | null
      slug?: string | null
      contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        slug?: string | null
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  }> | null
}

export type SearchDocumentPostFragment = {
  __typename: 'Post'
  id: string
  category?: string | null
  responseCount: number
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      hasBadge?: boolean | null
      kind?: string | null
    }
  }
}

export type SearchDocumentReplyFragment = {
  __typename: 'Reply'
  id: string
  post?: {
    __typename: 'Post'
    id: string
    category?: string | null
    responseCount: number
  } | null
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      hasBadge?: boolean | null
      kind?: string | null
    }
  }
}

export type SearchDocumentCommentFragment = {
  __typename: 'Comment'
  id: string
  post?: {
    __typename: 'Post'
    id: string
    category?: string | null
    responseCount: number
  } | null
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null
    profile: {
      __typename: 'UserProfile'
      avatarUrl: string
      hasBadge?: boolean | null
      kind?: string | null
    }
  }
}

export type SearchDocumentCmsSectionFragment = {
  __typename: 'CmsSection'
  id: string
  name: string
  path?: string | null
  heroImageUrl?: string | null
  cmsProgram: {
    __typename: 'CmsProgram'
    id: string
    slug: string
    topic?: string | null
    name: string
    heroImageUrl?: string | null
  }
  cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  parent?: {
    __typename: 'CmsSection'
    id: string
    cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
  } | null
}

export type SearchDocumentUserFragment = {
  __typename: 'User'
  id: string
  profile: {
    __typename: 'UserProfile'
    role?: string | null
    companyName?: string | null
    location?: string | null
    slug?: string | null
    hasBadge?: boolean | null
    kind?: string | null
    avatarUrl: string
  }
}

export type SearchDocumentResultFragment = {
  __typename: 'SearchDocument'
  id: string
  title?: string | null
  topic?: string | null
  pgSearchHighlight?: string | null
  updatedAt?: string | null
  searchable:
    | {
        __typename: 'CmsSection'
        id: string
        name: string
        path?: string | null
        heroImageUrl?: string | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          slug: string
          topic?: string | null
          name: string
          heroImageUrl?: string | null
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        parent?: {
          __typename: 'CmsSection'
          id: string
          cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        } | null
      }
    | {
        __typename: 'Comment'
        id: string
        post?: {
          __typename: 'Post'
          id: string
          category?: string | null
          responseCount: number
        } | null
        user: {
          __typename: 'User'
          id: string
          fullName?: string | null
          profile: {
            __typename: 'UserProfile'
            avatarUrl: string
            hasBadge?: boolean | null
            kind?: string | null
          }
        }
      }
    | {
        __typename: 'Post'
        id: string
        category?: string | null
        responseCount: number
        user: {
          __typename: 'User'
          id: string
          fullName?: string | null
          profile: {
            __typename: 'UserProfile'
            avatarUrl: string
            hasBadge?: boolean | null
            kind?: string | null
          }
        }
      }
    | {
        __typename: 'Reply'
        id: string
        post?: {
          __typename: 'Post'
          id: string
          category?: string | null
          responseCount: number
        } | null
        user: {
          __typename: 'User'
          id: string
          fullName?: string | null
          profile: {
            __typename: 'UserProfile'
            avatarUrl: string
            hasBadge?: boolean | null
            kind?: string | null
          }
        }
      }
    | {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          role?: string | null
          companyName?: string | null
          location?: string | null
          slug?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          avatarUrl: string
        }
      }
}

export type SearchDocumentsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  program?: InputMaybe<Scalars['String']>
  topic?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
}>

export type SearchDocumentsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    launchedEvergreenPrograms: Array<{
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
    }>
  } | null
  searchDocuments?: {
    __typename: 'SearchDocumentList'
    totalCount: number
    results: Array<{
      __typename: 'SearchDocument'
      id: string
      title?: string | null
      topic?: string | null
      pgSearchHighlight?: string | null
      updatedAt?: string | null
      searchable:
        | {
            __typename: 'CmsSection'
            id: string
            name: string
            path?: string | null
            heroImageUrl?: string | null
            cmsProgram: {
              __typename: 'CmsProgram'
              id: string
              slug: string
              topic?: string | null
              name: string
              heroImageUrl?: string | null
            }
            cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            parent?: {
              __typename: 'CmsSection'
              id: string
              cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            } | null
          }
        | {
            __typename: 'Comment'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Post'
            id: string
            category?: string | null
            responseCount: number
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Reply'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              role?: string | null
              companyName?: string | null
              location?: string | null
              slug?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              avatarUrl: string
            }
          }
    }>
  } | null
}

export type SearchDocumentsTopResultsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>
}>

export type SearchDocumentsTopResultsQuery = {
  __typename: 'Query'
  searchDocumentsTopResults?: {
    __typename: 'SearchDocumentTopResult'
    programContent: {
      __typename: 'SearchDocumentTopResultCategory'
      totalCount: number
      results: Array<{
        __typename: 'SearchDocument'
        id: string
        title?: string | null
        topic?: string | null
        pgSearchHighlight?: string | null
        updatedAt?: string | null
        searchable:
          | {
              __typename: 'CmsSection'
              id: string
              name: string
              path?: string | null
              heroImageUrl?: string | null
              cmsProgram: {
                __typename: 'CmsProgram'
                id: string
                slug: string
                topic?: string | null
                name: string
                heroImageUrl?: string | null
              }
              cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
              parent?: {
                __typename: 'CmsSection'
                id: string
                cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
              } | null
            }
          | {
              __typename: 'Comment'
              id: string
              post?: {
                __typename: 'Post'
                id: string
                category?: string | null
                responseCount: number
              } | null
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'Reply'
              id: string
              post?: {
                __typename: 'Post'
                id: string
                category?: string | null
                responseCount: number
              } | null
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                role?: string | null
                companyName?: string | null
                location?: string | null
                slug?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                avatarUrl: string
              }
            }
      }>
    }
    members: {
      __typename: 'SearchDocumentTopResultCategory'
      totalCount: number
      results: Array<{
        __typename: 'SearchDocument'
        id: string
        title?: string | null
        topic?: string | null
        pgSearchHighlight?: string | null
        updatedAt?: string | null
        searchable:
          | {
              __typename: 'CmsSection'
              id: string
              name: string
              path?: string | null
              heroImageUrl?: string | null
              cmsProgram: {
                __typename: 'CmsProgram'
                id: string
                slug: string
                topic?: string | null
                name: string
                heroImageUrl?: string | null
              }
              cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
              parent?: {
                __typename: 'CmsSection'
                id: string
                cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
              } | null
            }
          | {
              __typename: 'Comment'
              id: string
              post?: {
                __typename: 'Post'
                id: string
                category?: string | null
                responseCount: number
              } | null
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'Reply'
              id: string
              post?: {
                __typename: 'Post'
                id: string
                category?: string | null
                responseCount: number
              } | null
              user: {
                __typename: 'User'
                id: string
                fullName?: string | null
                profile: {
                  __typename: 'UserProfile'
                  avatarUrl: string
                  hasBadge?: boolean | null
                  kind?: string | null
                }
              }
            }
          | {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                role?: string | null
                companyName?: string | null
                location?: string | null
                slug?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                avatarUrl: string
              }
            }
      }>
    }
  } | null
}

export type SearchDocumentTopResultFragment = {
  __typename: 'SearchDocumentTopResult'
  programContent: {
    __typename: 'SearchDocumentTopResultCategory'
    totalCount: number
    results: Array<{
      __typename: 'SearchDocument'
      id: string
      title?: string | null
      topic?: string | null
      pgSearchHighlight?: string | null
      updatedAt?: string | null
      searchable:
        | {
            __typename: 'CmsSection'
            id: string
            name: string
            path?: string | null
            heroImageUrl?: string | null
            cmsProgram: {
              __typename: 'CmsProgram'
              id: string
              slug: string
              topic?: string | null
              name: string
              heroImageUrl?: string | null
            }
            cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            parent?: {
              __typename: 'CmsSection'
              id: string
              cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            } | null
          }
        | {
            __typename: 'Comment'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Post'
            id: string
            category?: string | null
            responseCount: number
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Reply'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              role?: string | null
              companyName?: string | null
              location?: string | null
              slug?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              avatarUrl: string
            }
          }
    }>
  }
  members: {
    __typename: 'SearchDocumentTopResultCategory'
    totalCount: number
    results: Array<{
      __typename: 'SearchDocument'
      id: string
      title?: string | null
      topic?: string | null
      pgSearchHighlight?: string | null
      updatedAt?: string | null
      searchable:
        | {
            __typename: 'CmsSection'
            id: string
            name: string
            path?: string | null
            heroImageUrl?: string | null
            cmsProgram: {
              __typename: 'CmsProgram'
              id: string
              slug: string
              topic?: string | null
              name: string
              heroImageUrl?: string | null
            }
            cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            parent?: {
              __typename: 'CmsSection'
              id: string
              cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
            } | null
          }
        | {
            __typename: 'Comment'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Post'
            id: string
            category?: string | null
            responseCount: number
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'Reply'
            id: string
            post?: {
              __typename: 'Post'
              id: string
              category?: string | null
              responseCount: number
            } | null
            user: {
              __typename: 'User'
              id: string
              fullName?: string | null
              profile: {
                __typename: 'UserProfile'
                avatarUrl: string
                hasBadge?: boolean | null
                kind?: string | null
              }
            }
          }
        | {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              role?: string | null
              companyName?: string | null
              location?: string | null
              slug?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              avatarUrl: string
            }
          }
    }>
  }
}

export type SidebarQueryVariables = Exact<{ [key: string]: never }>

export type SidebarQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasPosts: boolean
    unseenActivityCount: number
    groups: Array<{
      __typename: 'Group'
      id: string
      title: string
      slug: string
      userIsMember: boolean
      membersCount: number
    }>
  } | null
}

export type SimilarPostFieldsFragment = {
  __typename: 'SimilarPost'
  id: string
  title: string
  responseCount: number
  reactionsCount: number
  age: string
}

export type SimilarPostsQueryVariables = Exact<{
  title: Scalars['String']
}>

export type SimilarPostsQuery = {
  __typename: 'Query'
  similarPosts: Array<{
    __typename: 'SimilarPost'
    id: string
    title: string
    responseCount: number
    reactionsCount: number
    age: string
  }>
}

export type SsoAuthorizationQueryVariables = Exact<{
  email: Scalars['String']
  loginParams?: InputMaybe<LoginParamsInput>
}>

export type SsoAuthorizationQuery = {
  __typename: 'Query'
  ssoAuthorization?: {
    __typename: 'SsoAuthorization'
    isSsoActive: boolean
    authorizationUrl?: string | null
  } | null
}

export type EnterpriseSsoStatusQueryVariables = Exact<{ [key: string]: never }>

export type EnterpriseSsoStatusQuery = {
  __typename: 'Query'
  enterpriseSsoStatus: {
    __typename: 'SsoFeatureSet'
    saml: {
      __typename: 'SsoFeatureStatus'
      status: SsoFeatureStatusEnum
      errors: Array<string>
    }
    scim: {
      __typename: 'SsoFeatureStatus'
      status: SsoFeatureStatusEnum
      errors: Array<string>
    }
  }
}

export type StartingBalanceQueryVariables = Exact<{ [key: string]: never }>

export type StartingBalanceQuery = { __typename: 'Query'; startingBalance: number }

export type SubscriptionJoinRequestPartsFragment = {
  __typename: 'SubscriptionJoinRequest'
  id: string
  status: string
  subscription: {
    __typename: 'Subscription'
    id: string
    hasAvailableSeats: boolean
    logoUrl?: string | null
    user: { __typename: 'User'; id: string; fullName?: string | null }
  }
}

export type SubscriptionJoinRequestQueryVariables = Exact<{
  subscriptionJoinRequestId?: InputMaybe<Scalars['ID']>
}>

export type SubscriptionJoinRequestQuery = {
  __typename: 'Query'
  subscriptionJoinRequest?: {
    __typename: 'SubscriptionJoinRequest'
    id: string
    status: string
    subscription: {
      __typename: 'Subscription'
      id: string
      hasAvailableSeats: boolean
      logoUrl?: string | null
      user: { __typename: 'User'; id: string; fullName?: string | null }
    }
  } | null
}

export type TaxIdReverseChargeStatusQueryVariables = Exact<{ [key: string]: never }>

export type TaxIdReverseChargeStatusQuery = {
  __typename: 'Query'
  taxIdReverseChargeStatus?: {
    __typename: 'TaxIdReverseChargeStatus'
    isReverseCharge?: boolean | null
    taxIdVerificationStatus?: TaxIdVerificationStatus | null
  } | null
}

export type TaxIdsQueryVariables = Exact<{ [key: string]: never }>

export type TaxIdsQuery = {
  __typename: 'Query'
  taxIds: Array<{
    __typename: 'StripeCustomerTaxId'
    country: string
    createdAt: string
    id: string
    stripeCustomerId: string
    type: string
    value: string
    validationStatus: string
  }>
}

export type TaxInfoItemFragment = {
  __typename: 'TaxInfoItem'
  productItemCode: string
  details: Array<{
    __typename: 'TaxInfoItemDetails'
    rate: number
    taxableAmount: number
  }>
}

export type TaxInfoQueryVariables = Exact<{
  productKeys: Array<Scalars['String']> | Scalars['String']
}>

export type TaxInfoQuery = {
  __typename: 'Query'
  taxInfo?: Array<{
    __typename: 'TaxInfoItem'
    productItemCode: string
    details: Array<{
      __typename: 'TaxInfoItemDetails'
      rate: number
      taxableAmount: number
    }>
  }> | null
}

export type SanityTagGroupsQueryVariables = Exact<{ [key: string]: never }>

export type SanityTagGroupsQuery = {
  __typename: 'Query'
  sanityTagGroups: Array<{
    __typename: 'TagGroup'
    id: string
    title: string
    slug: string
    sortOrder?: number | null
    globalSortOrder?: number | null
    global?: boolean | null
    functionTag: {
      __typename: 'Function'
      id: string
      title: string
      slug: string
      sortOrder?: number | null
    }
  }>
}

export type SanityTagGroupQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type SanityTagGroupQuery = {
  __typename: 'Query'
  sanityTagGroup?: {
    __typename: 'TagGroup'
    id: string
    title: string
    slug: string
    functionTag: { __typename: 'Function'; id: string; title: string; slug: string }
  } | null
}

export type TestimonialFieldsFragment = {
  __typename: 'Testimonial'
  id: string
  copy?: string | null
  name?: string | null
  role?: string | null
  thumbnailUrl?: string | null
}

export type TimezonesQueryVariables = Exact<{ [key: string]: never }>

export type TimezonesQuery = { __typename: 'Query'; timezones: Array<string> }

export type UnassignSeatMutationVariables = Exact<{
  input: UnassignSeatInput
}>

export type UnassignSeatMutation = {
  __typename: 'Mutation'
  unassignSeat?: {
    __typename: 'UnassignSeatPayload'
    success?: boolean | null
    errors?: Array<string> | null
    seat?: {
      __typename: 'Seat'
      id: string
      subscriptionMember?: {
        __typename: 'SubscriptionMember'
        id: string
        userId: string
        email: string
        status: string
        role?: string | null
        createdAt: string
      } | null
    } | null
  } | null
}

export type UpcomingPostsQueryVariables = Exact<{ [key: string]: never }>

export type UpcomingPostsQuery = {
  __typename: 'Query'
  upcomingPosts: Array<{
    __typename: 'Post'
    allReactionsCount?: number | null
    createdAt: string
    href: string
    id: string
    responseCount: number
    title?: string | null
    user: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      role?: string | null
      companyName?: string | null
    }
  }>
}

export type OnboardingQuestionPartsFragment = {
  __typename: 'UqQuestion'
  id: string
  choiceText?: string | null
  description?: string | null
  maxChoices?: number | null
  page: number
  question: string
  choices: Array<{
    __typename: 'UqChoice'
    id: string
    choice?: string | null
    tags?: string | null
  }>
}

export type QuestionnaireFieldsFragment = {
  __typename: 'OnboardingQuestionnaire'
  id: string
  areasOfExpertiseTags?: string | null
  businessModelTags?: string | null
  communityInterestTags?: string | null
  companyAudienceTags?: string | null
}

export type UqQuestionnareQueryVariables = Exact<{
  questionnaireId: Scalars['ID']
}>

export type UqQuestionnareQuery = {
  __typename: 'Query'
  uqQuestionnaire?: {
    __typename: 'UqQuestionnaire'
    id: string
    pages: number
    questions: Array<{
      __typename: 'UqQuestion'
      id: string
      choiceText?: string | null
      description?: string | null
      maxChoices?: number | null
      page: number
      question: string
      choices: Array<{
        __typename: 'UqChoice'
        id: string
        choice?: string | null
        tags?: string | null
      }>
    }>
    questionnaire?: {
      __typename: 'OnboardingQuestionnaire'
      id: string
      areasOfExpertiseTags?: string | null
      businessModelTags?: string | null
      communityInterestTags?: string | null
      companyAudienceTags?: string | null
    } | null
  } | null
}

export type UserAnswerFragment = {
  __typename: 'UqUserAnswer'
  id: string
  choice: string
  choiceTags: string
  question: string
  uqChoiceId: string
  uqQuestionId: string
}

export type UqUserAnswersQueryVariables = Exact<{ [key: string]: never }>

export type UqUserAnswersQuery = {
  __typename: 'Query'
  uqUserAnswers: {
    __typename: 'UqUserAnswers'
    acceptanceAnswered: boolean
    onboardingStep: number
    answers: Array<{
      __typename: 'UqUserAnswer'
      id: string
      choice: string
      choiceTags: string
      question: string
      uqChoiceId: string
      uqQuestionId: string
    }>
  }
}

export type UserAccountDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserAccountDetailsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    canSubscribeWithoutTrial: boolean
    shouldSeeTrialMessaging: boolean
    slackUserId?: string | null
    profile: {
      __typename: 'UserProfile'
      hasBadge?: boolean | null
      kind?: string | null
      firstName?: string | null
      lastName?: string | null
      fullName?: string | null
      role?: string | null
      status: UserStatus
      companyName?: string | null
      timezone?: string | null
      location?: string | null
      avatarUrl: string
      twitter?: string | null
      linkedin?: string | null
      sanitySlug?: string | null
      profilePath?: string | null
      previousCompanies?: Array<{
        __typename: 'UserCompany'
        id: string
        company: { __typename: 'Company'; id: string; name?: string | null }
      }> | null
    }
    contact: {
      __typename: 'UserContact'
      allEmails: Array<string>
      primaryEmail: string
      notificationPreferences: {
        __typename: 'UserNotificationPreferences'
        id: string
        mentionEmail: string
        digestEmail: string
        repliesActivity: string
        commentsActivity: string
        postsActivity: string
      }
      slackAccount?: {
        __typename: 'SlackAccount'
        slackUserId: string
        createdAt: string
      } | null
    }
    can: {
      __typename: 'UserCan'
      editEmailPreferences?: boolean | null
      viewBilling?: boolean | null
      viewSubscriptionSummary?: boolean | null
      manageMyTeam?: boolean | null
      pay?: boolean | null
    }
    is: {
      __typename: 'UserIs'
      alumni: boolean
      badgeholder: boolean
      enrolledInLiveProgram: boolean
      expired: boolean
      member: boolean
      paidMember: boolean
      staff: boolean
      trialing: boolean
    }
    oauthProviders?: Array<{
      __typename: 'UserOauthProvider'
      provider?: string | null
      createdAt?: string | null
    }> | null
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        daysLeft: number
        isExpired?: boolean | null
        isCanceled?: boolean | null
        isTeam: boolean
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          id: string
          paymentFailed: boolean
          cancelAtPeriodEnd?: boolean | null
        } | null
      } | null
    } | null
  } | null
}

export type UserAccountDetailsFieldsFragment = {
  __typename: 'User'
  id: string
  accessPolicyKind: string
  canSubscribeWithoutTrial: boolean
  shouldSeeTrialMessaging: boolean
  slackUserId?: string | null
  profile: {
    __typename: 'UserProfile'
    hasBadge?: boolean | null
    kind?: string | null
    firstName?: string | null
    lastName?: string | null
    fullName?: string | null
    role?: string | null
    status: UserStatus
    companyName?: string | null
    timezone?: string | null
    location?: string | null
    avatarUrl: string
    twitter?: string | null
    linkedin?: string | null
    sanitySlug?: string | null
    profilePath?: string | null
    previousCompanies?: Array<{
      __typename: 'UserCompany'
      id: string
      company: { __typename: 'Company'; id: string; name?: string | null }
    }> | null
  }
  contact: {
    __typename: 'UserContact'
    allEmails: Array<string>
    primaryEmail: string
    notificationPreferences: {
      __typename: 'UserNotificationPreferences'
      id: string
      mentionEmail: string
      digestEmail: string
      repliesActivity: string
      commentsActivity: string
      postsActivity: string
    }
    slackAccount?: {
      __typename: 'SlackAccount'
      slackUserId: string
      createdAt: string
    } | null
  }
  can: {
    __typename: 'UserCan'
    editEmailPreferences?: boolean | null
    viewBilling?: boolean | null
    viewSubscriptionSummary?: boolean | null
    manageMyTeam?: boolean | null
    pay?: boolean | null
  }
  is: {
    __typename: 'UserIs'
    alumni: boolean
    badgeholder: boolean
    enrolledInLiveProgram: boolean
    expired: boolean
    member: boolean
    paidMember: boolean
    staff: boolean
    trialing: boolean
  }
  oauthProviders?: Array<{
    __typename: 'UserOauthProvider'
    provider?: string | null
    createdAt?: string | null
  }> | null
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      id: string
      daysLeft: number
      isExpired?: boolean | null
      isCanceled?: boolean | null
      isTeam: boolean
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        paymentFailed: boolean
        cancelAtPeriodEnd?: boolean | null
      } | null
    } | null
  } | null
}

export type UserAccountManageSeatsSubscriptionSeatsFragment = {
  __typename: 'Seat'
  id: string
  scheduledForDeletionDate?: string | null
  isRenewing: boolean
  hasCohortAccess: boolean
  subscriptionMember?: {
    __typename: 'SubscriptionMember'
    id: string
    userId: string
    status: string
    createdAt: string
    email: string
    role?: string | null
    user: {
      __typename: 'User'
      fullName?: string | null
      email: string
      workEmail?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
      is: { __typename: 'UserIs'; enrolledInLiveProgram: boolean }
    }
  } | null
}

export type UserAccountManageSeatsSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  userIsOwner: boolean
  termEndsAt?: string | null
  numSeats: number
  expiringSeatCount: number
  seats: Array<{
    __typename: 'Seat'
    id: string
    scheduledForDeletionDate?: string | null
    isRenewing: boolean
    hasCohortAccess: boolean
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      userId: string
      status: string
      createdAt: string
      email: string
      role?: string | null
      user: {
        __typename: 'User'
        fullName?: string | null
        email: string
        workEmail?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
        is: { __typename: 'UserIs'; enrolledInLiveProgram: boolean }
      }
    } | null
  }>
  stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
}

export type UserAccountManageSeatsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserAccountManageSeatsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    contact: { __typename: 'UserContact'; primaryEmail: string; allEmails: Array<string> }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        userIsOwner: boolean
        termEndsAt?: string | null
        numSeats: number
        expiringSeatCount: number
        seats: Array<{
          __typename: 'Seat'
          id: string
          scheduledForDeletionDate?: string | null
          isRenewing: boolean
          hasCohortAccess: boolean
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            status: string
            createdAt: string
            email: string
            role?: string | null
            user: {
              __typename: 'User'
              fullName?: string | null
              email: string
              workEmail?: string | null
              profile: { __typename: 'UserProfile'; avatarUrl: string }
              is: { __typename: 'UserIs'; enrolledInLiveProgram: boolean }
            }
          } | null
        }>
        stripeSubscription?: { __typename: 'StripeSubscription'; id: string } | null
      } | null
    } | null
  } | null
}

export type UserBookmarksFeedQueryVariables = Exact<{
  userId: Scalars['ID']
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<BookmarkFilters>
}>

export type UserBookmarksFeedQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    bookmarksFeed: {
      __typename: 'BookmarksFeed'
      total: number
      bookmarks: Array<{
        __typename: 'Bookmark'
        id: string
        eventId?: string | null
        lessonPosition?: number | null
        sanityId?: string | null
        type: BookmarkType
        basedOn?: string | null
        noteBody?: string | null
        updatedAt: string
        createdAtInteger: number
        isWithinContent: boolean
        numPeopleWhoBookmarked?: number | null
        anchor?: string | null
        referenceImageUrl?: string | null
        seconds?: number | null
        updated?: string | null
        created?: string | null
        title?: string | null
        externalUrl?: string | null
        metadata?: any | null
        startOffset?: number | null
        endOffset?: number | null
        filedBookmarks: Array<{
          __typename: 'FiledBookmark'
          id: string
          bookmarkFolderId: string
        }>
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          name: string
          position?: number | null
        } | null
        cmsSection?: {
          __typename: 'CmsSection'
          id: string
          name: string
          position?: number | null
          progressPercent?: number | null
          childrenCount: number
          completedChildrenCount?: number | null
          path?: string | null
        } | null
        content?: {
          __typename: 'CmsSection'
          id: string
          contentType?: CmsSectionContentType | null
          contentTypeIcon?: string | null
          estimatedTime: number
          contentBookmarkId?: string | null
          contentIsInMySavedItems?: boolean | null
          heroImageUrl?: string | null
          href: string
          name: string
          numPhases?: number | null
          projectIcon?: string | null
          shortDescription?: string | null
          numBookmarks?: number | null
          showNewBadge: boolean
          slug: string
          accessLevel?: string | null
          previewable: boolean
          lastViewedAt?: string | null
          contentSubtype?: string | null
          lessonNum?: number | null
          topic?: { __typename: 'PostTopic'; id: string; title: string } | null
          cmsModule?: {
            __typename: 'CmsModule'
            id: string
            slug?: string | null
            name: string
          } | null
          cmsProgram: {
            __typename: 'CmsProgram'
            id: string
            notLaunched?: boolean | null
            enrolled: boolean
            slug: string
            name: string
            collaborators: Array<{
              __typename: 'Host'
              id: string
              name?: string | null
              thumbnailUrl: string
            }>
          }
        } | null
        cmsProgram?: {
          __typename: 'CmsProgram'
          id: string
          name: string
          slug: string
          progressPercent: number
          iconImageUrl?: string | null
          notLaunched?: boolean | null
          deprecatedAt?: string | null
          description?: string | null
          synopsis?: string | null
          lessonCount: number
          purchased: boolean
          upcoming: boolean
          enrolled: boolean
          canEnroll: boolean
          showNewBadge: boolean
          topic?: string | null
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
            companyName?: string | null
            description?: string | null
            title?: string | null
            avatarUrl: string
          }>
        } | null
        event?: {
          __typename: 'Event'
          id: string
          name: string
          slug: string
          kind: string
          past: boolean
          willBeRecorded: boolean
          startsAtUtc: string
          endsAtUtc: string
          uuid: string
          attendees: {
            __typename: 'EventAttendeeConnection'
            edges: Array<{
              __typename: 'EventAttendeeEdge'
              node: {
                __typename: 'EventAttendee'
                id: string
                hasBadge: boolean
                kind?: string | null
                status: string
                user: {
                  __typename: 'User'
                  id: string
                  profile: {
                    __typename: 'UserProfile'
                    fullName?: string | null
                    companyName?: string | null
                    avatarUrl: string
                    role?: string | null
                    twitter?: string | null
                    linkedin?: string | null
                    hasBadge?: boolean | null
                    kind?: string | null
                    location?: string | null
                    onboardingStep: number
                    profileUrl?: string | null
                    status: UserStatus
                  }
                }
              }
            }>
          }
          cohorts: Array<{
            __typename: 'Cohort'
            id: string
            slug: string
            season: { __typename: 'Season'; showCohortViewerAt?: string | null }
          }>
          hosts: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
          featuredGuests: Array<{
            __typename: 'EventSpeaker'
            id: string
            kind?: string | null
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }>
        } | null
        artifact?: {
          __typename: 'Artifact'
          id: string
          title?: string | null
          slug?: string | null
          description?: any | null
          companyLogoUrl?: string | null
          companyName?: string | null
          thumbnailUrl?: string | null
          viewCount?: number | null
          commentCount?: number | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            slug?: string | null
            position?: string | null
            avatarUrl?: string | null
            company?: {
              __typename: 'SanityCompany'
              id?: string | null
              name?: string | null
            } | null
          }> | null
        } | null
        course?: {
          __typename: 'MarketingCourse'
          id: string
          slug?: string | null
          cmsProgramSlug?: string | null
          startDate?: string | null
          subtitle?: string | null
          title?: string | null
          legacyProgramId?: string | null
          bookmarkId?: string | null
          courseType?: string | null
          course?: {
            __typename: 'SanityCourse'
            id: string
            title: string
            shortDescription?: string | null
            slug: string
            bookmarkId?: string | null
            isAvailableOnDemand?: boolean | null
            creators?: Array<{
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            }> | null
          } | null
          creators?: Array<{
            __typename: 'Creator'
            id: string
            expert?: {
              __typename: 'ArtifactAuthor'
              id: string
              name?: string | null
              avatarPhoto?: {
                __typename: 'Image'
                imageUrl?: string | null
                imageAlt?: string | null
                aspectRatio?: number | null
              } | null
            } | null
          }> | null
        } | null
        guide?: {
          __typename: 'Unit'
          id: string
          title?: string | null
          whatYouWillDo?: any | null
          estimatedTimeInMinutes?: number | null
          stepsCount?: number | null
          slug?: string | null
          contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
          heroImage?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
          tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
          authors?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            slug?: string | null
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        cclCourse?: {
          __typename: 'CclCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          staticId: string
          onDemand?: boolean | null
          completionPercentage?: number | null
          live?: boolean | null
          cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
          topics?: Array<{
            __typename: 'CclTopic'
            id: string
            title: string
            slug: string
          }> | null
          creators?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
          upcomingSessions?: Array<{
            __typename: 'CclCourseSession'
            id: string
            startsAt: string
            endsAt: string
            duration?: string | null
            experts?: Array<{
              __typename: 'CclExpert'
              id: string
              name: string
              avatarUrl?: string | null
            }> | null
          }> | null
        } | null
      }>
    }
  } | null
}

export type BookmarksFeedQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<Scalars['String']>
  filters?: InputMaybe<BookmarkFilters>
}>

export type BookmarksFeedQuery = {
  __typename: 'Query'
  bookmarksFeed: {
    __typename: 'BookmarksFeed'
    total: number
    bookmarks: Array<{
      __typename: 'Bookmark'
      id: string
      eventId?: string | null
      lessonPosition?: number | null
      sanityId?: string | null
      type: BookmarkType
      basedOn?: string | null
      noteBody?: string | null
      updatedAt: string
      createdAtInteger: number
      isWithinContent: boolean
      numPeopleWhoBookmarked?: number | null
      anchor?: string | null
      referenceImageUrl?: string | null
      seconds?: number | null
      updated?: string | null
      created?: string | null
      title?: string | null
      externalUrl?: string | null
      metadata?: any | null
      startOffset?: number | null
      endOffset?: number | null
      filedBookmarks: Array<{
        __typename: 'FiledBookmark'
        id: string
        bookmarkFolderId: string
      }>
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        name: string
        position?: number | null
      } | null
      cmsSection?: {
        __typename: 'CmsSection'
        id: string
        name: string
        position?: number | null
        progressPercent?: number | null
        childrenCount: number
        completedChildrenCount?: number | null
        path?: string | null
      } | null
      content?: {
        __typename: 'CmsSection'
        id: string
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        estimatedTime: number
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        heroImageUrl?: string | null
        href: string
        name: string
        numPhases?: number | null
        projectIcon?: string | null
        shortDescription?: string | null
        numBookmarks?: number | null
        showNewBadge: boolean
        slug: string
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        lessonNum?: number | null
        topic?: { __typename: 'PostTopic'; id: string; title: string } | null
        cmsModule?: {
          __typename: 'CmsModule'
          id: string
          slug?: string | null
          name: string
        } | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          notLaunched?: boolean | null
          enrolled: boolean
          slug: string
          name: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
      } | null
      cmsProgram?: {
        __typename: 'CmsProgram'
        id: string
        name: string
        slug: string
        progressPercent: number
        iconImageUrl?: string | null
        notLaunched?: boolean | null
        deprecatedAt?: string | null
        description?: string | null
        synopsis?: string | null
        lessonCount: number
        purchased: boolean
        upcoming: boolean
        enrolled: boolean
        canEnroll: boolean
        showNewBadge: boolean
        topic?: string | null
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
          companyName?: string | null
          description?: string | null
          title?: string | null
          avatarUrl: string
        }>
      } | null
      event?: {
        __typename: 'Event'
        id: string
        name: string
        slug: string
        kind: string
        past: boolean
        willBeRecorded: boolean
        startsAtUtc: string
        endsAtUtc: string
        uuid: string
        attendees: {
          __typename: 'EventAttendeeConnection'
          edges: Array<{
            __typename: 'EventAttendeeEdge'
            node: {
              __typename: 'EventAttendee'
              id: string
              hasBadge: boolean
              kind?: string | null
              status: string
              user: {
                __typename: 'User'
                id: string
                profile: {
                  __typename: 'UserProfile'
                  fullName?: string | null
                  companyName?: string | null
                  avatarUrl: string
                  role?: string | null
                  twitter?: string | null
                  linkedin?: string | null
                  hasBadge?: boolean | null
                  kind?: string | null
                  location?: string | null
                  onboardingStep: number
                  profileUrl?: string | null
                  status: UserStatus
                }
              }
            }
          }>
        }
        cohorts: Array<{
          __typename: 'Cohort'
          id: string
          slug: string
          season: { __typename: 'Season'; showCohortViewerAt?: string | null }
        }>
        hosts: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
        featuredGuests: Array<{
          __typename: 'EventSpeaker'
          id: string
          kind?: string | null
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }>
      } | null
      artifact?: {
        __typename: 'Artifact'
        id: string
        title?: string | null
        slug?: string | null
        description?: any | null
        companyLogoUrl?: string | null
        companyName?: string | null
        thumbnailUrl?: string | null
        viewCount?: number | null
        commentCount?: number | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          slug?: string | null
          position?: string | null
          avatarUrl?: string | null
          company?: {
            __typename: 'SanityCompany'
            id?: string | null
            name?: string | null
          } | null
        }> | null
      } | null
      course?: {
        __typename: 'MarketingCourse'
        id: string
        slug?: string | null
        cmsProgramSlug?: string | null
        startDate?: string | null
        subtitle?: string | null
        title?: string | null
        legacyProgramId?: string | null
        bookmarkId?: string | null
        courseType?: string | null
        course?: {
          __typename: 'SanityCourse'
          id: string
          title: string
          shortDescription?: string | null
          slug: string
          bookmarkId?: string | null
          isAvailableOnDemand?: boolean | null
          creators?: Array<{
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          }> | null
        } | null
        creators?: Array<{
          __typename: 'Creator'
          id: string
          expert?: {
            __typename: 'ArtifactAuthor'
            id: string
            name?: string | null
            avatarPhoto?: {
              __typename: 'Image'
              imageUrl?: string | null
              imageAlt?: string | null
              aspectRatio?: number | null
            } | null
          } | null
        }> | null
      } | null
      guide?: {
        __typename: 'Unit'
        id: string
        title?: string | null
        whatYouWillDo?: any | null
        estimatedTimeInMinutes?: number | null
        stepsCount?: number | null
        slug?: string | null
        contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
        heroImage?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
        tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
        authors?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          slug?: string | null
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      cclCourse?: {
        __typename: 'CclCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        staticId: string
        onDemand?: boolean | null
        completionPercentage?: number | null
        live?: boolean | null
        cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
        topics?: Array<{
          __typename: 'CclTopic'
          id: string
          title: string
          slug: string
        }> | null
        creators?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
        upcomingSessions?: Array<{
          __typename: 'CclCourseSession'
          id: string
          startsAt: string
          endsAt: string
          duration?: string | null
          experts?: Array<{
            __typename: 'CclExpert'
            id: string
            name: string
            avatarUrl?: string | null
          }> | null
        }> | null
      } | null
    }>
  }
}

export type UserBookmarksFeedFiltersQueryVariables = Exact<{
  userId: Scalars['ID']
  filters?: InputMaybe<BookmarkFilters>
  moduleFilters?: InputMaybe<BookmarkModulesFilters>
}>

export type UserBookmarksFeedFiltersQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    bookmarksTotal: number
    bookmarkCmsModules: Array<{ __typename: 'CmsModule'; id: string; name: string }>
    bookmarkCmsPrograms: Array<{ __typename: 'CmsProgram'; id: string; name: string }>
  } | null
}

export type AllUserBookmarksProgramsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type AllUserBookmarksProgramsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    bookmarkCmsPrograms: Array<{ __typename: 'CmsProgram'; id: string; name: string }>
  } | null
}

export type UserBookmarksFeedTotalQueryVariables = Exact<{
  userId: Scalars['ID']
  filters?: InputMaybe<BookmarkFilters>
}>

export type UserBookmarksFeedTotalQuery = {
  __typename: 'Query'
  user?: { __typename: 'User'; id: string; bookmarksTotal: number } | null
}

export type ReforgeCollectionsQueryVariables = Exact<{ [key: string]: never }>

export type ReforgeCollectionsQuery = {
  __typename: 'Query'
  reforgeCollections?: Array<{
    __typename: 'BookmarkFolder'
    id: string
    name: string
    isWhiteGlove: boolean
    user: { __typename: 'User'; id: string; fullName?: string | null }
  }> | null
}

export type BookmarkFoldersQueryVariables = Exact<{ [key: string]: never }>

export type BookmarkFoldersQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    hasSeenCollectionsPrompt: boolean
    bookmarkFolders: Array<{
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    }>
  } | null
  teamMembers?: Array<{
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }> | null
}

export type RecommendedBookmarkFoldersQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type RecommendedBookmarkFoldersQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    recommendedBookmarkFolders: Array<{
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      forkedFromId?: string | null
      goalComplete?: boolean | null
      isShared: boolean
      parentId?: string | null
      reforgeCollection: boolean
      teamProgress?: number | null
      user: {
        __typename: 'User'
        id: string
        fullName?: string | null
        profile: { __typename: 'UserProfile'; avatarUrl: string }
      }
      sharedFolders: Array<{
        __typename: 'SharedFolder'
        id: string
        status: SharedFolderStatus
        sharedPartyId: string
        sharedPartyType: string
        userId: string
        bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
      }>
    }>
  } | null
}

export type UserBookmarkFoldersQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserBookmarkFoldersQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    userCollections?: Array<{
      __typename: 'BookmarkFolder'
      id: string
      name: string
      description?: string | null
      reforgeCollection: boolean
      forkedFromId?: string | null
    }> | null
  } | null
}

export type BookmarksFromFolderPartsFragment = {
  __typename: 'Bookmark'
  lastViewedContentAtInteger?: number | null
  id: string
  eventId?: string | null
  lessonPosition?: number | null
  sanityId?: string | null
  type: BookmarkType
  basedOn?: string | null
  noteBody?: string | null
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  anchor?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
  updated?: string | null
  created?: string | null
  title?: string | null
  externalUrl?: string | null
  metadata?: any | null
  startOffset?: number | null
  endOffset?: number | null
  cmsModule?: {
    __typename: 'CmsModule'
    id: string
    name: string
    position?: number | null
  } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    position?: number | null
    progressPercent?: number | null
    childrenCount: number
    completedChildrenCount?: number | null
    path?: string | null
  } | null
  content?: {
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    numPhases?: number | null
    projectIcon?: string | null
    shortDescription?: string | null
    numBookmarks?: number | null
    showNewBadge: boolean
    slug: string
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    lessonNum?: number | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      slug?: string | null
      name: string
    } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    slug: string
    progressPercent: number
    iconImageUrl?: string | null
    notLaunched?: boolean | null
    deprecatedAt?: string | null
    description?: string | null
    synopsis?: string | null
    lessonCount: number
    purchased: boolean
    upcoming: boolean
    enrolled: boolean
    canEnroll: boolean
    showNewBadge: boolean
    topic?: string | null
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
      companyName?: string | null
      description?: string | null
      title?: string | null
      avatarUrl: string
    }>
  } | null
  event?: {
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    willBeRecorded: boolean
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendees: {
      __typename: 'EventAttendeeConnection'
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  } | null
  artifact?: {
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    description?: any | null
    companyLogoUrl?: string | null
    companyName?: string | null
    thumbnailUrl?: string | null
    viewCount?: number | null
    commentCount?: number | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarUrl?: string | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
  } | null
  course?: {
    __typename: 'MarketingCourse'
    id: string
    slug?: string | null
    cmsProgramSlug?: string | null
    startDate?: string | null
    subtitle?: string | null
    title?: string | null
    legacyProgramId?: string | null
    bookmarkId?: string | null
    courseType?: string | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      bookmarkId?: string | null
      isAvailableOnDemand?: boolean | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  guide?: {
    __typename: 'Unit'
    id: string
    title?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    slug?: string | null
    contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      slug?: string | null
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
}

export type BookmarksFromFolderQueryVariables = Exact<{
  folderId: Scalars['ID']
}>

export type BookmarksFromFolderQuery = {
  __typename: 'Query'
  goalProgress?: number | null
  bookmarksFromFolder: Array<{
    __typename: 'Bookmark'
    id: string
    lastViewedContentAtInteger?: number | null
    eventId?: string | null
    lessonPosition?: number | null
    sanityId?: string | null
    type: BookmarkType
    basedOn?: string | null
    noteBody?: string | null
    updatedAt: string
    createdAtInteger: number
    isWithinContent: boolean
    numPeopleWhoBookmarked?: number | null
    anchor?: string | null
    referenceImageUrl?: string | null
    seconds?: number | null
    updated?: string | null
    created?: string | null
    title?: string | null
    externalUrl?: string | null
    metadata?: any | null
    startOffset?: number | null
    endOffset?: number | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      name: string
      position?: number | null
    } | null
    cmsSection?: {
      __typename: 'CmsSection'
      id: string
      name: string
      position?: number | null
      progressPercent?: number | null
      childrenCount: number
      completedChildrenCount?: number | null
      path?: string | null
    } | null
    content?: {
      __typename: 'CmsSection'
      id: string
      contentType?: CmsSectionContentType | null
      contentTypeIcon?: string | null
      estimatedTime: number
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      heroImageUrl?: string | null
      href: string
      name: string
      numPhases?: number | null
      projectIcon?: string | null
      shortDescription?: string | null
      numBookmarks?: number | null
      showNewBadge: boolean
      slug: string
      accessLevel?: string | null
      previewable: boolean
      lastViewedAt?: string | null
      contentSubtype?: string | null
      lessonNum?: number | null
      topic?: { __typename: 'PostTopic'; id: string; title: string } | null
      cmsModule?: {
        __typename: 'CmsModule'
        id: string
        slug?: string | null
        name: string
      } | null
      cmsProgram: {
        __typename: 'CmsProgram'
        id: string
        notLaunched?: boolean | null
        enrolled: boolean
        slug: string
        name: string
        collaborators: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          thumbnailUrl: string
        }>
      }
    } | null
    cmsProgram?: {
      __typename: 'CmsProgram'
      id: string
      name: string
      slug: string
      progressPercent: number
      iconImageUrl?: string | null
      notLaunched?: boolean | null
      deprecatedAt?: string | null
      description?: string | null
      synopsis?: string | null
      lessonCount: number
      purchased: boolean
      upcoming: boolean
      enrolled: boolean
      canEnroll: boolean
      showNewBadge: boolean
      topic?: string | null
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
        companyName?: string | null
        description?: string | null
        title?: string | null
        avatarUrl: string
      }>
    } | null
    event?: {
      __typename: 'Event'
      id: string
      name: string
      slug: string
      kind: string
      past: boolean
      willBeRecorded: boolean
      startsAtUtc: string
      endsAtUtc: string
      uuid: string
      attendees: {
        __typename: 'EventAttendeeConnection'
        edges: Array<{
          __typename: 'EventAttendeeEdge'
          node: {
            __typename: 'EventAttendee'
            id: string
            hasBadge: boolean
            kind?: string | null
            status: string
            user: {
              __typename: 'User'
              id: string
              profile: {
                __typename: 'UserProfile'
                fullName?: string | null
                companyName?: string | null
                avatarUrl: string
                role?: string | null
                twitter?: string | null
                linkedin?: string | null
                hasBadge?: boolean | null
                kind?: string | null
                location?: string | null
                onboardingStep: number
                profileUrl?: string | null
                status: UserStatus
              }
            }
          }
        }>
      }
      cohorts: Array<{
        __typename: 'Cohort'
        id: string
        slug: string
        season: { __typename: 'Season'; showCohortViewerAt?: string | null }
      }>
      hosts: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
      featuredGuests: Array<{
        __typename: 'EventSpeaker'
        id: string
        kind?: string | null
        user: {
          __typename: 'User'
          id: string
          profile: {
            __typename: 'UserProfile'
            fullName?: string | null
            companyName?: string | null
            avatarUrl: string
            role?: string | null
            twitter?: string | null
            linkedin?: string | null
            hasBadge?: boolean | null
            kind?: string | null
            location?: string | null
            onboardingStep: number
            profileUrl?: string | null
            status: UserStatus
          }
        }
      }>
    } | null
    artifact?: {
      __typename: 'Artifact'
      id: string
      title?: string | null
      slug?: string | null
      description?: any | null
      companyLogoUrl?: string | null
      companyName?: string | null
      thumbnailUrl?: string | null
      viewCount?: number | null
      commentCount?: number | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        slug?: string | null
        position?: string | null
        avatarUrl?: string | null
        company?: {
          __typename: 'SanityCompany'
          id?: string | null
          name?: string | null
        } | null
      }> | null
    } | null
    course?: {
      __typename: 'MarketingCourse'
      id: string
      slug?: string | null
      cmsProgramSlug?: string | null
      startDate?: string | null
      subtitle?: string | null
      title?: string | null
      legacyProgramId?: string | null
      bookmarkId?: string | null
      courseType?: string | null
      course?: {
        __typename: 'SanityCourse'
        id: string
        title: string
        shortDescription?: string | null
        slug: string
        bookmarkId?: string | null
        isAvailableOnDemand?: boolean | null
        creators?: Array<{
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        }> | null
      } | null
      creators?: Array<{
        __typename: 'Creator'
        id: string
        expert?: {
          __typename: 'ArtifactAuthor'
          id: string
          name?: string | null
          avatarPhoto?: {
            __typename: 'Image'
            imageUrl?: string | null
            imageAlt?: string | null
            aspectRatio?: number | null
          } | null
        } | null
      }> | null
    } | null
    guide?: {
      __typename: 'Unit'
      id: string
      title?: string | null
      whatYouWillDo?: any | null
      estimatedTimeInMinutes?: number | null
      stepsCount?: number | null
      slug?: string | null
      contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
      heroImage?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
      tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
      authors?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        slug?: string | null
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    filedBookmarks: Array<{
      __typename: 'FiledBookmark'
      id: string
      bookmarkFolderId: string
    }>
    cclCourse?: {
      __typename: 'CclCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      staticId: string
      onDemand?: boolean | null
      completionPercentage?: number | null
      live?: boolean | null
      cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
      topics?: Array<{
        __typename: 'CclTopic'
        id: string
        title: string
        slug: string
      }> | null
      creators?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
      upcomingSessions?: Array<{
        __typename: 'CclCourseSession'
        id: string
        startsAt: string
        endsAt: string
        duration?: string | null
        experts?: Array<{
          __typename: 'CclExpert'
          id: string
          name: string
          avatarUrl?: string | null
        }> | null
      }> | null
    } | null
  }>
  bookmarkFolder?: {
    __typename: 'BookmarkFolder'
    id: string
    name: string
    description?: string | null
    forkedFromId?: string | null
    goalComplete?: boolean | null
    isShared: boolean
    parentId?: string | null
    reforgeCollection: boolean
    teamProgress?: number | null
    user: {
      __typename: 'User'
      id: string
      fullName?: string | null
      profile: { __typename: 'UserProfile'; avatarUrl: string }
    }
    sharedFolders: Array<{
      __typename: 'SharedFolder'
      id: string
      status: SharedFolderStatus
      sharedPartyId: string
      sharedPartyType: string
      userId: string
      bookmarkFolder: { __typename: 'BookmarkFolder'; id: string }
    }>
  } | null
  teamMembers?: Array<{
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }> | null
}

export type BookmarkFragment = {
  __typename: 'Bookmark'
  id: string
  eventId?: string | null
  lessonPosition?: number | null
  sanityId?: string | null
  type: BookmarkType
  basedOn?: string | null
  noteBody?: string | null
  updatedAt: string
  createdAtInteger: number
  isWithinContent: boolean
  numPeopleWhoBookmarked?: number | null
  anchor?: string | null
  referenceImageUrl?: string | null
  seconds?: number | null
  updated?: string | null
  created?: string | null
  title?: string | null
  externalUrl?: string | null
  metadata?: any | null
  startOffset?: number | null
  endOffset?: number | null
  filedBookmarks: Array<{
    __typename: 'FiledBookmark'
    id: string
    bookmarkFolderId: string
  }>
  cmsModule?: {
    __typename: 'CmsModule'
    id: string
    name: string
    position?: number | null
  } | null
  cmsSection?: {
    __typename: 'CmsSection'
    id: string
    name: string
    position?: number | null
    progressPercent?: number | null
    childrenCount: number
    completedChildrenCount?: number | null
    path?: string | null
  } | null
  content?: {
    __typename: 'CmsSection'
    id: string
    contentType?: CmsSectionContentType | null
    contentTypeIcon?: string | null
    estimatedTime: number
    contentBookmarkId?: string | null
    contentIsInMySavedItems?: boolean | null
    heroImageUrl?: string | null
    href: string
    name: string
    numPhases?: number | null
    projectIcon?: string | null
    shortDescription?: string | null
    numBookmarks?: number | null
    showNewBadge: boolean
    slug: string
    accessLevel?: string | null
    previewable: boolean
    lastViewedAt?: string | null
    contentSubtype?: string | null
    lessonNum?: number | null
    topic?: { __typename: 'PostTopic'; id: string; title: string } | null
    cmsModule?: {
      __typename: 'CmsModule'
      id: string
      slug?: string | null
      name: string
    } | null
    cmsProgram: {
      __typename: 'CmsProgram'
      id: string
      notLaunched?: boolean | null
      enrolled: boolean
      slug: string
      name: string
      collaborators: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        thumbnailUrl: string
      }>
    }
  } | null
  cmsProgram?: {
    __typename: 'CmsProgram'
    id: string
    name: string
    slug: string
    progressPercent: number
    iconImageUrl?: string | null
    notLaunched?: boolean | null
    deprecatedAt?: string | null
    description?: string | null
    synopsis?: string | null
    lessonCount: number
    purchased: boolean
    upcoming: boolean
    enrolled: boolean
    canEnroll: boolean
    showNewBadge: boolean
    topic?: string | null
    collaborators: Array<{
      __typename: 'Host'
      id: string
      name?: string | null
      thumbnailUrl: string
      companyName?: string | null
      description?: string | null
      title?: string | null
      avatarUrl: string
    }>
  } | null
  event?: {
    __typename: 'Event'
    id: string
    name: string
    slug: string
    kind: string
    past: boolean
    willBeRecorded: boolean
    startsAtUtc: string
    endsAtUtc: string
    uuid: string
    attendees: {
      __typename: 'EventAttendeeConnection'
      edges: Array<{
        __typename: 'EventAttendeeEdge'
        node: {
          __typename: 'EventAttendee'
          id: string
          hasBadge: boolean
          kind?: string | null
          status: string
          user: {
            __typename: 'User'
            id: string
            profile: {
              __typename: 'UserProfile'
              fullName?: string | null
              companyName?: string | null
              avatarUrl: string
              role?: string | null
              twitter?: string | null
              linkedin?: string | null
              hasBadge?: boolean | null
              kind?: string | null
              location?: string | null
              onboardingStep: number
              profileUrl?: string | null
              status: UserStatus
            }
          }
        }
      }>
    }
    cohorts: Array<{
      __typename: 'Cohort'
      id: string
      slug: string
      season: { __typename: 'Season'; showCohortViewerAt?: string | null }
    }>
    hosts: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
    featuredGuests: Array<{
      __typename: 'EventSpeaker'
      id: string
      kind?: string | null
      user: {
        __typename: 'User'
        id: string
        profile: {
          __typename: 'UserProfile'
          fullName?: string | null
          companyName?: string | null
          avatarUrl: string
          role?: string | null
          twitter?: string | null
          linkedin?: string | null
          hasBadge?: boolean | null
          kind?: string | null
          location?: string | null
          onboardingStep: number
          profileUrl?: string | null
          status: UserStatus
        }
      }
    }>
  } | null
  artifact?: {
    __typename: 'Artifact'
    id: string
    title?: string | null
    slug?: string | null
    description?: any | null
    companyLogoUrl?: string | null
    companyName?: string | null
    thumbnailUrl?: string | null
    viewCount?: number | null
    commentCount?: number | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      name?: string | null
      slug?: string | null
      position?: string | null
      avatarUrl?: string | null
      company?: {
        __typename: 'SanityCompany'
        id?: string | null
        name?: string | null
      } | null
    }> | null
  } | null
  course?: {
    __typename: 'MarketingCourse'
    id: string
    slug?: string | null
    cmsProgramSlug?: string | null
    startDate?: string | null
    subtitle?: string | null
    title?: string | null
    legacyProgramId?: string | null
    bookmarkId?: string | null
    courseType?: string | null
    course?: {
      __typename: 'SanityCourse'
      id: string
      title: string
      shortDescription?: string | null
      slug: string
      bookmarkId?: string | null
      isAvailableOnDemand?: boolean | null
      creators?: Array<{
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      }> | null
    } | null
    creators?: Array<{
      __typename: 'Creator'
      id: string
      expert?: {
        __typename: 'ArtifactAuthor'
        id: string
        name?: string | null
        avatarPhoto?: {
          __typename: 'Image'
          imageUrl?: string | null
          imageAlt?: string | null
          aspectRatio?: number | null
        } | null
      } | null
    }> | null
  } | null
  guide?: {
    __typename: 'Unit'
    id: string
    title?: string | null
    whatYouWillDo?: any | null
    estimatedTimeInMinutes?: number | null
    stepsCount?: number | null
    slug?: string | null
    contentBlocks?: Array<{ __typename: 'ContentBlock'; id: string }> | null
    heroImage?: {
      __typename: 'Image'
      imageUrl?: string | null
      imageAlt?: string | null
      aspectRatio?: number | null
    } | null
    tags?: Array<{ __typename: 'Tag'; id: string; title: string }> | null
    authors?: Array<{
      __typename: 'ArtifactAuthor'
      id: string
      slug?: string | null
      name?: string | null
      avatarPhoto?: {
        __typename: 'Image'
        imageUrl?: string | null
        imageAlt?: string | null
        aspectRatio?: number | null
      } | null
    }> | null
  } | null
  cclCourse?: {
    __typename: 'CclCourse'
    id: string
    title: string
    shortDescription?: string | null
    slug: string
    staticId: string
    onDemand?: boolean | null
    completionPercentage?: number | null
    live?: boolean | null
    cmsProgram?: { __typename: 'CmsProgram'; slug: string } | null
    topics?: Array<{
      __typename: 'CclTopic'
      id: string
      title: string
      slug: string
    }> | null
    creators?: Array<{
      __typename: 'CclExpert'
      id: string
      name: string
      avatarUrl?: string | null
    }> | null
    upcomingSessions?: Array<{
      __typename: 'CclCourseSession'
      id: string
      startsAt: string
      endsAt: string
      duration?: string | null
      experts?: Array<{
        __typename: 'CclExpert'
        id: string
        name: string
        avatarUrl?: string | null
      }> | null
    }> | null
  } | null
}

export type UserCohortQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type UserCohortQuery = {
  __typename: 'Query'
  userCohort?: {
    __typename: 'UserCohort'
    cohortEventsAttended?: number | null
    numLessonsCompleted?: number | null
    programId: string
    cohortCompletionCertificate?: {
      __typename: 'CohortCertificate'
      id: string
      certificateUrl?: string | null
    } | null
  } | null
}

export type CohortHistoryUserFragment = {
  __typename: 'User'
  id: string
  profile: { __typename: 'UserProfile'; avatarUrl: string }
  is: { __typename: 'UserIs'; member: boolean; staff: boolean }
  userCourses: {
    __typename: 'UserCourses'
    all?: Array<{
      __typename: 'UserCourse'
      id: string
      course: { __typename: 'Course'; title: string; slug: string }
      courseSession: { __typename: 'CourseSession'; startsAt: string }
    }> | null
  }
  cohorts?: {
    __typename: 'UserCohorts'
    previous: Array<{
      __typename: 'UserCohort'
      id: string
      programId: string
      programName: string
      season: string
      canBeUpdated: boolean
      canViewCertificate: boolean
      snapshotable: boolean
      cohortCompletionCertificate?: {
        __typename: 'CohortCertificate'
        id: string
        certificateUrl?: string | null
      } | null
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
    }>
    current: Array<{
      __typename: 'UserCohort'
      id: string
      programId: string
      programName: string
      season: string
      seasonId: string
      canBeUpdated: boolean
      canViewCertificate: boolean
      cohortCompletionCertificate?: {
        __typename: 'CohortCertificate'
        id: string
        certificateUrl?: string | null
      } | null
      snapshot?: {
        __typename: 'Snapshot'
        id: string
        token: string
        released: boolean
      } | null
      cohort: {
        __typename: 'Cohort'
        id: string
        postCohort: boolean
        eventTimes?: Array<{
          __typename: 'CohortEventTimes'
          count: number
          firstStartDate: string
        }> | null
      }
    }>
  } | null
  recommendedPrograms: Array<{
    __typename: 'CmsProgram'
    id: string
    iconImageUrl?: string | null
    heroImageUrl?: string | null
    launched: boolean
    notLaunched?: boolean | null
    enrolled: boolean
    programImage?: string | null
    name: string
    recommendationRanking?: number | null
    whoItsFor?: string | null
    slug: string
    byTheEnd?: string | null
    synopsis?: string | null
    categoryNames?: Array<string> | null
    canEnroll: boolean
    launchAt?: string | null
    testimonials: Array<{
      __typename: 'Testimonial'
      id: string
      copy?: string | null
      name?: string | null
      role?: string | null
      thumbnailUrl?: string | null
    }>
    cohort?: {
      __typename: 'Cohort'
      id: string
      endsAt?: string | null
      startsAt?: string | null
      durationInWeeks?: number | null
      weekCount: number
      eventTimes?: Array<{
        __typename: 'CohortEventTimes'
        firstStartDate: string
        firstEndDate?: string | null
        count: number
      }> | null
      hosts?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      programCollaborators?: Array<{
        __typename: 'Host'
        id: string
        name?: string | null
        title?: string | null
        bio?: string | null
        thumbnailUrl: string
        previousCompanies?: string | null
      }> | null
      scheduledWeeks: Array<{
        __typename: 'ScheduledWeek'
        id: string
        core?: boolean | null
        description?: string | null
        title?: string | null
        bonus?: boolean | null
        postCohort: boolean
        startsAt?: string | null
        endsAt?: string | null
        numLessons?: number | null
        numModules?: number | null
        events: Array<{
          __typename: 'Event'
          startsAtUtc: string
          endsAtUtc: string
          kind: string
        }>
      }>
    } | null
  }>
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      id: string
      isCanceled?: boolean | null
      expiresAt: string
    } | null
  } | null
}

export type PreviousUserCohortFragment = {
  __typename: 'UserCohort'
  id: string
  programId: string
  programName: string
  season: string
  canBeUpdated: boolean
  canViewCertificate: boolean
  snapshotable: boolean
  cohortCompletionCertificate?: {
    __typename: 'CohortCertificate'
    id: string
    certificateUrl?: string | null
  } | null
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
}

export type CurrentUserCohortFragment = {
  __typename: 'UserCohort'
  id: string
  programId: string
  programName: string
  season: string
  seasonId: string
  canBeUpdated: boolean
  canViewCertificate: boolean
  cohortCompletionCertificate?: {
    __typename: 'CohortCertificate'
    id: string
    certificateUrl?: string | null
  } | null
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    token: string
    released: boolean
  } | null
  cohort: {
    __typename: 'Cohort'
    id: string
    postCohort: boolean
    eventTimes?: Array<{
      __typename: 'CohortEventTimes'
      count: number
      firstStartDate: string
    }> | null
  }
}

export type UserCohortHistoryQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserCohortHistoryQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
    is: { __typename: 'UserIs'; member: boolean; staff: boolean }
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        course: { __typename: 'Course'; title: string; slug: string }
        courseSession: { __typename: 'CourseSession'; startsAt: string }
      }> | null
    }
    cohorts?: {
      __typename: 'UserCohorts'
      previous: Array<{
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
        season: string
        canBeUpdated: boolean
        canViewCertificate: boolean
        snapshotable: boolean
        cohortCompletionCertificate?: {
          __typename: 'CohortCertificate'
          id: string
          certificateUrl?: string | null
        } | null
        snapshot?: {
          __typename: 'Snapshot'
          id: string
          token: string
          released: boolean
        } | null
      }>
      current: Array<{
        __typename: 'UserCohort'
        id: string
        programId: string
        programName: string
        season: string
        seasonId: string
        canBeUpdated: boolean
        canViewCertificate: boolean
        cohortCompletionCertificate?: {
          __typename: 'CohortCertificate'
          id: string
          certificateUrl?: string | null
        } | null
        snapshot?: {
          __typename: 'Snapshot'
          id: string
          token: string
          released: boolean
        } | null
        cohort: {
          __typename: 'Cohort'
          id: string
          postCohort: boolean
          eventTimes?: Array<{
            __typename: 'CohortEventTimes'
            count: number
            firstStartDate: string
          }> | null
        }
      }>
    } | null
    recommendedPrograms: Array<{
      __typename: 'CmsProgram'
      id: string
      iconImageUrl?: string | null
      heroImageUrl?: string | null
      launched: boolean
      notLaunched?: boolean | null
      enrolled: boolean
      programImage?: string | null
      name: string
      recommendationRanking?: number | null
      whoItsFor?: string | null
      slug: string
      byTheEnd?: string | null
      synopsis?: string | null
      categoryNames?: Array<string> | null
      canEnroll: boolean
      launchAt?: string | null
      testimonials: Array<{
        __typename: 'Testimonial'
        id: string
        copy?: string | null
        name?: string | null
        role?: string | null
        thumbnailUrl?: string | null
      }>
      cohort?: {
        __typename: 'Cohort'
        id: string
        endsAt?: string | null
        startsAt?: string | null
        durationInWeeks?: number | null
        weekCount: number
        eventTimes?: Array<{
          __typename: 'CohortEventTimes'
          firstStartDate: string
          firstEndDate?: string | null
          count: number
        }> | null
        hosts?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          title?: string | null
          bio?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
        }> | null
        programCollaborators?: Array<{
          __typename: 'Host'
          id: string
          name?: string | null
          title?: string | null
          bio?: string | null
          thumbnailUrl: string
          previousCompanies?: string | null
        }> | null
        scheduledWeeks: Array<{
          __typename: 'ScheduledWeek'
          id: string
          core?: boolean | null
          description?: string | null
          title?: string | null
          bonus?: boolean | null
          postCohort: boolean
          startsAt?: string | null
          endsAt?: string | null
          numLessons?: number | null
          numModules?: number | null
          events: Array<{
            __typename: 'Event'
            startsAtUtc: string
            endsAtUtc: string
            kind: string
          }>
        }>
      } | null
    }>
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        isCanceled?: boolean | null
        expiresAt: string
      } | null
    } | null
  } | null
  upcomingSeason?: {
    __typename: 'Season'
    id: string
    startsAt?: string | null
    endsAt?: string | null
    enrollmentsCloseAt?: string | null
    faqs?: Array<{
      __typename: 'Faq'
      seasonId: string
      question: string
      answer: string
      questionNumber: number
    }> | null
  } | null
  enrollmentSeason?: {
    __typename: 'Season'
    id: string
    startsAt?: string | null
    endsAt?: string | null
    prettyNameSeasonFirst: string
    showCohortViewerAt?: string | null
    faqs?: Array<{
      __typename: 'Faq'
      seasonId: string
      question: string
      answer: string
      questionNumber: number
    }> | null
  } | null
  lastActiveSeason?: { __typename: 'Season'; enrollmentsCloseAt?: string | null } | null
}

export type UserCohortUsersCountQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type UserCohortUsersCountQuery = {
  __typename: 'Query'
  userCohortUsersCount?: number | null
}

export type UserCohortUsersQueryVariables = Exact<{
  cmsSectionId?: InputMaybe<Scalars['ID']>
  userId?: InputMaybe<Scalars['ID']>
  limit?: InputMaybe<Scalars['Int']>
  random?: InputMaybe<Scalars['Boolean']>
  onlyWithAvatar?: InputMaybe<Scalars['Boolean']>
  includeCurrentUser?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
}>

export type UserCohortUsersQuery = {
  __typename: 'Query'
  userCohortUsers?: Array<{
    __typename: 'User'
    id: string
    profile: { __typename: 'UserProfile'; avatarUrl: string }
  }> | null
}

export type CompletedModuleUsersCountQueryVariables = Exact<{
  cmsModuleId?: InputMaybe<Scalars['ID']>
}>

export type CompletedModuleUsersCountQuery = {
  __typename: 'Query'
  completedModuleUsersCount?: number | null
}

export type CourseSnapshotsForUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type CourseSnapshotsForUserQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        isEnrolled: boolean
        courseId: string
        course: { __typename: 'Course'; id: string; title: string; slug: string }
        courseSession: {
          __typename: 'CourseSession'
          id: string
          startsAt: string
          endsAt: string
        }
        courseEnrollmentSnapshot?: {
          __typename: 'CourseEnrollmentSnapshot'
          id: string
          linkedinShareUrl?: string | null
          twitterShareUrl?: string | null
          imageUrl?: string | null
        } | null
        courseCompletionSnapshot?: {
          __typename: 'CourseCompletionSnapshot'
          id: string
          linkedinShareUrl?: string | null
          twitterShareUrl?: string | null
          imageUrl?: string | null
        } | null
      }> | null
    }
  } | null
}

export type UserCourseSnapshotPartsFragment = {
  __typename: 'UserCourse'
  id: string
  isEnrolled: boolean
  courseId: string
  course: { __typename: 'Course'; id: string; title: string; slug: string }
  courseSession: {
    __typename: 'CourseSession'
    id: string
    startsAt: string
    endsAt: string
  }
  courseEnrollmentSnapshot?: {
    __typename: 'CourseEnrollmentSnapshot'
    id: string
    linkedinShareUrl?: string | null
    twitterShareUrl?: string | null
    imageUrl?: string | null
  } | null
  courseCompletionSnapshot?: {
    __typename: 'CourseCompletionSnapshot'
    id: string
    linkedinShareUrl?: string | null
    twitterShareUrl?: string | null
    imageUrl?: string | null
  } | null
}

export type UserCoursePartsFragment = {
  __typename: 'UserCourse'
  id: string
  isEnrolled: boolean
  courseId: string
  paymentType?: string | null
  shadow?: boolean | null
  stripeInvoiceId?: string | null
  course: { __typename: 'Course'; id: string; title: string; slug: string }
  courseSession: { __typename: 'CourseSession'; id: string; startsAt: string }
  cohortCredit?: { __typename: 'CohortCredit'; id: string } | null
}

export type CourseSessionPartsFragment = {
  __typename: 'CourseSession'
  id: string
  courseSlug: string
  courseTitle: string
  maxEnrollmentUnlimited?: number | null
  maxEnrollmentPaid?: number | null
  maxEnrollmentTotal?: number | null
  numEnrolledUnlimited?: number | null
  numEnrolledPaid?: number | null
  numEnrolledTotal?: number | null
  startsAt: string
}

export type CourseSessionIsFirstPilotPartsFragment = {
  __typename: 'CourseSession'
  id: string
  startsAt: string
}

export type UserCoursesForUserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserCoursesForUserQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    hasUseableCohortCredits: boolean
    userCourses: {
      __typename: 'UserCourses'
      all?: Array<{
        __typename: 'UserCourse'
        id: string
        isEnrolled: boolean
        courseId: string
        paymentType?: string | null
        shadow?: boolean | null
        stripeInvoiceId?: string | null
        course: { __typename: 'Course'; id: string; title: string; slug: string }
        courseSession: { __typename: 'CourseSession'; id: string; startsAt: string }
        cohortCredit?: { __typename: 'CohortCredit'; id: string } | null
      }> | null
    }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: { __typename: 'Subscription'; id: string; planName: PlanName } | null
    } | null
  } | null
  activeCourseSessions: Array<{
    __typename: 'CourseSession'
    id: string
    courseSlug: string
    courseTitle: string
    maxEnrollmentUnlimited?: number | null
    maxEnrollmentPaid?: number | null
    maxEnrollmentTotal?: number | null
    numEnrolledUnlimited?: number | null
    numEnrolledPaid?: number | null
    numEnrolledTotal?: number | null
    startsAt: string
  }>
}

export type UserEmailsQueryVariables = Exact<{ [key: string]: never }>

export type UserEmailsQuery = {
  __typename: 'Query'
  userEmails: Array<{
    __typename: 'UserEmail'
    id: string
    confirmedByUser: boolean
    isPrimaryEmail: boolean
    email: string
  }>
}

export type UserGroupMembershipQueryVariables = Exact<{ [key: string]: never }>

export type UserGroupMembershipQuery = {
  __typename: 'Query'
  groups: {
    __typename: 'GroupFeed'
    groups: Array<{ __typename: 'Group'; id: string; userIsMember: boolean }>
  }
}

export type OrgSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  orgId?: string | null
  isTeam: boolean
  numSeats: number
  teamName?: string | null
  user: { __typename: 'User'; id: string; email: string; fullName?: string | null }
}

export type UserOrgFragment = {
  __typename: 'Org'
  id: string
  subscriptions: Array<{
    __typename: 'Subscription'
    id: string
    orgId?: string | null
    isTeam: boolean
    numSeats: number
    teamName?: string | null
    user: { __typename: 'User'; id: string; email: string; fullName?: string | null }
  }>
}

export type UserOrgQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserOrgQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        org?: {
          __typename: 'Org'
          id: string
          orgAdmins?: Array<{
            __typename: 'OrgAdmin'
            id: string
            status: string
            user: {
              __typename: 'User'
              id: string
              email: string
              fullName?: string | null
            }
          }> | null
          subscriptions: Array<{
            __typename: 'Subscription'
            id: string
            orgId?: string | null
            isTeam: boolean
            numSeats: number
            teamName?: string | null
            user: {
              __typename: 'User'
              id: string
              email: string
              fullName?: string | null
            }
          }>
        } | null
      } | null
    } | null
  } | null
}

export type UserPaymentHistoryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserPaymentHistoryQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    accessPolicyKind: string
    id: string
    pastPayments: Array<{
      __typename: 'PastPayment'
      id: string
      createdAt: string
      description: string
      invoiceId: string
      payerId: string
      paymentType: string
      status: string
      paidAt?: string | null
    }>
    openInvoices: Array<{
      __typename: 'StripeInvoice'
      id: string
      createdAt: string
      description: string
      dueDate?: string | null
      hostedInvoiceUrl?: string | null
      item: string
      status?: StripeInvoiceStatus | null
    }>
  } | null
}

export type PastPaymentFieldsFragment = {
  __typename: 'PastPayment'
  id: string
  createdAt: string
  description: string
  invoiceId: string
  payerId: string
  paymentType: string
  status: string
  paidAt?: string | null
}

export type OpenInvoiceFieldsFragment = {
  __typename: 'StripeInvoice'
  id: string
  createdAt: string
  description: string
  dueDate?: string | null
  hostedInvoiceUrl?: string | null
  item: string
  status?: StripeInvoiceStatus | null
}

export type UserPaymentMethodsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserPaymentMethodsQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    accessPolicyKind: string
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        userIsOwner: boolean
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        stripeCollectionMethod?: StripeCollectionMethod | null
      } | null
      lastExpired?: {
        __typename: 'Subscription'
        id: string
        userIsOwner: boolean
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        stripeCollectionMethod?: StripeCollectionMethod | null
      } | null
    } | null
  } | null
  userPaymentMethods: Array<{
    __typename: 'StripePaymentMethod'
    id: string
    type: string
    defaultPaymentMethod?: boolean | null
    card?: {
      __typename: 'StripePaymentMethodCard'
      brand: string
      last4: string
      expYear: number
      expMonth: number
      status: string
    } | null
  }>
  userPaymentSources?: Array<
    | {
        __typename: 'StripeBankAccount'
        id: string
        accountHolderName: string
        accountHolderType: string
        bankName: string
        country: string
        currency?: string | null
        fingerprint: string
        last4: string
        routingNumber: string
        status: string
        defaultSource?: boolean | null
      }
    | {
        __typename: 'StripeSource'
        id: string
        type: string
        status: string
        currency?: string | null
        defaultSource?: boolean | null
        achCreditTransfer?: {
          __typename: 'StripeAchCreditTransfer'
          accountNumber: string
          routingNumber: string
          fingerprint: string
          bankName: string
          swiftCode: string
        } | null
      }
  > | null
}

export type CurrentUserPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserPaymentMethodsQuery = {
  __typename: 'Query'
  userPaymentMethods: Array<{
    __typename: 'StripePaymentMethod'
    id: string
    type: string
    defaultPaymentMethod?: boolean | null
    card?: {
      __typename: 'StripePaymentMethodCard'
      brand: string
      last4: string
      expYear: number
      expMonth: number
      status: string
    } | null
  }>
}

export type PaymentMethodFragment = {
  __typename: 'StripePaymentMethod'
  id: string
  type: string
  defaultPaymentMethod?: boolean | null
  card?: {
    __typename: 'StripePaymentMethodCard'
    brand: string
    last4: string
    expYear: number
    expMonth: number
    status: string
  } | null
}

export type StripeBankAccountPartsFragment = {
  __typename: 'StripeBankAccount'
  id: string
  accountHolderName: string
  accountHolderType: string
  bankName: string
  country: string
  currency?: string | null
  fingerprint: string
  last4: string
  routingNumber: string
  status: string
  defaultSource?: boolean | null
}

export type StripeSourcePartsFragment = {
  __typename: 'StripeSource'
  id: string
  type: string
  status: string
  currency?: string | null
  defaultSource?: boolean | null
  achCreditTransfer?: {
    __typename: 'StripeAchCreditTransfer'
    accountNumber: string
    routingNumber: string
    fingerprint: string
    bankName: string
    swiftCode: string
  } | null
}

export type UserRecentlyReviewedContentQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserRecentlyReviewedContentQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    recentlyReviewedContent: Array<{
      __typename: 'Content'
      accessLevel?: string | null
      contentBookmarkId?: string | null
      contentIsInMySavedItems?: boolean | null
      showNewBadge?: boolean | null
      cmsSection: {
        __typename: 'CmsSection'
        id: string
        slug: string
        conceptIcon?: string | null
        contentType?: CmsSectionContentType | null
        contentTypeIcon?: string | null
        cmsModuleId: string
        cmsProgramId: string
        estimatedTime: number
        href: string
        heroImageUrl?: string | null
        name: string
        numBookmarks?: number | null
        numPhases?: number | null
        showNewBadge: boolean
        shortDescription?: string | null
        contentBookmarkId?: string | null
        contentIsInMySavedItems?: boolean | null
        unlimitedAccess: boolean
        lessonNum?: number | null
        accessLevel?: string | null
        previewable: boolean
        lastViewedAt?: string | null
        contentSubtype?: string | null
        cmsProgram: {
          __typename: 'CmsProgram'
          id: string
          programImage?: string | null
          notLaunched?: boolean | null
          name: string
          enrolled: boolean
          slug: string
          collaborators: Array<{
            __typename: 'Host'
            id: string
            name?: string | null
            thumbnailUrl: string
          }>
        }
        cmsModule?: { __typename: 'CmsModule'; id: string; name: string } | null
        parent?: { __typename: 'CmsSection'; id: string; slug: string } | null
      }
    }>
  } | null
}

export type UserSubscriptionsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserSubscriptionsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    canSubscribeWithoutTrial: boolean
    pricing: { __typename: 'UserPricing'; term: string }
    contact: { __typename: 'UserContact'; allEmails: Array<string>; primaryEmail: string }
    can: { __typename: 'UserCan'; pay?: boolean | null }
    is: {
      __typename: 'UserIs'
      member: boolean
      paidMember: boolean
      alumni: boolean
      badgeholder: boolean
      staff: boolean
      enrolledInLiveProgram: boolean
    }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        daysLeft: number
        userIsOwner: boolean
        numSeats: number
        expiresAt: string
        isPaidMonthly: boolean
        termEndsAt?: string | null
        isExpired?: boolean | null
        isDunning: boolean
        isCanceled?: boolean | null
        status?: string | null
        isPartnerSubscription?: boolean | null
        stripeSubscriptionId?: string | null
        expiringSeatCount: number
        purchaser?: {
          __typename: 'User'
          id: string
          profile: { __typename: 'UserProfile'; fullName?: string | null }
        } | null
        seats: Array<{
          __typename: 'Seat'
          id: string
          scheduledForDeletionDate?: string | null
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            email: string
            status: string
            role?: string | null
            createdAt: string
          } | null
        }>
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          id: string
          status?: string | null
          paymentFailed: boolean
          currentPeriodEnd?: number | null
          cancelAt?: string | null
          cancelAtPeriodEnd?: boolean | null
          plan?: {
            __typename: 'StripeSubscriptionPlan'
            id: string
            active?: boolean | null
          } | null
        } | null
      } | null
      lastExpired?: { __typename: 'Subscription'; id: string; expiresAt: string } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        startsAt?: string | null
        expiresAt: string
        trialLength?: number | null
        daysLeftInTrial?: number | null
        trialType?: string | null
      } | null
    } | null
  } | null
}

export type UserSubscriptionCancelModalQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserSubscriptionCancelModalQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        isDunning: boolean
        expiresAt: string
        numSeatsAffectedByCancellation?: number | null
        isTeam: boolean
      } | null
    } | null
  } | null
}

export type TrialSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  status?: string | null
  startsAt?: string | null
  expiresAt: string
  trialLength?: number | null
  daysLeftInTrial?: number | null
  trialType?: string | null
}

export type StripeSubscriptionFragment = {
  __typename: 'StripeSubscription'
  id: string
  status?: string | null
  paymentFailed: boolean
  currentPeriodEnd?: number | null
  cancelAt?: string | null
  cancelAtPeriodEnd?: boolean | null
  plan?: {
    __typename: 'StripeSubscriptionPlan'
    id: string
    active?: boolean | null
  } | null
}

export type SeatsFragment = {
  __typename: 'Seat'
  id: string
  scheduledForDeletionDate?: string | null
  subscriptionMember?: {
    __typename: 'SubscriptionMember'
    id: string
    userId: string
    email: string
    status: string
    role?: string | null
    createdAt: string
  } | null
}

export type ActiveSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  name?: string | null
  daysLeft: number
  userIsOwner: boolean
  numSeats: number
  expiresAt: string
  isPaidMonthly: boolean
  termEndsAt?: string | null
  isExpired?: boolean | null
  status?: string | null
  isPartnerSubscription?: boolean | null
  stripeSubscriptionId?: string | null
  expiringSeatCount: number
  planName: PlanName
  stripeSubscriptionStatus?: StripeSubscriptionStatus | null
  manualAssign?: boolean | null
  seats: Array<{
    __typename: 'Seat'
    id: string
    scheduledForDeletionDate?: string | null
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      userId: string
      email: string
      status: string
      role?: string | null
      createdAt: string
    } | null
  }>
  stripeSubscription?: {
    __typename: 'StripeSubscription'
    id: string
    status?: string | null
    paymentFailed: boolean
    currentPeriodEnd?: number | null
    cancelAt?: string | null
    cancelAtPeriodEnd?: boolean | null
    plan?: {
      __typename: 'StripeSubscriptionPlan'
      id: string
      active?: boolean | null
    } | null
  } | null
}

export type UserSubscriptionFragment = {
  __typename: 'UserSubscriptions'
  active?: {
    __typename: 'Subscription'
    id: string
    name?: string | null
    daysLeft: number
    userIsOwner: boolean
    numSeats: number
    expiresAt: string
    isPaidMonthly: boolean
    termEndsAt?: string | null
    isExpired?: boolean | null
    status?: string | null
    isPartnerSubscription?: boolean | null
    stripeSubscriptionId?: string | null
    expiringSeatCount: number
    planName: PlanName
    stripeSubscriptionStatus?: StripeSubscriptionStatus | null
    manualAssign?: boolean | null
    seats: Array<{
      __typename: 'Seat'
      id: string
      scheduledForDeletionDate?: string | null
      subscriptionMember?: {
        __typename: 'SubscriptionMember'
        id: string
        userId: string
        email: string
        status: string
        role?: string | null
        createdAt: string
      } | null
    }>
    stripeSubscription?: {
      __typename: 'StripeSubscription'
      id: string
      status?: string | null
      paymentFailed: boolean
      currentPeriodEnd?: number | null
      cancelAt?: string | null
      cancelAtPeriodEnd?: boolean | null
      plan?: {
        __typename: 'StripeSubscriptionPlan'
        id: string
        active?: boolean | null
      } | null
    } | null
  } | null
  lastExpired?: { __typename: 'Subscription'; id: string; expiresAt: string } | null
  trial?: {
    __typename: 'Subscription'
    id: string
    status?: string | null
    startsAt?: string | null
    expiresAt: string
    trialLength?: number | null
    daysLeftInTrial?: number | null
    trialType?: string | null
  } | null
}

export type PricingFragment = { __typename: 'UserPricing'; term: string }

export type UserSubscriptionModalQueryVariables = Exact<{ [key: string]: never }>

export type UserSubscriptionModalQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    email: string
    can: { __typename: 'UserCan'; pay?: boolean | null }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        name?: string | null
        daysLeft: number
        userIsOwner: boolean
        numSeats: number
        expiresAt: string
        isPaidMonthly: boolean
        termEndsAt?: string | null
        isExpired?: boolean | null
        status?: string | null
        isPartnerSubscription?: boolean | null
        stripeSubscriptionId?: string | null
        expiringSeatCount: number
        planName: PlanName
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        manualAssign?: boolean | null
        seats: Array<{
          __typename: 'Seat'
          id: string
          scheduledForDeletionDate?: string | null
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            email: string
            status: string
            role?: string | null
            createdAt: string
          } | null
        }>
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          id: string
          status?: string | null
          paymentFailed: boolean
          currentPeriodEnd?: number | null
          cancelAt?: string | null
          cancelAtPeriodEnd?: boolean | null
          plan?: {
            __typename: 'StripeSubscriptionPlan'
            id: string
            active?: boolean | null
          } | null
        } | null
      } | null
      lastExpired?: { __typename: 'Subscription'; id: string; expiresAt: string } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        startsAt?: string | null
        expiresAt: string
        trialLength?: number | null
        daysLeftInTrial?: number | null
        trialType?: string | null
      } | null
    } | null
    pricing: { __typename: 'UserPricing'; term: string }
  } | null
}

export type UserSubscriptionPlanDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserSubscriptionPlanDetailsQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    accessPolicyKind: string
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        isTeam: boolean
        numSeats: number
        isExpired?: boolean | null
        userIsOwner: boolean
        hasUnlimitedCohortAccess?: boolean | null
      } | null
      lastExpired?: {
        __typename: 'Subscription'
        id: string
        isTeam: boolean
        numSeats: number
        userIsOwner: boolean
      } | null
    } | null
  } | null
}

export type UserSubscriptionReactivationQueryVariables = Exact<{ [key: string]: never }>

export type UserSubscriptionReactivationQuery = {
  __typename: 'Query'
  currentUser?: {
    __typename: 'User'
    id: string
    paymentMethods: Array<{ __typename: 'StripePaymentMethod'; id: string }>
    stripeCustomer?: { __typename: 'StripeCustomer'; id: string; balance: number } | null
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        userIsOwner: boolean
        daysLeft: number
        expiresAt: string
        isCanceled?: boolean | null
        isPaidMonthly: boolean
        isTeam: boolean
        numSeats: number
        renewSeatCount: number
        hasUnlimitedCohortAccess?: boolean | null
      } | null
    } | null
  } | null
}

export type UserSubscriptionSummaryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserSubscriptionSummaryQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    accessPolicyKind: string
    id: string
    canSubscribeWithoutTrial: boolean
    can: {
      __typename: 'UserCan'
      pay?: boolean | null
      viewSubscriptionSummary?: boolean | null
    }
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        daysLeft: number
        userIsOwner: boolean
        numSeats: number
        expiresAt: string
        isPaidMonthly: boolean
        termEndsAt?: string | null
        isExpired?: boolean | null
        status?: string | null
        isPartnerSubscription?: boolean | null
        stripeSubscriptionId?: string | null
        expiringSeatCount: number
        seats: Array<{
          __typename: 'Seat'
          id: string
          scheduledForDeletionDate?: string | null
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            email: string
            status: string
            role?: string | null
            createdAt: string
          } | null
        }>
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          id: string
          status?: string | null
          paymentFailed: boolean
          currentPeriodEnd?: number | null
          cancelAt?: string | null
          cancelAtPeriodEnd?: boolean | null
          plan?: {
            __typename: 'StripeSubscriptionPlan'
            id: string
            active?: boolean | null
          } | null
        } | null
      } | null
      lastExpired?: { __typename: 'Subscription'; id: string; expiresAt: string } | null
      trial?: {
        __typename: 'Subscription'
        id: string
        status?: string | null
        startsAt?: string | null
        expiresAt: string
        trialLength?: number | null
        daysLeftInTrial?: number | null
        trialType?: string | null
      } | null
    } | null
  } | null
}

export type StripeSubscriptionFieldsFragment = {
  __typename: 'StripeSubscription'
  id: string
  status?: string | null
  paymentFailed: boolean
  currentPeriodEnd?: number | null
  cancelAt?: string | null
  cancelAtPeriodEnd?: boolean | null
  plan?: {
    __typename: 'StripeSubscriptionPlan'
    id: string
    active?: boolean | null
  } | null
}

export type ActiveSubscriptionFieldsFragment = {
  __typename: 'Subscription'
  id: string
  daysLeft: number
  userIsOwner: boolean
  numSeats: number
  expiresAt: string
  isPaidMonthly: boolean
  termEndsAt?: string | null
  isExpired?: boolean | null
  status?: string | null
  isPartnerSubscription?: boolean | null
  stripeSubscriptionId?: string | null
  expiringSeatCount: number
  seats: Array<{
    __typename: 'Seat'
    id: string
    scheduledForDeletionDate?: string | null
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      userId: string
      email: string
      status: string
      role?: string | null
      createdAt: string
    } | null
  }>
  stripeSubscription?: {
    __typename: 'StripeSubscription'
    id: string
    status?: string | null
    paymentFailed: boolean
    currentPeriodEnd?: number | null
    cancelAt?: string | null
    cancelAtPeriodEnd?: boolean | null
    plan?: {
      __typename: 'StripeSubscriptionPlan'
      id: string
      active?: boolean | null
    } | null
  } | null
}

export type SubscriptionsFieldsFragment = {
  __typename: 'UserSubscriptions'
  active?: {
    __typename: 'Subscription'
    id: string
    daysLeft: number
    userIsOwner: boolean
    numSeats: number
    expiresAt: string
    isPaidMonthly: boolean
    termEndsAt?: string | null
    isExpired?: boolean | null
    status?: string | null
    isPartnerSubscription?: boolean | null
    stripeSubscriptionId?: string | null
    expiringSeatCount: number
    seats: Array<{
      __typename: 'Seat'
      id: string
      scheduledForDeletionDate?: string | null
      subscriptionMember?: {
        __typename: 'SubscriptionMember'
        id: string
        userId: string
        email: string
        status: string
        role?: string | null
        createdAt: string
      } | null
    }>
    stripeSubscription?: {
      __typename: 'StripeSubscription'
      id: string
      status?: string | null
      paymentFailed: boolean
      currentPeriodEnd?: number | null
      cancelAt?: string | null
      cancelAtPeriodEnd?: boolean | null
      plan?: {
        __typename: 'StripeSubscriptionPlan'
        id: string
        active?: boolean | null
      } | null
    } | null
  } | null
  lastExpired?: { __typename: 'Subscription'; id: string; expiresAt: string } | null
  trial?: {
    __typename: 'Subscription'
    id: string
    status?: string | null
    startsAt?: string | null
    expiresAt: string
    trialLength?: number | null
    daysLeftInTrial?: number | null
    trialType?: string | null
  } | null
}

export type SubscriptionSeatFieldsFragment = {
  __typename: 'Seat'
  id: string
  scheduledForDeletionDate?: string | null
  subscriptionMember?: {
    __typename: 'SubscriptionMember'
    id: string
    userId: string
    email: string
    status: string
    role?: string | null
    createdAt: string
  } | null
}

export type SubscriptionTeamFieldsFragment = {
  __typename: 'Subscription'
  id: string
  domainName?: string | null
  isDiscoverable?: boolean | null
  isTeam: boolean
  logoUrl?: string | null
  name?: string | null
  shareableLinkUrl: string
  userIsOwner: boolean
}

export type SubscriptionCardFieldsFragment = {
  __typename: 'Subscription'
  id: string
  trialEndDate?: string | null
  dunningDaysLeft?: number | null
  planName: PlanName
  stripeCollectionMethod?: StripeCollectionMethod | null
  stripeSubscriptionStatus?: StripeSubscriptionStatus | null
  daysLeft: number
  expiresAt: string
  isCanceled?: boolean | null
  isPaidMonthly: boolean
  isTeam: boolean
  numSeats: number
  renewSeatCount: number
  userIsOwner: boolean
  hasUnlimitedCohortAccess?: boolean | null
  cohortCredits: {
    __typename: 'CohortCredits'
    assignable: Array<{ __typename: 'CohortCredit'; id: string }>
    unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
    useable: Array<{ __typename: 'CohortCredit'; id: string }>
  }
  stripeUpcomingInvoice?: {
    __typename: 'StripeUpcomingInvoice'
    id: string
    amountDue: number
    numSeats: number
    numCohortPasses: number
    planName: PlanName
  } | null
  user: { __typename: 'User'; id: string; email: string }
}

export type UserPermissionFieldsFragment = {
  __typename: 'User'
  can: {
    __typename: 'UserCan'
    editEmailPreferences?: boolean | null
    viewBilling?: boolean | null
    viewSubscriptionSummary?: boolean | null
    manageMyTeam?: boolean | null
    pay?: boolean | null
  }
}

export type UserSubscriptionWithTeamSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  termEndsAt?: string | null
  trialEndDate?: string | null
  dunningDaysLeft?: number | null
  isDunning: boolean
  isCanceled?: boolean | null
  expiringSeatCount: number
  numSeats: number
  numSeatsAvailable: number
  planName: PlanName
  domainName?: string | null
  isDiscoverable?: boolean | null
  isTeam: boolean
  logoUrl?: string | null
  name?: string | null
  shareableLinkUrl: string
  userIsOwner: boolean
  stripeCollectionMethod?: StripeCollectionMethod | null
  stripeSubscriptionStatus?: StripeSubscriptionStatus | null
  daysLeft: number
  expiresAt: string
  isPaidMonthly: boolean
  renewSeatCount: number
  hasUnlimitedCohortAccess?: boolean | null
  cohortCredits: {
    __typename: 'CohortCredits'
    useable: Array<{ __typename: 'CohortCredit'; id: string }>
    assignable: Array<{ __typename: 'CohortCredit'; id: string }>
    unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
  }
  stripeSubscription?: {
    __typename: 'StripeSubscription'
    id: string
    cancelAtPeriodEnd?: boolean | null
  } | null
  stripeUpcomingInvoice?: {
    __typename: 'StripeUpcomingInvoice'
    id: string
    amountDue: number
    numSeats: number
    numCohortPasses: number
    planName: PlanName
    subtotal: number
  } | null
  seats: Array<{
    __typename: 'Seat'
    id: string
    scheduledForDeletionDate?: string | null
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      userId: string
      status: string
      createdAt: string
      email: string
      role?: string | null
    } | null
  }>
  user: { __typename: 'User'; id: string; email: string }
}

export type UserSubscriptionWithTeamFieldsFragment = {
  __typename: 'User'
  accessPolicyKind: string
  id: string
  fullName?: string | null
  uniqueEmailDomains: Array<string>
  is: { __typename: 'UserIs'; trialing: boolean }
  openInvoices: Array<{
    __typename: 'StripeInvoice'
    id: string
    status?: StripeInvoiceStatus | null
  }>
  contact: { __typename: 'UserContact'; primaryEmail: string; allEmails: Array<string> }
  paymentMethods: Array<{
    __typename: 'StripePaymentMethod'
    id: string
    type: string
    defaultPaymentMethod?: boolean | null
    card?: {
      __typename: 'StripePaymentMethodCard'
      brand: string
      last4: string
      expYear: number
      expMonth: number
      status: string
    } | null
  }>
  stripeCustomer?: { __typename: 'StripeCustomer'; id: string; balance: number } | null
  subscriptions?: {
    __typename: 'UserSubscriptions'
    active?: {
      __typename: 'Subscription'
      id: string
      termEndsAt?: string | null
      trialEndDate?: string | null
      dunningDaysLeft?: number | null
      isDunning: boolean
      isCanceled?: boolean | null
      expiringSeatCount: number
      numSeats: number
      numSeatsAvailable: number
      planName: PlanName
      domainName?: string | null
      isDiscoverable?: boolean | null
      isTeam: boolean
      logoUrl?: string | null
      name?: string | null
      shareableLinkUrl: string
      userIsOwner: boolean
      stripeCollectionMethod?: StripeCollectionMethod | null
      stripeSubscriptionStatus?: StripeSubscriptionStatus | null
      daysLeft: number
      expiresAt: string
      isPaidMonthly: boolean
      renewSeatCount: number
      hasUnlimitedCohortAccess?: boolean | null
      cohortCredits: {
        __typename: 'CohortCredits'
        useable: Array<{ __typename: 'CohortCredit'; id: string }>
        assignable: Array<{ __typename: 'CohortCredit'; id: string }>
        unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
      }
      stripeSubscription?: {
        __typename: 'StripeSubscription'
        id: string
        cancelAtPeriodEnd?: boolean | null
      } | null
      stripeUpcomingInvoice?: {
        __typename: 'StripeUpcomingInvoice'
        id: string
        amountDue: number
        numSeats: number
        numCohortPasses: number
        planName: PlanName
        subtotal: number
      } | null
      seats: Array<{
        __typename: 'Seat'
        id: string
        scheduledForDeletionDate?: string | null
        subscriptionMember?: {
          __typename: 'SubscriptionMember'
          id: string
          userId: string
          status: string
          createdAt: string
          email: string
          role?: string | null
        } | null
      }>
      user: { __typename: 'User'; id: string; email: string }
    } | null
    lastExpired?: { __typename: 'Subscription'; id: string; isTeam: boolean } | null
  } | null
  can: {
    __typename: 'UserCan'
    editEmailPreferences?: boolean | null
    viewBilling?: boolean | null
    viewSubscriptionSummary?: boolean | null
    manageMyTeam?: boolean | null
    pay?: boolean | null
  }
}

export type UserSubscriptionWithTeamQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserSubscriptionWithTeamQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    accessPolicyKind: string
    fullName?: string | null
    uniqueEmailDomains: Array<string>
    is: { __typename: 'UserIs'; trialing: boolean }
    openInvoices: Array<{
      __typename: 'StripeInvoice'
      id: string
      status?: StripeInvoiceStatus | null
    }>
    contact: { __typename: 'UserContact'; primaryEmail: string; allEmails: Array<string> }
    paymentMethods: Array<{
      __typename: 'StripePaymentMethod'
      id: string
      type: string
      defaultPaymentMethod?: boolean | null
      card?: {
        __typename: 'StripePaymentMethodCard'
        brand: string
        last4: string
        expYear: number
        expMonth: number
        status: string
      } | null
    }>
    stripeCustomer?: { __typename: 'StripeCustomer'; id: string; balance: number } | null
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        termEndsAt?: string | null
        trialEndDate?: string | null
        dunningDaysLeft?: number | null
        isDunning: boolean
        isCanceled?: boolean | null
        expiringSeatCount: number
        numSeats: number
        numSeatsAvailable: number
        planName: PlanName
        domainName?: string | null
        isDiscoverable?: boolean | null
        isTeam: boolean
        logoUrl?: string | null
        name?: string | null
        shareableLinkUrl: string
        userIsOwner: boolean
        stripeCollectionMethod?: StripeCollectionMethod | null
        stripeSubscriptionStatus?: StripeSubscriptionStatus | null
        daysLeft: number
        expiresAt: string
        isPaidMonthly: boolean
        renewSeatCount: number
        hasUnlimitedCohortAccess?: boolean | null
        cohortCredits: {
          __typename: 'CohortCredits'
          useable: Array<{ __typename: 'CohortCredit'; id: string }>
          assignable: Array<{ __typename: 'CohortCredit'; id: string }>
          unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
        }
        stripeSubscription?: {
          __typename: 'StripeSubscription'
          id: string
          cancelAtPeriodEnd?: boolean | null
        } | null
        stripeUpcomingInvoice?: {
          __typename: 'StripeUpcomingInvoice'
          id: string
          amountDue: number
          numSeats: number
          numCohortPasses: number
          planName: PlanName
          subtotal: number
        } | null
        seats: Array<{
          __typename: 'Seat'
          id: string
          scheduledForDeletionDate?: string | null
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            status: string
            createdAt: string
            email: string
            role?: string | null
          } | null
        }>
        user: { __typename: 'User'; id: string; email: string }
      } | null
      lastExpired?: { __typename: 'Subscription'; id: string; isTeam: boolean } | null
    } | null
    can: {
      __typename: 'UserCan'
      editEmailPreferences?: boolean | null
      viewBilling?: boolean | null
      viewSubscriptionSummary?: boolean | null
      manageMyTeam?: boolean | null
      pay?: boolean | null
    }
  } | null
}

export type UsersFeedQueryVariables = Exact<{
  cohortSlug?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}>

export type UsersFeedQuery = {
  __typename: 'Query'
  usersFeed?: {
    __typename: 'UsersFeed'
    count?: number | null
    totalCount?: number | null
    users?: Array<{
      __typename: 'User'
      id: string
      profile: {
        __typename: 'UserProfile'
        fullName?: string | null
        role?: string | null
        companyName?: string | null
        avatarUrl: string
      }
    }> | null
  } | null
}

export type CreateRailsSubscriptionMutationVariables = Exact<{
  input: CreateRailsSubscriptionInput
}>

export type CreateRailsSubscriptionMutation = {
  __typename: 'Mutation'
  createRailsSubscription?: {
    __typename: 'CreateRailsSubscriptionPayload'
    success?: boolean | null
    errors?: Array<string> | null
  } | null
}

export type SearchSuggestionsQueryVariables = Exact<{
  query: Scalars['String']
}>

export type SearchSuggestionsQuery = {
  __typename: 'Query'
  searchSuggestions: Array<string>
}

export type SimilaritySearchQueryVariables = Exact<{
  query: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  initiator?: InputMaybe<Scalars['String']>
}>

export type SimilaritySearchQuery = {
  __typename: 'Query'
  similaritySearch: {
    __typename: 'SimilaritySearchResponse'
    suggestions: Array<string>
    id: string
    results: Array<{
      __typename: 'SimilaritySearchContent'
      id: string
      author?: string | null
      companyName?: string | null
      contentType: SimilaritySearchContentType
      highlight: string
      tags: Array<string>
      thumbnail?: string | null
      title: string
      url: string
      breadCrumbs: Array<{
        __typename: 'SimilaritySearchBreadcrumb'
        label: string
        url: string
      }>
    }>
  }
}

export type SimilarityContentFragment = {
  __typename: 'SimilaritySearchContent'
  id: string
  author?: string | null
  companyName?: string | null
  contentType: SimilaritySearchContentType
  highlight: string
  tags: Array<string>
  thumbnail?: string | null
  title: string
  url: string
  breadCrumbs: Array<{
    __typename: 'SimilaritySearchBreadcrumb'
    label: string
    url: string
  }>
}

export type TeamMembersPageSubscriptionSeatFragment = {
  __typename: 'Seat'
  id: string
  isRenewing: boolean
  hasCohortAccess: boolean
  subscriptionMember?: {
    __typename: 'SubscriptionMember'
    id: string
    userId: string
    status: string
    createdAt: string
    email: string
    role?: string | null
    token?: string | null
    user: {
      __typename: 'User'
      id: string
      isCurrentUser: boolean
      fullName?: string | null
      firstName?: string | null
      email: string
      workEmail?: string | null
      enrolledPrograms: Array<{ __typename: 'CmsProgram'; name: string }>
      profile: { __typename: 'UserProfile'; avatarUrl: string }
      is: {
        __typename: 'UserIs'
        enrolledInLiveProgram: boolean
        enrolledInActiveCourse: boolean
      }
      currentEnrolledCourse?: {
        __typename: 'UserCourse'
        id: string
        course: { __typename: 'Course'; id: string; title: string }
      } | null
    }
  } | null
}

export type TeamMembersPageSubscriptionFragment = {
  __typename: 'Subscription'
  id: string
  userIsOwner: boolean
  manualAssign?: boolean | null
  numSeats: number
  hasUnlimitedCohortAccess?: boolean | null
  status?: string | null
  expiresAt: string
  planName: PlanName
  domainName?: string | null
  isProvisionedByScim: boolean
  cohortCredits: {
    __typename: 'CohortCredits'
    unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
    assignable: Array<{ __typename: 'CohortCredit'; id: string }>
    useable: Array<{ __typename: 'CohortCredit'; id: string }>
  }
  seats: Array<{
    __typename: 'Seat'
    id: string
    isRenewing: boolean
    hasCohortAccess: boolean
    subscriptionMember?: {
      __typename: 'SubscriptionMember'
      id: string
      userId: string
      status: string
      createdAt: string
      email: string
      role?: string | null
      token?: string | null
      user: {
        __typename: 'User'
        id: string
        isCurrentUser: boolean
        fullName?: string | null
        firstName?: string | null
        email: string
        workEmail?: string | null
        enrolledPrograms: Array<{ __typename: 'CmsProgram'; name: string }>
        profile: { __typename: 'UserProfile'; avatarUrl: string }
        is: {
          __typename: 'UserIs'
          enrolledInLiveProgram: boolean
          enrolledInActiveCourse: boolean
        }
        currentEnrolledCourse?: {
          __typename: 'UserCourse'
          id: string
          course: { __typename: 'Course'; id: string; title: string }
        } | null
      }
    } | null
  }>
}

export type TeamMembersPageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TeamMembersPageQuery = {
  __typename: 'Query'
  user?: {
    __typename: 'User'
    id: string
    subscriptions?: {
      __typename: 'UserSubscriptions'
      active?: {
        __typename: 'Subscription'
        id: string
        userIsOwner: boolean
        manualAssign?: boolean | null
        numSeats: number
        hasUnlimitedCohortAccess?: boolean | null
        status?: string | null
        expiresAt: string
        planName: PlanName
        domainName?: string | null
        isProvisionedByScim: boolean
        cohortCredits: {
          __typename: 'CohortCredits'
          unexpired: Array<{ __typename: 'CohortCredit'; id: string }>
          assignable: Array<{ __typename: 'CohortCredit'; id: string }>
          useable: Array<{ __typename: 'CohortCredit'; id: string }>
        }
        seats: Array<{
          __typename: 'Seat'
          id: string
          isRenewing: boolean
          hasCohortAccess: boolean
          subscriptionMember?: {
            __typename: 'SubscriptionMember'
            id: string
            userId: string
            status: string
            createdAt: string
            email: string
            role?: string | null
            token?: string | null
            user: {
              __typename: 'User'
              id: string
              isCurrentUser: boolean
              fullName?: string | null
              firstName?: string | null
              email: string
              workEmail?: string | null
              enrolledPrograms: Array<{ __typename: 'CmsProgram'; name: string }>
              profile: { __typename: 'UserProfile'; avatarUrl: string }
              is: {
                __typename: 'UserIs'
                enrolledInLiveProgram: boolean
                enrolledInActiveCourse: boolean
              }
              currentEnrolledCourse?: {
                __typename: 'UserCourse'
                id: string
                course: { __typename: 'Course'; id: string; title: string }
              } | null
            }
          } | null
        }>
      } | null
    } | null
  } | null
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ActivateSubscriptionInput: ActivateSubscriptionInput
  ActivateSubscriptionPayload: ResolverTypeWrapper<ActivateSubscriptionPayload>
  Activity: ResolverTypeWrapper<Activity>
  ActivityAction: ResolverTypeWrapper<ActivityAction>
  ActivityList: ResolverTypeWrapper<ActivityList>
  ActivityListItem: ResolverTypeWrapper<ActivityListItem>
  ActivityNoun: ResolverTypeWrapper<ActivityNoun>
  ActivitySubject: ResolverTypeWrapper<ActivitySubject>
  AddAnnotationReactionInput: AddAnnotationReactionInput
  AddAnnotationReactionPayload: ResolverTypeWrapper<AddAnnotationReactionPayload>
  AddArtifactCommentInput: AddArtifactCommentInput
  AddArtifactCommentPayload: ResolverTypeWrapper<AddArtifactCommentPayload>
  AddArtifactCommentReactionInput: AddArtifactCommentReactionInput
  AddArtifactCommentReactionPayload: ResolverTypeWrapper<AddArtifactCommentReactionPayload>
  AddEmailToUserInput: AddEmailToUserInput
  AddEmailToUserPayload: ResolverTypeWrapper<AddEmailToUserPayload>
  AddReactionInput: AddReactionInput
  AddReactionPayload: ResolverTypeWrapper<AddReactionPayload>
  AddUserCompanyInput: AddUserCompanyInput
  AddUserCompanyPayload: ResolverTypeWrapper<AddUserCompanyPayload>
  AiChat: ResolverTypeWrapper<AiChat>
  AiChatConnection: ResolverTypeWrapper<AiChatConnection>
  AiChatEdge: ResolverTypeWrapper<AiChatEdge>
  AiChatMessage: ResolverTypeWrapper<AiChatMessage>
  AiExtensionApp: ResolverTypeWrapper<AiExtensionApp>
  AiPersonalization: ResolverTypeWrapper<AiPersonalization>
  ApplicablePrograms: ResolverTypeWrapper<ApplicablePrograms>
  ApproveSubscriptionJoinRequestInput: ApproveSubscriptionJoinRequestInput
  ApproveSubscriptionJoinRequestPayload: ResolverTypeWrapper<ApproveSubscriptionJoinRequestPayload>
  Artifact: ResolverTypeWrapper<Artifact>
  ArtifactAnnotation: ResolverTypeWrapper<ArtifactAnnotation>
  ArtifactAnnotationReaction: ResolverTypeWrapper<ArtifactAnnotationReaction>
  ArtifactAnnotationReactionGrouped: ResolverTypeWrapper<ArtifactAnnotationReactionGrouped>
  ArtifactAnnotationVideo: ResolverTypeWrapper<ArtifactAnnotationVideo>
  ArtifactAuthor: ResolverTypeWrapper<ArtifactAuthor>
  ArtifactComment: ResolverTypeWrapper<ArtifactComment>
  ArtifactCommentReaction: ResolverTypeWrapper<ArtifactCommentReaction>
  ArtifactCommentReactionGrouped: ResolverTypeWrapper<ArtifactCommentReactionGrouped>
  ArtifactCommentUser: ResolverTypeWrapper<ArtifactCommentUser>
  ArtifactContribution: ResolverTypeWrapper<ArtifactContribution>
  ArtifactContributor: ResolverTypeWrapper<ArtifactContributor>
  ArtifactFilter: ResolverTypeWrapper<ArtifactFilter>
  ArtifactFilters: ArtifactFilters
  ArtifactSubtopic: ResolverTypeWrapper<ArtifactSubtopic>
  ArtifactTopic: ResolverTypeWrapper<ArtifactTopic>
  ArtifactTopicFilter: ResolverTypeWrapper<ArtifactTopicFilter>
  ArtifactTopicInput: ArtifactTopicInput
  ArtifactUserData: ResolverTypeWrapper<ArtifactUserData>
  ArtifactWithWorkEntry: ResolverTypeWrapper<ArtifactWithWorkEntry>
  ArtifactsForSubtopic: ResolverTypeWrapper<ArtifactsForSubtopic>
  AssignCohortCreditInput: AssignCohortCreditInput
  AssignCohortCreditPayload: ResolverTypeWrapper<AssignCohortCreditPayload>
  AssignSeatInput: AssignSeatInput
  AssignSeatPayload: ResolverTypeWrapper<AssignSeatPayload>
  AttendeesFeed: ResolverTypeWrapper<AttendeesFeed>
  BatchInput: BatchInput
  BatchPayload: ResolverTypeWrapper<BatchPayload>
  BatchRsvpTimeData: ResolverTypeWrapper<BatchRsvpTimeData>
  BillingInformation: ResolverTypeWrapper<BillingInformation>
  BlogIndex: ResolverTypeWrapper<BlogIndex>
  BlogPost: ResolverTypeWrapper<BlogPost>
  BlogPostCategory: ResolverTypeWrapper<BlogPostCategory>
  BlogPostDetails: ResolverTypeWrapper<BlogPostDetails>
  Bookmark: ResolverTypeWrapper<Bookmark>
  BookmarkFilters: BookmarkFilters
  BookmarkFolder: ResolverTypeWrapper<BookmarkFolder>
  BookmarkModulesFilters: BookmarkModulesFilters
  BookmarkRelatedIdentifiers: BookmarkRelatedIdentifiers
  BookmarkType: BookmarkType
  BookmarksFeed: ResolverTypeWrapper<BookmarksFeed>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  CancelInput: CancelInput
  CancelPayload: ResolverTypeWrapper<CancelPayload>
  CaseStudiesIndex: ResolverTypeWrapper<CaseStudiesIndex>
  CaseStudy: ResolverTypeWrapper<CaseStudy>
  CaseStudyContentBlock: ResolverTypeWrapper<CaseStudyContentBlock>
  CaseStudyContributor: ResolverTypeWrapper<CaseStudyContributor>
  CaseStudyDetails: ResolverTypeWrapper<CaseStudyDetails>
  CclArtifact: ResolverTypeWrapper<CclArtifact>
  CclBlogPost: ResolverTypeWrapper<CclBlogPost>
  CclCompany: ResolverTypeWrapper<CclCompany>
  CclCourse: ResolverTypeWrapper<CclCourse>
  CclCourseBlock: ResolverTypeWrapper<CclCourseBlock>
  CclCourseSession: ResolverTypeWrapper<CclCourseSession>
  CclExpert: ResolverTypeWrapper<CclExpert>
  CclGuide: ResolverTypeWrapper<CclGuide>
  CclReview: ResolverTypeWrapper<CclReview>
  CclSessionEvent: ResolverTypeWrapper<CclSessionEvent>
  CclTopic: ResolverTypeWrapper<CclTopic>
  CclVideo: ResolverTypeWrapper<CclVideo>
  Certificates: ResolverTypeWrapper<Certificates>
  ChatQuery: ResolversTypes['Query']
  CheckoutInput: CheckoutInput
  CheckoutPayload: ResolverTypeWrapper<CheckoutPayload>
  CheckoutProrationAdjustment: ResolverTypeWrapper<CheckoutProrationAdjustment>
  ChoosePlanInput: ChoosePlanInput
  ChoosePlanPayload: ResolverTypeWrapper<ChoosePlanPayload>
  ClearbitCompany: ResolverTypeWrapper<ClearbitCompany>
  Clip: ResolverTypeWrapper<Clip>
  CmsContent: ResolverTypeWrapper<CmsContent>
  CmsModule: ResolverTypeWrapper<CmsModule>
  CmsProgram: ResolverTypeWrapper<CmsProgram>
  CmsProgramExpertsInput: CmsProgramExpertsInput
  CmsProgramExpertsPayload: ResolverTypeWrapper<CmsProgramExpertsPayload>
  CmsProgramNotification: ResolverTypeWrapper<CmsProgramNotification>
  CmsSection: ResolverTypeWrapper<CmsSection>
  CmsSectionContentType: CmsSectionContentType
  CmsSectionTag: ResolverTypeWrapper<CmsSectionTag>
  CoachingSession: ResolverTypeWrapper<CoachingSession>
  Cohort: ResolverTypeWrapper<Cohort>
  CohortActivityListItem: ResolverTypeWrapper<CohortActivityListItem>
  CohortActivityListWithMetadata: ResolverTypeWrapper<CohortActivityListWithMetadata>
  CohortBookmarkFolder: ResolverTypeWrapper<CohortBookmarkFolder>
  CohortCertificate: ResolverTypeWrapper<CohortCertificate>
  CohortCredit: ResolverTypeWrapper<CohortCredit>
  CohortCredits: ResolverTypeWrapper<CohortCredits>
  CohortEventTimes: ResolverTypeWrapper<CohortEventTimes>
  CohortPassPriceTier: ResolverTypeWrapper<CohortPassPriceTier>
  CohortPost: ResolverTypeWrapper<CohortPost>
  CohortTeam: ResolverTypeWrapper<CohortTeam>
  Collection: ResolverTypeWrapper<Collection>
  CollectionActivity: ResolverTypeWrapper<CollectionActivity>
  CollectionActivityList: ResolverTypeWrapper<CollectionActivityList>
  CollectionsQuery: ResolversTypes['Query']
  Comment: ResolverTypeWrapper<Comment>
  Company: ResolverTypeWrapper<Company>
  CompleteProductTourInput: CompleteProductTourInput
  CompleteProductTourPayload: ResolverTypeWrapper<CompleteProductTourPayload>
  CompletedFeedback: ResolverTypeWrapper<CompletedFeedback>
  CompletionSnapshot: ResolverTypeWrapper<CompletionSnapshot>
  ConfirmUserMergeInput: ConfirmUserMergeInput
  ConfirmUserMergePayload: ResolverTypeWrapper<ConfirmUserMergePayload>
  Content: ResolverTypeWrapper<Content>
  ContentBlock: ResolverTypeWrapper<ContentBlock>
  ContentBlockCollapsible: ResolverTypeWrapper<ContentBlockCollapsible>
  ContentCategory: ContentCategory
  ContentFeed: ResolverTypeWrapper<ContentFeed>
  ContentFilters: ContentFilters
  ContentTocItem: ResolverTypeWrapper<ContentTocItem>
  ContentViewer: ResolverTypeWrapper<ContentViewer>
  CookieConsent: ResolverTypeWrapper<CookieConsent>
  Course: ResolverTypeWrapper<Course>
  CourseBenefit: ResolverTypeWrapper<CourseBenefit>
  CourseBlock: ResolverTypeWrapper<CourseBlock>
  CourseCertificate: ResolverTypeWrapper<CourseCertificate>
  CourseCompletionSnapshot: ResolverTypeWrapper<CourseCompletionSnapshot>
  CourseConcepts: ResolverTypeWrapper<CourseConcepts>
  CourseDetails: ResolverTypeWrapper<CourseDetails>
  CourseEnrollmentConfirmedInput: CourseEnrollmentConfirmedInput
  CourseEnrollmentConfirmedPayload: ResolverTypeWrapper<CourseEnrollmentConfirmedPayload>
  CourseEnrollmentSnapshot: ResolverTypeWrapper<CourseEnrollmentSnapshot>
  CourseEnrollmentStartedInput: CourseEnrollmentStartedInput
  CourseEnrollmentStartedPayload: ResolverTypeWrapper<CourseEnrollmentStartedPayload>
  CourseEvent: ResolverTypeWrapper<CourseEvent>
  CourseForTopicAndFunction: ResolverTypeWrapper<CourseForTopicAndFunction>
  CourseIndex: ResolverTypeWrapper<CourseIndex>
  CourseIndexContent: ResolverTypeWrapper<CourseIndexContent>
  CourseIndexPage: ResolverTypeWrapper<CourseIndexPage>
  CourseInfoCard: ResolverTypeWrapper<CourseInfoCard>
  CoursePreview: ResolverTypeWrapper<CoursePreview>
  CourseSession: ResolverTypeWrapper<CourseSession>
  CourseSessionDetail: ResolverTypeWrapper<CourseSessionDetail>
  CourseSnapshot: ResolverTypeWrapper<CourseSnapshot>
  CourseTopicCovered: ResolverTypeWrapper<CourseTopicCovered>
  CreateAccountInput: CreateAccountInput
  CreateAccountPayload: ResolverTypeWrapper<CreateAccountPayload>
  CreateBookmarkFolderInput: CreateBookmarkFolderInput
  CreateBookmarkFolderPayload: ResolverTypeWrapper<CreateBookmarkFolderPayload>
  CreateBookmarkInput: CreateBookmarkInput
  CreateBookmarkPayload: ResolverTypeWrapper<CreateBookmarkPayload>
  CreateCohortPostInput: CreateCohortPostInput
  CreateCohortPostPayload: ResolverTypeWrapper<CreateCohortPostPayload>
  CreateCohortReplyInput: CreateCohortReplyInput
  CreateCohortReplyPayload: ResolverTypeWrapper<CreateCohortReplyPayload>
  CreateCourseEnrollmentInput: CreateCourseEnrollmentInput
  CreateCourseEnrollmentPayload: ResolverTypeWrapper<CreateCourseEnrollmentPayload>
  CreateCourseSessionInput: CreateCourseSessionInput
  CreateCourseSessionPayload: ResolverTypeWrapper<CreateCourseSessionPayload>
  CreateCourseSessionSlackChannelInput: CreateCourseSessionSlackChannelInput
  CreateCourseSessionSlackChannelPayload: ResolverTypeWrapper<CreateCourseSessionSlackChannelPayload>
  CreateDraftSubscriptionJoinRequestInput: CreateDraftSubscriptionJoinRequestInput
  CreateDraftSubscriptionJoinRequestPayload: ResolverTypeWrapper<CreateDraftSubscriptionJoinRequestPayload>
  CreateFiledBookmarkInput: CreateFiledBookmarkInput
  CreateFiledBookmarkPayload: ResolverTypeWrapper<CreateFiledBookmarkPayload>
  CreateInput: CreateInput
  CreatePayload: ResolverTypeWrapper<CreatePayload>
  CreateProgramApplicationInput: CreateProgramApplicationInput
  CreateProgramApplicationPayload: ResolverTypeWrapper<CreateProgramApplicationPayload>
  CreateProgramInvitationInput: CreateProgramInvitationInput
  CreateProgramInvitationPayload: ResolverTypeWrapper<CreateProgramInvitationPayload>
  CreateRailsSubscriptionInput: CreateRailsSubscriptionInput
  CreateRailsSubscriptionPayload: ResolverTypeWrapper<CreateRailsSubscriptionPayload>
  CreateRecommendationsInput: CreateRecommendationsInput
  CreateRecommendationsPayload: ResolverTypeWrapper<CreateRecommendationsPayload>
  CreateReplyInput: CreateReplyInput
  CreateReplyPayload: ResolverTypeWrapper<CreateReplyPayload>
  CreateSubscriptionJoinRequestInput: CreateSubscriptionJoinRequestInput
  CreateSubscriptionJoinRequestPayload: ResolverTypeWrapper<CreateSubscriptionJoinRequestPayload>
  CreateTeamPostInput: CreateTeamPostInput
  CreateTeamPostPayload: ResolverTypeWrapper<CreateTeamPostPayload>
  CreateTestUsersInput: CreateTestUsersInput
  CreateTestUsersPayload: ResolverTypeWrapper<CreateTestUsersPayload>
  CreateUserClipActivityInput: CreateUserClipActivityInput
  CreateUserClipActivityPayload: ResolverTypeWrapper<CreateUserClipActivityPayload>
  CreateUserCmsActivityInput: CreateUserCmsActivityInput
  CreateUserCmsActivityPayload: ResolverTypeWrapper<CreateUserCmsActivityPayload>
  CreateUserEventActivityInput: CreateUserEventActivityInput
  CreateUserEventActivityPayload: ResolverTypeWrapper<CreateUserEventActivityPayload>
  Creator: ResolverTypeWrapper<Creator>
  Cta: ResolverTypeWrapper<Cta>
  DeclineSubscriptionJoinRequestInput: DeclineSubscriptionJoinRequestInput
  DeclineSubscriptionJoinRequestPayload: ResolverTypeWrapper<DeclineSubscriptionJoinRequestPayload>
  DeleteBookmarkFolderInput: DeleteBookmarkFolderInput
  DeleteBookmarkFolderPayload: ResolverTypeWrapper<DeleteBookmarkFolderPayload>
  DeleteBookmarkInput: DeleteBookmarkInput
  DeleteBookmarkPayload: ResolverTypeWrapper<DeleteBookmarkPayload>
  DeleteCohortPassInput: DeleteCohortPassInput
  DeleteCohortPassPayload: ResolverTypeWrapper<DeleteCohortPassPayload>
  DeleteCohortPostInput: DeleteCohortPostInput
  DeleteCohortPostPayload: ResolverTypeWrapper<DeleteCohortPostPayload>
  DeleteCohortReplyInput: DeleteCohortReplyInput
  DeleteCohortReplyPayload: ResolverTypeWrapper<DeleteCohortReplyPayload>
  DeleteCustomerTaxIdInput: DeleteCustomerTaxIdInput
  DeleteCustomerTaxIdPayload: ResolverTypeWrapper<DeleteCustomerTaxIdPayload>
  DeleteEmailInput: DeleteEmailInput
  DeleteEmailPayload: ResolverTypeWrapper<DeleteEmailPayload>
  DeleteFiledBookmarkInput: DeleteFiledBookmarkInput
  DeleteFiledBookmarkPayload: ResolverTypeWrapper<DeleteFiledBookmarkPayload>
  DeletePaymentSourceInput: DeletePaymentSourceInput
  DeletePaymentSourcePayload: ResolverTypeWrapper<DeletePaymentSourcePayload>
  DeleteTeamPostInput: DeleteTeamPostInput
  DeleteTeamPostPayload: ResolverTypeWrapper<DeleteTeamPostPayload>
  DeleteUserCompanyInput: DeleteUserCompanyInput
  DeleteUserCompanyPayload: ResolverTypeWrapper<DeleteUserCompanyPayload>
  DuplicateAccount: ResolverTypeWrapper<DuplicateAccount>
  EndSubscriptionTrialEarlyInput: EndSubscriptionTrialEarlyInput
  EndSubscriptionTrialEarlyPayload: ResolverTypeWrapper<EndSubscriptionTrialEarlyPayload>
  EngagementReportCourse: ResolverTypeWrapper<EngagementReportCourse>
  EngagementReportLesson: ResolverTypeWrapper<EngagementReportLesson>
  EngagementReportParticipant: ResolverTypeWrapper<EngagementReportParticipant>
  EngagementReportSavedItem: ResolverTypeWrapper<EngagementReportSavedItem>
  EngagementReportSummary: ResolverTypeWrapper<EngagementReportSummary>
  EngagementReportTeam: ResolverTypeWrapper<EngagementReportTeam>
  EngagementReportTeamMember: ResolverTypeWrapper<EngagementReportTeamMember>
  Event: ResolverTypeWrapper<Event>
  EventAttendee: ResolverTypeWrapper<EventAttendee>
  EventAttendeeConnection: ResolverTypeWrapper<EventAttendeeConnection>
  EventAttendeeEdge: ResolverTypeWrapper<EventAttendeeEdge>
  EventFilters: EventFilters
  EventPerCourseSession: ResolverTypeWrapper<EventPerCourseSession>
  EventPrework: ResolverTypeWrapper<EventPrework>
  EventPreworkSubmission: ResolverTypeWrapper<EventPreworkSubmission>
  EventRelatedResources:
    | ResolversTypes['Artifact']
    | ResolversTypes['CclCourse']
    | ResolversTypes['MarketingCourse']
  EventSpeaker: ResolverTypeWrapper<EventSpeaker>
  EventsPastFeed: ResolverTypeWrapper<EventsPastFeed>
  ExpertUserProfile: ResolverTypeWrapper<ExpertUserProfile>
  ExpertsIndex: ResolverTypeWrapper<ExpertsIndex>
  Faq: ResolverTypeWrapper<Faq>
  FeaturedPost: ResolverTypeWrapper<FeaturedPost>
  FeedbacksInput: FeedbacksInput
  FeedbacksPayload: ResolverTypeWrapper<FeedbacksPayload>
  FiledBookmark: ResolverTypeWrapper<FiledBookmark>
  FilteredArtifacts: ResolverTypeWrapper<FilteredArtifacts>
  FilteredUserSearch: ResolverTypeWrapper<FilteredUserSearch>
  FilteredUserSearchList: ResolverTypeWrapper<FilteredUserSearchList>
  FlagArtifactCommentInput: FlagArtifactCommentInput
  FlagArtifactCommentPayload: ResolverTypeWrapper<FlagArtifactCommentPayload>
  Float: ResolverTypeWrapper<Scalars['Float']>
  ForkBookmarkFolderInput: ForkBookmarkFolderInput
  ForkBookmarkFolderPayload: ResolverTypeWrapper<ForkBookmarkFolderPayload>
  ForumContribution:
    | ResolversTypes['Comment']
    | ResolversTypes['Post']
    | ResolversTypes['Reply']
  Function: ResolverTypeWrapper<Function>
  GenerateAiGoalsInput: GenerateAiGoalsInput
  GenerateAiGoalsPayload: ResolverTypeWrapper<GenerateAiGoalsPayload>
  GenerateAiInferenceInput: GenerateAiInferenceInput
  GenerateAiInferencePayload: ResolverTypeWrapper<GenerateAiInferencePayload>
  GettingStartedBlock: ResolverTypeWrapper<GettingStartedBlock>
  GettingStartedCollapsible: ResolverTypeWrapper<GettingStartedCollapsible>
  Group: ResolverTypeWrapper<Group>
  GroupCategory: ResolverTypeWrapper<GroupCategory>
  GroupConnection: ResolverTypeWrapper<GroupConnection>
  GroupEdge: ResolverTypeWrapper<GroupEdge>
  GroupFeed: ResolverTypeWrapper<GroupFeed>
  GroupMemberList: ResolverTypeWrapper<GroupMemberList>
  GroupPostFeed: ResolverTypeWrapper<GroupPostFeed>
  HomeDashboard: ResolverTypeWrapper<HomeDashboard>
  Homepage: ResolverTypeWrapper<Homepage>
  HomepageCheck: ResolverTypeWrapper<HomepageCheck>
  HomepageContent: ResolverTypeWrapper<HomepageContent>
  HomepageCourse: ResolverTypeWrapper<HomepageCourse>
  HomepageCourseHost: ResolverTypeWrapper<HomepageCourseHost>
  HomepageRecentlyViewedItem:
    | ResolversTypes['Artifact']
    | ResolversTypes['Content']
    | ResolversTypes['Unit']
  HomepageTeamsBenefit: ResolverTypeWrapper<HomepageTeamsBenefit>
  Host: ResolverTypeWrapper<Host>
  ID: ResolverTypeWrapper<Scalars['ID']>
  ISO8601DateTime: ResolverTypeWrapper<Scalars['ISO8601DateTime']>
  Image: ResolverTypeWrapper<Image>
  Industry: ResolverTypeWrapper<Industry>
  InferableAction: InferableAction
  InlinePost: ResolverTypeWrapper<InlinePost>
  InlinePostReply: ResolverTypeWrapper<InlinePostReply>
  Int: ResolverTypeWrapper<Scalars['Int']>
  Invoice: ResolverTypeWrapper<Invoice>
  InvoiceMember: ResolverTypeWrapper<InvoiceMember>
  JSON: ResolverTypeWrapper<Scalars['JSON']>
  JoinCourseWaitlistInput: JoinCourseWaitlistInput
  JoinCourseWaitlistPayload: ResolverTypeWrapper<JoinCourseWaitlistPayload>
  JoinPublicCourseWaitlistInput: JoinPublicCourseWaitlistInput
  JoinPublicCourseWaitlistPayload: ResolverTypeWrapper<JoinPublicCourseWaitlistPayload>
  LessonViewer: ResolverTypeWrapper<LessonViewer>
  LoginParamsInput: LoginParamsInput
  MakeEmailPrimaryInput: MakeEmailPrimaryInput
  MakeEmailPrimaryPayload: ResolverTypeWrapper<MakeEmailPrimaryPayload>
  MarkActivitiesAsReadInput: MarkActivitiesAsReadInput
  MarkActivitiesAsReadPayload: ResolverTypeWrapper<MarkActivitiesAsReadPayload>
  MarkAllActivitiesAsReadInput: MarkAllActivitiesAsReadInput
  MarkAllActivitiesAsReadPayload: ResolverTypeWrapper<MarkAllActivitiesAsReadPayload>
  MarkArtifactAsViewedInput: MarkArtifactAsViewedInput
  MarkArtifactAsViewedPayload: ResolverTypeWrapper<MarkArtifactAsViewedPayload>
  MarketingCourse: ResolverTypeWrapper<MarketingCourse>
  MarketingFaq: ResolverTypeWrapper<MarketingFaq>
  ModuleCompletionStatus: ResolverTypeWrapper<ModuleCompletionStatus>
  Mutation: ResolverTypeWrapper<{}>
  MuxVideo: ResolverTypeWrapper<MuxVideo>
  NotAttendingInput: NotAttendingInput
  NotAttendingPayload: ResolverTypeWrapper<NotAttendingPayload>
  OnboardingInfo: ResolverTypeWrapper<OnboardingInfo>
  OnboardingQuestionnaire: ResolverTypeWrapper<OnboardingQuestionnaire>
  OnboardingTrackingInput: OnboardingTrackingInput
  OnboardingTrackingPayload: ResolverTypeWrapper<OnboardingTrackingPayload>
  Org: ResolverTypeWrapper<Org>
  OrgAdmin: ResolverTypeWrapper<OrgAdmin>
  Page: ResolverTypeWrapper<Page>
  PageInfo: ResolverTypeWrapper<PageInfo>
  PageSeo: ResolverTypeWrapper<PageSeo>
  PageTypeEnum: PageTypeEnum
  Pagination: ResolverTypeWrapper<Pagination>
  PastPayment: ResolverTypeWrapper<PastPayment>
  PaymentRecord: ResolverTypeWrapper<PaymentRecord>
  PersonalizationQuery: ResolversTypes['Query']
  PlanForSale: ResolverTypeWrapper<PlanForSale>
  PlanName: PlanName
  Podcast: ResolverTypeWrapper<Podcast>
  PodcastCta: ResolverTypeWrapper<PodcastCta>
  PodcastDetails: ResolverTypeWrapper<PodcastDetails>
  PodcastHost: ResolverTypeWrapper<PodcastHost>
  PodcastIndex: ResolverTypeWrapper<PodcastIndex>
  PodcastIndexContent: ResolverTypeWrapper<PodcastIndexContent>
  PodcastIndexPage: ResolverTypeWrapper<PodcastIndexPage>
  PodcastPlatform: ResolverTypeWrapper<PodcastPlatform>
  PopularEventsPastFeed: ResolverTypeWrapper<PopularEventsPastFeed>
  PopularPostFilter: PopularPostFilter
  Post: ResolverTypeWrapper<Post>
  PostContributor: ResolverTypeWrapper<PostContributor>
  PostList: ResolverTypeWrapper<PostList>
  PostSource: PostSource
  PostTopic: ResolverTypeWrapper<PostTopic>
  PostsSearch: ResolverTypeWrapper<PostsSearch>
  PostsSearchTopic: ResolverTypeWrapper<PostsSearchTopic>
  PricingPage: ResolverTypeWrapper<PricingPage>
  PricingPageContent: ResolverTypeWrapper<PricingPageContent>
  PricingPlanFeature: ResolverTypeWrapper<PricingPlanFeature>
  PricingPlanFeatureCategory: ResolverTypeWrapper<PricingPlanFeatureCategory>
  PricingPlanFeatureValue: ResolverTypeWrapper<PricingPlanFeatureValue>
  ProductTour: ResolverTypeWrapper<ProductTour>
  ProductTourKey: ProductTourKey
  Profile: ResolverTypeWrapper<Profile>
  ProgramParticipatingKinds: ProgramParticipatingKinds
  ProgramProgress: ResolverTypeWrapper<ProgramProgress>
  ProgramProgressScalar: ResolverTypeWrapper<Scalars['ProgramProgressScalar']>
  ProjectDeliverable: ResolverTypeWrapper<ProjectDeliverable>
  Query: ResolverTypeWrapper<{}>
  Reaction: ResolverTypeWrapper<Reaction>
  ReactionKind: ReactionKind
  RecentlyViewedContent: ResolverTypeWrapper<RecentlyViewedContent>
  RecommendedContent: ResolverTypeWrapper<RecommendedContent>
  RecommendedContentCursor: ResolverTypeWrapper<RecommendedContentCursor>
  RecommendedContentFeedCursorInput: RecommendedContentFeedCursorInput
  RecommendedContentResults: ResolverTypeWrapper<RecommendedContentResults>
  RecommendedItem: ResolverTypeWrapper<RecommendedItem>
  ReforgeComparison: ResolverTypeWrapper<ReforgeComparison>
  ReforgeContent: ReforgeContent
  ReforgeUsage: ResolverTypeWrapper<ReforgeUsage>
  RegenerateCourseSnapshotInput: RegenerateCourseSnapshotInput
  RegenerateCourseSnapshotPayload: ResolverTypeWrapper<RegenerateCourseSnapshotPayload>
  ReinstateSeatInput: ReinstateSeatInput
  ReinstateSeatPayload: ResolverTypeWrapper<ReinstateSeatPayload>
  RemoveAiChatInput: RemoveAiChatInput
  RemoveAiChatPayload: ResolverTypeWrapper<RemoveAiChatPayload>
  RemoveAnnotationReactionInput: RemoveAnnotationReactionInput
  RemoveAnnotationReactionPayload: ResolverTypeWrapper<RemoveAnnotationReactionPayload>
  RemoveArtifactCommentInput: RemoveArtifactCommentInput
  RemoveArtifactCommentPayload: ResolverTypeWrapper<RemoveArtifactCommentPayload>
  RemoveArtifactCommentReactionInput: RemoveArtifactCommentReactionInput
  RemoveArtifactCommentReactionPayload: ResolverTypeWrapper<RemoveArtifactCommentReactionPayload>
  RemoveReactionInput: RemoveReactionInput
  RemoveReactionPayload: ResolverTypeWrapper<RemoveReactionPayload>
  RemoveReplyInput: RemoveReplyInput
  RemoveReplyPayload: ResolverTypeWrapper<RemoveReplyPayload>
  RemoveSeatInput: RemoveSeatInput
  RemoveSeatPayload: ResolverTypeWrapper<RemoveSeatPayload>
  RemoveSubscriptionFromOrgInput: RemoveSubscriptionFromOrgInput
  RemoveSubscriptionFromOrgPayload: ResolverTypeWrapper<RemoveSubscriptionFromOrgPayload>
  RemoveSubscriptionMemberInput: RemoveSubscriptionMemberInput
  RemoveSubscriptionMemberPayload: ResolverTypeWrapper<RemoveSubscriptionMemberPayload>
  RenameAiChatInput: RenameAiChatInput
  RenameAiChatPayload: ResolverTypeWrapper<RenameAiChatPayload>
  ReorderFiledBookmarkInput: ReorderFiledBookmarkInput
  ReorderFiledBookmarkPayload: ResolverTypeWrapper<ReorderFiledBookmarkPayload>
  Reply: ResolverTypeWrapper<Reply>
  ReplyParent: ReplyParent
  ReportCohortPostOrReplyInput: ReportCohortPostOrReplyInput
  ReportCohortPostOrReplyPayload: ResolverTypeWrapper<ReportCohortPostOrReplyPayload>
  RestoreCollectionActivityInput: RestoreCollectionActivityInput
  RestoreCollectionActivityPayload: ResolverTypeWrapper<RestoreCollectionActivityPayload>
  Review: ResolverTypeWrapper<Review>
  SanityCompany: ResolverTypeWrapper<SanityCompany>
  SanityCourse: ResolverTypeWrapper<SanityCourse>
  SanityHost: ResolverTypeWrapper<SanityHost>
  SanityTestimonial: ResolverTypeWrapper<SanityTestimonial>
  SaveCustomerTaxIdInput: SaveCustomerTaxIdInput
  SaveCustomerTaxIdPayload: ResolverTypeWrapper<SaveCustomerTaxIdPayload>
  SavedCountPerArtifact: ResolverTypeWrapper<SavedCountPerArtifact>
  ScheduledWeek: ResolverTypeWrapper<ScheduledWeek>
  SearchDocument: ResolverTypeWrapper<
    Omit<SearchDocument, 'searchable'> & { searchable: ResolversTypes['Searchable'] }
  >
  SearchDocumentList: ResolverTypeWrapper<SearchDocumentList>
  SearchDocumentTopResult: ResolverTypeWrapper<SearchDocumentTopResult>
  SearchDocumentTopResultCategory: ResolverTypeWrapper<SearchDocumentTopResultCategory>
  Searchable:
    | ResolversTypes['CmsSection']
    | ResolversTypes['Comment']
    | ResolversTypes['Post']
    | ResolversTypes['Reply']
    | ResolversTypes['User']
  Season: ResolverTypeWrapper<Season>
  Seat: ResolverTypeWrapper<Seat>
  SelectFeaturedPostsInput: SelectFeaturedPostsInput
  SelectFeaturedPostsPayload: ResolverTypeWrapper<SelectFeaturedPostsPayload>
  SendEmailVerifyInput: SendEmailVerifyInput
  SendEmailVerifyPayload: ResolverTypeWrapper<SendEmailVerifyPayload>
  SendMergeRequestEmailInput: SendMergeRequestEmailInput
  SendMergeRequestEmailPayload: ResolverTypeWrapper<SendMergeRequestEmailPayload>
  SetDismissedUpdateCompanyBannerAtInput: SetDismissedUpdateCompanyBannerAtInput
  SetDismissedUpdateCompanyBannerAtPayload: ResolverTypeWrapper<SetDismissedUpdateCompanyBannerAtPayload>
  SetHasSeenDemographicsModalInput: SetHasSeenDemographicsModalInput
  SetHasSeenDemographicsModalPayload: ResolverTypeWrapper<SetHasSeenDemographicsModalPayload>
  SetUserCohortPreferencesInput: SetUserCohortPreferencesInput
  SetUserCohortPreferencesPayload: ResolverTypeWrapper<SetUserCohortPreferencesPayload>
  ShareBookmarkFolderInput: ShareBookmarkFolderInput
  ShareBookmarkFolderPayload: ResolverTypeWrapper<ShareBookmarkFolderPayload>
  SharedFolder: ResolverTypeWrapper<SharedFolder>
  SharedFolderStatus: SharedFolderStatus
  SimilarPost: ResolverTypeWrapper<SimilarPost>
  SimilaritySearchBreadcrumb: ResolverTypeWrapper<SimilaritySearchBreadcrumb>
  SimilaritySearchContent: ResolverTypeWrapper<SimilaritySearchContent>
  SimilaritySearchContentType: SimilaritySearchContentType
  SimilaritySearchResponse: ResolverTypeWrapper<SimilaritySearchResponse>
  SiteSeo: ResolverTypeWrapper<SiteSeo>
  SiteSettings: ResolverTypeWrapper<SiteSettings>
  SlackAccount: ResolverTypeWrapper<SlackAccount>
  Snapshot: ResolverTypeWrapper<Snapshot>
  SnapshotLandingPage: ResolverTypeWrapper<SnapshotLandingPage>
  SsoAuthorization: ResolverTypeWrapper<SsoAuthorization>
  SsoFeatureSet: ResolverTypeWrapper<SsoFeatureSet>
  SsoFeatureStatus: ResolverTypeWrapper<SsoFeatureStatus>
  SsoFeatureStatusEnum: SsoFeatureStatusEnum
  SsoQuery: ResolversTypes['Query']
  String: ResolverTypeWrapper<Scalars['String']>
  StripeAchCreditTransfer: ResolverTypeWrapper<StripeAchCreditTransfer>
  StripeAddress: ResolverTypeWrapper<StripeAddress>
  StripeAddressInput: StripeAddressInput
  StripeBankAccount: ResolverTypeWrapper<StripeBankAccount>
  StripeCollectionMethod: StripeCollectionMethod
  StripeCustomer: ResolverTypeWrapper<StripeCustomer>
  StripeCustomerTaxId: ResolverTypeWrapper<StripeCustomerTaxId>
  StripeInvoice: ResolverTypeWrapper<StripeInvoice>
  StripeInvoiceLineItem: ResolverTypeWrapper<StripeInvoiceLineItem>
  StripeInvoiceStatus: StripeInvoiceStatus
  StripePaymentMethod: ResolverTypeWrapper<StripePaymentMethod>
  StripePaymentMethodCard: ResolverTypeWrapper<StripePaymentMethodCard>
  StripePaymentSources:
    | ResolversTypes['StripeBankAccount']
    | ResolversTypes['StripeSource']
  StripeSetupIntentInput: StripeSetupIntentInput
  StripeSetupIntentPayload: ResolverTypeWrapper<StripeSetupIntentPayload>
  StripeSource: ResolverTypeWrapper<StripeSource>
  StripeSubscription: ResolverTypeWrapper<StripeSubscription>
  StripeSubscriptionPlan: ResolverTypeWrapper<StripeSubscriptionPlan>
  StripeSubscriptionStatus: StripeSubscriptionStatus
  StripeTaxAmount: ResolverTypeWrapper<StripeTaxAmount>
  StripeTaxRate: ResolverTypeWrapper<StripeTaxRate>
  StripeUpcomingInvoice: ResolverTypeWrapper<StripeUpcomingInvoice>
  SubmitOnboardingQuestionnaireInput: SubmitOnboardingQuestionnaireInput
  SubmitOnboardingQuestionnairePayload: ResolverTypeWrapper<SubmitOnboardingQuestionnairePayload>
  SubmitPostApplySurveyInput: SubmitPostApplySurveyInput
  SubmitPostApplySurveyPayload: ResolverTypeWrapper<SubmitPostApplySurveyPayload>
  Subscription: ResolverTypeWrapper<{}>
  SubscriptionCancelInput: SubscriptionCancelInput
  SubscriptionCancelMotivationInput: SubscriptionCancelMotivationInput
  SubscriptionCancelMotivationPayload: ResolverTypeWrapper<SubscriptionCancelMotivationPayload>
  SubscriptionCancelPayload: ResolverTypeWrapper<SubscriptionCancelPayload>
  SubscriptionJoinRequest: ResolverTypeWrapper<SubscriptionJoinRequest>
  SubscriptionMember: ResolverTypeWrapper<SubscriptionMember>
  SubscriptionSeatsChangePreviewInput: SubscriptionSeatsChangePreviewInput
  SubscriptionSeatsChangePreviewPayload: ResolverTypeWrapper<SubscriptionSeatsChangePreviewPayload>
  SupplyCourseSession: ResolverTypeWrapper<SupplyCourseSession>
  SwimlanesQuery: ResolversTypes['Query']
  Tag: ResolverTypeWrapper<Tag>
  TagGroup: ResolverTypeWrapper<TagGroup>
  TaxIdReverseChargeStatus: ResolverTypeWrapper<TaxIdReverseChargeStatus>
  TaxIdVerificationStatus: TaxIdVerificationStatus
  TaxInfoItem: ResolverTypeWrapper<TaxInfoItem>
  TaxInfoItemDetails: ResolverTypeWrapper<TaxInfoItemDetails>
  TeamMemberPreview: ResolverTypeWrapper<TeamMemberPreview>
  TeamMemberProfilePreview: ResolverTypeWrapper<TeamMemberProfilePreview>
  TeamMembersSearch: ResolverTypeWrapper<TeamMembersSearch>
  TeamPost: ResolverTypeWrapper<TeamPost>
  TeamPreview: ResolverTypeWrapper<TeamPreview>
  TentativeUpcomingCohort: ResolverTypeWrapper<TentativeUpcomingCohort>
  Testimonial: ResolverTypeWrapper<Testimonial>
  TestimonialWithImage: ResolverTypeWrapper<TestimonialWithImage>
  ToggleManualAssignSubscriptionInput: ToggleManualAssignSubscriptionInput
  ToggleManualAssignSubscriptionPayload: ResolverTypeWrapper<ToggleManualAssignSubscriptionPayload>
  TogglePostFollowInput: TogglePostFollowInput
  TogglePostFollowPayload: ResolverTypeWrapper<TogglePostFollowPayload>
  Topic: ResolverTypeWrapper<Topic>
  TopicActivity: ResolverTypeWrapper<TopicActivity>
  TrackServerEventInput: TrackServerEventInput
  TrackServerEventPayload: ResolverTypeWrapper<TrackServerEventPayload>
  UnassignCohortCreditInput: UnassignCohortCreditInput
  UnassignCohortCreditPayload: ResolverTypeWrapper<UnassignCohortCreditPayload>
  UnassignSeatInput: UnassignSeatInput
  UnassignSeatPayload: ResolverTypeWrapper<UnassignSeatPayload>
  UnifiedCourse: ResolverTypeWrapper<UnifiedCourse>
  Unit: ResolverTypeWrapper<Unit>
  UnreadElement: ResolverTypeWrapper<UnreadElement>
  UnshareBookmarkFolderInput: UnshareBookmarkFolderInput
  UnshareBookmarkFolderPayload: ResolverTypeWrapper<UnshareBookmarkFolderPayload>
  UpdateAiPersonalizationInput: UpdateAiPersonalizationInput
  UpdateAiPersonalizationPayload: ResolverTypeWrapper<UpdateAiPersonalizationPayload>
  UpdateArtifactCommentInput: UpdateArtifactCommentInput
  UpdateArtifactCommentPayload: ResolverTypeWrapper<UpdateArtifactCommentPayload>
  UpdateBookmarkFolderInput: UpdateBookmarkFolderInput
  UpdateBookmarkFolderPayload: ResolverTypeWrapper<UpdateBookmarkFolderPayload>
  UpdateBookmarkInput: UpdateBookmarkInput
  UpdateBookmarkPayload: ResolverTypeWrapper<UpdateBookmarkPayload>
  UpdateCclInput: UpdateCclInput
  UpdateCclPayload: ResolverTypeWrapper<UpdateCclPayload>
  UpdateCmsSectionTagsInput: UpdateCmsSectionTagsInput
  UpdateCmsSectionTagsPayload: ResolverTypeWrapper<UpdateCmsSectionTagsPayload>
  UpdateCoachingSessionInput: UpdateCoachingSessionInput
  UpdateCoachingSessionPayload: ResolverTypeWrapper<UpdateCoachingSessionPayload>
  UpdateCohortPostInput: UpdateCohortPostInput
  UpdateCohortPostPayload: ResolverTypeWrapper<UpdateCohortPostPayload>
  UpdateCohortReplyInput: UpdateCohortReplyInput
  UpdateCohortReplyPayload: ResolverTypeWrapper<UpdateCohortReplyPayload>
  UpdateCourseEnrollmentInput: UpdateCourseEnrollmentInput
  UpdateCourseEnrollmentPayload: ResolverTypeWrapper<UpdateCourseEnrollmentPayload>
  UpdateCourseSessionInput: UpdateCourseSessionInput
  UpdateCourseSessionPayload: ResolverTypeWrapper<UpdateCourseSessionPayload>
  UpdateCustomerContactInput: UpdateCustomerContactInput
  UpdateCustomerContactPayload: ResolverTypeWrapper<UpdateCustomerContactPayload>
  UpdateHomepageCheckInput: UpdateHomepageCheckInput
  UpdateHomepageCheckPayload: ResolverTypeWrapper<UpdateHomepageCheckPayload>
  UpdateInvoiceUserFieldsInput: UpdateInvoiceUserFieldsInput
  UpdateInvoiceUserFieldsPayload: ResolverTypeWrapper<UpdateInvoiceUserFieldsPayload>
  UpdatePaymentMethodInput: UpdatePaymentMethodInput
  UpdatePaymentMethodPayload: ResolverTypeWrapper<UpdatePaymentMethodPayload>
  UpdatePreferencesInput: UpdatePreferencesInput
  UpdatePreferencesPayload: ResolverTypeWrapper<UpdatePreferencesPayload>
  UpdateProjectDeliverableInput: UpdateProjectDeliverableInput
  UpdateProjectDeliverablePayload: ResolverTypeWrapper<UpdateProjectDeliverablePayload>
  UpdateReplyInput: UpdateReplyInput
  UpdateReplyPayload: ResolverTypeWrapper<UpdateReplyPayload>
  UpdateTeamInput: UpdateTeamInput
  UpdateTeamPayload: ResolverTypeWrapper<UpdateTeamPayload>
  UpdateTeamPostInput: UpdateTeamPostInput
  UpdateTeamPostPayload: ResolverTypeWrapper<UpdateTeamPostPayload>
  UpdateTopicActivityInput: UpdateTopicActivityInput
  UpdateTopicActivityPayload: ResolverTypeWrapper<UpdateTopicActivityPayload>
  UpdateUserCohortInput: UpdateUserCohortInput
  UpdateUserCohortPayload: ResolverTypeWrapper<UpdateUserCohortPayload>
  UpdateUserCourseInput: UpdateUserCourseInput
  UpdateUserCoursePayload: ResolverTypeWrapper<UpdateUserCoursePayload>
  UpdateUserInput: UpdateUserInput
  UpdateUserPayload: ResolverTypeWrapper<UpdateUserPayload>
  UpgradeSubscriptionInput: UpgradeSubscriptionInput
  UpgradeSubscriptionPayload: ResolverTypeWrapper<UpgradeSubscriptionPayload>
  UqChoice: ResolverTypeWrapper<UqChoice>
  UqQuestion: ResolverTypeWrapper<UqQuestion>
  UqQuestionnaire: ResolverTypeWrapper<UqQuestionnaire>
  UqUserAnswer: ResolverTypeWrapper<UqUserAnswer>
  UqUserAnswers: ResolverTypeWrapper<UqUserAnswers>
  UrlChangeInput: UrlChangeInput
  UrlChangePayload: ResolverTypeWrapper<UrlChangePayload>
  User: ResolverTypeWrapper<
    Omit<User, 'lastForumContributions'> & {
      lastForumContributions: Array<ResolversTypes['ForumContribution']>
    }
  >
  UserCan: ResolverTypeWrapper<UserCan>
  UserCmsActivity: ResolverTypeWrapper<UserCmsActivity>
  UserCohort: ResolverTypeWrapper<UserCohort>
  UserCohorts: ResolverTypeWrapper<UserCohorts>
  UserCompany: ResolverTypeWrapper<UserCompany>
  UserContact: ResolverTypeWrapper<UserContact>
  UserCourse: ResolverTypeWrapper<UserCourse>
  UserCourseActivity: ResolverTypeWrapper<UserCourseActivity>
  UserCourses: ResolverTypeWrapper<UserCourses>
  UserEmail: ResolverTypeWrapper<UserEmail>
  UserEventActivity: UserEventActivity
  UserEventsPastFeed: ResolverTypeWrapper<UserEventsPastFeed>
  UserIs: ResolverTypeWrapper<UserIs>
  UserMention: ResolverTypeWrapper<UserMention>
  UserMerge: ResolverTypeWrapper<UserMerge>
  UserNotificationPreferences: ResolverTypeWrapper<UserNotificationPreferences>
  UserOauthProvider: ResolverTypeWrapper<UserOauthProvider>
  UserPatronage: ResolverTypeWrapper<UserPatronage>
  UserPreference: ResolverTypeWrapper<UserPreference>
  UserPricing: ResolverTypeWrapper<UserPricing>
  UserProfile: ResolverTypeWrapper<UserProfile>
  UserProfileFieldsInterface: ResolversTypes['UserProfile']
  UserProgram: ResolverTypeWrapper<UserProgram>
  UserSearchFilters: UserSearchFilters
  UserStatus: UserStatus
  UserSubscriptions: ResolverTypeWrapper<UserSubscriptions>
  UsersFeed: ResolverTypeWrapper<UsersFeed>
  ValidateEmail: ResolverTypeWrapper<ValidateEmail>
  VerifyEmailInput: VerifyEmailInput
  VerifyEmailPayload: ResolverTypeWrapper<VerifyEmailPayload>
  ViewBookmarkFolderInput: ViewBookmarkFolderInput
  ViewBookmarkFolderPayload: ResolverTypeWrapper<ViewBookmarkFolderPayload>
  ViewedArtifact: ResolverTypeWrapper<ViewedArtifact>
  WhatWeCanDoBetterInput: WhatWeCanDoBetterInput
  WhatWeCanDoBetterPayload: ResolverTypeWrapper<WhatWeCanDoBetterPayload>
  WorkEntry: ResolverTypeWrapper<WorkEntry>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActivateSubscriptionInput: ActivateSubscriptionInput
  ActivateSubscriptionPayload: ActivateSubscriptionPayload
  Activity: Activity
  ActivityAction: ActivityAction
  ActivityList: ActivityList
  ActivityListItem: ActivityListItem
  ActivityNoun: ActivityNoun
  ActivitySubject: ActivitySubject
  AddAnnotationReactionInput: AddAnnotationReactionInput
  AddAnnotationReactionPayload: AddAnnotationReactionPayload
  AddArtifactCommentInput: AddArtifactCommentInput
  AddArtifactCommentPayload: AddArtifactCommentPayload
  AddArtifactCommentReactionInput: AddArtifactCommentReactionInput
  AddArtifactCommentReactionPayload: AddArtifactCommentReactionPayload
  AddEmailToUserInput: AddEmailToUserInput
  AddEmailToUserPayload: AddEmailToUserPayload
  AddReactionInput: AddReactionInput
  AddReactionPayload: AddReactionPayload
  AddUserCompanyInput: AddUserCompanyInput
  AddUserCompanyPayload: AddUserCompanyPayload
  AiChat: AiChat
  AiChatConnection: AiChatConnection
  AiChatEdge: AiChatEdge
  AiChatMessage: AiChatMessage
  AiExtensionApp: AiExtensionApp
  AiPersonalization: AiPersonalization
  ApplicablePrograms: ApplicablePrograms
  ApproveSubscriptionJoinRequestInput: ApproveSubscriptionJoinRequestInput
  ApproveSubscriptionJoinRequestPayload: ApproveSubscriptionJoinRequestPayload
  Artifact: Artifact
  ArtifactAnnotation: ArtifactAnnotation
  ArtifactAnnotationReaction: ArtifactAnnotationReaction
  ArtifactAnnotationReactionGrouped: ArtifactAnnotationReactionGrouped
  ArtifactAnnotationVideo: ArtifactAnnotationVideo
  ArtifactAuthor: ArtifactAuthor
  ArtifactComment: ArtifactComment
  ArtifactCommentReaction: ArtifactCommentReaction
  ArtifactCommentReactionGrouped: ArtifactCommentReactionGrouped
  ArtifactCommentUser: ArtifactCommentUser
  ArtifactContribution: ArtifactContribution
  ArtifactContributor: ArtifactContributor
  ArtifactFilter: ArtifactFilter
  ArtifactFilters: ArtifactFilters
  ArtifactSubtopic: ArtifactSubtopic
  ArtifactTopic: ArtifactTopic
  ArtifactTopicFilter: ArtifactTopicFilter
  ArtifactTopicInput: ArtifactTopicInput
  ArtifactUserData: ArtifactUserData
  ArtifactWithWorkEntry: ArtifactWithWorkEntry
  ArtifactsForSubtopic: ArtifactsForSubtopic
  AssignCohortCreditInput: AssignCohortCreditInput
  AssignCohortCreditPayload: AssignCohortCreditPayload
  AssignSeatInput: AssignSeatInput
  AssignSeatPayload: AssignSeatPayload
  AttendeesFeed: AttendeesFeed
  BatchInput: BatchInput
  BatchPayload: BatchPayload
  BatchRsvpTimeData: BatchRsvpTimeData
  BillingInformation: BillingInformation
  BlogIndex: BlogIndex
  BlogPost: BlogPost
  BlogPostCategory: BlogPostCategory
  BlogPostDetails: BlogPostDetails
  Bookmark: Bookmark
  BookmarkFilters: BookmarkFilters
  BookmarkFolder: BookmarkFolder
  BookmarkModulesFilters: BookmarkModulesFilters
  BookmarkRelatedIdentifiers: BookmarkRelatedIdentifiers
  BookmarksFeed: BookmarksFeed
  Boolean: Scalars['Boolean']
  CancelInput: CancelInput
  CancelPayload: CancelPayload
  CaseStudiesIndex: CaseStudiesIndex
  CaseStudy: CaseStudy
  CaseStudyContentBlock: CaseStudyContentBlock
  CaseStudyContributor: CaseStudyContributor
  CaseStudyDetails: CaseStudyDetails
  CclArtifact: CclArtifact
  CclBlogPost: CclBlogPost
  CclCompany: CclCompany
  CclCourse: CclCourse
  CclCourseBlock: CclCourseBlock
  CclCourseSession: CclCourseSession
  CclExpert: CclExpert
  CclGuide: CclGuide
  CclReview: CclReview
  CclSessionEvent: CclSessionEvent
  CclTopic: CclTopic
  CclVideo: CclVideo
  Certificates: Certificates
  ChatQuery: ResolversParentTypes['Query']
  CheckoutInput: CheckoutInput
  CheckoutPayload: CheckoutPayload
  CheckoutProrationAdjustment: CheckoutProrationAdjustment
  ChoosePlanInput: ChoosePlanInput
  ChoosePlanPayload: ChoosePlanPayload
  ClearbitCompany: ClearbitCompany
  Clip: Clip
  CmsContent: CmsContent
  CmsModule: CmsModule
  CmsProgram: CmsProgram
  CmsProgramExpertsInput: CmsProgramExpertsInput
  CmsProgramExpertsPayload: CmsProgramExpertsPayload
  CmsProgramNotification: CmsProgramNotification
  CmsSection: CmsSection
  CmsSectionTag: CmsSectionTag
  CoachingSession: CoachingSession
  Cohort: Cohort
  CohortActivityListItem: CohortActivityListItem
  CohortActivityListWithMetadata: CohortActivityListWithMetadata
  CohortBookmarkFolder: CohortBookmarkFolder
  CohortCertificate: CohortCertificate
  CohortCredit: CohortCredit
  CohortCredits: CohortCredits
  CohortEventTimes: CohortEventTimes
  CohortPassPriceTier: CohortPassPriceTier
  CohortPost: CohortPost
  CohortTeam: CohortTeam
  Collection: Collection
  CollectionActivity: CollectionActivity
  CollectionActivityList: CollectionActivityList
  CollectionsQuery: ResolversParentTypes['Query']
  Comment: Comment
  Company: Company
  CompleteProductTourInput: CompleteProductTourInput
  CompleteProductTourPayload: CompleteProductTourPayload
  CompletedFeedback: CompletedFeedback
  CompletionSnapshot: CompletionSnapshot
  ConfirmUserMergeInput: ConfirmUserMergeInput
  ConfirmUserMergePayload: ConfirmUserMergePayload
  Content: Content
  ContentBlock: ContentBlock
  ContentBlockCollapsible: ContentBlockCollapsible
  ContentFeed: ContentFeed
  ContentFilters: ContentFilters
  ContentTocItem: ContentTocItem
  ContentViewer: ContentViewer
  CookieConsent: CookieConsent
  Course: Course
  CourseBenefit: CourseBenefit
  CourseBlock: CourseBlock
  CourseCertificate: CourseCertificate
  CourseCompletionSnapshot: CourseCompletionSnapshot
  CourseConcepts: CourseConcepts
  CourseDetails: CourseDetails
  CourseEnrollmentConfirmedInput: CourseEnrollmentConfirmedInput
  CourseEnrollmentConfirmedPayload: CourseEnrollmentConfirmedPayload
  CourseEnrollmentSnapshot: CourseEnrollmentSnapshot
  CourseEnrollmentStartedInput: CourseEnrollmentStartedInput
  CourseEnrollmentStartedPayload: CourseEnrollmentStartedPayload
  CourseEvent: CourseEvent
  CourseForTopicAndFunction: CourseForTopicAndFunction
  CourseIndex: CourseIndex
  CourseIndexContent: CourseIndexContent
  CourseIndexPage: CourseIndexPage
  CourseInfoCard: CourseInfoCard
  CoursePreview: CoursePreview
  CourseSession: CourseSession
  CourseSessionDetail: CourseSessionDetail
  CourseSnapshot: CourseSnapshot
  CourseTopicCovered: CourseTopicCovered
  CreateAccountInput: CreateAccountInput
  CreateAccountPayload: CreateAccountPayload
  CreateBookmarkFolderInput: CreateBookmarkFolderInput
  CreateBookmarkFolderPayload: CreateBookmarkFolderPayload
  CreateBookmarkInput: CreateBookmarkInput
  CreateBookmarkPayload: CreateBookmarkPayload
  CreateCohortPostInput: CreateCohortPostInput
  CreateCohortPostPayload: CreateCohortPostPayload
  CreateCohortReplyInput: CreateCohortReplyInput
  CreateCohortReplyPayload: CreateCohortReplyPayload
  CreateCourseEnrollmentInput: CreateCourseEnrollmentInput
  CreateCourseEnrollmentPayload: CreateCourseEnrollmentPayload
  CreateCourseSessionInput: CreateCourseSessionInput
  CreateCourseSessionPayload: CreateCourseSessionPayload
  CreateCourseSessionSlackChannelInput: CreateCourseSessionSlackChannelInput
  CreateCourseSessionSlackChannelPayload: CreateCourseSessionSlackChannelPayload
  CreateDraftSubscriptionJoinRequestInput: CreateDraftSubscriptionJoinRequestInput
  CreateDraftSubscriptionJoinRequestPayload: CreateDraftSubscriptionJoinRequestPayload
  CreateFiledBookmarkInput: CreateFiledBookmarkInput
  CreateFiledBookmarkPayload: CreateFiledBookmarkPayload
  CreateInput: CreateInput
  CreatePayload: CreatePayload
  CreateProgramApplicationInput: CreateProgramApplicationInput
  CreateProgramApplicationPayload: CreateProgramApplicationPayload
  CreateProgramInvitationInput: CreateProgramInvitationInput
  CreateProgramInvitationPayload: CreateProgramInvitationPayload
  CreateRailsSubscriptionInput: CreateRailsSubscriptionInput
  CreateRailsSubscriptionPayload: CreateRailsSubscriptionPayload
  CreateRecommendationsInput: CreateRecommendationsInput
  CreateRecommendationsPayload: CreateRecommendationsPayload
  CreateReplyInput: CreateReplyInput
  CreateReplyPayload: CreateReplyPayload
  CreateSubscriptionJoinRequestInput: CreateSubscriptionJoinRequestInput
  CreateSubscriptionJoinRequestPayload: CreateSubscriptionJoinRequestPayload
  CreateTeamPostInput: CreateTeamPostInput
  CreateTeamPostPayload: CreateTeamPostPayload
  CreateTestUsersInput: CreateTestUsersInput
  CreateTestUsersPayload: CreateTestUsersPayload
  CreateUserClipActivityInput: CreateUserClipActivityInput
  CreateUserClipActivityPayload: CreateUserClipActivityPayload
  CreateUserCmsActivityInput: CreateUserCmsActivityInput
  CreateUserCmsActivityPayload: CreateUserCmsActivityPayload
  CreateUserEventActivityInput: CreateUserEventActivityInput
  CreateUserEventActivityPayload: CreateUserEventActivityPayload
  Creator: Creator
  Cta: Cta
  DeclineSubscriptionJoinRequestInput: DeclineSubscriptionJoinRequestInput
  DeclineSubscriptionJoinRequestPayload: DeclineSubscriptionJoinRequestPayload
  DeleteBookmarkFolderInput: DeleteBookmarkFolderInput
  DeleteBookmarkFolderPayload: DeleteBookmarkFolderPayload
  DeleteBookmarkInput: DeleteBookmarkInput
  DeleteBookmarkPayload: DeleteBookmarkPayload
  DeleteCohortPassInput: DeleteCohortPassInput
  DeleteCohortPassPayload: DeleteCohortPassPayload
  DeleteCohortPostInput: DeleteCohortPostInput
  DeleteCohortPostPayload: DeleteCohortPostPayload
  DeleteCohortReplyInput: DeleteCohortReplyInput
  DeleteCohortReplyPayload: DeleteCohortReplyPayload
  DeleteCustomerTaxIdInput: DeleteCustomerTaxIdInput
  DeleteCustomerTaxIdPayload: DeleteCustomerTaxIdPayload
  DeleteEmailInput: DeleteEmailInput
  DeleteEmailPayload: DeleteEmailPayload
  DeleteFiledBookmarkInput: DeleteFiledBookmarkInput
  DeleteFiledBookmarkPayload: DeleteFiledBookmarkPayload
  DeletePaymentSourceInput: DeletePaymentSourceInput
  DeletePaymentSourcePayload: DeletePaymentSourcePayload
  DeleteTeamPostInput: DeleteTeamPostInput
  DeleteTeamPostPayload: DeleteTeamPostPayload
  DeleteUserCompanyInput: DeleteUserCompanyInput
  DeleteUserCompanyPayload: DeleteUserCompanyPayload
  DuplicateAccount: DuplicateAccount
  EndSubscriptionTrialEarlyInput: EndSubscriptionTrialEarlyInput
  EndSubscriptionTrialEarlyPayload: EndSubscriptionTrialEarlyPayload
  EngagementReportCourse: EngagementReportCourse
  EngagementReportLesson: EngagementReportLesson
  EngagementReportParticipant: EngagementReportParticipant
  EngagementReportSavedItem: EngagementReportSavedItem
  EngagementReportSummary: EngagementReportSummary
  EngagementReportTeam: EngagementReportTeam
  EngagementReportTeamMember: EngagementReportTeamMember
  Event: Event
  EventAttendee: EventAttendee
  EventAttendeeConnection: EventAttendeeConnection
  EventAttendeeEdge: EventAttendeeEdge
  EventFilters: EventFilters
  EventPerCourseSession: EventPerCourseSession
  EventPrework: EventPrework
  EventPreworkSubmission: EventPreworkSubmission
  EventRelatedResources:
    | ResolversParentTypes['Artifact']
    | ResolversParentTypes['CclCourse']
    | ResolversParentTypes['MarketingCourse']
  EventSpeaker: EventSpeaker
  EventsPastFeed: EventsPastFeed
  ExpertUserProfile: ExpertUserProfile
  ExpertsIndex: ExpertsIndex
  Faq: Faq
  FeaturedPost: FeaturedPost
  FeedbacksInput: FeedbacksInput
  FeedbacksPayload: FeedbacksPayload
  FiledBookmark: FiledBookmark
  FilteredArtifacts: FilteredArtifacts
  FilteredUserSearch: FilteredUserSearch
  FilteredUserSearchList: FilteredUserSearchList
  FlagArtifactCommentInput: FlagArtifactCommentInput
  FlagArtifactCommentPayload: FlagArtifactCommentPayload
  Float: Scalars['Float']
  ForkBookmarkFolderInput: ForkBookmarkFolderInput
  ForkBookmarkFolderPayload: ForkBookmarkFolderPayload
  ForumContribution:
    | ResolversParentTypes['Comment']
    | ResolversParentTypes['Post']
    | ResolversParentTypes['Reply']
  Function: Function
  GenerateAiGoalsInput: GenerateAiGoalsInput
  GenerateAiGoalsPayload: GenerateAiGoalsPayload
  GenerateAiInferenceInput: GenerateAiInferenceInput
  GenerateAiInferencePayload: GenerateAiInferencePayload
  GettingStartedBlock: GettingStartedBlock
  GettingStartedCollapsible: GettingStartedCollapsible
  Group: Group
  GroupCategory: GroupCategory
  GroupConnection: GroupConnection
  GroupEdge: GroupEdge
  GroupFeed: GroupFeed
  GroupMemberList: GroupMemberList
  GroupPostFeed: GroupPostFeed
  HomeDashboard: HomeDashboard
  Homepage: Homepage
  HomepageCheck: HomepageCheck
  HomepageContent: HomepageContent
  HomepageCourse: HomepageCourse
  HomepageCourseHost: HomepageCourseHost
  HomepageRecentlyViewedItem:
    | ResolversParentTypes['Artifact']
    | ResolversParentTypes['Content']
    | ResolversParentTypes['Unit']
  HomepageTeamsBenefit: HomepageTeamsBenefit
  Host: Host
  ID: Scalars['ID']
  ISO8601DateTime: Scalars['ISO8601DateTime']
  Image: Image
  Industry: Industry
  InlinePost: InlinePost
  InlinePostReply: InlinePostReply
  Int: Scalars['Int']
  Invoice: Invoice
  InvoiceMember: InvoiceMember
  JSON: Scalars['JSON']
  JoinCourseWaitlistInput: JoinCourseWaitlistInput
  JoinCourseWaitlistPayload: JoinCourseWaitlistPayload
  JoinPublicCourseWaitlistInput: JoinPublicCourseWaitlistInput
  JoinPublicCourseWaitlistPayload: JoinPublicCourseWaitlistPayload
  LessonViewer: LessonViewer
  LoginParamsInput: LoginParamsInput
  MakeEmailPrimaryInput: MakeEmailPrimaryInput
  MakeEmailPrimaryPayload: MakeEmailPrimaryPayload
  MarkActivitiesAsReadInput: MarkActivitiesAsReadInput
  MarkActivitiesAsReadPayload: MarkActivitiesAsReadPayload
  MarkAllActivitiesAsReadInput: MarkAllActivitiesAsReadInput
  MarkAllActivitiesAsReadPayload: MarkAllActivitiesAsReadPayload
  MarkArtifactAsViewedInput: MarkArtifactAsViewedInput
  MarkArtifactAsViewedPayload: MarkArtifactAsViewedPayload
  MarketingCourse: MarketingCourse
  MarketingFaq: MarketingFaq
  ModuleCompletionStatus: ModuleCompletionStatus
  Mutation: {}
  MuxVideo: MuxVideo
  NotAttendingInput: NotAttendingInput
  NotAttendingPayload: NotAttendingPayload
  OnboardingInfo: OnboardingInfo
  OnboardingQuestionnaire: OnboardingQuestionnaire
  OnboardingTrackingInput: OnboardingTrackingInput
  OnboardingTrackingPayload: OnboardingTrackingPayload
  Org: Org
  OrgAdmin: OrgAdmin
  Page: Page
  PageInfo: PageInfo
  PageSeo: PageSeo
  Pagination: Pagination
  PastPayment: PastPayment
  PaymentRecord: PaymentRecord
  PersonalizationQuery: ResolversParentTypes['Query']
  PlanForSale: PlanForSale
  Podcast: Podcast
  PodcastCta: PodcastCta
  PodcastDetails: PodcastDetails
  PodcastHost: PodcastHost
  PodcastIndex: PodcastIndex
  PodcastIndexContent: PodcastIndexContent
  PodcastIndexPage: PodcastIndexPage
  PodcastPlatform: PodcastPlatform
  PopularEventsPastFeed: PopularEventsPastFeed
  PopularPostFilter: PopularPostFilter
  Post: Post
  PostContributor: PostContributor
  PostList: PostList
  PostTopic: PostTopic
  PostsSearch: PostsSearch
  PostsSearchTopic: PostsSearchTopic
  PricingPage: PricingPage
  PricingPageContent: PricingPageContent
  PricingPlanFeature: PricingPlanFeature
  PricingPlanFeatureCategory: PricingPlanFeatureCategory
  PricingPlanFeatureValue: PricingPlanFeatureValue
  ProductTour: ProductTour
  Profile: Profile
  ProgramProgress: ProgramProgress
  ProgramProgressScalar: Scalars['ProgramProgressScalar']
  ProjectDeliverable: ProjectDeliverable
  Query: {}
  Reaction: Reaction
  RecentlyViewedContent: RecentlyViewedContent
  RecommendedContent: RecommendedContent
  RecommendedContentCursor: RecommendedContentCursor
  RecommendedContentFeedCursorInput: RecommendedContentFeedCursorInput
  RecommendedContentResults: RecommendedContentResults
  RecommendedItem: RecommendedItem
  ReforgeComparison: ReforgeComparison
  ReforgeUsage: ReforgeUsage
  RegenerateCourseSnapshotInput: RegenerateCourseSnapshotInput
  RegenerateCourseSnapshotPayload: RegenerateCourseSnapshotPayload
  ReinstateSeatInput: ReinstateSeatInput
  ReinstateSeatPayload: ReinstateSeatPayload
  RemoveAiChatInput: RemoveAiChatInput
  RemoveAiChatPayload: RemoveAiChatPayload
  RemoveAnnotationReactionInput: RemoveAnnotationReactionInput
  RemoveAnnotationReactionPayload: RemoveAnnotationReactionPayload
  RemoveArtifactCommentInput: RemoveArtifactCommentInput
  RemoveArtifactCommentPayload: RemoveArtifactCommentPayload
  RemoveArtifactCommentReactionInput: RemoveArtifactCommentReactionInput
  RemoveArtifactCommentReactionPayload: RemoveArtifactCommentReactionPayload
  RemoveReactionInput: RemoveReactionInput
  RemoveReactionPayload: RemoveReactionPayload
  RemoveReplyInput: RemoveReplyInput
  RemoveReplyPayload: RemoveReplyPayload
  RemoveSeatInput: RemoveSeatInput
  RemoveSeatPayload: RemoveSeatPayload
  RemoveSubscriptionFromOrgInput: RemoveSubscriptionFromOrgInput
  RemoveSubscriptionFromOrgPayload: RemoveSubscriptionFromOrgPayload
  RemoveSubscriptionMemberInput: RemoveSubscriptionMemberInput
  RemoveSubscriptionMemberPayload: RemoveSubscriptionMemberPayload
  RenameAiChatInput: RenameAiChatInput
  RenameAiChatPayload: RenameAiChatPayload
  ReorderFiledBookmarkInput: ReorderFiledBookmarkInput
  ReorderFiledBookmarkPayload: ReorderFiledBookmarkPayload
  Reply: Reply
  ReportCohortPostOrReplyInput: ReportCohortPostOrReplyInput
  ReportCohortPostOrReplyPayload: ReportCohortPostOrReplyPayload
  RestoreCollectionActivityInput: RestoreCollectionActivityInput
  RestoreCollectionActivityPayload: RestoreCollectionActivityPayload
  Review: Review
  SanityCompany: SanityCompany
  SanityCourse: SanityCourse
  SanityHost: SanityHost
  SanityTestimonial: SanityTestimonial
  SaveCustomerTaxIdInput: SaveCustomerTaxIdInput
  SaveCustomerTaxIdPayload: SaveCustomerTaxIdPayload
  SavedCountPerArtifact: SavedCountPerArtifact
  ScheduledWeek: ScheduledWeek
  SearchDocument: Omit<SearchDocument, 'searchable'> & {
    searchable: ResolversParentTypes['Searchable']
  }
  SearchDocumentList: SearchDocumentList
  SearchDocumentTopResult: SearchDocumentTopResult
  SearchDocumentTopResultCategory: SearchDocumentTopResultCategory
  Searchable:
    | ResolversParentTypes['CmsSection']
    | ResolversParentTypes['Comment']
    | ResolversParentTypes['Post']
    | ResolversParentTypes['Reply']
    | ResolversParentTypes['User']
  Season: Season
  Seat: Seat
  SelectFeaturedPostsInput: SelectFeaturedPostsInput
  SelectFeaturedPostsPayload: SelectFeaturedPostsPayload
  SendEmailVerifyInput: SendEmailVerifyInput
  SendEmailVerifyPayload: SendEmailVerifyPayload
  SendMergeRequestEmailInput: SendMergeRequestEmailInput
  SendMergeRequestEmailPayload: SendMergeRequestEmailPayload
  SetDismissedUpdateCompanyBannerAtInput: SetDismissedUpdateCompanyBannerAtInput
  SetDismissedUpdateCompanyBannerAtPayload: SetDismissedUpdateCompanyBannerAtPayload
  SetHasSeenDemographicsModalInput: SetHasSeenDemographicsModalInput
  SetHasSeenDemographicsModalPayload: SetHasSeenDemographicsModalPayload
  SetUserCohortPreferencesInput: SetUserCohortPreferencesInput
  SetUserCohortPreferencesPayload: SetUserCohortPreferencesPayload
  ShareBookmarkFolderInput: ShareBookmarkFolderInput
  ShareBookmarkFolderPayload: ShareBookmarkFolderPayload
  SharedFolder: SharedFolder
  SimilarPost: SimilarPost
  SimilaritySearchBreadcrumb: SimilaritySearchBreadcrumb
  SimilaritySearchContent: SimilaritySearchContent
  SimilaritySearchResponse: SimilaritySearchResponse
  SiteSeo: SiteSeo
  SiteSettings: SiteSettings
  SlackAccount: SlackAccount
  Snapshot: Snapshot
  SnapshotLandingPage: SnapshotLandingPage
  SsoAuthorization: SsoAuthorization
  SsoFeatureSet: SsoFeatureSet
  SsoFeatureStatus: SsoFeatureStatus
  SsoQuery: ResolversParentTypes['Query']
  String: Scalars['String']
  StripeAchCreditTransfer: StripeAchCreditTransfer
  StripeAddress: StripeAddress
  StripeAddressInput: StripeAddressInput
  StripeBankAccount: StripeBankAccount
  StripeCustomer: StripeCustomer
  StripeCustomerTaxId: StripeCustomerTaxId
  StripeInvoice: StripeInvoice
  StripeInvoiceLineItem: StripeInvoiceLineItem
  StripePaymentMethod: StripePaymentMethod
  StripePaymentMethodCard: StripePaymentMethodCard
  StripePaymentSources:
    | ResolversParentTypes['StripeBankAccount']
    | ResolversParentTypes['StripeSource']
  StripeSetupIntentInput: StripeSetupIntentInput
  StripeSetupIntentPayload: StripeSetupIntentPayload
  StripeSource: StripeSource
  StripeSubscription: StripeSubscription
  StripeSubscriptionPlan: StripeSubscriptionPlan
  StripeTaxAmount: StripeTaxAmount
  StripeTaxRate: StripeTaxRate
  StripeUpcomingInvoice: StripeUpcomingInvoice
  SubmitOnboardingQuestionnaireInput: SubmitOnboardingQuestionnaireInput
  SubmitOnboardingQuestionnairePayload: SubmitOnboardingQuestionnairePayload
  SubmitPostApplySurveyInput: SubmitPostApplySurveyInput
  SubmitPostApplySurveyPayload: SubmitPostApplySurveyPayload
  Subscription: {}
  SubscriptionCancelInput: SubscriptionCancelInput
  SubscriptionCancelMotivationInput: SubscriptionCancelMotivationInput
  SubscriptionCancelMotivationPayload: SubscriptionCancelMotivationPayload
  SubscriptionCancelPayload: SubscriptionCancelPayload
  SubscriptionJoinRequest: SubscriptionJoinRequest
  SubscriptionMember: SubscriptionMember
  SubscriptionSeatsChangePreviewInput: SubscriptionSeatsChangePreviewInput
  SubscriptionSeatsChangePreviewPayload: SubscriptionSeatsChangePreviewPayload
  SupplyCourseSession: SupplyCourseSession
  SwimlanesQuery: ResolversParentTypes['Query']
  Tag: Tag
  TagGroup: TagGroup
  TaxIdReverseChargeStatus: TaxIdReverseChargeStatus
  TaxInfoItem: TaxInfoItem
  TaxInfoItemDetails: TaxInfoItemDetails
  TeamMemberPreview: TeamMemberPreview
  TeamMemberProfilePreview: TeamMemberProfilePreview
  TeamMembersSearch: TeamMembersSearch
  TeamPost: TeamPost
  TeamPreview: TeamPreview
  TentativeUpcomingCohort: TentativeUpcomingCohort
  Testimonial: Testimonial
  TestimonialWithImage: TestimonialWithImage
  ToggleManualAssignSubscriptionInput: ToggleManualAssignSubscriptionInput
  ToggleManualAssignSubscriptionPayload: ToggleManualAssignSubscriptionPayload
  TogglePostFollowInput: TogglePostFollowInput
  TogglePostFollowPayload: TogglePostFollowPayload
  Topic: Topic
  TopicActivity: TopicActivity
  TrackServerEventInput: TrackServerEventInput
  TrackServerEventPayload: TrackServerEventPayload
  UnassignCohortCreditInput: UnassignCohortCreditInput
  UnassignCohortCreditPayload: UnassignCohortCreditPayload
  UnassignSeatInput: UnassignSeatInput
  UnassignSeatPayload: UnassignSeatPayload
  UnifiedCourse: UnifiedCourse
  Unit: Unit
  UnreadElement: UnreadElement
  UnshareBookmarkFolderInput: UnshareBookmarkFolderInput
  UnshareBookmarkFolderPayload: UnshareBookmarkFolderPayload
  UpdateAiPersonalizationInput: UpdateAiPersonalizationInput
  UpdateAiPersonalizationPayload: UpdateAiPersonalizationPayload
  UpdateArtifactCommentInput: UpdateArtifactCommentInput
  UpdateArtifactCommentPayload: UpdateArtifactCommentPayload
  UpdateBookmarkFolderInput: UpdateBookmarkFolderInput
  UpdateBookmarkFolderPayload: UpdateBookmarkFolderPayload
  UpdateBookmarkInput: UpdateBookmarkInput
  UpdateBookmarkPayload: UpdateBookmarkPayload
  UpdateCclInput: UpdateCclInput
  UpdateCclPayload: UpdateCclPayload
  UpdateCmsSectionTagsInput: UpdateCmsSectionTagsInput
  UpdateCmsSectionTagsPayload: UpdateCmsSectionTagsPayload
  UpdateCoachingSessionInput: UpdateCoachingSessionInput
  UpdateCoachingSessionPayload: UpdateCoachingSessionPayload
  UpdateCohortPostInput: UpdateCohortPostInput
  UpdateCohortPostPayload: UpdateCohortPostPayload
  UpdateCohortReplyInput: UpdateCohortReplyInput
  UpdateCohortReplyPayload: UpdateCohortReplyPayload
  UpdateCourseEnrollmentInput: UpdateCourseEnrollmentInput
  UpdateCourseEnrollmentPayload: UpdateCourseEnrollmentPayload
  UpdateCourseSessionInput: UpdateCourseSessionInput
  UpdateCourseSessionPayload: UpdateCourseSessionPayload
  UpdateCustomerContactInput: UpdateCustomerContactInput
  UpdateCustomerContactPayload: UpdateCustomerContactPayload
  UpdateHomepageCheckInput: UpdateHomepageCheckInput
  UpdateHomepageCheckPayload: UpdateHomepageCheckPayload
  UpdateInvoiceUserFieldsInput: UpdateInvoiceUserFieldsInput
  UpdateInvoiceUserFieldsPayload: UpdateInvoiceUserFieldsPayload
  UpdatePaymentMethodInput: UpdatePaymentMethodInput
  UpdatePaymentMethodPayload: UpdatePaymentMethodPayload
  UpdatePreferencesInput: UpdatePreferencesInput
  UpdatePreferencesPayload: UpdatePreferencesPayload
  UpdateProjectDeliverableInput: UpdateProjectDeliverableInput
  UpdateProjectDeliverablePayload: UpdateProjectDeliverablePayload
  UpdateReplyInput: UpdateReplyInput
  UpdateReplyPayload: UpdateReplyPayload
  UpdateTeamInput: UpdateTeamInput
  UpdateTeamPayload: UpdateTeamPayload
  UpdateTeamPostInput: UpdateTeamPostInput
  UpdateTeamPostPayload: UpdateTeamPostPayload
  UpdateTopicActivityInput: UpdateTopicActivityInput
  UpdateTopicActivityPayload: UpdateTopicActivityPayload
  UpdateUserCohortInput: UpdateUserCohortInput
  UpdateUserCohortPayload: UpdateUserCohortPayload
  UpdateUserCourseInput: UpdateUserCourseInput
  UpdateUserCoursePayload: UpdateUserCoursePayload
  UpdateUserInput: UpdateUserInput
  UpdateUserPayload: UpdateUserPayload
  UpgradeSubscriptionInput: UpgradeSubscriptionInput
  UpgradeSubscriptionPayload: UpgradeSubscriptionPayload
  UqChoice: UqChoice
  UqQuestion: UqQuestion
  UqQuestionnaire: UqQuestionnaire
  UqUserAnswer: UqUserAnswer
  UqUserAnswers: UqUserAnswers
  UrlChangeInput: UrlChangeInput
  UrlChangePayload: UrlChangePayload
  User: Omit<User, 'lastForumContributions'> & {
    lastForumContributions: Array<ResolversParentTypes['ForumContribution']>
  }
  UserCan: UserCan
  UserCmsActivity: UserCmsActivity
  UserCohort: UserCohort
  UserCohorts: UserCohorts
  UserCompany: UserCompany
  UserContact: UserContact
  UserCourse: UserCourse
  UserCourseActivity: UserCourseActivity
  UserCourses: UserCourses
  UserEmail: UserEmail
  UserEventsPastFeed: UserEventsPastFeed
  UserIs: UserIs
  UserMention: UserMention
  UserMerge: UserMerge
  UserNotificationPreferences: UserNotificationPreferences
  UserOauthProvider: UserOauthProvider
  UserPatronage: UserPatronage
  UserPreference: UserPreference
  UserPricing: UserPricing
  UserProfile: UserProfile
  UserProfileFieldsInterface: ResolversParentTypes['UserProfile']
  UserProgram: UserProgram
  UserSearchFilters: UserSearchFilters
  UserSubscriptions: UserSubscriptions
  UsersFeed: UsersFeed
  ValidateEmail: ValidateEmail
  VerifyEmailInput: VerifyEmailInput
  VerifyEmailPayload: VerifyEmailPayload
  ViewBookmarkFolderInput: ViewBookmarkFolderInput
  ViewBookmarkFolderPayload: ViewBookmarkFolderPayload
  ViewedArtifact: ViewedArtifact
  WhatWeCanDoBetterInput: WhatWeCanDoBetterInput
  WhatWeCanDoBetterPayload: WhatWeCanDoBetterPayload
  WorkEntry: WorkEntry
}

export type ActivateSubscriptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivateSubscriptionPayload'] = ResolversParentTypes['ActivateSubscriptionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  redirectPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subscriptionMember?: Resolver<
    Maybe<ResolversTypes['SubscriptionMember']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Activity'] = ResolversParentTypes['Activity']
> = {
  action?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  trackableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  trackableSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trackableTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trackableType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityActionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivityAction'] = ResolversParentTypes['ActivityAction']
> = {
  actionString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  reactions?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivityList'] = ResolversParentTypes['ActivityList']
> = {
  activities?: Resolver<
    Array<ResolversTypes['ActivityListItem']>,
    ParentType,
    ContextType
  >
  meta?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityListItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivityListItem'] = ResolversParentTypes['ActivityListItem']
> = {
  action?: Resolver<ResolversTypes['ActivityAction'], ParentType, ContextType>
  activityIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  cohortChannelHref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  excerpt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  noun?: Resolver<ResolversTypes['ActivityNoun'], ParentType, ContextType>
  pathname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  subjects?: Resolver<Array<ResolversTypes['ActivitySubject']>, ParentType, ContextType>
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trackedAnchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  trackedId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  trackedPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  trackedSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  trackedTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  trackedType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivityNounResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivityNoun'] = ResolversParentTypes['ActivityNoun']
> = {
  asString?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pattern?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivitySubjectResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ActivitySubject'] = ResolversParentTypes['ActivitySubject']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  remainingNames?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  slug?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddAnnotationReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddAnnotationReactionPayload'] = ResolversParentTypes['AddAnnotationReactionPayload']
> = {
  annotationReaction?: Resolver<
    Maybe<ResolversTypes['ArtifactAnnotationReaction']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddArtifactCommentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddArtifactCommentPayload'] = ResolversParentTypes['AddArtifactCommentPayload']
> = {
  artifactComment?: Resolver<
    Maybe<ResolversTypes['ArtifactComment']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddArtifactCommentReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddArtifactCommentReactionPayload'] = ResolversParentTypes['AddArtifactCommentReactionPayload']
> = {
  artifactCommentReaction?: Resolver<
    Maybe<ResolversTypes['ArtifactCommentReaction']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddEmailToUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddEmailToUserPayload'] = ResolversParentTypes['AddEmailToUserPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddReactionPayload'] = ResolversParentTypes['AddReactionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  reaction?: Resolver<Maybe<ResolversTypes['Reaction']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddUserCompanyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddUserCompanyPayload'] = ResolversParentTypes['AddUserCompanyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  userCompany?: Resolver<Maybe<ResolversTypes['UserCompany']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiChatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiChat'] = ResolversParentTypes['AiChat']
> = {
  conversationText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  extId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiChatConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiChatConnection'] = ResolversParentTypes['AiChatConnection']
> = {
  edges?: Resolver<Array<ResolversTypes['AiChatEdge']>, ParentType, ContextType>
  nodes?: Resolver<Array<ResolversTypes['AiChat']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiChatEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiChatEdge'] = ResolversParentTypes['AiChatEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['AiChat'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiChatMessageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiChatMessage'] = ResolversParentTypes['AiChatMessage']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  data?: Resolver<Maybe<Array<ResolversTypes['JSON']>>, ParentType, ContextType>
  debug?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  documents?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPredefined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modeOptions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiExtensionAppResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiExtensionApp'] = ResolversParentTypes['AiExtensionApp']
> = {
  appLogo?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  appName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AiPersonalizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiPersonalization'] = ResolversParentTypes['AiPersonalization']
> = {
  additionalDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicableProgramsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApplicablePrograms'] = ResolversParentTypes['ApplicablePrograms']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApproveSubscriptionJoinRequestPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ApproveSubscriptionJoinRequestPayload'] = ResolversParentTypes['ApproveSubscriptionJoinRequestPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['SubscriptionJoinRequest']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Artifact'] = ResolversParentTypes['Artifact']
> = {
  allContributions?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactContribution']>>,
    ParentType,
    ContextType
  >
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  commentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['SanityCompany']>, ParentType, ContextType>
  companyLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyMaturity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyNumEmployees?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  draftLabelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  draftTemplateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  filters?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactFilter']>>,
    ParentType,
    ContextType
  >
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFree?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  summary?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  thumbnailPhoto?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topics?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactAnnotationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactAnnotation'] = ResolversParentTypes['ArtifactAnnotation']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  descriptionBody?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reactionsGroupedByName?: Resolver<
    Array<ResolversTypes['ArtifactAnnotationReactionGrouped']>,
    ParentType,
    ContextType
  >
  video?: Resolver<
    Maybe<ResolversTypes['ArtifactAnnotationVideo']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactAnnotationReactionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactAnnotationReaction'] = ResolversParentTypes['ArtifactAnnotationReaction']
> = {
  annotationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  artifactAnnotationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  artifactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactAnnotationReactionGroupedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactAnnotationReactionGrouped'] = ResolversParentTypes['ArtifactAnnotationReactionGrouped']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  currentUserHasReacted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactAnnotationVideoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactAnnotationVideo'] = ResolversParentTypes['ArtifactAnnotationVideo']
> = {
  playbackId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  thumbTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactAuthorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactAuthor'] = ResolversParentTypes['ArtifactAuthor']
> = {
  avatarPhoto?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  callToAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['SanityCompany']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isCollectiveMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  linkedinUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pastCompanies?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['ExpertUserProfile']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  workEntries?: Resolver<
    Maybe<Array<ResolversTypes['WorkEntry']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactComment'] = ResolversParentTypes['ArtifactComment']
> = {
  artifactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reactionsGroupedByName?: Resolver<
    Array<ResolversTypes['ArtifactCommentReactionGrouped']>,
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['ArtifactCommentUser'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactCommentReactionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactCommentReaction'] = ResolversParentTypes['ArtifactCommentReaction']
> = {
  annotationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  artifactCommentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  artifactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comment?: Resolver<ResolversTypes['ArtifactComment'], ParentType, ContextType>
  commentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactCommentReactionGroupedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactCommentReactionGrouped'] = ResolversParentTypes['ArtifactCommentReactionGrouped']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  currentUserHasReacted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactCommentUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactCommentUser'] = ResolversParentTypes['ArtifactCommentUser']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactContributionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactContribution'] = ResolversParentTypes['ArtifactContribution']
> = {
  artifact?: Resolver<Maybe<ResolversTypes['Artifact']>, ParentType, ContextType>
  artifactAuthor?: Resolver<
    Maybe<ResolversTypes['ArtifactAuthor']>,
    ParentType,
    ContextType
  >
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  contributionVerb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactContributorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactContributor'] = ResolversParentTypes['ArtifactContributor']
> = {
  allEntries?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contributionVerb?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCollectiveMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  relevantWorkEntry?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['ExpertUserProfile'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactFilterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactFilter'] = ResolversParentTypes['ArtifactFilter']
> = {
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactSubtopicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactSubtopic'] = ResolversParentTypes['ArtifactSubtopic']
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isDeprecated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastUpdate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  redirectTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  relatedBlogPosts?: Resolver<
    Maybe<Array<ResolversTypes['BlogPost']>>,
    ParentType,
    ContextType
  >
  relatedGuides?: Resolver<Maybe<Array<ResolversTypes['Unit']>>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  termDefinition?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  titlePlural?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topic?: Resolver<ResolversTypes['ArtifactTopic'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactTopicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactTopic'] = ResolversParentTypes['ArtifactTopic']
> = {
  icon?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isDeprecated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastUpdate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  redirectTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subtopicsToDisplay?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalArtifactCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactTopicFilterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactTopicFilter'] = ResolversParentTypes['ArtifactTopicFilter']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactUserDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactUserData'] = ResolversParentTypes['ArtifactUserData']
> = {
  savedArtifactsForUser?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  savedCountPerArtifact?: Resolver<
    Array<ResolversTypes['SavedCountPerArtifact']>,
    ParentType,
    ContextType
  >
  viewedArtifactsForUser?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactWithWorkEntryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactWithWorkEntry'] = ResolversParentTypes['ArtifactWithWorkEntry']
> = {
  allContributions?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactContribution']>>,
    ParentType,
    ContextType
  >
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  commentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['SanityCompany']>, ParentType, ContextType>
  companyLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyMaturity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyNumEmployees?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  draftLabelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  draftTemplateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  filters?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactFilter']>>,
    ParentType,
    ContextType
  >
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFree?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  summary?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  thumbnailPhoto?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topics?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  viewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  workEntry?: Resolver<Maybe<ResolversTypes['WorkEntry']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ArtifactsForSubtopicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ArtifactsForSubtopic'] = ResolversParentTypes['ArtifactsForSubtopic']
> = {
  artifacts?: Resolver<
    Array<ResolversTypes['ArtifactWithWorkEntry']>,
    ParentType,
    ContextType
  >
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignCohortCreditPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssignCohortCreditPayload'] = ResolversParentTypes['AssignCohortCreditPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  seat?: Resolver<Maybe<ResolversTypes['Seat']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignSeatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssignSeatPayload'] = ResolversParentTypes['AssignSeatPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  seat?: Resolver<Maybe<ResolversTypes['Seat']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttendeesFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AttendeesFeed'] = ResolversParentTypes['AttendeesFeed']
> = {
  attendees?: Resolver<
    Maybe<Array<ResolversTypes['EventAttendee']>>,
    ParentType,
    ContextType
  >
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatchPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BatchPayload'] = ResolversParentTypes['BatchPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatchRsvpTimeDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BatchRsvpTimeData'] = ResolversParentTypes['BatchRsvpTimeData']
> = {
  endsAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ids?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  startsAtUtc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timeGrouping?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BillingInformationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BillingInformation'] = ResolversParentTypes['BillingInformation']
> = {
  address?: Resolver<Maybe<ResolversTypes['StripeAddress']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  taxIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlogIndexResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogIndex'] = ResolversParentTypes['BlogIndex']
> = {
  blogPosts?: Resolver<Array<ResolversTypes['BlogPost']>, ParentType, ContextType>
  featuredBlogPosts?: Resolver<Array<ResolversTypes['BlogPost']>, ParentType, ContextType>
  nextBlogPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  prevBlogPostsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlogPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlogPost'] = ResolversParentTypes['BlogPost']
> = {
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  categories?: Resolver<
    Maybe<Array<ResolversTypes['BlogPostCategory']>>,
    ParentType,
    ContextType
  >
  contentBlock?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFeatured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  overview?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtopicIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>
  summaryImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  summaryText?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  tableOfContents?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlogPostCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BlogPostCategory'] = ResolversParentTypes['BlogPostCategory']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlogPostDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BlogPostDetails'] = ResolversParentTypes['BlogPostDetails']
> = {
  blogPost?: Resolver<ResolversTypes['BlogPost'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookmarkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Bookmark'] = ResolversParentTypes['Bookmark']
> = {
  anchor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  artifact?: Resolver<Maybe<ResolversTypes['Artifact']>, ParentType, ContextType>
  basedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cmsModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsSection?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  course?: Resolver<Maybe<ResolversTypes['MarketingCourse']>, ParentType, ContextType>
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAtInteger?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  event?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType>
  eventId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  externalUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  filedBookmarks?: Resolver<
    Array<ResolversTypes['FiledBookmark']>,
    ParentType,
    ContextType
  >
  guide?: Resolver<Maybe<ResolversTypes['Unit']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isWithinContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastViewedContentAtInteger?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  lessonPosition?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  mostRecentCmsActivityPerUser?: Resolver<
    Array<ResolversTypes['UserCmsActivity']>,
    ParentType,
    ContextType,
    RequireFields<BookmarkMostRecentCmsActivityPerUserArgs, 'collectionId'>
  >
  noteBody?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  numPeopleWhoBookmarked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  referenceImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startOffset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['BookmarkType'], ParentType, ContextType>
  updated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  videoCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookmarkFolderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BookmarkFolder'] = ResolversParentTypes['BookmarkFolder']
> = {
  bookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  forkedFromId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  goalComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  individualUsersSharedWith?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType
  >
  isShared?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isWhiteGlove?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  reforgeCollection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  sharedFolders?: Resolver<Array<ResolversTypes['SharedFolder']>, ParentType, ContextType>
  teamMembersWhoCompletedGoal?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >
  teamMembersWhoStartedGoal?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >
  teamProgress?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  usersSharedWith?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookmarksFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BookmarksFeed'] = ResolversParentTypes['BookmarksFeed']
> = {
  bookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  offset?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CancelPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CancelPayload'] = ResolversParentTypes['CancelPayload']
> = {
  attendee?: Resolver<Maybe<ResolversTypes['EventAttendee']>, ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CaseStudiesIndexResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CaseStudiesIndex'] = ResolversParentTypes['CaseStudiesIndex']
> = {
  caseStudies?: Resolver<Array<ResolversTypes['CaseStudy']>, ParentType, ContextType>
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CaseStudyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CaseStudy'] = ResolversParentTypes['CaseStudy']
> = {
  content?: Resolver<
    Maybe<Array<ResolversTypes['CaseStudyContentBlock']>>,
    ParentType,
    ContextType
  >
  contributors?: Resolver<
    Array<ResolversTypes['CaseStudyContributor']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  previewTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  useCases?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CaseStudyContentBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CaseStudyContentBlock'] = ResolversParentTypes['CaseStudyContentBlock']
> = {
  background?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contentBlock?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CaseStudyContributorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CaseStudyContributor'] = ResolversParentTypes['CaseStudyContributor']
> = {
  avatarPhoto?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  company?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CaseStudyDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CaseStudyDetails'] = ResolversParentTypes['CaseStudyDetails']
> = {
  caseStudy?: Resolver<ResolversTypes['CaseStudy'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclArtifactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclArtifact'] = ResolversParentTypes['CclArtifact']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclBlogPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclBlogPost'] = ResolversParentTypes['CclBlogPost']
> = {
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  cclProfileIds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  firstPublishedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summary?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclCompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclCompany'] = ResolversParentTypes['CclCompany']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  wordmarkLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclCourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclCourse'] = ResolversParentTypes['CclCourse']
> = {
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  blocks?: Resolver<
    Maybe<Array<ResolversTypes['CclCourseBlock']>>,
    ParentType,
    ContextType
  >
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  completionPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  creators?: Resolver<Maybe<Array<ResolversTypes['CclExpert']>>, ParentType, ContextType>
  currentSession?: Resolver<
    Maybe<ResolversTypes['CclCourseSession']>,
    ParentType,
    ContextType
  >
  exampleCompanies?: Resolver<
    Maybe<Array<ResolversTypes['CclCompany']>>,
    ParentType,
    ContextType
  >
  focus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  heroImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  legacyCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  live?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  onDemand?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  outlineComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  pastSessions?: Resolver<
    Maybe<Array<ResolversTypes['CclCourseSession']>>,
    ParentType,
    ContextType
  >
  publishedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  staticId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  testimonials?: Resolver<
    Maybe<Array<ResolversTypes['CclReview']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<Maybe<Array<ResolversTypes['CclTopic']>>, ParentType, ContextType>
  trailerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  upcomingSessions?: Resolver<
    Maybe<Array<ResolversTypes['CclCourseSession']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclCourseBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclCourseBlock'] = ResolversParentTypes['CclCourseBlock']
> = {
  artifacts?: Resolver<
    Maybe<Array<ResolversTypes['CclArtifact']>>,
    ParentType,
    ContextType
  >
  contentBlocks?: Resolver<
    Maybe<Array<ResolversTypes['ContentBlock']>>,
    ParentType,
    ContextType
  >
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  displayOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  eventPreviews?: Resolver<
    Maybe<Array<ResolversTypes['CclVideo']>>,
    ParentType,
    ContextType
  >
  events?: Resolver<
    Maybe<Array<ResolversTypes['CclSessionEvent']>>,
    ParentType,
    ContextType
  >
  guides?: Resolver<Maybe<Array<ResolversTypes['CclGuide']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previewable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclCourseSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclCourseSession'] = ResolversParentTypes['CclCourseSession']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  enrollmentStartsAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  events?: Resolver<
    Maybe<Array<ResolversTypes['CclSessionEvent']>>,
    ParentType,
    ContextType
  >
  experts?: Resolver<Maybe<Array<ResolversTypes['CclExpert']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDuringEnrollmentPeriod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEnrollmentFull?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maxEnrollmentPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclExpertResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclExpert'] = ResolversParentTypes['CclExpert']
> = {
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCollectiveMember?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclGuideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclGuide'] = ResolversParentTypes['CclGuide']
> = {
  creators?: Resolver<Maybe<Array<ResolversTypes['CclExpert']>>, ParentType, ContextType>
  displayOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  estimatedMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  freePreview?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  overview?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publishedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  staticId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stepsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclReview'] = ResolversParentTypes['CclReview']
> = {
  company?: Resolver<Maybe<ResolversTypes['CclCompany']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclSessionEventResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CclSessionEvent'] = ResolversParentTypes['CclSessionEvent']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  locationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclTopicResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclTopic'] = ResolversParentTypes['CclTopic']
> = {
  iconAltText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeprecated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CclVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CclVideo'] = ResolversParentTypes['CclVideo']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  durationSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CertificatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Certificates'] = ResolversParentTypes['Certificates']
> = {
  completed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pdfUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatQuery'] = ResolversParentTypes['ChatQuery']
> = {
  __resolveType: TypeResolveFn<'Query', ParentType, ContextType>
  aiChatUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  aiChats?: Resolver<
    Array<ResolversTypes['AiChat']>,
    ParentType,
    ContextType,
    RequireFields<ChatQueryAiChatsArgs, 'userId'>
  >
  aiSessionHistory?: Resolver<
    Array<ResolversTypes['AiChatMessage']>,
    ParentType,
    ContextType,
    RequireFields<ChatQueryAiSessionHistoryArgs, 'sessionId'>
  >
  exchangeChatIdForExtId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<ChatQueryExchangeChatIdForExtIdArgs, 'chatId'>
  >
  recentChats?: Resolver<
    ResolversTypes['AiChatConnection'],
    ParentType,
    ContextType,
    Partial<ChatQueryRecentChatsArgs>
  >
  recentChatsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  suggestedPrompts?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<ChatQuerySuggestedPromptsArgs>
  >
}

export type CheckoutPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CheckoutPayload'] = ResolversParentTypes['CheckoutPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  stripeSubscriptionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CheckoutProrationAdjustmentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CheckoutProrationAdjustment'] = ResolversParentTypes['CheckoutProrationAdjustment']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  amountForNewPlanRemainingTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endDate?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  paidTaxOnPrevPlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChoosePlanPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChoosePlanPayload'] = ResolversParentTypes['ChoosePlanPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  upcomingInvoice?: Resolver<
    Maybe<ResolversTypes['StripeUpcomingInvoice']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClearbitCompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ClearbitCompany'] = ResolversParentTypes['ClearbitCompany']
> = {
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Clip'] = ResolversParentTypes['Clip']
> = {
  clipSubtype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hasBeenViewedByCurrentUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastViewedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  summaryWistiaCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsContent'] = ResolversParentTypes['CmsContent']
> = {
  bookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inlinePostAnchors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsModuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CmsModule'] = ResolversParentTypes['CmsModule']
> = {
  anyCountsTowardsCompletion?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  clips?: Resolver<Maybe<Array<ResolversTypes['Clip']>>, ParentType, ContextType>
  cmsSections?: Resolver<Array<ResolversTypes['CmsSection']>, ParentType, ContextType>
  containsPreviewableLessons?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  estimatedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  featuredGuests?: Resolver<
    Array<ResolversTypes['EventSpeaker']>,
    ParentType,
    ContextType
  >
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lessonCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numBookmarks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numLessons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  programCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  programIconImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  programImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  progressPercent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  project?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  publishAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  published?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  showFeedback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalBookmarks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsProgramResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsProgram'] = ResolversParentTypes['CmsProgram']
> = {
  bookmarks?: Resolver<
    Array<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType,
    Partial<CmsProgramBookmarksArgs>
  >
  byTheEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  canEnroll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  canEnrollForCurrentSeason?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  categoryNames?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cclStaticCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cmsModules?: Resolver<Array<ResolversTypes['CmsModule']>, ParentType, ContextType>
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cohort?: Resolver<Maybe<ResolversTypes['Cohort']>, ParentType, ContextType>
  collaborators?: Resolver<Array<ResolversTypes['Host']>, ParentType, ContextType>
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  contentIsInMySavedItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  currentModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  deprecatedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enrolled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  estimatedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hasPreviewableContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  heroImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  launchAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  launched?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lessonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  longDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  noLessons?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  notLaunched?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  numBookmarks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  outcomes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previewableLessonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  programImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  programNotification?: Resolver<
    Maybe<ResolversTypes['CmsProgramNotification']>,
    ParentType,
    ContextType
  >
  progressPercent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  purchased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  recommendationRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  relatedPrograms?: Resolver<Array<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  releaseEnrollmentDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  releaseSeasonDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  releaseStartDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  season?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  seasonNameFormatted?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shortDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showNewBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  synopsis?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  templates?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  tentativeUpcomingCohorts?: Resolver<
    Maybe<Array<ResolversTypes['TentativeUpcomingCohort']>>,
    ParentType,
    ContextType
  >
  testimonials?: Resolver<Array<ResolversTypes['Testimonial']>, ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  upcoming?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  whatYouLearned?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  whoItsFor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsProgramExpertsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsProgramExpertsPayload'] = ResolversParentTypes['CmsProgramExpertsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsProgramId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  experts?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  possibleExperts?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsProgramNotificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsProgramNotification'] = ResolversParentTypes['CmsProgramNotification']
> = {
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  notificationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsSectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsSection'] = ResolversParentTypes['CmsSection']
> = {
  accessLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  blockedContentHtmlSafe?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  blockedDescriptionHtmlSafe?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  bookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType,
    Partial<CmsSectionBookmarksArgs>
  >
  children?: Resolver<Array<ResolversTypes['CmsSection']>, ParentType, ContextType>
  childrenCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  cmsModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  cmsModuleId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  cmsProgram?: Resolver<ResolversTypes['CmsProgram'], ParentType, ContextType>
  cmsProgramId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  completedChildrenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  conceptIcon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  contentIsInMySavedItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  contentSubtype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentType?: Resolver<
    Maybe<ResolversTypes['CmsSectionContentType']>,
    ParentType,
    ContextType
  >
  contentTypeIcon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countsTowardsCompletion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  currentUserSubmittedFeedback?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  deliverableExamples?: Resolver<
    Array<ResolversTypes['ProjectDeliverable']>,
    ParentType,
    ContextType
  >
  descriptionHtmlSafe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  descriptionText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  estimatedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  groups?: Resolver<
    ResolversTypes['GroupConnection'],
    ParentType,
    ContextType,
    Partial<CmsSectionGroupsArgs>
  >
  heroImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  introHtmlSafe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isResource?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isUnit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastViewedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lessonNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nextSection?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  nextSectionLearningCmsSectionPath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  nextSectionNameAndPath?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  numBookmarks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numPhases?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parent?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  path?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  previewable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  progressPercent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  projectIcon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publishAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  published?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  relatedContent?: Resolver<
    Maybe<Array<ResolversTypes['CmsSection']>>,
    ParentType,
    ContextType
  >
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  showFeedback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showNewBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summaryHtmlSafe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['CmsSectionTag']>>, ParentType, ContextType>
  toc?: Resolver<Array<ResolversTypes['ContentTocItem']>, ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['PostTopic']>, ParentType, ContextType>
  truncatedDescriptionHtml?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  unlimitedAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  wistiaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CmsSectionTagResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CmsSectionTag'] = ResolversParentTypes['CmsSectionTag']
> = {
  cmsSectionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tag?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CoachingSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CoachingSession'] = ResolversParentTypes['CoachingSession']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  externalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  prompt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Cohort'] = ResolversParentTypes['Cohort']
> = {
  batchRsvpTimeData?: Resolver<
    Array<ResolversTypes['BatchRsvpTimeData']>,
    ParentType,
    ContextType
  >
  cmsProgram?: Resolver<ResolversTypes['CmsProgram'], ParentType, ContextType>
  cohortCollections?: Resolver<
    Array<ResolversTypes['CohortBookmarkFolder']>,
    ParentType,
    ContextType
  >
  coreWeeksHaveEnded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  durationInWeeks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eventCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  eventTimes?: Resolver<
    Maybe<Array<ResolversTypes['CohortEventTimes']>>,
    ParentType,
    ContextType
  >
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  gettingStartedModuleForSeason?: Resolver<
    Maybe<ResolversTypes['CmsModule']>,
    ParentType,
    ContextType,
    RequireFields<CohortGettingStartedModuleForSeasonArgs, 'slug'>
  >
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType>
  hasCohortOffboardingStarted?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasEnded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hosts?: Resolver<Maybe<Array<ResolversTypes['Host']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isOngoing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isUpcoming?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kickoffEvents?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  marketingSiteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nextWeek?: Resolver<Maybe<ResolversTypes['ScheduledWeek']>, ParentType, ContextType>
  numUniqCohortEvents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  postCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  postCoreWeeksEnrollmentIsOpen?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  postKickoff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  programCollaborators?: Resolver<
    Maybe<Array<ResolversTypes['Host']>>,
    ParentType,
    ContextType
  >
  scheduledWeeks?: Resolver<
    Array<ResolversTypes['ScheduledWeek']>,
    ParentType,
    ContextType
  >
  season?: Resolver<ResolversTypes['Season'], ParentType, ContextType>
  shouldShowNpsSurvey?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  slackChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  snapshotReleased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thisWeek?: Resolver<Maybe<ResolversTypes['ScheduledWeek']>, ParentType, ContextType>
  weekCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortActivityListItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortActivityListItem'] = ResolversParentTypes['CohortActivityListItem']
> = {
  actionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  activityIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  agoTimephrase?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  anyActivityUnread?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  latestActivityCreatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postBodyText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  postTopicSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postTopicTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['ReactionKind']>, ParentType, ContextType>
  replies?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  unreadActivityIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >
  userAvatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortActivityListWithMetadataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortActivityListWithMetadata'] = ResolversParentTypes['CohortActivityListWithMetadata']
> = {
  activities?: Resolver<
    Array<ResolversTypes['CohortActivityListItem']>,
    ParentType,
    ContextType
  >
  unreadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortBookmarkFolderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortBookmarkFolder'] = ResolversParentTypes['CohortBookmarkFolder']
> = {
  bookmarkFolderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortCertificateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortCertificate'] = ResolversParentTypes['CohortCertificate']
> = {
  certificateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortCreditResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortCredit'] = ResolversParentTypes['CohortCredit']
> = {
  expiresAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seat?: Resolver<Maybe<ResolversTypes['Seat']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  useable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortCreditsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortCredits'] = ResolversParentTypes['CohortCredits']
> = {
  all?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  assignable?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  expired?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  unassigned?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  unexpired?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  unused?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  useable?: Resolver<Array<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortEventTimesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortEventTimes'] = ResolversParentTypes['CohortEventTimes']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  firstEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstStartDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortPassPriceTierResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortPassPriceTier'] = ResolversParentTypes['CohortPassPriceTier']
> = {
  maxCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  pricePerPass?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  stripeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortPost'] = ResolversParentTypes['CohortPost']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  pinnedPosts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  posts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  topic?: Resolver<ResolversTypes['PostTopic'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CohortTeamResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CohortTeam'] = ResolversParentTypes['CohortTeam']
> = {
  activities?: Resolver<
    Array<ResolversTypes['CohortActivityListItem']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  members?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CollectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Collection'] = ResolversParentTypes['Collection']
> = {
  contentTypes?: Resolver<
    Array<ResolversTypes['ReforgeContent']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['RecommendedItem']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CollectionActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CollectionActivity'] = ResolversParentTypes['CollectionActivity']
> = {
  actionText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  actionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bookmarkFolderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  filedBookmark?: Resolver<
    Maybe<ResolversTypes['FiledBookmark']>,
    ParentType,
    ContextType
  >
  hasRestoreAction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CollectionActivityListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CollectionActivityList'] = ResolversParentTypes['CollectionActivityList']
> = {
  collectionActivities?: Resolver<
    Array<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType
  >
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CollectionsQueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CollectionsQuery'] = ResolversParentTypes['CollectionsQuery']
> = {
  __resolveType: TypeResolveFn<'Query', ParentType, ContextType>
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType,
    RequireFields<CollectionsQueryBookmarkFolderArgs, 'folderId'>
  >
  bookmarksFromFolder?: Resolver<
    Array<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType,
    RequireFields<CollectionsQueryBookmarksFromFolderArgs, 'folderId'>
  >
  mostRecentCollectionActivityPerUser?: Resolver<
    Array<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType,
    RequireFields<CollectionsQueryMostRecentCollectionActivityPerUserArgs, 'collectionId'>
  >
}

export type CommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bodyText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  commentableId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  post?: Resolver<Maybe<ResolversTypes['Post']>, ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>
  rgt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']
> = {
  cardLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompleteProductTourPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CompleteProductTourPayload'] = ResolversParentTypes['CompleteProductTourPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  completedProductTours?: Resolver<ResolversTypes['ProductTour'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompletedFeedbackResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CompletedFeedback'] = ResolversParentTypes['CompletedFeedback']
> = {
  postApplySurvey?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompletionSnapshotResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CompletionSnapshot'] = ResolversParentTypes['CompletionSnapshot']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cohortId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  hashtag?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imagePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isReleased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  programName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seasonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  snapshotShareUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userCohortId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfirmUserMergePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConfirmUserMergePayload'] = ResolversParentTypes['ConfirmUserMergePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userMerge?: Resolver<Maybe<ResolversTypes['UserMerge']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']
> = {
  accessLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSection?: Resolver<ResolversTypes['CmsSection'], ParentType, ContextType>
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  contentIsInMySavedItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  showNewBadge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContentBlock'] = ResolversParentTypes['ContentBlock']
> = {
  contentBlockCollapsible?: Resolver<
    Maybe<Array<ResolversTypes['ContentBlockCollapsible']>>,
    ParentType,
    ContextType
  >
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentBlockCollapsibleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContentBlockCollapsible'] = ResolversParentTypes['ContentBlockCollapsible']
> = {
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContentFeed'] = ResolversParentTypes['ContentFeed']
> = {
  cmsModuleId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  content?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  topics?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  userProgram?: Resolver<Maybe<ResolversTypes['UserProgram']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentTocItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContentTocItem'] = ResolversParentTypes['ContentTocItem']
> = {
  children?: Resolver<
    Maybe<Array<ResolversTypes['ContentTocItem']>>,
    ParentType,
    ContextType
  >
  contentTypeIcon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  current?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  estimatedTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  href?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSidebar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  numBookmarks?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  phaseNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentViewerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContentViewer'] = ResolversParentTypes['ContentViewer']
> = {
  cmsContent?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  cmsContentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsProjectDeliverable?: Resolver<
    Maybe<ResolversTypes['ProjectDeliverable']>,
    ParentType,
    ContextType
  >
  contentBookmarkAnchors?: Resolver<
    Array<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType
  >
  contentBookmarkAnchorsInMySavedItems?: Resolver<
    Array<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType
  >
  expertUsers?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  isSidebar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  userProgram?: Resolver<Maybe<ResolversTypes['UserProgram']>, ParentType, ContextType>
  viewAccess?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CookieConsentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CookieConsent'] = ResolversParentTypes['CookieConsent']
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Course'] = ResolversParentTypes['Course']
> = {
  bookmark?: Resolver<Maybe<ResolversTypes['Bookmark']>, ParentType, ContextType>
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  completionPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  courseBlocks?: Resolver<
    Maybe<Array<ResolversTypes['CourseBlock']>>,
    ParentType,
    ContextType
  >
  creators?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAvailableOnDemand?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  isEnrollmentOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  sanityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  whatYouWillLearn?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  whoThisIsFor?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseBenefitResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseBenefit'] = ResolversParentTypes['CourseBenefit']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  icon?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseBlock'] = ResolversParentTypes['CourseBlock']
> = {
  artifacts?: Resolver<Maybe<Array<ResolversTypes['Artifact']>>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  eventPreviews?: Resolver<
    Maybe<Array<ResolversTypes['CourseEvent']>>,
    ParentType,
    ContextType
  >
  events?: Resolver<Maybe<Array<ResolversTypes['CourseEvent']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previewable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  readByDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  units?: Resolver<Maybe<Array<ResolversTypes['Unit']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseCertificateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseCertificate'] = ResolversParentTypes['CourseCertificate']
> = {
  certificateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseCompletionSnapshotResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseCompletionSnapshot'] = ResolversParentTypes['CourseCompletionSnapshot']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedinShareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  twitterShareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseConceptsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseConcepts'] = ResolversParentTypes['CourseConcepts']
> = {
  functions?: Resolver<Maybe<Array<ResolversTypes['Function']>>, ParentType, ContextType>
  topics?: Resolver<Maybe<Array<ResolversTypes['Topic']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseDetails'] = ResolversParentTypes['CourseDetails']
> = {
  course?: Resolver<ResolversTypes['UnifiedCourse'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseEnrollmentConfirmedPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseEnrollmentConfirmedPayload'] = ResolversParentTypes['CourseEnrollmentConfirmedPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseEnrollmentSnapshotResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseEnrollmentSnapshot'] = ResolversParentTypes['CourseEnrollmentSnapshot']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedinShareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  twitterShareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseEnrollmentStartedPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseEnrollmentStartedPayload'] = ResolversParentTypes['CourseEnrollmentStartedPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseEventResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseEvent'] = ResolversParentTypes['CourseEvent']
> = {
  endsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  eventPerCourseSession?: Resolver<
    Maybe<Array<ResolversTypes['EventPerCourseSession']>>,
    ParentType,
    ContextType
  >
  featuredGuests?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  hosts?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  joinLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recording?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  sanityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseForTopicAndFunctionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseForTopicAndFunction'] = ResolversParentTypes['CourseForTopicAndFunction']
> = {
  creators?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shortDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  testimonials?: Resolver<
    Maybe<Array<ResolversTypes['SanityTestimonial']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  whatYouWillLearn?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseIndexResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseIndex'] = ResolversParentTypes['CourseIndex']
> = {
  courses?: Resolver<Array<ResolversTypes['MarketingCourse']>, ParentType, ContextType>
  page?: Resolver<ResolversTypes['CourseIndexPage'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseIndexContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseIndexContent'] = ResolversParentTypes['CourseIndexContent']
> = {
  courseBenefits?: Resolver<
    Array<ResolversTypes['CourseBenefit']>,
    ParentType,
    ContextType
  >
  ctaLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ctaText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  heading?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  heroImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  logos?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>
  subheading?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseIndexPageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseIndexPage'] = ResolversParentTypes['CourseIndexPage']
> = {
  content?: Resolver<ResolversTypes['CourseIndexContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseInfoCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseInfoCard'] = ResolversParentTypes['CourseInfoCard']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CoursePreviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CoursePreview'] = ResolversParentTypes['CoursePreview']
> = {
  courseBlocks?: Resolver<
    Maybe<Array<ResolversTypes['CourseBlock']>>,
    ParentType,
    ContextType
  >
  creators?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  upcomingSession?: Resolver<
    Maybe<ResolversTypes['CourseSession']>,
    ParentType,
    ContextType
  >
  whoThisIsFor?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseSession'] = ResolversParentTypes['CourseSession']
> = {
  courseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  courseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  courseTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  endsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  enrollmentStartsAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  gettingStartedBlock?: Resolver<
    Maybe<ResolversTypes['GettingStartedBlock']>,
    ParentType,
    ContextType
  >
  hasEnded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasStarted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hosts?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isContentOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isCurrent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isDuringEnrollmentPeriod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEnrollmentFull?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isHostOrCreator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isUpcoming?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  markYourCalendar?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  maxEnrollmentPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxEnrollmentTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxEnrollmentUnlimited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledUnlimited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  overallDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  slackChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  surveyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  wrapUp?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseSessionDetailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseSessionDetail'] = ResolversParentTypes['CourseSessionDetail']
> = {
  dbCourseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  dbCourseSessionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  dbCourseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dbCourseTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dbEndsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  dbEnrollmentStartsAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  dbHostAccessAllowedForUserIds?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  dbMaxEnrollmentPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dbMaxEnrollmentTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dbMaxEnrollmentUnlimited?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  dbPrice?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dbSanityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dbSessionGroupId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dbSlackChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dbStartsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  dbStripeProductId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanityCourseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanityCourseSessionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  sanityCourseSessionTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  sanityCourseTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanityDuration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanityEndsAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  sanitySlackChannelId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  sanityStartsAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseSnapshotResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseSnapshot'] = ResolversParentTypes['CourseSnapshot']
> = {
  courseDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  courseName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  courseSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CourseTopicCoveredResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourseTopicCovered'] = ResolversParentTypes['CourseTopicCovered']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  heading?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateAccountPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateAccountPayload'] = ResolversParentTypes['CreateAccountPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currentUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateBookmarkFolderPayload'] = ResolversParentTypes['CreateBookmarkFolderPayload']
> = {
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateBookmarkPayload'] = ResolversParentTypes['CreateBookmarkPayload']
> = {
  bookmark?: Resolver<Maybe<ResolversTypes['Bookmark']>, ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionActivity?: Resolver<
    Maybe<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType
  >
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateCohortPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCohortPostPayload'] = ResolversParentTypes['CreateCohortPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateCohortReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCohortReplyPayload'] = ResolversParentTypes['CreateCohortReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateCourseEnrollmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCourseEnrollmentPayload'] = ResolversParentTypes['CreateCourseEnrollmentPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  courseSessionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  hasAssignableCohortPasses?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  redirectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateCourseSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCourseSessionPayload'] = ResolversParentTypes['CreateCourseSessionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateCourseSessionSlackChannelPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateCourseSessionSlackChannelPayload'] = ResolversParentTypes['CreateCourseSessionSlackChannelPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateDraftSubscriptionJoinRequestPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateDraftSubscriptionJoinRequestPayload'] = ResolversParentTypes['CreateDraftSubscriptionJoinRequestPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['SubscriptionJoinRequest']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateFiledBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateFiledBookmarkPayload'] = ResolversParentTypes['CreateFiledBookmarkPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  filedBookmark?: Resolver<
    Maybe<ResolversTypes['FiledBookmark']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreatePayload'] = ResolversParentTypes['CreatePayload']
> = {
  attendee?: Resolver<Maybe<ResolversTypes['EventAttendee']>, ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateProgramApplicationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateProgramApplicationPayload'] = ResolversParentTypes['CreateProgramApplicationPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateProgramInvitationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateProgramInvitationPayload'] = ResolversParentTypes['CreateProgramInvitationPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateRailsSubscriptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateRailsSubscriptionPayload'] = ResolversParentTypes['CreateRailsSubscriptionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateRecommendationsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateRecommendationsPayload'] = ResolversParentTypes['CreateRecommendationsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateReplyPayload'] = ResolversParentTypes['CreateReplyPayload']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bodyText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  parentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userRoleWithCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateSubscriptionJoinRequestPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateSubscriptionJoinRequestPayload'] = ResolversParentTypes['CreateSubscriptionJoinRequestPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['SubscriptionJoinRequest']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateTeamPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTeamPostPayload'] = ResolversParentTypes['CreateTeamPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateTestUsersPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateTestUsersPayload'] = ResolversParentTypes['CreateTestUsersPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  subscriptionMembers?: Resolver<
    Maybe<Array<ResolversTypes['SubscriptionMember']>>,
    ParentType,
    ContextType
  >
  subscriptionOwner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateUserClipActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateUserClipActivityPayload'] = ResolversParentTypes['CreateUserClipActivityPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateUserCmsActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateUserCmsActivityPayload'] = ResolversParentTypes['CreateUserCmsActivityPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateUserEventActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateUserEventActivityPayload'] = ResolversParentTypes['CreateUserEventActivityPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Creator'] = ResolversParentTypes['Creator']
> = {
  avatarPhoto?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  expert?: Resolver<Maybe<ResolversTypes['ArtifactAuthor']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CtaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Cta'] = ResolversParentTypes['Cta']
> = {
  ctaLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ctaText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeclineSubscriptionJoinRequestPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeclineSubscriptionJoinRequestPayload'] = ResolversParentTypes['DeclineSubscriptionJoinRequestPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subscriptionJoinRequest?: Resolver<
    ResolversTypes['SubscriptionJoinRequest'],
    ParentType,
    ContextType
  >
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteBookmarkFolderPayload'] = ResolversParentTypes['DeleteBookmarkFolderPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  forkedFromId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteBookmarkPayload'] = ResolversParentTypes['DeleteBookmarkPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteCohortPassPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteCohortPassPayload'] = ResolversParentTypes['DeleteCohortPassPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteCohortPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteCohortPostPayload'] = ResolversParentTypes['DeleteCohortPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteCohortReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteCohortReplyPayload'] = ResolversParentTypes['DeleteCohortReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteCustomerTaxIdPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteCustomerTaxIdPayload'] = ResolversParentTypes['DeleteCustomerTaxIdPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteEmailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteEmailPayload'] = ResolversParentTypes['DeleteEmailPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteFiledBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteFiledBookmarkPayload'] = ResolversParentTypes['DeleteFiledBookmarkPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionActivity?: Resolver<
    Maybe<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType
  >
  destroyedBookmark?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  filedBookmark?: Resolver<
    Maybe<ResolversTypes['FiledBookmark']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletePaymentSourcePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeletePaymentSourcePayload'] = ResolversParentTypes['DeletePaymentSourcePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteTeamPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteTeamPostPayload'] = ResolversParentTypes['DeleteTeamPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteUserCompanyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUserCompanyPayload'] = ResolversParentTypes['DeleteUserCompanyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DuplicateAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DuplicateAccount'] = ResolversParentTypes['DuplicateAccount']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EndSubscriptionTrialEarlyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EndSubscriptionTrialEarlyPayload'] = ResolversParentTypes['EndSubscriptionTrialEarlyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportCourse'] = ResolversParentTypes['EngagementReportCourse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  participants?: Resolver<
    Array<ResolversTypes['EngagementReportParticipant']>,
    ParentType,
    ContextType
  >
  synopsis?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportLessonResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportLesson'] = ResolversParentTypes['EngagementReportLesson']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastViewedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  numViews?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  programName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportParticipantResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportParticipant'] = ResolversParentTypes['EngagementReportParticipant']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportSavedItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportSavedItem'] = ResolversParentTypes['EngagementReportSavedItem']
> = {
  cmsSectionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportSummary'] = ResolversParentTypes['EngagementReportSummary']
> = {
  contentViewedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  coursesWorkedOnCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  insightsSavedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  liveCourseEnrollmentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  recentLessons?: Resolver<
    Array<ResolversTypes['EngagementReportLesson']>,
    ParentType,
    ContextType
  >
  recentSavedItems?: Resolver<
    Array<ResolversTypes['EngagementReportSavedItem']>,
    ParentType,
    ContextType
  >
  topCourses?: Resolver<
    Array<ResolversTypes['EngagementReportCourse']>,
    ParentType,
    ContextType
  >
  topLessons?: Resolver<
    Array<ResolversTypes['EngagementReportLesson']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportTeamResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportTeam'] = ResolversParentTypes['EngagementReportTeam']
> = {
  teamMembers?: Resolver<
    Array<ResolversTypes['EngagementReportTeamMember']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EngagementReportTeamMemberResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EngagementReportTeamMember'] = ResolversParentTypes['EngagementReportTeamMember']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contentViewed?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  contentViewedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  coursesWorkedOn?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  coursesWorkedOnCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  currentCourseName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  insightsSaved?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  insightsSavedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  liveCoursesTaken?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  liveCoursesTakenCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']
> = {
  attendees?: Resolver<
    ResolversTypes['EventAttendeeConnection'],
    ParentType,
    ContextType,
    Partial<EventAttendeesArgs>
  >
  attendeesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  attendingStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  caseCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  cmsModuleId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsProgramId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cmsSectionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cohorts?: Resolver<Array<ResolversTypes['Cohort']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  endsAtUtc?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventFormat?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventPrework?: Resolver<Maybe<ResolversTypes['EventPrework']>, ParentType, ContextType>
  eventPreworkSubmission?: Resolver<
    Maybe<ResolversTypes['EventPreworkSubmission']>,
    ParentType,
    ContextType
  >
  featuredGuests?: Resolver<
    Array<ResolversTypes['EventSpeaker']>,
    ParentType,
    ContextType
  >
  featuredImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  googleCalendarEndsAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  googleCalendarStartsAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hosts?: Resolver<Array<ResolversTypes['EventSpeaker']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFeaturedGuest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastViewedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  past?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  prep?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  preread?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recordingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rsvp?: Resolver<Maybe<ResolversTypes['EventAttendee']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  speakers?: Resolver<
    Maybe<Array<ResolversTypes['EventSpeaker']>>,
    ParentType,
    ContextType
  >
  startsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  startsAtUtc?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  summaryTranscript?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  summaryWistiaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timeUntil?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  uuid?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  willBeRecorded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  zoomLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventAttendeeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventAttendee'] = ResolversParentTypes['EventAttendee']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  hasBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventAttendeeConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventAttendeeConnection'] = ResolversParentTypes['EventAttendeeConnection']
> = {
  edges?: Resolver<Array<ResolversTypes['EventAttendeeEdge']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventAttendeeEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventAttendeeEdge'] = ResolversParentTypes['EventAttendeeEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EventAttendee'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventPerCourseSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventPerCourseSession'] = ResolversParentTypes['EventPerCourseSession']
> = {
  courseSession?: Resolver<
    Maybe<ResolversTypes['CourseSession']>,
    ParentType,
    ContextType
  >
  endsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  eventFormat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  featuredGuests?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  hosts?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  joinLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventPreworkResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventPrework'] = ResolversParentTypes['EventPrework']
> = {
  applicationOpen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  featuredGuestSlots?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  helperText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  includeTemplateLink?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  instructions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  preworkContext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  submissionDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  submissionPrompt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  submissionTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  templateLinkCTA?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  templateLinkUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventPreworkSubmissionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventPreworkSubmission'] = ResolversParentTypes['EventPreworkSubmission']
> = {
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventRelatedResourcesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventRelatedResources'] = ResolversParentTypes['EventRelatedResources']
> = {
  __resolveType: TypeResolveFn<
    'Artifact' | 'CclCourse' | 'MarketingCourse',
    ParentType,
    ContextType
  >
}

export type EventSpeakerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventSpeaker'] = ResolversParentTypes['EventSpeaker']
> = {
  eventId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  hostedEventsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EventsPastFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EventsPastFeed'] = ResolversParentTypes['EventsPastFeed']
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  events?: Resolver<Maybe<Array<ResolversTypes['Event']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExpertUserProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpertUserProfile'] = ResolversParentTypes['ExpertUserProfile']
> = {
  aboutMeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allBlogPosts?: Resolver<Array<ResolversTypes['CclBlogPost']>, ParentType, ContextType>
  allCourses?: Resolver<Array<ResolversTypes['CclCourse']>, ParentType, ContextType>
  allGuides?: Resolver<Array<ResolversTypes['CclGuide']>, ParentType, ContextType>
  allHostedEvents?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  can?: Resolver<ResolversTypes['UserCan'], ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  is?: Resolver<ResolversTypes['UserIs'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previousCompanies?: Resolver<
    Array<ResolversTypes['UserCompany']>,
    ParentType,
    ContextType
  >
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  upcomingEvents?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  upcomingLiveCourses?: Resolver<
    Array<ResolversTypes['CclCourse']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExpertsIndexResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpertsIndex'] = ResolversParentTypes['ExpertsIndex']
> = {
  experts?: Resolver<Array<ResolversTypes['ArtifactAuthor']>, ParentType, ContextType>
  page?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FaqResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Faq'] = ResolversParentTypes['Faq']
> = {
  answer?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  questionNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  seasonId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeaturedPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FeaturedPost'] = ResolversParentTypes['FeaturedPost']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  post?: Resolver<ResolversTypes['Post'], ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeedbacksPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FeedbacksPayload'] = ResolversParentTypes['FeedbacksPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  feedbackId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FiledBookmarkResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FiledBookmark'] = ResolversParentTypes['FiledBookmark']
> = {
  bookmark?: Resolver<ResolversTypes['Bookmark'], ParentType, ContextType>
  bookmarkFolderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  bookmarkId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FilteredArtifactsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FilteredArtifacts'] = ResolversParentTypes['FilteredArtifacts']
> = {
  artifacts?: Resolver<Array<ResolversTypes['Artifact']>, ParentType, ContextType>
  nextArtifactsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  prevArtifactsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalArtifactsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FilteredUserSearchResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FilteredUserSearch'] = ResolversParentTypes['FilteredUserSearch']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  companyAudience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mostRecentProgram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FilteredUserSearchListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FilteredUserSearchList'] = ResolversParentTypes['FilteredUserSearchList']
> = {
  members?: Resolver<Array<ResolversTypes['FilteredUserSearch']>, ParentType, ContextType>
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FlagArtifactCommentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FlagArtifactCommentPayload'] = ResolversParentTypes['FlagArtifactCommentPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ForkBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ForkBookmarkFolderPayload'] = ResolversParentTypes['ForkBookmarkFolderPayload']
> = {
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  newForkedBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ForumContributionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ForumContribution'] = ResolversParentTypes['ForumContribution']
> = {
  __resolveType: TypeResolveFn<'Comment' | 'Post' | 'Reply', ParentType, ContextType>
}

export type FunctionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Function'] = ResolversParentTypes['Function']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isDeprecated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  redirectTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenerateAiGoalsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateAiGoalsPayload'] = ResolversParentTypes['GenerateAiGoalsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coachingSession?: Resolver<
    Maybe<ResolversTypes['CoachingSession']>,
    ParentType,
    ContextType
  >
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenerateAiInferencePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateAiInferencePayload'] = ResolversParentTypes['GenerateAiInferencePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GettingStartedBlockResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GettingStartedBlock'] = ResolversParentTypes['GettingStartedBlock']
> = {
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  gettingStartedCollapsible?: Resolver<
    Maybe<Array<ResolversTypes['GettingStartedCollapsible']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GettingStartedCollapsibleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GettingStartedCollapsible'] = ResolversParentTypes['GettingStartedCollapsible']
> = {
  description?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']
> = {
  groupCategory?: Resolver<
    Maybe<ResolversTypes['GroupCategory']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  industries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>
  memberStack?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  membersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  populatedIndustries?: Resolver<
    Array<ResolversTypes['Industry']>,
    ParentType,
    ContextType
  >
  populatedTopics?: Resolver<Array<ResolversTypes['PostTopic']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['PostTopic']>, ParentType, ContextType>
  userIsMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GroupCategory'] = ResolversParentTypes['GroupCategory']
> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GroupConnection'] = ResolversParentTypes['GroupConnection']
> = {
  edges?: Resolver<Array<ResolversTypes['GroupEdge']>, ParentType, ContextType>
  nodes?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupEdge'] = ResolversParentTypes['GroupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Group'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupFeedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupFeed'] = ResolversParentTypes['GroupFeed']
> = {
  cacheKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupMemberListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GroupMemberList'] = ResolversParentTypes['GroupMemberList']
> = {
  members?: Resolver<Array<ResolversTypes['FilteredUserSearch']>, ParentType, ContextType>
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupPostFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GroupPostFeed'] = ResolversParentTypes['GroupPostFeed']
> = {
  posts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  unreadPostIds?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomeDashboardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomeDashboard'] = ResolversParentTypes['HomeDashboard']
> = {
  classicContent?: Resolver<Array<ResolversTypes['CmsSection']>, ParentType, ContextType>
  conceptBookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  concepts?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType>
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  previousCompanies?: Resolver<
    Array<ResolversTypes['UserCompany']>,
    ParentType,
    ContextType
  >
  programBookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  programs?: Resolver<Array<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  projectBookmarks?: Resolver<Array<ResolversTypes['Bookmark']>, ParentType, ContextType>
  projects?: Resolver<Array<ResolversTypes['Content']>, ParentType, ContextType>
  redirectPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Homepage'] = ResolversParentTypes['Homepage']
> = {
  content?: Resolver<ResolversTypes['HomepageContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageCheckResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageCheck'] = ResolversParentTypes['HomepageCheck']
> = {
  completedAskAQuestionWithSearch?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  completedChooseAProgram?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  completedCompleteALesson?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  completedSaveInsightsForLater?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  completedSetYourFirstGoal?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  currentChecklist?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hideChecklists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageContent'] = ResolversParentTypes['HomepageContent']
> = {
  aiCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  aiExtensionCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  aiSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  aiTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  artifactsAndGuidesSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  artifactsAndGuidesTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  artifactsBenefits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  artifactsCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  bottomCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  bottomTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  courses?: Resolver<Array<ResolversTypes['HomepageCourse']>, ParentType, ContextType>
  coursesCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  coursesSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  coursesTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  extensionApps?: Resolver<
    Array<ResolversTypes['AiExtensionApp']>,
    ParentType,
    ContextType
  >
  extensionSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  extensionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guidesBenefits?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  guidesCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  logos?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>
  mainCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  membershipBenefits1?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  membershipBenefits1Cta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  membershipBenefits2?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  membershipBenefits2Cta?: Resolver<Maybe<ResolversTypes['Cta']>, ParentType, ContextType>
  membershipSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  membershipTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reviews?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  secondaryCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  teamsBenefits?: Resolver<
    Array<ResolversTypes['HomepageTeamsBenefit']>,
    ParentType,
    ContextType
  >
  teamsBenefitsImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  teamsCta?: Resolver<ResolversTypes['Cta'], ParentType, ContextType>
  teamsSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  teamsTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  testimonials?: Resolver<
    Array<ResolversTypes['TestimonialWithImage']>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageCourse'] = ResolversParentTypes['HomepageCourse']
> = {
  course?: Resolver<Maybe<ResolversTypes['SanityCourse']>, ParentType, ContextType>
  courseType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  creators?: Resolver<Maybe<Array<ResolversTypes['Creator']>>, ParentType, ContextType>
  homepageCoursePreviewImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType
  >
  hosts?: Resolver<
    Maybe<Array<ResolversTypes['HomepageCourseHost']>>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  previewImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageCourseHostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageCourseHost'] = ResolversParentTypes['HomepageCourseHost']
> = {
  hostName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomepageRecentlyViewedItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageRecentlyViewedItem'] = ResolversParentTypes['HomepageRecentlyViewedItem']
> = {
  __resolveType: TypeResolveFn<'Artifact' | 'Content' | 'Unit', ParentType, ContextType>
}

export type HomepageTeamsBenefitResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HomepageTeamsBenefit'] = ResolversParentTypes['HomepageTeamsBenefit']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Host'] = ResolversParentTypes['Host']
> = {
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previousCompanies?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface Iso8601DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ISO8601DateTime'], any> {
  name: 'ISO8601DateTime'
}

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']
> = {
  aspectRatio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  imageAlt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IndustryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Industry'] = ResolversParentTypes['Industry']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InlinePostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InlinePost'] = ResolversParentTypes['InlinePost']
> = {
  anchor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  basedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bodyText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAtDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>
  referenceImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  replies?: Resolver<Array<ResolversTypes['InlinePostReply']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['PostTopic']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userRoleWithCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InlinePostReplyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InlinePostReply'] = ResolversParentTypes['InlinePostReply']
> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bodyText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  comments?: Resolver<Array<ResolversTypes['Comment']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  replyableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userAvatar?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userRoleWithCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']
> = {
  billingInformation?: Resolver<
    Maybe<ResolversTypes['BillingInformation']>,
    ParentType,
    ContextType
  >
  companyAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  extraInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  internalPoNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  members?: Resolver<Array<ResolversTypes['InvoiceMember']>, ParentType, ContextType>
  paymentRecord?: Resolver<ResolversTypes['PaymentRecord'], ParentType, ContextType>
  stripeInvoice?: Resolver<ResolversTypes['StripeInvoice'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceMemberResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['InvoiceMember'] = ResolversParentTypes['InvoiceMember']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON'
}

export type JoinCourseWaitlistPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinCourseWaitlistPayload'] = ResolversParentTypes['JoinCourseWaitlistPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JoinPublicCourseWaitlistPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['JoinPublicCourseWaitlistPayload'] = ResolversParentTypes['JoinPublicCourseWaitlistPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LessonViewerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LessonViewer'] = ResolversParentTypes['LessonViewer']
> = {
  cmsModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsSection?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  expertUsers?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  guestEndModuleEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  sameActiveUserCohort?: Resolver<
    Maybe<ResolversTypes['UserCohort']>,
    ParentType,
    ContextType
  >
  userProgram?: Resolver<Maybe<ResolversTypes['UserProgram']>, ParentType, ContextType>
  viewAccess?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MakeEmailPrimaryPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MakeEmailPrimaryPayload'] = ResolversParentTypes['MakeEmailPrimaryPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarkActivitiesAsReadPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarkActivitiesAsReadPayload'] = ResolversParentTypes['MarkActivitiesAsReadPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarkAllActivitiesAsReadPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarkAllActivitiesAsReadPayload'] = ResolversParentTypes['MarkAllActivitiesAsReadPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarkArtifactAsViewedPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarkArtifactAsViewedPayload'] = ResolversParentTypes['MarkArtifactAsViewedPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  viewedArtifact?: Resolver<
    Maybe<ResolversTypes['ViewedArtifact']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketingCourse'] = ResolversParentTypes['MarketingCourse']
> = {
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  bookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cclTopics?: Resolver<Maybe<Array<ResolversTypes['CclTopic']>>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsProgramSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  course?: Resolver<Maybe<ResolversTypes['SanityCourse']>, ParentType, ContextType>
  courseFaq?: Resolver<
    Maybe<Array<ResolversTypes['MarketingFaq']>>,
    ParentType,
    ContextType
  >
  courseInfoCards?: Resolver<
    Maybe<Array<ResolversTypes['CourseInfoCard']>>,
    ParentType,
    ContextType
  >
  coursePreviewAvailable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  creators?: Resolver<Maybe<Array<ResolversTypes['Creator']>>, ParentType, ContextType>
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostSubtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hosts?: Resolver<Maybe<Array<ResolversTypes['SanityHost']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  legacyProgramId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  previewImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingFaqResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MarketingFaq'] = ResolversParentTypes['MarketingFaq']
> = {
  answer?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModuleCompletionStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ModuleCompletionStatus'] = ResolversParentTypes['ModuleCompletionStatus']
> = {
  isComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  programPercentComplete?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  activateSubscription?: Resolver<
    Maybe<ResolversTypes['ActivateSubscriptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationActivateSubscriptionArgs, 'input'>
  >
  addAnnotationReaction?: Resolver<
    Maybe<ResolversTypes['AddAnnotationReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddAnnotationReactionArgs, 'input'>
  >
  addArtifactComment?: Resolver<
    Maybe<ResolversTypes['AddArtifactCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddArtifactCommentArgs, 'input'>
  >
  addArtifactCommentReaction?: Resolver<
    Maybe<ResolversTypes['AddArtifactCommentReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddArtifactCommentReactionArgs, 'input'>
  >
  addEmailToUser?: Resolver<
    Maybe<ResolversTypes['AddEmailToUserPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddEmailToUserArgs, 'input'>
  >
  addReaction?: Resolver<
    Maybe<ResolversTypes['AddReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddReactionArgs, 'input'>
  >
  addUserCompany?: Resolver<
    Maybe<ResolversTypes['AddUserCompanyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUserCompanyArgs, 'input'>
  >
  approveSubscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['ApproveSubscriptionJoinRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationApproveSubscriptionJoinRequestArgs, 'input'>
  >
  assignCohortCredit?: Resolver<
    Maybe<ResolversTypes['AssignCohortCreditPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAssignCohortCreditArgs, 'input'>
  >
  assignSeat?: Resolver<
    Maybe<ResolversTypes['AssignSeatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationAssignSeatArgs, 'input'>
  >
  batchRsvp?: Resolver<
    Maybe<ResolversTypes['BatchPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationBatchRsvpArgs, 'input'>
  >
  cancelRsvp?: Resolver<
    Maybe<ResolversTypes['CancelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelRsvpArgs, 'input'>
  >
  cancelSubscription?: Resolver<
    Maybe<ResolversTypes['SubscriptionCancelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelSubscriptionArgs, 'input'>
  >
  cancelSubscriptionMotivation?: Resolver<
    Maybe<ResolversTypes['SubscriptionCancelMotivationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelSubscriptionMotivationArgs, 'input'>
  >
  checkout?: Resolver<
    Maybe<ResolversTypes['CheckoutPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCheckoutArgs, 'input'>
  >
  choosePlan?: Resolver<
    Maybe<ResolversTypes['ChoosePlanPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationChoosePlanArgs, 'input'>
  >
  cmsProgramExperts?: Resolver<
    Maybe<ResolversTypes['CmsProgramExpertsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCmsProgramExpertsArgs, 'input'>
  >
  completeProductTour?: Resolver<
    Maybe<ResolversTypes['CompleteProductTourPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCompleteProductTourArgs, 'input'>
  >
  confirmUserMerge?: Resolver<
    Maybe<ResolversTypes['ConfirmUserMergePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationConfirmUserMergeArgs, 'input'>
  >
  createAccount?: Resolver<
    Maybe<ResolversTypes['CreateAccountPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAccountArgs, 'input'>
  >
  createBookmark?: Resolver<
    Maybe<ResolversTypes['CreateBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateBookmarkArgs, 'input'>
  >
  createBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['CreateBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateBookmarkFolderArgs, 'input'>
  >
  createCohortPost?: Resolver<
    Maybe<ResolversTypes['CreateCohortPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCohortPostArgs, 'input'>
  >
  createCohortReply?: Resolver<
    Maybe<ResolversTypes['CreateCohortReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCohortReplyArgs, 'input'>
  >
  createCourseEnrollment?: Resolver<
    Maybe<ResolversTypes['CreateCourseEnrollmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCourseEnrollmentArgs, 'input'>
  >
  createCourseSession?: Resolver<
    Maybe<ResolversTypes['CreateCourseSessionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCourseSessionArgs, 'input'>
  >
  createCourseSessionSlackChannel?: Resolver<
    Maybe<ResolversTypes['CreateCourseSessionSlackChannelPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateCourseSessionSlackChannelArgs, 'input'>
  >
  createDraftSubscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['CreateDraftSubscriptionJoinRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDraftSubscriptionJoinRequestArgs, 'input'>
  >
  createFiledBookmark?: Resolver<
    Maybe<ResolversTypes['CreateFiledBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateFiledBookmarkArgs, 'input'>
  >
  createProgramApplication?: Resolver<
    Maybe<ResolversTypes['CreateProgramApplicationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProgramApplicationArgs, 'input'>
  >
  createProgramInvitation?: Resolver<
    Maybe<ResolversTypes['CreateProgramInvitationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProgramInvitationArgs, 'input'>
  >
  createRailsSubscription?: Resolver<
    Maybe<ResolversTypes['CreateRailsSubscriptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateRailsSubscriptionArgs, 'input'>
  >
  createRecommendations?: Resolver<
    Maybe<ResolversTypes['CreateRecommendationsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateRecommendationsArgs, 'input'>
  >
  createReply?: Resolver<
    Maybe<ResolversTypes['CreateReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateReplyArgs, 'input'>
  >
  createSubscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['CreateSubscriptionJoinRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSubscriptionJoinRequestArgs, 'input'>
  >
  createTeamPost?: Resolver<
    Maybe<ResolversTypes['CreateTeamPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTeamPostArgs, 'input'>
  >
  createTestUsers?: Resolver<
    Maybe<ResolversTypes['CreateTestUsersPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTestUsersArgs, 'input'>
  >
  createUserClipActivity?: Resolver<
    Maybe<ResolversTypes['CreateUserClipActivityPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserClipActivityArgs, 'input'>
  >
  createUserCmsActivity?: Resolver<
    Maybe<ResolversTypes['CreateUserCmsActivityPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserCmsActivityArgs, 'input'>
  >
  createUserEventActivity?: Resolver<
    Maybe<ResolversTypes['CreateUserEventActivityPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserEventActivityArgs, 'input'>
  >
  declineSubscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['DeclineSubscriptionJoinRequestPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeclineSubscriptionJoinRequestArgs, 'input'>
  >
  deleteBookmark?: Resolver<
    Maybe<ResolversTypes['DeleteBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookmarkArgs, 'input'>
  >
  deleteBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['DeleteBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBookmarkFolderArgs, 'input'>
  >
  deleteCohortPass?: Resolver<
    Maybe<ResolversTypes['DeleteCohortPassPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCohortPassArgs, 'input'>
  >
  deleteCohortPost?: Resolver<
    Maybe<ResolversTypes['DeleteCohortPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCohortPostArgs, 'input'>
  >
  deleteCohortReply?: Resolver<
    Maybe<ResolversTypes['DeleteCohortReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCohortReplyArgs, 'input'>
  >
  deleteCustomerTaxId?: Resolver<
    Maybe<ResolversTypes['DeleteCustomerTaxIdPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCustomerTaxIdArgs, 'input'>
  >
  deleteEmail?: Resolver<
    Maybe<ResolversTypes['DeleteEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteEmailArgs, 'input'>
  >
  deleteFiledBookmark?: Resolver<
    Maybe<ResolversTypes['DeleteFiledBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteFiledBookmarkArgs, 'input'>
  >
  deletePaymentSource?: Resolver<
    Maybe<ResolversTypes['DeletePaymentSourcePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePaymentSourceArgs, 'input'>
  >
  deleteTeamPost?: Resolver<
    Maybe<ResolversTypes['DeleteTeamPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTeamPostArgs, 'input'>
  >
  deleteUserCompany?: Resolver<
    Maybe<ResolversTypes['DeleteUserCompanyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserCompanyArgs, 'input'>
  >
  endSubscriptionTrialEarly?: Resolver<
    Maybe<ResolversTypes['EndSubscriptionTrialEarlyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationEndSubscriptionTrialEarlyArgs, 'input'>
  >
  feedbacks?: Resolver<
    Maybe<ResolversTypes['FeedbacksPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationFeedbacksArgs, 'input'>
  >
  flagArtifactComment?: Resolver<
    Maybe<ResolversTypes['FlagArtifactCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationFlagArtifactCommentArgs, 'input'>
  >
  forkBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['ForkBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationForkBookmarkFolderArgs, 'input'>
  >
  generateAiGoals?: Resolver<
    Maybe<ResolversTypes['GenerateAiGoalsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationGenerateAiGoalsArgs, 'input'>
  >
  generateAiInference?: Resolver<
    Maybe<ResolversTypes['GenerateAiInferencePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationGenerateAiInferenceArgs, 'input'>
  >
  joinCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['JoinCourseWaitlistPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationJoinCourseWaitlistArgs, 'input'>
  >
  joinPublicCourseWaitlist?: Resolver<
    Maybe<ResolversTypes['JoinPublicCourseWaitlistPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationJoinPublicCourseWaitlistArgs, 'input'>
  >
  makeEmailPrimary?: Resolver<
    Maybe<ResolversTypes['MakeEmailPrimaryPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationMakeEmailPrimaryArgs, 'input'>
  >
  markActivitiesAsRead?: Resolver<
    Maybe<ResolversTypes['MarkActivitiesAsReadPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationMarkActivitiesAsReadArgs, 'input'>
  >
  markAllActivitiesAsRead?: Resolver<
    Maybe<ResolversTypes['MarkAllActivitiesAsReadPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationMarkAllActivitiesAsReadArgs, 'input'>
  >
  markArtifactAsViewed?: Resolver<
    Maybe<ResolversTypes['MarkArtifactAsViewedPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationMarkArtifactAsViewedArgs, 'input'>
  >
  notAttending?: Resolver<
    Maybe<ResolversTypes['NotAttendingPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationNotAttendingArgs, 'input'>
  >
  regenerateCourseSnapshot?: Resolver<
    Maybe<ResolversTypes['RegenerateCourseSnapshotPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRegenerateCourseSnapshotArgs, 'input'>
  >
  reinstateSeat?: Resolver<
    Maybe<ResolversTypes['ReinstateSeatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationReinstateSeatArgs, 'input'>
  >
  removeAiChat?: Resolver<
    Maybe<ResolversTypes['RemoveAiChatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAiChatArgs, 'input'>
  >
  removeAnnotationReaction?: Resolver<
    Maybe<ResolversTypes['RemoveAnnotationReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAnnotationReactionArgs, 'input'>
  >
  removeArtifactComment?: Resolver<
    Maybe<ResolversTypes['RemoveArtifactCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveArtifactCommentArgs, 'input'>
  >
  removeArtifactCommentReaction?: Resolver<
    Maybe<ResolversTypes['RemoveArtifactCommentReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveArtifactCommentReactionArgs, 'input'>
  >
  removeReaction?: Resolver<
    Maybe<ResolversTypes['RemoveReactionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveReactionArgs, 'input'>
  >
  removeReply?: Resolver<
    Maybe<ResolversTypes['RemoveReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveReplyArgs, 'input'>
  >
  removeSeat?: Resolver<
    Maybe<ResolversTypes['RemoveSeatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSeatArgs, 'input'>
  >
  removeSubscriptionFromOrg?: Resolver<
    Maybe<ResolversTypes['RemoveSubscriptionFromOrgPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSubscriptionFromOrgArgs, 'input'>
  >
  removeSubscriptionMember?: Resolver<
    Maybe<ResolversTypes['RemoveSubscriptionMemberPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSubscriptionMemberArgs, 'input'>
  >
  renameAiChat?: Resolver<
    Maybe<ResolversTypes['RenameAiChatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRenameAiChatArgs, 'input'>
  >
  reorderFiledBookmark?: Resolver<
    Maybe<ResolversTypes['ReorderFiledBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationReorderFiledBookmarkArgs, 'input'>
  >
  reportCohortPostOrReply?: Resolver<
    Maybe<ResolversTypes['ReportCohortPostOrReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationReportCohortPostOrReplyArgs, 'input'>
  >
  restoreCollectionActivity?: Resolver<
    Maybe<ResolversTypes['RestoreCollectionActivityPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRestoreCollectionActivityArgs, 'input'>
  >
  rsvp?: Resolver<
    Maybe<ResolversTypes['CreatePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationRsvpArgs, 'input'>
  >
  saveCustomerTaxId?: Resolver<
    Maybe<ResolversTypes['SaveCustomerTaxIdPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveCustomerTaxIdArgs, 'input'>
  >
  selectFeaturedPosts?: Resolver<
    Maybe<ResolversTypes['SelectFeaturedPostsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSelectFeaturedPostsArgs, 'input'>
  >
  sendEmailVerify?: Resolver<
    Maybe<ResolversTypes['SendEmailVerifyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendEmailVerifyArgs, 'input'>
  >
  sendMergeRequestEmail?: Resolver<
    Maybe<ResolversTypes['SendMergeRequestEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendMergeRequestEmailArgs, 'input'>
  >
  setDismissedUpdateCompanyBannerAt?: Resolver<
    Maybe<ResolversTypes['SetDismissedUpdateCompanyBannerAtPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetDismissedUpdateCompanyBannerAtArgs, 'input'>
  >
  setHasSeenDemographicsModal?: Resolver<
    Maybe<ResolversTypes['SetHasSeenDemographicsModalPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetHasSeenDemographicsModalArgs, 'input'>
  >
  setUserCohortPreferences?: Resolver<
    Maybe<ResolversTypes['SetUserCohortPreferencesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetUserCohortPreferencesArgs, 'input'>
  >
  shareBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['ShareBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationShareBookmarkFolderArgs, 'input'>
  >
  stripeSetupIntent?: Resolver<
    Maybe<ResolversTypes['StripeSetupIntentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationStripeSetupIntentArgs, 'input'>
  >
  submitOnboardingQuestionnaire?: Resolver<
    Maybe<ResolversTypes['SubmitOnboardingQuestionnairePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSubmitOnboardingQuestionnaireArgs, 'input'>
  >
  submitPostApplySurvey?: Resolver<
    Maybe<ResolversTypes['SubmitPostApplySurveyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSubmitPostApplySurveyArgs, 'input'>
  >
  subscriptionSeatsChangePreview?: Resolver<
    Maybe<ResolversTypes['SubscriptionSeatsChangePreviewPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationSubscriptionSeatsChangePreviewArgs, 'input'>
  >
  toggleManualAssignSubscription?: Resolver<
    Maybe<ResolversTypes['ToggleManualAssignSubscriptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationToggleManualAssignSubscriptionArgs, 'input'>
  >
  togglePostFollow?: Resolver<
    Maybe<ResolversTypes['TogglePostFollowPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationTogglePostFollowArgs, 'input'>
  >
  trackCourseEnrollmentConfirmed?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentConfirmedPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationTrackCourseEnrollmentConfirmedArgs, 'input'>
  >
  trackCourseEnrollmentStarted?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentStartedPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationTrackCourseEnrollmentStartedArgs, 'input'>
  >
  trackOnboarding?: Resolver<
    Maybe<ResolversTypes['OnboardingTrackingPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationTrackOnboardingArgs, 'input'>
  >
  trackServerEvent?: Resolver<
    Maybe<ResolversTypes['TrackServerEventPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationTrackServerEventArgs, 'input'>
  >
  unassignCohortCredit?: Resolver<
    Maybe<ResolversTypes['UnassignCohortCreditPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnassignCohortCreditArgs, 'input'>
  >
  unassignSeat?: Resolver<
    Maybe<ResolversTypes['UnassignSeatPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnassignSeatArgs, 'input'>
  >
  unshareBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['UnshareBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnshareBookmarkFolderArgs, 'input'>
  >
  updateAiPersonalization?: Resolver<
    Maybe<ResolversTypes['UpdateAiPersonalizationPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAiPersonalizationArgs, 'input'>
  >
  updateArtifactComment?: Resolver<
    Maybe<ResolversTypes['UpdateArtifactCommentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateArtifactCommentArgs, 'input'>
  >
  updateBookmark?: Resolver<
    Maybe<ResolversTypes['UpdateBookmarkPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBookmarkArgs, 'input'>
  >
  updateBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['UpdateBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBookmarkFolderArgs, 'input'>
  >
  updateCcl?: Resolver<
    Maybe<ResolversTypes['UpdateCclPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCclArgs, 'input'>
  >
  updateCmsSectionTags?: Resolver<
    Maybe<ResolversTypes['UpdateCmsSectionTagsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCmsSectionTagsArgs, 'input'>
  >
  updateCoachingSession?: Resolver<
    Maybe<ResolversTypes['UpdateCoachingSessionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCoachingSessionArgs, 'input'>
  >
  updateCohortPost?: Resolver<
    Maybe<ResolversTypes['UpdateCohortPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCohortPostArgs, 'input'>
  >
  updateCohortReply?: Resolver<
    Maybe<ResolversTypes['UpdateCohortReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCohortReplyArgs, 'input'>
  >
  updateCourseEnrollment?: Resolver<
    Maybe<ResolversTypes['UpdateCourseEnrollmentPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCourseEnrollmentArgs, 'input'>
  >
  updateCourseSession?: Resolver<
    Maybe<ResolversTypes['UpdateCourseSessionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCourseSessionArgs, 'input'>
  >
  updateCustomerContact?: Resolver<
    ResolversTypes['UpdateCustomerContactPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCustomerContactArgs, 'input'>
  >
  updateHomepageCheck?: Resolver<
    Maybe<ResolversTypes['UpdateHomepageCheckPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateHomepageCheckArgs, 'input'>
  >
  updateInvoiceUserFields?: Resolver<
    Maybe<ResolversTypes['UpdateInvoiceUserFieldsPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateInvoiceUserFieldsArgs, 'input'>
  >
  updatePaymentMethod?: Resolver<
    Maybe<ResolversTypes['UpdatePaymentMethodPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePaymentMethodArgs, 'input'>
  >
  updatePreferences?: Resolver<
    Maybe<ResolversTypes['UpdatePreferencesPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePreferencesArgs, 'input'>
  >
  updateProjectDeliverable?: Resolver<
    Maybe<ResolversTypes['UpdateProjectDeliverablePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProjectDeliverableArgs, 'input'>
  >
  updateReply?: Resolver<
    Maybe<ResolversTypes['UpdateReplyPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateReplyArgs, 'input'>
  >
  updateTeam?: Resolver<
    Maybe<ResolversTypes['UpdateTeamPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTeamArgs, 'input'>
  >
  updateTeamPost?: Resolver<
    Maybe<ResolversTypes['UpdateTeamPostPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTeamPostArgs, 'input'>
  >
  updateTopicActivity?: Resolver<
    Maybe<ResolversTypes['UpdateTopicActivityPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTopicActivityArgs, 'input'>
  >
  updateUser?: Resolver<
    Maybe<ResolversTypes['UpdateUserPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'input'>
  >
  updateUserCohort?: Resolver<
    Maybe<ResolversTypes['UpdateUserCohortPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserCohortArgs, 'input'>
  >
  updateUserCourse?: Resolver<
    Maybe<ResolversTypes['UpdateUserCoursePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserCourseArgs, 'input'>
  >
  upgradeSubscription?: Resolver<
    Maybe<ResolversTypes['UpgradeSubscriptionPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpgradeSubscriptionArgs, 'input'>
  >
  urlChange?: Resolver<
    Maybe<ResolversTypes['UrlChangePayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationUrlChangeArgs, 'input'>
  >
  verifyEmail?: Resolver<
    Maybe<ResolversTypes['VerifyEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationVerifyEmailArgs, 'input'>
  >
  viewBookmarkFolder?: Resolver<
    Maybe<ResolversTypes['ViewBookmarkFolderPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationViewBookmarkFolderArgs, 'input'>
  >
  whatWeCanDoBetter?: Resolver<
    Maybe<ResolversTypes['WhatWeCanDoBetterPayload']>,
    ParentType,
    ContextType,
    RequireFields<MutationWhatWeCanDoBetterArgs, 'input'>
  >
}

export type MuxVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MuxVideo'] = ResolversParentTypes['MuxVideo']
> = {
  playbackId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  thumbTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotAttendingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NotAttendingPayload'] = ResolversParentTypes['NotAttendingPayload']
> = {
  attendee?: Resolver<Maybe<ResolversTypes['EventAttendee']>, ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OnboardingInfo'] = ResolversParentTypes['OnboardingInfo']
> = {
  hasProgramRecommendations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  onboarded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingQuestionnaireResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OnboardingQuestionnaire'] = ResolversParentTypes['OnboardingQuestionnaire']
> = {
  areasOfExpertiseTags?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  businessModelTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  communityInterestTags?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  companyAudienceTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  teamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  usage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnboardingTrackingPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OnboardingTrackingPayload'] = ResolversParentTypes['OnboardingTrackingPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrgResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Org'] = ResolversParentTypes['Org']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orgAdmins?: Resolver<Maybe<Array<ResolversTypes['OrgAdmin']>>, ParentType, ContextType>
  subscriptions?: Resolver<Array<ResolversTypes['Subscription']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrgAdminResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrgAdmin'] = ResolversParentTypes['OrgAdmin']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  org?: Resolver<ResolversTypes['Org'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageSeoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageSeo'] = ResolversParentTypes['PageSeo']
> = {
  authors?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  metaDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  shareDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareGraphic?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  shareGraphicAlt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  structuredDataSchema?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  type?: Resolver<Maybe<ResolversTypes['PageTypeEnum']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaginationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']
> = {
  currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  previousPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalEntries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PastPaymentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PastPayment'] = ResolversParentTypes['PastPayment']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invoiceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  paymentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentRecordResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PaymentRecord'] = ResolversParentTypes['PaymentRecord']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  programName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PersonalizationQueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PersonalizationQuery'] = ResolversParentTypes['PersonalizationQuery']
> = {
  __resolveType: TypeResolveFn<'Query', ParentType, ContextType>
  aiPersonalization?: Resolver<
    Maybe<ResolversTypes['AiPersonalization']>,
    ParentType,
    ContextType,
    Partial<PersonalizationQueryAiPersonalizationArgs>
  >
}

export type PlanForSaleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PlanForSale'] = ResolversParentTypes['PlanForSale']
> = {
  maxSeatCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  pricePerPerson?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  pricePerYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  stripeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Podcast'] = ResolversParentTypes['Podcast']
> = {
  contentBlock?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  createdAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  ctaButton?: Resolver<Maybe<ResolversTypes['PodcastCta']>, ParentType, ContextType>
  hosts?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  listenOn?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  nextEpisode?: Resolver<Maybe<ResolversTypes['Podcast']>, ParentType, ContextType>
  prevEpisode?: Resolver<Maybe<ResolversTypes['Podcast']>, ParentType, ContextType>
  releaseDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  summaryImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  summaryText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastCtaResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastCta'] = ResolversParentTypes['PodcastCta']
> = {
  ctaLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  withReforgeSymbol?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastDetails'] = ResolversParentTypes['PodcastDetails']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  podcast?: Resolver<ResolversTypes['Podcast'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastHostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastHost'] = ResolversParentTypes['PodcastHost']
> = {
  artifactAuthor?: Resolver<ResolversTypes['ArtifactAuthor'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shortBio?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastIndexResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastIndex'] = ResolversParentTypes['PodcastIndex']
> = {
  nextPodcastsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['PodcastIndexPage'], ParentType, ContextType>
  podcasts?: Resolver<Array<ResolversTypes['Podcast']>, ParentType, ContextType>
  prevPodcastsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastIndexContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastIndexContent'] = ResolversParentTypes['PodcastIndexContent']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  formTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  heading?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hosts?: Resolver<Array<ResolversTypes['PodcastHost']>, ParentType, ContextType>
  mainImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  platforms?: Resolver<Array<ResolversTypes['PodcastPlatform']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastIndexPageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastIndexPage'] = ResolversParentTypes['PodcastIndexPage']
> = {
  content?: Resolver<ResolversTypes['PodcastIndexContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PodcastPlatformResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PodcastPlatform'] = ResolversParentTypes['PodcastPlatform']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  platformImage?: Resolver<ResolversTypes['Image'], ParentType, ContextType>
  platformLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopularEventsPastFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PopularEventsPastFeed'] = ResolversParentTypes['PopularEventsPastFeed']
> = {
  events?: Resolver<Maybe<Array<ResolversTypes['Event']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']
> = {
  activities?: Resolver<
    Array<ResolversTypes['Activity']>,
    ParentType,
    ContextType,
    Partial<PostActivitiesArgs>
  >
  allReactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  basedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bodyText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  categories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsProgramId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cmsSectionLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSectionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSectionParentName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  cohort?: Resolver<Maybe<ResolversTypes['Cohort']>, ParentType, ContextType>
  cohortId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  collectiveOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  comments?: Resolver<Array<ResolversTypes['Comment']>, ParentType, ContextType>
  commentsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  contributors?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  createdAtDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  draft?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  externalId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  followeesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  following?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  images?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  industries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>
  isPinned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastResponded?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  lastRespondedAtInWords?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  reactionCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>
  referenceImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  replies?: Resolver<Array<ResolversTypes['Reply']>, ParentType, ContextType>
  repliesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  replyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  replyPreviews?: Resolver<Array<ResolversTypes['Reply']>, ParentType, ContextType>
  responseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  safePostedIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  skipDigest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  skipForumIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  softDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timeAgo?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  toParam?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['PostTopic']>, ParentType, ContextType>
  unreadElements?: Resolver<
    Array<ResolversTypes['UnreadElement']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  updatedAtInWords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostContributorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PostContributor'] = ResolversParentTypes['PostContributor']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasBadge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostList'] = ResolversParentTypes['PostList']
> = {
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>
  posts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  unreadPostIds?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostTopicResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PostTopic'] = ResolversParentTypes['PostTopic']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  groupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isContentClarification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFeedback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  latestPostCreatedAtForCohort?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<PostTopicLatestPostCreatedAtForCohortArgs, 'cohortSlug'>
  >
  posts?: Resolver<Maybe<Array<ResolversTypes['Post']>>, ParentType, ContextType>
  restrictedToStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  userTopicActivity?: Resolver<ResolversTypes['TopicActivity'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostsSearchResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PostsSearch'] = ResolversParentTypes['PostsSearch']
> = {
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  members?: Resolver<Array<ResolversTypes['PostContributor']>, ParentType, ContextType>
  posts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  topics?: Resolver<Array<ResolversTypes['PostsSearchTopic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PostsSearchTopicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PostsSearchTopic'] = ResolversParentTypes['PostsSearchTopic']
> = {
  groupSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topicId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PricingPage'] = ResolversParentTypes['PricingPage']
> = {
  content?: Resolver<ResolversTypes['PricingPageContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPageContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PricingPageContent'] = ResolversParentTypes['PricingPageContent']
> = {
  caseStudies?: Resolver<
    Maybe<Array<ResolversTypes['CaseStudy']>>,
    ParentType,
    ContextType
  >
  caseStudiesSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  caseStudiesTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  faqs?: Resolver<Maybe<Array<ResolversTypes['MarketingFaq']>>, ParentType, ContextType>
  features?: Resolver<
    Maybe<Array<ResolversTypes['PricingPlanFeature']>>,
    ParentType,
    ContextType
  >
  logoSectionTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  logos?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>
  membershipSectionSubtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  membershipSectionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  membershipSectionVideo?: Resolver<ResolversTypes['MuxVideo'], ParentType, ContextType>
  reforgeComparisons?: Resolver<
    Maybe<Array<ResolversTypes['ReforgeComparison']>>,
    ParentType,
    ContextType
  >
  reviews?: Resolver<Maybe<Array<ResolversTypes['Review']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanFeatureResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PricingPlanFeature'] = ResolversParentTypes['PricingPlanFeature']
> = {
  category?: Resolver<
    ResolversTypes['PricingPlanFeatureCategory'],
    ParentType,
    ContextType
  >
  feature?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  values?: Resolver<
    Maybe<Array<ResolversTypes['PricingPlanFeatureValue']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanFeatureCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PricingPlanFeatureCategory'] = ResolversParentTypes['PricingPlanFeatureCategory']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanFeatureValueResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PricingPlanFeatureValue'] = ResolversParentTypes['PricingPlanFeatureValue']
> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  forPlan?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  textValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductTourResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ProductTour'] = ResolversParentTypes['ProductTour']
> = {
  bookmarkCreate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  collectionSaveBookmark?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  collectionShare?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  collectionViewContent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  collectionsTab?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  newSearchClicked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  savedItemsTab?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  searchTrainerViews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startDraftFromButton?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  teamCommentView?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  usedReforgeAiGlobalEntryPoint?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']
> = {
  artifactAuthor?: Resolver<ResolversTypes['ArtifactAuthor'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  seo?: Resolver<Maybe<ResolversTypes['PageSeo']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['ExpertUserProfile']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProgramProgressResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ProgramProgress'] = ResolversParentTypes['ProgramProgress']
> = {
  progress?: Resolver<ResolversTypes['ProgramProgressScalar'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface ProgramProgressScalarScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ProgramProgressScalar'], any> {
  name: 'ProgramProgressScalar'
}

export type ProjectDeliverableResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ProjectDeliverable'] = ResolversParentTypes['ProjectDeliverable']
> = {
  cmsSection?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  gid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isExample?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  templateGid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  templateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  acceptingApplications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  activeCourseSessions?: Resolver<
    Array<ResolversTypes['CourseSession']>,
    ParentType,
    ContextType
  >
  activeSeason?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  activityList?: Resolver<
    ResolversTypes['ActivityList'],
    ParentType,
    ContextType,
    Partial<QueryActivityListArgs>
  >
  aiChatUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  aiChats?: Resolver<
    Array<ResolversTypes['AiChat']>,
    ParentType,
    ContextType,
    RequireFields<QueryAiChatsArgs, 'userId'>
  >
  aiPersonalization?: Resolver<
    Maybe<ResolversTypes['AiPersonalization']>,
    ParentType,
    ContextType,
    Partial<QueryAiPersonalizationArgs>
  >
  aiSessionHistory?: Resolver<
    Array<ResolversTypes['AiChatMessage']>,
    ParentType,
    ContextType,
    RequireFields<QueryAiSessionHistoryArgs, 'sessionId'>
  >
  allGuidesWithSubtopics?: Resolver<
    Array<ResolversTypes['Unit']>,
    ParentType,
    ContextType
  >
  allTopicsAndFunctions?: Resolver<
    Array<ResolversTypes['ArtifactTopicFilter']>,
    ParentType,
    ContextType
  >
  anticopyExceptionIds?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  applicablePrograms?: Resolver<
    Maybe<Array<ResolversTypes['ApplicablePrograms']>>,
    ParentType,
    ContextType
  >
  artifact?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactArgs, 'slug'>
  >
  artifactAnnotations?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAnnotation']>>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactAnnotationsArgs, 'artifactSlug'>
  >
  artifactAnnotationsCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<QueryArtifactAnnotationsCountArgs, 'artifactSlug'>
  >
  artifactAuthorCourses?: Resolver<
    Array<ResolversTypes['MarketingCourse']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactAuthorCoursesArgs, 'slug'>
  >
  artifactAuthorProfiles?: Resolver<
    Array<ResolversTypes['Profile']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactAuthorProfilesArgs, 'slug'>
  >
  artifactComments?: Resolver<
    Array<ResolversTypes['ArtifactComment']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactCommentsArgs, 'artifactId'>
  >
  artifactCommentsCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<QueryArtifactCommentsCountArgs, 'artifactId'>
  >
  artifactContributors?: Resolver<
    Array<ResolversTypes['ArtifactContributor']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactContributorsArgs, 'artifactSlug'>
  >
  artifactOnboardingRecommendations?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    Partial<QueryArtifactOnboardingRecommendationsArgs>
  >
  artifactTopic?: Resolver<
    Maybe<ResolversTypes['ArtifactTopic']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactTopicArgs, 'slug'>
  >
  artifactUserData?: Resolver<ResolversTypes['ArtifactUserData'], ParentType, ContextType>
  artifactsByIds?: Resolver<
    Maybe<Array<ResolversTypes['Artifact']>>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactsByIdsArgs, 'ids'>
  >
  artifactsForSubtopic?: Resolver<
    Maybe<ResolversTypes['ArtifactsForSubtopic']>,
    ParentType,
    ContextType,
    RequireFields<QueryArtifactsForSubtopicArgs, 'slug'>
  >
  attendeesFeed?: Resolver<
    Maybe<ResolversTypes['AttendeesFeed']>,
    ParentType,
    ContextType,
    Partial<QueryAttendeesFeedArgs>
  >
  blogIndex?: Resolver<
    ResolversTypes['BlogIndex'],
    ParentType,
    ContextType,
    Partial<QueryBlogIndexArgs>
  >
  blogPost?: Resolver<
    Maybe<ResolversTypes['BlogPostDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlogPostArgs, 'slug'>
  >
  blogPostsForTopic?: Resolver<
    Maybe<Array<ResolversTypes['BlogPost']>>,
    ParentType,
    ContextType,
    RequireFields<QueryBlogPostsForTopicArgs, 'slug'>
  >
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType,
    RequireFields<QueryBookmarkFolderArgs, 'folderId'>
  >
  bookmarksFeed?: Resolver<
    ResolversTypes['BookmarksFeed'],
    ParentType,
    ContextType,
    Partial<QueryBookmarksFeedArgs>
  >
  bookmarksFromFolder?: Resolver<
    Array<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType,
    RequireFields<QueryBookmarksFromFolderArgs, 'folderId'>
  >
  bulkRsvpdAtForCohort?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<QueryBulkRsvpdAtForCohortArgs>
  >
  careersPage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  caseStudies?: Resolver<ResolversTypes['CaseStudiesIndex'], ParentType, ContextType>
  caseStudy?: Resolver<
    Maybe<ResolversTypes['CaseStudyDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryCaseStudyArgs, 'slug'>
  >
  cclCourse?: Resolver<
    Maybe<ResolversTypes['CclCourse']>,
    ParentType,
    ContextType,
    RequireFields<QueryCclCourseArgs, 'slug'>
  >
  cclCourseCclTopics?: Resolver<
    Maybe<Array<ResolversTypes['CclTopic']>>,
    ParentType,
    ContextType
  >
  cclCourses?: Resolver<
    Maybe<Array<ResolversTypes['CclCourse']>>,
    ParentType,
    ContextType,
    Partial<QueryCclCoursesArgs>
  >
  classicContent?: Resolver<Array<ResolversTypes['CmsSection']>, ParentType, ContextType>
  cmsContent?: Resolver<
    Maybe<ResolversTypes['CmsContent']>,
    ParentType,
    ContextType,
    RequireFields<QueryCmsContentArgs, 'cmsSectionId'>
  >
  cmsProgram?: Resolver<
    Maybe<ResolversTypes['CmsProgram']>,
    ParentType,
    ContextType,
    Partial<QueryCmsProgramArgs>
  >
  cmsProgramNames?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  cmsPrograms?: Resolver<Array<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsSection?: Resolver<
    Maybe<ResolversTypes['CmsSection']>,
    ParentType,
    ContextType,
    RequireFields<QueryCmsSectionArgs, 'contentType' | 'slug'>
  >
  cmsUnits?: Resolver<Maybe<Array<ResolversTypes['CmsSection']>>, ParentType, ContextType>
  cohort?: Resolver<
    ResolversTypes['Cohort'],
    ParentType,
    ContextType,
    RequireFields<QueryCohortArgs, 'slug'>
  >
  cohortDashboardScheduledWeek?: Resolver<
    ResolversTypes['ScheduledWeek'],
    ParentType,
    ContextType,
    RequireFields<QueryCohortDashboardScheduledWeekArgs, 'cohortSlug' | 'weekId'>
  >
  cohortPassPriceTiers?: Resolver<
    Array<ResolversTypes['CohortPassPriceTier']>,
    ParentType,
    ContextType
  >
  cohortPasses?: Resolver<
    Array<ResolversTypes['CohortCredit']>,
    ParentType,
    ContextType,
    RequireFields<QueryCohortPassesArgs, 'userId'>
  >
  cohortPost?: Resolver<
    ResolversTypes['Post'],
    ParentType,
    ContextType,
    RequireFields<QueryCohortPostArgs, 'id'>
  >
  cohortPosts?: Resolver<
    ResolversTypes['CohortPost'],
    ParentType,
    ContextType,
    RequireFields<QueryCohortPostsArgs, 'topicSlug'>
  >
  cohortTeam?: Resolver<
    Maybe<ResolversTypes['CohortTeam']>,
    ParentType,
    ContextType,
    RequireFields<QueryCohortTeamArgs, 'id'>
  >
  cohortTeams?: Resolver<
    Array<ResolversTypes['CohortTeam']>,
    ParentType,
    ContextType,
    Partial<QueryCohortTeamsArgs>
  >
  collectionActivity?: Resolver<
    ResolversTypes['CollectionActivityList'],
    ParentType,
    ContextType,
    RequireFields<QueryCollectionActivityArgs, 'id'>
  >
  completedFeedback?: Resolver<
    ResolversTypes['CompletedFeedback'],
    ParentType,
    ContextType
  >
  completedModuleUsersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<QueryCompletedModuleUsersCountArgs>
  >
  completionSnapshot?: Resolver<
    Maybe<ResolversTypes['CompletionSnapshot']>,
    ParentType,
    ContextType
  >
  concepts?: Resolver<
    Maybe<ResolversTypes['ContentFeed']>,
    ParentType,
    ContextType,
    Partial<QueryConceptsArgs>
  >
  contactUs?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  contentViewer?: Resolver<
    Maybe<ResolversTypes['ContentViewer']>,
    ParentType,
    ContextType,
    RequireFields<QueryContentViewerArgs, 'cmsType' | 'path' | 'slug'>
  >
  copyrightDisputePage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  course?: Resolver<
    Maybe<ResolversTypes['Course']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourseArgs, 'slug'>
  >
  courseBlocks?: Resolver<
    ResolversTypes['Course'],
    ParentType,
    ContextType,
    Partial<QueryCourseBlocksArgs>
  >
  courseConcepts?: Resolver<
    Maybe<ResolversTypes['CourseConcepts']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourseConceptsArgs, 'slug'>
  >
  courseEvent?: Resolver<
    Maybe<ResolversTypes['CourseEvent']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourseEventArgs, 'sanityId'>
  >
  coursePreview?: Resolver<
    Maybe<ResolversTypes['CoursePreview']>,
    ParentType,
    ContextType,
    RequireFields<QueryCoursePreviewArgs, 'slug'>
  >
  courseSession?: Resolver<
    Maybe<ResolversTypes['CourseSession']>,
    ParentType,
    ContextType,
    Partial<QueryCourseSessionArgs>
  >
  courseSessionDetail?: Resolver<
    ResolversTypes['CourseSessionDetail'],
    ParentType,
    ContextType,
    Partial<QueryCourseSessionDetailArgs>
  >
  courseSnapshot?: Resolver<
    ResolversTypes['CourseSnapshot'],
    ParentType,
    ContextType,
    RequireFields<QueryCourseSnapshotArgs, 'snapshotType' | 'token'>
  >
  coursesForTopicAndFunction?: Resolver<
    Maybe<Array<ResolversTypes['CourseForTopicAndFunction']>>,
    ParentType,
    ContextType,
    RequireFields<QueryCoursesForTopicAndFunctionArgs, 'slug'>
  >
  coursesMarketplaceWaitlist?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  currentSeasonPretty?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currentUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  currentUserCohortPostsByTopicCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<QueryCurrentUserCohortPostsByTopicCountArgs, 'topicSlug'>
  >
  dedupedTopicsAndFunctions?: Resolver<
    Array<ResolversTypes['ArtifactTopicFilter']>,
    ParentType,
    ContextType
  >
  discussionPost?: Resolver<
    Maybe<ResolversTypes['Post']>,
    ParentType,
    ContextType,
    RequireFields<QueryDiscussionPostArgs, 'slug'>
  >
  discussionPosts?: Resolver<
    ResolversTypes['PostList'],
    ParentType,
    ContextType,
    RequireFields<QueryDiscussionPostsArgs, 'postSource'>
  >
  duplicateAccounts?: Resolver<
    Array<ResolversTypes['DuplicateAccount']>,
    ParentType,
    ContextType
  >
  emailTemplate?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryEmailTemplateArgs, 'templateType'>
  >
  engagementReportSummary?: Resolver<
    ResolversTypes['EngagementReportSummary'],
    ParentType,
    ContextType
  >
  engagementReportTeam?: Resolver<
    ResolversTypes['EngagementReportTeam'],
    ParentType,
    ContextType
  >
  enrollmentSeason?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  enterpriseSsoStatus?: Resolver<ResolversTypes['SsoFeatureSet'], ParentType, ContextType>
  event?: Resolver<
    ResolversTypes['Event'],
    ParentType,
    ContextType,
    Partial<QueryEventArgs>
  >
  eventExpertHosts?: Resolver<
    Maybe<Array<ResolversTypes['EventSpeaker']>>,
    ParentType,
    ContextType
  >
  eventRelatedResources?: Resolver<
    Array<ResolversTypes['EventRelatedResources']>,
    ParentType,
    ContextType,
    RequireFields<QueryEventRelatedResourcesArgs, 'eventId'>
  >
  eventRsvp?: Resolver<
    Maybe<ResolversTypes['EventAttendee']>,
    ParentType,
    ContextType,
    RequireFields<QueryEventRsvpArgs, 'eventId'>
  >
  eventsByIds?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    Partial<QueryEventsByIdsArgs>
  >
  eventsPastFeed?: Resolver<
    ResolversTypes['EventsPastFeed'],
    ParentType,
    ContextType,
    Partial<QueryEventsPastFeedArgs>
  >
  exchangeChatIdForExtId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryExchangeChatIdForExtIdArgs, 'chatId'>
  >
  expertHostFeaturedEvents?: Resolver<
    Maybe<Array<ResolversTypes['Event']>>,
    ParentType,
    ContextType,
    RequireFields<QueryExpertHostFeaturedEventsArgs, 'userId'>
  >
  featuredCclCourses?: Resolver<
    Maybe<Array<ResolversTypes['CclCourse']>>,
    ParentType,
    ContextType
  >
  featuredPosts?: Resolver<Array<ResolversTypes['FeaturedPost']>, ParentType, ContextType>
  filteredArtifacts?: Resolver<
    Maybe<ResolversTypes['FilteredArtifacts']>,
    ParentType,
    ContextType,
    Partial<QueryFilteredArtifactsArgs>
  >
  filteredUsers?: Resolver<
    ResolversTypes['FilteredUserSearchList'],
    ParentType,
    ContextType,
    Partial<QueryFilteredUsersArgs>
  >
  findBookmarkById?: Resolver<
    Maybe<ResolversTypes['Bookmark']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindBookmarkByIdArgs, 'bookmarkId'>
  >
  function?: Resolver<
    Maybe<ResolversTypes['Function']>,
    ParentType,
    ContextType,
    RequireFields<QueryFunctionArgs, 'slug'>
  >
  goalProgress?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    RequireFields<QueryGoalProgressArgs, 'folderId'>
  >
  group?: Resolver<
    Maybe<ResolversTypes['Group']>,
    ParentType,
    ContextType,
    RequireFields<QueryGroupArgs, 'groupSlug'>
  >
  groupMembers?: Resolver<
    ResolversTypes['GroupMemberList'],
    ParentType,
    ContextType,
    RequireFields<QueryGroupMembersArgs, 'slug'>
  >
  groups?: Resolver<
    ResolversTypes['GroupFeed'],
    ParentType,
    ContextType,
    Partial<QueryGroupsArgs>
  >
  guidesBySanityTagGroup?: Resolver<
    Array<ResolversTypes['Unit']>,
    ParentType,
    ContextType,
    RequireFields<QueryGuidesBySanityTagGroupArgs, 'slug'>
  >
  guidesForDiscovery?: Resolver<
    Array<ResolversTypes['Unit']>,
    ParentType,
    ContextType,
    Partial<QueryGuidesForDiscoveryArgs>
  >
  homeDashboard?: Resolver<ResolversTypes['HomeDashboard'], ParentType, ContextType>
  homepageRecentlyViewedContent?: Resolver<
    Array<ResolversTypes['HomepageRecentlyViewedItem']>,
    ParentType,
    ContextType
  >
  industries?: Resolver<Array<ResolversTypes['Industry']>, ParentType, ContextType>
  inlinePosts?: Resolver<
    Array<ResolversTypes['InlinePost']>,
    ParentType,
    ContextType,
    Partial<QueryInlinePostsArgs>
  >
  invoice?: Resolver<
    ResolversTypes['Invoice'],
    ParentType,
    ContextType,
    RequireFields<QueryInvoiceArgs, 'id'>
  >
  lastActiveSeason?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  latestArtifacts?: Resolver<
    Maybe<Array<ResolversTypes['Artifact']>>,
    ParentType,
    ContextType,
    Partial<QueryLatestArtifactsArgs>
  >
  lessonViewer?: Resolver<
    Maybe<ResolversTypes['LessonViewer']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryLessonViewerArgs,
      'cmsModuleSlug' | 'cmsProgramSlug' | 'cmsSectionSlug' | 'path'
    >
  >
  liveCoursesIncludingGuide?: Resolver<
    Maybe<Array<ResolversTypes['CclCourse']>>,
    ParentType,
    ContextType,
    RequireFields<QueryLiveCoursesIncludingGuideArgs, 'guideSlug'>
  >
  marketingCourse?: Resolver<
    ResolversTypes['MarketingCourse'],
    ParentType,
    ContextType,
    RequireFields<QueryMarketingCourseArgs, 'slug'>
  >
  marketingCourseCclTopics?: Resolver<
    Maybe<Array<ResolversTypes['CclTopic']>>,
    ParentType,
    ContextType,
    Partial<QueryMarketingCourseCclTopicsArgs>
  >
  marketingCourseDetails?: Resolver<
    Maybe<ResolversTypes['CourseDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryMarketingCourseDetailsArgs, 'slug'>
  >
  marketingCourses?: Resolver<
    Array<ResolversTypes['MarketingCourse']>,
    ParentType,
    ContextType,
    Partial<QueryMarketingCoursesArgs>
  >
  marketingCoursesIndex?: Resolver<ResolversTypes['CourseIndex'], ParentType, ContextType>
  marketingExperts?: Resolver<ResolversTypes['ExpertsIndex'], ParentType, ContextType>
  marketingHomepage?: Resolver<ResolversTypes['Homepage'], ParentType, ContextType>
  marketingSiteSettings?: Resolver<
    ResolversTypes['SiteSettings'],
    ParentType,
    ContextType
  >
  mentionSearch?: Resolver<
    Array<ResolversTypes['UserMention']>,
    ParentType,
    ContextType,
    RequireFields<QueryMentionSearchArgs, 'nameQuery'>
  >
  moduleCompletionStatus?: Resolver<
    ResolversTypes['ModuleCompletionStatus'],
    ParentType,
    ContextType,
    RequireFields<QueryModuleCompletionStatusArgs, 'cmsSectionId'>
  >
  mostRecentCollectionActivityPerUser?: Resolver<
    Array<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType,
    RequireFields<QueryMostRecentCollectionActivityPerUserArgs, 'collectionId'>
  >
  newestContent?: Resolver<Array<ResolversTypes['CmsSection']>, ParentType, ContextType>
  onboardingInfo?: Resolver<ResolversTypes['OnboardingInfo'], ParentType, ContextType>
  openPositionsPage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  pinnedPosts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  plansForSale?: Resolver<Array<ResolversTypes['PlanForSale']>, ParentType, ContextType>
  podcast?: Resolver<
    Maybe<ResolversTypes['PodcastDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryPodcastArgs, 'slug'>
  >
  podcastIndex?: Resolver<
    ResolversTypes['PodcastIndex'],
    ParentType,
    ContextType,
    Partial<QueryPodcastIndexArgs>
  >
  popularCohortPosts?: Resolver<
    Array<ResolversTypes['Post']>,
    ParentType,
    ContextType,
    Partial<QueryPopularCohortPostsArgs>
  >
  popularEventsPastFeed?: Resolver<
    ResolversTypes['PopularEventsPastFeed'],
    ParentType,
    ContextType
  >
  popularPosts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  popularPostsFiltering?: Resolver<
    Maybe<ResolversTypes['GroupPostFeed']>,
    ParentType,
    ContextType,
    Partial<QueryPopularPostsFilteringArgs>
  >
  postContributorsFilter?: Resolver<
    Array<ResolversTypes['PostContributor']>,
    ParentType,
    ContextType,
    Partial<QueryPostContributorsFilterArgs>
  >
  postsSearch?: Resolver<
    ResolversTypes['PostsSearch'],
    ParentType,
    ContextType,
    RequireFields<QueryPostsSearchArgs, 'query'>
  >
  preEnrollmentSeason?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  previewUpcomingInvoice?: Resolver<
    Maybe<ResolversTypes['StripeUpcomingInvoice']>,
    ParentType,
    ContextType,
    RequireFields<QueryPreviewUpcomingInvoiceArgs, 'planName' | 'subscriptionId'>
  >
  pricingPage?: Resolver<ResolversTypes['PricingPage'], ParentType, ContextType>
  privacyPolicyPage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  profile?: Resolver<
    Maybe<ResolversTypes['Profile']>,
    ParentType,
    ContextType,
    RequireFields<QueryProfileArgs, 'slug'>
  >
  projectDeliverable?: Resolver<
    Maybe<ResolversTypes['ProjectDeliverable']>,
    ParentType,
    ContextType,
    RequireFields<QueryProjectDeliverableArgs, 'deliverableId'>
  >
  projects?: Resolver<
    Maybe<ResolversTypes['ContentFeed']>,
    ParentType,
    ContextType,
    Partial<QueryProjectsArgs>
  >
  publicEvents?: Resolver<Maybe<Array<ResolversTypes['Event']>>, ParentType, ContextType>
  recentChats?: Resolver<
    ResolversTypes['AiChatConnection'],
    ParentType,
    ContextType,
    Partial<QueryRecentChatsArgs>
  >
  recentChatsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  recentlyViewedContent?: Resolver<
    Array<ResolversTypes['RecentlyViewedContent']>,
    ParentType,
    ContextType
  >
  recommendedCollection?: Resolver<
    ResolversTypes['Collection'],
    ParentType,
    ContextType,
    RequireFields<QueryRecommendedCollectionArgs, 'swimlaneId'>
  >
  recommendedCollections?: Resolver<
    Array<ResolversTypes['Collection']>,
    ParentType,
    ContextType
  >
  recommendedContent?: Resolver<
    ResolversTypes['RecommendedContent'],
    ParentType,
    ContextType,
    RequireFields<QueryRecommendedContentArgs, 'limit'>
  >
  recommendedCourses?: Resolver<
    Array<ResolversTypes['CclCourse']>,
    ParentType,
    ContextType
  >
  reforgeCollections?: Resolver<
    Maybe<Array<ResolversTypes['BookmarkFolder']>>,
    ParentType,
    ContextType
  >
  reforgeUsages?: Resolver<Array<ResolversTypes['ReforgeUsage']>, ParentType, ContextType>
  reimbursementPage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  relatedBlogPosts?: Resolver<
    Array<ResolversTypes['BlogPost']>,
    ParentType,
    ContextType,
    RequireFields<QueryRelatedBlogPostsArgs, 'subtopicIds'>
  >
  relatedCourses?: Resolver<
    Maybe<Array<ResolversTypes['SanityCourse']>>,
    ParentType,
    ContextType,
    RequireFields<QueryRelatedCoursesArgs, 'slug'>
  >
  relatedGuides?: Resolver<
    Array<ResolversTypes['Unit']>,
    ParentType,
    ContextType,
    RequireFields<QueryRelatedGuidesArgs, 'subtopicIds'>
  >
  sanityTagGroup?: Resolver<
    Maybe<ResolversTypes['TagGroup']>,
    ParentType,
    ContextType,
    RequireFields<QuerySanityTagGroupArgs, 'slug'>
  >
  sanityTagGroups?: Resolver<Array<ResolversTypes['TagGroup']>, ParentType, ContextType>
  sanityUnit?: Resolver<
    Maybe<ResolversTypes['Unit']>,
    ParentType,
    ContextType,
    RequireFields<QuerySanityUnitArgs, 'slug'>
  >
  savedArtifactsAndGuidesForUser?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType,
    RequireFields<QuerySavedArtifactsAndGuidesForUserArgs, 'userId'>
  >
  savedArtifactsForUser?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType,
    RequireFields<QuerySavedArtifactsForUserArgs, 'userId'>
  >
  savedCountPerArtifact?: Resolver<
    Maybe<Array<ResolversTypes['SavedCountPerArtifact']>>,
    ParentType,
    ContextType,
    Partial<QuerySavedCountPerArtifactArgs>
  >
  scheduledWeekProgressPercentHasReachedThresholdForIntervention?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryScheduledWeekProgressPercentHasReachedThresholdForInterventionArgs,
      'scheduledWeekId'
    >
  >
  searchCompany?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType,
    Partial<QuerySearchCompanyArgs>
  >
  searchCompanyWithClearbit?: Resolver<
    Maybe<Array<ResolversTypes['ClearbitCompany']>>,
    ParentType,
    ContextType,
    Partial<QuerySearchCompanyWithClearbitArgs>
  >
  searchDocuments?: Resolver<
    Maybe<ResolversTypes['SearchDocumentList']>,
    ParentType,
    ContextType,
    Partial<QuerySearchDocumentsArgs>
  >
  searchDocumentsTopResults?: Resolver<
    Maybe<ResolversTypes['SearchDocumentTopResult']>,
    ParentType,
    ContextType,
    Partial<QuerySearchDocumentsTopResultsArgs>
  >
  searchSuggestions?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchSuggestionsArgs, 'query'>
  >
  season?: Resolver<
    ResolversTypes['Season'],
    ParentType,
    ContextType,
    RequireFields<QuerySeasonArgs, 'id'>
  >
  similarPosts?: Resolver<
    Array<ResolversTypes['SimilarPost']>,
    ParentType,
    ContextType,
    RequireFields<QuerySimilarPostsArgs, 'title'>
  >
  similaritySearch?: Resolver<
    ResolversTypes['SimilaritySearchResponse'],
    ParentType,
    ContextType,
    RequireFields<QuerySimilaritySearchArgs, 'query'>
  >
  snapshotLandingPage?: Resolver<
    ResolversTypes['SnapshotLandingPage'],
    ParentType,
    ContextType,
    RequireFields<QuerySnapshotLandingPageArgs, 'snapshotType' | 'token'>
  >
  ssoAuthorization?: Resolver<
    Maybe<ResolversTypes['SsoAuthorization']>,
    ParentType,
    ContextType,
    RequireFields<QuerySsoAuthorizationArgs, 'email'>
  >
  startingBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  subscriptionJoinRequest?: Resolver<
    Maybe<ResolversTypes['SubscriptionJoinRequest']>,
    ParentType,
    ContextType,
    Partial<QuerySubscriptionJoinRequestArgs>
  >
  subscriptionTeamPreview?: Resolver<
    Maybe<ResolversTypes['TeamPreview']>,
    ParentType,
    ContextType,
    RequireFields<QuerySubscriptionTeamPreviewArgs, 'memberToken'>
  >
  subtopic?: Resolver<
    Maybe<ResolversTypes['ArtifactSubtopic']>,
    ParentType,
    ContextType,
    RequireFields<QuerySubtopicArgs, 'slug'>
  >
  subtopics?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactSubtopic']>>,
    ParentType,
    ContextType,
    Partial<QuerySubtopicsArgs>
  >
  suggestedPrompts?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<QuerySuggestedPromptsArgs>
  >
  supplyCourseSession?: Resolver<
    ResolversTypes['SupplyCourseSession'],
    ParentType,
    ContextType,
    Partial<QuerySupplyCourseSessionArgs>
  >
  taxIdReverseChargeStatus?: Resolver<
    Maybe<ResolversTypes['TaxIdReverseChargeStatus']>,
    ParentType,
    ContextType
  >
  taxIds?: Resolver<Array<ResolversTypes['StripeCustomerTaxId']>, ParentType, ContextType>
  taxInfo?: Resolver<
    Maybe<Array<ResolversTypes['TaxInfoItem']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTaxInfoArgs, 'productKeys'>
  >
  teamMembers?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  teamMentionSearch?: Resolver<
    Array<ResolversTypes['UserMention']>,
    ParentType,
    ContextType,
    RequireFields<QueryTeamMentionSearchArgs, 'nameQuery'>
  >
  teamsGetInTouchPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>
  teamsMarketingPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>
  termsOfServicePage?: Resolver<ResolversTypes['Page'], ParentType, ContextType>
  testimonials?: Resolver<Array<ResolversTypes['Testimonial']>, ParentType, ContextType>
  timezones?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  topic?: Resolver<
    Maybe<ResolversTypes['Topic']>,
    ParentType,
    ContextType,
    RequireFields<QueryTopicArgs, 'slug'>
  >
  topics?: Resolver<
    Array<ResolversTypes['ArtifactTopic']>,
    ParentType,
    ContextType,
    Partial<QueryTopicsArgs>
  >
  totalCourseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  upcomingCourseSessions?: Resolver<
    Maybe<Array<ResolversTypes['CclCourseSession']>>,
    ParentType,
    ContextType,
    Partial<QueryUpcomingCourseSessionsArgs>
  >
  upcomingPosts?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>
  upcomingSeason?: Resolver<Maybe<ResolversTypes['Season']>, ParentType, ContextType>
  uqQuestionnaire?: Resolver<
    Maybe<ResolversTypes['UqQuestionnaire']>,
    ParentType,
    ContextType,
    RequireFields<QueryUqQuestionnaireArgs, 'id'>
  >
  uqUserAnswers?: Resolver<ResolversTypes['UqUserAnswers'], ParentType, ContextType>
  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, 'id'>
  >
  userCoachingSessions?: Resolver<
    Array<ResolversTypes['CoachingSession']>,
    ParentType,
    ContextType
  >
  userCohort?: Resolver<
    Maybe<ResolversTypes['UserCohort']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserCohortArgs, 'slug'>
  >
  userCohortUsers?: Resolver<
    Maybe<Array<ResolversTypes['User']>>,
    ParentType,
    ContextType,
    Partial<QueryUserCohortUsersArgs>
  >
  userCohortUsersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<QueryUserCohortUsersCountArgs>
  >
  userCohorts?: Resolver<
    Array<ResolversTypes['UserCohort']>,
    ParentType,
    ContextType,
    Partial<QueryUserCohortsArgs>
  >
  userCourse?: Resolver<
    Maybe<ResolversTypes['UserCourse']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserCourseArgs, 'courseSessionId'>
  >
  userEmails?: Resolver<Array<ResolversTypes['UserEmail']>, ParentType, ContextType>
  userEventsPastFeed?: Resolver<
    ResolversTypes['UserEventsPastFeed'],
    ParentType,
    ContextType,
    Partial<QueryUserEventsPastFeedArgs>
  >
  userPatronage?: Resolver<
    ResolversTypes['UserPatronage'],
    ParentType,
    ContextType,
    RequireFields<QueryUserPatronageArgs, 'externalId'>
  >
  userPaymentMethods?: Resolver<
    Array<ResolversTypes['StripePaymentMethod']>,
    ParentType,
    ContextType,
    Partial<QueryUserPaymentMethodsArgs>
  >
  userPaymentSources?: Resolver<
    Maybe<Array<ResolversTypes['StripePaymentSources']>>,
    ParentType,
    ContextType,
    Partial<QueryUserPaymentSourcesArgs>
  >
  usersFeed?: Resolver<
    Maybe<ResolversTypes['UsersFeed']>,
    ParentType,
    ContextType,
    Partial<QueryUsersFeedArgs>
  >
  validateEmail?: Resolver<
    ResolversTypes['ValidateEmail'],
    ParentType,
    ContextType,
    RequireFields<QueryValidateEmailArgs, 'email'>
  >
  viewedArtifactsForUser?: Resolver<
    Maybe<Array<ResolversTypes['ViewedArtifact']>>,
    ParentType,
    ContextType,
    RequireFields<QueryViewedArtifactsForUserArgs, 'userId'>
  >
}

export type ReactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Reaction'] = ResolversParentTypes['Reaction']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<ResolversTypes['ReactionKind'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecentlyViewedContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecentlyViewedContent'] = ResolversParentTypes['RecentlyViewedContent']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecommendedContent'] = ResolversParentTypes['RecommendedContent']
> = {
  cursor?: Resolver<
    Maybe<ResolversTypes['RecommendedContentCursor']>,
    ParentType,
    ContextType
  >
  results?: Resolver<
    Array<ResolversTypes['RecommendedContentResults']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedContentCursorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecommendedContentCursor'] = ResolversParentTypes['RecommendedContentCursor']
> = {
  cclSyncMapId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  firstPublishedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedContentResultsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecommendedContentResults'] = ResolversParentTypes['RecommendedContentResults']
> = {
  authors?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType>
  cclSyncMapId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  commentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  companyLogoAltText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  estimatedTimeInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isFree?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  localId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  sanityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stepsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  viewerCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RecommendedItem'] = ResolversParentTypes['RecommendedItem']
> = {
  authors?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType>
  cclSyncMapId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  commentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  companyLogoAltText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  draftTemplateLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  draftTemplateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  estimatedTimeInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFree?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  localId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  previewable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  sanityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stepsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  viewerCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReforgeComparisonResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReforgeComparison'] = ResolversParentTypes['ReforgeComparison']
> = {
  audience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  relevance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timeCommitment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReforgeUsageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReforgeUsage'] = ResolversParentTypes['ReforgeUsage']
> = {
  checklistCopy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  onboardingCopy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onboardingSubcopy?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RegenerateCourseSnapshotPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RegenerateCourseSnapshotPayload'] = ResolversParentTypes['RegenerateCourseSnapshotPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  userCourseId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReinstateSeatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReinstateSeatPayload'] = ResolversParentTypes['ReinstateSeatPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveAiChatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveAiChatPayload'] = ResolversParentTypes['RemoveAiChatPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveAnnotationReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveAnnotationReactionPayload'] = ResolversParentTypes['RemoveAnnotationReactionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveArtifactCommentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveArtifactCommentPayload'] = ResolversParentTypes['RemoveArtifactCommentPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveArtifactCommentReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveArtifactCommentReactionPayload'] = ResolversParentTypes['RemoveArtifactCommentReactionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveReactionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveReactionPayload'] = ResolversParentTypes['RemoveReactionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveReplyPayload'] = ResolversParentTypes['RemoveReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveSeatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveSeatPayload'] = ResolversParentTypes['RemoveSeatPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveSubscriptionFromOrgPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveSubscriptionFromOrgPayload'] = ResolversParentTypes['RemoveSubscriptionFromOrgPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  org?: Resolver<Maybe<ResolversTypes['Org']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveSubscriptionMemberPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveSubscriptionMemberPayload'] = ResolversParentTypes['RemoveSubscriptionMemberPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  subscription?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RenameAiChatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RenameAiChatPayload'] = ResolversParentTypes['RenameAiChatPayload']
> = {
  aiChat?: Resolver<ResolversTypes['AiChat'], ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReorderFiledBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReorderFiledBookmarkPayload'] = ResolversParentTypes['ReorderFiledBookmarkPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  filedBookmark?: Resolver<
    Maybe<ResolversTypes['FiledBookmark']>,
    ParentType,
    ContextType
  >
  oldPosition?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReplyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Reply'] = ResolversParentTypes['Reply']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bodyText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comments?: Resolver<Array<ResolversTypes['Comment']>, ParentType, ContextType>
  commentsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  followeesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  post?: Resolver<Maybe<ResolversTypes['Post']>, ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>
  replyableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReportCohortPostOrReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportCohortPostOrReplyPayload'] = ResolversParentTypes['ReportCohortPostOrReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RestoreCollectionActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RestoreCollectionActivityPayload'] = ResolversParentTypes['RestoreCollectionActivityPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionActivity?: Resolver<
    ResolversTypes['CollectionActivity'],
    ParentType,
    ContextType
  >
  filedBookmark?: Resolver<ResolversTypes['FiledBookmark'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review']
> = {
  authorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  authorPosition?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  complexAuthor?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  complexContent?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SanityCompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SanityCompany'] = ResolversParentTypes['SanityCompany']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SanityCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SanityCourse'] = ResolversParentTypes['SanityCourse']
> = {
  bookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  courseBlocks?: Resolver<
    Maybe<Array<ResolversTypes['CourseBlock']>>,
    ParentType,
    ContextType
  >
  creators?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  heroImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAvailableOnDemand?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  popularWithRoles?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  testimonials?: Resolver<
    Maybe<Array<ResolversTypes['SanityTestimonial']>>,
    ParentType,
    ContextType
  >
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  trailerVideo?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  upcomingSessions?: Resolver<
    Maybe<Array<ResolversTypes['CourseSession']>>,
    ParentType,
    ContextType
  >
  whatYouWillLearn?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  whoThisIsFor?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SanityHostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SanityHost'] = ResolversParentTypes['SanityHost']
> = {
  hostBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostPicture?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SanityTestimonialResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SanityTestimonial'] = ResolversParentTypes['SanityTestimonial']
> = {
  company?: Resolver<Maybe<ResolversTypes['SanityCompany']>, ParentType, ContextType>
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subheader?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaveCustomerTaxIdPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SaveCustomerTaxIdPayload'] = ResolversParentTypes['SaveCustomerTaxIdPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  taxId?: Resolver<ResolversTypes['StripeCustomerTaxId'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedCountPerArtifactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SavedCountPerArtifact'] = ResolversParentTypes['SavedCountPerArtifact']
> = {
  artifactId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduledWeekResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ScheduledWeek'] = ResolversParentTypes['ScheduledWeek']
> = {
  bonus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  core?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  estimatedTimeInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCurrentWeek?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  nextModule?: Resolver<Maybe<ResolversTypes['CmsModule']>, ParentType, ContextType>
  numLessons?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numModules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  orderedCmsModules?: Resolver<
    Array<ResolversTypes['CmsModule']>,
    ParentType,
    ContextType
  >
  postCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  progressPercent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchDocument'] = ResolversParentTypes['SearchDocument']
> = {
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentTsvectorTsearch?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  pgSearchHighlight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  searchable?: Resolver<ResolversTypes['Searchable'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchDocumentListResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchDocumentList'] = ResolversParentTypes['SearchDocumentList']
> = {
  results?: Resolver<Array<ResolversTypes['SearchDocument']>, ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchDocumentTopResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchDocumentTopResult'] = ResolversParentTypes['SearchDocumentTopResult']
> = {
  members?: Resolver<
    ResolversTypes['SearchDocumentTopResultCategory'],
    ParentType,
    ContextType
  >
  programContent?: Resolver<
    ResolversTypes['SearchDocumentTopResultCategory'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchDocumentTopResultCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SearchDocumentTopResultCategory'] = ResolversParentTypes['SearchDocumentTopResultCategory']
> = {
  results?: Resolver<Array<ResolversTypes['SearchDocument']>, ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchableResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Searchable'] = ResolversParentTypes['Searchable']
> = {
  __resolveType: TypeResolveFn<
    'CmsSection' | 'Comment' | 'Post' | 'Reply' | 'User',
    ParentType,
    ContextType
  >
}

export type SeasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Season'] = ResolversParentTypes['Season']
> = {
  activeAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  blogPostAnnouncementUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  endsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enrollmentsCloseAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  enrollmentsOpenAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  faqs?: Resolver<Maybe<Array<ResolversTypes['Faq']>>, ParentType, ContextType>
  hasStarted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nextSeasonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  npsSurveyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  npsSurveyText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  openForEnrollment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  prettyNameSeasonFirst?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showBlogPostAnnouncementAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  showCohortViewerAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  year?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Seat'] = ResolversParentTypes['Seat']
> = {
  hasCohortAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isRenewing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  scheduledForDeletionDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  subscriptionMember?: Resolver<
    Maybe<ResolversTypes['SubscriptionMember']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SelectFeaturedPostsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SelectFeaturedPostsPayload'] = ResolversParentTypes['SelectFeaturedPostsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendEmailVerifyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SendEmailVerifyPayload'] = ResolversParentTypes['SendEmailVerifyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendMergeRequestEmailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SendMergeRequestEmailPayload'] = ResolversParentTypes['SendMergeRequestEmailPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetDismissedUpdateCompanyBannerAtPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SetDismissedUpdateCompanyBannerAtPayload'] = ResolversParentTypes['SetDismissedUpdateCompanyBannerAtPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currentUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetHasSeenDemographicsModalPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SetHasSeenDemographicsModalPayload'] = ResolversParentTypes['SetHasSeenDemographicsModalPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currentUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetUserCohortPreferencesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SetUserCohortPreferencesPayload'] = ResolversParentTypes['SetUserCohortPreferencesPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ShareBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ShareBookmarkFolderPayload'] = ResolversParentTypes['ShareBookmarkFolderPayload']
> = {
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  sharedFolders?: Resolver<
    Maybe<Array<ResolversTypes['SharedFolder']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SharedFolderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SharedFolder'] = ResolversParentTypes['SharedFolder']
> = {
  bookmarkFolder?: Resolver<ResolversTypes['BookmarkFolder'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sharedPartyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sharedPartyType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['SharedFolderStatus'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilarPostResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SimilarPost'] = ResolversParentTypes['SimilarPost']
> = {
  age?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reactionsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  responseCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilaritySearchBreadcrumbResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SimilaritySearchBreadcrumb'] = ResolversParentTypes['SimilaritySearchBreadcrumb']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilaritySearchContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SimilaritySearchContent'] = ResolversParentTypes['SimilaritySearchContent']
> = {
  author?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  breadCrumbs?: Resolver<
    Array<ResolversTypes['SimilaritySearchBreadcrumb']>,
    ParentType,
    ContextType
  >
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentType?: Resolver<
    ResolversTypes['SimilaritySearchContentType'],
    ParentType,
    ContextType
  >
  highlight?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilaritySearchResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SimilaritySearchResponse'] = ResolversParentTypes['SimilaritySearchResponse']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  results?: Resolver<
    Array<ResolversTypes['SimilaritySearchContent']>,
    ParentType,
    ContextType
  >
  suggestions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SiteSeoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SiteSeo'] = ResolversParentTypes['SiteSeo']
> = {
  metaDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  metaTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareDesc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shareGraphic?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  shareTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SiteSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SiteSettings'] = ResolversParentTypes['SiteSettings']
> = {
  cookieConsent?: Resolver<
    Maybe<ResolversTypes['CookieConsent']>,
    ParentType,
    ContextType
  >
  seo?: Resolver<Maybe<ResolversTypes['SiteSeo']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SlackAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SlackAccount'] = ResolversParentTypes['SlackAccount']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slackUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Snapshot'] = ResolversParentTypes['Snapshot']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  released?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userCohort?: Resolver<ResolversTypes['UserCohort'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SnapshotLandingPageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SnapshotLandingPage'] = ResolversParentTypes['SnapshotLandingPage']
> = {
  cmsProgramId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  contentLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  programDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  programName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seasonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  showcaseContentId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SsoAuthorizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SsoAuthorization'] = ResolversParentTypes['SsoAuthorization']
> = {
  authorizationUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSsoActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SsoFeatureSetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SsoFeatureSet'] = ResolversParentTypes['SsoFeatureSet']
> = {
  saml?: Resolver<ResolversTypes['SsoFeatureStatus'], ParentType, ContextType>
  scim?: Resolver<ResolversTypes['SsoFeatureStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SsoFeatureStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SsoFeatureStatus'] = ResolversParentTypes['SsoFeatureStatus']
> = {
  errors?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['SsoFeatureStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SsoQueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SsoQuery'] = ResolversParentTypes['SsoQuery']
> = {
  __resolveType: TypeResolveFn<'Query', ParentType, ContextType>
  enterpriseSsoStatus?: Resolver<ResolversTypes['SsoFeatureSet'], ParentType, ContextType>
}

export type StripeAchCreditTransferResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeAchCreditTransfer'] = ResolversParentTypes['StripeAchCreditTransfer']
> = {
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fingerprint?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  routingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  swiftCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeAddressResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeAddress'] = ResolversParentTypes['StripeAddress']
> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  line1?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  line2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeBankAccountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeBankAccount'] = ResolversParentTypes['StripeBankAccount']
> = {
  accountHolderName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  accountHolderType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  defaultSource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  fingerprint?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  routingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeCustomerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeCustomer'] = ResolversParentTypes['StripeCustomer']
> = {
  address?: Resolver<Maybe<ResolversTypes['StripeAddress']>, ParentType, ContextType>
  balance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeCustomerTaxIdResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeCustomerTaxId'] = ResolversParentTypes['StripeCustomerTaxId']
> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  stripeCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  validationStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeInvoiceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeInvoice'] = ResolversParentTypes['StripeInvoice']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dueDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  hostedInvoiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  item?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lineItems?: Resolver<
    Array<ResolversTypes['StripeInvoiceLineItem']>,
    ParentType,
    ContextType
  >
  periodEnd?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  periodStart?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  postPaymentCreditNotesAmount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  status?: Resolver<Maybe<ResolversTypes['StripeInvoiceStatus']>, ParentType, ContextType>
  subtotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  subtotalExcludingTax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  tax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalExcludingTax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalTaxAmounts?: Resolver<
    Array<ResolversTypes['StripeTaxAmount']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeInvoiceLineItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeInvoiceLineItem'] = ResolversParentTypes['StripeInvoiceLineItem']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  amountExcludingTax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productItemCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  taxAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  taxAmounts?: Resolver<Array<ResolversTypes['StripeTaxAmount']>, ParentType, ContextType>
  taxRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripePaymentMethodResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripePaymentMethod'] = ResolversParentTypes['StripePaymentMethod']
> = {
  card?: Resolver<
    Maybe<ResolversTypes['StripePaymentMethodCard']>,
    ParentType,
    ContextType
  >
  defaultPaymentMethod?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripePaymentMethodCardResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripePaymentMethodCard'] = ResolversParentTypes['StripePaymentMethodCard']
> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  expMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  expYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  fingerprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripePaymentSourcesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripePaymentSources'] = ResolversParentTypes['StripePaymentSources']
> = {
  __resolveType: TypeResolveFn<
    'StripeBankAccount' | 'StripeSource',
    ParentType,
    ContextType
  >
}

export type StripeSetupIntentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeSetupIntentPayload'] = ResolversParentTypes['StripeSetupIntentPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeSourceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeSource'] = ResolversParentTypes['StripeSource']
> = {
  achCreditTransfer?: Resolver<
    Maybe<ResolversTypes['StripeAchCreditTransfer']>,
    ParentType,
    ContextType
  >
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  defaultSource?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeSubscriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeSubscription'] = ResolversParentTypes['StripeSubscription']
> = {
  cancelAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cancelAtPeriodEnd?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  currentPeriodEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaymentMethodOnFile?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  paymentFailed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  plan?: Resolver<
    Maybe<ResolversTypes['StripeSubscriptionPlan']>,
    ParentType,
    ContextType
  >
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeSubscriptionPlan'] = ResolversParentTypes['StripeSubscriptionPlan']
> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  object?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeTaxAmountResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeTaxAmount'] = ResolversParentTypes['StripeTaxAmount']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  taxRate?: Resolver<Maybe<ResolversTypes['StripeTaxRate']>, ParentType, ContextType>
  taxRateId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeTaxRateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeTaxRate'] = ResolversParentTypes['StripeTaxRate']
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  jurisdiction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StripeUpcomingInvoiceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StripeUpcomingInvoice'] = ResolversParentTypes['StripeUpcomingInvoice']
> = {
  amountDue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discountedCohortPassAmount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lines?: Resolver<
    Array<ResolversTypes['StripeInvoiceLineItem']>,
    ParentType,
    ContextType
  >
  numCohortPasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  numSeats?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  planName?: Resolver<ResolversTypes['PlanName'], ParentType, ContextType>
  proratedAdjustment?: Resolver<
    Maybe<ResolversTypes['CheckoutProrationAdjustment']>,
    ParentType,
    ContextType
  >
  startingBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  subtotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  tax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubmitOnboardingQuestionnairePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitOnboardingQuestionnairePayload'] = ResolversParentTypes['SubmitOnboardingQuestionnairePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  homepageChecks?: Resolver<ResolversTypes['HomepageCheck'], ParentType, ContextType>
  questionnaire?: Resolver<
    ResolversTypes['OnboardingQuestionnaire'],
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubmitPostApplySurveyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitPostApplySurveyPayload'] = ResolversParentTypes['SubmitPostApplySurveyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']
> = {
  cohortCredits?: SubscriptionResolver<
    ResolversTypes['CohortCredits'],
    'cohortCredits',
    ParentType,
    ContextType
  >
  completedTrial?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'completedTrial',
    ParentType,
    ContextType
  >
  daysLeft?: SubscriptionResolver<
    ResolversTypes['Int'],
    'daysLeft',
    ParentType,
    ContextType
  >
  daysLeftInTrial?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'daysLeftInTrial',
    ParentType,
    ContextType
  >
  domainName?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'domainName',
    ParentType,
    ContextType
  >
  dunningAmountDueInCents?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'dunningAmountDueInCents',
    ParentType,
    ContextType
  >
  dunningDaysLeft?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'dunningDaysLeft',
    ParentType,
    ContextType
  >
  expiresAt?: SubscriptionResolver<
    ResolversTypes['String'],
    'expiresAt',
    ParentType,
    ContextType
  >
  expiringSeatCount?: SubscriptionResolver<
    ResolversTypes['Int'],
    'expiringSeatCount',
    ParentType,
    ContextType
  >
  externalId?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'externalId',
    ParentType,
    ContextType
  >
  hasAvailableSeats?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'hasAvailableSeats',
    ParentType,
    ContextType
  >
  hasUnlimitedCohortAccess?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'hasUnlimitedCohortAccess',
    ParentType,
    ContextType
  >
  id?: SubscriptionResolver<ResolversTypes['ID'], 'id', ParentType, ContextType>
  isCanceled?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'isCanceled',
    ParentType,
    ContextType
  >
  isDiscoverable?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'isDiscoverable',
    ParentType,
    ContextType
  >
  isDunning?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'isDunning',
    ParentType,
    ContextType
  >
  isExpired?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'isExpired',
    ParentType,
    ContextType
  >
  isLegacy?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'isLegacy',
    ParentType,
    ContextType
  >
  isPaidMonthly?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'isPaidMonthly',
    ParentType,
    ContextType
  >
  isPartnerSubscription?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'isPartnerSubscription',
    ParentType,
    ContextType
  >
  isProvisionedByScim?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'isProvisionedByScim',
    ParentType,
    ContextType
  >
  isTeam?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'isTeam',
    ParentType,
    ContextType
  >
  lastFailedPaymentAt?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'lastFailedPaymentAt',
    ParentType,
    ContextType
  >
  logoUrl?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'logoUrl',
    ParentType,
    ContextType
  >
  manualAssign?: SubscriptionResolver<
    Maybe<ResolversTypes['Boolean']>,
    'manualAssign',
    ParentType,
    ContextType
  >
  name?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'name',
    ParentType,
    ContextType
  >
  numSeats?: SubscriptionResolver<
    ResolversTypes['Int'],
    'numSeats',
    ParentType,
    ContextType
  >
  numSeatsAffectedByCancellation?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'numSeatsAffectedByCancellation',
    ParentType,
    ContextType
  >
  numSeatsAvailable?: SubscriptionResolver<
    ResolversTypes['Int'],
    'numSeatsAvailable',
    ParentType,
    ContextType
  >
  org?: SubscriptionResolver<Maybe<ResolversTypes['Org']>, 'org', ParentType, ContextType>
  orgId?: SubscriptionResolver<
    Maybe<ResolversTypes['ID']>,
    'orgId',
    ParentType,
    ContextType
  >
  planName?: SubscriptionResolver<
    ResolversTypes['PlanName'],
    'planName',
    ParentType,
    ContextType
  >
  purchaser?: SubscriptionResolver<
    Maybe<ResolversTypes['User']>,
    'purchaser',
    ParentType,
    ContextType
  >
  renewSeatCount?: SubscriptionResolver<
    ResolversTypes['Int'],
    'renewSeatCount',
    ParentType,
    ContextType
  >
  seats?: SubscriptionResolver<
    Array<ResolversTypes['Seat']>,
    'seats',
    ParentType,
    ContextType
  >
  shareableLinkUrl?: SubscriptionResolver<
    ResolversTypes['String'],
    'shareableLinkUrl',
    ParentType,
    ContextType
  >
  ssoConfigurationId?: SubscriptionResolver<
    Maybe<ResolversTypes['ID']>,
    'ssoConfigurationId',
    ParentType,
    ContextType
  >
  startsAt?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'startsAt',
    ParentType,
    ContextType
  >
  status?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'status',
    ParentType,
    ContextType
  >
  stripeCollectionMethod?: SubscriptionResolver<
    Maybe<ResolversTypes['StripeCollectionMethod']>,
    'stripeCollectionMethod',
    ParentType,
    ContextType
  >
  stripeCustomerId?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'stripeCustomerId',
    ParentType,
    ContextType
  >
  stripeSubscription?: SubscriptionResolver<
    Maybe<ResolversTypes['StripeSubscription']>,
    'stripeSubscription',
    ParentType,
    ContextType
  >
  stripeSubscriptionId?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'stripeSubscriptionId',
    ParentType,
    ContextType
  >
  stripeSubscriptionStatus?: SubscriptionResolver<
    Maybe<ResolversTypes['StripeSubscriptionStatus']>,
    'stripeSubscriptionStatus',
    ParentType,
    ContextType
  >
  stripeUpcomingInvoice?: SubscriptionResolver<
    Maybe<ResolversTypes['StripeUpcomingInvoice']>,
    'stripeUpcomingInvoice',
    ParentType,
    ContextType
  >
  teamMembers?: SubscriptionResolver<
    ResolversTypes['TeamMembersSearch'],
    'teamMembers',
    ParentType,
    ContextType,
    Partial<SubscriptionTeamMembersArgs>
  >
  teamName?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'teamName',
    ParentType,
    ContextType
  >
  termEndsAt?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'termEndsAt',
    ParentType,
    ContextType
  >
  trialDaysLeft?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'trialDaysLeft',
    ParentType,
    ContextType
  >
  trialEndDate?: SubscriptionResolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    'trialEndDate',
    ParentType,
    ContextType
  >
  trialLength?: SubscriptionResolver<
    Maybe<ResolversTypes['Int']>,
    'trialLength',
    ParentType,
    ContextType
  >
  trialType?: SubscriptionResolver<
    Maybe<ResolversTypes['String']>,
    'trialType',
    ParentType,
    ContextType
  >
  user?: SubscriptionResolver<ResolversTypes['User'], 'user', ParentType, ContextType>
  userId?: SubscriptionResolver<
    ResolversTypes['String'],
    'userId',
    ParentType,
    ContextType
  >
  userIsOwner?: SubscriptionResolver<
    ResolversTypes['Boolean'],
    'userIsOwner',
    ParentType,
    ContextType,
    Partial<SubscriptionUserIsOwnerArgs>
  >
  userJoinRequests?: SubscriptionResolver<
    Array<ResolversTypes['SubscriptionJoinRequest']>,
    'userJoinRequests',
    ParentType,
    ContextType
  >
}

export type SubscriptionCancelMotivationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionCancelMotivationPayload'] = ResolversParentTypes['SubscriptionCancelMotivationPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionCancelPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionCancelPayload'] = ResolversParentTypes['SubscriptionCancelPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionJoinRequestResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionJoinRequest'] = ResolversParentTypes['SubscriptionJoinRequest']
> = {
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionMemberResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionMember'] = ResolversParentTypes['SubscriptionMember']
> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionSeatsChangePreviewPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionSeatsChangePreviewPayload'] = ResolversParentTypes['SubscriptionSeatsChangePreviewPayload']
> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SupplyCourseSessionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SupplyCourseSession'] = ResolversParentTypes['SupplyCourseSession']
> = {
  courseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  courseSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  courseTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  endsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  enrollees?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>
  enrollmentStartsAt?: Resolver<
    ResolversTypes['ISO8601DateTime'],
    ParentType,
    ContextType
  >
  hasEnded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasStarted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isContentOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isDuringEnrollmentPeriod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPast?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isUpcoming?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  maxEnrollmentPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxEnrollmentTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  maxEnrollmentUnlimited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledPaid?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  numEnrolledUnlimited?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  slackChannelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  surveyLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SwimlanesQueryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SwimlanesQuery'] = ResolversParentTypes['SwimlanesQuery']
> = {
  __resolveType: TypeResolveFn<'Query', ParentType, ContextType>
  recommendedCollection?: Resolver<
    ResolversTypes['Collection'],
    ParentType,
    ContextType,
    RequireFields<SwimlanesQueryRecommendedCollectionArgs, 'swimlaneId'>
  >
  recommendedCollections?: Resolver<
    Array<ResolversTypes['Collection']>,
    ParentType,
    ContextType
  >
}

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TagGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagGroup'] = ResolversParentTypes['TagGroup']
> = {
  functionTag?: Resolver<ResolversTypes['Function'], ParentType, ContextType>
  global?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  globalSortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaxIdReverseChargeStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TaxIdReverseChargeStatus'] = ResolversParentTypes['TaxIdReverseChargeStatus']
> = {
  isReverseCharge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  taxIdVerificationStatus?: Resolver<
    Maybe<ResolversTypes['TaxIdVerificationStatus']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaxInfoItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TaxInfoItem'] = ResolversParentTypes['TaxInfoItem']
> = {
  details?: Resolver<Array<ResolversTypes['TaxInfoItemDetails']>, ParentType, ContextType>
  productItemCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TaxInfoItemDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TaxInfoItemDetails'] = ResolversParentTypes['TaxInfoItemDetails']
> = {
  rate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  taxableAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamMemberPreviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TeamMemberPreview'] = ResolversParentTypes['TeamMemberPreview']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  profile?: Resolver<ResolversTypes['TeamMemberProfilePreview'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamMemberProfilePreviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TeamMemberProfilePreview'] = ResolversParentTypes['TeamMemberProfilePreview']
> = {
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamMembersSearchResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TeamMembersSearch'] = ResolversParentTypes['TeamMembersSearch']
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamPostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamPost'] = ResolversParentTypes['TeamPost']
> = {
  activities?: Resolver<Array<ResolversTypes['Activity']>, ParentType, ContextType>
  allReactionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bodyText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSectionLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSectionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsSectionParentName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  createdAtDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  images?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  reactions?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>
  referenceImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  replies?: Resolver<Array<ResolversTypes['Reply']>, ParentType, ContextType>
  repliesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  replyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  replyPreviews?: Resolver<Array<ResolversTypes['Reply']>, ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  timeAgo?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  toParam?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  unreadElements?: Resolver<
    Array<ResolversTypes['UnreadElement']>,
    ParentType,
    ContextType
  >
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  updatedAtInWords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamPreviewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TeamPreview'] = ResolversParentTypes['TeamPreview']
> = {
  members?: Resolver<Array<ResolversTypes['TeamMemberPreview']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ssoAuthorization?: Resolver<
    Maybe<ResolversTypes['SsoAuthorization']>,
    ParentType,
    ContextType
  >
  totalMembers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TentativeUpcomingCohortResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TentativeUpcomingCohort'] = ResolversParentTypes['TentativeUpcomingCohort']
> = {
  endsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  marketingSiteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startsAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  timeOfYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestimonialResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Testimonial'] = ResolversParentTypes['Testimonial']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  approvalStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  copy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  joined?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestimonialWithImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TestimonialWithImage'] = ResolversParentTypes['TestimonialWithImage']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  positionAndCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  quote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ToggleManualAssignSubscriptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToggleManualAssignSubscriptionPayload'] = ResolversParentTypes['ToggleManualAssignSubscriptionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  manualAssign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TogglePostFollowPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TogglePostFollowPayload'] = ResolversParentTypes['TogglePostFollowPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  following?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopicResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Topic'] = ResolversParentTypes['Topic']
> = {
  icon?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isDeprecated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  redirectTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopicActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TopicActivity'] = ResolversParentTypes['TopicActivity']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastViewedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrackServerEventPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TrackServerEventPayload'] = ResolversParentTypes['TrackServerEventPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnassignCohortCreditPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnassignCohortCreditPayload'] = ResolversParentTypes['UnassignCohortCreditPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  seat?: Resolver<Maybe<ResolversTypes['Seat']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnassignSeatPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnassignSeatPayload'] = ResolversParentTypes['UnassignSeatPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  seat?: Resolver<Maybe<ResolversTypes['Seat']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnifiedCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnifiedCourse'] = ResolversParentTypes['UnifiedCourse']
> = {
  availabilitySubtitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  backgroundImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  bookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  breakdownBackgroundImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType
  >
  breakdownImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cclTopics?: Resolver<Maybe<Array<ResolversTypes['CclTopic']>>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsProgramSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  course?: Resolver<Maybe<ResolversTypes['SanityCourse']>, ParentType, ContextType>
  courseFaq?: Resolver<
    Maybe<Array<ResolversTypes['MarketingFaq']>>,
    ParentType,
    ContextType
  >
  courseInfoCards?: Resolver<
    Maybe<Array<ResolversTypes['CourseInfoCard']>>,
    ParentType,
    ContextType
  >
  coursePreviewAvailable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  courseType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  creators?: Resolver<Maybe<Array<ResolversTypes['Creator']>>, ParentType, ContextType>
  ctaButtonLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaButtonText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  goals?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostSectionSubtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostSectionTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hostSubtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hosts?: Resolver<Maybe<Array<ResolversTypes['SanityHost']>>, ParentType, ContextType>
  icon?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  legacyProgramId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  previewImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  targetAudience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topicsCovered?: Resolver<
    Maybe<Array<ResolversTypes['CourseTopicCovered']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Unit'] = ResolversParentTypes['Unit']
> = {
  artifacts?: Resolver<Maybe<Array<ResolversTypes['Artifact']>>, ParentType, ContextType>
  authors?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactAuthor']>>,
    ParentType,
    ContextType
  >
  bookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType,
    Partial<UnitBookmarksArgs>
  >
  completed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  contentBlocks?: Resolver<
    Maybe<Array<ResolversTypes['ContentBlock']>>,
    ParentType,
    ContextType
  >
  contentBookmarkId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  contentIsInMySavedItems?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  estimatedTimeInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  guide?: Resolver<Maybe<ResolversTypes['CclGuide']>, ParentType, ContextType>
  heroImage?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  narrationFileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  overview?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  previewable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  publishDate?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  recap?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  showContentGate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stepsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  subtopicIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['Topic']>, ParentType, ContextType>
  whatYouWillDo?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnreadElementResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnreadElement'] = ResolversParentTypes['UnreadElement']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnshareBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UnshareBookmarkFolderPayload'] = ResolversParentTypes['UnshareBookmarkFolderPayload']
> = {
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  sharedFolder?: Resolver<Maybe<ResolversTypes['SharedFolder']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateAiPersonalizationPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAiPersonalizationPayload'] = ResolversParentTypes['UpdateAiPersonalizationPayload']
> = {
  aiPersonalization?: Resolver<
    Maybe<ResolversTypes['AiPersonalization']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateArtifactCommentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateArtifactCommentPayload'] = ResolversParentTypes['UpdateArtifactCommentPayload']
> = {
  artifactComment?: Resolver<
    Maybe<ResolversTypes['ArtifactComment']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateBookmarkFolderPayload'] = ResolversParentTypes['UpdateBookmarkFolderPayload']
> = {
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateBookmarkPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateBookmarkPayload'] = ResolversParentTypes['UpdateBookmarkPayload']
> = {
  bookmark?: Resolver<Maybe<ResolversTypes['Bookmark']>, ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectionActivity?: Resolver<
    Maybe<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType
  >
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCclPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCclPayload'] = ResolversParentTypes['UpdateCclPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCmsSectionTagsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCmsSectionTagsPayload'] = ResolversParentTypes['UpdateCmsSectionTagsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCoachingSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCoachingSessionPayload'] = ResolversParentTypes['UpdateCoachingSessionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coachingSession?: Resolver<ResolversTypes['CoachingSession'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCohortPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCohortPostPayload'] = ResolversParentTypes['UpdateCohortPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCohortReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCohortReplyPayload'] = ResolversParentTypes['UpdateCohortReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCourseEnrollmentPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCourseEnrollmentPayload'] = ResolversParentTypes['UpdateCourseEnrollmentPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  userCourseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCourseSessionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCourseSessionPayload'] = ResolversParentTypes['UpdateCourseSessionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCustomerContactPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateCustomerContactPayload'] = ResolversParentTypes['UpdateCustomerContactPayload']
> = {
  address?: Resolver<ResolversTypes['StripeAddress'], ParentType, ContextType>
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateHomepageCheckPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateHomepageCheckPayload'] = ResolversParentTypes['UpdateHomepageCheckPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  homepageChecks?: Resolver<ResolversTypes['HomepageCheck'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateInvoiceUserFieldsPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateInvoiceUserFieldsPayload'] = ResolversParentTypes['UpdateInvoiceUserFieldsPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatePaymentMethodPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePaymentMethodPayload'] = ResolversParentTypes['UpdatePaymentMethodPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatePreferencesPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdatePreferencesPayload'] = ResolversParentTypes['UpdatePreferencesPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProjectDeliverablePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateProjectDeliverablePayload'] = ResolversParentTypes['UpdateProjectDeliverablePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  projectDeliverable?: Resolver<
    Maybe<ResolversTypes['ProjectDeliverable']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateReplyPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateReplyPayload'] = ResolversParentTypes['UpdateReplyPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  replyableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateTeamPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTeamPayload'] = ResolversParentTypes['UpdateTeamPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  team?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateTeamPostPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTeamPostPayload'] = ResolversParentTypes['UpdateTeamPostPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  teamPost?: Resolver<Maybe<ResolversTypes['TeamPost']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateTopicActivityPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateTopicActivityPayload'] = ResolversParentTypes['UpdateTopicActivityPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  lastViewedAt?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateUserCohortPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserCohortPayload'] = ResolversParentTypes['UpdateUserCohortPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateUserCoursePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserCoursePayload'] = ResolversParentTypes['UpdateUserCoursePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateUserPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUserPayload'] = ResolversParentTypes['UpdateUserPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpgradeSubscriptionPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpgradeSubscriptionPayload'] = ResolversParentTypes['UpgradeSubscriptionPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UqChoiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UqChoice'] = ResolversParentTypes['UqChoice']
> = {
  choice?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UqQuestionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UqQuestion'] = ResolversParentTypes['UqQuestion']
> = {
  choiceText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  choices?: Resolver<Array<ResolversTypes['UqChoice']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  maxChoices?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UqQuestionnaireResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UqQuestionnaire'] = ResolversParentTypes['UqQuestionnaire']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  pages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  questionnaire?: Resolver<
    Maybe<ResolversTypes['OnboardingQuestionnaire']>,
    ParentType,
    ContextType
  >
  questions?: Resolver<Array<ResolversTypes['UqQuestion']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UqUserAnswerResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UqUserAnswer'] = ResolversParentTypes['UqUserAnswer']
> = {
  choice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  choiceTags?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  uqChoiceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  uqQuestionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UqUserAnswersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UqUserAnswers'] = ResolversParentTypes['UqUserAnswers']
> = {
  acceptanceAnswered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  answers?: Resolver<Array<ResolversTypes['UqUserAnswer']>, ParentType, ContextType>
  onboardingStep?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UrlChangePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UrlChangePayload'] = ResolversParentTypes['UrlChangePayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  aboutMeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  accessPolicyKind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  activeProgram?: Resolver<Maybe<ResolversTypes['UserProgram']>, ParentType, ContextType>
  activeSubscriptionMembership?: Resolver<
    Maybe<ResolversTypes['SubscriptionMember']>,
    ParentType,
    ContextType
  >
  allowDirectMessages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  artifactBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  askMeAboutText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  availableTeamSubscriptions?: Resolver<
    Array<ResolversTypes['Subscription']>,
    ParentType,
    ContextType
  >
  avatarUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  biggestChallenges?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bookmarkCmsModules?: Resolver<
    Array<ResolversTypes['CmsModule']>,
    ParentType,
    ContextType,
    Partial<UserBookmarkCmsModulesArgs>
  >
  bookmarkCmsPrograms?: Resolver<
    Array<ResolversTypes['CmsProgram']>,
    ParentType,
    ContextType
  >
  bookmarkFolders?: Resolver<
    Array<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType,
    Partial<UserBookmarkFoldersArgs>
  >
  bookmarksFeed?: Resolver<
    ResolversTypes['BookmarksFeed'],
    ParentType,
    ContextType,
    Partial<UserBookmarksFeedArgs>
  >
  bookmarksTotal?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UserBookmarksTotalArgs>
  >
  can?: Resolver<ResolversTypes['UserCan'], ParentType, ContextType>
  canSubscribeWithoutTrial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  certificates?: Resolver<Array<ResolversTypes['Certificates']>, ParentType, ContextType>
  clips?: Resolver<Array<ResolversTypes['Clip']>, ParentType, ContextType>
  cohort?: Resolver<Maybe<ResolversTypes['Cohort']>, ParentType, ContextType>
  cohortAllActivitiesWithMetadata?: Resolver<
    ResolversTypes['CohortActivityListWithMetadata'],
    ParentType,
    ContextType,
    RequireFields<UserCohortAllActivitiesWithMetadataArgs, 'slug'>
  >
  cohorts?: Resolver<Maybe<ResolversTypes['UserCohorts']>, ParentType, ContextType>
  cohortsHosted?: Resolver<Array<ResolversTypes['Cohort']>, ParentType, ContextType>
  cohortsShowingNpsSurvey?: Resolver<
    Maybe<Array<ResolversTypes['UserCohort']>>,
    ParentType,
    ContextType
  >
  communityInterestText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyNameUpdatedAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  companyProductText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  completedProductTours?: Resolver<
    Maybe<ResolversTypes['ProductTour']>,
    ParentType,
    ContextType
  >
  contact?: Resolver<ResolversTypes['UserContact'], ParentType, ContextType>
  courseBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  currentEnrolledCourse?: Resolver<
    Maybe<ResolversTypes['UserCourse']>,
    ParentType,
    ContextType
  >
  currentTeam?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  currentWorkingOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dismissedUpdateCompanyBannerAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  enrolledCohortEvents?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  enrolledPrograms?: Resolver<
    Array<ResolversTypes['CmsProgram']>,
    ParentType,
    ContextType
  >
  eventBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  eventSpeakerCohortsForActiveSeasons?: Resolver<
    Array<ResolversTypes['Cohort']>,
    ParentType,
    ContextType
  >
  eventsAttending?: Resolver<
    Maybe<Array<ResolversTypes['Event']>>,
    ParentType,
    ContextType
  >
  eventsAttendingStartingSoon?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType
  >
  eventsHosted?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>
  eventsNotAttending?: Resolver<
    Maybe<Array<ResolversTypes['Event']>>,
    ParentType,
    ContextType
  >
  eventsPast?: Resolver<
    Maybe<Array<ResolversTypes['Event']>>,
    ParentType,
    ContextType,
    Partial<UserEventsPastArgs>
  >
  eventsRecentlyInteracted?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType,
    Partial<UserEventsRecentlyInteractedArgs>
  >
  eventsRecommended?: Resolver<
    Maybe<Array<ResolversTypes['Event']>>,
    ParentType,
    ContextType
  >
  externalId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  forceFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  forkedFolderIds?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  groups?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>
  guideBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  hasApplied?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasContentInProgressOrComplete?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasMemberAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPosts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasSeenCollectionsPrompt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasSeenDemographicsModal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasSentInvites?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  hasUpcomingCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasUseableCohortCredits?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hideSlackBanner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  homepageChecks?: Resolver<ResolversTypes['HomepageCheck'], ParentType, ContextType>
  hostCohortsForActiveSeasons?: Resolver<
    Array<ResolversTypes['Cohort']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inPostCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  inProgressCourses?: Resolver<
    Maybe<Array<ResolversTypes['UserCourseActivity']>>,
    ParentType,
    ContextType
  >
  is?: Resolver<ResolversTypes['UserIs'], ParentType, ContextType>
  isCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jobFunction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  jobLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  joinSlackHref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastForumContributions?: Resolver<
    Array<ResolversTypes['ForumContribution']>,
    ParentType,
    ContextType
  >
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  launchedEvergreenPrograms?: Resolver<
    Array<ResolversTypes['CmsProgram']>,
    ParentType,
    ContextType
  >
  learningGoalsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  liveProgramName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  monthJoined?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mostRecentPastUserCohort?: Resolver<
    Maybe<ResolversTypes['UserCohort']>,
    ParentType,
    ContextType
  >
  networkFocus?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  networkOnboarded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  networkRecommendations?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  numModuleBookmarks?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  numSectionBookmarks?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  oauthProviders?: Resolver<
    Maybe<Array<ResolversTypes['UserOauthProvider']>>,
    ParentType,
    ContextType
  >
  onboarded?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  onboardingBookmarkFolderId?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType
  >
  openInvoices?: Resolver<Array<ResolversTypes['StripeInvoice']>, ParentType, ContextType>
  orgs?: Resolver<Array<ResolversTypes['Org']>, ParentType, ContextType>
  ownedOrg?: Resolver<Maybe<ResolversTypes['Org']>, ParentType, ContextType>
  passionateAbout?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pastPayments?: Resolver<Array<ResolversTypes['PastPayment']>, ParentType, ContextType>
  patronageRequestLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentMethods?: Resolver<
    Array<ResolversTypes['StripePaymentMethod']>,
    ParentType,
    ContextType
  >
  preference?: Resolver<Maybe<ResolversTypes['UserPreference']>, ParentType, ContextType>
  preferredCourseView?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  previousCompanies?: Resolver<
    Array<ResolversTypes['UserCompany']>,
    ParentType,
    ContextType
  >
  pricing?: Resolver<ResolversTypes['UserPricing'], ParentType, ContextType>
  profile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>
  programBookmarks?: Resolver<
    Maybe<Array<ResolversTypes['Bookmark']>>,
    ParentType,
    ContextType
  >
  programProgress?: Resolver<
    Maybe<ResolversTypes['ProgramProgress']>,
    ParentType,
    ContextType
  >
  programTeammates?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<UserProgramTeammatesArgs, 'slug'>
  >
  programTopics?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  recentSlackJoins?: Resolver<
    Array<ResolversTypes['SlackAccount']>,
    ParentType,
    ContextType
  >
  recentlyReviewedContent?: Resolver<
    Array<ResolversTypes['Content']>,
    ParentType,
    ContextType
  >
  recommendedBookmarkFolders?: Resolver<
    Array<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  recommendedPrograms?: Resolver<
    Array<ResolversTypes['CmsProgram']>,
    ParentType,
    ContextType
  >
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  roleWithCompany?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rsvpsInWeek?: Resolver<
    Array<ResolversTypes['EventAttendee']>,
    ParentType,
    ContextType,
    RequireFields<UserRsvpsInWeekArgs, 'weekId'>
  >
  seenSlackBannerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  shouldSeeHomepageCoursesSection?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  shouldSeeTrialMessaging?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  shouldShowNpsSurvey?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  slackResponseTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slackUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>
  stripeCustomer?: Resolver<
    Maybe<ResolversTypes['StripeCustomer']>,
    ParentType,
    ContextType
  >
  subscriptionJoinRequests?: Resolver<
    Array<ResolversTypes['SubscriptionJoinRequest']>,
    ParentType,
    ContextType
  >
  subscriptions?: Resolver<
    Maybe<ResolversTypes['UserSubscriptions']>,
    ParentType,
    ContextType
  >
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  trialStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  uniqueEmailDomains?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  unseenActivityCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  unseenCohortNotificationsCount?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<UserUnseenCohortNotificationsCountArgs, 'slug'>
  >
  upcomingEligibleEvents?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType
  >
  upcomingNonCohortEvents?: Resolver<
    Array<ResolversTypes['Event']>,
    ParentType,
    ContextType
  >
  userCollections?: Resolver<
    Maybe<Array<ResolversTypes['BookmarkFolder']>>,
    ParentType,
    ContextType,
    RequireFields<UserUserCollectionsArgs, 'userId'>
  >
  userCourses?: Resolver<ResolversTypes['UserCourses'], ParentType, ContextType>
  userProgram?: Resolver<Maybe<ResolversTypes['UserProgram']>, ParentType, ContextType>
  userProgramsProgressStarted?: Resolver<
    Maybe<Array<ResolversTypes['UserProgram']>>,
    ParentType,
    ContextType
  >
  userProgressByProgram?: Resolver<
    Maybe<ResolversTypes['UserProgram']>,
    ParentType,
    ContextType
  >
  workEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  yearJoined?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCanResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserCan'] = ResolversParentTypes['UserCan']
> = {
  accessAllCohortEventReplays?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  editEmailPreferences?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  enrollInCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  inviteGuests?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  linkToSearchResults?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  manageMyTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  onboard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  pay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewActivity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewActivitySidebar?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewBilling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewBrowsePage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewCohortHistory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewCohortIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewCollectionsIndex?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewConceptsIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewConceptsProjectsSidebar?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewDiscussionIndex?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewEventsIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewLearningGoals?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewManageSeats?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewMemberIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewMemberProfile?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewOrg?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewPost?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewPrograms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewProgramsDashboard?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewProjectsAndConcepts?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  viewProjectsIndex?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewPurchaseFlow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewSearchTabs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewSidebarGroups?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  viewSubscriptionSummary?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCmsActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCmsActivity'] = ResolversParentTypes['UserCmsActivity']
> = {
  actionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cmsProgram?: Resolver<Maybe<ResolversTypes['CmsProgram']>, ParentType, ContextType>
  cmsSection?: Resolver<Maybe<ResolversTypes['CmsSection']>, ParentType, ContextType>
  createdAtInWords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCohortResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCohort'] = ResolversParentTypes['UserCohort']
> = {
  afterCohortCalloutDismissed?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  canBeUpdated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  canViewCertificate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  cohort?: Resolver<ResolversTypes['Cohort'], ParentType, ContextType>
  cohortCompletionCertificate?: Resolver<
    Maybe<ResolversTypes['CohortCertificate']>,
    ParentType,
    ContextType
  >
  cohortEventsAttended?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hasClickedMeetCohortParticipantsCta?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  hasClickedShareSnapshotCta?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasCompletedCohort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasDismissedNewContentEirMessage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasDismissedPostCohortEirMessage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  hasPostedWelcome?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasSignedStatementOfCommitment?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  numLessonsCompleted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  postedInConversationsTopicForScheduledWeek?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<
      UserCohortPostedInConversationsTopicForScheduledWeekArgs,
      'scheduledWeekId'
    >
  >
  programApplicationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  programId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  programName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  season?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seasonId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  showEventRsvpModal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  snapshot?: Resolver<Maybe<ResolversTypes['Snapshot']>, ParentType, ContextType>
  snapshotable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  timeGroupingSelected?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCohortsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCohorts'] = ResolversParentTypes['UserCohorts']
> = {
  all?: Resolver<Array<ResolversTypes['UserCohort']>, ParentType, ContextType>
  current?: Resolver<Array<ResolversTypes['UserCohort']>, ParentType, ContextType>
  latestCurrent?: Resolver<Maybe<ResolversTypes['UserCohort']>, ParentType, ContextType>
  previous?: Resolver<Array<ResolversTypes['UserCohort']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCompany'] = ResolversParentTypes['UserCompany']
> = {
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserContactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserContact'] = ResolversParentTypes['UserContact']
> = {
  allEmails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  notificationPreferences?: Resolver<
    ResolversTypes['UserNotificationPreferences'],
    ParentType,
    ContextType
  >
  primaryEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  projectEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slackAccount?: Resolver<Maybe<ResolversTypes['SlackAccount']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCourseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCourse'] = ResolversParentTypes['UserCourse']
> = {
  cclCourse?: Resolver<Maybe<ResolversTypes['CclCourse']>, ParentType, ContextType>
  cclCourseSession?: Resolver<
    Maybe<ResolversTypes['CclCourseSession']>,
    ParentType,
    ContextType
  >
  cohortCredit?: Resolver<Maybe<ResolversTypes['CohortCredit']>, ParentType, ContextType>
  course?: Resolver<ResolversTypes['Course'], ParentType, ContextType>
  courseCompletionCertificate?: Resolver<
    Maybe<ResolversTypes['CourseCertificate']>,
    ParentType,
    ContextType
  >
  courseCompletionSnapshot?: Resolver<
    Maybe<ResolversTypes['CourseCompletionSnapshot']>,
    ParentType,
    ContextType
  >
  courseEnrollmentSnapshot?: Resolver<
    Maybe<ResolversTypes['CourseEnrollmentSnapshot']>,
    ParentType,
    ContextType
  >
  courseId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  courseSession?: Resolver<ResolversTypes['CourseSession'], ParentType, ContextType>
  courseSessionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  hasClickedOnSurveyLink?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  hasDismissedPostCourseCallout?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isEnrolled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  paymentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shadow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  stripeInvoiceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCourseActivityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCourseActivity'] = ResolversParentTypes['UserCourseActivity']
> = {
  cclCourse?: Resolver<ResolversTypes['CclCourse'], ParentType, ContextType>
  latestActivityAt?: Resolver<
    Maybe<ResolversTypes['ISO8601DateTime']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCoursesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserCourses'] = ResolversParentTypes['UserCourses']
> = {
  all?: Resolver<Maybe<Array<ResolversTypes['UserCourse']>>, ParentType, ContextType>
  current?: Resolver<Maybe<ResolversTypes['UserCourse']>, ParentType, ContextType>
  last?: Resolver<Maybe<ResolversTypes['UserCourse']>, ParentType, ContextType>
  previous?: Resolver<Maybe<Array<ResolversTypes['UserCourse']>>, ParentType, ContextType>
  upcoming?: Resolver<Maybe<Array<ResolversTypes['UserCourse']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserEmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEmail'] = ResolversParentTypes['UserEmail']
> = {
  confirmedByUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPrimaryEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserEventsPastFeedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserEventsPastFeed'] = ResolversParentTypes['UserEventsPastFeed']
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  events?: Resolver<Maybe<Array<ResolversTypes['Event']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserIsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserIs'] = ResolversParentTypes['UserIs']
> = {
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  accountCreated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  admin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  alumni?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  badgeholder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  banned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  collective?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  copyHold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  creator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  eligibleForTrial?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  enrolledInActiveCourse?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  enrolledInEnrollmentSeason?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >
  enrolledInLiveProgram?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  expiredPlanManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  freeUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  inPostCoursePeriod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  member?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  noAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  paidMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  planManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  premember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  provisionedByScim?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  staff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  subscriptionOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  teamSubscriptionOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  trialing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMentionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserMention'] = ResolversParentTypes['UserMention']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserMergeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMerge'] = ResolversParentTypes['UserMerge']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  mergedPrimaryEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserNotificationPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserNotificationPreferences'] = ResolversParentTypes['UserNotificationPreferences']
> = {
  commentsActivity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  digestEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  mentionEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postsActivity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  repliesActivity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOauthProviderResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserOauthProvider'] = ResolversParentTypes['UserOauthProvider']
> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPatronageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserPatronage'] = ResolversParentTypes['UserPatronage']
> = {
  stripePaymentLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userFullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userHasActiveSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  userIsEligibleForPatronage?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPreferenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserPreference'] = ResolversParentTypes['UserPreference']
> = {
  skipFeedback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserPricingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserPricing'] = ResolversParentTypes['UserPricing']
> = {
  term?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']
> = {
  aboutMeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allowDirectMessages?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  areasOfExpertiseTags?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  askMeAboutText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  avatarUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    Partial<UserProfileAvatarUrlArgs>
  >
  businessModelTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  communityInterestTags?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  communityInterestText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  companyAudienceTags?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyProductText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  completionPercentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guestId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasBadge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  learningGoalsText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  networkProfile?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>
  onboardingStep?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  previousCompanies?: Resolver<
    Maybe<Array<ResolversTypes['UserCompany']>>,
    ParentType,
    ContextType
  >
  profilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  profileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  responseTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sanitySlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserProfileFieldsInterfaceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserProfileFieldsInterface'] = ResolversParentTypes['UserProfileFieldsInterface']
> = {
  __resolveType: TypeResolveFn<'UserProfile', ParentType, ContextType>
  avatarUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    Partial<UserProfileFieldsInterfaceAvatarUrlArgs>
  >
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasBadge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  kind?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  linkedin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  profileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type UserProgramResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserProgram'] = ResolversParentTypes['UserProgram']
> = {
  cmsProgram?: Resolver<ResolversTypes['CmsProgram'], ParentType, ContextType>
  enrolled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  participatingKinds?: Resolver<
    Array<ResolversTypes['ProgramParticipatingKinds']>,
    ParentType,
    ContextType
  >
  progress?: Resolver<
    Maybe<ResolversTypes['ProgramProgressScalar']>,
    ParentType,
    ContextType
  >
  progressPercent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserSubscriptionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserSubscriptions'] = ResolversParentTypes['UserSubscriptions']
> = {
  active?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  last?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  lastExpired?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  trial?: Resolver<Maybe<ResolversTypes['Subscription']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersFeedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersFeed'] = ResolversParentTypes['UsersFeed']
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  users?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ValidateEmailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ValidateEmail'] = ResolversParentTypes['ValidateEmail']
> = {
  suggestion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VerifyEmailPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['VerifyEmailPayload'] = ResolversParentTypes['VerifyEmailPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isPrimaryEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ViewBookmarkFolderPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ViewBookmarkFolderPayload'] = ResolversParentTypes['ViewBookmarkFolderPayload']
> = {
  activity?: Resolver<
    Maybe<ResolversTypes['CollectionActivity']>,
    ParentType,
    ContextType
  >
  bookmarkFolder?: Resolver<
    Maybe<ResolversTypes['BookmarkFolder']>,
    ParentType,
    ContextType
  >
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  isOnboardingCollection?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ViewedArtifactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ViewedArtifact'] = ResolversParentTypes['ViewedArtifact']
> = {
  artifactId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['ISO8601DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  originalNetworkId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['ISO8601DateTime']>, ParentType, ContextType>
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WhatWeCanDoBetterPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WhatWeCanDoBetterPayload'] = ResolversParentTypes['WhatWeCanDoBetterPayload']
> = {
  clientMutationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errors?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WorkEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkEntry'] = ResolversParentTypes['WorkEntry']
> = {
  artifactContributions?: Resolver<
    Maybe<Array<ResolversTypes['ArtifactContribution']>>,
    ParentType,
    ContextType
  >
  company?: Resolver<Maybe<ResolversTypes['SanityCompany']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  generalRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  generalTimeframe?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  specificCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  specificTimeframe?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  ActivateSubscriptionPayload?: ActivateSubscriptionPayloadResolvers<ContextType>
  Activity?: ActivityResolvers<ContextType>
  ActivityAction?: ActivityActionResolvers<ContextType>
  ActivityList?: ActivityListResolvers<ContextType>
  ActivityListItem?: ActivityListItemResolvers<ContextType>
  ActivityNoun?: ActivityNounResolvers<ContextType>
  ActivitySubject?: ActivitySubjectResolvers<ContextType>
  AddAnnotationReactionPayload?: AddAnnotationReactionPayloadResolvers<ContextType>
  AddArtifactCommentPayload?: AddArtifactCommentPayloadResolvers<ContextType>
  AddArtifactCommentReactionPayload?: AddArtifactCommentReactionPayloadResolvers<ContextType>
  AddEmailToUserPayload?: AddEmailToUserPayloadResolvers<ContextType>
  AddReactionPayload?: AddReactionPayloadResolvers<ContextType>
  AddUserCompanyPayload?: AddUserCompanyPayloadResolvers<ContextType>
  AiChat?: AiChatResolvers<ContextType>
  AiChatConnection?: AiChatConnectionResolvers<ContextType>
  AiChatEdge?: AiChatEdgeResolvers<ContextType>
  AiChatMessage?: AiChatMessageResolvers<ContextType>
  AiExtensionApp?: AiExtensionAppResolvers<ContextType>
  AiPersonalization?: AiPersonalizationResolvers<ContextType>
  ApplicablePrograms?: ApplicableProgramsResolvers<ContextType>
  ApproveSubscriptionJoinRequestPayload?: ApproveSubscriptionJoinRequestPayloadResolvers<ContextType>
  Artifact?: ArtifactResolvers<ContextType>
  ArtifactAnnotation?: ArtifactAnnotationResolvers<ContextType>
  ArtifactAnnotationReaction?: ArtifactAnnotationReactionResolvers<ContextType>
  ArtifactAnnotationReactionGrouped?: ArtifactAnnotationReactionGroupedResolvers<ContextType>
  ArtifactAnnotationVideo?: ArtifactAnnotationVideoResolvers<ContextType>
  ArtifactAuthor?: ArtifactAuthorResolvers<ContextType>
  ArtifactComment?: ArtifactCommentResolvers<ContextType>
  ArtifactCommentReaction?: ArtifactCommentReactionResolvers<ContextType>
  ArtifactCommentReactionGrouped?: ArtifactCommentReactionGroupedResolvers<ContextType>
  ArtifactCommentUser?: ArtifactCommentUserResolvers<ContextType>
  ArtifactContribution?: ArtifactContributionResolvers<ContextType>
  ArtifactContributor?: ArtifactContributorResolvers<ContextType>
  ArtifactFilter?: ArtifactFilterResolvers<ContextType>
  ArtifactSubtopic?: ArtifactSubtopicResolvers<ContextType>
  ArtifactTopic?: ArtifactTopicResolvers<ContextType>
  ArtifactTopicFilter?: ArtifactTopicFilterResolvers<ContextType>
  ArtifactUserData?: ArtifactUserDataResolvers<ContextType>
  ArtifactWithWorkEntry?: ArtifactWithWorkEntryResolvers<ContextType>
  ArtifactsForSubtopic?: ArtifactsForSubtopicResolvers<ContextType>
  AssignCohortCreditPayload?: AssignCohortCreditPayloadResolvers<ContextType>
  AssignSeatPayload?: AssignSeatPayloadResolvers<ContextType>
  AttendeesFeed?: AttendeesFeedResolvers<ContextType>
  BatchPayload?: BatchPayloadResolvers<ContextType>
  BatchRsvpTimeData?: BatchRsvpTimeDataResolvers<ContextType>
  BillingInformation?: BillingInformationResolvers<ContextType>
  BlogIndex?: BlogIndexResolvers<ContextType>
  BlogPost?: BlogPostResolvers<ContextType>
  BlogPostCategory?: BlogPostCategoryResolvers<ContextType>
  BlogPostDetails?: BlogPostDetailsResolvers<ContextType>
  Bookmark?: BookmarkResolvers<ContextType>
  BookmarkFolder?: BookmarkFolderResolvers<ContextType>
  BookmarksFeed?: BookmarksFeedResolvers<ContextType>
  CancelPayload?: CancelPayloadResolvers<ContextType>
  CaseStudiesIndex?: CaseStudiesIndexResolvers<ContextType>
  CaseStudy?: CaseStudyResolvers<ContextType>
  CaseStudyContentBlock?: CaseStudyContentBlockResolvers<ContextType>
  CaseStudyContributor?: CaseStudyContributorResolvers<ContextType>
  CaseStudyDetails?: CaseStudyDetailsResolvers<ContextType>
  CclArtifact?: CclArtifactResolvers<ContextType>
  CclBlogPost?: CclBlogPostResolvers<ContextType>
  CclCompany?: CclCompanyResolvers<ContextType>
  CclCourse?: CclCourseResolvers<ContextType>
  CclCourseBlock?: CclCourseBlockResolvers<ContextType>
  CclCourseSession?: CclCourseSessionResolvers<ContextType>
  CclExpert?: CclExpertResolvers<ContextType>
  CclGuide?: CclGuideResolvers<ContextType>
  CclReview?: CclReviewResolvers<ContextType>
  CclSessionEvent?: CclSessionEventResolvers<ContextType>
  CclTopic?: CclTopicResolvers<ContextType>
  CclVideo?: CclVideoResolvers<ContextType>
  Certificates?: CertificatesResolvers<ContextType>
  ChatQuery?: ChatQueryResolvers<ContextType>
  CheckoutPayload?: CheckoutPayloadResolvers<ContextType>
  CheckoutProrationAdjustment?: CheckoutProrationAdjustmentResolvers<ContextType>
  ChoosePlanPayload?: ChoosePlanPayloadResolvers<ContextType>
  ClearbitCompany?: ClearbitCompanyResolvers<ContextType>
  Clip?: ClipResolvers<ContextType>
  CmsContent?: CmsContentResolvers<ContextType>
  CmsModule?: CmsModuleResolvers<ContextType>
  CmsProgram?: CmsProgramResolvers<ContextType>
  CmsProgramExpertsPayload?: CmsProgramExpertsPayloadResolvers<ContextType>
  CmsProgramNotification?: CmsProgramNotificationResolvers<ContextType>
  CmsSection?: CmsSectionResolvers<ContextType>
  CmsSectionTag?: CmsSectionTagResolvers<ContextType>
  CoachingSession?: CoachingSessionResolvers<ContextType>
  Cohort?: CohortResolvers<ContextType>
  CohortActivityListItem?: CohortActivityListItemResolvers<ContextType>
  CohortActivityListWithMetadata?: CohortActivityListWithMetadataResolvers<ContextType>
  CohortBookmarkFolder?: CohortBookmarkFolderResolvers<ContextType>
  CohortCertificate?: CohortCertificateResolvers<ContextType>
  CohortCredit?: CohortCreditResolvers<ContextType>
  CohortCredits?: CohortCreditsResolvers<ContextType>
  CohortEventTimes?: CohortEventTimesResolvers<ContextType>
  CohortPassPriceTier?: CohortPassPriceTierResolvers<ContextType>
  CohortPost?: CohortPostResolvers<ContextType>
  CohortTeam?: CohortTeamResolvers<ContextType>
  Collection?: CollectionResolvers<ContextType>
  CollectionActivity?: CollectionActivityResolvers<ContextType>
  CollectionActivityList?: CollectionActivityListResolvers<ContextType>
  CollectionsQuery?: CollectionsQueryResolvers<ContextType>
  Comment?: CommentResolvers<ContextType>
  Company?: CompanyResolvers<ContextType>
  CompleteProductTourPayload?: CompleteProductTourPayloadResolvers<ContextType>
  CompletedFeedback?: CompletedFeedbackResolvers<ContextType>
  CompletionSnapshot?: CompletionSnapshotResolvers<ContextType>
  ConfirmUserMergePayload?: ConfirmUserMergePayloadResolvers<ContextType>
  Content?: ContentResolvers<ContextType>
  ContentBlock?: ContentBlockResolvers<ContextType>
  ContentBlockCollapsible?: ContentBlockCollapsibleResolvers<ContextType>
  ContentFeed?: ContentFeedResolvers<ContextType>
  ContentTocItem?: ContentTocItemResolvers<ContextType>
  ContentViewer?: ContentViewerResolvers<ContextType>
  CookieConsent?: CookieConsentResolvers<ContextType>
  Course?: CourseResolvers<ContextType>
  CourseBenefit?: CourseBenefitResolvers<ContextType>
  CourseBlock?: CourseBlockResolvers<ContextType>
  CourseCertificate?: CourseCertificateResolvers<ContextType>
  CourseCompletionSnapshot?: CourseCompletionSnapshotResolvers<ContextType>
  CourseConcepts?: CourseConceptsResolvers<ContextType>
  CourseDetails?: CourseDetailsResolvers<ContextType>
  CourseEnrollmentConfirmedPayload?: CourseEnrollmentConfirmedPayloadResolvers<ContextType>
  CourseEnrollmentSnapshot?: CourseEnrollmentSnapshotResolvers<ContextType>
  CourseEnrollmentStartedPayload?: CourseEnrollmentStartedPayloadResolvers<ContextType>
  CourseEvent?: CourseEventResolvers<ContextType>
  CourseForTopicAndFunction?: CourseForTopicAndFunctionResolvers<ContextType>
  CourseIndex?: CourseIndexResolvers<ContextType>
  CourseIndexContent?: CourseIndexContentResolvers<ContextType>
  CourseIndexPage?: CourseIndexPageResolvers<ContextType>
  CourseInfoCard?: CourseInfoCardResolvers<ContextType>
  CoursePreview?: CoursePreviewResolvers<ContextType>
  CourseSession?: CourseSessionResolvers<ContextType>
  CourseSessionDetail?: CourseSessionDetailResolvers<ContextType>
  CourseSnapshot?: CourseSnapshotResolvers<ContextType>
  CourseTopicCovered?: CourseTopicCoveredResolvers<ContextType>
  CreateAccountPayload?: CreateAccountPayloadResolvers<ContextType>
  CreateBookmarkFolderPayload?: CreateBookmarkFolderPayloadResolvers<ContextType>
  CreateBookmarkPayload?: CreateBookmarkPayloadResolvers<ContextType>
  CreateCohortPostPayload?: CreateCohortPostPayloadResolvers<ContextType>
  CreateCohortReplyPayload?: CreateCohortReplyPayloadResolvers<ContextType>
  CreateCourseEnrollmentPayload?: CreateCourseEnrollmentPayloadResolvers<ContextType>
  CreateCourseSessionPayload?: CreateCourseSessionPayloadResolvers<ContextType>
  CreateCourseSessionSlackChannelPayload?: CreateCourseSessionSlackChannelPayloadResolvers<ContextType>
  CreateDraftSubscriptionJoinRequestPayload?: CreateDraftSubscriptionJoinRequestPayloadResolvers<ContextType>
  CreateFiledBookmarkPayload?: CreateFiledBookmarkPayloadResolvers<ContextType>
  CreatePayload?: CreatePayloadResolvers<ContextType>
  CreateProgramApplicationPayload?: CreateProgramApplicationPayloadResolvers<ContextType>
  CreateProgramInvitationPayload?: CreateProgramInvitationPayloadResolvers<ContextType>
  CreateRailsSubscriptionPayload?: CreateRailsSubscriptionPayloadResolvers<ContextType>
  CreateRecommendationsPayload?: CreateRecommendationsPayloadResolvers<ContextType>
  CreateReplyPayload?: CreateReplyPayloadResolvers<ContextType>
  CreateSubscriptionJoinRequestPayload?: CreateSubscriptionJoinRequestPayloadResolvers<ContextType>
  CreateTeamPostPayload?: CreateTeamPostPayloadResolvers<ContextType>
  CreateTestUsersPayload?: CreateTestUsersPayloadResolvers<ContextType>
  CreateUserClipActivityPayload?: CreateUserClipActivityPayloadResolvers<ContextType>
  CreateUserCmsActivityPayload?: CreateUserCmsActivityPayloadResolvers<ContextType>
  CreateUserEventActivityPayload?: CreateUserEventActivityPayloadResolvers<ContextType>
  Creator?: CreatorResolvers<ContextType>
  Cta?: CtaResolvers<ContextType>
  DeclineSubscriptionJoinRequestPayload?: DeclineSubscriptionJoinRequestPayloadResolvers<ContextType>
  DeleteBookmarkFolderPayload?: DeleteBookmarkFolderPayloadResolvers<ContextType>
  DeleteBookmarkPayload?: DeleteBookmarkPayloadResolvers<ContextType>
  DeleteCohortPassPayload?: DeleteCohortPassPayloadResolvers<ContextType>
  DeleteCohortPostPayload?: DeleteCohortPostPayloadResolvers<ContextType>
  DeleteCohortReplyPayload?: DeleteCohortReplyPayloadResolvers<ContextType>
  DeleteCustomerTaxIdPayload?: DeleteCustomerTaxIdPayloadResolvers<ContextType>
  DeleteEmailPayload?: DeleteEmailPayloadResolvers<ContextType>
  DeleteFiledBookmarkPayload?: DeleteFiledBookmarkPayloadResolvers<ContextType>
  DeletePaymentSourcePayload?: DeletePaymentSourcePayloadResolvers<ContextType>
  DeleteTeamPostPayload?: DeleteTeamPostPayloadResolvers<ContextType>
  DeleteUserCompanyPayload?: DeleteUserCompanyPayloadResolvers<ContextType>
  DuplicateAccount?: DuplicateAccountResolvers<ContextType>
  EndSubscriptionTrialEarlyPayload?: EndSubscriptionTrialEarlyPayloadResolvers<ContextType>
  EngagementReportCourse?: EngagementReportCourseResolvers<ContextType>
  EngagementReportLesson?: EngagementReportLessonResolvers<ContextType>
  EngagementReportParticipant?: EngagementReportParticipantResolvers<ContextType>
  EngagementReportSavedItem?: EngagementReportSavedItemResolvers<ContextType>
  EngagementReportSummary?: EngagementReportSummaryResolvers<ContextType>
  EngagementReportTeam?: EngagementReportTeamResolvers<ContextType>
  EngagementReportTeamMember?: EngagementReportTeamMemberResolvers<ContextType>
  Event?: EventResolvers<ContextType>
  EventAttendee?: EventAttendeeResolvers<ContextType>
  EventAttendeeConnection?: EventAttendeeConnectionResolvers<ContextType>
  EventAttendeeEdge?: EventAttendeeEdgeResolvers<ContextType>
  EventPerCourseSession?: EventPerCourseSessionResolvers<ContextType>
  EventPrework?: EventPreworkResolvers<ContextType>
  EventPreworkSubmission?: EventPreworkSubmissionResolvers<ContextType>
  EventRelatedResources?: EventRelatedResourcesResolvers<ContextType>
  EventSpeaker?: EventSpeakerResolvers<ContextType>
  EventsPastFeed?: EventsPastFeedResolvers<ContextType>
  ExpertUserProfile?: ExpertUserProfileResolvers<ContextType>
  ExpertsIndex?: ExpertsIndexResolvers<ContextType>
  Faq?: FaqResolvers<ContextType>
  FeaturedPost?: FeaturedPostResolvers<ContextType>
  FeedbacksPayload?: FeedbacksPayloadResolvers<ContextType>
  FiledBookmark?: FiledBookmarkResolvers<ContextType>
  FilteredArtifacts?: FilteredArtifactsResolvers<ContextType>
  FilteredUserSearch?: FilteredUserSearchResolvers<ContextType>
  FilteredUserSearchList?: FilteredUserSearchListResolvers<ContextType>
  FlagArtifactCommentPayload?: FlagArtifactCommentPayloadResolvers<ContextType>
  ForkBookmarkFolderPayload?: ForkBookmarkFolderPayloadResolvers<ContextType>
  ForumContribution?: ForumContributionResolvers<ContextType>
  Function?: FunctionResolvers<ContextType>
  GenerateAiGoalsPayload?: GenerateAiGoalsPayloadResolvers<ContextType>
  GenerateAiInferencePayload?: GenerateAiInferencePayloadResolvers<ContextType>
  GettingStartedBlock?: GettingStartedBlockResolvers<ContextType>
  GettingStartedCollapsible?: GettingStartedCollapsibleResolvers<ContextType>
  Group?: GroupResolvers<ContextType>
  GroupCategory?: GroupCategoryResolvers<ContextType>
  GroupConnection?: GroupConnectionResolvers<ContextType>
  GroupEdge?: GroupEdgeResolvers<ContextType>
  GroupFeed?: GroupFeedResolvers<ContextType>
  GroupMemberList?: GroupMemberListResolvers<ContextType>
  GroupPostFeed?: GroupPostFeedResolvers<ContextType>
  HomeDashboard?: HomeDashboardResolvers<ContextType>
  Homepage?: HomepageResolvers<ContextType>
  HomepageCheck?: HomepageCheckResolvers<ContextType>
  HomepageContent?: HomepageContentResolvers<ContextType>
  HomepageCourse?: HomepageCourseResolvers<ContextType>
  HomepageCourseHost?: HomepageCourseHostResolvers<ContextType>
  HomepageRecentlyViewedItem?: HomepageRecentlyViewedItemResolvers<ContextType>
  HomepageTeamsBenefit?: HomepageTeamsBenefitResolvers<ContextType>
  Host?: HostResolvers<ContextType>
  ISO8601DateTime?: GraphQLScalarType
  Image?: ImageResolvers<ContextType>
  Industry?: IndustryResolvers<ContextType>
  InlinePost?: InlinePostResolvers<ContextType>
  InlinePostReply?: InlinePostReplyResolvers<ContextType>
  Invoice?: InvoiceResolvers<ContextType>
  InvoiceMember?: InvoiceMemberResolvers<ContextType>
  JSON?: GraphQLScalarType
  JoinCourseWaitlistPayload?: JoinCourseWaitlistPayloadResolvers<ContextType>
  JoinPublicCourseWaitlistPayload?: JoinPublicCourseWaitlistPayloadResolvers<ContextType>
  LessonViewer?: LessonViewerResolvers<ContextType>
  MakeEmailPrimaryPayload?: MakeEmailPrimaryPayloadResolvers<ContextType>
  MarkActivitiesAsReadPayload?: MarkActivitiesAsReadPayloadResolvers<ContextType>
  MarkAllActivitiesAsReadPayload?: MarkAllActivitiesAsReadPayloadResolvers<ContextType>
  MarkArtifactAsViewedPayload?: MarkArtifactAsViewedPayloadResolvers<ContextType>
  MarketingCourse?: MarketingCourseResolvers<ContextType>
  MarketingFaq?: MarketingFaqResolvers<ContextType>
  ModuleCompletionStatus?: ModuleCompletionStatusResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  MuxVideo?: MuxVideoResolvers<ContextType>
  NotAttendingPayload?: NotAttendingPayloadResolvers<ContextType>
  OnboardingInfo?: OnboardingInfoResolvers<ContextType>
  OnboardingQuestionnaire?: OnboardingQuestionnaireResolvers<ContextType>
  OnboardingTrackingPayload?: OnboardingTrackingPayloadResolvers<ContextType>
  Org?: OrgResolvers<ContextType>
  OrgAdmin?: OrgAdminResolvers<ContextType>
  Page?: PageResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  PageSeo?: PageSeoResolvers<ContextType>
  Pagination?: PaginationResolvers<ContextType>
  PastPayment?: PastPaymentResolvers<ContextType>
  PaymentRecord?: PaymentRecordResolvers<ContextType>
  PersonalizationQuery?: PersonalizationQueryResolvers<ContextType>
  PlanForSale?: PlanForSaleResolvers<ContextType>
  Podcast?: PodcastResolvers<ContextType>
  PodcastCta?: PodcastCtaResolvers<ContextType>
  PodcastDetails?: PodcastDetailsResolvers<ContextType>
  PodcastHost?: PodcastHostResolvers<ContextType>
  PodcastIndex?: PodcastIndexResolvers<ContextType>
  PodcastIndexContent?: PodcastIndexContentResolvers<ContextType>
  PodcastIndexPage?: PodcastIndexPageResolvers<ContextType>
  PodcastPlatform?: PodcastPlatformResolvers<ContextType>
  PopularEventsPastFeed?: PopularEventsPastFeedResolvers<ContextType>
  Post?: PostResolvers<ContextType>
  PostContributor?: PostContributorResolvers<ContextType>
  PostList?: PostListResolvers<ContextType>
  PostTopic?: PostTopicResolvers<ContextType>
  PostsSearch?: PostsSearchResolvers<ContextType>
  PostsSearchTopic?: PostsSearchTopicResolvers<ContextType>
  PricingPage?: PricingPageResolvers<ContextType>
  PricingPageContent?: PricingPageContentResolvers<ContextType>
  PricingPlanFeature?: PricingPlanFeatureResolvers<ContextType>
  PricingPlanFeatureCategory?: PricingPlanFeatureCategoryResolvers<ContextType>
  PricingPlanFeatureValue?: PricingPlanFeatureValueResolvers<ContextType>
  ProductTour?: ProductTourResolvers<ContextType>
  Profile?: ProfileResolvers<ContextType>
  ProgramProgress?: ProgramProgressResolvers<ContextType>
  ProgramProgressScalar?: GraphQLScalarType
  ProjectDeliverable?: ProjectDeliverableResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  Reaction?: ReactionResolvers<ContextType>
  RecentlyViewedContent?: RecentlyViewedContentResolvers<ContextType>
  RecommendedContent?: RecommendedContentResolvers<ContextType>
  RecommendedContentCursor?: RecommendedContentCursorResolvers<ContextType>
  RecommendedContentResults?: RecommendedContentResultsResolvers<ContextType>
  RecommendedItem?: RecommendedItemResolvers<ContextType>
  ReforgeComparison?: ReforgeComparisonResolvers<ContextType>
  ReforgeUsage?: ReforgeUsageResolvers<ContextType>
  RegenerateCourseSnapshotPayload?: RegenerateCourseSnapshotPayloadResolvers<ContextType>
  ReinstateSeatPayload?: ReinstateSeatPayloadResolvers<ContextType>
  RemoveAiChatPayload?: RemoveAiChatPayloadResolvers<ContextType>
  RemoveAnnotationReactionPayload?: RemoveAnnotationReactionPayloadResolvers<ContextType>
  RemoveArtifactCommentPayload?: RemoveArtifactCommentPayloadResolvers<ContextType>
  RemoveArtifactCommentReactionPayload?: RemoveArtifactCommentReactionPayloadResolvers<ContextType>
  RemoveReactionPayload?: RemoveReactionPayloadResolvers<ContextType>
  RemoveReplyPayload?: RemoveReplyPayloadResolvers<ContextType>
  RemoveSeatPayload?: RemoveSeatPayloadResolvers<ContextType>
  RemoveSubscriptionFromOrgPayload?: RemoveSubscriptionFromOrgPayloadResolvers<ContextType>
  RemoveSubscriptionMemberPayload?: RemoveSubscriptionMemberPayloadResolvers<ContextType>
  RenameAiChatPayload?: RenameAiChatPayloadResolvers<ContextType>
  ReorderFiledBookmarkPayload?: ReorderFiledBookmarkPayloadResolvers<ContextType>
  Reply?: ReplyResolvers<ContextType>
  ReportCohortPostOrReplyPayload?: ReportCohortPostOrReplyPayloadResolvers<ContextType>
  RestoreCollectionActivityPayload?: RestoreCollectionActivityPayloadResolvers<ContextType>
  Review?: ReviewResolvers<ContextType>
  SanityCompany?: SanityCompanyResolvers<ContextType>
  SanityCourse?: SanityCourseResolvers<ContextType>
  SanityHost?: SanityHostResolvers<ContextType>
  SanityTestimonial?: SanityTestimonialResolvers<ContextType>
  SaveCustomerTaxIdPayload?: SaveCustomerTaxIdPayloadResolvers<ContextType>
  SavedCountPerArtifact?: SavedCountPerArtifactResolvers<ContextType>
  ScheduledWeek?: ScheduledWeekResolvers<ContextType>
  SearchDocument?: SearchDocumentResolvers<ContextType>
  SearchDocumentList?: SearchDocumentListResolvers<ContextType>
  SearchDocumentTopResult?: SearchDocumentTopResultResolvers<ContextType>
  SearchDocumentTopResultCategory?: SearchDocumentTopResultCategoryResolvers<ContextType>
  Searchable?: SearchableResolvers<ContextType>
  Season?: SeasonResolvers<ContextType>
  Seat?: SeatResolvers<ContextType>
  SelectFeaturedPostsPayload?: SelectFeaturedPostsPayloadResolvers<ContextType>
  SendEmailVerifyPayload?: SendEmailVerifyPayloadResolvers<ContextType>
  SendMergeRequestEmailPayload?: SendMergeRequestEmailPayloadResolvers<ContextType>
  SetDismissedUpdateCompanyBannerAtPayload?: SetDismissedUpdateCompanyBannerAtPayloadResolvers<ContextType>
  SetHasSeenDemographicsModalPayload?: SetHasSeenDemographicsModalPayloadResolvers<ContextType>
  SetUserCohortPreferencesPayload?: SetUserCohortPreferencesPayloadResolvers<ContextType>
  ShareBookmarkFolderPayload?: ShareBookmarkFolderPayloadResolvers<ContextType>
  SharedFolder?: SharedFolderResolvers<ContextType>
  SimilarPost?: SimilarPostResolvers<ContextType>
  SimilaritySearchBreadcrumb?: SimilaritySearchBreadcrumbResolvers<ContextType>
  SimilaritySearchContent?: SimilaritySearchContentResolvers<ContextType>
  SimilaritySearchResponse?: SimilaritySearchResponseResolvers<ContextType>
  SiteSeo?: SiteSeoResolvers<ContextType>
  SiteSettings?: SiteSettingsResolvers<ContextType>
  SlackAccount?: SlackAccountResolvers<ContextType>
  Snapshot?: SnapshotResolvers<ContextType>
  SnapshotLandingPage?: SnapshotLandingPageResolvers<ContextType>
  SsoAuthorization?: SsoAuthorizationResolvers<ContextType>
  SsoFeatureSet?: SsoFeatureSetResolvers<ContextType>
  SsoFeatureStatus?: SsoFeatureStatusResolvers<ContextType>
  SsoQuery?: SsoQueryResolvers<ContextType>
  StripeAchCreditTransfer?: StripeAchCreditTransferResolvers<ContextType>
  StripeAddress?: StripeAddressResolvers<ContextType>
  StripeBankAccount?: StripeBankAccountResolvers<ContextType>
  StripeCustomer?: StripeCustomerResolvers<ContextType>
  StripeCustomerTaxId?: StripeCustomerTaxIdResolvers<ContextType>
  StripeInvoice?: StripeInvoiceResolvers<ContextType>
  StripeInvoiceLineItem?: StripeInvoiceLineItemResolvers<ContextType>
  StripePaymentMethod?: StripePaymentMethodResolvers<ContextType>
  StripePaymentMethodCard?: StripePaymentMethodCardResolvers<ContextType>
  StripePaymentSources?: StripePaymentSourcesResolvers<ContextType>
  StripeSetupIntentPayload?: StripeSetupIntentPayloadResolvers<ContextType>
  StripeSource?: StripeSourceResolvers<ContextType>
  StripeSubscription?: StripeSubscriptionResolvers<ContextType>
  StripeSubscriptionPlan?: StripeSubscriptionPlanResolvers<ContextType>
  StripeTaxAmount?: StripeTaxAmountResolvers<ContextType>
  StripeTaxRate?: StripeTaxRateResolvers<ContextType>
  StripeUpcomingInvoice?: StripeUpcomingInvoiceResolvers<ContextType>
  SubmitOnboardingQuestionnairePayload?: SubmitOnboardingQuestionnairePayloadResolvers<ContextType>
  SubmitPostApplySurveyPayload?: SubmitPostApplySurveyPayloadResolvers<ContextType>
  Subscription?: SubscriptionResolvers<ContextType>
  SubscriptionCancelMotivationPayload?: SubscriptionCancelMotivationPayloadResolvers<ContextType>
  SubscriptionCancelPayload?: SubscriptionCancelPayloadResolvers<ContextType>
  SubscriptionJoinRequest?: SubscriptionJoinRequestResolvers<ContextType>
  SubscriptionMember?: SubscriptionMemberResolvers<ContextType>
  SubscriptionSeatsChangePreviewPayload?: SubscriptionSeatsChangePreviewPayloadResolvers<ContextType>
  SupplyCourseSession?: SupplyCourseSessionResolvers<ContextType>
  SwimlanesQuery?: SwimlanesQueryResolvers<ContextType>
  Tag?: TagResolvers<ContextType>
  TagGroup?: TagGroupResolvers<ContextType>
  TaxIdReverseChargeStatus?: TaxIdReverseChargeStatusResolvers<ContextType>
  TaxInfoItem?: TaxInfoItemResolvers<ContextType>
  TaxInfoItemDetails?: TaxInfoItemDetailsResolvers<ContextType>
  TeamMemberPreview?: TeamMemberPreviewResolvers<ContextType>
  TeamMemberProfilePreview?: TeamMemberProfilePreviewResolvers<ContextType>
  TeamMembersSearch?: TeamMembersSearchResolvers<ContextType>
  TeamPost?: TeamPostResolvers<ContextType>
  TeamPreview?: TeamPreviewResolvers<ContextType>
  TentativeUpcomingCohort?: TentativeUpcomingCohortResolvers<ContextType>
  Testimonial?: TestimonialResolvers<ContextType>
  TestimonialWithImage?: TestimonialWithImageResolvers<ContextType>
  ToggleManualAssignSubscriptionPayload?: ToggleManualAssignSubscriptionPayloadResolvers<ContextType>
  TogglePostFollowPayload?: TogglePostFollowPayloadResolvers<ContextType>
  Topic?: TopicResolvers<ContextType>
  TopicActivity?: TopicActivityResolvers<ContextType>
  TrackServerEventPayload?: TrackServerEventPayloadResolvers<ContextType>
  UnassignCohortCreditPayload?: UnassignCohortCreditPayloadResolvers<ContextType>
  UnassignSeatPayload?: UnassignSeatPayloadResolvers<ContextType>
  UnifiedCourse?: UnifiedCourseResolvers<ContextType>
  Unit?: UnitResolvers<ContextType>
  UnreadElement?: UnreadElementResolvers<ContextType>
  UnshareBookmarkFolderPayload?: UnshareBookmarkFolderPayloadResolvers<ContextType>
  UpdateAiPersonalizationPayload?: UpdateAiPersonalizationPayloadResolvers<ContextType>
  UpdateArtifactCommentPayload?: UpdateArtifactCommentPayloadResolvers<ContextType>
  UpdateBookmarkFolderPayload?: UpdateBookmarkFolderPayloadResolvers<ContextType>
  UpdateBookmarkPayload?: UpdateBookmarkPayloadResolvers<ContextType>
  UpdateCclPayload?: UpdateCclPayloadResolvers<ContextType>
  UpdateCmsSectionTagsPayload?: UpdateCmsSectionTagsPayloadResolvers<ContextType>
  UpdateCoachingSessionPayload?: UpdateCoachingSessionPayloadResolvers<ContextType>
  UpdateCohortPostPayload?: UpdateCohortPostPayloadResolvers<ContextType>
  UpdateCohortReplyPayload?: UpdateCohortReplyPayloadResolvers<ContextType>
  UpdateCourseEnrollmentPayload?: UpdateCourseEnrollmentPayloadResolvers<ContextType>
  UpdateCourseSessionPayload?: UpdateCourseSessionPayloadResolvers<ContextType>
  UpdateCustomerContactPayload?: UpdateCustomerContactPayloadResolvers<ContextType>
  UpdateHomepageCheckPayload?: UpdateHomepageCheckPayloadResolvers<ContextType>
  UpdateInvoiceUserFieldsPayload?: UpdateInvoiceUserFieldsPayloadResolvers<ContextType>
  UpdatePaymentMethodPayload?: UpdatePaymentMethodPayloadResolvers<ContextType>
  UpdatePreferencesPayload?: UpdatePreferencesPayloadResolvers<ContextType>
  UpdateProjectDeliverablePayload?: UpdateProjectDeliverablePayloadResolvers<ContextType>
  UpdateReplyPayload?: UpdateReplyPayloadResolvers<ContextType>
  UpdateTeamPayload?: UpdateTeamPayloadResolvers<ContextType>
  UpdateTeamPostPayload?: UpdateTeamPostPayloadResolvers<ContextType>
  UpdateTopicActivityPayload?: UpdateTopicActivityPayloadResolvers<ContextType>
  UpdateUserCohortPayload?: UpdateUserCohortPayloadResolvers<ContextType>
  UpdateUserCoursePayload?: UpdateUserCoursePayloadResolvers<ContextType>
  UpdateUserPayload?: UpdateUserPayloadResolvers<ContextType>
  UpgradeSubscriptionPayload?: UpgradeSubscriptionPayloadResolvers<ContextType>
  UqChoice?: UqChoiceResolvers<ContextType>
  UqQuestion?: UqQuestionResolvers<ContextType>
  UqQuestionnaire?: UqQuestionnaireResolvers<ContextType>
  UqUserAnswer?: UqUserAnswerResolvers<ContextType>
  UqUserAnswers?: UqUserAnswersResolvers<ContextType>
  UrlChangePayload?: UrlChangePayloadResolvers<ContextType>
  User?: UserResolvers<ContextType>
  UserCan?: UserCanResolvers<ContextType>
  UserCmsActivity?: UserCmsActivityResolvers<ContextType>
  UserCohort?: UserCohortResolvers<ContextType>
  UserCohorts?: UserCohortsResolvers<ContextType>
  UserCompany?: UserCompanyResolvers<ContextType>
  UserContact?: UserContactResolvers<ContextType>
  UserCourse?: UserCourseResolvers<ContextType>
  UserCourseActivity?: UserCourseActivityResolvers<ContextType>
  UserCourses?: UserCoursesResolvers<ContextType>
  UserEmail?: UserEmailResolvers<ContextType>
  UserEventsPastFeed?: UserEventsPastFeedResolvers<ContextType>
  UserIs?: UserIsResolvers<ContextType>
  UserMention?: UserMentionResolvers<ContextType>
  UserMerge?: UserMergeResolvers<ContextType>
  UserNotificationPreferences?: UserNotificationPreferencesResolvers<ContextType>
  UserOauthProvider?: UserOauthProviderResolvers<ContextType>
  UserPatronage?: UserPatronageResolvers<ContextType>
  UserPreference?: UserPreferenceResolvers<ContextType>
  UserPricing?: UserPricingResolvers<ContextType>
  UserProfile?: UserProfileResolvers<ContextType>
  UserProfileFieldsInterface?: UserProfileFieldsInterfaceResolvers<ContextType>
  UserProgram?: UserProgramResolvers<ContextType>
  UserSubscriptions?: UserSubscriptionsResolvers<ContextType>
  UsersFeed?: UsersFeedResolvers<ContextType>
  ValidateEmail?: ValidateEmailResolvers<ContextType>
  VerifyEmailPayload?: VerifyEmailPayloadResolvers<ContextType>
  ViewBookmarkFolderPayload?: ViewBookmarkFolderPayloadResolvers<ContextType>
  ViewedArtifact?: ViewedArtifactResolvers<ContextType>
  WhatWeCanDoBetterPayload?: WhatWeCanDoBetterPayloadResolvers<ContextType>
  WorkEntry?: WorkEntryResolvers<ContextType>
}

export const UserAvatarPartsFragmentDoc = gql`
  fragment UserAvatarParts on User {
    id
    fullName
    email
    profile {
      avatarUrl
    }
  }
`
export const ActivityFacepileCollectionActivityPartsFragmentDoc = gql`
  fragment ActivityFacepileCollectionActivityParts on CollectionActivity {
    id
    actionType
    createdAtInWords
    userId
    user {
      ...UserAvatarParts
    }
  }
  ${UserAvatarPartsFragmentDoc}
`
export const ActivityFacepileCmsActivityPartsFragmentDoc = gql`
  fragment ActivityFacepileCmsActivityParts on UserCmsActivity {
    id
    actionType
    createdAtInWords
    userId
    user {
      ...UserAvatarParts
    }
  }
  ${UserAvatarPartsFragmentDoc}
`
export const ActivityFacepileContentBookmarkPartsFragmentDoc = gql`
  fragment ActivityFacepileContentBookmarkParts on Bookmark {
    id
    created
    mostRecentCmsActivityPerUser(collectionId: $collectionId, userIds: $userIds) {
      ...ActivityFacepileCmsActivityParts
    }
  }
  ${ActivityFacepileCmsActivityPartsFragmentDoc}
`
export const PreviewLineItemFragmentDoc = gql`
  fragment PreviewLineItem on StripeInvoiceLineItem {
    productItemCode
  }
`
export const AiChatUserFragmentDoc = gql`
  fragment AiChatUser on User {
    id
    fullName
    email
  }
`
export const CourseEventAddToCalendarPartsFragmentDoc = gql`
  fragment CourseEventAddToCalendarParts on CourseEvent {
    title
    startsAt
    endsAt
    joinLink
    shortDescription
    sanityId
  }
`
export const CourseAddToCalendarPartsFragmentDoc = gql`
  fragment CourseAddToCalendarParts on Course {
    id
    title
  }
`
export const CourseSessionAddToCalendarPartsFragmentDoc = gql`
  fragment CourseSessionAddToCalendarParts on CourseSession {
    id
  }
`
export const CurrentUserUserCohortPartsFragmentDoc = gql`
  fragment CurrentUserUserCohortParts on UserCohort {
    id
    afterCohortCalloutDismissed
    canBeUpdated
    cohort {
      id
      slug
      postCoreWeeksEnrollmentIsOpen
      cmsProgram {
        id
        name
        code
        iconImageUrl
      }
      season {
        id
        showCohortViewerAt
      }
      group {
        id
      }
    }
  }
`
export const CohortsShowingNpsSurveySeasonFieldsFragmentDoc = gql`
  fragment CohortsShowingNpsSurveySeasonFields on Season {
    id
    name
    year
    npsSurveyLink
    npsSurveyText
  }
`
export const SubscriptionReactivationFieldsFragmentDoc = gql`
  fragment SubscriptionReactivationFields on Subscription {
    id
    daysLeft
    expiresAt
    isCanceled
    isPaidMonthly
    isTeam
    numSeats
    renewSeatCount
    userIsOwner
    hasUnlimitedCohortAccess
  }
`
export const SubscriptionTrialBannerFieldsFragmentDoc = gql`
  fragment SubscriptionTrialBannerFields on Subscription {
    id
    status
    daysLeftInTrial
  }
`
export const CurrentUserBannerPartsFragmentDoc = gql`
  fragment CurrentUserBannerParts on User {
    id
    accessPolicyKind
    shouldShowNpsSurvey
    liveProgramName
    cohorts {
      current {
        id
        ...CurrentUserUserCohortParts
      }
    }
    cohortsShowingNpsSurvey {
      id
      cohort {
        id
        cmsProgram {
          id
          name
        }
        season {
          id
          ...CohortsShowingNpsSurveySeasonFields
        }
      }
    }
    is {
      alumni
      member
      paidMember
      enrolledInLiveProgram
      enrolledInActiveCourse
      enrolledInEnrollmentSeason
      trialing
    }
    can {
      pay
      enrollInCohort
    }
    profile {
      timezone
    }
    shouldSeeTrialMessaging
    openInvoices {
      id
      status
    }
    paymentMethods {
      id
      type
      defaultPaymentMethod
      card {
        brand
        last4
        expYear
        expMonth
        status
      }
    }
    stripeCustomer {
      id
      balance
    }
    subscriptions {
      active {
        id
        daysLeft
        expiresAt
        startsAt
        isCanceled
        isDiscoverable
        isDunning
        isExpired
        isPaidMonthly
        isTeam
        lastFailedPaymentAt
        numSeats
        status
        stripeCollectionMethod
        stripeSubscription {
          cancelAtPeriodEnd
          id
        }
        stripeUpcomingInvoice {
          amountDue
        }
        stripeSubscriptionStatus
        userIsOwner
        ...SubscriptionReactivationFields
      }
      last {
        id
        isCanceled
        isExpired
        isTeam
        numSeats
        userIsOwner
      }
      trial {
        id
        ...SubscriptionTrialBannerFields
      }
    }
    dismissedUpdateCompanyBannerAt
    companyName
    companyNameUpdatedAt
    hasUpcomingCohort
  }
  ${CurrentUserUserCohortPartsFragmentDoc}
  ${CohortsShowingNpsSurveySeasonFieldsFragmentDoc}
  ${SubscriptionReactivationFieldsFragmentDoc}
  ${SubscriptionTrialBannerFieldsFragmentDoc}
`
export const BookmarkPartsFragmentDoc = gql`
  fragment BookmarkParts on Bookmark {
    id
    cmsProgram {
      id
      name
      collaborators {
        name
        id
        companyName
        description
        title
        thumbnailUrl
      }
    }
    cmsModule {
      id
      name
    }
    cmsSection {
      id
      name
      path
      contentType
    }
    anchor
    noteBody
    referenceImageUrl
    seconds
    type
    videoCode
    basedOn
    title
    externalUrl
  }
`
export const SharedFolderFragmentDoc = gql`
  fragment SharedFolder on SharedFolder {
    id
    status
    bookmarkFolder {
      id
    }
    sharedPartyId
    sharedPartyType
    userId
  }
`
export const BookmarkFolderPartsFragmentDoc = gql`
  fragment BookmarkFolderParts on BookmarkFolder {
    id
    name
    description
    forkedFromId
    goalComplete
    isShared
    parentId
    reforgeCollection
    teamProgress
    user {
      id
      fullName
      profile {
        avatarUrl
      }
    }
    sharedFolders {
      id
      ...SharedFolder
    }
  }
  ${SharedFolderFragmentDoc}
`
export const BookmarkFolderWithSharingPartsFragmentDoc = gql`
  fragment BookmarkFolderWithSharingParts on BookmarkFolder {
    ...BookmarkFolderParts
    usersSharedWith {
      id
      fullName
      profile {
        avatarUrl
      }
    }
    individualUsersSharedWith {
      id
      fullName
      profile {
        avatarUrl
      }
    }
    teamMembersWhoStartedGoal {
      id
      fullName
      avatarUrl
    }
    teamMembersWhoCompletedGoal {
      id
      fullName
      avatarUrl
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
`
export const CmsProgramFilterPartsFragmentDoc = gql`
  fragment CmsProgramFilterParts on CmsProgram {
    id
    progressPercent
    recommendationRanking
    contentBookmarkId
    contentIsInMySavedItems
    notLaunched
    enrolled
  }
`
export const CmsProgramCardPartsFragmentDoc = gql`
  fragment CmsProgramCardParts on CmsProgram {
    id
    lessonCount
    slug
    name
    canEnroll
    canEnrollForCurrentSeason
    hasPreviewableContent
    iconImageUrl
    synopsis
    releaseSeasonDate
    releaseStartDate
    releaseEnrollmentDate
    launchAt
    deprecatedAt
    numBookmarks
    code
    progressPercent
    notLaunched
    enrolled
    recommendationRanking
    contentBookmarkId
    contentIsInMySavedItems
  }
`
export const CmsProgramListPartsFragmentDoc = gql`
  fragment CmsProgramListParts on CmsProgram {
    id
    ...CmsProgramFilterParts
    ...CmsProgramCardParts
    topic
  }
  ${CmsProgramFilterPartsFragmentDoc}
  ${CmsProgramCardPartsFragmentDoc}
`
export const CohortLinksPartsFragmentDoc = gql`
  fragment CohortLinksParts on Cohort {
    id
    cmsProgram {
      id
      iconImageUrl
      name
    }
    season {
      id
      showCohortViewerAt
    }
    slug
  }
`
export const CohortLinksUserPartsFragmentDoc = gql`
  fragment CohortLinksUserParts on User {
    accessPolicyKind
    cohorts {
      latestCurrent {
        cohort {
          ...CohortLinksParts
        }
      }
    }
    eventSpeakerCohortsForActiveSeasons {
      ...CohortLinksParts
    }
    hostCohortsForActiveSeasons {
      ...CohortLinksParts
    }
  }
  ${CohortLinksPartsFragmentDoc}
`
export const TestimonialPartsFragmentDoc = gql`
  fragment TestimonialParts on CclReview {
    id
    title
    subTitle
    quote
    company {
      id
      name
      logoUrl
    }
  }
`
export const ContentBlockCollapsiblePartsFragmentDoc = gql`
  fragment ContentBlockCollapsibleParts on ContentBlockCollapsible {
    id
    type
    description
    label
  }
`
export const ContentBlockPartsFragmentDoc = gql`
  fragment ContentBlockParts on ContentBlock {
    id
    header
    slug
    order
    description
    contentBlockCollapsible {
      id
      ...ContentBlockCollapsibleParts
    }
  }
  ${ContentBlockCollapsiblePartsFragmentDoc}
`
export const CourseDetailCourseBlockGuidePartsFragmentDoc = gql`
  fragment CourseDetailCourseBlockGuideParts on CclGuide {
    id
    title
    overview
    slug
    freePreview
    displayOrder
    estimatedMinutes
    sourceId
  }
`
export const CourseDetailCourseBlockArtifactPartsFragmentDoc = gql`
  fragment CourseDetailCourseBlockArtifactParts on CclArtifact {
    id
    title
    slug
    sourceId
  }
`
export const CourseDetailCourseBlockEventPartsFragmentDoc = gql`
  fragment CourseDetailCourseBlockEventParts on CclSessionEvent {
    id
    title
    startsAt
    endsAt
    sourceId
  }
`
export const CourseDetailCourseVideoPartsFragmentDoc = gql`
  fragment CourseDetailCourseVideoParts on CclVideo {
    id
    title
    sourceId
    location
  }
`
export const CourseDetailCourseBlockPartsFragmentDoc = gql`
  fragment CourseDetailCourseBlockParts on CclCourseBlock {
    id
    title
    description
    previewable
    displayOrder
    kind
    contentBlocks {
      ...ContentBlockParts
    }
    guides {
      ...CourseDetailCourseBlockGuideParts
    }
    artifacts {
      ...CourseDetailCourseBlockArtifactParts
    }
    events {
      ...CourseDetailCourseBlockEventParts
    }
    eventPreviews {
      ...CourseDetailCourseVideoParts
    }
  }
  ${ContentBlockPartsFragmentDoc}
  ${CourseDetailCourseBlockGuidePartsFragmentDoc}
  ${CourseDetailCourseBlockArtifactPartsFragmentDoc}
  ${CourseDetailCourseBlockEventPartsFragmentDoc}
  ${CourseDetailCourseVideoPartsFragmentDoc}
`
export const CreatorPartsFragmentDoc = gql`
  fragment CreatorParts on CclExpert {
    id
    name
    avatarUrl
    bio
    position
    companyLogoUrl
    companyName
    slug
    isCollectiveMember
  }
`
export const CourseDetailPartsFragmentDoc = gql`
  fragment CourseDetailParts on CclCourse {
    id
    title
    shortDescription
    slug
    goals
    focus
    audience
    onDemand
    heroImageUrl
    trailerUrl
    outlineComplete
    sourceId
    staticId
    testimonials {
      ...TestimonialParts
    }
    blocks {
      ...CourseDetailCourseBlockParts
    }
    creators {
      ...CreatorParts
    }
  }
  ${TestimonialPartsFragmentDoc}
  ${CourseDetailCourseBlockPartsFragmentDoc}
  ${CreatorPartsFragmentDoc}
`
export const ImageFragmentFragmentDoc = gql`
  fragment ImageFragment on Image {
    imageUrl
    imageAlt
    aspectRatio
  }
`
export const RelatedCourseCreatorPartsFragmentDoc = gql`
  fragment RelatedCourseCreatorParts on ArtifactAuthor {
    id
    name
    shortBio
    position
    slug
    avatarPhoto {
      ...ImageFragment
    }
    position
    company {
      id
      name
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const RelatedCoursePartsFragmentDoc = gql`
  fragment RelatedCourseParts on SanityCourse {
    id
    title
    slug
    creators {
      ...RelatedCourseCreatorParts
    }
  }
  ${RelatedCourseCreatorPartsFragmentDoc}
`
export const CoursePreviewIdentifierPartsFragmentDoc = gql`
  fragment CoursePreviewIdentifierParts on CoursePreview {
    id
    title
  }
`
export const CourseSessionIsFirstPilotPartsFragmentDoc = gql`
  fragment CourseSessionIsFirstPilotParts on CourseSession {
    id
    startsAt
  }
`
export const CourseLinkUserCoursePartsFragmentDoc = gql`
  fragment CourseLinkUserCourseParts on UserCourse {
    id
    courseSession {
      id
      endsAt
      ...CourseSessionIsFirstPilotParts
    }
    course {
      id
      title
      slug
      completionPercentage
    }
  }
  ${CourseSessionIsFirstPilotPartsFragmentDoc}
`
export const CourseLinkCurrentUserPartsFragmentDoc = gql`
  fragment CourseLinkCurrentUserParts on User {
    id
    is {
      paidMember
      member
    }
    userCourses {
      current {
        ...CourseLinkUserCourseParts
      }
      previous {
        ...CourseLinkUserCourseParts
      }
      upcoming {
        ...CourseLinkUserCourseParts
      }
    }
  }
  ${CourseLinkUserCoursePartsFragmentDoc}
`
export const TrackCourseDashboardContentClickedCoursePartsFragmentDoc = gql`
  fragment TrackCourseDashboardContentClickedCourseParts on Course {
    id
    title
    sanityId
  }
`
export const TrackCourseDashboardContentClickedCourseSessionPartsFragmentDoc = gql`
  fragment TrackCourseDashboardContentClickedCourseSessionParts on CourseSession {
    id
    hasStarted
    hasEnded
  }
`
export const ProgressConsumerUserPartsFragmentDoc = gql`
  fragment ProgressConsumerUserParts on User {
    id
    is {
      freeUser
    }
  }
`
export const ProgressConsumerUnitPartsFragmentDoc = gql`
  fragment ProgressConsumerUnitParts on Unit {
    id
    previewable
  }
`
export const ProgressConsumerCourseSessionPartsFragmentDoc = gql`
  fragment ProgressConsumerCourseSessionParts on CourseSession {
    id
    hasStarted
    hasEnded
  }
`
export const ProgressConsumerCoursePartsFragmentDoc = gql`
  fragment ProgressConsumerCourseParts on Course {
    id
    title
    slug
  }
`
export const CohortRsvpKickoffEventPartsFragmentDoc = gql`
  fragment CohortRsvpKickoffEventParts on Event {
    id
  }
`
export const CurrentLiveProgramsPartsFragmentDoc = gql`
  fragment CurrentLiveProgramsParts on UserCohort {
    id
    cohort {
      coreWeeksHaveEnded
      postCoreWeeksEnrollmentIsOpen
      isOngoing
    }
  }
`
export const HostImagePartsFragmentDoc = gql`
  fragment HostImageParts on Host {
    thumbnailUrl
    name
  }
`
export const BillingAddressFragmentDoc = gql`
  fragment BillingAddress on StripeAddress {
    line1
    line2
    city
    state
    country
    postal_code: postalCode
  }
`
export const ArtifactFragmentFragmentDoc = gql`
  fragment ArtifactFragment on Artifact {
    id
    slug
    publishDate
    title
    description
    thumbnailUrl
    viewCount
    commentCount
    topics
    isFree
    filters {
      slug
      title
      type
    }
    company {
      id
      name
      logo {
        ...ImageFragment
      }
    }
    authors {
      id
      name
      slug
      position
      isCollectiveMember
      company {
        name
      }
      avatarUrl
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const ArtifactAuthorFragmentFragmentDoc = gql`
  fragment ArtifactAuthorFragment on ArtifactAuthor {
    id
    name
    slug
    position
    company {
      name
    }
    avatarPhoto {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const ExpertUserProfileFragmentFragmentDoc = gql`
  fragment ExpertUserProfileFragment on ExpertUserProfile {
    avatarUrl
    aboutMeText
    companyName
    ctaText
    ctaUrl
    firstName
    fullName
    hasBadge
    lastName
    linkedin
    location
    role
    externalId
    twitter
    kind
    is {
      collective
    }
    previousCompanies {
      id
      company {
        id
        name
      }
    }
    allCourses {
      id
      title
      slug
      shortDescription
      publishedAt
      creators {
        name
        avatarUrl
      }
      upcomingSessions {
        startsAt
        duration
        endsAt
      }
    }
    allHostedEvents {
      id
      name
      slug
      past
      kind
      startsAtUtc
      endsAtUtc
      hosts {
        id
        user {
          id
          profile {
            fullName
            avatarUrl
          }
        }
      }
    }
    allBlogPosts {
      id
      title
      slug
      thumbnailUrl
      firstPublishedAt
      summary
      sourceId
      authors {
        name
        avatarUrl
      }
    }
    allGuides {
      id
      title
      publishedAt
      overview
      slug
      thumbnailUrl
      estimatedTimeInMinutes: estimatedMinutes
      stepsCount
      sourceId
      previewable: freePreview
      authors: creators {
        name
        avatarUrl
      }
    }
    upcomingEvents {
      id
      name
      slug
      startsAt
      endsAt
    }
    upcomingLiveCourses {
      id
      title
      slug
      upcomingSessions {
        id
        startsAt
        duration
        endsAt
      }
    }
  }
`
export const ArtifactWithExpertFragmentFragmentDoc = gql`
  fragment ArtifactWithExpertFragment on Artifact {
    ...ArtifactFragment
    authors {
      ...ArtifactAuthorFragment
      user {
        ...ExpertUserProfileFragment
      }
    }
  }
  ${ArtifactFragmentFragmentDoc}
  ${ArtifactAuthorFragmentFragmentDoc}
  ${ExpertUserProfileFragmentFragmentDoc}
`
export const FilteredArtifactsFragmentFragmentDoc = gql`
  fragment FilteredArtifactsFragment on FilteredArtifacts {
    artifacts {
      ...ArtifactWithExpertFragment
    }
    nextArtifactsCount
    prevArtifactsCount
    totalArtifactsCount
  }
  ${ArtifactWithExpertFragmentFragmentDoc}
`
export const GuideBookmarkPartsFragmentDoc = gql`
  fragment GuideBookmarkParts on Bookmark {
    id
    basedOn
    noteBody
    sanityId
    type
    updatedAt
    createdAtInteger
    isWithinContent
    filedBookmarks {
      id
      bookmarkFolderId
    }
    numPeopleWhoBookmarked
  }
`
export const CmsSectionPartsFragmentDoc = gql`
  fragment CmsSectionParts on CmsSection {
    id
    name
    cmsProgram {
      id
      progressPercent
    }
    status
    publishAt
    shortDescription
    estimatedTime
    topic {
      id
      title
    }
  }
`
export const ContentCardPartsFragmentDoc = gql`
  fragment ContentCardParts on CmsSection {
    id
    conceptIcon
    contentType
    contentTypeIcon
    cmsModuleId
    cmsProgramId
    estimatedTime
    href
    heroImageUrl
    name
    numBookmarks
    numPhases
    showNewBadge
    shortDescription
    contentBookmarkId
    contentIsInMySavedItems
    unlimitedAccess
    slug
    cmsProgram {
      id
      programImage
      notLaunched
      name
      collaborators {
        id
        name
        thumbnailUrl
      }
    }
    lessonNum
    accessLevel
    previewable
  }
`
export const ContentLinkInfoPartsFragmentDoc = gql`
  fragment ContentLinkInfoParts on CmsSection {
    id
    href
    name
    contentType
    cmsProgram {
      id
      notLaunched
      enrolled
      slug
    }
    parent {
      id
      slug
    }
  }
`
export const LessonCardContentPartsFragmentDoc = gql`
  fragment LessonCardContentParts on CmsSection {
    id
    ...ContentLinkInfoParts
    accessLevel
    contentTypeIcon
    estimatedTime
    heroImageUrl
    name
    showNewBadge
    shortDescription
    numBookmarks
    lastViewedAt
    contentSubtype
    cmsModule {
      id
      name
    }
  }
  ${ContentLinkInfoPartsFragmentDoc}
`
export const SectionCardChildPartsFragmentDoc = gql`
  fragment SectionCardChildParts on CmsSection {
    id
    slug
    name
    heroImageUrl
    cmsModule {
      id
      name
    }
  }
`
export const SectionPartsFragmentDoc = gql`
  fragment SectionParts on CmsSection {
    id
    contentType
    name
    ...ContentCardParts
    ...LessonCardContentParts
    children {
      ...SectionCardChildParts
    }
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
  ${SectionCardChildPartsFragmentDoc}
`
export const CohortPostUserPartsFragmentDoc = gql`
  fragment CohortPostUserParts on User {
    id
    profile {
      fullName
      avatarUrl
    }
  }
`
export const CohortPostReactionPartsFragmentDoc = gql`
  fragment CohortPostReactionParts on Reaction {
    id
    kind
    user {
      id
      ...CohortPostUserParts
    }
  }
  ${CohortPostUserPartsFragmentDoc}
`
export const CohortPostReplyPartsFragmentDoc = gql`
  fragment CohortPostReplyParts on Reply {
    id
    body
    createdAtInWords
    user {
      id
      ...CohortPostUserParts
    }
    reactions {
      id
      ...CohortPostReactionParts
    }
  }
  ${CohortPostUserPartsFragmentDoc}
  ${CohortPostReactionPartsFragmentDoc}
`
export const CohortPostPartsFragmentDoc = gql`
  fragment CohortPostParts on Post {
    id
    title
    body
    createdAtInWords
    lastRespondedAtInWords
    isPinned
    following
    user {
      id
      ...CohortPostUserParts
    }
    reactions {
      id
      ...CohortPostReactionParts
    }
    replies {
      id
      ...CohortPostReplyParts
    }
    topics {
      id
      title
      description
    }
    groups {
      id
      title
      slug
    }
    basedOn
    cmsSectionName
    cmsSectionParentName
    cmsSectionLink
    referenceImageUrl
  }
  ${CohortPostUserPartsFragmentDoc}
  ${CohortPostReactionPartsFragmentDoc}
  ${CohortPostReplyPartsFragmentDoc}
`
export const CohortPostTopicPartsFragmentDoc = gql`
  fragment CohortPostTopicParts on PostTopic {
    id
    title
    description
    restrictedToStaff
    isContentClarification
    groupId
  }
`
export const CohortDashboardUserProgramPartsFragmentDoc = gql`
  fragment CohortDashboardUserProgramParts on UserProgram {
    id
    progress
  }
`
export const CohortDashboardCurrentUserPartsFragmentDoc = gql`
  fragment CohortDashboardCurrentUserParts on User {
    id
    timezone
    shouldShowNpsSurvey
    hasTeam
    activeProgram {
      id
      ...CohortDashboardUserProgramParts
    }
  }
  ${CohortDashboardUserProgramPartsFragmentDoc}
`
export const CohortDashboardSeasonPartsFragmentDoc = gql`
  fragment CohortDashboardSeasonParts on Season {
    name
    year
    npsSurveyLink
    npsSurveyText
  }
`
export const CohortDashboardScheduledWeekTabPartsFragmentDoc = gql`
  fragment CohortDashboardScheduledWeekTabParts on ScheduledWeek {
    id
    isCurrentWeek
    numLessons
    events {
      id
    }
  }
`
export const CohortEventSameInfoPartsFragmentDoc = gql`
  fragment CohortEventSameInfoParts on Event {
    id
    startsAtUtc
    endsAtUtc
    attendingStatus
    past
    summaryWistiaCode
    slug
    kind
    preread
    caseCompany {
      id
      name
    }
    uuid
  }
`
export const UserProfileImagePartsFragmentDoc = gql`
  fragment UserProfileImageParts on UserProfile {
    fullName
    avatarUrl
  }
`
export const CohortEventSpeakersPartsFragmentDoc = gql`
  fragment CohortEventSpeakersParts on EventSpeaker {
    id
    kind
    user {
      id
      profile {
        companyName
        ...UserProfileImageParts
      }
    }
  }
  ${UserProfileImagePartsFragmentDoc}
`
export const CohortEventCardPartsFragmentDoc = gql`
  fragment CohortEventCardParts on Event {
    id
    ...CohortEventSameInfoParts
    name
    willBeRecorded
    preread
    speakers {
      id
      ...CohortEventSpeakersParts
    }
  }
  ${CohortEventSameInfoPartsFragmentDoc}
  ${CohortEventSpeakersPartsFragmentDoc}
`
export const CohortDashboardProjectPartsFragmentDoc = gql`
  fragment CohortDashboardProjectParts on CmsSection {
    id
  }
`
export const CohortCmsSectionTagPartsFragmentDoc = gql`
  fragment CohortCmsSectionTagParts on CmsSectionTag {
    id
    tag
    cmsSectionId
  }
`
export const CohortDashboardLessonPartsFragmentDoc = gql`
  fragment CohortDashboardLessonParts on CmsSection {
    id
    name
    estimatedTime
    publishAt
    published
    slug
    href
    contentType
    heroImageUrl
    tags {
      ...CohortCmsSectionTagParts
    }
  }
  ${CohortCmsSectionTagPartsFragmentDoc}
`
export const CohortDashboardCmsSectionPartsFragmentDoc = gql`
  fragment CohortDashboardCmsSectionParts on CmsSection {
    id
    name
    slug
    estimatedTime
    contentType
    slug
    href
    publishAt
    published
    heroImageUrl
    tags {
      ...CohortCmsSectionTagParts
    }
    children {
      ...CohortDashboardLessonParts
    }
  }
  ${CohortCmsSectionTagPartsFragmentDoc}
  ${CohortDashboardLessonPartsFragmentDoc}
`
export const CohortCmsModulePartsFragmentDoc = gql`
  fragment CohortCmsModuleParts on CmsModule {
    id
    name
    slug
    href
    estimatedTime
    publishAt
    published
    project {
      id
      ...CohortDashboardProjectParts
    }
    cmsSections {
      ...CohortDashboardCmsSectionParts
    }
  }
  ${CohortDashboardProjectPartsFragmentDoc}
  ${CohortDashboardCmsSectionPartsFragmentDoc}
`
export const CohortDashboardCmsProgramPartFragmentDoc = gql`
  fragment CohortDashboardCmsProgramPart on CmsProgram {
    name
    byTheEnd
    shortDescription
    templates {
      ...CohortCmsModuleParts
    }
  }
  ${CohortCmsModulePartsFragmentDoc}
`
export const CohortDashboardCohortPartsFragmentDoc = gql`
  fragment CohortDashboardCohortParts on Cohort {
    id
    numUniqCohortEvents
    isOngoing
    isUpcoming
    hasCohortOffboardingStarted
    snapshotReleased
    shouldShowNpsSurvey
    season {
      id
      ...CohortDashboardSeasonParts
    }
    scheduledWeeks {
      ...CohortDashboardScheduledWeekTabParts
      events {
        id
        ...CohortEventCardParts
      }
    }
    cmsProgram {
      id
      ...CohortDashboardCmsProgramPart
    }
  }
  ${CohortDashboardSeasonPartsFragmentDoc}
  ${CohortDashboardScheduledWeekTabPartsFragmentDoc}
  ${CohortEventCardPartsFragmentDoc}
  ${CohortDashboardCmsProgramPartFragmentDoc}
`
export const ShowCohortDashboardSeasonPartsFragmentDoc = gql`
  fragment ShowCohortDashboardSeasonParts on Season {
    id
    showCohortViewerAt
  }
`
export const CohortDashboardHeaderScheduledWeekPartsFragmentDoc = gql`
  fragment CohortDashboardHeaderScheduledWeekParts on ScheduledWeek {
    id
    isCurrentWeek
    orderedCmsModules {
      id
      ...CohortCmsModuleParts
    }
  }
  ${CohortCmsModulePartsFragmentDoc}
`
export const CohortCollectionPartsFragmentDoc = gql`
  fragment CohortCollectionParts on CohortBookmarkFolder {
    id
    name
    bookmarkFolderId
  }
`
export const CohortDashboardSectionCardCmsModulePartsFragmentDoc = gql`
  fragment CohortDashboardSectionCardCmsModuleParts on CmsModule {
    id
    name
    published
    publishAt
    project {
      id
      ...CohortDashboardProjectParts
    }
  }
  ${CohortDashboardProjectPartsFragmentDoc}
`
export const CohortDashboardScheduledWeekCurrentUserPartsFragmentDoc = gql`
  fragment CohortDashboardScheduledWeekCurrentUserParts on User {
    id
    timezone
    hasTeam
    activeProgram {
      id
      progress
    }
  }
`
export const CohortDashboardScheduledWeekCohortPartsFragmentDoc = gql`
  fragment CohortDashboardScheduledWeekCohortParts on Cohort {
    id
    slackChannelId
    batchRsvpTimeData {
      ids
      startsAtUtc
      endsAtUtc
      timeGrouping
    }
    kickoffEvents {
      id
    }
    season {
      id
      name
      year
    }
    cmsProgram {
      name
    }
  }
`
export const CohortDashboardScheduledWeekUserCohortPartsFragmentDoc = gql`
  fragment CohortDashboardScheduledWeekUserCohortParts on UserCohort {
    id
    showEventRsvpModal
    hasSignedStatementOfCommitment
    hasClickedMeetCohortParticipantsCta
    timeGroupingSelected
    cohort {
      id
      ...CohortDashboardScheduledWeekCohortParts
    }
  }
  ${CohortDashboardScheduledWeekCohortPartsFragmentDoc}
`
export const CohortDashboardScheduledWeekPartsFragmentDoc = gql`
  fragment CohortDashboardScheduledWeekParts on ScheduledWeek {
    id
    title
    description
    isCurrentWeek
    numLessons
    startsAt
    endsAt
    orderedCmsModules {
      id
      name
      slug
      href
      estimatedTime
      published
      publishAt
      project {
        id
        ...CohortDashboardProjectParts
      }
      cmsSections {
        ...CohortDashboardCmsSectionParts
      }
    }
    events {
      id
      ...CohortEventCardParts
    }
  }
  ${CohortDashboardProjectPartsFragmentDoc}
  ${CohortDashboardCmsSectionPartsFragmentDoc}
  ${CohortEventCardPartsFragmentDoc}
`
export const InterventionEventSameInfoPartsFragmentDoc = gql`
  fragment InterventionEventSameInfoParts on Event {
    id
    startsAtUtc
    endsAtUtc
    attendingStatus
    slug
    kind
    preread
    uuid
  }
`
export const UnexpiredCohortCreditFragmentDoc = gql`
  fragment UnexpiredCohortCredit on CohortCredit {
    id
    expiresAt
    status
    useable
    seat {
      id
      subscriptionMember {
        userId
      }
    }
  }
`
export const CohortTeamWeekProgressEventAttendeePartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressEventAttendeeParts on EventAttendee {
    id
    status
    eventId
  }
`
export const CohortTeamWeekProgressUserPartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressUserParts on User {
    id
    activeProgram {
      id
      progress
    }
    rsvpsInWeek(weekId: $weekId) {
      ...CohortTeamWeekProgressEventAttendeeParts
    }
    profile {
      fullName
      avatarUrl
    }
  }
  ${CohortTeamWeekProgressEventAttendeePartsFragmentDoc}
`
export const CohortTeamWeekProgressCmsSectionPartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressCmsSectionParts on CmsSection {
    id
    name
    estimatedTime
    contentType
    slug
    href
  }
`
export const CohortTeamWeekProgressCmsModulePartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressCmsModuleParts on CmsModule {
    id
    name
    estimatedTime
    slug
    href
    project {
      id
    }
    cmsSections {
      ...CohortTeamWeekProgressCmsSectionParts
      children {
        ...CohortTeamWeekProgressCmsSectionParts
      }
    }
  }
  ${CohortTeamWeekProgressCmsSectionPartsFragmentDoc}
`
export const CohortTeamWeekProgressEventPartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressEventParts on Event {
    id
    slug
    kind
    name
  }
`
export const CohortTeamWeekProgressScheduledWeekPartsFragmentDoc = gql`
  fragment CohortTeamWeekProgressScheduledWeekParts on ScheduledWeek {
    numLessons
    startsAt
    endsAt
    orderedCmsModules {
      ...CohortTeamWeekProgressCmsModuleParts
    }
    events {
      ...CohortTeamWeekProgressEventParts
    }
  }
  ${CohortTeamWeekProgressCmsModulePartsFragmentDoc}
  ${CohortTeamWeekProgressEventPartsFragmentDoc}
`
export const CohortTeamActivitiesListItemPartsFragmentDoc = gql`
  fragment CohortTeamActivitiesListItemParts on CohortActivityListItem {
    actionType
    activityIds
    latestActivityCreatedAt
    agoTimephrase
    postBodyText
    postId
    postTopicSlug
    postTopicTitle
    reactions
    replies
    userAvatarUrl
    userFullName
  }
`
export const CohortUserProfilePartsFragmentDoc = gql`
  fragment CohortUserProfileParts on UserProfile {
    kind
    fullName
    companyName
    avatarUrl
    role
    location
    profileUrl
    aboutMeText
    askMeAboutText
    learningGoalsText
    companyProductText
    previousCompanies {
      id
      company {
        name
      }
    }
    communityInterestText
    communityInterestTags
    companyAudienceTags
    businessModelTags
    areasOfExpertiseTags
    allowDirectMessages
  }
`
export const CohortViewerEventPartsFragmentDoc = gql`
  fragment CohortViewerEventParts on Event {
    attendingStatus
    id
    name
    past
    slug
    startsAtUtc
    endsAtUtc
    willBeRecorded
    summaryWistiaCode
    uuid
    kind
    caseCompany {
      id
      name
    }
    speakers {
      id
      kind
      user {
        id
        profile {
          fullName
          companyName
          avatarUrl
        }
      }
    }
  }
`
export const CohortViewerOrderedCmsModulePartsFragmentDoc = gql`
  fragment CohortViewerOrderedCmsModuleParts on CmsModule {
    estimatedTime
    href
    id
    name
    numBookmarks
    numLessons
    programCode
    progressPercent
    shortDescription
    publishAt
    published
    programIconImageUrl
    clips {
      id
      title
      duration
      slug
      company {
        id
        cardLogoUrl
        name
      }
      thumbnailUrl
      summary
      summaryWistiaCode
      hasBeenViewedByCurrentUser
      users {
        id
        avatarUrl
        fullName
        role
        companyName
        profile {
          profileUrl
        }
      }
    }
  }
`
export const CohortViewerScheduledWeekPartsFragmentDoc = gql`
  fragment CohortViewerScheduledWeekParts on ScheduledWeek {
    id
    bonus
    startsAt
    endsAt
    title
    description
    numLessons
    estimatedTimeInMinutes
    postCohort
    progressPercent
    events {
      id
      ...CohortViewerEventParts
    }
    orderedCmsModules {
      id
      ...CohortViewerOrderedCmsModuleParts
    }
  }
  ${CohortViewerEventPartsFragmentDoc}
  ${CohortViewerOrderedCmsModulePartsFragmentDoc}
`
export const CohortViewerPartsFragmentDoc = gql`
  fragment CohortViewerParts on UserCohort {
    id
    hasPostedWelcome
    hasSignedStatementOfCommitment
    hasDismissedNewContentEirMessage
    showEventRsvpModal
    timeGroupingSelected
    hasDismissedPostCohortEirMessage
    hasClickedMeetCohortParticipantsCta
    hasClickedShareSnapshotCta
    snapshot {
      id
      token
      released
    }
    cohort {
      id
      slackChannelId
      startsAt
      kickoffEvents {
        id
      }
      batchRsvpTimeData {
        ids
        startsAtUtc
        endsAtUtc
        timeGrouping
      }
      season {
        id
        startsAt
        endsAt
        name
        year
        npsSurveyLink
        showCohortViewerAt
      }
      hosts {
        id
        userId
        name
        thumbnailUrl
        previousCompanies
        title
      }
      programCollaborators {
        id
        userId
        name
        thumbnailUrl
        previousCompanies
        title
      }
      cmsProgram {
        id
        code
        name
        notLaunched
        iconImageUrl
        heroImageUrl
        releaseStartDate
      }
      events {
        id
        startsAtUtc
      }
      scheduledWeeks {
        id
        ...CohortViewerScheduledWeekParts
      }
      shouldShowNpsSurvey
    }
  }
  ${CohortViewerScheduledWeekPartsFragmentDoc}
`
export const CohortViewerNewCohortTeamPartsFragmentDoc = gql`
  fragment CohortViewerNewCohortTeamParts on CohortTeam {
    id
    name
  }
`
export const CohortViewerNewScheduledWeekPartsFragmentDoc = gql`
  fragment CohortViewerNewScheduledWeekParts on ScheduledWeek {
    id
    isCurrentWeek
    numLessons
    events {
      id
    }
  }
`
export const CohortViewerNewCohortPartsFragmentDoc = gql`
  fragment CohortViewerNewCohortParts on Cohort {
    id
    name
    slug
    isOngoing
    isUpcoming
    hasCohortOffboardingStarted
    slackChannelId
    shouldShowNpsSurvey
    scheduledWeeks {
      ...CohortViewerNewScheduledWeekParts
    }
    season {
      id
      name
      year
      npsSurveyLink
      npsSurveyText
    }
    cmsProgram {
      id
      name
      slug
    }
    group {
      id
    }
  }
  ${CohortViewerNewScheduledWeekPartsFragmentDoc}
`
export const CohortViewerMaterialSectionPartsFragmentDoc = gql`
  fragment CohortViewerMaterialSectionParts on CmsSection {
    id
    name
    estimatedTime
    slug
    href
    contentType
    contentTypeIcon
    heroImageUrl
    shortDescription
    ...ContentCardParts
    ...LessonCardContentParts
    previewable
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`
export const CohortViewerMaterialParentSectionPartsFragmentDoc = gql`
  fragment CohortViewerMaterialParentSectionParts on CmsSection {
    id
    children {
      id
      parentId
      ...CohortViewerMaterialSectionParts
    }
    ...CohortViewerMaterialSectionParts
    previewable
  }
  ${CohortViewerMaterialSectionPartsFragmentDoc}
`
export const CohortViewerMaterialCmsModulePartsFragmentDoc = gql`
  fragment CohortViewerMaterialCmsModuleParts on CmsModule {
    id
    estimatedTime
    published
    name
    slug
    publishAt
    anyCountsTowardsCompletion
    cmsSections {
      id
      ...CohortViewerMaterialParentSectionParts
    }
    project {
      id
      cmsProgramId
      cmsModuleId
      ...CohortViewerMaterialSectionParts
    }
    containsPreviewableLessons
  }
  ${CohortViewerMaterialParentSectionPartsFragmentDoc}
  ${CohortViewerMaterialSectionPartsFragmentDoc}
`
export const CollectionActivityPartsFragmentDoc = gql`
  fragment CollectionActivityParts on CollectionActivity {
    id
    actionType
    actionText
    bookmarkFolderId
    hasRestoreAction
    createdAtInWords
    filedBookmark {
      id
      bookmarkId
    }
    user {
      ...UserAvatarParts
    }
  }
  ${UserAvatarPartsFragmentDoc}
`
export const ConceptCardSectionPartsFragmentDoc = gql`
  fragment ConceptCardSectionParts on CmsSection {
    id
    contentType
    contentTypeIcon
    estimatedTime
    contentBookmarkId
    contentIsInMySavedItems
    heroImageUrl
    href
    name
    numPhases
    projectIcon
    shortDescription
    numBookmarks
    showNewBadge
    slug
    topic {
      id
      title
    }
    cmsModule {
      id
      slug
      name
    }
    cmsProgram {
      id
      notLaunched
      enrolled
      slug
      name
      collaborators {
        id
        name
        thumbnailUrl
      }
    }
    accessLevel
    previewable
    lastViewedAt
    contentSubtype
    lessonNum
  }
`
export const ConceptCardContentPartsFragmentDoc = gql`
  fragment ConceptCardContentParts on Content {
    accessLevel
    contentBookmarkId
    contentIsInMySavedItems
    showNewBadge
    cmsSection {
      id
      ...ConceptCardSectionParts
    }
  }
  ${ConceptCardSectionPartsFragmentDoc}
`
export const ContentBookmarkAnchorPartsFragmentDoc = gql`
  fragment ContentBookmarkAnchorParts on Bookmark {
    id
    anchor
  }
`
export const ExpertUserPartsFragmentDoc = gql`
  fragment ExpertUserParts on User {
    id
    hasBadge
    avatarUrl
    fullName
    companyName
    role
    kind
  }
`
export const BaseContentTocItemPartsFragmentDoc = gql`
  fragment BaseContentTocItemParts on ContentTocItem {
    id
    name
    href
    current
    contentTypeIcon
    estimatedTime
    isSidebar
    numBookmarks
    phaseNumber
    parentId
  }
`
export const ContentTocItemPartsFragmentDoc = gql`
  fragment ContentTocItemParts on ContentTocItem {
    id
    ...BaseContentTocItemParts
    children {
      id
      ...BaseContentTocItemParts
    }
  }
  ${BaseContentTocItemPartsFragmentDoc}
`
export const ContentViewerTocPartsFragmentDoc = gql`
  fragment ContentViewerTocParts on ContentViewer {
    contentBookmarkAnchors {
      id
      ...ContentBookmarkAnchorParts
    }
    contentBookmarkAnchorsInMySavedItems {
      id
      ...ContentBookmarkAnchorParts
    }
    cmsProjectDeliverable {
      id
      url
      templateUrl
    }
    expertUsers {
      id
      ...ExpertUserParts
    }
    userProgram {
      id
      progress
    }
    cmsModule {
      id
    }
    cmsContent {
      id
      name
      cmsProgramId
      cmsModuleId
      contentType
      contentSubtype
      toc {
        id
        ...ContentTocItemParts
      }
      cmsProgram {
        id
        name
      }
    }
  }
  ${ContentBookmarkAnchorPartsFragmentDoc}
  ${ExpertUserPartsFragmentDoc}
  ${ContentTocItemPartsFragmentDoc}
`
export const ContentViewerExpertsPartsFragmentDoc = gql`
  fragment ContentViewerExpertsParts on ContentViewer {
    expertUsers {
      id
      ...ExpertUserParts
    }
  }
  ${ExpertUserPartsFragmentDoc}
`
export const HeaderUserProgramPartsFragmentDoc = gql`
  fragment HeaderUserProgramParts on UserProgram {
    id
    kind
    participatingKinds
  }
`
export const HeaderCmsSectionPartsFragmentDoc = gql`
  fragment HeaderCmsSectionParts on CmsSection {
    id
    name
    contentType
    position
    slug
    contentTypeIcon
    descriptionText
    heroImageUrl
    shortDescription
    estimatedTime
  }
`
export const HeaderCmsProgramPartsFragmentDoc = gql`
  fragment HeaderCmsProgramParts on CmsProgram {
    id
    name
  }
`
export const ContentViewerHeaderPartsFragmentDoc = gql`
  fragment ContentViewerHeaderParts on ContentViewer {
    userProgram {
      id
      ...HeaderUserProgramParts
    }
    cmsContent {
      id
      ...HeaderCmsSectionParts
      href
      showNewBadge
      contentBookmarkId
      contentIsInMySavedItems
      contentType
      cmsProgram {
        id
        programImage
        notLaunched
        enrolled
        slug
        name
        progressPercent
      }
    }
    cmsModule {
      id
    }
    cmsProgram {
      id
      slug
      progressPercent
      ...HeaderCmsProgramParts
    }
  }
  ${HeaderUserProgramPartsFragmentDoc}
  ${HeaderCmsSectionPartsFragmentDoc}
  ${HeaderCmsProgramPartsFragmentDoc}
`
export const ContentViewerContentPartsFragmentDoc = gql`
  fragment ContentViewerContentParts on ContentViewer {
    cmsContent {
      id
      truncatedDescriptionHtml
      blockedContentHtmlSafe
      accessLevel
    }
  }
`
export const ContentViewerPartialContentPartsFragmentDoc = gql`
  fragment ContentViewerPartialContentParts on ContentViewer {
    cmsContent {
      id
      name
      truncatedDescriptionHtml
      accessLevel
    }
  }
`
export const RelatedContentPartsFragmentDoc = gql`
  fragment RelatedContentParts on CmsSection {
    id
    ...ContentLinkInfoParts
    contentBookmarkId
    contentIsInMySavedItems
    heroImageUrl
    conceptIcon
    contentTypeIcon
    name
    shortDescription
    estimatedTime
    numBookmarks
    cmsModuleId
    cmsProgramId
    cmsProgram {
      id
      programImage
      notLaunched
      enrolled
      name
      collaborators {
        id
        name
        thumbnailUrl
      }
    }
    showNewBadge
    lessonNum
    numPhases
    accessLevel
    slug
    previewable
  }
  ${ContentLinkInfoPartsFragmentDoc}
`
export const DeliverableExamplePartsFragmentDoc = gql`
  fragment DeliverableExampleParts on ProjectDeliverable {
    id
    name
    title
    url
    company {
      id
      name
      cardLogoUrl
    }
  }
`
export const ContentViewerDeliverablePartsFragmentDoc = gql`
  fragment ContentViewerDeliverableParts on ContentViewer {
    cmsProjectDeliverable {
      id
      name
      title
      url
      templateUrl
    }
    cmsContent {
      id
      deliverableExamples {
        id
        ...DeliverableExampleParts
      }
    }
  }
  ${DeliverableExamplePartsFragmentDoc}
`
export const CheckoutCreatorPartsFragmentDoc = gql`
  fragment CheckoutCreatorParts on CclExpert {
    id
    name
    avatarUrl
  }
`
export const CourseCheckoutPartsFragmentDoc = gql`
  fragment CourseCheckoutParts on CclCourse {
    id
    title
    heroImageUrl
    testimonials {
      id
      title
      subTitle
      quote
      company {
        id
        name
        logoUrl
        wordmarkLogoUrl
      }
    }
    creators {
      ...CheckoutCreatorParts
    }
  }
  ${CheckoutCreatorPartsFragmentDoc}
`
export const CheckoutCourseSessionFragmentDoc = gql`
  fragment CheckoutCourseSession on CclCourseSession {
    id
    startsAt
    endsAt
    price
    duration
    experts {
      id
      name
      avatarUrl
    }
  }
`
export const CourseCompletionSnapshotPartsFragmentDoc = gql`
  fragment CourseCompletionSnapshotParts on CourseCompletionSnapshot {
    id
    imageUrl
    token
    shareUrl
    linkedinShareUrl
    twitterShareUrl
  }
`
export const CourseDashboardUserCoursePartsFragmentDoc = gql`
  fragment CourseDashboardUserCourseParts on UserCourse {
    id
    hasClickedOnSurveyLink
    courseCompletionSnapshot {
      ...CourseCompletionSnapshotParts
    }
  }
  ${CourseCompletionSnapshotPartsFragmentDoc}
`
export const CourseDashboardCreatorFragmentDoc = gql`
  fragment CourseDashboardCreator on ArtifactAuthor {
    id
    name
    avatarPhoto {
      ...ImageFragment
    }
    shortBio
    slug
    position
    pastCompanies
    company {
      id
      name
      logo {
        ...ImageFragment
      }
      slug
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CourseDashboardCoursePartsFragmentDoc = gql`
  fragment CourseDashboardCourseParts on Course {
    id
    title
    slug
    whoThisIsFor
    isAvailableOnDemand
    shortDescription
    completionPercentage
    sanityId
    creators {
      ...CourseDashboardCreator
    }
  }
  ${CourseDashboardCreatorFragmentDoc}
`
export const CourseDashboardCourseSessionGettingStartedBlockPartsFragmentDoc = gql`
  fragment CourseDashboardCourseSessionGettingStartedBlockParts on GettingStartedBlock {
    id
    description
    gettingStartedCollapsible {
      id
      description
      type
    }
  }
`
export const CourseDashboardCourseSessionPartsFragmentDoc = gql`
  fragment CourseDashboardCourseSessionParts on CourseSession {
    id
    courseId
    startsAt
    endsAt
    hasEnded
    hasStarted
    hosts {
      id
      name
      slug
      avatarPhoto {
        ...ImageFragment
      }
    }
    overallDuration
    markYourCalendar
    wrapUp
    surveyLink
    slackChannelId
    gettingStartedBlock {
      ...CourseDashboardCourseSessionGettingStartedBlockParts
    }
  }
  ${ImageFragmentFragmentDoc}
  ${CourseDashboardCourseSessionGettingStartedBlockPartsFragmentDoc}
`
export const CourseDashboardCourseBlockUnitPartsFragmentDoc = gql`
  fragment CourseDashboardCourseBlockUnitParts on Unit {
    id
    title
    slug
    estimatedTimeInMinutes
    previewable
    completed
  }
`
export const CohortDashboardCourseBlockArtifactAuthorPartsFragmentDoc = gql`
  fragment CohortDashboardCourseBlockArtifactAuthorParts on ArtifactAuthor {
    id
    name
    slug
    avatarPhoto {
      ...ImageFragment
    }
    position
    company {
      id
      name
      logo {
        ...ImageFragment
      }
      slug
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CourseDashboardCourseBlockArtifactPartsFragmentDoc = gql`
  fragment CourseDashboardCourseBlockArtifactParts on Artifact {
    id
    title
    slug
    thumbnailPhoto
    authors {
      ...CohortDashboardCourseBlockArtifactAuthorParts
    }
  }
  ${CohortDashboardCourseBlockArtifactAuthorPartsFragmentDoc}
`
export const CourseDashboardCourseBlockEventPartsFragmentDoc = gql`
  fragment CourseDashboardCourseBlockEventParts on CourseEvent {
    sanityId
    title
    startsAt
    endsAt
    joinLink
    recording
    shortDescription
  }
`
export const CourseDashboardCourseBlockPartsFragmentDoc = gql`
  fragment CourseDashboardCourseBlockParts on CourseBlock {
    id
    title
    description
    startDate
    endDate
    readByDate
    previewable
    units {
      ...CourseDashboardCourseBlockUnitParts
    }
    artifacts {
      ...CourseDashboardCourseBlockArtifactParts
    }
    events {
      ...CourseDashboardCourseBlockEventParts
    }
  }
  ${CourseDashboardCourseBlockUnitPartsFragmentDoc}
  ${CourseDashboardCourseBlockArtifactPartsFragmentDoc}
  ${CourseDashboardCourseBlockEventPartsFragmentDoc}
`
export const CourseEventHostFragmentDoc = gql`
  fragment CourseEventHost on ArtifactAuthor {
    id
    name
    avatarPhoto {
      ...ImageFragment
    }
    slug
    position
    company {
      id
      name
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CourseEventPartsFragmentDoc = gql`
  fragment CourseEventParts on CourseEvent {
    sanityId
    title
    startsAt
    endsAt
    joinLink
    recording
    shortDescription
    hosts {
      ...CourseEventHost
    }
    featuredGuests {
      ...CourseEventHost
    }
  }
  ${CourseEventHostFragmentDoc}
`
export const CourseNavigationOutlineFragmentDoc = gql`
  fragment CourseNavigationOutline on Course {
    id
    courseBlocks {
      id
      units {
        id
        title
        slug
      }
    }
  }
`
export const CourseOnDemandDashboardCourseBlockPartsFragmentDoc = gql`
  fragment CourseOnDemandDashboardCourseBlockParts on CourseBlock {
    id
    title
    description
    startDate
    endDate
    readByDate
    previewable
    units {
      ...CourseDashboardCourseBlockUnitParts
    }
    artifacts {
      ...CourseDashboardCourseBlockArtifactParts
    }
  }
  ${CourseDashboardCourseBlockUnitPartsFragmentDoc}
  ${CourseDashboardCourseBlockArtifactPartsFragmentDoc}
`
export const CourseOnDemandCmsProgramPartsFragmentDoc = gql`
  fragment CourseOnDemandCmsProgramParts on CmsProgram {
    id
    slug
  }
`
export const CoursePageAccessCreatorFragmentDoc = gql`
  fragment CoursePageAccessCreator on ArtifactAuthor {
    id
    name
    avatarPhoto {
      ...ImageFragment
    }
    shortBio
    slug
    position
    pastCompanies
    company {
      id
      name
      logo {
        ...ImageFragment
      }
      slug
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CourseIdentifierPartsFragmentDoc = gql`
  fragment CourseIdentifierParts on Course {
    id
    title
    sanityId
  }
`
export const CoursePageAccessPartsFragmentDoc = gql`
  fragment CoursePageAccessParts on Course {
    id
    title
    shortDescription
    isAvailableOnDemand
    slug
    sanityId
    whoThisIsFor
    cclCourse {
      id
    }
    creators {
      ...CoursePageAccessCreator
    }
    ...CourseIdentifierParts
  }
  ${CoursePageAccessCreatorFragmentDoc}
  ${CourseIdentifierPartsFragmentDoc}
`
export const CourseEnrollmentSnapshotPartsFragmentDoc = gql`
  fragment CourseEnrollmentSnapshotParts on CourseEnrollmentSnapshot {
    id
    imageUrl
    token
    shareUrl
    linkedinShareUrl
    twitterShareUrl
  }
`
export const CourseSessionIdentifierPartsFragmentDoc = gql`
  fragment CourseSessionIdentifierParts on CourseSession {
    id
    hasStarted
    hasEnded
  }
`
export const CourseSessionAccessPartsFragmentDoc = gql`
  fragment CourseSessionAccessParts on CourseSession {
    id
    startsAt
    endsAt
    hasEnded
    hosts {
      id
      name
      slug
      avatarPhoto {
        ...ImageFragment
      }
    }
    isContentOpen
    isHostOrCreator
    overallDuration
    markYourCalendar
    slackChannelId
    ...CourseSessionIdentifierParts
  }
  ${ImageFragmentFragmentDoc}
  ${CourseSessionIdentifierPartsFragmentDoc}
`
export const CoursePreviewDashboardCreatorFragmentDoc = gql`
  fragment CoursePreviewDashboardCreator on ArtifactAuthor {
    id
    name
    avatarPhoto {
      ...ImageFragment
    }
    shortBio
    slug
    position
    company {
      id
      name
      logo {
        ...ImageFragment
      }
      slug
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CoursePreviewDashboardCourseBlockUnitPartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseBlockUnitParts on Unit {
    id
    title
    slug
    estimatedTimeInMinutes
    previewable
  }
`
export const CoursePreviewDashboardCourseBlockArtifactPartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseBlockArtifactParts on Artifact {
    id
    title
    slug
    thumbnailPhoto
    authors {
      ...CohortDashboardCourseBlockArtifactAuthorParts
    }
  }
  ${CohortDashboardCourseBlockArtifactAuthorPartsFragmentDoc}
`
export const CoursePreviewDashboardCourseBlockEventPartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseBlockEventParts on CourseEvent {
    sanityId
    title
    recording
    shortDescription
  }
`
export const CoursePreviewDashboardCourseBlockPartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseBlockParts on CourseBlock {
    id
    title
    description
    previewable
    units {
      ...CoursePreviewDashboardCourseBlockUnitParts
    }
    artifacts {
      ...CoursePreviewDashboardCourseBlockArtifactParts
    }
    eventPreviews {
      ...CoursePreviewDashboardCourseBlockEventParts
    }
  }
  ${CoursePreviewDashboardCourseBlockUnitPartsFragmentDoc}
  ${CoursePreviewDashboardCourseBlockArtifactPartsFragmentDoc}
  ${CoursePreviewDashboardCourseBlockEventPartsFragmentDoc}
`
export const CoursePreviewDashboardCourseSessionPartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseSessionParts on CourseSession {
    id
    price
    startsAt
    isEnrollmentFull
  }
`
export const CoursePreviewDashboardCoursePartsFragmentDoc = gql`
  fragment CoursePreviewDashboardCourseParts on CoursePreview {
    id
    title
    slug
    whoThisIsFor
    shortDescription
    creators {
      ...CoursePreviewDashboardCreator
    }
    courseBlocks {
      ...CoursePreviewDashboardCourseBlockParts
    }
    upcomingSession {
      ...CoursePreviewDashboardCourseSessionParts
    }
  }
  ${CoursePreviewDashboardCreatorFragmentDoc}
  ${CoursePreviewDashboardCourseBlockPartsFragmentDoc}
  ${CoursePreviewDashboardCourseSessionPartsFragmentDoc}
`
export const CourseSnapshotArtifactAuthorPartsFragmentDoc = gql`
  fragment CourseSnapshotArtifactAuthorParts on ArtifactAuthor {
    id
    name
    slug
    avatarPhoto {
      ...ImageFragment
    }
    position
    company {
      id
      name
      logo {
        ...ImageFragment
      }
      slug
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CourseSnapshotArtifactPartsFragmentDoc = gql`
  fragment CourseSnapshotArtifactParts on Artifact {
    id
    title
    slug
    thumbnailPhoto
    authors {
      ...CourseSnapshotArtifactAuthorParts
    }
  }
  ${CourseSnapshotArtifactAuthorPartsFragmentDoc}
`
export const HostLegacyCourseCardPartsFragmentDoc = gql`
  fragment HostLegacyCourseCardParts on SanityHost {
    id
    hostName
    hostPicture {
      imageAlt
      imageUrl
    }
  }
`
export const CourseSnapshotMarketingCoursesPartsFragmentDoc = gql`
  fragment CourseSnapshotMarketingCoursesParts on MarketingCourse {
    id
    slug
    cmsProgramSlug
    startDate
    subtitle
    title
    legacyProgramId
    bookmarkId
    duration
    hosts {
      ...HostLegacyCourseCardParts
    }
    course {
      id
      title
      shortDescription
      slug
      popularWithRoles
      bookmarkId
      creators {
        id
        name
        avatarPhoto {
          ...ImageFragment
        }
      }
    }
    courseType
    creators {
      id
      role
      expert {
        id
        name
        avatarPhoto {
          ...ImageFragment
        }
      }
    }
  }
  ${HostLegacyCourseCardPartsFragmentDoc}
  ${ImageFragmentFragmentDoc}
`
export const ProgramIndexUserCourseSessionFieldsFragmentDoc = gql`
  fragment ProgramIndexUserCourseSessionFields on CourseSession {
    id
    courseSlug
    courseTitle
    overallDuration
    startsAt
    endsAt
    isPast
  }
`
export const LiveCourseUserCourseFragmentDoc = gql`
  fragment LiveCourseUserCourse on UserCourse {
    id
    courseSession {
      ...ProgramIndexUserCourseSessionFields
    }
  }
  ${ProgramIndexUserCourseSessionFieldsFragmentDoc}
`
export const CoursesPageCurrentUserPartsFragmentDoc = gql`
  fragment CoursesPageCurrentUserParts on User {
    id
    is {
      member
    }
    userCourses {
      all {
        ...LiveCourseUserCourse
      }
    }
  }
  ${LiveCourseUserCourseFragmentDoc}
`
export const CoursesPageActiveCourseSessionPartsFragmentDoc = gql`
  fragment CoursesPageActiveCourseSessionParts on CourseSession {
    courseSlug
    hasStarted
    hosts {
      id
      name
      slug
      avatarPhoto {
        ...ImageFragment
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const CohortPostCardUserPartsFragmentDoc = gql`
  fragment CohortPostCardUserParts on User {
    id
    is {
      collective
      staff
    }
  }
`
export const CohortsPageAccessUserPartsFragmentDoc = gql`
  fragment CohortsPageAccessUserParts on User {
    cohorts {
      all {
        id
        cohort {
          id
          slug
        }
      }
    }
    is {
      collective
      staff
    }
  }
`
export const BaseUserFragmentDoc = gql`
  fragment BaseUser on User {
    id
    fullName
    firstName
    lastName
    timezone
    forceFlag
  }
`
export const TocUserFragmentDoc = gql`
  fragment TocUser on User {
    id
    accessPolicyKind
    hasApplied
    numSectionBookmarks
    is {
      premember
      paidMember
      planManager
    }
  }
`
export const ContentCardUserFragmentDoc = gql`
  fragment ContentCardUser on User {
    id
    accessPolicyKind
    is {
      premember
      paidMember
      planManager
      member
    }
  }
`
export const CurrentUserUserCoursePartsFragmentDoc = gql`
  fragment CurrentUserUserCourseParts on UserCourse {
    id
    hasDismissedPostCourseCallout
    course {
      id
      title
      slug
      completionPercentage
    }
    courseSession {
      id
      startsAt
      endsAt
    }
  }
`
export const UserProgramPartsFragmentDoc = gql`
  fragment UserProgramParts on UserProgram {
    id
    enrolled
    progress
  }
`
export const CurrentUserPartsFragmentDoc = gql`
  fragment CurrentUserParts on User {
    id
    ...BaseUser
    ...TocUser
    ...ContentCardUser
    hasApplied
    hasMemberAccess
    hasContentInProgressOrComplete
    numSectionBookmarks
    accessPolicyKind
    shouldSeeHomepageCoursesSection
    shouldSeeTrialMessaging
    hasSeenCollectionsPrompt
    hasTeam
    externalId
    preferredCourseView
    profile {
      avatarUrl
      avatarXSmallUrl: avatarUrl(size: "xsmall")
      firstName
      hasBadge
      kind
      timezone
    }
    cohorts {
      all {
        id
        ...CurrentUserUserCohortParts
      }
      current {
        id
        ...CurrentUserUserCohortParts
      }
      latestCurrent {
        id
        ...CurrentUserUserCohortParts
      }
      previous {
        id
        ...CurrentUserUserCohortParts
      }
    }
    userCourses {
      current {
        id
        ...CurrentUserUserCourseParts
      }
      upcoming {
        id
        ...CurrentUserUserCourseParts
      }
      previous {
        id
        ...CurrentUserUserCourseParts
      }
      last {
        id
        ...CurrentUserUserCourseParts
      }
    }
    eventSpeakerCohortsForActiveSeasons {
      id
      ...CohortLinksParts
    }
    hostCohortsForActiveSeasons {
      id
      ...CohortLinksParts
    }
    contact {
      allEmails
      primaryEmail
      projectEmail
    }
    companyName
    slackUserId
    role
    joinSlackHref
    jobFunction
    onboarded
    is {
      subscriptionOwner
      accepted
      admin
      accountCreated
      creator
      collective
      enrolledInLiveProgram
      enrolledInEnrollmentSeason
      enrolledInActiveCourse
      freeUser
      expired
      member
      paidMember
      planManager
      staff
      teamSubscriptionOwner
      banned
      copyHold
      noAccess
      provisionedByScim
      trialing
      eligibleForTrial
    }
    can {
      accessAllCohortEventReplays
      pay
      onboard
      enrollInCohort
      linkToSearchResults
      manageMyTeam
      viewActivity
      viewActivitySidebar
      viewCollectionsIndex
      viewCohortIndex
      viewConceptsIndex
      viewConceptsProjectsSidebar
      viewDiscussionIndex
      viewEvent
      viewEventsIndex
      viewLearningGoals
      viewMemberIndex
      viewMemberProfile
      viewPost
      viewPrograms
      viewProjectsIndex
      viewSearchTabs
      viewSidebarGroups
      viewProjectsAndConcepts
      viewProgramsDashboard
      viewSubscriptionSummary
      viewBilling
      viewPurchaseFlow
      viewCohortHistory
      viewManageSeats
      viewOrg
    }
    activeProgram {
      ...UserProgramParts
    }
    preference {
      skipFeedback
    }
    allowDirectMessages
    networkFocus
    networkOnboarded
    networkRecommendations
    trialStatus
  }
  ${BaseUserFragmentDoc}
  ${TocUserFragmentDoc}
  ${ContentCardUserFragmentDoc}
  ${CurrentUserUserCohortPartsFragmentDoc}
  ${CurrentUserUserCoursePartsFragmentDoc}
  ${CohortLinksPartsFragmentDoc}
  ${UserProgramPartsFragmentDoc}
`
export const AvailableTeamSubscriptionMemberFragmentDoc = gql`
  fragment AvailableTeamSubscriptionMember on SubscriptionMember {
    id
    user {
      id
      avatarUrl
      fullName
    }
  }
`
export const AvailableTeamSeatFragmentDoc = gql`
  fragment AvailableTeamSeat on Seat {
    id
    subscriptionMember {
      ...AvailableTeamSubscriptionMember
    }
  }
  ${AvailableTeamSubscriptionMemberFragmentDoc}
`
export const AvailableTeamSubscriptionJoinRequestFragmentDoc = gql`
  fragment AvailableTeamSubscriptionJoinRequest on SubscriptionJoinRequest {
    id
    createdAt
    status
  }
`
export const AvailableTeamSubscriptionFragmentDoc = gql`
  fragment AvailableTeamSubscription on Subscription {
    id
    teamName
    logoUrl
    domainName
    isTeam
    externalId
    user {
      id
      fullName
    }
    seats {
      ...AvailableTeamSeat
    }
    userJoinRequests {
      ...AvailableTeamSubscriptionJoinRequest
    }
  }
  ${AvailableTeamSeatFragmentDoc}
  ${AvailableTeamSubscriptionJoinRequestFragmentDoc}
`
export const CohortRsvpTimeDataPartsFragmentDoc = gql`
  fragment CohortRsvpTimeDataParts on BatchRsvpTimeData {
    ids
    startsAtUtc
    endsAtUtc
    timeGrouping
  }
`
export const CohortFrontDoorNextPartsFragmentDoc = gql`
  fragment CohortFrontDoorNextParts on UserCohort {
    id
    hasClickedMeetCohortParticipantsCta
    hasClickedShareSnapshotCta
    hasSignedStatementOfCommitment
    showEventRsvpModal
    hasPostedWelcome
    timeGroupingSelected
    snapshot {
      id
      token
      released
    }
    cohort {
      id
      postCohort
      hasEnded
      startsAt
      slackChannelId
      cmsProgram {
        id
        code
        name
      }
      batchRsvpTimeData {
        ...CohortRsvpTimeDataParts
      }
      season {
        id
        name
        year
        npsSurveyLink
      }
      thisWeek {
        id
        startsAt
        endsAt
        events {
          id
          ...CohortEventCardParts
        }
        nextModule {
          estimatedTime
          href
          id
          name
          numBookmarks
          numLessons
          programCode
          programIconImageUrl
          progressPercent
          shortDescription
          publishAt
          published
        }
      }
      nextWeek {
        id
        events {
          id
          ...CohortEventCardParts
        }
      }
      scheduledWeeks {
        id
        startsAt
      }
    }
  }
  ${CohortRsvpTimeDataPartsFragmentDoc}
  ${CohortEventCardPartsFragmentDoc}
`
export const CohortFrontDoorPartsFragmentDoc = gql`
  fragment CohortFrontDoorParts on UserCohort {
    id
    canBeUpdated
    ...CohortFrontDoorNextParts
    cohort {
      id
      postKickoff
      postCoreWeeksEnrollmentIsOpen
      slug
      startsAt
      weekCount
      kickoffEvents {
        id
        startsAtUtc
      }
      scheduledWeeks {
        id
        bonus
        postCohort
        startsAt
        endsAt
      }
      events {
        id
        startsAtUtc
      }
      season {
        id
        name
        year
        npsSurveyLink
      }
      hosts {
        id
        name
        thumbnailUrl
        previousCompanies
        title
      }
      cmsProgram {
        id
        code
        name
        heroImageUrl
        programImage
        synopsis
        lessonCount
      }
      nextWeek {
        id
        events {
          id
          ...CohortEventCardParts
        }
      }
      thisWeek {
        id
        startsAt
        endsAt
        events {
          id
          ...CohortEventCardParts
        }
        nextModule {
          estimatedTime
          href
          id
          name
          numBookmarks
          numLessons
          programCode
          programIconImageUrl
          progressPercent
          shortDescription
          publishAt
          published
          clips {
            id
            title
            duration
            slug
            thumbnailUrl
            summary
            summaryWistiaCode
            hasBeenViewedByCurrentUser
            users {
              id
              avatarUrl
              fullName
              role
              companyName
              profile {
                profileUrl
              }
            }
          }
        }
      }
    }
  }
  ${CohortFrontDoorNextPartsFragmentDoc}
  ${CohortEventCardPartsFragmentDoc}
`
export const UserCohortsPartsFragmentDoc = gql`
  fragment UserCohortsParts on User {
    cohorts {
      latestCurrent {
        id
        ...CohortFrontDoorParts
      }
      current {
        cohort {
          id
          postKickoff
          hasEnded
          postCohort
        }
      }
    }
  }
  ${CohortFrontDoorPartsFragmentDoc}
`
export const ProgramSelectorHostPartsFragmentDoc = gql`
  fragment ProgramSelectorHostParts on Host {
    id
    name
    title
    bio
    thumbnailUrl
    previousCompanies
  }
`
export const ProgramSelectorScheduledWeekPartsFragmentDoc = gql`
  fragment ProgramSelectorScheduledWeekParts on ScheduledWeek {
    id
    core
    description
    title
    bonus
    postCohort
    startsAt
    endsAt
    events {
      startsAtUtc
      endsAtUtc
      kind
    }
    numLessons
    numModules
  }
`
export const ProgramSelectorProgramPartsFragmentDoc = gql`
  fragment ProgramSelectorProgramParts on CmsProgram {
    id
    iconImageUrl
    heroImageUrl
    launched
    notLaunched
    enrolled
    programImage
    name
    recommendationRanking
    whoItsFor
    slug
    byTheEnd
    synopsis
    categoryNames
    canEnroll
    launchAt
    cohort {
      id
      endsAt
      startsAt
      eventTimes {
        firstStartDate
        firstEndDate
        count
      }
      durationInWeeks
      weekCount
      hosts {
        ...ProgramSelectorHostParts
      }
      programCollaborators {
        ...ProgramSelectorHostParts
      }
      scheduledWeeks {
        ...ProgramSelectorScheduledWeekParts
      }
    }
  }
  ${ProgramSelectorHostPartsFragmentDoc}
  ${ProgramSelectorScheduledWeekPartsFragmentDoc}
`
export const TestimonialFieldsFragmentDoc = gql`
  fragment TestimonialFields on Testimonial {
    id
    copy
    name
    role
    thumbnailUrl
  }
`
export const ProgramSelectorCurrentUserFragmentDoc = gql`
  fragment ProgramSelectorCurrentUser on User {
    id
    timezone
    is {
      alumni
      paidMember
      enrolledInActiveCourse
      enrolledInLiveProgram
      enrolledInEnrollmentSeason
      member
    }
    can {
      enrollInCohort
    }
    profile {
      aboutMeText
      allowDirectMessages
      areasOfExpertiseTags
      askMeAboutText
      avatarUrl
      businessModelTags
      communityInterestTags
      communityInterestText
      companyAudienceTags
      companyName
      companyProductText
      firstName
      lastName
      learningGoalsText
      linkedin
      location
      previousCompanies {
        id
        company {
          id
          name
        }
      }
      responseTime
      onboardingStep
      role
      timezone
      twitter
    }
    pricing {
      term
    }
    shouldSeeTrialMessaging
    subscriptions {
      active {
        expiresAt
        id
        isCanceled
        numSeats
        status
        stripeSubscription {
          id
        }
      }
      trial {
        id
        status
        daysLeftInTrial
      }
    }
    cohorts {
      previous {
        id
        programId
        programName
        season
        canBeUpdated
      }
      latestCurrent {
        id
        programId
        programName
        season
        seasonId
        canBeUpdated
      }
      current {
        id
        programId
        programName
        season
        seasonId
        canBeUpdated
        cohort {
          id
          eventTimes {
            count
            firstStartDate
          }
        }
      }
    }
    recommendedPrograms {
      ...ProgramSelectorProgramParts
      testimonials {
        ...TestimonialFields
      }
    }
  }
  ${ProgramSelectorProgramPartsFragmentDoc}
  ${TestimonialFieldsFragmentDoc}
`
export const ProgramSelectorProgramSparsePartsFragmentDoc = gql`
  fragment ProgramSelectorProgramSparseParts on CmsProgram {
    id
    iconImageUrl
    heroImageUrl
    launched
    notLaunched
    enrolled
    programImage
    name
    whoItsFor
    slug
    byTheEnd
    synopsis
    categoryNames
    canEnroll
    launchAt
  }
`
export const OnboardingModalCurrentUserPartsFragmentDoc = gql`
  fragment OnboardingModalCurrentUserParts on User {
    id
    timezone
    hasBadge
    role
    firstName
    lastName
    linkedin
    twitter
    avatarUrl
    companyName
    jobFunction
    jobLevel
    onboarded
    previousCompanies {
      id
      company {
        id
        name
      }
    }
    is {
      badgeholder
      alumni
      paidMember
      enrolledInLiveProgram
      enrolledInEnrollmentSeason
      member
      subscriptionOwner
      teamSubscriptionOwner
    }
    can {
      enrollInCohort
    }
    profile {
      aboutMeText
      allowDirectMessages
      areasOfExpertiseTags
      askMeAboutText
      avatarUrl
      businessModelTags
      communityInterestTags
      communityInterestText
      companyAudienceTags
      companyName
      companyProductText
      firstName
      lastName
      learningGoalsText
      linkedin
      location
      previousCompanies {
        id
        company {
          id
          name
        }
      }
      responseTime
      onboardingStep
      role
      timezone
      twitter
    }
    pricing {
      term
    }
    shouldSeeTrialMessaging
    subscriptions {
      active {
        expiresAt
        id
        isCanceled
        numSeats
        numSeatsAvailable
        planName
        status
        stripeSubscription {
          id
        }
      }
      trial {
        id
        status
        daysLeftInTrial
      }
    }
    cohorts {
      current {
        id
        seasonId
        canBeUpdated
        programId
      }
    }
    recommendedPrograms {
      ...ProgramSelectorProgramSparseParts
      testimonials {
        ...TestimonialFields
      }
    }
  }
  ${ProgramSelectorProgramSparsePartsFragmentDoc}
  ${TestimonialFieldsFragmentDoc}
`
export const UserProfilePartsFragmentDoc = gql`
  fragment UserProfileParts on UserProfile {
    fullName
    companyName
    avatarUrl
    role
    twitter
    linkedin
    hasBadge
    kind
    location
    onboardingStep
    profileUrl
    status
  }
`
export const SpeakerPartsFragmentDoc = gql`
  fragment SpeakerParts on EventSpeaker {
    id
    kind
    user {
      id
      profile {
        ...UserProfileParts
      }
    }
  }
  ${UserProfilePartsFragmentDoc}
`
export const EventExpertHostsPartsFragmentDoc = gql`
  fragment EventExpertHostsParts on EventSpeaker {
    id
    ...SpeakerParts
    hostedEventsCount
  }
  ${SpeakerPartsFragmentDoc}
`
export const EventAttendeePartsFragmentDoc = gql`
  fragment EventAttendeeParts on EventAttendee {
    id
    hasBadge
    kind
    status
    user {
      id
      profile {
        ...UserProfileParts
      }
    }
  }
  ${UserProfilePartsFragmentDoc}
`
export const EventCohortsFragmentDoc = gql`
  fragment EventCohorts on Cohort {
    id
    slug
    season {
      showCohortViewerAt
    }
  }
`
export const EventPartsFragmentDoc = gql`
  fragment EventParts on Event {
    id
    name
    attendees(first: 4, hasAvatar: true) {
      edges {
        node {
          id
          ...EventAttendeeParts
        }
      }
    }
    slug
    kind
    past
    featuredImageUrl
    summaryWistiaCodeOptional: summaryWistiaCode
    willBeRecorded
    timezone
    startsAtUtc
    endsAtUtc
    uuid
    attendeesCount
    attendingStatus
    zoomLink
    description
    lastViewedAt
    isPublic
    cohorts {
      ...EventCohorts
    }
    cmsProgramIdOptional: cmsProgramId
    cmsProgramOptional: cmsProgram {
      id
      name
    }
    speakers {
      id
      ...SpeakerParts
    }
    hosts {
      id
      ...SpeakerParts
    }
    featuredGuests {
      id
      ...SpeakerParts
    }
  }
  ${EventAttendeePartsFragmentDoc}
  ${EventCohortsFragmentDoc}
  ${SpeakerPartsFragmentDoc}
`
export const EventCountdownPageAttendeePartsFragmentDoc = gql`
  fragment EventCountdownPageAttendeeParts on EventAttendee {
    id
    hasBadge
    kind
    status
    user {
      id
      profile {
        fullName
        avatarUrl
      }
    }
  }
`
export const EventCountdownPageEventPartsFragmentDoc = gql`
  fragment EventCountdownPageEventParts on Event {
    id
    startsAtUtc
    endsAtUtc
    zoomLink
    slug
    timeUntil
    kind
    name
    prep
    attendeesCount
    isPublic
    rsvp {
      id
    }
    cohorts {
      id
      slug
      season {
        showCohortViewerAt
      }
    }
    attendees(first: 4, hasAvatar: true) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...EventCountdownPageAttendeeParts
        }
      }
    }
  }
  ${EventCountdownPageAttendeePartsFragmentDoc}
`
export const FilteredUserSearchFieldsFragmentDoc = gql`
  fragment FilteredUserSearchFields on FilteredUserSearch {
    id
    avatarUrl
    companyAudience
    companyName
    createdAt
    fullName
    hasBadge
    isExpired
    kind
    location
    mostRecentProgram
    role
    status
    slug
  }
`
export const PaginationFieldsFragmentDoc = gql`
  fragment PaginationFields on Pagination {
    totalEntries
    nextPage
  }
`
export const MembersUserCohortPartFragmentDoc = gql`
  fragment MembersUserCohortPart on UserCohort {
    cohort {
      id
      cmsProgram {
        name
      }
      season {
        name
        year
      }
    }
  }
`
export const AvatarFragmentFragmentDoc = gql`
  fragment AvatarFragment on User {
    id
    profile {
      avatarUrl(size: "xsmall")
      hasBadge
      kind
    }
  }
`
export const UserInfoProfilePartsFragmentDoc = gql`
  fragment UserInfoProfileParts on UserProfile {
    companyName
    role
    previousCompanies {
      id
      company {
        id
        name
      }
    }
    location
    firstName
    lastName
    linkedin
    twitter
    kind
  }
`
export const UserInfoPartsFragmentDoc = gql`
  fragment UserInfoParts on User {
    id
    ...AvatarFragment
    is {
      badgeholder
    }
    profile {
      ...UserInfoProfileParts
    }
  }
  ${AvatarFragmentFragmentDoc}
  ${UserInfoProfilePartsFragmentDoc}
`
export const UserHeadingProfilePartsFragmentDoc = gql`
  fragment UserHeadingProfileParts on UserProfile {
    status
    hasBadge
    role
    companyName
    kind
    avatarUrl
    slug
    location
    previousCompanies {
      id
      company {
        id
        name
      }
    }
    fullName
  }
`
export const UpcomingSeasonEnrollmentFieldsFragmentDoc = gql`
  fragment UpcomingSeasonEnrollmentFields on Season {
    id
    activeAt
    endsAt
    enrollmentsCloseAt
    enrollmentsOpenAt
    name
    nextSeasonName
    npsSurveyLink
    openForEnrollment
    startsAt
    year
  }
`
export const MemberStackUserFragmentDoc = gql`
  fragment MemberStackUser on User {
    id
    slug
    fullName
    kind
    avatarUrl
    hasBadge
    isCurrentUser
  }
`
export const GroupsListGroupTopicFragmentDoc = gql`
  fragment GroupsListGroupTopic on PostTopic {
    id
    kind
    slug
    title
    topicId
    groupId
  }
`
export const GroupsListGroupIndustryFragmentDoc = gql`
  fragment GroupsListGroupIndustry on Industry {
    id
    name
    slug
  }
`
export const GroupsListGroupCategoryFragmentDoc = gql`
  fragment GroupsListGroupCategory on GroupCategory {
    id
    category
    description
  }
`
export const GroupsListGroupFragmentDoc = gql`
  fragment GroupsListGroup on Group {
    id
    title
    slug
    userIsMember
    membersCount
    memberStack {
      id
      ...MemberStackUser
    }
    topics {
      id
      ...GroupsListGroupTopic
    }
    populatedTopics {
      id
      ...GroupsListGroupTopic
    }
    industries {
      id
      ...GroupsListGroupIndustry
    }
    populatedIndustries {
      id
      ...GroupsListGroupIndustry
    }
    groupCategory {
      id
      ...GroupsListGroupCategory
    }
  }
  ${MemberStackUserFragmentDoc}
  ${GroupsListGroupTopicFragmentDoc}
  ${GroupsListGroupIndustryFragmentDoc}
  ${GroupsListGroupCategoryFragmentDoc}
`
export const UnitFragmentFragmentDoc = gql`
  fragment UnitFragment on Unit {
    id
    title
    whatYouWillDo
    estimatedTimeInMinutes
    stepsCount
    slug
    heroImage {
      imageUrl
      imageAlt
      aspectRatio
    }
    tags {
      id
      title
    }
    authors {
      id
      slug
      name
      avatarPhoto {
        ...ImageFragment
      }
    }
    previewable
    contentBookmarkId
    contentIsInMySavedItems
    narrationFileUrl
  }
  ${ImageFragmentFragmentDoc}
`
export const ConceptCurrentUserPartsFragmentDoc = gql`
  fragment ConceptCurrentUserParts on User {
    id
    accessPolicyKind
    shouldSeeTrialMessaging
    userProgram {
      id
      ...UserProgramParts
    }
    can {
      viewConceptsIndex
      viewPurchaseFlow
    }
    is {
      premember
      member
    }
  }
  ${UserProgramPartsFragmentDoc}
`
export const EventCurrentUserPartsFragmentDoc = gql`
  fragment EventCurrentUserParts on User {
    id
    timezone
    accessPolicyKind
    can {
      viewEvent
      accessAllCohortEventReplays
    }
    profile {
      avatarUrl
      firstName
      hasBadge
      kind
      timezone
    }
    cohorts {
      all {
        cohort {
          id
          slug
          season {
            showCohortViewerAt
          }
        }
      }
      current {
        cohort {
          id
          cmsProgram {
            id
            name
            code
          }
        }
      }
      previous {
        cohort {
          id
          cmsProgram {
            id
            name
            code
          }
        }
      }
    }
  }
`
export const ProgramCardUserPartsFragmentDoc = gql`
  fragment ProgramCardUserParts on User {
    id
    is {
      member
      paidMember
    }
    can {
      enrollInCohort
    }
    accessPolicyKind
    shouldSeeTrialMessaging
    timezone
  }
`
export const ClassicsCurrentUserPartsFragmentDoc = gql`
  fragment ClassicsCurrentUserParts on User {
    id
    ...ConceptCurrentUserParts
    ...EventCurrentUserParts
    ...ProgramCardUserParts
  }
  ${ConceptCurrentUserPartsFragmentDoc}
  ${EventCurrentUserPartsFragmentDoc}
  ${ProgramCardUserPartsFragmentDoc}
`
export const CohortCurrentUserPartsFragmentDoc = gql`
  fragment CohortCurrentUserParts on User {
    id
    joinSlackHref
    cohorts {
      current {
        id
        ...CohortFrontDoorParts
      }
      latestCurrent {
        id
        canBeUpdated
        ...CohortFrontDoorParts
      }
    }
    shouldShowNpsSurvey
    slackUserId
    timezone
  }
  ${CohortFrontDoorPartsFragmentDoc}
`
export const ProgramCardTopSectionPartsFragmentDoc = gql`
  fragment ProgramCardTopSectionParts on CmsProgram {
    id
    enrolled
    upcoming
    name
    programImage
    iconImageUrl
    heroImageUrl
    showNewBadge
    description
    topic
  }
`
export const ProgramCardPartsFragmentDoc = gql`
  fragment ProgramCardParts on CmsProgram {
    id
    ...ProgramCardTopSectionParts
    name
    contentBookmarkId
    contentIsInMySavedItems
    slug
    lessonCount
    iconImageUrl
    numBookmarks
    progressPercent
    purchased
    programImage
    description
    upcoming
    enrolled
    canEnroll
    deprecatedAt
    collaborators {
      id
      name
      avatarUrl: thumbnailUrl
    }
  }
  ${ProgramCardTopSectionPartsFragmentDoc}
`
export const HomePageUserPartsFragmentDoc = gql`
  fragment HomePageUserParts on User {
    id
    accessPolicyKind
    activeSubscriptionMembership {
      id
      subscription {
        id
        teamName
        userId
        teamMembers {
          users {
            id
            avatarUrl
            profile {
              avatarUrl
            }
          }
        }
      }
    }
    bookmarkFolders(limit: $limit) {
      ...BookmarkFolderParts
    }
    hasTeam
    hideSlackBanner
    seenSlackBannerCount
    shouldSeeTrialMessaging
    onboarded
    avatarUrl
    is {
      alumni
      accountCreated
      enrolledInActiveCourse
      enrolledInEnrollmentSeason
      inPostCoursePeriod
      member
      paidMember
      premember
    }
    can {
      accessAllCohortEventReplays
      viewEvent
      viewPost
      viewProjectsIndex
      viewCollectionsIndex
      viewConceptsIndex
      viewPurchaseFlow
      enrollInCohort
    }
    profile {
      completionPercentage
      avatarUrl
      firstName
      hasBadge
      kind
      timezone
    }
    userProgram {
      id
      ...UserProgramParts
    }
    programProgress {
      progress
    }
    enrolledPrograms {
      id
      ...ProgramCardParts
    }
    inPostCohort
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${UserProgramPartsFragmentDoc}
  ${ProgramCardPartsFragmentDoc}
`
export const IndustryFieldsFragmentDoc = gql`
  fragment IndustryFields on Industry {
    id
    name
    slug
  }
`
export const PaymentRecordFieldsFragmentDoc = gql`
  fragment PaymentRecordFields on PaymentRecord {
    id
    description
    status
    programName
    amount
    companyName
    fullName
  }
`
export const InvoiceMemberFieldsFragmentDoc = gql`
  fragment InvoiceMemberFields on InvoiceMember {
    id
    email
    fullName
  }
`
export const BillingInformationFragmentDoc = gql`
  fragment BillingInformation on BillingInformation {
    address {
      city
      country
      line1
      line2
      postalCode
      state
    }
    name
    taxIds
  }
`
export const TaxAmountFragmentDoc = gql`
  fragment TaxAmount on StripeTaxAmount {
    amount
    taxRateId
    taxRate {
      country
      displayName
      jurisdiction
      percentage
      state
    }
  }
`
export const StripeLineFragmentDoc = gql`
  fragment StripeLine on StripeInvoiceLineItem {
    id
    amount
    amountExcludingTax
    description
    quantity
    taxAmount
    taxAmounts {
      amount
      taxRateId
    }
    taxRate
  }
`
export const StripeInvoiceFragmentDoc = gql`
  fragment StripeInvoice on StripeInvoice {
    id
    date
    dueDate
    postPaymentCreditNotesAmount
    subtotal
    subtotalExcludingTax
    tax
    total
    totalExcludingTax
    totalTaxAmounts {
      ...TaxAmount
    }
    lineItems {
      ...StripeLine
    }
  }
  ${TaxAmountFragmentDoc}
  ${StripeLineFragmentDoc}
`
export const InvoiceFieldsFragmentDoc = gql`
  fragment InvoiceFields on Invoice {
    id
    companyAddress
    createdAt
    extraInformation
    internalPoNumber
    paymentRecord {
      id
      ...PaymentRecordFields
    }
    members {
      id
      ...InvoiceMemberFields
    }
    billingInformation {
      ...BillingInformation
    }
    stripeInvoice {
      id
      ...StripeInvoice
    }
  }
  ${PaymentRecordFieldsFragmentDoc}
  ${InvoiceMemberFieldsFragmentDoc}
  ${BillingInformationFragmentDoc}
  ${StripeInvoiceFragmentDoc}
`
export const LessonSectionPartsFragmentDoc = gql`
  fragment LessonSectionParts on CmsSection {
    id
    accessLevel
    contentType
    isUnit
    contentTypeIcon
    estimatedTime
    contentBookmarkId
    contentIsInMySavedItems
    heroImageUrl
    href
    name
    showNewBadge
    slug
    previewable
    parentId
    contentSubtype
    cmsProgramId
    lastViewedAt
    shortDescription
    cmsModule {
      id
      name
    }
    cmsProgram {
      id
      enrolled
      slug
      name
      collaborators {
        id
        name
        thumbnailUrl
      }
    }
  }
`
export const LessonParentSectionFragmentDoc = gql`
  fragment LessonParentSection on CmsSection {
    id
    slug
    ...LessonSectionParts
    children {
      id
      ...LessonSectionParts
    }
  }
  ${LessonSectionPartsFragmentDoc}
`
export const LessonTocCmsModulePartsFragmentDoc = gql`
  fragment LessonTocCmsModuleParts on CmsModule {
    id
    name
    cmsSections {
      id
      ...LessonParentSection
    }
  }
  ${LessonParentSectionFragmentDoc}
`
export const LessonTocCmsProgramPartsFragmentDoc = gql`
  fragment LessonTocCmsProgramParts on CmsProgram {
    id
    name
    slug
    noLessons
    launched
    releaseStartDate
    upcoming
    iconImageUrl
  }
`
export const LessonTocUserProgramPartsFragmentDoc = gql`
  fragment LessonTocUserProgramParts on UserProgram {
    id
    progress
  }
`
export const LessonTocExpertUserPartsFragmentDoc = gql`
  fragment LessonTocExpertUserParts on User {
    id
    ...ExpertUserParts
  }
  ${ExpertUserPartsFragmentDoc}
`
export const LessonHeaderSameActiveUserCohortPartsFragmentDoc = gql`
  fragment LessonHeaderSameActiveUserCohortParts on UserCohort {
    id
    cohort {
      id
      slug
    }
  }
`
export const LessonTocPartsFragmentDoc = gql`
  fragment LessonTocParts on LessonViewer {
    cmsModule {
      id
      ...LessonTocCmsModuleParts
    }
    cmsProgram {
      id
      ...LessonTocCmsProgramParts
    }
    cmsSection {
      id
      previewable
      nextSectionNameAndPath
    }
    userProgram {
      id
      ...LessonTocUserProgramParts
    }
    expertUsers {
      id
      ...LessonTocExpertUserParts
    }
    sameActiveUserCohort {
      id
      ...LessonHeaderSameActiveUserCohortParts
    }
  }
  ${LessonTocCmsModulePartsFragmentDoc}
  ${LessonTocCmsProgramPartsFragmentDoc}
  ${LessonTocUserProgramPartsFragmentDoc}
  ${LessonTocExpertUserPartsFragmentDoc}
  ${LessonHeaderSameActiveUserCohortPartsFragmentDoc}
`
export const LessonFooterCmsProgramPartsFragmentDoc = gql`
  fragment LessonFooterCmsProgramParts on CmsProgram {
    id
    slug
    upcoming
  }
`
export const LessonFooterCmsSectionPartsFragmentDoc = gql`
  fragment LessonFooterCmsSectionParts on CmsSection {
    id
    nextSectionNameAndPath
    nextSectionLearningCmsSectionPath
    isUnit
    isResource
    nextSection {
      id
      countsTowardsCompletion
      estimatedTime
      heroImageUrl
      position
      name
      href
    }
  }
`
export const LessonFooterCmsModulePartsFragmentDoc = gql`
  fragment LessonFooterCmsModuleParts on CmsModule {
    id
  }
`
export const LessonFooterPartsFragmentDoc = gql`
  fragment LessonFooterParts on LessonViewer {
    cmsProgram {
      id
      ...LessonFooterCmsProgramParts
    }
    cmsSection {
      id
      ...LessonFooterCmsSectionParts
    }
    cmsModule {
      id
      ...LessonFooterCmsModuleParts
    }
    sameActiveUserCohort {
      id
      ...LessonHeaderSameActiveUserCohortParts
    }
  }
  ${LessonFooterCmsProgramPartsFragmentDoc}
  ${LessonFooterCmsSectionPartsFragmentDoc}
  ${LessonFooterCmsModulePartsFragmentDoc}
  ${LessonHeaderSameActiveUserCohortPartsFragmentDoc}
`
export const LessonHeaderCmsModulePartsFragmentDoc = gql`
  fragment LessonHeaderCmsModuleParts on CmsModule {
    id
    name
  }
`
export const LessonHeaderCmsProgramPartsFragmentDoc = gql`
  fragment LessonHeaderCmsProgramParts on CmsProgram {
    id
    code
    name
    slug
    upcoming
    iconImageUrl
  }
`
export const LessonHeaderUserProgramPartsFragmentDoc = gql`
  fragment LessonHeaderUserProgramParts on UserProgram {
    id
    kind
    participatingKinds
  }
`
export const LessonContentCmsModulePartsFragmentDoc = gql`
  fragment LessonContentCmsModuleParts on CmsModule {
    id
    name
  }
`
export const LessonContentCmsSectionPartsFragmentDoc = gql`
  fragment LessonContentCmsSectionParts on CmsSection {
    id
    truncatedDescriptionHtml
  }
`
export const LessonContentPartsFragmentDoc = gql`
  fragment LessonContentParts on LessonViewer {
    cmsModule {
      id
      ...LessonContentCmsModuleParts
    }
    cmsSection {
      id
      ...LessonContentCmsSectionParts
    }
  }
  ${LessonContentCmsModulePartsFragmentDoc}
  ${LessonContentCmsSectionPartsFragmentDoc}
`
export const LessonHeaderCmsSectionPartsFragmentDoc = gql`
  fragment LessonHeaderCmsSectionParts on CmsSection {
    id
    name
    contentType
    position
    slug
    contentTypeIcon
    descriptionText
    heroImageUrl
    shortDescription
    estimatedTime
    nextSectionNameAndPath
    nextSectionLearningCmsSectionPath
    previewable
    contentBookmarkId
    contentIsInMySavedItems
    isUnit
    parent {
      id
      name
      slug
    }
  }
`
export const LessonTypePartsFragmentDoc = gql`
  fragment LessonTypeParts on CmsSection {
    id
    name
    contentTypeIcon
    estimatedTime
    heroImageUrl
    shortDescription
    href
    cmsModuleId
    cmsProgramId
    showNewBadge
    cmsProgram {
      id
      programImage
      notLaunched
      enrolled
      slug
      name
      progressPercent
    }
    previewable
  }
`
export const BookmarkableWistiaPlayerBookmarkFragmentDoc = gql`
  fragment BookmarkableWistiaPlayerBookmark on Bookmark {
    id
    noteBody
    referenceImageUrl
    seconds
    type
  }
`
export const LessonViewerCmsSectionPartsFragmentDoc = gql`
  fragment LessonViewerCmsSectionParts on CmsSection {
    ...LessonHeaderCmsSectionParts
    ...LessonFooterCmsSectionParts
    ...LessonContentCmsSectionParts
    ...LessonTypeParts
    wistiaCode
    unlimitedAccess
    videoBookmarks: bookmarks(type: VideoBookmark) {
      id
      ...BookmarkableWistiaPlayerBookmark
    }
    topic {
      id
      title
    }
    groups(last: 1) {
      edges {
        node {
          id
        }
      }
    }
    countsTowardsCompletion
    blockedDescriptionHtmlSafe
    descriptionHtmlSafe
    summaryHtmlSafe
    showFeedback
    currentUserSubmittedFeedback
    nextSection {
      id
      slug
      parent {
        slug
      }
      countsTowardsCompletion
      estimatedTime
      heroImageUrl
      position
      name
      href
    }
  }
  ${LessonHeaderCmsSectionPartsFragmentDoc}
  ${LessonFooterCmsSectionPartsFragmentDoc}
  ${LessonContentCmsSectionPartsFragmentDoc}
  ${LessonTypePartsFragmentDoc}
  ${BookmarkableWistiaPlayerBookmarkFragmentDoc}
`
export const CclCourseSessionEventPartsFragmentDoc = gql`
  fragment CclCourseSessionEventParts on CclSessionEvent {
    id
    startsAt
    endsAt
  }
`
export const CclCourseSessionExpertPartsFragmentDoc = gql`
  fragment CclCourseSessionExpertParts on CclExpert {
    id
    name
    avatarUrl
    slug
  }
`
export const CclCourseSessionPartsFragmentDoc = gql`
  fragment CclCourseSessionParts on CclCourseSession {
    id
    startsAt
    endsAt
    duration
    sourceId
    price
    isDuringEnrollmentPeriod
    isEnrollmentFull
    maxEnrollmentPaid
    numEnrolledPaid
    events {
      ...CclCourseSessionEventParts
    }
    experts {
      ...CclCourseSessionExpertParts
    }
    createdAt
  }
  ${CclCourseSessionEventPartsFragmentDoc}
  ${CclCourseSessionExpertPartsFragmentDoc}
`
export const CourseDetailEntityPreviewPartsFragmentDoc = gql`
  fragment CourseDetailEntityPreviewParts on CclCourse {
    id
    title
    slug
    sourceId
    staticId
  }
`
export const CclExpertCourseCardPartsFragmentDoc = gql`
  fragment CclExpertCourseCardParts on CclExpert {
    id
    name
    avatarUrl
  }
`
export const CclCourseSessionCourseDetailEnrollmentBadgesPartsFragmentDoc = gql`
  fragment CclCourseSessionCourseDetailEnrollmentBadgesParts on CclCourseSession {
    id
    startsAt
    isDuringEnrollmentPeriod
    isEnrollmentFull
    maxEnrollmentPaid
    numEnrolledPaid
    createdAt
  }
`
export const CclExpertFeaturedCourseCardPartsFragmentDoc = gql`
  fragment CclExpertFeaturedCourseCardParts on CclExpert {
    id
    name
    avatarUrl
    slug
  }
`
export const CclCourseSessionFeaturedCourseCardPartsFragmentDoc = gql`
  fragment CclCourseSessionFeaturedCourseCardParts on CclCourseSession {
    ...CclCourseSessionCourseDetailEnrollmentBadgesParts
    endsAt
    experts {
      ...CclExpertFeaturedCourseCardParts
    }
  }
  ${CclCourseSessionCourseDetailEnrollmentBadgesPartsFragmentDoc}
  ${CclExpertFeaturedCourseCardPartsFragmentDoc}
`
export const CclCourseFeaturedCourseCardPartsFragmentDoc = gql`
  fragment CclCourseFeaturedCourseCardParts on CclCourse {
    id
    title
    shortDescription
    slug
    creators {
      ...CclExpertCourseCardParts
    }
    upcomingSessions {
      ...CclCourseSessionFeaturedCourseCardParts
    }
    exampleCompanies {
      id
      wordmarkLogoUrl
      name
    }
  }
  ${CclExpertCourseCardPartsFragmentDoc}
  ${CclCourseSessionFeaturedCourseCardPartsFragmentDoc}
`
export const CclTopicFilterPartsFragmentDoc = gql`
  fragment CclTopicFilterParts on CclTopic {
    id
    title
    slug
  }
`
export const CclCourseSessionCourseCardPartsFragmentDoc = gql`
  fragment CclCourseSessionCourseCardParts on CclCourseSession {
    id
    startsAt
    endsAt
    duration
    experts {
      ...CclExpertCourseCardParts
    }
  }
  ${CclExpertCourseCardPartsFragmentDoc}
`
export const MarketingCourseCourseCardPartsFragmentDoc = gql`
  fragment MarketingCourseCourseCardParts on MarketingCourse {
    id
    title
    courseType
    bookmarkId
    startDate
    subtitle
    hosts {
      ...HostLegacyCourseCardParts
    }
    cclCourse {
      id
      title
      shortDescription
      completionPercentage
      slug
      staticId
      onDemand
      live
      topics {
        ...CclTopicFilterParts
      }
      creators {
        ...CclExpertCourseCardParts
      }
      upcomingSessions {
        ...CclCourseSessionCourseCardParts
      }
    }
    course {
      id
      title
      slug
      shortDescription
      bookmarkId
      creators {
        id
        name
        avatarPhoto {
          ...ImageFragment
        }
      }
    }
    slug
    cmsProgramSlug
    cmsProgram {
      id
      slug
      cclStaticCourseId
    }
    legacyProgramId
    cclTopics {
      ...CclTopicFilterParts
    }
    duration
    creators {
      id
      name
      expert {
        id
        name
        avatarPhoto {
          ...ImageFragment
        }
      }
    }
  }
  ${HostLegacyCourseCardPartsFragmentDoc}
  ${CclTopicFilterPartsFragmentDoc}
  ${CclExpertCourseCardPartsFragmentDoc}
  ${CclCourseSessionCourseCardPartsFragmentDoc}
  ${ImageFragmentFragmentDoc}
`
export const CtaFragmentFragmentDoc = gql`
  fragment CtaFragment on Cta {
    ctaText
    ctaLink
  }
`
export const BlogPostCategoryFragmentFragmentDoc = gql`
  fragment BlogPostCategoryFragment on BlogPostCategory {
    id
    name
    value
  }
`
export const BlogAuthorFragmentFragmentDoc = gql`
  fragment BlogAuthorFragment on ArtifactAuthor {
    id
    name
    shortBio
    slug
    avatarPhoto {
      ...ImageFragment
    }
  }
  ${ImageFragmentFragmentDoc}
`
export const MarketingFaqFragmentDoc = gql`
  fragment MarketingFaq on MarketingFaq {
    id
    question
    answer
  }
`
export const MarketingCourseStartDatePartsFragmentDoc = gql`
  fragment MarketingCourseStartDateParts on MarketingCourse {
    startDate
  }
`
export const PageSeoFragmentFragmentDoc = gql`
  fragment PageSeoFragment on PageSeo {
    metaDesc
    metaTitle
    publishDate
    authors
    shareDesc
    shareGraphic
    shareGraphicAlt
    shareTitle
    structuredDataSchema
    type
  }
`
export const MarketingCoursesIndexPagePartsFragmentDoc = gql`
  fragment MarketingCoursesIndexPageParts on CourseIndexPage {
    id
    title
    seo {
      ...PageSeoFragment
    }
    content {
      heading
      subheading
      ctaText
      ctaLink
      heroImage {
        ...ImageFragment
      }
      logos {
        ...ImageFragment
      }
      courseBenefits {
        id
        title
        description
        icon {
          ...ImageFragment
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`
export const MyCoursesUserProgramPartsFragmentDoc = gql`
  fragment MyCoursesUserProgramParts on UserProgram {
    id
    progressPercent
    updatedAt
    cmsProgram {
      id
      name
      synopsis
      slug
    }
  }
`
export const ArtifactBookmarkPartsFragmentDoc = gql`
  fragment ArtifactBookmarkParts on Bookmark {
    id
    basedOn
    noteBody
    sanityId
    type
    updatedAt
    createdAtInteger
    isWithinContent
    filedBookmarks {
      id
      bookmarkFolderId
    }
    numPeopleWhoBookmarked
  }
`
export const EventBookmarkPartsFragmentDoc = gql`
  fragment EventBookmarkParts on Bookmark {
    id
    basedOn
    noteBody
    sanityId
    eventId
    type
    updatedAt
    createdAtInteger
    isWithinContent
    filedBookmarks {
      id
      bookmarkFolderId
    }
    numPeopleWhoBookmarked
  }
`
export const CclCourseCourseCardPartsFragmentDoc = gql`
  fragment CclCourseCourseCardParts on CclCourse {
    id
    title
    shortDescription
    slug
    staticId
    cmsProgram {
      slug
    }
    onDemand
    completionPercentage
    live
    topics {
      ...CclTopicFilterParts
    }
    creators {
      ...CclExpertCourseCardParts
    }
    upcomingSessions {
      ...CclCourseSessionCourseCardParts
    }
  }
  ${CclTopicFilterPartsFragmentDoc}
  ${CclExpertCourseCardPartsFragmentDoc}
  ${CclCourseSessionCourseCardPartsFragmentDoc}
`
export const SharedCourseBookmarkPartsFragmentDoc = gql`
  fragment SharedCourseBookmarkParts on Bookmark {
    type
    basedOn
    noteBody
    updatedAt
    createdAtInteger
    isWithinContent
    filedBookmarks {
      id
      bookmarkFolderId
    }
    noteBody
    numPeopleWhoBookmarked
    cclCourse {
      ...CclCourseCourseCardParts
    }
  }
  ${CclCourseCourseCardPartsFragmentDoc}
`
export const CourseBookmarkPartsFragmentDoc = gql`
  fragment CourseBookmarkParts on Bookmark {
    id
    ...SharedCourseBookmarkParts
  }
  ${SharedCourseBookmarkPartsFragmentDoc}
`
export const MyCourseCclCoursePartsFragmentDoc = gql`
  fragment MyCourseCclCourseParts on CclCourse {
    id
    staticId
    title
    slug
    shortDescription
    completionPercentage
    creators {
      id
      name
      avatarUrl
    }
  }
`
export const MyCourseCclCourseInProgressPartsFragmentDoc = gql`
  fragment MyCourseCclCourseInProgressParts on UserCourseActivity {
    latestActivityAt
    cclCourse {
      ...MyCourseCclCourseParts
    }
  }
  ${MyCourseCclCoursePartsFragmentDoc}
`
export const VideoBookmarkPartsFragmentDoc = gql`
  fragment VideoBookmarkParts on Bookmark {
    id
    noteBody
    referenceImageUrl
    seconds
  }
`
export const BookmarkNoteFormPartsFragmentDoc = gql`
  fragment BookmarkNoteFormParts on Bookmark {
    id
    anchor
    ...VideoBookmarkParts
    type
  }
  ${VideoBookmarkPartsFragmentDoc}
`
export const ProgramBookmarkItemPartsFragmentDoc = gql`
  fragment ProgramBookmarkItemParts on Bookmark {
    id
    anchor
    cmsModule {
      id
      name
    }
    cmsSection {
      id
      name
      path
    }
    cmsProgram {
      id
      name
      lessonCount
      purchased
      upcoming
      enrolled
      canEnroll
      showNewBadge
      topic
      collaborators {
        name
        id
        companyName
        description
        title
        avatarUrl: thumbnailUrl
      }
    }
    type
    referenceImageUrl
    basedOn
    seconds
    updated
    created
    title
    externalUrl
    metadata
    startOffset
    endOffset
  }
`
export const ProgramBookmarkPartsFragmentDoc = gql`
  fragment ProgramBookmarkParts on Bookmark {
    id
    eventId
    ...SharedCourseBookmarkParts
    ...BookmarkNoteFormParts
    ...ProgramBookmarkItemParts
    lessonPosition
    sanityId
    cmsModule {
      id
      name
      position
    }
    cmsSection {
      id
      name
      position
      progressPercent
      childrenCount
      completedChildrenCount
    }
    content: cmsSection {
      ...ConceptCardSectionParts
    }
    cmsProgram {
      id
      name
      slug
      progressPercent
      iconImageUrl
      notLaunched
      deprecatedAt
      description
      synopsis
      collaborators {
        id
        name
        thumbnailUrl
        avatarUrl: thumbnailUrl
      }
    }
    event {
      id
      name
      slug
      kind
      past
      willBeRecorded
      startsAtUtc
      endsAtUtc
      uuid
      attendees(first: 4, hasAvatar: true) {
        edges {
          node {
            id
            ...EventAttendeeParts
          }
        }
      }
      cohorts {
        id
        slug
        season {
          showCohortViewerAt
        }
      }
      hosts {
        id
        ...SpeakerParts
      }
      featuredGuests {
        id
        ...SpeakerParts
      }
    }
    artifact {
      id
      title
      slug
      description
      companyLogoUrl
      companyName
      thumbnailUrl
      viewCount
      commentCount
      authors {
        id
        name
        slug
        position
        avatarUrl
        company {
          id
          name
        }
      }
    }
    course {
      id
      slug
      cmsProgramSlug
      startDate
      subtitle
      title
      legacyProgramId
      bookmarkId
      course {
        id
        title
        shortDescription
        slug
        bookmarkId
        isAvailableOnDemand
        creators {
          id
          name
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
      courseType
      creators {
        id
        expert {
          id
          name
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
    }
    guide {
      id
      title
      whatYouWillDo
      estimatedTimeInMinutes
      stepsCount
      contentBlocks {
        id
      }
      slug
      heroImage {
        imageUrl
        imageAlt
        aspectRatio
      }
      tags {
        id
        title
      }
      authors {
        id
        slug
        name
        avatarPhoto {
          ...ImageFragment
        }
      }
    }
  }
  ${SharedCourseBookmarkPartsFragmentDoc}
  ${BookmarkNoteFormPartsFragmentDoc}
  ${ProgramBookmarkItemPartsFragmentDoc}
  ${ConceptCardSectionPartsFragmentDoc}
  ${EventAttendeePartsFragmentDoc}
  ${SpeakerPartsFragmentDoc}
  ${ImageFragmentFragmentDoc}
`
export const LegacyCohortCardCohortPartsFragmentDoc = gql`
  fragment LegacyCohortCardCohortParts on Cohort {
    id
    slug
    startsAt
    endsAt
    cmsProgram {
      id
      name
      iconImageUrl
      synopsis
      slug
    }
    hosts {
      id
      name
      thumbnailUrl
    }
  }
`
export const MyCourseUserCohortPartsFragmentDoc = gql`
  fragment MyCourseUserCohortParts on UserCohort {
    id
    cohort {
      ...LegacyCohortCardCohortParts
    }
  }
  ${LegacyCohortCardCohortPartsFragmentDoc}
`
export const MyCoursesUserCohortsPartsFragmentDoc = gql`
  fragment MyCoursesUserCohortsParts on UserCohorts {
    previous {
      id
      ...MyCourseUserCohortParts
    }
  }
  ${MyCourseUserCohortPartsFragmentDoc}
`
export const MyCoursesUserCoursePartsFragmentDoc = gql`
  fragment MyCoursesUserCourseParts on UserCourse {
    id
    cclCourseSession {
      ...CclCourseSessionCourseCardParts
    }
    cclCourse {
      ...CclCourseCourseCardParts
    }
    course {
      id
      title
      slug
      sanityId
    }
    courseSession {
      id
      startsAt
      endsAt
      isCurrent
    }
  }
  ${CclCourseSessionCourseCardPartsFragmentDoc}
  ${CclCourseCourseCardPartsFragmentDoc}
`
export const MyCoursesUserCoursesPartsFragmentDoc = gql`
  fragment MyCoursesUserCoursesParts on UserCourses {
    all {
      id
      ...MyCoursesUserCourseParts
    }
    upcoming {
      id
      ...MyCoursesUserCourseParts
    }
    previous {
      id
      ...MyCoursesUserCourseParts
    }
    last {
      id
      ...MyCoursesUserCourseParts
    }
  }
  ${MyCoursesUserCoursePartsFragmentDoc}
`
export const MyCoursesCurrentUserPartsFragmentDoc = gql`
  fragment MyCoursesCurrentUserParts on User {
    id
    timezone
    hasContentInProgressOrComplete
    userProgramsProgressStarted {
      ...MyCoursesUserProgramParts
    }
    artifactBookmarks {
      ...ArtifactBookmarkParts
    }
    eventBookmarks {
      ...EventBookmarkParts
    }
    courseBookmarks {
      ...CourseBookmarkParts
    }
    inProgressCourses {
      ...MyCourseCclCourseInProgressParts
    }
    programBookmarks {
      ...ProgramBookmarkParts
    }
    cohorts {
      ...MyCoursesUserCohortsParts
    }
    userCourses {
      ...MyCoursesUserCoursesParts
    }
    is {
      member
    }
  }
  ${MyCoursesUserProgramPartsFragmentDoc}
  ${ArtifactBookmarkPartsFragmentDoc}
  ${EventBookmarkPartsFragmentDoc}
  ${CourseBookmarkPartsFragmentDoc}
  ${MyCourseCclCourseInProgressPartsFragmentDoc}
  ${ProgramBookmarkPartsFragmentDoc}
  ${MyCoursesUserCohortsPartsFragmentDoc}
  ${MyCoursesUserCoursesPartsFragmentDoc}
`
export const PostListReactionFragmentDoc = gql`
  fragment PostListReaction on Reaction {
    id
    kind
    user {
      id
      profile {
        fullName
      }
    }
  }
`
export const PostListReplyFragmentDoc = gql`
  fragment PostListReply on Reply {
    id
    createdAt
    createdAtInWords
    bodyText
    user {
      id
      profile {
        fullName
        avatarUrl
        hasBadge
        companyName
        slug
        role
        kind
      }
    }
    reactions {
      id
      ...PostListReaction
    }
  }
  ${PostListReactionFragmentDoc}
`
export const PostListGroupFragmentDoc = gql`
  fragment PostListGroup on Group {
    id
    title
    slug
  }
`
export const PostListTopicFragmentDoc = gql`
  fragment PostListTopic on PostTopic {
    id
    kind
    slug
    title
    topicId
    groupId
  }
`
export const PostListIndustryFragmentDoc = gql`
  fragment PostListIndustry on Industry {
    id
    name
    slug
  }
`
export const PostListUserFragmentDoc = gql`
  fragment PostListUser on User {
    id
    profile {
      slug
      fullName
      avatarUrl
      role
      companyName
      status
      hasBadge
      kind
      location
    }
  }
`
export const PostListCommentFragmentDoc = gql`
  fragment PostListComment on Comment {
    id
    createdAt
    user {
      id
      profile {
        fullName
      }
    }
    reactions {
      id
      kind
    }
  }
`
export const PostListPostFragmentDoc = gql`
  fragment PostListPost on Post {
    id
    toParam
    body
    bodyText
    title
    category
    createdAtDate
    createdAtInWords
    updatedAtInWords
    lastRespondedAtInWords
    responseCount
    cmsProgramId
    following
    images
    basedOn
    cmsSectionName
    cmsSectionLink
    referenceImageUrl
    skipForumIndex
    replies {
      id
      ...PostListReply
    }
    groups {
      id
      ...PostListGroup
    }
    topics {
      id
      ...PostListTopic
    }
    industries {
      id
      ...PostListIndustry
    }
    user {
      id
      ...PostListUser
    }
    reactions {
      id
      ...PostListReaction
    }
    comments {
      id
      ...PostListComment
    }
  }
  ${PostListReplyFragmentDoc}
  ${PostListGroupFragmentDoc}
  ${PostListTopicFragmentDoc}
  ${PostListIndustryFragmentDoc}
  ${PostListUserFragmentDoc}
  ${PostListReactionFragmentDoc}
  ${PostListCommentFragmentDoc}
`
export const PostListFieldsFragmentDoc = gql`
  fragment PostListFields on PostList {
    pagination {
      currentPage
      totalPages
    }
    unreadPostIds
    posts {
      id
      ...PostListPost
    }
  }
  ${PostListPostFragmentDoc}
`
export const PostUnreadElementFragmentDoc = gql`
  fragment PostUnreadElement on UnreadElement {
    id
    type
  }
`
export const PostUserFragmentDoc = gql`
  fragment PostUser on User {
    id
    profile {
      fullName
      avatarUrl
      hasBadge
      companyName
      location
      status
      slug
      role
      kind
    }
  }
`
export const PostReactionFragmentDoc = gql`
  fragment PostReaction on Reaction {
    id
    kind
    user {
      id
      profile {
        fullName
      }
    }
  }
`
export const PostReplyCommentFragmentDoc = gql`
  fragment PostReplyComment on Comment {
    id
    body
    bodyText
    commentableId
    parentId
    createdAt
    createdAtInWords
    reactions {
      id
      ...PostReaction
    }
    user {
      id
      ...PostUser
    }
  }
  ${PostReactionFragmentDoc}
  ${PostUserFragmentDoc}
`
export const PostReplyFragmentDoc = gql`
  fragment PostReply on Reply {
    id
    createdAt
    createdAtInWords
    body
    bodyText
    replyableId
    user {
      id
      ...PostUser
    }
    comments {
      id
      ...PostReplyComment
    }
    reactions {
      id
      ...PostReaction
    }
  }
  ${PostUserFragmentDoc}
  ${PostReplyCommentFragmentDoc}
  ${PostReactionFragmentDoc}
`
export const PostGroupFragmentDoc = gql`
  fragment PostGroup on Group {
    id
    title
    slug
  }
`
export const PostTopicFragmentDoc = gql`
  fragment PostTopic on PostTopic {
    id
    kind
    slug
    title
    topicId
    groupId
  }
`
export const PostIndustryFragmentDoc = gql`
  fragment PostIndustry on Industry {
    id
    name
    slug
  }
`
export const PostCommentFragmentDoc = gql`
  fragment PostComment on Comment {
    id
    createdAt
    user {
      id
      profile {
        fullName
      }
    }
    reactions {
      id
      ...PostReaction
    }
  }
  ${PostReactionFragmentDoc}
`
export const PostActivityFragmentDoc = gql`
  fragment PostActivity on Activity {
    id
    createdAt
    createdAtInWords
    action
    trackableId
    trackableSlug
    trackableTitle
    trackableType
    userId
  }
`
export const PostShowFieldsFragmentDoc = gql`
  fragment PostShowFields on Post {
    id
    toParam
    body
    bodyText
    title
    category
    createdAtDate
    createdAtInWords
    updatedAtInWords
    lastRespondedAtInWords
    responseCount
    cmsProgramId
    following
    images
    basedOn
    cmsSectionName
    cmsSectionLink
    referenceImageUrl
    skipForumIndex
    categories
    unreadElements {
      id
      ...PostUnreadElement
    }
    replies {
      id
      ...PostReply
    }
    groups {
      id
      ...PostGroup
    }
    topics {
      id
      ...PostTopic
    }
    industries {
      id
      ...PostIndustry
    }
    user {
      id
      ...PostUser
    }
    reactions {
      id
      ...PostReaction
    }
    comments {
      id
      ...PostComment
    }
    activities {
      id
      ...PostActivity
    }
  }
  ${PostUnreadElementFragmentDoc}
  ${PostReplyFragmentDoc}
  ${PostGroupFragmentDoc}
  ${PostTopicFragmentDoc}
  ${PostIndustryFragmentDoc}
  ${PostUserFragmentDoc}
  ${PostReactionFragmentDoc}
  ${PostCommentFragmentDoc}
  ${PostActivityFragmentDoc}
`
export const PostUserPartsFragmentDoc = gql`
  fragment PostUserParts on User {
    id
    fullName
    avatarUrl
  }
`
export const HomePagePostsPartsFragmentDoc = gql`
  fragment HomePagePostsParts on Post {
    id
    title
    text
    href
    timeAgo
    contributors {
      id
      ...PostUserParts
    }
  }
  ${PostUserPartsFragmentDoc}
`
export const PostsSearchPostFragmentDoc = gql`
  fragment PostsSearchPost on Post {
    id
    user {
      fullName
      roleWithCompany
    }
    reactionCount
    responseCount
    title
    toParam
  }
`
export const PostsSearchGroupFragmentDoc = gql`
  fragment PostsSearchGroup on Group {
    id
    title
    slug
    groupCategory {
      category
      description
    }
    userIsMember
    membersCount
    memberStack {
      id
      slug
      fullName
      kind
      avatarUrl
      isCurrentUser
      hasBadge
    }
    topics {
      id
      kind
      groupId
      slug
      title
      topicId
    }
    populatedTopics {
      id
      kind
      groupId
      slug
      title
      topicId
    }
    industries {
      id
      name
      slug
    }
    populatedIndustries {
      id
      name
      slug
    }
  }
`
export const ClipPartsFragmentDoc = gql`
  fragment ClipParts on Clip {
    id
    title
    slug
    clipSubtype
    company {
      id
      cardLogoUrl
      name
    }
    duration
    thumbnailUrl
    summary
    summaryWistiaCode
    hasBeenViewedByCurrentUser
    users {
      id
      avatarUrl
      fullName
      role
      companyName
      profile {
        profileUrl
      }
    }
    lastViewedAt
  }
`
export const SeasonNameFieldsFragmentDoc = gql`
  fragment SeasonNameFields on Season {
    id
    name
    year
  }
`
export const MemberProfileUserCohortFieldsFragmentDoc = gql`
  fragment MemberProfileUserCohortFields on UserCohort {
    id
    season
    cohort {
      id
      cmsProgram {
        id
        name
        code
        iconImageUrl
      }
      season {
        id
        ...SeasonNameFields
      }
    }
    snapshot {
      id
      token
      released
    }
  }
  ${SeasonNameFieldsFragmentDoc}
`
export const MemberProfileUserCourseSessionFieldsFragmentDoc = gql`
  fragment MemberProfileUserCourseSessionFields on CourseSession {
    id
    courseTitle
    startsAt
  }
`
export const MemberHostedCohortFieldsFragmentDoc = gql`
  fragment MemberHostedCohortFields on Cohort {
    id
    cmsProgram {
      id
      code
      name
      iconImageUrl
    }
    season {
      id
      name
      year
    }
  }
`
export const PostSummaryFieldsFragmentDoc = gql`
  fragment PostSummaryFields on Post {
    id
    createdAtInWords
    title
    toParam
  }
`
export const ReplySummaryFieldsFragmentDoc = gql`
  fragment ReplySummaryFields on Reply {
    id
    body
    createdAtInWords
    post {
      id
      title
      toParam
    }
  }
`
export const CommentSummaryFieldsFragmentDoc = gql`
  fragment CommentSummaryFields on Comment {
    id
    body
    createdAtInWords
    post {
      id
      title
      toParam
    }
  }
`
export const ForumContributionFieldsFragmentDoc = gql`
  fragment ForumContributionFields on ForumContribution {
    ... on Post {
      id
      ...PostSummaryFields
    }
    ... on Reply {
      id
      ...ReplySummaryFields
    }
    ... on Comment {
      id
      ...CommentSummaryFields
    }
  }
  ${PostSummaryFieldsFragmentDoc}
  ${ReplySummaryFieldsFragmentDoc}
  ${CommentSummaryFieldsFragmentDoc}
`
export const PreviousCompaniesCompanyFieldsFragmentDoc = gql`
  fragment PreviousCompaniesCompanyFields on Company {
    id
    companyName
    name
  }
`
export const PreviousCompaniesFieldsFragmentDoc = gql`
  fragment PreviousCompaniesFields on UserCompany {
    id
    company {
      id
      ...PreviousCompaniesCompanyFields
    }
  }
  ${PreviousCompaniesCompanyFieldsFragmentDoc}
`
export const MemberProfileSlackAccountFragmentDoc = gql`
  fragment MemberProfileSlackAccount on SlackAccount {
    slackUserId
    user {
      id
      profile {
        avatarUrl
        fullName
      }
    }
  }
`
export const MemberProfileUserFieldsFragmentDoc = gql`
  fragment MemberProfileUserFields on User {
    id
    accessPolicyKind
    clips {
      id
      ...ClipParts
    }
    cohorts {
      current {
        id
        ...MemberProfileUserCohortFields
      }
      previous {
        id
        ...MemberProfileUserCohortFields
      }
    }
    userCourses {
      all {
        id
        course {
          title
        }
        courseSession {
          id
          ...MemberProfileUserCourseSessionFields
        }
      }
    }
    cohortsHosted {
      ...MemberHostedCohortFields
    }
    fullName
    is {
      expired
      creator
    }
    monthJoined
    yearJoined
    lastForumContributions {
      ...ForumContributionFields
    }
    profile {
      aboutMeText
      avatarUrl
      allowDirectMessages
      areasOfExpertiseTags
      askMeAboutText
      businessModelTags
      communityInterestText
      companyProductText
      companyAudienceTags
      companyName
      communityInterestTags
      firstName
      hasBadge
      fullName
      kind
      lastName
      learningGoalsText
      linkedin
      location
      role
      sanitySlug
      twitter
    }
    previousCompanies {
      id
      ...PreviousCompaniesFields
    }
    recentSlackJoins {
      ...MemberProfileSlackAccount
    }
    status
    slackUserId
    slackResponseTime
    joinSlackHref
    networkOnboarded
    networkRecommendations
  }
  ${ClipPartsFragmentDoc}
  ${MemberProfileUserCohortFieldsFragmentDoc}
  ${MemberProfileUserCourseSessionFieldsFragmentDoc}
  ${MemberHostedCohortFieldsFragmentDoc}
  ${ForumContributionFieldsFragmentDoc}
  ${PreviousCompaniesFieldsFragmentDoc}
  ${MemberProfileSlackAccountFragmentDoc}
`
export const ProfileEditModalUserProfileFieldsFragmentDoc = gql`
  fragment ProfileEditModalUserProfileFields on UserProfile {
    aboutMeText
    allowDirectMessages
    areasOfExpertiseTags
    askMeAboutText
    avatarUrl
    businessModelTags
    communityInterestTags
    communityInterestText
    companyAudienceTags
    companyName
    companyProductText
    ctaText
    ctaUrl
    firstName
    lastName
    learningGoalsText
    linkedin
    location
    networkProfile
    previousCompanies {
      id
      ...PreviousCompaniesFields
    }
    responseTime
    role
    timezone
    twitter
  }
  ${PreviousCompaniesFieldsFragmentDoc}
`
export const ProfileEditModalUserFieldsFragmentDoc = gql`
  fragment ProfileEditModalUserFields on User {
    id
    is {
      badgeholder
      member
      paidMember
      creator
    }
    accessPolicyKind
    cohorts {
      current {
        id
        programName
        season
      }
    }
    profile {
      ...ProfileEditModalUserProfileFields
    }
    slackUserId
  }
  ${ProfileEditModalUserProfileFieldsFragmentDoc}
`
export const ArtifactAuthorProfileFragmentFragmentDoc = gql`
  fragment ArtifactAuthorProfileFragment on ArtifactAuthor {
    id
    userId
    name
    slug
    workEntries {
      id
      position
      company {
        id
        name
        slug
        logo {
          ...ImageFragment
        }
      }
      start
      end
      artifactContributions {
        id
        contributionVerb
        status
        artifact {
          ...ArtifactFragment
          authors {
            ...ArtifactAuthorFragment
          }
          allContributions {
            contributionVerb
            status
            artifactAuthor {
              ...ArtifactAuthorFragment
            }
          }
        }
      }
      specificCompany
      specificTimeframe
      generalRole
      generalTimeframe
    }
  }
  ${ImageFragmentFragmentDoc}
  ${ArtifactFragmentFragmentDoc}
  ${ArtifactAuthorFragmentFragmentDoc}
`
export const ProgramCardActiveSeasonPartsFragmentDoc = gql`
  fragment ProgramCardActiveSeasonParts on Season {
    id
    name
    year
    startsAt
    openForEnrollment
    enrollmentsOpenAt
    showCohortViewerAt
  }
`
export const ProjectCardProgramPartsFragmentDoc = gql`
  fragment ProjectCardProgramParts on CmsProgram {
    id
    notLaunched
    enrolled
    numBookmarks
    progressPercent
    programImage
    slug
    name
    collaborators {
      id
      name
      thumbnailUrl
    }
  }
`
export const ProjectCardSectionPartsFragmentDoc = gql`
  fragment ProjectCardSectionParts on CmsSection {
    id
    cmsProgramId
    cmsModuleId
    cmsProgram {
      id
      ...ProjectCardProgramParts
    }
    cmsModule {
      id
      name
    }
    contentType
    contentTypeIcon
    contentBookmarkId
    contentIsInMySavedItems
    showNewBadge
    estimatedTime
    heroImageUrl
    href
    lastViewedAt
    name
    numBookmarks
    numPhases
    shortDescription
    slug
    topic {
      id
      title
    }
    unlimitedAccess
    accessLevel
    projectIcon
    previewable
  }
  ${ProjectCardProgramPartsFragmentDoc}
`
export const CmsModuleGuestUserPartsFragmentDoc = gql`
  fragment CmsModuleGuestUserParts on User {
    id
    profile {
      avatarUrl
      fullName
    }
  }
`
export const ProgramsCmsSectionPartsFragmentDoc = gql`
  fragment ProgramsCmsSectionParts on CmsSection {
    id
    slug
    ...ContentCardParts
    ...LessonCardContentParts
    children {
      id
      slug
      ...ContentCardParts
      ...LessonCardContentParts
    }
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`
export const CmsModulePartsFragmentDoc = gql`
  fragment CmsModuleParts on CmsModule {
    id
    anyCountsTowardsCompletion
    estimatedTime
    published
    publishAt
    name
    numBookmarks
    events {
      id
      name
      slug
      kind
      past
      summaryWistiaCodeOptional: summaryWistiaCode
      willBeRecorded
      timezone
      startsAtUtc
      endsAtUtc
      uuid
      attendingStatus
      attendeesCount
      lastViewedAt
      isPublic
      attendees(first: 3, hasAvatar: true) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            ...EventAttendeeParts
          }
        }
      }
      cohorts {
        id
        slug
        season {
          showCohortViewerAt
        }
      }
      speakers {
        id
        ...SpeakerParts
      }
      hosts {
        id
        ...SpeakerParts
      }
      featuredGuests {
        id
        ...SpeakerParts
      }
    }
    project {
      id
      ...ProjectCardSectionParts
    }
    featuredGuests {
      id
      user {
        id
        ...CmsModuleGuestUserParts
      }
    }
    cmsSections {
      id
      ...ProgramsCmsSectionParts
    }
  }
  ${EventAttendeePartsFragmentDoc}
  ${SpeakerPartsFragmentDoc}
  ${ProjectCardSectionPartsFragmentDoc}
  ${CmsModuleGuestUserPartsFragmentDoc}
  ${ProgramsCmsSectionPartsFragmentDoc}
`
export const CclCourseIdentifierPartsFragmentDoc = gql`
  fragment CclCourseIdentifierParts on CclCourse {
    id
    staticId
    slug
    legacyCourseId
    title
  }
`
export const ProgramPageCclCoursePartsFragmentDoc = gql`
  fragment ProgramPageCclCourseParts on CclCourse {
    ...CclCourseIdentifierParts
    onDemand
  }
  ${CclCourseIdentifierPartsFragmentDoc}
`
export const ProgramSummaryPartsFragmentDoc = gql`
  fragment ProgramSummaryParts on CmsProgram {
    id
    code
    name
    byTheEnd
    iconImageUrl
    deprecatedAt
    lessonCount
    estimatedTime
    shortDescription
    progressPercent
    longDescription
    progressPercent
    collaborators {
      ...ProgramSelectorHostParts
    }
    programNotification {
      imageUrl
      message
      notificationName
      title
    }
    cclCourse {
      ...ProgramPageCclCourseParts
      onDemand
    }
  }
  ${ProgramSelectorHostPartsFragmentDoc}
  ${ProgramPageCclCoursePartsFragmentDoc}
`
export const UpcomingProgramFragmentDoc = gql`
  fragment UpcomingProgram on CmsProgram {
    id
    name
  }
`
export const TentativeUpcomingCohortFragmentDoc = gql`
  fragment TentativeUpcomingCohort on TentativeUpcomingCohort {
    name
    startsAt
    endsAt
    marketingSiteUrl
  }
`
export const ScheduledWeekPartsFragmentDoc = gql`
  fragment ScheduledWeekParts on ScheduledWeek {
    id
    title
    core
    startsAt
    endsAt
    orderedCmsModules {
      id
    }
  }
`
export const ProgramCollaboratorsFragmentDoc = gql`
  fragment ProgramCollaborators on Host {
    id
    name
    bio
    thumbnailUrl
    companyName
    role
  }
`
export const CmsProgramPreviewFragmentDoc = gql`
  fragment CmsProgramPreview on CmsProgram {
    id
    slug
    name
    synopsis
    lessonCount
    whoItsFor
    iconImageUrl
    outcomes
    previewableLessonCount
    tentativeUpcomingCohorts {
      ...TentativeUpcomingCohort
    }
    testimonials {
      id
      name
      role
      copy
      thumbnailUrl
    }
    cohort {
      id
      marketingSiteUrl
      scheduledWeeks {
        id
        ...ScheduledWeekParts
      }
      season {
        id
        nextSeasonName
        startsAt
        endsAt
      }
    }
    collaborators {
      id
      ...ProgramCollaborators
    }
  }
  ${TentativeUpcomingCohortFragmentDoc}
  ${ScheduledWeekPartsFragmentDoc}
  ${ProgramCollaboratorsFragmentDoc}
`
export const CohortPartFragmentDoc = gql`
  fragment CohortPart on Cohort {
    id
    isOngoing
    eventCount
    weekCount
    startsAt
    endsAt
    postCohort
    coreWeeksHaveEnded
    postCoreWeeksEnrollmentIsOpen
    postKickoff
    slug
    thisWeek {
      id
      nextModule {
        id
        href
      }
    }
    season {
      hasStarted
      id
      prettyNameSeasonFirst
      showCohortViewerAt
    }
    cmsProgram {
      id
      name
      lessonCount
      numBookmarks
      progressPercent
      code
      iconImageUrl
    }
  }
`
export const UserCohortPartFragmentDoc = gql`
  fragment UserCohortPart on UserCohort {
    id
    cohort {
      ...CohortPart
    }
  }
  ${CohortPartFragmentDoc}
`
export const ProgramTeammatesPartsFragmentDoc = gql`
  fragment ProgramTeammatesParts on User {
    id
    avatarUrl
    hasBadge
    kind
  }
`
export const ProjectCurrentUserPartsFragmentDoc = gql`
  fragment ProjectCurrentUserParts on User {
    id
    accessPolicyKind
    shouldSeeTrialMessaging
    can {
      viewProjectsAndConcepts
      viewPurchaseFlow
    }
    is {
      premember
      member
    }
  }
`
export const ProjectCardContentPartsFragmentDoc = gql`
  fragment ProjectCardContentParts on Content {
    accessLevel
    contentBookmarkId
    contentIsInMySavedItems
    showNewBadge
    cmsSection {
      id
      ...ProjectCardSectionParts
    }
  }
  ${ProjectCardSectionPartsFragmentDoc}
`
export const SnapshotDataFragmentDoc = gql`
  fragment SnapshotData on SnapshotLandingPage {
    cmsProgramId
    contentLink
    firstName
    imageUrl
    programDescription
    programName
    seasonName
    showcaseContentId
    slug
  }
`
export const CompletionSnapshotDataFragmentDoc = gql`
  fragment CompletionSnapshotData on CompletionSnapshot {
    avatarUrl
    cohortId
    hashtag
    imagePath
    isReleased
    programName
    seasonName
    snapshotShareUrl
    startsAt
    token
    userCohortId
  }
`
export const SpeakerFieldsFragmentDoc = gql`
  fragment SpeakerFields on UserProfileFieldsInterface {
    avatarUrl
    companyName
    fullName
    hasBadge
    linkedin
    location
    profileUrl
    role
    twitter
    kind
  }
`
export const ItemFragmentDoc = gql`
  fragment Item on RecommendedItem {
    authors
    creators: authors
    id
    cclSyncMapId
    commentCount
    companyLogoAltText
    companyLogoUrl
    companyName
    description
    draftTemplateLabel
    draftTemplateName
    estimatedTimeInMinutes
    isFree
    kind
    localId
    previewable
    publishDate
    sanityId
    slug
    stepsCount
    thumbnailUrl
    title
    viewerCount
  }
`
export const RecommendedCollectionFragmentDoc = gql`
  fragment RecommendedCollection on Collection {
    id
    title
    contentTypes
    items {
      ...Item
    }
  }
  ${ItemFragmentDoc}
`
export const SearchDocumentPostFragmentDoc = gql`
  fragment SearchDocumentPost on Post {
    id
    category
    responseCount
    user {
      id
      fullName
      ...AvatarFragment
    }
  }
  ${AvatarFragmentFragmentDoc}
`
export const SearchDocumentReplyFragmentDoc = gql`
  fragment SearchDocumentReply on Reply {
    id
    post {
      id
      category
      responseCount
    }
    user {
      id
      fullName
      ...AvatarFragment
    }
  }
  ${AvatarFragmentFragmentDoc}
`
export const SearchDocumentCommentFragmentDoc = gql`
  fragment SearchDocumentComment on Comment {
    id
    post {
      id
      category
      responseCount
    }
    user {
      id
      fullName
      ...AvatarFragment
    }
  }
  ${AvatarFragmentFragmentDoc}
`
export const SearchDocumentCmsSectionFragmentDoc = gql`
  fragment SearchDocumentCmsSection on CmsSection {
    id
    name
    path
    heroImageUrl
    cmsProgram {
      id
      slug
      topic
      name
      heroImageUrl
    }
    cmsModule {
      id
      name
    }
    parent {
      id
      cmsModule {
        id
        name
      }
    }
  }
`
export const SearchDocumentUserFragmentDoc = gql`
  fragment SearchDocumentUser on User {
    id
    profile {
      role
      companyName
      location
      slug
      hasBadge
      kind
      avatarUrl
    }
  }
`
export const SearchDocumentResultFragmentDoc = gql`
  fragment SearchDocumentResult on SearchDocument {
    id
    title
    topic
    pgSearchHighlight
    updatedAt
    searchable {
      ... on Post {
        id
        ...SearchDocumentPost
      }
      ... on Reply {
        id
        ...SearchDocumentReply
      }
      ... on Comment {
        id
        ...SearchDocumentComment
      }
      ... on CmsSection {
        id
        ...SearchDocumentCmsSection
      }
      ... on User {
        id
        ...SearchDocumentUser
      }
    }
  }
  ${SearchDocumentPostFragmentDoc}
  ${SearchDocumentReplyFragmentDoc}
  ${SearchDocumentCommentFragmentDoc}
  ${SearchDocumentCmsSectionFragmentDoc}
  ${SearchDocumentUserFragmentDoc}
`
export const SearchDocumentTopResultFragmentDoc = gql`
  fragment SearchDocumentTopResult on SearchDocumentTopResult {
    programContent {
      results {
        ...SearchDocumentResult
      }
      totalCount
    }
    members {
      results {
        ...SearchDocumentResult
      }
      totalCount
    }
  }
  ${SearchDocumentResultFragmentDoc}
`
export const SimilarPostFieldsFragmentDoc = gql`
  fragment SimilarPostFields on SimilarPost {
    id
    title
    responseCount
    reactionsCount
    age
  }
`
export const SubscriptionJoinRequestPartsFragmentDoc = gql`
  fragment SubscriptionJoinRequestParts on SubscriptionJoinRequest {
    id
    status
    subscription {
      id
      hasAvailableSeats
      logoUrl
      user {
        id
        fullName
      }
    }
  }
`
export const TaxInfoItemFragmentDoc = gql`
  fragment TaxInfoItem on TaxInfoItem {
    productItemCode
    details {
      rate
      taxableAmount
    }
  }
`
export const OnboardingQuestionPartsFragmentDoc = gql`
  fragment OnboardingQuestionParts on UqQuestion {
    id
    choices {
      id
      choice
      tags
    }
    choiceText
    description
    maxChoices
    page
    question
  }
`
export const QuestionnaireFieldsFragmentDoc = gql`
  fragment QuestionnaireFields on OnboardingQuestionnaire {
    id
    areasOfExpertiseTags
    businessModelTags
    communityInterestTags
    companyAudienceTags
  }
`
export const UserAnswerFragmentDoc = gql`
  fragment UserAnswer on UqUserAnswer {
    id
    choice
    choiceTags
    question
    uqChoiceId
    uqQuestionId
  }
`
export const UserAccountDetailsFieldsFragmentDoc = gql`
  fragment UserAccountDetailsFields on User {
    id
    accessPolicyKind
    profile {
      hasBadge
      kind
      firstName
      lastName
      fullName
      role
      status
      companyName
      timezone
      location
      avatarUrl
      twitter
      linkedin
      sanitySlug
      previousCompanies {
        id
        company {
          id
          name
        }
      }
      profilePath
    }
    contact {
      allEmails
      notificationPreferences {
        id
        mentionEmail
        digestEmail
        repliesActivity
        commentsActivity
        postsActivity
      }
      primaryEmail
      slackAccount {
        slackUserId
        createdAt
      }
    }
    can {
      editEmailPreferences
      viewBilling
      viewSubscriptionSummary
      manageMyTeam
      pay
    }
    canSubscribeWithoutTrial
    shouldSeeTrialMessaging
    is {
      alumni
      badgeholder
      enrolledInLiveProgram
      expired
      member
      paidMember
      staff
      trialing
    }
    oauthProviders {
      provider
      createdAt
    }
    subscriptions {
      active {
        id
        daysLeft
        isExpired
        isCanceled
        isTeam
        stripeSubscription {
          id
          paymentFailed
          cancelAtPeriodEnd
        }
      }
    }
    slackUserId
  }
`
export const UserAccountManageSeatsSubscriptionSeatsFragmentDoc = gql`
  fragment UserAccountManageSeatsSubscriptionSeats on Seat {
    id
    scheduledForDeletionDate
    isRenewing
    hasCohortAccess
    subscriptionMember {
      id
      userId
      status
      createdAt
      email
      role
      user {
        fullName
        email
        workEmail
        profile {
          avatarUrl
        }
        is {
          enrolledInLiveProgram
        }
      }
    }
  }
`
export const UserAccountManageSeatsSubscriptionFragmentDoc = gql`
  fragment UserAccountManageSeatsSubscription on Subscription {
    id
    userIsOwner
    termEndsAt
    numSeats
    expiringSeatCount
    seats {
      ...UserAccountManageSeatsSubscriptionSeats
    }
    stripeSubscription {
      id
    }
  }
  ${UserAccountManageSeatsSubscriptionSeatsFragmentDoc}
`
export const BookmarksFromFolderPartsFragmentDoc = gql`
  fragment bookmarksFromFolderParts on Bookmark {
    ...ProgramBookmarkParts
    lastViewedContentAtInteger
  }
  ${ProgramBookmarkPartsFragmentDoc}
`
export const BookmarkFragmentDoc = gql`
  fragment Bookmark on Bookmark {
    id
    ...ProgramBookmarkParts
    filedBookmarks {
      id
      bookmarkFolderId
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`
export const PreviousUserCohortFragmentDoc = gql`
  fragment PreviousUserCohort on UserCohort {
    id
    programId
    programName
    season
    canBeUpdated
    canViewCertificate
    cohortCompletionCertificate {
      id
      certificateUrl
    }
    snapshotable
    snapshot {
      id
      token
      released
    }
  }
`
export const CurrentUserCohortFragmentDoc = gql`
  fragment CurrentUserCohort on UserCohort {
    id
    programId
    programName
    season
    seasonId
    canBeUpdated
    canViewCertificate
    cohortCompletionCertificate {
      id
      certificateUrl
    }
    snapshot {
      id
      token
      released
    }
    cohort {
      id
      postCohort
      eventTimes {
        count
        firstStartDate
      }
    }
  }
`
export const CohortHistoryUserFragmentDoc = gql`
  fragment CohortHistoryUser on User {
    id
    profile {
      avatarUrl
    }
    is {
      member
      staff
    }
    userCourses {
      all {
        id
        course {
          title
          slug
        }
        courseSession {
          startsAt
        }
      }
    }
    cohorts {
      previous {
        ...PreviousUserCohort
      }
      current {
        ...CurrentUserCohort
      }
    }
    recommendedPrograms {
      ...ProgramSelectorProgramParts
      testimonials {
        ...TestimonialFields
      }
    }
    subscriptions {
      active {
        id
        isCanceled
        expiresAt
      }
    }
  }
  ${PreviousUserCohortFragmentDoc}
  ${CurrentUserCohortFragmentDoc}
  ${ProgramSelectorProgramPartsFragmentDoc}
  ${TestimonialFieldsFragmentDoc}
`
export const UserCourseSnapshotPartsFragmentDoc = gql`
  fragment UserCourseSnapshotParts on UserCourse {
    id
    isEnrolled
    courseId
    course {
      id
      title
      slug
    }
    courseSession {
      id
      startsAt
      endsAt
    }
    courseEnrollmentSnapshot {
      id
      linkedinShareUrl
      twitterShareUrl
      imageUrl
    }
    courseCompletionSnapshot {
      id
      linkedinShareUrl
      twitterShareUrl
      imageUrl
    }
  }
`
export const UserCoursePartsFragmentDoc = gql`
  fragment UserCourseParts on UserCourse {
    id
    isEnrolled
    courseId
    course {
      id
      title
      slug
    }
    courseSession {
      id
      startsAt
    }
    cohortCredit {
      id
    }
    paymentType
    shadow
    stripeInvoiceId
  }
`
export const CourseSessionPartsFragmentDoc = gql`
  fragment CourseSessionParts on CourseSession {
    id
    courseSlug
    courseTitle
    maxEnrollmentUnlimited
    maxEnrollmentPaid
    maxEnrollmentTotal
    numEnrolledUnlimited
    numEnrolledPaid
    numEnrolledTotal
    startsAt
  }
`
export const OrgSubscriptionFragmentDoc = gql`
  fragment OrgSubscription on Subscription {
    id
    orgId
    isTeam
    numSeats
    teamName
    user {
      id
      email
      fullName
    }
  }
`
export const UserOrgFragmentDoc = gql`
  fragment UserOrg on Org {
    id
    subscriptions {
      id
      ...OrgSubscription
    }
  }
  ${OrgSubscriptionFragmentDoc}
`
export const PastPaymentFieldsFragmentDoc = gql`
  fragment PastPaymentFields on PastPayment {
    id
    createdAt
    description
    invoiceId
    payerId
    paymentType
    status
    paidAt
  }
`
export const OpenInvoiceFieldsFragmentDoc = gql`
  fragment OpenInvoiceFields on StripeInvoice {
    id
    createdAt
    description
    dueDate
    hostedInvoiceUrl
    item
    status
  }
`
export const PaymentMethodFragmentDoc = gql`
  fragment PaymentMethod on StripePaymentMethod {
    id
    type
    defaultPaymentMethod
    card {
      brand
      last4
      expYear
      expMonth
      status
    }
  }
`
export const StripeBankAccountPartsFragmentDoc = gql`
  fragment StripeBankAccountParts on StripeBankAccount {
    id
    accountHolderName
    accountHolderType
    bankName
    country
    currency
    fingerprint
    last4
    routingNumber
    status
    defaultSource
  }
`
export const StripeSourcePartsFragmentDoc = gql`
  fragment StripeSourceParts on StripeSource {
    id
    type
    status
    currency
    achCreditTransfer {
      accountNumber
      routingNumber
      fingerprint
      bankName
      swiftCode
    }
    defaultSource
  }
`
export const SeatsFragmentDoc = gql`
  fragment Seats on Seat {
    id
    scheduledForDeletionDate
    subscriptionMember {
      id
      userId
      email
      status
      role
      createdAt
    }
  }
`
export const StripeSubscriptionFragmentDoc = gql`
  fragment StripeSubscription on StripeSubscription {
    id
    status
    paymentFailed
    currentPeriodEnd
    cancelAt
    cancelAtPeriodEnd
    plan {
      id
      active
    }
  }
`
export const ActiveSubscriptionFragmentDoc = gql`
  fragment ActiveSubscription on Subscription {
    id
    name
    daysLeft
    userIsOwner
    numSeats
    expiresAt
    isPaidMonthly
    termEndsAt
    isExpired
    status
    isPartnerSubscription
    stripeSubscriptionId
    expiringSeatCount
    planName
    stripeSubscriptionStatus
    manualAssign
    seats {
      ...Seats
    }
    stripeSubscription {
      ...StripeSubscription
    }
  }
  ${SeatsFragmentDoc}
  ${StripeSubscriptionFragmentDoc}
`
export const TrialSubscriptionFragmentDoc = gql`
  fragment TrialSubscription on Subscription {
    id
    status
    startsAt
    expiresAt
    trialLength
    daysLeftInTrial
    trialType
  }
`
export const UserSubscriptionFragmentDoc = gql`
  fragment UserSubscription on UserSubscriptions {
    active {
      ...ActiveSubscription
    }
    lastExpired {
      id
      expiresAt
    }
    trial {
      ...TrialSubscription
    }
  }
  ${ActiveSubscriptionFragmentDoc}
  ${TrialSubscriptionFragmentDoc}
`
export const PricingFragmentDoc = gql`
  fragment Pricing on UserPricing {
    term
  }
`
export const SubscriptionSeatFieldsFragmentDoc = gql`
  fragment SubscriptionSeatFields on Seat {
    id
    scheduledForDeletionDate
    subscriptionMember {
      id
      userId
      email
      status
      role
      createdAt
    }
  }
`
export const StripeSubscriptionFieldsFragmentDoc = gql`
  fragment StripeSubscriptionFields on StripeSubscription {
    id
    status
    paymentFailed
    currentPeriodEnd
    cancelAt
    cancelAtPeriodEnd
    plan {
      id
      active
    }
  }
`
export const ActiveSubscriptionFieldsFragmentDoc = gql`
  fragment ActiveSubscriptionFields on Subscription {
    id
    daysLeft
    userIsOwner
    numSeats
    expiresAt
    isPaidMonthly
    termEndsAt
    isExpired
    status
    isPartnerSubscription
    stripeSubscriptionId
    expiringSeatCount
    seats {
      ...SubscriptionSeatFields
    }
    stripeSubscription {
      ...StripeSubscriptionFields
    }
  }
  ${SubscriptionSeatFieldsFragmentDoc}
  ${StripeSubscriptionFieldsFragmentDoc}
`
export const SubscriptionsFieldsFragmentDoc = gql`
  fragment SubscriptionsFields on UserSubscriptions {
    active {
      ...ActiveSubscriptionFields
    }
    lastExpired {
      id
      expiresAt
    }
    trial {
      id
      status
      startsAt
      expiresAt
      trialLength
      daysLeftInTrial
      trialType
    }
  }
  ${ActiveSubscriptionFieldsFragmentDoc}
`
export const UserPermissionFieldsFragmentDoc = gql`
  fragment UserPermissionFields on User {
    can {
      editEmailPreferences
      viewBilling
      viewSubscriptionSummary
      manageMyTeam
      pay
    }
  }
`
export const SubscriptionTeamFieldsFragmentDoc = gql`
  fragment SubscriptionTeamFields on Subscription {
    id
    domainName
    isDiscoverable
    isTeam
    logoUrl
    name
    shareableLinkUrl
    userIsOwner
  }
`
export const SubscriptionCancelingFieldsFragmentDoc = gql`
  fragment SubscriptionCancelingFields on Subscription {
    id
    user {
      id
      email
    }
    ...SubscriptionReactivationFields
  }
  ${SubscriptionReactivationFieldsFragmentDoc}
`
export const SubscriptionCardFieldsFragmentDoc = gql`
  fragment SubscriptionCardFields on Subscription {
    id
    cohortCredits {
      assignable {
        id
      }
      unexpired {
        id
      }
      useable {
        id
      }
    }
    trialEndDate
    dunningDaysLeft
    planName
    stripeCollectionMethod
    stripeSubscriptionStatus
    stripeUpcomingInvoice {
      id
      amountDue
      numSeats
      numCohortPasses
      planName
    }
    ...SubscriptionCancelingFields
  }
  ${SubscriptionCancelingFieldsFragmentDoc}
`
export const UserSubscriptionWithTeamSubscriptionFragmentDoc = gql`
  fragment UserSubscriptionWithTeamSubscription on Subscription {
    id
    cohortCredits {
      useable {
        id
      }
      assignable {
        id
      }
    }
    termEndsAt
    trialEndDate
    dunningDaysLeft
    isDunning
    isCanceled
    expiringSeatCount
    numSeats
    numSeatsAvailable
    planName
    stripeSubscription {
      id
      cancelAtPeriodEnd
    }
    stripeUpcomingInvoice {
      id
      amountDue
      numSeats
      numCohortPasses
      planName
      subtotal
    }
    seats {
      id
      scheduledForDeletionDate
      subscriptionMember {
        id
        userId
        status
        createdAt
        email
        role
      }
    }
    ...SubscriptionTeamFields
    ...SubscriptionCardFields
  }
  ${SubscriptionTeamFieldsFragmentDoc}
  ${SubscriptionCardFieldsFragmentDoc}
`
export const UserSubscriptionWithTeamFieldsFragmentDoc = gql`
  fragment UserSubscriptionWithTeamFields on User {
    accessPolicyKind
    id
    fullName
    is {
      trialing
    }
    openInvoices {
      id
      status
    }
    contact {
      primaryEmail
      allEmails
    }
    paymentMethods {
      id
      type
      defaultPaymentMethod
      card {
        brand
        last4
        expYear
        expMonth
        status
      }
    }
    stripeCustomer {
      id
      balance
    }
    subscriptions {
      active {
        ...UserSubscriptionWithTeamSubscription
      }
      lastExpired {
        id
        isTeam
      }
    }
    uniqueEmailDomains
    can {
      editEmailPreferences
      viewBilling
      viewSubscriptionSummary
      manageMyTeam
      pay
    }
    is {
      trialing
    }
  }
  ${UserSubscriptionWithTeamSubscriptionFragmentDoc}
`
export const SimilarityContentFragmentDoc = gql`
  fragment SimilarityContent on SimilaritySearchContent {
    id
    author
    breadCrumbs {
      label
      url
    }
    companyName
    contentType
    highlight
    tags
    thumbnail
    title
    url
  }
`
export const TeamMembersPageSubscriptionSeatFragmentDoc = gql`
  fragment TeamMembersPageSubscriptionSeat on Seat {
    id
    isRenewing
    hasCohortAccess
    subscriptionMember {
      id
      userId
      status
      createdAt
      email
      role
      token
      user {
        id
        isCurrentUser
        fullName
        firstName
        email
        workEmail
        enrolledPrograms {
          name
        }
        profile {
          avatarUrl
        }
        is {
          enrolledInLiveProgram
          enrolledInActiveCourse
        }
        currentEnrolledCourse {
          id
          course {
            id
            title
          }
        }
      }
    }
  }
`
export const TeamMembersPageSubscriptionFragmentDoc = gql`
  fragment TeamMembersPageSubscription on Subscription {
    id
    userIsOwner
    manualAssign
    numSeats
    hasUnlimitedCohortAccess
    status
    expiresAt
    planName
    domainName
    isProvisionedByScim
    cohortCredits {
      unexpired {
        id
      }
      assignable {
        id
      }
      useable {
        id
      }
    }
    seats {
      ...TeamMembersPageSubscriptionSeat
    }
  }
  ${TeamMembersPageSubscriptionSeatFragmentDoc}
`
export const CollectionActivityFacepileDocument = gql`
  query collectionActivityFacepile($collectionId: ID!, $userIds: [ID!]) {
    bookmarkFolder(folderId: $collectionId) {
      id
      createdAtInWords
      usersSharedWith {
        ...UserAvatarParts
      }
      user {
        ...UserAvatarParts
      }
    }
    mostRecentCollectionActivityPerUser(collectionId: $collectionId, userIds: $userIds) {
      ...ActivityFacepileCollectionActivityParts
    }
  }
  ${UserAvatarPartsFragmentDoc}
  ${ActivityFacepileCollectionActivityPartsFragmentDoc}
`

/**
 * __useCollectionActivityFacepileQuery__
 *
 * To run a query within a React component, call `useCollectionActivityFacepileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionActivityFacepileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionActivityFacepileQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useCollectionActivityFacepileQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectionActivityFacepileQuery,
    CollectionActivityFacepileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CollectionActivityFacepileQuery,
    CollectionActivityFacepileQueryVariables
  >(CollectionActivityFacepileDocument, options)
}
export function useCollectionActivityFacepileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionActivityFacepileQuery,
    CollectionActivityFacepileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CollectionActivityFacepileQuery,
    CollectionActivityFacepileQueryVariables
  >(CollectionActivityFacepileDocument, options)
}
export type CollectionActivityFacepileQueryHookResult = ReturnType<
  typeof useCollectionActivityFacepileQuery
>
export type CollectionActivityFacepileLazyQueryHookResult = ReturnType<
  typeof useCollectionActivityFacepileLazyQuery
>
export type CollectionActivityFacepileQueryResult = Apollo.QueryResult<
  CollectionActivityFacepileQuery,
  CollectionActivityFacepileQueryVariables
>
export const ContentBookmarkActivityFacepileDocument = gql`
  query contentBookmarkActivityFacepile(
    $bookmarkId: ID!
    $collectionId: ID!
    $userIds: [ID!]
  ) {
    findBookmarkById(bookmarkId: $bookmarkId) {
      ...ActivityFacepileContentBookmarkParts
    }
    bookmarkFolder(folderId: $collectionId) {
      id
      user {
        id
      }
      usersSharedWith {
        ...UserAvatarParts
      }
    }
  }
  ${ActivityFacepileContentBookmarkPartsFragmentDoc}
  ${UserAvatarPartsFragmentDoc}
`

/**
 * __useContentBookmarkActivityFacepileQuery__
 *
 * To run a query within a React component, call `useContentBookmarkActivityFacepileQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentBookmarkActivityFacepileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentBookmarkActivityFacepileQuery({
 *   variables: {
 *      bookmarkId: // value for 'bookmarkId'
 *      collectionId: // value for 'collectionId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useContentBookmarkActivityFacepileQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContentBookmarkActivityFacepileQuery,
    ContentBookmarkActivityFacepileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContentBookmarkActivityFacepileQuery,
    ContentBookmarkActivityFacepileQueryVariables
  >(ContentBookmarkActivityFacepileDocument, options)
}
export function useContentBookmarkActivityFacepileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentBookmarkActivityFacepileQuery,
    ContentBookmarkActivityFacepileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContentBookmarkActivityFacepileQuery,
    ContentBookmarkActivityFacepileQueryVariables
  >(ContentBookmarkActivityFacepileDocument, options)
}
export type ContentBookmarkActivityFacepileQueryHookResult = ReturnType<
  typeof useContentBookmarkActivityFacepileQuery
>
export type ContentBookmarkActivityFacepileLazyQueryHookResult = ReturnType<
  typeof useContentBookmarkActivityFacepileLazyQuery
>
export type ContentBookmarkActivityFacepileQueryResult = Apollo.QueryResult<
  ContentBookmarkActivityFacepileQuery,
  ContentBookmarkActivityFacepileQueryVariables
>
export const CheckoutDocument = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      success
      errors
      clientSecret
      stripeSubscriptionId
    }
  }
`
export type CheckoutMutationFn = Apollo.MutationFunction<
  CheckoutMutation,
  CheckoutMutationVariables
>

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMutation, { data, loading, error }] = useCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckoutMutation, CheckoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CheckoutMutation, CheckoutMutationVariables>(
    CheckoutDocument,
    options
  )
}
export type CheckoutMutationHookResult = ReturnType<typeof useCheckoutMutation>
export type CheckoutMutationResult = Apollo.MutationResult<CheckoutMutation>
export type CheckoutMutationOptions = Apollo.BaseMutationOptions<
  CheckoutMutation,
  CheckoutMutationVariables
>
export const CheckoutModalDocument = gql`
  query CheckoutModal {
    currentUser {
      id
      email
      subscriptions {
        active {
          id
          planName
          stripeSubscriptionId
          stripeCustomerId
          startsAt
          status
        }
      }
    }
    plansForSale {
      name
      pricePerPerson
      pricePerYear
      maxSeatCount
    }
    cohortPassPriceTiers {
      pricePerPass
      minCount
      maxCount
      stripeId
    }
  }
`

/**
 * __useCheckoutModalQuery__
 *
 * To run a query within a React component, call `useCheckoutModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutModalQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckoutModalQuery, CheckoutModalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckoutModalQuery, CheckoutModalQueryVariables>(
    CheckoutModalDocument,
    options
  )
}
export function useCheckoutModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutModalQuery,
    CheckoutModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckoutModalQuery, CheckoutModalQueryVariables>(
    CheckoutModalDocument,
    options
  )
}
export type CheckoutModalQueryHookResult = ReturnType<typeof useCheckoutModalQuery>
export type CheckoutModalLazyQueryHookResult = ReturnType<
  typeof useCheckoutModalLazyQuery
>
export type CheckoutModalQueryResult = Apollo.QueryResult<
  CheckoutModalQuery,
  CheckoutModalQueryVariables
>
export const PreviewUpcomingInvoiceDocument = gql`
  query previewUpcomingInvoice(
    $planName: String!
    $subscriptionId: ID!
    $numCohortPasses: Int
  ) {
    previewUpcomingInvoice(
      planName: $planName
      subscriptionId: $subscriptionId
      numCohortPasses: $numCohortPasses
    ) {
      amountDue
      discountedCohortPassAmount
      proratedAdjustment {
        amount
        startDate
        endDate
        paidTaxOnPrevPlan
        amountForNewPlanRemainingTime
      }
      subtotal
      startingBalance
      lines {
        ...PreviewLineItem
      }
      tax
    }
  }
  ${PreviewLineItemFragmentDoc}
`

/**
 * __usePreviewUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `usePreviewUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewUpcomingInvoiceQuery({
 *   variables: {
 *      planName: // value for 'planName'
 *      subscriptionId: // value for 'subscriptionId'
 *      numCohortPasses: // value for 'numCohortPasses'
 *   },
 * });
 */
export function usePreviewUpcomingInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreviewUpcomingInvoiceQuery,
    PreviewUpcomingInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PreviewUpcomingInvoiceQuery,
    PreviewUpcomingInvoiceQueryVariables
  >(PreviewUpcomingInvoiceDocument, options)
}
export function usePreviewUpcomingInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreviewUpcomingInvoiceQuery,
    PreviewUpcomingInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PreviewUpcomingInvoiceQuery,
    PreviewUpcomingInvoiceQueryVariables
  >(PreviewUpcomingInvoiceDocument, options)
}
export type PreviewUpcomingInvoiceQueryHookResult = ReturnType<
  typeof usePreviewUpcomingInvoiceQuery
>
export type PreviewUpcomingInvoiceLazyQueryHookResult = ReturnType<
  typeof usePreviewUpcomingInvoiceLazyQuery
>
export type PreviewUpcomingInvoiceQueryResult = Apollo.QueryResult<
  PreviewUpcomingInvoiceQuery,
  PreviewUpcomingInvoiceQueryVariables
>
export const CohortPassPriceTiersDocument = gql`
  query CohortPassPriceTiers {
    cohortPassPriceTiers {
      pricePerPass
      minCount
      maxCount
    }
  }
`

/**
 * __useCohortPassPriceTiersQuery__
 *
 * To run a query within a React component, call `useCohortPassPriceTiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortPassPriceTiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortPassPriceTiersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCohortPassPriceTiersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CohortPassPriceTiersQuery,
    CohortPassPriceTiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortPassPriceTiersQuery, CohortPassPriceTiersQueryVariables>(
    CohortPassPriceTiersDocument,
    options
  )
}
export function useCohortPassPriceTiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortPassPriceTiersQuery,
    CohortPassPriceTiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortPassPriceTiersQuery,
    CohortPassPriceTiersQueryVariables
  >(CohortPassPriceTiersDocument, options)
}
export type CohortPassPriceTiersQueryHookResult = ReturnType<
  typeof useCohortPassPriceTiersQuery
>
export type CohortPassPriceTiersLazyQueryHookResult = ReturnType<
  typeof useCohortPassPriceTiersLazyQuery
>
export type CohortPassPriceTiersQueryResult = Apollo.QueryResult<
  CohortPassPriceTiersQuery,
  CohortPassPriceTiersQueryVariables
>
export const DatadogUserSessionDocument = gql`
  query DatadogUserSession {
    currentUser {
      id
      accessPolicyKind
      fullName
      email
      subscriptions {
        active {
          id
          name
          planName
        }
      }
    }
  }
`

/**
 * __useDatadogUserSessionQuery__
 *
 * To run a query within a React component, call `useDatadogUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatadogUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatadogUserSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatadogUserSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DatadogUserSessionQuery,
    DatadogUserSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DatadogUserSessionQuery, DatadogUserSessionQueryVariables>(
    DatadogUserSessionDocument,
    options
  )
}
export function useDatadogUserSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DatadogUserSessionQuery,
    DatadogUserSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DatadogUserSessionQuery, DatadogUserSessionQueryVariables>(
    DatadogUserSessionDocument,
    options
  )
}
export type DatadogUserSessionQueryHookResult = ReturnType<
  typeof useDatadogUserSessionQuery
>
export type DatadogUserSessionLazyQueryHookResult = ReturnType<
  typeof useDatadogUserSessionLazyQuery
>
export type DatadogUserSessionQueryResult = Apollo.QueryResult<
  DatadogUserSessionQuery,
  DatadogUserSessionQueryVariables
>
export const AiChatUsersDocument = gql`
  query aiChatUsers {
    aiChatUsers {
      ...AiChatUser
    }
  }
  ${AiChatUserFragmentDoc}
`

/**
 * __useAiChatUsersQuery__
 *
 * To run a query within a React component, call `useAiChatUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiChatUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiChatUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAiChatUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<AiChatUsersQuery, AiChatUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AiChatUsersQuery, AiChatUsersQueryVariables>(
    AiChatUsersDocument,
    options
  )
}
export function useAiChatUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AiChatUsersQuery, AiChatUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AiChatUsersQuery, AiChatUsersQueryVariables>(
    AiChatUsersDocument,
    options
  )
}
export type AiChatUsersQueryHookResult = ReturnType<typeof useAiChatUsersQuery>
export type AiChatUsersLazyQueryHookResult = ReturnType<typeof useAiChatUsersLazyQuery>
export type AiChatUsersQueryResult = Apollo.QueryResult<
  AiChatUsersQuery,
  AiChatUsersQueryVariables
>
export const UserAiChatsDocument = gql`
  query userAiChats($userId: ID!) {
    aiChats(userId: $userId) {
      id
      conversationText
    }
  }
`

/**
 * __useUserAiChatsQuery__
 *
 * To run a query within a React component, call `useUserAiChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAiChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAiChatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAiChatsQuery(
  baseOptions: Apollo.QueryHookOptions<UserAiChatsQuery, UserAiChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserAiChatsQuery, UserAiChatsQueryVariables>(
    UserAiChatsDocument,
    options
  )
}
export function useUserAiChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAiChatsQuery, UserAiChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserAiChatsQuery, UserAiChatsQueryVariables>(
    UserAiChatsDocument,
    options
  )
}
export type UserAiChatsQueryHookResult = ReturnType<typeof useUserAiChatsQuery>
export type UserAiChatsLazyQueryHookResult = ReturnType<typeof useUserAiChatsLazyQuery>
export type UserAiChatsQueryResult = Apollo.QueryResult<
  UserAiChatsQuery,
  UserAiChatsQueryVariables
>
export const CreateTestUsersDocument = gql`
  mutation CreateTestUsers($input: CreateTestUsersInput!) {
    createTestUsers(input: $input) {
      success
      errors
      subscription {
        id
        planName
        numSeats
      }
      subscriptionMembers {
        id
        email
        status
        user {
          id
          email
        }
      }
      subscriptionOwner {
        id
        email
      }
    }
  }
`
export type CreateTestUsersMutationFn = Apollo.MutationFunction<
  CreateTestUsersMutation,
  CreateTestUsersMutationVariables
>

/**
 * __useCreateTestUsersMutation__
 *
 * To run a mutation, you first call `useCreateTestUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestUsersMutation, { data, loading, error }] = useCreateTestUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTestUsersMutation,
    CreateTestUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTestUsersMutation, CreateTestUsersMutationVariables>(
    CreateTestUsersDocument,
    options
  )
}
export type CreateTestUsersMutationHookResult = ReturnType<
  typeof useCreateTestUsersMutation
>
export type CreateTestUsersMutationResult = Apollo.MutationResult<CreateTestUsersMutation>
export type CreateTestUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateTestUsersMutation,
  CreateTestUsersMutationVariables
>
export const GenerateAiGoalsDocument = gql`
  mutation generateAiGoals($input: GenerateAiGoalsInput!) {
    generateAiGoals(input: $input) {
      success
      coachingSession {
        id
        data
        externalId
      }
    }
  }
`
export type GenerateAiGoalsMutationFn = Apollo.MutationFunction<
  GenerateAiGoalsMutation,
  GenerateAiGoalsMutationVariables
>

/**
 * __useGenerateAiGoalsMutation__
 *
 * To run a mutation, you first call `useGenerateAiGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiGoalsMutation, { data, loading, error }] = useGenerateAiGoalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiGoalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAiGoalsMutation,
    GenerateAiGoalsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GenerateAiGoalsMutation, GenerateAiGoalsMutationVariables>(
    GenerateAiGoalsDocument,
    options
  )
}
export type GenerateAiGoalsMutationHookResult = ReturnType<
  typeof useGenerateAiGoalsMutation
>
export type GenerateAiGoalsMutationResult = Apollo.MutationResult<GenerateAiGoalsMutation>
export type GenerateAiGoalsMutationOptions = Apollo.BaseMutationOptions<
  GenerateAiGoalsMutation,
  GenerateAiGoalsMutationVariables
>
export const UpdateCoachingSessionDocument = gql`
  mutation UpdateCoachingSession($input: UpdateCoachingSessionInput!) {
    updateCoachingSession(input: $input) {
      coachingSession {
        id
        data
        externalId
      }
    }
  }
`
export type UpdateCoachingSessionMutationFn = Apollo.MutationFunction<
  UpdateCoachingSessionMutation,
  UpdateCoachingSessionMutationVariables
>

/**
 * __useUpdateCoachingSessionMutation__
 *
 * To run a mutation, you first call `useUpdateCoachingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachingSessionMutation, { data, loading, error }] = useUpdateCoachingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoachingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachingSessionMutation,
    UpdateCoachingSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCoachingSessionMutation,
    UpdateCoachingSessionMutationVariables
  >(UpdateCoachingSessionDocument, options)
}
export type UpdateCoachingSessionMutationHookResult = ReturnType<
  typeof useUpdateCoachingSessionMutation
>
export type UpdateCoachingSessionMutationResult =
  Apollo.MutationResult<UpdateCoachingSessionMutation>
export type UpdateCoachingSessionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachingSessionMutation,
  UpdateCoachingSessionMutationVariables
>
export const GenerateAiInferenceDocument = gql`
  mutation generateAiInference($input: GenerateAiInferenceInput!) {
    generateAiInference(input: $input) {
      success
    }
  }
`
export type GenerateAiInferenceMutationFn = Apollo.MutationFunction<
  GenerateAiInferenceMutation,
  GenerateAiInferenceMutationVariables
>

/**
 * __useGenerateAiInferenceMutation__
 *
 * To run a mutation, you first call `useGenerateAiInferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiInferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiInferenceMutation, { data, loading, error }] = useGenerateAiInferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiInferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAiInferenceMutation,
    GenerateAiInferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GenerateAiInferenceMutation,
    GenerateAiInferenceMutationVariables
  >(GenerateAiInferenceDocument, options)
}
export type GenerateAiInferenceMutationHookResult = ReturnType<
  typeof useGenerateAiInferenceMutation
>
export type GenerateAiInferenceMutationResult =
  Apollo.MutationResult<GenerateAiInferenceMutation>
export type GenerateAiInferenceMutationOptions = Apollo.BaseMutationOptions<
  GenerateAiInferenceMutation,
  GenerateAiInferenceMutationVariables
>
export const RemoveAiChatDocument = gql`
  mutation removeAiChat($input: RemoveAiChatInput!) {
    removeAiChat(input: $input) {
      id
    }
  }
`
export type RemoveAiChatMutationFn = Apollo.MutationFunction<
  RemoveAiChatMutation,
  RemoveAiChatMutationVariables
>

/**
 * __useRemoveAiChatMutation__
 *
 * To run a mutation, you first call `useRemoveAiChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAiChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAiChatMutation, { data, loading, error }] = useRemoveAiChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAiChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAiChatMutation,
    RemoveAiChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveAiChatMutation, RemoveAiChatMutationVariables>(
    RemoveAiChatDocument,
    options
  )
}
export type RemoveAiChatMutationHookResult = ReturnType<typeof useRemoveAiChatMutation>
export type RemoveAiChatMutationResult = Apollo.MutationResult<RemoveAiChatMutation>
export type RemoveAiChatMutationOptions = Apollo.BaseMutationOptions<
  RemoveAiChatMutation,
  RemoveAiChatMutationVariables
>
export const RenameAiChatDocument = gql`
  mutation renameAiChat($input: RenameAiChatInput!) {
    renameAiChat(input: $input) {
      aiChat {
        id
        title
        createdAt
      }
    }
  }
`
export type RenameAiChatMutationFn = Apollo.MutationFunction<
  RenameAiChatMutation,
  RenameAiChatMutationVariables
>

/**
 * __useRenameAiChatMutation__
 *
 * To run a mutation, you first call `useRenameAiChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAiChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAiChatMutation, { data, loading, error }] = useRenameAiChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameAiChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameAiChatMutation,
    RenameAiChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RenameAiChatMutation, RenameAiChatMutationVariables>(
    RenameAiChatDocument,
    options
  )
}
export type RenameAiChatMutationHookResult = ReturnType<typeof useRenameAiChatMutation>
export type RenameAiChatMutationResult = Apollo.MutationResult<RenameAiChatMutation>
export type RenameAiChatMutationOptions = Apollo.BaseMutationOptions<
  RenameAiChatMutation,
  RenameAiChatMutationVariables
>
export const UpdateAiPersonalizationDocument = gql`
  mutation updateAiPersonalization($input: UpdateAiPersonalizationInput!) {
    updateAiPersonalization(input: $input) {
      aiPersonalization {
        id
        role
        company
        audience
        industry
        additionalDetails
        enabled
      }
      errors
    }
  }
`
export type UpdateAiPersonalizationMutationFn = Apollo.MutationFunction<
  UpdateAiPersonalizationMutation,
  UpdateAiPersonalizationMutationVariables
>

/**
 * __useUpdateAiPersonalizationMutation__
 *
 * To run a mutation, you first call `useUpdateAiPersonalizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiPersonalizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiPersonalizationMutation, { data, loading, error }] = useUpdateAiPersonalizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAiPersonalizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAiPersonalizationMutation,
    UpdateAiPersonalizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAiPersonalizationMutation,
    UpdateAiPersonalizationMutationVariables
  >(UpdateAiPersonalizationDocument, options)
}
export type UpdateAiPersonalizationMutationHookResult = ReturnType<
  typeof useUpdateAiPersonalizationMutation
>
export type UpdateAiPersonalizationMutationResult =
  Apollo.MutationResult<UpdateAiPersonalizationMutation>
export type UpdateAiPersonalizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAiPersonalizationMutation,
  UpdateAiPersonalizationMutationVariables
>
export const AiSessionHistoryDocument = gql`
  query AiSessionHistory($sessionId: ID!) {
    aiSessionHistory(sessionId: $sessionId) {
      id
      content
      role
      data
      debug
      mode
      modeOptions
      documents
      isPredefined
    }
  }
`

/**
 * __useAiSessionHistoryQuery__
 *
 * To run a query within a React component, call `useAiSessionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiSessionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiSessionHistoryQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAiSessionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AiSessionHistoryQuery,
    AiSessionHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AiSessionHistoryQuery, AiSessionHistoryQueryVariables>(
    AiSessionHistoryDocument,
    options
  )
}
export function useAiSessionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiSessionHistoryQuery,
    AiSessionHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AiSessionHistoryQuery, AiSessionHistoryQueryVariables>(
    AiSessionHistoryDocument,
    options
  )
}
export type AiSessionHistoryQueryHookResult = ReturnType<typeof useAiSessionHistoryQuery>
export type AiSessionHistoryLazyQueryHookResult = ReturnType<
  typeof useAiSessionHistoryLazyQuery
>
export type AiSessionHistoryQueryResult = Apollo.QueryResult<
  AiSessionHistoryQuery,
  AiSessionHistoryQueryVariables
>
export const CourseInfoForTrackingDocument = gql`
  query courseInfoForTracking($slug: String!) {
    course(slug: $slug) {
      id
      title
      slug
    }
  }
`

/**
 * __useCourseInfoForTrackingQuery__
 *
 * To run a query within a React component, call `useCourseInfoForTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseInfoForTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseInfoForTrackingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseInfoForTrackingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseInfoForTrackingQuery,
    CourseInfoForTrackingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseInfoForTrackingQuery, CourseInfoForTrackingQueryVariables>(
    CourseInfoForTrackingDocument,
    options
  )
}
export function useCourseInfoForTrackingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseInfoForTrackingQuery,
    CourseInfoForTrackingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseInfoForTrackingQuery,
    CourseInfoForTrackingQueryVariables
  >(CourseInfoForTrackingDocument, options)
}
export type CourseInfoForTrackingQueryHookResult = ReturnType<
  typeof useCourseInfoForTrackingQuery
>
export type CourseInfoForTrackingLazyQueryHookResult = ReturnType<
  typeof useCourseInfoForTrackingLazyQuery
>
export type CourseInfoForTrackingQueryResult = Apollo.QueryResult<
  CourseInfoForTrackingQuery,
  CourseInfoForTrackingQueryVariables
>
export const CourseSessionInfoForTrackingDocument = gql`
  query courseSessionInfoForTracking($courseSessionId: ID!) {
    courseSession(courseSessionId: $courseSessionId) {
      id
      hasStarted
      hasEnded
    }
  }
`

/**
 * __useCourseSessionInfoForTrackingQuery__
 *
 * To run a query within a React component, call `useCourseSessionInfoForTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSessionInfoForTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSessionInfoForTrackingQuery({
 *   variables: {
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseSessionInfoForTrackingQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseSessionInfoForTrackingQuery,
    CourseSessionInfoForTrackingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseSessionInfoForTrackingQuery,
    CourseSessionInfoForTrackingQueryVariables
  >(CourseSessionInfoForTrackingDocument, options)
}
export function useCourseSessionInfoForTrackingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSessionInfoForTrackingQuery,
    CourseSessionInfoForTrackingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseSessionInfoForTrackingQuery,
    CourseSessionInfoForTrackingQueryVariables
  >(CourseSessionInfoForTrackingDocument, options)
}
export type CourseSessionInfoForTrackingQueryHookResult = ReturnType<
  typeof useCourseSessionInfoForTrackingQuery
>
export type CourseSessionInfoForTrackingLazyQueryHookResult = ReturnType<
  typeof useCourseSessionInfoForTrackingLazyQuery
>
export type CourseSessionInfoForTrackingQueryResult = Apollo.QueryResult<
  CourseSessionInfoForTrackingQuery,
  CourseSessionInfoForTrackingQueryVariables
>
export const AiExchangeChatIdForExtIdDocument = gql`
  query aiExchangeChatIdForExtId($chatId: ID!) {
    exchangeChatIdForExtId(chatId: $chatId)
  }
`

/**
 * __useAiExchangeChatIdForExtIdQuery__
 *
 * To run a query within a React component, call `useAiExchangeChatIdForExtIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiExchangeChatIdForExtIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiExchangeChatIdForExtIdQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useAiExchangeChatIdForExtIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AiExchangeChatIdForExtIdQuery,
    AiExchangeChatIdForExtIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AiExchangeChatIdForExtIdQuery,
    AiExchangeChatIdForExtIdQueryVariables
  >(AiExchangeChatIdForExtIdDocument, options)
}
export function useAiExchangeChatIdForExtIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiExchangeChatIdForExtIdQuery,
    AiExchangeChatIdForExtIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AiExchangeChatIdForExtIdQuery,
    AiExchangeChatIdForExtIdQueryVariables
  >(AiExchangeChatIdForExtIdDocument, options)
}
export type AiExchangeChatIdForExtIdQueryHookResult = ReturnType<
  typeof useAiExchangeChatIdForExtIdQuery
>
export type AiExchangeChatIdForExtIdLazyQueryHookResult = ReturnType<
  typeof useAiExchangeChatIdForExtIdLazyQuery
>
export type AiExchangeChatIdForExtIdQueryResult = Apollo.QueryResult<
  AiExchangeChatIdForExtIdQuery,
  AiExchangeChatIdForExtIdQueryVariables
>
export const AiPersonalizationDocument = gql`
  query aiPersonalization($userId: ID) {
    aiPersonalization(userId: $userId) {
      id
      role
      company
      audience
      industry
      additionalDetails
      enabled
    }
  }
`

/**
 * __useAiPersonalizationQuery__
 *
 * To run a query within a React component, call `useAiPersonalizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiPersonalizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiPersonalizationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAiPersonalizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AiPersonalizationQuery,
    AiPersonalizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AiPersonalizationQuery, AiPersonalizationQueryVariables>(
    AiPersonalizationDocument,
    options
  )
}
export function useAiPersonalizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiPersonalizationQuery,
    AiPersonalizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AiPersonalizationQuery, AiPersonalizationQueryVariables>(
    AiPersonalizationDocument,
    options
  )
}
export type AiPersonalizationQueryHookResult = ReturnType<
  typeof useAiPersonalizationQuery
>
export type AiPersonalizationLazyQueryHookResult = ReturnType<
  typeof useAiPersonalizationLazyQuery
>
export type AiPersonalizationQueryResult = Apollo.QueryResult<
  AiPersonalizationQuery,
  AiPersonalizationQueryVariables
>
export const AiRecentChatsDocument = gql`
  query aiRecentChats($userId: ID, $first: Int, $after: String) {
    recentChats(userId: $userId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          extId
          title
          createdAt
        }
      }
    }
  }
`

/**
 * __useAiRecentChatsQuery__
 *
 * To run a query within a React component, call `useAiRecentChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiRecentChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiRecentChatsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAiRecentChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<AiRecentChatsQuery, AiRecentChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AiRecentChatsQuery, AiRecentChatsQueryVariables>(
    AiRecentChatsDocument,
    options
  )
}
export function useAiRecentChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiRecentChatsQuery,
    AiRecentChatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AiRecentChatsQuery, AiRecentChatsQueryVariables>(
    AiRecentChatsDocument,
    options
  )
}
export type AiRecentChatsQueryHookResult = ReturnType<typeof useAiRecentChatsQuery>
export type AiRecentChatsLazyQueryHookResult = ReturnType<
  typeof useAiRecentChatsLazyQuery
>
export type AiRecentChatsQueryResult = Apollo.QueryResult<
  AiRecentChatsQuery,
  AiRecentChatsQueryVariables
>
export const AiRecentChatsCountDocument = gql`
  query aiRecentChatsCount {
    recentChatsCount
  }
`

/**
 * __useAiRecentChatsCountQuery__
 *
 * To run a query within a React component, call `useAiRecentChatsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiRecentChatsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiRecentChatsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAiRecentChatsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AiRecentChatsCountQuery,
    AiRecentChatsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AiRecentChatsCountQuery, AiRecentChatsCountQueryVariables>(
    AiRecentChatsCountDocument,
    options
  )
}
export function useAiRecentChatsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AiRecentChatsCountQuery,
    AiRecentChatsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AiRecentChatsCountQuery, AiRecentChatsCountQueryVariables>(
    AiRecentChatsCountDocument,
    options
  )
}
export type AiRecentChatsCountQueryHookResult = ReturnType<
  typeof useAiRecentChatsCountQuery
>
export type AiRecentChatsCountLazyQueryHookResult = ReturnType<
  typeof useAiRecentChatsCountLazyQuery
>
export type AiRecentChatsCountQueryResult = Apollo.QueryResult<
  AiRecentChatsCountQuery,
  AiRecentChatsCountQueryVariables
>
export const SuggestedPromptsDocument = gql`
  query SuggestedPrompts($path: String) {
    suggestedPrompts(path: $path)
  }
`

/**
 * __useSuggestedPromptsQuery__
 *
 * To run a query within a React component, call `useSuggestedPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedPromptsQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSuggestedPromptsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestedPromptsQuery,
    SuggestedPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SuggestedPromptsQuery, SuggestedPromptsQueryVariables>(
    SuggestedPromptsDocument,
    options
  )
}
export function useSuggestedPromptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestedPromptsQuery,
    SuggestedPromptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SuggestedPromptsQuery, SuggestedPromptsQueryVariables>(
    SuggestedPromptsDocument,
    options
  )
}
export type SuggestedPromptsQueryHookResult = ReturnType<typeof useSuggestedPromptsQuery>
export type SuggestedPromptsLazyQueryHookResult = ReturnType<
  typeof useSuggestedPromptsLazyQuery
>
export type SuggestedPromptsQueryResult = Apollo.QueryResult<
  SuggestedPromptsQuery,
  SuggestedPromptsQueryVariables
>
export const QueryDocument = gql`
  query Query($userId: ID!) {
    savedArtifactsForUser(userId: $userId) {
      id
      sanityId
    }
    viewedArtifactsForUser(userId: $userId) {
      artifactId
    }
    savedCountPerArtifact {
      artifactId
      count
    }
  }
`

/**
 * __useQueryQuery__
 *
 * To run a query within a React component, call `useQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useQueryQuery(
  baseOptions: Apollo.QueryHookOptions<QueryQuery, QueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options)
}
export function useQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQuery, QueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QueryQuery, QueryQueryVariables>(QueryDocument, options)
}
export type QueryQueryHookResult = ReturnType<typeof useQueryQuery>
export type QueryLazyQueryHookResult = ReturnType<typeof useQueryLazyQuery>
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>
export const RecentlyViewedContentDocument = gql`
  query RecentlyViewedContent {
    recentlyViewedContent {
      name
      path
    }
  }
`

/**
 * __useRecentlyViewedContentQuery__
 *
 * To run a query within a React component, call `useRecentlyViewedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyViewedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyViewedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentlyViewedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentlyViewedContentQuery,
    RecentlyViewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecentlyViewedContentQuery, RecentlyViewedContentQueryVariables>(
    RecentlyViewedContentDocument,
    options
  )
}
export function useRecentlyViewedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentlyViewedContentQuery,
    RecentlyViewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecentlyViewedContentQuery,
    RecentlyViewedContentQueryVariables
  >(RecentlyViewedContentDocument, options)
}
export type RecentlyViewedContentQueryHookResult = ReturnType<
  typeof useRecentlyViewedContentQuery
>
export type RecentlyViewedContentLazyQueryHookResult = ReturnType<
  typeof useRecentlyViewedContentLazyQuery
>
export type RecentlyViewedContentQueryResult = Apollo.QueryResult<
  RecentlyViewedContentQuery,
  RecentlyViewedContentQueryVariables
>
export const ActivateSubscriptionDocument = gql`
  mutation ActivateSubscription($input: ActivateSubscriptionInput!) {
    activateSubscription(input: $input) {
      errors
      redirectPath
    }
  }
`
export type ActivateSubscriptionMutationFn = Apollo.MutationFunction<
  ActivateSubscriptionMutation,
  ActivateSubscriptionMutationVariables
>

/**
 * __useActivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSubscriptionMutation, { data, loading, error }] = useActivateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateSubscriptionMutation,
    ActivateSubscriptionMutationVariables
  >(ActivateSubscriptionDocument, options)
}
export type ActivateSubscriptionMutationHookResult = ReturnType<
  typeof useActivateSubscriptionMutation
>
export type ActivateSubscriptionMutationResult =
  Apollo.MutationResult<ActivateSubscriptionMutation>
export type ActivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ActivateSubscriptionMutation,
  ActivateSubscriptionMutationVariables
>
export const AddAnnotationReactionDocument = gql`
  mutation addAnnotationReaction($input: AddAnnotationReactionInput!) {
    addAnnotationReaction(input: $input) {
      errors
    }
  }
`
export type AddAnnotationReactionMutationFn = Apollo.MutationFunction<
  AddAnnotationReactionMutation,
  AddAnnotationReactionMutationVariables
>

/**
 * __useAddAnnotationReactionMutation__
 *
 * To run a mutation, you first call `useAddAnnotationReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnotationReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnotationReactionMutation, { data, loading, error }] = useAddAnnotationReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAnnotationReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAnnotationReactionMutation,
    AddAnnotationReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddAnnotationReactionMutation,
    AddAnnotationReactionMutationVariables
  >(AddAnnotationReactionDocument, options)
}
export type AddAnnotationReactionMutationHookResult = ReturnType<
  typeof useAddAnnotationReactionMutation
>
export type AddAnnotationReactionMutationResult =
  Apollo.MutationResult<AddAnnotationReactionMutation>
export type AddAnnotationReactionMutationOptions = Apollo.BaseMutationOptions<
  AddAnnotationReactionMutation,
  AddAnnotationReactionMutationVariables
>
export const AddArtifactCommentDocument = gql`
  mutation addArtifactComment($input: AddArtifactCommentInput!) {
    addArtifactComment(input: $input) {
      artifactComment {
        id
        createdAt
      }
      errors
    }
  }
`
export type AddArtifactCommentMutationFn = Apollo.MutationFunction<
  AddArtifactCommentMutation,
  AddArtifactCommentMutationVariables
>

/**
 * __useAddArtifactCommentMutation__
 *
 * To run a mutation, you first call `useAddArtifactCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtifactCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtifactCommentMutation, { data, loading, error }] = useAddArtifactCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddArtifactCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddArtifactCommentMutation,
    AddArtifactCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddArtifactCommentMutation,
    AddArtifactCommentMutationVariables
  >(AddArtifactCommentDocument, options)
}
export type AddArtifactCommentMutationHookResult = ReturnType<
  typeof useAddArtifactCommentMutation
>
export type AddArtifactCommentMutationResult =
  Apollo.MutationResult<AddArtifactCommentMutation>
export type AddArtifactCommentMutationOptions = Apollo.BaseMutationOptions<
  AddArtifactCommentMutation,
  AddArtifactCommentMutationVariables
>
export const AddArtifactCommentReactionDocument = gql`
  mutation addArtifactCommentReaction($input: AddArtifactCommentReactionInput!) {
    addArtifactCommentReaction(input: $input) {
      errors
    }
  }
`
export type AddArtifactCommentReactionMutationFn = Apollo.MutationFunction<
  AddArtifactCommentReactionMutation,
  AddArtifactCommentReactionMutationVariables
>

/**
 * __useAddArtifactCommentReactionMutation__
 *
 * To run a mutation, you first call `useAddArtifactCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtifactCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtifactCommentReactionMutation, { data, loading, error }] = useAddArtifactCommentReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddArtifactCommentReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddArtifactCommentReactionMutation,
    AddArtifactCommentReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddArtifactCommentReactionMutation,
    AddArtifactCommentReactionMutationVariables
  >(AddArtifactCommentReactionDocument, options)
}
export type AddArtifactCommentReactionMutationHookResult = ReturnType<
  typeof useAddArtifactCommentReactionMutation
>
export type AddArtifactCommentReactionMutationResult =
  Apollo.MutationResult<AddArtifactCommentReactionMutation>
export type AddArtifactCommentReactionMutationOptions = Apollo.BaseMutationOptions<
  AddArtifactCommentReactionMutation,
  AddArtifactCommentReactionMutationVariables
>
export const AddReactionDocument = gql`
  mutation addReaction($input: AddReactionInput!) {
    addReaction(input: $input) {
      reaction {
        id
        kind
        user {
          id
          profile {
            fullName
          }
        }
      }
    }
  }
`
export type AddReactionMutationFn = Apollo.MutationFunction<
  AddReactionMutation,
  AddReactionMutationVariables
>

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReactionMutation,
    AddReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddReactionMutation, AddReactionMutationVariables>(
    AddReactionDocument,
    options
  )
}
export type AddReactionMutationHookResult = ReturnType<typeof useAddReactionMutation>
export type AddReactionMutationResult = Apollo.MutationResult<AddReactionMutation>
export type AddReactionMutationOptions = Apollo.BaseMutationOptions<
  AddReactionMutation,
  AddReactionMutationVariables
>
export const ApproveSubscriptionJoinRequestDocument = gql`
  mutation approveSubscriptionJoinRequest($input: ApproveSubscriptionJoinRequestInput!) {
    approveSubscriptionJoinRequest(input: $input) {
      error
      subscriptionJoinRequest {
        id
        status
        subscription {
          id
          seats {
            id
            subscriptionMember {
              id
              userId
              status
              email
            }
          }
        }
        user {
          id
          fullName
        }
      }
    }
  }
`
export type ApproveSubscriptionJoinRequestMutationFn = Apollo.MutationFunction<
  ApproveSubscriptionJoinRequestMutation,
  ApproveSubscriptionJoinRequestMutationVariables
>

/**
 * __useApproveSubscriptionJoinRequestMutation__
 *
 * To run a mutation, you first call `useApproveSubscriptionJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubscriptionJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubscriptionJoinRequestMutation, { data, loading, error }] = useApproveSubscriptionJoinRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSubscriptionJoinRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveSubscriptionJoinRequestMutation,
    ApproveSubscriptionJoinRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveSubscriptionJoinRequestMutation,
    ApproveSubscriptionJoinRequestMutationVariables
  >(ApproveSubscriptionJoinRequestDocument, options)
}
export type ApproveSubscriptionJoinRequestMutationHookResult = ReturnType<
  typeof useApproveSubscriptionJoinRequestMutation
>
export type ApproveSubscriptionJoinRequestMutationResult =
  Apollo.MutationResult<ApproveSubscriptionJoinRequestMutation>
export type ApproveSubscriptionJoinRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveSubscriptionJoinRequestMutation,
  ApproveSubscriptionJoinRequestMutationVariables
>
export const AssignCohortCreditDocument = gql`
  mutation AssignCohortCredit($input: AssignCohortCreditInput!) {
    assignCohortCredit(input: $input) {
      seat {
        id
      }
      errors
    }
  }
`
export type AssignCohortCreditMutationFn = Apollo.MutationFunction<
  AssignCohortCreditMutation,
  AssignCohortCreditMutationVariables
>

/**
 * __useAssignCohortCreditMutation__
 *
 * To run a mutation, you first call `useAssignCohortCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCohortCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCohortCreditMutation, { data, loading, error }] = useAssignCohortCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCohortCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignCohortCreditMutation,
    AssignCohortCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssignCohortCreditMutation,
    AssignCohortCreditMutationVariables
  >(AssignCohortCreditDocument, options)
}
export type AssignCohortCreditMutationHookResult = ReturnType<
  typeof useAssignCohortCreditMutation
>
export type AssignCohortCreditMutationResult =
  Apollo.MutationResult<AssignCohortCreditMutation>
export type AssignCohortCreditMutationOptions = Apollo.BaseMutationOptions<
  AssignCohortCreditMutation,
  AssignCohortCreditMutationVariables
>
export const AssignSeatDocument = gql`
  mutation AssignSeat($input: AssignSeatInput!) {
    assignSeat(input: $input) {
      seat {
        id
        subscriptionMember {
          id
          userId
          status
          email
          role
          createdAt
          user {
            email
            workEmail
            fullName
            profile {
              avatarUrl
            }
            is {
              enrolledInLiveProgram
            }
          }
        }
      }
      errors
    }
  }
`
export type AssignSeatMutationFn = Apollo.MutationFunction<
  AssignSeatMutation,
  AssignSeatMutationVariables
>

/**
 * __useAssignSeatMutation__
 *
 * To run a mutation, you first call `useAssignSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSeatMutation, { data, loading, error }] = useAssignSeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignSeatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignSeatMutation,
    AssignSeatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AssignSeatMutation, AssignSeatMutationVariables>(
    AssignSeatDocument,
    options
  )
}
export type AssignSeatMutationHookResult = ReturnType<typeof useAssignSeatMutation>
export type AssignSeatMutationResult = Apollo.MutationResult<AssignSeatMutation>
export type AssignSeatMutationOptions = Apollo.BaseMutationOptions<
  AssignSeatMutation,
  AssignSeatMutationVariables
>
export const BatchRsvpDocument = gql`
  mutation BatchRsvp($input: BatchInput!) {
    batchRsvp(input: $input) {
      __typename
    }
  }
`
export type BatchRsvpMutationFn = Apollo.MutationFunction<
  BatchRsvpMutation,
  BatchRsvpMutationVariables
>

/**
 * __useBatchRsvpMutation__
 *
 * To run a mutation, you first call `useBatchRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchRsvpMutation, { data, loading, error }] = useBatchRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchRsvpMutation(
  baseOptions?: Apollo.MutationHookOptions<BatchRsvpMutation, BatchRsvpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BatchRsvpMutation, BatchRsvpMutationVariables>(
    BatchRsvpDocument,
    options
  )
}
export type BatchRsvpMutationHookResult = ReturnType<typeof useBatchRsvpMutation>
export type BatchRsvpMutationResult = Apollo.MutationResult<BatchRsvpMutation>
export type BatchRsvpMutationOptions = Apollo.BaseMutationOptions<
  BatchRsvpMutation,
  BatchRsvpMutationVariables
>
export const BookmarkFolderByIdDocument = gql`
  query BookmarkFolderById($folderId: ID!) {
    bookmarkFolder(folderId: $folderId) {
      id
      ...BookmarkFolderWithSharingParts
    }
  }
  ${BookmarkFolderWithSharingPartsFragmentDoc}
`

/**
 * __useBookmarkFolderByIdQuery__
 *
 * To run a query within a React component, call `useBookmarkFolderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkFolderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkFolderByIdQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useBookmarkFolderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookmarkFolderByIdQuery,
    BookmarkFolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookmarkFolderByIdQuery, BookmarkFolderByIdQueryVariables>(
    BookmarkFolderByIdDocument,
    options
  )
}
export function useBookmarkFolderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookmarkFolderByIdQuery,
    BookmarkFolderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookmarkFolderByIdQuery, BookmarkFolderByIdQueryVariables>(
    BookmarkFolderByIdDocument,
    options
  )
}
export type BookmarkFolderByIdQueryHookResult = ReturnType<
  typeof useBookmarkFolderByIdQuery
>
export type BookmarkFolderByIdLazyQueryHookResult = ReturnType<
  typeof useBookmarkFolderByIdLazyQuery
>
export type BookmarkFolderByIdQueryResult = Apollo.QueryResult<
  BookmarkFolderByIdQuery,
  BookmarkFolderByIdQueryVariables
>
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($input: SubscriptionCancelInput!) {
    cancelSubscription(input: $input) {
      id
      errors
    }
  }
`
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options)
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>
export const CancelSubscriptionMotivationDocument = gql`
  mutation CancelSubscriptionMotivation($input: SubscriptionCancelMotivationInput!) {
    cancelSubscriptionMotivation(input: $input) {
      id
      errors
    }
  }
`
export type CancelSubscriptionMotivationMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMotivationMutation,
  CancelSubscriptionMotivationMutationVariables
>

/**
 * __useCancelSubscriptionMotivationMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMotivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMotivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMotivationMutation, { data, loading, error }] = useCancelSubscriptionMotivationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMotivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMotivationMutation,
    CancelSubscriptionMotivationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelSubscriptionMotivationMutation,
    CancelSubscriptionMotivationMutationVariables
  >(CancelSubscriptionMotivationDocument, options)
}
export type CancelSubscriptionMotivationMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMotivationMutation
>
export type CancelSubscriptionMotivationMutationResult =
  Apollo.MutationResult<CancelSubscriptionMotivationMutation>
export type CancelSubscriptionMotivationMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMotivationMutation,
  CancelSubscriptionMotivationMutationVariables
>
export const ChoosePlanDocument = gql`
  mutation ChoosePlan($input: ChoosePlanInput!) {
    choosePlan(input: $input) {
      upcomingInvoice {
        subtotal
      }
      errors
    }
  }
`
export type ChoosePlanMutationFn = Apollo.MutationFunction<
  ChoosePlanMutation,
  ChoosePlanMutationVariables
>

/**
 * __useChoosePlanMutation__
 *
 * To run a mutation, you first call `useChoosePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChoosePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [choosePlanMutation, { data, loading, error }] = useChoosePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChoosePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChoosePlanMutation,
    ChoosePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChoosePlanMutation, ChoosePlanMutationVariables>(
    ChoosePlanDocument,
    options
  )
}
export type ChoosePlanMutationHookResult = ReturnType<typeof useChoosePlanMutation>
export type ChoosePlanMutationResult = Apollo.MutationResult<ChoosePlanMutation>
export type ChoosePlanMutationOptions = Apollo.BaseMutationOptions<
  ChoosePlanMutation,
  ChoosePlanMutationVariables
>
export const UpdateCmsSectionTagsDocument = gql`
  mutation updateCmsSectionTags($input: UpdateCmsSectionTagsInput!) {
    updateCmsSectionTags(input: $input) {
      id
    }
  }
`
export type UpdateCmsSectionTagsMutationFn = Apollo.MutationFunction<
  UpdateCmsSectionTagsMutation,
  UpdateCmsSectionTagsMutationVariables
>

/**
 * __useUpdateCmsSectionTagsMutation__
 *
 * To run a mutation, you first call `useUpdateCmsSectionTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCmsSectionTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCmsSectionTagsMutation, { data, loading, error }] = useUpdateCmsSectionTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCmsSectionTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCmsSectionTagsMutation,
    UpdateCmsSectionTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCmsSectionTagsMutation,
    UpdateCmsSectionTagsMutationVariables
  >(UpdateCmsSectionTagsDocument, options)
}
export type UpdateCmsSectionTagsMutationHookResult = ReturnType<
  typeof useUpdateCmsSectionTagsMutation
>
export type UpdateCmsSectionTagsMutationResult =
  Apollo.MutationResult<UpdateCmsSectionTagsMutation>
export type UpdateCmsSectionTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCmsSectionTagsMutation,
  UpdateCmsSectionTagsMutationVariables
>
export const CompleteProductTourDocument = gql`
  mutation completeProductTour($input: CompleteProductTourInput!) {
    completeProductTour(input: $input) {
      completedProductTours {
        bookmarkCreate
        collectionSaveBookmark
        collectionShare
        collectionViewContent
        collectionsTab
        teamCommentView
        searchTrainerViews
        savedItemsTab
        newSearchClicked
        usedReforgeAiGlobalEntryPoint
      }
    }
  }
`
export type CompleteProductTourMutationFn = Apollo.MutationFunction<
  CompleteProductTourMutation,
  CompleteProductTourMutationVariables
>

/**
 * __useCompleteProductTourMutation__
 *
 * To run a mutation, you first call `useCompleteProductTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProductTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProductTourMutation, { data, loading, error }] = useCompleteProductTourMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteProductTourMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProductTourMutation,
    CompleteProductTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CompleteProductTourMutation,
    CompleteProductTourMutationVariables
  >(CompleteProductTourDocument, options)
}
export type CompleteProductTourMutationHookResult = ReturnType<
  typeof useCompleteProductTourMutation
>
export type CompleteProductTourMutationResult =
  Apollo.MutationResult<CompleteProductTourMutation>
export type CompleteProductTourMutationOptions = Apollo.BaseMutationOptions<
  CompleteProductTourMutation,
  CompleteProductTourMutationVariables
>
export const ConfirmUserMergeDocument = gql`
  mutation confirmUserMerge($input: ConfirmUserMergeInput!) {
    confirmUserMerge(input: $input) {
      success
      userMerge {
        id
        mergedPrimaryEmail
      }
    }
  }
`
export type ConfirmUserMergeMutationFn = Apollo.MutationFunction<
  ConfirmUserMergeMutation,
  ConfirmUserMergeMutationVariables
>

/**
 * __useConfirmUserMergeMutation__
 *
 * To run a mutation, you first call `useConfirmUserMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMergeMutation, { data, loading, error }] = useConfirmUserMergeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserMergeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUserMergeMutation,
    ConfirmUserMergeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ConfirmUserMergeMutation, ConfirmUserMergeMutationVariables>(
    ConfirmUserMergeDocument,
    options
  )
}
export type ConfirmUserMergeMutationHookResult = ReturnType<
  typeof useConfirmUserMergeMutation
>
export type ConfirmUserMergeMutationResult =
  Apollo.MutationResult<ConfirmUserMergeMutation>
export type ConfirmUserMergeMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserMergeMutation,
  ConfirmUserMergeMutationVariables
>
export const CreateAccountDocument = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      success
    }
  }
`
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(
    CreateAccountDocument,
    options
  )
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>
export const CreateBookmarkDocument = gql`
  mutation createBookmark($input: CreateBookmarkInput!) {
    createBookmark(input: $input) {
      bookmark {
        ...BookmarkParts
        ...ProgramBookmarkParts
        lastViewedContentAtInteger
      }
      collectionActivity {
        ...CollectionActivityParts
      }
      errors
    }
  }
  ${BookmarkPartsFragmentDoc}
  ${ProgramBookmarkPartsFragmentDoc}
  ${CollectionActivityPartsFragmentDoc}
`
export type CreateBookmarkMutationFn = Apollo.MutationFunction<
  CreateBookmarkMutation,
  CreateBookmarkMutationVariables
>

/**
 * __useCreateBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkMutation, { data, loading, error }] = useCreateBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookmarkMutation,
    CreateBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBookmarkMutation, CreateBookmarkMutationVariables>(
    CreateBookmarkDocument,
    options
  )
}
export type CreateBookmarkMutationHookResult = ReturnType<
  typeof useCreateBookmarkMutation
>
export type CreateBookmarkMutationResult = Apollo.MutationResult<CreateBookmarkMutation>
export type CreateBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreateBookmarkMutation,
  CreateBookmarkMutationVariables
>
export const CreateBookmarkFolderDocument = gql`
  mutation createBookmarkFolder($input: CreateBookmarkFolderInput!) {
    createBookmarkFolder(input: $input) {
      bookmarkFolder {
        ...BookmarkFolderParts
      }
      errors
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
`
export type CreateBookmarkFolderMutationFn = Apollo.MutationFunction<
  CreateBookmarkFolderMutation,
  CreateBookmarkFolderMutationVariables
>

/**
 * __useCreateBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkFolderMutation, { data, loading, error }] = useCreateBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookmarkFolderMutation,
    CreateBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBookmarkFolderMutation,
    CreateBookmarkFolderMutationVariables
  >(CreateBookmarkFolderDocument, options)
}
export type CreateBookmarkFolderMutationHookResult = ReturnType<
  typeof useCreateBookmarkFolderMutation
>
export type CreateBookmarkFolderMutationResult =
  Apollo.MutationResult<CreateBookmarkFolderMutation>
export type CreateBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateBookmarkFolderMutation,
  CreateBookmarkFolderMutationVariables
>
export const CreateCohortPostDocument = gql`
  mutation CreateCohortPost($input: CreateCohortPostInput!) {
    createCohortPost(input: $input) {
      id
    }
  }
`
export type CreateCohortPostMutationFn = Apollo.MutationFunction<
  CreateCohortPostMutation,
  CreateCohortPostMutationVariables
>

/**
 * __useCreateCohortPostMutation__
 *
 * To run a mutation, you first call `useCreateCohortPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCohortPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCohortPostMutation, { data, loading, error }] = useCreateCohortPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCohortPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCohortPostMutation,
    CreateCohortPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCohortPostMutation, CreateCohortPostMutationVariables>(
    CreateCohortPostDocument,
    options
  )
}
export type CreateCohortPostMutationHookResult = ReturnType<
  typeof useCreateCohortPostMutation
>
export type CreateCohortPostMutationResult =
  Apollo.MutationResult<CreateCohortPostMutation>
export type CreateCohortPostMutationOptions = Apollo.BaseMutationOptions<
  CreateCohortPostMutation,
  CreateCohortPostMutationVariables
>
export const CreateCohortReplyDocument = gql`
  mutation CreateCohortReply($input: CreateCohortReplyInput!) {
    createCohortReply(input: $input) {
      id
    }
  }
`
export type CreateCohortReplyMutationFn = Apollo.MutationFunction<
  CreateCohortReplyMutation,
  CreateCohortReplyMutationVariables
>

/**
 * __useCreateCohortReplyMutation__
 *
 * To run a mutation, you first call `useCreateCohortReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCohortReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCohortReplyMutation, { data, loading, error }] = useCreateCohortReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCohortReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCohortReplyMutation,
    CreateCohortReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCohortReplyMutation,
    CreateCohortReplyMutationVariables
  >(CreateCohortReplyDocument, options)
}
export type CreateCohortReplyMutationHookResult = ReturnType<
  typeof useCreateCohortReplyMutation
>
export type CreateCohortReplyMutationResult =
  Apollo.MutationResult<CreateCohortReplyMutation>
export type CreateCohortReplyMutationOptions = Apollo.BaseMutationOptions<
  CreateCohortReplyMutation,
  CreateCohortReplyMutationVariables
>
export const CreateCourseEnrollmentDocument = gql`
  mutation CreateCourseEnrollment($input: CreateCourseEnrollmentInput!) {
    createCourseEnrollment(input: $input) {
      id
      errors
      courseSessionId
      redirectUrl
    }
  }
`
export type CreateCourseEnrollmentMutationFn = Apollo.MutationFunction<
  CreateCourseEnrollmentMutation,
  CreateCourseEnrollmentMutationVariables
>

/**
 * __useCreateCourseEnrollmentMutation__
 *
 * To run a mutation, you first call `useCreateCourseEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseEnrollmentMutation, { data, loading, error }] = useCreateCourseEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseEnrollmentMutation,
    CreateCourseEnrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCourseEnrollmentMutation,
    CreateCourseEnrollmentMutationVariables
  >(CreateCourseEnrollmentDocument, options)
}
export type CreateCourseEnrollmentMutationHookResult = ReturnType<
  typeof useCreateCourseEnrollmentMutation
>
export type CreateCourseEnrollmentMutationResult =
  Apollo.MutationResult<CreateCourseEnrollmentMutation>
export type CreateCourseEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseEnrollmentMutation,
  CreateCourseEnrollmentMutationVariables
>
export const CreateCourseSessionDocument = gql`
  mutation CreateCourseSession($input: CreateCourseSessionInput!) {
    createCourseSession(input: $input) {
      id
      errors
    }
  }
`
export type CreateCourseSessionMutationFn = Apollo.MutationFunction<
  CreateCourseSessionMutation,
  CreateCourseSessionMutationVariables
>

/**
 * __useCreateCourseSessionMutation__
 *
 * To run a mutation, you first call `useCreateCourseSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseSessionMutation, { data, loading, error }] = useCreateCourseSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseSessionMutation,
    CreateCourseSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCourseSessionMutation,
    CreateCourseSessionMutationVariables
  >(CreateCourseSessionDocument, options)
}
export type CreateCourseSessionMutationHookResult = ReturnType<
  typeof useCreateCourseSessionMutation
>
export type CreateCourseSessionMutationResult =
  Apollo.MutationResult<CreateCourseSessionMutation>
export type CreateCourseSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseSessionMutation,
  CreateCourseSessionMutationVariables
>
export const CreateCourseSessionSlackChannelDocument = gql`
  mutation CreateCourseSessionSlackChannel(
    $input: CreateCourseSessionSlackChannelInput!
  ) {
    createCourseSessionSlackChannel(input: $input) {
      id
      errors
    }
  }
`
export type CreateCourseSessionSlackChannelMutationFn = Apollo.MutationFunction<
  CreateCourseSessionSlackChannelMutation,
  CreateCourseSessionSlackChannelMutationVariables
>

/**
 * __useCreateCourseSessionSlackChannelMutation__
 *
 * To run a mutation, you first call `useCreateCourseSessionSlackChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseSessionSlackChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseSessionSlackChannelMutation, { data, loading, error }] = useCreateCourseSessionSlackChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseSessionSlackChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseSessionSlackChannelMutation,
    CreateCourseSessionSlackChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCourseSessionSlackChannelMutation,
    CreateCourseSessionSlackChannelMutationVariables
  >(CreateCourseSessionSlackChannelDocument, options)
}
export type CreateCourseSessionSlackChannelMutationHookResult = ReturnType<
  typeof useCreateCourseSessionSlackChannelMutation
>
export type CreateCourseSessionSlackChannelMutationResult =
  Apollo.MutationResult<CreateCourseSessionSlackChannelMutation>
export type CreateCourseSessionSlackChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseSessionSlackChannelMutation,
  CreateCourseSessionSlackChannelMutationVariables
>
export const CreateDraftSubscriptionJoinRequestDocument = gql`
  mutation createDraftSubscriptionJoinRequest(
    $input: CreateDraftSubscriptionJoinRequestInput!
  ) {
    createDraftSubscriptionJoinRequest(input: $input) {
      errors
      subscriptionJoinRequest {
        id
        status
        subscription {
          id
        }
        user {
          id
        }
      }
    }
  }
`
export type CreateDraftSubscriptionJoinRequestMutationFn = Apollo.MutationFunction<
  CreateDraftSubscriptionJoinRequestMutation,
  CreateDraftSubscriptionJoinRequestMutationVariables
>

/**
 * __useCreateDraftSubscriptionJoinRequestMutation__
 *
 * To run a mutation, you first call `useCreateDraftSubscriptionJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftSubscriptionJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftSubscriptionJoinRequestMutation, { data, loading, error }] = useCreateDraftSubscriptionJoinRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftSubscriptionJoinRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftSubscriptionJoinRequestMutation,
    CreateDraftSubscriptionJoinRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDraftSubscriptionJoinRequestMutation,
    CreateDraftSubscriptionJoinRequestMutationVariables
  >(CreateDraftSubscriptionJoinRequestDocument, options)
}
export type CreateDraftSubscriptionJoinRequestMutationHookResult = ReturnType<
  typeof useCreateDraftSubscriptionJoinRequestMutation
>
export type CreateDraftSubscriptionJoinRequestMutationResult =
  Apollo.MutationResult<CreateDraftSubscriptionJoinRequestMutation>
export type CreateDraftSubscriptionJoinRequestMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDraftSubscriptionJoinRequestMutation,
    CreateDraftSubscriptionJoinRequestMutationVariables
  >
export const CreateFiledBookmarkDocument = gql`
  mutation createFiledBookmark($input: CreateFiledBookmarkInput!) {
    createFiledBookmark(input: $input) {
      filedBookmark {
        id
        bookmarkFolderId
        bookmark {
          id
          ...ProgramBookmarkParts
        }
      }
      errors
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`
export type CreateFiledBookmarkMutationFn = Apollo.MutationFunction<
  CreateFiledBookmarkMutation,
  CreateFiledBookmarkMutationVariables
>

/**
 * __useCreateFiledBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateFiledBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFiledBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFiledBookmarkMutation, { data, loading, error }] = useCreateFiledBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFiledBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFiledBookmarkMutation,
    CreateFiledBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFiledBookmarkMutation,
    CreateFiledBookmarkMutationVariables
  >(CreateFiledBookmarkDocument, options)
}
export type CreateFiledBookmarkMutationHookResult = ReturnType<
  typeof useCreateFiledBookmarkMutation
>
export type CreateFiledBookmarkMutationResult =
  Apollo.MutationResult<CreateFiledBookmarkMutation>
export type CreateFiledBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreateFiledBookmarkMutation,
  CreateFiledBookmarkMutationVariables
>
export const CreateProgramInvitationDocument = gql`
  mutation createProgramInvitation($input: CreateProgramInvitationInput!) {
    createProgramInvitation(input: $input) {
      id
      errors
    }
  }
`
export type CreateProgramInvitationMutationFn = Apollo.MutationFunction<
  CreateProgramInvitationMutation,
  CreateProgramInvitationMutationVariables
>

/**
 * __useCreateProgramInvitationMutation__
 *
 * To run a mutation, you first call `useCreateProgramInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramInvitationMutation, { data, loading, error }] = useCreateProgramInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgramInvitationMutation,
    CreateProgramInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProgramInvitationMutation,
    CreateProgramInvitationMutationVariables
  >(CreateProgramInvitationDocument, options)
}
export type CreateProgramInvitationMutationHookResult = ReturnType<
  typeof useCreateProgramInvitationMutation
>
export type CreateProgramInvitationMutationResult =
  Apollo.MutationResult<CreateProgramInvitationMutation>
export type CreateProgramInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramInvitationMutation,
  CreateProgramInvitationMutationVariables
>
export const CreateRecommendationsDocument = gql`
  mutation CreateRecommendations($input: CreateRecommendationsInput!) {
    createRecommendations(input: $input) {
      errors
      success
    }
  }
`
export type CreateRecommendationsMutationFn = Apollo.MutationFunction<
  CreateRecommendationsMutation,
  CreateRecommendationsMutationVariables
>

/**
 * __useCreateRecommendationsMutation__
 *
 * To run a mutation, you first call `useCreateRecommendationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecommendationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecommendationsMutation, { data, loading, error }] = useCreateRecommendationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecommendationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRecommendationsMutation,
    CreateRecommendationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRecommendationsMutation,
    CreateRecommendationsMutationVariables
  >(CreateRecommendationsDocument, options)
}
export type CreateRecommendationsMutationHookResult = ReturnType<
  typeof useCreateRecommendationsMutation
>
export type CreateRecommendationsMutationResult =
  Apollo.MutationResult<CreateRecommendationsMutation>
export type CreateRecommendationsMutationOptions = Apollo.BaseMutationOptions<
  CreateRecommendationsMutation,
  CreateRecommendationsMutationVariables
>
export const CreateReplyDocument = gql`
  mutation createReply($input: CreateReplyInput!) {
    createReply(input: $input) {
      id
      parentId
      userAvatar
      userName
      userRoleWithCompany
      body
      bodyText
      userId
    }
  }
`
export type CreateReplyMutationFn = Apollo.MutationFunction<
  CreateReplyMutation,
  CreateReplyMutationVariables
>

/**
 * __useCreateReplyMutation__
 *
 * To run a mutation, you first call `useCreateReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplyMutation, { data, loading, error }] = useCreateReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReplyMutation,
    CreateReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateReplyMutation, CreateReplyMutationVariables>(
    CreateReplyDocument,
    options
  )
}
export type CreateReplyMutationHookResult = ReturnType<typeof useCreateReplyMutation>
export type CreateReplyMutationResult = Apollo.MutationResult<CreateReplyMutation>
export type CreateReplyMutationOptions = Apollo.BaseMutationOptions<
  CreateReplyMutation,
  CreateReplyMutationVariables
>
export const CreateSubscriptionJoinRequestDocument = gql`
  mutation createSubscriptionJoinRequest($input: CreateSubscriptionJoinRequestInput!) {
    createSubscriptionJoinRequest(input: $input) {
      errors
      subscriptionJoinRequest {
        id
        status
        subscription {
          id
        }
        user {
          id
        }
      }
    }
  }
`
export type CreateSubscriptionJoinRequestMutationFn = Apollo.MutationFunction<
  CreateSubscriptionJoinRequestMutation,
  CreateSubscriptionJoinRequestMutationVariables
>

/**
 * __useCreateSubscriptionJoinRequestMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionJoinRequestMutation, { data, loading, error }] = useCreateSubscriptionJoinRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionJoinRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionJoinRequestMutation,
    CreateSubscriptionJoinRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSubscriptionJoinRequestMutation,
    CreateSubscriptionJoinRequestMutationVariables
  >(CreateSubscriptionJoinRequestDocument, options)
}
export type CreateSubscriptionJoinRequestMutationHookResult = ReturnType<
  typeof useCreateSubscriptionJoinRequestMutation
>
export type CreateSubscriptionJoinRequestMutationResult =
  Apollo.MutationResult<CreateSubscriptionJoinRequestMutation>
export type CreateSubscriptionJoinRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionJoinRequestMutation,
  CreateSubscriptionJoinRequestMutationVariables
>
export const CreateTeamPostDocument = gql`
  mutation createTeamPost($input: CreateTeamPostInput!) {
    createTeamPost(input: $input) {
      errors
      id
    }
  }
`
export type CreateTeamPostMutationFn = Apollo.MutationFunction<
  CreateTeamPostMutation,
  CreateTeamPostMutationVariables
>

/**
 * __useCreateTeamPostMutation__
 *
 * To run a mutation, you first call `useCreateTeamPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamPostMutation, { data, loading, error }] = useCreateTeamPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTeamPostMutation,
    CreateTeamPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTeamPostMutation, CreateTeamPostMutationVariables>(
    CreateTeamPostDocument,
    options
  )
}
export type CreateTeamPostMutationHookResult = ReturnType<
  typeof useCreateTeamPostMutation
>
export type CreateTeamPostMutationResult = Apollo.MutationResult<CreateTeamPostMutation>
export type CreateTeamPostMutationOptions = Apollo.BaseMutationOptions<
  CreateTeamPostMutation,
  CreateTeamPostMutationVariables
>
export const CreateUserClipActivityDocument = gql`
  mutation createUserClipActivity($input: CreateUserClipActivityInput!) {
    createUserClipActivity(input: $input) {
      id
      errors
    }
  }
`
export type CreateUserClipActivityMutationFn = Apollo.MutationFunction<
  CreateUserClipActivityMutation,
  CreateUserClipActivityMutationVariables
>

/**
 * __useCreateUserClipActivityMutation__
 *
 * To run a mutation, you first call `useCreateUserClipActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserClipActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserClipActivityMutation, { data, loading, error }] = useCreateUserClipActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserClipActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserClipActivityMutation,
    CreateUserClipActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserClipActivityMutation,
    CreateUserClipActivityMutationVariables
  >(CreateUserClipActivityDocument, options)
}
export type CreateUserClipActivityMutationHookResult = ReturnType<
  typeof useCreateUserClipActivityMutation
>
export type CreateUserClipActivityMutationResult =
  Apollo.MutationResult<CreateUserClipActivityMutation>
export type CreateUserClipActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateUserClipActivityMutation,
  CreateUserClipActivityMutationVariables
>
export const CreateUserCmsActivityDocument = gql`
  mutation createUserCmsActivity($input: CreateUserCmsActivityInput!) {
    createUserCmsActivity(input: $input) {
      id
      errors
    }
  }
`
export type CreateUserCmsActivityMutationFn = Apollo.MutationFunction<
  CreateUserCmsActivityMutation,
  CreateUserCmsActivityMutationVariables
>

/**
 * __useCreateUserCmsActivityMutation__
 *
 * To run a mutation, you first call `useCreateUserCmsActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCmsActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCmsActivityMutation, { data, loading, error }] = useCreateUserCmsActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserCmsActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserCmsActivityMutation,
    CreateUserCmsActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserCmsActivityMutation,
    CreateUserCmsActivityMutationVariables
  >(CreateUserCmsActivityDocument, options)
}
export type CreateUserCmsActivityMutationHookResult = ReturnType<
  typeof useCreateUserCmsActivityMutation
>
export type CreateUserCmsActivityMutationResult =
  Apollo.MutationResult<CreateUserCmsActivityMutation>
export type CreateUserCmsActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateUserCmsActivityMutation,
  CreateUserCmsActivityMutationVariables
>
export const CreateUserEventActivityDocument = gql`
  mutation createUserEventActivity($input: CreateUserEventActivityInput!) {
    createUserEventActivity(input: $input) {
      id
      errors
    }
  }
`
export type CreateUserEventActivityMutationFn = Apollo.MutationFunction<
  CreateUserEventActivityMutation,
  CreateUserEventActivityMutationVariables
>

/**
 * __useCreateUserEventActivityMutation__
 *
 * To run a mutation, you first call `useCreateUserEventActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEventActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEventActivityMutation, { data, loading, error }] = useCreateUserEventActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserEventActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserEventActivityMutation,
    CreateUserEventActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateUserEventActivityMutation,
    CreateUserEventActivityMutationVariables
  >(CreateUserEventActivityDocument, options)
}
export type CreateUserEventActivityMutationHookResult = ReturnType<
  typeof useCreateUserEventActivityMutation
>
export type CreateUserEventActivityMutationResult =
  Apollo.MutationResult<CreateUserEventActivityMutation>
export type CreateUserEventActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateUserEventActivityMutation,
  CreateUserEventActivityMutationVariables
>
export const DeclineSubscriptionJoinRequestDocument = gql`
  mutation declineSubscriptionJoinRequest($input: DeclineSubscriptionJoinRequestInput!) {
    declineSubscriptionJoinRequest(input: $input) {
      success
      error
      subscriptionJoinRequest {
        id
        status
        subscription {
          id
        }
        user {
          id
        }
      }
    }
  }
`
export type DeclineSubscriptionJoinRequestMutationFn = Apollo.MutationFunction<
  DeclineSubscriptionJoinRequestMutation,
  DeclineSubscriptionJoinRequestMutationVariables
>

/**
 * __useDeclineSubscriptionJoinRequestMutation__
 *
 * To run a mutation, you first call `useDeclineSubscriptionJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineSubscriptionJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineSubscriptionJoinRequestMutation, { data, loading, error }] = useDeclineSubscriptionJoinRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineSubscriptionJoinRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineSubscriptionJoinRequestMutation,
    DeclineSubscriptionJoinRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeclineSubscriptionJoinRequestMutation,
    DeclineSubscriptionJoinRequestMutationVariables
  >(DeclineSubscriptionJoinRequestDocument, options)
}
export type DeclineSubscriptionJoinRequestMutationHookResult = ReturnType<
  typeof useDeclineSubscriptionJoinRequestMutation
>
export type DeclineSubscriptionJoinRequestMutationResult =
  Apollo.MutationResult<DeclineSubscriptionJoinRequestMutation>
export type DeclineSubscriptionJoinRequestMutationOptions = Apollo.BaseMutationOptions<
  DeclineSubscriptionJoinRequestMutation,
  DeclineSubscriptionJoinRequestMutationVariables
>
export const DeleteBookmarkDocument = gql`
  mutation deleteBookmark($input: DeleteBookmarkInput!) {
    deleteBookmark(input: $input) {
      id
      status
      errors
    }
  }
`
export type DeleteBookmarkMutationFn = Apollo.MutationFunction<
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables
>

/**
 * __useDeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkMutation, { data, loading, error }] = useDeleteBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>(
    DeleteBookmarkDocument,
    options
  )
}
export type DeleteBookmarkMutationHookResult = ReturnType<
  typeof useDeleteBookmarkMutation
>
export type DeleteBookmarkMutationResult = Apollo.MutationResult<DeleteBookmarkMutation>
export type DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables
>
export const DeleteBookmarkFolderDocument = gql`
  mutation deleteBookmarkFolder($input: DeleteBookmarkFolderInput!) {
    deleteBookmarkFolder(input: $input) {
      id
      forkedFromId
      errors
    }
  }
`
export type DeleteBookmarkFolderMutationFn = Apollo.MutationFunction<
  DeleteBookmarkFolderMutation,
  DeleteBookmarkFolderMutationVariables
>

/**
 * __useDeleteBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkFolderMutation, { data, loading, error }] = useDeleteBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookmarkFolderMutation,
    DeleteBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteBookmarkFolderMutation,
    DeleteBookmarkFolderMutationVariables
  >(DeleteBookmarkFolderDocument, options)
}
export type DeleteBookmarkFolderMutationHookResult = ReturnType<
  typeof useDeleteBookmarkFolderMutation
>
export type DeleteBookmarkFolderMutationResult =
  Apollo.MutationResult<DeleteBookmarkFolderMutation>
export type DeleteBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookmarkFolderMutation,
  DeleteBookmarkFolderMutationVariables
>
export const DeleteCohortPassDocument = gql`
  mutation deleteCohortPass($input: DeleteCohortPassInput!) {
    deleteCohortPass(input: $input) {
      id
      status
    }
  }
`
export type DeleteCohortPassMutationFn = Apollo.MutationFunction<
  DeleteCohortPassMutation,
  DeleteCohortPassMutationVariables
>

/**
 * __useDeleteCohortPassMutation__
 *
 * To run a mutation, you first call `useDeleteCohortPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCohortPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCohortPassMutation, { data, loading, error }] = useDeleteCohortPassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCohortPassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCohortPassMutation,
    DeleteCohortPassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCohortPassMutation, DeleteCohortPassMutationVariables>(
    DeleteCohortPassDocument,
    options
  )
}
export type DeleteCohortPassMutationHookResult = ReturnType<
  typeof useDeleteCohortPassMutation
>
export type DeleteCohortPassMutationResult =
  Apollo.MutationResult<DeleteCohortPassMutation>
export type DeleteCohortPassMutationOptions = Apollo.BaseMutationOptions<
  DeleteCohortPassMutation,
  DeleteCohortPassMutationVariables
>
export const DeleteCohortPostDocument = gql`
  mutation DeleteCohortPost($input: DeleteCohortPostInput!) {
    deleteCohortPost(input: $input) {
      id
    }
  }
`
export type DeleteCohortPostMutationFn = Apollo.MutationFunction<
  DeleteCohortPostMutation,
  DeleteCohortPostMutationVariables
>

/**
 * __useDeleteCohortPostMutation__
 *
 * To run a mutation, you first call `useDeleteCohortPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCohortPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCohortPostMutation, { data, loading, error }] = useDeleteCohortPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCohortPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCohortPostMutation,
    DeleteCohortPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteCohortPostMutation, DeleteCohortPostMutationVariables>(
    DeleteCohortPostDocument,
    options
  )
}
export type DeleteCohortPostMutationHookResult = ReturnType<
  typeof useDeleteCohortPostMutation
>
export type DeleteCohortPostMutationResult =
  Apollo.MutationResult<DeleteCohortPostMutation>
export type DeleteCohortPostMutationOptions = Apollo.BaseMutationOptions<
  DeleteCohortPostMutation,
  DeleteCohortPostMutationVariables
>
export const DeleteCohortReplyDocument = gql`
  mutation DeleteCohortReply($input: DeleteCohortReplyInput!) {
    deleteCohortReply(input: $input) {
      id
    }
  }
`
export type DeleteCohortReplyMutationFn = Apollo.MutationFunction<
  DeleteCohortReplyMutation,
  DeleteCohortReplyMutationVariables
>

/**
 * __useDeleteCohortReplyMutation__
 *
 * To run a mutation, you first call `useDeleteCohortReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCohortReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCohortReplyMutation, { data, loading, error }] = useDeleteCohortReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCohortReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCohortReplyMutation,
    DeleteCohortReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCohortReplyMutation,
    DeleteCohortReplyMutationVariables
  >(DeleteCohortReplyDocument, options)
}
export type DeleteCohortReplyMutationHookResult = ReturnType<
  typeof useDeleteCohortReplyMutation
>
export type DeleteCohortReplyMutationResult =
  Apollo.MutationResult<DeleteCohortReplyMutation>
export type DeleteCohortReplyMutationOptions = Apollo.BaseMutationOptions<
  DeleteCohortReplyMutation,
  DeleteCohortReplyMutationVariables
>
export const DeleteCustomerTaxIdDocument = gql`
  mutation deleteCustomerTaxId($input: DeleteCustomerTaxIdInput!) {
    deleteCustomerTaxId(input: $input) {
      success
    }
  }
`
export type DeleteCustomerTaxIdMutationFn = Apollo.MutationFunction<
  DeleteCustomerTaxIdMutation,
  DeleteCustomerTaxIdMutationVariables
>

/**
 * __useDeleteCustomerTaxIdMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerTaxIdMutation, { data, loading, error }] = useDeleteCustomerTaxIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerTaxIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomerTaxIdMutation,
    DeleteCustomerTaxIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteCustomerTaxIdMutation,
    DeleteCustomerTaxIdMutationVariables
  >(DeleteCustomerTaxIdDocument, options)
}
export type DeleteCustomerTaxIdMutationHookResult = ReturnType<
  typeof useDeleteCustomerTaxIdMutation
>
export type DeleteCustomerTaxIdMutationResult =
  Apollo.MutationResult<DeleteCustomerTaxIdMutation>
export type DeleteCustomerTaxIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomerTaxIdMutation,
  DeleteCustomerTaxIdMutationVariables
>
export const DeleteFiledBookmarkDocument = gql`
  mutation deleteFiledBookmark($input: DeleteFiledBookmarkInput!) {
    deleteFiledBookmark(input: $input) {
      filedBookmark {
        id
        bookmarkFolderId
        bookmark {
          id
        }
      }
      collectionActivity {
        ...CollectionActivityParts
      }
      destroyedBookmark
      errors
    }
  }
  ${CollectionActivityPartsFragmentDoc}
`
export type DeleteFiledBookmarkMutationFn = Apollo.MutationFunction<
  DeleteFiledBookmarkMutation,
  DeleteFiledBookmarkMutationVariables
>

/**
 * __useDeleteFiledBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteFiledBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFiledBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFiledBookmarkMutation, { data, loading, error }] = useDeleteFiledBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFiledBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFiledBookmarkMutation,
    DeleteFiledBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFiledBookmarkMutation,
    DeleteFiledBookmarkMutationVariables
  >(DeleteFiledBookmarkDocument, options)
}
export type DeleteFiledBookmarkMutationHookResult = ReturnType<
  typeof useDeleteFiledBookmarkMutation
>
export type DeleteFiledBookmarkMutationResult =
  Apollo.MutationResult<DeleteFiledBookmarkMutation>
export type DeleteFiledBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeleteFiledBookmarkMutation,
  DeleteFiledBookmarkMutationVariables
>
export const DeletePaymentSourceDocument = gql`
  mutation deletePaymentSource($input: DeletePaymentSourceInput!) {
    deletePaymentSource(input: $input) {
      id
      errors
    }
  }
`
export type DeletePaymentSourceMutationFn = Apollo.MutationFunction<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>

/**
 * __useDeletePaymentSourceMutation__
 *
 * To run a mutation, you first call `useDeletePaymentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentSourceMutation, { data, loading, error }] = useDeletePaymentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePaymentSourceMutation,
    DeletePaymentSourceMutationVariables
  >(DeletePaymentSourceDocument, options)
}
export type DeletePaymentSourceMutationHookResult = ReturnType<
  typeof useDeletePaymentSourceMutation
>
export type DeletePaymentSourceMutationResult =
  Apollo.MutationResult<DeletePaymentSourceMutation>
export type DeletePaymentSourceMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentSourceMutation,
  DeletePaymentSourceMutationVariables
>
export const DeleteTeamPostDocument = gql`
  mutation deleteTeamPost($input: DeleteTeamPostInput!) {
    deleteTeamPost(input: $input) {
      id
    }
  }
`
export type DeleteTeamPostMutationFn = Apollo.MutationFunction<
  DeleteTeamPostMutation,
  DeleteTeamPostMutationVariables
>

/**
 * __useDeleteTeamPostMutation__
 *
 * To run a mutation, you first call `useDeleteTeamPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamPostMutation, { data, loading, error }] = useDeleteTeamPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTeamPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTeamPostMutation,
    DeleteTeamPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTeamPostMutation, DeleteTeamPostMutationVariables>(
    DeleteTeamPostDocument,
    options
  )
}
export type DeleteTeamPostMutationHookResult = ReturnType<
  typeof useDeleteTeamPostMutation
>
export type DeleteTeamPostMutationResult = Apollo.MutationResult<DeleteTeamPostMutation>
export type DeleteTeamPostMutationOptions = Apollo.BaseMutationOptions<
  DeleteTeamPostMutation,
  DeleteTeamPostMutationVariables
>
export const AddEmailToUserDocument = gql`
  mutation addEmailToUser($input: AddEmailToUserInput!) {
    addEmailToUser(input: $input) {
      id
    }
  }
`
export type AddEmailToUserMutationFn = Apollo.MutationFunction<
  AddEmailToUserMutation,
  AddEmailToUserMutationVariables
>

/**
 * __useAddEmailToUserMutation__
 *
 * To run a mutation, you first call `useAddEmailToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailToUserMutation, { data, loading, error }] = useAddEmailToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEmailToUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEmailToUserMutation,
    AddEmailToUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddEmailToUserMutation, AddEmailToUserMutationVariables>(
    AddEmailToUserDocument,
    options
  )
}
export type AddEmailToUserMutationHookResult = ReturnType<
  typeof useAddEmailToUserMutation
>
export type AddEmailToUserMutationResult = Apollo.MutationResult<AddEmailToUserMutation>
export type AddEmailToUserMutationOptions = Apollo.BaseMutationOptions<
  AddEmailToUserMutation,
  AddEmailToUserMutationVariables
>
export const DeleteEmailDocument = gql`
  mutation deleteEmail($input: DeleteEmailInput!) {
    deleteEmail(input: $input) {
      id
    }
  }
`
export type DeleteEmailMutationFn = Apollo.MutationFunction<
  DeleteEmailMutation,
  DeleteEmailMutationVariables
>

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEmailMutation,
    DeleteEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteEmailMutation, DeleteEmailMutationVariables>(
    DeleteEmailDocument,
    options
  )
}
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>
export type DeleteEmailMutationResult = Apollo.MutationResult<DeleteEmailMutation>
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<
  DeleteEmailMutation,
  DeleteEmailMutationVariables
>
export const MakeEmailPrimaryDocument = gql`
  mutation makeEmailPrimary($input: MakeEmailPrimaryInput!) {
    makeEmailPrimary(input: $input) {
      id
    }
  }
`
export type MakeEmailPrimaryMutationFn = Apollo.MutationFunction<
  MakeEmailPrimaryMutation,
  MakeEmailPrimaryMutationVariables
>

/**
 * __useMakeEmailPrimaryMutation__
 *
 * To run a mutation, you first call `useMakeEmailPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeEmailPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeEmailPrimaryMutation, { data, loading, error }] = useMakeEmailPrimaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeEmailPrimaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeEmailPrimaryMutation,
    MakeEmailPrimaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MakeEmailPrimaryMutation, MakeEmailPrimaryMutationVariables>(
    MakeEmailPrimaryDocument,
    options
  )
}
export type MakeEmailPrimaryMutationHookResult = ReturnType<
  typeof useMakeEmailPrimaryMutation
>
export type MakeEmailPrimaryMutationResult =
  Apollo.MutationResult<MakeEmailPrimaryMutation>
export type MakeEmailPrimaryMutationOptions = Apollo.BaseMutationOptions<
  MakeEmailPrimaryMutation,
  MakeEmailPrimaryMutationVariables
>
export const SendEmailVerifyDocument = gql`
  mutation sendEmailVerify($input: SendEmailVerifyInput!) {
    sendEmailVerify(input: $input) {
      id
    }
  }
`
export type SendEmailVerifyMutationFn = Apollo.MutationFunction<
  SendEmailVerifyMutation,
  SendEmailVerifyMutationVariables
>

/**
 * __useSendEmailVerifyMutation__
 *
 * To run a mutation, you first call `useSendEmailVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerifyMutation, { data, loading, error }] = useSendEmailVerifyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailVerifyMutation,
    SendEmailVerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendEmailVerifyMutation, SendEmailVerifyMutationVariables>(
    SendEmailVerifyDocument,
    options
  )
}
export type SendEmailVerifyMutationHookResult = ReturnType<
  typeof useSendEmailVerifyMutation
>
export type SendEmailVerifyMutationResult = Apollo.MutationResult<SendEmailVerifyMutation>
export type SendEmailVerifyMutationOptions = Apollo.BaseMutationOptions<
  SendEmailVerifyMutation,
  SendEmailVerifyMutationVariables
>
export const EndSubscriptionTrialEarlyDocument = gql`
  mutation EndSubscriptionTrialEarly {
    endSubscriptionTrialEarly(input: {}) {
      success
      errors
    }
  }
`
export type EndSubscriptionTrialEarlyMutationFn = Apollo.MutationFunction<
  EndSubscriptionTrialEarlyMutation,
  EndSubscriptionTrialEarlyMutationVariables
>

/**
 * __useEndSubscriptionTrialEarlyMutation__
 *
 * To run a mutation, you first call `useEndSubscriptionTrialEarlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSubscriptionTrialEarlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSubscriptionTrialEarlyMutation, { data, loading, error }] = useEndSubscriptionTrialEarlyMutation({
 *   variables: {
 *   },
 * });
 */
export function useEndSubscriptionTrialEarlyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndSubscriptionTrialEarlyMutation,
    EndSubscriptionTrialEarlyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    EndSubscriptionTrialEarlyMutation,
    EndSubscriptionTrialEarlyMutationVariables
  >(EndSubscriptionTrialEarlyDocument, options)
}
export type EndSubscriptionTrialEarlyMutationHookResult = ReturnType<
  typeof useEndSubscriptionTrialEarlyMutation
>
export type EndSubscriptionTrialEarlyMutationResult =
  Apollo.MutationResult<EndSubscriptionTrialEarlyMutation>
export type EndSubscriptionTrialEarlyMutationOptions = Apollo.BaseMutationOptions<
  EndSubscriptionTrialEarlyMutation,
  EndSubscriptionTrialEarlyMutationVariables
>
export const FlagArtifactCommentDocument = gql`
  mutation flagArtifactComment($input: FlagArtifactCommentInput!) {
    flagArtifactComment(input: $input) {
      errors
    }
  }
`
export type FlagArtifactCommentMutationFn = Apollo.MutationFunction<
  FlagArtifactCommentMutation,
  FlagArtifactCommentMutationVariables
>

/**
 * __useFlagArtifactCommentMutation__
 *
 * To run a mutation, you first call `useFlagArtifactCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagArtifactCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagArtifactCommentMutation, { data, loading, error }] = useFlagArtifactCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFlagArtifactCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlagArtifactCommentMutation,
    FlagArtifactCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FlagArtifactCommentMutation,
    FlagArtifactCommentMutationVariables
  >(FlagArtifactCommentDocument, options)
}
export type FlagArtifactCommentMutationHookResult = ReturnType<
  typeof useFlagArtifactCommentMutation
>
export type FlagArtifactCommentMutationResult =
  Apollo.MutationResult<FlagArtifactCommentMutation>
export type FlagArtifactCommentMutationOptions = Apollo.BaseMutationOptions<
  FlagArtifactCommentMutation,
  FlagArtifactCommentMutationVariables
>
export const ForkBookmarkFolderDocument = gql`
  mutation forkBookmarkFolder($input: ForkBookmarkFolderInput!) {
    forkBookmarkFolder(input: $input) {
      bookmarkFolder {
        ...BookmarkFolderParts
      }
      newForkedBookmarks {
        id
        ...ProgramBookmarkParts
      }
      errors
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${ProgramBookmarkPartsFragmentDoc}
`
export type ForkBookmarkFolderMutationFn = Apollo.MutationFunction<
  ForkBookmarkFolderMutation,
  ForkBookmarkFolderMutationVariables
>

/**
 * __useForkBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useForkBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForkBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forkBookmarkFolderMutation, { data, loading, error }] = useForkBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForkBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForkBookmarkFolderMutation,
    ForkBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ForkBookmarkFolderMutation,
    ForkBookmarkFolderMutationVariables
  >(ForkBookmarkFolderDocument, options)
}
export type ForkBookmarkFolderMutationHookResult = ReturnType<
  typeof useForkBookmarkFolderMutation
>
export type ForkBookmarkFolderMutationResult =
  Apollo.MutationResult<ForkBookmarkFolderMutation>
export type ForkBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  ForkBookmarkFolderMutation,
  ForkBookmarkFolderMutationVariables
>
export const TeamMembersDocument = gql`
  query teamMembers($limit: Int, $offset: Int, $search: String) {
    currentUser {
      id
      subscriptions {
        active {
          id
          teamMembers(limit: $limit, offset: $offset, search: $search) {
            count
            totalCount
            users {
              id
              fullName
              profile {
                avatarUrl
                fullName
                role
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTeamMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options
  )
}
export function useTeamMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(
    TeamMembersDocument,
    options
  )
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>
export type TeamMembersQueryResult = Apollo.QueryResult<
  TeamMembersQuery,
  TeamMembersQueryVariables
>
export const JoinCourseWaitingListDocument = gql`
  mutation JoinCourseWaitingList($input: JoinCourseWaitlistInput!) {
    joinCourseWaitlist(input: $input) {
      errors
    }
  }
`
export type JoinCourseWaitingListMutationFn = Apollo.MutationFunction<
  JoinCourseWaitingListMutation,
  JoinCourseWaitingListMutationVariables
>

/**
 * __useJoinCourseWaitingListMutation__
 *
 * To run a mutation, you first call `useJoinCourseWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCourseWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCourseWaitingListMutation, { data, loading, error }] = useJoinCourseWaitingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinCourseWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinCourseWaitingListMutation,
    JoinCourseWaitingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    JoinCourseWaitingListMutation,
    JoinCourseWaitingListMutationVariables
  >(JoinCourseWaitingListDocument, options)
}
export type JoinCourseWaitingListMutationHookResult = ReturnType<
  typeof useJoinCourseWaitingListMutation
>
export type JoinCourseWaitingListMutationResult =
  Apollo.MutationResult<JoinCourseWaitingListMutation>
export type JoinCourseWaitingListMutationOptions = Apollo.BaseMutationOptions<
  JoinCourseWaitingListMutation,
  JoinCourseWaitingListMutationVariables
>
export const JoinPublicCourseWaitingListDocument = gql`
  mutation JoinPublicCourseWaitingList($input: JoinPublicCourseWaitlistInput!) {
    joinPublicCourseWaitlist(input: $input) {
      errors
    }
  }
`
export type JoinPublicCourseWaitingListMutationFn = Apollo.MutationFunction<
  JoinPublicCourseWaitingListMutation,
  JoinPublicCourseWaitingListMutationVariables
>

/**
 * __useJoinPublicCourseWaitingListMutation__
 *
 * To run a mutation, you first call `useJoinPublicCourseWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinPublicCourseWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinPublicCourseWaitingListMutation, { data, loading, error }] = useJoinPublicCourseWaitingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinPublicCourseWaitingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinPublicCourseWaitingListMutation,
    JoinPublicCourseWaitingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    JoinPublicCourseWaitingListMutation,
    JoinPublicCourseWaitingListMutationVariables
  >(JoinPublicCourseWaitingListDocument, options)
}
export type JoinPublicCourseWaitingListMutationHookResult = ReturnType<
  typeof useJoinPublicCourseWaitingListMutation
>
export type JoinPublicCourseWaitingListMutationResult =
  Apollo.MutationResult<JoinPublicCourseWaitingListMutation>
export type JoinPublicCourseWaitingListMutationOptions = Apollo.BaseMutationOptions<
  JoinPublicCourseWaitingListMutation,
  JoinPublicCourseWaitingListMutationVariables
>
export const MarkActivitiesAsReadDocument = gql`
  mutation markActivitiesAsRead($input: MarkActivitiesAsReadInput!) {
    markActivitiesAsRead(input: $input) {
      success
      message
    }
  }
`
export type MarkActivitiesAsReadMutationFn = Apollo.MutationFunction<
  MarkActivitiesAsReadMutation,
  MarkActivitiesAsReadMutationVariables
>

/**
 * __useMarkActivitiesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkActivitiesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkActivitiesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markActivitiesAsReadMutation, { data, loading, error }] = useMarkActivitiesAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkActivitiesAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkActivitiesAsReadMutation,
    MarkActivitiesAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkActivitiesAsReadMutation,
    MarkActivitiesAsReadMutationVariables
  >(MarkActivitiesAsReadDocument, options)
}
export type MarkActivitiesAsReadMutationHookResult = ReturnType<
  typeof useMarkActivitiesAsReadMutation
>
export type MarkActivitiesAsReadMutationResult =
  Apollo.MutationResult<MarkActivitiesAsReadMutation>
export type MarkActivitiesAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkActivitiesAsReadMutation,
  MarkActivitiesAsReadMutationVariables
>
export const MarkAllActivitiesAsReadDocument = gql`
  mutation markAllActivitiesAsRead($input: MarkAllActivitiesAsReadInput!) {
    markAllActivitiesAsRead(input: $input) {
      success
    }
  }
`
export type MarkAllActivitiesAsReadMutationFn = Apollo.MutationFunction<
  MarkAllActivitiesAsReadMutation,
  MarkAllActivitiesAsReadMutationVariables
>

/**
 * __useMarkAllActivitiesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllActivitiesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllActivitiesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllActivitiesAsReadMutation, { data, loading, error }] = useMarkAllActivitiesAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAllActivitiesAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllActivitiesAsReadMutation,
    MarkAllActivitiesAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkAllActivitiesAsReadMutation,
    MarkAllActivitiesAsReadMutationVariables
  >(MarkAllActivitiesAsReadDocument, options)
}
export type MarkAllActivitiesAsReadMutationHookResult = ReturnType<
  typeof useMarkAllActivitiesAsReadMutation
>
export type MarkAllActivitiesAsReadMutationResult =
  Apollo.MutationResult<MarkAllActivitiesAsReadMutation>
export type MarkAllActivitiesAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAllActivitiesAsReadMutation,
  MarkAllActivitiesAsReadMutationVariables
>
export const MarkArtifactAsViewedDocument = gql`
  mutation markArtifactAsViewed($input: MarkArtifactAsViewedInput!) {
    markArtifactAsViewed(input: $input) {
      viewedArtifact {
        id
        artifactId
      }
    }
  }
`
export type MarkArtifactAsViewedMutationFn = Apollo.MutationFunction<
  MarkArtifactAsViewedMutation,
  MarkArtifactAsViewedMutationVariables
>

/**
 * __useMarkArtifactAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkArtifactAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkArtifactAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markArtifactAsViewedMutation, { data, loading, error }] = useMarkArtifactAsViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkArtifactAsViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkArtifactAsViewedMutation,
    MarkArtifactAsViewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkArtifactAsViewedMutation,
    MarkArtifactAsViewedMutationVariables
  >(MarkArtifactAsViewedDocument, options)
}
export type MarkArtifactAsViewedMutationHookResult = ReturnType<
  typeof useMarkArtifactAsViewedMutation
>
export type MarkArtifactAsViewedMutationResult =
  Apollo.MutationResult<MarkArtifactAsViewedMutation>
export type MarkArtifactAsViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkArtifactAsViewedMutation,
  MarkArtifactAsViewedMutationVariables
>
export const TrackOnboardingDocument = gql`
  mutation trackOnboarding($input: OnboardingTrackingInput!) {
    trackOnboarding(input: $input) {
      errors
    }
  }
`
export type TrackOnboardingMutationFn = Apollo.MutationFunction<
  TrackOnboardingMutation,
  TrackOnboardingMutationVariables
>

/**
 * __useTrackOnboardingMutation__
 *
 * To run a mutation, you first call `useTrackOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackOnboardingMutation, { data, loading, error }] = useTrackOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackOnboardingMutation,
    TrackOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TrackOnboardingMutation, TrackOnboardingMutationVariables>(
    TrackOnboardingDocument,
    options
  )
}
export type TrackOnboardingMutationHookResult = ReturnType<
  typeof useTrackOnboardingMutation
>
export type TrackOnboardingMutationResult = Apollo.MutationResult<TrackOnboardingMutation>
export type TrackOnboardingMutationOptions = Apollo.BaseMutationOptions<
  TrackOnboardingMutation,
  TrackOnboardingMutationVariables
>
export const RegenerateCourseSnapshotDocument = gql`
  mutation RegenerateCourseSnapshot($input: RegenerateCourseSnapshotInput!) {
    regenerateCourseSnapshot(input: $input) {
      userCourseId
      errors
    }
  }
`
export type RegenerateCourseSnapshotMutationFn = Apollo.MutationFunction<
  RegenerateCourseSnapshotMutation,
  RegenerateCourseSnapshotMutationVariables
>

/**
 * __useRegenerateCourseSnapshotMutation__
 *
 * To run a mutation, you first call `useRegenerateCourseSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateCourseSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateCourseSnapshotMutation, { data, loading, error }] = useRegenerateCourseSnapshotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegenerateCourseSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateCourseSnapshotMutation,
    RegenerateCourseSnapshotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RegenerateCourseSnapshotMutation,
    RegenerateCourseSnapshotMutationVariables
  >(RegenerateCourseSnapshotDocument, options)
}
export type RegenerateCourseSnapshotMutationHookResult = ReturnType<
  typeof useRegenerateCourseSnapshotMutation
>
export type RegenerateCourseSnapshotMutationResult =
  Apollo.MutationResult<RegenerateCourseSnapshotMutation>
export type RegenerateCourseSnapshotMutationOptions = Apollo.BaseMutationOptions<
  RegenerateCourseSnapshotMutation,
  RegenerateCourseSnapshotMutationVariables
>
export const RemoveAnnotationReactionDocument = gql`
  mutation removeAnnotationReaction($input: RemoveAnnotationReactionInput!) {
    removeAnnotationReaction(input: $input) {
      errors
    }
  }
`
export type RemoveAnnotationReactionMutationFn = Apollo.MutationFunction<
  RemoveAnnotationReactionMutation,
  RemoveAnnotationReactionMutationVariables
>

/**
 * __useRemoveAnnotationReactionMutation__
 *
 * To run a mutation, you first call `useRemoveAnnotationReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnnotationReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnnotationReactionMutation, { data, loading, error }] = useRemoveAnnotationReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAnnotationReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAnnotationReactionMutation,
    RemoveAnnotationReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveAnnotationReactionMutation,
    RemoveAnnotationReactionMutationVariables
  >(RemoveAnnotationReactionDocument, options)
}
export type RemoveAnnotationReactionMutationHookResult = ReturnType<
  typeof useRemoveAnnotationReactionMutation
>
export type RemoveAnnotationReactionMutationResult =
  Apollo.MutationResult<RemoveAnnotationReactionMutation>
export type RemoveAnnotationReactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveAnnotationReactionMutation,
  RemoveAnnotationReactionMutationVariables
>
export const RemoveArtifactCommentDocument = gql`
  mutation removeArtifactComment($input: RemoveArtifactCommentInput!) {
    removeArtifactComment(input: $input) {
      errors
    }
  }
`
export type RemoveArtifactCommentMutationFn = Apollo.MutationFunction<
  RemoveArtifactCommentMutation,
  RemoveArtifactCommentMutationVariables
>

/**
 * __useRemoveArtifactCommentMutation__
 *
 * To run a mutation, you first call `useRemoveArtifactCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArtifactCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArtifactCommentMutation, { data, loading, error }] = useRemoveArtifactCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveArtifactCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveArtifactCommentMutation,
    RemoveArtifactCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveArtifactCommentMutation,
    RemoveArtifactCommentMutationVariables
  >(RemoveArtifactCommentDocument, options)
}
export type RemoveArtifactCommentMutationHookResult = ReturnType<
  typeof useRemoveArtifactCommentMutation
>
export type RemoveArtifactCommentMutationResult =
  Apollo.MutationResult<RemoveArtifactCommentMutation>
export type RemoveArtifactCommentMutationOptions = Apollo.BaseMutationOptions<
  RemoveArtifactCommentMutation,
  RemoveArtifactCommentMutationVariables
>
export const RemoveArtifactCommentReactionDocument = gql`
  mutation removeArtifactCommentReaction($input: RemoveArtifactCommentReactionInput!) {
    removeArtifactCommentReaction(input: $input) {
      errors
    }
  }
`
export type RemoveArtifactCommentReactionMutationFn = Apollo.MutationFunction<
  RemoveArtifactCommentReactionMutation,
  RemoveArtifactCommentReactionMutationVariables
>

/**
 * __useRemoveArtifactCommentReactionMutation__
 *
 * To run a mutation, you first call `useRemoveArtifactCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArtifactCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArtifactCommentReactionMutation, { data, loading, error }] = useRemoveArtifactCommentReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveArtifactCommentReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveArtifactCommentReactionMutation,
    RemoveArtifactCommentReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveArtifactCommentReactionMutation,
    RemoveArtifactCommentReactionMutationVariables
  >(RemoveArtifactCommentReactionDocument, options)
}
export type RemoveArtifactCommentReactionMutationHookResult = ReturnType<
  typeof useRemoveArtifactCommentReactionMutation
>
export type RemoveArtifactCommentReactionMutationResult =
  Apollo.MutationResult<RemoveArtifactCommentReactionMutation>
export type RemoveArtifactCommentReactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveArtifactCommentReactionMutation,
  RemoveArtifactCommentReactionMutationVariables
>
export const RemoveReactionDocument = gql`
  mutation removeReaction($input: RemoveReactionInput!) {
    removeReaction(input: $input) {
      id
    }
  }
`
export type RemoveReactionMutationFn = Apollo.MutationFunction<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveReactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReactionMutation,
    RemoveReactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveReactionMutation, RemoveReactionMutationVariables>(
    RemoveReactionDocument,
    options
  )
}
export type RemoveReactionMutationHookResult = ReturnType<
  typeof useRemoveReactionMutation
>
export type RemoveReactionMutationResult = Apollo.MutationResult<RemoveReactionMutation>
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<
  RemoveReactionMutation,
  RemoveReactionMutationVariables
>
export const RemoveReplyDocument = gql`
  mutation removeReply($input: RemoveReplyInput!) {
    removeReply(input: $input) {
      id
    }
  }
`
export type RemoveReplyMutationFn = Apollo.MutationFunction<
  RemoveReplyMutation,
  RemoveReplyMutationVariables
>

/**
 * __useRemoveReplyMutation__
 *
 * To run a mutation, you first call `useRemoveReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReplyMutation, { data, loading, error }] = useRemoveReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReplyMutation,
    RemoveReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveReplyMutation, RemoveReplyMutationVariables>(
    RemoveReplyDocument,
    options
  )
}
export type RemoveReplyMutationHookResult = ReturnType<typeof useRemoveReplyMutation>
export type RemoveReplyMutationResult = Apollo.MutationResult<RemoveReplyMutation>
export type RemoveReplyMutationOptions = Apollo.BaseMutationOptions<
  RemoveReplyMutation,
  RemoveReplyMutationVariables
>
export const RemoveSubscriptionFromOrgDocument = gql`
  mutation RemoveSubscriptionFromOrg($input: RemoveSubscriptionFromOrgInput!) {
    removeSubscriptionFromOrg(input: $input) {
      org {
        id
        subscriptions {
          id
        }
      }
      errors
    }
  }
`
export type RemoveSubscriptionFromOrgMutationFn = Apollo.MutationFunction<
  RemoveSubscriptionFromOrgMutation,
  RemoveSubscriptionFromOrgMutationVariables
>

/**
 * __useRemoveSubscriptionFromOrgMutation__
 *
 * To run a mutation, you first call `useRemoveSubscriptionFromOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubscriptionFromOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubscriptionFromOrgMutation, { data, loading, error }] = useRemoveSubscriptionFromOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSubscriptionFromOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSubscriptionFromOrgMutation,
    RemoveSubscriptionFromOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveSubscriptionFromOrgMutation,
    RemoveSubscriptionFromOrgMutationVariables
  >(RemoveSubscriptionFromOrgDocument, options)
}
export type RemoveSubscriptionFromOrgMutationHookResult = ReturnType<
  typeof useRemoveSubscriptionFromOrgMutation
>
export type RemoveSubscriptionFromOrgMutationResult =
  Apollo.MutationResult<RemoveSubscriptionFromOrgMutation>
export type RemoveSubscriptionFromOrgMutationOptions = Apollo.BaseMutationOptions<
  RemoveSubscriptionFromOrgMutation,
  RemoveSubscriptionFromOrgMutationVariables
>
export const ReorderFiledBookmarkDocument = gql`
  mutation reorderFiledBookmark($input: ReorderFiledBookmarkInput!) {
    reorderFiledBookmark(input: $input) {
      filedBookmark {
        id
        position
      }
      oldPosition
      errors
    }
  }
`
export type ReorderFiledBookmarkMutationFn = Apollo.MutationFunction<
  ReorderFiledBookmarkMutation,
  ReorderFiledBookmarkMutationVariables
>

/**
 * __useReorderFiledBookmarkMutation__
 *
 * To run a mutation, you first call `useReorderFiledBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderFiledBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderFiledBookmarkMutation, { data, loading, error }] = useReorderFiledBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderFiledBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderFiledBookmarkMutation,
    ReorderFiledBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReorderFiledBookmarkMutation,
    ReorderFiledBookmarkMutationVariables
  >(ReorderFiledBookmarkDocument, options)
}
export type ReorderFiledBookmarkMutationHookResult = ReturnType<
  typeof useReorderFiledBookmarkMutation
>
export type ReorderFiledBookmarkMutationResult =
  Apollo.MutationResult<ReorderFiledBookmarkMutation>
export type ReorderFiledBookmarkMutationOptions = Apollo.BaseMutationOptions<
  ReorderFiledBookmarkMutation,
  ReorderFiledBookmarkMutationVariables
>
export const ReportCohortPostOrReplyDocument = gql`
  mutation ReportCohortPostOrReply($input: ReportCohortPostOrReplyInput!) {
    reportCohortPostOrReply(input: $input) {
      success
    }
  }
`
export type ReportCohortPostOrReplyMutationFn = Apollo.MutationFunction<
  ReportCohortPostOrReplyMutation,
  ReportCohortPostOrReplyMutationVariables
>

/**
 * __useReportCohortPostOrReplyMutation__
 *
 * To run a mutation, you first call `useReportCohortPostOrReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCohortPostOrReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCohortPostOrReplyMutation, { data, loading, error }] = useReportCohortPostOrReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCohortPostOrReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportCohortPostOrReplyMutation,
    ReportCohortPostOrReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReportCohortPostOrReplyMutation,
    ReportCohortPostOrReplyMutationVariables
  >(ReportCohortPostOrReplyDocument, options)
}
export type ReportCohortPostOrReplyMutationHookResult = ReturnType<
  typeof useReportCohortPostOrReplyMutation
>
export type ReportCohortPostOrReplyMutationResult =
  Apollo.MutationResult<ReportCohortPostOrReplyMutation>
export type ReportCohortPostOrReplyMutationOptions = Apollo.BaseMutationOptions<
  ReportCohortPostOrReplyMutation,
  ReportCohortPostOrReplyMutationVariables
>
export const RestoreCollectionActivityDocument = gql`
  mutation restoreCollectionActivity($input: RestoreCollectionActivityInput!) {
    restoreCollectionActivity(input: $input) {
      collectionActivity {
        id
      }
      filedBookmark {
        id
        position
        bookmarkFolderId
        bookmark {
          id
          ...ProgramBookmarkParts
        }
      }
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`
export type RestoreCollectionActivityMutationFn = Apollo.MutationFunction<
  RestoreCollectionActivityMutation,
  RestoreCollectionActivityMutationVariables
>

/**
 * __useRestoreCollectionActivityMutation__
 *
 * To run a mutation, you first call `useRestoreCollectionActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCollectionActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCollectionActivityMutation, { data, loading, error }] = useRestoreCollectionActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreCollectionActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreCollectionActivityMutation,
    RestoreCollectionActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RestoreCollectionActivityMutation,
    RestoreCollectionActivityMutationVariables
  >(RestoreCollectionActivityDocument, options)
}
export type RestoreCollectionActivityMutationHookResult = ReturnType<
  typeof useRestoreCollectionActivityMutation
>
export type RestoreCollectionActivityMutationResult =
  Apollo.MutationResult<RestoreCollectionActivityMutation>
export type RestoreCollectionActivityMutationOptions = Apollo.BaseMutationOptions<
  RestoreCollectionActivityMutation,
  RestoreCollectionActivityMutationVariables
>
export const SaveCustomerTaxIdDocument = gql`
  mutation saveCustomerTaxId($input: SaveCustomerTaxIdInput!) {
    saveCustomerTaxId(input: $input) {
      taxId {
        country
        createdAt
        id
        stripeCustomerId
        type
        value
        validationStatus
      }
    }
  }
`
export type SaveCustomerTaxIdMutationFn = Apollo.MutationFunction<
  SaveCustomerTaxIdMutation,
  SaveCustomerTaxIdMutationVariables
>

/**
 * __useSaveCustomerTaxIdMutation__
 *
 * To run a mutation, you first call `useSaveCustomerTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerTaxIdMutation, { data, loading, error }] = useSaveCustomerTaxIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerTaxIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCustomerTaxIdMutation,
    SaveCustomerTaxIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCustomerTaxIdMutation,
    SaveCustomerTaxIdMutationVariables
  >(SaveCustomerTaxIdDocument, options)
}
export type SaveCustomerTaxIdMutationHookResult = ReturnType<
  typeof useSaveCustomerTaxIdMutation
>
export type SaveCustomerTaxIdMutationResult =
  Apollo.MutationResult<SaveCustomerTaxIdMutation>
export type SaveCustomerTaxIdMutationOptions = Apollo.BaseMutationOptions<
  SaveCustomerTaxIdMutation,
  SaveCustomerTaxIdMutationVariables
>
export const SelectFeaturedPostsDocument = gql`
  mutation selectFeaturedPosts($input: SelectFeaturedPostsInput!) {
    selectFeaturedPosts(input: $input) {
      errors
      success
    }
  }
`
export type SelectFeaturedPostsMutationFn = Apollo.MutationFunction<
  SelectFeaturedPostsMutation,
  SelectFeaturedPostsMutationVariables
>

/**
 * __useSelectFeaturedPostsMutation__
 *
 * To run a mutation, you first call `useSelectFeaturedPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectFeaturedPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectFeaturedPostsMutation, { data, loading, error }] = useSelectFeaturedPostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectFeaturedPostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectFeaturedPostsMutation,
    SelectFeaturedPostsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SelectFeaturedPostsMutation,
    SelectFeaturedPostsMutationVariables
  >(SelectFeaturedPostsDocument, options)
}
export type SelectFeaturedPostsMutationHookResult = ReturnType<
  typeof useSelectFeaturedPostsMutation
>
export type SelectFeaturedPostsMutationResult =
  Apollo.MutationResult<SelectFeaturedPostsMutation>
export type SelectFeaturedPostsMutationOptions = Apollo.BaseMutationOptions<
  SelectFeaturedPostsMutation,
  SelectFeaturedPostsMutationVariables
>
export const SendMergeRequestEmailDocument = gql`
  mutation sendMergeRequestEmail($input: SendMergeRequestEmailInput!) {
    sendMergeRequestEmail(input: $input) {
      success
    }
  }
`
export type SendMergeRequestEmailMutationFn = Apollo.MutationFunction<
  SendMergeRequestEmailMutation,
  SendMergeRequestEmailMutationVariables
>

/**
 * __useSendMergeRequestEmailMutation__
 *
 * To run a mutation, you first call `useSendMergeRequestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMergeRequestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMergeRequestEmailMutation, { data, loading, error }] = useSendMergeRequestEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMergeRequestEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMergeRequestEmailMutation,
    SendMergeRequestEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendMergeRequestEmailMutation,
    SendMergeRequestEmailMutationVariables
  >(SendMergeRequestEmailDocument, options)
}
export type SendMergeRequestEmailMutationHookResult = ReturnType<
  typeof useSendMergeRequestEmailMutation
>
export type SendMergeRequestEmailMutationResult =
  Apollo.MutationResult<SendMergeRequestEmailMutation>
export type SendMergeRequestEmailMutationOptions = Apollo.BaseMutationOptions<
  SendMergeRequestEmailMutation,
  SendMergeRequestEmailMutationVariables
>
export const SetDismissedUpdateCompanyBannerAtDocument = gql`
  mutation setDismissedUpdateCompanyBannerAt(
    $input: SetDismissedUpdateCompanyBannerAtInput!
  ) {
    setDismissedUpdateCompanyBannerAt(input: $input) {
      currentUser {
        id
        dismissedUpdateCompanyBannerAt
      }
    }
  }
`
export type SetDismissedUpdateCompanyBannerAtMutationFn = Apollo.MutationFunction<
  SetDismissedUpdateCompanyBannerAtMutation,
  SetDismissedUpdateCompanyBannerAtMutationVariables
>

/**
 * __useSetDismissedUpdateCompanyBannerAtMutation__
 *
 * To run a mutation, you first call `useSetDismissedUpdateCompanyBannerAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDismissedUpdateCompanyBannerAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDismissedUpdateCompanyBannerAtMutation, { data, loading, error }] = useSetDismissedUpdateCompanyBannerAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDismissedUpdateCompanyBannerAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDismissedUpdateCompanyBannerAtMutation,
    SetDismissedUpdateCompanyBannerAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetDismissedUpdateCompanyBannerAtMutation,
    SetDismissedUpdateCompanyBannerAtMutationVariables
  >(SetDismissedUpdateCompanyBannerAtDocument, options)
}
export type SetDismissedUpdateCompanyBannerAtMutationHookResult = ReturnType<
  typeof useSetDismissedUpdateCompanyBannerAtMutation
>
export type SetDismissedUpdateCompanyBannerAtMutationResult =
  Apollo.MutationResult<SetDismissedUpdateCompanyBannerAtMutation>
export type SetDismissedUpdateCompanyBannerAtMutationOptions = Apollo.BaseMutationOptions<
  SetDismissedUpdateCompanyBannerAtMutation,
  SetDismissedUpdateCompanyBannerAtMutationVariables
>
export const SetHasSeenDemographicsModalDocument = gql`
  mutation SetHasSeenDemographicsModal($input: SetHasSeenDemographicsModalInput!) {
    setHasSeenDemographicsModal(input: $input) {
      currentUser {
        id
      }
    }
  }
`
export type SetHasSeenDemographicsModalMutationFn = Apollo.MutationFunction<
  SetHasSeenDemographicsModalMutation,
  SetHasSeenDemographicsModalMutationVariables
>

/**
 * __useSetHasSeenDemographicsModalMutation__
 *
 * To run a mutation, you first call `useSetHasSeenDemographicsModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHasSeenDemographicsModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHasSeenDemographicsModalMutation, { data, loading, error }] = useSetHasSeenDemographicsModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetHasSeenDemographicsModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetHasSeenDemographicsModalMutation,
    SetHasSeenDemographicsModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetHasSeenDemographicsModalMutation,
    SetHasSeenDemographicsModalMutationVariables
  >(SetHasSeenDemographicsModalDocument, options)
}
export type SetHasSeenDemographicsModalMutationHookResult = ReturnType<
  typeof useSetHasSeenDemographicsModalMutation
>
export type SetHasSeenDemographicsModalMutationResult =
  Apollo.MutationResult<SetHasSeenDemographicsModalMutation>
export type SetHasSeenDemographicsModalMutationOptions = Apollo.BaseMutationOptions<
  SetHasSeenDemographicsModalMutation,
  SetHasSeenDemographicsModalMutationVariables
>
export const SetUserCohortPreferencesDocument = gql`
  mutation setUserCohortPreferences($input: SetUserCohortPreferencesInput!) {
    setUserCohortPreferences(input: $input) {
      id
      success
    }
  }
`
export type SetUserCohortPreferencesMutationFn = Apollo.MutationFunction<
  SetUserCohortPreferencesMutation,
  SetUserCohortPreferencesMutationVariables
>

/**
 * __useSetUserCohortPreferencesMutation__
 *
 * To run a mutation, you first call `useSetUserCohortPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserCohortPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserCohortPreferencesMutation, { data, loading, error }] = useSetUserCohortPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserCohortPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserCohortPreferencesMutation,
    SetUserCohortPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetUserCohortPreferencesMutation,
    SetUserCohortPreferencesMutationVariables
  >(SetUserCohortPreferencesDocument, options)
}
export type SetUserCohortPreferencesMutationHookResult = ReturnType<
  typeof useSetUserCohortPreferencesMutation
>
export type SetUserCohortPreferencesMutationResult =
  Apollo.MutationResult<SetUserCohortPreferencesMutation>
export type SetUserCohortPreferencesMutationOptions = Apollo.BaseMutationOptions<
  SetUserCohortPreferencesMutation,
  SetUserCohortPreferencesMutationVariables
>
export const ShareBookmarkFolderDocument = gql`
  mutation shareBookmarkFolder($input: ShareBookmarkFolderInput!) {
    shareBookmarkFolder(input: $input) {
      bookmarkFolder {
        ...BookmarkFolderParts
      }
      sharedFolders {
        id
        ...SharedFolder
      }
      errors
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${SharedFolderFragmentDoc}
`
export type ShareBookmarkFolderMutationFn = Apollo.MutationFunction<
  ShareBookmarkFolderMutation,
  ShareBookmarkFolderMutationVariables
>

/**
 * __useShareBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useShareBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareBookmarkFolderMutation, { data, loading, error }] = useShareBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShareBookmarkFolderMutation,
    ShareBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShareBookmarkFolderMutation,
    ShareBookmarkFolderMutationVariables
  >(ShareBookmarkFolderDocument, options)
}
export type ShareBookmarkFolderMutationHookResult = ReturnType<
  typeof useShareBookmarkFolderMutation
>
export type ShareBookmarkFolderMutationResult =
  Apollo.MutationResult<ShareBookmarkFolderMutation>
export type ShareBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  ShareBookmarkFolderMutation,
  ShareBookmarkFolderMutationVariables
>
export const StripeSetupIntentDocument = gql`
  mutation stripeSetupIntent($input: StripeSetupIntentInput!) {
    stripeSetupIntent(input: $input) {
      clientSecret
      errors
    }
  }
`
export type StripeSetupIntentMutationFn = Apollo.MutationFunction<
  StripeSetupIntentMutation,
  StripeSetupIntentMutationVariables
>

/**
 * __useStripeSetupIntentMutation__
 *
 * To run a mutation, you first call `useStripeSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeSetupIntentMutation, { data, loading, error }] = useStripeSetupIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStripeSetupIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StripeSetupIntentMutation,
    StripeSetupIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StripeSetupIntentMutation,
    StripeSetupIntentMutationVariables
  >(StripeSetupIntentDocument, options)
}
export type StripeSetupIntentMutationHookResult = ReturnType<
  typeof useStripeSetupIntentMutation
>
export type StripeSetupIntentMutationResult =
  Apollo.MutationResult<StripeSetupIntentMutation>
export type StripeSetupIntentMutationOptions = Apollo.BaseMutationOptions<
  StripeSetupIntentMutation,
  StripeSetupIntentMutationVariables
>
export const SubmitOnboardingQuestionnaireDocument = gql`
  mutation SubmitOnboardingQuestionnaire($input: SubmitOnboardingQuestionnaireInput!) {
    submitOnboardingQuestionnaire(input: $input) {
      questionnaire {
        id
        user {
          id
          ...MemberProfileUserFields
        }
      }
      homepageChecks {
        currentChecklist
      }
    }
  }
  ${MemberProfileUserFieldsFragmentDoc}
`
export type SubmitOnboardingQuestionnaireMutationFn = Apollo.MutationFunction<
  SubmitOnboardingQuestionnaireMutation,
  SubmitOnboardingQuestionnaireMutationVariables
>

/**
 * __useSubmitOnboardingQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSubmitOnboardingQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOnboardingQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOnboardingQuestionnaireMutation, { data, loading, error }] = useSubmitOnboardingQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOnboardingQuestionnaireMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitOnboardingQuestionnaireMutation,
    SubmitOnboardingQuestionnaireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitOnboardingQuestionnaireMutation,
    SubmitOnboardingQuestionnaireMutationVariables
  >(SubmitOnboardingQuestionnaireDocument, options)
}
export type SubmitOnboardingQuestionnaireMutationHookResult = ReturnType<
  typeof useSubmitOnboardingQuestionnaireMutation
>
export type SubmitOnboardingQuestionnaireMutationResult =
  Apollo.MutationResult<SubmitOnboardingQuestionnaireMutation>
export type SubmitOnboardingQuestionnaireMutationOptions = Apollo.BaseMutationOptions<
  SubmitOnboardingQuestionnaireMutation,
  SubmitOnboardingQuestionnaireMutationVariables
>
export const SubmitPostApplySurveyDocument = gql`
  mutation SubmitPostApplySurvey($input: SubmitPostApplySurveyInput!) {
    submitPostApplySurvey(input: $input) {
      id
    }
  }
`
export type SubmitPostApplySurveyMutationFn = Apollo.MutationFunction<
  SubmitPostApplySurveyMutation,
  SubmitPostApplySurveyMutationVariables
>

/**
 * __useSubmitPostApplySurveyMutation__
 *
 * To run a mutation, you first call `useSubmitPostApplySurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPostApplySurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPostApplySurveyMutation, { data, loading, error }] = useSubmitPostApplySurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitPostApplySurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitPostApplySurveyMutation,
    SubmitPostApplySurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitPostApplySurveyMutation,
    SubmitPostApplySurveyMutationVariables
  >(SubmitPostApplySurveyDocument, options)
}
export type SubmitPostApplySurveyMutationHookResult = ReturnType<
  typeof useSubmitPostApplySurveyMutation
>
export type SubmitPostApplySurveyMutationResult =
  Apollo.MutationResult<SubmitPostApplySurveyMutation>
export type SubmitPostApplySurveyMutationOptions = Apollo.BaseMutationOptions<
  SubmitPostApplySurveyMutation,
  SubmitPostApplySurveyMutationVariables
>
export const SubscriptionSeatsChangePreviewDocument = gql`
  mutation subscriptionSeatsChangePreview($input: SubscriptionSeatsChangePreviewInput!) {
    subscriptionSeatsChangePreview(input: $input) {
      amount
    }
  }
`
export type SubscriptionSeatsChangePreviewMutationFn = Apollo.MutationFunction<
  SubscriptionSeatsChangePreviewMutation,
  SubscriptionSeatsChangePreviewMutationVariables
>

/**
 * __useSubscriptionSeatsChangePreviewMutation__
 *
 * To run a mutation, you first call `useSubscriptionSeatsChangePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionSeatsChangePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionSeatsChangePreviewMutation, { data, loading, error }] = useSubscriptionSeatsChangePreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriptionSeatsChangePreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscriptionSeatsChangePreviewMutation,
    SubscriptionSeatsChangePreviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubscriptionSeatsChangePreviewMutation,
    SubscriptionSeatsChangePreviewMutationVariables
  >(SubscriptionSeatsChangePreviewDocument, options)
}
export type SubscriptionSeatsChangePreviewMutationHookResult = ReturnType<
  typeof useSubscriptionSeatsChangePreviewMutation
>
export type SubscriptionSeatsChangePreviewMutationResult =
  Apollo.MutationResult<SubscriptionSeatsChangePreviewMutation>
export type SubscriptionSeatsChangePreviewMutationOptions = Apollo.BaseMutationOptions<
  SubscriptionSeatsChangePreviewMutation,
  SubscriptionSeatsChangePreviewMutationVariables
>
export const ToggleManualAssignSubscriptionDocument = gql`
  mutation toggleManualAssignSubscription($input: ToggleManualAssignSubscriptionInput!) {
    toggleManualAssignSubscription(input: $input) {
      manualAssign
    }
  }
`
export type ToggleManualAssignSubscriptionMutationFn = Apollo.MutationFunction<
  ToggleManualAssignSubscriptionMutation,
  ToggleManualAssignSubscriptionMutationVariables
>

/**
 * __useToggleManualAssignSubscriptionMutation__
 *
 * To run a mutation, you first call `useToggleManualAssignSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleManualAssignSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleManualAssignSubscriptionMutation, { data, loading, error }] = useToggleManualAssignSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleManualAssignSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleManualAssignSubscriptionMutation,
    ToggleManualAssignSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleManualAssignSubscriptionMutation,
    ToggleManualAssignSubscriptionMutationVariables
  >(ToggleManualAssignSubscriptionDocument, options)
}
export type ToggleManualAssignSubscriptionMutationHookResult = ReturnType<
  typeof useToggleManualAssignSubscriptionMutation
>
export type ToggleManualAssignSubscriptionMutationResult =
  Apollo.MutationResult<ToggleManualAssignSubscriptionMutation>
export type ToggleManualAssignSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ToggleManualAssignSubscriptionMutation,
  ToggleManualAssignSubscriptionMutationVariables
>
export const TogglePostFollowDocument = gql`
  mutation togglePostFollow($input: TogglePostFollowInput!) {
    togglePostFollow(input: $input) {
      following
    }
  }
`
export type TogglePostFollowMutationFn = Apollo.MutationFunction<
  TogglePostFollowMutation,
  TogglePostFollowMutationVariables
>

/**
 * __useTogglePostFollowMutation__
 *
 * To run a mutation, you first call `useTogglePostFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePostFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePostFollowMutation, { data, loading, error }] = useTogglePostFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTogglePostFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TogglePostFollowMutation,
    TogglePostFollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TogglePostFollowMutation, TogglePostFollowMutationVariables>(
    TogglePostFollowDocument,
    options
  )
}
export type TogglePostFollowMutationHookResult = ReturnType<
  typeof useTogglePostFollowMutation
>
export type TogglePostFollowMutationResult =
  Apollo.MutationResult<TogglePostFollowMutation>
export type TogglePostFollowMutationOptions = Apollo.BaseMutationOptions<
  TogglePostFollowMutation,
  TogglePostFollowMutationVariables
>
export const UpdateTopicActivityDocument = gql`
  mutation updateTopicActivity($input: UpdateTopicActivityInput!) {
    updateTopicActivity(input: $input) {
      id
      lastViewedAt
      success
    }
  }
`
export type UpdateTopicActivityMutationFn = Apollo.MutationFunction<
  UpdateTopicActivityMutation,
  UpdateTopicActivityMutationVariables
>

/**
 * __useUpdateTopicActivityMutation__
 *
 * To run a mutation, you first call `useUpdateTopicActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicActivityMutation, { data, loading, error }] = useUpdateTopicActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTopicActivityMutation,
    UpdateTopicActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTopicActivityMutation,
    UpdateTopicActivityMutationVariables
  >(UpdateTopicActivityDocument, options)
}
export type UpdateTopicActivityMutationHookResult = ReturnType<
  typeof useUpdateTopicActivityMutation
>
export type UpdateTopicActivityMutationResult =
  Apollo.MutationResult<UpdateTopicActivityMutation>
export type UpdateTopicActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateTopicActivityMutation,
  UpdateTopicActivityMutationVariables
>
export const TrackCourseEnrollmentConfirmedDocument = gql`
  mutation TrackCourseEnrollmentConfirmed($input: CourseEnrollmentConfirmedInput!) {
    trackCourseEnrollmentConfirmed(input: $input) {
      errors
    }
  }
`
export type TrackCourseEnrollmentConfirmedMutationFn = Apollo.MutationFunction<
  TrackCourseEnrollmentConfirmedMutation,
  TrackCourseEnrollmentConfirmedMutationVariables
>

/**
 * __useTrackCourseEnrollmentConfirmedMutation__
 *
 * To run a mutation, you first call `useTrackCourseEnrollmentConfirmedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackCourseEnrollmentConfirmedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackCourseEnrollmentConfirmedMutation, { data, loading, error }] = useTrackCourseEnrollmentConfirmedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackCourseEnrollmentConfirmedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackCourseEnrollmentConfirmedMutation,
    TrackCourseEnrollmentConfirmedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TrackCourseEnrollmentConfirmedMutation,
    TrackCourseEnrollmentConfirmedMutationVariables
  >(TrackCourseEnrollmentConfirmedDocument, options)
}
export type TrackCourseEnrollmentConfirmedMutationHookResult = ReturnType<
  typeof useTrackCourseEnrollmentConfirmedMutation
>
export type TrackCourseEnrollmentConfirmedMutationResult =
  Apollo.MutationResult<TrackCourseEnrollmentConfirmedMutation>
export type TrackCourseEnrollmentConfirmedMutationOptions = Apollo.BaseMutationOptions<
  TrackCourseEnrollmentConfirmedMutation,
  TrackCourseEnrollmentConfirmedMutationVariables
>
export const TrackCourseEnrollmentStartedDocument = gql`
  mutation TrackCourseEnrollmentStarted($input: CourseEnrollmentStartedInput!) {
    trackCourseEnrollmentStarted(input: $input) {
      errors
    }
  }
`
export type TrackCourseEnrollmentStartedMutationFn = Apollo.MutationFunction<
  TrackCourseEnrollmentStartedMutation,
  TrackCourseEnrollmentStartedMutationVariables
>

/**
 * __useTrackCourseEnrollmentStartedMutation__
 *
 * To run a mutation, you first call `useTrackCourseEnrollmentStartedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackCourseEnrollmentStartedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackCourseEnrollmentStartedMutation, { data, loading, error }] = useTrackCourseEnrollmentStartedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackCourseEnrollmentStartedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackCourseEnrollmentStartedMutation,
    TrackCourseEnrollmentStartedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TrackCourseEnrollmentStartedMutation,
    TrackCourseEnrollmentStartedMutationVariables
  >(TrackCourseEnrollmentStartedDocument, options)
}
export type TrackCourseEnrollmentStartedMutationHookResult = ReturnType<
  typeof useTrackCourseEnrollmentStartedMutation
>
export type TrackCourseEnrollmentStartedMutationResult =
  Apollo.MutationResult<TrackCourseEnrollmentStartedMutation>
export type TrackCourseEnrollmentStartedMutationOptions = Apollo.BaseMutationOptions<
  TrackCourseEnrollmentStartedMutation,
  TrackCourseEnrollmentStartedMutationVariables
>
export const TrackServerEventDocument = gql`
  mutation TrackServerEvent($input: TrackServerEventInput!) {
    trackServerEvent(input: $input) {
      errors
    }
  }
`
export type TrackServerEventMutationFn = Apollo.MutationFunction<
  TrackServerEventMutation,
  TrackServerEventMutationVariables
>

/**
 * __useTrackServerEventMutation__
 *
 * To run a mutation, you first call `useTrackServerEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackServerEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackServerEventMutation, { data, loading, error }] = useTrackServerEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackServerEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackServerEventMutation,
    TrackServerEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TrackServerEventMutation, TrackServerEventMutationVariables>(
    TrackServerEventDocument,
    options
  )
}
export type TrackServerEventMutationHookResult = ReturnType<
  typeof useTrackServerEventMutation
>
export type TrackServerEventMutationResult =
  Apollo.MutationResult<TrackServerEventMutation>
export type TrackServerEventMutationOptions = Apollo.BaseMutationOptions<
  TrackServerEventMutation,
  TrackServerEventMutationVariables
>
export const UnassignCohortCreditDocument = gql`
  mutation UnassignCohortCredit($input: UnassignCohortCreditInput!) {
    unassignCohortCredit(input: $input) {
      seat {
        id
      }
      errors
    }
  }
`
export type UnassignCohortCreditMutationFn = Apollo.MutationFunction<
  UnassignCohortCreditMutation,
  UnassignCohortCreditMutationVariables
>

/**
 * __useUnassignCohortCreditMutation__
 *
 * To run a mutation, you first call `useUnassignCohortCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCohortCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCohortCreditMutation, { data, loading, error }] = useUnassignCohortCreditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCohortCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignCohortCreditMutation,
    UnassignCohortCreditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnassignCohortCreditMutation,
    UnassignCohortCreditMutationVariables
  >(UnassignCohortCreditDocument, options)
}
export type UnassignCohortCreditMutationHookResult = ReturnType<
  typeof useUnassignCohortCreditMutation
>
export type UnassignCohortCreditMutationResult =
  Apollo.MutationResult<UnassignCohortCreditMutation>
export type UnassignCohortCreditMutationOptions = Apollo.BaseMutationOptions<
  UnassignCohortCreditMutation,
  UnassignCohortCreditMutationVariables
>
export const UnshareBookmarkFolderDocument = gql`
  mutation unshareBookmarkFolder($input: UnshareBookmarkFolderInput!) {
    unshareBookmarkFolder(input: $input) {
      bookmarkFolder {
        ...BookmarkFolderParts
      }
      sharedFolder {
        id
        ...SharedFolder
      }
      errors
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${SharedFolderFragmentDoc}
`
export type UnshareBookmarkFolderMutationFn = Apollo.MutationFunction<
  UnshareBookmarkFolderMutation,
  UnshareBookmarkFolderMutationVariables
>

/**
 * __useUnshareBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useUnshareBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnshareBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unshareBookmarkFolderMutation, { data, loading, error }] = useUnshareBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnshareBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnshareBookmarkFolderMutation,
    UnshareBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnshareBookmarkFolderMutation,
    UnshareBookmarkFolderMutationVariables
  >(UnshareBookmarkFolderDocument, options)
}
export type UnshareBookmarkFolderMutationHookResult = ReturnType<
  typeof useUnshareBookmarkFolderMutation
>
export type UnshareBookmarkFolderMutationResult =
  Apollo.MutationResult<UnshareBookmarkFolderMutation>
export type UnshareBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  UnshareBookmarkFolderMutation,
  UnshareBookmarkFolderMutationVariables
>
export const UpdateArtifactCommentDocument = gql`
  mutation updateArtifactComment($input: UpdateArtifactCommentInput!) {
    updateArtifactComment(input: $input) {
      artifactComment {
        id
        createdAt
      }
      errors
    }
  }
`
export type UpdateArtifactCommentMutationFn = Apollo.MutationFunction<
  UpdateArtifactCommentMutation,
  UpdateArtifactCommentMutationVariables
>

/**
 * __useUpdateArtifactCommentMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactCommentMutation, { data, loading, error }] = useUpdateArtifactCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArtifactCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArtifactCommentMutation,
    UpdateArtifactCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateArtifactCommentMutation,
    UpdateArtifactCommentMutationVariables
  >(UpdateArtifactCommentDocument, options)
}
export type UpdateArtifactCommentMutationHookResult = ReturnType<
  typeof useUpdateArtifactCommentMutation
>
export type UpdateArtifactCommentMutationResult =
  Apollo.MutationResult<UpdateArtifactCommentMutation>
export type UpdateArtifactCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateArtifactCommentMutation,
  UpdateArtifactCommentMutationVariables
>
export const UpdateBookmarkDocument = gql`
  mutation updateBookmark($input: UpdateBookmarkInput!) {
    updateBookmark(input: $input) {
      bookmark {
        ...BookmarkParts
      }
      collectionActivity {
        ...CollectionActivityParts
      }
      errors
    }
  }
  ${BookmarkPartsFragmentDoc}
  ${CollectionActivityPartsFragmentDoc}
`
export type UpdateBookmarkMutationFn = Apollo.MutationFunction<
  UpdateBookmarkMutation,
  UpdateBookmarkMutationVariables
>

/**
 * __useUpdateBookmarkMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkMutation, { data, loading, error }] = useUpdateBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookmarkMutation,
    UpdateBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBookmarkMutation, UpdateBookmarkMutationVariables>(
    UpdateBookmarkDocument,
    options
  )
}
export type UpdateBookmarkMutationHookResult = ReturnType<
  typeof useUpdateBookmarkMutation
>
export type UpdateBookmarkMutationResult = Apollo.MutationResult<UpdateBookmarkMutation>
export type UpdateBookmarkMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookmarkMutation,
  UpdateBookmarkMutationVariables
>
export const UpdateBookmarkFolderDocument = gql`
  mutation updateBookmarkFolder($input: UpdateBookmarkFolderInput!) {
    updateBookmarkFolder(input: $input) {
      bookmarkFolder {
        id
        name
        description
        parentId
        reforgeCollection
        forkedFromId
        user {
          id
          fullName
        }
        sharedFolders {
          id
          status
          sharedPartyType
        }
      }
      errors
    }
  }
`
export type UpdateBookmarkFolderMutationFn = Apollo.MutationFunction<
  UpdateBookmarkFolderMutation,
  UpdateBookmarkFolderMutationVariables
>

/**
 * __useUpdateBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkFolderMutation, { data, loading, error }] = useUpdateBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookmarkFolderMutation,
    UpdateBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBookmarkFolderMutation,
    UpdateBookmarkFolderMutationVariables
  >(UpdateBookmarkFolderDocument, options)
}
export type UpdateBookmarkFolderMutationHookResult = ReturnType<
  typeof useUpdateBookmarkFolderMutation
>
export type UpdateBookmarkFolderMutationResult =
  Apollo.MutationResult<UpdateBookmarkFolderMutation>
export type UpdateBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookmarkFolderMutation,
  UpdateBookmarkFolderMutationVariables
>
export const UpdateCclDocument = gql`
  mutation updateCcl($input: UpdateCclInput!) {
    updateCcl(input: $input) {
      status
    }
  }
`
export type UpdateCclMutationFn = Apollo.MutationFunction<
  UpdateCclMutation,
  UpdateCclMutationVariables
>

/**
 * __useUpdateCclMutation__
 *
 * To run a mutation, you first call `useUpdateCclMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCclMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCclMutation, { data, loading, error }] = useUpdateCclMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCclMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCclMutation, UpdateCclMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCclMutation, UpdateCclMutationVariables>(
    UpdateCclDocument,
    options
  )
}
export type UpdateCclMutationHookResult = ReturnType<typeof useUpdateCclMutation>
export type UpdateCclMutationResult = Apollo.MutationResult<UpdateCclMutation>
export type UpdateCclMutationOptions = Apollo.BaseMutationOptions<
  UpdateCclMutation,
  UpdateCclMutationVariables
>
export const UpdateCohortPostDocument = gql`
  mutation updateCohortPost($input: UpdateCohortPostInput!) {
    updateCohortPost(input: $input) {
      id
    }
  }
`
export type UpdateCohortPostMutationFn = Apollo.MutationFunction<
  UpdateCohortPostMutation,
  UpdateCohortPostMutationVariables
>

/**
 * __useUpdateCohortPostMutation__
 *
 * To run a mutation, you first call `useUpdateCohortPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCohortPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCohortPostMutation, { data, loading, error }] = useUpdateCohortPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCohortPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCohortPostMutation,
    UpdateCohortPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCohortPostMutation, UpdateCohortPostMutationVariables>(
    UpdateCohortPostDocument,
    options
  )
}
export type UpdateCohortPostMutationHookResult = ReturnType<
  typeof useUpdateCohortPostMutation
>
export type UpdateCohortPostMutationResult =
  Apollo.MutationResult<UpdateCohortPostMutation>
export type UpdateCohortPostMutationOptions = Apollo.BaseMutationOptions<
  UpdateCohortPostMutation,
  UpdateCohortPostMutationVariables
>
export const UpdateCohortReplyDocument = gql`
  mutation UpdateCohortReply($input: UpdateCohortReplyInput!) {
    updateCohortReply(input: $input) {
      id
    }
  }
`
export type UpdateCohortReplyMutationFn = Apollo.MutationFunction<
  UpdateCohortReplyMutation,
  UpdateCohortReplyMutationVariables
>

/**
 * __useUpdateCohortReplyMutation__
 *
 * To run a mutation, you first call `useUpdateCohortReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCohortReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCohortReplyMutation, { data, loading, error }] = useUpdateCohortReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCohortReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCohortReplyMutation,
    UpdateCohortReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCohortReplyMutation,
    UpdateCohortReplyMutationVariables
  >(UpdateCohortReplyDocument, options)
}
export type UpdateCohortReplyMutationHookResult = ReturnType<
  typeof useUpdateCohortReplyMutation
>
export type UpdateCohortReplyMutationResult =
  Apollo.MutationResult<UpdateCohortReplyMutation>
export type UpdateCohortReplyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCohortReplyMutation,
  UpdateCohortReplyMutationVariables
>
export const UpdateCourseEnrollmentDocument = gql`
  mutation UpdateCourseEnrollment($input: UpdateCourseEnrollmentInput!) {
    updateCourseEnrollment(input: $input) {
      userCourseId
      errors
    }
  }
`
export type UpdateCourseEnrollmentMutationFn = Apollo.MutationFunction<
  UpdateCourseEnrollmentMutation,
  UpdateCourseEnrollmentMutationVariables
>

/**
 * __useUpdateCourseEnrollmentMutation__
 *
 * To run a mutation, you first call `useUpdateCourseEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseEnrollmentMutation, { data, loading, error }] = useUpdateCourseEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseEnrollmentMutation,
    UpdateCourseEnrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCourseEnrollmentMutation,
    UpdateCourseEnrollmentMutationVariables
  >(UpdateCourseEnrollmentDocument, options)
}
export type UpdateCourseEnrollmentMutationHookResult = ReturnType<
  typeof useUpdateCourseEnrollmentMutation
>
export type UpdateCourseEnrollmentMutationResult =
  Apollo.MutationResult<UpdateCourseEnrollmentMutation>
export type UpdateCourseEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseEnrollmentMutation,
  UpdateCourseEnrollmentMutationVariables
>
export const UpdateCourseSessionDocument = gql`
  mutation UpdateCourseSession($input: UpdateCourseSessionInput!) {
    updateCourseSession(input: $input) {
      id
      errors
    }
  }
`
export type UpdateCourseSessionMutationFn = Apollo.MutationFunction<
  UpdateCourseSessionMutation,
  UpdateCourseSessionMutationVariables
>

/**
 * __useUpdateCourseSessionMutation__
 *
 * To run a mutation, you first call `useUpdateCourseSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseSessionMutation, { data, loading, error }] = useUpdateCourseSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseSessionMutation,
    UpdateCourseSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCourseSessionMutation,
    UpdateCourseSessionMutationVariables
  >(UpdateCourseSessionDocument, options)
}
export type UpdateCourseSessionMutationHookResult = ReturnType<
  typeof useUpdateCourseSessionMutation
>
export type UpdateCourseSessionMutationResult =
  Apollo.MutationResult<UpdateCourseSessionMutation>
export type UpdateCourseSessionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseSessionMutation,
  UpdateCourseSessionMutationVariables
>
export const UpdateCustomerContactDocument = gql`
  mutation UpdateCustomerContact($input: UpdateCustomerContactInput!) {
    updateCustomerContact(input: $input) {
      name
      address {
        ...BillingAddress
      }
    }
  }
  ${BillingAddressFragmentDoc}
`
export type UpdateCustomerContactMutationFn = Apollo.MutationFunction<
  UpdateCustomerContactMutation,
  UpdateCustomerContactMutationVariables
>

/**
 * __useUpdateCustomerContactMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerContactMutation, { data, loading, error }] = useUpdateCustomerContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerContactMutation,
    UpdateCustomerContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCustomerContactMutation,
    UpdateCustomerContactMutationVariables
  >(UpdateCustomerContactDocument, options)
}
export type UpdateCustomerContactMutationHookResult = ReturnType<
  typeof useUpdateCustomerContactMutation
>
export type UpdateCustomerContactMutationResult =
  Apollo.MutationResult<UpdateCustomerContactMutation>
export type UpdateCustomerContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerContactMutation,
  UpdateCustomerContactMutationVariables
>
export const UpdateInvoiceUserFieldsDocument = gql`
  mutation updateInvoiceUserFields($input: UpdateInvoiceUserFieldsInput!) {
    updateInvoiceUserFields(input: $input) {
      id
    }
  }
`
export type UpdateInvoiceUserFieldsMutationFn = Apollo.MutationFunction<
  UpdateInvoiceUserFieldsMutation,
  UpdateInvoiceUserFieldsMutationVariables
>

/**
 * __useUpdateInvoiceUserFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceUserFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceUserFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceUserFieldsMutation, { data, loading, error }] = useUpdateInvoiceUserFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceUserFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceUserFieldsMutation,
    UpdateInvoiceUserFieldsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateInvoiceUserFieldsMutation,
    UpdateInvoiceUserFieldsMutationVariables
  >(UpdateInvoiceUserFieldsDocument, options)
}
export type UpdateInvoiceUserFieldsMutationHookResult = ReturnType<
  typeof useUpdateInvoiceUserFieldsMutation
>
export type UpdateInvoiceUserFieldsMutationResult =
  Apollo.MutationResult<UpdateInvoiceUserFieldsMutation>
export type UpdateInvoiceUserFieldsMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceUserFieldsMutation,
  UpdateInvoiceUserFieldsMutationVariables
>
export const UpdatePaymentMethodDocument = gql`
  mutation updatePaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      errors
    }
  }
`
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument, options)
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>
export type UpdatePaymentMethodMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodMutation>
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>
export const UpdatePreferencesDocument = gql`
  mutation updatePreferences($input: UpdatePreferencesInput!) {
    updatePreferences(input: $input) {
      success
    }
  }
`
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >(UpdatePreferencesDocument, options)
}
export type UpdatePreferencesMutationHookResult = ReturnType<
  typeof useUpdatePreferencesMutation
>
export type UpdatePreferencesMutationResult =
  Apollo.MutationResult<UpdatePreferencesMutation>
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>
export const UpdateProjectDeliverableDocument = gql`
  mutation UpdateProjectDeliverable($input: UpdateProjectDeliverableInput!) {
    updateProjectDeliverable(input: $input) {
      errors
      projectDeliverable {
        id
        url
      }
    }
  }
`
export type UpdateProjectDeliverableMutationFn = Apollo.MutationFunction<
  UpdateProjectDeliverableMutation,
  UpdateProjectDeliverableMutationVariables
>

/**
 * __useUpdateProjectDeliverableMutation__
 *
 * To run a mutation, you first call `useUpdateProjectDeliverableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectDeliverableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectDeliverableMutation, { data, loading, error }] = useUpdateProjectDeliverableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectDeliverableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectDeliverableMutation,
    UpdateProjectDeliverableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProjectDeliverableMutation,
    UpdateProjectDeliverableMutationVariables
  >(UpdateProjectDeliverableDocument, options)
}
export type UpdateProjectDeliverableMutationHookResult = ReturnType<
  typeof useUpdateProjectDeliverableMutation
>
export type UpdateProjectDeliverableMutationResult =
  Apollo.MutationResult<UpdateProjectDeliverableMutation>
export type UpdateProjectDeliverableMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectDeliverableMutation,
  UpdateProjectDeliverableMutationVariables
>
export const UpdateReplyDocument = gql`
  mutation updateReply($input: UpdateReplyInput!) {
    updateReply(input: $input) {
      id
      replyableId
    }
  }
`
export type UpdateReplyMutationFn = Apollo.MutationFunction<
  UpdateReplyMutation,
  UpdateReplyMutationVariables
>

/**
 * __useUpdateReplyMutation__
 *
 * To run a mutation, you first call `useUpdateReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReplyMutation, { data, loading, error }] = useUpdateReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReplyMutation,
    UpdateReplyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateReplyMutation, UpdateReplyMutationVariables>(
    UpdateReplyDocument,
    options
  )
}
export type UpdateReplyMutationHookResult = ReturnType<typeof useUpdateReplyMutation>
export type UpdateReplyMutationResult = Apollo.MutationResult<UpdateReplyMutation>
export type UpdateReplyMutationOptions = Apollo.BaseMutationOptions<
  UpdateReplyMutation,
  UpdateReplyMutationVariables
>
export const UpdateTeamDocument = gql`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      success
      errors
      team {
        id
        domainName
        isDiscoverable
        logoUrl
        name
      }
    }
  }
`
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMutation,
    UpdateTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options
  )
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMutation,
  UpdateTeamMutationVariables
>
export const UpdateTeamPostDocument = gql`
  mutation updateTeamPost($input: UpdateTeamPostInput!) {
    updateTeamPost(input: $input) {
      errors
      teamPost {
        id
        body
      }
    }
  }
`
export type UpdateTeamPostMutationFn = Apollo.MutationFunction<
  UpdateTeamPostMutation,
  UpdateTeamPostMutationVariables
>

/**
 * __useUpdateTeamPostMutation__
 *
 * To run a mutation, you first call `useUpdateTeamPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamPostMutation, { data, loading, error }] = useUpdateTeamPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamPostMutation,
    UpdateTeamPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTeamPostMutation, UpdateTeamPostMutationVariables>(
    UpdateTeamPostDocument,
    options
  )
}
export type UpdateTeamPostMutationHookResult = ReturnType<
  typeof useUpdateTeamPostMutation
>
export type UpdateTeamPostMutationResult = Apollo.MutationResult<UpdateTeamPostMutation>
export type UpdateTeamPostMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamPostMutation,
  UpdateTeamPostMutationVariables
>
export const UpdateTimezoneDocument = gql`
  mutation UpdateTimezone($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors
      user {
        id
        timezone
      }
    }
  }
`
export type UpdateTimezoneMutationFn = Apollo.MutationFunction<
  UpdateTimezoneMutation,
  UpdateTimezoneMutationVariables
>

/**
 * __useUpdateTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimezoneMutation, { data, loading, error }] = useUpdateTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTimezoneMutation,
    UpdateTimezoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTimezoneMutation, UpdateTimezoneMutationVariables>(
    UpdateTimezoneDocument,
    options
  )
}
export type UpdateTimezoneMutationHookResult = ReturnType<
  typeof useUpdateTimezoneMutation
>
export type UpdateTimezoneMutationResult = Apollo.MutationResult<UpdateTimezoneMutation>
export type UpdateTimezoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateTimezoneMutation,
  UpdateTimezoneMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors
      user {
        id
        aboutMeText
        allowDirectMessages
        askMeAboutText
        avatarUrl
        communityInterestText
        companyName
        companyProductText
        firstName
        lastName
        fullName
        forceFlag
        learningGoalsText
        linkedin
        location
        slackResponseTime
        role
        status
        timezone
        twitter
        profile {
          aboutMeText
          allowDirectMessages
          askMeAboutText
          avatarUrl
          communityInterestText
          companyName
          companyProductText
          ctaText
          ctaUrl
          firstName
          lastName
          fullName
          learningGoalsText
          linkedin
          location
          role
          status
          timezone
          twitter
        }
        ...MemberProfileUserFields
      }
    }
  }
  ${MemberProfileUserFieldsFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const UpdateUserStatusDocument = gql`
  mutation UpdateUserStatus($input: UpdateUserInput!) {
    updateUser(input: $input) {
      errors
      user {
        id
        profile {
          status
        }
      }
    }
  }
`
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<
  UpdateUserStatusMutation,
  UpdateUserStatusMutationVariables
>

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserStatusMutation,
    UpdateUserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(
    UpdateUserStatusDocument,
    options
  )
}
export type UpdateUserStatusMutationHookResult = ReturnType<
  typeof useUpdateUserStatusMutation
>
export type UpdateUserStatusMutationResult =
  Apollo.MutationResult<UpdateUserStatusMutation>
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserStatusMutation,
  UpdateUserStatusMutationVariables
>
export const UpdateUserCohortDocument = gql`
  mutation UpdateUserCohort($input: UpdateUserCohortInput!) {
    updateUserCohort(input: $input) {
      id
      errors
    }
  }
`
export type UpdateUserCohortMutationFn = Apollo.MutationFunction<
  UpdateUserCohortMutation,
  UpdateUserCohortMutationVariables
>

/**
 * __useUpdateUserCohortMutation__
 *
 * To run a mutation, you first call `useUpdateUserCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCohortMutation, { data, loading, error }] = useUpdateUserCohortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCohortMutation,
    UpdateUserCohortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserCohortMutation, UpdateUserCohortMutationVariables>(
    UpdateUserCohortDocument,
    options
  )
}
export type UpdateUserCohortMutationHookResult = ReturnType<
  typeof useUpdateUserCohortMutation
>
export type UpdateUserCohortMutationResult =
  Apollo.MutationResult<UpdateUserCohortMutation>
export type UpdateUserCohortMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserCohortMutation,
  UpdateUserCohortMutationVariables
>
export const UpdateUserCourseDocument = gql`
  mutation UpdateUserCourse($input: UpdateUserCourseInput!) {
    updateUserCourse(input: $input) {
      id
      errors
    }
  }
`
export type UpdateUserCourseMutationFn = Apollo.MutationFunction<
  UpdateUserCourseMutation,
  UpdateUserCourseMutationVariables
>

/**
 * __useUpdateUserCourseMutation__
 *
 * To run a mutation, you first call `useUpdateUserCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCourseMutation, { data, loading, error }] = useUpdateUserCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserCourseMutation,
    UpdateUserCourseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserCourseMutation, UpdateUserCourseMutationVariables>(
    UpdateUserCourseDocument,
    options
  )
}
export type UpdateUserCourseMutationHookResult = ReturnType<
  typeof useUpdateUserCourseMutation
>
export type UpdateUserCourseMutationResult =
  Apollo.MutationResult<UpdateUserCourseMutation>
export type UpdateUserCourseMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserCourseMutation,
  UpdateUserCourseMutationVariables
>
export const UpgradeSubscriptionDocument = gql`
  mutation UpgradeSubscription($input: UpgradeSubscriptionInput!) {
    upgradeSubscription(input: $input) {
      success
      errors
    }
  }
`
export type UpgradeSubscriptionMutationFn = Apollo.MutationFunction<
  UpgradeSubscriptionMutation,
  UpgradeSubscriptionMutationVariables
>

/**
 * __useUpgradeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeSubscriptionMutation, { data, loading, error }] = useUpgradeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeSubscriptionMutation,
    UpgradeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpgradeSubscriptionMutation,
    UpgradeSubscriptionMutationVariables
  >(UpgradeSubscriptionDocument, options)
}
export type UpgradeSubscriptionMutationHookResult = ReturnType<
  typeof useUpgradeSubscriptionMutation
>
export type UpgradeSubscriptionMutationResult =
  Apollo.MutationResult<UpgradeSubscriptionMutation>
export type UpgradeSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpgradeSubscriptionMutation,
  UpgradeSubscriptionMutationVariables
>
export const UrlChangeDocument = gql`
  mutation UrlChange($input: UrlChangeInput!) {
    urlChange(input: $input) {
      errors
    }
  }
`
export type UrlChangeMutationFn = Apollo.MutationFunction<
  UrlChangeMutation,
  UrlChangeMutationVariables
>

/**
 * __useUrlChangeMutation__
 *
 * To run a mutation, you first call `useUrlChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUrlChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [urlChangeMutation, { data, loading, error }] = useUrlChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUrlChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<UrlChangeMutation, UrlChangeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UrlChangeMutation, UrlChangeMutationVariables>(
    UrlChangeDocument,
    options
  )
}
export type UrlChangeMutationHookResult = ReturnType<typeof useUrlChangeMutation>
export type UrlChangeMutationResult = Apollo.MutationResult<UrlChangeMutation>
export type UrlChangeMutationOptions = Apollo.BaseMutationOptions<
  UrlChangeMutation,
  UrlChangeMutationVariables
>
export const VerifyEmailDocument = gql`
  mutation verifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      companyName
      isPrimaryEmail
      name
    }
  }
`
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options
  )
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>
export const ViewBookmarkFolderDocument = gql`
  mutation viewBookmarkFolder($input: ViewBookmarkFolderInput!) {
    viewBookmarkFolder(input: $input) {
      bookmarkFolder {
        ...BookmarkFolderWithSharingParts
      }
      activity {
        ...CollectionActivityParts
      }
      errors
      isOnboardingCollection
    }
  }
  ${BookmarkFolderWithSharingPartsFragmentDoc}
  ${CollectionActivityPartsFragmentDoc}
`
export type ViewBookmarkFolderMutationFn = Apollo.MutationFunction<
  ViewBookmarkFolderMutation,
  ViewBookmarkFolderMutationVariables
>

/**
 * __useViewBookmarkFolderMutation__
 *
 * To run a mutation, you first call `useViewBookmarkFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewBookmarkFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewBookmarkFolderMutation, { data, loading, error }] = useViewBookmarkFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewBookmarkFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ViewBookmarkFolderMutation,
    ViewBookmarkFolderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ViewBookmarkFolderMutation,
    ViewBookmarkFolderMutationVariables
  >(ViewBookmarkFolderDocument, options)
}
export type ViewBookmarkFolderMutationHookResult = ReturnType<
  typeof useViewBookmarkFolderMutation
>
export type ViewBookmarkFolderMutationResult =
  Apollo.MutationResult<ViewBookmarkFolderMutation>
export type ViewBookmarkFolderMutationOptions = Apollo.BaseMutationOptions<
  ViewBookmarkFolderMutation,
  ViewBookmarkFolderMutationVariables
>
export const WhatWeCanDoBetterDocument = gql`
  mutation WhatWeCanDoBetter($input: WhatWeCanDoBetterInput!) {
    whatWeCanDoBetter(input: $input) {
      success
      errors
    }
  }
`
export type WhatWeCanDoBetterMutationFn = Apollo.MutationFunction<
  WhatWeCanDoBetterMutation,
  WhatWeCanDoBetterMutationVariables
>

/**
 * __useWhatWeCanDoBetterMutation__
 *
 * To run a mutation, you first call `useWhatWeCanDoBetterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWhatWeCanDoBetterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [whatWeCanDoBetterMutation, { data, loading, error }] = useWhatWeCanDoBetterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWhatWeCanDoBetterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WhatWeCanDoBetterMutation,
    WhatWeCanDoBetterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WhatWeCanDoBetterMutation,
    WhatWeCanDoBetterMutationVariables
  >(WhatWeCanDoBetterDocument, options)
}
export type WhatWeCanDoBetterMutationHookResult = ReturnType<
  typeof useWhatWeCanDoBetterMutation
>
export type WhatWeCanDoBetterMutationResult =
  Apollo.MutationResult<WhatWeCanDoBetterMutation>
export type WhatWeCanDoBetterMutationOptions = Apollo.BaseMutationOptions<
  WhatWeCanDoBetterMutation,
  WhatWeCanDoBetterMutationVariables
>
export const AcceptingApplicationsDocument = gql`
  query AcceptingApplications {
    acceptingApplications
  }
`

/**
 * __useAcceptingApplicationsQuery__
 *
 * To run a query within a React component, call `useAcceptingApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptingApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptingApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcceptingApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AcceptingApplicationsQuery,
    AcceptingApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AcceptingApplicationsQuery, AcceptingApplicationsQueryVariables>(
    AcceptingApplicationsDocument,
    options
  )
}
export function useAcceptingApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcceptingApplicationsQuery,
    AcceptingApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AcceptingApplicationsQuery,
    AcceptingApplicationsQueryVariables
  >(AcceptingApplicationsDocument, options)
}
export type AcceptingApplicationsQueryHookResult = ReturnType<
  typeof useAcceptingApplicationsQuery
>
export type AcceptingApplicationsLazyQueryHookResult = ReturnType<
  typeof useAcceptingApplicationsLazyQuery
>
export type AcceptingApplicationsQueryResult = Apollo.QueryResult<
  AcceptingApplicationsQuery,
  AcceptingApplicationsQueryVariables
>
export const ActiveProgramDocument = gql`
  query activeProgram {
    currentUser {
      activeProgram {
        id
        enrolled
        progress
      }
    }
  }
`

/**
 * __useActiveProgramQuery__
 *
 * To run a query within a React component, call `useActiveProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveProgramQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveProgramQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveProgramQuery, ActiveProgramQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActiveProgramQuery, ActiveProgramQueryVariables>(
    ActiveProgramDocument,
    options
  )
}
export function useActiveProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveProgramQuery,
    ActiveProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActiveProgramQuery, ActiveProgramQueryVariables>(
    ActiveProgramDocument,
    options
  )
}
export type ActiveProgramQueryHookResult = ReturnType<typeof useActiveProgramQuery>
export type ActiveProgramLazyQueryHookResult = ReturnType<
  typeof useActiveProgramLazyQuery
>
export type ActiveProgramQueryResult = Apollo.QueryResult<
  ActiveProgramQuery,
  ActiveProgramQueryVariables
>
export const ActivityListDocument = gql`
  query activityList($page: Int, $filter: String) {
    activityList(page: $page, filter: $filter) {
      activities {
        action {
          actionString
          type
          reactions
        }
        activityIds
        excerpt
        noun {
          asString
          name
          pattern
          slug
        }
        read
        subjects {
          name
          slug
          remainingNames
        }
        timestamp
        trackedAnchor
        trackedId
        trackedPath
        trackedSlug
        trackedTitle
        trackedType
        cohortChannelHref
        collectionPath
        collectionName
      }
      meta {
        currentPage
        totalEntries
        totalPages
      }
    }
  }
`

/**
 * __useActivityListQuery__
 *
 * To run a query within a React component, call `useActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useActivityListQuery(
  baseOptions?: Apollo.QueryHookOptions<ActivityListQuery, ActivityListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListDocument,
    options
  )
}
export function useActivityListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivityListQuery, ActivityListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListDocument,
    options
  )
}
export type ActivityListQueryHookResult = ReturnType<typeof useActivityListQuery>
export type ActivityListLazyQueryHookResult = ReturnType<typeof useActivityListLazyQuery>
export type ActivityListQueryResult = Apollo.QueryResult<
  ActivityListQuery,
  ActivityListQueryVariables
>
export const AdminCourseSessionDetailDocument = gql`
  query AdminCourseSessionDetail($courseSessionId: ID!) {
    courseSessionDetail(courseSessionId: $courseSessionId) {
      dbHostAccessAllowedForUserIds
      dbCourseSessionId
      dbCourseId
      dbCourseSlug
      dbCourseTitle
      dbSanityId
      dbStartsAt
      dbSlackChannelId
      dbEndsAt
      dbEnrollmentStartsAt
      dbMaxEnrollmentPaid
      dbMaxEnrollmentTotal
      dbMaxEnrollmentUnlimited
      dbPrice
      dbSessionGroupId
      dbStripeProductId
      sanityCourseId
      sanityCourseTitle
      sanityCourseSessionId
      sanityCourseSessionTitle
      sanityDuration
      sanitySlackChannelId
      sanityStartsAt
      sanityEndsAt
    }
  }
`

/**
 * __useAdminCourseSessionDetailQuery__
 *
 * To run a query within a React component, call `useAdminCourseSessionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCourseSessionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCourseSessionDetailQuery({
 *   variables: {
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useAdminCourseSessionDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminCourseSessionDetailQuery,
    AdminCourseSessionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminCourseSessionDetailQuery,
    AdminCourseSessionDetailQueryVariables
  >(AdminCourseSessionDetailDocument, options)
}
export function useAdminCourseSessionDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminCourseSessionDetailQuery,
    AdminCourseSessionDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminCourseSessionDetailQuery,
    AdminCourseSessionDetailQueryVariables
  >(AdminCourseSessionDetailDocument, options)
}
export type AdminCourseSessionDetailQueryHookResult = ReturnType<
  typeof useAdminCourseSessionDetailQuery
>
export type AdminCourseSessionDetailLazyQueryHookResult = ReturnType<
  typeof useAdminCourseSessionDetailLazyQuery
>
export type AdminCourseSessionDetailQueryResult = Apollo.QueryResult<
  AdminCourseSessionDetailQuery,
  AdminCourseSessionDetailQueryVariables
>
export const AnticopyExceptionIdsDocument = gql`
  query anticopyExceptionIds {
    anticopyExceptionIds
  }
`

/**
 * __useAnticopyExceptionIdsQuery__
 *
 * To run a query within a React component, call `useAnticopyExceptionIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnticopyExceptionIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnticopyExceptionIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnticopyExceptionIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AnticopyExceptionIdsQuery,
    AnticopyExceptionIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AnticopyExceptionIdsQuery, AnticopyExceptionIdsQueryVariables>(
    AnticopyExceptionIdsDocument,
    options
  )
}
export function useAnticopyExceptionIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AnticopyExceptionIdsQuery,
    AnticopyExceptionIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AnticopyExceptionIdsQuery,
    AnticopyExceptionIdsQueryVariables
  >(AnticopyExceptionIdsDocument, options)
}
export type AnticopyExceptionIdsQueryHookResult = ReturnType<
  typeof useAnticopyExceptionIdsQuery
>
export type AnticopyExceptionIdsLazyQueryHookResult = ReturnType<
  typeof useAnticopyExceptionIdsLazyQuery
>
export type AnticopyExceptionIdsQueryResult = Apollo.QueryResult<
  AnticopyExceptionIdsQuery,
  AnticopyExceptionIdsQueryVariables
>
export const ArtifactAnnotationsDocument = gql`
  query ArtifactAnnotations($artifactSlug: String!) {
    artifactAnnotations(artifactSlug: $artifactSlug) {
      id
      descriptionBody
      createdAt
      video {
        playbackId
        thumbTime
      }
      reactionsGroupedByName {
        name
        count
        currentUserHasReacted
      }
    }
  }
`

/**
 * __useArtifactAnnotationsQuery__
 *
 * To run a query within a React component, call `useArtifactAnnotationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactAnnotationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactAnnotationsQuery({
 *   variables: {
 *      artifactSlug: // value for 'artifactSlug'
 *   },
 * });
 */
export function useArtifactAnnotationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactAnnotationsQuery,
    ArtifactAnnotationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactAnnotationsQuery, ArtifactAnnotationsQueryVariables>(
    ArtifactAnnotationsDocument,
    options
  )
}
export function useArtifactAnnotationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactAnnotationsQuery,
    ArtifactAnnotationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactAnnotationsQuery, ArtifactAnnotationsQueryVariables>(
    ArtifactAnnotationsDocument,
    options
  )
}
export type ArtifactAnnotationsQueryHookResult = ReturnType<
  typeof useArtifactAnnotationsQuery
>
export type ArtifactAnnotationsLazyQueryHookResult = ReturnType<
  typeof useArtifactAnnotationsLazyQuery
>
export type ArtifactAnnotationsQueryResult = Apollo.QueryResult<
  ArtifactAnnotationsQuery,
  ArtifactAnnotationsQueryVariables
>
export const ArtifactAnnotationsCountDocument = gql`
  query ArtifactAnnotationsCount($artifactSlug: String!) {
    artifactAnnotationsCount(artifactSlug: $artifactSlug)
  }
`

/**
 * __useArtifactAnnotationsCountQuery__
 *
 * To run a query within a React component, call `useArtifactAnnotationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactAnnotationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactAnnotationsCountQuery({
 *   variables: {
 *      artifactSlug: // value for 'artifactSlug'
 *   },
 * });
 */
export function useArtifactAnnotationsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactAnnotationsCountQuery,
    ArtifactAnnotationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactAnnotationsCountQuery,
    ArtifactAnnotationsCountQueryVariables
  >(ArtifactAnnotationsCountDocument, options)
}
export function useArtifactAnnotationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactAnnotationsCountQuery,
    ArtifactAnnotationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactAnnotationsCountQuery,
    ArtifactAnnotationsCountQueryVariables
  >(ArtifactAnnotationsCountDocument, options)
}
export type ArtifactAnnotationsCountQueryHookResult = ReturnType<
  typeof useArtifactAnnotationsCountQuery
>
export type ArtifactAnnotationsCountLazyQueryHookResult = ReturnType<
  typeof useArtifactAnnotationsCountLazyQuery
>
export type ArtifactAnnotationsCountQueryResult = Apollo.QueryResult<
  ArtifactAnnotationsCountQuery,
  ArtifactAnnotationsCountQueryVariables
>
export const ArtifactCommentsDocument = gql`
  query ArtifactComments($artifactId: String!) {
    artifactComments(artifactId: $artifactId) {
      id
      message
      createdAt
      reactionsGroupedByName {
        name
        count
        currentUserHasReacted
      }
      user {
        id
        fullName
        avatarUrl
        role
        companyName
      }
    }
  }
`

/**
 * __useArtifactCommentsQuery__
 *
 * To run a query within a React component, call `useArtifactCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCommentsQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactCommentsQuery,
    ArtifactCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactCommentsQuery, ArtifactCommentsQueryVariables>(
    ArtifactCommentsDocument,
    options
  )
}
export function useArtifactCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactCommentsQuery,
    ArtifactCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactCommentsQuery, ArtifactCommentsQueryVariables>(
    ArtifactCommentsDocument,
    options
  )
}
export type ArtifactCommentsQueryHookResult = ReturnType<typeof useArtifactCommentsQuery>
export type ArtifactCommentsLazyQueryHookResult = ReturnType<
  typeof useArtifactCommentsLazyQuery
>
export type ArtifactCommentsQueryResult = Apollo.QueryResult<
  ArtifactCommentsQuery,
  ArtifactCommentsQueryVariables
>
export const ArtifactCommentsCountDocument = gql`
  query ArtifactCommentsCount($artifactId: String!) {
    artifactCommentsCount(artifactId: $artifactId)
  }
`

/**
 * __useArtifactCommentsCountQuery__
 *
 * To run a query within a React component, call `useArtifactCommentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCommentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCommentsCountQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactCommentsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactCommentsCountQuery,
    ArtifactCommentsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactCommentsCountQuery, ArtifactCommentsCountQueryVariables>(
    ArtifactCommentsCountDocument,
    options
  )
}
export function useArtifactCommentsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactCommentsCountQuery,
    ArtifactCommentsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactCommentsCountQuery,
    ArtifactCommentsCountQueryVariables
  >(ArtifactCommentsCountDocument, options)
}
export type ArtifactCommentsCountQueryHookResult = ReturnType<
  typeof useArtifactCommentsCountQuery
>
export type ArtifactCommentsCountLazyQueryHookResult = ReturnType<
  typeof useArtifactCommentsCountLazyQuery
>
export type ArtifactCommentsCountQueryResult = Apollo.QueryResult<
  ArtifactCommentsCountQuery,
  ArtifactCommentsCountQueryVariables
>
export const ArtifactOnboardingRecommendationsDocument = gql`
  query ArtifactOnboardingRecommendations($limit: Int, $lastId: String, $sortBy: String) {
    artifactOnboardingRecommendations(limit: $limit, lastId: $lastId, sortBy: $sortBy)
  }
`

/**
 * __useArtifactOnboardingRecommendationsQuery__
 *
 * To run a query within a React component, call `useArtifactOnboardingRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactOnboardingRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactOnboardingRecommendationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      lastId: // value for 'lastId'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useArtifactOnboardingRecommendationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArtifactOnboardingRecommendationsQuery,
    ArtifactOnboardingRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactOnboardingRecommendationsQuery,
    ArtifactOnboardingRecommendationsQueryVariables
  >(ArtifactOnboardingRecommendationsDocument, options)
}
export function useArtifactOnboardingRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactOnboardingRecommendationsQuery,
    ArtifactOnboardingRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactOnboardingRecommendationsQuery,
    ArtifactOnboardingRecommendationsQueryVariables
  >(ArtifactOnboardingRecommendationsDocument, options)
}
export type ArtifactOnboardingRecommendationsQueryHookResult = ReturnType<
  typeof useArtifactOnboardingRecommendationsQuery
>
export type ArtifactOnboardingRecommendationsLazyQueryHookResult = ReturnType<
  typeof useArtifactOnboardingRecommendationsLazyQuery
>
export type ArtifactOnboardingRecommendationsQueryResult = Apollo.QueryResult<
  ArtifactOnboardingRecommendationsQuery,
  ArtifactOnboardingRecommendationsQueryVariables
>
export const ArtifactPageDocument = gql`
  query ArtifactPage($slug: String!) {
    artifact(slug: $slug)
    artifactContributors(artifactSlug: $slug) {
      avatarUrl
      companyName
      contributionVerb
      id
      name
      position
      primaryEmail
      slug
      contributionVerb
      status
      relevantWorkEntry
      allEntries
      isCollectiveMember
      user {
        id
        kind
        fullName
        avatarUrl
        companyName
        role
      }
    }
  }
`

/**
 * __useArtifactPageQuery__
 *
 * To run a query within a React component, call `useArtifactPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArtifactPageQuery(
  baseOptions: Apollo.QueryHookOptions<ArtifactPageQuery, ArtifactPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactPageQuery, ArtifactPageQueryVariables>(
    ArtifactPageDocument,
    options
  )
}
export function useArtifactPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArtifactPageQuery, ArtifactPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactPageQuery, ArtifactPageQueryVariables>(
    ArtifactPageDocument,
    options
  )
}
export type ArtifactPageQueryHookResult = ReturnType<typeof useArtifactPageQuery>
export type ArtifactPageLazyQueryHookResult = ReturnType<typeof useArtifactPageLazyQuery>
export type ArtifactPageQueryResult = Apollo.QueryResult<
  ArtifactPageQuery,
  ArtifactPageQueryVariables
>
export const ArtifactSubtopicDocument = gql`
  query ArtifactSubtopic($slug: String!) {
    subtopic(slug: $slug) {
      id
      title
      titlePlural
      slug
      isDeprecated
      redirectTo
      lastUpdate
      termDefinition
      topic {
        title
        slug
        isPublic
        isDeprecated
        redirectTo
      }
      relatedBlogPosts {
        id
        title
        slug
        summaryText
        summaryImage {
          ...ImageFragment
        }
        authors {
          id
          name
        }
      }
      relatedGuides {
        id
        title
        slug
        overview
        heroImage {
          ...ImageFragment
        }
        authors {
          id
          name
        }
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useArtifactSubtopicQuery__
 *
 * To run a query within a React component, call `useArtifactSubtopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactSubtopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactSubtopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArtifactSubtopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactSubtopicQuery,
    ArtifactSubtopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactSubtopicQuery, ArtifactSubtopicQueryVariables>(
    ArtifactSubtopicDocument,
    options
  )
}
export function useArtifactSubtopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactSubtopicQuery,
    ArtifactSubtopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactSubtopicQuery, ArtifactSubtopicQueryVariables>(
    ArtifactSubtopicDocument,
    options
  )
}
export type ArtifactSubtopicQueryHookResult = ReturnType<typeof useArtifactSubtopicQuery>
export type ArtifactSubtopicLazyQueryHookResult = ReturnType<
  typeof useArtifactSubtopicLazyQuery
>
export type ArtifactSubtopicQueryResult = Apollo.QueryResult<
  ArtifactSubtopicQuery,
  ArtifactSubtopicQueryVariables
>
export const ArtifactTopicDocument = gql`
  query ArtifactTopic($slug: String!) {
    artifactTopic(slug: $slug) {
      id
      title
      slug
      isDeprecated
      redirectTo
      subtopicsToDisplay
      totalArtifactCount
      lastUpdate
    }
  }
`

/**
 * __useArtifactTopicQuery__
 *
 * To run a query within a React component, call `useArtifactTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactTopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArtifactTopicQuery(
  baseOptions: Apollo.QueryHookOptions<ArtifactTopicQuery, ArtifactTopicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactTopicQuery, ArtifactTopicQueryVariables>(
    ArtifactTopicDocument,
    options
  )
}
export function useArtifactTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactTopicQuery,
    ArtifactTopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactTopicQuery, ArtifactTopicQueryVariables>(
    ArtifactTopicDocument,
    options
  )
}
export type ArtifactTopicQueryHookResult = ReturnType<typeof useArtifactTopicQuery>
export type ArtifactTopicLazyQueryHookResult = ReturnType<
  typeof useArtifactTopicLazyQuery
>
export type ArtifactTopicQueryResult = Apollo.QueryResult<
  ArtifactTopicQuery,
  ArtifactTopicQueryVariables
>
export const ArtifactsForSubtopicDocument = gql`
  query ArtifactsForSubtopic($slug: String!, $max: Int) {
    artifactsForSubtopic(slug: $slug, max: $max) {
      artifacts {
        id
        title
        slug
        description
        summary
        thumbnailUrl
        companyLogoUrl
        companyName
        authors {
          id
          name
          slug
        }
        workEntry {
          position
          generalRole
          specificCompany
          company {
            name
            logo {
              ...ImageFragment
            }
          }
        }
      }
      totalCount
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useArtifactsForSubtopicQuery__
 *
 * To run a query within a React component, call `useArtifactsForSubtopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactsForSubtopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactsForSubtopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      max: // value for 'max'
 *   },
 * });
 */
export function useArtifactsForSubtopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactsForSubtopicQuery,
    ArtifactsForSubtopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactsForSubtopicQuery, ArtifactsForSubtopicQueryVariables>(
    ArtifactsForSubtopicDocument,
    options
  )
}
export function useArtifactsForSubtopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactsForSubtopicQuery,
    ArtifactsForSubtopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactsForSubtopicQuery,
    ArtifactsForSubtopicQueryVariables
  >(ArtifactsForSubtopicDocument, options)
}
export type ArtifactsForSubtopicQueryHookResult = ReturnType<
  typeof useArtifactsForSubtopicQuery
>
export type ArtifactsForSubtopicLazyQueryHookResult = ReturnType<
  typeof useArtifactsForSubtopicLazyQuery
>
export type ArtifactsForSubtopicQueryResult = Apollo.QueryResult<
  ArtifactsForSubtopicQuery,
  ArtifactsForSubtopicQueryVariables
>
export const TopicsDocument = gql`
  query topics($slug: String) {
    topics(slug: $slug) {
      id
      title
      slug
      icon {
        ...ImageFragment
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options)
}
export function useTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options)
}
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>
export const ArtifactSubtopicsDocument = gql`
  query ArtifactSubtopics($subtopicSlug: String, $topicSlug: String) {
    subtopics(subtopicSlug: $subtopicSlug, topicSlug: $topicSlug) {
      id
      title
      slug
      titlePlural
      topic {
        title
        slug
      }
    }
  }
`

/**
 * __useArtifactSubtopicsQuery__
 *
 * To run a query within a React component, call `useArtifactSubtopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactSubtopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactSubtopicsQuery({
 *   variables: {
 *      subtopicSlug: // value for 'subtopicSlug'
 *      topicSlug: // value for 'topicSlug'
 *   },
 * });
 */
export function useArtifactSubtopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArtifactSubtopicsQuery,
    ArtifactSubtopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactSubtopicsQuery, ArtifactSubtopicsQueryVariables>(
    ArtifactSubtopicsDocument,
    options
  )
}
export function useArtifactSubtopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactSubtopicsQuery,
    ArtifactSubtopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactSubtopicsQuery, ArtifactSubtopicsQueryVariables>(
    ArtifactSubtopicsDocument,
    options
  )
}
export type ArtifactSubtopicsQueryHookResult = ReturnType<
  typeof useArtifactSubtopicsQuery
>
export type ArtifactSubtopicsLazyQueryHookResult = ReturnType<
  typeof useArtifactSubtopicsLazyQuery
>
export type ArtifactSubtopicsQueryResult = Apollo.QueryResult<
  ArtifactSubtopicsQuery,
  ArtifactSubtopicsQueryVariables
>
export const ArtifactSubtopicsWithRelatedBlogsDocument = gql`
  query ArtifactSubtopicsWithRelatedBlogs($subtopicSlug: String, $topicSlug: String) {
    subtopics(subtopicSlug: $subtopicSlug, topicSlug: $topicSlug) {
      id
      title
      slug
      titlePlural
      topic {
        title
        slug
      }
      relatedBlogPosts {
        id
        title
        slug
      }
    }
  }
`

/**
 * __useArtifactSubtopicsWithRelatedBlogsQuery__
 *
 * To run a query within a React component, call `useArtifactSubtopicsWithRelatedBlogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactSubtopicsWithRelatedBlogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactSubtopicsWithRelatedBlogsQuery({
 *   variables: {
 *      subtopicSlug: // value for 'subtopicSlug'
 *      topicSlug: // value for 'topicSlug'
 *   },
 * });
 */
export function useArtifactSubtopicsWithRelatedBlogsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArtifactSubtopicsWithRelatedBlogsQuery,
    ArtifactSubtopicsWithRelatedBlogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactSubtopicsWithRelatedBlogsQuery,
    ArtifactSubtopicsWithRelatedBlogsQueryVariables
  >(ArtifactSubtopicsWithRelatedBlogsDocument, options)
}
export function useArtifactSubtopicsWithRelatedBlogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactSubtopicsWithRelatedBlogsQuery,
    ArtifactSubtopicsWithRelatedBlogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactSubtopicsWithRelatedBlogsQuery,
    ArtifactSubtopicsWithRelatedBlogsQueryVariables
  >(ArtifactSubtopicsWithRelatedBlogsDocument, options)
}
export type ArtifactSubtopicsWithRelatedBlogsQueryHookResult = ReturnType<
  typeof useArtifactSubtopicsWithRelatedBlogsQuery
>
export type ArtifactSubtopicsWithRelatedBlogsLazyQueryHookResult = ReturnType<
  typeof useArtifactSubtopicsWithRelatedBlogsLazyQuery
>
export type ArtifactSubtopicsWithRelatedBlogsQueryResult = Apollo.QueryResult<
  ArtifactSubtopicsWithRelatedBlogsQuery,
  ArtifactSubtopicsWithRelatedBlogsQueryVariables
>
export const ArtifactUserDataDocument = gql`
  query ArtifactUserData {
    artifactUserData {
      savedArtifactsForUser
      viewedArtifactsForUser
      savedCountPerArtifact {
        artifactId
        count
      }
    }
  }
`

/**
 * __useArtifactUserDataQuery__
 *
 * To run a query within a React component, call `useArtifactUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useArtifactUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArtifactUserDataQuery,
    ArtifactUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactUserDataQuery, ArtifactUserDataQueryVariables>(
    ArtifactUserDataDocument,
    options
  )
}
export function useArtifactUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactUserDataQuery,
    ArtifactUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactUserDataQuery, ArtifactUserDataQueryVariables>(
    ArtifactUserDataDocument,
    options
  )
}
export type ArtifactUserDataQueryHookResult = ReturnType<typeof useArtifactUserDataQuery>
export type ArtifactUserDataLazyQueryHookResult = ReturnType<
  typeof useArtifactUserDataLazyQuery
>
export type ArtifactUserDataQueryResult = Apollo.QueryResult<
  ArtifactUserDataQuery,
  ArtifactUserDataQueryVariables
>
export const SavedCountPerArtifactDocument = gql`
  query SavedCountPerArtifact {
    savedCountPerArtifact {
      artifactId
      count
    }
  }
`

/**
 * __useSavedCountPerArtifactQuery__
 *
 * To run a query within a React component, call `useSavedCountPerArtifactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedCountPerArtifactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedCountPerArtifactQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedCountPerArtifactQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SavedCountPerArtifactQuery,
    SavedCountPerArtifactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SavedCountPerArtifactQuery, SavedCountPerArtifactQueryVariables>(
    SavedCountPerArtifactDocument,
    options
  )
}
export function useSavedCountPerArtifactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavedCountPerArtifactQuery,
    SavedCountPerArtifactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SavedCountPerArtifactQuery,
    SavedCountPerArtifactQueryVariables
  >(SavedCountPerArtifactDocument, options)
}
export type SavedCountPerArtifactQueryHookResult = ReturnType<
  typeof useSavedCountPerArtifactQuery
>
export type SavedCountPerArtifactLazyQueryHookResult = ReturnType<
  typeof useSavedCountPerArtifactLazyQuery
>
export type SavedCountPerArtifactQueryResult = Apollo.QueryResult<
  SavedCountPerArtifactQuery,
  SavedCountPerArtifactQueryVariables
>
export const ArtifactsIndexPageViewedArtifactsForUserDocument = gql`
  query ArtifactsIndexPageViewedArtifactsForUser($userId: ID!) {
    viewedArtifactsForUser(userId: $userId) {
      artifactId
      updatedAt
    }
  }
`

/**
 * __useArtifactsIndexPageViewedArtifactsForUserQuery__
 *
 * To run a query within a React component, call `useArtifactsIndexPageViewedArtifactsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactsIndexPageViewedArtifactsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactsIndexPageViewedArtifactsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useArtifactsIndexPageViewedArtifactsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactsIndexPageViewedArtifactsForUserQuery,
    ArtifactsIndexPageViewedArtifactsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactsIndexPageViewedArtifactsForUserQuery,
    ArtifactsIndexPageViewedArtifactsForUserQueryVariables
  >(ArtifactsIndexPageViewedArtifactsForUserDocument, options)
}
export function useArtifactsIndexPageViewedArtifactsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactsIndexPageViewedArtifactsForUserQuery,
    ArtifactsIndexPageViewedArtifactsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactsIndexPageViewedArtifactsForUserQuery,
    ArtifactsIndexPageViewedArtifactsForUserQueryVariables
  >(ArtifactsIndexPageViewedArtifactsForUserDocument, options)
}
export type ArtifactsIndexPageViewedArtifactsForUserQueryHookResult = ReturnType<
  typeof useArtifactsIndexPageViewedArtifactsForUserQuery
>
export type ArtifactsIndexPageViewedArtifactsForUserLazyQueryHookResult = ReturnType<
  typeof useArtifactsIndexPageViewedArtifactsForUserLazyQuery
>
export type ArtifactsIndexPageViewedArtifactsForUserQueryResult = Apollo.QueryResult<
  ArtifactsIndexPageViewedArtifactsForUserQuery,
  ArtifactsIndexPageViewedArtifactsForUserQueryVariables
>
export const AllTopicsAndFunctionsDocument = gql`
  query AllTopicsAndFunctions {
    allTopicsAndFunctions {
      id
      title
      slug
      type
    }
  }
`

/**
 * __useAllTopicsAndFunctionsQuery__
 *
 * To run a query within a React component, call `useAllTopicsAndFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTopicsAndFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTopicsAndFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTopicsAndFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllTopicsAndFunctionsQuery,
    AllTopicsAndFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllTopicsAndFunctionsQuery, AllTopicsAndFunctionsQueryVariables>(
    AllTopicsAndFunctionsDocument,
    options
  )
}
export function useAllTopicsAndFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllTopicsAndFunctionsQuery,
    AllTopicsAndFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllTopicsAndFunctionsQuery,
    AllTopicsAndFunctionsQueryVariables
  >(AllTopicsAndFunctionsDocument, options)
}
export type AllTopicsAndFunctionsQueryHookResult = ReturnType<
  typeof useAllTopicsAndFunctionsQuery
>
export type AllTopicsAndFunctionsLazyQueryHookResult = ReturnType<
  typeof useAllTopicsAndFunctionsLazyQuery
>
export type AllTopicsAndFunctionsQueryResult = Apollo.QueryResult<
  AllTopicsAndFunctionsQuery,
  AllTopicsAndFunctionsQueryVariables
>
export const DedupedTopicsAndFunctionsDocument = gql`
  query DedupedTopicsAndFunctions {
    dedupedTopicsAndFunctions {
      id
      title
      slug
      type
    }
  }
`

/**
 * __useDedupedTopicsAndFunctionsQuery__
 *
 * To run a query within a React component, call `useDedupedTopicsAndFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDedupedTopicsAndFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDedupedTopicsAndFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDedupedTopicsAndFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DedupedTopicsAndFunctionsQuery,
    DedupedTopicsAndFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DedupedTopicsAndFunctionsQuery,
    DedupedTopicsAndFunctionsQueryVariables
  >(DedupedTopicsAndFunctionsDocument, options)
}
export function useDedupedTopicsAndFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DedupedTopicsAndFunctionsQuery,
    DedupedTopicsAndFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DedupedTopicsAndFunctionsQuery,
    DedupedTopicsAndFunctionsQueryVariables
  >(DedupedTopicsAndFunctionsDocument, options)
}
export type DedupedTopicsAndFunctionsQueryHookResult = ReturnType<
  typeof useDedupedTopicsAndFunctionsQuery
>
export type DedupedTopicsAndFunctionsLazyQueryHookResult = ReturnType<
  typeof useDedupedTopicsAndFunctionsLazyQuery
>
export type DedupedTopicsAndFunctionsQueryResult = Apollo.QueryResult<
  DedupedTopicsAndFunctionsQuery,
  DedupedTopicsAndFunctionsQueryVariables
>
export const ArtifactsIndexPageForYouRecommendationsDocument = gql`
  query ArtifactsIndexPageForYouRecommendations {
    artifactOnboardingRecommendations
  }
`

/**
 * __useArtifactsIndexPageForYouRecommendationsQuery__
 *
 * To run a query within a React component, call `useArtifactsIndexPageForYouRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactsIndexPageForYouRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactsIndexPageForYouRecommendationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArtifactsIndexPageForYouRecommendationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArtifactsIndexPageForYouRecommendationsQuery,
    ArtifactsIndexPageForYouRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactsIndexPageForYouRecommendationsQuery,
    ArtifactsIndexPageForYouRecommendationsQueryVariables
  >(ArtifactsIndexPageForYouRecommendationsDocument, options)
}
export function useArtifactsIndexPageForYouRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactsIndexPageForYouRecommendationsQuery,
    ArtifactsIndexPageForYouRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactsIndexPageForYouRecommendationsQuery,
    ArtifactsIndexPageForYouRecommendationsQueryVariables
  >(ArtifactsIndexPageForYouRecommendationsDocument, options)
}
export type ArtifactsIndexPageForYouRecommendationsQueryHookResult = ReturnType<
  typeof useArtifactsIndexPageForYouRecommendationsQuery
>
export type ArtifactsIndexPageForYouRecommendationsLazyQueryHookResult = ReturnType<
  typeof useArtifactsIndexPageForYouRecommendationsLazyQuery
>
export type ArtifactsIndexPageForYouRecommendationsQueryResult = Apollo.QueryResult<
  ArtifactsIndexPageForYouRecommendationsQuery,
  ArtifactsIndexPageForYouRecommendationsQueryVariables
>
export const ArtifactsByIdsDocument = gql`
  query ArtifactsByIds($ids: [String!]!) {
    artifactsByIds(ids: $ids) {
      id
      title
      slug
      thumbnailUrl
      authors {
        userId
        position
        company {
          name
        }
        name
        user {
          ...ExpertUserProfileFragment
        }
      }
    }
  }
  ${ExpertUserProfileFragmentFragmentDoc}
`

/**
 * __useArtifactsByIdsQuery__
 *
 * To run a query within a React component, call `useArtifactsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArtifactsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<ArtifactsByIdsQuery, ArtifactsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ArtifactsByIdsQuery, ArtifactsByIdsQueryVariables>(
    ArtifactsByIdsDocument,
    options
  )
}
export function useArtifactsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactsByIdsQuery,
    ArtifactsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ArtifactsByIdsQuery, ArtifactsByIdsQueryVariables>(
    ArtifactsByIdsDocument,
    options
  )
}
export type ArtifactsByIdsQueryHookResult = ReturnType<typeof useArtifactsByIdsQuery>
export type ArtifactsByIdsLazyQueryHookResult = ReturnType<
  typeof useArtifactsByIdsLazyQuery
>
export type ArtifactsByIdsQueryResult = Apollo.QueryResult<
  ArtifactsByIdsQuery,
  ArtifactsByIdsQueryVariables
>
export const FilteredArtifactsDocument = gql`
  query FilteredArtifacts($filters: ArtifactFilters, $lastId: String, $limit: Int) {
    filteredArtifacts(filters: $filters, lastId: $lastId, limit: $limit) {
      artifacts {
        id
        isFree
        title
        slug
        thumbnailUrl
        companyLogoUrl
        viewCount
        commentCount
        authors {
          avatarUrl
          id
          userId
          name
          slug
          position
          companyName
          user {
            id
            ...ExpertUserProfileFragment
          }
        }
      }
      nextArtifactsCount
      prevArtifactsCount
      totalArtifactsCount
    }
  }
  ${ExpertUserProfileFragmentFragmentDoc}
`

/**
 * __useFilteredArtifactsQuery__
 *
 * To run a query within a React component, call `useFilteredArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredArtifactsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      lastId: // value for 'lastId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFilteredArtifactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilteredArtifactsQuery,
    FilteredArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FilteredArtifactsQuery, FilteredArtifactsQueryVariables>(
    FilteredArtifactsDocument,
    options
  )
}
export function useFilteredArtifactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredArtifactsQuery,
    FilteredArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FilteredArtifactsQuery, FilteredArtifactsQueryVariables>(
    FilteredArtifactsDocument,
    options
  )
}
export type FilteredArtifactsQueryHookResult = ReturnType<
  typeof useFilteredArtifactsQuery
>
export type FilteredArtifactsLazyQueryHookResult = ReturnType<
  typeof useFilteredArtifactsLazyQuery
>
export type FilteredArtifactsQueryResult = Apollo.QueryResult<
  FilteredArtifactsQuery,
  FilteredArtifactsQueryVariables
>
export const BannerDataDocument = gql`
  query BannerData {
    upcomingSeason {
      id
      ...UpcomingSeasonEnrollmentFields
    }
    preEnrollmentSeason {
      id
      ...UpcomingSeasonEnrollmentFields
    }
    enrollmentSeason {
      id
      ...UpcomingSeasonEnrollmentFields
    }
    currentUser {
      id
      ...CurrentUserBannerParts
    }
    currentLivePrograms: userCohorts(timePeriod: "current") {
      id
      cohort {
        id
        isOngoing
        postCohort
        coreWeeksHaveEnded
        postCoreWeeksEnrollmentIsOpen
      }
    }
  }
  ${UpcomingSeasonEnrollmentFieldsFragmentDoc}
  ${CurrentUserBannerPartsFragmentDoc}
`

/**
 * __useBannerDataQuery__
 *
 * To run a query within a React component, call `useBannerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBannerDataQuery(
  baseOptions?: Apollo.QueryHookOptions<BannerDataQuery, BannerDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BannerDataQuery, BannerDataQueryVariables>(
    BannerDataDocument,
    options
  )
}
export function useBannerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BannerDataQuery, BannerDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BannerDataQuery, BannerDataQueryVariables>(
    BannerDataDocument,
    options
  )
}
export type BannerDataQueryHookResult = ReturnType<typeof useBannerDataQuery>
export type BannerDataLazyQueryHookResult = ReturnType<typeof useBannerDataLazyQuery>
export type BannerDataQueryResult = Apollo.QueryResult<
  BannerDataQuery,
  BannerDataQueryVariables
>
export const CmsProgramBookmarksFeedDocument = gql`
  query CmsProgramBookmarksFeed($cmsProgramId: ID!, $cmsSectionId: ID) {
    cmsProgram(cmsProgramId: $cmsProgramId) {
      id
      bookmarks(cmsSectionId: $cmsSectionId) {
        id
        ...ProgramBookmarkParts
      }
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`

/**
 * __useCmsProgramBookmarksFeedQuery__
 *
 * To run a query within a React component, call `useCmsProgramBookmarksFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsProgramBookmarksFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsProgramBookmarksFeedQuery({
 *   variables: {
 *      cmsProgramId: // value for 'cmsProgramId'
 *      cmsSectionId: // value for 'cmsSectionId'
 *   },
 * });
 */
export function useCmsProgramBookmarksFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    CmsProgramBookmarksFeedQuery,
    CmsProgramBookmarksFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CmsProgramBookmarksFeedQuery,
    CmsProgramBookmarksFeedQueryVariables
  >(CmsProgramBookmarksFeedDocument, options)
}
export function useCmsProgramBookmarksFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CmsProgramBookmarksFeedQuery,
    CmsProgramBookmarksFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CmsProgramBookmarksFeedQuery,
    CmsProgramBookmarksFeedQueryVariables
  >(CmsProgramBookmarksFeedDocument, options)
}
export type CmsProgramBookmarksFeedQueryHookResult = ReturnType<
  typeof useCmsProgramBookmarksFeedQuery
>
export type CmsProgramBookmarksFeedLazyQueryHookResult = ReturnType<
  typeof useCmsProgramBookmarksFeedLazyQuery
>
export type CmsProgramBookmarksFeedQueryResult = Apollo.QueryResult<
  CmsProgramBookmarksFeedQuery,
  CmsProgramBookmarksFeedQueryVariables
>
export const UserBookmarksDocument = gql`
  query UserBookmarks {
    currentUser {
      id
      hasSeenCollectionsPrompt
      bookmarkFolders {
        ...BookmarkFolderParts
      }
      courseBookmarks {
        ...CourseBookmarkParts
      }
      programBookmarks {
        ...ProgramBookmarkParts
      }
      artifactBookmarks {
        ...ArtifactBookmarkParts
      }
      eventBookmarks {
        ...EventBookmarkParts
      }
      guideBookmarks {
        ...GuideBookmarkParts
      }
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${CourseBookmarkPartsFragmentDoc}
  ${ProgramBookmarkPartsFragmentDoc}
  ${ArtifactBookmarkPartsFragmentDoc}
  ${EventBookmarkPartsFragmentDoc}
  ${GuideBookmarkPartsFragmentDoc}
`

/**
 * __useUserBookmarksQuery__
 *
 * To run a query within a React component, call `useUserBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBookmarksQuery(
  baseOptions?: Apollo.QueryHookOptions<UserBookmarksQuery, UserBookmarksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserBookmarksQuery, UserBookmarksQueryVariables>(
    UserBookmarksDocument,
    options
  )
}
export function useUserBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBookmarksQuery,
    UserBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserBookmarksQuery, UserBookmarksQueryVariables>(
    UserBookmarksDocument,
    options
  )
}
export type UserBookmarksQueryHookResult = ReturnType<typeof useUserBookmarksQuery>
export type UserBookmarksLazyQueryHookResult = ReturnType<
  typeof useUserBookmarksLazyQuery
>
export type UserBookmarksQueryResult = Apollo.QueryResult<
  UserBookmarksQuery,
  UserBookmarksQueryVariables
>
export const CertificatesDocument = gql`
  query certificates {
    currentUser {
      id
      certificates {
        completed
        id
        name
        pdfUrl
      }
    }
  }
`

/**
 * __useCertificatesQuery__
 *
 * To run a query within a React component, call `useCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificatesQuery(
  baseOptions?: Apollo.QueryHookOptions<CertificatesQuery, CertificatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CertificatesQuery, CertificatesQueryVariables>(
    CertificatesDocument,
    options
  )
}
export function useCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CertificatesQuery, CertificatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CertificatesQuery, CertificatesQueryVariables>(
    CertificatesDocument,
    options
  )
}
export type CertificatesQueryHookResult = ReturnType<typeof useCertificatesQuery>
export type CertificatesLazyQueryHookResult = ReturnType<typeof useCertificatesLazyQuery>
export type CertificatesQueryResult = Apollo.QueryResult<
  CertificatesQuery,
  CertificatesQueryVariables
>
export const CmsContentDocument = gql`
  query CmsContent($cmsSectionId: ID!) {
    cmsContent(cmsSectionId: $cmsSectionId) {
      id
      bookmarks {
        id
        ...BookmarkNoteFormParts
      }
      inlinePostAnchors
    }
  }
  ${BookmarkNoteFormPartsFragmentDoc}
`

/**
 * __useCmsContentQuery__
 *
 * To run a query within a React component, call `useCmsContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsContentQuery({
 *   variables: {
 *      cmsSectionId: // value for 'cmsSectionId'
 *   },
 * });
 */
export function useCmsContentQuery(
  baseOptions: Apollo.QueryHookOptions<CmsContentQuery, CmsContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsContentQuery, CmsContentQueryVariables>(
    CmsContentDocument,
    options
  )
}
export function useCmsContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CmsContentQuery, CmsContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsContentQuery, CmsContentQueryVariables>(
    CmsContentDocument,
    options
  )
}
export type CmsContentQueryHookResult = ReturnType<typeof useCmsContentQuery>
export type CmsContentLazyQueryHookResult = ReturnType<typeof useCmsContentLazyQuery>
export type CmsContentQueryResult = Apollo.QueryResult<
  CmsContentQuery,
  CmsContentQueryVariables
>
export const CmsProgramNamesDocument = gql`
  query cmsProgramNames {
    cmsProgramNames
  }
`

/**
 * __useCmsProgramNamesQuery__
 *
 * To run a query within a React component, call `useCmsProgramNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsProgramNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsProgramNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCmsProgramNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CmsProgramNamesQuery,
    CmsProgramNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsProgramNamesQuery, CmsProgramNamesQueryVariables>(
    CmsProgramNamesDocument,
    options
  )
}
export function useCmsProgramNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CmsProgramNamesQuery,
    CmsProgramNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsProgramNamesQuery, CmsProgramNamesQueryVariables>(
    CmsProgramNamesDocument,
    options
  )
}
export type CmsProgramNamesQueryHookResult = ReturnType<typeof useCmsProgramNamesQuery>
export type CmsProgramNamesLazyQueryHookResult = ReturnType<
  typeof useCmsProgramNamesLazyQuery
>
export type CmsProgramNamesQueryResult = Apollo.QueryResult<
  CmsProgramNamesQuery,
  CmsProgramNamesQueryVariables
>
export const CmsSectionDocument = gql`
  query cmsSection($contentType: CmsSectionContentType!, $slug: String!) {
    cmsSection(contentType: $contentType, slug: $slug) {
      id
      bookmarks(type: VideoBookmark) {
        id
      }
      ...CmsSectionParts
    }
  }
  ${CmsSectionPartsFragmentDoc}
`

/**
 * __useCmsSectionQuery__
 *
 * To run a query within a React component, call `useCmsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsSectionQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCmsSectionQuery(
  baseOptions: Apollo.QueryHookOptions<CmsSectionQuery, CmsSectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsSectionQuery, CmsSectionQueryVariables>(
    CmsSectionDocument,
    options
  )
}
export function useCmsSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CmsSectionQuery, CmsSectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsSectionQuery, CmsSectionQueryVariables>(
    CmsSectionDocument,
    options
  )
}
export type CmsSectionQueryHookResult = ReturnType<typeof useCmsSectionQuery>
export type CmsSectionLazyQueryHookResult = ReturnType<typeof useCmsSectionLazyQuery>
export type CmsSectionQueryResult = Apollo.QueryResult<
  CmsSectionQuery,
  CmsSectionQueryVariables
>
export const CmsUnitsDocument = gql`
  query CmsUnits {
    cmsUnits {
      id
      contentType
      name
      ...ContentCardParts
      ...LessonCardContentParts
      children {
        id
        slug
        name
        heroImageUrl
        cmsModule {
          id
          name
        }
      }
    }
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`

/**
 * __useCmsUnitsQuery__
 *
 * To run a query within a React component, call `useCmsUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCmsUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<CmsUnitsQuery, CmsUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsUnitsQuery, CmsUnitsQueryVariables>(CmsUnitsDocument, options)
}
export function useCmsUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CmsUnitsQuery, CmsUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsUnitsQuery, CmsUnitsQueryVariables>(
    CmsUnitsDocument,
    options
  )
}
export type CmsUnitsQueryHookResult = ReturnType<typeof useCmsUnitsQuery>
export type CmsUnitsLazyQueryHookResult = ReturnType<typeof useCmsUnitsLazyQuery>
export type CmsUnitsQueryResult = Apollo.QueryResult<
  CmsUnitsQuery,
  CmsUnitsQueryVariables
>
export const CmsUnitsPageTempDocument = gql`
  query cmsUnitsPageTemp {
    cmsUnits {
      id
      contentType
      name
      ...ContentCardParts
      ...LessonCardContentParts
      children {
        slug
        name
        heroImageUrl
      }
    }
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`

/**
 * __useCmsUnitsPageTempQuery__
 *
 * To run a query within a React component, call `useCmsUnitsPageTempQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsUnitsPageTempQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsUnitsPageTempQuery({
 *   variables: {
 *   },
 * });
 */
export function useCmsUnitsPageTempQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CmsUnitsPageTempQuery,
    CmsUnitsPageTempQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsUnitsPageTempQuery, CmsUnitsPageTempQueryVariables>(
    CmsUnitsPageTempDocument,
    options
  )
}
export function useCmsUnitsPageTempLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CmsUnitsPageTempQuery,
    CmsUnitsPageTempQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsUnitsPageTempQuery, CmsUnitsPageTempQueryVariables>(
    CmsUnitsPageTempDocument,
    options
  )
}
export type CmsUnitsPageTempQueryHookResult = ReturnType<typeof useCmsUnitsPageTempQuery>
export type CmsUnitsPageTempLazyQueryHookResult = ReturnType<
  typeof useCmsUnitsPageTempLazyQuery
>
export type CmsUnitsPageTempQueryResult = Apollo.QueryResult<
  CmsUnitsPageTempQuery,
  CmsUnitsPageTempQueryVariables
>
export const FullWidthProgramCardDocument = gql`
  query fullWidthProgramCard {
    enrollmentSeason {
      activeAt
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      hasStarted
      id
      name
      nextSeasonName
      openForEnrollment
      prettyNameSeasonFirst
      startsAt
      year
      showBlogPostAnnouncementAt
      blogPostAnnouncementUrl
    }
    lastActiveSeason {
      activeAt
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      hasStarted
      id
      name
      nextSeasonName
      openForEnrollment
      prettyNameSeasonFirst
      startsAt
      year
      showBlogPostAnnouncementAt
      blogPostAnnouncementUrl
    }
    currentLivePrograms: userCohorts(timePeriod: "current") {
      ...UserCohortPart
    }
  }
  ${UserCohortPartFragmentDoc}
`

/**
 * __useFullWidthProgramCardQuery__
 *
 * To run a query within a React component, call `useFullWidthProgramCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullWidthProgramCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullWidthProgramCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useFullWidthProgramCardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FullWidthProgramCardQuery,
    FullWidthProgramCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FullWidthProgramCardQuery, FullWidthProgramCardQueryVariables>(
    FullWidthProgramCardDocument,
    options
  )
}
export function useFullWidthProgramCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullWidthProgramCardQuery,
    FullWidthProgramCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FullWidthProgramCardQuery,
    FullWidthProgramCardQueryVariables
  >(FullWidthProgramCardDocument, options)
}
export type FullWidthProgramCardQueryHookResult = ReturnType<
  typeof useFullWidthProgramCardQuery
>
export type FullWidthProgramCardLazyQueryHookResult = ReturnType<
  typeof useFullWidthProgramCardLazyQuery
>
export type FullWidthProgramCardQueryResult = Apollo.QueryResult<
  FullWidthProgramCardQuery,
  FullWidthProgramCardQueryVariables
>
export const UserCoachingSessionsDocument = gql`
  query UserCoachingSessions {
    userCoachingSessions {
      id
      data
      externalId
    }
  }
`

/**
 * __useUserCoachingSessionsQuery__
 *
 * To run a query within a React component, call `useUserCoachingSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoachingSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoachingSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCoachingSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCoachingSessionsQuery,
    UserCoachingSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCoachingSessionsQuery, UserCoachingSessionsQueryVariables>(
    UserCoachingSessionsDocument,
    options
  )
}
export function useUserCoachingSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCoachingSessionsQuery,
    UserCoachingSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserCoachingSessionsQuery,
    UserCoachingSessionsQueryVariables
  >(UserCoachingSessionsDocument, options)
}
export type UserCoachingSessionsQueryHookResult = ReturnType<
  typeof useUserCoachingSessionsQuery
>
export type UserCoachingSessionsLazyQueryHookResult = ReturnType<
  typeof useUserCoachingSessionsLazyQuery
>
export type UserCoachingSessionsQueryResult = Apollo.QueryResult<
  UserCoachingSessionsQuery,
  UserCoachingSessionsQueryVariables
>
export const CohortDocument = gql`
  query Cohort($slug: String!) {
    cohort(slug: $slug) {
      id
      season {
        id
        showCohortViewerAt
      }
    }
  }
`

/**
 * __useCohortQuery__
 *
 * To run a query within a React component, call `useCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortQuery(
  baseOptions: Apollo.QueryHookOptions<CohortQuery, CohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortQuery, CohortQueryVariables>(CohortDocument, options)
}
export function useCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CohortQuery, CohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortQuery, CohortQueryVariables>(CohortDocument, options)
}
export type CohortQueryHookResult = ReturnType<typeof useCohortQuery>
export type CohortLazyQueryHookResult = ReturnType<typeof useCohortLazyQuery>
export type CohortQueryResult = Apollo.QueryResult<CohortQuery, CohortQueryVariables>
export const CohortConversationPostsDocument = gql`
  query cohortConversationPosts(
    $slug: String!
    $topicSlug: String!
    $sortBy: String!
    $limit: Int
    $offset: Int
  ) {
    cohortPosts(
      slug: $slug
      topicSlug: $topicSlug
      sortBy: $sortBy
      limit: $limit
      offset: $offset
    ) {
      topic {
        ...CohortPostTopicParts
      }
      pinnedPosts {
        ...CohortPostParts
      }
      posts {
        ...CohortPostParts
      }
      count
    }
  }
  ${CohortPostTopicPartsFragmentDoc}
  ${CohortPostPartsFragmentDoc}
`

/**
 * __useCohortConversationPostsQuery__
 *
 * To run a query within a React component, call `useCohortConversationPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortConversationPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortConversationPostsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      topicSlug: // value for 'topicSlug'
 *      sortBy: // value for 'sortBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCohortConversationPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortConversationPostsQuery,
    CohortConversationPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortConversationPostsQuery,
    CohortConversationPostsQueryVariables
  >(CohortConversationPostsDocument, options)
}
export function useCohortConversationPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortConversationPostsQuery,
    CohortConversationPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortConversationPostsQuery,
    CohortConversationPostsQueryVariables
  >(CohortConversationPostsDocument, options)
}
export type CohortConversationPostsQueryHookResult = ReturnType<
  typeof useCohortConversationPostsQuery
>
export type CohortConversationPostsLazyQueryHookResult = ReturnType<
  typeof useCohortConversationPostsLazyQuery
>
export type CohortConversationPostsQueryResult = Apollo.QueryResult<
  CohortConversationPostsQuery,
  CohortConversationPostsQueryVariables
>
export const CohortConversationPostDocument = gql`
  query cohortConversationPost($postId: ID!) {
    cohortPost(id: $postId) {
      ...CohortPostParts
    }
  }
  ${CohortPostPartsFragmentDoc}
`

/**
 * __useCohortConversationPostQuery__
 *
 * To run a query within a React component, call `useCohortConversationPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortConversationPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortConversationPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCohortConversationPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortConversationPostQuery,
    CohortConversationPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortConversationPostQuery,
    CohortConversationPostQueryVariables
  >(CohortConversationPostDocument, options)
}
export function useCohortConversationPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortConversationPostQuery,
    CohortConversationPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortConversationPostQuery,
    CohortConversationPostQueryVariables
  >(CohortConversationPostDocument, options)
}
export type CohortConversationPostQueryHookResult = ReturnType<
  typeof useCohortConversationPostQuery
>
export type CohortConversationPostLazyQueryHookResult = ReturnType<
  typeof useCohortConversationPostLazyQuery
>
export type CohortConversationPostQueryResult = Apollo.QueryResult<
  CohortConversationPostQuery,
  CohortConversationPostQueryVariables
>
export const CohortConversationTopicsDocument = gql`
  query cohortConversationTopics($slug: String!) {
    cohort(slug: $slug) {
      id
      group {
        id
        topics {
          id
          title
          slug
          description
          restrictedToStaff
          latestPostCreatedAtForCohort(cohortSlug: $slug)
          userTopicActivity {
            id
            lastViewedAt
          }
        }
      }
    }
  }
`

/**
 * __useCohortConversationTopicsQuery__
 *
 * To run a query within a React component, call `useCohortConversationTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortConversationTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortConversationTopicsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortConversationTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortConversationTopicsQuery,
    CohortConversationTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortConversationTopicsQuery,
    CohortConversationTopicsQueryVariables
  >(CohortConversationTopicsDocument, options)
}
export function useCohortConversationTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortConversationTopicsQuery,
    CohortConversationTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortConversationTopicsQuery,
    CohortConversationTopicsQueryVariables
  >(CohortConversationTopicsDocument, options)
}
export type CohortConversationTopicsQueryHookResult = ReturnType<
  typeof useCohortConversationTopicsQuery
>
export type CohortConversationTopicsLazyQueryHookResult = ReturnType<
  typeof useCohortConversationTopicsLazyQuery
>
export type CohortConversationTopicsQueryResult = Apollo.QueryResult<
  CohortConversationTopicsQuery,
  CohortConversationTopicsQueryVariables
>
export const CohortDashboardDocument = gql`
  query cohortDashboard($slug: String!) {
    currentUser {
      id
      ...CohortDashboardScheduledWeekCurrentUserParts
    }
    cohort(slug: $slug) {
      ...CohortDashboardCohortParts
      gettingStartedModuleForSeason(slug: $slug) {
        id
        ...CohortCmsModuleParts
      }
    }
  }
  ${CohortDashboardScheduledWeekCurrentUserPartsFragmentDoc}
  ${CohortDashboardCohortPartsFragmentDoc}
  ${CohortCmsModulePartsFragmentDoc}
`

/**
 * __useCohortDashboardQuery__
 *
 * To run a query within a React component, call `useCohortDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardQuery,
    CohortDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortDashboardQuery, CohortDashboardQueryVariables>(
    CohortDashboardDocument,
    options
  )
}
export function useCohortDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardQuery,
    CohortDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortDashboardQuery, CohortDashboardQueryVariables>(
    CohortDashboardDocument,
    options
  )
}
export type CohortDashboardQueryHookResult = ReturnType<typeof useCohortDashboardQuery>
export type CohortDashboardLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardLazyQuery
>
export type CohortDashboardQueryResult = Apollo.QueryResult<
  CohortDashboardQuery,
  CohortDashboardQueryVariables
>
export const CohortDashboardHeaderDocument = gql`
  query cohortDashboardHeader($weekId: ID!, $cohortSlug: String!) {
    cohortDashboardScheduledWeek(cohortSlug: $cohortSlug, weekId: $weekId) {
      ...CohortDashboardHeaderScheduledWeekParts
    }
  }
  ${CohortDashboardHeaderScheduledWeekPartsFragmentDoc}
`

/**
 * __useCohortDashboardHeaderQuery__
 *
 * To run a query within a React component, call `useCohortDashboardHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardHeaderQuery({
 *   variables: {
 *      weekId: // value for 'weekId'
 *      cohortSlug: // value for 'cohortSlug'
 *   },
 * });
 */
export function useCohortDashboardHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardHeaderQuery,
    CohortDashboardHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortDashboardHeaderQuery, CohortDashboardHeaderQueryVariables>(
    CohortDashboardHeaderDocument,
    options
  )
}
export function useCohortDashboardHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardHeaderQuery,
    CohortDashboardHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardHeaderQuery,
    CohortDashboardHeaderQueryVariables
  >(CohortDashboardHeaderDocument, options)
}
export type CohortDashboardHeaderQueryHookResult = ReturnType<
  typeof useCohortDashboardHeaderQuery
>
export type CohortDashboardHeaderLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardHeaderLazyQuery
>
export type CohortDashboardHeaderQueryResult = Apollo.QueryResult<
  CohortDashboardHeaderQuery,
  CohortDashboardHeaderQueryVariables
>
export const CohortDashboardHeaderUserDocument = gql`
  query cohortDashboardHeaderUser($seasonId: ID!, $userId: ID!) {
    season(id: $seasonId) {
      id
      startsAt
      endsAt
      prettyNameSeasonFirst
      showCohortViewerAt
      faqs {
        seasonId
        question
        answer
        questionNumber
      }
    }
    user(id: $userId) {
      id
      ...CohortPostUserParts
    }
  }
  ${CohortPostUserPartsFragmentDoc}
`

/**
 * __useCohortDashboardHeaderUserQuery__
 *
 * To run a query within a React component, call `useCohortDashboardHeaderUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardHeaderUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardHeaderUserQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCohortDashboardHeaderUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardHeaderUserQuery,
    CohortDashboardHeaderUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortDashboardHeaderUserQuery,
    CohortDashboardHeaderUserQueryVariables
  >(CohortDashboardHeaderUserDocument, options)
}
export function useCohortDashboardHeaderUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardHeaderUserQuery,
    CohortDashboardHeaderUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardHeaderUserQuery,
    CohortDashboardHeaderUserQueryVariables
  >(CohortDashboardHeaderUserDocument, options)
}
export type CohortDashboardHeaderUserQueryHookResult = ReturnType<
  typeof useCohortDashboardHeaderUserQuery
>
export type CohortDashboardHeaderUserLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardHeaderUserLazyQuery
>
export type CohortDashboardHeaderUserQueryResult = Apollo.QueryResult<
  CohortDashboardHeaderUserQuery,
  CohortDashboardHeaderUserQueryVariables
>
export const CohortAboutProgramQueryDocument = gql`
  query cohortAboutProgramQuery($cmsProgramId: ID!) {
    cmsProgram(cmsProgramId: $cmsProgramId) {
      id
      ...ProgramSelectorProgramParts
    }
  }
  ${ProgramSelectorProgramPartsFragmentDoc}
`

/**
 * __useCohortAboutProgramQueryQuery__
 *
 * To run a query within a React component, call `useCohortAboutProgramQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortAboutProgramQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortAboutProgramQueryQuery({
 *   variables: {
 *      cmsProgramId: // value for 'cmsProgramId'
 *   },
 * });
 */
export function useCohortAboutProgramQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortAboutProgramQueryQuery,
    CohortAboutProgramQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortAboutProgramQueryQuery,
    CohortAboutProgramQueryQueryVariables
  >(CohortAboutProgramQueryDocument, options)
}
export function useCohortAboutProgramQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortAboutProgramQueryQuery,
    CohortAboutProgramQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortAboutProgramQueryQuery,
    CohortAboutProgramQueryQueryVariables
  >(CohortAboutProgramQueryDocument, options)
}
export type CohortAboutProgramQueryQueryHookResult = ReturnType<
  typeof useCohortAboutProgramQueryQuery
>
export type CohortAboutProgramQueryLazyQueryHookResult = ReturnType<
  typeof useCohortAboutProgramQueryLazyQuery
>
export type CohortAboutProgramQueryQueryResult = Apollo.QueryResult<
  CohortAboutProgramQueryQuery,
  CohortAboutProgramQueryQueryVariables
>
export const CohortCollectionsDocument = gql`
  query cohortCollections($slug: String!) {
    cohort(slug: $slug) {
      id
      cohortCollections {
        id
        ...CohortCollectionParts
      }
    }
  }
  ${CohortCollectionPartsFragmentDoc}
`

/**
 * __useCohortCollectionsQuery__
 *
 * To run a query within a React component, call `useCohortCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortCollectionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortCollectionsQuery,
    CohortCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortCollectionsQuery, CohortCollectionsQueryVariables>(
    CohortCollectionsDocument,
    options
  )
}
export function useCohortCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortCollectionsQuery,
    CohortCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortCollectionsQuery, CohortCollectionsQueryVariables>(
    CohortCollectionsDocument,
    options
  )
}
export type CohortCollectionsQueryHookResult = ReturnType<
  typeof useCohortCollectionsQuery
>
export type CohortCollectionsLazyQueryHookResult = ReturnType<
  typeof useCohortCollectionsLazyQuery
>
export type CohortCollectionsQueryResult = Apollo.QueryResult<
  CohortCollectionsQuery,
  CohortCollectionsQueryVariables
>
export const CohortDashboardNotificationsDocument = gql`
  query CohortDashboardNotifications($slug: String!) {
    currentUser {
      id
      cohortAllActivitiesWithMetadata(slug: $slug) {
        unreadCount
        activities {
          actionType
          activityIds
          latestActivityCreatedAt
          anyActivityUnread
          agoTimephrase
          postBodyText
          postId
          postTopicSlug
          postTopicTitle
          reactions
          replies
          unreadActivityIds
          userAvatarUrl
          userFullName
        }
      }
    }
  }
`

/**
 * __useCohortDashboardNotificationsQuery__
 *
 * To run a query within a React component, call `useCohortDashboardNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardNotificationsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortDashboardNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardNotificationsQuery,
    CohortDashboardNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortDashboardNotificationsQuery,
    CohortDashboardNotificationsQueryVariables
  >(CohortDashboardNotificationsDocument, options)
}
export function useCohortDashboardNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardNotificationsQuery,
    CohortDashboardNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardNotificationsQuery,
    CohortDashboardNotificationsQueryVariables
  >(CohortDashboardNotificationsDocument, options)
}
export type CohortDashboardNotificationsQueryHookResult = ReturnType<
  typeof useCohortDashboardNotificationsQuery
>
export type CohortDashboardNotificationsLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardNotificationsLazyQuery
>
export type CohortDashboardNotificationsQueryResult = Apollo.QueryResult<
  CohortDashboardNotificationsQuery,
  CohortDashboardNotificationsQueryVariables
>
export const CohortDashboardNotificationsBadgeDocument = gql`
  query CohortDashboardNotificationsBadge($slug: String!) {
    currentUser {
      id
      unseenCohortNotificationsCount(slug: $slug)
    }
  }
`

/**
 * __useCohortDashboardNotificationsBadgeQuery__
 *
 * To run a query within a React component, call `useCohortDashboardNotificationsBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardNotificationsBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardNotificationsBadgeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortDashboardNotificationsBadgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardNotificationsBadgeQuery,
    CohortDashboardNotificationsBadgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortDashboardNotificationsBadgeQuery,
    CohortDashboardNotificationsBadgeQueryVariables
  >(CohortDashboardNotificationsBadgeDocument, options)
}
export function useCohortDashboardNotificationsBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardNotificationsBadgeQuery,
    CohortDashboardNotificationsBadgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardNotificationsBadgeQuery,
    CohortDashboardNotificationsBadgeQueryVariables
  >(CohortDashboardNotificationsBadgeDocument, options)
}
export type CohortDashboardNotificationsBadgeQueryHookResult = ReturnType<
  typeof useCohortDashboardNotificationsBadgeQuery
>
export type CohortDashboardNotificationsBadgeLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardNotificationsBadgeLazyQuery
>
export type CohortDashboardNotificationsBadgeQueryResult = Apollo.QueryResult<
  CohortDashboardNotificationsBadgeQuery,
  CohortDashboardNotificationsBadgeQueryVariables
>
export const CohortDashboardScheduledWeekDocument = gql`
  query cohortDashboardScheduledWeek(
    $cohortSlug: String!
    $weekId: ID!
    $interventionThreshold: Int
  ) {
    cohortDashboardScheduledWeek(cohortSlug: $cohortSlug, weekId: $weekId) {
      ...CohortDashboardScheduledWeekParts
    }
    scheduledWeekProgressPercentHasReachedThresholdForIntervention(
      scheduledWeekId: $weekId
      threshold: $interventionThreshold
    )
  }
  ${CohortDashboardScheduledWeekPartsFragmentDoc}
`

/**
 * __useCohortDashboardScheduledWeekQuery__
 *
 * To run a query within a React component, call `useCohortDashboardScheduledWeekQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardScheduledWeekQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardScheduledWeekQuery({
 *   variables: {
 *      cohortSlug: // value for 'cohortSlug'
 *      weekId: // value for 'weekId'
 *      interventionThreshold: // value for 'interventionThreshold'
 *   },
 * });
 */
export function useCohortDashboardScheduledWeekQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardScheduledWeekQuery,
    CohortDashboardScheduledWeekQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortDashboardScheduledWeekQuery,
    CohortDashboardScheduledWeekQueryVariables
  >(CohortDashboardScheduledWeekDocument, options)
}
export function useCohortDashboardScheduledWeekLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardScheduledWeekQuery,
    CohortDashboardScheduledWeekQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardScheduledWeekQuery,
    CohortDashboardScheduledWeekQueryVariables
  >(CohortDashboardScheduledWeekDocument, options)
}
export type CohortDashboardScheduledWeekQueryHookResult = ReturnType<
  typeof useCohortDashboardScheduledWeekQuery
>
export type CohortDashboardScheduledWeekLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardScheduledWeekLazyQuery
>
export type CohortDashboardScheduledWeekQueryResult = Apollo.QueryResult<
  CohortDashboardScheduledWeekQuery,
  CohortDashboardScheduledWeekQueryVariables
>
export const CohortDashboardScheduledWeekChecklistDocument = gql`
  query cohortDashboardScheduledWeekChecklist(
    $slug: String!
    $topicSlug: String!
    $scheduledWeekId: ID!
  ) {
    bulkRsvpdAtForCohort(slug: $slug)
    currentUserCohortPostsByTopicCount(slug: $slug, topicSlug: $topicSlug)
    userCohort(slug: $slug) {
      id
      ...CohortDashboardScheduledWeekUserCohortParts
      postedInConversationsTopicForScheduledWeek(scheduledWeekId: $scheduledWeekId)
    }
  }
  ${CohortDashboardScheduledWeekUserCohortPartsFragmentDoc}
`

/**
 * __useCohortDashboardScheduledWeekChecklistQuery__
 *
 * To run a query within a React component, call `useCohortDashboardScheduledWeekChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortDashboardScheduledWeekChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortDashboardScheduledWeekChecklistQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      topicSlug: // value for 'topicSlug'
 *      scheduledWeekId: // value for 'scheduledWeekId'
 *   },
 * });
 */
export function useCohortDashboardScheduledWeekChecklistQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortDashboardScheduledWeekChecklistQuery,
    CohortDashboardScheduledWeekChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortDashboardScheduledWeekChecklistQuery,
    CohortDashboardScheduledWeekChecklistQueryVariables
  >(CohortDashboardScheduledWeekChecklistDocument, options)
}
export function useCohortDashboardScheduledWeekChecklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortDashboardScheduledWeekChecklistQuery,
    CohortDashboardScheduledWeekChecklistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortDashboardScheduledWeekChecklistQuery,
    CohortDashboardScheduledWeekChecklistQueryVariables
  >(CohortDashboardScheduledWeekChecklistDocument, options)
}
export type CohortDashboardScheduledWeekChecklistQueryHookResult = ReturnType<
  typeof useCohortDashboardScheduledWeekChecklistQuery
>
export type CohortDashboardScheduledWeekChecklistLazyQueryHookResult = ReturnType<
  typeof useCohortDashboardScheduledWeekChecklistLazyQuery
>
export type CohortDashboardScheduledWeekChecklistQueryResult = Apollo.QueryResult<
  CohortDashboardScheduledWeekChecklistQuery,
  CohortDashboardScheduledWeekChecklistQueryVariables
>
export const CohortPassesListDocument = gql`
  query CohortPassesList($userId: ID!) {
    user(id: $userId) {
      id
      subscriptions {
        active {
          id
          cohortCredits {
            unexpired {
              ...UnexpiredCohortCredit
            }
            expired {
              ...UnexpiredCohortCredit
            }
          }
        }
      }
    }
  }
  ${UnexpiredCohortCreditFragmentDoc}
`

/**
 * __useCohortPassesListQuery__
 *
 * To run a query within a React component, call `useCohortPassesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortPassesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortPassesListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCohortPassesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortPassesListQuery,
    CohortPassesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortPassesListQuery, CohortPassesListQueryVariables>(
    CohortPassesListDocument,
    options
  )
}
export function useCohortPassesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortPassesListQuery,
    CohortPassesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortPassesListQuery, CohortPassesListQueryVariables>(
    CohortPassesListDocument,
    options
  )
}
export type CohortPassesListQueryHookResult = ReturnType<typeof useCohortPassesListQuery>
export type CohortPassesListLazyQueryHookResult = ReturnType<
  typeof useCohortPassesListLazyQuery
>
export type CohortPassesListQueryResult = Apollo.QueryResult<
  CohortPassesListQuery,
  CohortPassesListQueryVariables
>
export const CohortTeamWeekProgressDocument = gql`
  query cohortTeamWeekProgress($cohortTeamId: ID!, $cohortSlug: String!, $weekId: ID!) {
    cohortTeam(id: $cohortTeamId) {
      id
      members {
        ...CohortTeamWeekProgressUserParts
      }
    }
    cohortDashboardScheduledWeek(cohortSlug: $cohortSlug, weekId: $weekId) {
      ...CohortTeamWeekProgressScheduledWeekParts
    }
  }
  ${CohortTeamWeekProgressUserPartsFragmentDoc}
  ${CohortTeamWeekProgressScheduledWeekPartsFragmentDoc}
`

/**
 * __useCohortTeamWeekProgressQuery__
 *
 * To run a query within a React component, call `useCohortTeamWeekProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortTeamWeekProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortTeamWeekProgressQuery({
 *   variables: {
 *      cohortTeamId: // value for 'cohortTeamId'
 *      cohortSlug: // value for 'cohortSlug'
 *      weekId: // value for 'weekId'
 *   },
 * });
 */
export function useCohortTeamWeekProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortTeamWeekProgressQuery,
    CohortTeamWeekProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CohortTeamWeekProgressQuery,
    CohortTeamWeekProgressQueryVariables
  >(CohortTeamWeekProgressDocument, options)
}
export function useCohortTeamWeekProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortTeamWeekProgressQuery,
    CohortTeamWeekProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortTeamWeekProgressQuery,
    CohortTeamWeekProgressQueryVariables
  >(CohortTeamWeekProgressDocument, options)
}
export type CohortTeamWeekProgressQueryHookResult = ReturnType<
  typeof useCohortTeamWeekProgressQuery
>
export type CohortTeamWeekProgressLazyQueryHookResult = ReturnType<
  typeof useCohortTeamWeekProgressLazyQuery
>
export type CohortTeamWeekProgressQueryResult = Apollo.QueryResult<
  CohortTeamWeekProgressQuery,
  CohortTeamWeekProgressQueryVariables
>
export const CohortTeamActivitiesDocument = gql`
  query cohortTeamActivities($cohortTeamId: ID!) {
    cohortTeam(id: $cohortTeamId) {
      id
      activities {
        ...CohortTeamActivitiesListItemParts
      }
    }
  }
  ${CohortTeamActivitiesListItemPartsFragmentDoc}
`

/**
 * __useCohortTeamActivitiesQuery__
 *
 * To run a query within a React component, call `useCohortTeamActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortTeamActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortTeamActivitiesQuery({
 *   variables: {
 *      cohortTeamId: // value for 'cohortTeamId'
 *   },
 * });
 */
export function useCohortTeamActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortTeamActivitiesQuery,
    CohortTeamActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortTeamActivitiesQuery, CohortTeamActivitiesQueryVariables>(
    CohortTeamActivitiesDocument,
    options
  )
}
export function useCohortTeamActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortTeamActivitiesQuery,
    CohortTeamActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortTeamActivitiesQuery,
    CohortTeamActivitiesQueryVariables
  >(CohortTeamActivitiesDocument, options)
}
export type CohortTeamActivitiesQueryHookResult = ReturnType<
  typeof useCohortTeamActivitiesQuery
>
export type CohortTeamActivitiesLazyQueryHookResult = ReturnType<
  typeof useCohortTeamActivitiesLazyQuery
>
export type CohortTeamActivitiesQueryResult = Apollo.QueryResult<
  CohortTeamActivitiesQuery,
  CohortTeamActivitiesQueryVariables
>
export const CohortUserDocument = gql`
  query cohortUser($userId: ID!) {
    user(id: $userId) {
      id
      profile {
        ...CohortUserProfileParts
      }
      monthJoined
      yearJoined
      linkedin
      twitter
      slackUserId
    }
  }
  ${CohortUserProfilePartsFragmentDoc}
`

/**
 * __useCohortUserQuery__
 *
 * To run a query within a React component, call `useCohortUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCohortUserQuery(
  baseOptions: Apollo.QueryHookOptions<CohortUserQuery, CohortUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortUserQuery, CohortUserQueryVariables>(
    CohortUserDocument,
    options
  )
}
export function useCohortUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CohortUserQuery, CohortUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortUserQuery, CohortUserQueryVariables>(
    CohortUserDocument,
    options
  )
}
export type CohortUserQueryHookResult = ReturnType<typeof useCohortUserQuery>
export type CohortUserLazyQueryHookResult = ReturnType<typeof useCohortUserLazyQuery>
export type CohortUserQueryResult = Apollo.QueryResult<
  CohortUserQuery,
  CohortUserQueryVariables
>
export const CohortViewerDocument = gql`
  query CohortViewer($slug: String!) {
    userCohort(slug: $slug) {
      id
      ...CohortViewerParts
    }
  }
  ${CohortViewerPartsFragmentDoc}
`

/**
 * __useCohortViewerQuery__
 *
 * To run a query within a React component, call `useCohortViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortViewerQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortViewerQuery(
  baseOptions: Apollo.QueryHookOptions<CohortViewerQuery, CohortViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortViewerQuery, CohortViewerQueryVariables>(
    CohortViewerDocument,
    options
  )
}
export function useCohortViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CohortViewerQuery, CohortViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortViewerQuery, CohortViewerQueryVariables>(
    CohortViewerDocument,
    options
  )
}
export type CohortViewerQueryHookResult = ReturnType<typeof useCohortViewerQuery>
export type CohortViewerLazyQueryHookResult = ReturnType<typeof useCohortViewerLazyQuery>
export type CohortViewerQueryResult = Apollo.QueryResult<
  CohortViewerQuery,
  CohortViewerQueryVariables
>
export const ContentFeedbackDocument = gql`
  mutation ContentFeedback($input: FeedbacksInput!) {
    feedbacks(input: $input) {
      feedbackId
    }
  }
`
export type ContentFeedbackMutationFn = Apollo.MutationFunction<
  ContentFeedbackMutation,
  ContentFeedbackMutationVariables
>

/**
 * __useContentFeedbackMutation__
 *
 * To run a mutation, you first call `useContentFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentFeedbackMutation, { data, loading, error }] = useContentFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContentFeedbackMutation,
    ContentFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ContentFeedbackMutation, ContentFeedbackMutationVariables>(
    ContentFeedbackDocument,
    options
  )
}
export type ContentFeedbackMutationHookResult = ReturnType<
  typeof useContentFeedbackMutation
>
export type ContentFeedbackMutationResult = Apollo.MutationResult<ContentFeedbackMutation>
export type ContentFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ContentFeedbackMutation,
  ContentFeedbackMutationVariables
>
export const CohortViewerNewDocument = gql`
  query CohortViewerNew($slug: String!) {
    cohort(slug: $slug) {
      id
      ...CohortViewerNewCohortParts
    }
    userCohort(slug: $slug) {
      id
      user {
        id
        shouldShowNpsSurvey
      }
    }
    cohortTeams(slug: $slug) {
      ...CohortViewerNewCohortTeamParts
    }
  }
  ${CohortViewerNewCohortPartsFragmentDoc}
  ${CohortViewerNewCohortTeamPartsFragmentDoc}
`

/**
 * __useCohortViewerNewQuery__
 *
 * To run a query within a React component, call `useCohortViewerNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortViewerNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortViewerNewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortViewerNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortViewerNewQuery,
    CohortViewerNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortViewerNewQuery, CohortViewerNewQueryVariables>(
    CohortViewerNewDocument,
    options
  )
}
export function useCohortViewerNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortViewerNewQuery,
    CohortViewerNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortViewerNewQuery, CohortViewerNewQueryVariables>(
    CohortViewerNewDocument,
    options
  )
}
export type CohortViewerNewQueryHookResult = ReturnType<typeof useCohortViewerNewQuery>
export type CohortViewerNewLazyQueryHookResult = ReturnType<
  typeof useCohortViewerNewLazyQuery
>
export type CohortViewerNewQueryResult = Apollo.QueryResult<
  CohortViewerNewQuery,
  CohortViewerNewQueryVariables
>
export const CohortViewerAccessDocument = gql`
  query CohortViewerAccess($slug: String!) {
    cohort(slug: $slug) {
      id
      season {
        id
        showCohortViewerAt
      }
    }
  }
`

/**
 * __useCohortViewerAccessQuery__
 *
 * To run a query within a React component, call `useCohortViewerAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortViewerAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortViewerAccessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCohortViewerAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortViewerAccessQuery,
    CohortViewerAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortViewerAccessQuery, CohortViewerAccessQueryVariables>(
    CohortViewerAccessDocument,
    options
  )
}
export function useCohortViewerAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortViewerAccessQuery,
    CohortViewerAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CohortViewerAccessQuery, CohortViewerAccessQueryVariables>(
    CohortViewerAccessDocument,
    options
  )
}
export type CohortViewerAccessQueryHookResult = ReturnType<
  typeof useCohortViewerAccessQuery
>
export type CohortViewerAccessLazyQueryHookResult = ReturnType<
  typeof useCohortViewerAccessLazyQuery
>
export type CohortViewerAccessQueryResult = Apollo.QueryResult<
  CohortViewerAccessQuery,
  CohortViewerAccessQueryVariables
>
export const CohortViewerMaterialDocument = gql`
  query CohortViewerMaterial($programId: ID!) {
    cmsProgram(cmsProgramId: $programId) {
      id
      launched
      cmsModules {
        id
        ...CohortViewerMaterialCmsModuleParts
      }
    }
    currentUser {
      id
      programProgress {
        progress
      }
      numModuleBookmarks
      numSectionBookmarks
    }
  }
  ${CohortViewerMaterialCmsModulePartsFragmentDoc}
`

/**
 * __useCohortViewerMaterialQuery__
 *
 * To run a query within a React component, call `useCohortViewerMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useCohortViewerMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCohortViewerMaterialQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useCohortViewerMaterialQuery(
  baseOptions: Apollo.QueryHookOptions<
    CohortViewerMaterialQuery,
    CohortViewerMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CohortViewerMaterialQuery, CohortViewerMaterialQueryVariables>(
    CohortViewerMaterialDocument,
    options
  )
}
export function useCohortViewerMaterialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CohortViewerMaterialQuery,
    CohortViewerMaterialQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CohortViewerMaterialQuery,
    CohortViewerMaterialQueryVariables
  >(CohortViewerMaterialDocument, options)
}
export type CohortViewerMaterialQueryHookResult = ReturnType<
  typeof useCohortViewerMaterialQuery
>
export type CohortViewerMaterialLazyQueryHookResult = ReturnType<
  typeof useCohortViewerMaterialLazyQuery
>
export type CohortViewerMaterialQueryResult = Apollo.QueryResult<
  CohortViewerMaterialQuery,
  CohortViewerMaterialQueryVariables
>
export const CollectionActivityDocument = gql`
  query collectionActivity($collectionId: ID!, $page: Int) {
    collectionActivity(id: $collectionId, page: $page) {
      collectionActivities {
        ...CollectionActivityParts
      }
      totalCount
    }
  }
  ${CollectionActivityPartsFragmentDoc}
`

/**
 * __useCollectionActivityQuery__
 *
 * To run a query within a React component, call `useCollectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionActivityQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCollectionActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectionActivityQuery,
    CollectionActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CollectionActivityQuery, CollectionActivityQueryVariables>(
    CollectionActivityDocument,
    options
  )
}
export function useCollectionActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionActivityQuery,
    CollectionActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CollectionActivityQuery, CollectionActivityQueryVariables>(
    CollectionActivityDocument,
    options
  )
}
export type CollectionActivityQueryHookResult = ReturnType<
  typeof useCollectionActivityQuery
>
export type CollectionActivityLazyQueryHookResult = ReturnType<
  typeof useCollectionActivityLazyQuery
>
export type CollectionActivityQueryResult = Apollo.QueryResult<
  CollectionActivityQuery,
  CollectionActivityQueryVariables
>
export const MostRecentCollectionActivityPerUserDocument = gql`
  query mostRecentCollectionActivityPerUser($collectionId: ID!, $userIds: [ID!]!) {
    mostRecentCollectionActivityPerUser(collectionId: $collectionId, userIds: $userIds) {
      ...CollectionActivityParts
    }
  }
  ${CollectionActivityPartsFragmentDoc}
`

/**
 * __useMostRecentCollectionActivityPerUserQuery__
 *
 * To run a query within a React component, call `useMostRecentCollectionActivityPerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRecentCollectionActivityPerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRecentCollectionActivityPerUserQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useMostRecentCollectionActivityPerUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    MostRecentCollectionActivityPerUserQuery,
    MostRecentCollectionActivityPerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MostRecentCollectionActivityPerUserQuery,
    MostRecentCollectionActivityPerUserQueryVariables
  >(MostRecentCollectionActivityPerUserDocument, options)
}
export function useMostRecentCollectionActivityPerUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MostRecentCollectionActivityPerUserQuery,
    MostRecentCollectionActivityPerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MostRecentCollectionActivityPerUserQuery,
    MostRecentCollectionActivityPerUserQueryVariables
  >(MostRecentCollectionActivityPerUserDocument, options)
}
export type MostRecentCollectionActivityPerUserQueryHookResult = ReturnType<
  typeof useMostRecentCollectionActivityPerUserQuery
>
export type MostRecentCollectionActivityPerUserLazyQueryHookResult = ReturnType<
  typeof useMostRecentCollectionActivityPerUserLazyQuery
>
export type MostRecentCollectionActivityPerUserQueryResult = Apollo.QueryResult<
  MostRecentCollectionActivityPerUserQuery,
  MostRecentCollectionActivityPerUserQueryVariables
>
export const CompletedFeedbackDocument = gql`
  query completedFeedback {
    completedFeedback {
      postApplySurvey
    }
  }
`

/**
 * __useCompletedFeedbackQuery__
 *
 * To run a query within a React component, call `useCompletedFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedFeedbackQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletedFeedbackQuery,
    CompletedFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompletedFeedbackQuery, CompletedFeedbackQueryVariables>(
    CompletedFeedbackDocument,
    options
  )
}
export function useCompletedFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedFeedbackQuery,
    CompletedFeedbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompletedFeedbackQuery, CompletedFeedbackQueryVariables>(
    CompletedFeedbackDocument,
    options
  )
}
export type CompletedFeedbackQueryHookResult = ReturnType<
  typeof useCompletedFeedbackQuery
>
export type CompletedFeedbackLazyQueryHookResult = ReturnType<
  typeof useCompletedFeedbackLazyQuery
>
export type CompletedFeedbackQueryResult = Apollo.QueryResult<
  CompletedFeedbackQuery,
  CompletedFeedbackQueryVariables
>
export const CompletedProductToursDocument = gql`
  query CompletedProductTours {
    currentUser {
      id
      is {
        member
        paidMember
      }
      completedProductTours {
        bookmarkCreate
        collectionSaveBookmark
        collectionShare
        collectionsTab
        collectionViewContent
        savedItemsTab
        searchTrainerViews
        teamCommentView
        newSearchClicked
        usedReforgeAiGlobalEntryPoint
        startDraftFromButton
      }
    }
  }
`

/**
 * __useCompletedProductToursQuery__
 *
 * To run a query within a React component, call `useCompletedProductToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedProductToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedProductToursQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletedProductToursQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletedProductToursQuery,
    CompletedProductToursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompletedProductToursQuery, CompletedProductToursQueryVariables>(
    CompletedProductToursDocument,
    options
  )
}
export function useCompletedProductToursLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedProductToursQuery,
    CompletedProductToursQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompletedProductToursQuery,
    CompletedProductToursQueryVariables
  >(CompletedProductToursDocument, options)
}
export type CompletedProductToursQueryHookResult = ReturnType<
  typeof useCompletedProductToursQuery
>
export type CompletedProductToursLazyQueryHookResult = ReturnType<
  typeof useCompletedProductToursLazyQuery
>
export type CompletedProductToursQueryResult = Apollo.QueryResult<
  CompletedProductToursQuery,
  CompletedProductToursQueryVariables
>
export const ConceptsFeedDocument = gql`
  query ConceptsFeed($filters: ContentFilters, $limit: Int, $offset: Int) {
    concepts(filters: $filters, limit: $limit, offset: $offset) {
      count
      content {
        ...ConceptCardContentParts
      }
      cmsModuleId
      topics
      userProgram {
        id
        ...UserProgramParts
      }
    }
  }
  ${ConceptCardContentPartsFragmentDoc}
  ${UserProgramPartsFragmentDoc}
`

/**
 * __useConceptsFeedQuery__
 *
 * To run a query within a React component, call `useConceptsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useConceptsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConceptsFeedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useConceptsFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<ConceptsFeedQuery, ConceptsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConceptsFeedQuery, ConceptsFeedQueryVariables>(
    ConceptsFeedDocument,
    options
  )
}
export function useConceptsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConceptsFeedQuery, ConceptsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ConceptsFeedQuery, ConceptsFeedQueryVariables>(
    ConceptsFeedDocument,
    options
  )
}
export type ConceptsFeedQueryHookResult = ReturnType<typeof useConceptsFeedQuery>
export type ConceptsFeedLazyQueryHookResult = ReturnType<typeof useConceptsFeedLazyQuery>
export type ConceptsFeedQueryResult = Apollo.QueryResult<
  ConceptsFeedQuery,
  ConceptsFeedQueryVariables
>
export const ContentViewerDocument = gql`
  query ContentViewer($cmsType: String!, $slug: String!, $path: String!) {
    currentUser {
      id
    }
    contentViewer(cmsType: $cmsType, slug: $slug, path: $path) {
      ...ContentViewerContentParts
      ...ContentViewerPartialContentParts
      ...ContentViewerHeaderParts
      ...ContentViewerExpertsParts
      ...ContentViewerTocParts
      ...ContentViewerDeliverableParts
      viewAccess
      cmsModule {
        id
        showFeedback
      }
      cmsContent {
        id
        accessLevel
        showFeedback
        currentUserSubmittedFeedback
        introHtmlSafe
        summaryHtmlSafe
        previewable
        unlimitedAccess
        contentBookmarkId
        contentIsInMySavedItems
        topic {
          id
          title
        }
        groups(last: 1) {
          edges {
            node {
              id
            }
          }
        }
        relatedContent {
          id
          ... on CmsSection {
            ...RelatedContentParts
            slug
            previewable
          }
        }
      }
    }
  }
  ${ContentViewerContentPartsFragmentDoc}
  ${ContentViewerPartialContentPartsFragmentDoc}
  ${ContentViewerHeaderPartsFragmentDoc}
  ${ContentViewerExpertsPartsFragmentDoc}
  ${ContentViewerTocPartsFragmentDoc}
  ${ContentViewerDeliverablePartsFragmentDoc}
  ${RelatedContentPartsFragmentDoc}
`

/**
 * __useContentViewerQuery__
 *
 * To run a query within a React component, call `useContentViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentViewerQuery({
 *   variables: {
 *      cmsType: // value for 'cmsType'
 *      slug: // value for 'slug'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useContentViewerQuery(
  baseOptions: Apollo.QueryHookOptions<ContentViewerQuery, ContentViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContentViewerQuery, ContentViewerQueryVariables>(
    ContentViewerDocument,
    options
  )
}
export function useContentViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentViewerQuery,
    ContentViewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContentViewerQuery, ContentViewerQueryVariables>(
    ContentViewerDocument,
    options
  )
}
export type ContentViewerQueryHookResult = ReturnType<typeof useContentViewerQuery>
export type ContentViewerLazyQueryHookResult = ReturnType<
  typeof useContentViewerLazyQuery
>
export type ContentViewerQueryResult = Apollo.QueryResult<
  ContentViewerQuery,
  ContentViewerQueryVariables
>
export const CourseBlocksDocument = gql`
  query courseBlocks($slug: String!, $courseSessionId: ID!) {
    courseBlocks(slug: $slug, courseSessionId: $courseSessionId) {
      courseBlocks {
        events {
          title
          sanityId
          shortDescription
          eventPerCourseSession {
            startsAt
            endsAt
            joinLink
          }
        }
      }
    }
  }
`

/**
 * __useCourseBlocksQuery__
 *
 * To run a query within a React component, call `useCourseBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseBlocksQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<CourseBlocksQuery, CourseBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseBlocksQuery, CourseBlocksQueryVariables>(
    CourseBlocksDocument,
    options
  )
}
export function useCourseBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseBlocksQuery, CourseBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseBlocksQuery, CourseBlocksQueryVariables>(
    CourseBlocksDocument,
    options
  )
}
export type CourseBlocksQueryHookResult = ReturnType<typeof useCourseBlocksQuery>
export type CourseBlocksLazyQueryHookResult = ReturnType<typeof useCourseBlocksLazyQuery>
export type CourseBlocksQueryResult = Apollo.QueryResult<
  CourseBlocksQuery,
  CourseBlocksQueryVariables
>
export const CourseCheckoutPageDocument = gql`
  query CourseCheckoutPage($slug: String!) {
    cclCourse(slug: $slug) {
      ...CourseCheckoutParts
    }
    upcomingCourseSessions(slug: $slug) {
      ...CheckoutCourseSession
    }
    currentUser {
      id
      timezone
      subscriptions {
        active {
          id
          planName
        }
      }
    }
  }
  ${CourseCheckoutPartsFragmentDoc}
  ${CheckoutCourseSessionFragmentDoc}
`

/**
 * __useCourseCheckoutPageQuery__
 *
 * To run a query within a React component, call `useCourseCheckoutPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCheckoutPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCheckoutPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseCheckoutPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCheckoutPageQuery,
    CourseCheckoutPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseCheckoutPageQuery, CourseCheckoutPageQueryVariables>(
    CourseCheckoutPageDocument,
    options
  )
}
export function useCourseCheckoutPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseCheckoutPageQuery,
    CourseCheckoutPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseCheckoutPageQuery, CourseCheckoutPageQueryVariables>(
    CourseCheckoutPageDocument,
    options
  )
}
export type CourseCheckoutPageQueryHookResult = ReturnType<
  typeof useCourseCheckoutPageQuery
>
export type CourseCheckoutPageLazyQueryHookResult = ReturnType<
  typeof useCourseCheckoutPageLazyQuery
>
export type CourseCheckoutPageQueryResult = Apollo.QueryResult<
  CourseCheckoutPageQuery,
  CourseCheckoutPageQueryVariables
>
export const CourseCompletionCertificateDocument = gql`
  query courseCompletionCertificate($courseSessionId: ID!) {
    userCourse(courseSessionId: $courseSessionId) {
      courseCompletionCertificate {
        id
        certificateUrl
      }
    }
  }
`

/**
 * __useCourseCompletionCertificateQuery__
 *
 * To run a query within a React component, call `useCourseCompletionCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCompletionCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCompletionCertificateQuery({
 *   variables: {
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseCompletionCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCompletionCertificateQuery,
    CourseCompletionCertificateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseCompletionCertificateQuery,
    CourseCompletionCertificateQueryVariables
  >(CourseCompletionCertificateDocument, options)
}
export function useCourseCompletionCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseCompletionCertificateQuery,
    CourseCompletionCertificateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseCompletionCertificateQuery,
    CourseCompletionCertificateQueryVariables
  >(CourseCompletionCertificateDocument, options)
}
export type CourseCompletionCertificateQueryHookResult = ReturnType<
  typeof useCourseCompletionCertificateQuery
>
export type CourseCompletionCertificateLazyQueryHookResult = ReturnType<
  typeof useCourseCompletionCertificateLazyQuery
>
export type CourseCompletionCertificateQueryResult = Apollo.QueryResult<
  CourseCompletionCertificateQuery,
  CourseCompletionCertificateQueryVariables
>
export const CourseDetailDocument = gql`
  query courseDetail($slug: String!, $courseSessionId: ID!) {
    course(slug: $slug) {
      ...CourseDashboardCourseParts
      courseBlocks {
        ...CourseDashboardCourseBlockParts
      }
    }
    courseSession(courseSessionId: $courseSessionId) {
      ...CourseDashboardCourseSessionParts
    }
    userCourse(courseSessionId: $courseSessionId) {
      ...CourseDashboardUserCourseParts
    }
    cclCourse(slug: $slug) {
      ...CourseDetailParts
    }
  }
  ${CourseDashboardCoursePartsFragmentDoc}
  ${CourseDashboardCourseBlockPartsFragmentDoc}
  ${CourseDashboardCourseSessionPartsFragmentDoc}
  ${CourseDashboardUserCoursePartsFragmentDoc}
  ${CourseDetailPartsFragmentDoc}
`

/**
 * __useCourseDetailQuery__
 *
 * To run a query within a React component, call `useCourseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseDetailQuery(
  baseOptions: Apollo.QueryHookOptions<CourseDetailQuery, CourseDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseDetailQuery, CourseDetailQueryVariables>(
    CourseDetailDocument,
    options
  )
}
export function useCourseDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseDetailQuery, CourseDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseDetailQuery, CourseDetailQueryVariables>(
    CourseDetailDocument,
    options
  )
}
export type CourseDetailQueryHookResult = ReturnType<typeof useCourseDetailQuery>
export type CourseDetailLazyQueryHookResult = ReturnType<typeof useCourseDetailLazyQuery>
export type CourseDetailQueryResult = Apollo.QueryResult<
  CourseDetailQuery,
  CourseDetailQueryVariables
>
export const MarketingCourseBySlugDocument = gql`
  query MarketingCourseBySlug($slug: String!) {
    marketingCourse(slug: $slug) {
      id
      course {
        id
        title
        shortDescription
        slug
        whatYouWillLearn
        whoThisIsFor
      }
      price
      duration
      coursePreviewAvailable
      startDate
      hostSubtitle
      courseInfoCards {
        id
        title
        description
      }
      backgroundImage {
        ...ImageFragment
      }
      hosts {
        id
        hostName
        hostBio
        hostPicture {
          ...ImageFragment
        }
      }
      creators {
        id
        role
        expert {
          id
          name
          slug
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
      courseFaq {
        id
        question
        answer
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useMarketingCourseBySlugQuery__
 *
 * To run a query within a React component, call `useMarketingCourseBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingCourseBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingCourseBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useMarketingCourseBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketingCourseBySlugQuery,
    MarketingCourseBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MarketingCourseBySlugQuery, MarketingCourseBySlugQueryVariables>(
    MarketingCourseBySlugDocument,
    options
  )
}
export function useMarketingCourseBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingCourseBySlugQuery,
    MarketingCourseBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketingCourseBySlugQuery,
    MarketingCourseBySlugQueryVariables
  >(MarketingCourseBySlugDocument, options)
}
export type MarketingCourseBySlugQueryHookResult = ReturnType<
  typeof useMarketingCourseBySlugQuery
>
export type MarketingCourseBySlugLazyQueryHookResult = ReturnType<
  typeof useMarketingCourseBySlugLazyQuery
>
export type MarketingCourseBySlugQueryResult = Apollo.QueryResult<
  MarketingCourseBySlugQuery,
  MarketingCourseBySlugQueryVariables
>
export const CourseEventDocument = gql`
  query courseEvent($sanityId: String!, $courseSessionId: ID) {
    courseEvent(sanityId: $sanityId, courseSessionId: $courseSessionId) {
      ...CourseEventParts
    }
  }
  ${CourseEventPartsFragmentDoc}
`

/**
 * __useCourseEventQuery__
 *
 * To run a query within a React component, call `useCourseEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseEventQuery({
 *   variables: {
 *      sanityId: // value for 'sanityId'
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseEventQuery(
  baseOptions: Apollo.QueryHookOptions<CourseEventQuery, CourseEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseEventQuery, CourseEventQueryVariables>(
    CourseEventDocument,
    options
  )
}
export function useCourseEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseEventQuery, CourseEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseEventQuery, CourseEventQueryVariables>(
    CourseEventDocument,
    options
  )
}
export type CourseEventQueryHookResult = ReturnType<typeof useCourseEventQuery>
export type CourseEventLazyQueryHookResult = ReturnType<typeof useCourseEventLazyQuery>
export type CourseEventQueryResult = Apollo.QueryResult<
  CourseEventQuery,
  CourseEventQueryVariables
>
export const CourseNavigationOutlineDocument = gql`
  query courseNavigationOutline($slug: String!) {
    course(slug: $slug) {
      ...CourseNavigationOutline
    }
  }
  ${CourseNavigationOutlineFragmentDoc}
`

/**
 * __useCourseNavigationOutlineQuery__
 *
 * To run a query within a React component, call `useCourseNavigationOutlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseNavigationOutlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseNavigationOutlineQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseNavigationOutlineQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseNavigationOutlineQuery,
    CourseNavigationOutlineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseNavigationOutlineQuery,
    CourseNavigationOutlineQueryVariables
  >(CourseNavigationOutlineDocument, options)
}
export function useCourseNavigationOutlineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseNavigationOutlineQuery,
    CourseNavigationOutlineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseNavigationOutlineQuery,
    CourseNavigationOutlineQueryVariables
  >(CourseNavigationOutlineDocument, options)
}
export type CourseNavigationOutlineQueryHookResult = ReturnType<
  typeof useCourseNavigationOutlineQuery
>
export type CourseNavigationOutlineLazyQueryHookResult = ReturnType<
  typeof useCourseNavigationOutlineLazyQuery
>
export type CourseNavigationOutlineQueryResult = Apollo.QueryResult<
  CourseNavigationOutlineQuery,
  CourseNavigationOutlineQueryVariables
>
export const CourseOnDemandDetailDocument = gql`
  query courseOnDemandDetail($slug: String!) {
    course(slug: $slug) {
      ...CourseDashboardCourseParts
      cmsProgram {
        ...CourseOnDemandCmsProgramParts
      }
      courseBlocks {
        ...CourseDashboardCourseBlockParts
      }
      cmsProgram {
        id
      }
    }
    cclCourse(slug: $slug) {
      ...CourseDetailParts
    }
  }
  ${CourseDashboardCoursePartsFragmentDoc}
  ${CourseOnDemandCmsProgramPartsFragmentDoc}
  ${CourseDashboardCourseBlockPartsFragmentDoc}
  ${CourseDetailPartsFragmentDoc}
`

/**
 * __useCourseOnDemandDetailQuery__
 *
 * To run a query within a React component, call `useCourseOnDemandDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseOnDemandDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseOnDemandDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseOnDemandDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseOnDemandDetailQuery,
    CourseOnDemandDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseOnDemandDetailQuery, CourseOnDemandDetailQueryVariables>(
    CourseOnDemandDetailDocument,
    options
  )
}
export function useCourseOnDemandDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseOnDemandDetailQuery,
    CourseOnDemandDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseOnDemandDetailQuery,
    CourseOnDemandDetailQueryVariables
  >(CourseOnDemandDetailDocument, options)
}
export type CourseOnDemandDetailQueryHookResult = ReturnType<
  typeof useCourseOnDemandDetailQuery
>
export type CourseOnDemandDetailLazyQueryHookResult = ReturnType<
  typeof useCourseOnDemandDetailLazyQuery
>
export type CourseOnDemandDetailQueryResult = Apollo.QueryResult<
  CourseOnDemandDetailQuery,
  CourseOnDemandDetailQueryVariables
>
export const CourseOnDemandPageAccessDocument = gql`
  query courseOnDemandPageAccess($slug: String!) {
    course(slug: $slug) {
      ...CoursePageAccessParts
      cmsProgram {
        ...CourseOnDemandCmsProgramParts
      }
    }
    cclCourse(slug: $slug) {
      ...CclCourseIdentifierParts
    }
  }
  ${CoursePageAccessPartsFragmentDoc}
  ${CourseOnDemandCmsProgramPartsFragmentDoc}
  ${CclCourseIdentifierPartsFragmentDoc}
`

/**
 * __useCourseOnDemandPageAccessQuery__
 *
 * To run a query within a React component, call `useCourseOnDemandPageAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseOnDemandPageAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseOnDemandPageAccessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseOnDemandPageAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseOnDemandPageAccessQuery,
    CourseOnDemandPageAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseOnDemandPageAccessQuery,
    CourseOnDemandPageAccessQueryVariables
  >(CourseOnDemandPageAccessDocument, options)
}
export function useCourseOnDemandPageAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseOnDemandPageAccessQuery,
    CourseOnDemandPageAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseOnDemandPageAccessQuery,
    CourseOnDemandPageAccessQueryVariables
  >(CourseOnDemandPageAccessDocument, options)
}
export type CourseOnDemandPageAccessQueryHookResult = ReturnType<
  typeof useCourseOnDemandPageAccessQuery
>
export type CourseOnDemandPageAccessLazyQueryHookResult = ReturnType<
  typeof useCourseOnDemandPageAccessLazyQuery
>
export type CourseOnDemandPageAccessQueryResult = Apollo.QueryResult<
  CourseOnDemandPageAccessQuery,
  CourseOnDemandPageAccessQueryVariables
>
export const CoursePageAccessDocument = gql`
  query coursePageAccess($slug: String!, $courseSessionId: ID!) {
    course(slug: $slug) {
      ...CoursePageAccessParts
    }
    courseSession(courseSessionId: $courseSessionId) {
      ...CourseSessionAccessParts
    }
    userCourse(courseSessionId: $courseSessionId) {
      id
      courseEnrollmentSnapshot {
        ...CourseEnrollmentSnapshotParts
      }
    }
  }
  ${CoursePageAccessPartsFragmentDoc}
  ${CourseSessionAccessPartsFragmentDoc}
  ${CourseEnrollmentSnapshotPartsFragmentDoc}
`

/**
 * __useCoursePageAccessQuery__
 *
 * To run a query within a React component, call `useCoursePageAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePageAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePageAccessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCoursePageAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoursePageAccessQuery,
    CoursePageAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursePageAccessQuery, CoursePageAccessQueryVariables>(
    CoursePageAccessDocument,
    options
  )
}
export function useCoursePageAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursePageAccessQuery,
    CoursePageAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursePageAccessQuery, CoursePageAccessQueryVariables>(
    CoursePageAccessDocument,
    options
  )
}
export type CoursePageAccessQueryHookResult = ReturnType<typeof useCoursePageAccessQuery>
export type CoursePageAccessLazyQueryHookResult = ReturnType<
  typeof useCoursePageAccessLazyQuery
>
export type CoursePageAccessQueryResult = Apollo.QueryResult<
  CoursePageAccessQuery,
  CoursePageAccessQueryVariables
>
export const CoursePaymentPageDocument = gql`
  query CoursePaymentPage($slug: String!, $startDate: String!) {
    courseSession(slug: $slug, startDate: $startDate) {
      id
      price
      startsAt
      endsAt
      isDuringEnrollmentPeriod
      isEnrollmentFull
      courseTitle
    }
    currentUser {
      subscriptions {
        active {
          id
          planName
        }
      }
    }
  }
`

/**
 * __useCoursePaymentPageQuery__
 *
 * To run a query within a React component, call `useCoursePaymentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePaymentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePaymentPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useCoursePaymentPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoursePaymentPageQuery,
    CoursePaymentPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursePaymentPageQuery, CoursePaymentPageQueryVariables>(
    CoursePaymentPageDocument,
    options
  )
}
export function useCoursePaymentPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursePaymentPageQuery,
    CoursePaymentPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursePaymentPageQuery, CoursePaymentPageQueryVariables>(
    CoursePaymentPageDocument,
    options
  )
}
export type CoursePaymentPageQueryHookResult = ReturnType<
  typeof useCoursePaymentPageQuery
>
export type CoursePaymentPageLazyQueryHookResult = ReturnType<
  typeof useCoursePaymentPageLazyQuery
>
export type CoursePaymentPageQueryResult = Apollo.QueryResult<
  CoursePaymentPageQuery,
  CoursePaymentPageQueryVariables
>
export const CoursePreviewDocument = gql`
  query coursePreview($slug: String!) {
    coursePreview(slug: $slug) {
      ...CoursePreviewDashboardCourseParts
    }
  }
  ${CoursePreviewDashboardCoursePartsFragmentDoc}
`

/**
 * __useCoursePreviewQuery__
 *
 * To run a query within a React component, call `useCoursePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePreviewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCoursePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<CoursePreviewQuery, CoursePreviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursePreviewQuery, CoursePreviewQueryVariables>(
    CoursePreviewDocument,
    options
  )
}
export function useCoursePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursePreviewQuery,
    CoursePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursePreviewQuery, CoursePreviewQueryVariables>(
    CoursePreviewDocument,
    options
  )
}
export type CoursePreviewQueryHookResult = ReturnType<typeof useCoursePreviewQuery>
export type CoursePreviewLazyQueryHookResult = ReturnType<
  typeof useCoursePreviewLazyQuery
>
export type CoursePreviewQueryResult = Apollo.QueryResult<
  CoursePreviewQuery,
  CoursePreviewQueryVariables
>
export const CourseSessionHostDashboardDocument = gql`
  query courseSessionHostDashboard($courseSessionId: ID!) {
    supplyCourseSession(courseSessionId: $courseSessionId) {
      courseTitle
      enrollmentStartsAt
      startsAt
      endsAt
      maxEnrollmentTotal
      maxEnrollmentPaid
      maxEnrollmentUnlimited
      numEnrolledTotal
      numEnrolledPaid
      numEnrolledUnlimited
      enrollees {
        fullName
        email
        companyName
        jobFunction
        role
      }
    }
  }
`

/**
 * __useCourseSessionHostDashboardQuery__
 *
 * To run a query within a React component, call `useCourseSessionHostDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSessionHostDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSessionHostDashboardQuery({
 *   variables: {
 *      courseSessionId: // value for 'courseSessionId'
 *   },
 * });
 */
export function useCourseSessionHostDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseSessionHostDashboardQuery,
    CourseSessionHostDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseSessionHostDashboardQuery,
    CourseSessionHostDashboardQueryVariables
  >(CourseSessionHostDashboardDocument, options)
}
export function useCourseSessionHostDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSessionHostDashboardQuery,
    CourseSessionHostDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseSessionHostDashboardQuery,
    CourseSessionHostDashboardQueryVariables
  >(CourseSessionHostDashboardDocument, options)
}
export type CourseSessionHostDashboardQueryHookResult = ReturnType<
  typeof useCourseSessionHostDashboardQuery
>
export type CourseSessionHostDashboardLazyQueryHookResult = ReturnType<
  typeof useCourseSessionHostDashboardLazyQuery
>
export type CourseSessionHostDashboardQueryResult = Apollo.QueryResult<
  CourseSessionHostDashboardQuery,
  CourseSessionHostDashboardQueryVariables
>
export const CourseSnapshotDocument = gql`
  query courseSnapshot($token: String!, $snapshotType: String!) {
    courseSnapshot(snapshotType: $snapshotType, token: $token) {
      courseDescription
      courseName
      courseSlug
      firstName
      imageUrl
    }
  }
`

/**
 * __useCourseSnapshotQuery__
 *
 * To run a query within a React component, call `useCourseSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSnapshotQuery({
 *   variables: {
 *      token: // value for 'token'
 *      snapshotType: // value for 'snapshotType'
 *   },
 * });
 */
export function useCourseSnapshotQuery(
  baseOptions: Apollo.QueryHookOptions<CourseSnapshotQuery, CourseSnapshotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseSnapshotQuery, CourseSnapshotQueryVariables>(
    CourseSnapshotDocument,
    options
  )
}
export function useCourseSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSnapshotQuery,
    CourseSnapshotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseSnapshotQuery, CourseSnapshotQueryVariables>(
    CourseSnapshotDocument,
    options
  )
}
export type CourseSnapshotQueryHookResult = ReturnType<typeof useCourseSnapshotQuery>
export type CourseSnapshotLazyQueryHookResult = ReturnType<
  typeof useCourseSnapshotLazyQuery
>
export type CourseSnapshotQueryResult = Apollo.QueryResult<
  CourseSnapshotQuery,
  CourseSnapshotQueryVariables
>
export const LatestArtifactsDocument = gql`
  query latestArtifacts($limit: Int) {
    latestArtifacts(limit: $limit) {
      ...CourseSnapshotArtifactParts
    }
  }
  ${CourseSnapshotArtifactPartsFragmentDoc}
`

/**
 * __useLatestArtifactsQuery__
 *
 * To run a query within a React component, call `useLatestArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestArtifactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestArtifactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestArtifactsQuery,
    LatestArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestArtifactsQuery, LatestArtifactsQueryVariables>(
    LatestArtifactsDocument,
    options
  )
}
export function useLatestArtifactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestArtifactsQuery,
    LatestArtifactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestArtifactsQuery, LatestArtifactsQueryVariables>(
    LatestArtifactsDocument,
    options
  )
}
export type LatestArtifactsQueryHookResult = ReturnType<typeof useLatestArtifactsQuery>
export type LatestArtifactsLazyQueryHookResult = ReturnType<
  typeof useLatestArtifactsLazyQuery
>
export type LatestArtifactsQueryResult = Apollo.QueryResult<
  LatestArtifactsQuery,
  LatestArtifactsQueryVariables
>
export const CourseSnapshotMarketingCoursesDocument = gql`
  query CourseSnapshotMarketingCourses {
    marketingCourses {
      ...CourseSnapshotMarketingCoursesParts
    }
  }
  ${CourseSnapshotMarketingCoursesPartsFragmentDoc}
`

/**
 * __useCourseSnapshotMarketingCoursesQuery__
 *
 * To run a query within a React component, call `useCourseSnapshotMarketingCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSnapshotMarketingCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSnapshotMarketingCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseSnapshotMarketingCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CourseSnapshotMarketingCoursesQuery,
    CourseSnapshotMarketingCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseSnapshotMarketingCoursesQuery,
    CourseSnapshotMarketingCoursesQueryVariables
  >(CourseSnapshotMarketingCoursesDocument, options)
}
export function useCourseSnapshotMarketingCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSnapshotMarketingCoursesQuery,
    CourseSnapshotMarketingCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseSnapshotMarketingCoursesQuery,
    CourseSnapshotMarketingCoursesQueryVariables
  >(CourseSnapshotMarketingCoursesDocument, options)
}
export type CourseSnapshotMarketingCoursesQueryHookResult = ReturnType<
  typeof useCourseSnapshotMarketingCoursesQuery
>
export type CourseSnapshotMarketingCoursesLazyQueryHookResult = ReturnType<
  typeof useCourseSnapshotMarketingCoursesLazyQuery
>
export type CourseSnapshotMarketingCoursesQueryResult = Apollo.QueryResult<
  CourseSnapshotMarketingCoursesQuery,
  CourseSnapshotMarketingCoursesQueryVariables
>
export const CoursesIncludingGuideDocument = gql`
  query coursesIncludingGuide($guideSlug: String!) {
    currentUser {
      hasUseableCohortCredits
      courseBookmarks {
        ...CourseBookmarkParts
      }
    }
    liveCoursesIncludingGuide(guideSlug: $guideSlug) {
      ...CclCourseCourseCardParts
      guideCount
    }
  }
  ${CourseBookmarkPartsFragmentDoc}
  ${CclCourseCourseCardPartsFragmentDoc}
`

/**
 * __useCoursesIncludingGuideQuery__
 *
 * To run a query within a React component, call `useCoursesIncludingGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesIncludingGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesIncludingGuideQuery({
 *   variables: {
 *      guideSlug: // value for 'guideSlug'
 *   },
 * });
 */
export function useCoursesIncludingGuideQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoursesIncludingGuideQuery,
    CoursesIncludingGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursesIncludingGuideQuery, CoursesIncludingGuideQueryVariables>(
    CoursesIncludingGuideDocument,
    options
  )
}
export function useCoursesIncludingGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesIncludingGuideQuery,
    CoursesIncludingGuideQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CoursesIncludingGuideQuery,
    CoursesIncludingGuideQueryVariables
  >(CoursesIncludingGuideDocument, options)
}
export type CoursesIncludingGuideQueryHookResult = ReturnType<
  typeof useCoursesIncludingGuideQuery
>
export type CoursesIncludingGuideLazyQueryHookResult = ReturnType<
  typeof useCoursesIncludingGuideLazyQuery
>
export type CoursesIncludingGuideQueryResult = Apollo.QueryResult<
  CoursesIncludingGuideQuery,
  CoursesIncludingGuideQueryVariables
>
export const CoursesPageCurrentUserDocument = gql`
  query CoursesPageCurrentUser {
    currentUser {
      ...CoursesPageCurrentUserParts
    }
    userCohorts(descendingByStartDate: true) {
      ...UserCohortPart
    }
  }
  ${CoursesPageCurrentUserPartsFragmentDoc}
  ${UserCohortPartFragmentDoc}
`

/**
 * __useCoursesPageCurrentUserQuery__
 *
 * To run a query within a React component, call `useCoursesPageCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesPageCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesPageCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesPageCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesPageCurrentUserQuery,
    CoursesPageCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CoursesPageCurrentUserQuery,
    CoursesPageCurrentUserQueryVariables
  >(CoursesPageCurrentUserDocument, options)
}
export function useCoursesPageCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesPageCurrentUserQuery,
    CoursesPageCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CoursesPageCurrentUserQuery,
    CoursesPageCurrentUserQueryVariables
  >(CoursesPageCurrentUserDocument, options)
}
export type CoursesPageCurrentUserQueryHookResult = ReturnType<
  typeof useCoursesPageCurrentUserQuery
>
export type CoursesPageCurrentUserLazyQueryHookResult = ReturnType<
  typeof useCoursesPageCurrentUserLazyQuery
>
export type CoursesPageCurrentUserQueryResult = Apollo.QueryResult<
  CoursesPageCurrentUserQuery,
  CoursesPageCurrentUserQueryVariables
>
export const CoursesPageMarketingCoursesDocument = gql`
  query CoursesPageMarketingCourses($courseType: String) {
    marketingCourses(courseType: $courseType) {
      id
      slug
      cmsProgramSlug
      startDate
      subtitle
      title
      legacyProgramId
      bookmarkId
      course {
        id
        title
        shortDescription
        isAvailableOnDemand
        slug
        bookmarkId
        popularWithRoles
        creators {
          id
          name
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
      courseType
      creators {
        id
        expert {
          id
          name
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
    }
    activeCourseSessions {
      ...CoursesPageActiveCourseSessionParts
    }
  }
  ${ImageFragmentFragmentDoc}
  ${CoursesPageActiveCourseSessionPartsFragmentDoc}
`

/**
 * __useCoursesPageMarketingCoursesQuery__
 *
 * To run a query within a React component, call `useCoursesPageMarketingCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesPageMarketingCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesPageMarketingCoursesQuery({
 *   variables: {
 *      courseType: // value for 'courseType'
 *   },
 * });
 */
export function useCoursesPageMarketingCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesPageMarketingCoursesQuery,
    CoursesPageMarketingCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CoursesPageMarketingCoursesQuery,
    CoursesPageMarketingCoursesQueryVariables
  >(CoursesPageMarketingCoursesDocument, options)
}
export function useCoursesPageMarketingCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesPageMarketingCoursesQuery,
    CoursesPageMarketingCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CoursesPageMarketingCoursesQuery,
    CoursesPageMarketingCoursesQueryVariables
  >(CoursesPageMarketingCoursesDocument, options)
}
export type CoursesPageMarketingCoursesQueryHookResult = ReturnType<
  typeof useCoursesPageMarketingCoursesQuery
>
export type CoursesPageMarketingCoursesLazyQueryHookResult = ReturnType<
  typeof useCoursesPageMarketingCoursesLazyQuery
>
export type CoursesPageMarketingCoursesQueryResult = Apollo.QueryResult<
  CoursesPageMarketingCoursesQuery,
  CoursesPageMarketingCoursesQueryVariables
>
export const CourseTopicDocument = gql`
  query CourseTopic($slug: String!) {
    topic(slug: $slug) {
      id
      title
      slug
      isDeprecated
      redirectTo
    }
  }
`

/**
 * __useCourseTopicQuery__
 *
 * To run a query within a React component, call `useCourseTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseTopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseTopicQuery(
  baseOptions: Apollo.QueryHookOptions<CourseTopicQuery, CourseTopicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseTopicQuery, CourseTopicQueryVariables>(
    CourseTopicDocument,
    options
  )
}
export function useCourseTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CourseTopicQuery, CourseTopicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseTopicQuery, CourseTopicQueryVariables>(
    CourseTopicDocument,
    options
  )
}
export type CourseTopicQueryHookResult = ReturnType<typeof useCourseTopicQuery>
export type CourseTopicLazyQueryHookResult = ReturnType<typeof useCourseTopicLazyQuery>
export type CourseTopicQueryResult = Apollo.QueryResult<
  CourseTopicQuery,
  CourseTopicQueryVariables
>
export const CourseFunctionDocument = gql`
  query CourseFunction($slug: String!) {
    function(slug: $slug) {
      id
      title
      slug
      isDeprecated
      redirectTo
    }
  }
`

/**
 * __useCourseFunctionQuery__
 *
 * To run a query within a React component, call `useCourseFunctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseFunctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseFunctionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseFunctionQuery(
  baseOptions: Apollo.QueryHookOptions<CourseFunctionQuery, CourseFunctionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseFunctionQuery, CourseFunctionQueryVariables>(
    CourseFunctionDocument,
    options
  )
}
export function useCourseFunctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseFunctionQuery,
    CourseFunctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseFunctionQuery, CourseFunctionQueryVariables>(
    CourseFunctionDocument,
    options
  )
}
export type CourseFunctionQueryHookResult = ReturnType<typeof useCourseFunctionQuery>
export type CourseFunctionLazyQueryHookResult = ReturnType<
  typeof useCourseFunctionLazyQuery
>
export type CourseFunctionQueryResult = Apollo.QueryResult<
  CourseFunctionQuery,
  CourseFunctionQueryVariables
>
export const CoursesForTopicAndFunctionDocument = gql`
  query CoursesForTopicAndFunction($slug: String!) {
    coursesForTopicAndFunction(slug: $slug) {
      id
      title
      slug
      shortDescription
      testimonials {
        id
        header
        subheader
        quote
        company {
          name
          logo {
            ...ImageFragment
          }
        }
      }
      creators {
        id
        name
        slug
        company {
          name
        }
        pastCompanies
        avatarPhoto {
          ...ImageFragment
        }
      }
      whatYouWillLearn
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useCoursesForTopicAndFunctionQuery__
 *
 * To run a query within a React component, call `useCoursesForTopicAndFunctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesForTopicAndFunctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesForTopicAndFunctionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCoursesForTopicAndFunctionQuery(
  baseOptions: Apollo.QueryHookOptions<
    CoursesForTopicAndFunctionQuery,
    CoursesForTopicAndFunctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CoursesForTopicAndFunctionQuery,
    CoursesForTopicAndFunctionQueryVariables
  >(CoursesForTopicAndFunctionDocument, options)
}
export function useCoursesForTopicAndFunctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesForTopicAndFunctionQuery,
    CoursesForTopicAndFunctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CoursesForTopicAndFunctionQuery,
    CoursesForTopicAndFunctionQueryVariables
  >(CoursesForTopicAndFunctionDocument, options)
}
export type CoursesForTopicAndFunctionQueryHookResult = ReturnType<
  typeof useCoursesForTopicAndFunctionQuery
>
export type CoursesForTopicAndFunctionLazyQueryHookResult = ReturnType<
  typeof useCoursesForTopicAndFunctionLazyQuery
>
export type CoursesForTopicAndFunctionQueryResult = Apollo.QueryResult<
  CoursesForTopicAndFunctionQuery,
  CoursesForTopicAndFunctionQueryVariables
>
export const TotalCourseCountDocument = gql`
  query TotalCourseCount {
    totalCourseCount
  }
`

/**
 * __useTotalCourseCountQuery__
 *
 * To run a query within a React component, call `useTotalCourseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalCourseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalCourseCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalCourseCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TotalCourseCountQuery,
    TotalCourseCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TotalCourseCountQuery, TotalCourseCountQueryVariables>(
    TotalCourseCountDocument,
    options
  )
}
export function useTotalCourseCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TotalCourseCountQuery,
    TotalCourseCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TotalCourseCountQuery, TotalCourseCountQueryVariables>(
    TotalCourseCountDocument,
    options
  )
}
export type TotalCourseCountQueryHookResult = ReturnType<typeof useTotalCourseCountQuery>
export type TotalCourseCountLazyQueryHookResult = ReturnType<
  typeof useTotalCourseCountLazyQuery
>
export type TotalCourseCountQueryResult = Apollo.QueryResult<
  TotalCourseCountQuery,
  TotalCourseCountQueryVariables
>
export const CourseConceptsDocument = gql`
  query CourseConcepts($slug: String!) {
    courseConcepts(slug: $slug) {
      topics {
        id
        title
        slug
      }
      functions {
        id
        title
        slug
      }
    }
  }
`

/**
 * __useCourseConceptsQuery__
 *
 * To run a query within a React component, call `useCourseConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseConceptsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseConceptsQuery(
  baseOptions: Apollo.QueryHookOptions<CourseConceptsQuery, CourseConceptsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseConceptsQuery, CourseConceptsQueryVariables>(
    CourseConceptsDocument,
    options
  )
}
export function useCourseConceptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseConceptsQuery,
    CourseConceptsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseConceptsQuery, CourseConceptsQueryVariables>(
    CourseConceptsDocument,
    options
  )
}
export type CourseConceptsQueryHookResult = ReturnType<typeof useCourseConceptsQuery>
export type CourseConceptsLazyQueryHookResult = ReturnType<
  typeof useCourseConceptsLazyQuery
>
export type CourseConceptsQueryResult = Apollo.QueryResult<
  CourseConceptsQuery,
  CourseConceptsQueryVariables
>
export const BlogPostsForTopicDocument = gql`
  query BlogPostsForTopic($slug: String!) {
    blogPostsForTopic(slug: $slug) {
      id
      title
      slug
      summaryImage {
        ...ImageFragment
      }
      authors {
        id
        name
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useBlogPostsForTopicQuery__
 *
 * To run a query within a React component, call `useBlogPostsForTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostsForTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostsForTopicQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogPostsForTopicQuery(
  baseOptions: Apollo.QueryHookOptions<
    BlogPostsForTopicQuery,
    BlogPostsForTopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlogPostsForTopicQuery, BlogPostsForTopicQueryVariables>(
    BlogPostsForTopicDocument,
    options
  )
}
export function useBlogPostsForTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogPostsForTopicQuery,
    BlogPostsForTopicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BlogPostsForTopicQuery, BlogPostsForTopicQueryVariables>(
    BlogPostsForTopicDocument,
    options
  )
}
export type BlogPostsForTopicQueryHookResult = ReturnType<
  typeof useBlogPostsForTopicQuery
>
export type BlogPostsForTopicLazyQueryHookResult = ReturnType<
  typeof useBlogPostsForTopicLazyQuery
>
export type BlogPostsForTopicQueryResult = Apollo.QueryResult<
  BlogPostsForTopicQuery,
  BlogPostsForTopicQueryVariables
>
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      id
      ...CurrentUserParts
    }
  }
  ${CurrentUserPartsFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>
export const CurrentUserAvailableTeamsDocument = gql`
  query currentUserAvailableTeams {
    currentUser {
      id
      accessPolicyKind
      activeSubscriptionMembership {
        id
        createdAt
      }
      availableTeamSubscriptions {
        ...AvailableTeamSubscription
      }
      subscriptions {
        active {
          ...AvailableTeamSubscription
        }
      }
    }
  }
  ${AvailableTeamSubscriptionFragmentDoc}
`

/**
 * __useCurrentUserAvailableTeamsQuery__
 *
 * To run a query within a React component, call `useCurrentUserAvailableTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAvailableTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAvailableTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAvailableTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserAvailableTeamsQuery,
    CurrentUserAvailableTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserAvailableTeamsQuery,
    CurrentUserAvailableTeamsQueryVariables
  >(CurrentUserAvailableTeamsDocument, options)
}
export function useCurrentUserAvailableTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserAvailableTeamsQuery,
    CurrentUserAvailableTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserAvailableTeamsQuery,
    CurrentUserAvailableTeamsQueryVariables
  >(CurrentUserAvailableTeamsDocument, options)
}
export type CurrentUserAvailableTeamsQueryHookResult = ReturnType<
  typeof useCurrentUserAvailableTeamsQuery
>
export type CurrentUserAvailableTeamsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAvailableTeamsLazyQuery
>
export type CurrentUserAvailableTeamsQueryResult = Apollo.QueryResult<
  CurrentUserAvailableTeamsQuery,
  CurrentUserAvailableTeamsQueryVariables
>
export const UserCohortsDocument = gql`
  query UserCohorts {
    currentUser {
      ...UserCohortsParts
    }
  }
  ${UserCohortsPartsFragmentDoc}
`

/**
 * __useUserCohortsQuery__
 *
 * To run a query within a React component, call `useUserCohortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCohortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCohortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCohortsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserCohortsQuery, UserCohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCohortsQuery, UserCohortsQueryVariables>(
    UserCohortsDocument,
    options
  )
}
export function useUserCohortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCohortsQuery, UserCohortsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCohortsQuery, UserCohortsQueryVariables>(
    UserCohortsDocument,
    options
  )
}
export type UserCohortsQueryHookResult = ReturnType<typeof useUserCohortsQuery>
export type UserCohortsLazyQueryHookResult = ReturnType<typeof useUserCohortsLazyQuery>
export type UserCohortsQueryResult = Apollo.QueryResult<
  UserCohortsQuery,
  UserCohortsQueryVariables
>
export const CurrentUserMemberAccessDocument = gql`
  query CurrentUserMemberAccess {
    currentUser {
      id
      hasMemberAccess
      hasUseableCohortCredits
      paymentMethods {
        card {
          last4
        }
        defaultPaymentMethod
      }
      subscriptions {
        active {
          planName
          expiresAt
        }
      }
      stripeCustomer {
        id
      }
      is {
        trialing
      }
    }
  }
`

/**
 * __useCurrentUserMemberAccessQuery__
 *
 * To run a query within a React component, call `useCurrentUserMemberAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserMemberAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserMemberAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserMemberAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserMemberAccessQuery,
    CurrentUserMemberAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserMemberAccessQuery,
    CurrentUserMemberAccessQueryVariables
  >(CurrentUserMemberAccessDocument, options)
}
export function useCurrentUserMemberAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserMemberAccessQuery,
    CurrentUserMemberAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserMemberAccessQuery,
    CurrentUserMemberAccessQueryVariables
  >(CurrentUserMemberAccessDocument, options)
}
export type CurrentUserMemberAccessQueryHookResult = ReturnType<
  typeof useCurrentUserMemberAccessQuery
>
export type CurrentUserMemberAccessLazyQueryHookResult = ReturnType<
  typeof useCurrentUserMemberAccessLazyQuery
>
export type CurrentUserMemberAccessQueryResult = Apollo.QueryResult<
  CurrentUserMemberAccessQuery,
  CurrentUserMemberAccessQueryVariables
>
export const CurrentUserPatronageRequestLinkDocument = gql`
  query currentUserPatronageRequestLink {
    currentUser {
      id
      patronageRequestLink
    }
  }
`

/**
 * __useCurrentUserPatronageRequestLinkQuery__
 *
 * To run a query within a React component, call `useCurrentUserPatronageRequestLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPatronageRequestLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPatronageRequestLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPatronageRequestLinkQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserPatronageRequestLinkQuery,
    CurrentUserPatronageRequestLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserPatronageRequestLinkQuery,
    CurrentUserPatronageRequestLinkQueryVariables
  >(CurrentUserPatronageRequestLinkDocument, options)
}
export function useCurrentUserPatronageRequestLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserPatronageRequestLinkQuery,
    CurrentUserPatronageRequestLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserPatronageRequestLinkQuery,
    CurrentUserPatronageRequestLinkQueryVariables
  >(CurrentUserPatronageRequestLinkDocument, options)
}
export type CurrentUserPatronageRequestLinkQueryHookResult = ReturnType<
  typeof useCurrentUserPatronageRequestLinkQuery
>
export type CurrentUserPatronageRequestLinkLazyQueryHookResult = ReturnType<
  typeof useCurrentUserPatronageRequestLinkLazyQuery
>
export type CurrentUserPatronageRequestLinkQueryResult = Apollo.QueryResult<
  CurrentUserPatronageRequestLinkQuery,
  CurrentUserPatronageRequestLinkQueryVariables
>
export const CustomerContactDocument = gql`
  query CustomerContact {
    currentUser {
      id
      stripeCustomer {
        id
        name
        address {
          line1
          line2
          city
          state
          country
          postal_code: postalCode
        }
      }
    }
  }
`

/**
 * __useCustomerContactQuery__
 *
 * To run a query within a React component, call `useCustomerContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerContactQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerContactQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerContactQuery,
    CustomerContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CustomerContactQuery, CustomerContactQueryVariables>(
    CustomerContactDocument,
    options
  )
}
export function useCustomerContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerContactQuery,
    CustomerContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CustomerContactQuery, CustomerContactQueryVariables>(
    CustomerContactDocument,
    options
  )
}
export type CustomerContactQueryHookResult = ReturnType<typeof useCustomerContactQuery>
export type CustomerContactLazyQueryHookResult = ReturnType<
  typeof useCustomerContactLazyQuery
>
export type CustomerContactQueryResult = Apollo.QueryResult<
  CustomerContactQuery,
  CustomerContactQueryVariables
>
export const DunningFlowDocument = gql`
  query DunningFlow {
    currentUser {
      id
      subscriptions {
        active {
          id
          daysLeft
          dunningAmountDueInCents
          expiresAt
          startsAt
          isCanceled
          isDunning
          isExpired
          isPaidMonthly
          isTeam
          lastFailedPaymentAt
          numSeats
          status
          stripeCollectionMethod
          stripeUpcomingInvoice {
            id
            amountDue
            date
            subtotal
          }
          stripeSubscriptionStatus
          userIsOwner
        }
      }
      paymentMethods {
        id
        type
        defaultPaymentMethod
        card {
          brand
          last4
          expYear
          expMonth
          status
        }
      }
    }
  }
`

/**
 * __useDunningFlowQuery__
 *
 * To run a query within a React component, call `useDunningFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useDunningFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDunningFlowQuery({
 *   variables: {
 *   },
 * });
 */
export function useDunningFlowQuery(
  baseOptions?: Apollo.QueryHookOptions<DunningFlowQuery, DunningFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DunningFlowQuery, DunningFlowQueryVariables>(
    DunningFlowDocument,
    options
  )
}
export function useDunningFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DunningFlowQuery, DunningFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DunningFlowQuery, DunningFlowQueryVariables>(
    DunningFlowDocument,
    options
  )
}
export type DunningFlowQueryHookResult = ReturnType<typeof useDunningFlowQuery>
export type DunningFlowLazyQueryHookResult = ReturnType<typeof useDunningFlowLazyQuery>
export type DunningFlowQueryResult = Apollo.QueryResult<
  DunningFlowQuery,
  DunningFlowQueryVariables
>
export const DuplicateAccountsDocument = gql`
  query duplicateAccounts {
    duplicateAccounts {
      id
      email
      createdAt
    }
  }
`

/**
 * __useDuplicateAccountsQuery__
 *
 * To run a query within a React component, call `useDuplicateAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuplicateAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuplicateAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDuplicateAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DuplicateAccountsQuery,
    DuplicateAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DuplicateAccountsQuery, DuplicateAccountsQueryVariables>(
    DuplicateAccountsDocument,
    options
  )
}
export function useDuplicateAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DuplicateAccountsQuery,
    DuplicateAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DuplicateAccountsQuery, DuplicateAccountsQueryVariables>(
    DuplicateAccountsDocument,
    options
  )
}
export type DuplicateAccountsQueryHookResult = ReturnType<
  typeof useDuplicateAccountsQuery
>
export type DuplicateAccountsLazyQueryHookResult = ReturnType<
  typeof useDuplicateAccountsLazyQuery
>
export type DuplicateAccountsQueryResult = Apollo.QueryResult<
  DuplicateAccountsQuery,
  DuplicateAccountsQueryVariables
>
export const EmailTemplateDocument = gql`
  query emailTemplate($templateType: String!) {
    emailTemplate(templateType: $templateType)
  }
`

/**
 * __useEmailTemplateQuery__
 *
 * To run a query within a React component, call `useEmailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplateQuery({
 *   variables: {
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useEmailTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<EmailTemplateQuery, EmailTemplateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(
    EmailTemplateDocument,
    options
  )
}
export function useEmailTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTemplateQuery,
    EmailTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EmailTemplateQuery, EmailTemplateQueryVariables>(
    EmailTemplateDocument,
    options
  )
}
export type EmailTemplateQueryHookResult = ReturnType<typeof useEmailTemplateQuery>
export type EmailTemplateLazyQueryHookResult = ReturnType<
  typeof useEmailTemplateLazyQuery
>
export type EmailTemplateQueryResult = Apollo.QueryResult<
  EmailTemplateQuery,
  EmailTemplateQueryVariables
>
export const EngagementReportSummaryDocument = gql`
  query engagementReportSummary {
    engagementReportSummary {
      liveCourseEnrollmentCount
      coursesWorkedOnCount
      contentViewedCount
      insightsSavedCount
      topCourses {
        id
        imageUrl
        name
        synopsis
        participants {
          id
          avatarUrl
        }
      }
      topLessons {
        id
        name
        programName
        numViews
      }
      recentLessons {
        id
        name
        programName
        lastViewedAt
        numViews
      }
      recentSavedItems {
        id
        cmsSectionName
        updatedAt
      }
    }
  }
`

/**
 * __useEngagementReportSummaryQuery__
 *
 * To run a query within a React component, call `useEngagementReportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementReportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementReportSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useEngagementReportSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EngagementReportSummaryQuery,
    EngagementReportSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EngagementReportSummaryQuery,
    EngagementReportSummaryQueryVariables
  >(EngagementReportSummaryDocument, options)
}
export function useEngagementReportSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementReportSummaryQuery,
    EngagementReportSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EngagementReportSummaryQuery,
    EngagementReportSummaryQueryVariables
  >(EngagementReportSummaryDocument, options)
}
export type EngagementReportSummaryQueryHookResult = ReturnType<
  typeof useEngagementReportSummaryQuery
>
export type EngagementReportSummaryLazyQueryHookResult = ReturnType<
  typeof useEngagementReportSummaryLazyQuery
>
export type EngagementReportSummaryQueryResult = Apollo.QueryResult<
  EngagementReportSummaryQuery,
  EngagementReportSummaryQueryVariables
>
export const EngagementReportTeamDocument = gql`
  query engagementReportTeam {
    engagementReportTeam {
      teamMembers {
        id
        fullName
        avatarUrl
        currentCourseName
        coursesWorkedOn
        coursesWorkedOnCount
        liveCoursesTaken
        liveCoursesTakenCount
        contentViewed
        contentViewedCount
        insightsSaved
        insightsSavedCount
      }
    }
  }
`

/**
 * __useEngagementReportTeamQuery__
 *
 * To run a query within a React component, call `useEngagementReportTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useEngagementReportTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEngagementReportTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useEngagementReportTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EngagementReportTeamQuery,
    EngagementReportTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EngagementReportTeamQuery, EngagementReportTeamQueryVariables>(
    EngagementReportTeamDocument,
    options
  )
}
export function useEngagementReportTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EngagementReportTeamQuery,
    EngagementReportTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EngagementReportTeamQuery,
    EngagementReportTeamQueryVariables
  >(EngagementReportTeamDocument, options)
}
export type EngagementReportTeamQueryHookResult = ReturnType<
  typeof useEngagementReportTeamQuery
>
export type EngagementReportTeamLazyQueryHookResult = ReturnType<
  typeof useEngagementReportTeamLazyQuery
>
export type EngagementReportTeamQueryResult = Apollo.QueryResult<
  EngagementReportTeamQuery,
  EngagementReportTeamQueryVariables
>
export const GetOnboardingEnrollmentInfoDocument = gql`
  query GetOnboardingEnrollmentInfo {
    currentUser {
      id
      ...OnboardingModalCurrentUserParts
    }
    enrollmentSeason {
      id
      year
      name
    }
    lastActiveSeason {
      id
    }
    upcomingSeason {
      id
    }
  }
  ${OnboardingModalCurrentUserPartsFragmentDoc}
`

/**
 * __useGetOnboardingEnrollmentInfoQuery__
 *
 * To run a query within a React component, call `useGetOnboardingEnrollmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingEnrollmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingEnrollmentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingEnrollmentInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOnboardingEnrollmentInfoQuery,
    GetOnboardingEnrollmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnboardingEnrollmentInfoQuery,
    GetOnboardingEnrollmentInfoQueryVariables
  >(GetOnboardingEnrollmentInfoDocument, options)
}
export function useGetOnboardingEnrollmentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnboardingEnrollmentInfoQuery,
    GetOnboardingEnrollmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnboardingEnrollmentInfoQuery,
    GetOnboardingEnrollmentInfoQueryVariables
  >(GetOnboardingEnrollmentInfoDocument, options)
}
export type GetOnboardingEnrollmentInfoQueryHookResult = ReturnType<
  typeof useGetOnboardingEnrollmentInfoQuery
>
export type GetOnboardingEnrollmentInfoLazyQueryHookResult = ReturnType<
  typeof useGetOnboardingEnrollmentInfoLazyQuery
>
export type GetOnboardingEnrollmentInfoQueryResult = Apollo.QueryResult<
  GetOnboardingEnrollmentInfoQuery,
  GetOnboardingEnrollmentInfoQueryVariables
>
export const GetProgramSelectorInfoDocument = gql`
  query GetProgramSelectorInfo {
    lastActiveSeason {
      id
      enrollmentsCloseAt
      hasStarted
      prettyNameSeasonFirst
      name
      nextSeasonName
      openForEnrollment
      startsAt
      year
    }
    upcomingSeason {
      hasStarted
      prettyNameSeasonFirst
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      id
      name
      nextSeasonName
      openForEnrollment
      startsAt
      year
      faqs {
        seasonId
        question
        answer
        questionNumber
      }
    }
    enrollmentSeason {
      hasStarted
      prettyNameSeasonFirst
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      id
      name
      nextSeasonName
      openForEnrollment
      startsAt
      year
      showCohortViewerAt
      faqs {
        seasonId
        question
        answer
        questionNumber
      }
    }
    currentUser {
      id
      ...ProgramSelectorCurrentUser
    }
  }
  ${ProgramSelectorCurrentUserFragmentDoc}
`

/**
 * __useGetProgramSelectorInfoQuery__
 *
 * To run a query within a React component, call `useGetProgramSelectorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramSelectorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramSelectorInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgramSelectorInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProgramSelectorInfoQuery,
    GetProgramSelectorInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProgramSelectorInfoQuery,
    GetProgramSelectorInfoQueryVariables
  >(GetProgramSelectorInfoDocument, options)
}
export function useGetProgramSelectorInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProgramSelectorInfoQuery,
    GetProgramSelectorInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProgramSelectorInfoQuery,
    GetProgramSelectorInfoQueryVariables
  >(GetProgramSelectorInfoDocument, options)
}
export type GetProgramSelectorInfoQueryHookResult = ReturnType<
  typeof useGetProgramSelectorInfoQuery
>
export type GetProgramSelectorInfoLazyQueryHookResult = ReturnType<
  typeof useGetProgramSelectorInfoLazyQuery
>
export type GetProgramSelectorInfoQueryResult = Apollo.QueryResult<
  GetProgramSelectorInfoQuery,
  GetProgramSelectorInfoQueryVariables
>
export const ModifyEnrollmentInfoDocument = gql`
  query ModifyEnrollmentInfo {
    currentUser {
      id
      is {
        member
      }
      cohorts {
        latestCurrent {
          id
          programId
          programName
        }
      }
    }
  }
`

/**
 * __useModifyEnrollmentInfoQuery__
 *
 * To run a query within a React component, call `useModifyEnrollmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModifyEnrollmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModifyEnrollmentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useModifyEnrollmentInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModifyEnrollmentInfoQuery,
    ModifyEnrollmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModifyEnrollmentInfoQuery, ModifyEnrollmentInfoQueryVariables>(
    ModifyEnrollmentInfoDocument,
    options
  )
}
export function useModifyEnrollmentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModifyEnrollmentInfoQuery,
    ModifyEnrollmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModifyEnrollmentInfoQuery,
    ModifyEnrollmentInfoQueryVariables
  >(ModifyEnrollmentInfoDocument, options)
}
export type ModifyEnrollmentInfoQueryHookResult = ReturnType<
  typeof useModifyEnrollmentInfoQuery
>
export type ModifyEnrollmentInfoLazyQueryHookResult = ReturnType<
  typeof useModifyEnrollmentInfoLazyQuery
>
export type ModifyEnrollmentInfoQueryResult = Apollo.QueryResult<
  ModifyEnrollmentInfoQuery,
  ModifyEnrollmentInfoQueryVariables
>
export const GetEnrollmentSeasonDocument = gql`
  query GetEnrollmentSeason {
    enrollmentSeason {
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      id
      name
      hasStarted
      prettyNameSeasonFirst
      nextSeasonName
      openForEnrollment
      showCohortViewerAt
      startsAt
      year
    }
  }
`

/**
 * __useGetEnrollmentSeasonQuery__
 *
 * To run a query within a React component, call `useGetEnrollmentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnrollmentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnrollmentSeasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnrollmentSeasonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEnrollmentSeasonQuery,
    GetEnrollmentSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEnrollmentSeasonQuery, GetEnrollmentSeasonQueryVariables>(
    GetEnrollmentSeasonDocument,
    options
  )
}
export function useGetEnrollmentSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnrollmentSeasonQuery,
    GetEnrollmentSeasonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEnrollmentSeasonQuery, GetEnrollmentSeasonQueryVariables>(
    GetEnrollmentSeasonDocument,
    options
  )
}
export type GetEnrollmentSeasonQueryHookResult = ReturnType<
  typeof useGetEnrollmentSeasonQuery
>
export type GetEnrollmentSeasonLazyQueryHookResult = ReturnType<
  typeof useGetEnrollmentSeasonLazyQuery
>
export type GetEnrollmentSeasonQueryResult = Apollo.QueryResult<
  GetEnrollmentSeasonQuery,
  GetEnrollmentSeasonQueryVariables
>
export const PublicEventsDocument = gql`
  query PublicEvents {
    publicEvents {
      ...EventParts
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __usePublicEventsQuery__
 *
 * To run a query within a React component, call `usePublicEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<PublicEventsQuery, PublicEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PublicEventsQuery, PublicEventsQueryVariables>(
    PublicEventsDocument,
    options
  )
}
export function usePublicEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicEventsQuery, PublicEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PublicEventsQuery, PublicEventsQueryVariables>(
    PublicEventsDocument,
    options
  )
}
export type PublicEventsQueryHookResult = ReturnType<typeof usePublicEventsQuery>
export type PublicEventsLazyQueryHookResult = ReturnType<typeof usePublicEventsLazyQuery>
export type PublicEventsQueryResult = Apollo.QueryResult<
  PublicEventsQuery,
  PublicEventsQueryVariables
>
export const UserEventsDocument = gql`
  query UserEvents($userId: ID!) {
    user(id: $userId) {
      id
      ...EventCurrentUserParts
      enrolledCohortEvents {
        id
        ...EventParts
      }
      eventsAttending {
        id
        ...EventParts
      }
      eventsNotAttending {
        id
        ...EventParts
      }
      eventsRecommended {
        id
        ...EventParts
      }
      eventsRecentlyInteracted {
        id
        ...EventParts
      }
      eventsHosted {
        id
        ...EventParts
      }
      upcomingEligibleEvents {
        id
        ...EventParts
      }
    }
    currentUser {
      id
      ...EventCurrentUserParts
    }
  }
  ${EventCurrentUserPartsFragmentDoc}
  ${EventPartsFragmentDoc}
`

/**
 * __useUserEventsQuery__
 *
 * To run a query within a React component, call `useUserEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserEventsQuery(
  baseOptions: Apollo.QueryHookOptions<UserEventsQuery, UserEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEventsQuery, UserEventsQueryVariables>(
    UserEventsDocument,
    options
  )
}
export function useUserEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserEventsQuery, UserEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEventsQuery, UserEventsQueryVariables>(
    UserEventsDocument,
    options
  )
}
export type UserEventsQueryHookResult = ReturnType<typeof useUserEventsQuery>
export type UserEventsLazyQueryHookResult = ReturnType<typeof useUserEventsLazyQuery>
export type UserEventsQueryResult = Apollo.QueryResult<
  UserEventsQuery,
  UserEventsQueryVariables
>
export const UserStartingSoonEventsDocument = gql`
  query UserStartingSoonEvents {
    currentUser {
      id
      eventsAttendingStartingSoon {
        ...EventParts
      }
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __useUserStartingSoonEventsQuery__
 *
 * To run a query within a React component, call `useUserStartingSoonEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStartingSoonEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStartingSoonEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStartingSoonEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserStartingSoonEventsQuery,
    UserStartingSoonEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserStartingSoonEventsQuery,
    UserStartingSoonEventsQueryVariables
  >(UserStartingSoonEventsDocument, options)
}
export function useUserStartingSoonEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserStartingSoonEventsQuery,
    UserStartingSoonEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserStartingSoonEventsQuery,
    UserStartingSoonEventsQueryVariables
  >(UserStartingSoonEventsDocument, options)
}
export type UserStartingSoonEventsQueryHookResult = ReturnType<
  typeof useUserStartingSoonEventsQuery
>
export type UserStartingSoonEventsLazyQueryHookResult = ReturnType<
  typeof useUserStartingSoonEventsLazyQuery
>
export type UserStartingSoonEventsQueryResult = Apollo.QueryResult<
  UserStartingSoonEventsQuery,
  UserStartingSoonEventsQueryVariables
>
export const EventExpertHostsDocument = gql`
  query EventExpertHosts {
    eventExpertHosts {
      id
      ...EventExpertHostsParts
    }
  }
  ${EventExpertHostsPartsFragmentDoc}
`

/**
 * __useEventExpertHostsQuery__
 *
 * To run a query within a React component, call `useEventExpertHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventExpertHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventExpertHostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventExpertHostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventExpertHostsQuery,
    EventExpertHostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventExpertHostsQuery, EventExpertHostsQueryVariables>(
    EventExpertHostsDocument,
    options
  )
}
export function useEventExpertHostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventExpertHostsQuery,
    EventExpertHostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventExpertHostsQuery, EventExpertHostsQueryVariables>(
    EventExpertHostsDocument,
    options
  )
}
export type EventExpertHostsQueryHookResult = ReturnType<typeof useEventExpertHostsQuery>
export type EventExpertHostsLazyQueryHookResult = ReturnType<
  typeof useEventExpertHostsLazyQuery
>
export type EventExpertHostsQueryResult = Apollo.QueryResult<
  EventExpertHostsQuery,
  EventExpertHostsQueryVariables
>
export const ExpertHostFeaturedEventsDocument = gql`
  query ExpertHostFeaturedEvents($userId: ID!) {
    expertHostFeaturedEvents(userId: $userId) {
      id
      ...EventParts
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __useExpertHostFeaturedEventsQuery__
 *
 * To run a query within a React component, call `useExpertHostFeaturedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertHostFeaturedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertHostFeaturedEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useExpertHostFeaturedEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExpertHostFeaturedEventsQuery,
    ExpertHostFeaturedEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ExpertHostFeaturedEventsQuery,
    ExpertHostFeaturedEventsQueryVariables
  >(ExpertHostFeaturedEventsDocument, options)
}
export function useExpertHostFeaturedEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExpertHostFeaturedEventsQuery,
    ExpertHostFeaturedEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ExpertHostFeaturedEventsQuery,
    ExpertHostFeaturedEventsQueryVariables
  >(ExpertHostFeaturedEventsDocument, options)
}
export type ExpertHostFeaturedEventsQueryHookResult = ReturnType<
  typeof useExpertHostFeaturedEventsQuery
>
export type ExpertHostFeaturedEventsLazyQueryHookResult = ReturnType<
  typeof useExpertHostFeaturedEventsLazyQuery
>
export type ExpertHostFeaturedEventsQueryResult = Apollo.QueryResult<
  ExpertHostFeaturedEventsQuery,
  ExpertHostFeaturedEventsQueryVariables
>
export const EventsPastFeedDocument = gql`
  query EventsPastFeed($userId: ID, $limit: Int, $offset: Int, $filters: EventFilters) {
    eventsPastFeed(userId: $userId, limit: $limit, offset: $offset, filters: $filters) {
      count
      events {
        id
        ...EventParts
      }
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __useEventsPastFeedQuery__
 *
 * To run a query within a React component, call `useEventsPastFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsPastFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsPastFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEventsPastFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsPastFeedQuery, EventsPastFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsPastFeedQuery, EventsPastFeedQueryVariables>(
    EventsPastFeedDocument,
    options
  )
}
export function useEventsPastFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsPastFeedQuery,
    EventsPastFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsPastFeedQuery, EventsPastFeedQueryVariables>(
    EventsPastFeedDocument,
    options
  )
}
export type EventsPastFeedQueryHookResult = ReturnType<typeof useEventsPastFeedQuery>
export type EventsPastFeedLazyQueryHookResult = ReturnType<
  typeof useEventsPastFeedLazyQuery
>
export type EventsPastFeedQueryResult = Apollo.QueryResult<
  EventsPastFeedQuery,
  EventsPastFeedQueryVariables
>
export const UserEventsPastFeedDocument = gql`
  query UserEventsPastFeed($limit: Int, $offset: Int, $filters: EventFilters) {
    userEventsPastFeed(limit: $limit, offset: $offset, filters: $filters) {
      count
      events {
        id
        ...EventParts
      }
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __useUserEventsPastFeedQuery__
 *
 * To run a query within a React component, call `useUserEventsPastFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventsPastFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventsPastFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUserEventsPastFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserEventsPastFeedQuery,
    UserEventsPastFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEventsPastFeedQuery, UserEventsPastFeedQueryVariables>(
    UserEventsPastFeedDocument,
    options
  )
}
export function useUserEventsPastFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEventsPastFeedQuery,
    UserEventsPastFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEventsPastFeedQuery, UserEventsPastFeedQueryVariables>(
    UserEventsPastFeedDocument,
    options
  )
}
export type UserEventsPastFeedQueryHookResult = ReturnType<
  typeof useUserEventsPastFeedQuery
>
export type UserEventsPastFeedLazyQueryHookResult = ReturnType<
  typeof useUserEventsPastFeedLazyQuery
>
export type UserEventsPastFeedQueryResult = Apollo.QueryResult<
  UserEventsPastFeedQuery,
  UserEventsPastFeedQueryVariables
>
export const PopularEventsPastFeedDocument = gql`
  query PopularEventsPastFeed {
    popularEventsPastFeed {
      events {
        id
        ...EventParts
      }
    }
  }
  ${EventPartsFragmentDoc}
`

/**
 * __usePopularEventsPastFeedQuery__
 *
 * To run a query within a React component, call `usePopularEventsPastFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularEventsPastFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularEventsPastFeedQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularEventsPastFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularEventsPastFeedQuery,
    PopularEventsPastFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PopularEventsPastFeedQuery, PopularEventsPastFeedQueryVariables>(
    PopularEventsPastFeedDocument,
    options
  )
}
export function usePopularEventsPastFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularEventsPastFeedQuery,
    PopularEventsPastFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PopularEventsPastFeedQuery,
    PopularEventsPastFeedQueryVariables
  >(PopularEventsPastFeedDocument, options)
}
export type PopularEventsPastFeedQueryHookResult = ReturnType<
  typeof usePopularEventsPastFeedQuery
>
export type PopularEventsPastFeedLazyQueryHookResult = ReturnType<
  typeof usePopularEventsPastFeedLazyQuery
>
export type PopularEventsPastFeedQueryResult = Apollo.QueryResult<
  PopularEventsPastFeedQuery,
  PopularEventsPastFeedQueryVariables
>
export const EventDocument = gql`
  query Event($eventId: ID, $eventUuid: String) {
    event(eventId: $eventId, eventUuid: $eventUuid) {
      id
      ...EventParts
      prep
      description
      eventFormat
      attendeesCount
      attendingStatus
      googleCalendarEndsAt
      googleCalendarStartsAt
      isFeaturedGuest
      recordingUrl
      summaryTranscript
      timeUntil
      zoomLink
      lastViewedAt
      kind
      preread
      isPublic
      caseCompany {
        id
        name
      }
      eventPrework {
        id
        applicationOpen
        includeTemplateLink
        templateLinkUrl
        templateLinkCTA
        instructions
        helperText
        submissionTitle
        submissionDescription
        submissionPrompt
        preworkContext
        featuredGuestSlots
      }
      eventPreworkSubmission {
        status
      }
      rsvp {
        id
        ...EventAttendeeParts
      }
      attendees(first: 4, hasAvatar: true) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            ...EventAttendeeParts
          }
        }
      }
    }
  }
  ${EventPartsFragmentDoc}
  ${EventAttendeePartsFragmentDoc}
`

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventUuid: // value for 'eventUuid'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions?: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options)
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options)
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>
export const EventAttendeesDocument = gql`
  query EventAttendees($eventId: ID, $eventUuid: String, $first: Int, $after: String) {
    event(eventId: $eventId, eventUuid: $eventUuid) {
      id
      attendees(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            id
            ...EventAttendeeParts
          }
        }
      }
    }
  }
  ${EventAttendeePartsFragmentDoc}
`

/**
 * __useEventAttendeesQuery__
 *
 * To run a query within a React component, call `useEventAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventAttendeesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventUuid: // value for 'eventUuid'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useEventAttendeesQuery(
  baseOptions?: Apollo.QueryHookOptions<EventAttendeesQuery, EventAttendeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventAttendeesQuery, EventAttendeesQueryVariables>(
    EventAttendeesDocument,
    options
  )
}
export function useEventAttendeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventAttendeesQuery,
    EventAttendeesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventAttendeesQuery, EventAttendeesQueryVariables>(
    EventAttendeesDocument,
    options
  )
}
export type EventAttendeesQueryHookResult = ReturnType<typeof useEventAttendeesQuery>
export type EventAttendeesLazyQueryHookResult = ReturnType<
  typeof useEventAttendeesLazyQuery
>
export type EventAttendeesQueryResult = Apollo.QueryResult<
  EventAttendeesQuery,
  EventAttendeesQueryVariables
>
export const AttendeesFeedDocument = gql`
  query AttendeesFeed($eventId: ID, $limit: Int, $offset: Int, $search: String) {
    attendeesFeed(eventId: $eventId, limit: $limit, offset: $offset, search: $search) {
      count
      totalCount
      attendees {
        id
        ...EventAttendeeParts
      }
    }
  }
  ${EventAttendeePartsFragmentDoc}
`

/**
 * __useAttendeesFeedQuery__
 *
 * To run a query within a React component, call `useAttendeesFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesFeedQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAttendeesFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<AttendeesFeedQuery, AttendeesFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AttendeesFeedQuery, AttendeesFeedQueryVariables>(
    AttendeesFeedDocument,
    options
  )
}
export function useAttendeesFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AttendeesFeedQuery,
    AttendeesFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AttendeesFeedQuery, AttendeesFeedQueryVariables>(
    AttendeesFeedDocument,
    options
  )
}
export type AttendeesFeedQueryHookResult = ReturnType<typeof useAttendeesFeedQuery>
export type AttendeesFeedLazyQueryHookResult = ReturnType<
  typeof useAttendeesFeedLazyQuery
>
export type AttendeesFeedQueryResult = Apollo.QueryResult<
  AttendeesFeedQuery,
  AttendeesFeedQueryVariables
>
export const EventsByIdsDocument = gql`
  query eventsByIds($ids: [ID!]) {
    eventsByIds(ids: $ids) {
      id
      ...CohortEventCardParts
    }
  }
  ${CohortEventCardPartsFragmentDoc}
`

/**
 * __useEventsByIdsQuery__
 *
 * To run a query within a React component, call `useEventsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useEventsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsByIdsQuery, EventsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsByIdsQuery, EventsByIdsQueryVariables>(
    EventsByIdsDocument,
    options
  )
}
export function useEventsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsByIdsQuery, EventsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsByIdsQuery, EventsByIdsQueryVariables>(
    EventsByIdsDocument,
    options
  )
}
export type EventsByIdsQueryHookResult = ReturnType<typeof useEventsByIdsQuery>
export type EventsByIdsLazyQueryHookResult = ReturnType<typeof useEventsByIdsLazyQuery>
export type EventsByIdsQueryResult = Apollo.QueryResult<
  EventsByIdsQuery,
  EventsByIdsQueryVariables
>
export const EventVideoRecordingDocument = gql`
  query EventVideoRecording($eventId: ID!) {
    event(eventId: $eventId) {
      id
      summaryWistiaCode
      summaryTranscript
    }
  }
`

/**
 * __useEventVideoRecordingQuery__
 *
 * To run a query within a React component, call `useEventVideoRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventVideoRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventVideoRecordingQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventVideoRecordingQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventVideoRecordingQuery,
    EventVideoRecordingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventVideoRecordingQuery, EventVideoRecordingQueryVariables>(
    EventVideoRecordingDocument,
    options
  )
}
export function useEventVideoRecordingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventVideoRecordingQuery,
    EventVideoRecordingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventVideoRecordingQuery, EventVideoRecordingQueryVariables>(
    EventVideoRecordingDocument,
    options
  )
}
export type EventVideoRecordingQueryHookResult = ReturnType<
  typeof useEventVideoRecordingQuery
>
export type EventVideoRecordingLazyQueryHookResult = ReturnType<
  typeof useEventVideoRecordingLazyQuery
>
export type EventVideoRecordingQueryResult = Apollo.QueryResult<
  EventVideoRecordingQuery,
  EventVideoRecordingQueryVariables
>
export const EventDetailsRsvpDocument = gql`
  query EventDetailsRsvp($eventId: ID!) {
    event(eventId: $eventId) {
      id
      attendeesCount
      attendees(first: 3, hasAvatar: true) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            ...EventAttendeeParts
          }
        }
      }
      rsvp {
        id
        status
      }
    }
  }
  ${EventAttendeePartsFragmentDoc}
`

/**
 * __useEventDetailsRsvpQuery__
 *
 * To run a query within a React component, call `useEventDetailsRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsRsvpQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventDetailsRsvpQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailsRsvpQuery,
    EventDetailsRsvpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventDetailsRsvpQuery, EventDetailsRsvpQueryVariables>(
    EventDetailsRsvpDocument,
    options
  )
}
export function useEventDetailsRsvpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailsRsvpQuery,
    EventDetailsRsvpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventDetailsRsvpQuery, EventDetailsRsvpQueryVariables>(
    EventDetailsRsvpDocument,
    options
  )
}
export type EventDetailsRsvpQueryHookResult = ReturnType<typeof useEventDetailsRsvpQuery>
export type EventDetailsRsvpLazyQueryHookResult = ReturnType<
  typeof useEventDetailsRsvpLazyQuery
>
export type EventDetailsRsvpQueryResult = Apollo.QueryResult<
  EventDetailsRsvpQuery,
  EventDetailsRsvpQueryVariables
>
export const EventRsvpDocument = gql`
  query EventRsvp($eventId: ID!) {
    eventRsvp(eventId: $eventId) {
      status
    }
  }
`

/**
 * __useEventRsvpQuery__
 *
 * To run a query within a React component, call `useEventRsvpQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventRsvpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventRsvpQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventRsvpQuery(
  baseOptions: Apollo.QueryHookOptions<EventRsvpQuery, EventRsvpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventRsvpQuery, EventRsvpQueryVariables>(
    EventRsvpDocument,
    options
  )
}
export function useEventRsvpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventRsvpQuery, EventRsvpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventRsvpQuery, EventRsvpQueryVariables>(
    EventRsvpDocument,
    options
  )
}
export type EventRsvpQueryHookResult = ReturnType<typeof useEventRsvpQuery>
export type EventRsvpLazyQueryHookResult = ReturnType<typeof useEventRsvpLazyQuery>
export type EventRsvpQueryResult = Apollo.QueryResult<
  EventRsvpQuery,
  EventRsvpQueryVariables
>
export const RsvpDocument = gql`
  mutation Rsvp($input: CreateInput!) {
    rsvp(input: $input) {
      attendee {
        status
      }
      clientMutationId
      errors
    }
  }
`
export type RsvpMutationFn = Apollo.MutationFunction<RsvpMutation, RsvpMutationVariables>

/**
 * __useRsvpMutation__
 *
 * To run a mutation, you first call `useRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rsvpMutation, { data, loading, error }] = useRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRsvpMutation(
  baseOptions?: Apollo.MutationHookOptions<RsvpMutation, RsvpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RsvpMutation, RsvpMutationVariables>(RsvpDocument, options)
}
export type RsvpMutationHookResult = ReturnType<typeof useRsvpMutation>
export type RsvpMutationResult = Apollo.MutationResult<RsvpMutation>
export type RsvpMutationOptions = Apollo.BaseMutationOptions<
  RsvpMutation,
  RsvpMutationVariables
>
export const CancelRsvpDocument = gql`
  mutation CancelRsvp($input: CancelInput!) {
    cancelRsvp(input: $input) {
      clientMutationId
      errors
    }
  }
`
export type CancelRsvpMutationFn = Apollo.MutationFunction<
  CancelRsvpMutation,
  CancelRsvpMutationVariables
>

/**
 * __useCancelRsvpMutation__
 *
 * To run a mutation, you first call `useCancelRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRsvpMutation, { data, loading, error }] = useCancelRsvpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelRsvpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRsvpMutation,
    CancelRsvpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelRsvpMutation, CancelRsvpMutationVariables>(
    CancelRsvpDocument,
    options
  )
}
export type CancelRsvpMutationHookResult = ReturnType<typeof useCancelRsvpMutation>
export type CancelRsvpMutationResult = Apollo.MutationResult<CancelRsvpMutation>
export type CancelRsvpMutationOptions = Apollo.BaseMutationOptions<
  CancelRsvpMutation,
  CancelRsvpMutationVariables
>
export const NotAttendingDocument = gql`
  mutation NotAttending($input: NotAttendingInput!) {
    notAttending(input: $input) {
      attendee {
        avatarUrl
        hasBadge
        id
        kind
        status
      }
      clientMutationId
      errors
    }
  }
`
export type NotAttendingMutationFn = Apollo.MutationFunction<
  NotAttendingMutation,
  NotAttendingMutationVariables
>

/**
 * __useNotAttendingMutation__
 *
 * To run a mutation, you first call `useNotAttendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotAttendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notAttendingMutation, { data, loading, error }] = useNotAttendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotAttendingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotAttendingMutation,
    NotAttendingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<NotAttendingMutation, NotAttendingMutationVariables>(
    NotAttendingDocument,
    options
  )
}
export type NotAttendingMutationHookResult = ReturnType<typeof useNotAttendingMutation>
export type NotAttendingMutationResult = Apollo.MutationResult<NotAttendingMutation>
export type NotAttendingMutationOptions = Apollo.BaseMutationOptions<
  NotAttendingMutation,
  NotAttendingMutationVariables
>
export const EventCountdownPageDocument = gql`
  query EventCountdownPage($eventUuid: String) {
    event(eventUuid: $eventUuid) {
      ...EventCountdownPageEventParts
    }
  }
  ${EventCountdownPageEventPartsFragmentDoc}
`

/**
 * __useEventCountdownPageQuery__
 *
 * To run a query within a React component, call `useEventCountdownPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventCountdownPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCountdownPageQuery({
 *   variables: {
 *      eventUuid: // value for 'eventUuid'
 *   },
 * });
 */
export function useEventCountdownPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventCountdownPageQuery,
    EventCountdownPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventCountdownPageQuery, EventCountdownPageQueryVariables>(
    EventCountdownPageDocument,
    options
  )
}
export function useEventCountdownPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventCountdownPageQuery,
    EventCountdownPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventCountdownPageQuery, EventCountdownPageQueryVariables>(
    EventCountdownPageDocument,
    options
  )
}
export type EventCountdownPageQueryHookResult = ReturnType<
  typeof useEventCountdownPageQuery
>
export type EventCountdownPageLazyQueryHookResult = ReturnType<
  typeof useEventCountdownPageLazyQuery
>
export type EventCountdownPageQueryResult = Apollo.QueryResult<
  EventCountdownPageQuery,
  EventCountdownPageQueryVariables
>
export const EventRelatedResourcesDocument = gql`
  query EventRelatedResources($eventId: ID!) {
    eventRelatedResources(eventId: $eventId) {
      ... on CclCourse {
        ...CclCourseCourseCardParts
      }
      ... on MarketingCourse {
        id
        marketingCourseTitle: title
        marketingCourseSlug: slug
        startDate
        subtitle
        duration
        courseType
        creators {
          id
          role
          expert {
            id
            name
            slug
            avatarPhoto {
              ...ImageFragment
            }
          }
        }
        cclCourse {
          ...CclCourseCourseCardParts
        }
        course {
          id
          title
          shortDescription
          isAvailableOnDemand
          slug
          creators {
            id
            name
            avatarPhoto {
              imageUrl
              imageAlt
              aspectRatio
            }
          }
        }
      }
      ... on Artifact {
        id
        artifactTitle: title
        artifactSlug: slug
        companyName
        companyLogoUrl
        thumbnailUrl
        description
        viewCount
        commentCount
        authors {
          id
          slug
          name
          position
          companyName
          avatarPhoto {
            imageUrl
            imageAlt
            aspectRatio
          }
        }
      }
    }
  }
  ${CclCourseCourseCardPartsFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useEventRelatedResourcesQuery__
 *
 * To run a query within a React component, call `useEventRelatedResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventRelatedResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventRelatedResourcesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventRelatedResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventRelatedResourcesQuery,
    EventRelatedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventRelatedResourcesQuery, EventRelatedResourcesQueryVariables>(
    EventRelatedResourcesDocument,
    options
  )
}
export function useEventRelatedResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventRelatedResourcesQuery,
    EventRelatedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EventRelatedResourcesQuery,
    EventRelatedResourcesQueryVariables
  >(EventRelatedResourcesDocument, options)
}
export type EventRelatedResourcesQueryHookResult = ReturnType<
  typeof useEventRelatedResourcesQuery
>
export type EventRelatedResourcesLazyQueryHookResult = ReturnType<
  typeof useEventRelatedResourcesLazyQuery
>
export type EventRelatedResourcesQueryResult = Apollo.QueryResult<
  EventRelatedResourcesQuery,
  EventRelatedResourcesQueryVariables
>
export const FeaturedPostsDocument = gql`
  query FeaturedPosts {
    featuredPosts {
      id
      kind
      startsAt
      post {
        allReactionsCount
        createdAt
        href
        id
        responseCount
        title
        user {
          id
          firstName
          lastName
          role
          companyName
        }
      }
    }
  }
`

/**
 * __useFeaturedPostsQuery__
 *
 * To run a query within a React component, call `useFeaturedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<FeaturedPostsQuery, FeaturedPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FeaturedPostsQuery, FeaturedPostsQueryVariables>(
    FeaturedPostsDocument,
    options
  )
}
export function useFeaturedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeaturedPostsQuery,
    FeaturedPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FeaturedPostsQuery, FeaturedPostsQueryVariables>(
    FeaturedPostsDocument,
    options
  )
}
export type FeaturedPostsQueryHookResult = ReturnType<typeof useFeaturedPostsQuery>
export type FeaturedPostsLazyQueryHookResult = ReturnType<
  typeof useFeaturedPostsLazyQuery
>
export type FeaturedPostsQueryResult = Apollo.QueryResult<
  FeaturedPostsQuery,
  FeaturedPostsQueryVariables
>
export const FilteredUserSearchDocument = gql`
  query FilteredUserSearch($filters: UserSearchFilters, $page: Int) {
    filteredUsers(filters: $filters, page: $page) {
      members {
        id
        ...FilteredUserSearchFields
      }
      pagination {
        ...PaginationFields
      }
    }
    userCohorts: userCohorts(descendingByStartDate: true) {
      ...MembersUserCohortPart
    }
  }
  ${FilteredUserSearchFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
  ${MembersUserCohortPartFragmentDoc}
`

/**
 * __useFilteredUserSearchQuery__
 *
 * To run a query within a React component, call `useFilteredUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredUserSearchQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFilteredUserSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FilteredUserSearchQuery,
    FilteredUserSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FilteredUserSearchQuery, FilteredUserSearchQueryVariables>(
    FilteredUserSearchDocument,
    options
  )
}
export function useFilteredUserSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredUserSearchQuery,
    FilteredUserSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FilteredUserSearchQuery, FilteredUserSearchQueryVariables>(
    FilteredUserSearchDocument,
    options
  )
}
export type FilteredUserSearchQueryHookResult = ReturnType<
  typeof useFilteredUserSearchQuery
>
export type FilteredUserSearchLazyQueryHookResult = ReturnType<
  typeof useFilteredUserSearchLazyQuery
>
export type FilteredUserSearchQueryResult = Apollo.QueryResult<
  FilteredUserSearchQuery,
  FilteredUserSearchQueryVariables
>
export const FindBookmarkByIdDocument = gql`
  query FindBookmarkById($bookmarkId: ID!) {
    findBookmarkById(bookmarkId: $bookmarkId) {
      id
      ...ProgramBookmarkParts
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`

/**
 * __useFindBookmarkByIdQuery__
 *
 * To run a query within a React component, call `useFindBookmarkByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBookmarkByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBookmarkByIdQuery({
 *   variables: {
 *      bookmarkId: // value for 'bookmarkId'
 *   },
 * });
 */
export function useFindBookmarkByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindBookmarkByIdQuery,
    FindBookmarkByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FindBookmarkByIdQuery, FindBookmarkByIdQueryVariables>(
    FindBookmarkByIdDocument,
    options
  )
}
export function useFindBookmarkByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindBookmarkByIdQuery,
    FindBookmarkByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FindBookmarkByIdQuery, FindBookmarkByIdQueryVariables>(
    FindBookmarkByIdDocument,
    options
  )
}
export type FindBookmarkByIdQueryHookResult = ReturnType<typeof useFindBookmarkByIdQuery>
export type FindBookmarkByIdLazyQueryHookResult = ReturnType<
  typeof useFindBookmarkByIdLazyQuery
>
export type FindBookmarkByIdQueryResult = Apollo.QueryResult<
  FindBookmarkByIdQuery,
  FindBookmarkByIdQueryVariables
>
export const GroupMembersDocument = gql`
  query GroupMembers($slug: String!, $page: Int, $perPage: Int, $sortBy: String) {
    groupMembers(slug: $slug, sortBy: $sortBy, page: $page, perPage: $perPage) {
      members {
        avatarUrl
        companyAudience
        id
        createdAt
        companyName
        fullName
        hasBadge
        isExpired
        kind
        location
        mostRecentProgram
        role
        slug
        status
      }
      pagination {
        currentPage
        nextPage
        previousPage
        totalEntries
        totalPages
      }
    }
  }
`

/**
 * __useGroupMembersQuery__
 *
 * To run a query within a React component, call `useGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupMembersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGroupMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupMembersQuery, GroupMembersQueryVariables>(
    GroupMembersDocument,
    options
  )
}
export function useGroupMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupMembersQuery, GroupMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupMembersQuery, GroupMembersQueryVariables>(
    GroupMembersDocument,
    options
  )
}
export type GroupMembersQueryHookResult = ReturnType<typeof useGroupMembersQuery>
export type GroupMembersLazyQueryHookResult = ReturnType<typeof useGroupMembersLazyQuery>
export type GroupMembersQueryResult = Apollo.QueryResult<
  GroupMembersQuery,
  GroupMembersQueryVariables
>
export const GroupsListDocument = gql`
  query GroupsList($limit: Int, $onlyWithoutMembership: Boolean) {
    groups(limit: $limit, onlyWithoutMembership: $onlyWithoutMembership) {
      groups {
        id
        ...GroupsListGroup
      }
    }
  }
  ${GroupsListGroupFragmentDoc}
`

/**
 * __useGroupsListQuery__
 *
 * To run a query within a React component, call `useGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      onlyWithoutMembership: // value for 'onlyWithoutMembership'
 *   },
 * });
 */
export function useGroupsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsListQuery, GroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupsListQuery, GroupsListQueryVariables>(
    GroupsListDocument,
    options
  )
}
export function useGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsListQuery, GroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupsListQuery, GroupsListQueryVariables>(
    GroupsListDocument,
    options
  )
}
export type GroupsListQueryHookResult = ReturnType<typeof useGroupsListQuery>
export type GroupsListLazyQueryHookResult = ReturnType<typeof useGroupsListLazyQuery>
export type GroupsListQueryResult = Apollo.QueryResult<
  GroupsListQuery,
  GroupsListQueryVariables
>
export const GroupShowDocument = gql`
  query GroupShow($groupSlug: String!) {
    group(groupSlug: $groupSlug) {
      id
      ...GroupsListGroup
    }
    currentSeasonPretty
  }
  ${GroupsListGroupFragmentDoc}
`

/**
 * __useGroupShowQuery__
 *
 * To run a query within a React component, call `useGroupShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupShowQuery({
 *   variables: {
 *      groupSlug: // value for 'groupSlug'
 *   },
 * });
 */
export function useGroupShowQuery(
  baseOptions: Apollo.QueryHookOptions<GroupShowQuery, GroupShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupShowQuery, GroupShowQueryVariables>(
    GroupShowDocument,
    options
  )
}
export function useGroupShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupShowQuery, GroupShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupShowQuery, GroupShowQueryVariables>(
    GroupShowDocument,
    options
  )
}
export type GroupShowQueryHookResult = ReturnType<typeof useGroupShowQuery>
export type GroupShowLazyQueryHookResult = ReturnType<typeof useGroupShowLazyQuery>
export type GroupShowQueryResult = Apollo.QueryResult<
  GroupShowQuery,
  GroupShowQueryVariables
>
export const GuidesBySanityTagGroupDocument = gql`
  query guidesBySanityTagGroup($slug: String!, $limit: Int) {
    guidesBySanityTagGroup(slug: $slug, limit: $limit) {
      ...UnitFragment
    }
  }
  ${UnitFragmentFragmentDoc}
`

/**
 * __useGuidesBySanityTagGroupQuery__
 *
 * To run a query within a React component, call `useGuidesBySanityTagGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidesBySanityTagGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidesBySanityTagGroupQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGuidesBySanityTagGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GuidesBySanityTagGroupQuery,
    GuidesBySanityTagGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GuidesBySanityTagGroupQuery,
    GuidesBySanityTagGroupQueryVariables
  >(GuidesBySanityTagGroupDocument, options)
}
export function useGuidesBySanityTagGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GuidesBySanityTagGroupQuery,
    GuidesBySanityTagGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GuidesBySanityTagGroupQuery,
    GuidesBySanityTagGroupQueryVariables
  >(GuidesBySanityTagGroupDocument, options)
}
export type GuidesBySanityTagGroupQueryHookResult = ReturnType<
  typeof useGuidesBySanityTagGroupQuery
>
export type GuidesBySanityTagGroupLazyQueryHookResult = ReturnType<
  typeof useGuidesBySanityTagGroupLazyQuery
>
export type GuidesBySanityTagGroupQueryResult = Apollo.QueryResult<
  GuidesBySanityTagGroupQuery,
  GuidesBySanityTagGroupQueryVariables
>
export const GuidesForDiscoveryDocument = gql`
  query guidesForDiscovery($limit: Int) {
    guidesForDiscovery(limit: $limit) {
      ...UnitFragment
    }
  }
  ${UnitFragmentFragmentDoc}
`

/**
 * __useGuidesForDiscoveryQuery__
 *
 * To run a query within a React component, call `useGuidesForDiscoveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidesForDiscoveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidesForDiscoveryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGuidesForDiscoveryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GuidesForDiscoveryQuery,
    GuidesForDiscoveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GuidesForDiscoveryQuery, GuidesForDiscoveryQueryVariables>(
    GuidesForDiscoveryDocument,
    options
  )
}
export function useGuidesForDiscoveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GuidesForDiscoveryQuery,
    GuidesForDiscoveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GuidesForDiscoveryQuery, GuidesForDiscoveryQueryVariables>(
    GuidesForDiscoveryDocument,
    options
  )
}
export type GuidesForDiscoveryQueryHookResult = ReturnType<
  typeof useGuidesForDiscoveryQuery
>
export type GuidesForDiscoveryLazyQueryHookResult = ReturnType<
  typeof useGuidesForDiscoveryLazyQuery
>
export type GuidesForDiscoveryQueryResult = Apollo.QueryResult<
  GuidesForDiscoveryQuery,
  GuidesForDiscoveryQueryVariables
>
export const AllGuidesWithSubtopicsDocument = gql`
  query allGuidesWithSubtopics {
    allGuidesWithSubtopics {
      id
      title
      slug
      topic {
        id
        slug
        title
      }
    }
  }
`

/**
 * __useAllGuidesWithSubtopicsQuery__
 *
 * To run a query within a React component, call `useAllGuidesWithSubtopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGuidesWithSubtopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGuidesWithSubtopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGuidesWithSubtopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllGuidesWithSubtopicsQuery,
    AllGuidesWithSubtopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllGuidesWithSubtopicsQuery,
    AllGuidesWithSubtopicsQueryVariables
  >(AllGuidesWithSubtopicsDocument, options)
}
export function useAllGuidesWithSubtopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGuidesWithSubtopicsQuery,
    AllGuidesWithSubtopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllGuidesWithSubtopicsQuery,
    AllGuidesWithSubtopicsQueryVariables
  >(AllGuidesWithSubtopicsDocument, options)
}
export type AllGuidesWithSubtopicsQueryHookResult = ReturnType<
  typeof useAllGuidesWithSubtopicsQuery
>
export type AllGuidesWithSubtopicsLazyQueryHookResult = ReturnType<
  typeof useAllGuidesWithSubtopicsLazyQuery
>
export type AllGuidesWithSubtopicsQueryResult = Apollo.QueryResult<
  AllGuidesWithSubtopicsQuery,
  AllGuidesWithSubtopicsQueryVariables
>
export const RelatedGuidesDocument = gql`
  query relatedGuides($subtopicIds: [ID!]!) {
    relatedGuides(subtopicIds: $subtopicIds) {
      id
      title
      slug
      overview
      heroImage {
        ...ImageFragment
      }
      authors {
        id
        name
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useRelatedGuidesQuery__
 *
 * To run a query within a React component, call `useRelatedGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedGuidesQuery({
 *   variables: {
 *      subtopicIds: // value for 'subtopicIds'
 *   },
 * });
 */
export function useRelatedGuidesQuery(
  baseOptions: Apollo.QueryHookOptions<RelatedGuidesQuery, RelatedGuidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RelatedGuidesQuery, RelatedGuidesQueryVariables>(
    RelatedGuidesDocument,
    options
  )
}
export function useRelatedGuidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelatedGuidesQuery,
    RelatedGuidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RelatedGuidesQuery, RelatedGuidesQueryVariables>(
    RelatedGuidesDocument,
    options
  )
}
export type RelatedGuidesQueryHookResult = ReturnType<typeof useRelatedGuidesQuery>
export type RelatedGuidesLazyQueryHookResult = ReturnType<
  typeof useRelatedGuidesLazyQuery
>
export type RelatedGuidesQueryResult = Apollo.QueryResult<
  RelatedGuidesQuery,
  RelatedGuidesQueryVariables
>
export const UserTeamDocument = gql`
  query UserTeam {
    currentUser {
      id
      currentTeam {
        id
        teamName
        userId
        teamMembers {
          users {
            id
            avatarUrl
            profile {
              avatarUrl
            }
          }
        }
      }
    }
  }
`

/**
 * __useUserTeamQuery__
 *
 * To run a query within a React component, call `useUserTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTeamQuery(
  baseOptions?: Apollo.QueryHookOptions<UserTeamQuery, UserTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserTeamQuery, UserTeamQueryVariables>(UserTeamDocument, options)
}
export function useUserTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserTeamQuery, UserTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserTeamQuery, UserTeamQueryVariables>(
    UserTeamDocument,
    options
  )
}
export type UserTeamQueryHookResult = ReturnType<typeof useUserTeamQuery>
export type UserTeamLazyQueryHookResult = ReturnType<typeof useUserTeamLazyQuery>
export type UserTeamQueryResult = Apollo.QueryResult<
  UserTeamQuery,
  UserTeamQueryVariables
>
export const HomepageRecentlyViewedContentDocument = gql`
  query homepageRecentlyViewedContent {
    homepageRecentlyViewedContent {
      ... on Artifact {
        id
        title
        slug
        authors {
          id
          name
          avatarUrl
          position
          companyName
        }
        publishDate
        thumbnailUrl
        viewCount
        commentCount
        description
        companyName
        companyLogoUrl
      }
      ... on Unit {
        id
        title
        slug
        authors {
          id
          name
          avatarPhoto {
            ...ImageFragment
          }
        }
        heroImage {
          ...ImageFragment
        }
        publishDate
        stepsCount
        estimatedTimeInMinutes
        previewable
        contentIsInMySavedItems
        contentBookmarkId
      }
      ... on Content {
        accessLevel
        cmsSection {
          id
          slug
          ...ContentCardParts
          ...LessonCardContentParts
        }
        contentBookmarkId
        contentIsInMySavedItems
        showNewBadge
      }
    }
  }
  ${ImageFragmentFragmentDoc}
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`

/**
 * __useHomepageRecentlyViewedContentQuery__
 *
 * To run a query within a React component, call `useHomepageRecentlyViewedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageRecentlyViewedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageRecentlyViewedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageRecentlyViewedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomepageRecentlyViewedContentQuery,
    HomepageRecentlyViewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HomepageRecentlyViewedContentQuery,
    HomepageRecentlyViewedContentQueryVariables
  >(HomepageRecentlyViewedContentDocument, options)
}
export function useHomepageRecentlyViewedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomepageRecentlyViewedContentQuery,
    HomepageRecentlyViewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HomepageRecentlyViewedContentQuery,
    HomepageRecentlyViewedContentQueryVariables
  >(HomepageRecentlyViewedContentDocument, options)
}
export type HomepageRecentlyViewedContentQueryHookResult = ReturnType<
  typeof useHomepageRecentlyViewedContentQuery
>
export type HomepageRecentlyViewedContentLazyQueryHookResult = ReturnType<
  typeof useHomepageRecentlyViewedContentLazyQuery
>
export type HomepageRecentlyViewedContentQueryResult = Apollo.QueryResult<
  HomepageRecentlyViewedContentQuery,
  HomepageRecentlyViewedContentQueryVariables
>
export const IndustriesListDocument = gql`
  query IndustriesList {
    industries {
      id
      ...IndustryFields
    }
  }
  ${IndustryFieldsFragmentDoc}
`

/**
 * __useIndustriesListQuery__
 *
 * To run a query within a React component, call `useIndustriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesListQuery(
  baseOptions?: Apollo.QueryHookOptions<IndustriesListQuery, IndustriesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IndustriesListQuery, IndustriesListQueryVariables>(
    IndustriesListDocument,
    options
  )
}
export function useIndustriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndustriesListQuery,
    IndustriesListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IndustriesListQuery, IndustriesListQueryVariables>(
    IndustriesListDocument,
    options
  )
}
export type IndustriesListQueryHookResult = ReturnType<typeof useIndustriesListQuery>
export type IndustriesListLazyQueryHookResult = ReturnType<
  typeof useIndustriesListLazyQuery
>
export type IndustriesListQueryResult = Apollo.QueryResult<
  IndustriesListQuery,
  IndustriesListQueryVariables
>
export const InlinePostsDocument = gql`
  query inlinePosts($cmsSectionId: ID!, $userId: ID) {
    inlinePosts(cmsSectionId: $cmsSectionId, userId: $userId) {
      anchor
      basedOn
      body
      bodyText
      createdAtDate
      id
      referenceImageUrl
      reactions {
        id
        kind
        user {
          id
          fullName
        }
      }
      replies {
        id
        body
        bodyText
        userAvatar
        userFullName
        userId
        userRoleWithCompany
        comments {
          id
          body
          bodyText
          user {
            id
            fullName
            avatarUrl
            roleWithCompany
          }
        }
      }
      title
      type
      userAvatar
      userId
      userFullName
      userRoleWithCompany
      groups {
        id
        ...PostGroup
      }
      topics {
        id
        ...PostTopic
      }
    }
  }
  ${PostGroupFragmentDoc}
  ${PostTopicFragmentDoc}
`

/**
 * __useInlinePostsQuery__
 *
 * To run a query within a React component, call `useInlinePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInlinePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInlinePostsQuery({
 *   variables: {
 *      cmsSectionId: // value for 'cmsSectionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInlinePostsQuery(
  baseOptions: Apollo.QueryHookOptions<InlinePostsQuery, InlinePostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InlinePostsQuery, InlinePostsQueryVariables>(
    InlinePostsDocument,
    options
  )
}
export function useInlinePostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InlinePostsQuery, InlinePostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InlinePostsQuery, InlinePostsQueryVariables>(
    InlinePostsDocument,
    options
  )
}
export type InlinePostsQueryHookResult = ReturnType<typeof useInlinePostsQuery>
export type InlinePostsLazyQueryHookResult = ReturnType<typeof useInlinePostsLazyQuery>
export type InlinePostsQueryResult = Apollo.QueryResult<
  InlinePostsQuery,
  InlinePostsQueryVariables
>
export const InvoiceDocument = gql`
  query invoice($invoiceId: ID!) {
    invoice(id: $invoiceId) {
      id
      ...InvoiceFields
    }
  }
  ${InvoiceFieldsFragmentDoc}
`

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options)
}
export function useInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options
  )
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>
export const LearningGoalsDocument = gql`
  query LearningGoals {
    reforgeCollections {
      id
      name
      user {
        id
        fullName
      }
      isWhiteGlove
    }
    currentUser {
      forkedFolderIds
    }
  }
`

/**
 * __useLearningGoalsQuery__
 *
 * To run a query within a React component, call `useLearningGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLearningGoalsQuery(
  baseOptions?: Apollo.QueryHookOptions<LearningGoalsQuery, LearningGoalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LearningGoalsQuery, LearningGoalsQueryVariables>(
    LearningGoalsDocument,
    options
  )
}
export function useLearningGoalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearningGoalsQuery,
    LearningGoalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LearningGoalsQuery, LearningGoalsQueryVariables>(
    LearningGoalsDocument,
    options
  )
}
export type LearningGoalsQueryHookResult = ReturnType<typeof useLearningGoalsQuery>
export type LearningGoalsLazyQueryHookResult = ReturnType<
  typeof useLearningGoalsLazyQuery
>
export type LearningGoalsQueryResult = Apollo.QueryResult<
  LearningGoalsQuery,
  LearningGoalsQueryVariables
>
export const LessonViewerDocument = gql`
  query LessonViewer(
    $cmsProgramSlug: String!
    $cmsModuleSlug: String!
    $cmsSectionParentSlug: String
    $cmsSectionSlug: String!
    $path: String!
    $referringPostId: String
  ) {
    currentUser {
      id
    }
    lessonViewer(
      cmsProgramSlug: $cmsProgramSlug
      cmsModuleSlug: $cmsModuleSlug
      cmsSectionParentSlug: $cmsSectionParentSlug
      cmsSectionSlug: $cmsSectionSlug
      path: $path
      referringPostId: $referringPostId
    ) {
      viewAccess
      cmsProgram {
        id
        ...LessonHeaderCmsProgramParts
        ...LessonFooterCmsProgramParts
        ...LessonTocCmsProgramParts
        topic
        slug
        progressPercent
        programNotification {
          imageUrl
          message
          notificationName
          title
        }
      }
      cmsModule {
        id
        slug
        ...LessonContentCmsModuleParts
        ...LessonHeaderCmsModuleParts
        ...LessonFooterCmsModuleParts
        ...LessonTocCmsModuleParts
        showFeedback
      }
      cmsSection {
        id
        ...LessonViewerCmsSectionParts
        ...LessonParentSection
      }
      userProgram {
        id
        ...LessonHeaderUserProgramParts
        ...LessonTocUserProgramParts
      }
      expertUsers {
        id
        ...LessonTocExpertUserParts
      }
      sameActiveUserCohort {
        id
        ...LessonHeaderSameActiveUserCohortParts
      }
    }
  }
  ${LessonHeaderCmsProgramPartsFragmentDoc}
  ${LessonFooterCmsProgramPartsFragmentDoc}
  ${LessonTocCmsProgramPartsFragmentDoc}
  ${LessonContentCmsModulePartsFragmentDoc}
  ${LessonHeaderCmsModulePartsFragmentDoc}
  ${LessonFooterCmsModulePartsFragmentDoc}
  ${LessonTocCmsModulePartsFragmentDoc}
  ${LessonViewerCmsSectionPartsFragmentDoc}
  ${LessonParentSectionFragmentDoc}
  ${LessonHeaderUserProgramPartsFragmentDoc}
  ${LessonTocUserProgramPartsFragmentDoc}
  ${LessonTocExpertUserPartsFragmentDoc}
  ${LessonHeaderSameActiveUserCohortPartsFragmentDoc}
`

/**
 * __useLessonViewerQuery__
 *
 * To run a query within a React component, call `useLessonViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonViewerQuery({
 *   variables: {
 *      cmsProgramSlug: // value for 'cmsProgramSlug'
 *      cmsModuleSlug: // value for 'cmsModuleSlug'
 *      cmsSectionParentSlug: // value for 'cmsSectionParentSlug'
 *      cmsSectionSlug: // value for 'cmsSectionSlug'
 *      path: // value for 'path'
 *      referringPostId: // value for 'referringPostId'
 *   },
 * });
 */
export function useLessonViewerQuery(
  baseOptions: Apollo.QueryHookOptions<LessonViewerQuery, LessonViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LessonViewerQuery, LessonViewerQueryVariables>(
    LessonViewerDocument,
    options
  )
}
export function useLessonViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LessonViewerQuery, LessonViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LessonViewerQuery, LessonViewerQueryVariables>(
    LessonViewerDocument,
    options
  )
}
export type LessonViewerQueryHookResult = ReturnType<typeof useLessonViewerQuery>
export type LessonViewerLazyQueryHookResult = ReturnType<typeof useLessonViewerLazyQuery>
export type LessonViewerQueryResult = Apollo.QueryResult<
  LessonViewerQuery,
  LessonViewerQueryVariables
>
export const ManagePlanDocument = gql`
  query managePlan($id: ID!) {
    user(id: $id) {
      id
      accessPolicyKind
      is {
        trialing
      }
      subscriptions {
        active {
          dunningDaysLeft
          expiresAt
          hasUnlimitedCohortAccess
          isDunning
          isCanceled
          isExpired
          isTeam
          numSeats
          completedTrial
          trialDaysLeft
          trialEndDate
          planName
          stripeCollectionMethod
          stripeSubscriptionStatus
          userIsOwner
          stripeSubscription {
            cancelAtPeriodEnd
            id
          }
          stripeUpcomingInvoice {
            id
            amountDue
            numSeats
            numCohortPasses
            planName
            subtotal
          }
          status
          trialEndDate
        }
      }
      is {
        trialing
      }
    }
    userPaymentMethods(userId: $id) {
      id
    }
    plansForSale {
      name
      pricePerYear
      maxSeatCount
    }
  }
`

/**
 * __useManagePlanQuery__
 *
 * To run a query within a React component, call `useManagePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManagePlanQuery(
  baseOptions: Apollo.QueryHookOptions<ManagePlanQuery, ManagePlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ManagePlanQuery, ManagePlanQueryVariables>(
    ManagePlanDocument,
    options
  )
}
export function useManagePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManagePlanQuery, ManagePlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ManagePlanQuery, ManagePlanQueryVariables>(
    ManagePlanDocument,
    options
  )
}
export type ManagePlanQueryHookResult = ReturnType<typeof useManagePlanQuery>
export type ManagePlanLazyQueryHookResult = ReturnType<typeof useManagePlanLazyQuery>
export type ManagePlanQueryResult = Apollo.QueryResult<
  ManagePlanQuery,
  ManagePlanQueryVariables
>
export const AvailableCoursesQueryForWaitlistDocument = gql`
  query AvailableCoursesQueryForWaitlist {
    marketingCourses {
      id
      title
      slug
      course {
        title
      }
    }
  }
`

/**
 * __useAvailableCoursesQueryForWaitlistQuery__
 *
 * To run a query within a React component, call `useAvailableCoursesQueryForWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoursesQueryForWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoursesQueryForWaitlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableCoursesQueryForWaitlistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableCoursesQueryForWaitlistQuery,
    AvailableCoursesQueryForWaitlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AvailableCoursesQueryForWaitlistQuery,
    AvailableCoursesQueryForWaitlistQueryVariables
  >(AvailableCoursesQueryForWaitlistDocument, options)
}
export function useAvailableCoursesQueryForWaitlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCoursesQueryForWaitlistQuery,
    AvailableCoursesQueryForWaitlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AvailableCoursesQueryForWaitlistQuery,
    AvailableCoursesQueryForWaitlistQueryVariables
  >(AvailableCoursesQueryForWaitlistDocument, options)
}
export type AvailableCoursesQueryForWaitlistQueryHookResult = ReturnType<
  typeof useAvailableCoursesQueryForWaitlistQuery
>
export type AvailableCoursesQueryForWaitlistLazyQueryHookResult = ReturnType<
  typeof useAvailableCoursesQueryForWaitlistLazyQuery
>
export type AvailableCoursesQueryForWaitlistQueryResult = Apollo.QueryResult<
  AvailableCoursesQueryForWaitlistQuery,
  AvailableCoursesQueryForWaitlistQueryVariables
>
export const BlogIndexDocument = gql`
  query blogIndex($category: String, $limit: Int, $lastId: String, $reverse: Boolean) {
    blogIndex(category: $category, limit: $limit, lastId: $lastId, reverse: $reverse) {
      page {
        id
        title
        seo {
          ...PageSeoFragment
        }
      }
      blogPosts {
        id
        publishDate
        title
        slug
        summaryText
        summaryImage {
          ...ImageFragment
        }
        authors {
          ...BlogAuthorFragment
        }
      }
      featuredBlogPosts {
        id
        title
        slug
        summaryImage {
          ...ImageFragment
        }
        authors {
          ...BlogAuthorFragment
        }
        isFeatured
      }
      nextBlogPostsCount
      prevBlogPostsCount
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${BlogAuthorFragmentFragmentDoc}
`

/**
 * __useBlogIndexQuery__
 *
 * To run a query within a React component, call `useBlogIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogIndexQuery({
 *   variables: {
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      lastId: // value for 'lastId'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useBlogIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<BlogIndexQuery, BlogIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlogIndexQuery, BlogIndexQueryVariables>(
    BlogIndexDocument,
    options
  )
}
export function useBlogIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlogIndexQuery, BlogIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BlogIndexQuery, BlogIndexQueryVariables>(
    BlogIndexDocument,
    options
  )
}
export type BlogIndexQueryHookResult = ReturnType<typeof useBlogIndexQuery>
export type BlogIndexLazyQueryHookResult = ReturnType<typeof useBlogIndexLazyQuery>
export type BlogIndexQueryResult = Apollo.QueryResult<
  BlogIndexQuery,
  BlogIndexQueryVariables
>
export const BlogDetailsDocument = gql`
  query blogDetails($slug: String!) {
    blogPost(slug: $slug) {
      createdAt
      updatedAt
      id
      title
      seo {
        ...PageSeoFragment
      }
      blogPost {
        id
        title
        categories {
          ...BlogPostCategoryFragment
        }
        subtopicIds
        overview
        authors {
          ...BlogAuthorFragment
        }
        contentBlock
        summaryImage {
          ...ImageFragment
        }
        tableOfContents
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${BlogPostCategoryFragmentFragmentDoc}
  ${BlogAuthorFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useBlogDetailsQuery__
 *
 * To run a query within a React component, call `useBlogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<BlogDetailsQuery, BlogDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlogDetailsQuery, BlogDetailsQueryVariables>(
    BlogDetailsDocument,
    options
  )
}
export function useBlogDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlogDetailsQuery, BlogDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BlogDetailsQuery, BlogDetailsQueryVariables>(
    BlogDetailsDocument,
    options
  )
}
export type BlogDetailsQueryHookResult = ReturnType<typeof useBlogDetailsQuery>
export type BlogDetailsLazyQueryHookResult = ReturnType<typeof useBlogDetailsLazyQuery>
export type BlogDetailsQueryResult = Apollo.QueryResult<
  BlogDetailsQuery,
  BlogDetailsQueryVariables
>
export const RelatedBlogPostsDocument = gql`
  query relatedBlogPosts($subtopicIds: [ID!]!) {
    relatedBlogPosts(subtopicIds: $subtopicIds) {
      id
      title
      slug
      summaryText
      summaryImage {
        ...ImageFragment
      }
      authors {
        id
        name
      }
    }
  }
  ${ImageFragmentFragmentDoc}
`

/**
 * __useRelatedBlogPostsQuery__
 *
 * To run a query within a React component, call `useRelatedBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedBlogPostsQuery({
 *   variables: {
 *      subtopicIds: // value for 'subtopicIds'
 *   },
 * });
 */
export function useRelatedBlogPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelatedBlogPostsQuery,
    RelatedBlogPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RelatedBlogPostsQuery, RelatedBlogPostsQueryVariables>(
    RelatedBlogPostsDocument,
    options
  )
}
export function useRelatedBlogPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelatedBlogPostsQuery,
    RelatedBlogPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RelatedBlogPostsQuery, RelatedBlogPostsQueryVariables>(
    RelatedBlogPostsDocument,
    options
  )
}
export type RelatedBlogPostsQueryHookResult = ReturnType<typeof useRelatedBlogPostsQuery>
export type RelatedBlogPostsLazyQueryHookResult = ReturnType<
  typeof useRelatedBlogPostsLazyQuery
>
export type RelatedBlogPostsQueryResult = Apollo.QueryResult<
  RelatedBlogPostsQuery,
  RelatedBlogPostsQueryVariables
>
export const CareersPageDocument = gql`
  query careersPage {
    careersPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useCareersPageQuery__
 *
 * To run a query within a React component, call `useCareersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCareersPageQuery(
  baseOptions?: Apollo.QueryHookOptions<CareersPageQuery, CareersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CareersPageQuery, CareersPageQueryVariables>(
    CareersPageDocument,
    options
  )
}
export function useCareersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareersPageQuery, CareersPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CareersPageQuery, CareersPageQueryVariables>(
    CareersPageDocument,
    options
  )
}
export type CareersPageQueryHookResult = ReturnType<typeof useCareersPageQuery>
export type CareersPageLazyQueryHookResult = ReturnType<typeof useCareersPageLazyQuery>
export type CareersPageQueryResult = Apollo.QueryResult<
  CareersPageQuery,
  CareersPageQueryVariables
>
export const OpenPositionsPageDocument = gql`
  query openPositionsPage {
    openPositionsPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useOpenPositionsPageQuery__
 *
 * To run a query within a React component, call `useOpenPositionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenPositionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenPositionsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpenPositionsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OpenPositionsPageQuery,
    OpenPositionsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OpenPositionsPageQuery, OpenPositionsPageQueryVariables>(
    OpenPositionsPageDocument,
    options
  )
}
export function useOpenPositionsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpenPositionsPageQuery,
    OpenPositionsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OpenPositionsPageQuery, OpenPositionsPageQueryVariables>(
    OpenPositionsPageDocument,
    options
  )
}
export type OpenPositionsPageQueryHookResult = ReturnType<
  typeof useOpenPositionsPageQuery
>
export type OpenPositionsPageLazyQueryHookResult = ReturnType<
  typeof useOpenPositionsPageLazyQuery
>
export type OpenPositionsPageQueryResult = Apollo.QueryResult<
  OpenPositionsPageQuery,
  OpenPositionsPageQueryVariables
>
export const CaseStudiesIndexDocument = gql`
  query caseStudiesIndex {
    caseStudies {
      page {
        id
        title
        seo {
          ...PageSeoFragment
        }
      }
      caseStudies {
        id
        previewTitle
        slug
        contributors {
          id
          name
          company
          position
          avatarPhoto {
            ...ImageFragment
          }
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useCaseStudiesIndexQuery__
 *
 * To run a query within a React component, call `useCaseStudiesIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStudiesIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStudiesIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useCaseStudiesIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CaseStudiesIndexQuery,
    CaseStudiesIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CaseStudiesIndexQuery, CaseStudiesIndexQueryVariables>(
    CaseStudiesIndexDocument,
    options
  )
}
export function useCaseStudiesIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseStudiesIndexQuery,
    CaseStudiesIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CaseStudiesIndexQuery, CaseStudiesIndexQueryVariables>(
    CaseStudiesIndexDocument,
    options
  )
}
export type CaseStudiesIndexQueryHookResult = ReturnType<typeof useCaseStudiesIndexQuery>
export type CaseStudiesIndexLazyQueryHookResult = ReturnType<
  typeof useCaseStudiesIndexLazyQuery
>
export type CaseStudiesIndexQueryResult = Apollo.QueryResult<
  CaseStudiesIndexQuery,
  CaseStudiesIndexQueryVariables
>
export const CaseStudyDetailsDocument = gql`
  query caseStudyDetails($slug: String!) {
    caseStudy(slug: $slug) {
      id
      title
      createdAt
      updatedAt
      seo {
        ...PageSeoFragment
      }
      caseStudy {
        id
        title
        useCases
        contributors {
          id
          name
          company
          position
          avatarPhoto {
            ...ImageFragment
          }
        }
        content {
          id
          background
          contentBlock
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useCaseStudyDetailsQuery__
 *
 * To run a query within a React component, call `useCaseStudyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseStudyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseStudyDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCaseStudyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CaseStudyDetailsQuery,
    CaseStudyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CaseStudyDetailsQuery, CaseStudyDetailsQueryVariables>(
    CaseStudyDetailsDocument,
    options
  )
}
export function useCaseStudyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseStudyDetailsQuery,
    CaseStudyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CaseStudyDetailsQuery, CaseStudyDetailsQueryVariables>(
    CaseStudyDetailsDocument,
    options
  )
}
export type CaseStudyDetailsQueryHookResult = ReturnType<typeof useCaseStudyDetailsQuery>
export type CaseStudyDetailsLazyQueryHookResult = ReturnType<
  typeof useCaseStudyDetailsLazyQuery
>
export type CaseStudyDetailsQueryResult = Apollo.QueryResult<
  CaseStudyDetailsQuery,
  CaseStudyDetailsQueryVariables
>
export const ContactUsDocument = gql`
  query contactUs {
    contactUs {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useContactUsQuery__
 *
 * To run a query within a React component, call `useContactUsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactUsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactUsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactUsQuery, ContactUsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContactUsQuery, ContactUsQueryVariables>(
    ContactUsDocument,
    options
  )
}
export function useContactUsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactUsQuery, ContactUsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContactUsQuery, ContactUsQueryVariables>(
    ContactUsDocument,
    options
  )
}
export type ContactUsQueryHookResult = ReturnType<typeof useContactUsQuery>
export type ContactUsLazyQueryHookResult = ReturnType<typeof useContactUsLazyQuery>
export type ContactUsQueryResult = Apollo.QueryResult<
  ContactUsQuery,
  ContactUsQueryVariables
>
export const CopyrightDisputePageDocument = gql`
  query copyrightDisputePage {
    copyrightDisputePage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useCopyrightDisputePageQuery__
 *
 * To run a query within a React component, call `useCopyrightDisputePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyrightDisputePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyrightDisputePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCopyrightDisputePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CopyrightDisputePageQuery,
    CopyrightDisputePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CopyrightDisputePageQuery, CopyrightDisputePageQueryVariables>(
    CopyrightDisputePageDocument,
    options
  )
}
export function useCopyrightDisputePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CopyrightDisputePageQuery,
    CopyrightDisputePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CopyrightDisputePageQuery,
    CopyrightDisputePageQueryVariables
  >(CopyrightDisputePageDocument, options)
}
export type CopyrightDisputePageQueryHookResult = ReturnType<
  typeof useCopyrightDisputePageQuery
>
export type CopyrightDisputePageLazyQueryHookResult = ReturnType<
  typeof useCopyrightDisputePageLazyQuery
>
export type CopyrightDisputePageQueryResult = Apollo.QueryResult<
  CopyrightDisputePageQuery,
  CopyrightDisputePageQueryVariables
>
export const CourseDetailPageDocument = gql`
  query courseDetailPage($slug: String!) {
    cclCourse(slug: $slug) {
      ...CourseDetailParts
    }
    relatedCourses(slug: $slug) {
      ...RelatedCourseParts
    }
    upcomingCourseSessions(slug: $slug) {
      ...CclCourseSessionParts
    }
  }
  ${CourseDetailPartsFragmentDoc}
  ${RelatedCoursePartsFragmentDoc}
  ${CclCourseSessionPartsFragmentDoc}
`

/**
 * __useCourseDetailPageQuery__
 *
 * To run a query within a React component, call `useCourseDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseDetailPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseDetailPageQuery,
    CourseDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CourseDetailPageQuery, CourseDetailPageQueryVariables>(
    CourseDetailPageDocument,
    options
  )
}
export function useCourseDetailPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseDetailPageQuery,
    CourseDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CourseDetailPageQuery, CourseDetailPageQueryVariables>(
    CourseDetailPageDocument,
    options
  )
}
export type CourseDetailPageQueryHookResult = ReturnType<typeof useCourseDetailPageQuery>
export type CourseDetailPageLazyQueryHookResult = ReturnType<
  typeof useCourseDetailPageLazyQuery
>
export type CourseDetailPageQueryResult = Apollo.QueryResult<
  CourseDetailPageQuery,
  CourseDetailPageQueryVariables
>
export const LoggedOutCourseDetailPageDocument = gql`
  query loggedOutCourseDetailPage($slug: String!) {
    marketingCourseDetails(slug: $slug) {
      course {
        courseFaq {
          id
          answer
          question
        }
      }
    }
  }
`

/**
 * __useLoggedOutCourseDetailPageQuery__
 *
 * To run a query within a React component, call `useLoggedOutCourseDetailPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedOutCourseDetailPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedOutCourseDetailPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLoggedOutCourseDetailPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoggedOutCourseDetailPageQuery,
    LoggedOutCourseDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoggedOutCourseDetailPageQuery,
    LoggedOutCourseDetailPageQueryVariables
  >(LoggedOutCourseDetailPageDocument, options)
}
export function useLoggedOutCourseDetailPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedOutCourseDetailPageQuery,
    LoggedOutCourseDetailPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoggedOutCourseDetailPageQuery,
    LoggedOutCourseDetailPageQueryVariables
  >(LoggedOutCourseDetailPageDocument, options)
}
export type LoggedOutCourseDetailPageQueryHookResult = ReturnType<
  typeof useLoggedOutCourseDetailPageQuery
>
export type LoggedOutCourseDetailPageLazyQueryHookResult = ReturnType<
  typeof useLoggedOutCourseDetailPageLazyQuery
>
export type LoggedOutCourseDetailPageQueryResult = Apollo.QueryResult<
  LoggedOutCourseDetailPageQuery,
  LoggedOutCourseDetailPageQueryVariables
>
export const CourseDetailEntityPreviewDocument = gql`
  query courseDetailEntityPreview($slug: String!) {
    course(slug: $slug) {
      id
      slug
      title
    }
    cclCourse(slug: $slug) {
      ...CourseDetailEntityPreviewParts
    }
    upcomingCourseSessions(slug: $slug) {
      ...CclCourseSessionParts
    }
  }
  ${CourseDetailEntityPreviewPartsFragmentDoc}
  ${CclCourseSessionPartsFragmentDoc}
`

/**
 * __useCourseDetailEntityPreviewQuery__
 *
 * To run a query within a React component, call `useCourseDetailEntityPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailEntityPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailEntityPreviewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCourseDetailEntityPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseDetailEntityPreviewQuery,
    CourseDetailEntityPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseDetailEntityPreviewQuery,
    CourseDetailEntityPreviewQueryVariables
  >(CourseDetailEntityPreviewDocument, options)
}
export function useCourseDetailEntityPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseDetailEntityPreviewQuery,
    CourseDetailEntityPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseDetailEntityPreviewQuery,
    CourseDetailEntityPreviewQueryVariables
  >(CourseDetailEntityPreviewDocument, options)
}
export type CourseDetailEntityPreviewQueryHookResult = ReturnType<
  typeof useCourseDetailEntityPreviewQuery
>
export type CourseDetailEntityPreviewLazyQueryHookResult = ReturnType<
  typeof useCourseDetailEntityPreviewLazyQuery
>
export type CourseDetailEntityPreviewQueryResult = Apollo.QueryResult<
  CourseDetailEntityPreviewQuery,
  CourseDetailEntityPreviewQueryVariables
>
export const CoursesListDocument = gql`
  query coursesList($topicSlugs: [String!], $availability: [String!]) {
    cclCourses(topicSlugs: $topicSlugs, availability: $availability) {
      ...CclCourseCourseCardParts
    }
  }
  ${CclCourseCourseCardPartsFragmentDoc}
`

/**
 * __useCoursesListQuery__
 *
 * To run a query within a React component, call `useCoursesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesListQuery({
 *   variables: {
 *      topicSlugs: // value for 'topicSlugs'
 *      availability: // value for 'availability'
 *   },
 * });
 */
export function useCoursesListQuery(
  baseOptions?: Apollo.QueryHookOptions<CoursesListQuery, CoursesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursesListQuery, CoursesListQueryVariables>(
    CoursesListDocument,
    options
  )
}
export function useCoursesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoursesListQuery, CoursesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursesListQuery, CoursesListQueryVariables>(
    CoursesListDocument,
    options
  )
}
export type CoursesListQueryHookResult = ReturnType<typeof useCoursesListQuery>
export type CoursesListLazyQueryHookResult = ReturnType<typeof useCoursesListLazyQuery>
export type CoursesListQueryResult = Apollo.QueryResult<
  CoursesListQuery,
  CoursesListQueryVariables
>
export const CoursesListFiltersDocument = gql`
  query coursesListFilters {
    cclCourseCclTopics {
      ...CclTopicFilterParts
    }
    legacyCourseCclTopics: marketingCourseCclTopics(courseType: "legacy") {
      ...CclTopicFilterParts
    }
  }
  ${CclTopicFilterPartsFragmentDoc}
`

/**
 * __useCoursesListFiltersQuery__
 *
 * To run a query within a React component, call `useCoursesListFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesListFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesListFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesListFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesListFiltersQuery,
    CoursesListFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursesListFiltersQuery, CoursesListFiltersQueryVariables>(
    CoursesListFiltersDocument,
    options
  )
}
export function useCoursesListFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesListFiltersQuery,
    CoursesListFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursesListFiltersQuery, CoursesListFiltersQueryVariables>(
    CoursesListFiltersDocument,
    options
  )
}
export type CoursesListFiltersQueryHookResult = ReturnType<
  typeof useCoursesListFiltersQuery
>
export type CoursesListFiltersLazyQueryHookResult = ReturnType<
  typeof useCoursesListFiltersLazyQuery
>
export type CoursesListFiltersQueryResult = Apollo.QueryResult<
  CoursesListFiltersQuery,
  CoursesListFiltersQueryVariables
>
export const FeaturedCoursesDocument = gql`
  query featuredCourses {
    featuredCclCourses {
      ...CclCourseFeaturedCourseCardParts
    }
  }
  ${CclCourseFeaturedCourseCardPartsFragmentDoc}
`

/**
 * __useFeaturedCoursesQuery__
 *
 * To run a query within a React component, call `useFeaturedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeaturedCoursesQuery,
    FeaturedCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FeaturedCoursesQuery, FeaturedCoursesQueryVariables>(
    FeaturedCoursesDocument,
    options
  )
}
export function useFeaturedCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeaturedCoursesQuery,
    FeaturedCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FeaturedCoursesQuery, FeaturedCoursesQueryVariables>(
    FeaturedCoursesDocument,
    options
  )
}
export type FeaturedCoursesQueryHookResult = ReturnType<typeof useFeaturedCoursesQuery>
export type FeaturedCoursesLazyQueryHookResult = ReturnType<
  typeof useFeaturedCoursesLazyQuery
>
export type FeaturedCoursesQueryResult = Apollo.QueryResult<
  FeaturedCoursesQuery,
  FeaturedCoursesQueryVariables
>
export const CoursesListUserDocument = gql`
  query coursesListUser {
    currentUser {
      id
      hasContentInProgressOrComplete
      hasSeenCollectionsPrompt
      bookmarkFolders {
        ...BookmarkFolderParts
      }
      courseBookmarks {
        ...CourseBookmarkParts
      }
      programBookmarks {
        ...ProgramBookmarkParts
      }
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
  ${CourseBookmarkPartsFragmentDoc}
  ${ProgramBookmarkPartsFragmentDoc}
`

/**
 * __useCoursesListUserQuery__
 *
 * To run a query within a React component, call `useCoursesListUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesListUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesListUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesListUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesListUserQuery,
    CoursesListUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursesListUserQuery, CoursesListUserQueryVariables>(
    CoursesListUserDocument,
    options
  )
}
export function useCoursesListUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesListUserQuery,
    CoursesListUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursesListUserQuery, CoursesListUserQueryVariables>(
    CoursesListUserDocument,
    options
  )
}
export type CoursesListUserQueryHookResult = ReturnType<typeof useCoursesListUserQuery>
export type CoursesListUserLazyQueryHookResult = ReturnType<
  typeof useCoursesListUserLazyQuery
>
export type CoursesListUserQueryResult = Apollo.QueryResult<
  CoursesListUserQuery,
  CoursesListUserQueryVariables
>
export const CoursesIndexSeoDocument = gql`
  query CoursesIndexSeo {
    marketingCoursesIndex {
      page {
        id
        ...MarketingCoursesIndexPageParts
      }
    }
  }
  ${MarketingCoursesIndexPagePartsFragmentDoc}
`

/**
 * __useCoursesIndexSeoQuery__
 *
 * To run a query within a React component, call `useCoursesIndexSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesIndexSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesIndexSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesIndexSeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesIndexSeoQuery,
    CoursesIndexSeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CoursesIndexSeoQuery, CoursesIndexSeoQueryVariables>(
    CoursesIndexSeoDocument,
    options
  )
}
export function useCoursesIndexSeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesIndexSeoQuery,
    CoursesIndexSeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CoursesIndexSeoQuery, CoursesIndexSeoQueryVariables>(
    CoursesIndexSeoDocument,
    options
  )
}
export type CoursesIndexSeoQueryHookResult = ReturnType<typeof useCoursesIndexSeoQuery>
export type CoursesIndexSeoLazyQueryHookResult = ReturnType<
  typeof useCoursesIndexSeoLazyQuery
>
export type CoursesIndexSeoQueryResult = Apollo.QueryResult<
  CoursesIndexSeoQuery,
  CoursesIndexSeoQueryVariables
>
export const CoursesMarketplaceWaitlistDocument = gql`
  query CoursesMarketplaceWaitlist {
    coursesMarketplaceWaitlist {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useCoursesMarketplaceWaitlistQuery__
 *
 * To run a query within a React component, call `useCoursesMarketplaceWaitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursesMarketplaceWaitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursesMarketplaceWaitlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoursesMarketplaceWaitlistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoursesMarketplaceWaitlistQuery,
    CoursesMarketplaceWaitlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CoursesMarketplaceWaitlistQuery,
    CoursesMarketplaceWaitlistQueryVariables
  >(CoursesMarketplaceWaitlistDocument, options)
}
export function useCoursesMarketplaceWaitlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoursesMarketplaceWaitlistQuery,
    CoursesMarketplaceWaitlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CoursesMarketplaceWaitlistQuery,
    CoursesMarketplaceWaitlistQueryVariables
  >(CoursesMarketplaceWaitlistDocument, options)
}
export type CoursesMarketplaceWaitlistQueryHookResult = ReturnType<
  typeof useCoursesMarketplaceWaitlistQuery
>
export type CoursesMarketplaceWaitlistLazyQueryHookResult = ReturnType<
  typeof useCoursesMarketplaceWaitlistLazyQuery
>
export type CoursesMarketplaceWaitlistQueryResult = Apollo.QueryResult<
  CoursesMarketplaceWaitlistQuery,
  CoursesMarketplaceWaitlistQueryVariables
>
export const ExpertsIndexDocument = gql`
  query expertsIndex {
    marketingExperts {
      page {
        id
        title
        seo {
          ...PageSeoFragment
        }
      }
      experts {
        id
        name
        slug
        avatarPhoto {
          ...ImageFragment
        }
        pastCompanies
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useExpertsIndexQuery__
 *
 * To run a query within a React component, call `useExpertsIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertsIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertsIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpertsIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<ExpertsIndexQuery, ExpertsIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExpertsIndexQuery, ExpertsIndexQueryVariables>(
    ExpertsIndexDocument,
    options
  )
}
export function useExpertsIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExpertsIndexQuery, ExpertsIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExpertsIndexQuery, ExpertsIndexQueryVariables>(
    ExpertsIndexDocument,
    options
  )
}
export type ExpertsIndexQueryHookResult = ReturnType<typeof useExpertsIndexQuery>
export type ExpertsIndexLazyQueryHookResult = ReturnType<typeof useExpertsIndexLazyQuery>
export type ExpertsIndexQueryResult = Apollo.QueryResult<
  ExpertsIndexQuery,
  ExpertsIndexQueryVariables
>
export const LoggedOutCourseDetailsPageBridgeDocument = gql`
  query loggedOutCourseDetailsPageBridge($slug: String!) {
    marketingCourseDetails(slug: $slug) {
      id
      seo {
        ...PageSeoFragment
      }
      course {
        courseType
      }
    }
    cclCourse(slug: $slug) {
      id
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useLoggedOutCourseDetailsPageBridgeQuery__
 *
 * To run a query within a React component, call `useLoggedOutCourseDetailsPageBridgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedOutCourseDetailsPageBridgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedOutCourseDetailsPageBridgeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLoggedOutCourseDetailsPageBridgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoggedOutCourseDetailsPageBridgeQuery,
    LoggedOutCourseDetailsPageBridgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LoggedOutCourseDetailsPageBridgeQuery,
    LoggedOutCourseDetailsPageBridgeQueryVariables
  >(LoggedOutCourseDetailsPageBridgeDocument, options)
}
export function useLoggedOutCourseDetailsPageBridgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedOutCourseDetailsPageBridgeQuery,
    LoggedOutCourseDetailsPageBridgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LoggedOutCourseDetailsPageBridgeQuery,
    LoggedOutCourseDetailsPageBridgeQueryVariables
  >(LoggedOutCourseDetailsPageBridgeDocument, options)
}
export type LoggedOutCourseDetailsPageBridgeQueryHookResult = ReturnType<
  typeof useLoggedOutCourseDetailsPageBridgeQuery
>
export type LoggedOutCourseDetailsPageBridgeLazyQueryHookResult = ReturnType<
  typeof useLoggedOutCourseDetailsPageBridgeLazyQuery
>
export type LoggedOutCourseDetailsPageBridgeQueryResult = Apollo.QueryResult<
  LoggedOutCourseDetailsPageBridgeQuery,
  LoggedOutCourseDetailsPageBridgeQueryVariables
>
export const MarketingCoursesIndexDocument = gql`
  query marketingCoursesIndex {
    marketingCoursesIndex {
      page {
        id
        ...MarketingCoursesIndexPageParts
      }
      courses {
        id
        courseType
        isLive
        title
        course {
          title
          shortDescription
          slug
        }
        startDate
        subtitle
        slug
        cmsProgramSlug
        creators {
          id
          expert {
            id
            name
          }
        }
        hosts {
          id
          hostName
        }
        previewImage {
          ...ImageFragment
        }
      }
    }
  }
  ${MarketingCoursesIndexPagePartsFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useMarketingCoursesIndexQuery__
 *
 * To run a query within a React component, call `useMarketingCoursesIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingCoursesIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingCoursesIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketingCoursesIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketingCoursesIndexQuery,
    MarketingCoursesIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MarketingCoursesIndexQuery, MarketingCoursesIndexQueryVariables>(
    MarketingCoursesIndexDocument,
    options
  )
}
export function useMarketingCoursesIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingCoursesIndexQuery,
    MarketingCoursesIndexQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketingCoursesIndexQuery,
    MarketingCoursesIndexQueryVariables
  >(MarketingCoursesIndexDocument, options)
}
export type MarketingCoursesIndexQueryHookResult = ReturnType<
  typeof useMarketingCoursesIndexQuery
>
export type MarketingCoursesIndexLazyQueryHookResult = ReturnType<
  typeof useMarketingCoursesIndexLazyQuery
>
export type MarketingCoursesIndexQueryResult = Apollo.QueryResult<
  MarketingCoursesIndexQuery,
  MarketingCoursesIndexQueryVariables
>
export const MarketingCourseDetailsDocument = gql`
  query marketingCourseDetails($slug: String!) {
    marketingCourseDetails(slug: $slug) {
      id
      seo {
        ...PageSeoFragment
      }
      course {
        courseType
        isLive
        course {
          title
          shortDescription
          slug
          whatYouWillLearn
          whoThisIsFor
        }
        price
        duration
        coursePreviewAvailable
        startDate
        courseInfoCards {
          id
          title
          description
        }
        title
        slug
        cmsProgramSlug
        icon {
          ...ImageFragment
        }
        backgroundImage {
          ...ImageFragment
        }
        subtitle
        availabilitySubtitle
        hostSubtitle
        description
        ctaButtonText
        ctaButtonLink
        hostSectionTitle
        hostSectionSubtitle
        hosts {
          id
          hostName
          hostBio
          hostPicture {
            ...ImageFragment
          }
        }
        creators {
          id
          expert {
            id
            name
            slug
            avatarPhoto {
              ...ImageFragment
            }
          }
          role
        }
        breakdownBackgroundImage {
          ...ImageFragment
        }
        targetAudience
        goals
        breakdownImage {
          ...ImageFragment
        }
        topicsCovered {
          id
          heading
          description
        }
        courseFaq {
          id
          question
          answer
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useMarketingCourseDetailsQuery__
 *
 * To run a query within a React component, call `useMarketingCourseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingCourseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingCourseDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useMarketingCourseDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketingCourseDetailsQuery,
    MarketingCourseDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MarketingCourseDetailsQuery,
    MarketingCourseDetailsQueryVariables
  >(MarketingCourseDetailsDocument, options)
}
export function useMarketingCourseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingCourseDetailsQuery,
    MarketingCourseDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketingCourseDetailsQuery,
    MarketingCourseDetailsQueryVariables
  >(MarketingCourseDetailsDocument, options)
}
export type MarketingCourseDetailsQueryHookResult = ReturnType<
  typeof useMarketingCourseDetailsQuery
>
export type MarketingCourseDetailsLazyQueryHookResult = ReturnType<
  typeof useMarketingCourseDetailsLazyQuery
>
export type MarketingCourseDetailsQueryResult = Apollo.QueryResult<
  MarketingCourseDetailsQuery,
  MarketingCourseDetailsQueryVariables
>
export const MarketingHomepageDocument = gql`
  query marketingHomepage {
    marketingHomepage {
      id
      title
      seo {
        ...PageSeoFragment
      }
      content {
        title
        subtitle
        mainCta {
          ...CtaFragment
        }
        secondaryCta {
          ...CtaFragment
        }
        logos {
          ...ImageFragment
        }
        testimonials {
          name
          quote
          positionAndCompany
          avatar {
            ...ImageFragment
          }
        }
        artifactsAndGuidesTitle
        artifactsAndGuidesSubtitle
        artifactsBenefits
        artifactsCta {
          ...CtaFragment
        }
        guidesBenefits
        guidesCta {
          ...CtaFragment
        }
        aiTitle
        aiSubtitle
        aiCta {
          ...CtaFragment
        }
        aiExtensionCta {
          ...CtaFragment
        }
        extensionTitle
        extensionSubtitle
        extensionApps {
          id
          appName
          appLogo {
            ...ImageFragment
          }
        }
        membershipTitle
        membershipSubtitle
        membershipBenefits1
        membershipBenefits1Cta {
          ...CtaFragment
        }
        membershipBenefits2
        membershipBenefits2Cta {
          ...CtaFragment
        }
        teamsTitle
        teamsSubtitle
        teamsBenefitsImage {
          imageUrl
          imageAlt
          aspectRatio
        }
        teamsBenefits {
          id
          title
          description
        }
        teamsCta {
          ...CtaFragment
        }
        bottomTitle
        bottomCta {
          ...CtaFragment
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${CtaFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __useMarketingHomepageQuery__
 *
 * To run a query within a React component, call `useMarketingHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingHomepageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketingHomepageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketingHomepageQuery,
    MarketingHomepageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MarketingHomepageQuery, MarketingHomepageQueryVariables>(
    MarketingHomepageDocument,
    options
  )
}
export function useMarketingHomepageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingHomepageQuery,
    MarketingHomepageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MarketingHomepageQuery, MarketingHomepageQueryVariables>(
    MarketingHomepageDocument,
    options
  )
}
export type MarketingHomepageQueryHookResult = ReturnType<
  typeof useMarketingHomepageQuery
>
export type MarketingHomepageLazyQueryHookResult = ReturnType<
  typeof useMarketingHomepageLazyQuery
>
export type MarketingHomepageQueryResult = Apollo.QueryResult<
  MarketingHomepageQuery,
  MarketingHomepageQueryVariables
>
export const MarketingSiteSettingsDocument = gql`
  query marketingSiteSettings {
    marketingSiteSettings {
      title
      cookieConsent {
        enabled
        message
        url
      }
      seo {
        metaTitle
        metaDesc
        shareTitle
        shareDesc
        shareGraphic
      }
    }
  }
`

/**
 * __useMarketingSiteSettingsQuery__
 *
 * To run a query within a React component, call `useMarketingSiteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingSiteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingSiteSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketingSiteSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketingSiteSettingsQuery,
    MarketingSiteSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MarketingSiteSettingsQuery, MarketingSiteSettingsQueryVariables>(
    MarketingSiteSettingsDocument,
    options
  )
}
export function useMarketingSiteSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingSiteSettingsQuery,
    MarketingSiteSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketingSiteSettingsQuery,
    MarketingSiteSettingsQueryVariables
  >(MarketingSiteSettingsDocument, options)
}
export type MarketingSiteSettingsQueryHookResult = ReturnType<
  typeof useMarketingSiteSettingsQuery
>
export type MarketingSiteSettingsLazyQueryHookResult = ReturnType<
  typeof useMarketingSiteSettingsLazyQuery
>
export type MarketingSiteSettingsQueryResult = Apollo.QueryResult<
  MarketingSiteSettingsQuery,
  MarketingSiteSettingsQueryVariables
>
export const MyCoursesDocument = gql`
  query myCourses {
    currentUser {
      ...MyCoursesCurrentUserParts
    }
    legacyCourses: marketingCourses(courseType: "legacy") {
      ...MarketingCourseCourseCardParts
    }
    marketplaceCourses: marketingCourses(courseType: "marketplace") {
      ...MarketingCourseCourseCardParts
    }
  }
  ${MyCoursesCurrentUserPartsFragmentDoc}
  ${MarketingCourseCourseCardPartsFragmentDoc}
`

/**
 * __useMyCoursesQuery__
 *
 * To run a query within a React component, call `useMyCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<MyCoursesQuery, MyCoursesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyCoursesQuery, MyCoursesQueryVariables>(
    MyCoursesDocument,
    options
  )
}
export function useMyCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCoursesQuery, MyCoursesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyCoursesQuery, MyCoursesQueryVariables>(
    MyCoursesDocument,
    options
  )
}
export type MyCoursesQueryHookResult = ReturnType<typeof useMyCoursesQuery>
export type MyCoursesLazyQueryHookResult = ReturnType<typeof useMyCoursesLazyQuery>
export type MyCoursesQueryResult = Apollo.QueryResult<
  MyCoursesQuery,
  MyCoursesQueryVariables
>
export const PodcastIndexDocument = gql`
  query podcastIndex($limit: Int, $lastId: String, $reverse: Boolean) {
    podcastIndex(limit: $limit, lastId: $lastId, reverse: $reverse) {
      page {
        id
        title
        seo {
          ...PageSeoFragment
        }
        content {
          heading
          description
          mainImage {
            ...ImageFragment
          }
          platforms {
            id
            title
            platformImage {
              ...ImageFragment
            }
            platformLink
          }
          hosts {
            id
            shortBio
            artifactAuthor {
              id
              slug
              name
              avatarPhoto {
                ...ImageFragment
              }
            }
          }
          formTitle
        }
      }
      podcasts {
        id
        createdAt
        slug
        title
        summaryText
        summaryImage {
          ...ImageFragment
        }
      }
      nextPodcastsCount
      prevPodcastsCount
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __usePodcastIndexQuery__
 *
 * To run a query within a React component, call `usePodcastIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodcastIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodcastIndexQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      lastId: // value for 'lastId'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function usePodcastIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<PodcastIndexQuery, PodcastIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PodcastIndexQuery, PodcastIndexQueryVariables>(
    PodcastIndexDocument,
    options
  )
}
export function usePodcastIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PodcastIndexQuery, PodcastIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PodcastIndexQuery, PodcastIndexQueryVariables>(
    PodcastIndexDocument,
    options
  )
}
export type PodcastIndexQueryHookResult = ReturnType<typeof usePodcastIndexQuery>
export type PodcastIndexLazyQueryHookResult = ReturnType<typeof usePodcastIndexLazyQuery>
export type PodcastIndexQueryResult = Apollo.QueryResult<
  PodcastIndexQuery,
  PodcastIndexQueryVariables
>
export const PodcastDetailsDocument = gql`
  query podcastDetails($slug: String!) {
    podcast(slug: $slug) {
      createdAt
      updatedAt
      id
      title
      seo {
        ...PageSeoFragment
      }
      podcast {
        id
        title
        ctaButton {
          enabled
          ctaText
          ctaLink
          withReforgeSymbol
        }
        contentBlock
        summaryImage {
          ...ImageFragment
        }
        hosts
        topics
        listenOn
        releaseDate
        nextEpisode {
          title
          slug
        }
        prevEpisode {
          title
          slug
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
`

/**
 * __usePodcastDetailsQuery__
 *
 * To run a query within a React component, call `usePodcastDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodcastDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodcastDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePodcastDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<PodcastDetailsQuery, PodcastDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PodcastDetailsQuery, PodcastDetailsQueryVariables>(
    PodcastDetailsDocument,
    options
  )
}
export function usePodcastDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PodcastDetailsQuery,
    PodcastDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PodcastDetailsQuery, PodcastDetailsQueryVariables>(
    PodcastDetailsDocument,
    options
  )
}
export type PodcastDetailsQueryHookResult = ReturnType<typeof usePodcastDetailsQuery>
export type PodcastDetailsLazyQueryHookResult = ReturnType<
  typeof usePodcastDetailsLazyQuery
>
export type PodcastDetailsQueryResult = Apollo.QueryResult<
  PodcastDetailsQuery,
  PodcastDetailsQueryVariables
>
export const PricingPageDocument = gql`
  query pricingPage {
    pricingPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
      content {
        title
        subtitle
        logoSectionTitle
        logos {
          ...ImageFragment
        }
        reforgeComparisons {
          name
          cost
          audience
          timeCommitment
          relevance
        }
        features {
          id
          feature
          category {
            name
            order
          }
          values {
            id
            enabled
            forPlan
            textValue
          }
        }
        reviews {
          id
          authorName
          authorPosition
          complexContent
          image {
            ...ImageFragment
          }
        }
        membershipSectionTitle
        membershipSectionSubtitle
        membershipSectionVideo {
          playbackId
        }
        caseStudiesTitle
        caseStudiesSubtitle
        caseStudies {
          id
          previewTitle
          slug
          contributors {
            id
            name
            company
            position
            avatarPhoto {
              ...ImageFragment
            }
          }
        }
        faqs {
          ...MarketingFaq
        }
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
  ${ImageFragmentFragmentDoc}
  ${MarketingFaqFragmentDoc}
`

/**
 * __usePricingPageQuery__
 *
 * To run a query within a React component, call `usePricingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<PricingPageQuery, PricingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PricingPageQuery, PricingPageQueryVariables>(
    PricingPageDocument,
    options
  )
}
export function usePricingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingPageQuery, PricingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PricingPageQuery, PricingPageQueryVariables>(
    PricingPageDocument,
    options
  )
}
export type PricingPageQueryHookResult = ReturnType<typeof usePricingPageQuery>
export type PricingPageLazyQueryHookResult = ReturnType<typeof usePricingPageLazyQuery>
export type PricingPageQueryResult = Apollo.QueryResult<
  PricingPageQuery,
  PricingPageQueryVariables
>
export const MarketingReimbursementPageDocument = gql`
  query MarketingReimbursementPage {
    reimbursementPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useMarketingReimbursementPageQuery__
 *
 * To run a query within a React component, call `useMarketingReimbursementPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingReimbursementPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingReimbursementPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketingReimbursementPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketingReimbursementPageQuery,
    MarketingReimbursementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MarketingReimbursementPageQuery,
    MarketingReimbursementPageQueryVariables
  >(MarketingReimbursementPageDocument, options)
}
export function useMarketingReimbursementPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketingReimbursementPageQuery,
    MarketingReimbursementPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MarketingReimbursementPageQuery,
    MarketingReimbursementPageQueryVariables
  >(MarketingReimbursementPageDocument, options)
}
export type MarketingReimbursementPageQueryHookResult = ReturnType<
  typeof useMarketingReimbursementPageQuery
>
export type MarketingReimbursementPageLazyQueryHookResult = ReturnType<
  typeof useMarketingReimbursementPageLazyQuery
>
export type MarketingReimbursementPageQueryResult = Apollo.QueryResult<
  MarketingReimbursementPageQuery,
  MarketingReimbursementPageQueryVariables
>
export const TeamsGetInTouchPageDocument = gql`
  query teamsGetInTouchPage {
    teamsGetInTouchPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useTeamsGetInTouchPageQuery__
 *
 * To run a query within a React component, call `useTeamsGetInTouchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsGetInTouchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsGetInTouchPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsGetInTouchPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamsGetInTouchPageQuery,
    TeamsGetInTouchPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamsGetInTouchPageQuery, TeamsGetInTouchPageQueryVariables>(
    TeamsGetInTouchPageDocument,
    options
  )
}
export function useTeamsGetInTouchPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamsGetInTouchPageQuery,
    TeamsGetInTouchPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamsGetInTouchPageQuery, TeamsGetInTouchPageQueryVariables>(
    TeamsGetInTouchPageDocument,
    options
  )
}
export type TeamsGetInTouchPageQueryHookResult = ReturnType<
  typeof useTeamsGetInTouchPageQuery
>
export type TeamsGetInTouchPageLazyQueryHookResult = ReturnType<
  typeof useTeamsGetInTouchPageLazyQuery
>
export type TeamsGetInTouchPageQueryResult = Apollo.QueryResult<
  TeamsGetInTouchPageQuery,
  TeamsGetInTouchPageQueryVariables
>
export const TeamsMarketingPageDocument = gql`
  query teamsMarketingPage {
    teamsMarketingPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useTeamsMarketingPageQuery__
 *
 * To run a query within a React component, call `useTeamsMarketingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsMarketingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsMarketingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsMarketingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TeamsMarketingPageQuery,
    TeamsMarketingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamsMarketingPageQuery, TeamsMarketingPageQueryVariables>(
    TeamsMarketingPageDocument,
    options
  )
}
export function useTeamsMarketingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamsMarketingPageQuery,
    TeamsMarketingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamsMarketingPageQuery, TeamsMarketingPageQueryVariables>(
    TeamsMarketingPageDocument,
    options
  )
}
export type TeamsMarketingPageQueryHookResult = ReturnType<
  typeof useTeamsMarketingPageQuery
>
export type TeamsMarketingPageLazyQueryHookResult = ReturnType<
  typeof useTeamsMarketingPageLazyQuery
>
export type TeamsMarketingPageQueryResult = Apollo.QueryResult<
  TeamsMarketingPageQuery,
  TeamsMarketingPageQueryVariables
>
export const TermsOfServicePageDocument = gql`
  query TermsOfServicePage {
    termsOfServicePage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __useTermsOfServicePageQuery__
 *
 * To run a query within a React component, call `useTermsOfServicePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsOfServicePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsOfServicePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useTermsOfServicePageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TermsOfServicePageQuery,
    TermsOfServicePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>(
    TermsOfServicePageDocument,
    options
  )
}
export function useTermsOfServicePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TermsOfServicePageQuery,
    TermsOfServicePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TermsOfServicePageQuery, TermsOfServicePageQueryVariables>(
    TermsOfServicePageDocument,
    options
  )
}
export type TermsOfServicePageQueryHookResult = ReturnType<
  typeof useTermsOfServicePageQuery
>
export type TermsOfServicePageLazyQueryHookResult = ReturnType<
  typeof useTermsOfServicePageLazyQuery
>
export type TermsOfServicePageQueryResult = Apollo.QueryResult<
  TermsOfServicePageQuery,
  TermsOfServicePageQueryVariables
>
export const PrivacyPolicyPageDocument = gql`
  query PrivacyPolicyPage {
    privacyPolicyPage {
      id
      title
      seo {
        ...PageSeoFragment
      }
    }
  }
  ${PageSeoFragmentFragmentDoc}
`

/**
 * __usePrivacyPolicyPageQuery__
 *
 * To run a query within a React component, call `usePrivacyPolicyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivacyPolicyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivacyPolicyPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrivacyPolicyPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PrivacyPolicyPageQuery,
    PrivacyPolicyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrivacyPolicyPageQuery, PrivacyPolicyPageQueryVariables>(
    PrivacyPolicyPageDocument,
    options
  )
}
export function usePrivacyPolicyPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PrivacyPolicyPageQuery,
    PrivacyPolicyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrivacyPolicyPageQuery, PrivacyPolicyPageQueryVariables>(
    PrivacyPolicyPageDocument,
    options
  )
}
export type PrivacyPolicyPageQueryHookResult = ReturnType<
  typeof usePrivacyPolicyPageQuery
>
export type PrivacyPolicyPageLazyQueryHookResult = ReturnType<
  typeof usePrivacyPolicyPageLazyQuery
>
export type PrivacyPolicyPageQueryResult = Apollo.QueryResult<
  PrivacyPolicyPageQuery,
  PrivacyPolicyPageQueryVariables
>
export const MentionSearchDocument = gql`
  query mentionSearch($nameQuery: String!) {
    mentionSearch(nameQuery: $nameQuery) {
      id
      name
      title
      slug
    }
  }
`

/**
 * __useMentionSearchQuery__
 *
 * To run a query within a React component, call `useMentionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMentionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMentionSearchQuery({
 *   variables: {
 *      nameQuery: // value for 'nameQuery'
 *   },
 * });
 */
export function useMentionSearchQuery(
  baseOptions: Apollo.QueryHookOptions<MentionSearchQuery, MentionSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MentionSearchQuery, MentionSearchQueryVariables>(
    MentionSearchDocument,
    options
  )
}
export function useMentionSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MentionSearchQuery,
    MentionSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MentionSearchQuery, MentionSearchQueryVariables>(
    MentionSearchDocument,
    options
  )
}
export type MentionSearchQueryHookResult = ReturnType<typeof useMentionSearchQuery>
export type MentionSearchLazyQueryHookResult = ReturnType<
  typeof useMentionSearchLazyQuery
>
export type MentionSearchQueryResult = Apollo.QueryResult<
  MentionSearchQuery,
  MentionSearchQueryVariables
>
export const TeamMentionSearchDocument = gql`
  query teamMentionSearch($nameQuery: String!) {
    teamMentionSearch(nameQuery: $nameQuery) {
      id
      name
      title
      slug
    }
  }
`

/**
 * __useTeamMentionSearchQuery__
 *
 * To run a query within a React component, call `useTeamMentionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMentionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMentionSearchQuery({
 *   variables: {
 *      nameQuery: // value for 'nameQuery'
 *   },
 * });
 */
export function useTeamMentionSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamMentionSearchQuery,
    TeamMentionSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamMentionSearchQuery, TeamMentionSearchQueryVariables>(
    TeamMentionSearchDocument,
    options
  )
}
export function useTeamMentionSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMentionSearchQuery,
    TeamMentionSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamMentionSearchQuery, TeamMentionSearchQueryVariables>(
    TeamMentionSearchDocument,
    options
  )
}
export type TeamMentionSearchQueryHookResult = ReturnType<
  typeof useTeamMentionSearchQuery
>
export type TeamMentionSearchLazyQueryHookResult = ReturnType<
  typeof useTeamMentionSearchLazyQuery
>
export type TeamMentionSearchQueryResult = Apollo.QueryResult<
  TeamMentionSearchQuery,
  TeamMentionSearchQueryVariables
>
export const ModuleCompletionStatusDocument = gql`
  query ModuleCompletionStatus($cmsSectionId: ID!) {
    moduleCompletionStatus(cmsSectionId: $cmsSectionId) {
      isComplete
      programPercentComplete
    }
  }
`

/**
 * __useModuleCompletionStatusQuery__
 *
 * To run a query within a React component, call `useModuleCompletionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleCompletionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleCompletionStatusQuery({
 *   variables: {
 *      cmsSectionId: // value for 'cmsSectionId'
 *   },
 * });
 */
export function useModuleCompletionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModuleCompletionStatusQuery,
    ModuleCompletionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ModuleCompletionStatusQuery,
    ModuleCompletionStatusQueryVariables
  >(ModuleCompletionStatusDocument, options)
}
export function useModuleCompletionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModuleCompletionStatusQuery,
    ModuleCompletionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModuleCompletionStatusQuery,
    ModuleCompletionStatusQueryVariables
  >(ModuleCompletionStatusDocument, options)
}
export type ModuleCompletionStatusQueryHookResult = ReturnType<
  typeof useModuleCompletionStatusQuery
>
export type ModuleCompletionStatusLazyQueryHookResult = ReturnType<
  typeof useModuleCompletionStatusLazyQuery
>
export type ModuleCompletionStatusQueryResult = Apollo.QueryResult<
  ModuleCompletionStatusQuery,
  ModuleCompletionStatusQueryVariables
>
export const PlansForSaleDocument = gql`
  query PlansForSale {
    plansForSale {
      name
      pricePerYear
      maxSeatCount
      pricePerPerson
      stripeId
    }
  }
`

/**
 * __usePlansForSaleQuery__
 *
 * To run a query within a React component, call `usePlansForSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansForSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansForSaleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansForSaleQuery(
  baseOptions?: Apollo.QueryHookOptions<PlansForSaleQuery, PlansForSaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PlansForSaleQuery, PlansForSaleQueryVariables>(
    PlansForSaleDocument,
    options
  )
}
export function usePlansForSaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlansForSaleQuery, PlansForSaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PlansForSaleQuery, PlansForSaleQueryVariables>(
    PlansForSaleDocument,
    options
  )
}
export type PlansForSaleQueryHookResult = ReturnType<typeof usePlansForSaleQuery>
export type PlansForSaleLazyQueryHookResult = ReturnType<typeof usePlansForSaleLazyQuery>
export type PlansForSaleQueryResult = Apollo.QueryResult<
  PlansForSaleQuery,
  PlansForSaleQueryVariables
>
export const PostContributorsFilterDocument = gql`
  query postContributorsFilter(
    $query: String
    $groupSlug: String
    $slugs: [String!]
    $excludeSlugs: [String!]
  ) {
    postContributorsFilter(
      query: $query
      groupSlug: $groupSlug
      slugs: $slugs
      excludeSlugs: $excludeSlugs
    ) {
      id
      slug
      fullName
      avatarUrl
      kind
      hasBadge
    }
  }
`

/**
 * __usePostContributorsFilterQuery__
 *
 * To run a query within a React component, call `usePostContributorsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostContributorsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostContributorsFilterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      groupSlug: // value for 'groupSlug'
 *      slugs: // value for 'slugs'
 *      excludeSlugs: // value for 'excludeSlugs'
 *   },
 * });
 */
export function usePostContributorsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PostContributorsFilterQuery,
    PostContributorsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PostContributorsFilterQuery,
    PostContributorsFilterQueryVariables
  >(PostContributorsFilterDocument, options)
}
export function usePostContributorsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostContributorsFilterQuery,
    PostContributorsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PostContributorsFilterQuery,
    PostContributorsFilterQueryVariables
  >(PostContributorsFilterDocument, options)
}
export type PostContributorsFilterQueryHookResult = ReturnType<
  typeof usePostContributorsFilterQuery
>
export type PostContributorsFilterLazyQueryHookResult = ReturnType<
  typeof usePostContributorsFilterLazyQuery
>
export type PostContributorsFilterQueryResult = Apollo.QueryResult<
  PostContributorsFilterQuery,
  PostContributorsFilterQueryVariables
>
export const PostListDocument = gql`
  query PostList(
    $audience: [String!]
    $businessModels: [String!]
    $contributors: [String!]
    $filter: String
    $group: String
    $industries: [String!]
    $intersectingGroups: [String!]
    $page: Int
    $postSource: PostSource!
    $postType: [String!]
    $sortBy: String
    $topics: [String!]
  ) {
    discussionPosts(
      audience: $audience
      businessModels: $businessModels
      contributors: $contributors
      filter: $filter
      group: $group
      industries: $industries
      intersectingGroups: $intersectingGroups
      page: $page
      postSource: $postSource
      postType: $postType
      sortBy: $sortBy
      topics: $topics
    ) {
      ...PostListFields
    }
  }
  ${PostListFieldsFragmentDoc}
`

/**
 * __usePostListQuery__
 *
 * To run a query within a React component, call `usePostListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostListQuery({
 *   variables: {
 *      audience: // value for 'audience'
 *      businessModels: // value for 'businessModels'
 *      contributors: // value for 'contributors'
 *      filter: // value for 'filter'
 *      group: // value for 'group'
 *      industries: // value for 'industries'
 *      intersectingGroups: // value for 'intersectingGroups'
 *      page: // value for 'page'
 *      postSource: // value for 'postSource'
 *      postType: // value for 'postType'
 *      sortBy: // value for 'sortBy'
 *      topics: // value for 'topics'
 *   },
 * });
 */
export function usePostListQuery(
  baseOptions: Apollo.QueryHookOptions<PostListQuery, PostListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostListQuery, PostListQueryVariables>(PostListDocument, options)
}
export function usePostListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostListQuery, PostListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostListQuery, PostListQueryVariables>(
    PostListDocument,
    options
  )
}
export type PostListQueryHookResult = ReturnType<typeof usePostListQuery>
export type PostListLazyQueryHookResult = ReturnType<typeof usePostListLazyQuery>
export type PostListQueryResult = Apollo.QueryResult<
  PostListQuery,
  PostListQueryVariables
>
export const PostShowDocument = gql`
  query PostShow($slug: String!) {
    discussionPost(slug: $slug) {
      id
      ...PostShowFields
    }
  }
  ${PostShowFieldsFragmentDoc}
`

/**
 * __usePostShowQuery__
 *
 * To run a query within a React component, call `usePostShowQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostShowQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePostShowQuery(
  baseOptions: Apollo.QueryHookOptions<PostShowQuery, PostShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostShowQuery, PostShowQueryVariables>(PostShowDocument, options)
}
export function usePostShowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostShowQuery, PostShowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostShowQuery, PostShowQueryVariables>(
    PostShowDocument,
    options
  )
}
export type PostShowQueryHookResult = ReturnType<typeof usePostShowQuery>
export type PostShowLazyQueryHookResult = ReturnType<typeof usePostShowLazyQuery>
export type PostShowQueryResult = Apollo.QueryResult<
  PostShowQuery,
  PostShowQueryVariables
>
export const PinnedPostsDocument = gql`
  query pinnedPosts {
    pinnedPosts {
      id
      ...HomePagePostsParts
    }
  }
  ${HomePagePostsPartsFragmentDoc}
`

/**
 * __usePinnedPostsQuery__
 *
 * To run a query within a React component, call `usePinnedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePinnedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePinnedPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePinnedPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<PinnedPostsQuery, PinnedPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PinnedPostsQuery, PinnedPostsQueryVariables>(
    PinnedPostsDocument,
    options
  )
}
export function usePinnedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PinnedPostsQuery, PinnedPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PinnedPostsQuery, PinnedPostsQueryVariables>(
    PinnedPostsDocument,
    options
  )
}
export type PinnedPostsQueryHookResult = ReturnType<typeof usePinnedPostsQuery>
export type PinnedPostsLazyQueryHookResult = ReturnType<typeof usePinnedPostsLazyQuery>
export type PinnedPostsQueryResult = Apollo.QueryResult<
  PinnedPostsQuery,
  PinnedPostsQueryVariables
>
export const PostsSearchDocument = gql`
  query PostsSearch($query: String!) {
    postsSearch(query: $query) {
      posts {
        ...PostsSearchPost
      }
      topics {
        id
        title
        topicId
        groupSlug
      }
      members {
        id
        avatarUrl
        fullName
        hasBadge
        kind
        slug
      }
      groups {
        ...PostsSearchGroup
      }
    }
  }
  ${PostsSearchPostFragmentDoc}
  ${PostsSearchGroupFragmentDoc}
`

/**
 * __usePostsSearchQuery__
 *
 * To run a query within a React component, call `usePostsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePostsSearchQuery(
  baseOptions: Apollo.QueryHookOptions<PostsSearchQuery, PostsSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostsSearchQuery, PostsSearchQueryVariables>(
    PostsSearchDocument,
    options
  )
}
export function usePostsSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostsSearchQuery, PostsSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostsSearchQuery, PostsSearchQueryVariables>(
    PostsSearchDocument,
    options
  )
}
export type PostsSearchQueryHookResult = ReturnType<typeof usePostsSearchQuery>
export type PostsSearchLazyQueryHookResult = ReturnType<typeof usePostsSearchLazyQuery>
export type PostsSearchQueryResult = Apollo.QueryResult<
  PostsSearchQuery,
  PostsSearchQueryVariables
>
export const MemberProfileDocument = gql`
  query MemberProfile($userId: ID!) {
    user(id: $userId) {
      id
      ...MemberProfileUserFields
    }
  }
  ${MemberProfileUserFieldsFragmentDoc}
`

/**
 * __useMemberProfileQuery__
 *
 * To run a query within a React component, call `useMemberProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMemberProfileQuery(
  baseOptions: Apollo.QueryHookOptions<MemberProfileQuery, MemberProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MemberProfileQuery, MemberProfileQueryVariables>(
    MemberProfileDocument,
    options
  )
}
export function useMemberProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MemberProfileQuery,
    MemberProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MemberProfileQuery, MemberProfileQueryVariables>(
    MemberProfileDocument,
    options
  )
}
export type MemberProfileQueryHookResult = ReturnType<typeof useMemberProfileQuery>
export type MemberProfileLazyQueryHookResult = ReturnType<
  typeof useMemberProfileLazyQuery
>
export type MemberProfileQueryResult = Apollo.QueryResult<
  MemberProfileQuery,
  MemberProfileQueryVariables
>
export const ProfileEditModalDocument = gql`
  query ProfileEditModal {
    currentUser {
      id
      ...ProfileEditModalUserFields
    }
  }
  ${ProfileEditModalUserFieldsFragmentDoc}
`

/**
 * __useProfileEditModalQuery__
 *
 * To run a query within a React component, call `useProfileEditModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileEditModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileEditModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileEditModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileEditModalQuery,
    ProfileEditModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProfileEditModalQuery, ProfileEditModalQueryVariables>(
    ProfileEditModalDocument,
    options
  )
}
export function useProfileEditModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileEditModalQuery,
    ProfileEditModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProfileEditModalQuery, ProfileEditModalQueryVariables>(
    ProfileEditModalDocument,
    options
  )
}
export type ProfileEditModalQueryHookResult = ReturnType<typeof useProfileEditModalQuery>
export type ProfileEditModalLazyQueryHookResult = ReturnType<
  typeof useProfileEditModalLazyQuery
>
export type ProfileEditModalQueryResult = Apollo.QueryResult<
  ProfileEditModalQuery,
  ProfileEditModalQueryVariables
>
export const AddUserCompanyDocument = gql`
  mutation AddUserCompany($input: AddUserCompanyInput!) {
    addUserCompany(input: $input) {
      user {
        id
        profile {
          previousCompanies {
            id
            ...PreviousCompaniesFields
          }
        }
      }
    }
  }
  ${PreviousCompaniesFieldsFragmentDoc}
`
export type AddUserCompanyMutationFn = Apollo.MutationFunction<
  AddUserCompanyMutation,
  AddUserCompanyMutationVariables
>

/**
 * __useAddUserCompanyMutation__
 *
 * To run a mutation, you first call `useAddUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserCompanyMutation, { data, loading, error }] = useAddUserCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserCompanyMutation,
    AddUserCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddUserCompanyMutation, AddUserCompanyMutationVariables>(
    AddUserCompanyDocument,
    options
  )
}
export type AddUserCompanyMutationHookResult = ReturnType<
  typeof useAddUserCompanyMutation
>
export type AddUserCompanyMutationResult = Apollo.MutationResult<AddUserCompanyMutation>
export type AddUserCompanyMutationOptions = Apollo.BaseMutationOptions<
  AddUserCompanyMutation,
  AddUserCompanyMutationVariables
>
export const DeleteUserCompanyDocument = gql`
  mutation DeleteUserCompany($input: DeleteUserCompanyInput!) {
    deleteUserCompany(input: $input) {
      errors
      user {
        id
        profile {
          previousCompanies {
            id
            ...PreviousCompaniesFields
          }
        }
      }
    }
  }
  ${PreviousCompaniesFieldsFragmentDoc}
`
export type DeleteUserCompanyMutationFn = Apollo.MutationFunction<
  DeleteUserCompanyMutation,
  DeleteUserCompanyMutationVariables
>

/**
 * __useDeleteUserCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserCompanyMutation, { data, loading, error }] = useDeleteUserCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserCompanyMutation,
    DeleteUserCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteUserCompanyMutation,
    DeleteUserCompanyMutationVariables
  >(DeleteUserCompanyDocument, options)
}
export type DeleteUserCompanyMutationHookResult = ReturnType<
  typeof useDeleteUserCompanyMutation
>
export type DeleteUserCompanyMutationResult =
  Apollo.MutationResult<DeleteUserCompanyMutation>
export type DeleteUserCompanyMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserCompanyMutation,
  DeleteUserCompanyMutationVariables
>
export const ProfilePageDocument = gql`
  query ProfilePage($slug: String!) {
    profile(slug: $slug) {
      artifactAuthor {
        ...ArtifactAuthorProfileFragment
      }
      user {
        ...ExpertUserProfileFragment
      }
    }
  }
  ${ArtifactAuthorProfileFragmentFragmentDoc}
  ${ExpertUserProfileFragmentFragmentDoc}
`

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProfilePageQuery(
  baseOptions: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(
    ProfilePageDocument,
    options
  )
}
export function useProfilePageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(
    ProfilePageDocument,
    options
  )
}
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>
export type ProfilePageQueryResult = Apollo.QueryResult<
  ProfilePageQuery,
  ProfilePageQueryVariables
>
export const ArtifactAuthorProfilesDocument = gql`
  query ArtifactAuthorProfiles($slug: [String!]!) {
    artifactAuthorProfiles(slug: $slug) {
      title
      artifactAuthor {
        ...ArtifactAuthorProfileFragment
      }
    }
  }
  ${ArtifactAuthorProfileFragmentFragmentDoc}
`

/**
 * __useArtifactAuthorProfilesQuery__
 *
 * To run a query within a React component, call `useArtifactAuthorProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactAuthorProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactAuthorProfilesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArtifactAuthorProfilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArtifactAuthorProfilesQuery,
    ArtifactAuthorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ArtifactAuthorProfilesQuery,
    ArtifactAuthorProfilesQueryVariables
  >(ArtifactAuthorProfilesDocument, options)
}
export function useArtifactAuthorProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArtifactAuthorProfilesQuery,
    ArtifactAuthorProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ArtifactAuthorProfilesQuery,
    ArtifactAuthorProfilesQueryVariables
  >(ArtifactAuthorProfilesDocument, options)
}
export type ArtifactAuthorProfilesQueryHookResult = ReturnType<
  typeof useArtifactAuthorProfilesQuery
>
export type ArtifactAuthorProfilesLazyQueryHookResult = ReturnType<
  typeof useArtifactAuthorProfilesLazyQuery
>
export type ArtifactAuthorProfilesQueryResult = Apollo.QueryResult<
  ArtifactAuthorProfilesQuery,
  ArtifactAuthorProfilesQueryVariables
>
export const ProgramDetailsDocument = gql`
  query ProgramDetails($cmsProgramId: ID, $slug: String) {
    cmsProgram(cmsProgramId: $cmsProgramId, slug: $slug) {
      name
      id
      currentModule {
        id
      }
      numBookmarks
      cmsModules {
        id
        ...CmsModuleParts
      }
      progressPercent
    }
  }
  ${CmsModulePartsFragmentDoc}
`

/**
 * __useProgramDetailsQuery__
 *
 * To run a query within a React component, call `useProgramDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramDetailsQuery({
 *   variables: {
 *      cmsProgramId: // value for 'cmsProgramId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProgramDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramDetailsQuery, ProgramDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramDetailsQuery, ProgramDetailsQueryVariables>(
    ProgramDetailsDocument,
    options
  )
}
export function useProgramDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramDetailsQuery,
    ProgramDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramDetailsQuery, ProgramDetailsQueryVariables>(
    ProgramDetailsDocument,
    options
  )
}
export type ProgramDetailsQueryHookResult = ReturnType<typeof useProgramDetailsQuery>
export type ProgramDetailsLazyQueryHookResult = ReturnType<
  typeof useProgramDetailsLazyQuery
>
export type ProgramDetailsQueryResult = Apollo.QueryResult<
  ProgramDetailsQuery,
  ProgramDetailsQueryVariables
>
export const ProgramSummaryDocument = gql`
  query ProgramSummary($cmsProgramId: ID, $slug: String) {
    cmsProgram(cmsProgramId: $cmsProgramId, slug: $slug) {
      ...ProgramSummaryParts
    }
  }
  ${ProgramSummaryPartsFragmentDoc}
`

/**
 * __useProgramSummaryQuery__
 *
 * To run a query within a React component, call `useProgramSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramSummaryQuery({
 *   variables: {
 *      cmsProgramId: // value for 'cmsProgramId'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProgramSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramSummaryQuery, ProgramSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramSummaryQuery, ProgramSummaryQueryVariables>(
    ProgramSummaryDocument,
    options
  )
}
export function useProgramSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramSummaryQuery,
    ProgramSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramSummaryQuery, ProgramSummaryQueryVariables>(
    ProgramSummaryDocument,
    options
  )
}
export type ProgramSummaryQueryHookResult = ReturnType<typeof useProgramSummaryQuery>
export type ProgramSummaryLazyQueryHookResult = ReturnType<
  typeof useProgramSummaryLazyQuery
>
export type ProgramSummaryQueryResult = Apollo.QueryResult<
  ProgramSummaryQuery,
  ProgramSummaryQueryVariables
>
export const CmsProgramPreviewDocument = gql`
  query CmsProgramPreview($slug: String) {
    cmsProgram(slug: $slug) {
      id
      ...CmsProgramPreview
      ...UpcomingProgram
    }
  }
  ${CmsProgramPreviewFragmentDoc}
  ${UpcomingProgramFragmentDoc}
`

/**
 * __useCmsProgramPreviewQuery__
 *
 * To run a query within a React component, call `useCmsProgramPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsProgramPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsProgramPreviewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCmsProgramPreviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CmsProgramPreviewQuery,
    CmsProgramPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CmsProgramPreviewQuery, CmsProgramPreviewQueryVariables>(
    CmsProgramPreviewDocument,
    options
  )
}
export function useCmsProgramPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CmsProgramPreviewQuery,
    CmsProgramPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CmsProgramPreviewQuery, CmsProgramPreviewQueryVariables>(
    CmsProgramPreviewDocument,
    options
  )
}
export type CmsProgramPreviewQueryHookResult = ReturnType<
  typeof useCmsProgramPreviewQuery
>
export type CmsProgramPreviewLazyQueryHookResult = ReturnType<
  typeof useCmsProgramPreviewLazyQuery
>
export type CmsProgramPreviewQueryResult = Apollo.QueryResult<
  CmsProgramPreviewQuery,
  CmsProgramPreviewQueryVariables
>
export const ProgramIndexDocument = gql`
  query programIndex {
    currentUser {
      is {
        enrolledInActiveCourse
      }
      userCourses {
        all {
          id
          courseSession {
            ...ProgramIndexUserCourseSessionFields
          }
        }
      }
    }
    cmsPrograms {
      id
      ...CmsProgramListParts
    }
    upcomingSeason {
      activeAt
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      hasStarted
      id
      name
      nextSeasonName
      openForEnrollment
      prettyNameSeasonFirst
      startsAt
      year
      showBlogPostAnnouncementAt
      blogPostAnnouncementUrl
    }
    enrollmentSeason {
      activeAt
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      hasStarted
      id
      name
      nextSeasonName
      openForEnrollment
      prettyNameSeasonFirst
      startsAt
      year
      showBlogPostAnnouncementAt
      blogPostAnnouncementUrl
    }
    lastActiveSeason {
      activeAt
      endsAt
      enrollmentsCloseAt
      enrollmentsOpenAt
      hasStarted
      id
      name
      nextSeasonName
      openForEnrollment
      prettyNameSeasonFirst
      startsAt
      year
      showBlogPostAnnouncementAt
      blogPostAnnouncementUrl
    }
    currentLivePrograms: userCohorts(timePeriod: "current") {
      ...UserCohortPart
    }
    pastLivePrograms: userCohorts(timePeriod: "past") {
      ...UserCohortPart
    }
  }
  ${ProgramIndexUserCourseSessionFieldsFragmentDoc}
  ${CmsProgramListPartsFragmentDoc}
  ${UserCohortPartFragmentDoc}
`

/**
 * __useProgramIndexQuery__
 *
 * To run a query within a React component, call `useProgramIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramIndexQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramIndexQuery(
  baseOptions?: Apollo.QueryHookOptions<ProgramIndexQuery, ProgramIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramIndexQuery, ProgramIndexQueryVariables>(
    ProgramIndexDocument,
    options
  )
}
export function useProgramIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProgramIndexQuery, ProgramIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramIndexQuery, ProgramIndexQueryVariables>(
    ProgramIndexDocument,
    options
  )
}
export type ProgramIndexQueryHookResult = ReturnType<typeof useProgramIndexQuery>
export type ProgramIndexLazyQueryHookResult = ReturnType<typeof useProgramIndexLazyQuery>
export type ProgramIndexQueryResult = Apollo.QueryResult<
  ProgramIndexQuery,
  ProgramIndexQueryVariables
>
export const UserProgramTeammatesDocument = gql`
  query userProgramTeammates($slug: String!) {
    currentUser {
      id
      programTeammates(slug: $slug) {
        ...ProgramTeammatesParts
      }
    }
  }
  ${ProgramTeammatesPartsFragmentDoc}
`

/**
 * __useUserProgramTeammatesQuery__
 *
 * To run a query within a React component, call `useUserProgramTeammatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProgramTeammatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProgramTeammatesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserProgramTeammatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserProgramTeammatesQuery,
    UserProgramTeammatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserProgramTeammatesQuery, UserProgramTeammatesQueryVariables>(
    UserProgramTeammatesDocument,
    options
  )
}
export function useUserProgramTeammatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserProgramTeammatesQuery,
    UserProgramTeammatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserProgramTeammatesQuery,
    UserProgramTeammatesQueryVariables
  >(UserProgramTeammatesDocument, options)
}
export type UserProgramTeammatesQueryHookResult = ReturnType<
  typeof useUserProgramTeammatesQuery
>
export type UserProgramTeammatesLazyQueryHookResult = ReturnType<
  typeof useUserProgramTeammatesLazyQuery
>
export type UserProgramTeammatesQueryResult = Apollo.QueryResult<
  UserProgramTeammatesQuery,
  UserProgramTeammatesQueryVariables
>
export const ProjectDeliverableDocument = gql`
  query ProjectDeliverable($deliverableId: ID!) {
    projectDeliverable(deliverableId: $deliverableId) {
      id
      url
      name
      title
      url
      templateUrl
    }
  }
`

/**
 * __useProjectDeliverableQuery__
 *
 * To run a query within a React component, call `useProjectDeliverableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDeliverableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDeliverableQuery({
 *   variables: {
 *      deliverableId: // value for 'deliverableId'
 *   },
 * });
 */
export function useProjectDeliverableQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectDeliverableQuery,
    ProjectDeliverableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectDeliverableQuery, ProjectDeliverableQueryVariables>(
    ProjectDeliverableDocument,
    options
  )
}
export function useProjectDeliverableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectDeliverableQuery,
    ProjectDeliverableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectDeliverableQuery, ProjectDeliverableQueryVariables>(
    ProjectDeliverableDocument,
    options
  )
}
export type ProjectDeliverableQueryHookResult = ReturnType<
  typeof useProjectDeliverableQuery
>
export type ProjectDeliverableLazyQueryHookResult = ReturnType<
  typeof useProjectDeliverableLazyQuery
>
export type ProjectDeliverableQueryResult = Apollo.QueryResult<
  ProjectDeliverableQuery,
  ProjectDeliverableQueryVariables
>
export const ProjectsFeedDocument = gql`
  query ProjectsFeed($filters: ContentFilters, $limit: Int, $offset: Int) {
    projects(filters: $filters, limit: $limit, offset: $offset) {
      cmsModuleId
      topics
      count
      content {
        ...ProjectCardContentParts
      }
      userProgram {
        id
        progress
        enrolled
      }
    }
  }
  ${ProjectCardContentPartsFragmentDoc}
`

/**
 * __useProjectsFeedQuery__
 *
 * To run a query within a React component, call `useProjectsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsFeedQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useProjectsFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectsFeedQuery, ProjectsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectsFeedQuery, ProjectsFeedQueryVariables>(
    ProjectsFeedDocument,
    options
  )
}
export function useProjectsFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectsFeedQuery, ProjectsFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectsFeedQuery, ProjectsFeedQueryVariables>(
    ProjectsFeedDocument,
    options
  )
}
export type ProjectsFeedQueryHookResult = ReturnType<typeof useProjectsFeedQuery>
export type ProjectsFeedLazyQueryHookResult = ReturnType<typeof useProjectsFeedLazyQuery>
export type ProjectsFeedQueryResult = Apollo.QueryResult<
  ProjectsFeedQuery,
  ProjectsFeedQueryVariables
>
export const GetApplicableProgramsDocument = gql`
  query getApplicablePrograms {
    applicablePrograms {
      name
      slug
    }
  }
`

/**
 * __useGetApplicableProgramsQuery__
 *
 * To run a query within a React component, call `useGetApplicableProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicableProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicableProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicableProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicableProgramsQuery,
    GetApplicableProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApplicableProgramsQuery, GetApplicableProgramsQueryVariables>(
    GetApplicableProgramsDocument,
    options
  )
}
export function useGetApplicableProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicableProgramsQuery,
    GetApplicableProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetApplicableProgramsQuery,
    GetApplicableProgramsQueryVariables
  >(GetApplicableProgramsDocument, options)
}
export type GetApplicableProgramsQueryHookResult = ReturnType<
  typeof useGetApplicableProgramsQuery
>
export type GetApplicableProgramsLazyQueryHookResult = ReturnType<
  typeof useGetApplicableProgramsLazyQuery
>
export type GetApplicableProgramsQueryResult = Apollo.QueryResult<
  GetApplicableProgramsQuery,
  GetApplicableProgramsQueryVariables
>
export const SearchCompanyDocument = gql`
  query searchCompany($search: String!, $kind: String!) {
    searchCompany(search: $search, kind: $kind)
  }
`

/**
 * __useSearchCompanyQuery__
 *
 * To run a query within a React component, call `useSearchCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyQuery({
 *   variables: {
 *      search: // value for 'search'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useSearchCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<SearchCompanyQuery, SearchCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(
    SearchCompanyDocument,
    options
  )
}
export function useSearchCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCompanyQuery,
    SearchCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchCompanyQuery, SearchCompanyQueryVariables>(
    SearchCompanyDocument,
    options
  )
}
export type SearchCompanyQueryHookResult = ReturnType<typeof useSearchCompanyQuery>
export type SearchCompanyLazyQueryHookResult = ReturnType<
  typeof useSearchCompanyLazyQuery
>
export type SearchCompanyQueryResult = Apollo.QueryResult<
  SearchCompanyQuery,
  SearchCompanyQueryVariables
>
export const SearchCompanyWithClearbitDocument = gql`
  query searchCompanyWithClearbit($search: String!) {
    searchCompanyWithClearbit(search: $search) {
      name
      domain
      logo
    }
  }
`

/**
 * __useSearchCompanyWithClearbitQuery__
 *
 * To run a query within a React component, call `useSearchCompanyWithClearbitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompanyWithClearbitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompanyWithClearbitQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCompanyWithClearbitQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchCompanyWithClearbitQuery,
    SearchCompanyWithClearbitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchCompanyWithClearbitQuery,
    SearchCompanyWithClearbitQueryVariables
  >(SearchCompanyWithClearbitDocument, options)
}
export function useSearchCompanyWithClearbitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchCompanyWithClearbitQuery,
    SearchCompanyWithClearbitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchCompanyWithClearbitQuery,
    SearchCompanyWithClearbitQueryVariables
  >(SearchCompanyWithClearbitDocument, options)
}
export type SearchCompanyWithClearbitQueryHookResult = ReturnType<
  typeof useSearchCompanyWithClearbitQuery
>
export type SearchCompanyWithClearbitLazyQueryHookResult = ReturnType<
  typeof useSearchCompanyWithClearbitLazyQuery
>
export type SearchCompanyWithClearbitQueryResult = Apollo.QueryResult<
  SearchCompanyWithClearbitQuery,
  SearchCompanyWithClearbitQueryVariables
>
export const GetCountryCodeDocument = gql`
  query getCountryCode {
    countryCode
  }
`

/**
 * __useGetCountryCodeQuery__
 *
 * To run a query within a React component, call `useGetCountryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCountryCodeQuery, GetCountryCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCountryCodeQuery, GetCountryCodeQueryVariables>(
    GetCountryCodeDocument,
    options
  )
}
export function useGetCountryCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryCodeQuery,
    GetCountryCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCountryCodeQuery, GetCountryCodeQueryVariables>(
    GetCountryCodeDocument,
    options
  )
}
export type GetCountryCodeQueryHookResult = ReturnType<typeof useGetCountryCodeQuery>
export type GetCountryCodeLazyQueryHookResult = ReturnType<
  typeof useGetCountryCodeLazyQuery
>
export type GetCountryCodeQueryResult = Apollo.QueryResult<
  GetCountryCodeQuery,
  GetCountryCodeQueryVariables
>
export const CreateProgramApplicationDocument = gql`
  mutation createProgramApplication($input: CreateProgramApplicationInput!) {
    createProgramApplication(input: $input) {
      id
      token
      errors
    }
  }
`
export type CreateProgramApplicationMutationFn = Apollo.MutationFunction<
  CreateProgramApplicationMutation,
  CreateProgramApplicationMutationVariables
>

/**
 * __useCreateProgramApplicationMutation__
 *
 * To run a mutation, you first call `useCreateProgramApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramApplicationMutation, { data, loading, error }] = useCreateProgramApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProgramApplicationMutation,
    CreateProgramApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProgramApplicationMutation,
    CreateProgramApplicationMutationVariables
  >(CreateProgramApplicationDocument, options)
}
export type CreateProgramApplicationMutationHookResult = ReturnType<
  typeof useCreateProgramApplicationMutation
>
export type CreateProgramApplicationMutationResult =
  Apollo.MutationResult<CreateProgramApplicationMutation>
export type CreateProgramApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramApplicationMutation,
  CreateProgramApplicationMutationVariables
>
export const SnapshotLandingPageDocument = gql`
  query snapshotLandingPage($snapshotType: String!, $token: String!) {
    snapshotLandingPage(snapshotType: $snapshotType, token: $token) {
      ...SnapshotData
    }
  }
  ${SnapshotDataFragmentDoc}
`

/**
 * __useSnapshotLandingPageQuery__
 *
 * To run a query within a React component, call `useSnapshotLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotLandingPageQuery({
 *   variables: {
 *      snapshotType: // value for 'snapshotType'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSnapshotLandingPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SnapshotLandingPageQuery,
    SnapshotLandingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotLandingPageQuery, SnapshotLandingPageQueryVariables>(
    SnapshotLandingPageDocument,
    options
  )
}
export function useSnapshotLandingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnapshotLandingPageQuery,
    SnapshotLandingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotLandingPageQuery, SnapshotLandingPageQueryVariables>(
    SnapshotLandingPageDocument,
    options
  )
}
export type SnapshotLandingPageQueryHookResult = ReturnType<
  typeof useSnapshotLandingPageQuery
>
export type SnapshotLandingPageLazyQueryHookResult = ReturnType<
  typeof useSnapshotLandingPageLazyQuery
>
export type SnapshotLandingPageQueryResult = Apollo.QueryResult<
  SnapshotLandingPageQuery,
  SnapshotLandingPageQueryVariables
>
export const CompletionSnapshotDocument = gql`
  query completionSnapshot {
    completionSnapshot {
      ...CompletionSnapshotData
    }
  }
  ${CompletionSnapshotDataFragmentDoc}
`

/**
 * __useCompletionSnapshotQuery__
 *
 * To run a query within a React component, call `useCompletionSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletionSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletionSnapshotQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompletionSnapshotQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletionSnapshotQuery,
    CompletionSnapshotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompletionSnapshotQuery, CompletionSnapshotQueryVariables>(
    CompletionSnapshotDocument,
    options
  )
}
export function useCompletionSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletionSnapshotQuery,
    CompletionSnapshotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompletionSnapshotQuery, CompletionSnapshotQueryVariables>(
    CompletionSnapshotDocument,
    options
  )
}
export type CompletionSnapshotQueryHookResult = ReturnType<
  typeof useCompletionSnapshotQuery
>
export type CompletionSnapshotLazyQueryHookResult = ReturnType<
  typeof useCompletionSnapshotLazyQuery
>
export type CompletionSnapshotQueryResult = Apollo.QueryResult<
  CompletionSnapshotQuery,
  CompletionSnapshotQueryVariables
>
export const SubscriptionTeamPreviewDocument = gql`
  query SubscriptionTeamPreview($token: String!, $loginParams: LoginParamsInput) {
    subscriptionTeamPreview(memberToken: $token, loginParams: $loginParams) {
      name
      members {
        id
        profile {
          avatarUrl
        }
      }
      totalMembers
      ssoAuthorization {
        isSsoActive
        authorizationUrl
      }
    }
  }
`

/**
 * __useSubscriptionTeamPreviewQuery__
 *
 * To run a query within a React component, call `useSubscriptionTeamPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionTeamPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionTeamPreviewQuery({
 *   variables: {
 *      token: // value for 'token'
 *      loginParams: // value for 'loginParams'
 *   },
 * });
 */
export function useSubscriptionTeamPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriptionTeamPreviewQuery,
    SubscriptionTeamPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SubscriptionTeamPreviewQuery,
    SubscriptionTeamPreviewQueryVariables
  >(SubscriptionTeamPreviewDocument, options)
}
export function useSubscriptionTeamPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionTeamPreviewQuery,
    SubscriptionTeamPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SubscriptionTeamPreviewQuery,
    SubscriptionTeamPreviewQueryVariables
  >(SubscriptionTeamPreviewDocument, options)
}
export type SubscriptionTeamPreviewQueryHookResult = ReturnType<
  typeof useSubscriptionTeamPreviewQuery
>
export type SubscriptionTeamPreviewLazyQueryHookResult = ReturnType<
  typeof useSubscriptionTeamPreviewLazyQuery
>
export type SubscriptionTeamPreviewQueryResult = Apollo.QueryResult<
  SubscriptionTeamPreviewQuery,
  SubscriptionTeamPreviewQueryVariables
>
export const TestimonialsDocument = gql`
  query Testimonials {
    testimonials {
      id
      userId
      avatarUrl
      name
      role
      copy
      joined
      approvalStatus
      active
    }
  }
`

/**
 * __useTestimonialsQuery__
 *
 * To run a query within a React component, call `useTestimonialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestimonialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestimonialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestimonialsQuery(
  baseOptions?: Apollo.QueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TestimonialsQuery, TestimonialsQueryVariables>(
    TestimonialsDocument,
    options
  )
}
export function useTestimonialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestimonialsQuery, TestimonialsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TestimonialsQuery, TestimonialsQueryVariables>(
    TestimonialsDocument,
    options
  )
}
export type TestimonialsQueryHookResult = ReturnType<typeof useTestimonialsQuery>
export type TestimonialsLazyQueryHookResult = ReturnType<typeof useTestimonialsLazyQuery>
export type TestimonialsQueryResult = Apollo.QueryResult<
  TestimonialsQuery,
  TestimonialsQueryVariables
>
export const UserPatronageDocument = gql`
  query userPatronage($externalId: String!) {
    userPatronage(externalId: $externalId) {
      userExists
      userFullName
      userHasActiveSubscription
      stripePaymentLink
      userIsEligibleForPatronage
    }
  }
`

/**
 * __useUserPatronageQuery__
 *
 * To run a query within a React component, call `useUserPatronageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPatronageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPatronageQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUserPatronageQuery(
  baseOptions: Apollo.QueryHookOptions<UserPatronageQuery, UserPatronageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserPatronageQuery, UserPatronageQueryVariables>(
    UserPatronageDocument,
    options
  )
}
export function useUserPatronageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPatronageQuery,
    UserPatronageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserPatronageQuery, UserPatronageQueryVariables>(
    UserPatronageDocument,
    options
  )
}
export type UserPatronageQueryHookResult = ReturnType<typeof useUserPatronageQuery>
export type UserPatronageLazyQueryHookResult = ReturnType<
  typeof useUserPatronageLazyQuery
>
export type UserPatronageQueryResult = Apollo.QueryResult<
  UserPatronageQuery,
  UserPatronageQueryVariables
>
export const RecommendedCoursesDocument = gql`
  query RecommendedCourses {
    recommendedCourses {
      id
      ...CclCourseCourseCardParts
    }
  }
  ${CclCourseCourseCardPartsFragmentDoc}
`

/**
 * __useRecommendedCoursesQuery__
 *
 * To run a query within a React component, call `useRecommendedCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendedCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecommendedCoursesQuery,
    RecommendedCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecommendedCoursesQuery, RecommendedCoursesQueryVariables>(
    RecommendedCoursesDocument,
    options
  )
}
export function useRecommendedCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedCoursesQuery,
    RecommendedCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecommendedCoursesQuery, RecommendedCoursesQueryVariables>(
    RecommendedCoursesDocument,
    options
  )
}
export type RecommendedCoursesQueryHookResult = ReturnType<
  typeof useRecommendedCoursesQuery
>
export type RecommendedCoursesLazyQueryHookResult = ReturnType<
  typeof useRecommendedCoursesLazyQuery
>
export type RecommendedCoursesQueryResult = Apollo.QueryResult<
  RecommendedCoursesQuery,
  RecommendedCoursesQueryVariables
>
export const RecommendedCollectionsDocument = gql`
  query RecommendedCollections {
    recommendedCollections {
      ...RecommendedCollection
    }
  }
  ${RecommendedCollectionFragmentDoc}
`

/**
 * __useRecommendedCollectionsQuery__
 *
 * To run a query within a React component, call `useRecommendedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendedCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecommendedCollectionsQuery,
    RecommendedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RecommendedCollectionsQuery,
    RecommendedCollectionsQueryVariables
  >(RecommendedCollectionsDocument, options)
}
export function useRecommendedCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedCollectionsQuery,
    RecommendedCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecommendedCollectionsQuery,
    RecommendedCollectionsQueryVariables
  >(RecommendedCollectionsDocument, options)
}
export type RecommendedCollectionsQueryHookResult = ReturnType<
  typeof useRecommendedCollectionsQuery
>
export type RecommendedCollectionsLazyQueryHookResult = ReturnType<
  typeof useRecommendedCollectionsLazyQuery
>
export type RecommendedCollectionsQueryResult = Apollo.QueryResult<
  RecommendedCollectionsQuery,
  RecommendedCollectionsQueryVariables
>
export const RecommendedCollectionDocument = gql`
  query RecommendedCollection($swimlaneId: ID!) {
    recommendedCollection(swimlaneId: $swimlaneId) {
      ...RecommendedCollection
    }
  }
  ${RecommendedCollectionFragmentDoc}
`

/**
 * __useRecommendedCollectionQuery__
 *
 * To run a query within a React component, call `useRecommendedCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedCollectionQuery({
 *   variables: {
 *      swimlaneId: // value for 'swimlaneId'
 *   },
 * });
 */
export function useRecommendedCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendedCollectionQuery,
    RecommendedCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecommendedCollectionQuery, RecommendedCollectionQueryVariables>(
    RecommendedCollectionDocument,
    options
  )
}
export function useRecommendedCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedCollectionQuery,
    RecommendedCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecommendedCollectionQuery,
    RecommendedCollectionQueryVariables
  >(RecommendedCollectionDocument, options)
}
export type RecommendedCollectionQueryHookResult = ReturnType<
  typeof useRecommendedCollectionQuery
>
export type RecommendedCollectionLazyQueryHookResult = ReturnType<
  typeof useRecommendedCollectionLazyQuery
>
export type RecommendedCollectionQueryResult = Apollo.QueryResult<
  RecommendedCollectionQuery,
  RecommendedCollectionQueryVariables
>
export const RecommendedContentDocument = gql`
  query RecommendedContent($cursor: RecommendedContentFeedCursorInput, $limit: Int!) {
    recommendedContent(cursor: $cursor, limit: $limit) {
      results {
        authors
        cclSyncMapId
        commentCount
        companyLogoAltText
        companyLogoUrl
        companyName
        description
        estimatedTimeInMinutes
        isFree
        kind
        localId
        publishDate
        sanityId
        slug
        stepsCount
        thumbnailUrl
        title
        viewerCount
      }
      cursor {
        firstPublishedAt
        cclSyncMapId
      }
    }
  }
`

/**
 * __useRecommendedContentQuery__
 *
 * To run a query within a React component, call `useRecommendedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedContentQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecommendedContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendedContentQuery,
    RecommendedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecommendedContentQuery, RecommendedContentQueryVariables>(
    RecommendedContentDocument,
    options
  )
}
export function useRecommendedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedContentQuery,
    RecommendedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecommendedContentQuery, RecommendedContentQueryVariables>(
    RecommendedContentDocument,
    options
  )
}
export type RecommendedContentQueryHookResult = ReturnType<
  typeof useRecommendedContentQuery
>
export type RecommendedContentLazyQueryHookResult = ReturnType<
  typeof useRecommendedContentLazyQuery
>
export type RecommendedContentQueryResult = Apollo.QueryResult<
  RecommendedContentQuery,
  RecommendedContentQueryVariables
>
export const ReforgeUsagesDocument = gql`
  query reforgeUsages {
    reforgeUsages {
      id
      onboardingCopy
      onboardingSubcopy
      checklistCopy
    }
  }
`

/**
 * __useReforgeUsagesQuery__
 *
 * To run a query within a React component, call `useReforgeUsagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReforgeUsagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReforgeUsagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReforgeUsagesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReforgeUsagesQuery, ReforgeUsagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReforgeUsagesQuery, ReforgeUsagesQueryVariables>(
    ReforgeUsagesDocument,
    options
  )
}
export function useReforgeUsagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReforgeUsagesQuery,
    ReforgeUsagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReforgeUsagesQuery, ReforgeUsagesQueryVariables>(
    ReforgeUsagesDocument,
    options
  )
}
export type ReforgeUsagesQueryHookResult = ReturnType<typeof useReforgeUsagesQuery>
export type ReforgeUsagesLazyQueryHookResult = ReturnType<
  typeof useReforgeUsagesLazyQuery
>
export type ReforgeUsagesQueryResult = Apollo.QueryResult<
  ReforgeUsagesQuery,
  ReforgeUsagesQueryVariables
>
export const ReinstateSeatDocument = gql`
  mutation ReinstateSeat($input: ReinstateSeatInput!) {
    reinstateSeat(input: $input) {
      success
      errors
      subscription {
        id
        seats {
          id
          scheduledForDeletionDate
        }
      }
      errors
    }
  }
`
export type ReinstateSeatMutationFn = Apollo.MutationFunction<
  ReinstateSeatMutation,
  ReinstateSeatMutationVariables
>

/**
 * __useReinstateSeatMutation__
 *
 * To run a mutation, you first call `useReinstateSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateSeatMutation, { data, loading, error }] = useReinstateSeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReinstateSeatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReinstateSeatMutation,
    ReinstateSeatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReinstateSeatMutation, ReinstateSeatMutationVariables>(
    ReinstateSeatDocument,
    options
  )
}
export type ReinstateSeatMutationHookResult = ReturnType<typeof useReinstateSeatMutation>
export type ReinstateSeatMutationResult = Apollo.MutationResult<ReinstateSeatMutation>
export type ReinstateSeatMutationOptions = Apollo.BaseMutationOptions<
  ReinstateSeatMutation,
  ReinstateSeatMutationVariables
>
export const RemoveSeatDocument = gql`
  mutation RemoveSeat($input: RemoveSeatInput!) {
    removeSeat(input: $input) {
      success
      subscription {
        id
        seats {
          id
          scheduledForDeletionDate
        }
      }
      errors
    }
  }
`
export type RemoveSeatMutationFn = Apollo.MutationFunction<
  RemoveSeatMutation,
  RemoveSeatMutationVariables
>

/**
 * __useRemoveSeatMutation__
 *
 * To run a mutation, you first call `useRemoveSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeatMutation, { data, loading, error }] = useRemoveSeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSeatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSeatMutation,
    RemoveSeatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveSeatMutation, RemoveSeatMutationVariables>(
    RemoveSeatDocument,
    options
  )
}
export type RemoveSeatMutationHookResult = ReturnType<typeof useRemoveSeatMutation>
export type RemoveSeatMutationResult = Apollo.MutationResult<RemoveSeatMutation>
export type RemoveSeatMutationOptions = Apollo.BaseMutationOptions<
  RemoveSeatMutation,
  RemoveSeatMutationVariables
>
export const RemoveSubscriptionMemberDocument = gql`
  mutation RemoveSubscriptionMember($input: RemoveSubscriptionMemberInput!) {
    removeSubscriptionMember(input: $input) {
      success
      subscription {
        id
        seats {
          id
        }
      }
      errors
    }
  }
`
export type RemoveSubscriptionMemberMutationFn = Apollo.MutationFunction<
  RemoveSubscriptionMemberMutation,
  RemoveSubscriptionMemberMutationVariables
>

/**
 * __useRemoveSubscriptionMemberMutation__
 *
 * To run a mutation, you first call `useRemoveSubscriptionMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubscriptionMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubscriptionMemberMutation, { data, loading, error }] = useRemoveSubscriptionMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSubscriptionMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSubscriptionMemberMutation,
    RemoveSubscriptionMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveSubscriptionMemberMutation,
    RemoveSubscriptionMemberMutationVariables
  >(RemoveSubscriptionMemberDocument, options)
}
export type RemoveSubscriptionMemberMutationHookResult = ReturnType<
  typeof useRemoveSubscriptionMemberMutation
>
export type RemoveSubscriptionMemberMutationResult =
  Apollo.MutationResult<RemoveSubscriptionMemberMutation>
export type RemoveSubscriptionMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveSubscriptionMemberMutation,
  RemoveSubscriptionMemberMutationVariables
>
export const SanityUnitDocument = gql`
  query sanityUnit(
    $slug: String!
    $courseId: String
    $courseSessionId: String
    $contentMode: String
    $path: String
  ) {
    sanityUnit(
      slug: $slug
      courseId: $courseId
      courseSessionId: $courseSessionId
      contentMode: $contentMode
      path: $path
    ) {
      id
      title
      slug
      whatYouWillDo
      estimatedTimeInMinutes
      stepsCount
      previewable
      subtopicIds
      publishDate
      showContentGate
      narrationFileUrl
      shortDescription
      overview
      guide {
        id
        staticId
      }
      heroImage {
        ...ImageFragment
      }
      artifacts {
        id
        slug
        thumbnailUrl
        thumbnailPhoto
        viewCount
        commentCount
        title
        authors {
          id
          name
          slug
          avatarPhoto {
            ...ImageFragment
          }
          position
          company {
            id
            name
            logo {
              ...ImageFragment
            }
            slug
          }
        }
      }
      tags {
        id
        title
      }
      recap
      authors {
        id
        name
        isCollectiveMember
        avatarPhoto {
          ...ImageFragment
        }
        shortBio
        slug
        position
        pastCompanies
        company {
          id
          name
          logo {
            ...ImageFragment
          }
          slug
        }
      }
      contentBlocks {
        ...ContentBlockParts
      }
      contentBookmarkId
      contentIsInMySavedItems
    }
  }
  ${ImageFragmentFragmentDoc}
  ${ContentBlockPartsFragmentDoc}
`

/**
 * __useSanityUnitQuery__
 *
 * To run a query within a React component, call `useSanityUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useSanityUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSanityUnitQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      courseId: // value for 'courseId'
 *      courseSessionId: // value for 'courseSessionId'
 *      contentMode: // value for 'contentMode'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSanityUnitQuery(
  baseOptions: Apollo.QueryHookOptions<SanityUnitQuery, SanityUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SanityUnitQuery, SanityUnitQueryVariables>(
    SanityUnitDocument,
    options
  )
}
export function useSanityUnitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SanityUnitQuery, SanityUnitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SanityUnitQuery, SanityUnitQueryVariables>(
    SanityUnitDocument,
    options
  )
}
export type SanityUnitQueryHookResult = ReturnType<typeof useSanityUnitQuery>
export type SanityUnitLazyQueryHookResult = ReturnType<typeof useSanityUnitLazyQuery>
export type SanityUnitQueryResult = Apollo.QueryResult<
  SanityUnitQuery,
  SanityUnitQueryVariables
>
export const SavedArtifactsAndGuidesForUserDocument = gql`
  query savedArtifactsAndGuidesForUser($userId: ID!) {
    savedArtifactsAndGuidesForUser(userId: $userId) {
      id
      sanityId
      numPeopleWhoBookmarked
      ...ProgramBookmarkParts
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`

/**
 * __useSavedArtifactsAndGuidesForUserQuery__
 *
 * To run a query within a React component, call `useSavedArtifactsAndGuidesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedArtifactsAndGuidesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedArtifactsAndGuidesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSavedArtifactsAndGuidesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    SavedArtifactsAndGuidesForUserQuery,
    SavedArtifactsAndGuidesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SavedArtifactsAndGuidesForUserQuery,
    SavedArtifactsAndGuidesForUserQueryVariables
  >(SavedArtifactsAndGuidesForUserDocument, options)
}
export function useSavedArtifactsAndGuidesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavedArtifactsAndGuidesForUserQuery,
    SavedArtifactsAndGuidesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SavedArtifactsAndGuidesForUserQuery,
    SavedArtifactsAndGuidesForUserQueryVariables
  >(SavedArtifactsAndGuidesForUserDocument, options)
}
export type SavedArtifactsAndGuidesForUserQueryHookResult = ReturnType<
  typeof useSavedArtifactsAndGuidesForUserQuery
>
export type SavedArtifactsAndGuidesForUserLazyQueryHookResult = ReturnType<
  typeof useSavedArtifactsAndGuidesForUserLazyQuery
>
export type SavedArtifactsAndGuidesForUserQueryResult = Apollo.QueryResult<
  SavedArtifactsAndGuidesForUserQuery,
  SavedArtifactsAndGuidesForUserQueryVariables
>
export const SavedArtifactsForUserDocument = gql`
  query savedArtifactsForUser($userId: ID!) {
    savedArtifactsForUser(userId: $userId) {
      id
      sanityId
      numPeopleWhoBookmarked
      ...ProgramBookmarkParts
    }
  }
  ${ProgramBookmarkPartsFragmentDoc}
`

/**
 * __useSavedArtifactsForUserQuery__
 *
 * To run a query within a React component, call `useSavedArtifactsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedArtifactsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedArtifactsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSavedArtifactsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    SavedArtifactsForUserQuery,
    SavedArtifactsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SavedArtifactsForUserQuery, SavedArtifactsForUserQueryVariables>(
    SavedArtifactsForUserDocument,
    options
  )
}
export function useSavedArtifactsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavedArtifactsForUserQuery,
    SavedArtifactsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SavedArtifactsForUserQuery,
    SavedArtifactsForUserQueryVariables
  >(SavedArtifactsForUserDocument, options)
}
export type SavedArtifactsForUserQueryHookResult = ReturnType<
  typeof useSavedArtifactsForUserQuery
>
export type SavedArtifactsForUserLazyQueryHookResult = ReturnType<
  typeof useSavedArtifactsForUserLazyQuery
>
export type SavedArtifactsForUserQueryResult = Apollo.QueryResult<
  SavedArtifactsForUserQuery,
  SavedArtifactsForUserQueryVariables
>
export const SearchDocumentsDocument = gql`
  query SearchDocuments(
    $query: String
    $type: String
    $page: Int
    $program: String
    $topic: String
    $path: String
  ) {
    currentUser {
      id
      launchedEvergreenPrograms {
        id
        name
        slug
      }
    }
    searchDocuments(
      query: $query
      type: $type
      page: $page
      program: $program
      topic: $topic
      path: $path
    ) {
      totalCount
      results {
        id
        ...SearchDocumentResult
      }
    }
  }
  ${SearchDocumentResultFragmentDoc}
`

/**
 * __useSearchDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *      page: // value for 'page'
 *      program: // value for 'program'
 *      topic: // value for 'topic'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSearchDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchDocumentsQuery,
    SearchDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(
    SearchDocumentsDocument,
    options
  )
}
export function useSearchDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchDocumentsQuery,
    SearchDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(
    SearchDocumentsDocument,
    options
  )
}
export type SearchDocumentsQueryHookResult = ReturnType<typeof useSearchDocumentsQuery>
export type SearchDocumentsLazyQueryHookResult = ReturnType<
  typeof useSearchDocumentsLazyQuery
>
export type SearchDocumentsQueryResult = Apollo.QueryResult<
  SearchDocumentsQuery,
  SearchDocumentsQueryVariables
>
export const SearchDocumentsTopResultsDocument = gql`
  query searchDocumentsTopResults($query: String) {
    searchDocumentsTopResults(query: $query) {
      ...SearchDocumentTopResult
    }
  }
  ${SearchDocumentTopResultFragmentDoc}
`

/**
 * __useSearchDocumentsTopResultsQuery__
 *
 * To run a query within a React component, call `useSearchDocumentsTopResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentsTopResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentsTopResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchDocumentsTopResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchDocumentsTopResultsQuery,
    SearchDocumentsTopResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchDocumentsTopResultsQuery,
    SearchDocumentsTopResultsQueryVariables
  >(SearchDocumentsTopResultsDocument, options)
}
export function useSearchDocumentsTopResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchDocumentsTopResultsQuery,
    SearchDocumentsTopResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchDocumentsTopResultsQuery,
    SearchDocumentsTopResultsQueryVariables
  >(SearchDocumentsTopResultsDocument, options)
}
export type SearchDocumentsTopResultsQueryHookResult = ReturnType<
  typeof useSearchDocumentsTopResultsQuery
>
export type SearchDocumentsTopResultsLazyQueryHookResult = ReturnType<
  typeof useSearchDocumentsTopResultsLazyQuery
>
export type SearchDocumentsTopResultsQueryResult = Apollo.QueryResult<
  SearchDocumentsTopResultsQuery,
  SearchDocumentsTopResultsQueryVariables
>
export const SidebarDocument = gql`
  query Sidebar {
    currentUser {
      id
      hasPosts
      groups {
        id
        title
        slug
        userIsMember
        membersCount
      }
      unseenActivityCount
    }
  }
`

/**
 * __useSidebarQuery__
 *
 * To run a query within a React component, call `useSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebarQuery(
  baseOptions?: Apollo.QueryHookOptions<SidebarQuery, SidebarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SidebarQuery, SidebarQueryVariables>(SidebarDocument, options)
}
export function useSidebarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SidebarQuery, SidebarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SidebarQuery, SidebarQueryVariables>(
    SidebarDocument,
    options
  )
}
export type SidebarQueryHookResult = ReturnType<typeof useSidebarQuery>
export type SidebarLazyQueryHookResult = ReturnType<typeof useSidebarLazyQuery>
export type SidebarQueryResult = Apollo.QueryResult<SidebarQuery, SidebarQueryVariables>
export const SimilarPostsDocument = gql`
  query SimilarPosts($title: String!) {
    similarPosts(title: $title) {
      id
      ...SimilarPostFields
    }
  }
  ${SimilarPostFieldsFragmentDoc}
`

/**
 * __useSimilarPostsQuery__
 *
 * To run a query within a React component, call `useSimilarPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarPostsQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useSimilarPostsQuery(
  baseOptions: Apollo.QueryHookOptions<SimilarPostsQuery, SimilarPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SimilarPostsQuery, SimilarPostsQueryVariables>(
    SimilarPostsDocument,
    options
  )
}
export function useSimilarPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimilarPostsQuery, SimilarPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SimilarPostsQuery, SimilarPostsQueryVariables>(
    SimilarPostsDocument,
    options
  )
}
export type SimilarPostsQueryHookResult = ReturnType<typeof useSimilarPostsQuery>
export type SimilarPostsLazyQueryHookResult = ReturnType<typeof useSimilarPostsLazyQuery>
export type SimilarPostsQueryResult = Apollo.QueryResult<
  SimilarPostsQuery,
  SimilarPostsQueryVariables
>
export const SsoAuthorizationDocument = gql`
  query SsoAuthorization($email: String!, $loginParams: LoginParamsInput) {
    ssoAuthorization(email: $email, loginParams: $loginParams) {
      isSsoActive
      authorizationUrl
    }
  }
`

/**
 * __useSsoAuthorizationQuery__
 *
 * To run a query within a React component, call `useSsoAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSsoAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSsoAuthorizationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      loginParams: // value for 'loginParams'
 *   },
 * });
 */
export function useSsoAuthorizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SsoAuthorizationQuery,
    SsoAuthorizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SsoAuthorizationQuery, SsoAuthorizationQueryVariables>(
    SsoAuthorizationDocument,
    options
  )
}
export function useSsoAuthorizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SsoAuthorizationQuery,
    SsoAuthorizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SsoAuthorizationQuery, SsoAuthorizationQueryVariables>(
    SsoAuthorizationDocument,
    options
  )
}
export type SsoAuthorizationQueryHookResult = ReturnType<typeof useSsoAuthorizationQuery>
export type SsoAuthorizationLazyQueryHookResult = ReturnType<
  typeof useSsoAuthorizationLazyQuery
>
export type SsoAuthorizationQueryResult = Apollo.QueryResult<
  SsoAuthorizationQuery,
  SsoAuthorizationQueryVariables
>
export const EnterpriseSsoStatusDocument = gql`
  query enterpriseSsoStatus {
    enterpriseSsoStatus {
      saml {
        status
        errors
      }
      scim {
        status
        errors
      }
    }
  }
`

/**
 * __useEnterpriseSsoStatusQuery__
 *
 * To run a query within a React component, call `useEnterpriseSsoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseSsoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseSsoStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useEnterpriseSsoStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EnterpriseSsoStatusQuery,
    EnterpriseSsoStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EnterpriseSsoStatusQuery, EnterpriseSsoStatusQueryVariables>(
    EnterpriseSsoStatusDocument,
    options
  )
}
export function useEnterpriseSsoStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnterpriseSsoStatusQuery,
    EnterpriseSsoStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EnterpriseSsoStatusQuery, EnterpriseSsoStatusQueryVariables>(
    EnterpriseSsoStatusDocument,
    options
  )
}
export type EnterpriseSsoStatusQueryHookResult = ReturnType<
  typeof useEnterpriseSsoStatusQuery
>
export type EnterpriseSsoStatusLazyQueryHookResult = ReturnType<
  typeof useEnterpriseSsoStatusLazyQuery
>
export type EnterpriseSsoStatusQueryResult = Apollo.QueryResult<
  EnterpriseSsoStatusQuery,
  EnterpriseSsoStatusQueryVariables
>
export const StartingBalanceDocument = gql`
  query startingBalance {
    startingBalance
  }
`

/**
 * __useStartingBalanceQuery__
 *
 * To run a query within a React component, call `useStartingBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartingBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartingBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartingBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StartingBalanceQuery,
    StartingBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StartingBalanceQuery, StartingBalanceQueryVariables>(
    StartingBalanceDocument,
    options
  )
}
export function useStartingBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartingBalanceQuery,
    StartingBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StartingBalanceQuery, StartingBalanceQueryVariables>(
    StartingBalanceDocument,
    options
  )
}
export type StartingBalanceQueryHookResult = ReturnType<typeof useStartingBalanceQuery>
export type StartingBalanceLazyQueryHookResult = ReturnType<
  typeof useStartingBalanceLazyQuery
>
export type StartingBalanceQueryResult = Apollo.QueryResult<
  StartingBalanceQuery,
  StartingBalanceQueryVariables
>
export const SubscriptionJoinRequestDocument = gql`
  query subscriptionJoinRequest($subscriptionJoinRequestId: ID) {
    subscriptionJoinRequest(id: $subscriptionJoinRequestId) {
      id
      ...SubscriptionJoinRequestParts
    }
  }
  ${SubscriptionJoinRequestPartsFragmentDoc}
`

/**
 * __useSubscriptionJoinRequestQuery__
 *
 * To run a query within a React component, call `useSubscriptionJoinRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionJoinRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionJoinRequestQuery({
 *   variables: {
 *      subscriptionJoinRequestId: // value for 'subscriptionJoinRequestId'
 *   },
 * });
 */
export function useSubscriptionJoinRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionJoinRequestQuery,
    SubscriptionJoinRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SubscriptionJoinRequestQuery,
    SubscriptionJoinRequestQueryVariables
  >(SubscriptionJoinRequestDocument, options)
}
export function useSubscriptionJoinRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionJoinRequestQuery,
    SubscriptionJoinRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SubscriptionJoinRequestQuery,
    SubscriptionJoinRequestQueryVariables
  >(SubscriptionJoinRequestDocument, options)
}
export type SubscriptionJoinRequestQueryHookResult = ReturnType<
  typeof useSubscriptionJoinRequestQuery
>
export type SubscriptionJoinRequestLazyQueryHookResult = ReturnType<
  typeof useSubscriptionJoinRequestLazyQuery
>
export type SubscriptionJoinRequestQueryResult = Apollo.QueryResult<
  SubscriptionJoinRequestQuery,
  SubscriptionJoinRequestQueryVariables
>
export const TaxIdReverseChargeStatusDocument = gql`
  query taxIdReverseChargeStatus {
    taxIdReverseChargeStatus {
      isReverseCharge
      taxIdVerificationStatus
    }
  }
`

/**
 * __useTaxIdReverseChargeStatusQuery__
 *
 * To run a query within a React component, call `useTaxIdReverseChargeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxIdReverseChargeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxIdReverseChargeStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaxIdReverseChargeStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaxIdReverseChargeStatusQuery,
    TaxIdReverseChargeStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TaxIdReverseChargeStatusQuery,
    TaxIdReverseChargeStatusQueryVariables
  >(TaxIdReverseChargeStatusDocument, options)
}
export function useTaxIdReverseChargeStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxIdReverseChargeStatusQuery,
    TaxIdReverseChargeStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TaxIdReverseChargeStatusQuery,
    TaxIdReverseChargeStatusQueryVariables
  >(TaxIdReverseChargeStatusDocument, options)
}
export type TaxIdReverseChargeStatusQueryHookResult = ReturnType<
  typeof useTaxIdReverseChargeStatusQuery
>
export type TaxIdReverseChargeStatusLazyQueryHookResult = ReturnType<
  typeof useTaxIdReverseChargeStatusLazyQuery
>
export type TaxIdReverseChargeStatusQueryResult = Apollo.QueryResult<
  TaxIdReverseChargeStatusQuery,
  TaxIdReverseChargeStatusQueryVariables
>
export const TaxIdsDocument = gql`
  query taxIds {
    taxIds {
      country
      createdAt
      id
      stripeCustomerId
      type
      value
      validationStatus
    }
  }
`

/**
 * __useTaxIdsQuery__
 *
 * To run a query within a React component, call `useTaxIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaxIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<TaxIdsQuery, TaxIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaxIdsQuery, TaxIdsQueryVariables>(TaxIdsDocument, options)
}
export function useTaxIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxIdsQuery, TaxIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaxIdsQuery, TaxIdsQueryVariables>(TaxIdsDocument, options)
}
export type TaxIdsQueryHookResult = ReturnType<typeof useTaxIdsQuery>
export type TaxIdsLazyQueryHookResult = ReturnType<typeof useTaxIdsLazyQuery>
export type TaxIdsQueryResult = Apollo.QueryResult<TaxIdsQuery, TaxIdsQueryVariables>
export const TaxInfoDocument = gql`
  query taxInfo($productKeys: [String!]!) {
    taxInfo(productKeys: $productKeys) {
      ...TaxInfoItem
    }
  }
  ${TaxInfoItemFragmentDoc}
`

/**
 * __useTaxInfoQuery__
 *
 * To run a query within a React component, call `useTaxInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxInfoQuery({
 *   variables: {
 *      productKeys: // value for 'productKeys'
 *   },
 * });
 */
export function useTaxInfoQuery(
  baseOptions: Apollo.QueryHookOptions<TaxInfoQuery, TaxInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaxInfoQuery, TaxInfoQueryVariables>(TaxInfoDocument, options)
}
export function useTaxInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaxInfoQuery, TaxInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaxInfoQuery, TaxInfoQueryVariables>(
    TaxInfoDocument,
    options
  )
}
export type TaxInfoQueryHookResult = ReturnType<typeof useTaxInfoQuery>
export type TaxInfoLazyQueryHookResult = ReturnType<typeof useTaxInfoLazyQuery>
export type TaxInfoQueryResult = Apollo.QueryResult<TaxInfoQuery, TaxInfoQueryVariables>
export const SanityTagGroupsDocument = gql`
  query sanityTagGroups {
    sanityTagGroups {
      id
      title
      slug
      sortOrder
      globalSortOrder
      global
      functionTag {
        id
        title
        slug
        sortOrder
      }
    }
  }
`

/**
 * __useSanityTagGroupsQuery__
 *
 * To run a query within a React component, call `useSanityTagGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSanityTagGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSanityTagGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSanityTagGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SanityTagGroupsQuery,
    SanityTagGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SanityTagGroupsQuery, SanityTagGroupsQueryVariables>(
    SanityTagGroupsDocument,
    options
  )
}
export function useSanityTagGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SanityTagGroupsQuery,
    SanityTagGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SanityTagGroupsQuery, SanityTagGroupsQueryVariables>(
    SanityTagGroupsDocument,
    options
  )
}
export type SanityTagGroupsQueryHookResult = ReturnType<typeof useSanityTagGroupsQuery>
export type SanityTagGroupsLazyQueryHookResult = ReturnType<
  typeof useSanityTagGroupsLazyQuery
>
export type SanityTagGroupsQueryResult = Apollo.QueryResult<
  SanityTagGroupsQuery,
  SanityTagGroupsQueryVariables
>
export const SanityTagGroupDocument = gql`
  query sanityTagGroup($slug: String!) {
    sanityTagGroup(slug: $slug) {
      id
      title
      slug
      functionTag {
        id
        title
        slug
      }
    }
  }
`

/**
 * __useSanityTagGroupQuery__
 *
 * To run a query within a React component, call `useSanityTagGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSanityTagGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSanityTagGroupQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSanityTagGroupQuery(
  baseOptions: Apollo.QueryHookOptions<SanityTagGroupQuery, SanityTagGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SanityTagGroupQuery, SanityTagGroupQueryVariables>(
    SanityTagGroupDocument,
    options
  )
}
export function useSanityTagGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SanityTagGroupQuery,
    SanityTagGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SanityTagGroupQuery, SanityTagGroupQueryVariables>(
    SanityTagGroupDocument,
    options
  )
}
export type SanityTagGroupQueryHookResult = ReturnType<typeof useSanityTagGroupQuery>
export type SanityTagGroupLazyQueryHookResult = ReturnType<
  typeof useSanityTagGroupLazyQuery
>
export type SanityTagGroupQueryResult = Apollo.QueryResult<
  SanityTagGroupQuery,
  SanityTagGroupQueryVariables
>
export const TimezonesDocument = gql`
  query Timezones {
    timezones
  }
`

/**
 * __useTimezonesQuery__
 *
 * To run a query within a React component, call `useTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimezonesQuery(
  baseOptions?: Apollo.QueryHookOptions<TimezonesQuery, TimezonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  )
}
export function useTimezonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimezonesQuery, TimezonesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TimezonesQuery, TimezonesQueryVariables>(
    TimezonesDocument,
    options
  )
}
export type TimezonesQueryHookResult = ReturnType<typeof useTimezonesQuery>
export type TimezonesLazyQueryHookResult = ReturnType<typeof useTimezonesLazyQuery>
export type TimezonesQueryResult = Apollo.QueryResult<
  TimezonesQuery,
  TimezonesQueryVariables
>
export const UnassignSeatDocument = gql`
  mutation UnassignSeat($input: UnassignSeatInput!) {
    unassignSeat(input: $input) {
      success
      seat {
        id
        subscriptionMember {
          id
          userId
          email
          status
          role
          createdAt
        }
      }
      errors
    }
  }
`
export type UnassignSeatMutationFn = Apollo.MutationFunction<
  UnassignSeatMutation,
  UnassignSeatMutationVariables
>

/**
 * __useUnassignSeatMutation__
 *
 * To run a mutation, you first call `useUnassignSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSeatMutation, { data, loading, error }] = useUnassignSeatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignSeatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignSeatMutation,
    UnassignSeatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnassignSeatMutation, UnassignSeatMutationVariables>(
    UnassignSeatDocument,
    options
  )
}
export type UnassignSeatMutationHookResult = ReturnType<typeof useUnassignSeatMutation>
export type UnassignSeatMutationResult = Apollo.MutationResult<UnassignSeatMutation>
export type UnassignSeatMutationOptions = Apollo.BaseMutationOptions<
  UnassignSeatMutation,
  UnassignSeatMutationVariables
>
export const UpcomingPostsDocument = gql`
  query UpcomingPosts {
    upcomingPosts {
      allReactionsCount
      createdAt
      href
      id
      responseCount
      title
      user {
        id
        firstName
        lastName
        role
        companyName
      }
    }
  }
`

/**
 * __useUpcomingPostsQuery__
 *
 * To run a query within a React component, call `useUpcomingPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<UpcomingPostsQuery, UpcomingPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UpcomingPostsQuery, UpcomingPostsQueryVariables>(
    UpcomingPostsDocument,
    options
  )
}
export function useUpcomingPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingPostsQuery,
    UpcomingPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UpcomingPostsQuery, UpcomingPostsQueryVariables>(
    UpcomingPostsDocument,
    options
  )
}
export type UpcomingPostsQueryHookResult = ReturnType<typeof useUpcomingPostsQuery>
export type UpcomingPostsLazyQueryHookResult = ReturnType<
  typeof useUpcomingPostsLazyQuery
>
export type UpcomingPostsQueryResult = Apollo.QueryResult<
  UpcomingPostsQuery,
  UpcomingPostsQueryVariables
>
export const UqQuestionnareDocument = gql`
  query UqQuestionnare($questionnaireId: ID!) {
    uqQuestionnaire(id: $questionnaireId) {
      id
      pages
      questions {
        id
        ...OnboardingQuestionParts
      }
      questionnaire {
        id
        ...QuestionnaireFields
      }
    }
  }
  ${OnboardingQuestionPartsFragmentDoc}
  ${QuestionnaireFieldsFragmentDoc}
`

/**
 * __useUqQuestionnareQuery__
 *
 * To run a query within a React component, call `useUqQuestionnareQuery` and pass it any options that fit your needs.
 * When your component renders, `useUqQuestionnareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUqQuestionnareQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useUqQuestionnareQuery(
  baseOptions: Apollo.QueryHookOptions<UqQuestionnareQuery, UqQuestionnareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UqQuestionnareQuery, UqQuestionnareQueryVariables>(
    UqQuestionnareDocument,
    options
  )
}
export function useUqQuestionnareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UqQuestionnareQuery,
    UqQuestionnareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UqQuestionnareQuery, UqQuestionnareQueryVariables>(
    UqQuestionnareDocument,
    options
  )
}
export type UqQuestionnareQueryHookResult = ReturnType<typeof useUqQuestionnareQuery>
export type UqQuestionnareLazyQueryHookResult = ReturnType<
  typeof useUqQuestionnareLazyQuery
>
export type UqQuestionnareQueryResult = Apollo.QueryResult<
  UqQuestionnareQuery,
  UqQuestionnareQueryVariables
>
export const UqUserAnswersDocument = gql`
  query UqUserAnswers {
    uqUserAnswers {
      acceptanceAnswered
      onboardingStep
      answers {
        id
        ...UserAnswer
      }
    }
  }
  ${UserAnswerFragmentDoc}
`

/**
 * __useUqUserAnswersQuery__
 *
 * To run a query within a React component, call `useUqUserAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUqUserAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUqUserAnswersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUqUserAnswersQuery(
  baseOptions?: Apollo.QueryHookOptions<UqUserAnswersQuery, UqUserAnswersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UqUserAnswersQuery, UqUserAnswersQueryVariables>(
    UqUserAnswersDocument,
    options
  )
}
export function useUqUserAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UqUserAnswersQuery,
    UqUserAnswersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UqUserAnswersQuery, UqUserAnswersQueryVariables>(
    UqUserAnswersDocument,
    options
  )
}
export type UqUserAnswersQueryHookResult = ReturnType<typeof useUqUserAnswersQuery>
export type UqUserAnswersLazyQueryHookResult = ReturnType<
  typeof useUqUserAnswersLazyQuery
>
export type UqUserAnswersQueryResult = Apollo.QueryResult<
  UqUserAnswersQuery,
  UqUserAnswersQueryVariables
>
export const UserAccountDetailsDocument = gql`
  query UserAccountDetails($id: ID!) {
    user(id: $id) {
      ...UserAccountDetailsFields
    }
  }
  ${UserAccountDetailsFieldsFragmentDoc}
`

/**
 * __useUserAccountDetailsQuery__
 *
 * To run a query within a React component, call `useUserAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAccountDetailsQuery,
    UserAccountDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>(
    UserAccountDetailsDocument,
    options
  )
}
export function useUserAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAccountDetailsQuery,
    UserAccountDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserAccountDetailsQuery, UserAccountDetailsQueryVariables>(
    UserAccountDetailsDocument,
    options
  )
}
export type UserAccountDetailsQueryHookResult = ReturnType<
  typeof useUserAccountDetailsQuery
>
export type UserAccountDetailsLazyQueryHookResult = ReturnType<
  typeof useUserAccountDetailsLazyQuery
>
export type UserAccountDetailsQueryResult = Apollo.QueryResult<
  UserAccountDetailsQuery,
  UserAccountDetailsQueryVariables
>
export const UserAccountManageSeatsDocument = gql`
  query userAccountManageSeats($id: ID!) {
    user(id: $id) {
      id
      contact {
        primaryEmail
        allEmails
      }
      subscriptions {
        active {
          ...UserAccountManageSeatsSubscription
        }
      }
    }
  }
  ${UserAccountManageSeatsSubscriptionFragmentDoc}
`

/**
 * __useUserAccountManageSeatsQuery__
 *
 * To run a query within a React component, call `useUserAccountManageSeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountManageSeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountManageSeatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAccountManageSeatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAccountManageSeatsQuery,
    UserAccountManageSeatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserAccountManageSeatsQuery,
    UserAccountManageSeatsQueryVariables
  >(UserAccountManageSeatsDocument, options)
}
export function useUserAccountManageSeatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAccountManageSeatsQuery,
    UserAccountManageSeatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserAccountManageSeatsQuery,
    UserAccountManageSeatsQueryVariables
  >(UserAccountManageSeatsDocument, options)
}
export type UserAccountManageSeatsQueryHookResult = ReturnType<
  typeof useUserAccountManageSeatsQuery
>
export type UserAccountManageSeatsLazyQueryHookResult = ReturnType<
  typeof useUserAccountManageSeatsLazyQuery
>
export type UserAccountManageSeatsQueryResult = Apollo.QueryResult<
  UserAccountManageSeatsQuery,
  UserAccountManageSeatsQueryVariables
>
export const UserBookmarksFeedDocument = gql`
  query UserBookmarksFeed(
    $userId: ID!
    $limit: Int
    $offset: Int
    $sort: String
    $filters: BookmarkFilters
  ) {
    user(id: $userId) {
      id
      bookmarksFeed(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
        bookmarks {
          id
          ...Bookmark
        }
        total
      }
    }
  }
  ${BookmarkFragmentDoc}
`

/**
 * __useUserBookmarksFeedQuery__
 *
 * To run a query within a React component, call `useUserBookmarksFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookmarksFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookmarksFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUserBookmarksFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBookmarksFeedQuery,
    UserBookmarksFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserBookmarksFeedQuery, UserBookmarksFeedQueryVariables>(
    UserBookmarksFeedDocument,
    options
  )
}
export function useUserBookmarksFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBookmarksFeedQuery,
    UserBookmarksFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserBookmarksFeedQuery, UserBookmarksFeedQueryVariables>(
    UserBookmarksFeedDocument,
    options
  )
}
export type UserBookmarksFeedQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedQuery
>
export type UserBookmarksFeedLazyQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedLazyQuery
>
export type UserBookmarksFeedQueryResult = Apollo.QueryResult<
  UserBookmarksFeedQuery,
  UserBookmarksFeedQueryVariables
>
export const BookmarksFeedDocument = gql`
  query BookmarksFeed(
    $limit: Int
    $offset: Int
    $sort: String
    $filters: BookmarkFilters
  ) {
    bookmarksFeed(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
      bookmarks {
        id
        ...Bookmark
      }
      total
    }
  }
  ${BookmarkFragmentDoc}
`

/**
 * __useBookmarksFeedQuery__
 *
 * To run a query within a React component, call `useBookmarksFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksFeedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBookmarksFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<BookmarksFeedQuery, BookmarksFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookmarksFeedQuery, BookmarksFeedQueryVariables>(
    BookmarksFeedDocument,
    options
  )
}
export function useBookmarksFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookmarksFeedQuery,
    BookmarksFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookmarksFeedQuery, BookmarksFeedQueryVariables>(
    BookmarksFeedDocument,
    options
  )
}
export type BookmarksFeedQueryHookResult = ReturnType<typeof useBookmarksFeedQuery>
export type BookmarksFeedLazyQueryHookResult = ReturnType<
  typeof useBookmarksFeedLazyQuery
>
export type BookmarksFeedQueryResult = Apollo.QueryResult<
  BookmarksFeedQuery,
  BookmarksFeedQueryVariables
>
export const UserBookmarksFeedFiltersDocument = gql`
  query UserBookmarksFeedFilters(
    $userId: ID!
    $filters: BookmarkFilters
    $moduleFilters: BookmarkModulesFilters
  ) {
    user(id: $userId) {
      id
      bookmarksTotal(filters: $filters)
      bookmarkCmsModules(moduleFilters: $moduleFilters) {
        id
        name
      }
      bookmarkCmsPrograms {
        id
        name
      }
    }
  }
`

/**
 * __useUserBookmarksFeedFiltersQuery__
 *
 * To run a query within a React component, call `useUserBookmarksFeedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookmarksFeedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookmarksFeedFiltersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filters: // value for 'filters'
 *      moduleFilters: // value for 'moduleFilters'
 *   },
 * });
 */
export function useUserBookmarksFeedFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBookmarksFeedFiltersQuery,
    UserBookmarksFeedFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserBookmarksFeedFiltersQuery,
    UserBookmarksFeedFiltersQueryVariables
  >(UserBookmarksFeedFiltersDocument, options)
}
export function useUserBookmarksFeedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBookmarksFeedFiltersQuery,
    UserBookmarksFeedFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserBookmarksFeedFiltersQuery,
    UserBookmarksFeedFiltersQueryVariables
  >(UserBookmarksFeedFiltersDocument, options)
}
export type UserBookmarksFeedFiltersQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedFiltersQuery
>
export type UserBookmarksFeedFiltersLazyQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedFiltersLazyQuery
>
export type UserBookmarksFeedFiltersQueryResult = Apollo.QueryResult<
  UserBookmarksFeedFiltersQuery,
  UserBookmarksFeedFiltersQueryVariables
>
export const AllUserBookmarksProgramsDocument = gql`
  query AllUserBookmarksPrograms($userId: ID!) {
    user(id: $userId) {
      id
      bookmarkCmsPrograms {
        id
        name
      }
    }
  }
`

/**
 * __useAllUserBookmarksProgramsQuery__
 *
 * To run a query within a React component, call `useAllUserBookmarksProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserBookmarksProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserBookmarksProgramsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllUserBookmarksProgramsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllUserBookmarksProgramsQuery,
    AllUserBookmarksProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AllUserBookmarksProgramsQuery,
    AllUserBookmarksProgramsQueryVariables
  >(AllUserBookmarksProgramsDocument, options)
}
export function useAllUserBookmarksProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUserBookmarksProgramsQuery,
    AllUserBookmarksProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AllUserBookmarksProgramsQuery,
    AllUserBookmarksProgramsQueryVariables
  >(AllUserBookmarksProgramsDocument, options)
}
export type AllUserBookmarksProgramsQueryHookResult = ReturnType<
  typeof useAllUserBookmarksProgramsQuery
>
export type AllUserBookmarksProgramsLazyQueryHookResult = ReturnType<
  typeof useAllUserBookmarksProgramsLazyQuery
>
export type AllUserBookmarksProgramsQueryResult = Apollo.QueryResult<
  AllUserBookmarksProgramsQuery,
  AllUserBookmarksProgramsQueryVariables
>
export const UserBookmarksFeedTotalDocument = gql`
  query UserBookmarksFeedTotal($userId: ID!, $filters: BookmarkFilters) {
    user(id: $userId) {
      id
      bookmarksTotal(filters: $filters)
    }
  }
`

/**
 * __useUserBookmarksFeedTotalQuery__
 *
 * To run a query within a React component, call `useUserBookmarksFeedTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookmarksFeedTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookmarksFeedTotalQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUserBookmarksFeedTotalQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBookmarksFeedTotalQuery,
    UserBookmarksFeedTotalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserBookmarksFeedTotalQuery,
    UserBookmarksFeedTotalQueryVariables
  >(UserBookmarksFeedTotalDocument, options)
}
export function useUserBookmarksFeedTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBookmarksFeedTotalQuery,
    UserBookmarksFeedTotalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserBookmarksFeedTotalQuery,
    UserBookmarksFeedTotalQueryVariables
  >(UserBookmarksFeedTotalDocument, options)
}
export type UserBookmarksFeedTotalQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedTotalQuery
>
export type UserBookmarksFeedTotalLazyQueryHookResult = ReturnType<
  typeof useUserBookmarksFeedTotalLazyQuery
>
export type UserBookmarksFeedTotalQueryResult = Apollo.QueryResult<
  UserBookmarksFeedTotalQuery,
  UserBookmarksFeedTotalQueryVariables
>
export const ReforgeCollectionsDocument = gql`
  query ReforgeCollections {
    reforgeCollections {
      id
      name
      isWhiteGlove
      user {
        id
        fullName
      }
    }
  }
`

/**
 * __useReforgeCollectionsQuery__
 *
 * To run a query within a React component, call `useReforgeCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReforgeCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReforgeCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReforgeCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReforgeCollectionsQuery,
    ReforgeCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReforgeCollectionsQuery, ReforgeCollectionsQueryVariables>(
    ReforgeCollectionsDocument,
    options
  )
}
export function useReforgeCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReforgeCollectionsQuery,
    ReforgeCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReforgeCollectionsQuery, ReforgeCollectionsQueryVariables>(
    ReforgeCollectionsDocument,
    options
  )
}
export type ReforgeCollectionsQueryHookResult = ReturnType<
  typeof useReforgeCollectionsQuery
>
export type ReforgeCollectionsLazyQueryHookResult = ReturnType<
  typeof useReforgeCollectionsLazyQuery
>
export type ReforgeCollectionsQueryResult = Apollo.QueryResult<
  ReforgeCollectionsQuery,
  ReforgeCollectionsQueryVariables
>
export const BookmarkFoldersDocument = gql`
  query BookmarkFolders {
    currentUser {
      id
      hasSeenCollectionsPrompt
      bookmarkFolders {
        ...BookmarkFolderParts
      }
    }
    teamMembers {
      id
      profile {
        avatarUrl
      }
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
`

/**
 * __useBookmarkFoldersQuery__
 *
 * To run a query within a React component, call `useBookmarkFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookmarkFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BookmarkFoldersQuery,
    BookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookmarkFoldersQuery, BookmarkFoldersQueryVariables>(
    BookmarkFoldersDocument,
    options
  )
}
export function useBookmarkFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookmarkFoldersQuery,
    BookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookmarkFoldersQuery, BookmarkFoldersQueryVariables>(
    BookmarkFoldersDocument,
    options
  )
}
export type BookmarkFoldersQueryHookResult = ReturnType<typeof useBookmarkFoldersQuery>
export type BookmarkFoldersLazyQueryHookResult = ReturnType<
  typeof useBookmarkFoldersLazyQuery
>
export type BookmarkFoldersQueryResult = Apollo.QueryResult<
  BookmarkFoldersQuery,
  BookmarkFoldersQueryVariables
>
export const RecommendedBookmarkFoldersDocument = gql`
  query RecommendedBookmarkFolders($userId: ID!) {
    user(id: $userId) {
      recommendedBookmarkFolders {
        ...BookmarkFolderParts
      }
    }
  }
  ${BookmarkFolderPartsFragmentDoc}
`

/**
 * __useRecommendedBookmarkFoldersQuery__
 *
 * To run a query within a React component, call `useRecommendedBookmarkFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendedBookmarkFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendedBookmarkFoldersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRecommendedBookmarkFoldersQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecommendedBookmarkFoldersQuery,
    RecommendedBookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RecommendedBookmarkFoldersQuery,
    RecommendedBookmarkFoldersQueryVariables
  >(RecommendedBookmarkFoldersDocument, options)
}
export function useRecommendedBookmarkFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendedBookmarkFoldersQuery,
    RecommendedBookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RecommendedBookmarkFoldersQuery,
    RecommendedBookmarkFoldersQueryVariables
  >(RecommendedBookmarkFoldersDocument, options)
}
export type RecommendedBookmarkFoldersQueryHookResult = ReturnType<
  typeof useRecommendedBookmarkFoldersQuery
>
export type RecommendedBookmarkFoldersLazyQueryHookResult = ReturnType<
  typeof useRecommendedBookmarkFoldersLazyQuery
>
export type RecommendedBookmarkFoldersQueryResult = Apollo.QueryResult<
  RecommendedBookmarkFoldersQuery,
  RecommendedBookmarkFoldersQueryVariables
>
export const UserBookmarkFoldersDocument = gql`
  query UserBookmarkFolders($userId: ID!) {
    user(id: $userId) {
      id
      userCollections(userId: $userId) {
        id
        name
        description
        reforgeCollection
        forkedFromId
      }
    }
  }
`

/**
 * __useUserBookmarkFoldersQuery__
 *
 * To run a query within a React component, call `useUserBookmarkFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBookmarkFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBookmarkFoldersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserBookmarkFoldersQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBookmarkFoldersQuery,
    UserBookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserBookmarkFoldersQuery, UserBookmarkFoldersQueryVariables>(
    UserBookmarkFoldersDocument,
    options
  )
}
export function useUserBookmarkFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBookmarkFoldersQuery,
    UserBookmarkFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserBookmarkFoldersQuery, UserBookmarkFoldersQueryVariables>(
    UserBookmarkFoldersDocument,
    options
  )
}
export type UserBookmarkFoldersQueryHookResult = ReturnType<
  typeof useUserBookmarkFoldersQuery
>
export type UserBookmarkFoldersLazyQueryHookResult = ReturnType<
  typeof useUserBookmarkFoldersLazyQuery
>
export type UserBookmarkFoldersQueryResult = Apollo.QueryResult<
  UserBookmarkFoldersQuery,
  UserBookmarkFoldersQueryVariables
>
export const BookmarksFromFolderDocument = gql`
  query BookmarksFromFolder($folderId: ID!) {
    bookmarksFromFolder(folderId: $folderId) {
      id
      ...bookmarksFromFolderParts
    }
    bookmarkFolder(folderId: $folderId) {
      ...BookmarkFolderParts
    }
    goalProgress(folderId: $folderId)
    teamMembers {
      id
      profile {
        avatarUrl
      }
    }
  }
  ${BookmarksFromFolderPartsFragmentDoc}
  ${BookmarkFolderPartsFragmentDoc}
`

/**
 * __useBookmarksFromFolderQuery__
 *
 * To run a query within a React component, call `useBookmarksFromFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksFromFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksFromFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useBookmarksFromFolderQuery(
  baseOptions: Apollo.QueryHookOptions<
    BookmarksFromFolderQuery,
    BookmarksFromFolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BookmarksFromFolderQuery, BookmarksFromFolderQueryVariables>(
    BookmarksFromFolderDocument,
    options
  )
}
export function useBookmarksFromFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BookmarksFromFolderQuery,
    BookmarksFromFolderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BookmarksFromFolderQuery, BookmarksFromFolderQueryVariables>(
    BookmarksFromFolderDocument,
    options
  )
}
export type BookmarksFromFolderQueryHookResult = ReturnType<
  typeof useBookmarksFromFolderQuery
>
export type BookmarksFromFolderLazyQueryHookResult = ReturnType<
  typeof useBookmarksFromFolderLazyQuery
>
export type BookmarksFromFolderQueryResult = Apollo.QueryResult<
  BookmarksFromFolderQuery,
  BookmarksFromFolderQueryVariables
>
export const UserCohortDocument = gql`
  query userCohort($slug: String!) {
    userCohort(slug: $slug) {
      cohortEventsAttended
      numLessonsCompleted
      programId
      cohortCompletionCertificate {
        id
        certificateUrl
      }
    }
  }
`

/**
 * __useUserCohortQuery__
 *
 * To run a query within a React component, call `useUserCohortQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCohortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCohortQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserCohortQuery(
  baseOptions: Apollo.QueryHookOptions<UserCohortQuery, UserCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCohortQuery, UserCohortQueryVariables>(
    UserCohortDocument,
    options
  )
}
export function useUserCohortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserCohortQuery, UserCohortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCohortQuery, UserCohortQueryVariables>(
    UserCohortDocument,
    options
  )
}
export type UserCohortQueryHookResult = ReturnType<typeof useUserCohortQuery>
export type UserCohortLazyQueryHookResult = ReturnType<typeof useUserCohortLazyQuery>
export type UserCohortQueryResult = Apollo.QueryResult<
  UserCohortQuery,
  UserCohortQueryVariables
>
export const UserCohortHistoryDocument = gql`
  query UserCohortHistory($userId: ID!) {
    user(id: $userId) {
      ...CohortHistoryUser
    }
    upcomingSeason {
      id
      startsAt
      endsAt
      enrollmentsCloseAt
      faqs {
        seasonId
        question
        answer
        questionNumber
      }
    }
    enrollmentSeason {
      id
      startsAt
      endsAt
      prettyNameSeasonFirst
      showCohortViewerAt
      faqs {
        seasonId
        question
        answer
        questionNumber
      }
    }
    lastActiveSeason {
      enrollmentsCloseAt
    }
  }
  ${CohortHistoryUserFragmentDoc}
`

/**
 * __useUserCohortHistoryQuery__
 *
 * To run a query within a React component, call `useUserCohortHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCohortHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCohortHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCohortHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCohortHistoryQuery,
    UserCohortHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCohortHistoryQuery, UserCohortHistoryQueryVariables>(
    UserCohortHistoryDocument,
    options
  )
}
export function useUserCohortHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCohortHistoryQuery,
    UserCohortHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCohortHistoryQuery, UserCohortHistoryQueryVariables>(
    UserCohortHistoryDocument,
    options
  )
}
export type UserCohortHistoryQueryHookResult = ReturnType<
  typeof useUserCohortHistoryQuery
>
export type UserCohortHistoryLazyQueryHookResult = ReturnType<
  typeof useUserCohortHistoryLazyQuery
>
export type UserCohortHistoryQueryResult = Apollo.QueryResult<
  UserCohortHistoryQuery,
  UserCohortHistoryQueryVariables
>
export const UserCohortUsersCountDocument = gql`
  query userCohortUsersCount($slug: String) {
    userCohortUsersCount(slug: $slug)
  }
`

/**
 * __useUserCohortUsersCountQuery__
 *
 * To run a query within a React component, call `useUserCohortUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCohortUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCohortUsersCountQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserCohortUsersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCohortUsersCountQuery,
    UserCohortUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCohortUsersCountQuery, UserCohortUsersCountQueryVariables>(
    UserCohortUsersCountDocument,
    options
  )
}
export function useUserCohortUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCohortUsersCountQuery,
    UserCohortUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserCohortUsersCountQuery,
    UserCohortUsersCountQueryVariables
  >(UserCohortUsersCountDocument, options)
}
export type UserCohortUsersCountQueryHookResult = ReturnType<
  typeof useUserCohortUsersCountQuery
>
export type UserCohortUsersCountLazyQueryHookResult = ReturnType<
  typeof useUserCohortUsersCountLazyQuery
>
export type UserCohortUsersCountQueryResult = Apollo.QueryResult<
  UserCohortUsersCountQuery,
  UserCohortUsersCountQueryVariables
>
export const UserCohortUsersDocument = gql`
  query userCohortUsers(
    $cmsSectionId: ID
    $userId: ID
    $limit: Int
    $random: Boolean
    $onlyWithAvatar: Boolean
    $includeCurrentUser: Boolean
    $slug: String
  ) {
    userCohortUsers(
      cmsSectionId: $cmsSectionId
      userId: $userId
      limit: $limit
      random: $random
      onlyWithAvatar: $onlyWithAvatar
      includeCurrentUser: $includeCurrentUser
      slug: $slug
    ) {
      id
      profile {
        avatarUrl
      }
    }
  }
`

/**
 * __useUserCohortUsersQuery__
 *
 * To run a query within a React component, call `useUserCohortUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCohortUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCohortUsersQuery({
 *   variables: {
 *      cmsSectionId: // value for 'cmsSectionId'
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      random: // value for 'random'
 *      onlyWithAvatar: // value for 'onlyWithAvatar'
 *      includeCurrentUser: // value for 'includeCurrentUser'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserCohortUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserCohortUsersQuery,
    UserCohortUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCohortUsersQuery, UserCohortUsersQueryVariables>(
    UserCohortUsersDocument,
    options
  )
}
export function useUserCohortUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCohortUsersQuery,
    UserCohortUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCohortUsersQuery, UserCohortUsersQueryVariables>(
    UserCohortUsersDocument,
    options
  )
}
export type UserCohortUsersQueryHookResult = ReturnType<typeof useUserCohortUsersQuery>
export type UserCohortUsersLazyQueryHookResult = ReturnType<
  typeof useUserCohortUsersLazyQuery
>
export type UserCohortUsersQueryResult = Apollo.QueryResult<
  UserCohortUsersQuery,
  UserCohortUsersQueryVariables
>
export const CompletedModuleUsersCountDocument = gql`
  query completedModuleUsersCount($cmsModuleId: ID) {
    completedModuleUsersCount(cmsModuleId: $cmsModuleId)
  }
`

/**
 * __useCompletedModuleUsersCountQuery__
 *
 * To run a query within a React component, call `useCompletedModuleUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedModuleUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedModuleUsersCountQuery({
 *   variables: {
 *      cmsModuleId: // value for 'cmsModuleId'
 *   },
 * });
 */
export function useCompletedModuleUsersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompletedModuleUsersCountQuery,
    CompletedModuleUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompletedModuleUsersCountQuery,
    CompletedModuleUsersCountQueryVariables
  >(CompletedModuleUsersCountDocument, options)
}
export function useCompletedModuleUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompletedModuleUsersCountQuery,
    CompletedModuleUsersCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompletedModuleUsersCountQuery,
    CompletedModuleUsersCountQueryVariables
  >(CompletedModuleUsersCountDocument, options)
}
export type CompletedModuleUsersCountQueryHookResult = ReturnType<
  typeof useCompletedModuleUsersCountQuery
>
export type CompletedModuleUsersCountLazyQueryHookResult = ReturnType<
  typeof useCompletedModuleUsersCountLazyQuery
>
export type CompletedModuleUsersCountQueryResult = Apollo.QueryResult<
  CompletedModuleUsersCountQuery,
  CompletedModuleUsersCountQueryVariables
>
export const CourseSnapshotsForUserDocument = gql`
  query CourseSnapshotsForUser($userId: ID!) {
    user(id: $userId) {
      userCourses {
        all {
          ...UserCourseSnapshotParts
        }
      }
    }
  }
  ${UserCourseSnapshotPartsFragmentDoc}
`

/**
 * __useCourseSnapshotsForUserQuery__
 *
 * To run a query within a React component, call `useCourseSnapshotsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSnapshotsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSnapshotsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCourseSnapshotsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseSnapshotsForUserQuery,
    CourseSnapshotsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CourseSnapshotsForUserQuery,
    CourseSnapshotsForUserQueryVariables
  >(CourseSnapshotsForUserDocument, options)
}
export function useCourseSnapshotsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseSnapshotsForUserQuery,
    CourseSnapshotsForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CourseSnapshotsForUserQuery,
    CourseSnapshotsForUserQueryVariables
  >(CourseSnapshotsForUserDocument, options)
}
export type CourseSnapshotsForUserQueryHookResult = ReturnType<
  typeof useCourseSnapshotsForUserQuery
>
export type CourseSnapshotsForUserLazyQueryHookResult = ReturnType<
  typeof useCourseSnapshotsForUserLazyQuery
>
export type CourseSnapshotsForUserQueryResult = Apollo.QueryResult<
  CourseSnapshotsForUserQuery,
  CourseSnapshotsForUserQueryVariables
>
export const UserCoursesForUserDocument = gql`
  query UserCoursesForUser($userId: ID!) {
    user(id: $userId) {
      userCourses {
        all {
          ...UserCourseParts
        }
      }
      hasUseableCohortCredits
      subscriptions {
        active {
          id
          planName
        }
      }
    }
    activeCourseSessions {
      ...CourseSessionParts
    }
  }
  ${UserCoursePartsFragmentDoc}
  ${CourseSessionPartsFragmentDoc}
`

/**
 * __useUserCoursesForUserQuery__
 *
 * To run a query within a React component, call `useUserCoursesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCoursesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCoursesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserCoursesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserCoursesForUserQuery,
    UserCoursesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserCoursesForUserQuery, UserCoursesForUserQueryVariables>(
    UserCoursesForUserDocument,
    options
  )
}
export function useUserCoursesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserCoursesForUserQuery,
    UserCoursesForUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserCoursesForUserQuery, UserCoursesForUserQueryVariables>(
    UserCoursesForUserDocument,
    options
  )
}
export type UserCoursesForUserQueryHookResult = ReturnType<
  typeof useUserCoursesForUserQuery
>
export type UserCoursesForUserLazyQueryHookResult = ReturnType<
  typeof useUserCoursesForUserLazyQuery
>
export type UserCoursesForUserQueryResult = Apollo.QueryResult<
  UserCoursesForUserQuery,
  UserCoursesForUserQueryVariables
>
export const UserEmailsDocument = gql`
  query userEmails {
    userEmails {
      id
      confirmedByUser
      isPrimaryEmail
      email
    }
  }
`

/**
 * __useUserEmailsQuery__
 *
 * To run a query within a React component, call `useUserEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserEmailsQuery, UserEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEmailsQuery, UserEmailsQueryVariables>(
    UserEmailsDocument,
    options
  )
}
export function useUserEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserEmailsQuery, UserEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEmailsQuery, UserEmailsQueryVariables>(
    UserEmailsDocument,
    options
  )
}
export type UserEmailsQueryHookResult = ReturnType<typeof useUserEmailsQuery>
export type UserEmailsLazyQueryHookResult = ReturnType<typeof useUserEmailsLazyQuery>
export type UserEmailsQueryResult = Apollo.QueryResult<
  UserEmailsQuery,
  UserEmailsQueryVariables
>
export const UserGroupMembershipDocument = gql`
  query UserGroupMembership {
    groups {
      groups {
        id
        userIsMember
      }
    }
  }
`

/**
 * __useUserGroupMembershipQuery__
 *
 * To run a query within a React component, call `useUserGroupMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGroupMembershipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserGroupMembershipQuery,
    UserGroupMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserGroupMembershipQuery, UserGroupMembershipQueryVariables>(
    UserGroupMembershipDocument,
    options
  )
}
export function useUserGroupMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserGroupMembershipQuery,
    UserGroupMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserGroupMembershipQuery, UserGroupMembershipQueryVariables>(
    UserGroupMembershipDocument,
    options
  )
}
export type UserGroupMembershipQueryHookResult = ReturnType<
  typeof useUserGroupMembershipQuery
>
export type UserGroupMembershipLazyQueryHookResult = ReturnType<
  typeof useUserGroupMembershipLazyQuery
>
export type UserGroupMembershipQueryResult = Apollo.QueryResult<
  UserGroupMembershipQuery,
  UserGroupMembershipQueryVariables
>
export const UserOrgDocument = gql`
  query userOrg($id: ID!) {
    user(id: $id) {
      id
      subscriptions {
        active {
          id
          org {
            id
            orgAdmins {
              id
              user {
                id
                email
                fullName
              }
              status
            }
            ...UserOrg
          }
        }
      }
    }
  }
  ${UserOrgFragmentDoc}
`

/**
 * __useUserOrgQuery__
 *
 * To run a query within a React component, call `useUserOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrgQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOrgQuery(
  baseOptions: Apollo.QueryHookOptions<UserOrgQuery, UserOrgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options)
}
export function useUserOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOrgQuery, UserOrgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserOrgQuery, UserOrgQueryVariables>(
    UserOrgDocument,
    options
  )
}
export type UserOrgQueryHookResult = ReturnType<typeof useUserOrgQuery>
export type UserOrgLazyQueryHookResult = ReturnType<typeof useUserOrgLazyQuery>
export type UserOrgQueryResult = Apollo.QueryResult<UserOrgQuery, UserOrgQueryVariables>
export const UserPaymentHistoryDocument = gql`
  query userPaymentHistory($id: ID!) {
    user(id: $id) {
      accessPolicyKind
      id
      pastPayments {
        ...PastPaymentFields
      }
      openInvoices {
        ...OpenInvoiceFields
      }
    }
  }
  ${PastPaymentFieldsFragmentDoc}
  ${OpenInvoiceFieldsFragmentDoc}
`

/**
 * __useUserPaymentHistoryQuery__
 *
 * To run a query within a React component, call `useUserPaymentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPaymentHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserPaymentHistoryQuery,
    UserPaymentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserPaymentHistoryQuery, UserPaymentHistoryQueryVariables>(
    UserPaymentHistoryDocument,
    options
  )
}
export function useUserPaymentHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPaymentHistoryQuery,
    UserPaymentHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserPaymentHistoryQuery, UserPaymentHistoryQueryVariables>(
    UserPaymentHistoryDocument,
    options
  )
}
export type UserPaymentHistoryQueryHookResult = ReturnType<
  typeof useUserPaymentHistoryQuery
>
export type UserPaymentHistoryLazyQueryHookResult = ReturnType<
  typeof useUserPaymentHistoryLazyQuery
>
export type UserPaymentHistoryQueryResult = Apollo.QueryResult<
  UserPaymentHistoryQuery,
  UserPaymentHistoryQueryVariables
>
export const UserPaymentMethodsDocument = gql`
  query userPaymentMethods($userId: ID!) {
    currentUser {
      accessPolicyKind
      id
      subscriptions {
        active {
          id
          userIsOwner
          stripeSubscriptionStatus
          stripeCollectionMethod
        }
        lastExpired {
          id
          userIsOwner
          stripeSubscriptionStatus
          stripeCollectionMethod
        }
      }
    }
    userPaymentMethods(userId: $userId) {
      id
      ...PaymentMethod
    }
    userPaymentSources(userId: $userId) {
      ... on StripeBankAccount {
        id
        ...StripeBankAccountParts
      }
      ... on StripeSource {
        id
        ...StripeSourceParts
      }
    }
  }
  ${PaymentMethodFragmentDoc}
  ${StripeBankAccountPartsFragmentDoc}
  ${StripeSourcePartsFragmentDoc}
`

/**
 * __useUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(
    UserPaymentMethodsDocument,
    options
  )
}
export function useUserPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserPaymentMethodsQuery, UserPaymentMethodsQueryVariables>(
    UserPaymentMethodsDocument,
    options
  )
}
export type UserPaymentMethodsQueryHookResult = ReturnType<
  typeof useUserPaymentMethodsQuery
>
export type UserPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useUserPaymentMethodsLazyQuery
>
export type UserPaymentMethodsQueryResult = Apollo.QueryResult<
  UserPaymentMethodsQuery,
  UserPaymentMethodsQueryVariables
>
export const CurrentUserPaymentMethodsDocument = gql`
  query currentUserPaymentMethods {
    userPaymentMethods {
      id
      ...PaymentMethod
    }
  }
  ${PaymentMethodFragmentDoc}
`

/**
 * __useCurrentUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useCurrentUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserPaymentMethodsQuery,
    CurrentUserPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserPaymentMethodsQuery,
    CurrentUserPaymentMethodsQueryVariables
  >(CurrentUserPaymentMethodsDocument, options)
}
export function useCurrentUserPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserPaymentMethodsQuery,
    CurrentUserPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserPaymentMethodsQuery,
    CurrentUserPaymentMethodsQueryVariables
  >(CurrentUserPaymentMethodsDocument, options)
}
export type CurrentUserPaymentMethodsQueryHookResult = ReturnType<
  typeof useCurrentUserPaymentMethodsQuery
>
export type CurrentUserPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserPaymentMethodsLazyQuery
>
export type CurrentUserPaymentMethodsQueryResult = Apollo.QueryResult<
  CurrentUserPaymentMethodsQuery,
  CurrentUserPaymentMethodsQueryVariables
>
export const UserRecentlyReviewedContentDocument = gql`
  query userRecentlyReviewedContent($userId: ID!) {
    user(id: $userId) {
      id
      recentlyReviewedContent {
        accessLevel
        cmsSection {
          id
          slug
          ...ContentCardParts
          ...LessonCardContentParts
        }
        contentBookmarkId
        contentIsInMySavedItems
        showNewBadge
      }
    }
  }
  ${ContentCardPartsFragmentDoc}
  ${LessonCardContentPartsFragmentDoc}
`

/**
 * __useUserRecentlyReviewedContentQuery__
 *
 * To run a query within a React component, call `useUserRecentlyReviewedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRecentlyReviewedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRecentlyReviewedContentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserRecentlyReviewedContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserRecentlyReviewedContentQuery,
    UserRecentlyReviewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserRecentlyReviewedContentQuery,
    UserRecentlyReviewedContentQueryVariables
  >(UserRecentlyReviewedContentDocument, options)
}
export function useUserRecentlyReviewedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserRecentlyReviewedContentQuery,
    UserRecentlyReviewedContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserRecentlyReviewedContentQuery,
    UserRecentlyReviewedContentQueryVariables
  >(UserRecentlyReviewedContentDocument, options)
}
export type UserRecentlyReviewedContentQueryHookResult = ReturnType<
  typeof useUserRecentlyReviewedContentQuery
>
export type UserRecentlyReviewedContentLazyQueryHookResult = ReturnType<
  typeof useUserRecentlyReviewedContentLazyQuery
>
export type UserRecentlyReviewedContentQueryResult = Apollo.QueryResult<
  UserRecentlyReviewedContentQuery,
  UserRecentlyReviewedContentQueryVariables
>
export const UserSubscriptionsDocument = gql`
  query UserSubscriptions($id: ID!) {
    user(id: $id) {
      id
      accessPolicyKind
      canSubscribeWithoutTrial
      pricing {
        term
      }
      contact {
        allEmails
        primaryEmail
      }
      can {
        pay
      }
      is {
        member
        paidMember
        alumni
        badgeholder
        staff
        enrolledInLiveProgram
      }
      subscriptions {
        active {
          id
          daysLeft
          userIsOwner
          numSeats
          expiresAt
          isPaidMonthly
          termEndsAt
          isExpired
          isDunning
          isCanceled
          status
          isPartnerSubscription
          stripeSubscriptionId
          expiringSeatCount
          purchaser {
            id
            profile {
              fullName
            }
          }
          seats {
            id
            scheduledForDeletionDate
            subscriptionMember {
              id
              userId
              email
              status
              role
              createdAt
            }
          }
          stripeSubscription {
            id
            status
            paymentFailed
            currentPeriodEnd
            cancelAt
            cancelAtPeriodEnd
            plan {
              id
              active
            }
          }
        }
        lastExpired {
          id
          expiresAt
        }
        trial {
          id
          status
          startsAt
          expiresAt
          trialLength
          daysLeftInTrial
          trialType
        }
      }
    }
  }
`

/**
 * __useUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(
    UserSubscriptionsDocument,
    options
  )
}
export function useUserSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(
    UserSubscriptionsDocument,
    options
  )
}
export type UserSubscriptionsQueryHookResult = ReturnType<
  typeof useUserSubscriptionsQuery
>
export type UserSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionsLazyQuery
>
export type UserSubscriptionsQueryResult = Apollo.QueryResult<
  UserSubscriptionsQuery,
  UserSubscriptionsQueryVariables
>
export const UserSubscriptionCancelModalDocument = gql`
  query UserSubscriptionCancelModal($id: ID!) {
    user(id: $id) {
      id
      subscriptions {
        active {
          id
          isDunning
          expiresAt
          numSeatsAffectedByCancellation
          isTeam
        }
      }
    }
  }
`

/**
 * __useUserSubscriptionCancelModalQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionCancelModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionCancelModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionCancelModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionCancelModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionCancelModalQuery,
    UserSubscriptionCancelModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSubscriptionCancelModalQuery,
    UserSubscriptionCancelModalQueryVariables
  >(UserSubscriptionCancelModalDocument, options)
}
export function useUserSubscriptionCancelModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionCancelModalQuery,
    UserSubscriptionCancelModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionCancelModalQuery,
    UserSubscriptionCancelModalQueryVariables
  >(UserSubscriptionCancelModalDocument, options)
}
export type UserSubscriptionCancelModalQueryHookResult = ReturnType<
  typeof useUserSubscriptionCancelModalQuery
>
export type UserSubscriptionCancelModalLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionCancelModalLazyQuery
>
export type UserSubscriptionCancelModalQueryResult = Apollo.QueryResult<
  UserSubscriptionCancelModalQuery,
  UserSubscriptionCancelModalQueryVariables
>
export const UserSubscriptionModalDocument = gql`
  query userSubscriptionModal {
    currentUser {
      id
      accessPolicyKind
      can {
        pay
      }
      email
      subscriptions {
        ...UserSubscription
      }
      pricing {
        ...Pricing
      }
    }
  }
  ${UserSubscriptionFragmentDoc}
  ${PricingFragmentDoc}
`

/**
 * __useUserSubscriptionModalQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionModalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSubscriptionModalQuery,
    UserSubscriptionModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserSubscriptionModalQuery, UserSubscriptionModalQueryVariables>(
    UserSubscriptionModalDocument,
    options
  )
}
export function useUserSubscriptionModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionModalQuery,
    UserSubscriptionModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionModalQuery,
    UserSubscriptionModalQueryVariables
  >(UserSubscriptionModalDocument, options)
}
export type UserSubscriptionModalQueryHookResult = ReturnType<
  typeof useUserSubscriptionModalQuery
>
export type UserSubscriptionModalLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionModalLazyQuery
>
export type UserSubscriptionModalQueryResult = Apollo.QueryResult<
  UserSubscriptionModalQuery,
  UserSubscriptionModalQueryVariables
>
export const UserSubscriptionPlanDetailsDocument = gql`
  query userSubscriptionPlanDetails($id: ID!) {
    user(id: $id) {
      accessPolicyKind
      id
      subscriptions {
        active {
          id
          isTeam
          numSeats
          isExpired
          userIsOwner
          hasUnlimitedCohortAccess
        }
        lastExpired {
          id
          isTeam
          numSeats
          userIsOwner
        }
      }
    }
  }
`

/**
 * __useUserSubscriptionPlanDetailsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPlanDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionPlanDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionPlanDetailsQuery,
    UserSubscriptionPlanDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSubscriptionPlanDetailsQuery,
    UserSubscriptionPlanDetailsQueryVariables
  >(UserSubscriptionPlanDetailsDocument, options)
}
export function useUserSubscriptionPlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionPlanDetailsQuery,
    UserSubscriptionPlanDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionPlanDetailsQuery,
    UserSubscriptionPlanDetailsQueryVariables
  >(UserSubscriptionPlanDetailsDocument, options)
}
export type UserSubscriptionPlanDetailsQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlanDetailsQuery
>
export type UserSubscriptionPlanDetailsLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlanDetailsLazyQuery
>
export type UserSubscriptionPlanDetailsQueryResult = Apollo.QueryResult<
  UserSubscriptionPlanDetailsQuery,
  UserSubscriptionPlanDetailsQueryVariables
>
export const UserSubscriptionReactivationDocument = gql`
  query UserSubscriptionReactivation {
    currentUser {
      id
      paymentMethods {
        id
      }
      stripeCustomer {
        id
        balance
      }
      subscriptions {
        active {
          id
          userIsOwner
          ...SubscriptionReactivationFields
        }
      }
    }
  }
  ${SubscriptionReactivationFieldsFragmentDoc}
`

/**
 * __useUserSubscriptionReactivationQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionReactivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionReactivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionReactivationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionReactivationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSubscriptionReactivationQuery,
    UserSubscriptionReactivationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSubscriptionReactivationQuery,
    UserSubscriptionReactivationQueryVariables
  >(UserSubscriptionReactivationDocument, options)
}
export function useUserSubscriptionReactivationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionReactivationQuery,
    UserSubscriptionReactivationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionReactivationQuery,
    UserSubscriptionReactivationQueryVariables
  >(UserSubscriptionReactivationDocument, options)
}
export type UserSubscriptionReactivationQueryHookResult = ReturnType<
  typeof useUserSubscriptionReactivationQuery
>
export type UserSubscriptionReactivationLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionReactivationLazyQuery
>
export type UserSubscriptionReactivationQueryResult = Apollo.QueryResult<
  UserSubscriptionReactivationQuery,
  UserSubscriptionReactivationQueryVariables
>
export const UserSubscriptionSummaryDocument = gql`
  query userSubscriptionSummary($id: ID!) {
    user(id: $id) {
      accessPolicyKind
      id
      can {
        pay
        viewSubscriptionSummary
      }
      canSubscribeWithoutTrial
      subscriptions {
        ...SubscriptionsFields
      }
    }
  }
  ${SubscriptionsFieldsFragmentDoc}
`

/**
 * __useUserSubscriptionSummaryQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionSummaryQuery,
    UserSubscriptionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSubscriptionSummaryQuery,
    UserSubscriptionSummaryQueryVariables
  >(UserSubscriptionSummaryDocument, options)
}
export function useUserSubscriptionSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionSummaryQuery,
    UserSubscriptionSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionSummaryQuery,
    UserSubscriptionSummaryQueryVariables
  >(UserSubscriptionSummaryDocument, options)
}
export type UserSubscriptionSummaryQueryHookResult = ReturnType<
  typeof useUserSubscriptionSummaryQuery
>
export type UserSubscriptionSummaryLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionSummaryLazyQuery
>
export type UserSubscriptionSummaryQueryResult = Apollo.QueryResult<
  UserSubscriptionSummaryQuery,
  UserSubscriptionSummaryQueryVariables
>
export const UserSubscriptionWithTeamDocument = gql`
  query UserSubscriptionWithTeam($id: ID!) {
    user(id: $id) {
      id
      ...UserSubscriptionWithTeamFields
    }
  }
  ${UserSubscriptionWithTeamFieldsFragmentDoc}
`

/**
 * __useUserSubscriptionWithTeamQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionWithTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionWithTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionWithTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionWithTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSubscriptionWithTeamQuery,
    UserSubscriptionWithTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserSubscriptionWithTeamQuery,
    UserSubscriptionWithTeamQueryVariables
  >(UserSubscriptionWithTeamDocument, options)
}
export function useUserSubscriptionWithTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSubscriptionWithTeamQuery,
    UserSubscriptionWithTeamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserSubscriptionWithTeamQuery,
    UserSubscriptionWithTeamQueryVariables
  >(UserSubscriptionWithTeamDocument, options)
}
export type UserSubscriptionWithTeamQueryHookResult = ReturnType<
  typeof useUserSubscriptionWithTeamQuery
>
export type UserSubscriptionWithTeamLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionWithTeamLazyQuery
>
export type UserSubscriptionWithTeamQueryResult = Apollo.QueryResult<
  UserSubscriptionWithTeamQuery,
  UserSubscriptionWithTeamQueryVariables
>
export const UsersFeedDocument = gql`
  query usersFeed($cohortSlug: String, $limit: Int, $offset: Int, $search: String) {
    usersFeed(cohortSlug: $cohortSlug, limit: $limit, offset: $offset, search: $search) {
      count
      totalCount
      users {
        id
        profile {
          fullName
          role
          companyName
          avatarUrl
        }
      }
    }
  }
`

/**
 * __useUsersFeedQuery__
 *
 * To run a query within a React component, call `useUsersFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersFeedQuery({
 *   variables: {
 *      cohortSlug: // value for 'cohortSlug'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersFeedQuery, UsersFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsersFeedQuery, UsersFeedQueryVariables>(
    UsersFeedDocument,
    options
  )
}
export function useUsersFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersFeedQuery, UsersFeedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsersFeedQuery, UsersFeedQueryVariables>(
    UsersFeedDocument,
    options
  )
}
export type UsersFeedQueryHookResult = ReturnType<typeof useUsersFeedQuery>
export type UsersFeedLazyQueryHookResult = ReturnType<typeof useUsersFeedLazyQuery>
export type UsersFeedQueryResult = Apollo.QueryResult<
  UsersFeedQuery,
  UsersFeedQueryVariables
>
export const CreateRailsSubscriptionDocument = gql`
  mutation CreateRailsSubscription($input: CreateRailsSubscriptionInput!) {
    createRailsSubscription(input: $input) {
      success
      errors
    }
  }
`
export type CreateRailsSubscriptionMutationFn = Apollo.MutationFunction<
  CreateRailsSubscriptionMutation,
  CreateRailsSubscriptionMutationVariables
>

/**
 * __useCreateRailsSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateRailsSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRailsSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRailsSubscriptionMutation, { data, loading, error }] = useCreateRailsSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRailsSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRailsSubscriptionMutation,
    CreateRailsSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRailsSubscriptionMutation,
    CreateRailsSubscriptionMutationVariables
  >(CreateRailsSubscriptionDocument, options)
}
export type CreateRailsSubscriptionMutationHookResult = ReturnType<
  typeof useCreateRailsSubscriptionMutation
>
export type CreateRailsSubscriptionMutationResult =
  Apollo.MutationResult<CreateRailsSubscriptionMutation>
export type CreateRailsSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateRailsSubscriptionMutation,
  CreateRailsSubscriptionMutationVariables
>
export const SearchSuggestionsDocument = gql`
  query SearchSuggestions($query: String!) {
    searchSuggestions(query: $query)
  }
`

/**
 * __useSearchSuggestionsQuery__
 *
 * To run a query within a React component, call `useSearchSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(
    SearchSuggestionsDocument,
    options
  )
}
export function useSearchSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSuggestionsQuery,
    SearchSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchSuggestionsQuery, SearchSuggestionsQueryVariables>(
    SearchSuggestionsDocument,
    options
  )
}
export type SearchSuggestionsQueryHookResult = ReturnType<
  typeof useSearchSuggestionsQuery
>
export type SearchSuggestionsLazyQueryHookResult = ReturnType<
  typeof useSearchSuggestionsLazyQuery
>
export type SearchSuggestionsQueryResult = Apollo.QueryResult<
  SearchSuggestionsQuery,
  SearchSuggestionsQueryVariables
>
export const SimilaritySearchDocument = gql`
  query SimilaritySearch($query: String!, $type: String, $initiator: String) {
    similaritySearch(query: $query, type: $type, initiator: $initiator) {
      results {
        id
        ...SimilarityContent
      }
      suggestions
      id
    }
  }
  ${SimilarityContentFragmentDoc}
`

/**
 * __useSimilaritySearchQuery__
 *
 * To run a query within a React component, call `useSimilaritySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilaritySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilaritySearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      type: // value for 'type'
 *      initiator: // value for 'initiator'
 *   },
 * });
 */
export function useSimilaritySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimilaritySearchQuery,
    SimilaritySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SimilaritySearchQuery, SimilaritySearchQueryVariables>(
    SimilaritySearchDocument,
    options
  )
}
export function useSimilaritySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimilaritySearchQuery,
    SimilaritySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SimilaritySearchQuery, SimilaritySearchQueryVariables>(
    SimilaritySearchDocument,
    options
  )
}
export type SimilaritySearchQueryHookResult = ReturnType<typeof useSimilaritySearchQuery>
export type SimilaritySearchLazyQueryHookResult = ReturnType<
  typeof useSimilaritySearchLazyQuery
>
export type SimilaritySearchQueryResult = Apollo.QueryResult<
  SimilaritySearchQuery,
  SimilaritySearchQueryVariables
>
export const TeamMembersPageDocument = gql`
  query teamMembersPage($id: ID!) {
    user(id: $id) {
      id
      subscriptions {
        active {
          ...TeamMembersPageSubscription
        }
      }
    }
  }
  ${TeamMembersPageSubscriptionFragmentDoc}
`

/**
 * __useTeamMembersPageQuery__
 *
 * To run a query within a React component, call `useTeamMembersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamMembersPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamMembersPageQuery,
    TeamMembersPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamMembersPageQuery, TeamMembersPageQueryVariables>(
    TeamMembersPageDocument,
    options
  )
}
export function useTeamMembersPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamMembersPageQuery,
    TeamMembersPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamMembersPageQuery, TeamMembersPageQueryVariables>(
    TeamMembersPageDocument,
    options
  )
}
export type TeamMembersPageQueryHookResult = ReturnType<typeof useTeamMembersPageQuery>
export type TeamMembersPageLazyQueryHookResult = ReturnType<
  typeof useTeamMembersPageLazyQuery
>
export type TeamMembersPageQueryResult = Apollo.QueryResult<
  TeamMembersPageQuery,
  TeamMembersPageQueryVariables
>
