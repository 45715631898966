/*
    Largely this module is a small abstraction over the Segement API.
    It helps us avoid accessing window directly and gives us a place
    to add some type safety for Segment identify/track/page calls.
*/
import { EventMapper } from './generated/types/mapper'

export function identify(userId: string | undefined, traits: Record<any, any>): void {
  window.analytics?.identify(userId, traits)
}

/*
    track accepts many unique combinations of eventNames and eventData.
    The preference is to use functions generated from the Segment Tracking Plan.
*/
export function track<EventKey extends keyof EventMapper>(
  eventName: EventKey,
  eventData: EventMapper[EventKey]
): void {
  window.analytics?.track(eventName, eventData)
}

export function trackPage(referrer: string): void {
  window.analytics?.page({
    path: window.location.pathname,
    url: window.location.href,
    referrer: referrer,
    search: window.location.search,
    title: document.title
  })
}

export function getAnonymousId(): string {
  return window.analytics?.user?.()?.anonymousId() || ''
}
