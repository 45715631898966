import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

export const useFeatureFlags: any = () => {
  const ldClient = useLDClient()
  const ldUser = ldClient?.getContext && ldClient.getContext()

  return {
    ...useFlags(),
    showPayToPlayMarketingSite: true,
    // NOTE: When the app first loads, the flags may be retrieved and read before the user has been identified for LD.
    // IF you are gating by userId, this may cause a flicker of the default treatment, which can be avoided by checking the userIdentified bool before evaluating the flag.
    // More info on this issue may be found here: https://docs.launchdarkly.com/sdk/client-side/javascript/default-values#block-drawing-the-page-until-the-flag-values-are-available
    userIdentified: !!ldUser && !ldUser.anonymous && ldUser.key !== '0'
  }
}
